import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from '@mui/icons-material/Info';

import { AppDispatch } from "../../../../../shared/state/store";
import { Button, DialogActionButton, saveCallbackStatus } from "@finpay-development/shared-components";

import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearStatus } from "src/implementation-specialist/state/clients/implementation-clients-slice";
import { RootState } from "src/shared/state/root-reducer";
import { deleteClientRiskAssessmentConfig } from "src/implementation-specialist/state/clients/implementation-clients-thunk";
import { ClientRiskAssessmentFields } from "src/shared/model/client-risk-assessment-config";

interface DeleteOperatingCostsModalProps {
    open: boolean;
    selectedFacilityRiskAssessmentFields: ClientRiskAssessmentFields;
    handleDeleteOperatingCostsModalCancel: () => void;
    handleDeleteOperatingCostsModalSubmit: () => void;
  }

export function DeleteOperatingCostsModal(props: DeleteOperatingCostsModalProps) {
    const {
        open,
        selectedFacilityRiskAssessmentFields,
        handleDeleteOperatingCostsModalCancel,
        handleDeleteOperatingCostsModalSubmit,
    } = props;

    const dispatch = useDispatch<AppDispatch>();

    async function handleDelete() {
        const marginId = selectedFacilityRiskAssessmentFields?.clientPatientEpisodeMarginId

        const result = await dispatch(deleteClientRiskAssessmentConfig({marginId}))

        if(result.meta.requestStatus === "fulfilled") {
            handleDeleteOperatingCostsModalSubmit();
        } else {
            dispatch(showErrorStatus("Delete facility cost failed"));
        }
        handleDeleteOperatingCostsModalCancel();
        dispatch(clearStatus());
    }

    return (
        <Dialog
            scroll="body"
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>
                <span className="title">Delete Facility Costs</span>
            </DialogTitle>
            <DialogContent>
               Are you sure you would like to delete the operating cost configuration for this facility?
               Once deleted, the client's operating cost configuration will be used as the default for this facility.
            </DialogContent>
            <DialogActions>
                <Button
                    type="secondary"
                    onClick={handleDeleteOperatingCostsModalCancel}
                >Cancel</Button>
                <Button
                    type="primary"
                    style={{
                        backgroundColor: "#df1e44",
                        border: 'solid 1px #df1e44'
                    }}
                    onClick={handleDelete}
                >Delete</Button>
            </DialogActions>
        </Dialog>

    );

}