import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../shared/state/root-reducer';
import {
  setIsLocModalOpen,
} from '../../../state/patient-record-locking-slice';
import {AppDispatch} from '../../../../shared/state/store';
import React from 'react';
import {Grid} from '@mui/material';
import AccessControl from '../../../../security/components/access-control';
import {RolePageNames} from '../../../../security/model/role-page-names';
import {FinpassLevelOfCareModal} from './finpass-loc-modal';
import {LOCSummary} from '@finpay-development/shared-components'



export const LevelsOfCareAccordionContents = (
) => {

  const state = {
    selectedPatientEncounter: useSelector(
        (state: RootState) => state.patientContext.selectedEncounter
    ),
    patientModalOpenStatus: useSelector(
        (state: RootState) =>
            state.patientRecordLockingContext.patientModalOpenStatus
    ),
    patientVOBState: useSelector(
        (state: RootState) => state.patientContext.selectedVOB
    ),
    estimatorState: useSelector(
        (state: RootState) => state.patientContext.selectedEstimate.estimator
    ),
    facilities: useSelector(
        (state: RootState) =>
            state.implementationContext.implementationFacility.facilities
    ),
    vob: useSelector(
        (state: RootState) => state.patientContext.selectedVOB
    ),
  };

  const {
    selectedPatientEncounter,
    estimatorState,
    patientModalOpenStatus,
    facilities,
    vob
  } = state;

  const dispatch = useDispatch<AppDispatch>();

  const handleLocModalOpen = () => {
    dispatch(setIsLocModalOpen(true));
  };

  const handleLocModalClose = () => {
    dispatch(setIsLocModalOpen(false))
  }

  return (
      <>
        <Grid container>
          <AccessControl
              rolePageName={RolePageNames.PatientRecords}
              actionName="Add Instance of Care"
              renderNoAccess={() => {
                return (
                    <LOCSummary
                        handleLocModalOpen={handleLocModalOpen}
                        enableLOSbutton={false}
                        facilities={facilities}
                        selectedLevelsOfCare={estimatorState.selectedLevelsOfCare}
                        quoteMethod={estimatorState.quoteMethod}
                        vob={{
                          inNetwork: !!vob?.payer?.inNetwork,
                          isSelfPay: !!vob?.selfPay
                        }}
                        columnsToShow={["code","name"]}
                    />
                )
              }}
          >
            <LOCSummary
                handleLocModalOpen={handleLocModalOpen}
                enableLOSbutton={
                    !selectedPatientEncounter?.isConverted &&
                    typeof estimatorState.totalEstimatedPfr !== 'number'                //we are making LOC non-editable for IOCs created through FinAdvisor
                }
                facilities={facilities}
                selectedLevelsOfCare={estimatorState.selectedLevelsOfCare}
                quoteMethod={estimatorState.quoteMethod}
                vob={{
                  inNetwork: !!vob?.payer?.inNetwork,
                  isSelfPay: !!vob?.selfPay
                }}
                columnsToShow={["code","name"]}
            />
          </AccessControl>
        </Grid>
        {(patientModalOpenStatus?.isLocModalOpen) && (
            <FinpassLevelOfCareModal
                open={patientModalOpenStatus?.isLocModalOpen}
                clientId={selectedPatientEncounter.clientId}
                facilityId={selectedPatientEncounter.facilityId}
                facilityName={facilities?.flat().find((el) => el.facilityId === selectedPatientEncounter.facilityId)?.facilityName}
                quoteMethod={estimatorState?.quoteMethod}
                handleFinpassLevelOfCareModalClose={handleLocModalClose}
            />
        )}
      </>
  );
};
