import React from 'react';

function HomeView() {
    return (
        <div className="page-container">
            <main>
            </main>
        </div>
    )
}

export default HomeView;