import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ClientBillingIds, ClientsAndFacilities, PatientsAndAccountHolders } from '../../../implementation-specialist/models/reportsFilters';
import { emptyUser, getDefaultUser, User } from '../../../security/model/user';
import { UserRole } from '../../../security/model/user-role';
import { Client } from '../../../shared/model/client';
import { GuestParameterInfo } from '../../../guest/models/anonymous-guest';
import { SavingStatus, UNKNOWN_SERVER_ERROR } from '../../../shared/state/saving-status';
import { FilterValues } from '../../models/filter-values';
import { FilteredUserInfo } from '../../models/filtered-user-info';
import { LogsInfo } from '../../models/log-info';
import { emptyReportFilter, emptyReportInfo, ReportFilter, ReportsInfo } from '../../models/reports-info';
import { getDefaultUserInfo, UserInfo } from '../../models/user-info';
import { emptyUserInfoClient, UserInfoClient } from '../../models/user-info-client';
import { ChannelInfo } from '../../models/channel-payments';
import {
  deActivateUser,
  filterLogs,
  filterUsers,
  getUser,
  getUserCognitoStatus,
  handleGetClients,
  handleGetDetailedFilteredReports,
  handleGetDetailedSettlementReport,
  handleGetFilteredReports,
  handleGetLogs,
  handleGetReports,
  reActivateUser,
  saveUser,
  getPaymentChannels,
} from './admin-thunk';
import { UserSearchFormValues,blankUserSearchFormValues } from 'src/admin/components/admin-users/admin-user-search-form';

interface AdminContextState extends SavingStatus {
  userSearch: any; // TODO: don't use any
  logsSearch: any; // TODO: don't use any
  allReports: ReportsInfo[]; // TODO: don't use any
  selectedUser: UserInfo;
  fullUser: User;
  selectedClient: UserInfoClient;
  searchUserForm: Partial<UserSearchFormValues>
  currentFilterUserValues: FilterValues; // filter object for main Users filter
  currentFilterLogsValues:FilterValues;
  currentFilterReportsValues:FilterValues;
  filteredUsers: FilteredUserInfo[];
  filteredLogs: LogsInfo[] | any;
  filteredReport: ReportsInfo;
  filteredDetailedReport: ReportsInfo;
  reportTypeValues:Array<Object>
  roles: UserRole[];
  clients: Client[];
  userModalForm: {};
  modalSaveStatus: saveCallbackStatus;
  modelErrorMessage: string;
  isLoading: boolean;
  isLoadingCognito: boolean;
  isReportsLoading:boolean;
  isSettlementDetailedLoading: boolean
  clientCheckboxesChecked: Number[];
  facilityCheckboxesChecked: Number[];
  clientAndFacilityData: Client[];
  reportFilters: ReportFilter;
  csvData: Array<Array<string>>;
  patientsNames: string[];
  accountHoldersNames: string[];
  collabIds: string[];
  clientNames: string[];
  facilityNames: string[];
  detailedPatientsNames: string[];
  detailedAccountHoldersNames: string[];
  detailedClientNames: string[];
  detailedFacilityNames: string[];
  usersCsvData: [string[]];
  showDetailModal: boolean;
  isPublicMode: boolean;
  isPublicModeLoggedIn: boolean;
  urlParameters: GuestParameterInfo;
  paymentChannels: ChannelInfo[] | any;
  showLogin: boolean;
  pathName: string;
}

const initialState = {
  userSearch: {
    allUsers: [],
    clientsFilter: [],
    rolesFilter: [],
    facilitiesFilter: [],
  },
  logsSearch: {
    allLogs: [],
  },
  allReports:[],
  filteredUsers: [],
  filteredLogs:[],
  filteredReport: emptyReportInfo,
  filteredDetailedReport: emptyReportInfo,
  reportTypeValues:[],
  selectedUser: getDefaultUserInfo(),
  fullUser: emptyUser,
  selectedClient: emptyUserInfoClient,
  currentFilterUserValues: {},
  currentFilterLogsValues:{},
  currentFilterReportsValues:{},
  roles: [],
  clients: [],
  userModalForm: {},
  modalSaveStatus: saveCallbackStatus.none,
  modelErrorMessage: "",
  isLoading: false,
  isLoadingCognito: false,
  isReportsLoading:false,
  isSettlementDetailedLoading: false,
  clientCheckboxesChecked:[],
  facilityCheckboxesChecked:[],
  clientAndFacilityData: [],
  reportFilters: emptyReportFilter,
  patientsNames: [],
  accountHoldersNames: [],
  clientNames: [],
  collabIds: [],
  facilityNames: [],
  detailedPatientsNames: [],
  detailedAccountHoldersNames: [],
  detailedClientNames: [],
  detailedFacilityNames: [],
  csvData: [], // for 'reports' page
  usersCsvData: [[]], // for 'users' page
  showDetailModal: false,
  isPublicMode: false,
  isPublicModeLoggedIn: false,
  urlParameters: {},
  paymentChannels: [],
  searchUserForm: blankUserSearchFormValues,
  showLogin: false,
  pathName: "",
} as AdminContextState;

const adminSlice = createSlice({
  name: "adminUserContext",
  initialState,
  reducers: {
    setSelectedClient: (state, action: PayloadAction<number>) => {
      const client = state.userSearch.clientsFilter.find(
        (client: Client) => client.clientId === action.payload
      );

      state.selectedClient = client ? client : emptyUserInfoClient;
    },
    clearAddEditUser: (state) => {
      state.selectedUser = getDefaultUserInfo();
      state.fullUser = getDefaultUser();
    },
    clearStatus: (state) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.modelErrorMessage = "";
    },
    setUsersFilter: (state, action: PayloadAction<FilterValues>) => {
      state.currentFilterUserValues = {
        ...state.currentFilterUserValues,
        ...action.payload,
      } as FilterValues;
    },
    setLogsFilter: (state, action: PayloadAction<FilterValues>) => {
      state.currentFilterLogsValues = {
        ...state.currentFilterLogsValues,
        ...action.payload,
      } as FilterValues;
    },
    resetUsersFilter: (state) => {
      state.currentFilterUserValues = {};
      state.selectedClient = emptyUserInfoClient;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => { // may not be needed. (unused currently). re-evaluate later
      state.isLoading = action.payload
    },
    resetAdminUserContext() {
      return initialState;
    },
    setClientAndFacilityData: (state, action) => {
      state.clientAndFacilityData = action.payload
    },
    setReportFilters: (
      state,
      action: PayloadAction<{
        reportType: string;
        startDate: string;
        endDate: string;
        admissionDate: string;
        dischargeDate: string;
        patientName: string;
        clientId: number[];
        facilityId: number[];
        includeFacilityTotals: boolean;
      }>
    ) => {
      state.reportFilters.reportType = action.payload.reportType;
      state.reportFilters.startDate = action.payload.startDate;
      state.reportFilters.endDate = action.payload.endDate;
      state.reportFilters.admissionDate = action.payload.admissionDate;
      state.reportFilters.dischargeDate = action.payload.dischargeDate;
      state.reportFilters.patientName = action.payload.patientName;
      state.reportFilters.clientId = action.payload.clientId;
      state.reportFilters.facilityId = action.payload.facilityId;
      state.reportFilters.includeFacilityTotals = action.payload.includeFacilityTotals;
    },
    setCsvData: (state, action) => {
      state.csvData = action.payload // csv data for 'reports' page
    },
    setUsersCsvData: (state, action) => {
      state.usersCsvData = action.payload
    },
    clearReportInfo: (state) => {
      state.filteredReport = emptyReportInfo
      state.reportFilters = emptyReportFilter
    },
    setPatientAndAccountHoldersNames: (state, action: PayloadAction<PatientsAndAccountHolders>) => {
      state.patientsNames = action.payload.patientsNames;
      state.accountHoldersNames = action.payload.accountHoldersNames
    },
    setClientBillingIds: (state, action: PayloadAction<ClientBillingIds>) => {
      state.collabIds = action.payload.collabIds;
    },
    setDetailedPatientAndAccountHoldersNames: (state, action: PayloadAction<PatientsAndAccountHolders>) => {
      state.detailedPatientsNames = action.payload.patientsNames;
      state.detailedAccountHoldersNames = action.payload.accountHoldersNames
    },
    setClientAndFacilityNames: (state, action: PayloadAction<ClientsAndFacilities>) =>{
      state.clientNames = action.payload.clientNames;
      state.facilityNames = action.payload.facilityNames;
    },
    setDetailedClientAndFacilityNames: (state, action: PayloadAction<ClientsAndFacilities>) =>{
      state.detailedClientNames = action.payload.clientNames;
      state.detailedFacilityNames = action.payload.facilityNames;
    },
    resetShowDetailModal: (state) => {
      state.showDetailModal = false
    },
    setIsPublicMode: (state, action) => {
      state.isPublicMode = action.payload;
    },
    setIsPublicModeLoggedIn: (state, action) => {
      state.isPublicModeLoggedIn = action.payload;
    },
    setUrlParameters: (state, action) => {
      state.urlParameters = action.payload;
    },
    clearAdminUserState: (state) => {
      state.userSearch = initialState.userSearch;
      state.selectedUser = initialState.selectedUser;
      state.fullUser = initialState.fullUser;
      state.filteredUsers = initialState.filteredUsers;
    },
    setShowLogin: (state, action)=>{
      state.showLogin = action.payload
    },
    setPathName: (state, action)=>{
      state.pathName = action.payload
    }
  },
  extraReducers: (builder) => {

    builder.addCase(handleGetLogs.fulfilled, (state, action) => {
      state.logsSearch.allLogs = action.payload;
      state.filteredLogs = action.payload
    });

    builder.addCase(handleGetReports.fulfilled, (state, action) => {
      state.allReports = action.payload;
    });

    builder.addCase(handleGetFilteredReports.fulfilled, (state, action) => {
      state.filteredReport = action.payload.reportFiltered
      state.reportTypeValues = action.payload.reportTypeValues
      state.isReportsLoading = false
    });

    builder.addCase(handleGetFilteredReports.pending, (state, action) => {
      state.isReportsLoading = true
    });

    builder.addCase(handleGetFilteredReports.rejected, (state, action) => {
      state.isReportsLoading = false
    });

    builder.addCase(handleGetDetailedFilteredReports.fulfilled, (state, action) => {
      state.filteredReport = action.payload
      state.isReportsLoading = false
    });

    builder.addCase(handleGetDetailedSettlementReport.pending, (state, action) => {
      state.isSettlementDetailedLoading = true
    });

    builder.addCase(handleGetDetailedSettlementReport.rejected, (state, action) => {
      state.isSettlementDetailedLoading = false
    });

    builder.addCase(handleGetDetailedSettlementReport.fulfilled, (state, action) => {
      state.filteredDetailedReport = action.payload
      if(action.payload.data.length > 0) state.showDetailModal = true
      state.isSettlementDetailedLoading = false
    });

    builder.addCase(handleGetDetailedFilteredReports.pending, (state, action) => {
      state.isReportsLoading = true
    });

    builder.addCase(handleGetDetailedFilteredReports.rejected, (state, action) => {
      state.isReportsLoading = false
    });

    builder.addCase(handleGetClients.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clients = action.payload;
    });

    builder.addCase(filterUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filteredUsers = action.payload;
    });

    builder.addCase(filterLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.filteredLogs = action.payload;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedUser = action.payload.userInfo;
      state.fullUser = action.payload.fullUser;
    });

    builder.addCase(saveUser.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    });

    builder.addCase(saveUser.fulfilled, (state, action) => {
      state.userSearch.allUsers = action.payload;
      state.modalSaveStatus = saveCallbackStatus.success;
    });

    builder.addCase(saveUser.rejected, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.modelErrorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(deActivateUser.fulfilled, (state, action) => {
      state.userSearch.allUsers = action.payload.allUsers;
      state.filteredUsers = action.payload.filteredUsers;
    });

    builder.addCase(reActivateUser.fulfilled, (state, action) => {
      state.userSearch.allUsers = action.payload.allUsers;
      state.filteredUsers = action.payload.filteredUsers;
    });

    builder.addCase(getUserCognitoStatus.pending, (state, action) => {
      state.isLoadingCognito = true;
    });

    builder.addCase(getUserCognitoStatus.fulfilled, (state, action) => {
      state.isLoadingCognito = false;
      state.selectedUser.cognitoStatus = {
        ...state.selectedUser.cognitoStatus,
        ...action.payload
      };
    });

    builder.addCase(getUserCognitoStatus.rejected, (state, action) => {
      state.isLoadingCognito = false;
    });

    builder.addCase(getPaymentChannels.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getPaymentChannels.fulfilled, (state, action) => {
      state.paymentChannels = action.payload;
      state.isLoading = false;
    });

  },
});

export const {
  setSelectedClient,
  clearAddEditUser,
  clearStatus,
  setUsersFilter,
  setLogsFilter,
  resetUsersFilter,
  resetAdminUserContext,
  setClientAndFacilityData,
  setReportFilters,
  setCsvData,
  clearReportInfo,
  setPatientAndAccountHoldersNames,
  setClientBillingIds,
  setClientAndFacilityNames,
  setDetailedPatientAndAccountHoldersNames,
  setDetailedClientAndFacilityNames,
  setUsersCsvData,
  resetShowDetailModal,
  setIsPublicMode,
  setIsPublicModeLoggedIn,
  setUrlParameters,
  clearAdminUserState,
  setShowLogin,
  setPathName
} = adminSlice.actions;
export default adminSlice.reducer;
