import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch } from "react-redux";
import React, {useRef} from "react";
import { ImplementationFacility } from "../models/implementation-facility";
import { getFacilityRules } from "../../../../state/rules-engine/rules-engine-thunk";
import { Form, Formik } from "formik";
import { TextField, Button } from "@finpay-development/shared-components";
import * as Yup from "yup";
import { number } from 'yup';
import { saveClientFacilityVob } from "../../../../state/facility/implementation-facility-thunk";
import { AppDispatch } from "../../../../../shared/state/store";

interface vobRulesAccordionProps {
  facility: ImplementationFacility;
}

const VobRulesAccordion = (props: vobRulesAccordionProps) => {
  const { facility } = props;

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const handleVobRulesFacilityAccordionClick = () => {
    if (facility.rulesGrouped === undefined) {
      dispatch(getFacilityRules({facility: facility}));
    }
  }

  function handleSave() {
    let facilityToSave: ImplementationFacility = {
      ...formRef.current.values,
    };
    if (facilityToSave.customConfigs.length > 0) {
      if (facilityToSave.customConfigs[0].configKey === undefined) {
        facilityToSave.customConfigs = [];
      }
    }

    dispatch(
        saveClientFacilityVob({ clientId: facility.clientId, facilityToSave })
    );
  }

  const validationSchema = Yup.object({
    vboPfrThreshold: number().required().positive().integer(),
    vboTimeThreshold: number().required().positive().integer(),
  });

  const initialValues = {
    ...facility
  };

  return <>
    <Accordion key={facility.facilityId} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="facility-details" onClick={() => handleVobRulesFacilityAccordionClick()}>
        <Typography variant="body1">{facility.facilityName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={5} justifyContent="space-between">
          <Grid item xs={12}>
              <div>
                  <Box mt={2}>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        innerRef={formRef}
                        validationSchema={validationSchema}
                        onSubmit={() => {}}
                    >
                      {(formik) => (
                          <Form autoComplete="off">
                            <Grid container spacing={2}>
                              <Grid xs={9} item>
                              <Typography className="mt-6" variant="subtitle2">
                                Rerun VOB PFR Threshold
                              </Typography>
                              </Grid>
                              <Grid xs={3} item>
                                <TextField
                                    error={
                                      formik.touched["vboPfrThreshold"] &&
                                      formik.errors["vboPfrThreshold"]
                                          ? formik.errors["vboPfrThreshold"]
                                          : ""
                                    }
                                    name="vboPfrThreshold"
                                    value={formik.values.vboPfrThreshold}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label="%"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid xs={9} item>
                                <Typography className="mt-6" variant="subtitle2">
                                Rerun VOB Time Threshold
                              </Typography>
                              </Grid>
                              <Grid xs={3} item>
                                <TextField
                                    error={
                                      formik.touched["vboTimeThreshold"] &&
                                      formik.errors["vboTimeThreshold"]
                                          ? formik.errors["vboTimeThreshold"]
                                          : ""
                                    }
                                    name="vboTimeThreshold"
                                    value={formik.values.vboTimeThreshold}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    label="Days"
                                />
                              </Grid>
                              <Button disabled={!formik.isValid} onClick={handleSave}>Save</Button>
                            </Grid>
                          </Form>
                      )}
                    </Formik>
                  </Box>
              </div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  </>;
}

export default VobRulesAccordion
