


export const OAuthGrantType = [
    {
      oauthGrantTypeId: 1,
      oauthGranTypeName: "Authorization",
    },
    {
      oauthGrantTypeId: 2,
      oauthGranTypeName: "Implicit",
    },
    {
      oauthGrantTypeId: 3,
      oauthGranTypeName: "Resource Owner Credentials",
    },
    {
        oauthGrantTypeId: 4,
        oauthGranTypeName: "Client Credentials",
    },
    {
        oauthGrantTypeId: 5,
        oauthGranTypeName: "Refresh Token",
      },
  ];