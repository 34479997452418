
export type TimingRiskMetaData = {
  timingRiskId: number
  timingRiskName: string,
  shortName: string
}

export type PaymentChannelMetaData = {
  paymentChannelId: number
  paymentChannelName: string,
  shortName: string
}


export class TimingRisk {
  timingRiskId!: number;
  timingRiskName!: string;
  shortName!: string
}

export type CRMObjectTypeMetaData = {
  crmObjectTypeId: number;
  crmTypeId: number;
  crmObjectTypeSlug: string;
  crmObjectApiName: string;
  crmObjectName: string;
}

export type CRMTypeMetaData = {
  crmTypeId: number;
  crmTypeSlug: string;
  crmName: string;
}

export type RuleParamsMetadata = {
  ruleParamId: number,
  paramName: string,
  paramPropertyName: string,
  dataType: string,
  comparators: string,
  isList: boolean,
  sortOrder: number,
}

export type Alert = {
  client_alert_id: number;
  alert_ref_id: string;
  alert_type_id: number;
  note_title: string | null;
  note_body: string;
  client_employer_id: number | null;
  employer_name: string | null;
  group_code: string | null;
  payor_id: number | null;
  payor_name: string | null;
  client_prefix_id: number | null;
  prefix: string | null;
}

export type Employer = {
  clientEmployerId: number;
  fpClientId: number;
  fpFacilityId: number | null;
  employerName: string;
}

export type Alerts = {
  [key: number] : {
    payer: Alert[];
    prefix: Alert[];
    group: Alert[];
    employer: Alert[]
  }
}

export type Employers = {
  [key: number] : Employer[]
}

export const emptyAlerts = {
  0: {
    payer: [],
    prefix: [],
    group: [],
    employer: []
  }
}

export const emptyEmployers = {
  0: []
}