import { StripeMetadata } from "./stripe-metadata";

export interface StripePaymentMethod {
  brand: string;
  country: string;
  customer: string;
  funding: string;
  id: string; // stripe card or bank id
  last4: string;
  name: string;
  object: string; // 'card' or 'bank_account'
  status?: string;
  metadata: StripeMetadata;
  isDefaultSource?: Boolean;
}

export const emptyStripePaymentMethod = {
  brand: '',
  country: '',
  customer: '',
  funding: '',
  id: '',
  last4: '',
  name: '',
  object: '',
  status: '',
  metadata: {},
}