import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { useDispatch, useSelector } from 'react-redux';
import { getClientDeficiencyNotification } from "../../../../state/clients/implementation-clients-thunk";
import { ClientDeficiencyNotificationModal } from "./deficiency-notification-modal";
import { LoadingOverlay } from "@finpay-development/shared-components";


function MinRevRequirements() {

    const [showEditDeficiencyNotificationModal, setShowEditDeficiencyNotificationModal] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const selectors = {
        currentClient: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient?.currentClientEntityDetails
        ),
        currentClientDeficiencyNotification: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient.currentClientDeficiencyNotification
        ),
        isLoadingDeficiencyNotification: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient.isLoadingDeficiencyNotification
        ),
    };

    const {
        currentClient,
        currentClientDeficiencyNotification,
        isLoadingDeficiencyNotification
    } = selectors;

    useEffect(() => {
        if (currentClient.clientId > 0) {
            dispatch(getClientDeficiencyNotification(currentClient.clientId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function handleEditDeficiencyNotificationClose() {
        setShowEditDeficiencyNotificationModal(false);
    }

    return (
        <Paper>
            {isLoadingDeficiencyNotification ? (
                <LoadingOverlay />
            ) : (
            <Box
                style={{
                    margin: "0.5em",
                    padding: "1.0em",
                    borderBottom: "1px solid #cccccc",
                }}
            >
                <Grid container justifyContent="space-between">
                    <Grid>
                        <Box style={{ padding: "1.5em" }}>
                            <Typography variant="subtitle2">Net Patient Revenue Deficiency Settings</Typography>
                        </Box>
                    </Grid>
                    <Grid container direction="column">
                        <Box style={{
                            margin: "0.5em",
                            paddingLeft: "1.0em",
                            // borderBottom: "1px solid #cccccc",
                        }}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item xs={11}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Is Estimated Insurance Value?</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle2" className="mb-6 breaklongstring">
                                                {currentClientDeficiencyNotification?.isEiv === true ? 'Yes' : 'No'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => setShowEditDeficiencyNotificationModal(true)}
                                        className="edit-button"
                                        size="large"> <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">Added to Downpayment?</Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="subtitle2" className="mb-6 breaklongstring">
                                    {currentClientDeficiencyNotification?.addedToDownpayment === true ? 'Yes' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">Downpayment Amount</Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="subtitle2" className="mb-6 breaklongstring">
                                    {currentClientDeficiencyNotification?.downpaymentPct}&nbsp;&nbsp;&nbsp;%
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                {showEditDeficiencyNotificationModal && (
                    <ClientDeficiencyNotificationModal
                        open={showEditDeficiencyNotificationModal}
                        handleEditDeficiencyNotifCancel={handleEditDeficiencyNotificationClose}
                        handleEditDeficiencyNotifSubmit={handleEditDeficiencyNotificationClose}
                    />
                )}

            </Box>)}
        </Paper>
    );
}

export default MinRevRequirements;

