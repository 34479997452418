export interface ErrorState {
    getConfig: boolean;
    getAuthToken: boolean;
    getPaymentAuthToken: boolean;
    getEmailLookupAuthToken: boolean;
    checkEmailProcessing: boolean;
    paymentProcessing: boolean;
    launchPayloadProcessing: boolean;
    getPaymentChannels: boolean;
    getClientFacility: boolean;
    getClientFacilities: boolean;
  }
  
  export const emptyErrorState: ErrorState = {
    getConfig: false,
    getAuthToken: false,
    getPaymentAuthToken: false,
    getEmailLookupAuthToken: false,
    checkEmailProcessing: false,
    paymentProcessing: false,
    launchPayloadProcessing: false,
    getPaymentChannels: false,
    getClientFacility: false,
    getClientFacilities: false
  }