import './../../scss/components/_comment.scss';

import { DeleteModal } from '@finpay-development/shared-components';
import DeleteIcon from '@mui/icons-material/Delete';
import { ButtonBase, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteClientComment } from '../../implementation-specialist/state/clients/implementation-clients-thunk';
import { Comment } from '../../patient/components/models/comment';
import { PatientNotes } from '../../patient/components/models/patient-note';
import { deletePatientNote, dismissReminder } from '../../patient/state/patient-thunk';
import AccessControl from '../../security/components/access-control';
import { RolePageNames } from '../../security/model/role-page-names';
import { commentsPage } from '../enums';
import { RootState } from '../state/root-reducer';
import { AppDispatch } from '../state/store';
import { Utils } from '../utils';


interface commentProps {
  page:string,
  comment: PatientNotes | Comment |any,
  commentId: number
}

function FppComment(props:commentProps) {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReminder, setIsReminder] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [commentValues, setCommentValues] = useState({
    formattedDate: "",
    commentText: "",
    formattedReminderDate: "",
    userName:"",
    completedBy: "",
    completionAtDt: "",
  })
  const {page,comment, commentId} = props
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    let date;
    let formattedDate = "";
    let commentText = "";
    let formattedReminderDate = "";
    let formattedDoneDate = "";
    let userName=`${comment.userFirstName} ${comment.userLastName}`

    switch (page) {
      case commentsPage.patients:
        date = new Date(comment.noteDt.toString())
        formattedDate = Utils.convertISODate(date, true)
        commentText = comment.noteText
        if(comment.isReminder){
          const reminderDate = new Date(comment?.completionDueDt?.toString())
          formattedReminderDate = Utils.convertISODate(reminderDate, true)
          setIsReminder(true)
        }
        if (comment.isCompleted && comment.completionAtDt) {
          const completeDate = new Date(comment.completionAtDt.toString())
          formattedDoneDate = Utils.convertISODate(completeDate, true)
          setIsCompleted(true)
          setIsReminder(false)
        }
        setCommentValues({
          formattedDate:formattedDate,
          commentText:commentText,
          formattedReminderDate:formattedReminderDate,
          userName,
          completedBy:comment.completedByUserId,
          completionAtDt:formattedDoneDate,
        })
      break;

      case commentsPage.clients:
        date = new Date(comment.commentDt.toString())
        formattedDate = Utils.convertISODate(date, true)
        commentText = comment.commentText

        setCommentValues({
          formattedDate: formattedDate,
          commentText: commentText,
          formattedReminderDate: "",
          userName,
          completedBy: "",
          completionAtDt: "",
        })
      break;

      default:
        break;
    }
    // eslint-disable-next-line
  }, [])


  const selectedEncounter = useSelector(
    (state: RootState) =>
      state.patientContext.selectedEncounter
  );

  const client = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.client
  );

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  function handleDeleteOk() {

    switch (page) {
      case commentsPage.patients:
        dispatch(deletePatientNote({commentId}))
      break;
      case commentsPage.clients:
        dispatch(deleteClientComment({
          clientId: client.clientId,
          commentId: commentId}))
      break;
      default:
        break;
    }

    setIsDeleteModalOpen(false);
  }

  const handleDelete = () => {
    setIsDeleteModalOpen(true)
  }

  const dismissReminderUI = (commentId:number)=> {
    dispatch(dismissReminder({
      patientId: selectedEncounter.patientId,
      encounterId: selectedEncounter.patientEncounterId,
      commentId: commentId}))
  }

  return (
    <div className="comment mb-4">
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={isReminder ? 8: 11}>
          <ButtonBase
            children={
              <Typography variant="body2"  >
                    {
                      isReminder
                        ?
                        commentValues?.formattedReminderDate
                        :
                        commentValues?.formattedDate
                    }
              </Typography>
            }
            />
          </Grid>

            {isReminder && (
              <Grid item xs={3}>
                <AccessControl rolePageName={page === commentsPage.patients ? RolePageNames.PatientRecords : RolePageNames.Clients} actionName="Add Notes"
                  renderNoAccess={() => <ButtonBase disabled>Dismiss</ButtonBase>}>
                  <ButtonBase onClick={() => dismissReminderUI(commentId)}
                    children={
                      <Typography variant="body2" color="error" >Dismiss</Typography>
                    }
                  />
                </AccessControl>
              </Grid>
            )}

            <Grid item xs={1}>
              <AccessControl rolePageName={page === commentsPage.patients ? RolePageNames.PatientRecords : RolePageNames.Clients} actionName="Add Notes"
                renderNoAccess={() => <ButtonBase disabled>{<DeleteIcon color="secondary" fontSize="small"></DeleteIcon>}</ButtonBase>}>
                <ButtonBase onClick={() => handleDelete()}
                  children={
                    <DeleteIcon color="primary" fontSize="small"></DeleteIcon>
                  }
                />
              </AccessControl>
            </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="word-wrap">
        <Typography variant="body2" paragraph>
         {commentValues.commentText}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" align="right">
          {commentValues.userName}
        </Typography>
        { isCompleted &&
            <Typography variant="body2" align="right">
              <strong>Completed By:</strong> {commentValues.completedBy}<br/>
              <strong>Completed At:</strong> {commentValues.completionAtDt}
            </Typography>
        }
      </Grid>
    </Grid>

    {isDeleteModalOpen && (
        <DeleteModal
          open={isDeleteModalOpen}
          title="Delete Comment"
          subTitle="This action cannot be undone"
          okButtonText="Delete Forever"
          handleDeleteCancel={handleDeleteCancel}
          handleDeleteOk={handleDeleteOk}
        />
      )}
  </div>
  );
}

export default FppComment;
