import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {patientService} from '../../../patient/services/patient-service';
import {useSelector} from 'react-redux';
import {RootState} from '../../../shared/state/root-reducer';
import {LoginVerifiedStatus} from '../../../shared/enums';
import {Paper, Typography} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import '../../../scss/pages/account-holder/_email-verification-view.scss'
import {AxiosResultStatus} from '../../../shared/service/axios-result-status';
import {LoadingOverlay} from '@finpay-development/shared-components';

const AccountHolderEmailVerification = () => {

  const {encounterId} = useParams()
  const [isEmailVerified, setIsEmailVerified] = useState<boolean | null>(null);
  /**
   * The component mounts and remounts on the app level, which causes the below
   * useEffect and api calls to be called twice. In order to prevent this,
   * we keep track of the number of mounts, and call the verifyEmail function
   * only once on the very first mount.
   * */
  const isFirstMount = useRef(true);

  const accountHolderEmail = useSelector((state: RootState) => state.userContext.userProfile.userName);
  const userId = useSelector((state: RootState) => state.userContext.userProfile.userId);


  useEffect(()=>{

    const controller = new AbortController();
    const signal = controller.signal;

    const verifyEmail = async(patientEncounterId: number) => {

      const patientEncounterResponse = await patientService.getPatientInstanceOfCare({
        patientId: 0,
        encounterId: patientEncounterId
      }, signal);
      if(patientEncounterResponse.hasErrors) throw new Error(`Error while fetching instance of care : ${patientEncounterResponse.errorMessage}`);

      const thirdPartyGuarantorArr = patientEncounterResponse.entity.patientChampion.filter((patientChampion: any)=>{
        return patientChampion.isGuarantor === true
      })

      const promiseArr = [];

      switch(thirdPartyGuarantorArr.length){

        case 0 :
          /**
           * Account holder is the patient.
           * Update patient table userId column.
           * */
          promiseArr.push(patientService.updatePatient({
            userId,
            /**
             * We are adding the email because the node patient put end point
             * does not allow payloads without the contact object.
             */
            contact: {
              email: accountHolderEmail.toLowerCase()
            }
          },
              patientEncounterResponse.entity.patientId,
              signal
              ));

          break;

        case 1 :
          /**
           * Account holder is a patient champion.
           * Update patient_champion table userId column.
           * */

          if(thirdPartyGuarantorArr[0].contact.email.toLowerCase() !== accountHolderEmail.toLowerCase()) {
            throw new Error("Guarantor email does not match with email on file");
          }

          promiseArr.push(patientService.updatePatientChampion({
            userId
          },
              thirdPartyGuarantorArr[0].patientChampionId,
              signal
              ));

          break;

        default :
          //it there is more than one guarantor, something isn't right..
          throw new Error("Multiple third party guarantors on file. There should only be one third party guarantor");
      }

      const promiseResponse = await Promise.all([
        patientService.saveIOCWithPartialObject({
          loginVerified: LoginVerifiedStatus.verified,
        }, patientEncounterId),
        ...promiseArr
      ])

      promiseResponse.forEach((resolvedPromise:Awaited<AxiosResultStatus>)=>{
        if(resolvedPromise.hasErrors){
          throw new Error(resolvedPromise.entity)
        }
      })

      setIsEmailVerified(true);
  }

  if(isFirstMount.current){
    verifyEmail(+encounterId!).catch((error)=>{
      console.log(error.message);
      setIsEmailVerified(false);
    })
  }

  return () => {
    /**
     * Setting the isFirstMount to false after the component unmounts
     * prevents the verifyEmail from being called the second time
     * on the second mount, while still allowing the verifyEmail to be
     * called when the dependency updates during the first mount.
     * */
    isFirstMount.current = false;
    controller.abort();
  }

  }, [encounterId, userId, accountHolderEmail])

  const verificationStatus = (verificationStatus: boolean | null) => {

    switch(verificationStatus){
      case true:
        return (
            <>
              <CheckCircleIcon
                  sx={{
                    color: 'green'
                  }}
                  className='icon-size'
              />
              <Typography variant="h1" className='font-color'>
                Email verified!
              </Typography>
              <Typography variant='body1' className='font-color'>
                Thank you for verifying your email.
                <br/>
                You may either log out or continue onto the patient dashboard.
              </Typography>
            </>
        )
      case false:
        return (
            <>
              <CancelIcon
                  sx={{
                    color: 'red'
                  }}
                  className='icon-size'
              />
              <Typography variant="h1" className='font-color'>
                Email was not verified.
              </Typography>
              <Typography variant='body1' className='font-color'>
                An error happened while verifying your email.
                <br/>
                Please contact a FinPay Patient Engagement Specialist to resolve this issue.
              </Typography>
            </>
        )
      default:
        return (
            <LoadingOverlay whiteBackground={true}/>
        )
    }
  }

  return(
            <main>
              <Paper
                  className="account-holder-email-verification"
                  sx={{
                    width: '800px',
                    height: '500px',
                    margin: '100px'
                  }}
              >
                {verificationStatus(isEmailVerified)}
              </Paper>
            </main>
  )
}

export default AccountHolderEmailVerification;
