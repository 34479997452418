import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Comment } from '../../../patient/components/models/comment';
import { blankClient, blankClientBusinessRules, client, ClientBusinessRules } from '../../../shared/model/client';
import { ClientFacility, emptyClientFacility } from '../../../shared/model/client-facility';
import { ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import { ClientRiskAssessmentFields } from 'src/shared/model/client-risk-assessment-config';
import { getClientRiskAssessmentConfigs, createClientRiskAssessmentConfig, updateClientRiskAssessmentConfig, deleteClientRiskAssessmentConfig } from './implementation-clients-thunk';
import { UNKNOWN_SERVER_ERROR } from '../../../shared/state/saving-status';
import { emptyFacilitiesInfo, FacilitiesInfo } from '../../components/implementation-clients/details/models/implementation-facility';
import { emptyPayersInfo, PayersInfo } from '../../components/implementation-clients/details/models/payer-plan-info';
import { emptyTabComplete, TabComplete } from '../../components/implementation-clients/details/models/tabCompleteModel';
import {
  blankClientContactForm,
  blankClientEntityForm,
  blankClientForm,
  ClientContactForm,
  ClientEntityForm,
  ClientForm,
  emptyClientDeficiencyNotification,
  ClientDeficiencyNotification
} from '../../models/client';
import { ClientFilter, emptyClientFilter } from '../../models/client-filter';
import { ClientKPI, emptyClientKPI, FacilityKPI } from '../../models/kpi';
import { specialistClientService } from '../../services/implementation-client-service';
import { ClientReferralSource, FacilityLOCReferralSourceForm, SelectedFacilityReferralSource } from '../../models/referral-source';

import {
  addClient,
  createClientComment,
  deleteClientComment,
  generateClientLOCSpreadsheet,
  generateClientPayorSpreadsheet,
  getClient,
  getClientComments,
  getClientKPIs,
  getClients,
  notifyClient,
  resendInvitation,
  saveClientBusinessRules,
  saveClientContact,
  saveClientEntity,
  saveFacilityRiskSetting,
  setRecentlyViewedClient,
  getClientReferralSources,
  getFacilityReferralSources,
  getFacilityLOCReferralSources,
  saveClientReferralSource,
  addFacilityReferralSource,
  saveFacilityReferralSource,
  saveFacilityLOCReferralSource,
  deleteReferralSource,
  addFacilityClientReferralSource,
  getClientDeficiencyNotification,
  saveClientDeficiencyNotification,
  savePayerClaimRate,
  activateClient,
  getClientPaymentTransactionFee,
  saveClientPaymentTransactionFee,
} from './implementation-clients-thunk';
import {
  ClientPaymentTransactionFee, intialClientPaymentTransactionFee,
} from '../../models/payment-transaction-fee';
import { ClientRiskClassSettings } from 'src/admissions-advisor/models/risk-assessment';

interface InitialState {
  selectedFacility: ClientFacility,
  allClients: ClientStatusCardViewModel[],
  allClientsMap: Map<number, ClientStatusCardViewModel>
  allClientsWithFacillities?: ClientStatusCardViewModel[],
  allClientsWithFacilitiesMap?: Map<number, ClientStatusCardViewModel>
  // client filtering
  filterFormValues: ClientFilter,
  filteredByIsActive: ClientStatusCardViewModel[],
  filteredByClosed: ClientStatusCardViewModel[],
  filteredByStripeSetupIncomplete: ClientStatusCardViewModel[],
  filteredByReadyForActivation: ClientStatusCardViewModel[],
  filteredBySetUpIncomplete: ClientStatusCardViewModel[],
  filteredByStripeActionNeeded: ClientStatusCardViewModel[],
  currentClientEntityDetails: ClientForm,
  currentClientBusinessRules: ClientBusinessRules,
  currentClient: ClientForm, // TODO(JB): think of renaming this type to not include word 'form'
  client: client; // full client object from server
  isActivating: boolean;

  addClientForm: ClientForm;
  editClientEntityForm: ClientEntityForm;
  editClientContactForm: ClientContactForm;

  isTabComplete: TabComplete;

  // common state for most/all slices
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
  isLoadingComments: boolean;
  isLoadingClientKPIs: boolean;
  recentViewedClients:Array<number>;
  comments: Comment[];
  clientKPI: ClientKPI;
  facilityKPIs: FacilityKPI[];
  AAselectedFacilities: FacilitiesInfo;
  AAselectedPayers: PayersInfo;
  isLoadingLOCSpreadsheet: boolean;
  isLoadingPayorSpreadsheet: boolean;
  isLoadingRiskSetting: boolean,
  isLoadingPayerClaimRate: boolean,
  modalSaveStatus: saveCallbackStatus;
  clientReferralSources: ClientReferralSource[];
  facilityReferralSources: SelectedFacilityReferralSource[];
  isLoadingFacilityReferralSources: boolean,
  facilityReferralSourcesStatus: saveCallbackStatus,
  facilityLOCReferralSources: FacilityLOCReferralSourceForm[];
  isLoadingReferralSources: boolean,
  currentClientDeficiencyNotification: ClientDeficiencyNotification,
  isLoadingDeficiencyNotification: boolean,
  clientDeficiencyNotificationStatus: saveCallbackStatus,
  isLoadingClientRiskAssessmentConfigs: boolean,
  clientRiskAssessmentConfigs: ClientRiskAssessmentFields[],
  clientRiskAssessmentConfigLoadingStatus: saveCallbackStatus,
  deleteClientRiskAssessmentConfigStatus: saveCallbackStatus

  clientPaymentTransactionFee: {
    fees: ClientPaymentTransactionFee,
    clientId: number
  }
}

const initialState: InitialState = {
  selectedFacility: emptyClientFacility,
  allClients: [],
  allClientsMap: new Map(),
  allClientsWithFacillities: [],
  allClientsWithFacilitiesMap: new Map(),
  filteredByIsActive: [],
  filteredByClosed: [],
  filteredByStripeSetupIncomplete: [],
  filteredByReadyForActivation: [],
  filteredBySetUpIncomplete: [],
  filteredByStripeActionNeeded: [],
  currentClientEntityDetails: blankClientForm,
  currentClientBusinessRules: blankClientBusinessRules,
  client: blankClient,
  isActivating: false,
  currentClient: blankClientForm,
  filterFormValues: emptyClientFilter(),

  isTabComplete: emptyTabComplete,

  apiStatus: saveCallbackStatus.none,
  errorMessage: "",
  isLoading: false,
  isLoadingComments: false,
  isLoadingClientKPIs: false,
  addClientForm: blankClientForm,
  editClientEntityForm: blankClientEntityForm,
  editClientContactForm: blankClientContactForm,
  recentViewedClients:[],
  comments:[],
  clientKPI: emptyClientKPI,
  facilityKPIs: [],
  AAselectedFacilities: emptyFacilitiesInfo,
  AAselectedPayers: emptyPayersInfo,
  isLoadingLOCSpreadsheet: false,
  isLoadingPayorSpreadsheet: false,
  isLoadingRiskSetting: false,
  isLoadingPayerClaimRate: false,
  modalSaveStatus: saveCallbackStatus.none,
  clientReferralSources: [],
  facilityReferralSources: [],
  isLoadingFacilityReferralSources: false,
  facilityReferralSourcesStatus: saveCallbackStatus.none,
  facilityLOCReferralSources: [],
  isLoadingReferralSources: false,
  currentClientDeficiencyNotification: emptyClientDeficiencyNotification,
  isLoadingDeficiencyNotification: false,
  clientDeficiencyNotificationStatus: saveCallbackStatus.none,
  isLoadingClientRiskAssessmentConfigs: false,
  clientRiskAssessmentConfigs: [],
  clientRiskAssessmentConfigLoadingStatus: saveCallbackStatus.none,
  deleteClientRiskAssessmentConfigStatus: saveCallbackStatus.none,
  clientPaymentTransactionFee: {
    fees: intialClientPaymentTransactionFee,
    clientId: 0
  }
};

const implementationSpecialistClientSlice = createSlice({
  name: "implementationSpecialistClientContext",
  initialState,
  reducers: {
    setFilterFormValues: (state, action: PayloadAction<ClientFilter>) => {
      state.filterFormValues = {
        ...state.filterFormValues,
        ...action.payload
      };

      // if any filter is checked other than 'all' then set filter 'all' to false (this unchecks 'all' in the UI)
      const isNotFilterAllChecked = specialistClientService.isNotFilterByAll(action.payload);
      if (isNotFilterAllChecked) {
        state.filterFormValues = {
          ...state.filterFormValues,
          ...action.payload,
          all: false
        }
      }
    },
    setAllCheckboxFilterChecked: (state) => {
      state.filterFormValues = {
        ...state.filterFormValues,
          recentlyViewed: false,
          all: !state.filterFormValues.all,
          active: false,
          closed: false,
          followUp: false,
          readyForActivation: false,
          setUpIncomplete: false,
          stripeSetupIncomplete: false,
      }
    },
    resetFilterFormValues: (state) => {
      state.filterFormValues = emptyClientFilter();
    },
    resetAddClientForm: (state) => {
      state.addClientForm = blankClientForm;
    },
    setCurrentClient: (state, action) => {
      state.currentClient = action.payload;
    },
    clearCurrentClient: (state) => {
      state.currentClientBusinessRules = blankClientBusinessRules;
      state.currentClientEntityDetails = blankClientForm;
      state.apiStatus = saveCallbackStatus.none;
      state.isTabComplete = emptyTabComplete;
    },
    clearClients: (state) => {
      state.allClients = [];
      state.filteredByClosed = [];
      state.filteredByStripeSetupIncomplete = [];
      state.filteredBySetUpIncomplete = [];
      state.filteredByReadyForActivation = [];
      state.filteredByStripeActionNeeded = [];
      state.isLoading = false;
    },
    clearStatus: (state) => {
      state.apiStatus = saveCallbackStatus.none;
      state.errorMessage = "";
    },
    setRecentlyViewedClients:(state, action) => {
      state.recentViewedClients = action.payload
    },
    setEntitySettingsTabComplete: (state, action: PayloadAction<boolean>) => {
      state.isTabComplete = {
        ...state.isTabComplete,
        entitySettings: action.payload
      }
    },
    setFacilityTabComplete: (state, action: PayloadAction<boolean>) => {
      state.isTabComplete = {
        ...state.isTabComplete,
        facilities: action.payload
      }
    },
    setPESScriptTabComplete: (state, action: PayloadAction<boolean>) => {
      state.isTabComplete = {
        ...state.isTabComplete,
        pesScript: action.payload
      }
    },
    setAuthDocsTabComplete: (state, action: PayloadAction<boolean>) => {
      state.isTabComplete = {
        ...state.isTabComplete,
        pesScript: action.payload
      }
    },
    setRulesEngineTabComplete: (state, action: PayloadAction<boolean>) => {
      state.isTabComplete = {
        ...state.isTabComplete,
        rulesEngine: action.payload
      }
    },
    clearComments: (state) => {
      state.comments = []
    },
    resetIsActivating: (state) => {
      state.isActivating = false;
    },
    resetImplementationClientContext() {
      return initialState;
    },
    setAdmissionsAdvisorSelectedFacilities: (state, action) => {
      state.AAselectedFacilities = action.payload
    },
    setAdmissionsAdvisorSelectedPayers: (state, action) => {
      state.AAselectedPayers = action.payload
    },
    clearRiskSettingEditStatus: (state) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    },
    clearClientDeficiencyNotificationStatus: (state) => {
      state.clientDeficiencyNotificationStatus = saveCallbackStatus.none;
    },
    clearFacilityReferralSourcesStatus: (state) => {
      state.facilityReferralSourcesStatus = initialState.facilityReferralSourcesStatus;
    },
    clearModalEditStatus: (state) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    },
    clearClientRiskAssessmentConfigs: (state) => {
      state.clientRiskAssessmentConfigs = []
      state.apiStatus = saveCallbackStatus.none;
      state.isLoadingClientRiskAssessmentConfigs = false;
      state.clientRiskAssessmentConfigLoadingStatus = saveCallbackStatus.none
      state.deleteClientRiskAssessmentConfigStatus = saveCallbackStatus.none
    }
  },
  extraReducers: (builder) => {

    // getClients
    builder.addCase(
      getClients.fulfilled, (state, action) => {

        const allClientsMap = action.payload.allClients.reduce((clientsMap: Map<number, ClientStatusCardViewModel>, currClient: ClientStatusCardViewModel)=>{
          clientsMap.set(currClient.clientId, currClient)
          return clientsMap
        }, new Map())

        if (action.meta.arg.includeFacility) {
          state.allClientsWithFacillities = action.payload.allClients;
          state.allClientsWithFacilitiesMap = allClientsMap
        }
        state.allClients = action.payload.allClients;
        state.allClientsMap = allClientsMap;
        state.filteredByIsActive = action.payload.isActive;
        state.filteredByClosed = action.payload.closed;
        state.filteredByStripeSetupIncomplete = action.payload.stripeSetupIncomplete;
        state.filteredBySetUpIncomplete = action.payload.setupIncomplete;
        state.filteredByReadyForActivation = action.payload.readyForActivation;
        state.filteredByStripeActionNeeded = action.payload.stripeActionNeeded;
        state.isLoading = false;
      }
    );
    builder.addCase(getClients.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.isLoading = false;
    });

    // getClient
    builder.addCase(
      getClient.fulfilled, (state, action) => {
        state.currentClientEntityDetails = action.payload.clientEntityDetails;
        state.currentClientBusinessRules = action.payload.clientBusinessRules;
        state.client = action.payload.fullClient;
        state.apiStatus = saveCallbackStatus.none;
        state.isLoading = false;
        state.AAselectedFacilities = emptyFacilitiesInfo;
        state.AAselectedPayers = emptyPayersInfo;
      }
    )
    builder.addCase(getClient.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClient.rejected, (state, action) => {
      state.apiStatus = saveCallbackStatus.error;
      state.isLoading = false;
    });
      // Error toast handled already in the thunk. May not need to do anything here
    builder.addCase(addClient.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });

    builder.addCase(addClient.fulfilled, (state, action) => {
      state.currentClientEntityDetails = action.payload.clientEntityDetails;
      state.currentClientBusinessRules = action.payload.clientBusinessRules;
      state.currentClient = action.payload.returnedClient;
      state.allClients = action.payload.allClients;
      state.filteredByIsActive = action.payload.isActive;
      state.filteredByClosed = action.payload.closed;
      state.filteredByStripeSetupIncomplete = action.payload.stripeSetupIncomplete;
      state.filteredBySetUpIncomplete = action.payload.setupIncomplete;
      state.filteredByReadyForActivation = action.payload.readyForActivation;
      state.filteredByStripeActionNeeded = action.payload.stripeActionNeeded;
      state.apiStatus = saveCallbackStatus.success;
    });

    builder.addCase(addClient.rejected, (state, action) => {
      state.apiStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveClientEntity.rejected, (state, action) => {
      state.apiStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveClientEntity.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });

    builder.addCase(saveClientEntity.fulfilled, (state, action) => {
      state.currentClientEntityDetails = action.payload.clientEntityDetails;
      state.client = action.payload.fullClient;
      state.apiStatus = saveCallbackStatus.success;
    });

    builder.addCase(saveClientContact.rejected, (state, action) => {
      state.apiStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveClientContact.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });

    builder.addCase(saveClientContact.fulfilled, (state, action) => {
      state.currentClientEntityDetails = action.payload.clientEntityDetails;
      state.client = action.payload.fullClient;
      state.apiStatus = saveCallbackStatus.success;
    });

    builder.addCase(saveClientBusinessRules.rejected, (state, action) => {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(saveClientBusinessRules.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });

    builder.addCase(saveClientBusinessRules.fulfilled, (state, action) => {
      state.currentClientBusinessRules = action.payload.clientBusinessRules;
      state.client = action.payload.fullClient;
      state.apiStatus = saveCallbackStatus.success;
    });

    builder.addCase(notifyClient.rejected,(state, action)=> {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    })

    builder.addCase(notifyClient.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(notifyClient.fulfilled, (state, action) => {
      state.client = action.payload.updatedClient;
      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(activateClient.rejected,(state, action)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.isActivating = true;
    })

    builder.addCase(activateClient.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
      state.isActivating = true;
    })

    builder.addCase(activateClient.fulfilled, (state, action) => {
      state.client = action.payload.updatedClient;
      state.apiStatus = saveCallbackStatus.success;
      state.isActivating = true;
    })

    builder.addCase(resendInvitation.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
    })

    builder.addCase(resendInvitation.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(resendInvitation.fulfilled, (state) => {
      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(createClientComment.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(createClientComment.fulfilled, (state,action) => {
      state.apiStatus = saveCallbackStatus.success;
      state.comments.push(action.payload)
    })

    builder.addCase(createClientComment.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;

    })

    builder.addCase(getClientComments.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
      state.isLoadingComments = true
    })

    builder.addCase(getClientComments.fulfilled, (state,action) => {
      state.comments = action.payload.comments
      state.isLoadingComments = false
    })

    builder.addCase(getClientComments.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.isLoadingComments = false
    })

    builder.addCase(deleteClientComment.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(deleteClientComment.fulfilled, (state,action) => {
      state.apiStatus = saveCallbackStatus.success;
      const clientCommentId = action.payload.clientCommentsId
      state.comments= state.comments.filter(function( comments ) {
        return comments.clientCommentsId !== clientCommentId;
      });

    })

    builder.addCase(deleteClientComment.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
    })

    builder.addCase(setRecentlyViewedClient.fulfilled,(state, action)=> {
      state.recentViewedClients = action.payload
    })
    builder.addCase(getClientKPIs.pending, (state, action) => {
      state.isLoadingClientKPIs = true;
    });
    builder.addCase(getClientKPIs.fulfilled, (state, action) => {
      state.isLoadingClientKPIs = false;
      state.clientKPI = action.payload;
    });
    builder.addCase(getClientKPIs.rejected, (state, action) => {
      state.isLoadingClientKPIs = false;
    });

    builder.addCase(generateClientLOCSpreadsheet.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
      state.isLoadingLOCSpreadsheet = true;
    })

    builder.addCase(generateClientLOCSpreadsheet.fulfilled, (state,action) => {
      state.apiStatus = saveCallbackStatus.success;
      state.isLoadingLOCSpreadsheet = false;
    })

    builder.addCase(generateClientLOCSpreadsheet.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.isLoadingLOCSpreadsheet = false;
    })

    builder.addCase(generateClientPayorSpreadsheet.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
      state.isLoadingPayorSpreadsheet = true;
    })

    builder.addCase(generateClientPayorSpreadsheet.fulfilled, (state,action) => {
      state.apiStatus = saveCallbackStatus.success;
      state.isLoadingPayorSpreadsheet = false;
    })

    builder.addCase(generateClientPayorSpreadsheet.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.isLoadingPayorSpreadsheet = false;
    })
    builder.addCase(saveFacilityRiskSetting.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingRiskSetting = true;
    })

    builder.addCase(saveFacilityRiskSetting.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingRiskSetting = false;
    })

    builder.addCase(saveFacilityRiskSetting.rejected,(state)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingRiskSetting = false;
    })
    builder.addCase(savePayerClaimRate.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingPayerClaimRate = true;
    })

    builder.addCase(savePayerClaimRate.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingPayerClaimRate = false;
    })

    builder.addCase(savePayerClaimRate.rejected,(state)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingPayerClaimRate = false;
    })

    builder.addCase(
      getClientReferralSources.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.clientReferralSources = action.payload;
        state.facilityReferralSources = [];
        state.facilityLOCReferralSources = [];
        state.isLoadingReferralSources = false;
      }
    );
    builder.addCase(getClientReferralSources.pending, (state, action) => {
      state.isLoadingReferralSources = true;
    });
    builder.addCase(getClientReferralSources.rejected, (state, action) => {
      state.isLoadingReferralSources = false;
    });

    builder.addCase(saveClientReferralSource.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingReferralSources = true;
    })
    builder.addCase(saveClientReferralSource.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingReferralSources = false;
    })
    builder.addCase(saveClientReferralSource.rejected,(state, action)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingReferralSources = false;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(deleteReferralSource.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })
    builder.addCase(deleteReferralSource.fulfilled, (state,action) => {
      state.apiStatus = saveCallbackStatus.success;
    })
    builder.addCase(deleteReferralSource.rejected,(state)=> {
      state.apiStatus = saveCallbackStatus.error;
    })

    builder.addCase(saveFacilityReferralSource.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingReferralSources = true;
    })
    builder.addCase(saveFacilityReferralSource.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingReferralSources = false;
    })
    builder.addCase(saveFacilityReferralSource.rejected,(state, action)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingReferralSources = false;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(addFacilityReferralSource.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingReferralSources = true;
    })
    builder.addCase(addFacilityReferralSource.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingReferralSources = false;
    })
    builder.addCase(addFacilityReferralSource.rejected,(state, action)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingReferralSources = false;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(addFacilityClientReferralSource.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingReferralSources = true;
    })
    builder.addCase(addFacilityClientReferralSource.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingReferralSources = false;
    })
    builder.addCase(addFacilityClientReferralSource.rejected,(state, action)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingReferralSources = false;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(getFacilityReferralSources.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoadingFacilityReferralSources = false;
			state.facilityReferralSourcesStatus = saveCallbackStatus.success;
			state.facilityReferralSources = action.payload;
    });
    builder.addCase(getFacilityReferralSources.pending, (state, action) => {
      state.isLoadingFacilityReferralSources = true;
      state.facilityReferralSourcesStatus = saveCallbackStatus.none;
    });
    builder.addCase(getFacilityReferralSources.rejected, (state, action) => {
      state.isLoadingFacilityReferralSources = false;
      state.facilityReferralSourcesStatus = saveCallbackStatus.error;
    });

    builder.addCase(
      getFacilityLOCReferralSources.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.facilityLOCReferralSources = action.payload.facilityLOCReferralSourceForms;
        state.isLoadingReferralSources = false;
      }
    );
    builder.addCase(getFacilityLOCReferralSources.pending, (state, action) => {
      state.isLoadingReferralSources = true;
    });
    builder.addCase(getFacilityLOCReferralSources.rejected, (state, action) => {
      state.isLoadingReferralSources = false;
    });

    builder.addCase(saveFacilityLOCReferralSource.pending, (state)=> {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isLoadingReferralSources = true;
    })
    builder.addCase(saveFacilityLOCReferralSource.fulfilled, (state,action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isLoadingReferralSources = false;
    })
    builder.addCase(saveFacilityLOCReferralSource.rejected,(state)=> {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isLoadingReferralSources = false;
    })

    builder.addCase(getClientDeficiencyNotification.fulfilled, (state, action: PayloadAction<any>) => {
      state.currentClientDeficiencyNotification = action.payload;
      state.clientDeficiencyNotificationStatus = saveCallbackStatus.success;
      state.isLoadingDeficiencyNotification = false;
    });
    builder.addCase(getClientDeficiencyNotification.pending, (state, action) => {
      state.isLoadingDeficiencyNotification = true;
      state.clientDeficiencyNotificationStatus = saveCallbackStatus.none;
    });
    builder.addCase(getClientDeficiencyNotification.rejected, (state, action) => {
      state.isLoadingDeficiencyNotification = false;
      state.clientDeficiencyNotificationStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveClientDeficiencyNotification.rejected, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(saveClientDeficiencyNotification.pending, (state) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    });

    builder.addCase(saveClientDeficiencyNotification.fulfilled, (state, action) => {
      state.currentClientDeficiencyNotification = action.payload;
      // state.client = action.payload.fullClient;
      state.modalSaveStatus = saveCallbackStatus.success;
    });

    builder.addCase(getClientPaymentTransactionFee.rejected,(state, action)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(getClientPaymentTransactionFee.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(getClientPaymentTransactionFee.fulfilled, (state, action) => {
      state.clientPaymentTransactionFee = action.payload;
      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(saveClientPaymentTransactionFee.rejected,(state, action)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(saveClientPaymentTransactionFee.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(saveClientPaymentTransactionFee.fulfilled, (state, action) => {
      state.clientPaymentTransactionFee = action.payload;
      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(getClientRiskAssessmentConfigs.rejected, (state, action)=> {
      state.isLoadingClientRiskAssessmentConfigs = false;
      state.clientRiskAssessmentConfigLoadingStatus = saveCallbackStatus.success
      state.clientRiskAssessmentConfigs = []
    })

    builder.addCase(getClientRiskAssessmentConfigs.pending, (state)=> {
      state.isLoadingClientRiskAssessmentConfigs = true;
      state.clientRiskAssessmentConfigLoadingStatus = saveCallbackStatus.none
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(getClientRiskAssessmentConfigs.fulfilled, (state, action) => {
      state.isLoadingClientRiskAssessmentConfigs = false;
      state.clientRiskAssessmentConfigLoadingStatus = saveCallbackStatus.success
      state.clientRiskAssessmentConfigs = action.payload;
    })

    builder.addCase(createClientRiskAssessmentConfig.rejected, (state, action)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(createClientRiskAssessmentConfig.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(createClientRiskAssessmentConfig.fulfilled, (state, action) => {
      const copyOfClientRiskAssessmentConfigs = [...state.clientRiskAssessmentConfigs]
      
      copyOfClientRiskAssessmentConfigs.push(action.payload)
      
      state.clientRiskAssessmentConfigs = copyOfClientRiskAssessmentConfigs;

      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(updateClientRiskAssessmentConfig.rejected, (state, action)=> {
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(updateClientRiskAssessmentConfig.pending, (state)=> {
      state.apiStatus = saveCallbackStatus.none;
    })

    builder.addCase(updateClientRiskAssessmentConfig.fulfilled, (state, action) => {
      const index = state.clientRiskAssessmentConfigs.findIndex((riskAssessmentConfig: ClientRiskAssessmentFields) => {
        return riskAssessmentConfig.clientPatientEpisodeMarginId === action.payload[0].clientPatientEpisodeMarginId
      })!

      state.clientRiskAssessmentConfigs[index] = action.payload[0]

      const copyOfClientRiskAssessmentConfigs = [...state.clientRiskAssessmentConfigs]

      state.clientRiskAssessmentConfigs = copyOfClientRiskAssessmentConfigs;
      state.apiStatus = saveCallbackStatus.success;
    })

    builder.addCase(deleteClientRiskAssessmentConfig.rejected, (state, action)=> {
      state.deleteClientRiskAssessmentConfigStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    })

    builder.addCase(deleteClientRiskAssessmentConfig.pending, (state)=> {
      state.deleteClientRiskAssessmentConfigStatus = saveCallbackStatus.none;
    })

    builder.addCase(deleteClientRiskAssessmentConfig.fulfilled, (state, action) => {
      const index = state.clientRiskAssessmentConfigs.findIndex((riskAssessmentConfig: ClientRiskAssessmentFields) => {
        return riskAssessmentConfig.clientPatientEpisodeMarginId === action.payload?.marginId
      })!

      state.clientRiskAssessmentConfigs.splice(index, 1)

      const copyOfClientRiskAssessmentConfigs = [...state.clientRiskAssessmentConfigs]

      state.clientRiskAssessmentConfigs = copyOfClientRiskAssessmentConfigs;
      state.deleteClientRiskAssessmentConfigStatus = saveCallbackStatus.success;
    })
  },
});

export const {
  setCurrentClient,
  setFilterFormValues,
  setAllCheckboxFilterChecked,
  resetFilterFormValues,
  clearStatus,
  clearCurrentClient,
  setRecentlyViewedClients,
  setEntitySettingsTabComplete,
  setFacilityTabComplete,
  setPESScriptTabComplete,
  setAuthDocsTabComplete,
  setRulesEngineTabComplete,
  clearComments,
  resetIsActivating,
  resetImplementationClientContext,
  setAdmissionsAdvisorSelectedFacilities,
  setAdmissionsAdvisorSelectedPayers,
  clearRiskSettingEditStatus,
  clearModalEditStatus,
  clearClientDeficiencyNotificationStatus,
  clearFacilityReferralSourcesStatus,
  clearClientRiskAssessmentConfigs
} = implementationSpecialistClientSlice.actions;
export default implementationSpecialistClientSlice.reducer;
