import axios from "axios";
import { configSettings } from "../configuration/config-settings";
import { AxiosResultStatus } from "./axios-result-status";
import { AxiosSavePayload } from "./axios-save-payload";

// Save Helper for calling API Using Axios
export const axiosSaveHelper = async function (
  payload: AxiosSavePayload
): Promise<AxiosResultStatus> {
  const saveResult = new AxiosResultStatus();

  // standard headers for all requests are in http-interceptor.ts
  const header = payload.customHeader;

  //abort signal to cancel api calls. needed for cleaning up useEffect
  const signal = payload.signal

  let url = "";
  // POST request if dataId === 0
  if (payload.dataId === 0) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
    await axios
      .post(url, payload.dataToSave, header && { headers: header })
      .then(function (response) {
        saveResult.entity = response.data;
      })
      .catch(function (error) {
        saveResult.entity = error.response?.data;
        saveResult.errorMessage = error.message;
        saveResult.statusCode = error.response?.status;
        console.log(error);
      });
  } else if (payload.dataId > 0) {
    // PUT or PATCH if dataId > 0
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
    if (!payload.isPatch) {
      await axios
        .put(url, payload.dataToSave, (header || signal) && {
        ...header && {headers: header},
        ...signal && {signal}})
        .then(function (response) {
          saveResult.entity = response.data;
        })
        .catch(function (error) {
          saveResult.entity = error.response?.data;
          saveResult.errorMessage = error.message;
          saveResult.statusCode = error.response?.status;
          console.log(error);
        });
    } else {
      url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
      await axios
        .patch(url, payload.dataToSave, header && { headers: header })
        .then(function (response) {
          saveResult.entity = response.data;
        })
        .catch(function (error) {
          saveResult.entity = error.response?.data;
          saveResult.errorMessage = error.message;
          saveResult.statusCode = error.response?.status;
          console.log(error);
        });
    }
  } else if (payload.dataId < 0) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
    if (!payload.isPatch) {
      await axios
        .post(url, payload.dataToSave, header && { headers: header })
        .then(function (response) {
          saveResult.entity = response.data;
        })
        .catch(function (error) {
          saveResult.entity = error.response?.data;
          saveResult.errorMessage = error.message;
          console.log(error);
        });
    } else {
      await axios
        .put(url, payload.dataToSave, header && { headers: header })
        .then(function (response) {
          saveResult.entity = response.data;
        })
        .catch(function (error) {
          saveResult.entity = error?.response?.data;
          saveResult.errorMessage = error.message;
          console.log(error);
        });
    }
  } else if (payload.dataId === undefined) {
    // in the case that the dataId is a part of the URL already
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
    await axios
      .put(url, payload.dataToSave, header && { headers: header })
      .then(function (response) {
        saveResult.entity = response.data;
      })
      .catch(function (error: Error) {
        saveResult.errorMessage = error.message;
        console.log(error);
      });
  }

  saveResult.hasErrors = saveResult.errorMessage?.length > 0;
  return saveResult;
};