import { createAsyncThunk } from '@reduxjs/toolkit'
import { RuleEditViewModel } from '../../../admin-configuration/models/rule';
import { showErrorStatus, showStatus, updateApplicationStatus } from '../../../security/state/user-slice';
import { errorCodes, errorMessages } from '../../../shared/enums';
import { rulesService } from '../../../shared/service/rules-service';
import { RootState } from '../../../shared/state/root-reducer';
import { ImplementationFacility } from '../../components/implementation-clients/details/models/implementation-facility';
import { DeleteRule } from '../../models/delete-rule';
import { implementationRulesEngineService } from '../../services/implementation-rules-engine-service';
import { setRulesEngineTabComplete } from '../clients/implementation-clients-slice';

export const getPaymentFrequency = createAsyncThunk('rulesEngineContext/getPaymentFrequency',
  async (clientId: number, thunkAPI) => {
    const response = await implementationRulesEngineService.getPaymentFrequency(clientId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error();
    }
    return response.entity;
  }
);

export const savePaymentFrequency = createAsyncThunk('rulesEngineContext/savePaymentFrequency',
  async (facility: ImplementationFacility, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let allFacilities = [...state.implementationContext.implementationRulesEngineContext.facilities];
    const response = await implementationRulesEngineService.savePaymentFrequency(facility);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error()
    }

    const indexToReplace = allFacilities.findIndex((_facility) => {
      return facility.facilityId === _facility.facilityId
    })

    if (indexToReplace > -1) {
      const facilityResponse = {
        ...response.entity,
        rulesGrouped: facility.rulesGrouped,
        rulesUngrouped: facility.rulesUngrouped
      }
      allFacilities.splice(indexToReplace, 1, facilityResponse)
    }

    const isPmtFreqCompleted = allFacilities.every((facility) => !!facility.patientPmtFreq);
    updateRulesEngineTabCompleteStatus(allFacilities, thunkAPI);

    thunkAPI.dispatch(showStatus("Payment Frequency Updated Successfully"));

    return {isPmtFreqCompleted, allFacilities};
  }
);

export const applyCommonRuleSet = createAsyncThunk('rulesEngineContext/applyCommonRuleSet',
  async (facility: ImplementationFacility, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const response = await rulesService.applyCommonRuleSet(facility);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error();
    }

    const facilityWithRules = {
      ...facility,
      isRuleSetupComplete: true,
      rulesGrouped: response.entity.rulesGrouped,
      rulesUngrouped: response.entity.rulesUngrouped
    };

    const allFacilities = [...state.implementationContext.implementationRulesEngineContext.facilities];
    const indexToUpdate = allFacilities.findIndex((item) => item.facilityId === facility.facilityId);

    if (indexToUpdate > -1) {
      allFacilities.splice(indexToUpdate, 1, facilityWithRules)
    }

    updateRulesEngineTabCompleteStatus(allFacilities, thunkAPI);

    return allFacilities;
  }
);

export const clearRules = createAsyncThunk('rulesEngineContext/clearRules',
  async (facility: ImplementationFacility, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const response = await implementationRulesEngineService.clearRules(facility);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error();
    }

    let allFacilities = [...state.implementationContext.implementationRulesEngineContext.facilities];
    const indexToClear = allFacilities.findIndex((item) => {
      return facility.facilityId === item.facilityId;
    });
    if (indexToClear > -1) {
      const facilityToClear = {
        ...allFacilities[indexToClear],
        isRuleSetupComplete: false,
        rulesGrouped: [],
        rulesUngrouped: []
      };

      allFacilities.splice(indexToClear, 1, facilityToClear);

      updateRulesEngineTabCompleteStatus(allFacilities, thunkAPI);

    }
    return allFacilities;
  }
);

export const getFacilityRules = createAsyncThunk('rulesEngineContext/getFacilityRules',
  async (data: {facility: ImplementationFacility}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allFacilities = JSON.parse(JSON.stringify(state.implementationContext.implementationRulesEngineContext.facilities));

    const response = await rulesService.getFacilityRules(data.facility);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error();
    }

    const indexToUpdate = allFacilities.findIndex((f: ImplementationFacility) => f.facilityId === data.facility.facilityId);

    if (indexToUpdate > -1) {
      const facilityToUpdate = {
        ...allFacilities[indexToUpdate],
        rulesGrouped: response.entity.rulesGrouped,
        rulesUngrouped: response.entity.rulesUngrouped
      }

      const updatedFacilities = [...allFacilities];
      updatedFacilities.splice(indexToUpdate, 1, facilityToUpdate);
      return updatedFacilities;
    }
    return allFacilities;
  }
);

export const saveRule = createAsyncThunk(
  "rulesEngineContext/saveRule",
  async (formPayload: RuleEditViewModel, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const clientId = state.implementationContext.implementationSpecialistClient.client.clientId;
    const fullRule = {...state.implementationContext.implementationRulesEngineContext.currentRuleFull};
    const currentFacility = {...state.implementationContext.implementationRulesEngineContext.currentFacility};
    const allFacilities = [...state.implementationContext.implementationRulesEngineContext.facilities];
    const ruleParamsMetaData = state.metaData.ruleParams

    const response = await rulesService.saveRule(
      formPayload,
      fullRule,
      clientId,
      currentFacility,
      ruleParamsMetaData
    );

    if (response.hasErrors) {
      let errorMessage = response.errorMessage;
      if (response.entity.code === errorCodes.duplicateRule){
        errorMessage = errorMessages.duplicateRule
      }
      updateApplicationStatus(errorMessage)
      throw new Error(errorMessage);
    }

    // update facility with updated rules
    const facilityToEditIndex = allFacilities.findIndex((facility) => {
      return facility.facilityId === currentFacility.facilityId;
    });

    let updatedFacility: ImplementationFacility = {...currentFacility};

    if (facilityToEditIndex > -1) {
      updatedFacility = {
        ...currentFacility,
        isRuleSetupComplete: true,
        rulesGrouped: response.entity.groupedRules,
        rulesUngrouped: response.entity.ungroupedRules
      };
    }

    // replace facility in all the facilities (with the facility that has updated rules)
    let updatedFacilities = [...allFacilities];
    updatedFacilities.splice(facilityToEditIndex, 1, updatedFacility);

    updateRulesEngineTabCompleteStatus(updatedFacilities, thunkAPI);

    return updatedFacilities;
  }
);

export const deleteRule = createAsyncThunk(
  "rulesEngineContext/deleteRule",
  async (data: DeleteRule, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allFacilities = [...state.implementationContext.implementationRulesEngineContext.facilities];

    const response = await rulesService.deleteRule(data.ruleId, data.currentFacility);
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage)
      throw new Error(response.errorMessage);
    }

    // update facility with updated rules
    const facilityToEditIndex = allFacilities.findIndex((facility) => {
      return facility.facilityId === data.currentFacility.facilityId;
    });

    let updatedFacility: ImplementationFacility = {...data.currentFacility};

    if (facilityToEditIndex > -1) {
      updatedFacility = {
        ...data.currentFacility,
        isRuleSetupComplete: response.entity.ungroupedRules.length !== 0,
        rulesGrouped: response.entity.groupedRules,
        rulesUngrouped: response.entity.ungroupedRules
      };
    }

    // replace facility in all the facilities (with the facility that has updated rules)
    let updatedFacilities = [...allFacilities];
    updatedFacilities.splice(facilityToEditIndex, 1, updatedFacility);

    updateRulesEngineTabCompleteStatus(updatedFacilities, thunkAPI);

    return updatedFacilities;
  }


);


export const reOrderRule = createAsyncThunk(
  "rulesEngineContext/reOrderRule",
  async (data: any, thunkAPI) => {
    const response = await rulesService.reOrderRule(
      data.facility.rulesUngrouped,
      data.rulesInGroup,
      data.dndSourceIndex,
      data.dndDestinationIndex,
      data.facility
    );
    // no need to send back anything to Slice. Just handle any error.
    // "reOrderRuleInUI()" function below independently does reorder in state cache and updates UI
    // (for purpose of instant snappy reorder experience without async API lag)
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    }
  }
);

// Similar function to above. This reorders rule in state memory for instant snappy reordering experience
// The function above actually does async save to API
export const reOrderRuleInUI = createAsyncThunk(
  "rulesEngineContext/reOrderRuleInUI",
  async (data: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allFacilities = state.implementationContext.implementationRulesEngineContext.facilities;

    const response = await rulesService.reOrderRuleInUI(
      data.facility.rulesGrouped,
      data.rulesInGroup,
      data.dndSourceIndex,
      data.dndDestinationIndex,
      data.ruleSetIndex
    );

    // update facility with updated rules
    const facilityToEditIndex = allFacilities.findIndex((facility) => {
      return facility.facilityId === data.facility.facilityId;
    });

    let updatedFacility: ImplementationFacility = {...data.facility};

    if (facilityToEditIndex > -1) {
      updatedFacility = {
        ...data.facility,
        rulesGrouped: response
      };
    }

    // replace facility in all the facilities (with the facility that has updated rules)
    let updatedFacilities = [...allFacilities];
    updatedFacilities.splice(facilityToEditIndex, 1, updatedFacility)

    return updatedFacilities;
  }
);


const updateRulesEngineTabCompleteStatus = (allFacilities: ImplementationFacility[], thunkAPI: any) => {
  const isPmtFreqCompleted = allFacilities.every((facility) => !!facility.patientPmtFreq);
  const isRiskRulesCompleted = allFacilities.some((facility) => facility.isRuleSetupComplete);

  if (!isPmtFreqCompleted || !isRiskRulesCompleted) {
    thunkAPI.dispatch(setRulesEngineTabComplete(false));
  } else {
    thunkAPI.dispatch(setRulesEngineTabComplete(true));
  }
}