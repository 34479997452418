import {FacilityPayorFormObj, PayorPlanActions} from '../preselect-payors-plan';
import {
  AxiosSavePayload,
} from '../../../../../../shared/service/axios-save-payload';
import {
  axiosSaveHelper,
} from '../../../../../../shared/service/axios-save-helper';
import {
  AxiosDeletePayload,
} from '../../../../../../shared/service/axios-delete-payload';
import {
  axiosDeleteHelper,
} from '../../../../../../shared/service/axios-delete-helper';

export default async function createPayorPlanPromise(payor: FacilityPayorFormObj) {

  let facilityPayorId: number | undefined = payor.facilityPayorId;

  const returnObj = {
    hasErrors: false,
    cfgPayorId: payor.cfgPayorId,
    facilityPayorId,
  };

  let payorResponse;

  //payor http requests

  switch (payor.action) {

    case PayorPlanActions.Create: {

      if (facilityPayorId) {
        console.error(
            `Cannot create facility payor with cfgPayorId ${payor.cfgPayorId}: a facilityPayorId associated with this cfgPayorId seems to exist for this facility.`);
        payorResponse = {hasErrors: true};
        break;
      }
      const payload: AxiosSavePayload = {
        dataToSave: {
          clientFacilityId: payor.clientFacilityId,
          cfgPayorId: payor.cfgPayorId,
          facilityPayorCode: payor.facilityPayorCode || null,
          facilityPayorName: payor.facilityPayorName || null,
          inNetwork: payor.inNetwork,
        },
        dataId: 0,
        url: `clients/v2/facility/${payor.clientFacilityId}/payor`,
      };
      payorResponse = await axiosSaveHelper(payload);
      if (!payorResponse.hasErrors) {
        facilityPayorId = payorResponse.entity?.facilityPayorId;
        returnObj.facilityPayorId = payorResponse.entity?.facilityPayorId;
      }
      break;

    }

    case PayorPlanActions.Update: {

      if (!facilityPayorId) {
        console.error(
            `Cannot update facility payor associated with cfgPayorId ${payor.cfgPayorId}: facilityPayorId is missing.`);
        payorResponse = {hasErrors: true};
        break;
      }
      const payload: AxiosSavePayload = {
        dataToSave: {
          facilityPayorCode: payor.facilityPayorCode || null,
          facilityPayorName: payor.facilityPayorName || null,
          inNetwork: payor.inNetwork,
        },
        dataId: facilityPayorId,
        url: `clients/v2/facility/${payor.clientFacilityId}/payor`,
      };
      payorResponse = await axiosSaveHelper(payload);
      break;

    }
    case PayorPlanActions.Delete: {

      if (!facilityPayorId) {
        console.error(
            `Cannot delete facility payor associated with cfgPayorId ${payor.cfgPayorId}: facilityPayorId is missing.`);
        payorResponse = {hasErrors: true};
        break;
      }
      const payload: AxiosDeletePayload = {
        dataId: facilityPayorId,
        url: `clients/v2/facility/${payor.clientFacilityId}/payor`,
      };
      payorResponse = await axiosDeleteHelper(payload);
      break;

    }
    default:
      break;
  }

  if (payorResponse?.hasErrors) {
    /**
     *  If there is an error in the payor level,
     *  we will just skip to the next payor
     *  without doing anything in the plan level.
     * */
    returnObj.hasErrors = true;
    return returnObj;
  }

  //plan http requests

  const planPromiseArr: Promise<any>[] = [];

  payor.facilityPayorPlans.forEach((plan) => {

    const facilityPayorPlanId = plan.facilityPayorPlanId;

    switch (true) {

      case payor.action === PayorPlanActions.Delete: {
        /**
         * Deleting the payor above deletes all associated plans and rates,
         * so we do not need to do anything.
         * */
        break;
      }

      case plan.action === PayorPlanActions.Create: {

        if (facilityPayorPlanId) {
          console.error(
              `Cannot create facility payor plan with cfgPayorPlanId ${plan.cfgPayorPlanId}: a facilityPayorPlanId associated with this cfgPayorPlanId seems to exist for this facility.`);
          returnObj.hasErrors = true;
          break;
        }

        const payload: AxiosSavePayload = {
          dataToSave: {
            facilityPayorId,
            clientFacilityId: plan.clientFacilityId,
            cfgPayorPlanId: plan.cfgPayorPlanId,
            facilityPayorPlanCode: plan.facilityPayorPlanCode || null,
            facilityPayorPlanName: plan.facilityPayorPlanName || null,
          },
          dataId: 0,
          url: `clients/v2/facility/${plan.clientFacilityId}/payor/${facilityPayorId}/plan`,
        };
        planPromiseArr.push(axiosSaveHelper(payload));
        break;
      }

      case plan.action === PayorPlanActions.Update: {

        if (!facilityPayorPlanId) {
          console.error(
              `Cannot update facility payor plan associated with cfgPayorPlanId ${plan.cfgPayorPlanId}: facilityPayorPlanId is missing.`);
          returnObj.hasErrors = true;
          break;
        }

        const payload: AxiosSavePayload = {
          dataToSave: {
            facilityPayorPlanCode: plan.facilityPayorPlanCode || null,
            facilityPayorPlanName: plan.facilityPayorPlanName || null,
          },
          dataId: facilityPayorPlanId,
          url: `clients/v2/facility/${plan.clientFacilityId}/payor/${facilityPayorId}/plan`,
        };
        planPromiseArr.push(axiosSaveHelper(payload));

        break;
      }

      case plan.action === PayorPlanActions.Delete: {

        if (!facilityPayorPlanId) {
          console.error(
              `Cannot delete facility payor associated with cfgPayorId ${payor.cfgPayorId}: facilityPayorId is missing.`);
          returnObj.hasErrors = true;
          break;
        }
        const payload: AxiosDeletePayload = {
          dataId: facilityPayorPlanId,
          url: `clients/v2/facility/${payor.clientFacilityId}/payor/${facilityPayorId}/plan`,
        };
        planPromiseArr.push(axiosDeleteHelper(payload));
        break;
      }

      default: {
        break;
      }

    }

  });

  const resolvedPromises = await Promise.all(planPromiseArr);

  resolvedPromises.forEach((resolvedPromise) => {

    if (resolvedPromise?.hasErrors) {
      returnObj.hasErrors = true;
    }
  });

  return returnObj;

}