import {axiosDeleteHelper} from '../../shared/service/axios-delete-helper';
import {AxiosDeletePayload} from '../../shared/service/axios-delete-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import {
  ImplementationAuthDocs,
} from '../components/implementation-clients/details/models/implementation-auth-docs';
import {AuthDocsDetails, AuthDocsForm} from '../models/auth-docs-form';

export class AuthDocsService {
  private mapAuthDocsToClient = (
    formValues: AuthDocsForm,
    fullClientAuthDocs: ImplementationAuthDocs,
    isEditAuthDocs: boolean
  ) => {
    return {
      typeOfDoc: formValues.typeOfDoc.toLowerCase(),
      documentUrl: formValues.documentUrl,
      clientFacilityId: formValues.clientFacilityId || null,
      isSigReq: formValues.isSigReq
    };
  };

  private mapSendAuthDocs = (
    formValues: AuthDocsForm,
  ) => {
    return {
      typeOfDoc: formValues.typeOfDoc,
      documentUrl: formValues.documentUrl,
      clientId: formValues.clientId,
      clientFacilityId: formValues.clientFacilityId || null,
    };
  };

  private mapAuthDocsToForm = (authDocs: ImplementationAuthDocs) => {
    const authDocsForm: AuthDocsForm = {
      authorizationDocumentsId: authDocs?.authorizationDocumentsId || undefined,
      clientFacilityId: authDocs?.clientFacilityId,
      typeOfDoc: authDocs?.typeOfDoc,
      documentUrl: authDocs?.documentUrl,
      isSigReq: authDocs?.isSigReq
    };
    return authDocsForm;
  };

  private mapAuthDocsToDetails = (authDocs: ImplementationAuthDocs) => {
    const authDocsDetails: AuthDocsDetails = {
      clientId: authDocs?.clientId,
      authorizationDocumentsId: authDocs?.authorizationDocumentsId || undefined,
      clientFacilityId: authDocs?.clientFacilityId,
      typeOfDoc: authDocs?.typeOfDoc || "",
      documentUrl: authDocs?.documentUrl || "",
      isSigReq: authDocs?.isSigReq
    };
    return authDocsDetails;
  };

  async getClientAuthDocs(
    clientId: number,
    clientFacilityId: number | null
  ): Promise<AxiosResultStatus> {
    const queryParameters3 = clientFacilityId
      ? `?clientFacilityId=${clientFacilityId}`
      : clientId
      ? `?clientId=${clientId}`
      : "";

    const payload: AxiosReadPayload = {
      dataId: -2,
      url: `patient-encounter/v2/patient/encounter/config/authDocument/templates${queryParameters3}`,
    };
    const response = await axiosReadHelper(payload);
    const authDocs = response.entity;

    if (!response.hasErrors) {
      const authDocsForm = this.mapAuthDocsToForm(authDocs);
      response.entity = {
        authDocsForm: authDocsForm,
        apiResponseAuthDocs: authDocs,
      };
    }
    return response;
  }

  async getClientAuthDoc(templateId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: -2,
      url: `patient-encounter/v2/patient/encounter/config/authDocument/${templateId}`,
    };
    const response = await axiosReadHelper(payload);
    const authDocs = response.entity;

    if (!response.hasErrors) {
      const authDocsForm = this.mapAuthDocsToForm(authDocs);
      const authDocsDetails = this.mapAuthDocsToDetails(authDocs);
      response.entity = {
        authDocsForm: authDocsForm,
        apiResponseAuthDocs: authDocs,
        authDocsDetails: authDocsDetails,
      };
    }
    return response;
  }

  async saveAuthDocs(
    clientId: number | null,
    clientFacilityId: number | null | undefined,
    formValues: AuthDocsForm,
    fullClientAuthDocs: ImplementationAuthDocs,
    allClientAuthDocs: ImplementationAuthDocs[],
    existingAuthDocs: Set<string>
  ) {
    const templateId = formValues?.authorizationDocumentsId;

    const isEditAuthDocs = templateId !== 0 && templateId !== undefined;

    const clientAuthDocsToSave = this.mapAuthDocsToClient(
      formValues,
      fullClientAuthDocs,
      isEditAuthDocs
    );

    const updateAuthDocsUrl = `patient-encounter/v2/patient/encounter/config/authDocument/${templateId}/template`;

    const addAuthDocsUrl = `patient-encounter/v2/patient/encounter/config/authDocument/template?clientId=${clientId}`;

    const payload: AxiosSavePayload = {
      dataToSave: clientAuthDocsToSave,
      dataId: -2,
      isPatch: isEditAuthDocs,
      url: isEditAuthDocs ? updateAuthDocsUrl : addAuthDocsUrl,
    };

    const response = await axiosSaveHelper(payload);

    if (!response.hasErrors) {
      const authDocsResponse = response.entity;
      let apiResponseAuthDocs: ImplementationAuthDocs[] = [];

      const authDocsIndex = allClientAuthDocs.findIndex((authDoc) => {
        return (
          authDoc.authorizationDocumentsId ===
          authDocsResponse.authorizationDocumentsId
        );
      });

      if (authDocsIndex > -1) {
        allClientAuthDocs.splice(authDocsIndex, 1, authDocsResponse);
      } else {
        allClientAuthDocs.push(authDocsResponse);
      }
      apiResponseAuthDocs = allClientAuthDocs;
      response.entity = {
        apiResponseAuthDocs,
      };
    }
    return response;
  }

  async deleteAuthDocs(
    templateId: number
  ) {
    const deleteAuthDocUrl = `patient-encounter/v2/patient/encounter/config/authDocument/${templateId}/template`;

    const payload: AxiosDeletePayload = {
      dataId: -2,
      url: deleteAuthDocUrl,
    };

    const response = await axiosDeleteHelper(payload);

    return response;
  }

  async sendDocumentsTest(
    formValues: AuthDocsForm,
  ) {
    const clientAuthDocsToSave = this.mapSendAuthDocs(
      formValues);

    const sendDocumentUrl =
      "patient-encounter/v2/patient/encounter/config/authDocument/signature";

    const payload: AxiosSavePayload = {
      dataToSave: clientAuthDocsToSave,
      dataId: 0,
      url: sendDocumentUrl,
    };

    const response = await axiosSaveHelper(payload);

    return response;
  }
}

export const authDocsService = new AuthDocsService();
