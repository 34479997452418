import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { blankLockingModalOpenStatus, LockingModalOpenStatus } from "../components/models/locking-modal-open-status";
import { blankPatientModalOpenStatus, PatientModalOpenStatus } from "../components/models/patient-modal-open-status";

export interface RecordLockingState {
  lockingModalOpenStatus: LockingModalOpenStatus,
  patientModalOpenStatus: PatientModalOpenStatus,

  isLockStatusReleased_overview: boolean,
  isLockStatusReleased_insurance: boolean,
  isLockStatusReleased_patientChampions: boolean,
  isLockStatusReleased_paymentPrograms: boolean,

  lockRequestedById?: string | null | undefined
}

const initialState = {
  lockingModalOpenStatus: blankLockingModalOpenStatus,
  patientModalOpenStatus: blankPatientModalOpenStatus,

  isLockStatusReleased_overview: false,
  isLockStatusReleased_insurance: false,
  isLockStatusReleased_patientChampions: false,
  isLockStatusReleased_paymentPrograms: false,

  lockRequestedById: ""
} as RecordLockingState;

const patientRecordLockingContextSlice = createSlice({
  name: "patientRecordLockingContext",
  initialState,
  reducers: {
    setLockRequestedModalOpen_overview: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isLockRequestedModalOpen_overview: action.payload
      }
    },
    setLockRequestedModalOpen_insurance: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isLockRequestedModalOpen_insurance: action.payload
      }
    },
    setLockRequestedModalOpen_patientChampions: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isLockRequestedModalOpen_patientChampions: action.payload
      }
    },
    setLockRequestedModalOpen_paymentPrograms: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isLockRequestedModalOpen_paymentPrograms: action.payload
      }
    },


    setInUseModalOpen_overview: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isInUseModalOpen_overview: action.payload
      }
    },
    setInUseModalOpen_insurance: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isInUseModalOpen_insurance: action.payload
      }
    },
    setInUseModalOpen_patientChampions: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isInUseModalOpen_patientChampions: action.payload
      }
    },
    setInUseModalOpen_paymentPrograms: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isInUseModalOpen_paymentPrograms: action.payload
      }
    },



    setRequestDeniedModalOpen_overview: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isRequestDeniedModalOpen_overview: action.payload
      }
    },
    setRequestDeniedModalOpen_insurance: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isRequestDeniedModalOpen_insurance: action.payload
      }
    },
    setRequestDeniedModalOpen_patientChampions: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isRequestDeniedModalOpen_patientChampions: action.payload
      }
    },
    setRequestDeniedModalOpen_paymentPrograms: (state, action: PayloadAction<boolean>) => {
      state.lockingModalOpenStatus = {
        ...state.lockingModalOpenStatus,
        isRequestDeniedModalOpen_paymentPrograms: action.payload
      }
    },



    setIsIOCModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus = {
        ...state.patientModalOpenStatus,
        isIOCModalOpen: action.payload
      }
    },
    setIsInsuranceModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus = {
        ...state.patientModalOpenStatus,
        isInsuranceModalOpen: action.payload
      }
    },
    setIsVOBModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus = {
        ...state.patientModalOpenStatus,
        isVOBModalOpen: action.payload
      }
    },
    setIsLocModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus={
        ...state.patientModalOpenStatus,
        isLocModalOpen: action.payload
      }
    },
    setIsPatientChampionsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus = {
        ...state.patientModalOpenStatus,
        isPatientChampionsModalOpen: action.payload
      }
    },
    setIsPaymentProgramsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.patientModalOpenStatus = {
        ...state.patientModalOpenStatus,
        isPaymentProgramModalOpen: action.payload
      }
    },


    setLockStatusReleased_overview: (state, action: PayloadAction<boolean>) => {
      state.isLockStatusReleased_overview = action.payload
    },
    setLockStatusReleased_insurance: (state, action: PayloadAction<boolean>) => {
      state.isLockStatusReleased_insurance = action.payload
    },
    setLockStatusReleased_patientChampions: (state, action: PayloadAction<boolean>) => {
      state.isLockStatusReleased_patientChampions = action.payload
    },
    setLockStatusReleased_paymentPrograms: (state, action: PayloadAction<boolean>) => {
      state.isLockStatusReleased_paymentPrograms = action.payload
    },

    setLockRequestedById: (state, action: PayloadAction<string | undefined | null>) => {
      state.lockRequestedById = action.payload || null
    },

    resetPatientRecordLockingContext() {
      return initialState;
    },
  },

});

export const {
  setLockRequestedModalOpen_overview,
  setLockRequestedModalOpen_insurance,
  setLockRequestedModalOpen_patientChampions,
  setLockRequestedModalOpen_paymentPrograms,

  setInUseModalOpen_overview,
  setInUseModalOpen_insurance,
  setInUseModalOpen_patientChampions,
  setInUseModalOpen_paymentPrograms,

  setRequestDeniedModalOpen_overview,
  setRequestDeniedModalOpen_insurance,
  setRequestDeniedModalOpen_patientChampions,
  setRequestDeniedModalOpen_paymentPrograms,

  setIsIOCModalOpen,
  setIsInsuranceModalOpen,
  setIsPatientChampionsModalOpen,
  setIsPaymentProgramsModalOpen,
  setIsVOBModalOpen,
  setIsLocModalOpen,

  setLockStatusReleased_overview,
  setLockStatusReleased_insurance,
  setLockStatusReleased_patientChampions,
  setLockStatusReleased_paymentPrograms,

  resetPatientRecordLockingContext,
  setLockRequestedById
} = patientRecordLockingContextSlice.actions;
export default patientRecordLockingContextSlice.reducer;
