import {
  Grid,
  Paper,
  Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent, useCallback,
  useEffect, useMemo,
  useState,
} from 'react';
import {
  Button, Checkbox, Snackbar, TextField, LoadingOverlay,
} from '@finpay-development/shared-components';
import ClientFacilityDropDown
  from '../../../../../shared/components/client-facility-dropdown';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../../shared/state/root-reducer';
import {
  configGetPayers,
} from '../../../../../admin-configuration/state/admin-configuration-thunk';
import {AppDispatch} from '../../../../../shared/state/store';
import {
  PayerRow,
} from '../../../../../admin-configuration/models/payers';
import '../../../../../scss/pages/admin/_admin-clients-payors-plans-preselect.scss';
import {
  useFormik,
} from 'formik';
import {axiosReadHelper} from '../../../../../shared/service/axios-read-helper';
import {
  showErrorStatus,
} from '../../../../../security/state/user-slice';
import {FacilityPayers} from '../../../../../admissions-advisor/models/vob';
import _ from 'lodash/fp';
import SearchIcon from '@mui/icons-material/Search';
import createPayorPlanPromises, {
  CreatePayorPlanPromiseResponse,
} from './helpers/createPayorPlanPromises';
import {
  ApplicationStatusState, emptyApplicationStatus,
  GetError, GetNotification,
} from '../../../../../security/model/application-status';
import {AddPayorsPlanModal} from './add-payors-plan-modal';
import {Utils} from '../../../../../shared/utils';
import SectionCompleteStatus
  from '../../../../../shared/components/section-complete-status';
import ErrorIcon from '@mui/icons-material/Error';

export interface FacilityPlanFormObj {
  facilityPayorPlanId: number;
  facilityPayorId: number;
  clientFacilityId: number;
  cfgPayorPlanId: number;
  cfgPayorPlanName: string;
  facilityPayorPlanCode?: null | string;
  facilityPayorPlanName?: null | string;
  facilityPayorLocRateLos?: any[];
  action?: PayorPlanActions;
}

export interface FacilityPayorFormObj {
  facilityPayorId: number;
  clientFacilityId: number;
  cfgPayorId: number;
  cfgPayorName: string;
  facilityPayorCode?: null | string;
  facilityPayorName?: null | string;
  inNetwork: boolean;
  facilityPayorPlans: FacilityPlanFormObj[];
  action?: PayorPlanActions;
}

interface PreselectPayorsPlanProps {
  handleFormOnChange: (formValues: Record<number, FacilityPayorFormObj>[]) => void;
}

export enum PayorPlanActions {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export const PreselectPayorsPlan = ({handleFormOnChange}: PreselectPayorsPlanProps) => {

  const {allClientsWithFacilitiesMap, currentClientId, payors, payorsMap} = {
    allClientsWithFacilitiesMap: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.allClientsWithFacilitiesMap;
    }),
    currentClientId: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientEntityDetails.clientId;
    }),
    payors: useSelector(
        (state: RootState) => state.adminContext.adminConfigurationContext?.payers,
    ),
    payorsMap: useSelector(
        (state: RootState) => state.adminContext.adminConfigurationContext?.payersMap,
    ),
  };

  const dispatch = useDispatch<AppDispatch>();

  const [currentFacilityId, setCurrentFacilityId] = useState<number>(0);
  const [initFacilityPayorAndPlans, setInitFacilityPayorAndPlans] = useState<Record<string, any>>(
      {});
  const [msg, setMsg] = useState<ApplicationStatusState>(
      emptyApplicationStatus);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [searchKeyWord, setSearchKeyWord] = useState<string>('');
  const [filteredPayor, setFilteredPayor] = useState<FacilityPayorFormObj[]>(
      []);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFormProcessing, setIsFormProcessing] = useState<boolean>(false);

  useEffect(() => {
    if (payors.length === 0) dispatch(configGetPayers(-2));
  }, []);

  const fetchFacilityPayors = async (facilityId: number) => {

    if (!facilityId) {
      setMsg(GetError(NO_FACILITY_SET_UP_ERR_MSG));
      return;
    }

    const response = await axiosReadHelper({
      dataId: -2,
      url: `clients/v2/client/facility/${facilityId}/plan/loc`,
    });

    if (response?.hasErrors) {
      setMsg(GetError(FACILITY_PAYOR_PLAN_LOADING_FAILURE_ERR_MSG));
      return;
    }

    const updatedPayorPlan: FacilityPayorFormObj[] = Array.isArray(
        response?.entity?.facilityPayors) ?
        response.entity?.facilityPayors.map((payor: FacilityPayers) => {

          const payorFromMasterList: PayerRow | undefined = payorsMap.get(
              payor.cfgPayorId);

          return {
            facilityPayorId: payor.facilityPayorId,
            clientFacilityId: payor.clientFacilityId,
            cfgPayorId: payor.cfgPayorId,
            //adding name for sorting
            cfgPayorName: payorFromMasterList?.payorName ?
                payorFromMasterList.payorName :
                '',
            facilityPayorCode: payor.facilityPayorCode,
            facilityPayorName: payor.facilityPayorName,
            inNetwork: payor.inNetwork,
            facilityPayorPlans: Array.isArray(payor.facilityPayorPlans) ?
                payor.facilityPayorPlans.map((plan) => {

                  const planFromMasterList = Array.isArray(
                      payorFromMasterList?.payorPlans) ?
                      payorFromMasterList?.payorPlans.find((planFromMaster) => {
                        return planFromMaster.payorPlanId ===
                            plan.cfgPayorPlanId;
                      }) :
                      null;

                  return {
                    facilityPayorPlanId: plan.facilityPayorPlanId,
                    facilityPayorId: plan.facilityPayorId,
                    clientFacilityId: plan.clientFacilityId,
                    cfgPayorPlanId: plan.cfgPayorPlanId,
                    //adding name for sorting
                    cfgPayorPlanName: planFromMasterList?.planName ?
                        planFromMasterList?.planName :
                        '',
                    facilityPayorPlanCode: plan.facilityPayorPlanCode,
                    facilityPayorPlanName: plan.facilityPayorPlanName,
                    facilityPayorLocRateLos: plan.facilityPayorLocRateLos,
                  };
                }) :
                [],
          };
        }) :
        [];

    const sortedPlans = updatedPayorPlan.map((updatedPayor) => {
      return {
        ...updatedPayor,
        facilityPayorPlans: Utils.sortAlphabetically(
            updatedPayor.facilityPayorPlans, 'cfgPayorPlanName'),
      };
    });

    return {
      facilityId,
      sortedPayorsAndPlans: Utils.sortAlphabetically(sortedPlans,
          'cfgPayorName'),
    };

  };

  const loadFacilityPayors = async () => {
    if (payorsMap.size > 0) {
      if (!currentFacilityId) {
        const initialFacilityId = allClientsWithFacilitiesMap?.get(
            currentClientId)?.clientFacilities?.[0]?.facilityId!;
        setCurrentFacilityId(initialFacilityId);
        return fetchFacilityPayors(initialFacilityId);
      } else if (!initFacilityPayorAndPlans.hasOwnProperty(
          `${currentFacilityId}`)) {
        return fetchFacilityPayors(currentFacilityId);
      }
    }
  };

  useEffect(() => {

    loadFacilityPayors().then((res) => {
      if (res) setInitFacilityPayorAndPlans({
        ...initFacilityPayorAndPlans,
        [res.facilityId]: res.sortedPayorsAndPlans,
      });
    });

  }, [currentFacilityId, payorsMap, initFacilityPayorAndPlans]);

  const handleSubmit = async (facilityPayors: FacilityPayorFormObj[]): Promise<any> => {

    const payorPlanPromiseArr = createPayorPlanPromises(facilityPayors);

    if (!Array.isArray(facilityPayors) || facilityPayors.length === 0 ||
        payorPlanPromiseArr.length === 0) {
      setMsg(GetNotification(NO_FACILITY_PAYOR_PLAN_TO_SAVE_MSG));
      return;
    }

    const resolvedPromises = await Promise.all(payorPlanPromiseArr);

    let errorMessage = 'Error creating facility payors/plans associated with the following payors: ';
    let errorWhileSaving = false;

    resolvedPromises.forEach(
        (resolvedPromise: Awaited<CreatePayorPlanPromiseResponse>) => {
          if (resolvedPromise?.hasErrors) {

            const payor = payors.find((payor) => {
              return payor.payorId === resolvedPromise.cfgPayorId;
            });

            errorMessage += `${payor?.payorName}`;
            errorWhileSaving = true;
          }
        });

    if (errorWhileSaving) {
      dispatch(showErrorStatus(errorMessage));
    } else {
      setMsg(GetNotification(FACILITY_PAYOR_PLAN_CREATION_SUCCESS_MSG));
      const res = await fetchFacilityPayors(currentFacilityId);
      if (res) {
        setInitFacilityPayorAndPlans({
          ...initFacilityPayorAndPlans,
          [res.facilityId]: res.sortedPayorsAndPlans,
        });
        setSearchKeyWord('');
      }
    }

  };

  const preselectPayorPlanFormik = useFormik({
    initialValues: initFacilityPayorAndPlans,
    enableReinitialize: true,
    onSubmit: () => {
    },
  });

  useEffect(() => {
    handleFormOnChange(
        preselectPayorPlanFormik.values as Record<number, FacilityPayorFormObj>[]);
  }, [preselectPayorPlanFormik.values]);

  const handleModalActionButtonOnClick = (value: boolean) => {
    setIsModalOpen(value);
    if (!value) setPage(0);
  };

  const handleClientFacilityDropDownOnChange = useCallback(
      (facilityId: number) => {
        setCurrentFacilityId(facilityId);
        setPage(0);
        setSearchKeyWord('');
      }, []);

  const currentFacilityFormikValues = preselectPayorPlanFormik.values?.[currentFacilityId];

  useEffect(() => {
    /**
     * Using useEffect because directly setting
     * the payors props as the initial state
     * sometimes leads to a stale state
     * */
    setFilteredPayorFromSearchText(searchKeyWord);

  }, [currentFacilityFormikValues, searchKeyWord]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target?.value;
    _.debounce(500, setFilteredPayorFromSearchText);
    setSearchKeyWord(searchText);
  };

  const setFilteredPayorFromSearchText = (searchText: string) => {

    if (searchText.trim() === '') {

      setFilteredPayor(currentFacilityFormikValues);

    } else {
      const filteredPayorsArr = Array.isArray(currentFacilityFormikValues) ?
          currentFacilityFormikValues.filter(
              (payor: FacilityPayorFormObj) => {

                const matchesPayor = (
                    payor.cfgPayorName &&
                    payor.cfgPayorName.toLowerCase().
                        includes(searchText.toLowerCase())
                );
                if (matchesPayor) return true;

              }) :
          [];
      setFilteredPayor(filteredPayorsArr);
      setPage(0);
    }
  };

  const tableBody = useMemo(() => {

    if (Array.isArray(filteredPayor) && Array.isArray(
        initFacilityPayorAndPlans?.[currentFacilityId])) {
      return filteredPayor.slice!(
          page * rowsPerPage,
          page * rowsPerPage +
          rowsPerPage).
          map((payor: FacilityPayorFormObj) => {

                const initFacilityPayor = initFacilityPayorAndPlans[currentFacilityId]?.find(
                    (payorAndPlans: FacilityPayorFormObj) => {
                      return payorAndPlans?.cfgPayorId ===
                          payor?.cfgPayorId;
                    });

                const currentFormikPayorIndex = Array.isArray(
                    currentFacilityFormikValues) ?
                    currentFacilityFormikValues.findIndex(
                        (selectedPayor: FacilityPayorFormObj) => {
                          return selectedPayor.cfgPayorId ===
                              payor?.cfgPayorId;
                        }) : -1;

                const filteredPayorIndex = Array.isArray(
                    filteredPayor) ?
                    filteredPayor.findIndex(
                        (selectedPayor: FacilityPayorFormObj) => {
                          return selectedPayor.cfgPayorId ===
                              payor?.cfgPayorId;
                        }) : -1;

                const selectedFacilityPayor = currentFacilityFormikValues?.[currentFormikPayorIndex];

                const payorFromMasterList = payorsMap.get(
                    payor.cfgPayorId);

                const checkedPlansArr = Array.isArray(
                    selectedFacilityPayor?.facilityPayorPlans) ?
                    selectedFacilityPayor.facilityPayorPlans.filter(
                        (displayedPlan: FacilityPlanFormObj) => {
                          return (displayedPlan.action ===
                                  PayorPlanActions.Create) ||
                              (displayedPlan.action ===
                                  PayorPlanActions.Update) ||
                              (typeof displayedPlan.action ===
                                  'undefined' &&
                                  displayedPlan.facilityPayorPlanId);
                        }) :
                    [];

                /*
                 * Disabling Payor inputs if
                 * 1) Plan has not been selected
                 * */

                const disablePayorField = checkedPlansArr.length === 0;

                return (
                    <TableBody
                        key={`payor-${payor.cfgPayorId}`}
                        test-id="payor-plan-group">
                      <TableRow
                          className="table-row">
                        <TableCell
                            className="table-cell-other">
                          <Typography
                              test-id={`preselect-payor-plans-payor-${payor.cfgPayorId}`}
                          >
                            {payorFromMasterList?.payorName ?
                                payorFromMasterList.payorName :
                                ''}
                          </Typography>
                        </TableCell>
                        <TableCell
                            className="table-cell-other">
                          <TextField
                              name={`[${currentFacilityId}].${currentFormikPayorIndex}.facilityPayorName`}
                              onBlur={preselectPayorPlanFormik.handleBlur}
                              test-id={`payor-${payor.cfgPayorId}-override-name`}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                preselectPayorPlanFormik.handleChange(e);

                                if (selectedFacilityPayor?.action === undefined) {
                                  preselectPayorPlanFormik.setFieldValue(
                                      `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                      PayorPlanActions.Update);
                                } else if (selectedFacilityPayor.action ===
                                    PayorPlanActions.Update) {
                                  //The following is to make sure that payors are not marked as 'UPDATE'
                                  //if the input field end results are the same as what they were initially
                                  if (initFacilityPayor &&
                                      e.target.value ===
                                      (initFacilityPayor.facilityPayorName || '') &&
                                      (selectedFacilityPayor.facilityPayorCode ||
                                          '') ===
                                      (initFacilityPayor.facilityPayorCode || '') &&
                                      selectedFacilityPayor.inNetwork ===
                                      initFacilityPayor.inNetwork
                                  ) {
                                    preselectPayorPlanFormik.setFieldValue(
                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                        undefined);
                                  }
                                }
                              }
                              }
                              value={filteredPayor?.[filteredPayorIndex]?.facilityPayorName ||
                                  ''}
                              disabled={disablePayorField}
                          />
                        </TableCell>
                        <TableCell
                            className="table-cell-other">
                          <TextField
                              name={`[${currentFacilityId}].${currentFormikPayorIndex}.facilityPayorCode`}
                              onBlur={preselectPayorPlanFormik.handleBlur}
                              test-id={`payor-${payor.cfgPayorId}-override-code`}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                preselectPayorPlanFormik.handleChange(e);

                                if (selectedFacilityPayor?.action === undefined) {
                                  preselectPayorPlanFormik.setFieldValue(
                                      `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                      PayorPlanActions.Update);
                                } else if (selectedFacilityPayor.action ===
                                    PayorPlanActions.Update) {

                                  //The following is to make sure that payors are not marked as 'UPDATE'
                                  //if the input field end results are the same as what they were initially

                                  if (initFacilityPayor &&
                                      (selectedFacilityPayor.facilityPayorName ||
                                          '') ===
                                      (initFacilityPayor.facilityPayorName || '') &&
                                      e.target.value ===
                                      (initFacilityPayor.facilityPayorCode || '') &&
                                      selectedFacilityPayor.inNetwork ===
                                      initFacilityPayor.inNetwork
                                  ) {
                                    preselectPayorPlanFormik.setFieldValue(
                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                        undefined);
                                  }
                                }
                              }}
                              value={filteredPayor?.[filteredPayorIndex]?.facilityPayorCode ||
                                  ''}
                              disabled={disablePayorField}
                          />
                        </TableCell>
                        <TableCell
                            className="inn">
                          <Checkbox
                              checked={!!selectedFacilityPayor?.inNetwork}
                              disabled={disablePayorField}
                              id={`payor-${payor.cfgPayorId}-inn`}
                              name={`${currentFacilityId}.${currentFormikPayorIndex}.inNetwork`}
                              label=""
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                preselectPayorPlanFormik.handleChange(e);

                                if (selectedFacilityPayor?.action === undefined) {
                                  preselectPayorPlanFormik.setFieldValue(
                                      `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                      PayorPlanActions.Update);
                                } else if (selectedFacilityPayor.action ===
                                    PayorPlanActions.Update) {

                                  //The following is to make sure that payors are not marked as 'UPDATE'
                                  //if the input field end results are the same as what they were initially

                                  if (initFacilityPayor &&
                                      (selectedFacilityPayor.facilityPayorName ||
                                          '') ===
                                      (initFacilityPayor.facilityPayorName || '') &&
                                      (selectedFacilityPayor.facilityPayorCode ||
                                          '') ===
                                      (initFacilityPayor.facilityPayorCode || '') &&
                                      e.target.checked ===
                                      initFacilityPayor.inNetwork
                                  ) {
                                    preselectPayorPlanFormik.setFieldValue(
                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                        undefined);
                                  }
                                }
                              }}
                          />
                        </TableCell>
                        <TableCell
                            className="inn">
                        </TableCell>
                      </TableRow>

                      <>
                        {payor.facilityPayorPlans.map(
                            (plan: FacilityPlanFormObj) => {

                              const currentFormikPlanIndex = Array.isArray(
                                  selectedFacilityPayor?.facilityPayorPlans) ?
                                  selectedFacilityPayor?.facilityPayorPlans.findIndex(
                                      (selectedPlan: FacilityPlanFormObj) => {
                                        return selectedPlan.cfgPayorPlanId ===
                                            plan.cfgPayorPlanId;
                                      }) :
                                  -1;

                              const selectedFacilityPlan = selectedFacilityPayor?.facilityPayorPlans?.[currentFormikPlanIndex];

                              const initFacilityPlan = Array.isArray(
                                  initFacilityPayor?.facilityPayorPlans) ?
                                  initFacilityPayor.facilityPayorPlans.find(
                                      (facilityPlan: FacilityPlanFormObj) => {
                                        return facilityPlan?.cfgPayorPlanId ===
                                            plan?.cfgPayorPlanId;
                                      }) :
                                  false;

                              const payorPlanFromMasterList = Array.isArray(
                                  payorFromMasterList?.payorPlans) ?
                                  payorFromMasterList?.payorPlans.find(
                                      planFromMaster => {
                                        return planFromMaster.payorPlanId ===
                                            plan.cfgPayorPlanId;
                                      }) :
                                  null;

                              /*
                              * Disabling Plan inputs if plan checkbox is not checked
                              * and marked for deletion
                              * */

                              const disablePlanField = selectedFacilityPlan?.action ===
                                  PayorPlanActions.Delete
                                  || (!selectedFacilityPlan?.facilityPayorPlanId &&
                                      !selectedFacilityPlan?.action);

                              return (
                                  <React.Fragment
                                      key={`plan-${plan.cfgPayorPlanId}`}>
                                    <TableRow
                                        className="table-row">
                                      <TableCell
                                          className="table-cell-other"
                                          style={{paddingLeft: '3rem'}}>
                                        <Checkbox
                                            checked={(selectedFacilityPlan?.action ===
                                                PayorPlanActions.Create ||
                                                selectedFacilityPlan?.action ===
                                                PayorPlanActions.Update
                                                ||
                                                (selectedFacilityPlan?.action ===
                                                    undefined &&
                                                    selectedFacilityPlan?.facilityPayorPlanId)
                                            )}
                                            id={`plan-${plan.cfgPayorPlanId}`}
                                            name={`[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}]`}
                                            label={payorPlanFromMasterList?.planName ?
                                                payorPlanFromMasterList?.planName :
                                                ''}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {

                                              const isChecked = !!event?.target?.checked;

                                              if (!isChecked) {

                                                if (selectedFacilityPlan.facilityPayorPlanId) {
                                                  //Unchecking saved plan. Need to delete plan

                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                      PayorPlanActions.Delete);

                                                  //Setting back the initial plan values, so user can see what they are deleting
                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].facilityPayorPlanCode`,
                                                      initFacilityPlan.facilityPayorPlanCode);
                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].facilityPayorPlanName`,
                                                      initFacilityPlan.facilityPayorPlanName);

                                                } else {

                                                  /**
                                                   * Unchecking plan that is not saved.
                                                   * Getting rid of the plan from the list.
                                                   * */

                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}].${currentFormikPayorIndex}.facilityPayorPlans`,
                                                      preselectPayorPlanFormik.values[currentFacilityId][currentFormikPayorIndex].facilityPayorPlans.filter(
                                                          (
                                                              _: FacilityPlanFormObj,
                                                              index: number) => {
                                                            return index !==
                                                                currentFormikPlanIndex;
                                                          }),
                                                  );

                                                }

                                                if (checkedPlansArr.length <= 1) {

                                                  //If we are removing the last remaining plan,
                                                  //we need to remove the payor as well

                                                  if (selectedFacilityPayor.facilityPayorId) {

                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                                        PayorPlanActions.Delete);

                                                    //Setting back the initial payor values, so user can see what they are deleting
                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorCode`,
                                                        initFacilityPayor.facilityPayorCode);
                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorName`,
                                                        initFacilityPayor.facilityPayorName);
                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].inNetwork`,
                                                        initFacilityPayor.inNetwork);

                                                  } else {

                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}]`,
                                                        currentFacilityFormikValues.filter(
                                                            (
                                                                _: FacilityPlanFormObj,
                                                                index: number) => {
                                                              return index !==
                                                                  currentFormikPayorIndex;
                                                            }),
                                                    );
                                                  }
                                                }

                                              } else if (isChecked) {

                                                if (checkedPlansArr.length === 0) {
                                                  //If there were no plans prior to checking a plan, we need to make sure the payor is included as well

                                                  if (selectedFacilityPayor.facilityPayorId) {
                                                    //Adding back the payor that was previously marked for deletion
                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                                        undefined);
                                                  } else {
                                                    preselectPayorPlanFormik.setFieldValue(
                                                        `[${currentFacilityId}][${currentFormikPayorIndex}].action`,
                                                        PayorPlanActions.Create);
                                                  }
                                                }

                                                if (selectedFacilityPlan.facilityPayorPlanId) {
                                                  //Adding back the plan that was previously marked for deletion
                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                      undefined);
                                                } else {
                                                  preselectPayorPlanFormik.setFieldValue(
                                                      `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                      PayorPlanActions.Create);
                                                }

                                              }

                                            }}
                                        />
                                      </TableCell>
                                      {plan.action === PayorPlanActions.Delete &&
                                      (Array.isArray(
                                              plan.facilityPayorLocRateLos) &&
                                          plan.facilityPayorLocRateLos.length > 0) ?
                                          (
                                              <TableCell align="center" style={{
                                                textAlign: 'center',
                                                color: 'red',
                                                fontWeight: 'bold',
                                              }} colSpan={8}>
                                                <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                }}>
                                                  <ErrorIcon
                                                      style={{fontSize: '16px'}}/>
                                                  <span
                                                      test-id={`plan-${plan.cfgPayorPlanId}-active-rates-warning`}
                                                      style={{paddingLeft: '4px'}}>Deselecting a Plan with rates.</span>
                                                  <span style={{
                                                    color: '#111',
                                                    paddingLeft: '4px',
                                                  }}>All active rates will be removed upon save.</span>
                                                </div>
                                              </TableCell>
                                          ) :
                                          (
                                              <>
                                                <TableCell
                                                    className="table-cell-other">
                                                  <TextField
                                                      name={`${currentFacilityId}.${currentFormikPayorIndex}.facilityPayorPlans.${currentFormikPlanIndex}.facilityPayorPlanName`}
                                                      test-id={`plan-${plan.cfgPayorPlanId}-override-name`}
                                                      onBlur={preselectPayorPlanFormik.handleBlur}
                                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                                        preselectPayorPlanFormik.handleChange(
                                                            e);

                                                        if (selectedFacilityPlan.action ===
                                                            undefined) {
                                                          preselectPayorPlanFormik.setFieldValue(
                                                              `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                              PayorPlanActions.Update);
                                                        } else if (selectedFacilityPlan.action ===
                                                            PayorPlanActions.Update) {

                                                          //The following is to make sure that plans are not marked as 'UPDATE'
                                                          //if the input field end results are the same as what they were initially

                                                          if (initFacilityPlan &&
                                                              e.target.value ===
                                                              (initFacilityPlan.facilityPayorPlanName ||
                                                                  '') &&
                                                              (selectedFacilityPlan.facilityPayorPlanCode ||
                                                                  '') ===
                                                              (initFacilityPlan.facilityPayorPlanCode ||
                                                                  '')
                                                          ) {
                                                            preselectPayorPlanFormik.setFieldValue(
                                                                `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                                undefined);
                                                          }
                                                        }
                                                      }}
                                                      value={preselectPayorPlanFormik.values?.[currentFacilityId]?.[currentFormikPayorIndex]?.facilityPayorPlans?.[currentFormikPlanIndex]?.facilityPayorPlanName ||
                                                          ''}
                                                      disabled={disablePlanField}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                    className="table-cell-other">
                                                  <TextField
                                                      name={`${currentFacilityId}.${currentFormikPayorIndex}.facilityPayorPlans.${currentFormikPlanIndex}.facilityPayorPlanCode`}
                                                      onBlur={preselectPayorPlanFormik.handleBlur}
                                                      test-id={`plan-${plan.cfgPayorPlanId}-override-code`}
                                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {

                                                        preselectPayorPlanFormik.handleChange(
                                                            e);

                                                        if (selectedFacilityPlan.action ===
                                                            undefined) {
                                                          preselectPayorPlanFormik.setFieldValue(
                                                              `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                              PayorPlanActions.Update);
                                                        } else if (selectedFacilityPlan.action ===
                                                            PayorPlanActions.Update) {

                                                          //The following is to make sure that plans are not marked as 'UPDATE'
                                                          //if the input field end results are the same as what they were initially

                                                          if (initFacilityPlan &&
                                                              (selectedFacilityPlan.facilityPayorPlanName ||
                                                                  '') ===
                                                              (initFacilityPlan.facilityPayorPlanName ||
                                                                  '') &&
                                                              e.target.value ===
                                                              (initFacilityPlan.facilityPayorPlanCode ||
                                                                  '')
                                                          ) {
                                                            preselectPayorPlanFormik.setFieldValue(
                                                                `[${currentFacilityId}][${currentFormikPayorIndex}].facilityPayorPlans[${currentFormikPlanIndex}].action`,
                                                                undefined);
                                                          }
                                                        }
                                                      }}
                                                      value={preselectPayorPlanFormik.values?.[currentFacilityId]?.[currentFormikPayorIndex]?.facilityPayorPlans?.[currentFormikPlanIndex]?.facilityPayorPlanCode ||
                                                          ''}
                                                      disabled={disablePlanField}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                    className="table-cell-inn">
                                                </TableCell>
                                                <TableCell
                                                    className="table-cell-other">
                                                  {Array.isArray(
                                                          plan.facilityPayorLocRateLos) &&
                                                      plan.facilityPayorLocRateLos.length >
                                                      0 && <SectionCompleteStatus
                                                          label="Yes"
                                                          isComplete={true}
                                                          test-id={`plan-${plan.cfgPayorPlanId}-active-rates`}
                                                      />}
                                                </TableCell>
                                              </>
                                          )}

                                    </TableRow>
                                  </React.Fragment>
                              );
                            })}
                      </>
                    </TableBody>
                );
              },
          );

    } else {
      return (
          <TableBody key={`empty-key`}>
            <TableRow
                style={{height: '300px'}}>
              <TableCell align="center"
                         colSpan={12}
                         style={{border: 'none'}}>
                <LoadingOverlay
                    whiteBackground={true}/>
              </TableCell>
            </TableRow>
          </TableBody>
      );
    }
  }, [
    filteredPayor,
    initFacilityPayorAndPlans,
    currentFacilityId,
    page,
    rowsPerPage,
    currentFacilityFormikValues,
  ]);

  return (
      <>
        <Paper style={{padding: '20px'}}>
          <Grid container>
            <Grid item xs={12}>
              <form>
                <Grid container item
                      justifyContent="space-between">
                  <Grid item container xs="auto" alignItems="center"
                  >
                    <Typography variant="subtitle2">
                      Preselect Payors and Plans
                    </Typography>
                  </Grid>
                  <Grid item className="table-header-items">
                    <TextField
                        name="search"
                        placeholder="Search by Payor Name"
                        value={searchKeyWord}
                        onChange={handleSearch}
                        onBlur={() => {
                        }}
                        startAdornment={<SearchIcon/>}
                        test-id="preselect-payors-plan-search"
                    />
                  </Grid>
                  <Grid className="table-header-items">
                    <Button
                        onClick={() => handleModalActionButtonOnClick(
                            true)} style={{width: '100%'}}
                        test-id="preselect-payors-plan-add-btn"
                    >
                      Add Payors/Plans
                    </Button>
                  </Grid>
                  <Grid item
                        justifyContent="flex-end">
                    <ClientFacilityDropDown
                        selectedClient={allClientsWithFacilitiesMap?.get(
                            currentClientId)!
                        }
                        handleChange={(event: any) => {
                          handleClientFacilityDropDownOnChange(
                              event?.target?.value);
                        }}
                        initFacility={currentFacilityId!}
                    />
                  </Grid>
                </Grid>
                <Grid item style={{
                  margin: '1.5rem 0',
                  borderBottom: '1px solid #cccccc',
                }}/>
                <>
                  <Grid item>
                    <TableContainer component={Paper}>
                      <Table
                          className="table alternating-row"
                          stickyHeader
                          sx={{
                            height: 'max-content',
                          }}
                          test-id="preselect-payors-plan-table"
                      >
                        <TableHead>
                          <TableRow>
                            {tableHeaderLabels.map(
                                (tableHeaders, index) => (
                                    <TableCell
                                        key={`${tableHeaders.id}${index}`}
                                        className={`fee-mainhead nowrap table-cell.${tableHeaders.label ===
                                        'INN' ?
                                            `table-cell-inn` :
                                            'table-cell-other'}`}
                                    >
                                      {tableHeaders.label}
                                    </TableCell>
                                ))}
                          </TableRow>
                        </TableHead>
                        {tableBody}
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item container justifyContent="flex-end"
                        className="container-row">
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[
                          5,
                          10,
                          15,
                        ]}
                        count={Array.isArray(
                            preselectPayorPlanFormik.values?.[currentFacilityId]) ?
                            preselectPayorPlanFormik.values?.[currentFacilityId].length :
                            0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={'View'}
                        onPageChange={(
                            event: unknown, newPage: number) => {
                          setPage(newPage);
                        }}
                        onRowsPerPageChange={(
                            event: ChangeEvent<HTMLInputElement>,
                        ) => {
                          setRowsPerPage(+event.target.value);
                          setPage(0);
                        }}
                    />
                  </Grid>
                </>
                <Grid container item justifyContent="flex-end">
                  <Button
                      spinnerLeftPosition={4}
                      disabled={isFormProcessing}
                      loading={isFormProcessing}
                      test-id={`payor-plan-save-btn`}
                      onClick={async () => {
                        setIsFormProcessing(true);
                        await handleSubmit(
                            preselectPayorPlanFormik.values?.[currentFacilityId]);
                        setIsFormProcessing(false);
                      }}
                  >
                    Save
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
          <Snackbar
              open={!!msg?.showMessage}
              message={msg.message}
              type={msg?.messageType}
              onClose={() => {
                setMsg(emptyApplicationStatus);
              }}
          />
        </Paper>
        {isModalOpen && <AddPayorsPlanModal
            open={isModalOpen}
            currentFacilityId={currentFacilityId}
            preselectPayorPlanFormik={preselectPayorPlanFormik}
            initFacilityPayorAndPlans={initFacilityPayorAndPlans}
            handleModalActionButtonOnClick={handleModalActionButtonOnClick}
        />}
      </>
  );
};

export const DEFAULT_ROWS_PER_PAGE = 10;

const tableHeaderLabels = [
  {label: 'Payor/Plan', id: 'payorAndPlan'},
  {label: 'Override Name', id: 'facilityPayorAndPlanName'},
  {label: 'Override Code', id: 'facilityPayorAndPlanCode'},
  {label: 'INN', id: 'isINN'},
  {label: 'Active Rates', id: 'activeRates'},
];

export const NO_FACILITY_SET_UP_ERR_MSG = 'Facility not set up for client';
export const FACILITY_PAYOR_PLAN_LOADING_FAILURE_ERR_MSG = 'There was an error loading the facility\'s payors and plans. Please contact a system administrator.';
export const FACILITY_PAYOR_PLAN_CREATION_SUCCESS_MSG = 'Facility Payors and Plans Saved';
export const NO_FACILITY_PAYOR_PLAN_TO_SAVE_MSG = 'No Facility Payors or Plans to Save';
