import * as Yup from "yup";
import { ValidationError } from "yup";
import {
  allRulesPass,
  initPasswordRules,
  PasswordFieldState,
  RulesCheck,
} from "../model/password-rule-state";
import { consecutiveLettersValidator, specialCharsValidator } from "./../../shared/misc/regex";

// Validation Rules for Passwords
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, "length")
    .matches(/[a-z]/, "hasLowerCase")
    .matches(/[A-Z]/, "hasUpperCase")
    .matches(/[0-9]/, "hasNumber")
    .matches(consecutiveLettersValidator, "hasNoConsecutiveLetters")
    .matches(specialCharsValidator, "hasSpecialCharacter"),    
  confirmPassword: Yup.string()
    .required("passwordMatch")
    .oneOf([Yup.ref("newPassword"), ""], "passwordMatch"),
});

// Utility Function for checking passwords against the defined YUP Rules
export async function validatePassword(
  values: PasswordFieldState
): Promise<RulesCheck> {
  const rulesCheckResult: RulesCheck = {
    isValid: false,
    rules: initPasswordRules,
  };

  await validationSchema
    .validate(values, { abortEarly: false })
    .then(function () {
      // No Errors Return Happy Day Result
      rulesCheckResult.isValid = true;
      rulesCheckResult.rules = allRulesPass;
    })
    .catch(function (err: ValidationError) {
      // Identify which rules are negative
      let rulesCheckState: any = {};
      err.errors.forEach((e: string) => {
        rulesCheckState[e] = false;
      });

      // Merge negative rules into result state
      const rulesToShow = { ...allRulesPass, ...rulesCheckState };
      rulesCheckResult.isValid = false;
      rulesCheckResult.rules = rulesToShow;
    });

  return rulesCheckResult;
}
