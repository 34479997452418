export interface Facility { // for security
  userFacilityScopeId?: number;
  facilityId: number;
  facilityName: string;
  clientFacilityId?: number
  userClientScopeId?: number
}

export const emptyFacility: Facility = {
  userFacilityScopeId: 0,
  facilityId: -1,
  facilityName: "",
}

export class FacilitySave implements Facility{
  userFacilityScopeId!: number;
  facilityId!: number;
  facilityName!: string;
}
