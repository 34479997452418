import '../../../scss/components/_forms.scss';
import classes from '../../../admissions-advisor/components/patient-search/patient-search.module.css';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography
} from '@mui/material';
import MUILink from '@mui/material/Link';

import moment from "moment";

import {
  patientIocResult,
  patientSearchResult,
} from "../../../admissions-advisor/models/patient";

interface OpenSearchPatientRowProps {
  patient: patientSearchResult;
  patientIdToOpen: string;
  handleExpandPatientTable: (documentId: string) => void;
  handleNavigateToPatientDetails: (patient: patientSearchResult) => void;
  handleNavigateToIOC: (patientId: number, iocId: number) => void;
}

const tableCellStyle = { borderBottom: "none", padding: "5px 5px" };
const subTableHeaderCellStyle = { fontWeight: 600 };
const cellIconWidth = "5%";
const cellNameWidth = "25%";
const cellDOBWidth = "15%";
const cellEmailWidth = "55%";
const cellIOCWidth = "15%";
const cellClientWidth = "40%";
const cellFacilityWidth = "40%";

const OpenSearchPatientRow = (props: OpenSearchPatientRowProps) => {
  const {
    patient,
    patientIdToOpen,
    handleExpandPatientTable,
    handleNavigateToPatientDetails,
    handleNavigateToIOC,
  } = props;
  let hasIOCs = true;
  if (patient._source?.iocs && patient._source?.iocs?.length === 0) {
    hasIOCs = false;
  }
  return (
    <>
      <TableRow>
        <TableCell sx={tableCellStyle} align="right" width={cellIconWidth}>
          {hasIOCs ? (
            <IconButton
              size="small"
              onClick={() => handleExpandPatientTable(patient._id)}
              style={{ fontSize: "1.5rem" }}
            >
              {patientIdToOpen === patient._id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          ) : (
            <Typography
              component="div"
              style={{ textAlign: "center" }}
              variant="caption"
            >
              No IOCs
            </Typography>
          )}
        </TableCell>
        <TableCell width={cellNameWidth} sx={tableCellStyle}>
          <Box>
            <MUILink
              style={{ cursor: "pointer", fontWeight: "500" }}
              onClick={() => {
                handleNavigateToPatientDetails(patient);
              }}
            >{`${patient._source?.firstName} ${patient._source?.lastName}`}</MUILink>
            <Typography variant="caption" component="div">
              {patient._source.phoneNumber}
            </Typography>
          </Box>
        </TableCell>
        <TableCell width={cellDOBWidth} sx={tableCellStyle}>
          {moment(patient._source?.birthDate).format("l")}
        </TableCell>
        <TableCell width={cellEmailWidth} sx={tableCellStyle}>
          {patient._source?.email ? (
            patient._source?.email
          ) : (
            <i>email unavailable</i>
          )}
        </TableCell>
      </TableRow>
      <TableRow style={{ padding: 0, margin: 0 }} key={`${patient._id}-r2`}>
        <TableCell
          colSpan={4}
          style={{ margin: 0, backgroundColor: "transparent", padding: 0 }}
        >
          {hasIOCs && (
            <Collapse in={patientIdToOpen === patient._id} timeout="auto">
              <Box sx={{ margin: 1 }}>
                <Table
                  size="small"
                  width={"100%"}
                  className={classes.lightSectionBackground}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={subTableHeaderCellStyle}
                        width={cellIOCWidth}
                      >
                        IOC ID
                      </TableCell>
                      <TableCell
                        sx={subTableHeaderCellStyle}
                        width={cellClientWidth}
                      >
                        Client
                      </TableCell>
                      <TableCell
                        sx={subTableHeaderCellStyle}
                        width={cellFacilityWidth}
                      >
                        Facility
                      </TableCell>
                    </TableRow>
                    {patient._source?.iocs?.map((ioc: patientIocResult) => (
                      <>
                        <TableRow key={ioc.patientEncounterId}>
                          <TableCell width={cellIOCWidth}>
                            <MUILink
                              style={{ cursor: "pointer", fontWeight: "500" }}
                              onClick={() => {
                                handleNavigateToIOC(
                                  patient._source.fpPatientId,
                                  ioc.patientEncounterId
                                );
                              }}
                            >
                              {ioc.patientEncounterId}
                            </MUILink>
                          </TableCell>
                          <TableCell width={cellClientWidth}>
                            {ioc.clientName}
                          </TableCell>
                          <TableCell width={cellFacilityWidth}>
                            {ioc.facilityName}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default OpenSearchPatientRow
