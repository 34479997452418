import { Button, Toggle } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreateIcon from '@mui/icons-material/Create';
import { Box, Grid, IconButton, LinearProgress, Paper, Typography, Alert } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumberToUSD } from '../../../shared/calculators';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { EstFinAssistModalNew } from './estimator-financial-assistance-modal-new';
import { admissionsAdvisorUtils } from 'src/admissions-advisor/utils/admission-advisor-utils';
import { Utils } from 'src/shared/utils';
import { callNewEstimatePut } from 'src/admissions-advisor/state/estimator-thunk';

export function EstFinAssistanceSummaryNew() {
  const [finAssistModalOpen, setFinAssistModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [progressBarColor, setProgressBarColor] = useState("#36b37e");
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const state = {
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.newEstimate
    ),
    vobState: useSelector(
        (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    vobPatientState: useSelector(
        (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
  };

  const { estimatorState, vobState, vobPatientState } = state;

  useEffect(() => {
    finAssistFormik.values.finAssistYesNo = estimatorState?.financialAssistance !== undefined ? true : false;
    const toggledisabled: boolean = (estimatorState?.isPlanYearCrossover) ? true : false;
    setToggleDisabled(toggledisabled);
    if (estimatorState?.financialAssistance?.qualifiesForAssistance) {
      setProgressBarColor("#36b37e");
    } else {
      setProgressBarColor("#BF3549");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: progressBarColor,
      },
    })
  )(LinearProgress);

  const handleFinAssistModalOpen = (isEdit: boolean) => {
    setEditMode(isEdit);
    setFinAssistModalOpen(true);
  };

  function handleModalSubmit(isEditMode: boolean) {
    setFinAssistModalOpen(false);
  }

  function handleModalCancel() {
    setFinAssistModalOpen(false);
  }

  const finAssistFormik = useFormik({
    initialValues: {
      finAssistYesNo: estimatorState?.financialAssistance !== undefined ? true : false
    },
    onSubmit: () => {},
  });

  const handleHasAssitance = () => {
      // resetting Financial Assistance if No is toggled
      if (finAssistFormik.values.finAssistYesNo) {
          let copyOfEstimateState = Utils.deepClone(estimatorState);
          delete copyOfEstimateState.financialAssistance;
          const putEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
              vobState.vobId,
              vobState.client?.clientId!,
              vobState.facility?.facilityId!,
              copyOfEstimateState,
              vobPatientState.patient.advisorPatientId!,
              copyOfEstimateState.patientEncounterId
          );
          dispatch(
              callNewEstimatePut({
                  estimate: putEstimateBody,
                  estimateId: copyOfEstimateState?.estimateId,
              })
          );
      }
  };

  const patientStatedIncome = () => {
    let income: number = 0.0;
    if (estimatorState?.financialAssistance?.income) {
      income =
        estimatorState.financialAssistance?.income * 12;
    }
    return income;
  };

  const patientFPL = () => {
    let fpl: number = 0;
    let patientIncome: number = patientStatedIncome();
    let natlPovertLevel: number | undefined = estimatorState?.financialAssistance?.natlPovertyThreshold;
    if (patientIncome && natlPovertLevel) {
      fpl = (patientIncome / natlPovertLevel) * 100;
    }
    return fpl.toFixed(1) + "%";
  }

  const formatIncomeSourceData = (incomeSourceData: number | undefined | null): string | 0 => {
    let incomeAmt = 0.00;
    if (incomeSourceData) {
      // powerlytics data represents thousands
      incomeAmt = parseFloat(incomeSourceData.toString()) * 100;
    }
    return formatNumberToUSD(incomeAmt);
  }

  const renderIncomeSource = (incomeSource: string) => {
    switch(incomeSource) {
      case 'businessIncome':
        return <>
        <Typography variant="body2">
          Business Income
        </Typography>
        {formatIncomeSourceData(estimatorState?.financialAssistance?.businessIncome)}
      </>
      case 'investmentIncome':
        return <>
        <Typography variant="body2">
          Investment Income
        </Typography>
        {formatIncomeSourceData(estimatorState?.financialAssistance?.avgWealthIncome)}
      </>
      case 'alimonyIncome':
        return <>
        <Typography variant="body2">
          Alimony Income
        </Typography>
        {formatIncomeSourceData(estimatorState?.financialAssistance?.alimonyIncome)}
      </>
      case 'retirementIncome':
        return <>
        <Typography variant="body2">
          Retirement Income
        </Typography>
        {formatIncomeSourceData(estimatorState?.financialAssistance?.retirementIncome)}
      </>
      default:
        // nothing
    }
  }

  const patientFPLApprovalThreshold = () => {
    const thresholdPct: number = estimatorState?.financialAssistance?.clientFplApprovalThreshold? estimatorState?.financialAssistance?.clientFplApprovalThreshold : 0;
    return (thresholdPct).toFixed(2) + "%";
  };

  const expenseIncomeRatio = () => {
    let expIncRatio: number = 0.0;
    if (
      estimatorState?.financialAssistance?.income &&
      estimatorState?.financialAssistance?.expenses
    ) {
      expIncRatio =
        (estimatorState.financialAssistance.expenses /
          estimatorState.financialAssistance.income) *
        100;
    }
    return expIncRatio.toFixed(2).toString() + "%";
  };

  const wagesEarnings = () => {
    let income: number = 0.0;
    if (estimatorState?.financialAssistance?.income) {
      income =
        estimatorState.financialAssistance.income * 12;
    }
    return formatNumberToUSD(income - 10000);
  };

  return <>
    {estimatorState?.facilityLevelOfCare?.length! > 0 && (
      <>
        <Paper>
          <Box
            style={{
              padding: "1.0em",
              borderBottom: "1px solid #cccccc",
              marginBottom: "1.5em",
            }}
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid
                item
                justifyContent="center"
                direction="column"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AttachMoneyIcon /> Financial Assistance?
                </Typography>
              </Grid>
              <Grid item>
                <Toggle
                  leftText="Yes"
                  rightText="No"
                  name="finAssistYesNo"
                  disabled={toggleDisabled}
                  value={finAssistFormik.values.finAssistYesNo}
                  formik={finAssistFormik}
                  onChange={() => {
                    handleHasAssitance();
                  }}
                />
              </Grid>
            </Grid>

            {finAssistFormik.values.finAssistYesNo && (
              <>
                {estimatorState?.financialAssistance?.isValid ? (
                  <>
                    <Grid container direction="row">
                      <Grid item direction="column" md={3}>
                        <Typography variant="body2">
                          Patient Stated Income
                        </Typography>
                        {formatNumberToUSD(patientStatedIncome())}
                      </Grid>
                      <Grid item direction="column" md={3}>
                        <Typography variant="body2">Patient FPL</Typography>
                        {patientFPL()}
                      </Grid>
                      <Grid item direction="column" md={4}>
                        <Typography variant="body2">
                          Patient FPL Approval Threshold
                        </Typography>
                        {patientFPLApprovalThreshold()}
                      </Grid>
                      <Grid
                        item
                        direction="column"
                        md={2}
                        style={{ textAlign: "right" }}
                      >
                        <IconButton
                          color="primary"
                          title="Edit Financial Assistance"
                          style={{ fontSize: "1.5rem" }}
                          onClick={() => handleFinAssistModalOpen(true)}
                          size="large">
                          <CreateIcon /> Edit
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      style={{ borderBottom: "1px solid #cccccc" }}
                    >
                      <Grid item direction="column" md={3}>
                        <Typography variant="body2">
                          Expense/Income Ratio
                        </Typography>
                        {expenseIncomeRatio()}
                      </Grid>
                      <Grid item direction="column" md={3}>
                        <Typography variant="body2">
                          Expense/Income Threshold
                        </Typography>
                        {estimatorState.financialAssistance?.clientIncomeExpenseThreshold?.toFixed(2).toString() + "%"}
                      </Grid>
                      <Grid item direction="column" md={4}></Grid>
                      <Grid item direction="column" md={2}></Grid>
                    </Grid>

                    <Grid container direction="row" className="mt-2">
                      <Grid
                        item
                        direction="column"
                        md={12}
                        style={{
                          color: "white",
                          backgroundColor: progressBarColor,
                          textAlign: "center",
                        }}
                      >

                      {estimatorState?.financialAssistance?.qualifiesForAssistance ? (
                          <>Eligible for {estimatorState?.financialAssistance?.scholarshipName}</>
                        ) : (
                          <>Ineligible for {estimatorState?.financialAssistance?.scholarshipName}</>
                        )
                      }
                        
                      </Grid>
                    </Grid>

                    <p className="mt-3">Capacity to Pay Profile</p>

                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      className="mt-2"
                    >
                      <Grid item direction="column" md={6}>
                        <div>
                          <Typography
                            style={{ float: "left" }}
                            variant="body2"
                          >
                            Income Verification Score
                          </Typography>
                          <Typography
                            style={{ float: "right" }}
                            variant="body2"
                          >
                            {(estimatorState?.financialAssistance?.incomeVerificationScore!).toFixed(0)}/10
                          </Typography>
                        </div>
                        <div style={{ clear: "both" }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(estimatorState?.financialAssistance?.incomeVerificationScore! * 10).toFixed(0)}
                            className="success"
                          />
                        </div>
                      </Grid>

                      <Grid item direction="column" md={6}>
                        <div>
                          <Typography
                            style={{ float: "left" }}
                            variant="body2"
                          >
                            Capacity to Pay Score
                          </Typography>
                          <Typography
                            style={{ float: "right" }}
                            variant="body2"
                          >
                            {(estimatorState?.financialAssistance?.capacityToPayScore!).toFixed(0)}/10
                          </Typography>
                        </div>
                        <div style={{ clear: "both" }}>
                          <BorderLinearProgress
                            variant="determinate"
                            value={(estimatorState?.financialAssistance?.capacityToPayScore! * 10).toFixed(2)}
                            className="success"
                          />
                        </div>
                      </Grid>
                    </Grid>

                    <p className="mt-3">Demographic Income Data</p>

                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      className="mt-2"
                    >
                      <Grid item direction="column" md={6}>
                        <Typography variant="body2">
                          Disposable Income
                        </Typography>
                        {formatNumberToUSD(estimatorState?.financialAssistance?.disposableIncome! * 100)}
                      </Grid>
                      {estimatorState?.financialAssistance?.incomeSources?.map((incSource: string, i) => (
                        <Grid item direction="column" md={6}>
                          {renderIncomeSource(incSource)}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      className="mt-2"
                    >
                      <Grid item direction="column" md={6}>
                        <Typography variant="body2">
                          Wages and Earnings
                        </Typography>
                        {wagesEarnings()}
                      </Grid>
                      <Grid item direction="column" md={6}></Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container direction="row">
                      <>
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: "center", padding: "1em" }}
                        >
                          {(estimatorState?.financialAssistance?.inValidReason && estimatorState?.financialAssistance?.inValidReason !== "") && (
                            <><Alert severity="warning" className="mb-3">{estimatorState?.financialAssistance.inValidReason}</Alert></>
                          )}
                          
                          <Button
                            onClick={() => handleFinAssistModalOpen(false)}
                          >
                            Verify Eligibility
                          </Button>
                        </Grid>
                      </>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Box>
        </Paper>
      </>
    )}

    {finAssistModalOpen && (
      <EstFinAssistModalNew
        open={finAssistModalOpen}
        isEdit={editMode}
        handleFinAssistModalCancel={handleModalCancel}
        handleFinAssistModalSubmit={handleModalSubmit}
      />
    )}
  </>;
}
