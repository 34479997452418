import { Client } from "../../shared/model/client";
import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { updateApplicationStatus } from "../state/user-slice";

export class ClientService {
  async getClients(): Promise<Client[]> {

    const readPayload: AxiosReadPayload = {
      url: 'clients/v2/client?includeFacility=true'
    }

    const response = await axiosReadHelper(readPayload);

    let listOfClients: Client[] = [];

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage)
    } else {
      listOfClients = response.entity;
    }
    // Setting the value isAll = true, this is the flag used in the create/update User
    listOfClients[0].isAll= true
    return listOfClients;
  }
}

export const clientService = new ClientService();
