import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Paper,
  Grid,
  MenuItem,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Button, TextField } from "@finpay-development/shared-components";
import { Utils } from "../../../shared/utils";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { ImplementationFacility } from "../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility";
import { riskScoringSchema } from "../../../shared/validation/schemas";
import { clearPatientEpisodeMarginSettingsStatus, setRiskAssessmentForm } from "../../state/risk-assessment-slice";
import { EstFooter } from "../pfr-estimator/estimator-footer";
import { setEstimator } from "../../state/estimator-slice";
import {
  payorRisk,
  timingRisk,
} from "../../../shared/model/timing-and-payor-risk";
import { saveCallbackStatus } from "@finpay-development/shared-components";
import { RiskAssessmentMidSection } from "./risk-assessment-midsection";
import { RiskAssessmentBottomSection } from "./risk-assessment-bottomsection";
import {
  getClientRiskAssessmentConfigs,
  getFacilityReferralSources,
} from "../../../implementation-specialist/state/clients/implementation-clients-thunk";
import {
  clearClientRiskAssessmentConfigs,
  clearFacilityReferralSourcesStatus,
} from "../../../implementation-specialist/state/clients/implementation-clients-slice";
import { configGetFinPayRiskClasses } from "../../../admin-configuration/state/admin-configuration-thunk";
import { RiskClass } from "src/shared/model/risk-class";
import { showErrorStatus } from "src/security/state/user-slice";
import { getPatientEpisodeMarginSettings } from "src/admissions-advisor/state/risk-assessment-thunk";
import { riskAssessmentUtils } from "src/admissions-advisor/utils/risk-assessment-utils";
import { ClientRiskAssessmentFields } from "src/shared/model/client-risk-assessment-config";

const requiredProperties = [
  "monthlyOperatingCostIp",
  "monthlyOperatingCostOp",
  "bedCapacityIp",
  "bedCapacityOp",
  "variableCostPct" 
]

const mapToPrettyPropertyNames = {
  monthlyOperatingCostIp: "In Patient Total Monthly Operating Costs",
  monthlyOperatingCostOp: "Out Patient Total Monthly Operating Costs",
  bedCapacityIp: "In Patient Bed Capacity",
  bedCapacityOp: "Out Patient Bed Capacity",
  variableCostPct: "Variable Cost Percentage"
}

const {
  findInvalidProperties,
  isValidNumber,
  generateMessage
} = riskAssessmentUtils

export function RiskAssessmentMain() {
  const dispatch = useDispatch<AppDispatch>();
  const [enableRunButton, setEnableRunButton] = useState(false);
  const [facilitiesIsLoaded, setFacilitiesIsLoaded] = useState(false);
  const [riskClass, setRiskClass] = useState<RiskClass>()
  const paramId = -2;

  const state = {
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.facilities
    ),
    isLoadingFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isLoading
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    patientEpisodeMarginSettings: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext.patientEpisodeMarginSettings
    ),
    patientEpisodeMarginSettingsStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext.isLoading.patientEpisodeMarginSettingsStatus
    ),
    riskAssessmentFormState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext.riskAssessmentForm
    ),
    isLoadingConfigRiskThresholdsState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .isLoadingRiskThresholds
    ),
    facilityReferralSources: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          ?.facilityReferralSources
    ),
    isLoadingFacilityReferralSources: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          ?.isLoadingFacilityReferralSources
    ),
    facilityReferralSourcesStatus: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          ?.facilityReferralSourcesStatus
    ),
		finPayRiskClasses: useSelector(
			(state: RootState) =>
					state.adminContext.adminConfigurationContext?.finPayRiskClasses
		),
    clientRiskAssessmentConfigs: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient?.clientRiskAssessmentConfigs
    ),
    isLoadingClientRiskAssessmentConfigs: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient?.isLoadingClientRiskAssessmentConfigs
    ),
    clientRiskAssessmentConfigLoadingStatus: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient?.clientRiskAssessmentConfigLoadingStatus
    ),
  };

  const {
    facilities,
    isLoadingFacilities,
    vobState,
    estimatorState,
    riskAssessmentFormState,
    facilityReferralSources,
    isLoadingFacilityReferralSources,
    facilityReferralSourcesStatus,
    finPayRiskClasses,
    clientRiskAssessmentConfigs,
    clientRiskAssessmentConfigLoadingStatus,
    isLoadingClientRiskAssessmentConfigs,
  } = state;

  const payerRisk = vobState.selfPay ? "Self-pay" : vobState.payer.inNetwork ? "In Network" : "Out of Network"
  const hasReferralSourcesConfigured = facilityReferralSources.length > 0

  const allFacilityIds = useMemo(() => {
    return facilities?.flat().map((facility) => {
      return facility.facilityId
    });
  }, [facilities])

  const selectedFacility = useMemo(() => {
    const facilitId = riskAssessmentFormState.facility.facilityId || estimatorState.facility.facilityId

    return facilities?.flat().find((fac) => fac?.facilityId === facilitId);
  }, [riskAssessmentFormState.facility.facilityId])

  const areClientRiskAssessmentConfigsLoaded = useMemo(() => {
    return (clientRiskAssessmentConfigLoadingStatus === saveCallbackStatus.success && !isLoadingClientRiskAssessmentConfigs)
  }, [clientRiskAssessmentConfigLoadingStatus, isLoadingClientRiskAssessmentConfigs])

  const selectedFacilityRiskAssessmentConfig = useMemo(() => {
    if(selectedFacility && areClientRiskAssessmentConfigsLoaded) {

      let facilityRiskAssessmentFields: ClientRiskAssessmentFields | undefined = clientRiskAssessmentConfigs.find((riskConfig: ClientRiskAssessmentFields) => {
        return riskConfig.clientFacilityId === selectedFacility.facilityId
      })

      if(!facilityRiskAssessmentFields) {

        facilityRiskAssessmentFields = clientRiskAssessmentConfigs?.find((riskConfig: ClientRiskAssessmentFields) => {
          return riskConfig.clientFacilityId === null
        })!
      }

      return facilityRiskAssessmentFields
    }
  }, [selectedFacility, areClientRiskAssessmentConfigsLoaded])

  const [listOfMissingOrInvalidProperties, isFacilityConfigured] = useMemo(() => {
      const { 
        variableCostPct,
        monthlyOperatingCostIp,
        monthlyOperatingCostOp,
        bedCapacityIp,
        bedCapacityOp 
      } = selectedFacilityRiskAssessmentConfig || {} 

      const propertiesToCheck = {
        monthlyOperatingCostIp,
        monthlyOperatingCostOp,
        bedCapacityIp,
        bedCapacityOp,
        variableCostPct
      }

      const [listOfMissingOrInvalidProperties, isMissingProperties] = findInvalidProperties(propertiesToCheck, requiredProperties, isValidNumber)
      if(isMissingProperties) {
        return [listOfMissingOrInvalidProperties, false]
      } else {
        return [[], true]
      }
  }, [selectedFacilityRiskAssessmentConfig])

  const isRunButtonEnabled = isFacilityConfigured && enableRunButton 

  useEffect(() => {
    // if riskAssessmentFormState.facility not set use estimatorState.facility for selected facility
    if (!isLoadingFacilities && facilities.length && !facilitiesIsLoaded) {
      setFacilitiesIsLoaded(true);

      let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);

      if (!riskAssessmentFormState.facility.facilityId) {
        if (estimatorState.riskAssessmentForm) {
          riskAssessmentFormStateCopy = Utils.deepClone(estimatorState.riskAssessmentForm);
          // update formik
          riskScoringFormik.setFieldValue("facility", riskAssessmentFormStateCopy.facility.facilityId);
          riskScoringFormik.setFieldValue("facilityCensus", riskAssessmentFormStateCopy.facilityCensus);
          riskScoringFormik.setFieldValue("payorRisk", riskAssessmentFormStateCopy.payorRisk.payorRiskId);
          riskScoringFormik.setFieldValue("timingRisk", riskAssessmentFormStateCopy.timingRisk.timingRiskId);
          riskScoringFormik.setFieldValue("riskClass", riskAssessmentFormStateCopy.riskClass.riskClassId);
          riskScoringFormik.setFieldValue("referralSource", riskAssessmentFormStateCopy.referralSource.facilityReferralSourceId);
          riskScoringFormik.setFieldValue("compareBest", riskAssessmentFormStateCopy.compareBest);
        } else {
          riskAssessmentFormStateCopy.facility = {
            facilityId: estimatorState?.facility?.facilityId,
            facilityName: estimatorState?.facility?.facilityName,
          };
        }
      }
      riskAssessmentFormStateCopy.facilityIds = allFacilityIds;

      // preselect payor risk
      if (!riskAssessmentFormStateCopy.payerRisk?.payorRiskId) {
        if (vobState.selfPay) {
          const selfPayOption = payorRisk.find((payrisk) => payrisk.payorRiskId === 3);
          if (selfPayOption) {
            riskAssessmentFormStateCopy.payorRisk = selfPayOption;
          }
        } else {
          if (vobState.payer.inNetwork) {
            const inNetwOption = payorRisk.find((payrisk) => payrisk.payorRiskId === 1);
            if (inNetwOption) {
              riskAssessmentFormStateCopy.payorRisk = inNetwOption;
            }
          } else {
            const ooNetwOption = payorRisk.find((payrisk) => payrisk.payorRiskId === 2);
            if (ooNetwOption) {
              riskAssessmentFormStateCopy.payorRisk = ooNetwOption;
            }
          }
        }
      }
      dispatch(
        getFacilityReferralSources({
          facilityId: riskAssessmentFormStateCopy.facility?.facilityId!,
          clientId: estimatorState.client.clientId!,
        })
      );
      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
      fetchFinPayRiskClasses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFacilities]);

  useEffect(() => {
    if(estimatorState.client.clientId) {
      dispatch(getClientRiskAssessmentConfigs({clientId: estimatorState.client.clientId}))
    }

    return () => {
        dispatch(clearClientRiskAssessmentConfigs())
    }
  }, [estimatorState.client.clientId])

  useEffect(() => {
    if (!isLoadingFacilityReferralSources && facilityReferralSourcesStatus === saveCallbackStatus.success) {
      // facility referral sources has loaded
      dispatch(clearFacilityReferralSourcesStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingFacilityReferralSources]);

  useEffect(() => {
    if (selectedFacility) {
      dispatch(
        getFacilityReferralSources({
          facilityId: selectedFacility.facilityId,
          clientId: estimatorState.client.clientId!,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacility]);

  useEffect(() => {
    if(clientRiskAssessmentConfigLoadingStatus === saveCallbackStatus.success && !isFacilityConfigured && listOfMissingOrInvalidProperties.length > 0) {
        const listOfMissingProperties = generateMessage(listOfMissingOrInvalidProperties, mapToPrettyPropertyNames)
        const message = `The following required facility settings are missing: ${listOfMissingProperties}. Please contact your system, implementation, or support coordinator.`
        dispatch(showErrorStatus(message));
    }
  }, [selectedFacilityRiskAssessmentConfig, isFacilityConfigured, clientRiskAssessmentConfigLoadingStatus])

  const fetchFinPayRiskClasses = () => {
    dispatch(configGetFinPayRiskClasses({paramId: paramId}));
  }

  const riskScoringValidationSchema = Yup.object(riskScoringSchema);

  function checkIsFormValid(value: {}) {
    riskScoringValidationSchema
      .validate(value, {context: {hasReferralSourcesConfigured}})
      .then(() => { 
        setEnableRunButton(true);
      })
      .catch((err) => {
        let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
        riskAssessmentFormStateCopy.isValid = false;
        dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
        setEnableRunButton(false);
      });
  }

  const riskScoringFormik = useFormik({
    initialValues: {
      facility: riskAssessmentFormState.facility.facilityId
        ? riskAssessmentFormState.facility.facilityId
        : estimatorState?.facility?.facilityId,
      facilityCensus: riskAssessmentFormState.facilityCensus? riskAssessmentFormState.facilityCensus : null,
      payorRisk: riskAssessmentFormState.payorRisk.payorRiskId,
      timingRisk: 1,
      riskClass: riskAssessmentFormState.riskClass.riskClassId,
      referralSource: riskAssessmentFormState.referralSource.facilityReferralSourceId,
      compareBest: riskAssessmentFormState.compareBest,
      showCleared: riskAssessmentFormState.showCleared ? "1" : "0",
    },
    validate: checkIsFormValid,
    validationSchema: riskScoringValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  // fps-6989
  // useEffect(() => {
  //   const timingRiskObj = timingRisk.find((timingRisk) => {
  //     return timingRisk.timingRiskId === riskScoringFormik.values.timingRisk
  //   })

  //   if(
  //     vobState.facility.facilityId &&
  //     timingRiskObj?.timingRiskName &&
  //     payerRisk &&
  //     estimatorState.totalEstimatedPfr
  //   ) {
  //     fetchRiskClass(vobState.facility.facilityId, false, timingRiskObj.timingRiskName, payerRisk, estimatorState.totalEstimatedPfr)
  //   }

  // }, [riskScoringFormik.values.timingRisk])

  // const fetchRiskClass = async (facilityId: number, paidToPatient: boolean, timingRiskName: string, payorRiskName: string, pfrAmt: number) => {
  //   const riskClass = await dispatch(
  //     getRiskClass({
  //         facilityId,
  //         paidToPatient,
  //         timingRiskName,
  //         payorRiskName,
  //         pfrAmt,
  //     }))
  //     console.log('riskClass :>> ', riskClass);
  //   setRiskClass(riskClass.payload)
  // }

  const handleFacilityChange = (e: Event) => {
    const selectedFacilityId = (e.target as HTMLInputElement).value;

    const facilityToSave = facilities
      ?.flat()
      .find((fac) => fac?.facilityId === +selectedFacilityId);

    if(facilityToSave) {
      let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);

      riskAssessmentFormStateCopy.facility = {
        facilityId: facilityToSave?.facilityId,
        facilityName: facilityToSave?.facilityName,
      };

      riskAssessmentFormStateCopy.referralSource = {
        facilityReferralSourceId: undefined,
			  facilityReferralSourceName: "",
      }

      let facilityRiskAssessmentFields: ClientRiskAssessmentFields | undefined = clientRiskAssessmentConfigs.find((riskConfig: ClientRiskAssessmentFields) => {
        return riskConfig.clientFacilityId === facilityToSave.facilityId
      })

      if(!facilityRiskAssessmentFields) {
        facilityRiskAssessmentFields = clientRiskAssessmentConfigs?.find((riskConfig: ClientRiskAssessmentFields) => {
          return riskConfig.clientFacilityId === null
        })!
      }

      const { 
        variableCostPct,
        monthlyOperatingCostIp,
        monthlyOperatingCostOp,
        bedCapacityIp,
        bedCapacityOp 
      } = facilityRiskAssessmentFields || {}

      const propertiesToCheck = {
        monthlyOperatingCostIp,
        monthlyOperatingCostOp,
        bedCapacityIp,
        bedCapacityOp,
        variableCostPct
      }

      const [listOfMissingOrInvalidProperties, isMissingProperties] = findInvalidProperties(propertiesToCheck, requiredProperties, isValidNumber)

      if(isMissingProperties) {
        const message = generateMessage(listOfMissingOrInvalidProperties, mapToPrettyPropertyNames, "Missing the following required facility configurations: ")
        dispatch(showErrorStatus(message));
      } 

      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    }
  };

  const handleTimingRiskChange = (e: Event) => {
    const selectedTimingRiskId = (e.target as HTMLInputElement).value;
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    let selectedTimingRisk = timingRisk.find((trisk) => trisk.timingRiskId === +selectedTimingRiskId);
    if (selectedTimingRisk) {
      riskAssessmentFormStateCopy.timingRisk = selectedTimingRisk;
      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    }
  };

  const handlePayerRiskChange = (e: Event) => {
    const selectedPayerRiskId = (e.target as HTMLInputElement).value;
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    const selectedPayorRisk = payorRisk.find((payrisk) => payrisk.payorRiskId === +selectedPayerRiskId);
    if (selectedPayorRisk) {
      riskAssessmentFormStateCopy.payorRisk = selectedPayorRisk;
      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    }
  };

  const handleReferralSourceChange = (e: Event) => {
    const selectedReferralSourceId = (e.target as HTMLInputElement).value;
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    let selectedReferralSource = facilityReferralSources.find((facref) => facref.facilityReferralSourceId === +selectedReferralSourceId);
    if (selectedReferralSource) {
      riskAssessmentFormStateCopy.referralSource = selectedReferralSource;
      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    }
  };

  const handleRiskClassChange = (e: Event) => {
    const selectedRiskClassId = (e.target as HTMLInputElement).value;
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    let selectedRiskClass = finPayRiskClasses.find((riskClass) => riskClass.riskClassId === +selectedRiskClassId);
    if (selectedRiskClass) {
      riskAssessmentFormStateCopy.riskClass = selectedRiskClass;
      dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    }
  };

  const handleFacilityCensusChange = (e: Event) => {
    const censusValue = (e.target as HTMLInputElement).value;
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    riskAssessmentFormStateCopy.facilityCensus = censusValue;
    dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
  };

  const handleShowClearedChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    riskScoringFormik.setFieldValue("showCleared", value);
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    riskAssessmentFormStateCopy.showCleared = Boolean(+value);
    dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
  };

  const handleCompareBestChange = (isChecked: boolean) => {
    riskScoringFormik.setFieldValue("compareBest", isChecked);
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    riskAssessmentFormStateCopy.compareBest = isChecked;
    dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
  };

  const runRiskAssessment = () => {
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    riskAssessmentFormStateCopy.isValid = true;
    dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));
    // update estimate state with riskAssessmentForm
    let estimatorStateCopy = Utils.deepClone(estimatorState);
    estimatorStateCopy.riskAssessmentForm = riskAssessmentFormStateCopy;
    dispatch(setEstimator(estimatorStateCopy));
  };

  return (
    <>
      <Paper>
        <Box className="m-2 p-2">
          <Typography variant="subtitle2" className="my-4">
            Risk Scoring
          </Typography>

          <Grid container direction="row" spacing={2} className="mt-1">
            <Grid item direction="column" md={3} sm={3}>
              <TextField
                select
                label="Facility"
                name="facility"
                className="state-field"
                value={
                  riskAssessmentFormState.facility?.facilityId
                    ? riskAssessmentFormState.facility.facilityId
                    : riskScoringFormik.values.facility
                }
                loading={isLoadingFacilities}
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handleFacilityChange(e);
                }}
                onBlur={riskScoringFormik.handleBlur}
                error={Boolean(
                  riskScoringFormik.touched.facility &&
                    riskScoringFormik.errors.facility
                )}
              >
                {facilities.flat().map((facility: ImplementationFacility) => (
                  <MenuItem
                    key={facility.facilityId}
                    value={facility.facilityId}
                  >
                    {facility.facilityName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item direction="column" md={1} sm={1}>
              <TextField
                label="Census"
                name="facilityCensus"
                type="number"
                value={
                  riskAssessmentFormState.facilityCensus
                    ? riskAssessmentFormState.facilityCensus
                    : null
                }
                endAdornment={
                  <InputAdornment position="end">
                    <span>
                      <strong>%</strong>
                    </span>
                  </InputAdornment>
                }
                error={
                  riskScoringFormik.touched["facilityCensus"] &&
                  riskScoringFormik.errors["facilityCensus"]
                    ? riskScoringFormik.errors["facilityCensus"]
                    : ""
                }
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handleFacilityCensusChange(e);
                }}
                test-id="facility-census"
                onBlur={riskScoringFormik.handleBlur}
              />
            </Grid>

            <Grid item direction="column" md={2} sm={2}>
              <TextField
                select
                error={
                  riskScoringFormik.touched["payorRisk"] &&
                  riskScoringFormik.errors["payorRisk"]
                    ? riskScoringFormik.errors["payorRisk"]
                    : ""
                }
                label="Payer Risk"
                className="state-field"
                name="payorRisk"
                value={
                  riskAssessmentFormState.payorRisk?.payorRiskId
                    ? riskAssessmentFormState.payorRisk?.payorRiskId
                    : riskScoringFormik.values.payorRisk
                }
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handlePayerRiskChange(e);
                }}
                onBlur={riskScoringFormik.handleBlur}
                placeholder="Select Payer Risk"
              >
                {payorRisk.map((risk) => (
                  <MenuItem key={risk.payorRiskId} value={risk.payorRiskId}>
                    {risk.payorRiskName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item direction="column" md={3} sm={3}>
              <TextField
                select
                error={
                  riskScoringFormik.touched["timingRisk"] &&
                  riskScoringFormik.errors["timingRisk"]
                    ? riskScoringFormik.errors["timingRisk"]
                    : ""
                }
                label="Timing Risk"
                className="state-field"
                name="timingRisk"
                value={riskScoringFormik.values.timingRisk}
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handleTimingRiskChange(e);
                }}
                onBlur={riskScoringFormik.handleBlur}
              >
                {timingRisk.map((risk) => (
                  <MenuItem key={risk.timingRiskId} value={risk.timingRiskId}>
                    {risk.timingRiskName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item direction="column" md={3} sm={3}>
              <TextField
                select
                label="Referral Source"
                className="state-field"
                name="referralSource"
                value={
                  riskAssessmentFormState.referralSource.facilityReferralSourceId
                  ? riskAssessmentFormState.referralSource.facilityReferralSourceId
                  : riskScoringFormik.values.referralSource
                }
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handleReferralSourceChange(e);
                }}
                disabled={!hasReferralSourcesConfigured}
                onBlur={riskScoringFormik.handleBlur}
                error={Boolean(
                  riskScoringFormik.touched.referralSource &&
                  riskScoringFormik.errors.referralSource
                  )}
                >
                  {!hasReferralSourcesConfigured && <MenuItem value={-1}>Data Not Provided</MenuItem>}
                {hasReferralSourcesConfigured && facilityReferralSources.map((refsrc) => (
                  <MenuItem
                  key={refsrc.facilityReferralSourceId}
                  value={refsrc.facilityReferralSourceId}
                  >
                    {refsrc.facilityReferralSourceName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
                </Grid>
          {/* fps-6989 <HeaderWithDetails details={payerRisk} width="half" header="Payor Risk"/>
          <HeaderWithDetails details={riskClass?.riskClassName} width="half" header="Risk Class"/> */}
          <Grid container direction="row" spacing={2} className="mt-2">
            <Grid item direction="column" md={3} sm={3}>
              <TextField
                select
                label="Risk Class"
                className="state-field"
                name="riskClass"
                value={
                  riskAssessmentFormState?.riskClass?.riskClassId
                    ? riskAssessmentFormState.riskClass.riskClassId
                    : riskScoringFormik.values.riskClass
                }
                onChange={(e: Event) => {
                  riskScoringFormik.handleChange(e);
                  handleRiskClassChange(e);
                }}
                onBlur={riskScoringFormik.handleBlur}
                error={Boolean(
                  riskScoringFormik.touched.riskClass &&
                    riskScoringFormik.errors.riskClass
                )}
              >
                {finPayRiskClasses.map((riskClass) => (
                  <MenuItem key={riskClass.riskClassId} value={riskClass.riskClassId}>
                    {riskClass.riskClassName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item direction="column" md={3} sm={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="compareBest"
                      checked={riskScoringFormik.values.compareBest}
                      onChange={(e) => {
                        handleCompareBestChange(e.target.checked);
                      }}
                    />
                  }
                  label="Compare Selected vs. Best Facility"
                />
              </FormGroup>
            </Grid> */}
            {/* <Grid item direction="column" md={3} sm={3}>
              <FormControl>
                <RadioGroup
                  name="showCleared"
                  onChange={handleShowClearedChange}
                  defaultValue={+riskAssessmentFormState.showCleared}
                  value={+riskAssessmentFormState.showCleared}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Cleared"
                    disabled={!riskAssessmentFormState.compareBest}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label="High-Risk Exception"
                    disabled={!riskAssessmentFormState.compareBest}
                  />
                </RadioGroup>
              </FormControl>
            </Grid> */}
          </Grid>
          <Grid container direction="row" spacing={2} className="mt-2">
            <Grid
              item
              direction="column"
              md={12}
              sm={12}
              style={{ textAlign: "right", marginBottom: "1em" }}
            >
              <Button
                onClick={() => runRiskAssessment()}
                disabled={!isRunButtonEnabled}
              >
                Run Assessment
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Risk Assessment Grid */}
      {riskAssessmentFormState.isValid && isFacilityConfigured && (
        <>
          <RiskAssessmentMidSection facilityIds={allFacilityIds} coreRiskClasses={finPayRiskClasses} clientRiskAssessmentConfigs={clientRiskAssessmentConfigs} selectedFacilityRiskAssessmentConfig={selectedFacilityRiskAssessmentConfig}/>

          <RiskAssessmentBottomSection />

          <EstFooter />
        </>
      )}
    </>
  );
}
