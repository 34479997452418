import { Snackbar } from '@finpay-development/shared-components';
import React, { useState } from 'react';

function AccessDeniedView() {
  const [isOpen, setIsOpen] = useState(true);
  function handleCloseSnackbar() {
    setIsOpen(false);
  }

  const noAccess = "Access Denied to this page";
  return (
    <Snackbar
      open={isOpen}
      message={noAccess}
      type="error"
      onClose={handleCloseSnackbar}
    />
  );
}
export default AccessDeniedView;
