  // Fields for change password
 export interface PasswordFieldState {
    newPassword: string;
    confirmPassword: string;
    verificationCode: string;
 }

// Password rules check result
export interface RulesCheck {
    isValid: boolean;
    rules: PasswordRuleState;
}

// Password Rules State
export interface PasswordRuleState {
    length: boolean;
    hasUpperCase: boolean;
    hasLowerCase: boolean;
    hasSpecialCharacter: boolean;
    hasNumber: boolean;
    hasNoConsecutiveLetters: boolean;
    passwordMatch: boolean;
}

// Initial Password Rule Result with all things negative
export const initPasswordRules: PasswordRuleState = {
    length: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialCharacter: false,
    hasNumber: false,
    hasNoConsecutiveLetters: false,
    passwordMatch: false,
}

// Happy Day Password Rule with all things passing 
export const allRulesPass: PasswordRuleState = {
    length: true,
    hasUpperCase: true,
    hasLowerCase: true,
    hasSpecialCharacter: true,
    hasNumber: true,
    hasNoConsecutiveLetters: true,
    passwordMatch: true
}
