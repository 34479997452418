import { LoadingOverlay } from '@finpay-development/shared-components';
import { debounce, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { setLogsFilter } from '../state/users/admin-slice';
import { filterLogs, handleGetLogs } from '../state/users/admin-thunk';
import AdminLogs from './admin-users/admin-logs-filter';
import LogsResults from './admin-users/admin-logs-filter-results';

function AdminLogsView() {

  const dispatch = useDispatch<AppDispatch>();


  const stateFields = {
    searchResultLogs: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredLogs
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.isLoading
    ),
  }

  useEffect(() => {
    dispatch(handleGetLogs());
  }, [dispatch]);

  function handlePatientFilterChange(_patient: string) {
    dispatch(
      setLogsFilter({
        patient: (patient: string) =>
          patient.toLowerCase().includes(_patient.toLowerCase()),
      })
    );
    dispatch(filterLogs());
  }


  function handleUserFilterChange(_user: string) {
    dispatch(
      setLogsFilter({
        user: (user: string) =>
          user.toLowerCase().includes(_user.toLowerCase()),
      })
    );
    dispatch(filterLogs());
  }

  function handleClientFilterChange(_user: string) {
    dispatch(
      setLogsFilter({
        client: (user: string) =>
          user.toLowerCase().includes(_user.toLowerCase()),
      })
    );
    dispatch(filterLogs());
  }

  const adminUsersView = (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <AdminLogs
          handlePatientFilterChange={debounce(
            handlePatientFilterChange,
            750
          )}
          handleUserFilterChange={debounce(
            handleUserFilterChange,
            750
          )}
          handleClientFilterChange={debounce(
            handleClientFilterChange,
            750
          )}
        />
      </Grid>
      <Grid item xs={9}>
        <LogsResults
          dataLogs={stateFields.searchResultLogs}
        />
      </Grid>
    </Grid>
  )

  return (
    <main>
      <div className="content fullwidth">
        {stateFields.isLoading ? <LoadingOverlay /> : adminUsersView }
      </div>
    </main>
  );
}

export default AdminLogsView;
