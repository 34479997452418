import { saveCallbackStatus } from "@finpay-development/shared-components";
import {
  ApplicationStatusState,
  GetError,
  emptyApplicationStatus,
} from "../../../security/model/application-status";
import {
  AuthDocsType,
  ImplementationAuthDocs,
  emptyAuthDocsType,
} from "../../components/implementation-clients/details/models/implementation-auth-docs";
import {
  AuthDocsDetails,
  AuthDocsForm,
  blankAuthDocsDetails,
  blankAuthDocsForm,
} from "../../models/auth-docs-form";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  deleteAuthDocs,
  getClientAuthDoc,
  getClientAuthDocs,
  saveAuthDocs,
  sendAuthDocsTest,
} from "./implementation-auth-docs-thunk";
import { UNKNOWN_SERVER_ERROR } from "../../../shared/state/saving-status";

interface ImplementationAuthDocsState {
  authDoc: AuthDocsType;
  authDocs: ImplementationAuthDocs[];
  currentAuthDocsForm: Partial<AuthDocsForm>;
  currentAuthDocsDetails: AuthDocsDetails;
  apiResponseAuthDocs: ImplementationAuthDocs;
  authDocsWindowObjectReference: Array<any>;

  // common state for most/all slices
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
  applicationStatus: ApplicationStatusState;
}

const initialState: ImplementationAuthDocsState = {
  authDoc: emptyAuthDocsType,
  authDocs: [],
  apiResponseAuthDocs: emptyAuthDocsType,
  currentAuthDocsForm: blankAuthDocsForm,
  currentAuthDocsDetails: blankAuthDocsDetails,
  authDocsWindowObjectReference: [],

  apiStatus: saveCallbackStatus.none,
  errorMessage: "",
  isLoading: false,
  applicationStatus: emptyApplicationStatus,
};

const authDocsSlice = createSlice({
  name: " authDocsSliceContext",
  initialState,
  reducers: {
    clearAuthDocsForm: (state) => {
      state.currentAuthDocsForm = blankAuthDocsForm;
    },
    clearStatus: (state) => {
      state.apiStatus = saveCallbackStatus.none;
      state.errorMessage = "";
    },
    clearAuthDocs: (state) => {
      state.authDocs = [];
    },
    resetAuthDocsContext: (state) => {
      return initialState;
    },
    resetAddAuthDocs: (state) => {
      state.isLoading = false;
    },
    addAuthDocsWindowObjectReference(state, action: PayloadAction<any>) {
      state.authDocsWindowObjectReference = [action.payload];
    },
    resetAuthDocsWindowObjectReference(state) {
      state.authDocsWindowObjectReference =
        initialState.authDocsWindowObjectReference;
    },
    showAuthDocsErrorStatus(state, action: PayloadAction<string>) {
      state.applicationStatus = GetError(action.payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getClientAuthDocs.fulfilled, (state, action) => {
      state.authDocs = action.payload.apiResponseAuthDocs;
      state.isLoading = false;
    });
    builder.addCase(getClientAuthDocs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientAuthDocs.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getClientAuthDoc.fulfilled, (state, action) => {
      state.currentAuthDocsForm = action.payload.authDocsForm;
      state.currentAuthDocsDetails = action.payload.authDocsDetails;
      state.apiResponseAuthDocs = action.payload.apiResponseAuthDocs;
    });
    builder.addCase(getClientAuthDoc.pending, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getClientAuthDoc.rejected, (state) => {
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(saveAuthDocs.fulfilled, (state, action) => {
      state.authDocs = action?.payload?.apiResponseAuthDocs;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveAuthDocs.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveAuthDocs.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(deleteAuthDocs.pending, (state, action) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(deleteAuthDocs.fulfilled, (state, action) => {
      state.authDocs = state.authDocs.filter(
        (authDoc) =>
          authDoc?.authorizationDocumentsId !==
          action.payload.authorizationDocumentsId
      );
    });
    builder.addCase(deleteAuthDocs.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(sendAuthDocsTest.pending, (state, action) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(sendAuthDocsTest.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
  },
});

export const {
  clearAuthDocsForm,
  resetAddAuthDocs,
  clearStatus,
  addAuthDocsWindowObjectReference,
  resetAuthDocsWindowObjectReference,
  showAuthDocsErrorStatus,
} = authDocsSlice.actions;

export default authDocsSlice.reducer;
