import React from "react";
import RiskClassSettingConfigGrid from "../risk-class-setting/risk-class-setting-config";
import RiskThresholdConfigGrid from "../risk-thresholds/risk-threshold-config-grid";

function RiskAssessmentMasterListView() {
  return (
    <>
      <RiskThresholdConfigGrid />
      <RiskClassSettingConfigGrid />
    </>
  );
}

export default RiskAssessmentMasterListView;
