import { ChangeEvent, KeyboardEventHandler } from "react";
import { CircularProgress, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment } from "@mui/material";

interface OpenSearchInputProps {
  inputValue: string;
  isLoading: boolean;
  handleInputChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleKeyUp: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const OpenSearchInput = (props: OpenSearchInputProps) => {
  const { inputValue, isLoading, handleInputChange, handleKeyUp } = props;

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} direction="column">
        <TextField
          label="Search Patient"
          type="text"
          placeholder="Enter Search Text"
          sx={{ width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: isLoading && (
              <InputAdornment position="end">
                <CircularProgress style={{ padding: "5px" }} />
              </InputAdornment>
            ),
            onKeyUp: handleKeyUp,
          }}
          value={inputValue}
          name="openSearch"
          onChange={handleInputChange}
          onBlur={() => {}}
        />
      </Grid>
    </Grid>
  );
};

export default OpenSearchInput;
