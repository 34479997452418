import { UserInfoClient } from "./user-info-client";

// subset of full user info, for display in the users filter result table
export class FilteredUserInfo {
  userId!: number;
  fullName!: string;
  email!: string;
  status!: string;
  domainAccess!: string;
  hasFacilityLvlAccess!: boolean;
  userRole!: string;
  clients!: UserInfoClient[];
  isActive?: boolean;
  cognitoStatus?: boolean;
  hasAllTimingRisks?: boolean;
}
