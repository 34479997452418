import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import {
  InputAdornment,
  TextField as MuiTextField,
} from '@mui/material';
import {
  FieldArray,
  FormikContextType,
  FormikProvider,
} from 'formik';
import {
  Button,
  Checkbox,
  LoadingOverlay,
} from '@finpay-development/shared-components';
import DialogActions from '@mui/material/DialogActions';
import React, {ChangeEvent, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../shared/state/root-reducer';
import {
  Grid, Paper,
  TablePagination,
  Typography,
} from '@mui/material';
import {
  DEFAULT_ROWS_PER_PAGE,
  FacilityPayorFormObj, FacilityPlanFormObj, PayorPlanActions,
} from './preselect-payors-plan';
import {
  PayerPlans,
  PayerRow,
} from '../../../../../admin-configuration/models/payers';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash/fp';

interface AddPayorsPlanModalProps {
  open: boolean;
  currentFacilityId: number;
  preselectPayorPlanFormik: FormikContextType<any>;
  initFacilityPayorAndPlans: Record<string, any>;
  handleModalActionButtonOnClick: (value: boolean) => void;
}

export const AddPayorsPlanModal = ({
                                     open,
                                     currentFacilityId,
                                     preselectPayorPlanFormik,
                                     initFacilityPayorAndPlans,
                                     handleModalActionButtonOnClick,
                                   }: AddPayorsPlanModalProps) => {

  const {payors} = {
    payors: useSelector(
        (state: RootState) => state.adminContext.adminConfigurationContext?.payers,
    ),
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [searchKeyWord, setSearchKeyWord] = useState<string>('');
  const [filteredPayor, setFilteredPayor] = useState<PayerRow[]>([]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target?.value;
    debounceFilter(searchText);
    setSearchKeyWord(searchText);
  };

  const debounceFilter = _.debounce(2000, (searchText: string) => {

    if (searchText.trim() === '') {

      setFilteredPayor([]);

    } else {

      const filteredPayorsArr = payors.filter((payor) => {
        return (
            payor.payorName &&
            payor.payorName.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setFilteredPayor(filteredPayorsArr);
      setPage(0);
    }
  });

  return (
      <Dialog
          className="modal"
          scroll="body"
          open={open}
          fullWidth={true}
          maxWidth="md"
          test-id="add-payors-plans-modal"
      >
        <DialogTitle>
          Add Payors and Plans
        </DialogTitle>
        <Grid container>
          <MuiTextField
              name="modal-search"
              placeholder="Search by Payor Name"
              value={searchKeyWord}
              onChange={handleSearch}
              inputProps={{
                'test-id': 'modal-payors-plans-search',
              }}
              InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon/>
                    </InputAdornment>
                ),
              }}
              style={{width: '100%', padding: '0rem 3rem'}}
          />
        </Grid>
        <DialogContent>
          <Paper
              {...filteredPayor.length > 0 && {variant: 'outlined'}}
              style={{padding: filteredPayor.length > 0 ? '20px' : '0px'}}
          >
            {filteredPayor.length > 0 ? (
                <form>
                  {currentFacilityId && (
                      <FormikProvider value={preselectPayorPlanFormik}>
                        <FieldArray
                            name={`${currentFacilityId}`}
                            render={arrayHelpers => {

                              if (
                                  Array.isArray(
                                      initFacilityPayorAndPlans?.[currentFacilityId]) &&
                                  payors.length > 0
                              ) {

                                return filteredPayor.slice!(page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage).
                                    map((payor: PayerRow) => {

                                      const initFacilityPayor = initFacilityPayorAndPlans[currentFacilityId]?.find(
                                          (payorAndPlans: FacilityPayorFormObj) => {
                                            return payorAndPlans?.cfgPayorId ===
                                                payor?.payorId;
                                          });

                                      const currentPayorIndex = Array.isArray(
                                          preselectPayorPlanFormik.values?.[currentFacilityId]) ?
                                          preselectPayorPlanFormik.values[currentFacilityId].findIndex(
                                              (selectedPayor: FacilityPayorFormObj) => {
                                                return selectedPayor.cfgPayorId ===
                                                    payor.payorId;
                                              }) : -1;

                                      const selectedFacilityPayor = preselectPayorPlanFormik.values?.[currentFacilityId]?.[currentPayorIndex];

                                      return (
                                          <div
                                              key={`modal-payor-${payor.payorId}`}
                                              test-id="modal-payor-plan-group">
                                            <Typography
                                                test-id={`modal-preselect-payor-plans-payor-${payor.payorId}`}>
                                              {payor.payorName}
                                            </Typography>
                                            {payor.payorPlans.map(
                                                (plan: PayerPlans) => {

                                                  const currentPlanIndex = Array.isArray(
                                                      selectedFacilityPayor?.facilityPayorPlans) ?
                                                      selectedFacilityPayor?.facilityPayorPlans.findIndex(
                                                          (selectedPlan: FacilityPlanFormObj) => {
                                                            return selectedPlan.cfgPayorPlanId ===
                                                                plan.payorPlanId;
                                                          }) : -1;

                                                  const selectedFacilityPlan = selectedFacilityPayor?.facilityPayorPlans?.[currentPlanIndex];

                                                  const initFacilityPlan = Array.isArray(
                                                      initFacilityPayor?.facilityPayorPlans) ?
                                                      initFacilityPayor.facilityPayorPlans.find(
                                                          (facilityPlan: FacilityPlanFormObj) => {
                                                            return facilityPlan?.cfgPayorPlanId ===
                                                                plan?.payorPlanId;
                                                          }) : false;

                                                  /*
                                                  * Disabling Plan inputs if
                                                  * 1) Plan has already been saved
                                                  * */

                                                  return (
                                                      <FieldArray
                                                          key={`modal-plan-field-arr-${plan.payorPlanId}`}
                                                          name={`${currentFacilityId}.${currentPayorIndex}.facilityPayorPlans`}
                                                          render={arrayHelpersInner => {
                                                            return (
                                                                <div
                                                                    style={{marginLeft: '1rem'}}>
                                                                  <Checkbox
                                                                      checked={!!selectedFacilityPlan}
                                                                      disabled={!!initFacilityPlan}
                                                                      id={`modal-plan-${plan.payorPlanId}`}
                                                                      name={`[${currentFacilityId}][${currentPayorIndex}].facilityPayorPlans[${currentPlanIndex}]`}
                                                                      label={plan.planName}
                                                                      onChange={(event: ChangeEvent<HTMLInputElement>) => {

                                                                        if (event?.target?.checked) {

                                                                          const planToInclude = {
                                                                            clientFacilityId: currentFacilityId,
                                                                            cfgPayorPlanId: plan.payorPlanId,
                                                                            facilityPayorPlanCode: null,
                                                                            facilityPayorPlanName: null,
                                                                            action: PayorPlanActions.Create,
                                                                          };

                                                                          if (currentPayorIndex <
                                                                              0) {
                                                                            //Payor is not selected, we need to add payor as well
                                                                            arrayHelpers.unshift(
                                                                                {
                                                                                  clientFacilityId: currentFacilityId,
                                                                                  cfgPayorId: payor.payorId,
                                                                                  facilityPayorCode: null,
                                                                                  facilityPayorName: null,
                                                                                  inNetwork: false,
                                                                                  action: PayorPlanActions.Create,
                                                                                  facilityPayorPlans: [planToInclude],
                                                                                });
                                                                          } else {

                                                                            arrayHelpersInner.push(
                                                                                {
                                                                                  ...planToInclude,
                                                                                  facilityPayorId: preselectPayorPlanFormik.values[currentFacilityId][currentPayorIndex].facilityPayorId,
                                                                                });

                                                                            arrayHelpers.move(
                                                                                currentPayorIndex,
                                                                                0);

                                                                          }
                                                                        } else {

                                                                          if (selectedFacilityPayor.facilityPayorPlans.length <=
                                                                              1) {

                                                                            //If we are removing the last remaining plan,
                                                                            //we need to remove the payor as well

                                                                            arrayHelpers.remove(
                                                                                currentPayorIndex);

                                                                          } else {
                                                                            arrayHelpersInner.remove(
                                                                                currentPlanIndex);
                                                                          }
                                                                        }
                                                                      }}
                                                                  />
                                                                </div>
                                                            );
                                                          }}/>
                                                  );
                                                })
                                            }
                                          </div>
                                      );
                                    });
                              } else {
                                return ([
                                  <div key={`modal-payor-0`}>
                                    <LoadingOverlay whiteBackground={true}/>
                                  </div>,
                                ]);
                              }
                            }}
                        />
                      </FormikProvider>
                  )}
                </form>
            ) : (
                <div key="empty-key">
                  <Typography
                      style={{
                        textAlign: 'center',
                        ...searchKeyWord.length > 0 && {padding: '3rem 0'},
                      }}
                  >
                    <b>{searchKeyWord.length === 0 ?
                        '' :
                        'No Matching Results To Show'}</b>
                  </Typography>
                </div>
            )}
          </Paper>
          <TablePagination
              component="div"
              rowsPerPageOptions={[
                5,
                10,
                15,
              ]}
              count={filteredPayor.length!}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={'View'}
              onPageChange={(event: unknown, newPage: number) => {
                setPage(newPage);
              }}
              onRowsPerPageChange={(
                  event: ChangeEvent<HTMLInputElement>,
              ) => {
                setRowsPerPage(+event.target.value);
                setPage(0);
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button
              onClick={() => handleModalActionButtonOnClick(false)}
              test-id="modal-payor-plan-exit-btn"
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
  );
};