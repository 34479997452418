import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from "@mui/material";
import { TextField, DialogActionButton } from '@finpay-development/shared-components';
import React, { useState } from 'react';
import { Scholarship } from "../../models/scholarship";
import { showErrorStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { useDispatch, useSelector } from "react-redux";
import { clearStatus } from "../../state/admin-configuration-slice";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { Utils } from "../../../shared/utils";
import { configNewScholarship, configSaveScholarship } from "../../state/admin-configuration-thunk";


interface ScholarshipProps {
    open: boolean;
    isEdit: boolean;
    scholarshipItem: Scholarship;

    handleScholarshipCancel: () => void;
    handleScholarshipSubmit: (isEdit: boolean) => void;
  }


export function ScholarshipModal(props: ScholarshipProps) {
    const {
      open,
      isEdit,
      scholarshipItem,
      handleScholarshipCancel,
      handleScholarshipSubmit,
    } = props;

    const saveStatus = useSelector(
      (state: RootState) =>
          state.adminContext.adminConfigurationContext.modalSaveStatus
    );

    const isSaveSuccessful = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.isSaveSuccessful
    );

    const errorMessage = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.errorMessage
    );

    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const paramId: number = -2;

    const initialScholarship: Scholarship = {
      paramId: paramId,
      clientScholarshipLevelsId: scholarshipItem.clientScholarshipLevelsId,
      clientScholarshipLevelDesc: scholarshipItem.clientScholarshipLevelDesc,
      clientId: scholarshipItem.clientId,
      scholarshipPercentage: scholarshipItem.scholarshipPercentage,
      fplApprovalThreshold: scholarshipItem.fplApprovalThreshold,
      incomeThreshold: scholarshipItem.incomeThreshold,
    };

    const validationSchema = Yup.object({
      clientScholarshipLevelDesc: Yup.string().required("Scholarship Name is required"),
      scholarshipPercentage: Yup.number().required("Scholarship Percent is required"),
      fplApprovalThreshold: Yup.number().required("FPL Threshold is required"),
      incomeThreshold: Yup.number().required("Expense Income Threshold is required"),
    });

    const scholarshipFormik = useFormik({
      initialValues: initialScholarship,
      enableReinitialize: true,
      onSubmit: () => {},
      validationSchema: validationSchema,
      validate: checkIfIsValid
    });

    function checkIfIsValid(value: {}) {
      validationSchema
        .validate(value)
        .then(() => {
          setEnableSaveButton(true);
        })
        .catch((err) => {
          setEnableSaveButton(false);
        });
    }

    async function handleSave() {
      const requestBody: Scholarship = {
        paramId: paramId,
        clientId: scholarshipItem.clientId,
        clientScholarshipLevelsId: scholarshipItem.clientScholarshipLevelsId,
        clientScholarshipLevelDesc: scholarshipFormik.values.clientScholarshipLevelDesc,
        scholarshipPercentage: scholarshipFormik.values.scholarshipPercentage,
        fplApprovalThreshold: scholarshipFormik.values.fplApprovalThreshold,
        incomeThreshold: scholarshipFormik.values.incomeThreshold,
      }

      if (isEdit) {
        await dispatch(configSaveScholarship(requestBody));
      } else {
        await dispatch(configNewScholarship(requestBody));
      }
    }

    function handleSaveCallback() {
        if (isSaveSuccessful) {
            handleScholarshipSubmit(isEdit);
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    function handleCancelCallback() {
        handleScholarshipCancel();
    }

    return (
        <Dialog
          className="modal"
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="body"
        >
          <DialogTitle>{isEdit ? "Edit" : "Add"} Scholarship</DialogTitle>
          <DialogContent>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <TextField
                    name="clientScholarshipLevelDesc"
                    label="Scholarship Level"
                    variant="outlined"
                    value={scholarshipFormik.values.clientScholarshipLevelDesc}
                    onChange={scholarshipFormik.handleChange}
                    onBlur={scholarshipFormik.handleBlur}
                    placeholder=""
                    error={
                      scholarshipFormik.touched.clientScholarshipLevelDesc &&
                      scholarshipFormik.errors.clientScholarshipLevelDesc
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4} className="mt-4">
                  <TextField
                    label="Scholarship %"
                    type="number"
                    value={scholarshipFormik.values.scholarshipPercentage}
                    name="scholarshipPercentage"
                    onChange={(e: Event) => {
                      if(Utils.handleNumericValues(e))
                        scholarshipFormik.handleChange(e)
                    }}
                    onBlur={scholarshipFormik.handleBlur}
                    placeholder=""
                    endAdornment="%"
                    error={
                      scholarshipFormik.touched.scholarshipPercentage &&
                      scholarshipFormik.errors.scholarshipPercentage
                    }
                  />
                </Grid>
                <Grid item xs={4} className="mt-4">
                  <TextField
                    label="FPL Threshold"
                    type="number"
                    value={scholarshipFormik.values.fplApprovalThreshold}
                    name="fplApprovalThreshold"
                    onChange={(e: Event) => {
                      if(Utils.handleNumericValues(e))
                        scholarshipFormik.handleChange(e)
                    }}
                    onBlur={scholarshipFormik.handleBlur}
                    placeholder=""
                    endAdornment="%"
                    error={
                      scholarshipFormik.touched.fplApprovalThreshold &&
                      scholarshipFormik.errors.fplApprovalThreshold
                    }
                  />
                </Grid>
                <Grid item xs={4} className="mt-4">
                  <TextField
                    label="Expense: Income Threshold"
                    type="number"
                    value={scholarshipFormik.values.incomeThreshold}
                    name="incomeThreshold"
                    onChange={(e: Event) => {
                      if(Utils.handleNumericValues(e))
                        scholarshipFormik.handleChange(e)
                    }}
                    onBlur={scholarshipFormik.handleBlur}
                    placeholder=""
                    endAdornment="%"
                    error={
                      scholarshipFormik.touched.incomeThreshold &&
                      scholarshipFormik.errors.incomeThreshold
                    }
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
          <DialogActionButton
            isEnabled={enableSaveButton}
            savebuttonText={isEdit ? "Update" : "Save"}
            saveStatus={saveStatus}
            spinnerLeftPosition={5}
            executeSave={handleSave}
            handleCallbackSave={handleSaveCallback}
            handleCallbackCancel={handleCancelCallback}
          />
        </DialogActions>
    </Dialog>
  );
}