export interface ExternalAccount {
  externalAccountId: number | string;
  externalStatus: string;
}

export interface PaynowExternalAccount {
  externalEventTimestamp: number;
  externalAccountNotPresent?: boolean;
  externalAccountId: string;
  externalStatus: string;
}

export const emptyExternalAccount: ExternalAccount = {
  externalAccountId: 0,
  externalStatus: ""
}
