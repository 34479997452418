import { UserRolePage } from "./user-role-page";
import {
  convertedPatientFilter,
  preConvertedPatientFilter,
} from '../../patient/components/models/patient-filter';

export class UserRole {
  userRoleId?: number;
  roleName!: string;
  roleDesc!: string;
  roleType!: string;
  isActive!: boolean;
  userRolePages!: UserRolePage[];
  patientDashboardFilters!: {
    preConvertedFilterFormValues: Partial<preConvertedPatientFilter>,
    convertedFilterFormValues: Partial<convertedPatientFilter>
  } | null;
}

export const emptyUserRole: UserRole  = {
  roleName: "",
  roleDesc: "",
  roleType: "",
  isActive: true,
  userRolePages: [],
  patientDashboardFilters: null,
}

export class UserRoleAction {
  id?: number;
  value!: string;
}

export class UserRoleItem {
  id?: number;
  pageName!: string;
  accessRights!: string;
  actions!: UserRoleAction[];
}

export class UserRoleViewModel {
  userRoleId?: number;
  roleName!: string;
  roleDesc!: string;
  roleType!: string;
  isActive!: boolean;
  navigation!: UserRoleItem[];
}

export class UserRoleListViewModel {
  internalUserRoles!: UserRoleViewModel[];
  externalUserRoles!: UserRoleViewModel[];
}