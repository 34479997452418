export interface LoadingState {
    getVobPatient: boolean,
    searchPatient: boolean,
    capacityToPay: boolean,
    createPatientIoc: boolean,
    nationalPovertyThreshold: boolean,
    saveFinPassPatient: boolean,
}

export const emptyLoadingState: LoadingState = {
    getVobPatient: false,
    searchPatient: false,
    capacityToPay: false,
    createPatientIoc: false,
    nationalPovertyThreshold: false,
    saveFinPassPatient: false,
}