import { Checkbox, DialogActionButton, Button } from '@finpay-development/shared-components';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Divider, InputAdornment, TableContainer, TextField, Typography, Checkbox as MuiCheckbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  payoutStatusDropdown,
  ReportsFiltersInitialValues,
} from '../../../implementation-specialist/models/reportsFilters';
import { clearStatus } from '../../../patient/state/patient-slice';
import { showErrorStatus } from '../../../security/state/user-slice';
import { reportsType } from '../../../shared/enums';
import { ChannelInfo } from '../../models/channel-payments';
import { settlementDetailFilters } from '../../../shared/Mocks/reportsFilters';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { adminUserHelper } from '../../services/admin-user-service';
import { handleGetDetailedFilteredReports, handleGetDetailedSettlementReport, handleGetFilteredReports } from '../../state/users/admin-thunk';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface ReportFilterProps {
  headersLength?: number;
  transactionNumber?: string;
  title: string;
  open: boolean;
  handleFilterReportCancel: () => void;
  handleFilterReportSubmit: () => void
  availableChannelFilters?: ChannelInfo[] | any;
  filterByPayoutStatus?: (v: [string]) => void;
}

export function ReportFilterModal(props: ReportFilterProps) {
  const {
    headersLength,
    transactionNumber,
    title,
    open,
    handleFilterReportCancel,
    handleFilterReportSubmit,
    availableChannelFilters,
    filterByPayoutStatus,
  } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disableAccountHolderName, setDisableAccountHolderName] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [disablePatientName, setDisablePatientName] = useState(false)
  const [useBillingName, setUseBillingName] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const stateFields = {
    saveStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext.modalSaveStatus
    ),
    reportTypeValues: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.reportTypeValues
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext.errorMessage
    ),
    reportFilters: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.reportFilters
    ),
    patientsNames: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.patientsNames
    ),
    accountHoldersNames: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.accountHoldersNames
    ),
    collabIds: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.collabIds
    ),
    clientNames: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.clientNames
    ),
    facilityNames: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.facilityNames
    ),
    detailedPatientsNames: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.detailedPatientsNames
    ),
    detailedAccountHoldersNames: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.detailedAccountHoldersNames
    ),
    detailedClientNames: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.detailedClientNames
    ),
    detailedFacilityNames: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.detailedFacilityNames
    ),
    paymentChannels: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.paymentChannels
    )
  }

  const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  let {
    saveStatus,
    reportTypeValues,
    errorMessage,
    reportFilters,
    patientsNames,
    accountHoldersNames,
    collabIds,
    clientNames,
    facilityNames,
    detailedPatientsNames,
    detailedAccountHoldersNames,
    paymentChannels
  } = stateFields

  if(title === reportsType.SettlementDetail.toString()){
    reportTypeValues = settlementDetailFilters;
  }

  useEffect(() => {
    if ( (title === reportsType.SettlementDetail.toString() && headersLength === 19)) {
      setUseBillingName(true);
    } else {
      setUseBillingName(false);
    }
  }, [title, headersLength])

  function handleSave() {
    const formValues = formRef.current.values;
    let detailedReportFilter:any = {
      clients:[]
    }
    reportTypeValues.forEach((reportType:any)=>{
      const objectName = reportType.objectName
      if(formValues[reportType.objectName].length !== 0){
        detailedReportFilter[objectName]= formValues[reportType.objectName]
      }
    })
    let paymentWorkflowStatusIds: number[] = []
    let paymentWorkflowSubStatusIds: number[] = []
    let acctHolderWorkflowStatusIds: number[] = []
    let acctHolderWorkflowSubStatusIds: number[] = []
    let payorRiskIds: number[] = []
    let timingRiskIds: number[] = []
    let initialRiskIds: number[] = []
    let modifiedRiskIds: number[] = []
    let convertedRiskIds: number[] = []
    let finalRiskIds: number[] = []

    if(formValues.patientName === "") formValues.patientName = null
    if(formValues.accountHolderName === "") formValues.accountHolderName = null
    if(formValues.collabId === "") formValues.collabId = null
    if(formValues.clientName === "") formValues.clientName = null;
    if(formValues.facilityName === "") formValues.facilityName = null;
    if(formValues.accountHolderStatus.length > 0) {
      const mappedObject = adminUserHelper.mapWorkflowIds(acctHolderWorkflowStatusIds, acctHolderWorkflowSubStatusIds, formValues.accountHolderStatus)
      acctHolderWorkflowStatusIds = mappedObject.workFlowStatusIds
      acctHolderWorkflowSubStatusIds = mappedObject.workFlowSubStatusIds
    }
    if(formValues.paymentWorkflowStatus.length > 0){
      const mappedObject = adminUserHelper.mapWorkflowIds(paymentWorkflowStatusIds, paymentWorkflowSubStatusIds, formValues.paymentWorkflowStatus)
      paymentWorkflowStatusIds = mappedObject.workFlowStatusIds
      paymentWorkflowSubStatusIds = mappedObject.workFlowSubStatusIds
    }
    if (formValues.payoutStatus?.length > 0) {
      detailedReportFilter.payoutStatus = formValues.payoutStatus;
    }

    if(formValues.riskClass.length > 0 ){
      const mappedObject = adminUserHelper.mapRiskIds(payorRiskIds, timingRiskIds, formValues.riskClass)
      payorRiskIds = mappedObject.payorRiskIds
      timingRiskIds = mappedObject.timingRiskIds
    }

    if(formValues.initialRiskIds > 0 || formValues.modifiedRiskIds > 0 || formValues.convertedRiskIds > 0 || formValues.finalRiskIds > 0){
      const mappedObject = adminUserHelper.mapAllRiskIds(initialRiskIds, modifiedRiskIds, convertedRiskIds, finalRiskIds, formValues.initialRiskIds, formValues.modifiedRiskIds, formValues.convertedRiskIds, formValues.finalRiskids)
      initialRiskIds = mappedObject.initialRiskIds
      modifiedRiskIds = mappedObject.modifiedRiskIds
      convertedRiskIds = mappedObject.convertedRiskIds
      finalRiskIds = mappedObject.finalRiskIds
    }

    detailedReportFilter.reportType = reportFilters.reportType
    detailedReportFilter.startDate = reportFilters.startDate
    detailedReportFilter.endDate = reportFilters.endDate
    detailedReportFilter.patientName = formValues.patientName
    detailedReportFilter.accountHolderName = formValues.accountHolderName

    if(title === reportsType.SettlementDetail.toString()){
      detailedReportFilter.reportType = reportsType.SettlementDetail.toString()
      detailedReportFilter.groupTransactionNumber = transactionNumber
      detailedReportFilter.channelNames = formValues.channelNames;
    }
    if(title === reportsType.RiskClassReports.toString()){
      detailedReportFilter.reportType = reportsType.RiskClassReports.toString();
      detailedReportFilter.patientName = formValues.patientName;
      detailedReportFilter.clientName = formValues.clientName;
      detailedReportFilter.facilityName = formValues.facilityName;
    }

    
    if(detailedReportFilter.clients.length === 0){
      detailedReportFilter.clients = null
    }
    setDisableAccountHolderName(false)
    setDisablePatientName(false)

    if(detailedReportFilter.clients?.length === 1 && detailedReportFilter.clients[0].clientId === 0 ){
      detailedReportFilter.clients = null
    }

    if(paymentWorkflowStatusIds.length > 0 && paymentWorkflowSubStatusIds.length > 0){
      detailedReportFilter.paymentWorkflowStatusIds = paymentWorkflowStatusIds
      detailedReportFilter.paymentWorkflowSubStatusIds = paymentWorkflowSubStatusIds
    }

    if(acctHolderWorkflowStatusIds.length > 0 && acctHolderWorkflowSubStatusIds.length > 0){
      detailedReportFilter.acctHolderWorkflowStatusIds = acctHolderWorkflowStatusIds
      detailedReportFilter.acctHolderWorkflowSubStatusIds = acctHolderWorkflowSubStatusIds
    }

    if(payorRiskIds.length > 0 && timingRiskIds.length > 0){
      detailedReportFilter.payorRiskIds = payorRiskIds
      detailedReportFilter.timingRiskIds = timingRiskIds
    }
    if(initialRiskIds.length > 0){ detailedReportFilter.initialReportIds = initialRiskIds}
    if(modifiedRiskIds.length > 0){ detailedReportFilter.modifiedReportIds = modifiedRiskIds}
    if(convertedRiskIds.length > 0){ detailedReportFilter.convertedReportIds = convertedRiskIds}
    if(finalRiskIds.length > 0){ detailedReportFilter.finalReportIds = finalRiskIds}

    //Remove unnecessary properties from the detailedReportFilter object
    delete detailedReportFilter.accountHolderStatus
    delete detailedReportFilter.paymentWorkflowStatus
    delete detailedReportFilter.riskClass

    switch (title) {
      case reportsType.SummaryTransactions.toString():
        detailedReportFilter.reportType = reportsType.SummaryTransactions.toString();
        detailedReportFilter.clientId = reportFilters.clientId;
        detailedReportFilter.facilityId = reportFilters.facilityId;
        detailedReportFilter.includeFacilityTotals = reportFilters.includeFacilityTotals;
        detailedReportFilter.paymentChannel = formValues.channelNames;
        delete detailedReportFilter.patientName;
        delete detailedReportFilter.accountHolderName;
        delete detailedReportFilter.clients;
        delete detailedReportFilter.payoutStatus;
        dispatch(handleGetFilteredReports(detailedReportFilter));
        break;
      case reportsType.MergedTransactions.toString():
        detailedReportFilter.reportType = reportsType.MergedTransactions.toString();
        detailedReportFilter.clientId = reportFilters.clientId;
        detailedReportFilter.facilityId = reportFilters.facilityId;
        detailedReportFilter.paymentChannel = formValues.channelNames;
        delete detailedReportFilter.clients;
        delete detailedReportFilter.payoutStatus;
        dispatch(handleGetFilteredReports(detailedReportFilter));
        break;
      case reportsType.AutomatedPayment.toString():
        detailedReportFilter.reportType = reportsType.AutomatedPayment.toString();
        detailedReportFilter.clientId = reportFilters.clientId;
        detailedReportFilter.facilityId = reportFilters.facilityId;
        detailedReportFilter.collabId = formValues.collabId
        delete detailedReportFilter.clients;
        delete detailedReportFilter.payoutStatus;
        dispatch(handleGetFilteredReports(detailedReportFilter));
        break;
      case reportsType.SettlementSummary.toString():
        filterByPayoutStatus && filterByPayoutStatus(formValues.payoutStatus);
        break;
      case reportsType.SettlementDetail.toString():
        dispatch(handleGetDetailedSettlementReport(detailedReportFilter));
        break;
      default:
        dispatch(handleGetDetailedFilteredReports(detailedReportFilter));
        break;
    }
    handleFilterReportSubmit();
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleFilterReportSubmit();
      setEnableSaveButton(false);

    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleFilterReportCancel();
  }

  const initialValues = {
    patientName:"",
    accountHolderName: "",
    collabId: "",
    clientName: "",
    facilityName: "",
    planType:[],
    sentToProvider: [],
    automatedPaymentStatus: [],
    transactionType:[],
    paymentTypeIds:[],
    patientFinancialStatus:[],
    paymentWorkflowStatus:[],
    levelOfCare:[],
    riskClass:[],
    initialRiskClasses:[],
    modifiedRiskClasses:[],
    convertedRiskClasses:[],
    finalRiskClasses:[],
    accountHolderStatus:[],
    payoutStatus: ['Completed'],
    channelNames: []
  } as ReportsFiltersInitialValues;

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const reportTypesCheckBox = (formik:any) => {

    const handleSelectedAll = (type: any) => {
      let objectName: any = ""
      for (const [key, value] of Object.entries(type)) {
        if (key === "objectName") objectName = value;
      }
      Object.entries(type).forEach((entry: any) => {
        if (entry[0] !== "name" || entry[0] !== "objectName") {
          if (!formik.values[objectName].includes(entry[1].value) && entry[1].value) {
            formik.setFieldValue(entry[0], true);
            formik.values[objectName].push(entry[1].value);
            return true;
          }
        }
        return false;
      });
    };

    const handleUnSelectedAll = (type: any) => {
      let objectName: any = ""
      for (const [key, value] of Object.entries(type)) {
        if (key === "objectName") objectName = value;
      }
      Object.entries(type).forEach((entry: any) => {
        if (entry[0] !== "name" || entry[0] !== "objectName") {
          formik.setFieldValue(entry[0], false);
          formik.values[objectName] = [];
          return true;
        }
        return false;
      });
    };


    return reportTypeValues.map(type => {
      let objectName = ""
      for (const [key, value] of Object.entries(type)) {
        if(key === "objectName") objectName = value
      }
      // Entry[0] is equivalent to "key" and Entry[1] is equivalent to "value"
      return Object.entries(type).map((entry, index) => {
        if(entry[0] === "name"){
          return (
            <div>
              {entry[1] === "Plan Type" &&
              title === reportsType.PatientFinancialClearance.toString() ? (
                <Typography variant="h2" className="my-5">
                  Payment Type
                </Typography>
              ) : (
                <Typography variant="h2" className="my-5">
                  {entry[1]}
                </Typography>
              )}
              <Button type="text" paddingLeft={0} onClick={() => handleSelectedAll(type)}>
                Select All
              </Button>
              <Button type="text" paddingLeft={0} onClick={() => handleUnSelectedAll(type)}>
                Unselect All
              </Button>
            </div>
          );
        }
        if(entry[0] !== "objectName" ) {
          return(
            <Checkbox
              key={`${objectName}${index}`}
              id={`${objectName}${entry[0]}`}
              checked={formik.values[objectName].includes(entry[1].value)}
              label={entry[1].label}
              name={entry[0]}
              onChange={(e: any)=>{
                formik.handleChange(e);
                if(e.target.checked){
                  formik.values[objectName].push(entry[1].value)
                } else {
                  const index = formik.values[objectName].indexOf(entry[1].value)
                  formik.values[objectName].splice(index,1)
                }
              }}
            />
          )
        }
        return <></>;
      })
    })
  }
  
  const channelNameCheckboxMapper = (
    filterList: ChannelInfo[],
    formik:any
  ) => {
  
    const handleSelectedAll = () => {
      filterList?.map((filter: any) => {
        if (!formik.values["channelNames"].includes(filter.paymentChannelName)) {
          formik.setFieldValue(filter.paymentChannelName, true);
          formik.values["channelNames"].push(
            filter.paymentChannelName
          );
          return true;
        }
        return false;
      })
    };

    const handleUnSelectedAll = () => {
      filterList?.map((filter: any) => {
        formik.setFieldValue(filter.paymentChannelName, false);
        formik.values["channelNames"] = [];
        return true;
      })
    };

    return (
      <>
        <Button type="text" paddingLeft={0} onClick={handleSelectedAll}>
          Select All
        </Button>
        <Button type="text" paddingLeft={0} onClick={handleUnSelectedAll}>
          Unselect All
        </Button>
        <TableContainer style={{ maxHeight: 200 }}>
          {filterList?.map((filter: any, index) => (
            <div
              className={filter.shortName}
              key={`${index}${filter.shortName}`}
            >
              <Checkbox
                key={`${filter.shortName}`}
                id={`${filter.shortName}`}
                checked={formik.values["channelNames"].includes(
                  filter.paymentChannelName
                )}
                label={filter.paymentChannelName}
                name={filter.paymentChannelName}
                onChange={(e: any) => {
                  formik.handleChange(e);
                  if (e.target.checked) {
                    formik.values["channelNames"].push(
                      filter.paymentChannelName
                    );
                  } else {
                    const index = formik.values["channelNames"].indexOf(
                      filter.paymentChannelName
                    );
                    formik.values["channelNames"].splice(index, 1);
                  }
                }}
              />
            </div>
          ))}
        </TableContainer>
      </>
    );
  };

  return (
    <Dialog
      className="modal user-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{title} Filters</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                {(title === reportsType.SettlementDetail.toString() ||
                  title === reportsType.SettlementSummary.toString()) && (
                  <>
                    <Grid item xs={12} className="mt-4">
                      <Typography variant="h2" className="my-2">
                        Payout Statuses
                      </Typography>
                    </Grid>
                    <Grid item xs={12} className="mt-4">
                      <Autocomplete
                        multiple
                        id="payoutStatus"
                        options={payoutStatusDropdown}
                        defaultValue={["Completed"]}
                        onChange={(e, value) => {
                          formik.handleChange(e);
                          formik.setFieldValue("payoutStatus", value);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <MuiCheckbox
                              icon={uncheckedIcon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Payout Status"
                            name="payoutStatus"
                            placeholder="Select a Payout Status"
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {title === reportsType.RiskClassReports.toString() ? (
                  <>
                    <Grid item xs={6} className="mt-4">
                      <Autocomplete
                        id="patientName"
                        options={patientsNames}
                        onChange={(e, value) => {
                          formik.handleChange(e);
                          formik.setFieldValue("patientName", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Patient Name"
                            {...params}
                            name="patientName"
                            placeholder="Search Patients"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {<SearchIcon />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Autocomplete
                        id="clientName"
                        options={clientNames}
                        onChange={(e, value) => {
                          formik.handleChange(e);
                          formik.setFieldValue("clientName", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Client Name"
                            {...params}
                            name="clientName"
                            placeholder="Search Clients"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {<SearchIcon />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} className="mt-4">
                      <Autocomplete
                        disabled={formik.values.facilityName?.length > 0}
                        id="facilityName"
                        options={facilityNames}
                        onChange={(e, value) => {
                          formik.handleChange(e);
                          formik.setFieldValue("facilityName", value);
                        }}
                        renderInput={(params) => (
                          <TextField
                            label="Facility Name"
                            {...params}
                            name="facilityName"
                            placeholder="Search Facilities"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  {<SearchIcon />}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : title === reportsType.SummaryTransactions.toString() ? (
                  <>
                    <Grid container item xs={12} className="mt-2">
                      <Typography variant="h2" className="my-2">
                        Channel Name
                      </Typography>
                      <Grid item xs={12}>
                        <>{channelNameCheckboxMapper(paymentChannels, formik)}</>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {title !== reportsType.SettlementSummary.toString() && (
                      <>
                        <Grid item xs={12} className="mt-4">
                          <Typography variant="h2" className="my-2">
                            Patient and Account Holders
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className="mt-4">
                          <Autocomplete
                            disabled={
                              formik.values.accountHolderName?.length > 0
                            }
                            id="patientName"
                            isOptionEqualToValue={(option, value) =>
                              option === value
                            }
                            options={
                              title === reportsType.SettlementDetail.toString()
                                ? detailedPatientsNames
                                : patientsNames
                            }
                            onChange={(e, value) => {
                              formik.handleChange(e);
                              formik.setFieldValue("patientName", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                label="Patient Name"
                                {...params}
                                name="patientName"
                                placeholder="Search Patients"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {<SearchIcon />}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} className="mt-4">
                          <Autocomplete
                            disabled={formik.values.patientName?.length > 0}
                            id="accountHolderName"
                            options={
                              title === reportsType.SettlementDetail.toString()
                                ? detailedAccountHoldersNames
                                : accountHoldersNames
                            }
                            onChange={(e, value) => {
                              formik.handleChange(e);
                              formik.setFieldValue("accountHolderName", value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                label={
                                  useBillingName
                                    ? "Billing Name"
                                    : "Account Holder Name"
                                }
                                {...params}
                                name="accountHolderName"
                                placeholder={
                                  useBillingName
                                    ? "Search Billing Name"
                                    : "Search Account Holders"
                                }
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {<SearchIcon />}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {title === reportsType.AutomatedPayment.toString() && (
                      <>
                        <Grid item xs={12} className="mt-4">
                          <Typography variant="h2" className="my-2">
                            Client Billing ID
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className="mt-4">
                        <Autocomplete
                          disabled={formik.values.collabId?.length > 0}
                          id="collabId"
                          isOptionEqualToValue={(option, value) =>
                            option === value
                          }
                          options={collabIds}
                          onChange={(e, value) => {
                            formik.handleChange(e);
                            formik.setFieldValue("collabId", value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Client Billing ID"
                              {...params}
                              name="collabId"
                              placeholder="Search Collab Id"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {<SearchIcon />}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      </>
                    )}
                  </>
                )}
                <Divider className="my-5" />
                <Grid container item xs={12} className="mt-2">
                  <Grid item xs={12}>
                    <>{reportTypesCheckBox(formik)}</>
                  </Grid>
                </Grid>
                {title === reportsType.MergedTransactions.toString() && (
                  <Grid container item xs={12} className="mt-2">
                    <Typography variant="h2" className="my-2">
                      Channel Name
                    </Typography>
                    <Grid item xs={12}>
                      <>{channelNameCheckboxMapper(paymentChannels, formik)}</>
                    </Grid>
                  </Grid>
                )}
                {title === reportsType.SettlementDetail.toString() && (
                  <Grid container item xs={12} className="mt-2">
                    <Typography variant="h2" className="my-2">
                      Channel Name
                    </Typography>
                    <Grid item xs={12}>
                      <>{channelNameCheckboxMapper(availableChannelFilters, formik)}</>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={true}
          savebuttonText="Apply"
          saveStatus={saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
