import '../../../../scss/components/_btn-group.scss';
import '../../../../scss/components/_icons.scss';
import '../../../../scss/pages/admin/_admin-clients.scss';

import {
  DatePicker,
  DialogActionButton,
  saveCallbackStatus,
  TextField,
  Toggle,
} from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Box, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import {
  patientAddPaymentMethodACH,
  patientAddPaymentMethodCard,
  patientRecurringPayment,
  patientRecurringPaymentACH,
} from '../../../../shared/validation/schemas';
import StripeBankField from '../../../../stripe/components/StripeBankField';
import StripeCardField from '../../../../stripe/components/StripeCardField';
import { patientHelper, patientService } from '../../../services/patient-service';
import { setRecurringPaymentToken } from '../../../state/patient-slice';
import { addPatientPaymentMethod } from '../../../state/patient-thunk';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PaymentDetail } from '../../models/payment-detail';
import { PAYMENT_METHOD_TYPES, PaymentMethod } from '../../models/payment-method';
import { PAYMENT_TYPES } from '../../models/payment-type';
import {paynowService as paymentService } from '../../../../guest/services/paynow-service';

interface RecurringPaymentModalProps {
  isAccountHolder?: boolean;
  open: boolean;
  handleModalClose: () => void;
}

export function RecurringPaymentModal(props: RecurringPaymentModalProps) {
  const { isAccountHolder, open, handleModalClose } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [isStripeEmpty, setIsStripeEmpty] = useState(true);
  const [isPaymentTypeCard, setIsPaymentTypeCard] = useState(true);
  const [receiptForEmail, setReceiptForEmail] = useState<string>('')

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    stripeCardToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.token
    ),
    stripeBankToken: useSelector(
      (state: RootState) => state.patientContext.recurringPaymentTokens.bankToken
    ),
    selectedEncounter: useSelector(
      (state: RootState) => isAccountHolder ? state.patientContext?.currentConvertedEncounter : state.patientContext.selectedEncounter
    ),
    selectedPatient: useSelector(
      (state: RootState) => state.patientContext.selectedPatient
    ),
    patientPaymentProgram: useSelector((state: RootState) => {
      return isAccountHolder ? state?.patientContext?.currentConvertedEncounter?.patientPaymentProgram?.length > 0 ?
        state.patientContext.currentConvertedEncounter.patientPaymentProgram[state.patientContext.currentConvertedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram :
      state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
        state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    missingAddressError: useSelector((state: RootState) => state.patientContext.isError.missingAddress),
  }
  const { stripeCardToken, stripeBankToken, selectedEncounter, patientPaymentProgram, selectedPatient, missingAddressError } = stateFields;

  const transactions = selectedEncounter?.patientTransactions?.length > 0 && selectedEncounter?.patientTransactions
  const paymentMethods = selectedEncounter?.patientPaymentMethods?.length > 0 && selectedEncounter?.patientPaymentMethods
  const doAnyAchPaymentsOrPaymentMethodsExist = patientHelper.checkNonRefundedAchTransactionsOrPaymentMethods(transactions, paymentMethods)

  const patientsAndChampionsList = Utils.getPatientsAndChampionsList(selectedPatient, selectedEncounter, true); // payor can be anyone in this list
  const getReceiptEmail = (id: string) => {
    patientsAndChampionsList.forEach((item: {id: number, email: string}) => {
      if (item.id === parseInt(id)) {
        setReceiptForEmail(item.email)
      }
    })
  }

  const formRef: any = useRef();

  function handleSaveCallback() {
    handleModalClose()
  }

  useEffect(() => {
    if (!open && stripeCardToken) { // cleanup token state
      dispatch(setRecurringPaymentToken({}))
    }
  }, [dispatch, open, stripeCardToken])


  const stripeTokenCallback = (data: {token?: string, cardToken?: string, bankToken?: string}) => {
    const {token, cardToken, bankToken} = data
    if (token && cardToken) {
      dispatch(setRecurringPaymentToken({token: token, cardToken: cardToken}))
    } else if (bankToken) {
      dispatch(setRecurringPaymentToken({bankToken: bankToken}))
    }
  }

  const stripeDisabledCallback = (isDisabledFromStripe: boolean ) => {
    setIsStripeEmpty(isDisabledFromStripe)
  };

  const mapToPayment = () => {
    const currentVals = formRef?.current?.values;
    const paymentDay = new Date(currentVals?.paymentDay || '').setUTCHours(23,59,59,999);

    const paymentMethods = [{
        paymentMethodId: 0,
        payorId: currentVals?.payorName === selectedPatient?.patientId ? null : currentVals?.payorName,
        paymentAmt: isAccountHolder? 0 : patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt,
        scheduleStartDt: isAccountHolder ? "" : new Date(paymentDay || '').toISOString(),
        externalPaymentMethodId: isPaymentTypeCard ? PAYMENT_METHOD_TYPES.CARD.externalPaymentMethodId : PAYMENT_METHOD_TYPES.ACH.externalPaymentMethodId,
        paymentMethodTypeId: isPaymentTypeCard ? PAYMENT_METHOD_TYPES.CARD.paymentMethodTypeId : PAYMENT_METHOD_TYPES.ACH.paymentMethodTypeId,
        externalPaymentId: isPaymentTypeCard ? stripeCardToken : stripeBankToken,
        receiptEmail: receiptForEmail,
        nameOnCard: isPaymentTypeCard ? currentVals?.nameOnCard : Utils.getPayorName(
          {
            payorId: parseInt(currentVals?.payorName),
            patientId: selectedPatient?.patientId,
            selectedPatient: selectedPatient,
            selectedEncounter: selectedEncounter
          }
        )
      }] as PaymentMethod[]
    const paymentType = PAYMENT_TYPES.RECURRING

    return {
      paymentMethods: paymentMethods,
      paymentType: paymentType
    } as PaymentDetail
  }

  async function handleSave() {
    if ((isPaymentTypeCard && stripeCardToken) || ((!isPaymentTypeCard) && stripeBankToken)) {
      const paymentInfo = mapToPayment();
      await dispatch(addPatientPaymentMethod(
        {patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId, paymentDetail: paymentInfo, isAccountHolder: isAccountHolder}
      ))
      await paymentService.updatePaymentProgram(selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0, {
        patientPaymentProgramId: selectedEncounter.patientPaymentProgram?.[0]?.patientPaymentProgramId || 0,
        patientId: selectedEncounter.patientId,
        patientEncounterId: selectedEncounter.patientEncounterId,
        patientPaymentSchedule: {
          nextPaymentDueDt: paymentInfo.paymentMethods[0].scheduleStartDt,
        }
      })
    }
    handleModalClose();
  }

  function getDateInFuture(days: number) {
    let todayFullDate = new Date();
    const todayDayOfMonth = todayFullDate.getDate();
    return todayFullDate.setDate(todayDayOfMonth + days);
  }

  const initialValues = {
    payorName: "-1",
    amount: patientPaymentProgram?.patientPaymentSchedule?.paymentDueAmt,
    isPaymentTypeCard: true,
    nameOnCard: "",
    paymentDay: "",
    bankName: "",
    routingNumber: "",
    accountNumber: "",
    retypeAccountNumber: ""
  };

  const getValidationSchema = () => {
    if (isAccountHolder) {
      if (isPaymentTypeCard) {
        return patientAddPaymentMethodCard
      } else {
        return patientAddPaymentMethodACH
      }
    } else {
      if (isPaymentTypeCard) {
        return patientRecurringPayment
      } else {
        return patientRecurringPaymentACH
      }
    }
  }
  let validationSchema = Yup.object(getValidationSchema());

  useEffect(() => {
    // eslint-disable-next-line
    validationSchema = Yup.object(getValidationSchema());
  }, [dispatch, isPaymentTypeCard, formRef?.current?.values])

  function checkIfIsValid(value: {payorName: string, amount: number, isPaymentTypeCard: boolean, nameOnCard: string, paymentDay: string}) {
    if ((value?.isPaymentTypeCard !== isPaymentTypeCard) && stripeCardToken) {
      dispatch(setRecurringPaymentToken({})) // need to reset stripe token each time we change 'use existing source'
    }

    setIsPaymentTypeCard(value.isPaymentTypeCard);
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((e) => {
        setEnableSaveButton(false);
      });
  }

  const allowAch = !doAnyAchPaymentsOrPaymentMethodsExist
  // console.log('allowAch', allowAch)
  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">{isAccountHolder ? 'Add a Payment Method' : 'Monthly Recurring Payment'}</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form autoComplete='off'>
              <Grid container spacing={2} className="icon-colors">
                {missingAddressError && (
                  <Grid item xs={12}>
                    <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                      <Typography variant="subtitle2" color="error" className="pt-2 px-1">
                        Missing Address Information. Please fill out address information in the Demographics tab before attempting to submit any payments.
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {!isAccountHolder && (
                  <>
                    <Grid item xs={8} className="mt-2">
                      <Typography variant="subtitle2">
                        Recurring Payment Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display="flex" justifyContent="flex-end" className="pr-1 mt-2">
                        <Typography variant="subtitle2">
                          {`$${selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.paymentDueAmt}`}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Payor
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select={true}
                    error={
                      formik.touched["payorName"] && formik.errors["payorName"]
                    }
                    label="Payor Name"
                    name="payorName"
                    value={formik.values.payorName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      formik.handleChange(e);
                      getReceiptEmail(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {patientsAndChampionsList.map((payor: {name: string, id: number}) => (
                      <MenuItem key={payor.id} value={payor.id}>
                        {payor.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {!isAccountHolder && (
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="flex-end">
                      <TextField
                        error={
                          formik.touched["amount"] && formik.errors["amount"]
                        }
                        label="Amount"
                        disabled
                        startAdornment={<AttachMoneyIcon />}
                        name="amount"
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Payment Source
                  </Typography>
                </Grid>
                {(!allowAch && !isAccountHolder) &&
                  (
                    <Grid item xs={12}>
                      <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                        <Typography variant="h4" color="error" className="py-2 px-1">
                          ACH is disabled because an existing ACH transaction or payment method already exists.
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {allowAch && (
                    <>
                      <Grid item xs={12} style={{ marginBottom: -12}} className="ml-2">
                        <Typography variant="h4">
                          Payment Type
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Toggle
                          leftText="Credit Card"
                          rightText="ACH"
                          name="isPaymentTypeCard"
                          value={formik.values.isPaymentTypeCard}
                          onChange={() => {
                            formik.setFieldValue("payorName", "-1")
                            formik.setFieldTouched("payorName", false)
                          }}
                          formik={formik}
                        />
                      </Grid>
                    </>
                  )}
                {!isAccountHolder && (
                  <Grid item xs={4}>
                    <DatePicker
                      label="Payment Date"
                      minDate={getDateInFuture(1)}
                      maxDate={getDateInFuture(60)}
                      daysToDisable={[29, 30, 31]}
                      value={formik.values.paymentDay}
                      onChange={(date: Date) => {
                        formik.setFieldValue('paymentDay', date)
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={8} />
                {(formik.values.isPaymentTypeCard) ? (
                  <>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["nameOnCard"] && formik.errors["nameOnCard"]
                        }
                        label="Name on Card"
                        placeholder="Enter Name on Card"
                        name="nameOnCard"
                        value={formik.values.nameOnCard}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12} className="my-2" aria-autocomplete='none'>
                      <StripeCardField
                        disabledCallback={stripeDisabledCallback}
                        tokenCallback={stripeTokenCallback}
                        data={
                          {
                            name: formik.values.nameOnCard,
                            address_country: 'US'
                          }
                        }
                      />
                    </Grid>
                  </> )
                 : (
                  <>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["bankName"] && formik.errors["bankName"]
                        }
                        label="Bank Name"
                        name="bankName"
                        value={formik.values.bankName}
                        placeholder="Enter Bank Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["routingNumber"] && formik.errors["routingNumber"]
                        }
                        label="Routing Number"
                        name="routingNumber"
                        value={formik.values.routingNumber}
                        placeholder="Enter Routing Number"
                        maxLength={9}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["accountNumber"] && formik.errors["accountNumber"]
                        }
                        label="Account Number"
                        name="accountNumber"
                        value={formik.values.accountNumber}
                        placeholder="Enter Account Number"
                        maxLength={17}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6} aria-autocomplete='none'>
                      <TextField
                        autoComplete={false}
                        error={
                          formik.touched["retypeAccountNumber"] && formik.errors["retypeAccountNumber"]
                        }
                        label="Re-Enter Account Number"
                        name="retypeAccountNumber"
                        value={formik.values.retypeAccountNumber}
                        placeholder="Re Enter Account Number"
                        maxLength={17}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} aria-autocomplete='none'>
                      <StripeBankField
                        tokenCallback={stripeTokenCallback}
                        bankData={
                          {
                            country: 'US',
                            currency: 'usd',
                            routing_number: formik.values.routingNumber,
                            account_number: (formik.values.accountNumber === formik.values.retypeAccountNumber) ? formik.values.retypeAccountNumber.padStart(12, '0') : '',
                            bank_name: formik.values.bankName,
                            account_holder_name: Utils.getPayorName(
                              {
                                payorId: parseInt(formik?.values?.payorName),
                                patientId: selectedPatient?.patientId,
                                selectedPatient: selectedPatient,
                                selectedEncounter: selectedEncounter
                              }
                            ),
                            account_holder_type: 'individual',
                          }
                        }
                      />
                    </Grid>
                  </ >
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton && !missingAddressError && (isPaymentTypeCard ? (!isStripeEmpty && (!!stripeCardToken)) : (!!stripeBankToken) )}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
    </Dialog>
  );
}
