import React from "react";
import { Grid, Paper } from "@mui/material";
import PayerClaimRates from "../payer-claim-rates/payer-claim-rates-view";


function PayerPatientPaymentPerformance() {

  return (
      <Paper>
          <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                  <PayerClaimRates />
              </Grid>
          </Grid>
      </Paper>
  );
}

export default PayerPatientPaymentPerformance;