import '../../../../../scss/components/_table.scss';
import '../../../../../scss/pages/admin/_payment-channel-fees-view.scss';

import { TextField, Button } from '@finpay-development/shared-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography, InputAdornment } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, {useEffect, useState, Fragment} from 'react';
import { useDispatch } from 'react-redux';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

import SectionCompleteStatus from '../../../../../shared/components/section-complete-status';
import {useSelector} from 'react-redux';
import {RootState} from '../../../../../shared/state/root-reducer';
import {
  getClientPaymentTransactionFee,
  saveClientPaymentTransactionFee,
} from '../../../../state/clients/implementation-clients-thunk';
import {AppDispatch} from '../../../../../shared/state/store';
import {
  PaymentChannelMetaData,
  TimingRiskMetaData,
} from '../../../../../meta-data/models/metaData';
import {
  ClientPaymentTransactionFee
} from '../../../../models/payment-transaction-fee';
import {MetaDataState} from '../../../../../meta-data/state/meta-data-slice';
import AccessControl from '../../../../../security/components/access-control';
import {RolePageNames} from '../../../../../security/model/role-page-names';

interface TableHeaders {
  label: string
}

function PaymentChannelFees() {

  const [isSetupComplete, setIsSetupComplete] = useState(true);

  const {client, clientPaymentTransactionFee, clientPaymentTransactionFeeClientId, metaData} = {
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient?.client
    }),
    clientPaymentTransactionFee: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient?.clientPaymentTransactionFee.fees
    }),
    clientPaymentTransactionFeeClientId: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient?.clientPaymentTransactionFee.clientId
    }),
    metaData: useSelector((state: RootState) => {
      return state.metaData
    })
  }

  const dispatch = useDispatch<AppDispatch>();

  useEffect(()=>{
    /**
     * Refetching transaction fee only if the clientId's are out of sync,
     * so we can prevent unnecessary re-fetching.
     */
    if(client.clientId !== clientPaymentTransactionFeeClientId){
      dispatch(getClientPaymentTransactionFee(client.clientId))
    }
  }, [client.clientId, clientPaymentTransactionFeeClientId, dispatch])

  const handleSave = async (formik: FormikValues) => {
    dispatch(saveClientPaymentTransactionFee({clientId: client.clientId, payload: formik.values}))
  }

  const tableHeaderLabels: TableHeaders[] =  [
    { label: 'Fees by Payment Channel & Timing Risk' },
      ...metaData.paymentChannel.map((paymentChannel: PaymentChannelMetaData)=>{
        return {label: paymentChannel.paymentChannelName}
      })
  ]

  const paymentFeesValidationSchema = Yup.object({
    merchantProcessingFee: Yup.string().required("Merchant Process Fee is required")
  });

  function checkIfPaymentFeesValid(value: {}) {
    paymentFeesValidationSchema
      .validate(value)
      .then(() => {
        setIsSetupComplete(true);
      })
      .catch(() => {
        setIsSetupComplete(false);
      }
    );
  }

  const getClientPaymentTransactionFeeObj = (reduxMetaData: MetaDataState, reduxClientPaymentTransactionFee: ClientPaymentTransactionFee) => {

    const timingRiskMetaData = reduxMetaData.timingRisk;
    const paymentChannelMetaData = reduxMetaData.paymentChannel
    const obj: Record<string, any> = {}

    timingRiskMetaData.forEach((timingRisk:TimingRiskMetaData)=>{
        obj[timingRisk.shortName] = {}
      paymentChannelMetaData.forEach((paymentChannel: PaymentChannelMetaData)=>{
          // @ts-ignore
        obj[timingRisk.shortName][paymentChannel.shortName] = reduxClientPaymentTransactionFee[timingRisk.shortName]?.[paymentChannel.shortName] ? reduxClientPaymentTransactionFee[timingRisk.shortName]?.[paymentChannel.shortName] : 0
      })
    })
    return obj;
  }

  const createChannelFeeFormik = useFormik({
    initialValues:
        {
      merchantProcessingFee: client?.clientBusRules?.merchantProcessingFee ? client?.clientBusRules?.merchantProcessingFee : 0,
      accountHolderFee: false,
      ...getClientPaymentTransactionFeeObj(metaData, clientPaymentTransactionFee)
    },
    enableReinitialize: true,
    validate: checkIfPaymentFeesValid,
    validationSchema: paymentFeesValidationSchema,
    onSubmit: () => {},
  });

  const tableCellHeader = (
    <TableHead>
      <TableRow>
      {tableHeaderLabels.map((tableHeaders: TableHeaders, index) => (
        <TableCell
          className="fee-mainhead"
          style={index === 0 ? { width: '11%', paddingBottom: '8' } : {}}
          colSpan={index === 0 ? 1 : 2}
          rowSpan={index === 0 ? 2 : 1}
        >{tableHeaders.label}</TableCell>
      ))}
      </TableRow>
      <TableRow>
        {tableHeaderLabels.slice(0, 5).map(() => (
          <>
            <TableCell className="fee-subhead">Channel Fee</TableCell>
            <TableCell className="fee-subhead">Total Fees</TableCell>
          </>
        ))}
      </TableRow>
    </TableHead>
  );

  return (
    <div className="payment-channel-fees-table">
      <form>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="accordion-primary-contact"
          >
            <SectionCompleteStatus
              label="Fee Setup"
              isComplete={isSetupComplete}
            />
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container spacing={2} className="table-area">
                <Grid item xs={5} className="hero-fees">
                  <Typography variant="h3" >Merchant Process Fee:</Typography>
                  <TextField
                    type="number"
                    name="merchantProcessingFee"
                      // @ts-ignore
                    value={createChannelFeeFormik.values.merchantProcessingFee}
                    onChange={(e: Event) => {createChannelFeeFormik.handleChange(e)}}
                    onBlur={createChannelFeeFormik.handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <span>
                          <strong>%</strong>
                        </span>
                      </InputAdornment>
                    }
                  />
                </Grid>
              </Grid>
              <TableContainer>
                <Table
                  data-testid="results"
                  className="table alternating-row payment-channel-fees-table"
                  size="small"
                  stickyHeader
                >
                  {tableCellHeader}
                  <TableBody>
                    {metaData.timingRisk.map((timingRisk: TimingRiskMetaData) => {
                      return (
                        <TableRow
                          data-testid="result-row"
                          hover
                          tabIndex={-1}
                          key={timingRisk.timingRiskId}
                      >
                        <TableCell style={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="body1">
                            <strong>{timingRisk.timingRiskName.length < 11 ? timingRisk.timingRiskName : timingRisk.shortName.toUpperCase()}</strong>
                          </Typography>
                        </TableCell>
                            {metaData.paymentChannel.map((paymentChannel: PaymentChannelMetaData) => {
                              return (
                                  <Fragment key={paymentChannel.paymentChannelId}>
                                    <TableCell align="center" className="cell-container fees-table">
                                      <Grid item container className="field-container">
                                        <TextField
                                          type="number"
                                          name={
                                            `${timingRisk.shortName}.${paymentChannel.shortName}`}
                                          value={// @ts-ignore
                                            createChannelFeeFormik.values[timingRisk.shortName][paymentChannel.shortName]}
                                          onChange={(e: Event) => {createChannelFeeFormik.handleChange(e)}}
                                          onBlur={createChannelFeeFormik.handleBlur}
                                          endAdornment={
                                            <InputAdornment position="end">
                                              <span>
                                                <strong>%</strong>
                                              </span>
                                            </InputAdornment>
                                          }
                                        />
                                      </Grid>
                                    </TableCell>
                                    <TableCell align="center">
                                      {// @ts-ignore
                                        ((createChannelFeeFormik.values[timingRisk.shortName]?.[paymentChannel.shortName] ? createChannelFeeFormik.values[timingRisk.shortName]?.[paymentChannel.shortName]: 0) + (createChannelFeeFormik.values?.merchantProcessingFee ? createChannelFeeFormik.values?.merchantProcessingFee : 0)).toFixed(2)
                                      }%
                                    </TableCell>
                                  </Fragment>
                              )
                            })}
                      </TableRow>)
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid
                item
                xs={12}
                justifyContent="flex-end"
                style={{ textAlign: "right" }}
                className="mt-5"
              >
                <AccessControl
                    rolePageName={RolePageNames.Clients}
                    actionName="Add New Client"
                    renderNoAccess={() =>
                        <Button disabled>
                          {!isSetupComplete ? "Update" : "Save"}
                        </Button>}
                >
                  <Button onClick={()=>handleSave(createChannelFeeFormik)}>
                    {!isSetupComplete ? "Update" : "Save"}
                  </Button>
                </AccessControl>
              </Grid>
          </AccordionDetails>
        </Accordion>
      </form>
    </div>
  );
}

export default PaymentChannelFees;
