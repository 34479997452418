import {Grid} from '@mui/material';
import {
  DatePicker
} from '@finpay-development/shared-components';
import {Utils} from '../../../shared/utils';
import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

interface AdmissionDateProps {
  admissionDate?: string | Date
  onChangeHandler: (date:Date)=> void
}

const admissionDateSchema = Yup.object({
  admissionDate: Yup.mixed().test(
      'is-date-or-stringed-date',
      'Must be a valid date or string that can be parsed into a date',
      (value)=>{
        return (value instanceof Date ||  !isNaN(Date.parse(value)))
      }
  )
})

export const AdmissionDate = ({
    admissionDate,
    onChangeHandler
                              }: AdmissionDateProps) => {

  const formik = useFormik({
    initialValues: {
      admissionDate: admissionDate || new Date()
    },
    enableReinitialize: true,
    validationSchema: admissionDateSchema,
    onSubmit: () => {},
  })

  return (
      <form>
        <Grid test-id="admission-date-container" container spacing={2} direction="row">
          <Grid item xs={12} sm={12} direction="column">
            <DatePicker
                error={
                    Utils.isValidDate(formik.values.admissionDate) &&
                    formik.touched["admissionDate"] &&
                    formik.errors["admissionDate"]
                }
                data="admission-date-input"
                label="Anticipated Admission Date"
                value={new Date(formik.values.admissionDate)}
                onChange={(date: Date) => {
                  if (Utils.isValidDate(date)) {
                    onChangeHandler(date)
                    formik.setFieldValue("admissionDate", date);
                  }
                }}
                minDate={Utils.getMinDate()}
                maxDate={Utils.getMaxDate()}
            />
          </Grid>
        </Grid>
      </form>
  )
}