import "./../../../scss/pages/admin/_admin-users.scss";

import { Button, LoadingOverlay } from "@finpay-development/shared-components";
import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { resendInvitation } from "../../../implementation-specialist/state/clients/implementation-clients-thunk";
import AccessControl from "../../../security/components/access-control";
import { RolePageNames } from "../../../security/model/role-page-names";
import { showStatus } from "../../../security/state/user-slice";
import NotifyModal from "../../../shared/components/notify-modal";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { getAdminUserRoles } from "../../state/admin-roles-thunk";
import {
  clearAddEditUser,
  clearAdminUserState,
} from "../../state/users/admin-slice";
import {
  deActivateUser,
  filterUsers,
  getUser,
  getUserCognitoStatus,
  reActivateUser,
} from "../../state/users/admin-thunk";
import AdminUsersFilter from "./admin-users-filter";
import AdminUsersFilterResults from "./admin-users-filter-results";
import { EditUserModal } from "./edit-user-modal";
import {CognitoStatus} from '../../models/cognito-statuses';
import {
  sendForgetPasswordNotificationV2,
} from '../../../security/state/user-thunk';

function AdminUsersView() {
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [resendInvitationModal, setResendInvitationModal] = useState(false);
  const [isUserSearchActive, setIsUserSearchActive] = useState(false);
  const [userData, setUserData] = useState<any>([]);
  const [isError, setIsError] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    searchResult: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredUsers
    ),
    clients: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.userSearch.clientsFilter
    ),
    selectedClient: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.selectedClient
    ),
    roles: useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.userRoles
    ),
    isLoading: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isLoading
    ),
    user: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.selectedUser
    ),
  };

  useEffect(() => {
    dispatch(getAdminUserRoles());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearAdminUserState());
    };
  }, [dispatch]);

  function showAddNewDialog() {
    dispatch(clearAddEditUser());
    setEditUserModalOpen(true);
  }

  async function editUser(userId: number) {
    await dispatch(getUser(userId)); // Get Latest User Data
    setEditUserModalOpen(true);
  }

  function showUserDetails(userId: number) {}

  function deactivateUser(userId: number) {
    dispatch(deActivateUser(userId));
  }

  function reactivateUser(userId: number) {
    dispatch(reActivateUser(userId));
  }

  function handleGetUserCognitoStatus(userId: number) {
    dispatch(getUserCognitoStatus(userId));
    dispatch(getUser(userId));
  }

  async function showResendInvitation(userId: number) {
    dispatch(getUser(userId)); // Get Latest User Data
    setResendInvitationModal(true);
  }

  const sendEmailReminderNotification = async () => {
    setResendInvitationModal(true);

    switch(stateFields.user?.cognitoStatus?.UserStatus){
      case CognitoStatus.forceChangePw:
        //User has not updated password after receiving welcome email. Need to send welcome email.
        await dispatch(resendInvitation(stateFields.user.userId));
        break;
      case CognitoStatus.confirmed:
        //User has already updated password after receiving welcome email. Need to send forgot password email.
        await dispatch(sendForgetPasswordNotificationV2({username: stateFields.user.email, userRoleId: stateFields.user.userRole.roleId }));
        break;
      default:
        //do nothing for now
    }

    setResendInvitationModal(false);
  };

  function handleNotifyCancel() {
    setResendInvitationModal(false);
  }

  function handleEditUserModalCancel() {
    setEditUserModalOpen(false);
  }

  function handleEditUserModalSubmit(isEditMode: boolean) {
    setEditUserModalOpen(false);

    if (isEditMode) {
      dispatch(showStatus("User Updated"));
    } else {
      dispatch(showStatus("New User created"));
    }

    dispatch(filterUsers());
  }

  const handleUserSearchActive = (isActive: boolean) => {
    setIsUserSearchActive(isActive);
  };

  const adminUsersView = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AdminUsersFilter
            setIsUserSearchActive={handleUserSearchActive}
            setUserData={(data) => {
              setUserData(data);
              setIsSearching(false);
            }}
            onError={(error) => {
              setIsError(error);
              setIsSearching(false);
            }}
            onResetFilters={() => {
              setUserData([]);
              setIsError(null);
              setIsUserSearchActive(false);
            }}
            onSearch={() => setIsSearching(true)}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            <AccessControl
              rolePageName={RolePageNames.Users}
              actionName="Add New User"
              renderNoAccess={() => <Button disabled>Add New User</Button>}
            >
              <Button onClick={() => showAddNewDialog()}>Add New User</Button>
            </AccessControl>
          </Grid>
          {isSearching ? (
            <LoadingOverlay />
          ) : userData?.filteredUsers?.length > 0 ? (
            <AdminUsersFilterResults
              data={userData?.filteredUsers}
              onRowMenuEdit={editUser}
              onRowMenuDetails={showUserDetails}
              onRowMenuResendInvitation={showResendInvitation}
              onRowMenuDeactivate={deactivateUser}
              onRowMenuReactivate={reactivateUser}
              handleGetUserCognitoStatus={handleGetUserCognitoStatus}
              isUserSearchActive={isUserSearchActive}
            />
          ) : isUserSearchActive || isError ? (
            <Box
              sx={{
                bgcolor: "background.paper",
                pt: 8,
                pb: 6,
                mt: 6,
              }}
            >
              <Container maxWidth="sm">
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="error.main"
                  gutterBottom
                >
                  {isError ? isError
                    : "No users match your search criteria. Please try again."}{" "}
                </Typography>
              </Container>
            </Box>
          ) : (
            <Box
              sx={{
                bgcolor: "background.paper",
                pt: 8,
                pb: 6,
                mt: 6,
              }}
            >
              <Container maxWidth="sm">
                <Typography
                  component="h1"
                  variant="h2"
                  align="center"
                  color="text.primary"
                  gutterBottom
                >
                  Search for a user to view results.
                </Typography>
              </Container>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <main className="admin-users">
      <div className="content fullwidth">
        {adminUsersView}
        {editUserModalOpen && (
          <EditUserModal
            handleEditUserModalCancel={handleEditUserModalCancel}
            handleEditUserModalSubmit={handleEditUserModalSubmit}
            open={editUserModalOpen}
          />
        )}
        <NotifyModal
          open={resendInvitationModal}
          title="Pending user"
          subTitle="This will resend an email to the user"
          okButtonText="Resend Invitation"
          sendEmailReminderNotification={sendEmailReminderNotification}
          handleNotifyCancel={handleNotifyCancel}
        />
      </div>
    </main>
  );
}

export default AdminUsersView;
