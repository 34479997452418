import { Box, Grid, Paper, TableContainer } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Button, Checkbox, } from '@finpay-development/shared-components';
import * as Yup from 'yup';
import { ImplementationFacility, FacilitiesInfo, ClientFacilitySelected } from "../models/implementation-facility";

import { generateClientPayorSpreadsheet, generateClientLOCSpreadsheet } from '../../../../state/clients/implementation-clients-thunk';
import { clearStatus, setAdmissionsAdvisorSelectedFacilities, setAdmissionsAdvisorSelectedPayers } from '../../../../state/clients/implementation-clients-slice';
import { configGetLOC, configGetPayers } from '../../../../../admin-configuration/state/admin-configuration-thunk';
import { PayerRow } from '../../../../../admin-configuration/models/payers';
import { ClientPayerSelected, PayersInfo } from '../models/payer-plan-info';
import { showErrorStatus } from '../../../../../security/state/user-slice';
import { Utils } from '../../../../../shared/utils';
import { EstLevelOfCare } from 'src/admissions-advisor/models/estimator';
import { getClientLevelsOfCare } from 'src/admissions-advisor/state/estimator-thunk';
import { setLevelsOfCare } from 'src/admissions-advisor/state/estimator-slice';

interface SelectLOCEvent extends Event {
  target: EventTarget & {
    checked?: boolean;
  };
}

function ClientAdmissionsAdvisor() {
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    currentClientId: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.currentClientEntityDetails.clientId
    ),
    implementationFacilities: useSelector(
      (state: RootState) => state.implementationContext.implementationFacility.facilities
    ),
    isLoadingLOCSpreadsheet: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoadingLOCSpreadsheet;
    }),
    isLoadingPayorSpreadsheet: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoadingPayorSpreadsheet;
    }),
    AAselectedFacilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.AAselectedFacilities;
    }),
    payers: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext?.payers
    ),
    AAselectedPayers: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.AAselectedPayers;
    }),
    clientLevelsOfCareState: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare;
    }),
    masterLevelsOfCareState: useSelector((state: RootState) => {
      return state.adminContext.adminConfigurationContext?.levelsOfCare;
    })
  }

  const { currentClientId, implementationFacilities, AAselectedFacilities, payers, AAselectedPayers, isLoadingLOCSpreadsheet, isLoadingPayorSpreadsheet, clientLevelsOfCareState, masterLevelsOfCareState} = selectors;

  const [enableDownloadLOCButton, setEnableDownloadLOCButton]  = useState(false);
  const [enableDownloadPayerButton, setEnableDownloadPayerButton]  = useState(false);
  const [allLevelsOfCare, setAllLevelsOfCare]  = useState(false);
  const [selectedClientFacility, setSelectedClientFacility]  = useState<number[]>([]);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);
  const [isMasterLOCLoadComplete, setIsMasterLOCLoadComplete] = useState(false);
  const [isClientLevelLOCLoadComplete, setIsClientLevelLOCLoadComplete] = useState(false);
  const paramId = -2;

  useEffect(() => {
    if ( (payers !== undefined) && (payers.length === 0) ) {
      dispatch(configGetPayers(paramId));
    }
  }, [dispatch, payers, paramId]);

  useEffect(() => {
    if (implementationFacilities?.flat().length > 0) {
      if (AAselectedFacilities?.clientFacilitiesList.length === 0) {
        let workFacilities = {
          clientFacilitiesList: []
        } as FacilitiesInfo;
        implementationFacilities?.flat().forEach((facilityItem: ImplementationFacility) => {
          const clientFacility = {
            clientId: facilityItem.clientId,
            facilityId: facilityItem.facilityId,
            facilityName: facilityItem.facilityName,
            checked: false
          } as ClientFacilitySelected
          workFacilities.clientFacilitiesList.push(clientFacility);
        })
        //add All Facilities: value -999 is used for checkbox with label 'All Facilities' to help set the checked/unchecked of each row on the UI
        workFacilities.clientFacilitiesList.unshift({ clientId: currentClientId, facilityId: -999, facilityName: 'All Facilities', checked: false });
        dispatch(setAdmissionsAdvisorSelectedFacilities(workFacilities));
      }
      if (AAselectedFacilities?.clientFacilitiesList.length > 0) {
        const hasCheckedRecords = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.some(
          (facility: any) => facility.checked === true
        );
        if(hasCheckedRecords) {
          const newSelectAllClientFacility = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList
            .filter((facility: any) => facility.checked === true)
            .map((facility: any) => facility.facilityId);
          setSelectedClientFacility(newSelectAllClientFacility);
        }
      }
    }
  }, [dispatch, currentClientId, implementationFacilities, AAselectedFacilities])

  useEffect(() => {
    if (payers?.length > 0) {
      if (AAselectedPayers?.clientPayersList.length === 0) {
        let workPayers = {
          clientPayersList: []
        } as PayersInfo;
        payers?.forEach((payerItem: PayerRow)=>{
          const clientPayer = {
            clientId: currentClientId,
            payerId: payerItem.payorId,
            payerName: payerItem.payorName,
            checked:false,
            payorPlans: payerItem.payorPlans,
          } as ClientPayerSelected
          workPayers.clientPayersList.push(clientPayer);
        })
        Utils.sortAlphabetically(workPayers.clientPayersList, "payerName");
        //add All Payers: value -999 is used for checkbox with label 'All Payers' to help set the checked/unchecked of each row on the UI
        workPayers.clientPayersList.unshift({clientId: currentClientId, payerId: -999, payerName: 'All Payers', checked: false, payorPlans: []});
        dispatch(setAdmissionsAdvisorSelectedPayers(workPayers));
      }
    }
  }, [dispatch, currentClientId, payers, AAselectedPayers])

  useEffect(() => {
    const getLevelsOfCareByClient = async () => {
      const config: any = {
        paramId: paramId,
        clientId: currentClientId,
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      try {
        await dispatch(getClientLevelsOfCare(config));
        setIsInitialLoadComplete(true); 
      } catch (error) {
        console.log(error);
      }
    };
  
    if ((masterLevelsOfCareState !== undefined) && (masterLevelsOfCareState.length === 0)) {
      dispatch(configGetLOC(paramId));
    }
  
    if (masterLevelsOfCareState?.length > 0 && clientLevelsOfCareState.length === 0 && !isMasterLOCLoadComplete) {
      setIsMasterLOCLoadComplete(true);
      getLevelsOfCareByClient();
    }
  
    if (isInitialLoadComplete && isMasterLOCLoadComplete && !isClientLevelLOCLoadComplete) {
      let buildingLOC = [] as EstLevelOfCare[];
      clientLevelsOfCareState?.forEach((levelOfCare: EstLevelOfCare) => {
        const clientLevelsOfCare = {
          ...levelOfCare,
          checked: false
        } as EstLevelOfCare;
        buildingLOC.push(clientLevelsOfCare);
      });
      setIsClientLevelLOCLoadComplete(true);
      dispatch(setLevelsOfCare(buildingLOC));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, masterLevelsOfCareState, isInitialLoadComplete, isMasterLOCLoadComplete, isClientLevelLOCLoadComplete]);

  const initialValues : any = {
    allFacilitiesInfo: Utils.deepClone(AAselectedFacilities),
    clientId: currentClientId,
    allPayersInfo: Utils.deepClone(AAselectedPayers),
    clientLevelsOfCare: groupByCfgLevelOfCareId()
  }

  const validationAAClientSchema = Yup.object({
    clientId: Yup.number()
  });

  const AAClientFormik = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationAAClientSchema,
      onSubmit: () => {},
  });

  function groupByCfgLevelOfCareId() {
    let filteredData: EstLevelOfCare[] = Utils.deepClone(clientLevelsOfCareState).filter((item : any) => selectedClientFacility.includes(item.clientFacilityId));
    filteredData.sort(
      (a: { cfgLevelOfCareId: number }, b: { cfgLevelOfCareId: number }) =>
        a.cfgLevelOfCareId - b.cfgLevelOfCareId
    );
    const groupedArray: EstLevelOfCare[] = [];
    filteredData.forEach(item => {
      const existingGroupIndex = groupedArray.findIndex(
        group => group.cfgLevelOfCareId === item.cfgLevelOfCareId
      );
  
      if (existingGroupIndex !== -1) {
        // Create a new object for the existing group
        const existingGroup = { ...groupedArray[existingGroupIndex] };
        existingGroup.facilityName += `, ${item.facilityName}`;
        existingGroup.facilityLevelOfCareIdArray?.push(
          item.facilityLevelOfCareId!
        );
        // Update the existing group in the groupedArray
        groupedArray[existingGroupIndex] = existingGroup;
      } else {
        // Check if the item is already in groupedArray, if not, push it
        if (!groupedArray.some(group => group.cfgLevelOfCareId === item.cfgLevelOfCareId)) {
          const newGroup: EstLevelOfCare = {
            ...item,
            facilityName: item.facilityName,
            facilityLevelOfCareIdArray: [item?.facilityLevelOfCareId!],
          };
          groupedArray.push(newGroup);
        }
      }
    });
  
    return groupedArray;
  }
  
  function setFacilityChecked(facilityId: number, bChecked: boolean) {
    const facilityIndex = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.findIndex((facility: any) => facility.facilityId === facilityId);
    AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList[facilityIndex].checked = bChecked;
    if (bChecked && !selectedClientFacility.includes(facilityId)) {
      setSelectedClientFacility((prevIds: number[]) => [...prevIds, facilityId]);
    }
    if (!bChecked && selectedClientFacility.includes(facilityId)) {
      setSelectedClientFacility((prevIds: number[]) => prevIds.filter(item => item !== facilityId));
    }
    
    //if All Facilities is checked/unchecked then set checked/unchecked to each facility
    if (facilityId === -999) {
      if (bChecked) {
        const newSelectAllClientFacility = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.map((obj: any) => obj.facilityId);
        setSelectedClientFacility(newSelectAllClientFacility);
      } else {
        setSelectedClientFacility([]);
        setAllLevelsOfCare(false);
      }
      AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.forEach( (facility: ClientFacilitySelected) => { facility.checked = bChecked})
    } else {
      //if one facility gets unchecked and 'All facilities' was checked, reset 'All facilities' checkbox and leave previously checked facilities as-is
      if (facilityId !== -999) {
        const allFacilitiesItem = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.find((facility: any) => facility.facilityId ===-999);
        if (allFacilitiesItem !== undefined && allFacilitiesItem.checked) {
          allFacilitiesItem.checked = false;
        }
      }
    }

    //make sure at least one facility gets selected then enable button to download Levels of Care
    const checkedPayer = AAClientFormik.values.allPayersInfo.clientPayersList.some((payer: ClientPayerSelected) => payer.checked === true);
    const checkedFacility = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.some((facility: ClientFacilitySelected) => facility.checked === true);
    if (checkedFacility) {
      if (checkedPayer) {
        setEnableDownloadPayerButton(true);
      }
    } else {
        setAllLevelsOfCare(false);
        setEnableDownloadLOCButton(false);
      if (checkedPayer) {
        setEnableDownloadPayerButton(false);
      }
    }
    dispatch(setAdmissionsAdvisorSelectedFacilities(JSON.parse(JSON.stringify(AAClientFormik.values.allFacilitiesInfo))));
  }

  function isFacilityChecked(facilityId: number): boolean {
    const facilityIndex = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.findIndex((facility: ClientFacilitySelected) => facility.facilityId === facilityId);
    return AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList[facilityIndex].checked;
  }

  function getSelectedFacilityIds() : number[] {
    const checkedFacilities = AAClientFormik.values.allFacilitiesInfo.clientFacilitiesList.filter( (x: ClientFacilitySelected) => x.checked === true);
    let facilityIds: number[] = [];
    if( (checkedFacilities !== undefined) && (checkedFacilities.length > 0) ) {
      checkedFacilities.forEach( (facility: ClientFacilitySelected) => {facility.facilityId !== -999 && facilityIds.push(facility.facilityId)});
    }
    return facilityIds;
  }
  
  function setLOCChecked(cfgLevelOfCareId: number, bChecked: boolean) {
    const checkedLOC = AAClientFormik.values.clientLevelsOfCare.some((levelOfCare: EstLevelOfCare) => levelOfCare.checked === true);
    const locIndex = AAClientFormik.values.clientLevelsOfCare.findIndex((loc: any) => loc.cfgLevelOfCareId === cfgLevelOfCareId);
    if (checkedLOC || bChecked) {setEnableDownloadLOCButton(true)};
    if (!bChecked && AAClientFormik.values.clientLevelsOfCare[locIndex].checked) {
      let clonedLOCs = Utils.deepClone(AAClientFormik.values.clientLevelsOfCare).filter((item : any) => item.cfgLevelOfCareId !== cfgLevelOfCareId)
      const checkedclonedLOCs = clonedLOCs.some((levelOfCare: EstLevelOfCare) => levelOfCare.checked === true);
      if (!checkedclonedLOCs) {
        setEnableDownloadLOCButton(false)
      }
    }
  }

  function setPayerChecked(payerId: number, bChecked: boolean) {
    const payerIndex = AAClientFormik.values.allPayersInfo.clientPayersList.findIndex((payer: any) => payer.payerId === payerId);
    AAClientFormik.values.allPayersInfo.clientPayersList[payerIndex].checked = bChecked;

    //if All Payers is checked/unchecked then set checked/unchecked to each payer
    if (payerId === -999) {
      AAClientFormik.values.allPayersInfo.clientPayersList.forEach( (payer: ClientPayerSelected) => { if (hasPayerPlans(payer)) { payer.checked = bChecked} })
    } else {
      //if one payer gets unchecked and 'All Payers' was checked, reset 'All Payers' checkbox and leave previously checked payers as-is
      if (payerId !== -999) {
        const allPayersItem = AAClientFormik.values.allPayersInfo.clientPayersList.find((payer: any) => payer.payerId ===-999);
        if (allPayersItem !== undefined && allPayersItem.checked) {
          allPayersItem.checked = false;
        }
      }
    }

    //make sure at least one payer gets selected in order to enable button to download Payors
    const checkedPayer = AAClientFormik.values.allPayersInfo.clientPayersList.some((payer: ClientPayerSelected) => payer.checked === true);
    if (checkedPayer) {
      if (getSelectedFacilityIds().length === 0) {
        setEnableDownloadPayerButton(false);
      } else {
        setEnableDownloadPayerButton(true);
      }
    } else {
      setEnableDownloadPayerButton(false);
    }
    dispatch(setAdmissionsAdvisorSelectedPayers(JSON.parse(JSON.stringify(AAClientFormik.values.allPayersInfo))));
  }

  function isPayerChecked(payerId: number): boolean {
    const payerIndex = AAClientFormik.values.allPayersInfo.clientPayersList.findIndex((payer: ClientPayerSelected) => payer.payerId === payerId);
    return AAClientFormik.values.allPayersInfo.clientPayersList[payerIndex].checked;
  }

  function hasPayerPlans(payerItem: ClientPayerSelected): boolean{
    if (payerItem.payerId === -999)
      return true;
    if (payerItem.payorPlans.length > 0) {
      return true;
    }
    return false;
  }

  function handleDownloadPayers() {
    let selectedLevelsOfCare: number[] = [];
    const checkedLOCs = AAClientFormik.values.clientLevelsOfCare.filter( (l: EstLevelOfCare) => l.checked === true);
    const checkedPayers = AAClientFormik.values.allPayersInfo.clientPayersList.filter( (x: ClientPayerSelected) => x.checked === true);
    checkedLOCs.forEach((loc: EstLevelOfCare) => {
        selectedLevelsOfCare = selectedLevelsOfCare.concat(
            loc.facilityLevelOfCareIdArray || []
        );
    });
    let payerIds: number[] = [];
    if( (checkedPayers !== undefined) && (checkedPayers.length > 0) ) {
      checkedPayers.forEach( (payer: ClientPayerSelected) => { payer.payerId !== -999 && payerIds.push(payer.payerId)});
      const facilityIds = getSelectedFacilityIds();
      //verify that one or more facilityIds has been selected
      if (facilityIds.length === 0) {
        const errorMessage = "One ore more facilities must be selected in order to download a payer excel file.";
        dispatch(showErrorStatus(errorMessage));
      } else {
        dispatch(generateClientPayorSpreadsheet({ 
          clientId: currentClientId,
          spreadsheetType: 'payor',
          facilityIds: facilityIds,
          facilityLevelOfCareIds: selectedLevelsOfCare,
          payorIds: payerIds
        }));
      }
      dispatch(clearStatus());
    }
  }

  function handlePayerSelectionChange(e: any, payer: ClientPayerSelected) {
    AAClientFormik.handleChange(e);
    if (e.target.checked) {
      setPayerChecked(payer.payerId, true)
    } else {
      setPayerChecked(payer.payerId, false)
    }
  }

  function handleFacilitySelectionChange(e: any, facility: ClientFacilitySelected) {
    AAClientFormik.handleChange(e);
    if (e.target.checked) {
      setFacilityChecked(facility.facilityId, true)
    } else {
      setFacilityChecked(facility.facilityId, false)
    }
  }

  const handleLOCSelectionChange = useCallback(async (e: SelectLOCEvent, cfgLevelOfCareId: number) => {
    AAClientFormik.handleChange(e);
    if (e.target.checked) {
      setLOCChecked(cfgLevelOfCareId, true);
    } else {
      setLOCChecked(cfgLevelOfCareId, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AAClientFormik])

  const handleLOCSelectAll = useCallback((e: SelectLOCEvent) => {
    setEnableDownloadLOCButton(e.target.checked ? true : false);
    setAllLevelsOfCare(e.target.checked!);
    const updatedClientLevelsOfCare = AAClientFormik.values.clientLevelsOfCare.map((loc: EstLevelOfCare) => ({
      ...loc,
      checked: e.target.checked
    }));
    AAClientFormik.setFieldValue('clientLevelsOfCare', updatedClientLevelsOfCare);
  }, [AAClientFormik]);

  const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
      <Tooltip {...props} classes={{popper: className}} />
  ))({
      [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 900,
      },
  });

  const facilitiesBox = (
    <Box
      style={{
        padding: "1.0em",
        borderBottom: "1px solid #cccccc",
        marginBottom: "1.5em"
      }}
    >
      <Grid container justifyContent="space-between" direction="row">
        <Grid item
          justifyContent="center"
          direction="column"
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            paddingBottom: "0.5em"
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Select Facilities
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <TableContainer style={{ maxHeight: 200 }}>
          <div className="ml-6">
            {currentClientId !== 0 &&
              AAClientFormik.values.allFacilitiesInfo?.clientFacilitiesList.map((facility: any, index: number) => (
                <div key={facility.facilityId}>
                  <Checkbox
                    checked={isFacilityChecked(facility.facilityId)}
                    id={`${currentClientId}${facility.facilityId}`}
                    name={facility.facilityName}
                    label={facility.facilityName}
                    onChange={ (e: Event) => handleFacilitySelectionChange(e, facility)}
                  />
                </div>
              ))}
          </div>
        </TableContainer>
      </Grid>
    </Box>
  )

  const payorsBox = (
    <Box
      style={{
        padding: "1.0em",
        borderBottom: "1px solid #cccccc",
        marginBottom: "1.5em"
      }}
    >
      <Grid container justifyContent="space-between" direction="row">
        <Grid
          item
          justifyContent="center"
          direction="column"
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            paddingBottom: "0.5em"
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            Payers and Plans
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <TableContainer style={{ maxHeight: 200 }}>
          <div className="ml-6">
            {currentClientId !== 0 &&
              AAClientFormik.values.allPayersInfo.clientPayersList.map((payer: any, index: number) => (

                <div key={payer.payerId}>
                  {hasPayerPlans(payer) && (
                    <Checkbox
                    checked={isPayerChecked(payer.payerId)}
                    id={`${currentClientId}_Payor_${payer.payerId}`}
                    name={payer.payerName}
                    label={payer.payerName}
                    onChange={ (e: Event) => handlePayerSelectionChange(e, payer)}
                  />
                  )}
                  {!hasPayerPlans(payer) && (
                    <Tooltip title="Payer has no plans"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [-30, -40],
                            },
                          },
                        ],
                      }}>
                      <div>
                        <Checkbox
                          checked={isPayerChecked(payer.payerId)}
                          disabled
                          id={`${currentClientId}_Payor_${payer.payerId}`}
                          name={payer.payerName}
                          label={payer.payerName}
                          onChange={(e: Event) => handlePayerSelectionChange(e, payer)}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ))}
          </div>
        </TableContainer>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid
          item xs={12}
          style={{
            paddingBottom: "37px",
            alignItems: "center",
          }}
        >
          <Box textAlign='center'>
            <Button
              type="primary"
              isSubmitButton
              disabled={!enableDownloadPayerButton || isLoadingPayorSpreadsheet}
              loading={isLoadingPayorSpreadsheet}
              spinnerLeftPosition={6}
              onClick={() => handleDownloadPayers()}
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

  const levelsOfCareBox = (
    <Box
      style={{
        padding: "1.0em",
        borderBottom: "1px solid #cccccc",
        marginBottom: "1.5em"
      }}
    >
      <Grid container justifyContent="space-between" direction="row">
        <Grid item
          direction="column"
          xs={12}
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingBottom: "0.5em"
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: '10px'
            }}
          >
            <PermContactCalendarIcon sx={{ marginRight: "0.25em" }} />Levels of Care
          </Typography>
          <Grid container spacing={2} direction="row">
            <TableContainer style={{ maxHeight: 280, paddingLeft: 24, paddingTop: 8 }}>
              {currentClientId !== 0 && AAClientFormik.values.clientLevelsOfCare.length > 0 && 
                  <div>
                    <Checkbox
                      checked={allLevelsOfCare}
                      id={`allLevelsOfCare`}
                      name={`allLevelsOfCare`}
                      label={`All Levels of Care`}
                      onChange={ (e: SelectLOCEvent) => handleLOCSelectAll(e)}
                    />
                </div>
              }
              {currentClientId !== 0 && AAClientFormik.values.clientLevelsOfCare.length > 0 && AAClientFormik.values.clientLevelsOfCare.map((levelofcare: EstLevelOfCare, index: number) => (
                  <CustomWidthTooltip
                      title={`${
                          levelofcare.facilityLevelOfCareIdArray &&
                          levelofcare
                              .facilityLevelOfCareIdArray
                              .length > 1
                              ? 'Facilities: '
                              : 'Facility: '
                      } ${levelofcare.facilityName!}`}
                      placement="bottom-start"
                      PopperProps={{
                          modifiers: [
                              {
                                  name: 'offset',
                                  options: {
                                      offset: [32, -27],
                                  },
                              },
                          ],
                      }}
                  >
                      <div
                          key={`${levelofcare.facilityLevelOfCareId}-${index}`}
                          className="checkbox-wlabel"
                      >
                          <Checkbox
                              checked={
                                  AAClientFormik.values
                                      .clientLevelsOfCare[
                                      index
                                  ].checked
                              }
                              id={`${currentClientId}_loc_${levelofcare.facilityLevelOfCareCode}_${index}`}
                              name={`clientLevelsOfCare.${index}.checked`}
                              label={`${levelofcare.facilityLevelOfCareName!} `}
                              onChange={(
                                  e: SelectLOCEvent
                              ) =>
                                  handleLOCSelectionChange(
                                      e,
                                      levelofcare.cfgLevelOfCareId!
                                  )
                              }
                          />
                      </div>
                  </CustomWidthTooltip>
              ))}
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      <Grid container justifyContent="space-between" direction="row" spacing={2}>
        <Grid item direction="column" xs={12}>
          <form onSubmit={AAClientFormik.handleSubmit}>
            <Paper>
              {facilitiesBox}
            </Paper>
            <Paper>
              {levelsOfCareBox}
            </Paper>
            <Paper>
              {payorsBox}
            </Paper>
          </form>
        </Grid>
      </Grid>
    </>
  );
}

export default ClientAdmissionsAdvisor;
