import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Transaction } from '../../models/transaction';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../shared/state/root-reducer';
import '../../../../scss/pages/patients/dispute-response.scss';
import  paymentIcon from '../../../../scss/pages/patients/payment-icon.png'
import  smsIcon from '../../../../scss/pages/patients/sms-icon.png'
import spaIcon from '../../../../scss/pages/patients/spa-icon.png'

function DisputeResponse() {
    const params = useParams()
    const disputeId = params.did
    const currentDate = new Date().toLocaleDateString()
    const [disputeTransaction, setDisputeTransaction] = useState<Transaction | undefined>(undefined)
    const [originalPaymentTransaction, setOriginalPaymentTransaction] = useState<Transaction | undefined>(undefined)
    const stateFields = {
        selectedPatient: useSelector((state: RootState) => {
            return state.patientContext.selectedPatient
        }),
        selectedPatientEncounter: useSelector((state: RootState) => {
            return state.patientContext.selectedEncounter
        }),
    }
    const { selectedPatient, selectedPatientEncounter } = stateFields;
    const transactions = selectedPatientEncounter?.patientTransactions
    const patient = selectedPatient
    const contact = patient?.contact
    const authDocCreatedDt = selectedPatientEncounter?.instanceCreateDt
    const clientName = selectedPatientEncounter?.clientName
    const signedInstall = selectedPatientEncounter?.authorizationDocumentStatus?.installmentDocuments?.find((doc) => doc?.lastUpdateDt)
    const spaSigned = selectedPatientEncounter?.authorizationDocumentStatus?.spaDocuments?.find((doc) => doc?.lastUpdateDt)
    const patientChampion = selectedPatientEncounter?.patientChampion
    const selectedPatientChampion = patientChampion.find((champion) => disputeTransaction?.paymentDispute?.patientEncounterId === champion?.patientEncounterId)

    useEffect(() => {
        if (transactions && disputeId) {
            const findTransaction = transactions.find((transaction) => {
                const paymentDispute = transaction.paymentDispute
                const paymentDisputeId = paymentDispute?.paymentDisputeId
                return String(paymentDisputeId) === String(disputeId)
            })
            setDisputeTransaction(findTransaction)
        }
    }, [transactions, disputeId])


    useEffect(() => {
        if (transactions && disputeId) {
            const findTransaction = transactions.find((transaction) => {
                const paymentDispute = transaction.paymentDispute
                const paymentDisputeId = paymentDispute?.paymentDisputeId
                return String(paymentDisputeId) === String(disputeId)
            })
            setDisputeTransaction(findTransaction)
        }
    }, [transactions, disputeId])

    const originalPaymentId = disputeTransaction?.paymentDispute?.paymentId

    useEffect(() => {
        if (transactions && originalPaymentId) {
            const findPayment = transactions.find((transaction) => {
                const payment = transaction.payment
                const paymentId = payment?.paymentId
                return String(paymentId) === String(originalPaymentId)
            })
            setOriginalPaymentTransaction(findPayment)
        }
    }, [transactions, disputeTransaction])

    const paymentOriginDate = originalPaymentTransaction?.payment?.paymentInitDt

    function formattedDate(date: string | Date) {
        const originalDate = new Date(date)
        const edtDate: Intl.DateTimeFormatOptions = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'America/New_York',
            hour12: false
        }
        const formatter = new Intl.DateTimeFormat('en-US', edtDate)
        const formattedDate = formatter.format(originalDate)
        const standardOffSet = new Date(originalDate.getFullYear(), 0, 1).getTimezoneOffset()
        const currentOffset = originalDate.getTimezoneOffset()
        const dateTimeParts = formattedDate.split(',')
        const dMY = dateTimeParts[0]
        const time = dateTimeParts[1].trim()

        const timezoneAbbr = currentOffset === standardOffSet ? 'EST' : 'EDT'

        return `${dMY} ${time} ${timezoneAbbr}`
    }
    
    if (transactions[0]?.payment?.paymentId === 0) {
        return <div className="dispute-not-found">
            <h1>Dispute Response Not Available</h1>
            <p>The requested dispute informmation could not be found. Please navigate back to the Instance of Care.</p>
        </div>
    }
    return (
        <div className="dispute-doc">
        <div className="dispute-header">
            <h1>Payment Consent Acknowledgement Audit Trail</h1>
            <h3>Client: {clientName}</h3>
            <h3>Date Generated: {currentDate}</h3>
            <h3>Status: {signedInstall?.lastUpdateDt || spaSigned?.lastUpdateDt ? "Acknowledged" : "Sent"}</h3>
        </div>
    
        <h1 className="dispute-mini-header">Consent Acknowledgment History:</h1>
    
        <div>
            {authDocCreatedDt && (
                <div className="dispute-box">
                    <div className="dispute-box-icon">
                        <img src={smsIcon} alt="SMS Icon" />
                    </div>
                    <div className="dispute-box-content">
                        <h2>SMS/Text sent from FinPay to:</h2>
                        {selectedPatientChampion ? (
                            <>
                                <h3>{selectedPatientChampion?.contact?.firstName} {selectedPatientChampion?.contact?.lastName}</h3>
                                <h3>{selectedPatientChampion?.contact?.primPhoneNum}</h3>
                                <h3>{selectedPatientChampion?.contact?.email}</h3>
                                <h3>{formattedDate(authDocCreatedDt)}</h3>
                            </>
                        ) : (
                            contact && (
                                <>
                                    <h3>{contact.firstName} {contact.lastName}</h3>
                                    <h3>{contact.primPhoneNum}</h3>
                                    <h3>{contact.email}</h3>
                                    <h3>{formattedDate(authDocCreatedDt)}</h3>
                                </>
                            )
                        )}
                    </div>
                </div>
            )}
    
            {(signedInstall || spaSigned) && (
                <div className="dispute-box">
                    <div className="dispute-box-icon">
                        <img src={spaIcon} alt="SPA Icon" />
                    </div>
                    <div className="dispute-box-content">
                        <h2>Single Pay Agreement Reviewed and Acknowledged on FinPay by:</h2>
                        {selectedPatientChampion ? (
                            <>
                                <h3>{selectedPatientChampion?.contact?.firstName} {selectedPatientChampion?.contact?.lastName}</h3>
                                <h3>{selectedPatientChampion?.contact?.primPhoneNum}</h3>
                                <h3>{selectedPatientChampion?.contact?.email}</h3>
                                {signedInstall?.lastUpdateDt ? (
                                    <h3>{formattedDate(signedInstall.lastUpdateDt)}</h3>
                                ) : spaSigned?.lastUpdateDt ? (
                                    <h3>{formattedDate(spaSigned.lastUpdateDt)}</h3>
                                ) : null}
                            </>
                        ) : (
                            contact && (
                                <>
                                    <h3>{contact.firstName} {contact.lastName}</h3>
                                    <h3>{contact.primPhoneNum}</h3>
                                    <h3>{contact.email}</h3>
                                    {signedInstall?.lastUpdateDt ? (
                                        <h3>{formattedDate(signedInstall.lastUpdateDt)}</h3>
                                    ) : spaSigned?.lastUpdateDt ? (
                                        <h3>{formattedDate(spaSigned.lastUpdateDt)}</h3>
                                    ) : null}
                                </>
                            )
                        )}
                    </div>
                </div>
            )}
    
            <div className="dispute-box">
                <div className="dispute-box-icon">
                    <img src={paymentIcon} alt="Payment Icon" />
                </div>
                <div className="dispute-box-content">
                    <h2>Payment Made and Completed on FinPay by:</h2>
                    {selectedPatientChampion ? (
                        <>
                            <h3>{selectedPatientChampion?.contact?.firstName} {selectedPatientChampion?.contact?.lastName}</h3>
                            <h3>{selectedPatientChampion?.contact?.primPhoneNum}</h3>
                            <h3>{selectedPatientChampion?.contact?.email}</h3>
                            {paymentOriginDate ? <h3>{formattedDate(paymentOriginDate)}</h3> : null}
                        </>
                    ) : (
                        contact && (
                            <>
                                <h3>{contact.firstName} {contact.lastName}</h3>
                                <h3>{contact.primPhoneNum}</h3>
                                <h3>{contact.email}</h3>
                                {paymentOriginDate ? <h3>{formattedDate(paymentOriginDate)}</h3> : null}
                            </>
                        )
                    )}
                </div>
            </div>
        </div>
    </div>
    
    )
}

export default DisputeResponse