import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import React, { useState, useEffect} from "react";
import { ClientStatusCardViewModel } from "../../../../../shared/model/client-status-card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../../shared/state/store";
import { ImplementationAuthDocs } from "../models/implementation-auth-docs";
import { showErrorStatus, showStatus } from "../../../../../security/state/user-slice";
import AccessControl from "../../../../../security/components/access-control";
import { RolePageNames } from "../../../../../security/model/role-page-names";
import { LoadingOverlay } from "@finpay-development/shared-components";
import {
  AuthDocs,
  authorizationDocuments,
} from "../../../../../shared/model/auth-docs";
import AuthDocsModal from "./auth-docs-modal";
import {
  deleteAuthDocs,
  getClientAuthDoc,
  getClientAuthDocs,
  sendAuthDocsTest,
} from "../../../../state/auth-docs/implementation-auth-docs-thunk";
import { AuthDocsForm } from "../../../../models/auth-docs-form";

interface AuthDocsColumn {
  id: string;
  label: string;
  align?: "right" | "left";
  inlineSize?: number;
}

const columns: AuthDocsColumn[] = [
  { id: "documentType", label: "Document Type", inlineSize: 170 },
  {
    id: "documentTemplateURL",
    label: "Document Template URL",
    inlineSize: 200,
  },
  { id: "menu", label: "Menu", inlineSize: 25 },
];

type AuthDocsActionType = "edit" | "test" | "delete";

export default function AuthDocsView() {
  const [authDocs, setAuthDocs] = useState<ImplementationAuthDocs | AuthDocs>();
  const [addAuthDocsModalOpen, setAddAuthDocsModalOpen] = useState(false);
  const [editAuthDocsModalOpen, setEditAuthDocsModalOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [facilities, setFacilities] = useState<ClientStatusCardViewModel[]>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<any>(null);
  const [documentDataToRender, setDocumentDataToRender] = useState<AuthDocs[]>(
    []
  );

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client;
    }),
    currentClientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .currentClientEntityDetails.clientId
    ),
    allClientsWithFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .allClientsWithFacillities
    ),
    implementationAuthDocs: useSelector(
      (state: RootState) =>
        state.authDocsContext.implementationAuthDocs.authDocs
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.authDocsContext.implementationAuthDocs.isLoading
    ),
  };

  const {
    currentClientId,
    isLoading,
    allClientsWithFacilities,
    implementationAuthDocs,
  } = selectors;

  const [selectedValue, setSelectedValue] = useState<any>({
    clientId: currentClientId,
  });
  const [updateFlag, setUpdateFlag] = useState(false);

  const mergeDocumentData = (
    implementationData: ImplementationAuthDocs[],
    defaultDocumentData: AuthDocs[]
  ): AuthDocs[] => {
    const mergedData: AuthDocs[] = [];

    defaultDocumentData.forEach((defaultItem) => {
      const foundItem = implementationData.find(
        (item) =>
          item.typeOfDoc === defaultItem.typeOfDoc
      );

      if (foundItem) {
        const updatedItem = {
          ...defaultItem,
          documentUrl: foundItem.documentUrl || defaultItem.documentUrl,
          authorizationDocumentsId: foundItem.authorizationDocumentsId,
        };
        mergedData.push(updatedItem);
      } else {
        mergedData.push(defaultItem);
      }
    });
    implementationAuthDocs.forEach((item) => {
      if (
        !mergedData.find(
          (mergedItem) =>
            mergedItem.typeOfDoc === item.typeOfDoc
        )
      ) {
        mergedData.push({
          typeOfDoc: item.typeOfDoc,
          documentUrl: item.documentUrl,
          authorizationDocumentsId: item.authorizationDocumentsId,
        });
      }
    });

    return mergedData;
  };

  useEffect(() => {
    setCurrentFacilityId(selectedValue?.facilityId || null);

    if (!currentClientId && !isLoading) {
      setSelectedValue({ clientId: currentClientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, currentClientId, isLoading]);

  useEffect(
    () => {
      const fetchAuthorizationDocuments = async () => {
        if (currentClientId && !isLoading) {
          await dispatch(
            getClientAuthDocs({
              clientId: currentClientId,
              clientFacilityId: currentFacilityId,
            })
          );
        }
      };

      fetchAuthorizationDocuments();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentClientId, currentFacilityId, dispatch, updateFlag]
  );

  useEffect(() => {
    if (!isLoading && implementationAuthDocs) {
      const filterImplementationAuthDocs = implementationAuthDocs.filter(
        (doc) => {
          if (currentFacilityId) {
            return doc.clientFacilityId === currentFacilityId;
          } else {
            return !doc.clientFacilityId;
          }
        }
      );
      const mergedAuthDocsData = mergeDocumentData(
        filterImplementationAuthDocs,
        authorizationDocuments
      );
      setDocumentDataToRender(mergedAuthDocsData);
    } else if (!isLoading) {
      setDocumentDataToRender(authorizationDocuments);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, implementationAuthDocs]);

  useEffect(() => {
    if (allClientsWithFacilities) {
      setFacilities(allClientsWithFacilities);
    }
  }, [allClientsWithFacilities]);

  const handleAuthDocsDeleted = async () => {
    const authDocumentId = authDocs?.authorizationDocumentsId || 0;
    if (authDocumentId === 0) {
      return;
    }

    await dispatch(
      deleteAuthDocs({
        authorizationDocumentsId: authDocumentId,
      })
    );

    setUpdateFlag(!updateFlag);

    dispatch(showStatus("Authorization Document Deleted"));
  };

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    authDocs: ImplementationAuthDocs | AuthDocs
  ) => {
    setAnchorElement(event.currentTarget);
    setAuthDocs(authDocs);
  };

  const handleCloseMenu = (menuActionType?: AuthDocsActionType) => {
    setAnchorElement(null);
    setEditAuthDocsModalOpen(false);

    switch (menuActionType) {
      case "edit":
        const authDocId = authDocs?.authorizationDocumentsId || 0;

        if (authDocId === undefined || authDocId === 0) {
          setAddAuthDocsModalOpen(true);
        } else {
          setEditAuthDocsModalOpen(true);
          dispatch(
            getClientAuthDoc({
              authorizationDocumentsId: authDocId,
            })
          );
        }
        break;

      case "delete":
        handleAuthDocsDeleted();
        break;

      case "test":
        handleSendAuthDocs();
        break;
    }
  };

  const handleAuthDocsModalCancel = () => {
    setEditAuthDocsModalOpen(false);
    setAddAuthDocsModalOpen(false);
  };

  const handleAuthDocsModalSubmit = async (isEditAuthDocs: boolean) => {
    setEditAuthDocsModalOpen(false);
    setAddAuthDocsModalOpen(false);

    if (isEditAuthDocs) {
      dispatch(showStatus("Authorization Document Updated"));
    } else {
      dispatch(showStatus("Authorization Document Created"));
    }
    setUpdateFlag(!updateFlag);
  };

  const handleSendAuthDocs = async () => {
    const authDocumentId = authDocs?.authorizationDocumentsId || 0;

    if (authDocs) {
      try {
        const singleDocument = await dispatch(
          getClientAuthDoc({
            authorizationDocumentsId: authDocumentId,
          })
        );

        const formValues: AuthDocsForm = {
          authorizationDocumentsId:
            singleDocument?.payload?.apiResponseAuthDocs
              ?.authorizationDocumentsId,
          clientId: singleDocument?.payload?.apiResponseAuthDocs?.clientId,
          clientFacilityId: currentFacilityId,
          typeOfDoc: singleDocument?.payload?.apiResponseAuthDocs?.typeOfDoc,
          documentUrl: singleDocument?.payload?.apiResponseAuthDocs?.documentUrl,
          isSigReq: singleDocument?.payload?.apiResponseAuthDocs?.isSigReq
        };

        const sendDocResponse = await dispatch(
          sendAuthDocsTest({
            formValues,
          })
        );

        if (sendDocResponse.meta.requestStatus === 'rejected') {
          await dispatch(showErrorStatus("Failed to send Document. Please check Document URL ."))
        } else {
          await dispatch(showStatus("Authorization Document Send"));
        }

      } catch (error: any) {
        await dispatch(showErrorStatus(error.toString()));
      }
    }
  };

  const selectedClient = facilities.find(
    (client) => client.clientId === currentClientId
  );

  const handleChange = (event: any) => {

    const selectedValueArr = event?.target.value.split("-")
    const selectedOptions: any = +selectedValueArr[1];

    if (!selectedOptions) {
      throw new Error("No selected options");
    }

    const selectedObj = selectedValueArr[0] === 'client' ?
        facilities.find((client) =>
            client.clientId === selectedOptions) : facilities.find((client) =>
            client?.clientFacilities?.some((facility) => facility.facilityId === selectedOptions)
        )

    if (selectedObj?.clientFacilities) {
      const selectedFacility = selectedObj.clientFacilities.find(
        (facility) => facility.facilityId === selectedOptions
      );
      setSelectedValue({
        ...selectedObj,
        facilityId: selectedFacility?.facilityId,
      });
      setCurrentFacilityId(selectedFacility?.facilityId);
    } else {
      setSelectedValue(selectedObj);
      setCurrentFacilityId(null);
    }

    const selectedFacilityNames = Array.from(
      selectedOptions,
      (option: any) => option.value
    );

    const newFacilities = facilities.map((facility) => {
      return {
        ...facility,
        isActive: selectedFacilityNames?.includes(
          facility?.clientFacilities?.map((f) => f.facilityId)
        ),
      };
    });
    setFacilities(newFacilities);
  };

  const noAuthDocsAvailableMessage = (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          No Authorization Documents Available.
        </Typography>
      </Container>
    </Box>
  );

  return (
    <>
      <div className="authDocs-view" style={{ display: "flex" }}>
        <Box
          sx={{ flexGrow: 1, justifyContent: "flex-start", display: "flex" }}
        >
          <Grid container spacing={2} columns={16}>
            <Grid item xs={12}>
              <FormControl variant="standard" sx={{ width: 420 }}>
                <InputLabel
                  id="client-view-select-label"
                  sx={{ paddingLeft: 8 }}
                >
                  {"Client Default"}
                </InputLabel>

                <Select
                  labelId="authDocs-view-select-label"
                  id="authDocs-view-select"
                  value={selectedClient?.clientName}
                  onChange={handleChange}
                  label="Select Client"
                >
                  <MenuItem
                    value={`client-${selectedClient?.clientId}`}
                    key={`client-${selectedClient?.clientId}`}
                  >
                    {"Client Default"}
                  </MenuItem>

                  {selectedClient &&
                    selectedClient.clientFacilities?.map((facility) => (
                      <MenuItem
                        key={`facility-${facility?.facilityId}`}
                        value={`facility-${facility?.facilityId}`}
                      >
                        {facility?.facilityName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        <Menu
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          keepMounted
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={() => handleCloseMenu()}
        >
          <div>
            <>
              {authDocs?.authorizationDocumentsId ? (
                <AccessControl
                  rolePageName={RolePageNames.Clients}
                  actionName="Add New Client"
                  renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}
                >
                  <MenuItem
                    className="primary"
                    onClick={() => handleCloseMenu("edit")}
                  >
                    Edit
                  </MenuItem>
                </AccessControl>
              ) : (
                <AccessControl
                  rolePageName={RolePageNames.Clients}
                  actionName="Add New Client"
                  renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}
                >
                  <MenuItem
                    className="primary"
                    onClick={() => handleCloseMenu("edit")}
                  >
                    Add
                  </MenuItem>
                </AccessControl>
              )}
            </>
            {
              authDocs &&
              authDocs.authorizationDocumentsId !== undefined &&
              authDocs.authorizationDocumentsId !== 0 &&
              <AccessControl
              rolePageName={RolePageNames.Clients}
              actionName="Add New Client"
              renderNoAccess={() => <MenuItem disabled>Test</MenuItem>}
            >
              <MenuItem
                className="primary"
                onClick={() => handleCloseMenu("test")}
              >
                Test
              </MenuItem>
            </AccessControl>
            }


            {authDocs &&
              authDocs.authorizationDocumentsId !== undefined &&
              authDocs.authorizationDocumentsId !== 0 && (
                <AccessControl
                  rolePageName={RolePageNames.Clients}
                  actionName="Add New Client"
                  renderNoAccess={() => <MenuItem disabled>Delete</MenuItem>}
                >
                  <MenuItem
                    className="primary"
                    onClick={() => handleCloseMenu("delete")}
                  >
                    Delete
                  </MenuItem>
                </AccessControl>
              )}
          </div>
        </Menu>

        {addAuthDocsModalOpen && (
          <AuthDocsModal
            open={addAuthDocsModalOpen}
            handleAuthDocsModalCancel={handleAuthDocsModalCancel}
            handleAuthDocsModalSubmit={handleAuthDocsModalSubmit}
            handleAuthDocsDeleted={handleAuthDocsDeleted}
            selectedAuthDocs={authDocs}
            isEditAuthDocs={false}
            selectedValue={selectedValue}
          />
        )}

        {editAuthDocsModalOpen && (
          <AuthDocsModal
            open={editAuthDocsModalOpen}
            handleAuthDocsModalCancel={handleAuthDocsModalCancel}
            handleAuthDocsModalSubmit={handleAuthDocsModalSubmit}
            handleAuthDocsDeleted={handleAuthDocsDeleted}
            selectedAuthDocs={authDocs}
            isEditAuthDocs={true}
            selectedValue={selectedValue}
          />
        )}
      </div>

      {selectors.isLoading ? (
        <LoadingOverlay />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper className="mt-8">
              <Divider />

              <>
                {documentDataToRender && documentDataToRender.length > 0 ? (
                  <TableContainer>
                    <Table
                      data-testid="results"
                      className="table alternating-row"
                      size="small"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ inlineSize: column.inlineSize }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {documentDataToRender.map((mockDoc, index) => {
                          return (
                            <TableRow
                              data-testid="result-row"
                              hover
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                return (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ inlineSize: column.inlineSize }}
                                    >
                                      <Typography variant="body1">
                                        {column.id === "documentTemplateURL" &&
                                          mockDoc.documentUrl}
                                      </Typography>

                                      <Typography variant="body1">
                                        {column.id === "documentType" &&
                                          mockDoc.typeOfDoc.toUpperCase()}
                                      </Typography>

                                      {column.id === "menu" && (
                                        <>
                                          <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={(e) =>
                                              handleOpenMenu(e, mockDoc)
                                            }
                                            size="large"
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        </>
                                      )}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  noAuthDocsAvailableMessage
                )}
              </>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
