import './../../../scss/components/_forms.scss';

import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Grid, InputAdornment, TextField } from '@mui/material';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import { RecentlyViewedUser, UsersCookiesList } from '../../../shared/model/recentlyViewed';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { ImplementationClientHelper } from '../../services/implementation-client-service';
import { clearComments } from '../../state/clients/implementation-clients-slice';
import { setRecentlyViewedClient } from '../../state/clients/implementation-clients-thunk';
import { AddEditClientModal } from './add-edit-client-modal';
import ClientFilterResults from './implementation-filter-results';
import ClientFilter from './implementation-filters';

function ImplementationClientsView() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const allClients: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.allClients;
  })

  const filteredByIsActive: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredByIsActive;
  })

  const filteredByClosed: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredByClosed;
  })

  const filteredByStripeActionNeeded: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredByStripeActionNeeded;
  })

  const filteredByStripeSetupIncomplete: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredByStripeSetupIncomplete;
  })

  const filteredByReadyForActivation: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredByReadyForActivation;
  })

  const filteredBySetUpIncomplete: ClientStatusCardViewModel[] = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filteredBySetUpIncomplete;
  })

  const isLoading: boolean = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.isLoading
  })

  const userName: string = useSelector((state: RootState) => {
    return state.userContext.userProfile.userName
  })

  function handleModalClose() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    dispatch(clearComments())
  }, [dispatch])

  const recentlyViewedListFromCookie:UsersCookiesList = Cookies.getJSON('recentlyViewed')
  let recentViewedClients:ClientStatusCardViewModel[] | any =[]

  if(recentlyViewedListFromCookie) {
    const {usersCookiesList} = recentlyViewedListFromCookie
    const index = usersCookiesList?.findIndex((recentlyViewed:RecentlyViewedUser) => (
      recentlyViewed.userName === userName
      ))

      if(index >= 0 && allClients.length > 0){
        const recentlyViewedClients = ImplementationClientHelper.removeNotFoundClients(usersCookiesList[index]?.clients, allClients)
        if(recentlyViewedClients){
          recentViewedClients = recentlyViewedClients.map((clientId:number)=> {
            const client = allClients.find((client:ClientStatusCardViewModel) =>  client.clientId === clientId)
            return client;
          })
        }
      }
  }

  const ClientsView = (allClients?.length > 0) ? (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <ClientFilter
          filterCounts={{
            allClients: allClients.length,
            filteredByIsActive: filteredByIsActive.length,
            filteredByClosed: filteredByClosed.length,
            filteredByStripeActionNeeded: filteredByStripeActionNeeded.length,
            filteredByStripeSetupIncomplete: filteredByStripeSetupIncomplete.length,
            filteredByReadyForActivation: filteredByReadyForActivation.length,
            filteredBySetUpIncomplete: filteredBySetUpIncomplete.length,
            filteredByRecentViewed: recentViewedClients.length
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container direction="row" className="mb-8">
          <Grid item xs={6}>
            <Autocomplete
              id="search-typeahead"
              isOptionEqualToValue={(option, value) =>
                option.clientId === value.clientId
              }
              onChange={(event, value) => {
                dispatch(setRecentlyViewedClient(value?.clientId || -1))
                navigate(`/clients/${value?.clientId}`)
              }}
              options={allClients}
              getOptionLabel={(option) => option.clientName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="search"
                  placeholder="Search Clients"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {<SearchIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
              renderNoAccess={() => <Button disabled>Add New Client</Button>}>
              <Button onClick={() => setIsModalOpen(true)}>Add New Client</Button>
            </AccessControl>
          </Grid>
        </Grid>

        <ClientFilterResults
          clients={{
            allClients,
            filteredByIsActive,
            filteredByClosed,
            filteredByStripeActionNeeded,
            filteredByStripeSetupIncomplete,
            filteredByReadyForActivation,
            filteredBySetUpIncomplete,
            recentViewedClients
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
          renderNoAccess={() => <Button disabled>Add New Client</Button>}>
          <Button onClick={() => setIsModalOpen(true)}>Add New Client</Button>
        </AccessControl>
      </Grid>
    </Grid>
  )

  return (
    <main>
      <div className="content fullwidth px-4 py-4">
        {isLoading ? <LoadingOverlay /> : ClientsView}
        <AddEditClientModal
          open={isModalOpen}
          handleModalClose={handleModalClose}
        />
      </div>
    </main>
  );
}

export default ImplementationClientsView;
