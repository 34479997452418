import { DeleteModal, LoadingOverlay, TextField } from '@finpay-development/shared-components';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showStatus } from '../../../security/state/user-slice';
import { TableColumn } from '../../../shared/model/table-column';
import { AppDispatch } from '../../../shared/state/store';
import { EditPayer, PayerPlans, PayerRow } from '../../models/payers';
import { configDeletePayer, configGetPayers } from '../../state/admin-configuration-thunk';
import { PayersModal } from './payers-modal';

// import makeStyles from '@mui/styles/makeStyles';
function PayersGrid() {
  useEffect(() => {
    getPayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: TableColumn[] = [
    { id: "col-payername", label: "Payer Name", minWidth: 200 },
    { id: "col-payerid", label: "Payer ID", minWidth: 200 },
    { id: "col-payerstate", label: "State", minWidth: 200 },
    { id: "col-menu", label: "", minWidth: 50 },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [searched, setSearched] = useState<string>("");
  const [originalRows, setOriginalRows] = useState<PayerRow[]>();
  const [rows, setRows] = useState<PayerRow[]>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(true);
  const [selectedItem, setSelectedItem] = useState<PayerRow>();
  const [itemId, setItemId] = useState(0);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open] = React.useState(false);
  const [openSubTable, setOpenSubTable] = React.useState(-1);

  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  type MenuActionType = "add" | "edit" | "delete";

  const getPayers = async () => {
    const response: any = await dispatch(configGetPayers(paramId));
    if (response.payload) {
      const data: any = _.sortBy(response.payload, "payorName");
      setIsLoading(false);
      setOriginalRows(data);
      setRows(data);
    }
  };

  const handleNewPayerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedItem({
      payorId: 0,
      externalPayorId: "",
      payorName: "",
      stateCode: "",
      ormVersion: "",
      createUserId: "",
      createDt: "",
      lastUpdateUserId: "",
      lastUpdateDt: "",
      lastFunctionName: "",
      payorPlans: [],
    });
    setIsEditModal(false);
    setIsEditModalOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemId: number
  ) => {
    event.stopPropagation();
    setItemId(itemId);
    var el: HTMLElement = event.currentTarget;
    setAnchorEl(el);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);
    setSelectedItem(originalRows?.find((el) => el.payorId === itemId));

    switch (menuActionType) {
      case "edit":
        setIsEditModal(true);
        setIsEditModalOpen(true);
        break;
      case "delete":
        setIsDeleteModalOpen(true);
        break;
    }
  };

  async function handleModalSubmit(isEditMode: boolean) {
    setIsEditModalOpen(false);

    if (isEditMode) {
      dispatch(showStatus("Payer Updated Successfully"));
      setIsLoading(true);
      await getPayers();
    } else {
      dispatch(showStatus("Payer Added Successfully"));
      setIsLoading(true);
      await getPayers();
    }
  }

  function handleModalCancel() {
    setIsEditModalOpen(false);
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  async function handleDeleteOk() {
    const deletePayerPostBody: EditPayer = {
      paramId: paramId,
      payorId: selectedItem?.payorId,
      externalPayorId: selectedItem?.externalPayorId,
      payorName: selectedItem?.payorName,
      stateCode: selectedItem?.stateCode,
      payorPlans: selectedItem?.payorPlans,
    };
    await dispatch(configDeletePayer(deletePayerPostBody));
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    await getPayers();
  }

  const handleExpandSubTable = (rowId: number) => {
    if (openSubTable === rowId) {
      setOpenSubTable(-1);
    } else {
      setOpenSubTable(rowId);
    }
  };

  const requestSearch = (searchedVal: string) => {
    if (typeof searchedVal === 'string') {
      setSearched(searchedVal);
    }
    const filteredRows = originalRows?.filter((row) => {
      return row?.payorName?.toLowerCase()?.includes(searchedVal?.toLowerCase());
    });
    setRows(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  // const useRowStyles = makeStyles({
  //   root: {
  //     "& > *": {
  //       borderBottom: "unset",
  //     },
  //   },
  // });

  // const classes = useRowStyles();

  function getStripedStyle(index: number) {
    return { background: index % 2 ? "#F5F7F9" : "white" };
  }

  return (
    <Paper>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <Box style={{ padding: "1em", marginBottom: "1.5em" }}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Box style={{ padding: "1.5em" }}>
                <Typography variant="subtitle2">Payers</Typography>
              </Box>
            </Grid>
          <Grid item>
            <TextField
              placeholder="Search by name"
              name="searched"
              value={searched}
              onChange={(event: any) => requestSearch(event?.target?.value)}
              onBlur={() => cancelSearch()}
              startAdornment={<SearchIcon />}
            />
          </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table
              aria-label="collapsible table"
              className="table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        padding: "0.5em",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice!(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row: PayerRow, index: number) => (
                  <React.Fragment>
                    <TableRow
                      // className={classes.root}
                      style={{ ...getStripedStyle(index) }}
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        {row?.payorName}
                      </TableCell>
                      <TableCell>{row?.externalPayorId}</TableCell>
                      <TableCell>{row?.stateCode}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          disabled={row?.payorPlans?.length === 0}
                          onClick={() => handleExpandSubTable(row.payorId)}
                          style={{ fontSize: "1.5rem" }}
                        >
                          {row?.payorPlans?.length} Insurance plans{" "}
                          {open ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                        <IconButton
                          aria-haspopup="true"
                          onClick={(e) => handleMenuClick(e, row?.payorId)}
                          size="large">
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={openSubTable === row.payorId}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Plan name</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {_.sortBy(row.payorPlans, "planName").map((planRow: PayerPlans) => (
                                    <TableRow key={planRow.payorPlanId}>
                                      <TableCell component="th" scope="row">
                                        {planRow.planName}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>

            <Grid container justifyContent="space-between">
              <Grid item>
                <IconButton
                  title="Add Payer"
                  color="primary"
                  onClick={(e) => handleNewPayerClick(e)}
                  size="large">
                  <AddBoxOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    50,
                    100,
                    { value: rows?.length!, label: "All" },
                  ]}
                  component="div"
                  count={rows?.length!}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={"View"}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </TableContainer>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose()}
          >
            <MenuItem
              className="primary"
              onClick={() => handleMenuClose("edit")}
            >
              Edit
            </MenuItem>
            <MenuItem
              className="danger"
              onClick={() => handleMenuClose("delete")}
            >
              Delete
            </MenuItem>
          </Menu>
          {isEditModalOpen && (
            <PayersModal
              open={isEditModalOpen}
              isEdit={isEditModal}
              handlePayersModalCancel={handleModalCancel}
              handlePayersModalSubmit={handleModalSubmit}
              payerItem={{
                paramId: paramId,
                payorId: selectedItem?.payorId,
                externalPayorId: selectedItem?.externalPayorId,
                payorName: selectedItem?.payorName,
                stateCode: selectedItem?.stateCode,
                payorPlans: selectedItem?.payorPlans,
              }}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteModal
              open={isDeleteModalOpen}
              title="Delete Payer"
              subTitle="This action cannot be undone"
              okButtonText="Delete Forever"
              handleDeleteCancel={handleDeleteCancel}
              handleDeleteOk={handleDeleteOk}
            />
          )}
        </Box>
      )}
    </Paper>
  );
}

export default PayersGrid;
