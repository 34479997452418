import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { LoadingOverlay, Status } from '@finpay-development/shared-components';
import { RootState } from '../../../shared/state/root-reducer';
import { Grid, Table, TableHead, TableRow, TableCell, TableContainer, TableSortLabel, TableBody, Paper, Typography, Box } from '@mui/material';
import { MasterPatientLedger } from '../../components/models/payment';
import { StatusColors } from "../../../shared/enums";
import { patientLedgerErrorMessages } from "../../../shared/enums";

interface PatientLedgerProps {
  patientLedgerData: MasterPatientLedger[];
  loadStatus: boolean;
}

const headers: { key: keyof MasterPatientLedger; label: string }[] = [
  { key: 'initiatedDate', label: 'Initiated Date' },
  { key: 'type', label: 'Type' },
  { key: 'status', label: 'Status' },
  { key: 'amount', label: 'Amount' },
  { key: 'iocId', label: 'IOC ID' },
  { key: 'levelOfCare', label: 'Level of Care' },
  { key: 'transactionType', label: 'Transaction Type' },
];

const MasterLedgerGrid = (props: PatientLedgerProps) => {
  const { patientLedgerData, loadStatus } = props;
  const [orderBy, setOrderBy] = useState<keyof MasterPatientLedger>('initiatedDate');
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  const stateFields = {
    isLoadingState: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.isLoading
    )
  };

  const { isLoadingState } = stateFields;

  const handleSort = (property: keyof MasterPatientLedger) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = patientLedgerData.slice().sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return order === 'asc' ? aValue - bValue : bValue - aValue;
    } else {
      const comparison = aValue?.toString().localeCompare(bValue.toString());
      return order === 'asc' ? comparison : -comparison;
    }
  });

  const renderTableCell = (key: keyof MasterPatientLedger, row: any) => {
    if (key === 'initiatedDate' && row[key] !== undefined) {
      return new Date(row[key]).toISOString().substring(0, 10);
    } else if (key === 'amount') {
      return row[key].toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    } else if (key === 'status') {
      return (
        <Status
          text={row[key]}
          statusColor={row[key] === "Success" ? StatusColors.success : StatusColors.error}
        />
      );
    } else {
      return row[key];
    }
  };

  return(
    <Paper style={{padding: '0px', margin: '0px',  width: '100%', marginLeft: '11px', marginTop: '4px'}}>
      <Box
        style={{
          padding: "1.0em",
          marginBottom: "1.5em",
        }}
      >
        <Grid justifyContent="space-between" container direction="row">
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexWrap: "wrap",
              paddingBottom: "0.5em"
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ContentPasteIcon style={{marginRight: '6px'}} /> Transactions
            </Typography>
          </Grid>
        </Grid>
        <TableContainer style={{ maxHeight: '368px' }}>
          <Table
            data-testid="results"
            className="table alternating-row"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {headers.map(({ key, label }) => (
                  <TableCell className="fee-mainhead" key={key}>
                    <TableSortLabel
                      active={orderBy === key}
                      direction={order}
                      onClick={() => handleSort(key)}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row, index) => (
                <TableRow tabIndex={-1} key={row.iocId+index}>
                  {headers.map(({ key }, index) => (
                    <TableCell style={{padding: '6px 16px'}} key={key+row.iocId+index}>
                       {renderTableCell(key, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {sortedData.length === 0 && !isLoadingState && !loadStatus && 
                <TableRow>
                  <TableCell colSpan={7} style={{padding: '10px 16px'}}>
                    <Typography style={{ fontStyle: 'italic', fontSize: '14px' }}>
                      {patientLedgerErrorMessages.transactions}
                    </Typography>
                  </TableCell>
                </TableRow>
              }
              { isLoadingState || loadStatus && 
                <TableRow>
                  <TableCell colSpan={7} style={{padding: '10px 16px'}}>
                    <LoadingOverlay /> 
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  )
}
export default MasterLedgerGrid;
