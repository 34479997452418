import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import {
  TextField,
  DialogActionButton,
} from "@finpay-development/shared-components";
import React, { useState } from "react";
import {
  ConfigRiskThreshold,
  RiskThresholdSymbols,
} from "../../models/risk-threshold";
import { showErrorStatus } from "../../../security/state/user-slice";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus } from "../../state/admin-configuration-slice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { riskAssessmentUtils } from "../../../admissions-advisor/utils/risk-assessment-utils"; 
import {
  configSaveRiskThreshold,
  configNewRiskThreshold,
} from "../../state/admin-configuration-thunk";

interface RiskThresholdConfigModalProps {
  open: boolean;
  isEdit: boolean;
  riskThresholdItem: ConfigRiskThreshold;
  handleScholarshipCancel: () => void;
  handleScholarshipSubmit: (isEdit: boolean) => void;
}

export function RiskThresholdConfigModal(props: RiskThresholdConfigModalProps) {
  const {
    open,
    isEdit,
    riskThresholdItem,
    handleScholarshipCancel,
    handleScholarshipSubmit,
  } = props;

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const isSaveSuccessful = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.isSaveSuccessful
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );

  const symbols: RiskThresholdSymbols =
    riskAssessmentUtils.getRiskThresholdSymbols(
      riskThresholdItem?.riskThresholdCode
    );

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  const initialRiskThreshold: ConfigRiskThreshold = {
    paramId: paramId,
    riskThresholdId: riskThresholdItem.riskThresholdId,
    riskThresholdName: riskThresholdItem.riskThresholdName,
    riskThresholdCode: riskThresholdItem.riskThresholdCode,
    sortOrder: riskThresholdItem.sortOrder,
    lowRiskDefault: riskThresholdItem.lowRiskDefault,
    mediumRiskDefault: riskThresholdItem.mediumRiskDefault,
    highRiskDefault: riskThresholdItem.highRiskDefault,
  };

  const validationSchema = Yup.object({
    riskThresholdName: Yup.string().required("Risk Threshold Name is required"),
    riskThresholdCode: Yup.string().required("Risk Threshold Code is required"),
    sortOrder: Yup.number().required("Sort Order is required"),
    lowRiskDefault: Yup.number().required("Low Risk Threshold is required"),
    mediumRiskDefault: Yup.number().required(
      "Medium Risk Threshold is required"
    ),
    highRiskDefault: Yup.number().required("High Risk Threshold is required"),
  });

  const riskThresholdFormik = useFormik({
    initialValues: initialRiskThreshold,
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: validationSchema,
    validate: checkIfIsValid,
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  async function handleSave() {

    const requestBody: ConfigRiskThreshold = {
      paramId: paramId,
      riskThresholdId: riskThresholdItem.riskThresholdId,
      riskThresholdName: riskThresholdFormik.values.riskThresholdName,
      riskThresholdCode: riskThresholdFormik.values.riskThresholdCode,
      sortOrder: riskThresholdFormik.values.sortOrder,
      lowRiskDefault: riskThresholdFormik.values.lowRiskDefault,
      mediumRiskDefault: riskThresholdFormik.values.mediumRiskDefault,
      highRiskDefault: riskThresholdFormik.values.highRiskDefault,
    };

    if (isEdit) {
      await dispatch(configSaveRiskThreshold(requestBody));
    } else {
      await dispatch(configNewRiskThreshold(requestBody));
    }
  }

  function handleSaveCallback() {
    if (isSaveSuccessful) {
      handleScholarshipSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleScholarshipCancel();
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Risk Threshold</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={7} className="mt-4">
              <TextField
                name="riskThresholdName"
                label="Name"
                variant="outlined"
                value={riskThresholdFormik.values.riskThresholdName}
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                error={
                  riskThresholdFormik.touched.riskThresholdName &&
                  riskThresholdFormik.errors.riskThresholdName
                }
              />
            </Grid>
            <Grid item xs={5} className="mt-4">
              <TextField
                name="riskThresholdCode"
                label="Code"
                variant="outlined"
                value={riskThresholdFormik.values.riskThresholdCode}
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                error={
                  riskThresholdFormik.touched.riskThresholdCode &&
                  riskThresholdFormik.errors.riskThresholdCode
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3} className="mt-4">
              <TextField
                label="Sort Order"
                type="number"
                value={riskThresholdFormik.values.sortOrder}
                name="sortOrder"
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                error={
                  riskThresholdFormik.touched.sortOrder &&
                  riskThresholdFormik.errors.sortOrder
                }
              />
            </Grid>
            <Grid item xs={3} className="mt-4">
              <TextField
                label="Low Risk"
                type="number"
                value={riskThresholdFormik.values.lowRiskDefault}
                name="lowRiskDefault"
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                endAdornment={symbols.fieldAdornment}
                error={
                  riskThresholdFormik.touched.lowRiskDefault &&
                  riskThresholdFormik.errors.lowRiskDefault
                }
              />
            </Grid>
            <Grid item xs={3} className="mt-4">
              <TextField
                label="Medium Risk"
                type="number"
                value={riskThresholdFormik.values.mediumRiskDefault}
                name="mediumRiskDefault"
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                endAdornment={symbols.fieldAdornment}
                error={
                  riskThresholdFormik.touched.mediumRiskDefault &&
                  riskThresholdFormik.errors.mediumRiskDefault
                }
              />
            </Grid>
            <Grid item xs={3} className="mt-4">
              <TextField
                label="High Risk"
                type="number"
                value={riskThresholdFormik.values.highRiskDefault}
                name="highRiskDefault"
                onChange={riskThresholdFormik.handleChange}
                onBlur={riskThresholdFormik.handleBlur}
                placeholder=""
                endAdornment={symbols.fieldAdornment}
                error={
                  riskThresholdFormik.touched.highRiskDefault &&
                  riskThresholdFormik.errors.highRiskDefault
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
