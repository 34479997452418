import { ClientType, emptyClientType } from "../../../../../patient/components/models/patient-encounter";
import { emptyRiskClass, RiskClass } from "../../../../../shared/model/risk-class";

export interface ImplementationPESScript {
    pesScriptId: number;
    riskClass: RiskClass;
    scriptUrl: string;
    clientId: number;
    facilityId: number;
    client: ClientType;
}
export interface PESScriptType {
    pesScriptId: number;
    riskClass: RiskClass;
    scriptUrl: string;
    clientId: number;
    facilityId: number;
    isRiskClassOverriden?: boolean;
    client: ClientType;
}

export const emptyImplementationPESScript: ImplementationPESScript = {
    pesScriptId:0,
    riskClass: emptyRiskClass,
    scriptUrl: "",
    clientId: 0,
    facilityId: 0,
    client: emptyClientType,
};

export const emptyPESScriptType: PESScriptType = {
    pesScriptId:-1,
    riskClass: emptyRiskClass,
    scriptUrl: "",
    clientId: -1,
    facilityId: -1,
    isRiskClassOverriden: false,
    client: emptyClientType,
};

