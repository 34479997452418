import { Button, LabelIconCard, LoadingOverlay, Toggle, TwoRowDetailsGrid } from '@finpay-development/shared-components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { statusNames } from '../../implementation-specialist/misc/client-and-facility-status';
import { getClient, getClientKPIs } from '../../implementation-specialist/state/clients/implementation-clients-thunk';
import { getClientFacilities } from '../../implementation-specialist/state/facility/implementation-facility-thunk';
import AccessControl from '../../security/components/access-control';
import { RolePageNames } from '../../security/model/role-page-names';
import { formatNumberToUSD } from '../../shared/calculators';
import { blankClient } from '../../shared/model/client';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { Utils } from '../../shared/utils';

function ClientDashboard() {
  const dispatch = useDispatch<AppDispatch>();

  const [isCurrentDay, setIsCurrentDay] = useState(true);

  const selectors = {
    allowedClient: useSelector((state: RootState) => {
      return state.userContext.userProfile.allowedClients[0]
    }),
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client
    }),
    clientKPI: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.clientKPI
    }),
    isLoadingClient: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoading
    }),
    facilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationFacility.facilities
    }),
    isLoadingFacilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationFacility.isLoading
    }),
    isLoadingClientKPIs: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoadingClientKPIs
    })
  }
  const {
    allowedClient, client, clientKPI, isLoadingClient,
    facilities, isLoadingFacilities, isLoadingClientKPIs
  } = selectors;

  useEffect(() => {
    if (allowedClient?.clientId && !isLoadingClient && ((_.isEqual(client, blankClient)))) {
      dispatch(getClient(allowedClient.clientId))
    }
    // eslint-disable-next-line
  }, [dispatch, allowedClient]);

  useEffect(() => {
    if (client?.clientId) {
      dispatch(getClientKPIs({clientId: client?.clientId, months: (isCurrentDay ? 1 : 12)}))
    }
    if (client?.clientId && !isLoadingFacilities) {
      dispatch(getClientFacilities(client.clientId));
    }
    // eslint-disable-next-line
  }, [dispatch, client])

  useEffect(() => {
    if (client?.clientId) {
      dispatch(getClientKPIs({clientId: client?.clientId, months: (isCurrentDay ? 1 : 12)}))
    }
    // eslint-disable-next-line
  }, [dispatch, isCurrentDay]) // on toggle click, re-fetch client kpis

  const formRef: any = useRef();

  const numberFormatter = (inputNumber: number) => {
    return (inputNumber < 1000) ? formatNumberToUSD(inputNumber) : '$'+Utils.abbreviateNum(inputNumber);
  }

  const navigate = useNavigate();

  const header = (
    <div className="mb-4">
      <Typography variant="h5">
        <Box fontWeight="fontWeightBold">
          {client?.clientName}
        </Box>
      </Typography>
    </div>
  )

  const cardGrid = (
    <div className="mb-6">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LabelIconCard
            content={numberFormatter(clientKPI?.downPmts || 0).toString()} // down payments
            icon={<AttachMoneyIcon />}
            label="Down Payments"
            showIconBackground={false}
          />
        </Grid>
        <Grid item xs={4}>
          <LabelIconCard
            content={numberFormatter(clientKPI?.transactions || 0).toString()} // transactions
            icon={<AccountCircleIcon />}
            label="Gross Transactions"
            showIconBackground={false}
          />
        </Grid>
      </Grid>
    </div>
  )

  const isFacilitySetUp = (facilities?.length > 0) && (facilities[0]?.length > 0) &&
    facilities[0][0]?.workflow?.workflowStatus?.workflowStatusDesc === statusNames.ACTIVE;

  const initialValues = {
    isFilteredForToday: true,
  }

  const validationSchema = Yup.object({
    isFilteredForToday: Yup.boolean(),
  })

  function checkIfIsValid(value: { isFilteredForToday: boolean }) {
    setIsCurrentDay(value.isFilteredForToday);
    validationSchema
      .validate(value)
  }

  return (
    <main>
      {isLoadingClient ? (
        <LoadingOverlay />
      ) : (
        <div className="content px-4 py-4">
          {header}
          {!isFacilitySetUp && (
            <div className="mb-6">
              <AccessControl rolePageName={RolePageNames.ClientPortal} actionName="Complete Facility Set Up"
                renderNoAccess={() => <Button disabled>Complete Facility Set Up</Button>}>
                <Button onClick={() => navigate('/client/facilities')}>Complete Facility Set Up</Button>
              </AccessControl>
            </div>
          )}
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              onSubmit={() => {}}
              validationSchema={validationSchema}
              validate={checkIfIsValid}
            >
              {(formik) => (
                <Form>
                  <div className="mb-6">
                    <Toggle
                      leftText="Today"
                      rightText="Last 12 Months"
                      name="isFilteredForToday"
                      formik={formik}
                      value={formik.values.isFilteredForToday}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          {isLoadingClientKPIs ? (
            <LoadingOverlay />
          ) : (
            <>
              {cardGrid}
              <Paper className="mb-4 px-4 py-4">
                <TwoRowDetailsGrid
                  title="Patients"
                  column1Title="Converted"
                  column1Value={clientKPI?.converted?.toString() || '0'}
                  column2Title="Full Payments"
                  column2Value={clientKPI?.fullPayments?.toString() || '0'}
                  column3Title="Paid Off"
                  column3Value={clientKPI?.paidOff?.toString() || '0'}
                />
              </Paper>
            </>
          )}
        </div>
      )}
  </main>
  )
}

export default ClientDashboard
