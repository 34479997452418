import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { useFormik } from 'formik';
import {
  Button,
  Checkbox,
  LoadingOverlay,
  TextField,
} from "@finpay-development/shared-components";
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ErrorIcon from '@mui/icons-material/Error';
import * as Yup from 'yup';
import { configGetLOC } from "../../../../../admin-configuration/state/admin-configuration-thunk";
import { showStatus } from "../../../../../security/state/user-slice";
import { getClientLevelsOfCare, updateLevelofCare, deleteLevelofCare, addLevelofCare, addRLOS, updateRLOS, deleteRLOS } from "../../../../../admissions-advisor/state/estimator-thunk";
import { Utils } from '../../../../../shared/utils';
import { setLevelsOfCare } from '../../../../../admissions-advisor/state/estimator-slice';
import { ClientStatusCardViewModel } from "../../../../../shared/model/client-status-card";
import { EstLevelOfCare, FacilityLocRlos } from "../../../../../admissions-advisor/models/estimator";
import ClientFacilityDropDown from "../../../../../shared/components/client-facility-dropdown";
import { LevelOfCare } from '../../../../../admin-configuration/models/level-of-care';
import { any } from 'lodash/fp';
import { showErrorStatus } from '../../../../../security/state/user-slice';

interface TableHeaders {
  label: string,
  id: string
}

interface SelectLOCEvent extends Event {
  target: EventTarget & {
    checked?: boolean;
  };
}

function PreselectLevelsOfCare() {
  const selectors = {
    currentClientId: useSelector((state: RootState) => {
        return state.implementationContext.implementationSpecialistClient.currentClientEntityDetails.clientId;
    }),
    masterLevelsOfCareState: useSelector((state: RootState) => {
      return state.adminContext.adminConfigurationContext?.levelsOfCare;
    }),
    clientLevelsOfCareState: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare;
    }),
    isLoadingClientLoc: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext?.isLoading.isLoadingClientLoc;
    }),
    fullClient: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client;
    }),
    allClientsWithFacilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.allClientsWithFacillities;
    }),
  }

  const dispatch = useDispatch<AppDispatch>();
  const paramId = -2;
  const initialLoadLoc = useRef(false);
  const initialLoadClientLoc = useRef(false);
  const [facilities, setFacilities] = useState<ClientStatusCardViewModel[]>([]);
  const [rerunStatus, setRerunStatus] = useState<boolean>(false);
  const [loadingLevels, setLoadingLevels] = useState<boolean>(false);
  const [activeRatesRemoved, setActiveRatesRemoved] = useState<number[]>([]);
  const [initialLocList, setInitialLocList] = useState<EstLevelOfCare[]>([]);
  const [clientUpdatedLOCList, setclientUpdatedLOCList] = useState<EstLevelOfCare[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const {currentClientId, clientLevelsOfCareState, masterLevelsOfCareState, fullClient, allClientsWithFacilities, isLoadingClientLoc} = selectors;
  const [selectedClient, setSelectedClient] = useState<ClientStatusCardViewModel | null>(null);
  const [currentFacilityId, setCurrentFacilityId] = useState<number>();

  useEffect(() => {
  if (allClientsWithFacilities) {
      setFacilities(allClientsWithFacilities);
      const selectedClients: any = allClientsWithFacilities.find((client) => client.clientId === currentClientId);
      setSelectedClient(selectedClients!);
      setCurrentFacilityId(selectedClients.clientFacilities[0]?.facilityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClientsWithFacilities]);

  useEffect(() => {
    const getLevelsOfCareByClient = async () => {
      const config: any = {
        paramId: paramId,
        clientId: fullClient.clientId,
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      await dispatch(getClientLevelsOfCare(config));
    };
    if ((masterLevelsOfCareState !== undefined) && (masterLevelsOfCareState.length === 0) ) {
      dispatch(configGetLOC(paramId));
    }
    if (masterLevelsOfCareState?.length > 0 && !initialLoadLoc.current) {
      getLevelsOfCareByClient()
      initialLoadLoc.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, masterLevelsOfCareState, paramId]);

  useEffect(()=>{
    const mergeMasterList = () => {
      const masterList: LevelOfCare[] =  Utils.deepClone(masterLevelsOfCareState);
      const clientList: EstLevelOfCare[] =  Utils.deepClone(clientLevelsOfCareState);
      const updatedItems: any[] = masterList.map((masterItem) => {
        const matchingClientLOC = clientList.find(
          (clientItem) => clientItem.cfgLevelOfCareId === masterItem.levelOfCareId && clientItem.clientFacilityId === currentFacilityId
        );
        if (matchingClientLOC) {
          
          return {
            ...masterItem,
            preselected: true,
            cfgLevelOfCareId: matchingClientLOC.cfgLevelOfCareId,
            clientFacilityId: matchingClientLOC.clientFacilityId,
            facilityLevelOfCareCode: matchingClientLOC.facilityLevelOfCareCode,
            facilityLevelOfCareName: matchingClientLOC.facilityLevelOfCareName,
            facilityLevelOfCareId: matchingClientLOC.facilityLevelOfCareId,
            facilityLocRlos: matchingClientLOC.facilityLocRlos,
            facilityPayorLocRateLos: matchingClientLOC.facilityPayorLocRateLos,
            minRevenueAmount: matchingClientLOC.minRevenueAmount ? matchingClientLOC.minRevenueAmount : 0,
            minRevenueFlatRate: matchingClientLOC.minRevenueFlatRate ? matchingClientLOC.minRevenueFlatRate : false,
            perDiemOperatingCost: matchingClientLOC.perDiemOperatingCost ? matchingClientLOC.perDiemOperatingCost : 0,
            sortOrder: matchingClientLOC.sortOrder ? matchingClientLOC.sortOrder : 999,
          };
        }
        return {
          ...masterItem,
          preselected: false,
          cfgLevelOfCareId: masterItem.levelOfCareId,
          facilityLevelOfCareId: null,
          clientFacilityId: currentFacilityId,
          facilityLevelOfCareCode: null,
          facilityLevelOfCareName: null,
          facilityLocRlos: {
            losDays: 0,
          },
          facilityPayorLocRateLos: {
            cfgPayorPlanId: null
          },
          minRevenueAmount: 0,
          minRevenueFlatRate: false,
          perDiemOperatingCost: 0,
          sortOrder: 999,
        };
      });
      const formedList = sortPreselectedByOrder(updatedItems);
      setclientUpdatedLOCList(formedList);
      setInitialLocList(formedList);
      initialLoadClientLoc.current = true;
      setIsLoading(false);
    };
    
    if (clientLevelsOfCareState?.length > 0 && masterLevelsOfCareState.length > 0 && currentClientId && !initialLoadClientLoc.current && currentFacilityId && !isLoadingClientLoc) {
      mergeMasterList();
      let buildingLOC = [] as EstLevelOfCare[];
        clientLevelsOfCareState?.forEach((levelOfCare: EstLevelOfCare) => {
          const clientLevelsOfCare = {
            ...levelOfCare,
            checked: false
          } as EstLevelOfCare
          buildingLOC.push(clientLevelsOfCare);
        })
        dispatch(setLevelsOfCare(buildingLOC));
    }
    if(clientLevelsOfCareState?.length === 0 && masterLevelsOfCareState.length > 0) {
      const masterList: LevelOfCare[] =  Utils.deepClone(masterLevelsOfCareState);
      const updatedItems: any[] = masterList.map((masterItem) => {
        return {
          ...masterItem,
          preselected: false,
          cfgLevelOfCareId: masterItem.levelOfCareId,
          clientFacilityId: currentFacilityId,
          facilityLevelOfCareCode: null,
          facilityLevelOfCareName: null,
          facilityLocRlos: {
            losDays: 0,
          },
          facilityPayorLocRateLos: {
            cfgPayorPlanId: null
          },
          minRevenueAmount: 0,
          minRevenueFlatRate: false,
          perDiemOperatingCost: 0,
          sortOrder: 999,
        };
      });
      initialLoadClientLoc.current = true;
      setclientUpdatedLOCList(updatedItems);
      setInitialLocList(updatedItems);
      setIsLoading(false);
    }

    if(rerunStatus) {
      mergeMasterList();
      setRerunStatus(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientLevelsOfCareState, currentClientId, masterLevelsOfCareState, rerunStatus, currentFacilityId, isLoadingClientLoc])  

  const tableHeaderLabels: TableHeaders[] =  [
    { label: 'Level of Care', id: 'facilityLevelOfCare'  },
    { label: 'Override Name', id: 'facilityLevelOfCareName' },
    { label: 'Override Code', id: 'facilityLevelOfCareCode' },
    { label: 'Operating Cost', id: 'perDiemOperatingCost' },
    { label: 'Min. Revenue', id: 'minRevenueAmount' },
    { label: 'RLOS', id: 'facilityLocRlos' },
    { label: 'Flat Rate MRA', id: 'minRevenueFlatRate' },
    { label: 'Sort Order', id: 'sortOrder' },
    { label: 'Rates', id: 'active' },
  ]

  const validationLOCDetailSchema = Yup.object().shape({
    clientId: Yup.number(),
    selectedLOCList: Yup.array()
    .of(
      Yup.object().shape({
        facilityLocRlos: Yup.object().shape({
          losDays: Yup.number()
          .required("RLOS Required")
          .min(1,"Must be greater than 1"),
        })
      })
    )
  });

  const setInitialValues = () => {
    let clonedLOC = Utils.deepClone(clientUpdatedLOCList);
    const initialValues = {
      clientId: currentClientId,
      selectedLOCList: sortPreselectedByOrder(clonedLOC),
    }
    return initialValues;
  }

  const PreselectLOCFormik = useFormik({
    initialValues: setInitialValues(),
    enableReinitialize: true,
    validationSchema: validationLOCDetailSchema,
    onSubmit: () => {},
  });

  const handleLOCSelectionChange = useCallback((e: SelectLOCEvent, cfgLevelOfCareId: number) => {
    PreselectLOCFormik.handleChange(e);
    let clonedRemovedRates = activeRatesRemoved.length > 0 ? Utils.deepClone(activeRatesRemoved) : [];
    if (!e.target?.checked) {
      const locIndex = PreselectLOCFormik.values.selectedLOCList.findIndex((loc: EstLevelOfCare) => loc.cfgLevelOfCareId === cfgLevelOfCareId);
      if (PreselectLOCFormik.values.selectedLOCList[locIndex].facilityPayorLocRateLos?.cfgPayorPlanId > 0) {
        if(!clonedRemovedRates.includes(cfgLevelOfCareId)) {
          clonedRemovedRates.push(cfgLevelOfCareId);
        }
        setActiveRatesRemoved(clonedRemovedRates);
      }
    } else {
      setActiveRatesRemoved(clonedRemovedRates => clonedRemovedRates.filter(code => code !== cfgLevelOfCareId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ PreselectLOCFormik, activeRatesRemoved])

  const savePreselectLOCList = async () => {
    setLoadingLevels(true);
    let updatedInitialLOCList: EstLevelOfCare[] = Utils.deepClone(initialLocList);
    let modifiedLOCs: EstLevelOfCare[] = [];
    let levelOfCareState: EstLevelOfCare[] = Utils.deepClone(clientLevelsOfCareState);
    let locDeletedArray: number[] = [];
    const initialLOCListArr: EstLevelOfCare[] = Utils.deepClone(initialLocList);
    const currentLOCList: EstLevelOfCare[] = Utils.deepClone(PreselectLOCFormik.values.selectedLOCList);
    const listOfRemovedRates = Utils.deepClone(activeRatesRemoved);

    let selectedLoc = currentLOCList.filter((loc)=> (loc.preselected === true && loc.facilityLocRlos?.losDays == 0 || loc.facilityLocRlos?.losDays == null))

    if (selectedLoc.length >= 1) {
      setLoadingLevels(false);
      const errorMessage: string = "RLOS value() is missing";
       return dispatch(showErrorStatus(errorMessage));
    } 

    try {
      for (const initialLOC of initialLOCListArr) {
        let updatedLOC = currentLOCList.find((updatedLOC) => updatedLOC.cfgLevelOfCareId === initialLOC.cfgLevelOfCareId);
        const initialIndex = updatedInitialLOCList.findIndex((loc: EstLevelOfCare) => loc.cfgLevelOfCareId === updatedLOC?.cfgLevelOfCareId);
        if (initialLOC.preselected) {
          if (updatedLOC && updatedLOC.preselected) {
            if (JSON.stringify(initialLOC) !== JSON.stringify(updatedLOC)) {
              updatedLOC.cfgLevelOfCareId = initialLOC.facilityLevelOfCareId!;
              updatedInitialLOCList[initialIndex] = updatedLOC;
              modifiedLOCs.push(updatedLOC);
              const { facilityLevelOfCareId, ...updatedLOCWithoutFacilityLevelOfCareId } = updatedLOC;
              const updatedSavePayload = sanitizeLOCValues(updatedLOCWithoutFacilityLevelOfCareId);
              const updateResults = await dispatch(updateLevelofCare(updatedSavePayload));
              updatedInitialLOCList[initialIndex].cfgLevelOfCareId = updateResults.payload.cfgLevelOfCareId;
              updatedInitialLOCList[initialIndex].facilityLevelOfCareId = updateResults.payload.facilityLevelOfCareId;
              updatedInitialLOCList[initialIndex].checked = false;
              updatedInitialLOCList[initialIndex].clientFacilityId = updateResults.payload.clientFacilityId;

              const formatedRLOS = Number(updatedLOC?.facilityLocRlos?.losDays!);
              const formatedfacilityLocRlosId = initialLOC.facilityLocRlos?.facilityLocRlosId;
              if ((initialLOC.facilityLocRlos?.losDays !== formatedRLOS)) {
                const rlosPayload: FacilityLocRlos = {
                  facilityLocRlosId: formatedfacilityLocRlosId,
                  facilityLevelOfCareId: updatedLOC?.facilityLevelOfCareId!.toString(),
                  losDays: formatedRLOS,
                  clientFacilityId: updatedLOC.clientFacilityId
                }
                if (formatedfacilityLocRlosId) {
                  const updateRLOSResponse = await dispatch(updateRLOS(rlosPayload));
                  updatedInitialLOCList[initialIndex].facilityLocRlos = {
                    facilityLocRlosId: updateRLOSResponse.payload.facilityLocRlosId,
                    facilityLevelOfCareId: updateRLOSResponse.payload.facilityLevelOfCareId,
                    losDays: updateRLOSResponse.payload.losDays,
                    clientFacilityId: updateRLOSResponse.payload.clientFacilityId,
                  };
                } else {
                  delete rlosPayload.facilityLocRlosId
                  const rlosResponse = await dispatch(addRLOS(rlosPayload));
                  updatedInitialLOCList[initialIndex].facilityLocRlos = {
                    facilityLocRlosId: rlosResponse.payload.facilityLocRlosId,
                    facilityLevelOfCareId: rlosResponse.payload.facilityLevelOfCareId,
                    losDays: rlosResponse.payload.losDays,
                    clientFacilityId: rlosResponse.payload.clientFacilityId,
                  };
                }
              }
            }
          }
          if (updatedLOC && !updatedLOC.preselected) {
            updatedInitialLOCList[initialIndex] = updatedLOC;
            modifiedLOCs.push(updatedLOC);
            locDeletedArray.push(updatedLOC?.cfgLevelOfCareId!);
            await dispatch(deleteLevelofCare(updatedLOC));
            const filteredListOfRemovedRates = listOfRemovedRates.filter((rate: any) => rate !== updatedLOC?.cfgLevelOfCareId!);
            setActiveRatesRemoved(filteredListOfRemovedRates);
          }
        } else {
          if (updatedLOC && updatedLOC.preselected) {
            updatedInitialLOCList[initialIndex] = updatedLOC;
            modifiedLOCs.push(updatedLOC);
            delete updatedLOC.facilityLevelOfCareId;
            const updatedSavePayload = sanitizeLOCValues(updatedLOC);
            const res = await dispatch(addLevelofCare(updatedSavePayload));
            updatedInitialLOCList[initialIndex].cfgLevelOfCareId = res.payload.cfgLevelOfCareId;
            updatedInitialLOCList[initialIndex].facilityLevelOfCareId = res.payload.facilityLevelOfCareId;
            updatedInitialLOCList[initialIndex].checked = false;
            updatedInitialLOCList[initialIndex].clientFacilityId = res.payload.clientFacilityId;
  
            const formatedRLOS = Number(updatedLOC?.facilityLocRlos?.losDays!);
            if (formatedRLOS > 0) {
              const rlosPayload: FacilityLocRlos = {
                facilityLevelOfCareId: updatedLOC?.facilityLevelOfCareId!.toString(),
                losDays: formatedRLOS,
                clientFacilityId: updatedLOC.clientFacilityId
              }
              const rlosResponse = await dispatch(addRLOS(rlosPayload));
              updatedInitialLOCList[initialIndex].facilityLocRlos = {
                facilityLocRlosId: rlosResponse.payload.facilityLocRlosId,
                facilityLevelOfCareId: rlosResponse.payload.facilityLevelOfCareId,
                losDays: rlosResponse.payload.losDays,
                clientFacilityId: rlosResponse.payload.clientFacilityId,
              };
            }
          }
        }      
      }
    
      for (const changedLOC of modifiedLOCs) {
        const stateIndex = levelOfCareState.findIndex((loc: EstLevelOfCare) => loc.clientFacilityId === changedLOC?.clientFacilityId && loc.cfgLevelOfCareId === changedLOC?.cfgLevelOfCareId);
        if (stateIndex >= 0) {
          levelOfCareState[stateIndex] = changedLOC;
        } else {
          if (locDeletedArray.length > 0) {
            levelOfCareState = levelOfCareState.filter(loc => !locDeletedArray.includes(loc.facilityLevelOfCareId!));
          } else {
            levelOfCareState.push(changedLOC);
          }
        }
      }
  
      dispatch(setLevelsOfCare(levelOfCareState));
      setInitialLocList(updatedInitialLOCList);
      setclientUpdatedLOCList(updatedInitialLOCList);
      setLoadingLevels(false);
      dispatch(showStatus("Preselected Levels of Care Updated Successfully"))
    } catch (error) {
      // Handle errors if necessary
      setLoadingLevels(false);
      dispatch(showStatus("Error: Unable to Update Preselected Levels of Care"))
      console.error(error);
    }
  };

  const truncateLabel = useCallback((text: string, maxLength: number) => {
      return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // using this local function to remove blanks strings and replace w/ null values
  const sanitizeLOCValues = (updatedLOC: EstLevelOfCare): EstLevelOfCare => {
    const sanitizeField = (field: string | null) => (field === "" ? null : field);
  
    return {
      ...updatedLOC,
      facilityLevelOfCareCode: sanitizeField(updatedLOC.facilityLevelOfCareCode!),
      facilityLevelOfCareName: sanitizeField(updatedLOC.facilityLevelOfCareName!),
    };
  };

  const handleFacilityChange = useCallback((event: any) => {
    const selectedOptions = event?.target?.value;
    if (!selectedOptions) {
      throw new Error("No selected options")
    }

    const selectedObj = facilities.find((client) =>
      client?.clientFacilities?.some((facility) => facility.facilityId === selectedOptions)
    );
    if (selectedObj?.clientFacilities) {
      const selectedFacility = selectedObj.clientFacilities.find((facility) => facility.facilityId === selectedOptions);
      setCurrentFacilityId(selectedFacility?.facilityId);
    }

    setRerunStatus(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ facilities])

  function sortPreselectedByOrder(array: any[]): any[] {
      return array.sort((a, b) => {
          // First, sort by 'preselected' property
          if (a.preselected && !b.preselected) {
              return -1;
          } else if (!a.preselected && b.preselected) {
              return 1;
          } else {
              // If 'preselected' properties are equal, then sort by 'sortOrder'
              if (a.sortOrder < b.sortOrder) {
                  return -1;
              } else if (a.sortOrder > b.sortOrder) {
                  return 1;
              } else {
                  return 0;
              }
          }
      });
  }

  const tableCellHeader = (
    <TableHead>
      <TableRow>
        {tableHeaderLabels.map((tableHeaders: TableHeaders, index) => (
          <TableCell align={tableHeaders.id !== 'facilityLevelOfCare' ? 'center' : 'left'} key={`${tableHeaders.id}${index}`} className="fee-mainhead nowrap">
            {tableHeaders.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const levelsOfCareBox = (
    <Box
      style={{
        padding: "1.0em",
        marginBottom: "1.5em"
      }}
    >
      <Grid container justifyContent="space-between" direction="row" style={{ margin: "8px 0px 24px", width: "100%" }}>
        <Grid
          item
          justifyContent="center"
          direction="column"
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingBottom: "0.5em",
            flexDirection: "row",
            justifyContent: "normal",
            width: "100%"
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              flex: "1",
              marginBottom: "0",
              display: "flex"
            }}
          >
            <PermContactCalendarIcon sx={{ marginRight: "0.25em" }} />Preselect Levels of Care
          </Typography>
          <ClientFacilityDropDown selectedClient={selectedClient!} handleChange={handleFacilityChange} initFacility={currentFacilityId!}/>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <TableContainer sx={{height: 380}}>
          <Table className="table alternating-row" stickyHeader  sx={{height: "max-content"}}>
            {tableCellHeader}
            <TableBody>
              {clientUpdatedLOCList.length > 0 && PreselectLOCFormik.values.selectedLOCList.map((loc: EstLevelOfCare, index: number) => (
                <TableRow 
                  key={`${loc.cfgLevelOfCareId}-${index}`}
                >
                  <Tooltip title={loc.levelOfCareName!.length > 24 ? loc.levelOfCareName! : ''}>
                    <TableCell align="center" className="input-checkbox-loc">
                        <Checkbox
                            checked={PreselectLOCFormik.values.selectedLOCList[index].preselected}
                            id={`${currentClientId}_loc_${loc.cfgLevelOfCareId}`}
                            name={`selectedLOCList.${index}.preselected`}
                            label={`${truncateLabel(loc.levelOfCareName!, 24)} - ${loc.levelOfCareCode}`}
                            onChange={ (e: SelectLOCEvent) => handleLOCSelectionChange(e, loc.cfgLevelOfCareId!)}
                        />
                    </TableCell>
                      </Tooltip>
                  {PreselectLOCFormik.values.selectedLOCList[index].preselected ?
                    <>
                      <TableCell align="center">
                        <TextField
                          name={`selectedLOCList.${index}.facilityLevelOfCareName`}
                          placeholder="Name Override"
                          value={PreselectLOCFormik.values.selectedLOCList[index].facilityLevelOfCareName}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                        />    
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          name={`selectedLOCList.${index}.facilityLevelOfCareCode`}
                          placeholder="Code Override"
                          value={PreselectLOCFormik.values.selectedLOCList[index].facilityLevelOfCareCode}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                        />    
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          name={`selectedLOCList.${index}.perDiemOperatingCost`}
                          placeholder="0"
                          value={PreselectLOCFormik.values.selectedLOCList[index].perDiemOperatingCost}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          name={`selectedLOCList.${index}.minRevenueAmount`}
                          placeholder="0"
                          value={PreselectLOCFormik.values.selectedLOCList[index].minRevenueAmount}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          name={`selectedLOCList.${index}.facilityLocRlos.losDays`}
                          placeholder="0"
                          value={PreselectLOCFormik.values.selectedLOCList[index].facilityLocRlos?.losDays || ''}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                          className={
                            PreselectLOCFormik.errors.selectedLOCList &&
                            PreselectLOCFormik.errors.selectedLOCList[index]
                              ? 'error-border'
                              : ''
                          }
                        />
                      </TableCell> 
                      <TableCell align="center">
                        <TextField
                          select={true}
                          placeholder="Flat Rate MRA"
                          name={`selectedLOCList.${index}.minRevenueFlatRate`}
                          value={PreselectLOCFormik.values.selectedLOCList[index].minRevenueFlatRate}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={() => {}}
                          className="state-field"
                        >
                          <MenuItem value={'true'}>Yes</MenuItem>
                          <MenuItem value={'false'}>No</MenuItem>
                        </TextField>
                      </TableCell>
                      <TableCell align="center">
                        <TextField
                          type="number"
                          name={`selectedLOCList.${index}.sortOrder`}
                          placeholder="0"
                          value={PreselectLOCFormik.values.selectedLOCList[index].sortOrder}
                          onChange={PreselectLOCFormik.handleChange}
                          onBlur={PreselectLOCFormik.handleBlur}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography style={{fontSize: "14", color: "#38b079", fontWeight: "bold", display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                            {PreselectLOCFormik.values.selectedLOCList[index].facilityPayorLocRateLos?.cfgPayorPlanId > 0 ? 
                              <>
                                <CheckCircleOutlineIcon className="active-icon" /> 
                                <span style={{color: "#38b079"}}>Yes</span>
                              </>
                            : 
                              <>
                                <DoNotDisturbAltIcon className="inactive-icon" /> 
                                <span style={{color: "#7f8994"}}>No</span>
                              </>
                            }                          
                        </Typography>
                      </TableCell>
                      
                    </>
                  :
                    <>
                      { activeRatesRemoved.includes(loc.facilityLevelOfCareId!) ?
                        <>
                          <TableCell align="center" style={{textAlign: "center", color: "red", fontWeight: "bold"}} colSpan={8}>
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                              <ErrorIcon style={{fontSize: "16px"}} />
                              <span style={{paddingLeft: "4px"}}>Deselecting a LOC with rates.</span>
                              <span style={{color: "#111", paddingLeft: "4px"}}>All active rates and lengths of stay will be removed upon save.</span>
                            </div>
                          </TableCell>
                        </>
                      :
                        <>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </>
                      }
                    </>
                  }
                </TableRow>
            ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  )

  return (
    <> {isLoading ? (
      <LoadingOverlay />
    ) : (
      <Grid container justifyContent="space-between" direction="row" spacing={2}>
        <Grid item direction="column" xs={12}>
          <form onSubmit={PreselectLOCFormik.handleSubmit}>
            <Paper style={{boxShadow: "none"}}>
              {levelsOfCareBox}
              {clientUpdatedLOCList.length === 0 && 
                <Typography variant="body2">Currently no levels of care exist. </Typography>
              }
              <Box display="flex" justifyContent="flex-end" marginTop={-4} padding={"20px"}>
                <Button onClick={() => savePreselectLOCList()} loading={loadingLevels}>
                  Save
                </Button>
              </Box>
            </Paper>
          </form>
        </Grid>
      </Grid>
       )}
    </>
  );
}

export default PreselectLevelsOfCare;
