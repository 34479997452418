import { Client } from "../../shared/model/client";
import {emptyUserRole, UserRole} from './user-role';
import { AwsTokenState } from "./aws-token-state";
import { Navigation } from "./navigation";

export class UserProfile {
  userId!: number;
  userName!: string;
  firstName!: string;
  lastName!: string;
  userScope!: string;
  userRole!: UserRole;
  SubNav!: Navigation[];
  defaultRoute!: string;
  landingPageRoute!: string;
  agreedToTC!: boolean;
  isFacilityLvlAccess!: boolean;
  isActive!: boolean;
  isPatient!: boolean;
  allowedClients!: Client[];
  tokenState!: AwsTokenState;
}

export const emptyUserProfile: UserProfile = {
  userId: -1,
  userName: "",
  firstName: "",
  lastName: "",
  userScope: "",
  SubNav: [],
  defaultRoute: "",
  userRole: emptyUserRole,
  landingPageRoute: "",
  agreedToTC: false,
  isFacilityLvlAccess: false,
  isActive: false,
  isPatient: false,
  allowedClients: [],
  tokenState: {
    jwtAccessToken: "",
    jwtIdToken: ""
  }
};
