export interface TimingRisk {
  timingRiskId: number;
  timingRiskName: string;
}

export interface PayorRisk {
  payorRiskId: number;
  payorRiskName: string;
}

export const timingRisk: TimingRisk[] = [
  {
    timingRiskId: 1,
    timingRiskName: "Precare",
  },
  {
    timingRiskId: 2,
    timingRiskName: "In Service",
  },
  {
    timingRiskId: 3,
    timingRiskName: "Discharged",
  },
  //need to change EBO and BDR to match name in the db. need to also change patient encounter microservice
  // TODO: use the metadata endpoint / redux for this
  {
    timingRiskId: 4,
    timingRiskName: "Extended Business Office"
  },
  {
    timingRiskId: 5,
    timingRiskName: "Bad Debt Recovery"
  }
];

export const payorRisk: PayorRisk[] = [
  {
    payorRiskId: 1,
    payorRiskName: "In Network",
  },
  {
    payorRiskId: 2,
    payorRiskName: "Out of Network",
  },
  {
    payorRiskId: 3,
    payorRiskName: "Self-Pay",
  }
];

export const emptyTimingRisk: TimingRisk = {
  timingRiskId: 0,
  timingRiskName: '',
};

export const emptyPayorRisk: PayorRisk = {
  payorRiskId: 0,
  payorRiskName: '',
};

export const timingRiskNameLookup = (id: number) => {
  const foundTimingRisk = timingRisk.find((timing: TimingRisk) => timing.timingRiskId === id)
  return foundTimingRisk?.timingRiskName
};

export const payorRiskNameLookup = (id: number) => {
  const foundPayorRisk = payorRisk.find((payor: PayorRisk) => payor.payorRiskId === id)
  return foundPayorRisk?.payorRiskName
};
