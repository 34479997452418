import {LoadingOverlay} from '@finpay-development/shared-components';
import {
    Grid,
    Typography,
    Box,
    TableCell,
    TableRow,
    TableContainer,
    TableBody,
    Table,
    Paper,
    TableHead,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '../../../../shared/state/root-reducer';
import {AppDispatch} from '../../../../shared/state/store';
import {estimateErrorMessage} from '../../../../shared/enums';
import {EstimateRequestConfig} from 'src/admissions-advisor/models/estimator';
import {callNewEstimateGet} from 'src/admissions-advisor/state/estimator-thunk';
import {
    CrossoverEstimateSummary,
    FacilityLevelOfCare,
    PriorCareProvider,
    ThirdPartyPayerStatus,
    QuoteMethod,
    CrossoverSelectionEnum,
    FacilityLevelOfCareWithCrossoverDays
} from '@finpay/estimation-types';
import {formatNumberToUSD} from '../../../../shared/calculators';
import {TableColumn} from '../../../../shared/model/table-column';
import {admissionsAdvisorUtils} from '../../../../admissions-advisor/utils/admission-advisor-utils';
import _ from 'lodash';
import {checkPermissions, checkViewOnlyPermissions} from '../../../../security/components/access-control';
import {RolePageNames} from '../../../../security/model/role-page-names';
import {NewEstSummaryModalCrossover} from '../../../../admissions-advisor/components/pfr-estimator/estimator-summary-modal/crossover-summary-new';
import { getClientFacilities } from '../../../../implementation-specialist/state/facility/implementation-facility-thunk';
import { CrossoverView } from '../../../../shared/components/crossover-view';
import PriorCareSummaryTable from '../../../../shared/components/prior-care-summary-table';

function IOCEstimateDetails() {
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams<{eid?: string}>();

    const stateFields = {
        isLoadingState: useSelector(
            (state: RootState) => state.adminContext.adminUserContext.isLoading
        ),
        allClients: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.allClients
        ),
        estimate: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.newEstimate
        ),
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        userProfile: useSelector(
            (state: RootState) => state.userContext.userProfile
        ),
    };

    const {isLoadingState, allClients, estimate, facilities, userProfile} = stateFields;
    const [isLoadingLedger, setIsLoadingLedger] = useState(true);
    const [selectedFacilityName, setSelectedFacilityName] = useState('');
    const [selectedClientName, setSelectedClientName] = useState('');
    const roleAccess = checkViewOnlyPermissions(
        userProfile.userRole.userRolePages,
        RolePageNames.AdmissionsAdvisor,
        'VOB',
        false
    );
    console.log('roleAccess', roleAccess);
    const estimateId = parseInt(params.eid || '') || 0;
    const paramId = -2;

    const locTableHeaders: TableColumn[] = [
        {id: 'colCode', label: 'Code'},
        {id: 'colLocName', label: 'Level of Care'},
        {id: 'colLocDays', label: 'Days/Sessions'},
        {id: 'colLocDays', label: 'Rate per Day'},
        {id: 'colCovered', label: 'Covered'},
    ];

    const estimatedAdjustedCharges =
    formatNumberToUSD(
        estimate?.summary?.coveredCharges! +
            estimate?.summary?.unCoveredCharges!
    )

    useEffect(() => {
        // fetching estimate based on url param
        const getSavedIOCEstimateName = async () => {
            const config: EstimateRequestConfig = {
                paramId: paramId,
                estimationId: estimateId,
            };
            await dispatch(callNewEstimateGet(config));
        };
        getSavedIOCEstimateName();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getClientFacilitiesList = async () => {
            await dispatch(getClientFacilities(Number(estimate?.clientId!)));
        };
        const getFacilityName = () => {
            const selectedClient = allClients?.find(
                client => client.clientId === estimate?.clientId!
            );
            const facilityNm = admissionsAdvisorUtils.getFacilityName(
                Number(estimate?.facilityId),
                facilities
            );
            setSelectedClientName(selectedClient?.clientName!);
            setSelectedFacilityName(facilityNm);
        };
        if (estimate) {
            facilities.length > 0
                ? getFacilityName()
                : getClientFacilitiesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimate, facilities, allClients]);

    function LocTable(props: {levelsOfCare: FacilityLevelOfCareWithCrossoverDays[]}) {
        const {levelsOfCare} = props;

        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {locTableHeaders.map(column => (
                                    <TableCell key={column.id}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.sortBy(levelsOfCare, 'sortOrder')
                                .filter((loc: FacilityLevelOfCareWithCrossoverDays) => {
                                    if (loc.locType === 1) return false;
                                    return true;
                                })
                                .map(
                                    (
                                        row: FacilityLevelOfCareWithCrossoverDays,
                                        index: number
                                    ) => {
                                        const days = estimate?.isPlanYearCrossover
                                            ? estimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE && row?.losDaysAfterCrossover
                                                ? row?.losDaysBeforeCrossover
                                                : row?.losDays
                                            : row?.losDays;
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        row?.facilityLevelOfCareCode
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row?.facilityLevelOfCareName
                                                    }
                                                    <div
                                                        style={{
                                                            fontSize: '8pt',
                                                        }}
                                                    >
                                                        {selectedFacilityName}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {days}
                                                </TableCell>
                                                <TableCell>
                                                    {row?.pdrRate}
                                                </TableCell>
                                                <TableCell>
                                                    {row?.isCovered ? (
                                                        <>Yes</>
                                                    ) : (
                                                        <>No</>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }

    function adjustedChgs() {
        let charges = 0.0;
        estimate?.facilityLevelOfCare!.forEach(loc => {
            if (loc.locType === 1) return;
            charges += loc?.pdrRate * loc?.losDays;
        });
        return formatNumberToUSD(charges);
    }

    function estimatedLos() {
        let los = 0;
        estimate?.facilityLevelOfCare!.forEach(loc => {
            if (loc.locType === 1) return;
            los += loc?.losDays;
        });
        return los;
    }

    function PriorCare() {
        const priorLevelsOfCareSummary = estimate?.priorCareSummary;
        const genericPriorLoc = estimate?.priorCare;
        // If priorCare doesn't exist, return null to hide this section
        if (!genericPriorLoc) {
            return null;
        }

        function estimatedPriorLos() {
            let los = 0;
            genericPriorLoc?.loc.forEach(loc => {
                los += loc?.losDays;
            });
            return los;
        }

        return (
            <>
                <Typography variant="subtitle2" className="mt-4">
                    Estimated Prior Care Adjustment Details
                </Typography>
                <Grid container direction="row">
                    <Grid item direction="column" md={5}>
                        <Typography variant="body2">
                            Prior Care Class
                        </Typography>
                        {genericPriorLoc?.priorCareProvider ===
                        PriorCareProvider.THIRDPARTY
                            ? 'Third Party'
                            : 'Client'}
                    </Grid>
                    <Grid item direction="column" md={5}>
                        <Typography variant="body2">
                            {genericPriorLoc?.priorCareProvider ===
                            PriorCareProvider.THIRDPARTY
                                ? 'Third Party Payer Status'
                                : 'Facility'}
                        </Typography>
                        {genericPriorLoc?.priorCareProvider ===
                        PriorCareProvider.THIRDPARTY
                            ? genericPriorLoc?.thirdPartyPayerStatus ===
                              ThirdPartyPayerStatus.INNETW
                                ? 'Average In-Network'
                                : 'Average Out-of-Network'
                            : admissionsAdvisorUtils.getFacilityName(
                                  Number(estimate?.facilityId),
                                  facilities
                              )}
                    </Grid>
                </Grid>
                <LocTable levelsOfCare={genericPriorLoc?.loc!.map(loc => ({
                    ...loc,
                })) as FacilityLevelOfCareWithCrossoverDays[]} />
                {estimate.priorCareSummary && <PriorCareSummaryTable priorCareSummary={estimate.priorCareSummary} />}
            </>
        );
    }

    function NormalPfr(props: {hasCrossover: boolean}) {
        const {hasCrossover} = props;
        const financialSummary: any = estimate?.summary;

        return (
            <>
                <Typography variant="subtitle2" className="mt-2">
                    PFR Details {hasCrossover && <> (with Crossover)</>}
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Pay</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.patientTotalCoPays
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Deductible</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.deductibleSpent
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Insurance</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.patientTotalCoInsurance
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell></TableCell>
                                <TableCell
                                    align="right"
                                    sx={{borderTop: '2px solid #999'}}
                                >
                                    <strong>
                                        {formatNumberToUSD(
                                            financialSummary.coveredSvcsPfr
                                        )}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>
                                    Out of Pocket Max Remaining
                                </TableCell>
                                <TableCell align="right">
                                    {financialSummary.remainingOopMaxFromVob !==
                                    -1 ? (
                                        formatNumberToUSD(
                                            financialSummary.remainingOopMaxFromVob
                                        )
                                    ) : (
                                        <>N/A</>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>PFR for Covered Services</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(financialSummary.netPFR)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Uncovered Services</TableCell>
                                <TableCell align="right">
                                    {formatNumberToUSD(
                                        financialSummary.unCoveredCharges
                                    )}
                                </TableCell>
                            </TableRow>
                            {financialSummary.totalSurcharges > 0 && (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                financialSummary.subtotalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Admission Assessment
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                financialSummary.totalSurcharges
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                            <TableRow className="py-3">
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        Estimated Patient Financial
                                        Responsibility
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography variant="subtitle2">
                                        {formatNumberToUSD(
                                            financialSummary.totalPFR
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    function FinAssistPFR() {
        // calculate PFR for plan crossover
        const crossoverPolicies: CrossoverEstimateSummary =
            estimate?.crossOverSummary!;

        const financialSummary = estimate?.summary!;

        const showScholarshipPct = () => {
            let scholarship = 0;
            if (estimate?.financialAssistance?.scholarshipPercentage!) {
                scholarship =
                    estimate?.financialAssistance?.scholarshipPercentage * 100;
            }
            return scholarship.toFixed(0) + '%';
        };

        const showScholarshipAmt = (pfr: number) => {
            let scholarshipPct = 1;
            if (estimate?.financialAssistance?.scholarshipPercentage) {
                scholarshipPct =
                    estimate?.financialAssistance?.scholarshipPercentage;
            }
            return formatNumberToUSD(pfr * scholarshipPct);
        };

        const pfrAdjWithCrossover = () => {
            const scholarshipAmt =
                (crossoverPolicies?.summaryBeforeCrossover?.totalPFR! +
                    crossoverPolicies?.summaryWithCrossover?.totalPFR!) *
                estimate?.financialAssistance?.scholarshipPercentage!;
            return formatNumberToUSD(
                crossoverPolicies?.summaryBeforeCrossover?.totalPFR! +
                    crossoverPolicies?.summaryWithCrossover?.totalPFR! -
                    scholarshipAmt
            );
        };

        const pfrAdjustment = () => {
            const scholarshipAmt =
                financialSummary.totalPFR *
                estimate?.financialAssistance?.scholarshipPercentage!;
            return formatNumberToUSD(
                financialSummary.totalPFR - scholarshipAmt
            );
        };

        return (
            <>
                <Typography variant="subtitle2" className="mt-4">
                    Estimated PFR After Financial Assistance
                </Typography>

                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            {estimate?.isPlanYearCrossover ? (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Crossover Year PFR
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                crossoverPolicies
                                                    ?.summaryWithCrossover
                                                    ?.totalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>Current Year PFR</TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                crossoverPolicies
                                                    ?.summaryBeforeCrossover
                                                    ?.totalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                crossoverPolicies
                                                    ?.summaryWithCrossover
                                                    ?.totalPFR! +
                                                    crossoverPolicies
                                                        ?.summaryBeforeCrossover
                                                        ?.totalPFR!
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Financial Assistance Scholarship (
                                            {showScholarshipPct()})
                                        </TableCell>
                                        <TableCell align="right">
                                            {showScholarshipAmt(
                                                crossoverPolicies
                                                    ?.summaryWithCrossover
                                                    ?.totalPFR! +
                                                    crossoverPolicies
                                                        ?.summaryBeforeCrossover
                                                        ?.totalPFR!
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                PFR After Financial Assistance
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography variant="subtitle2">
                                                {pfrAdjWithCrossover()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>Total Patient PFR</TableCell>
                                        <TableCell align="right">
                                            {formatNumberToUSD(
                                                financialSummary.totalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Financial Assistance Scholarship (
                                            {showScholarshipPct()})
                                        </TableCell>
                                        <TableCell align="right">
                                            {showScholarshipAmt(
                                                financialSummary.totalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            PFR After Financial Assistance
                                        </TableCell>
                                        <TableCell align="right">
                                            {pfrAdjustment()}
                                        </TableCell>
                                    </TableRow>
                                    {financialSummary.scholarshipAdjustedSurcharge >
                                        0 && (
                                        <TableRow className="py-3">
                                            <TableCell>
                                                Admission Assessment
                                            </TableCell>
                                            <TableCell align="right">
                                                {formatNumberToUSD(
                                                    financialSummary.scholarshipAdjustedSurcharge
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    <TableRow className="py-3">
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                Total PFR
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Typography variant="subtitle2">
                                                {pfrAdjustment()}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    return (
        <Grid>
            {roleAccess && (
                <main className="patient-details">
                    <Box className="content extra-padding">
                        <Grid
                            sx={{
                                marginTop: '2rem',
                                marginBottom: '2rem',
                                display: 'flex',
                            }}
                        >
                            <Grid item xs={10}>
                                <Typography
                                    variant="h3"
                                    className="pt-0_5"
                                    style={{
                                        display: 'flex',
                                        alignContent: 'center',
                                        paddingLeft: '6px',
                                        fontSize: '2rem',
                                    }}
                                >
                                    Estimate Details
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box
                            border={1}
                            borderColor="primary.main"
                            borderRadius="5px"
                            className="mb-5"
                            sx={{
                                marginTop: '1rem',
                                marginBottom: '4rem !important',
                            }}
                        >
                            <Box style={{margin: '0.5em', padding: '12px'}}>
                                {estimate ? (
                                    <>
                                        {/* Client and Facility information */}
                                        <Grid container direction="row" spacing={2}>
                                            <Grid item direction="column" md={6}>
                                                <Typography variant="body2">
                                                    Client
                                                </Typography>
                                                {`${selectedClientName} - ${estimate?.clientId}`}
                                            </Grid>
                                            <Grid item direction="column" md={6}>
                                                <Typography variant="body2">
                                                    Facility
                                                </Typography>
                                                {`${selectedFacilityName} - ${estimate?.facilityId}`}
                                            </Grid>
                                            <Grid item direction="column" md={6}>
                                                <Typography variant="body2">
                                                    Quote Method
                                                </Typography>
                                                {admissionsAdvisorUtils.getQuoteMethodName(estimate?.quoteMethod!)}
                                            </Grid>
                                            <Grid item direction="column" md={12}>
                                                <Typography variant="body2">
                                                    Anticipated Admission Date
                                                </Typography>
                                                {new Date(
                                                    estimate?.anticipatedAdmitDate!
                                                ).toLocaleDateString('en-US', {
                                                    timeZone: 'UTC',
                                                })}
                                            </Grid>
                                        </Grid>

                                        {/* Prior Care section */}
                                        {estimate.priorCare && <PriorCare />}

                                        {/* Care Plan Details section */}
                                        {estimate.facilityLevelOfCare?.length! > 0 && (
                                            <>
                                                <Typography variant="subtitle2" className="mt-4">
                                                    Care Plan Details
                                                </Typography>
                                                <LocTable levelsOfCare={estimate.facilityLevelOfCare!.map(loc => ({
                                                    ...loc,
                                                })) as FacilityLevelOfCareWithCrossoverDays[]} />
                                                <TableContainer component={Paper}>
                                                    <Table size="small" className="alternating-row">
                                                        <TableBody>
                                                            <TableRow className="py-3 light-green-row">
                                                                <TableCell>
                                                                    Estimated Adjusted Charges
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {estimatedAdjustedCharges}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow className="py-3 light-green-row">
                                                                <TableCell>
                                                                    Estimated Length of Stay
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {estimate?.summary?.lengthOfStay}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </>
                                        )}
                                        {!estimate.isPlanYearCrossover && estimate?.summary && (
                                            <NormalPfr hasCrossover={false} />
                                        )}
                                        {estimate.crossOverSummary && estimate.isPlanYearCrossover && (
                                            <CrossoverView estimate={estimate} isPriorCareSummary={false} />
                                        )}
                                        {estimate.financialAssistance && <FinAssistPFR />}
                                    </>
                                ) : (
                                    <Typography>
                                        {estimateErrorMessage.loadingError}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </main>
            )}
            {!roleAccess && !isLoadingState && !isLoadingLedger && (
                <Typography className="access-error">
                    <span className="message">
                        <LockIcon
                            style={{paddingRight: '8px', fontSize: '1.6em'}}
                        />
                        {estimateErrorMessage.authenticationError}
                    </span>
                </Typography>
            )}
            {isLoadingLedger || (isLoadingState && <LoadingOverlay />)}
        </Grid>
    );
}
export default IOCEstimateDetails;