export enum RolePageNames {
    Roles = "Roles",
    Configuration = "Configuration",
    Users = "Users",
    Logs = "Logs",
    Operations = "Operations",
    Clients = "Clients",
    PatientRecords = "Patient Records",
    AccountHoldersDetails = "Account Holder's Details",
    AccountManagementDashboard = "Account Management Dashboard",
    ClientOnboardingDashboard = "Client On-boarding Dashboard",
    ClientPortal = "Client Portal",
    AdmissionsAdvisor = "Admissions Advisor",
}