import { createAsyncThunk } from "@reduxjs/toolkit";

import { commentsService } from "../../../admin/services/comments-service";
import { cookiesService } from "../../../admin/services/cookie-service";
import { EstLevelOfCare } from "../../../admissions-advisor/models/estimator";
import { Comment } from "../../../patient/components/models/comment";
import { patientHelper } from "../../../patient/services/patient-service";
import { showErrorStatus, showStatus } from "../../../security/state/user-slice";
import { errorCodes, errorMessages, RecentlyViewedTypes } from "../../../shared/enums";
import { ClientBusinessRules } from "../../../shared/model/client";
import { RootState } from "../../../shared/state/root-reducer";
import { Utils } from "../../../shared/utils";
import { ClientContactForm, ClientEntityForm, ClientForm, ClientDeficiencyNotification } from "../../models/client";
import { FacilityRiskThreshold, PayerClaimRate } from "../../../admissions-advisor/models/risk-assessment";
import { specialistClientService } from "../../services/implementation-client-service";
import { ClientReferralSource, FacilityLOCReferralSource, FacilityReferralSourceModel, SelectedFacilityReferralSource } from "../../models/referral-source";
import {activateFacilities} from '../facility/implementation-facility-thunk';
import {
  ClientPaymentTransactionFee,
  intialClientPaymentTransactionFee,
} from '../../models/payment-transaction-fee';
import _ from 'lodash';
import { facility } from "src/shared/validation/schemas";
import { saveCallbackStatus } from "@finpay-development/shared-components";

export const getClients = createAsyncThunk(
  "implementationSpecialistClientContext/getClients",
  async (data: { includeFacility: boolean }, thunkAPI) => {
    const { includeFacility } = data
    const clients = await specialistClientService.getClients(includeFacility);
    // returned 'entity' contains objects for all clients, as well as clients filtered by closed, isActive, Stripe Set Up Incomplete, and more
    if (clients.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(clients.errorMessage));
      throw new Error();
    } else {
      return clients.entity;
    }
  }
);

export const getClientKPIs = createAsyncThunk(
  "implementationSpecialistClientContext/getClientKPIs",
  async (data: {clientId: number, months: number}, thunkAPI) => {
    const { clientId, months } = data;
    const clientKPIs = await specialistClientService.getClientKPIs(clientId, months);

    if (clientKPIs.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(clientKPIs.errorMessage));
      throw new Error();
    } else {
      return clientKPIs.entity;
    }
  }
)

export const getClient = createAsyncThunk(
  "implementationSpecialistClientContext/getClient",
  async (clientId: number, thunkAPI) => {
    const client = await specialistClientService.getClient(clientId);
    if (client.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(client.errorMessage));
      throw new Error();
    } else {
      return client.entity;
    }
  }
);

export const addClient = createAsyncThunk("implementationClientsContext/addClient", async (data: ClientForm, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const allClients = [...state.implementationContext.implementationSpecialistClient.allClients];
  const response = await specialistClientService.addClient(data, allClients);

  if (response.hasErrors) {
    throw new Error(response.errorMessage);
  }
  thunkAPI.dispatch(setRecentlyViewedClient(response.entity.returnedClient.clientId))
  return response.entity;
});

export const activateClient = createAsyncThunk(
    "implementationSpecialistClientContext/saveClient",
    async(clientId: number, thunkAPI) => {

      const workflowToActivate = {
        workflowId: 1,
        workflowStatus: {
          workflowStatusId: 2
        },
        workflowSubStatus: {
          workflowSubStatusId: 4
        }
      }

      const [resolvedClient, ] = await Promise.all([
        specialistClientService.saveClient({clientId, payload: workflowToActivate}),
        thunkAPI.dispatch(activateFacilities({clientId, payload: workflowToActivate}))
      ])

      if(resolvedClient.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(resolvedClient.errorMessage));
        throw new Error(resolvedClient.errorMessage);
      }

      return {
        updatedClient: resolvedClient.entity
      }
    }
)

// uses node client update endpoint
export const saveClientEntity = createAsyncThunk(
  "implementationSpecialistClientContext/saveClientEntity",
  async(formValues: ClientEntityForm, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const originalClient = {...state.implementationContext.implementationSpecialistClient.client};
    const response = await specialistClientService.saveClientEntity(formValues, originalClient);

    if (response.hasErrors) {
      throw new Error(response.errorMessage);
    } else {
      thunkAPI.dispatch(showStatus("Entity Updated"))
    }
    return response.entity;
  }
)

// uses node client update endpoint
export const saveClientContact = createAsyncThunk(
  "implementationSpecialistClientContext/saveClientContact",
  async(formValues: ClientContactForm, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const originalClient = {...state.implementationContext.implementationSpecialistClient.client};
    const response = await specialistClientService.saveClientContact(formValues, originalClient);

    if (response.hasErrors) {
      throw new Error(response.errorMessage);
    } else {
      thunkAPI.dispatch(showStatus("Client Entity Updated"));
    }

    return response.entity;
  }
)

export const getClientPaymentTransactionFee = createAsyncThunk(
    "implementationSpecialistClientContext/getClientPaymentTransactionFee",
    async(clientId: number, thunkAPI)=>{

      const response = await specialistClientService.getClientPaymentTransactionFee(clientId)

      if(!response.hasErrors){
        if(Object.entries(response.entity).length>0) {
          return {
            fees: response.entity,
            clientId
          };
        }else{
          //set transaction fees to 0 if no records are found in the db
          return{
            fees: intialClientPaymentTransactionFee,
            clientId
          }
        }
      }else{
        thunkAPI.dispatch(showErrorStatus(`Error while fetching payment transaction fee`));
        throw new Error(response.errorMessage);
      }
    }
)

export const saveClientPaymentTransactionFee = createAsyncThunk(
    "implementationSpecialistClientContext/saveClientPaymentTransactionFee",
    async(data: {clientId: number, payload: any}, thunkAPI)=>{

      const state = thunkAPI.getState() as RootState;

      const [clientPaymentTransactionFeeResponse] = await Promise.all([
        specialistClientService.saveClientPaymentTransactionFee({
          clientId: data.clientId,
          payload: _.omit(data.payload, ['accountHolderFee', 'merchantProcessingFee']) as ClientPaymentTransactionFee}),
        thunkAPI.dispatch(saveClientBusinessRules({
          ...state.implementationContext.implementationSpecialistClient?.client.clientBusRules!,
          merchantProcessingFee: data.payload.merchantProcessingFee
        }))
      ])

      if(!clientPaymentTransactionFeeResponse.hasErrors){
          return {
            fees: clientPaymentTransactionFeeResponse.entity,
            clientId: data.clientId
          };
      }else{
        thunkAPI.dispatch(showErrorStatus(`Error while fetching payment transaction fee`));
        throw new Error(clientPaymentTransactionFeeResponse.errorMessage);
      }
    }
)

export const saveClientBusinessRules = createAsyncThunk(
  "implementationSpecialistClientContext/saveClientBusinessRules",
  async(formValues: ClientBusinessRules, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const originalClient = {...state.implementationContext.implementationSpecialistClient.client};
    formValues.clientId = originalClient?.clientId;

    if ( (formValues.clientId === 0) || (formValues.clientId < 0) || (formValues.clientId === null) || (formValues.clientId === undefined) ) {
      thunkAPI.dispatch(showErrorStatus("Internal error - client Id is not positive."));
      throw new Error();
    }

    const response = await specialistClientService.saveClientBusinessRules(formValues);
    if (response.hasErrors) {
      throw new Error(response.errorMessage);
    } else {
      thunkAPI.dispatch(showStatus("Business Rules Updated"));
    }

    return response.entity;
  }
)
export const notifyClient = createAsyncThunk(
  "implementationSpecialistClientContext/notifyClient",
  async(clientId:number, thunkAPI) => {
      const response = await specialistClientService.notifyClient(clientId);

    if (response.hasErrors){
      let errorMessage = response.errorMessage;
      if (response.entity.code === errorCodes.invalidWorkflow ||
        response.entity.code === errorCodes.duplicateUserName){
        errorMessage = errorMessages.duplicateUser
      }
      thunkAPI.dispatch(showErrorStatus(errorMessage))
      throw new Error(errorMessage);
    }
    thunkAPI.dispatch(showStatus("Client notified"));

    return response.entity;
  }
)

export const resendInvitation = createAsyncThunk(
  "implementationSpecialistClientContext/resendInvitation",
  async(userId:number, thunkAPI) => {
    const response = await specialistClientService.resendInvitation(userId);
    if(response.hasErrors){
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus("Invitation sent successfully "));
    }
    return response.entity
  }
)


export const createClientComment = createAsyncThunk(
  'implementationSpecialistClientContext/createClientComment',
  async (data:{message: string, comment: Comment}, thunkAPI) => {
      const {message, comment} = data
       const response = await commentsService.createClientComment(comment,comment.clientId );

      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage))
        throw new Error(response.errorMessage);
      } else {
        thunkAPI.dispatch(showStatus(message));
      }

      return response.entity
    }
);


export const getClientComments = createAsyncThunk(
  'implementationSpecialistClientContext/getClientComments',
  async (clientId:number, thunkAPI) =>  {

      const response = await commentsService.getClientComments(clientId);

      const filteredResult = filterComments(response.entity)
      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage))
        throw new Error(response.errorMessage);
      }
      return filteredResult;
  }
);

export const generateClientLOCSpreadsheet = createAsyncThunk(
  'implementationSpecialistClientContext/generateClientLOCSpreadsheet',
  async (data: {clientId: number, spreadsheetType: string, facilityIds: number[], facilityLevelOfCareIds: number[], payorIds: number[]}, thunkAPI) => {
    const response = await specialistClientService.generateClientSpreadsheet(data.clientId, data.spreadsheetType, data.facilityIds, data.facilityLevelOfCareIds, data.payorIds);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('File downloaded successfully.'));
      return response.entity;
    }
  }
);

export const generateClientPayorSpreadsheet = createAsyncThunk(
  'implementationSpecialistClientContext/generateClientPayorSpreadsheet',
  async (data: {clientId: number, spreadsheetType: string, facilityIds: number[], facilityLevelOfCareIds: number[], payorIds: number[]}, thunkAPI) => {
    const response = await specialistClientService.generateClientSpreadsheet(data.clientId, data.spreadsheetType, data.facilityIds, data.facilityLevelOfCareIds, data.payorIds);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('File downloaded successfully.'));
      return response.entity;
    }

  }
);

export const saveFacilityRiskSetting = createAsyncThunk(
  'implementationSpecialistClientContext/saveFacilityRiskSetting',
  async(formValues: FacilityRiskThreshold, thunkAPI) => {
    let response;
    if (formValues.facilityRiskThresholdId) {
      // update facility risk threshold
      response = await specialistClientService.updateFacilityRiskSetting(formValues);
    } else {
      // create facility risk threshold
      response = await specialistClientService.createFacilityRiskSetting(formValues);
    }
    if (response?.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const deleteClientComment = createAsyncThunk(
  'implementationSpecialistClientContext/deleteClientComment',
  async (data: {clientId: number, commentId: number}, thunkAPI) =>  {

      const response = await commentsService.deleteClientComment(data.clientId, data.commentId);
      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage))
        throw new Error(response.errorMessage);
      }

      return response.entity
  }
);

export const setRecentlyViewedClient = createAsyncThunk(
  'implementationSpecialistClientContext/setRecentlyViewedClient',
  async (clientId: number, thunkAPI) =>  {
    const state = thunkAPI.getState() as RootState;
    const userName = state.userContext.userProfile.userName
    let recentlyViewedClients:Array<number> = Utils.deepClone(state.implementationContext.implementationSpecialistClient.recentViewedClients)
    if(clientId === -1){
      return recentlyViewedClients
    }
    recentlyViewedClients =  Utils.recentlyViewedAddVerificationClients(recentlyViewedClients, clientId)
    const updatedRecentlyViewed = await cookiesService.setRecentlyViewed(recentlyViewedClients, userName, RecentlyViewedTypes.clients )

    return updatedRecentlyViewed.clients
  }
);

const filterComments = (comments:Comment[] ) => {
  const commentsFilter = comments.filter((comment) => {
    return !comment.isArchived
  })
  return {comments:commentsFilter}
}

export const getClientReferralSources = createAsyncThunk(
  "implementationSpecialistClientContext/getClientReferralSources",
  async (data: {clientId: number}, thunkAPI) => {
    const response = await specialistClientService.getReferralSources(0, data.clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const saveClientReferralSource = createAsyncThunk(
  'implementationSpecialistClientContext/saveClientReferralSource',
  async(formValues: ClientReferralSource, thunkAPI) => {
    let statusMessage = 'added';
    if (formValues.clientReferralSourceId !== undefined) {
      statusMessage = 'updated';
    };
    const response = await specialistClientService.saveClientReferralSource(formValues);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Referral Source ' + statusMessage + ' successfully.'));
      return response.entity;
    }
  }
);

export const deleteReferralSource = createAsyncThunk(
  "implementationSpecialistClientContext/deleteReferralSource",
  async (data: { referralSourceId: number, clientId: number, facilityId: number }, thunkAPI) => {
    const response = await specialistClientService.deleteReferralSource(data.referralSourceId, data.clientId, data.facilityId);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Referral Source deleted successfully.'));
      return response.entity;
    }
  }
);

export const getFacilityReferralSources = createAsyncThunk(
  "implementationSpecialistClientContext/getFacilityReferralSources",
  async (data: {facilityId: number, clientId: number}, thunkAPI) => {
    const response = await specialistClientService.getReferralSources(data.facilityId, data.clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const saveFacilityLOCReferralSource = createAsyncThunk(
  'implementationSpecialistClientContext/saveFacilityLOCReferralSource',
  async(formValues: FacilityLOCReferralSource, thunkAPI) => {
    const response = await specialistClientService.saveFacilityLOCReferralSource(formValues);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Facility LOC Referral Source updated successfully.'))
      return response.entity;
    }
  }
);

export const saveFacilityReferralSource = createAsyncThunk(
  'implementationSpecialistClientContext/saveFacilityReferralSource',
  async(formValues: FacilityReferralSourceModel, thunkAPI) => {
    let statusMessage = 'added';
    if (formValues.facilityReferralSourceId !== undefined) {
      statusMessage = 'updated';
    }
    const response = await specialistClientService.saveFacilityReferralSource(formValues);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Facility Referral Source ' + statusMessage + ' successfully.'))
      return response.entity;
    }
  }
);

export const addFacilityReferralSource = createAsyncThunk(
  'implementationSpecialistClientContext/addFacilityReferralSource',
  async(formValues: FacilityReferralSourceModel, thunkAPI) => {
    const response = await specialistClientService.addFacilityReferralSource(formValues);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const addFacilityClientReferralSource = createAsyncThunk(
  'implementationSpecialistClientContext/addFacilityClientReferralSource',
  async(formValues: FacilityReferralSourceModel[], thunkAPI) => {

    let refSourcesAdded: FacilityReferralSourceModel[] = [];
    if (formValues.length > 0) {
      await Promise.all(
        formValues.map(async (item: FacilityReferralSourceModel): Promise<any> => {
          let response = await specialistClientService.addFacilityReferralSource(item);
          if (response.hasErrors) {
            Utils.robustErrorHandler(response, thunkAPI);
          } else {
            thunkAPI.dispatch(showStatus('Facility Referral Source added successfully.'));
            refSourcesAdded.push(response.entity);
          }
        })
      );
    }
    return refSourcesAdded;
  }
);

export const getFacilityLOCReferralSources = createAsyncThunk(
  "implementationSpecialistClientContext/getFacilityLOCReferralSources",
  async (data: {facilityId: number, clientId: number,
                facilityReferralSources: SelectedFacilityReferralSource[],
                facilityLevelsOfCare: EstLevelOfCare[],
                levelOfCareId: number}, thunkAPI) => {
    const response = await specialistClientService.getFacilityLOCReferralSources(data.facilityId, data.clientId,data.facilityReferralSources, data.facilityLevelsOfCare, data.levelOfCareId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getClientDeficiencyNotification = createAsyncThunk(
  "implementationSpecialistClientContext/getClientDeficiencyNotification",
  async (clientId: number, thunkAPI) => {
    const response = await specialistClientService.getClientDeficiencyNotification(clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const saveClientDeficiencyNotification = createAsyncThunk(
  'implementationSpecialistClientContext/saveClientDeficiencyNotification',
  async(formValues: ClientDeficiencyNotification, thunkAPI) => {
    const response = await specialistClientService.saveClientDeficiencyNotification(formValues);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Deficiency Notification updated successfully.'));
      return response.entity;
    }
  }
);

export const savePayerClaimRate = createAsyncThunk(
  'implementationSpecialistClientContext/savePayerClaimRate',
  async(formValues: PayerClaimRate, thunkAPI) => {
    let response;
    if (formValues.clientPayorClaimrateId) {
      // update facility risk threshold
      response = await specialistClientService.updatePayerClaimRate(formValues);
    } else {
      // create facility risk threshold
      response = await specialistClientService.createPayerClaimRate(formValues);
    }
    if (response?.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getClientRiskAssessmentConfigs = createAsyncThunk(
  'implementationSpecialistClientContext/getClientRiskAssessmentConfigs',
  async (data: 
    {
      clientId: number, 
      facilityId?: number
    }, 
    thunkAPI) => {
    const response = await specialistClientService.getClientRiskAssessmentConfigs(data.clientId, data.facilityId)
    
    if (response.hasErrors) {
      return []// TODO: api shouldn't throw 400 if no records are found
    } else {
      return response.entity;
    }
  }
)

export const createClientRiskAssessmentConfig = createAsyncThunk(
  'implementationSpecialistClientContext/createClientRiskAssessmentConfig',
  async (data: 
    {
      clientId: number, 
      formValues: any,
      facilityId?: number | null
    }, 
    thunkAPI) => {
    const response = await specialistClientService.createClientRiskAssessmentConfig(data.clientId, data.formValues, data.facilityId)

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
)

export const updateClientRiskAssessmentConfig = createAsyncThunk(
  'implementationSpecialistClientContext/updateClientRiskAssessmentConfig',
  async (data: 
    {
      marginId: number, 
      formValues: any,
      facilityId?: number
    },
    thunkAPI) => {
    const response = await specialistClientService.updateClientRiskAssessmentConfig(data.marginId, data.formValues, data.facilityId)
    
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
)

export const deleteClientRiskAssessmentConfig = createAsyncThunk(
  'implementationSpecialistClientContext/deleteClientRiskAssessmentConfig',
  async (data: 
    {
      marginId: number
    },
    thunkAPI) => {
    const response = await specialistClientService.deleteClientRiskAssessmentConfig(data.marginId)
    
    if (response.hasErrors) {
      return { marginId: data.marginId, status: saveCallbackStatus.success }
    } else {
      return { marginId: data.marginId, status: saveCallbackStatus.success }
    }
  }
)