import { createAsyncThunk } from "@reduxjs/toolkit";
import { CRMForm } from "src/implementation-specialist/models/crm-form";
import { crmService } from "src/implementation-specialist/services/implementation-crm-service";
import { RootState } from "src/shared/state/root-reducer";
import { Utils } from "src/shared/utils";

export const saveCRM = createAsyncThunk("implementationCRMContext/saveCRM", async (
    data: {
        clientId: number;
        clientFacilityId: number | null;
        formValues: CRMForm
    },
    thunkAPI
) => {

    const state = thunkAPI.getState() as RootState

    const fullClientCRM = Utils.deepClone(
        state?.crmContext?.implementationCRM?.apiResponseCRM
    );
    const allClientCRM = Utils.deepClone(
        state?.crmContext.implementationCRM?.implementationCRM
    )

    const response = await crmService.saveCRM(
        data.clientId,
        data.clientFacilityId,
        data.formValues,
        fullClientCRM,
        allClientCRM
    )

    if (response?.hasErrors) {
       return Utils.robustErrorHandler(response,thunkAPI)
    }

    return response.entity


})

export const getClientCRM = createAsyncThunk("implementationCRMContext/getClientCRM", async (
    data: {
        clientCrmId:number
    },thunkAPI
) => {
    const response = await crmService.getClientCRM(data.clientCrmId)

    if (response.hasErrors) {
        return Utils.robustErrorHandler(response,thunkAPI)
    }

    return response.entity
})

export const getAllClientCRM = createAsyncThunk("implementationCRMContext/getAllClientCRM", async (
    data: {
        clientId: number,
        clientFacilityId:number | null
    },thunkAPI
) => {

    const response = await crmService.getAllClientCRM(data.clientId, data.clientFacilityId)

    if (response.hasErrors) {
        return Utils.robustErrorHandler(response,thunkAPI)
    }

    return response.entity
})