import { SysRolePage } from "../../security/model/sys-role-page";
import { emptyUserRole, UserRole } from "../../security/model/user-role";
import { RolePageState } from "./role-page-state";

export class UserRoleFieldState {
    userRoleId?: number;
    roleName!: string;
    roleDescription!: string;
}

export class AddEditRoleState {
    fieldState!: UserRoleFieldState;
    roleEnableState!: RolePageState[];
    userRole!: UserRole;
    systemRoles!: SysRolePage[]
}

export const emptyAddEditRoleState: AddEditRoleState = {
    fieldState: {
        roleName: "",
        roleDescription: ""
    },
    userRole: emptyUserRole,
    systemRoles: [],
    roleEnableState: []
}