import axios from 'axios';
import { AwsTokenState } from '../../security/model/aws-token-state';
import { authService } from '../../security/services/authorization-service';
import {GuestToken, Tokens} from '../../security/services/SSO';


let isSSOState : boolean = false;
let useTokens: Tokens | GuestToken;
let isPayNowState : boolean = false;

export const HttpInterceptor =  (isSSO?: boolean, tokens?: Tokens | GuestToken, isPayNow?: boolean) => {

    if (typeof isSSO === 'boolean') {
        isSSOState = isSSO;
    }

    if (typeof tokens !== "undefined") {
        useTokens = tokens;
    }

    if (typeof isPayNow === 'boolean'){
      isPayNowState = isPayNow
    }

    let id_token: string;

    axios.interceptors.request.use(

        async config => {
            if (!isSSOState && !isPayNowState) {
                const securityToken: AwsTokenState = await authService.fetchSecurityToken();
                if(!securityToken?.jwtIdToken) throw new Error("There was an issue retrieving your user session. Please log out and log back in to continue. If the problem persists, contact support for assistance.")
                id_token = securityToken.jwtIdToken;

            } else if(!isSSOState && isPayNowState){
              if ('token' in useTokens) {
                id_token = useTokens.token;
              }
            } else {
              if ('id_token' in useTokens) {
                id_token = useTokens.id_token;
              }
                if (typeof useTokens === "undefined") {
                    console.error("Token is required");
                }
            }

            config.headers['Authorization'] = `Bearer ${id_token}`;
            config.headers['x-cog-id'] = id_token;
            return config;

        },
        async err => {
            return Promise.reject(err);
        }
    );
}

export default HttpInterceptor
