import '../../scss/components/mobile-payments.scss';
import { LoadingOverlay } from '@finpay-development/shared-components';

interface LoaderProps {
    showLoader?: boolean;
    customMessage?: string;
}
  
function MobilePaymentsLoader(props: LoaderProps) {
    const { showLoader, customMessage } = props;

    return (
        <>
            {showLoader && 
                <div className="mobile-loader">
                    <div className="text-warn">
                        {customMessage ?
                            <p>{customMessage}, please do not refresh.</p>
                        :
                            <p>Payment is being processed, please do not refresh.</p>
                        }
                        <span className="time-warn">This can take up to 30 seconds.</span>
                    </div>
                    <LoadingOverlay whiteBackground={true}/>
                </div>
            }
        </>
    );
}

export default MobilePaymentsLoader;
