import { PayOutFrequency } from "../../implementation-specialist/models/payOutFreq";
import { ExternalAccount } from "./external-account";
import { Facility } from "./facility";
import { PrimaryAddress } from "./primary-address";
import { emptyPrimaryContact, PrimaryContact } from "./primary-contact";

// Add isAll flag to support "Select All"
export class Client {
  isAll!: boolean;
  userClientScopeId!: number;
  clientId!: number;
  clientName!: string;
  allowedFacilities!: Facility[];
  isFacilityLevel?: boolean | null;
}

export interface client { // TODO(JB): lowercase to distinguish from 'class' of Client. This is a FULL client model. Eventually transition the old class to this interface.
  clientId: number,
  clientName: string,
  websiteUrl: string,
  parentEntity: ParentEntity,
  primaryContact: PrimaryContact,
  implSpecUserName: string,
  clientUserName: string,
  respPartyType: RespPartyType,
  clientBusRules?: ClientBusinessRules,
  multiFacilityFl: boolean,
  clientFacilities: ClientFacility[],
  clientComments: {},
  workflow: Workflow,
  workflowStatusDt: string,
  clientStatus: string,
  clientStatusDt: string,
  externalAccountIds: string[],
}

export interface RespPartyType {
  respPartyId: number,
  respPartyDesc: string,
}

export interface ParentEntity {
  parentEntityId: number,
  entityName: string,
}

export interface ClientFacility {
  facilityId: 1,
  facilityName: string,
  websiteUrl: string,
  payOutFreq: PayOutFrequency,
  payOutDay: 7,
  primaryContact: PrimaryContact,
  address: PrimaryAddress,
  phoneNo: string,
  taxId: string,
  taxIdLast4: string,
  facilityType: string,
  externalAccount: ExternalAccount;
}

export interface Workflow {
  workflowId: number,
  workflowName: string,
  workflowStatusDt?: string,
  workflowStatus: WorkflowStatus,
  workflowSubStatus: WorkflowSubStatus
}

export interface WorkflowStatus {
  workflowStatusId: number,
  workflowStatusDesc: string
}
export interface WorkflowSubStatus {
  workflowSubStatusId: number,
  workflowSubStatusDesc: string
}

export interface ClientBusinessRules {
  clientId: number,
  preCareFullPayMaxDiscount: number | "",
  postCareFullPayMaxDiscount: number | "",
  clientBusinessRuleId: number,
  refundsIssuedBy: string | "",
  missedPmtsForDefault: number | "",
  pfrVarianceTolerance: number | "",
  automationFee: number | "",
  merchantProcessingFee: number | "",
  customDocTokenId: string,
  useClientTemplate: "All" | "Partial",
  realTimeUpdates: boolean,
  paynowFee: number | "",
  sendPFRDocs: boolean,
}

export const blankClientBusinessRules: ClientBusinessRules = {
  clientId: 0,
  preCareFullPayMaxDiscount: 0,
  postCareFullPayMaxDiscount: 0,
  clientBusinessRuleId: 0,
  refundsIssuedBy: "",
  missedPmtsForDefault: 0,
  pfrVarianceTolerance: 0,
  automationFee: 0,
  merchantProcessingFee: 0,
  customDocTokenId: "",
  useClientTemplate: "All",
  realTimeUpdates: false,
  paynowFee: 0,
  sendPFRDocs: false
}

export const emptyWorkflowStatus: WorkflowStatus = {
  workflowStatusId: 0,
  workflowStatusDesc: '',
}

export const emptyWorkflowSubStatus: WorkflowSubStatus = {
  workflowSubStatusId: 0,
  workflowSubStatusDesc: '',
}

export const emptyWorkflow: Workflow = {
  workflowId: 0,
  workflowName: '',
  workflowStatus: emptyWorkflowStatus,
  workflowSubStatus: emptyWorkflowSubStatus
}

// TODO(JB): possible will be deprecated in favor of large version below
export const emptyClient: Client = {
  isAll: false,
  userClientScopeId: 0,
  clientId: -1,
  clientName: "",
  allowedFacilities: []
};

export function getAllClient() : Client {
  return {
    isAll: true,
    userClientScopeId: 0,
    clientId: 0,
    clientName: "All Clients",
    allowedFacilities: []
  }
}

export const blankClient: client = {
  clientId: 0,
  clientName: "",
  websiteUrl: "",
  parentEntity: {
    parentEntityId: 0,
    entityName: "",
  },
  primaryContact: emptyPrimaryContact,
  implSpecUserName: "",
  clientUserName: "",
  respPartyType: {
    respPartyId: 0,
    respPartyDesc: "",
  },
  multiFacilityFl: false,
  clientFacilities: [],
  clientComments: [],
  workflow: {
    workflowId: 0,
    workflowName: "",
    workflowStatus: {
      workflowStatusId: 0,
      workflowStatusDesc: "",
    },
    workflowSubStatus: {
      workflowSubStatusId: 0,
      workflowSubStatusDesc: "",
    },
  },
  workflowStatusDt: "",
  clientStatus: "",
  clientStatusDt: "",
  externalAccountIds: []
};