/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid, IconButton, TableSortLabel, Typography } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { ReportsInfo } from "../../models/reports-info";
import { adminUserHelper} from "../../services/admin-user-service";
import { Column } from "../../../shared/model/column";
import { orderDirections, reportResultType, reportsType } from "../../../shared/enums";
import { Utils } from "../../../shared/utils";
import { formatNumberToUSDWithNegativeValues } from "../../../shared/calculators";
import { useDispatch, useSelector } from "react-redux";
import {
  resetShowDetailModal,
  setClientAndFacilityNames,
  setCsvData,
  setPatientAndAccountHoldersNames,
  setClientBillingIds
} from "../../state/users/admin-slice";
import {
  ClientsAndFacilities,
  Filters,
  PatientsAndAccountHolders,
  ClientBillingIds
} from "../../../implementation-specialist/models/reportsFilters";
import { RootState } from "../../../shared/state/root-reducer";
import { ReportFilterModal } from "./report-filters-modal";
import { DetailReportModal } from "./detail-report-modal";
import { handleGetDetailedSettlementReport, getPaymentChannels } from "../../state/users/admin-thunk";
import { AppDispatch } from "../../../shared/state/store";

interface AdminUsersReportsProps {
  dataReports: ReportsInfo
}

function ReportsResults(props: AdminUsersReportsProps) {
  const dispatch = useDispatch<AppDispatch>()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false)
  const [groupTransactionNumber, setGroupTransactionNumber] = useState("")

  const stateFields = {
    filteredReport: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredReport
    ),
    showDetailModal: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.showDetailModal
    ),
    reportFilters: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.reportFilters
    ),
    filteredDetailedReport: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredDetailedReport
    ),
  }

  const {showDetailModal, reportFilters, filteredDetailedReport} = stateFields

  const { dataReports } = props;
  let columns: Column[] = []
  let reports: any = []
  let patientsNames:string[] = []
  let accountHoldersNames:string[] = []
  let collabIds: string[] = []
  let clientNames: string[] = []
  let facilityNames: string[] = []
  columns = adminUserHelper.mapToColumns(dataReports)

  // change label of Account Holder Name to Billing Name only when reports_type=PayNow
  if (dataReports.filters.reportType === "PayNow") {
    let workCol = columns.find(x => x.label === 'Account Holder Name')
    if (workCol !== undefined) {
      workCol.label = "Billing Name"
    }
  }

  reports = adminUserHelper.mapToData(columns, dataReports)
  const [columnType, setColumnType] = useState("")
  const [orderDirection, setOrderDirection] = useState(orderDirections.asc)
  const [valueToOrderBy, setValueToOrderBy] = useState("")
  const [showDetailReportModal, setShowDetailReportModal] = useState(false)
  const [initialLoadStatus, setInitialLoadStatus] = useState(false);

  function handleDetailReportCancel() {
    dispatch(resetShowDetailModal());
    setShowDetailReportModal(false);
    setInitialLoadStatus(false);
  }

  const detailReportsInitialLoadHandler = (value: boolean) => {
    setInitialLoadStatus(value);
  }

  if (
    dataReports.filters.reportType === "SettlementSummary" ||
    dataReports.filters.reportType === "SettlementSummaryV2"
  ) {
    const column = {
      id : "link",
      label: "Link",
      minWidth: 15
    } as Column
    columns.unshift(column)
  }


  const descendingComparator = (a: any, b: any, orderBy: any) => {
    //a and b are the complete objects and orderBy is the object property
    let firstValue = a[orderBy]
    let secondValue = b[orderBy]
    switch (columnType) {
      case reportResultType.string:
        if(!firstValue) firstValue = ""
        if(!secondValue) secondValue = ""
        firstValue = formatStringValue(firstValue.toString())
        secondValue = formatStringValue(secondValue.toString())
        break;
      case reportResultType.integer:
        if(!firstValue) firstValue = 0
        if(!secondValue) secondValue = 0
        break;

      default:
        break;
    }

    if(secondValue === "" || secondValue === null || secondValue === undefined) return -1
    if(secondValue < firstValue) return -1
    if(secondValue > firstValue ) return 1
    if(secondValue === firstValue ) return 0
    return 1
  }

  const getComparator = (order:any , orderBy: any) => {
    return order === "desc"
      ? (a: any,b: any) => descendingComparator(a, b, orderBy)
      : (a: any,b: any) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray: any, comparator: any) => {
    const stabilizedRowArray = rowArray.map((el: any, index: number) => [el, index])
    stabilizedRowArray.sort((a: any,b: any) => {
      const order = comparator(a[0], b[0])
      if(order !== 0) return order
      return a[1] - b[1]
    })
    const sortedArray = stabilizedRowArray.map((el: any) => el[0])
    const additionalInfoArray = sortedArray.map((el: any) => {
      if (
        el.hasOwnProperty("IsReconciled") &&
        el.IsReconciled !== null &&
        el.IsReconciled !== undefined &&
        (el.IsReconciled === false || el.IsReconciled === "false")
      ) {
        // we need to highlight the row (light red color)
        return {
          ...el,
          highlightRow: true
        }
      } else {
        return {
          // we won't highlight the row
          ...el,
          highlightRow: false
        }
      }
    })
    return additionalInfoArray;
  }

  const handleRequestSort = async (event: any, property: any) => {
    const isAscending = (valueToOrderBy === property && orderDirection === "asc")
    await setValueToOrderBy(property)
    await setOrderDirection(isAscending ? orderDirections.desc : orderDirections.asc)
    setSortedCsvData(isAscending ? orderDirections.desc : orderDirections.asc, property)
  }

  const createSortHandler = (property: any, type?: string) => (event: any) => {
    if(type) setColumnType(type)
    handleRequestSort(event, property)
  }

  function handleFilterReportCancel() {
    setShowModal(false);
  }

  function handleFilterReportSubmit() {
    setShowModal(false);
  }

  let formatStringValue = (value:string | any) =>{
    if(value !== null){
      value = value.trimStart()
      value = value.toUpperCase()
      return value
    }
  }

  const formatValue = (label:string, type:string | any, columnId?: string) =>{
    switch (type) {
      case reportResultType.timeStamp:
        // we want admissionDate/dischargeDate to only show the dates, in UTC. no timestamp.
        if(label && (columnId === 'AdmissionDate' || columnId === 'DischargeDate')) {
          let date = Utils.convertISODateAsUTC(label);
          label = date?.toString() || '';
        }
        else if(label){
          let date = new Date(label)
          label = Utils.convertISODate(date, true)
        }
        break;
      case reportResultType.bigDecimal:
          if (columnId === 'Balance' && label === null) {
            label = ""
          } else {
            label = formatNumberToUSDWithNegativeValues(Number(label)).toString()
          }
        break;
      case reportResultType.boolean:
        label = (label !== null && label !== undefined) ? label?.toString() : '';
        break;
      default:
        break;
    }
    return label
  }

  const openModalHandler = (index: any) =>{
    const sortedReports = sortedRowInformation(reports, getComparator(orderDirection, valueToOrderBy))
    const pageAdjustedIndex = (page === 0) ? index : (page * rowsPerPage) + index
    const transactionNumber = sortedReports[pageAdjustedIndex]?.SettlementId?.toString()
    setGroupTransactionNumber(transactionNumber)
    const reportsFilters = {
      startDate: reportFilters.startDate,
      endDate: reportFilters.endDate,
      reportType: reportsType.SettlementDetail.toString(),
      groupTransactionNumber: transactionNumber
    } as Filters
    dispatch(handleGetDetailedSettlementReport(reportsFilters))
  }

  useEffect(() => {
    let csvData: Array<Array<string>>= [];
    reports.forEach((report: ReportsInfo) => {
      let tableValues:string[] = []
      columns.forEach(column=>{
        if (column.id !== "link"){
          let value = (report as any)[column.id]
          value = formatValue(value, column.type, column.id)
          tableValues.push(value)
          if(column.id === "PatientName" && !patientsNames.includes(formatStringValue(value)) && value !== null ){
            value = formatStringValue(value)
            patientsNames.push(value)
          }
          if(column.id === "AccountHolderName" && !accountHoldersNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            accountHoldersNames.push(value)
          }
          if(column.id === "CollabId" && !collabIds.includes(formatStringValue(value)) && value !== null) {
            value = formatStringValue(value)
            collabIds.push(value)
          }
          if(column.id === "ClientName" && !clientNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            clientNames.push(value)
          }
          if(column.id === "FacilityName" && !facilityNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            facilityNames.push(value)
          }
        }
      })
      csvData.push(tableValues)
    })

    const patientsAndAccountHoldersNames= {
      patientsNames: [...patientsNames.sort()],
      accountHoldersNames: [...accountHoldersNames.sort()]
    } as PatientsAndAccountHolders

    const clientBillingIds = {
      collabIds: [...collabIds.sort()]
    } as ClientBillingIds

    const clientAndFacilityNames= {
      clientNames: [...clientNames.sort()],
      facilityNames: [...facilityNames.sort()]
    } as ClientsAndFacilities

    dispatch(setPatientAndAccountHoldersNames(patientsAndAccountHoldersNames));
    dispatch(setClientBillingIds(clientBillingIds));
    dispatch(setClientAndFacilityNames(clientAndFacilityNames));

    let tableHeaders:string[] = []
    dataReports.headers.forEach(header=>{
      if (dataReports.filters.reportType === "PayNow")
      {
        if (header.columnName === "Account Holder Name") {
          tableHeaders.push("Billing Name")
        } else {
          tableHeaders.push(header.columnName)
        }
      } else {
        tableHeaders.push(header.columnName)
      }
    })
    csvData.unshift(tableHeaders)
    dispatch(setCsvData(csvData))
  }, [dataReports])

  useEffect(() => {
    if(filteredDetailedReport.data.length > 0){
      setShowDetailReportModal(showDetailModal)
    }
  }, [filteredDetailedReport])

  useEffect(() => {
    dispatch(getPaymentChannels());
  }, [dispatch])

  const setSortedCsvData = async (orderDirection: string, valueToOrderBy: string) => {
    let csvData: Array<Array<string>>= [];
    const sortedReports = await sortedRowInformation(reports, getComparator(orderDirection, valueToOrderBy))
    sortedReports.forEach((report: ReportsInfo)=>{
      let tableValues:string[] = []
        columns.forEach(column=>{
          if (column.id !== "link"){
            let value = (report as any)[column.id];
            value = formatValue(value, column.type, column.id)
            tableValues.push(value)
          }
      })
      csvData.push(tableValues)
    })
    let tableHeaders:string[] = []
    dataReports.headers.forEach(header=>{

      if (dataReports.filters.reportType === "PayNow")
      {
        if (header.columnName === "Account Holder Name") {
          tableHeaders.push("Billing Name")
        } else {
          tableHeaders.push(header.columnName)
        }
      } else {
        tableHeaders.push(header.columnName)
      }
    })
    csvData.unshift(tableHeaders)
    dispatch(setCsvData(csvData))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return <>
    <Paper className="mt-8">
      <>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, { value: reports?.length, label: 'All'  }]}
          component="div"
          count={reports.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"View"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer style={{maxHeight:"60vh"}}>
          <Table
            data-testid="results"
            className="table alternating-row"
            size="small"
            stickyHeader
          >
            <TableHead >
              <TableRow sx={{backgroundColor: 'red'}}>
                {columns.map((column) => (
                  (column.id !== "LegacyPatientId") && (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.id === "link" ? column.minWidth : 170, maxWidth:300} }
                      align="center"
                    >
                    {
                      column.id !== "link" ?
                        (
                          <TableSortLabel
                            active={valueToOrderBy === column.id}
                            direction={valueToOrderBy === column.id ? orderDirection : "asc"}
                            onClick={createSortHandler(column.id, column.type)}
                          >
                            {column.label}
                          </TableSortLabel>
                        ) : (
                          column.label
                        )
                    }
                    </TableCell>
                  )
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRowInformation(reports, getComparator(orderDirection, valueToOrderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((report: any, indexReport: number) => {
                  return (
                    <TableRow
                      data-testid="result-row"
                      hover
                      tabIndex={-1}
                      style={{backgroundColor: report?.highlightRow ? (indexReport % 2 === 0) ? '#ffcccb' : '#f7bdbc' : ""}}
                      key={indexReport}
                    >
                      {columns.map((column) => {
                        let value = (report as any)[column.id];
                        value = formatValue(value, column.type, column.id)
                        return (column.id !== "LegacyPatientId") && (
                          <TableCell key={column.id} align="center">
                            {
                              column.id !== "link" && (
                                <Grid container className="word-wrap">
                                  <Grid item xs={12} className="word-wrap">
                                    <Typography className="py-3" variant="body1">{value}</Typography>
                                  </Grid>
                                </Grid>
                              )
                            }
                            {column.id === "link" && (
                              <>
                                <IconButton
                                  disabled={report.hasOwnProperty('PayoutStatus') ? (report?.PayoutStatus !== 'Completed') : false}
                                  onClick={()=> openModalHandler(indexReport)}
                                  size="large"
                                >
                                  <LinkIcon color="primary"></LinkIcon>
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

        </TableContainer>
      </>
    </Paper>
    <ReportFilterModal
      transactionNumber = {groupTransactionNumber}
      title = {reportsType.SettlementDetail.toString()}
      open ={showModal}
      handleFilterReportCancel={handleFilterReportCancel}
      handleFilterReportSubmit={handleFilterReportSubmit}
    />

    <DetailReportModal
      transactionNumber = {groupTransactionNumber}
      title = {reportsType.SettlementDetail.toString()}
      open ={showDetailReportModal}
      handleDetailReportCancel={handleDetailReportCancel}
      initalLoadHandler={detailReportsInitialLoadHandler}
      initialLoadStatus={initialLoadStatus}
    />
  </>;
}

export default ReportsResults;
