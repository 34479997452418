import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  Chip,
  Paper,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Button, TextField, LoadingOverlay } from "@finpay-development/shared-components";
import { debounce } from "lodash";
import { AppDispatch } from "../../../shared/state/store";
import { RootState } from "../../../shared/state/root-reducer";
import {
  clearStatus,
  clearRiskClassSettingsStatus,
} from "../../../admin-configuration/state/admin-configuration-slice";
import { Utils } from "../../../shared/utils";
import { formatNumberToUSD } from "../../../shared/calculators";
import {
  setRiskAssessmentForm,
  clearFacilityRiskThresholdsStatus,
  clearClaimRatesStatus,
  clearClientRiskClassSettingsStatus,
  clearMultiFacilityRiskClassSettingsStatus,
  clearMultiFacilityReferralSourceRevenueStatus,
  clearPatientEpisodeMarginSettingsStatus,
  clearMultiFacilityReferralSourcesStatus,
  setSelectedFacilityRiskData,
} from "../../state/risk-assessment-slice";
import { configGetRiskThresholds } from "../../../admin-configuration/state/admin-configuration-thunk";
import {
  getMultiFacilityRiskThresholds,
  getClientClaimRates,
  getClientRiskClassSettings,
  getMultiFacilityiskClassSettings,
  getMultiFacilityReferralSourceRevenue,
  getPatientEpisodeMarginSettings,
  getMultiFacilityReferralSources,
} from "../../state/risk-assessment-thunk";
import { ConfigRiskThreshold } from "../../../admin-configuration/models/risk-threshold";
import {
  RiskStatus,
  FacilityRiskThreshold,
  FacilityRiskAssessment,
  emptyFacilityRiskAssessment,
  MultiFacilityRiskThreshold,
  FacilityRiskClassSettings,
  PatientEpisodeMarginSettings,
  ReferralSourceRevenue,
  emptyPatientEpisodeMarginSettings,
  EditedFacilityCensus,
  AdmitStatus,
  ClientRiskClassSettings,
} from "../../models/risk-assessment";
import { FinPayRiskClass } from "../../../admin-configuration/models/risk-class-setting";
import { apiStatus } from "../../models/estimator";
import InfoIcon from "@mui/icons-material/Info";
import {
  configGetLOC,
  configGetRiskClassSettings,
} from "../../../admin-configuration/state/admin-configuration-thunk";
import { getClientDetails, getClientLevelsOfCare } from "../../state/estimator-thunk";
import { getClientDeficiencyNotification } from "../../../implementation-specialist/state/clients/implementation-clients-thunk";
import { clearClientDeficiencyNotificationStatus } from "../../../implementation-specialist/state/clients/implementation-clients-slice";
import { clearVobClassificationsLoadingStatus } from "../../state/vob-slice";
import { getVobClassifications } from "../../state/vob-thunk";
import {
  setEstimator,
  clearClientLocStatus,
  clearConfigGetLOCStatus,
} from "../../state/estimator-slice";
import {
	Vob,
	VobPostBody
} from "../../models/vob";
import { saveCallbackStatus } from "@finpay-development/shared-components";
import { RiskOverwriteEstimateModal } from "./risk-overwrite-estimate-modal";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import { riskAssessmentUtils } from "../../utils/risk-assessment-utils";
import { ClientRiskAssessmentFields } from "src/shared/model/client-risk-assessment-config";

interface RiskMidSectionProps {
  facilityIds: number[];
  coreRiskClasses: FinPayRiskClass[];
  clientRiskAssessmentConfigs: ClientRiskAssessmentFields[]
  selectedFacilityRiskAssessmentConfig?: ClientRiskAssessmentFields
}

export function RiskAssessmentMidSection(props: RiskMidSectionProps) {
  const { facilityIds, coreRiskClasses, clientRiskAssessmentConfigs, selectedFacilityRiskAssessmentConfig } = props;

  const paramId = -2;

  const dispatch = useDispatch<AppDispatch>();

  // this will hold risk assessment data for every client facility
  const [facilityRiskAssessments, setFacilityRiskAssessments] = useState<FacilityRiskAssessment[]>([]);
  const [hreOnlyFacilityRiskAssessments, setHreOnlyFacilityRiskAssessments] = useState<FacilityRiskAssessment[]>([]);
  const [selectedFacilityData, setSelectedFacilityData] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [selectedFacilityDataHre, setSelectedFacilityDataHre] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [bestFacilityData, setBestFacilityData] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [secondBestFacilityData, setSecondBestFacilityData] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [newFacilityiskAssessment, setNewFacilityiskAssessment] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [oldFacilityiskAssessment, setOldFacilityiskAssessment] = useState<FacilityRiskAssessment>(emptyFacilityRiskAssessment);
  const [clientLocIsLoaded, setClientLocIsLoaded] = useState<Boolean>(false);
  const [claimRatesIsLoaded, setClaimRatesIsLoaded] = useState(false);
  const [configRiskClassSettingsIsLoaded, setConfigRiskClassSettingsIsLoaded] = useState(false);
  const [clientRiskClassSettingsIsLoaded, setClientRiskClassSettingsIsLoaded] = useState(false);
  const [multiFacilityRiskClassSettingsIsLoaded, setMultiFacilityRiskClassSettingsIsLoaded] = useState(false);
  const [clientDeficiencyNotificationIsLoaded, setClientDeficiencyNotificationIsLoaded] = useState(false);
  const [multiFacilityReferralSourcesAreLoaded, setMultiFacilityReferralSourcesAreLoaded ] = useState(false);
  const [multiFacilityReferralSourceRevenueIsLoaded, setMultiFacilityReferralSourceRevenueIsLoaded,] = useState(false);
  const [patientEpisodeMarginSettingsStateIsLoaded, setPatientEpisodeMarginSettingsStateIsLoaded ] = useState(false);
  const [vobClassificationsAreLoaded, setVobClassificationsAreLoaded] = useState(false);
  const [selectedRiskAssessmentFacilityId, setSelectedRiskAssessmentFacilityId] = useState(-1);
  const [deficiencySettingEiv, setDeficiencySettingEiv] = useState(false);
  const [hasEivdeficiencies, setHasEivdeficiencies] = useState(false);
  const [hasNprdeficiencies, setHasNprdeficiencies] = useState(false);

  const [editedFacilityCensus, setEditedFacilityCensus] = useState<EditedFacilityCensus[]>([]);

  const [overwriteEstimateModalOpen, setOverwriteEstimateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [censusData, setCensusData] = useState<number | null>(null);

  type GridItemProps = {
    txtAlign: string;
    txtColor?: string;
    underline?: boolean;
    background?: boolean;
  };

  type RowLabelHeaderProps = {
    isCleared: boolean;
  };

  const state = {
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.facilities
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext?.vobPatientContext.patient
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    riskAssessmentFormState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext.riskAssessmentForm
    ),
    configRiskThresholds: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.riskThresholds
    ),
    isLoadingConfigRiskThresholdsState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .isLoadingRiskThresholds
    ),
    getConfigRiskThresholdsStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .getRiskThresholdsStatus
    ),
    // holds risk threshold data for all client facilities
    multiFacilityRiskThresholds: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.multiFacilityRiskThresholds
    ),
    isLoadingFacilityRiskThresholdsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingFacilityRiskThresholds
    ),
    facilityRiskThresholdsStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .facilityRiskThresholdsStatus
    ),
    // begin level of care state
    masterLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.levelsOfCare
    ),
    clientLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare
    ),
    getClientLOCStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading
          .getClientLOCStatus
    ),
    // levels of care for all client facilities
    isLoadingClientLoc: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading
          .isLoadingClientLoc
    ),
    configGetLOCStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading
          .configGetLOCStatus
    ),
    isLoadingMasterLoc: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading
          .isLoadingMasterLoc
    ),
    // payer claim rates
    clientPayerClaimRatesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.clientPayerClaimRates
    ),
    isLoadingClientClaimRatesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingClientClaimRates
    ),
    clientClaimRatesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .clientClaimRatesStatus
    ),
    // config risk class settings
    configRiskClassSettingsState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.riskClassSettings
    ),
    isLoadingConfigRiskClassSettingState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .isLoadingRiskClassSettings
    ),
    getConfigRiskClassSettingStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .getRiskClassSettingsStatus
    ),
    // client risk class settings
    clientRiskClassSettingsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.clientRiskClassSettings
    ),
    clientDetails: useSelector(
      (state: RootState) =>
      state.admissionsAdvisorContext.estimatorContext?.clientDetails
    ),
    isLoadingClientRiskClassSettingsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingClientRiskClassSettings
    ),
    clientRiskClassSettingsStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .clientRiskClassSettingsStatus
    ),
    // facility risk class settings
    multiFacilityRiskClassSettingsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.multiFacilityRiskClassSettings
    ),
    isLoadingMultiFacilityRiskClassSettingsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingMultiFacilityRiskClassSettings
    ),
    multiFacilityRiskClassSettingsStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .multiFacilityRiskClassSettingsStatus
    ),
    // client deficiency notofication
    clientDeficiencyNotificationState: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .currentClientDeficiencyNotification
    ),
    isLoadingDeficiencyNotificationState: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .isLoadingDeficiencyNotification
    ),
    clientDeficiencyNotificationStatusState: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .clientDeficiencyNotificationStatus
    ),
    // facility referral sources
    multiFacilityReferralSourcesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.multiFacilityReferralSources
    ),
    isLoadingMultiFacilityReferralSourcesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingMultiFacilityReferralSources
    ),
    multiFacilityReferralSourcesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .multiFacilityReferralSourcesStatus
    ),
    // level of care referral source revenue
    multiFacilityReferralSourceRevenueState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.multiFacilityReferralSourceRevenue
    ),
    isLoadingtMultiFacilityReferralSourceRevenueState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingtMultiFacilityReferralSourceRevenue
    ),
    multiFacilityReferralSourceRevenueStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .multiFacilityReferralSourceRevenueStatus
    ),
    // vob classifications
    vobClassificationsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobContext?.vobClassifications
    ),
    isLoadingVobClassificationsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobContext?.isLoading
          .isLoadingVobClassifications
    ),
    vobClassificationsLoadingStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobContext?.isLoading
          .vobClassificationsLoadingStatus
    ),
  };

  const {
    facilities,
    vobPatientState,
    vobState,
    estimatorState,
    riskAssessmentFormState,
    configRiskThresholds,
    isLoadingConfigRiskThresholdsState,
    getConfigRiskThresholdsStatus,
    multiFacilityRiskThresholds,
    isLoadingFacilityRiskThresholdsState,
    facilityRiskThresholdsStatusState,
    isLoadingMasterLoc,
    configGetLOCStatus,
    masterLevelsOfCareState,
    isLoadingClientLoc,
    getClientLOCStatus,
    clientLevelsOfCareState,
    clientPayerClaimRatesState,
    isLoadingClientClaimRatesState,
    clientClaimRatesStatusState,
    configRiskClassSettingsState,
    isLoadingConfigRiskClassSettingState,
    getConfigRiskClassSettingStatus,
    clientRiskClassSettingsState,
    clientDetails,
    isLoadingClientRiskClassSettingsState,
    clientRiskClassSettingsStatusState,
    multiFacilityRiskClassSettingsState,
    isLoadingMultiFacilityRiskClassSettingsState,
    multiFacilityRiskClassSettingsStatusState,
    clientDeficiencyNotificationState,
    isLoadingDeficiencyNotificationState,
    clientDeficiencyNotificationStatusState,
    multiFacilityReferralSourcesState,
    isLoadingMultiFacilityReferralSourcesState,
    multiFacilityReferralSourcesStatusState,
    multiFacilityReferralSourceRevenueState,
    isLoadingtMultiFacilityReferralSourceRevenueState,
    multiFacilityReferralSourceRevenueStatusState,
    vobClassificationsState,
    isLoadingVobClassificationsState,
    vobClassificationsLoadingStatusState,
  } = state;

  const isConfigRiskThresholdLoaded = !isLoadingConfigRiskThresholdsState && getConfigRiskThresholdsStatus === saveCallbackStatus.success
  const isMultiFacilityRiskThresholdsLoaded = !isLoadingFacilityRiskThresholdsState && facilityRiskThresholdsStatusState === saveCallbackStatus.success

  const riskThresholdData = useMemo(() => {
    if(isConfigRiskThresholdLoaded && isMultiFacilityRiskThresholdsLoaded && configRiskThresholds.length > 0) {

      return multiFacilityRiskThresholds.map(
        (multiFacility: MultiFacilityRiskThreshold) => {
          const mergedRiskThresholds =
            riskAssessmentUtils.mergeConfigFacilityRiskThresholds(
              configRiskThresholds,
              multiFacility.facilityRiskThresholds,
              multiFacility.clientFacilityId
            );
            return {
              ...multiFacility,
              facilityRiskThresholds: mergedRiskThresholds
            }
        }
      );
    } else {
      return []
    }
  }, [isConfigRiskThresholdLoaded, isMultiFacilityRiskThresholdsLoaded])

  useEffect(() => {
    return () => {
      dispatch(clearStatus());
      dispatch(clearFacilityRiskThresholdsStatus());
    }
  }, [])

  useEffect(() => {
    if (!masterLevelsOfCareState.length) {
      dispatch(configGetLOC(paramId));
    } else {
      getLevelsOfCareByClient();
    }
    setIsLoading(true);

    dispatch(getVobClassifications(paramId));
    dispatch(configGetRiskThresholds({ paramId: paramId }));
    dispatch(configGetRiskClassSettings({ paramId: paramId }));
    dispatch(getClientDetails({paramId: paramId, clientId: estimatorState.client?.clientId!}))

    dispatch(
      getClientRiskClassSettings({
        paramId: paramId,
        clientId: estimatorState.client?.clientId!,
      })
    );

    dispatch(
      getMultiFacilityiskClassSettings({
        paramId: paramId,
        facilityIds: riskAssessmentFormState.facilityIds,
      })
    );

    dispatch(
      getClientClaimRates({
        paramId: paramId,
        clientId: estimatorState.client?.clientId!,
      })
    );

    dispatch(getClientDeficiencyNotification(estimatorState.client?.clientId!));

    dispatch(
      getMultiFacilityReferralSources({
        paramId: paramId,
        clientId: estimatorState.client?.clientId!,
        facilityIds: riskAssessmentFormState.facilityIds,
      })
    );

    dispatch(
      getMultiFacilityReferralSourceRevenue({
        paramId: paramId,
        facilityIds: riskAssessmentFormState.facilityIds,
      })
    );

    if (facilityIds.length > 0) {
      dispatch(
        getMultiFacilityRiskThresholds({
          paramId: paramId,
          facilityIds: riskAssessmentFormState.facilityIds,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingMasterLoc && configGetLOCStatus === apiStatus.success) {
      // config Levels of Care has loaded
      dispatch(clearConfigGetLOCStatus());
      getLevelsOfCareByClient();
    }

    if (!isLoadingClientLoc && getClientLOCStatus === apiStatus.success) {
      // client Levels of Care has loaded
      dispatch(clearClientLocStatus());
      setClientLocIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMasterLoc, isLoadingClientLoc]);

  useEffect(() => {
    // claimrates is done loading
    if (
      !isLoadingClientClaimRatesState &&
      clientClaimRatesStatusState === saveCallbackStatus.success
    ) {
      setClaimRatesIsLoaded(true);
      clearClaimRatesStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientClaimRatesStatusState,
  ]);

  useEffect(() => {
    // config risk class settings are done loading
    if (
      !isLoadingConfigRiskClassSettingState &&
      getConfigRiskClassSettingStatus === saveCallbackStatus.success
    ) {
      if (configRiskClassSettingsState !== undefined) {
        setConfigRiskClassSettingsIsLoaded(true);
        dispatch(clearRiskClassSettingsStatus());
      }
    }
    // client risk class settings are done loading
    if (
      !isLoadingClientRiskClassSettingsState &&
      clientRiskClassSettingsStatusState === saveCallbackStatus.success
    ) {
      if (clientRiskClassSettingsState !== undefined) {
        setClientRiskClassSettingsIsLoaded(true);
        dispatch(clearClientRiskClassSettingsStatus());
      }
    }
    // facility risk class settings are done loading
    if (
      !isLoadingMultiFacilityRiskClassSettingsState &&
      multiFacilityRiskClassSettingsStatusState === saveCallbackStatus.success
    ) {
      if (multiFacilityRiskClassSettingsState !== undefined) {
        setMultiFacilityRiskClassSettingsIsLoaded(true);
        dispatch(clearMultiFacilityRiskClassSettingsStatus());
      }
    }

    // client deficiency settings are done loading
    if (
      !isLoadingDeficiencyNotificationState &&
      clientDeficiencyNotificationStatusState === saveCallbackStatus.success
    ) {
      if (clientDeficiencyNotificationState !== undefined) {
        setClientDeficiencyNotificationIsLoaded(true);
        dispatch(clearClientDeficiencyNotificationStatus());
      }
    }

    // referral source revenue settings are done loading
    if (
      !isLoadingMultiFacilityReferralSourcesState &&
      multiFacilityReferralSourcesStatusState === saveCallbackStatus.success
    ) {
      if (multiFacilityReferralSourcesState !== undefined) {
        setMultiFacilityReferralSourcesAreLoaded(true);
        dispatch(clearMultiFacilityReferralSourcesStatus());
      }
    }
    if (
      !isLoadingtMultiFacilityReferralSourceRevenueState &&
      multiFacilityReferralSourceRevenueStatusState ===
        saveCallbackStatus.success
    ) {
      if (multiFacilityReferralSourceRevenueState !== undefined) {
        setMultiFacilityReferralSourceRevenueIsLoaded(true);
        dispatch(clearMultiFacilityReferralSourceRevenueStatus());
      }
    }
    // vob classifications
    if (
      !isLoadingVobClassificationsState &&
      vobClassificationsLoadingStatusState === saveCallbackStatus.success
    ) {
      if (vobClassificationsState !== undefined) {
        dispatch(clearVobClassificationsLoadingStatus());
        setVobClassificationsAreLoaded(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingConfigRiskClassSettingState,
    isLoadingClientRiskClassSettingsState,
    isLoadingMultiFacilityRiskClassSettingsState,
    isLoadingDeficiencyNotificationState,
    isLoadingMultiFacilityReferralSourcesState,
    isLoadingtMultiFacilityReferralSourceRevenueState,
    isLoadingVobClassificationsState,
  ]);

  useEffect(() => {
    if (
      clientLocIsLoaded &&
      isMultiFacilityRiskThresholdsLoaded &&
      isConfigRiskThresholdLoaded &&
      claimRatesIsLoaded &&
      configRiskClassSettingsIsLoaded &&
      clientRiskClassSettingsIsLoaded &&
      multiFacilityRiskClassSettingsIsLoaded &&
      clientDeficiencyNotificationIsLoaded &&
      multiFacilityReferralSourcesAreLoaded &&
      multiFacilityReferralSourceRevenueIsLoaded &&
      vobClassificationsAreLoaded
    ) {
      loadFacilityRiskAssessments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientLocIsLoaded,
    isMultiFacilityRiskThresholdsLoaded,
    isConfigRiskThresholdLoaded,
    claimRatesIsLoaded,
    configRiskClassSettingsIsLoaded,
    clientRiskClassSettingsIsLoaded,
    multiFacilityRiskClassSettingsIsLoaded,
    clientDeficiencyNotificationIsLoaded,
    multiFacilityReferralSourcesAreLoaded,
    multiFacilityReferralSourceRevenueIsLoaded,
    patientEpisodeMarginSettingsStateIsLoaded,
    vobClassificationsAreLoaded,
  ]);

  useEffect(() => {
    if (!isLoading) {
      loadFacilityRiskAssessments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskAssessmentFormState, editedFacilityCensus, isLoading]);

  useEffect(() => {
    if (facilityRiskAssessments.length > 0) {
      createSelectedBestSecondBest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityRiskAssessments]);

  const getLevelsOfCareByClient = () => {
    if (masterLevelsOfCareState?.length > 0) {
      const config: any = {
        paramId: paramId,
        clientId: estimatorState.client.clientId,
        payerPlanId: vobState.plan.payorPlanId,
        filterByPayerPlanId: false,
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      dispatch(getClientLevelsOfCare(config));
    }
  };

  const createSelectedBestSecondBest = () => {
    let facilitiesByRisk: FacilityRiskAssessment[] = [];

    // add up facility risk scores, lowest score is best facility
    facilityRiskAssessments?.forEach((facrisk) => {
      if (facrisk.facilityId === riskAssessmentFormState.facility.facilityId) {
        let facriskCopy = Utils.deepClone(facrisk);
        facriskCopy.facilityLabel = riskAssessmentFormState.compareBest? "Selected Facility" : <span style={{color: "#009900"}}>Cleared</span>;
        if (selectedRiskAssessmentFacilityId === -1) {
          facriskCopy.isSelected = true;
          setSelectedRiskAssessmentFacilityId(facriskCopy.facilityId);
        } else {
          if (selectedRiskAssessmentFacilityId === facriskCopy.facilityId) {
            facriskCopy.isSelected = true;
          }
        }
        setSelectedFacilityData(facriskCopy);
        dispatch(setSelectedFacilityRiskData(facriskCopy))
        // create pia hre version from selectedFacilityData
        let selectedHreOnlyFacilityData = hreOnlyFacilityRiskAssessments.find(hreo => hreo.facilityId ===  riskAssessmentFormState.facility.facilityId);
        if (selectedHreOnlyFacilityData) {
          selectedHreOnlyFacilityData.facilityLabel = <span style={{color: "red"}}>High Risk Exception</span>;
          setSelectedFacilityDataHre(selectedHreOnlyFacilityData);
        }

      }

      if (facrisk.isValid) {
        facilitiesByRisk.push(facrisk);
      }
    });

    if (selectedFacilityRiskAssessmentConfig?.isEiv) {
      // sort by estInsVal then by netPatientRevenueEst
      facilitiesByRisk.sort(function (a, b) {
        if (a.estInsVal > b.estInsVal) return -1;
        else if (a.estInsVal < b.estInsVal) return 1;
        else if (a.netPatientRevenueEst > b.netPatientRevenueEst) return -1;
        else if (a.netPatientRevenueEst < b.netPatientRevenueEst) return 1;
        else return 0;
      });
    } else {
      // sort by netPatientRevenueEst then by estInsVal
      facilitiesByRisk.sort(function (a, b) {
        if (a.netPatientRevenueEst > b.netPatientRevenueEst) return -1;
        else if (a.netPatientRevenueEst < b.netPatientRevenueEst) return 1;
        else if (a.estInsVal > b.estInsVal) return -1;
        else if (a.estInsVal < b.estInsVal) return 1;
        else return 0;
      });
    }

    if (facilitiesByRisk.length > 0) {
      facilitiesByRisk[0].facilityLabel = "Best Facility";
      if (selectedRiskAssessmentFacilityId === facilitiesByRisk[0].facilityId) {
        facilitiesByRisk[0].isSelected = true;
      }
      setBestFacilityData(facilitiesByRisk[0]);
    }
    if (facilitiesByRisk.length > 1) {
      facilitiesByRisk[1].facilityLabel = "2nd Best Facility";
      if (selectedRiskAssessmentFacilityId === facilitiesByRisk[1].facilityId) {
        facilitiesByRisk[1].isSelected = true;
      }
      setSecondBestFacilityData(facilitiesByRisk[1]);
    }
  };

  // this function is the heart of this component.
  // It takes all the configuration state and estimate/vob state
  // and creates a risk assessment for every Facility
  const loadFacilityRiskAssessments = () => {

    // this merges all Risk Class cleared and hre data for all facilities for lookup
    let mergedRiskClassSettings: FacilityRiskClassSettings[] =
      riskAssessmentUtils.mergeRiskClassSettings(
        coreRiskClasses,
        configRiskClassSettingsState,
        clientRiskClassSettingsState,
        multiFacilityRiskClassSettingsState
      );

    // this merges all Referral Sources data for all facilities for lookup
    let mergedReferralSourceData: ReferralSourceRevenue[] =
      riskAssessmentUtils.mergeReferralSourceRevenue(
        multiFacilityReferralSourcesState,
        multiFacilityReferralSourceRevenueState
      );

    // if payorRisk is selected it changes vob
    let vobCopy: Vob = Utils.deepClone(vobState);
    switch(riskAssessmentFormState.payorRisk.payorRiskId) {
      case 2:
        // Out of Network Payer Risk
        vobCopy.payer.inNetwork = false;
        break;
      case 3:
        // Self Pay Payer Risk
        let vobPostBody: VobPostBody = admissionsAdvisorUtils.createSelfPayVob(vobPatientState?.advisorPatientId!, vobCopy, vobClassificationsState[0]);
        vobCopy = vobPostBody?.vobBody;
        break;
      default:
        // nothing
    }

    let multiFacilityRiskAssessments: FacilityRiskAssessment[] =
      riskAssessmentUtils.makeMultiFacilityRiskAssessments(
        facilities,
        riskAssessmentFormState,
        vobCopy,
        estimatorState,
        clientLevelsOfCareState,
        riskThresholdData,
        mergedRiskClassSettings,
        clientPayerClaimRatesState,
        clientRiskAssessmentConfigs,
        clientDeficiencyNotificationState,
        mergedReferralSourceData,
        false,
        vobClassificationsState,
        editedFacilityCensus
      );

      // this is klugey but CW really wanted this.
      // it creates the hre version of the selected facility risk assessment
      let hreMultiFacilityRiskAssessments: FacilityRiskAssessment[] =
      riskAssessmentUtils.makeMultiFacilityRiskAssessments(
        facilities,
        riskAssessmentFormState,
        vobCopy,
        estimatorState,
        clientLevelsOfCareState,
        riskThresholdData,
        mergedRiskClassSettings,
        clientPayerClaimRatesState,
        clientRiskAssessmentConfigs,
        clientDeficiencyNotificationState,
        mergedReferralSourceData,
        true,
        vobClassificationsState,
        editedFacilityCensus
      );

    // determine if there are any eiv or npr deficiencies (hides/shows data)
    setDeficiencySettingEiv(
      clientDeficiencyNotificationState?.isEiv ? true : false
    );
    multiFacilityRiskAssessments.forEach((multifac) => {
      if (multifac.eivDeficiency > 0) {
        setHasEivdeficiencies(true);
      }
      if (multifac.netPatientRevenueDeficiency > 0) setHasNprdeficiencies(true);
    });

    setFacilityRiskAssessments(multiFacilityRiskAssessments);
    setHreOnlyFacilityRiskAssessments(hreMultiFacilityRiskAssessments);
    setIsLoading(false);
  };

  const displayAsPct = (value: number): string => {
    let returnPct = "0%";
    if (value) {
      returnPct = value.toFixed(0) + "%";
    }
    return returnPct;
  };

  const setSelectedAssessment = (facilityId: number) => {
    let selectedRiskAsses = facilityRiskAssessments.find(
      (riskassess: FacilityRiskAssessment) =>
        riskassess.facilityId === facilityId
    );
    let oldRiskAsses = facilityRiskAssessments.find(
      (riskassess: FacilityRiskAssessment) =>
        riskassess.facilityId === selectedRiskAssessmentFacilityId
    );
    //oldFacilityiskAssessment, setOldFacilityiskAssessment
    setOldFacilityiskAssessment(oldRiskAsses!);
    setNewFacilityiskAssessment(selectedRiskAsses!);
    setOverwriteEstimateModalOpen(true);
  };

  const handleOverwriteModalSubmit = () => {
    let facilityRiskAssessmentsCopy = Utils.deepClone(facilityRiskAssessments);

    // set selected facilityRiskAssessment to false;
    facilityRiskAssessmentsCopy.forEach(
      (riskassess: FacilityRiskAssessment) => {
        riskassess.isSelected = false;
      }
    );
    let selectedRiskAsses: FacilityRiskAssessment =
      facilityRiskAssessmentsCopy.find(
        (riskassess: FacilityRiskAssessment) =>
          riskassess.facilityId === newFacilityiskAssessment.facilityId
      );
    selectedRiskAsses.isSelected = true;
    setSelectedRiskAssessmentFacilityId(newFacilityiskAssessment.facilityId);
    setFacilityRiskAssessments(facilityRiskAssessmentsCopy);

    // update riskAssessmentForm state with selection
    const facilityToSave = facilities
      ?.flat()
      .find((fac) => fac?.facilityId === newFacilityiskAssessment.facilityId);
    let riskAssessmentFormStateCopy = Utils.deepClone(riskAssessmentFormState);
    riskAssessmentFormStateCopy.facility = {
      facilityId: facilityToSave?.facilityId,
      facilityName: facilityToSave?.facilityName,
    };
    dispatch(setRiskAssessmentForm(riskAssessmentFormStateCopy));

    // update estimate state with selection
    let estimatorStateCopy = Utils.deepClone(selectedRiskAsses.estimateBody);
    estimatorStateCopy.riskAssessmentForm = riskAssessmentFormStateCopy;
    dispatch(setEstimator(estimatorStateCopy));
    setOverwriteEstimateModalOpen(false);
  };

  const changeFacilityCensus = (facilityId: number, value: number, facilityCensus: EditedFacilityCensus[]) => {
    let editedFacilityCensusCopy = Utils.deepClone(facilityCensus);
    let newFacilityCensus: EditedFacilityCensus = {facilityId: facilityId, census: value}
    //remove this facility census if exists
    let index = editedFacilityCensusCopy?.findIndex(
      (cens: EditedFacilityCensus) => cens.facilityId === facilityId
    );
    if (index! > -1) {
      editedFacilityCensusCopy?.splice(index!, 1);
    }
    editedFacilityCensusCopy.push(newFacilityCensus);
    setEditedFacilityCensus(editedFacilityCensusCopy);
  };

  const debouncedChangeHandler = useMemo(() => {
    return debounce(changeFacilityCensus, 900);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function handleOverwriteModalCancel() {
    setOverwriteEstimateModalOpen(false);
  }

  const renderAdmissionRiskScoring = (riskAssessment: AdmitStatus | null) => {

    switch(riskAssessment) {
      case (AdmitStatus.fast):
        return (
          <Chip
            label={AdmitStatus.fast}
            sx={{ backgroundColor: "#d0e7e1", color: "#5e8e6b" }}
          />
        )

      case (AdmitStatus.admit):
        return (
          <Chip
            label={AdmitStatus.admit}
            sx={{ backgroundColor: "#d0e7e1", color: "#5e8e6b" }}
          />
        )

      case (AdmitStatus.pending):
        return (
          <Chip
            label={AdmitStatus.pending}
            sx={{ backgroundColor: "#f1e6c9", color: "#9c7427" }}
          />
        )
      
      case (AdmitStatus.noAdmit):
        return (
          <Chip
            label={AdmitStatus.noAdmit}
            sx={{ backgroundColor: "#e6cbd4", color: "#D13D3F" }}
          />
        )
      
      default: 
          return null
    }
  }

  // renders High/Medium/Low Risk
  const riskStatusChip = (statusCode: RiskStatus) => {
    switch (statusCode) {
      case RiskStatus.high:
        return (
          <Chip
            label="High Risk"
            sx={{ backgroundColor: "#e6cbd4", color: "#D13D3F" }}
          />
        );
      case RiskStatus.medium:
        return (
          <Chip
            label="Medium Risk"
            sx={{ backgroundColor: "#f1e6c9", color: "#9c7427" }}
          />
        );
      case RiskStatus.low:
        return (
          <Chip
            label="Low Risk"
            sx={{ backgroundColor: "#d0e7e1", color: "#5e8e6b" }}
          />
        );
      default:
        return <Chip label="N/A" color="secondary" />;
    }
  };

  const GridItem = styled(Box)<GridItemProps>(
    ({ theme, txtAlign, txtColor, underline, background }) => ({
      ...theme.typography.body2,
      padding: theme.spacing(1),
      height: "2.5em",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: txtAlign === "center" ? "center" : "start",
      borderBottom: underline ? "1px solid #cccccc" : "none",
      color: txtColor === "" || txtColor === undefined ? theme.palette.text.secondary : txtColor,
      backgroundColor: background ? "#f5f7f9" : ""
    })
  );

  const RowLabelHeader = styled(Box)<RowLabelHeaderProps>(
    ({ theme, isCleared }) => ({
      backgroundColor: isCleared ? "#d0e7e1" : "#e6cbd4",
      ...theme.typography.body2,
      padding: theme.spacing(1),
      height: "2.5em",
      display: "flex",
      color: isCleared ? "#5e8e6b" : "#D13D3F"
    })
  );

  const censusHandler = (value: string) => {
    if (value === "") {
      return null
    }

    let returnVal: number = 0;

    if (Number(value) > 100) {
      returnVal = 100;
    } else {
      returnVal = Number(value);
    }

    return returnVal;
  }
  

  const isClientConfiguredForRiskScoring = clientDetails?.raVersion === 2
  const isCompareBest = riskAssessmentFormState.compareBest
  const isCleared = riskAssessmentFormState.showCleared

  const firstColumnWidth = isCompareBest ? 6 : 8
  const facilityColumnWidth = 2
  const admitStatusFullWidth = 4

  // client needs to be configured to see admit status
  const selectedFacilityAdmitStatus = isClientConfiguredForRiskScoring && renderAdmissionRiskScoring(selectedFacilityData.riskAssessment)
  const selectedHREFacilityAdmitStatus = isClientConfiguredForRiskScoring && renderAdmissionRiskScoring(selectedFacilityDataHre.riskAssessment)
  const bestFacilityAdmitStatus = isClientConfiguredForRiskScoring && renderAdmissionRiskScoring(bestFacilityData.riskAssessment)
  const secondBestFacilityAdmitStatus = isClientConfiguredForRiskScoring && renderAdmissionRiskScoring(secondBestFacilityData.riskAssessment)

  return (
    <>
      <Paper className="mt-5">
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <Box className="p-4">

            {/* top level container (parent) */}
            <Grid container>

              {/* header and titles row */}
              <Grid item xs={firstColumnWidth} />

              <Grid item xs={facilityColumnWidth} textAlign="center">
                <h4 className="my-0">{selectedFacilityData.facilityName}</h4>
                <small>{selectedFacilityData.facilityLabel}</small>
              </Grid>

              {
                isCompareBest ?
                  <>
                    <Grid item xs={facilityColumnWidth} textAlign="center">
                      <h4 className="my-0">{bestFacilityData.facilityName}</h4>
                      <small>{bestFacilityData.facilityLabel}</small>
                    </Grid>
                    <Grid item xs={facilityColumnWidth} textAlign="center">
                      <h4 className="my-0">{secondBestFacilityData.facilityName}</h4>
                      <small>{secondBestFacilityData.facilityLabel}</small>
                    </Grid>
                  </>
                  :
                  <Grid item xs={facilityColumnWidth} textAlign="center">
                    <h4 className="my-0">{selectedFacilityDataHre.facilityName}</h4>
                    <small>{selectedFacilityDataHre.facilityLabel}</small>
                  </Grid>
              }

              {/* admit status row */}
              {isClientConfiguredForRiskScoring && 
                <>
                  <Grid xs={firstColumnWidth}/>
                  {
                    isCompareBest ?
                    <>
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem txtAlign="center">
                          {selectedFacilityAdmitStatus}
                        </GridItem>
                      </Grid>
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem txtAlign="center">
                          {bestFacilityAdmitStatus}
                        </GridItem>
                      </Grid>
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem txtAlign="center">
                          {secondBestFacilityAdmitStatus}
                        </GridItem>
                      </Grid>
                    </>
                    :
                    <Grid test-id="admit-status" item xs={admitStatusFullWidth}>
                      <GridItem txtAlign="center">
                        {selectedHREFacilityAdmitStatus}
                      </GridItem>
                    </Grid>
                  }
                </>
              }
              
              {/* facility census input row */}
              {isCompareBest &&
                  <>
                    <Grid item xs={firstColumnWidth}/>
                    <Grid item xs={facilityColumnWidth} style={{padding: '1rem'}}>
                      <TextField
                        name={`census-${selectedFacilityData.facilityId}`}
                        type="number"
                        label="Facility Census"
                        value={censusData}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <strong>%</strong>
                            </span>
                          </InputAdornment>
                        }
                        onChange={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (numberValue) {
                            debouncedChangeHandler(selectedFacilityData.facilityId, numberValue, editedFacilityCensus);
                          }
                        }}
                        onBlur={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (!numberValue) {
                            setCensusData(0);
                            changeFacilityCensus(selectedFacilityData.facilityId, 0, editedFacilityCensus)
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={facilityColumnWidth} style={{padding: '1rem'}}>
                      <TextField
                        name={`census-${bestFacilityData.facilityId}`}
                        type="number"
                        label="Facility Census"
                        value={censusData}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <strong>%</strong>
                            </span>
                          </InputAdornment>
                        }
                        onChange={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (numberValue) {
                            debouncedChangeHandler(bestFacilityData.facilityId, numberValue, editedFacilityCensus);
                          }
                        }}
                        onBlur={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (!numberValue) {
                            setCensusData(0);
                            changeFacilityCensus(bestFacilityData.facilityId, 0, editedFacilityCensus)
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={facilityColumnWidth} style={{padding: '1rem'}}>
                      <TextField
                        name={`census-${secondBestFacilityData.facilityId}`}
                        type="number"
                        label="Facility Census"
                        value={censusData}
                        endAdornment={
                          <InputAdornment position="end">
                            <span>
                              <strong>%</strong>
                            </span>
                          </InputAdornment>
                        }
                        onChange={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (numberValue) {
                            debouncedChangeHandler(secondBestFacilityData.facilityId, numberValue, editedFacilityCensus);
                          }
                        }}
                        onBlur={(e: Event) => {
                          let numberValue: number | null = censusHandler((e.target as HTMLInputElement).value);
                          setCensusData(numberValue);
                          if (!numberValue) {
                            setCensusData(0);
                            changeFacilityCensus(secondBestFacilityData.facilityId, 0, editedFacilityCensus)
                          }
                        }}
                      />
                    </Grid>
                  </>
              }

            {/* risk callout, and buttons row */}
            {
              isCompareBest && 
              <>
                <Grid item container xs={firstColumnWidth} alignItems="center">
                  <Grid 
                    item
                    xs={12}
                    alignItems="center"
                    height="3.5rem"
                    color={isCleared ? "#5e8e6b" : "#D13D3F"}
                    style={{backgroundColor: isCleared ? "#d0e7e1" : "#e6cbd4"}}
                  >
                    <RowLabelHeader isCleared={isCleared}> 
                      {isCleared ? "CLEARED" : "HIGH RISK EXCEPTION"}
                    </RowLabelHeader>
                  </Grid>
                </Grid>
                <Grid item xs={facilityColumnWidth} textAlign="center">
                  <Button
                    style={{ margin: "0.25em" }}
                    type={selectedFacilityData.isSelected ? "primary" : "secondary"}
                    onClick={() => setSelectedAssessment(selectedFacilityData.facilityId)}
                    >
                    {selectedFacilityData.isSelected ? "Selected" : "Select"}
                  </Button>
                </Grid>
                <Grid item xs={facilityColumnWidth} textAlign="center">
                  <Button
                    style={{ margin: "0.25em" }}
                    type={bestFacilityData.isSelected ? "primary" : "secondary"}
                    onClick={() => setSelectedAssessment(bestFacilityData.facilityId)}
                    >
                    {bestFacilityData.isSelected ? "Selected" : "Select"}
                  </Button>
                </Grid>
                <Grid item xs={facilityColumnWidth} textAlign="center">
                  <Button
                    style={{ margin: "0.25em" }}
                    type={secondBestFacilityData.isSelected ? "primary" : "secondary"}
                    onClick={() => setSelectedAssessment(secondBestFacilityData.facilityId)}
                    >
                    {secondBestFacilityData.isSelected ? "Selected" : "Select"}
                  </Button>
                </Grid>
              </>
            }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="left">
                  Patient Days to Reimbursement
                </GridItem>
              </Grid>
              <Grid test-id="days-to-reimbursement-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center">
                  {Math.ceil(selectedFacilityData.daysToReimbursement.value)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {Math.ceil(bestFacilityData.daysToReimbursement.value)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {Math.ceil(secondBestFacilityData.daysToReimbursement.value)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="days-to-reimbursement-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {Math.ceil(selectedFacilityDataHre.daysToReimbursement.value)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" underline background>
                  <div>
                    <strong>AMA Risk</strong>
                    <IconButton size="small" title="The risk of the patient leaving AMA before insurance reimbursement kicks in.">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </div>
                </GridItem>
              </Grid>
              <Grid test-id="ama-risk-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" underline background>
                  {riskStatusChip(selectedFacilityData.daysToReimbursement.risk)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                <Grid item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline background>
                    {riskStatusChip(bestFacilityData.daysToReimbursement.risk)}
                  </GridItem>
                </Grid>
                <Grid item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline background>
                    {riskStatusChip(secondBestFacilityData.daysToReimbursement.risk)}
                  </GridItem>
                </Grid>
                </>
                :
                <Grid test-id="ama-risk-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline background>
                    {riskStatusChip(selectedFacilityDataHre.daysToReimbursement.risk)}
                  </GridItem>
                </Grid>
              }
              

              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" >
                  PFR As a Percentage of Adjusted Charges
                </GridItem>
              </Grid>
              <Grid test-id="pfr-percent-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" >
                  {displayAsPct(selectedFacilityData.pfrRiskSettings.value)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {displayAsPct(bestFacilityData.pfrRiskSettings.value)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {displayAsPct(secondBestFacilityData.pfrRiskSettings.value)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="pfr-percent-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {displayAsPct(selectedFacilityDataHre.pfrRiskSettings.value)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" underline background>
                  <div>
                    <strong>PFR Concentration Risk</strong>
                    <IconButton size="small" title="The risk associated with the percentage of the total that is due by the patient versus the insurance payer.">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </div>
                </GridItem>
              </Grid>
              <Grid test-id="pfr-concentration-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" underline background>
                  {riskStatusChip(selectedFacilityData.pfrRiskSettings.risk)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline background>
                      {riskStatusChip(bestFacilityData.pfrRiskSettings.risk)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline background>
                      {riskStatusChip(secondBestFacilityData.pfrRiskSettings.risk)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="pfr-concentration-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline background>
                    {riskStatusChip(selectedFacilityDataHre.pfrRiskSettings.risk)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start">
                  Estimated Adjusted Charges
                </GridItem>
              </Grid>
              <Grid test-id="adjusted-charges-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center">
                  {formatNumberToUSD(selectedFacilityData.estAdjChgs)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(bestFacilityData.estAdjChgs)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(secondBestFacilityData.estAdjChgs)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="adjusted-charges-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {formatNumberToUSD(selectedFacilityDataHre.estAdjChgs)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" background>
                  Estimated Insurance Value ("EIV")
                </GridItem>
              </Grid>
              <Grid test-id="eiv-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" background>
                  {formatNumberToUSD(selectedFacilityData.estInsVal)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(bestFacilityData.estInsVal)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(secondBestFacilityData.estInsVal)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="eiv-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" background>
                    {formatNumberToUSD(selectedFacilityDataHre.estInsVal)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              {deficiencySettingEiv && hasEivdeficiencies &&
                <>
                  <Grid item xs={firstColumnWidth}>
                    <GridItem txtAlign="start">
                      EIV Deficiency
                    </GridItem>
                  </Grid>
                  <Grid test-id="eiv-deficiency-selected" item xs={facilityColumnWidth}>
                    <GridItem
                      txtAlign="center"
                      txtColor={selectedFacilityData.eivDeficiency > 0 ? "red" : ""}
                      underline={false}
                    >
                      {formatNumberToUSD(Math.round(selectedFacilityData.eivDeficiency))}
                    </GridItem>
                  </Grid>
                  {
                    isCompareBest ? 
                    <>
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem
                          txtAlign="center"
                          txtColor={bestFacilityData.eivDeficiency > 0 ? "red" : ""}
                        >
                          {formatNumberToUSD(Math.round(bestFacilityData.eivDeficiency))}
                        </GridItem>
                      </Grid>
                      
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem
                          txtAlign="center"
                          txtColor={secondBestFacilityData.eivDeficiency > 0 ? "red" : ""}
                        >
                          {formatNumberToUSD(Math.round(secondBestFacilityData.eivDeficiency))}
                        </GridItem>
                      </Grid>
                    </>
                    :
                    <Grid item xs={facilityColumnWidth}>
                      <GridItem
                        test-id="eiv-deficiency-selected-hre"
                        txtAlign="center"
                        txtColor={selectedFacilityDataHre.eivDeficiency > 0 ? "red" : ""}
                      >
                        {formatNumberToUSD(Math.round(selectedFacilityDataHre.eivDeficiency))}
                      </GridItem>
                    </Grid>
                  }
                </>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start">
                  Patient Financial Responsibility
                </GridItem>
              </Grid>
              <Grid test-id="pfr-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center">
                  {formatNumberToUSD(selectedFacilityData.pfr)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(bestFacilityData.pfr)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(secondBestFacilityData.pfr)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="pfr-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {formatNumberToUSD(selectedFacilityDataHre.pfr)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" background> 
                  Net Patient Revenue Estimate (Paid EIV + Paid PFR)
                </GridItem>
              </Grid>
              <Grid test-id="net-patient-revenue-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" background> 
                  {formatNumberToUSD(Math.round(selectedFacilityData.netPatientRevenueEst))}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background> 
                      {formatNumberToUSD(Math.round(bestFacilityData.netPatientRevenueEst))}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background> 
                      {formatNumberToUSD(Math.round(secondBestFacilityData.netPatientRevenueEst))}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="net-patient-revenue-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" background>
                    {formatNumberToUSD(Math.round(selectedFacilityDataHre.netPatientRevenueEst))}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              {!deficiencySettingEiv && hasNprdeficiencies && (
                <>
                  <Grid item xs={firstColumnWidth}>
                    <GridItem txtAlign="start">
                      Net Patient Revenue Deficiency
                    </GridItem>
                  </Grid>
                  <Grid test-id="net-patient-revenue-deficiency-selected" item xs={facilityColumnWidth}>
                    <GridItem
                      txtAlign="center"
                      txtColor={
                        selectedFacilityData.netPatientRevenueDeficiency > 0 ? "red" : ""
                      }
                    >
                      {formatNumberToUSD(Math.round(selectedFacilityData.netPatientRevenueDeficiency))}
                    </GridItem>
                  </Grid>
                  {
                    isCompareBest ? 
                    <>
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem
                          txtAlign="center"
                          txtColor={
                            bestFacilityData.netPatientRevenueDeficiency > 0 ? "red" : ""
                          }
                        >
                          {formatNumberToUSD(Math.round(bestFacilityData.netPatientRevenueDeficiency))}
                        </GridItem>
                      </Grid> 
                      <Grid item xs={facilityColumnWidth}>
                        <GridItem
                          txtAlign="center"
                          txtColor={
                            secondBestFacilityData.netPatientRevenueDeficiency > 0 ? "red" : ""
                          }
                        >
                          {formatNumberToUSD(Math.round(secondBestFacilityData.netPatientRevenueDeficiency))}
                        </GridItem>
                      </Grid>
                    </>
                    :
                    <Grid test-id="net-patient-revenue-deficiency-selected-hre" item xs={facilityColumnWidth}>
                      <GridItem
                        txtAlign="center"
                        txtColor={
                          selectedFacilityDataHre.netPatientRevenueDeficiency > 0 ? "red" : ""
                        }
                      >
                        {formatNumberToUSD(Math.round(selectedFacilityDataHre.netPatientRevenueDeficiency))}
                      </GridItem>
                    </Grid>
                  }
              </>
              )}


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start">
                  Patient Episode Margin
                </GridItem>
              </Grid>
              <Grid test-id="patient-episode-margin-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center">
                  {displayAsPct(selectedFacilityData.patientEpisodeMargin)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {displayAsPct(bestFacilityData.patientEpisodeMargin)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {displayAsPct(secondBestFacilityData.patientEpisodeMargin)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="patient-episode-margin-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {displayAsPct(selectedFacilityDataHre.patientEpisodeMargin)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" underline background>
                  <div>
                    <strong>Profitability Risk</strong>
                    <IconButton size="small" title="The financial risk associated with the admission based on operating and census costs.">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </div>
                </GridItem>
              </Grid>
              <Grid test-id="profitability-risk-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" underline background>
                  {riskStatusChip(selectedFacilityData.profitabilityRisk.risk)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline background>
                      {riskStatusChip(bestFacilityData.profitabilityRisk.risk)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline background>
                      {riskStatusChip(secondBestFacilityData.profitabilityRisk.risk)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="profitability-risk-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline background>
                    {riskStatusChip(selectedFacilityDataHre.profitabilityRisk.risk)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start">
                  Variable Cost Coverage %
                </GridItem>
              </Grid>
              <Grid test-id="variable-cost-percent-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center">
                  {Math.round(selectedFacilityData.variableCostCoveragePercent) + "%"}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {Math.round(bestFacilityData.variableCostCoveragePercent) + "%"}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {Math.round(secondBestFacilityData.variableCostCoveragePercent) + "%"}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="variable-cost-percent-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {Math.round(selectedFacilityDataHre.variableCostCoveragePercent) + "%"}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" background>
                  Variable Cost Coverage Amount
                </GridItem>
              </Grid>
              <Grid test-id="variable-cost-amount-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" background>
                  {formatNumberToUSD(selectedFacilityData.totalVariableCost)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(Math.round(bestFacilityData.totalVariableCost))}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(Math.round(secondBestFacilityData.totalVariableCost))}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="variable-cost-amount-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" background>
                    {formatNumberToUSD(selectedFacilityDataHre.totalVariableCost)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start">
                  Minimum Down Payment Required
                </GridItem>
              </Grid>
              <Grid test-id="minimum-down-payment-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center"> 
                  {formatNumberToUSD(selectedFacilityData.minimumDownPayment as number)}
                </GridItem>
              </Grid>
              {
                isCompareBest ? 
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(Math.round(bestFacilityData.minimumDownPayment as number))}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center">
                      {formatNumberToUSD(Math.round(secondBestFacilityData.minimumDownPayment as number))}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="minimum-down-payment-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center">
                    {formatNumberToUSD(selectedFacilityDataHre.minimumDownPayment as number)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" background>
                  Revenue Estimate from Referral Source Data
                </GridItem>
              </Grid>
              <Grid test-id="revenue-estimate-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" background>
                  {formatNumberToUSD(selectedFacilityData.referralSourceRisk.value)}
                </GridItem>
              </Grid>
              {
                isCompareBest ?
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(Math.round(bestFacilityData.referralSourceRisk.value))}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" background>
                      {formatNumberToUSD(Math.round(secondBestFacilityData.referralSourceRisk.value))}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="revenue-estimate-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" background>
                    {formatNumberToUSD(selectedFacilityDataHre.referralSourceRisk.value)}
                  </GridItem>
                </Grid>
              }


              {/* row */}
              <Grid item xs={firstColumnWidth}>
                <GridItem txtAlign="start" underline>
                  <div>
                    <strong>Referral Source Risk</strong>
                    <IconButton size="small" title="The risk associated with the referral source of the patient in terms of historical reimbursement numbers.">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </div>
                </GridItem>
              </Grid>
              <Grid test-id="referral-source-risk-selected" item xs={facilityColumnWidth}>
                <GridItem txtAlign="center" underline>
                  {riskStatusChip(selectedFacilityData.referralSourceRisk.risk)}
                </GridItem>
              </Grid>
              {
                isCompareBest ?
                <>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline>
                      {riskStatusChip(bestFacilityData.referralSourceRisk.risk)}
                    </GridItem>
                  </Grid>
                  <Grid item xs={facilityColumnWidth}>
                    <GridItem txtAlign="center" underline>
                      {riskStatusChip(secondBestFacilityData.referralSourceRisk.risk)}
                    </GridItem>
                  </Grid>
                </>
                :
                <Grid test-id="referral-source-risk-selected-hre" item xs={facilityColumnWidth}>
                  <GridItem txtAlign="center" underline>
                    {riskStatusChip(selectedFacilityDataHre.referralSourceRisk.risk)}
                  </GridItem>
                </Grid>
              }
              
            </Grid>
          </Box>
        )}
      </Paper>
      {overwriteEstimateModalOpen && (
        <RiskOverwriteEstimateModal
          open={overwriteEstimateModalOpen}
          currentRiskAssessment={oldFacilityiskAssessment}
          newRiskAssessment={newFacilityiskAssessment}
          handleOverwriteEstimateModalCancel={handleOverwriteModalCancel}
          handleOverwriteEstimateModalSubmit={handleOverwriteModalSubmit}
        />
      )}
    </>
  );
}
