import React from 'react';

function AdminView() {

  return (
    <main className="no-padding">
      <div className="content fullwidth">
          Admin View
      </div>
    </main>
  );
}

export default AdminView;