import React, {useCallback, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearStatus } from '../../../../admin-configuration/state/admin-configuration-slice';
import {
  showErrorStatus,
  showStatus,
} from '../../../../security/state/user-slice';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import {
  apiStatus,
  EditEstLevelOfCare,
  SaveEstimatorPostBody,
} from '../../../../admissions-advisor/models/estimator';
import { Utils } from "../../../../shared/utils";
import {
  getClientLevelsOfCare,
  saveFinpassLOC,
} from '../../../state/patient-thunk';
import {
  configGetLOC
} from '../../../../admin-configuration/state/admin-configuration-thunk';
import {
  clearClientLocStatus,
  clearConfigGetLOCStatus,
} from '../../../state/patient-slice';
import {
  LOCModalContents,
} from '@finpay-development/shared-components';
import _ from 'lodash';

interface FinpassLevelOfCareModalProps {
  open: boolean;
  clientId: number | undefined;
  facilityId: number | undefined;
  facilityName: string | undefined;
  quoteMethod: string | undefined;
  handleFinpassLevelOfCareModalClose: () => void;
}

const compareFunction = (prevValue: any, nextValue: any) =>
    _.isEqual(prevValue, nextValue);

export function FinpassLevelOfCareModal({
                                          open,
                                          clientId,
                                          facilityId,
                                          facilityName,
                                          quoteMethod,
                                          handleFinpassLevelOfCareModalClose,
                                        }: FinpassLevelOfCareModalProps) {

  const state = {
    estimatorState: useSelector(
        (state: RootState) =>
            state.patientContext.selectedEstimate.estimator
    ),
    saveStatus: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.modalSaveStatus
    ),
    errorMessage: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.errorMessage
    ),
    vob: useSelector(
        (state: RootState) => state.patientContext.selectedVOB
    ),
    patient: useSelector(
        (state: RootState) => state.patientContext.selectedPatient
    ),
    masterLevelsOfCareState: useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext?.levelsOfCare,
        compareFunction
    ),
    isLoadingMasterLoc: useSelector(
        (state: RootState) =>
            state.patientContext.selectedEstimate?.isLoading
                .isLoadingMasterLoc
    ),
    getClientLOCStatus: useSelector(
        (state: RootState) =>
            state.patientContext.selectedEstimate?.isLoading?.getClientLOCStatus
    ),
    isLoadingClientLoc: useSelector(
        (state: RootState) =>
            state.patientContext.selectedEstimate?.isLoading
                .isLoadingClientLoc
    ),
    configGetLOCStatus: useSelector(
        (state: RootState) =>
            state.patientContext?.selectedEstimate?.isLoading
                .configGetLOCStatus
    ),
    clientLevelsOfCareState: useSelector(
        (state: RootState) =>
            state.patientContext.selectedEstimate?.clientLevelsOfCare,
        compareFunction
    ),
    selectedPatientEncounter: useSelector(
        (state: RootState) => state.patientContext.selectedEncounter,
        compareFunction
    ),
  };
  const { estimatorState, saveStatus, errorMessage, vob, patient, masterLevelsOfCareState, isLoadingMasterLoc, getClientLOCStatus, isLoadingClientLoc, configGetLOCStatus, clientLevelsOfCareState, selectedPatientEncounter} = state;

  const dispatch = useDispatch<AppDispatch>();
  const paramId = -2;
  const doesLocExist = Array.isArray(estimatorState?.selectedLevelsOfCare) && estimatorState?.selectedLevelsOfCare.length > 0 && !!estimatorState?.selectedLevelsOfCareFacilityType;

  function handleSave(selectedLocs: EditEstLevelOfCare[], selectedLocTypeFinal: string){
    return async function(){
      let estimatorStateCopy = Utils.deepClone(estimatorState);
      estimatorStateCopy.selectedLevelsOfCareFacilityType = selectedLocTypeFinal;
      estimatorStateCopy.selectedLevelsOfCare = selectedLocs;
      let estimatePayLoad: SaveEstimatorPostBody = {
        paramId: -2,
        estimateId: estimatorStateCopy.pfrEstimateId,
        vobId: vob.vobId!,
        advisorPatientId: patient.advisorPatientId!,
        fpClientId: estimatorStateCopy.client?.clientId,
        fpClientFacilityId: estimatorStateCopy.facility?.facilityId,
        estimateBody: estimatorStateCopy,
      };
      await dispatch(saveFinpassLOC(estimatePayLoad));
      handleLocModalCallback(true);
      dispatch(showStatus(doesLocExist ? 'LOC Updated' : 'LOC Created'));
    }
  }

  function handleLocModalCallback(saveSuccessful: boolean | undefined){
    if (saveSuccessful || typeof saveSuccessful === 'undefined') {
      handleFinpassLevelOfCareModalClose();
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  const getLevelsOfCareByClient = useCallback(() => {
    if (masterLevelsOfCareState?.length > 0) {
      const config: any = {
        paramId: paramId,
        clientId: clientId,
        payerPlanId: vob?.plan?.payorPlanId && selectedPatientEncounter.payorRisk?.payorRiskId !== 3 ? vob?.plan?.payorPlanId : undefined,
        filterByPayerPlanId: !!(vob?.plan?.payorPlanId && selectedPatientEncounter.payorRisk?.payorRiskId !== 3),
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      dispatch(getClientLevelsOfCare(config));
    }
  }, [clientId, dispatch, masterLevelsOfCareState, paramId, selectedPatientEncounter.payorRisk?.payorRiskId, vob?.plan?.payorPlanId]);

  useEffect(() => {

    dispatch(configGetLOC(paramId));
    if (estimatorState?.selectedLevelsOfCareFacilityType === 'multi') getLevelsOfCareByClient();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState?.selectedLevelsOfCareFacilityType]);

  useEffect(() => {

    if (!isLoadingMasterLoc && configGetLOCStatus === apiStatus.success) {
      // config Levels of Care has loaded
      dispatch(clearConfigGetLOCStatus());
      getLevelsOfCareByClient();
    }
    if (!isLoadingClientLoc && getClientLOCStatus === apiStatus.success) {
      // client Levels of Care has loaded
      dispatch(clearClientLocStatus());

    }

  }, [isLoadingMasterLoc, isLoadingClientLoc, configGetLOCStatus, getClientLOCStatus, dispatch, getLevelsOfCareByClient, estimatorState?.selectedLevelsOfCareFacilityType, clientLevelsOfCareState, facilityId]);

  return (
      <>
        {(clientLevelsOfCareState.length) > 0 && (
            <LOCModalContents
              open={open}
              loc={{
                selectedLocType: estimatorState?.selectedLevelsOfCareFacilityType,
                preSelectedLocs: Array.isArray(estimatorState?.selectedLevelsOfCare) ? estimatorState?.selectedLevelsOfCare : [],
                facilityLocList: Array.isArray(clientLevelsOfCareState) ? clientLevelsOfCareState : [],
              }}
              quoteMethod={quoteMethod}
              vob={{
                inNetwork: vob.payer.inNetwork!,
                selfPay: vob.selfPay!
              }}
              selectedFacility={{
                facilityId: facilityId!,
                facilityName: facilityName
              }}
              saveStatus={saveStatus}
              handleSave={handleSave}
              handleLocModalCallback={handleLocModalCallback}
              columnsToShow={['code','name','facility','covered']}
        />)}
      </>
  );
}
