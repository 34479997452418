import '../../../../scss/components/_list-header.scss';
import '../../../../scss/pages/admin/_admin-clients.scss';

import {
  Button,
  listItemData,
  LoadingOverlay,
  RadioListItemGroup,
  TextField,
  Toggle,
} from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  calculateDownByPercent,
  calculateDownPmt,
  calculateTerm,
  calculateTermPmt,
  formatNumberToUSD,
} from '../../../../shared/calculators';
import { PfrListHeader } from '../../../../shared/components/pfr-list-header';
import { RootState } from '../../../../shared/state/root-reducer';
import { customView } from '../../../../shared/validation/schemas';
import { PfrRecurringType } from '../../misc/pfr-recurring-type';
import { PfrRowOutlineColor } from '../../misc/pfr-row-outline-color';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PatientPaymentSchedule } from '../../models/patient-payment.schedule';
import { PaymentProgramTabs } from '../../models/payment-program-tabs';
import { PfrDownPaymentAmt } from 'src/shared/components/pfr-downpayment';

interface CustomPFRViewProps {
  enableSaveButtonCallback: (enableSave: boolean) => void,
  handleSaveCustom: (paymentProgram: PatientPaymentProgram) => void,
  customSaveClicked: boolean,
  isEdit: boolean,
  originalTabIndex: number
  isRuleLoading: boolean,
}

export function CustomPFRView(props: CustomPFRViewProps) {
  const { enableSaveButtonCallback, handleSaveCustom, customSaveClicked, isEdit, originalTabIndex, isRuleLoading } = props;

  const [showPaymentPlanInfo, setShowPaymentPlanInfo] = useState(false);
  const [listData, setListData] = useState<listItemData[]>([]);
  const [isHighRisk, setIsHighRisk] = useState(false);
  const [isCalculateEnabled, setIsCalculateEnabled] = useState(false);
  const [hasTerms, setHasTerms] = useState(false);

  const formRef: any = useRef();

  const stateFields = {
    selectedRule: useSelector((state: RootState) => {
      return state.patientContext.matchingRule
    }),
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    })
  }

  const { selectedEncounter, selectedRule, patientPaymentProgram } = stateFields;

  const handleCalculate = () => {
    enableSaveButtonCallback(false);
    buildData();
    setShowPaymentPlanInfo(true);
  }

  const mapToPatientPaymentProgram = useCallback(() => {
    const pfrAmt = selectedEncounter.pfrAmt;
    const pfrAmtBalance = selectedEncounter.patientPaymentProgram[0]?.patientPaymentSchedule.pfrBalance > 0 ? selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrBalance : pfrAmt;
    const currentDate = new Date();
    const formValues = formRef?.current?.values;
    const formValuesObj = JSON.parse(formValues.customTerm);

    const dollarOrCommaRegex = /[$,]/g;
    const downPaymentAmt = parseFloat(formValuesObj.secondaryLabel.replace(dollarOrCommaRegex, ""));
    const terms = parseInt(formValuesObj.label);
    const recurringPaymentAmt = parseFloat(formValuesObj.tertiaryLabel.replace(dollarOrCommaRegex, ""));
    const paymentFreq = formValues.recurring === true ? PfrRecurringType.monthly : PfrRecurringType.weekly;

    const patientPaymentSchedule = {
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId ?? undefined,
      pfrAmt: pfrAmt,
      pfrBalance: pfrAmtBalance,
      paymentFreq: paymentFreq,
      downPmtAmt: downPaymentAmt,
      terms: terms,
      remainingTerms: terms,
      paymentDueAmt: recurringPaymentAmt,
      scheduleStatus: 'Pending',
      scheduleStartDt: isEdit ? patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt || currentDate.toISOString() : currentDate.toISOString(),
    } as PatientPaymentSchedule

    const paymentProgram = {
      patientPaymentProgramId: formRef?.current?.values.patientPaymentProgramId,
      isHighRisk: isHighRisk,
      isPaidInFull: false, // TODO: DEPRECATED - use patientPaymentSchedule?.paymentFreq === 'F' for full pay plans
      downPmtAmt: downPaymentAmt,
      isACH: false,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram
    return paymentProgram;
  }, [selectedEncounter.pfrAmt, isHighRisk,
      patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId,
      patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt, isEdit
    ])

  useEffect(() => {
    if (customSaveClicked) {
      const paymentProgram = mapToPatientPaymentProgram();
      handleSaveCustom(paymentProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSaveClicked, mapToPatientPaymentProgram])

  useEffect(() => {
    if (formRef.current?.values) {
      formRef.current.values.customTerm = ""; // clears radio button choice, so that form is invalid and Save button will disable until a choice is made. Because is possible radio choice has a value from the first tab
    }
    enableSaveButtonCallback(false);
    if (isEdit && (originalTabIndex === PaymentProgramTabs.Custom) && formRef?.current?.values) {
      handleCalculate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // will only work if array is blank. This should run ONLY a single time on component load

  const getInitialValues = () => {

    if (isEdit) {
      let patientPaymentProgram = [...stateFields.selectedEncounter.patientPaymentProgram]?.pop(); // returns last in array
      return {
        patientPaymentProgramId: patientPaymentProgram?.patientPaymentProgramId,
        recurring: patientPaymentProgram?.patientPaymentSchedule.paymentFreq === PfrRecurringType.monthly,
        downPayment: originalTabIndex === PaymentProgramTabs.Custom ? patientPaymentProgram!.downPmtAmt : "", // if coming from a different tab, then clear out down payment field
        recurringPayment: originalTabIndex === PaymentProgramTabs.Custom ? patientPaymentProgram!.patientPaymentSchedule?.paymentDueAmt : "", // if coming from a different tab, then clear out recurring payment field
        customTerm: JSON.stringify({
          "value": patientPaymentProgram!.patientPaymentSchedule?.terms?.toString(),
          "label": `${patientPaymentProgram!.patientPaymentSchedule?.terms}` || "",
          "secondaryLabel": `$${patientPaymentProgram!.downPmtAmt}` || "",
          "tertiaryLabel": `$${patientPaymentProgram!.patientPaymentSchedule?.paymentDueAmt}` || "",
          "color": patientPaymentProgram!.isHighRisk ? PfrRowOutlineColor.error : undefined,
        }),
      }
    } else {
      return {
        patientPaymentProgramId: 0,
        recurring: true,
        downPayment: "",
        recurringPayment: "",
        customTerm: ""
      }
    }
  }

  const validationSchema = Yup.object(customView);

  function checkIfIsValid(value: any) {
    const downPaymentRecurringPaymentCheck = ((!!value.downPayment || value.downPayment === 0) && (value.recurringPayment === 0 || !!value.recurringPayment));
    formRef.current.values.customTerm = "";

    // calculate button should enable if both 'down payment' and 'recurring payment' are filled out with some number (including zero).
    if (downPaymentRecurringPaymentCheck) {
      setIsCalculateEnabled(true);
    } else {
      setIsCalculateEnabled(false);
    }

    const pfr = selectedEncounter.pfrAmt;
    if (value.downPayment < pfr && value.recurringPayment < pfr && value.downPayment + value.recurringPayment <= pfr && (downPaymentRecurringPaymentCheck)) {
      setIsCalculateEnabled(true);
    } else {
      setIsCalculateEnabled(false);
      if (hasTerms) {
        setHasTerms(false);
        setShowPaymentPlanInfo(false);
      }
    }

    validationSchema
    .validate(value)
    .then(() => {
      if (value.downPayment < pfr && value.recurringPayment < pfr && value.downPayment + value.recurringPayment <= pfr && formRef.current.values.customTerm !== "") {
        enableSaveButtonCallback(true);
      }
    })
    .catch((err) => {
      enableSaveButtonCallback(false);
    });

  }

  const header = (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Patient Financial Responsibility
          </Typography>
        </Grid>
        <Box justifyContent="flex-end" className="pr-1 mt-2">
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {formatNumberToUSD(selectedEncounter.pfrAmt)}
            </Typography>
          </Grid>
        </Box>
        <PfrDownPaymentAmt selectedEncounter={selectedEncounter} labelPadding={2} amountMargin={2} amountPadding={0} />
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Custom Payments
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Enter down payment and/or recurring payment to see options.
          </Typography>
        </Grid>
      </Grid>
    </>
  )

  const buildData = () => {
    const downPayment = formRef.current.values?.downPayment;
    const recurringPayment = formRef.current.values?.recurringPayment;

    if (selectedRule.ruleId !== 0) {
      let availableTerms: number[];

      if (selectedRule.ruleId > -1) {
        availableTerms = selectedRule?.availableTerms?.length > 0 ? selectedRule.availableTerms.replace(" ", "").split(",").map((termAsString) => +termAsString) : [];
      } else {
        availableTerms = [];
      }

      const largestTerm = Math.max(...availableTerms);

      const isSolveForDownPayment = downPayment === "" && recurringPayment !== "";
      const isSolveForRecurringPayment = downPayment !== "" && recurringPayment === "";
      const isSolveForTerms = downPayment !== "" && recurringPayment !== "";

      if (isSolveForDownPayment) setListData(solveForDownPayment(availableTerms, recurringPayment));
      if (isSolveForRecurringPayment) setListData(solveForRecurringPayment(availableTerms, downPayment));
      if (isSolveForTerms) setListData(solveForTerms(recurringPayment, downPayment, largestTerm, selectedRule.minDownPmt));
    } else {
      setListData(solveForTerms(recurringPayment, downPayment));
    }
  }

  const solveForDownPayment = (availableTerms: number[], recurringPayment: number) => {
    const result = availableTerms.map((term, index) => {
      const calculatedDownPayment = calculateDownPmt(selectedEncounter.pfrAmt, recurringPayment, +term);

        return {
          id: index,
          label: term.toString(), // terms column
          secondaryLabel: `${formatNumberToUSD(calculatedDownPayment)}`, // down payment column
          tertiaryLabel: `${formatNumberToUSD(recurringPayment)}`, // recurring payment column
          value: term.toString()
        }
    })
    const onlyPositivePayments = result.filter((row) => {
      return !row.secondaryLabel.includes("("); // a parentheses char in the number string means it is negative. Filter out term rows that have a neg value
    })

    if (onlyPositivePayments.length === 0) {
      setHasTerms(false);
    } else {
      setHasTerms(true);
    }

    return onlyPositivePayments;
  }

  const solveForRecurringPayment = (availableTerms: number[], downPayment: number) => {
    const result = availableTerms.map((term, index) => {
      const calculatedTermPayment = calculateTermPmt(selectedEncounter.pfrAmt, downPayment, +term);
      return {
        id: index,
        label: term.toString(), // terms column
        secondaryLabel: `${formatNumberToUSD(downPayment)}`, // down payment column
        tertiaryLabel: `${formatNumberToUSD(calculatedTermPayment)}`, // recurring payment column
        value: term.toString()
      }
    })
    if (result.length === 0) {
      setHasTerms(false);
    } else {
      setHasTerms(true);
    }
    return result;
  }

  const solveForTerms = (recurringPayment: number, downPayment: number, largestTerm?: number, minDownPayment?: number) => {
      const calculatedTerm = calculateTerm(selectedEncounter.pfrAmt, downPayment, recurringPayment);
      let isHighRisk;
      let calcDownPayment = minDownPayment ?? 0

      if (largestTerm && minDownPayment) {
        if (selectedRule.minDownPmtType === '%') {
          calcDownPayment = calculateDownByPercent(minDownPayment, selectedEncounter.pfrAmt)/100;
        }
        isHighRisk = (calculatedTerm >= largestTerm) || (downPayment < calcDownPayment);
      } else {
        isHighRisk = true;
      }

      setIsHighRisk(isHighRisk);
      setHasTerms(true); // will always be a single custom term
      return [
        {
          id: 0,
          label: Math.ceil(calculatedTerm).toString(), // terms column
          secondaryLabel: `${formatNumberToUSD(downPayment)}`, // down payment column
          tertiaryLabel: `${formatNumberToUSD(recurringPayment)}`, // recurring payment column
          value: calculatedTerm.toString(),
          selectColor: isHighRisk ? PfrRowOutlineColor.error : undefined
      }
    ]
  }

  return (
    <div className="px-2 py-2 w-100">
      {isRuleLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingOverlay />
        </Box>
      ) : (
        <>
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues()}
            validationSchema={validationSchema}
            validate={checkIfIsValid}
            onSubmit={() => {}}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2} className="list-header">
                  <Grid item xs={12}>
                    {header}
                  </Grid>
                  <Grid xs={12} item style={{ marginBottom: -12 }}>
                    <Typography variant="h4">
                      Recurring Type
                    </Typography>
                  </Grid>
                  <Grid xs={12} item className="mb-4">
                    <Toggle
                      leftText="Monthly"
                      rightText="Weekly"
                      name="recurring"
                      value={formik.values.recurring}
                      formik={formik}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextField
                      error={
                        formik.touched["downPayment"] && formik.errors["downPayment"]
                      }
                      type="number"
                      startAdornment={<AttachMoneyIcon />}
                      label="Down Payment"
                      name="downPayment"
                      minLength={3}
                      placeholder="Enter Down Payment"
                      value={formik.values.downPayment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <TextField
                      error={
                        formik.touched["recurringPayment"] && formik.errors["recurringPayment"]
                      }
                      type="number"
                      startAdornment={<AttachMoneyIcon />}
                      label="Recurring Payment"
                      name="recurringPayment"
                      placeholder="Enter Recurring Payment"
                      minLength={3}
                      value={formik.values.recurringPayment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        disabled={!isCalculateEnabled}
                        onClick={() => handleCalculate()}
                        type="secondary"
                      >
                        Calculate
                      </Button>
                    </Box>
                  </Grid>
                  {(showPaymentPlanInfo) && (
                    <>
                      <Grid xs={12} item>
                        <Typography variant="subtitle2">
                          {isHighRisk ? "High Risk" : "Select Terms"}
                        </Typography>
                      </Grid>
                      {hasTerms ? (
                        <Grid xs={12} item>
                        {<PfrListHeader />}
                        <RadioListItemGroup
                          data={listData}
                          name="customTerm"
                          direction="column"
                          formik={formik}
                        />
                      </Grid>
                      ) : (
                        <Typography variant="body1">No Terms Available</Typography>
                      )}
                    </>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
}
