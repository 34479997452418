
import React from 'react';
import { Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "../../scss/components/_icons.scss";
interface headerWithDetailsProps {
  header: string,
  details?: string | number | any,
  showIcon?: boolean,
  iconStatus?: string,
  hideIfUndefined? : boolean,
  showNAIfUndefined?: boolean,
  width: "full" | "half" | "third",
  testId?:string
}

const HeaderWithDetails = (props: headerWithDetailsProps) => {
  const { header, details, width, showIcon, iconStatus, hideIfUndefined, testId } = props;
  let { showNAIfUndefined = true } = props;
  if(details === 0) showNAIfUndefined = false


  const getClassNamesForIcon = () => {
    const classNames = "inline-icon mr-1"
    if (iconStatus === 'new') {
      return classNames + " icon"
    } else if (iconStatus === 'cleared') {
      return classNames + " green-icon"
    } else if (iconStatus === 'canceled') {
     return classNames + ' red-icon'
    }
  }

  return (
    <>
      {(details || !hideIfUndefined) &&
      <Grid item xs={12} className="pb-4 word-wrap" sm={width === "full" ? 12 : width === "half" ? 6 : 4}>
        <Grid item xs={12}>
          <Typography variant="body2">
            {header}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {showIcon && <FiberManualRecordIcon className={getClassNamesForIcon()} fontSize="small" />}
          <Typography variant="h3" test-id = {testId?testId:''}>
            {(showNAIfUndefined && (!details)) ? "N/A" : details}
          </Typography>
        </Grid>
      </Grid>
      }

    </>
  )
}

export default HeaderWithDetails;
