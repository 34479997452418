import { UserRole } from "../model/user-role";
import { UserRolePage } from "../model/user-role-page";

export const roleNavigations = [
  {
    roleName: "Account Holder",
    subNav: [
      {
        key: 1,
        displayValue: "Active Programs",
        route: "/patient/dashboard/active",
      },
      {
        key: 2,
        displayValue: "Completed Programs",
        route: "/patient/dashboard/completed",
      },
      {
        key: 3,
        displayValue: "Canceled Programs",
        route: "/patient/dashboard/canceled",
      },
    ],
  },
  {
    roleName: "Client Owner",
    subNav: [
      {
        key: 1,
        displayValue: "Dashboard",
        route: "/client/dashboard",
      },
      {
        key: 2,
        displayValue: "Facilities",
        route: "/client/facilities",
      },
      {
        key: 3,
        displayValue: "Reports",
        route: "/client/reports",
      },
    ],
    userRolePages: [
      {
        isEnabled: true,
        isReadOnly: false,
        sysRolePage: {
          rolePageName: "Client Portal",
          rolePageType: "Navigate",
          sortOrder: 7,
          sysRolePageAction: null,
          sysRolePageId: 7,
        },
        userRolePageActions: [
          {
            isEnabled: true,
            sysRolePageAction: {
              actionName: "Complete Facility Set Up",
              actionKey: "",
              sortOrder: 1,
              sysRolePageActionId: 1,
            },
            userPageActionId:1
          },
          {
            isEnabled: true,
            sysRolePageAction: {
              actionName: "Complete Setup",
              actionKey: "",
              sortOrder: 2,
              sysRolePageActionId: 2,
            },
            userPageActionId:2
          },
        ],
      },
    ],
  },
  {
    roleName: "Client Analyst",
    subNav: [
      {
        key: 1,
        displayValue: "Dashboard",
        route: "/client/dashboard",
      },
      {
        key: 2,
        displayValue: "Facilities",
        route: "/client/facilities",
      },
      {
        key: 3,
        displayValue: "Reports",
        route: "/client/reports",
      },
    ]
  },
];

export const getStaticRoleNavigation = (roleName: string): any => {
  const roleSubNav = roleNavigations.find((roleNavigation) => {
    return roleNavigation.roleName === roleName;
  })?.subNav;

  return roleSubNav;

};

export const setStaticRolePageAction = (userRole: UserRole): any => {
  const userRolePages = roleNavigations.find((roleNavigation) => {
    return roleNavigation.roleName === userRole.roleName;
  })?.userRolePages;

  userRole.userRolePages.forEach((userRolePage: UserRolePage) => {

    const userRolePageActions = userRolePages?.find((roleNavigation) => {
      return (roleNavigation.sysRolePage.rolePageName === userRolePage.sysRolePage.rolePageName);
    })?.userRolePageActions;

    if(userRolePageActions){
      userRolePage.userRolePageActions = userRolePageActions;
    }
  });
};