import { FilteredUserInfo } from "./filtered-user-info";
import { UserInfo } from "./user-info";
import { UserInfoClient } from "./user-info-client";
import { UserInfoFacility } from "./user-info-facility";
import { UserInfoRole } from "./user-info-role";

export class editableUser {
  userId!: number;
  userName!: string;
  firstName!: string;
  lastName!: string;
}
export class AdminUserState {
  allUsers!: UserInfo[];
  filteredUsers!: FilteredUserInfo[];
  clientsFilter!: UserInfoClient[];
  rolesFilter!: UserInfoRole[];
  facilitiesFilter!: UserInfoFacility[];
}

export const emptyUser: editableUser = {
  userId: 0,
  userName: "",
  firstName: "",
  lastName: "",
};
