import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiskAssessmentForm, FacilityRiskThreshold, MultiFacilityRiskThreshold, PayerClaimRate, ClientRiskClassSettings, FacilityRiskClassSettings, MultiFacilityRiskClassSetting, PatientEpisodeMarginSettings, emptyPatientEpisodeMarginSettings, ReferralSourceRevenue, FacilityRiskAssessment } from "../models/risk-assessment";
import { SelectedFacilityReferralSource } from "../../implementation-specialist/models/referral-source";
import { getFacilityRiskThresholds, getMultiFacilityRiskThresholds, getClientClaimRates, getClientRiskClassSettings, getFacilityRiskClassSettings, getMultiFacilityiskClassSettings, getPatientEpisodeMarginSettings, getMultiFacilityReferralSources, getMultiFacilityReferralSourceRevenue } from './risk-assessment-thunk';
import { saveCallbackStatus, } from "@finpay-development/shared-components";

interface riskAssessmentSliceState {
	riskAssessmentForm: RiskAssessmentForm,
	facilityRiskThresholds: FacilityRiskThreshold[],
	multiFacilityRiskThresholds: MultiFacilityRiskThreshold[],
	clientPayerClaimRates: PayerClaimRate[],
	clientRiskClassSettings: ClientRiskClassSettings[],
	facilityRiskClassSettings: FacilityRiskClassSettings[],
	multiFacilityRiskClassSettings: MultiFacilityRiskClassSetting[],
	multiFacilityReferralSources: SelectedFacilityReferralSource[],
	multiFacilityReferralSourceRevenue: ReferralSourceRevenue[],
	patientEpisodeMarginSettings: PatientEpisodeMarginSettings
	isLoading: {
		isLoadingFacilityRiskThresholds: boolean;
		facilityRiskThresholdsStatus: saveCallbackStatus,
		isLoadingClientClaimRates: boolean;
		clientClaimRatesStatus: saveCallbackStatus,
		isLoadingClientRiskClassSettings: boolean,
		clientRiskClassSettingsStatus: saveCallbackStatus,
		isLoadingFacilityRiskClassSettings: boolean,
		facilityRiskClassSettingsStatus: saveCallbackStatus,
		isLoadingMultiFacilityRiskClassSettings: boolean,
		multiFacilityRiskClassSettingsStatus: saveCallbackStatus,
		isLoadingMultiFacilityReferralSources: boolean,
		multiFacilityReferralSourcesStatus: saveCallbackStatus,
		isLoadingtMultiFacilityReferralSourceRevenue: boolean,
		multiFacilityReferralSourceRevenueStatus: saveCallbackStatus,
		isLoadingPatientEpisodeMarginSettings: boolean,
		patientEpisodeMarginSettingsStatus: saveCallbackStatus,
	},
	selectedFacilityRiskData: FacilityRiskAssessment | null
}

const initialState = {
	riskAssessmentForm: {
		isValid: false,
		facility: {
			facilityId: undefined,
			facilityName: "",
		},
		facilityIds: [],
		facilityCensus: undefined,
		payorRisk: {
			payorRiskId: undefined,
			payorRiskName: "",
		},
		timingRisk: {
			timingRiskId: undefined,
			timingRiskName: "",
		},
		riskClass: {
			riskClassId: undefined,
			riskClassName: "",
		},
		referralSource: {
			facilityReferralSourceId: undefined,
			facilityReferralSourceName: "",
		},		
		compareBest: false,
		showCleared: true,
	},
	facilityRiskThresholds: [],
	multiFacilityRiskThresholds: [],
	clientPayerClaimRates: [],
	clientRiskClassSettings: [],
	facilityRiskClassSettings: [],
	multiFacilityRiskClassSettings: [],
	multiFacilityReferralSources: [],
	multiFacilityReferralSourceRevenue: [],
	patientEpisodeMarginSettings: emptyPatientEpisodeMarginSettings,
	isLoading: {
		isLoadingFacilityRiskThresholds: false,
		facilityRiskThresholdsStatus: saveCallbackStatus.none,
		isLoadingClientClaimRates: false,
		clientClaimRatesStatus: saveCallbackStatus.none,
		isLoadingClientRiskClassSettings: false,
		clientRiskClassSettingsStatus: saveCallbackStatus.none,
		isLoadingFacilityRiskClassSettings: false,
		facilityRiskClassSettingsStatus: saveCallbackStatus.none,
		isLoadingMultiFacilityRiskClassSettings: false,
		multiFacilityRiskClassSettingsStatus: saveCallbackStatus.none,
		isLoadingMultiFacilityReferralSources: false,
		multiFacilityReferralSourcesStatus: saveCallbackStatus.none,
		isLoadingtMultiFacilityReferralSourceRevenue: false,
		multiFacilityReferralSourceRevenueStatus: saveCallbackStatus.none,
		isLoadingPatientEpisodeMarginSettings: false,
		patientEpisodeMarginSettingsStatus: saveCallbackStatus.none,
	},
	selectedFacilityRiskData: null
} as riskAssessmentSliceState;

export const riskAssessmentSlice = createSlice({
	name: "riskAssessmentContext",
	initialState,
	reducers: {
		setRiskAssessmentForm: (state, action) => {
			state.riskAssessmentForm = action.payload;
		},
		setSelectedFacilityRiskData: (state, action) => {
			state.selectedFacilityRiskData = action.payload
		},
		clearRiskAssessment: (state) => {
			state.riskAssessmentForm = initialState.riskAssessmentForm;
		},
		clearFacilityRiskThresholdsStatus: (state) => {
			state.isLoading.facilityRiskThresholdsStatus = initialState.isLoading.facilityRiskThresholdsStatus;
		},
		clearClaimRatesStatus: (state) => {
			state.isLoading.clientClaimRatesStatus = initialState.isLoading.clientClaimRatesStatus;
		},
		clearClientRiskClassSettingsStatus: (state) => {
			state.isLoading.clientRiskClassSettingsStatus = initialState.isLoading.clientRiskClassSettingsStatus;
		},
		clearFacilityRiskClassSettingsStatus: (state) => {
			state.isLoading.facilityRiskClassSettingsStatus = initialState.isLoading.facilityRiskClassSettingsStatus;
		},
		clearMultiFacilityRiskClassSettingsStatus: (state) => {
			state.isLoading.multiFacilityRiskClassSettingsStatus = initialState.isLoading.multiFacilityRiskClassSettingsStatus;
		},
		clearMultiFacilityReferralSourceRevenueStatus: (state) => {
			state.isLoading.multiFacilityReferralSourceRevenueStatus = initialState.isLoading.multiFacilityReferralSourceRevenueStatus;
		},
		clearPatientEpisodeMarginSettingsStatus: (state) => {
			state.isLoading.patientEpisodeMarginSettingsStatus = initialState.isLoading.patientEpisodeMarginSettingsStatus;
		},
		clearMultiFacilityReferralSourcesStatus: (state) => {
			state.isLoading.multiFacilityReferralSourcesStatus = initialState.isLoading.multiFacilityReferralSourcesStatus;
		},
	},
  extraReducers: (builder) => {
    builder.addCase(getFacilityRiskThresholds.pending, (state, action) => {
      state.isLoading.isLoadingFacilityRiskThresholds = true;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.none;
    });
    builder.addCase(getFacilityRiskThresholds.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingFacilityRiskThresholds = false;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.success;
			state.facilityRiskThresholds = action.payload;
    });
    builder.addCase(getFacilityRiskThresholds.rejected, (state, action) => {
      state.isLoading.isLoadingFacilityRiskThresholds = false;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.error;
    });

    builder.addCase(getMultiFacilityRiskThresholds.pending, (state, action) => {
      state.isLoading.isLoadingFacilityRiskThresholds = true;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.none;
    });
    builder.addCase(getMultiFacilityRiskThresholds.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingFacilityRiskThresholds = false;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.success;
			state.multiFacilityRiskThresholds = action.payload;
    });
    builder.addCase(getMultiFacilityRiskThresholds.rejected, (state, action) => {
      state.isLoading.isLoadingFacilityRiskThresholds = false;
			state.isLoading.facilityRiskThresholdsStatus = saveCallbackStatus.error;
    });

    builder.addCase(getClientClaimRates.pending, (state, action) => {
      state.isLoading.isLoadingClientClaimRates = true;
			state.isLoading.clientClaimRatesStatus = saveCallbackStatus.none;
    });
    builder.addCase(getClientClaimRates.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingClientClaimRates = false;
			state.isLoading.clientClaimRatesStatus = saveCallbackStatus.success;
			state.clientPayerClaimRates = action.payload;
    });
    builder.addCase(getClientClaimRates.rejected, (state, action) => {
      state.isLoading.isLoadingClientClaimRates = false;
			state.isLoading.clientClaimRatesStatus = saveCallbackStatus.error;
    });

    builder.addCase(getClientRiskClassSettings.pending, (state, action) => {
      state.isLoading.isLoadingClientRiskClassSettings = true;
			state.isLoading.clientRiskClassSettingsStatus = saveCallbackStatus.none;
    });
    builder.addCase(getClientRiskClassSettings.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingClientRiskClassSettings = false;
			state.isLoading.clientRiskClassSettingsStatus = saveCallbackStatus.success;
			state.clientRiskClassSettings = action.payload;
    });
    builder.addCase(getClientRiskClassSettings.rejected, (state, action) => {
      state.isLoading.isLoadingClientRiskClassSettings = false;
			state.isLoading.clientRiskClassSettingsStatus = saveCallbackStatus.error;
    });

    builder.addCase(getFacilityRiskClassSettings.pending, (state, action) => {
      state.isLoading.isLoadingFacilityRiskClassSettings = true;
			state.isLoading.facilityRiskClassSettingsStatus = saveCallbackStatus.none;
    });
    builder.addCase(getFacilityRiskClassSettings.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingFacilityRiskClassSettings = false;
			state.isLoading.facilityRiskClassSettingsStatus = saveCallbackStatus.success;
			state.facilityRiskClassSettings = action.payload;
    });
    builder.addCase(getFacilityRiskClassSettings.rejected, (state, action) => {
      state.isLoading.isLoadingFacilityRiskClassSettings = false;
			state.isLoading.facilityRiskClassSettingsStatus = saveCallbackStatus.error;
    });

    builder.addCase(getMultiFacilityiskClassSettings.pending, (state, action) => {
      state.isLoading.isLoadingMultiFacilityRiskClassSettings = true;
			state.isLoading.multiFacilityRiskClassSettingsStatus = saveCallbackStatus.none;
    });
    builder.addCase(getMultiFacilityiskClassSettings.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingMultiFacilityRiskClassSettings = false;
			state.isLoading.multiFacilityRiskClassSettingsStatus = saveCallbackStatus.success;
			state.multiFacilityRiskClassSettings = action.payload;
    });
    builder.addCase(getMultiFacilityiskClassSettings.rejected, (state, action) => {
      state.isLoading.isLoadingMultiFacilityRiskClassSettings = false;
			state.isLoading.multiFacilityRiskClassSettingsStatus = saveCallbackStatus.error;
    });

    builder.addCase(getMultiFacilityReferralSources.pending, (state, action) => {
      state.isLoading.isLoadingMultiFacilityReferralSources = true;
			state.isLoading.multiFacilityReferralSourcesStatus = saveCallbackStatus.none;
    });
    builder.addCase(getMultiFacilityReferralSources.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingMultiFacilityReferralSources = false;
			state.isLoading.multiFacilityReferralSourcesStatus = saveCallbackStatus.success;
			state.multiFacilityReferralSources = action.payload;
    });
    builder.addCase(getMultiFacilityReferralSources.rejected, (state, action) => {
      state.isLoading.isLoadingMultiFacilityReferralSources = false;
			state.isLoading.multiFacilityReferralSourcesStatus = saveCallbackStatus.error;
    });

    builder.addCase(getMultiFacilityReferralSourceRevenue.pending, (state, action) => {
      state.isLoading.isLoadingtMultiFacilityReferralSourceRevenue = true;
			state.isLoading.multiFacilityReferralSourceRevenueStatus = saveCallbackStatus.none;
    });
    builder.addCase(getMultiFacilityReferralSourceRevenue.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingtMultiFacilityReferralSourceRevenue = false;
			state.isLoading.multiFacilityReferralSourceRevenueStatus = saveCallbackStatus.success;
			state.multiFacilityReferralSourceRevenue = action.payload;
    });
    builder.addCase(getMultiFacilityReferralSourceRevenue.rejected, (state, action) => {
      state.isLoading.isLoadingtMultiFacilityReferralSourceRevenue = false;
			state.isLoading.multiFacilityReferralSourceRevenueStatus = saveCallbackStatus.error;
    });

    builder.addCase(getPatientEpisodeMarginSettings.pending, (state, action) => {
      state.isLoading.isLoadingPatientEpisodeMarginSettings = true;
			state.isLoading.multiFacilityReferralSourceRevenueStatus = saveCallbackStatus.none;
    });
    builder.addCase(getPatientEpisodeMarginSettings.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingPatientEpisodeMarginSettings = false;
			state.isLoading.patientEpisodeMarginSettingsStatus = saveCallbackStatus.success;
			state.patientEpisodeMarginSettings = action.payload;
    });
    builder.addCase(getPatientEpisodeMarginSettings.rejected, (state, action) => {
      state.isLoading.isLoadingPatientEpisodeMarginSettings = false;
			state.isLoading.patientEpisodeMarginSettingsStatus = saveCallbackStatus.error;
    });

  },
});

export const { setRiskAssessmentForm, setSelectedFacilityRiskData, clearRiskAssessment, clearFacilityRiskThresholdsStatus, clearClaimRatesStatus, clearClientRiskClassSettingsStatus, clearFacilityRiskClassSettingsStatus, clearMultiFacilityRiskClassSettingsStatus, clearPatientEpisodeMarginSettingsStatus, clearMultiFacilityReferralSourceRevenueStatus, clearMultiFacilityReferralSourcesStatus} = riskAssessmentSlice.actions;

export default riskAssessmentSlice.reducer;