import React from "react";
import Typography from "@mui/material/Typography";
import { Grid, Paper, IconButton } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";

function AccountHolderSettingsView() {
  return (
    <main className="active-view">
      <div className="content">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} className="mt-4 pl-6">
            <Typography variant="h1">Settings</Typography>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper className="mt-2 px-4 pb-4">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h1">Name & Address</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => { }} className="edit-button" size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="body2" className="mt-1 mb-3">
                  Clary M Rominov
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <address>
                    299 Summit Lane
                    <br />
                    Apt 301
                    <br />
                    New York, NY 1002
                  </address>
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper className="mt-2 px-4 pb-4">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h1">Contact</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => {}} className="edit-button" size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="body2" className="mt-1 mb-3">
                  crominov@gmail.com
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  (212) 5555 - 1212 (Mobile)
                </Typography>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper className="mt-2 px-4 pb-4">
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography variant="h1">Password</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => {}} className="edit-button" size="large">
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item>
                <Typography variant="body2" className="mt-1 mb-3">
                  Last updated MM/DD/YYYY
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </main>
  );
}
export default AccountHolderSettingsView;
