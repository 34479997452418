import { emptyPaymentMethodType, PaymentMethodType } from "./payment-method-type";

export interface PatientPaymentMethod {
  patientPaymentMethodId: number;
  paymentMethodType: PaymentMethodType;
  externalPaymentMethodId: string;
}

export const emptyPatientPaymentMethod: PatientPaymentMethod = {
  patientPaymentMethodId: 0,
  paymentMethodType: emptyPaymentMethodType,
  externalPaymentMethodId: '',
}