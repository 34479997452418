import './login.scss';

import { Button, TextField } from '@finpay-development/shared-components';
import { Grid, Paper, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FppHeader from '../../shared/components/header';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { verifySigninCode } from '../state/user-thunk';

function ConfirmSignIn() {
    const [verificationCode, setVerificationCode] = useState("");
    const [enabledButton, setEnabledButton] = useState(false);

    const dispatch = useDispatch<AppDispatch>();

    const userContextClearForm = useSelector(
        (state: RootState) => state.userContext.clearForm
      );

    useEffect(() => {
        setVerificationCode("");
        setEnabledButton(false);
    }, [userContextClearForm]);


    function verifyCode() {
        const verificationCodeValue: string = verificationCode.toString();
        dispatch(verifySigninCode(verificationCodeValue));
     }

    const validationSchema = Yup.object({
        verificationCode: Yup.number().required('Please Enter an Verification Code')
    });

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        const verificationCodeValue = e.target.value;
        setVerificationCode(verificationCodeValue);

        validationSchema.validate({ verificationCode: verificationCodeValue }, { abortEarly: false })
            .then(function() {
                setEnabledButton(true);
            }).catch(function (err: Yup.ValidationError) {
                setEnabledButton(false);
            });
      }

    return (
        <div className="page-container login-container" slot="confirm-sign-in">
            <FppHeader showLinks={false} />
            <main className="no-padding">
                <div className="content fullwidth loginflow">
                    <Grid container alignContent="center" justifyContent="center" direction="column" spacing={4}>
                        <Paper>
                            <Grid container direction="column" alignContent="center" justifyContent="center" spacing={2}>
                                <Grid item>
                                    <Typography className="mb-6" variant="h1">Enter Verification Code</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                            error=""
                                            label="Verification Code"
                                            name="email"
                                            value={verificationCode}
                                            onChange={handleChange}
                                            placeholder="Enter Verification Code"
                                            helperText=""
                                            onBlur={() => true}
                                    />
                                </Grid>
                                <Grid item container direction="column" alignContent="flex-end" justifyContent="flex-end" spacing={2}>
                                    <Grid item>
                                        <Button type="primary" disabled={!enabledButton} onClick={() => verifyCode()}>Verify</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Grid item className="terms-conditions">
                            <Grid container alignContent="center" justifyContent="center">
                              <Typography
                                color={'#0093d5'}
                                onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
                              >
                                Terms & Conditions
                              </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
             </main>

        </div>
    );
}

export default ConfirmSignIn;
