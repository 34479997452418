import { createAsyncThunk } from '@reduxjs/toolkit'
import { patientHelper } from '../../patient/services/patient-service';
import { VobPostBody, FacilityPayers } from '../models/vob';
import { admissionsAdvisorUtils } from "../utils/admission-advisor-utils";
import { admissionsAdvisorService } from '../services/admissions-advisor-services';

// handles add and edit cases.
export const saveVob = createAsyncThunk( 
  'vobContext/saveVob',
  async (vob: VobPostBody, thunkAPI) => {
    let response;

    if (vob?.vobBody.vobId) { // edit
      response = await admissionsAdvisorService.updateVob(vob?.vobBody.vobId, vob)
    } else { // add
      response = await admissionsAdvisorService.newVob(vob)
    }
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
)

export const getVob = createAsyncThunk(
  "vobContext/getVob",
  async (config: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getVob(config.paramId, config.vobId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getVobClassifications = createAsyncThunk(
  "vobContext/getVobClassifications",
  async (paramId: number, thunkAPI) => {
    const response = await admissionsAdvisorService.getVobClassifications(paramId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getEstimatorFacilityPayers = createAsyncThunk(
  "vobContext/getEstimatorFacilityPayers",
  async (params: any, thunkAPI) => {
    const response = await admissionsAdvisorService.getEstimatorFacilityLocPayers(params.paramId, params.facilityId);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    } else {
      let responseBody = {...response.entity};
      responseBody.masterListPayers = params.masterListPayers;
      const cleanedData:FacilityPayers[] = admissionsAdvisorUtils.fillClientPayerDataGaps(responseBody);
      return cleanedData;
    }
  }
);
