import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UNKNOWN_SERVER_ERROR } from '../../shared/state/saving-status';
import { LevelOfCare } from '../models/level-of-care';
import { PayerRow } from '../models/payers';
import {
  EditRule,
  getBlankRule,
  getBlankRuleCriteriaParam,
  getDefaultRuleEdit,
  Rule,
  RuleCriteriaParam,
  RuleEditViewModel,
  RuleSet,
} from '../models/rule';
import { Scholarship } from '../models/scholarship';
import { States } from '../models/us-states';
import { ConfigRiskThreshold } from '../models/risk-threshold'
import {
  configDeleteRule,
  configGetLOC,
  configGetPayers,
  configGetRule,
  configGetScholarships,
  configGetStates,
  configNewLOC,
  configNewPayer,
  configSaveLOC,
  configSavePayer,
  configSaveRule,
  deleteRuleCriteriaParam,
  getRuleCriteriaParam,
  getRuleCriteriaParams,
  getRules,
  reOrderRuleInUI,
  saveRuleCriteriaParam,
  configSaveScholarship,
  configNewScholarship,
  configGetRiskThresholds,
  configSaveRiskThreshold,
  configNewRiskThreshold,
  configGetRiskClassSettings,
  configSaveRiskClassSetting,
  configGetFinPayRiskClasses,
} from './admin-configuration-thunk';
import { ConfigRiskClassSetting, FinPayRiskClass } from '../models/risk-class-setting';

interface AdminContextState {
  rules: RuleSet;
  allRules: Rule[];
  currentRule: RuleEditViewModel;
  fullRule: Rule;
  currentRuleCriteriaParam: RuleCriteriaParam;
  allRuleCriteriaParams: RuleCriteriaParam[];
  usStates: States[],
  levelsOfCare: LevelOfCare[],
  payers: PayerRow[]
  payersMap: Map<number, PayerRow>
  modalSaveStatus: saveCallbackStatus;
  modalDeleteStatus: saveCallbackStatus;
  errorMessage: string;
  isSaveSuccessful: boolean;
  isLoading: {
    isLoadingRules: boolean;
    isLoadingPayer: boolean;
    isLoadingUsStates: boolean;
    isLoadingLevelsOfCare: boolean,
    getLevelsOfCareStatus: saveCallbackStatus,
    isLoadingScholarship: boolean,
    getScholarshipsStatus: saveCallbackStatus,
    isLoadingRiskThresholds: boolean,
    getRiskThresholdsStatus: saveCallbackStatus,
    isLoadingRiskClassSettings: boolean,
    getRiskClassSettingsStatus: saveCallbackStatus,
    isLoadingFinPayRiskClasses: boolean,
  },
  scholarships: Scholarship[],
  riskThresholds: ConfigRiskThreshold[],
  riskClassSettings: ConfigRiskClassSetting[],
  finPayRiskClasses: FinPayRiskClass[],
}

const initialState = {
  rules: [],
  allRules: [],
  currentRule: getDefaultRuleEdit(),
  fullRule: getBlankRule(),
  currentRuleCriteriaParam: getBlankRuleCriteriaParam(),
  allRuleCriteriaParams: [],
  usStates: [],
  levelsOfCare: [],
  payers: [],
  payersMap:  new Map(),
  modalSaveStatus: saveCallbackStatus.none,
  modalDeleteStatus: saveCallbackStatus.none,
  errorMessage: "",
  isSaveSuccessful: true,
  isLoading: {
    isLoadingRules: false,
    isLoadingPayer: false,
    isLoadingUsStates: false,
    isLoadingLevelsOfCare: false,
    getLevelsOfCareStatus: saveCallbackStatus.none,
    isLoadingScholarship: false,
    getScholarshipsStatus: saveCallbackStatus.none,
    isLoadingRiskThresholds: false,
    getRiskThresholdsStatus: saveCallbackStatus.none,
    isLoadingRiskClassSettings: false,
    getRiskClassSettingsStatus: saveCallbackStatus.none,
    isLoadingFinPayRiskClasses: false,
  },
  scholarships: [],
  riskThresholds: [],
  riskClassSettings: [],
  finPayRiskClasses: [],

} as AdminContextState;

const adminConfigurationSlice = createSlice({
  name: "adminConfigurationContext",
  initialState,
  reducers: {
    resetRuleForm: (state) => {
      state.currentRule = getDefaultRuleEdit();
    },
    resetFormulaForm: (state) => {
      state.currentRuleCriteriaParam = getBlankRuleCriteriaParam();
    },
    clearStatus: (state) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.modalDeleteStatus = saveCallbackStatus.none;
      state.errorMessage = "";
      state.isLoading.getLevelsOfCareStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = true;
      state.isLoading.getScholarshipsStatus = saveCallbackStatus.none;
      state.isLoading.getRiskThresholdsStatus = saveCallbackStatus.none;
    },
    clearRiskClassSettingsStatus: (state) => {
      state.isLoading.getRiskClassSettingsStatus = saveCallbackStatus.none;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      // may not be needed. (unused currently). re-evaluate later
      state.isLoading.isLoadingRules = action.payload;
    },
    resetAdminConfigurationContext() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(reOrderRuleInUI.fulfilled, (state, action) => {
      state.isLoading.isLoadingRules = false;
      if (action.payload !== undefined) {
        state.rules = action.payload;
      }
    });
    builder.addCase(getRules.pending, (state, action) => {
      state.isLoading.isLoadingRules = true;
    });
    builder.addCase(getRules.fulfilled, (state, action) => {
      state.isLoading.isLoadingRules = false;
      state.rules = action.payload?.groupedRules;
      state.allRules = action.payload?.ungroupedRules;
    });
    builder.addCase(
      configGetRule.fulfilled,
      (state, action: PayloadAction<EditRule>) => {
        state.isLoading.isLoadingRules = false;
        state.currentRule = action.payload?.ruleEditView;
        state.fullRule = action.payload?.fullRule;
      }
    );
    builder.addCase(
      configGetPayers.fulfilled,
      (state, action: PayloadAction<any>) => {

        const allPayersMap = action.payload.reduce((payersMap: Map<number, PayerRow>, currPayer: PayerRow)=>{
          payersMap.set(currPayer.payorId, currPayer)
          return payersMap
        }, new Map())

        state.payers = action.payload;
        state.payersMap = allPayersMap;
        state.isLoading.isLoadingPayer = false;
      }
    );
    builder.addCase(configGetPayers.pending, (state, action) => {
      state.isLoading.isLoadingPayer = true;
    });
    builder.addCase(configGetPayers.rejected, (state, action) => {
      state.isLoading.isLoadingPayer = false;
    });

    builder.addCase(
      configGetScholarships.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.scholarships = action.payload;
        state.isLoading.isLoadingScholarship = false;
        state.isLoading.getScholarshipsStatus = saveCallbackStatus.success;
      }
    );
    builder.addCase(configGetScholarships.pending, (state, action) => {
      state.isLoading.isLoadingScholarship = true;
      state.isLoading.getScholarshipsStatus = saveCallbackStatus.none;
    });
    builder.addCase(configGetScholarships.rejected, (state, action) => {
      state.isLoading.isLoadingScholarship = false;
      state.isLoading.getScholarshipsStatus = saveCallbackStatus.error;
    });
    builder.addCase(configSaveScholarship.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveScholarship.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveScholarship.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configNewScholarship.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configNewScholarship.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configNewScholarship.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configSaveRule.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    });

    builder.addCase(configSaveRule.fulfilled, (state, action) => {
      state.rules = action.payload?.groupedRules;
      state.allRules = action.payload?.ungroupedRules;
      state.modalSaveStatus = saveCallbackStatus.success;
    });

    builder.addCase(configSaveRule.rejected, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(configDeleteRule.pending, (state, action) => {
      state.modalDeleteStatus = saveCallbackStatus.none;
    });

    builder.addCase(configDeleteRule.fulfilled, (state, action) => {
      state.rules = action.payload?.groupedRules;
      state.allRules = action.payload?.ungroupedRules;
      state.modalDeleteStatus = saveCallbackStatus.success;
    });

    builder.addCase(configDeleteRule.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(
      getRuleCriteriaParam.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.currentRuleCriteriaParam = action.payload;
      }
    );

    builder.addCase(getRuleCriteriaParam.pending, (state, action) => {});

    builder.addCase(getRuleCriteriaParams.pending, (state, action) => {
      state.isLoading.isLoadingRules = true;
    });

    builder.addCase(
      getRuleCriteriaParams.fulfilled,
      (state, action: PayloadAction<RuleCriteriaParam[]>) => {
        state.allRuleCriteriaParams = action.payload;
        state.isLoading.isLoadingRules = false;
      }
    );

    builder.addCase(saveRuleCriteriaParam.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
    });

    builder.addCase(
      saveRuleCriteriaParam.fulfilled,
      (state, action: PayloadAction<RuleCriteriaParam[]>) => {
        state.allRuleCriteriaParams = action.payload;
        state.modalSaveStatus = saveCallbackStatus.success;
      }
    );

    builder.addCase(saveRuleCriteriaParam.rejected, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(
      deleteRuleCriteriaParam.fulfilled,
      (state, action: PayloadAction<RuleCriteriaParam[]>) => {
        state.allRuleCriteriaParams = action.payload;
        state.modalDeleteStatus = saveCallbackStatus.success;
      }
    );

    builder.addCase(deleteRuleCriteriaParam.pending, (state) => {
      state.modalDeleteStatus = saveCallbackStatus.none;
    });

    builder.addCase(deleteRuleCriteriaParam.rejected, (state, action) => {
      state.modalDeleteStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(configNewPayer.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });

    builder.addCase(configNewPayer.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configSavePayer.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });

    builder.addCase(configSavePayer.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configSaveLOC.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });

    builder.addCase(configSaveLOC.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configNewLOC.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });

    builder.addCase(configNewLOC.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configGetStates.pending, (state, action) => {
      state.isLoading.isLoadingUsStates = true;
    });

    builder.addCase(configGetStates.fulfilled, (state, action: PayloadAction<any>) => {
      state.usStates = action.payload;
	    state.isLoading.isLoadingUsStates = false;
    });

    builder.addCase(configGetStates.rejected, (state, action) => {
      state.isLoading.isLoadingUsStates = false;
    });

    builder.addCase(configGetLOC.pending, (state, action) => {
      state.isLoading.getLevelsOfCareStatus = saveCallbackStatus.none;
      state.isLoading.isLoadingLevelsOfCare = true;
    });

    builder.addCase(configGetLOC.fulfilled, (state, action: PayloadAction<any>) => {
      state.levelsOfCare = action.payload;
      state.isLoading.getLevelsOfCareStatus = saveCallbackStatus.success;
	    state.isLoading.isLoadingLevelsOfCare = false;
    });

    builder.addCase(configGetLOC.rejected, (state, action) => {
      state.isLoading.getLevelsOfCareStatus = saveCallbackStatus.error;
      state.isLoading.isLoadingLevelsOfCare = false;
    });

    builder.addCase(
      configGetRiskThresholds.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.riskThresholds = action.payload;
        state.isLoading.isLoadingRiskThresholds = false;
        state.isLoading.getRiskThresholdsStatus = saveCallbackStatus.success;
      }
    );

    builder.addCase(configGetRiskThresholds.pending, (state, action) => {
      state.isLoading.isLoadingRiskThresholds = true;
      state.isLoading.getRiskThresholdsStatus = saveCallbackStatus.none;
    });

    builder.addCase(configGetRiskThresholds.rejected, (state, action) => {
      state.isLoading.isLoadingRiskThresholds = false;
      state.isLoading.getRiskThresholdsStatus = saveCallbackStatus.error;
    });

    builder.addCase(configSaveRiskThreshold.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveRiskThreshold.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveRiskThreshold.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(configNewRiskThreshold.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configNewRiskThreshold.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configNewRiskThreshold.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(
      configGetRiskClassSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.riskClassSettings = action.payload;
        state.isLoading.isLoadingRiskClassSettings = false;
        state.isLoading.getRiskClassSettingsStatus = saveCallbackStatus.success;
      }
    );
    builder.addCase(configGetRiskClassSettings.pending, (state, action) => {
      state.isLoading.isLoadingRiskClassSettings = true;
      state.isLoading.getRiskClassSettingsStatus = saveCallbackStatus.none;
    });
    builder.addCase(configGetRiskClassSettings.rejected, (state, action) => {
      state.isLoading.isLoadingRiskClassSettings = false;
      state.isLoading.getRiskClassSettingsStatus = saveCallbackStatus.error;
    });

    builder.addCase(configSaveRiskClassSetting.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.modalSaveStatus = saveCallbackStatus.error;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveRiskClassSetting.pending, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.none;
      state.isSaveSuccessful = false;
    });
    builder.addCase(configSaveRiskClassSetting.fulfilled, (state, action) => {
      state.modalSaveStatus = saveCallbackStatus.success;
      state.isSaveSuccessful = true;
    });

    builder.addCase(
      configGetFinPayRiskClasses.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.finPayRiskClasses = action.payload;
        state.isLoading.isLoadingFinPayRiskClasses = false;
      }
    );
    builder.addCase(configGetFinPayRiskClasses.pending, (state, action) => {
      state.isLoading.isLoadingFinPayRiskClasses = true;
    });
    builder.addCase(configGetFinPayRiskClasses.rejected, (state, action) => {
      state.isLoading.isLoadingFinPayRiskClasses = false;
    });
  },
});

export const {
  resetRuleForm,
  resetFormulaForm,
  clearStatus,
  clearRiskClassSettingsStatus,
  resetAdminConfigurationContext,
} = adminConfigurationSlice.actions;

export default adminConfigurationSlice.reducer;
