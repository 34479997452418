import axios from "axios";
import { configSettings } from "../configuration/config-settings";
import { AxiosReadPayload } from "./axios-read-payload";
import { AxiosResultStatus } from "./axios-result-status";

 export const axiosDownloadHelper = async function(payload: AxiosReadPayload): Promise<AxiosResultStatus> {

  const downloadResult = new AxiosResultStatus();

  // Build URL for Getting Data
  let url: string = "";
  if (payload.dataId === 0) {
    url = `${configSettings.aws_gateway_server_uri}/${payload.url}`;
  } else if (payload.dataId > 0) {
    url = `${configSettings.aws_gateway_server_uri}/${payload.url}/${payload.dataId}`;
  } else if (payload.dataId === null || payload.dataId === undefined) {
    url = `${configSettings.aws_gateway_server_uri}/${payload.url}`;
  }

  // Execute Download Get And Handle Errors
  await axios({
    url: url,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    }
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'signingdocument.pdf');
    document.body.appendChild(link);
    link.click();
    downloadResult.entity = document;
  })
  .catch((error: Error) => {
    downloadResult.errorMessage = error.message;
  })

  downloadResult.hasErrors = downloadResult.errorMessage?.length > 0;
  return downloadResult;
 }

