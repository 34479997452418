import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isCurrentUrlPublic } from '../../guest/misc/helpers';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';

function AuthenticationMonitor() {
    const navigate = useNavigate();
    const isPublic = isCurrentUrlPublic(window.location.href);

    const userContext = useSelector(
        (state: RootState) => state.userContext
    );

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
      if (userContext.userIsAuthenticated) {
          if (userContext.homeRoute && userContext.homeRoute.length > 0) {
            if (window.location.pathname === "" || window.location.pathname === "/"
              || isPublic.isCurrentUrlPublic) {
                navigate(userContext.homeRoute);
            }
          }
       }
    }, [navigate, userContext.userIsAuthenticated, userContext.homeRoute, userContext.onLoginScreen, dispatch, isPublic]);

    return (
        <div></div>
    )
}

export default AuthenticationMonitor;
