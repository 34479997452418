import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { AxiosResultStatus } from "../../shared/service/axios-result-status";
import { AxiosSavePayload } from "../../shared/service/axios-save-payload";
import { axiosSaveHelper } from "../../shared/service/axios-save-helper";
import { ImplementationFacility } from "../components/implementation-clients/details/models/implementation-facility";

export class ImplementationRulesEngineService {
  async getPaymentFrequency(clientId: number): Promise<AxiosResultStatus> {

    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client/${clientId}/facility`,
    };
    const response = await axiosReadHelper(readPayload);
    return response;
  }

  async savePaymentFrequency(facility: ImplementationFacility): Promise<AxiosResultStatus> {
    const isEdit = facility.facilityId !== 0
    const propertyFacilityToSave = {
      patientPmtFreq: facility.patientPmtFreq
    }
    const facilityId = facility.facilityId;
    const updateFacilityURL = `clients/v2/client/facility/${facilityId}`;

    const payload: AxiosSavePayload = {
      dataToSave: propertyFacilityToSave,
      dataId: -2,
      isPatch: isEdit,
      url: updateFacilityURL
    }

    const response = await axiosSaveHelper(payload);
    return response;
  }

  async clearRules(facility: ImplementationFacility): Promise<AxiosResultStatus | any> {
    const payload: AxiosSavePayload = {
      dataToSave: {},
      dataId: 0,
      url: `client/${facility.clientId}/facility/${facility.facilityId}/rules/clear`,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }
}

export const implementationRulesEngineService = new ImplementationRulesEngineService();