import React, { useEffect, useState } from 'react';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Grid, IconButton } from '@mui/material';

import { AApatientSearchModal } from '../patient-search/patient-search-modal';
import { vobPatientPostBody } from '../../models/patient';
import { Vob } from '../../models/vob';
import { ClientCrm, ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import { useSelector } from 'react-redux';
import { RootState } from '../../../shared/state/root-reducer';

interface PatientSearchProps {
  clearAAState: () => void;
  vob: any; 
  updatePatientFormik: (patientState: vobPatientPostBody) => void | undefined;
  updateVOBOnPatientSelection: (clientId: number | undefined) => void | undefined;
  updateVobFormik: (vobBody: Vob) => void | undefined;
  handleEstimatorTabClick: (tab: number) => void;
}

export function PatientSearchArea(props: PatientSearchProps) {
  const [searchPatientModalOpen, setSearchPatientModalOpen] = useState(false);
  const [hideStartOver, setHideStartOver] = useState(false);
  const {
    clearAAState,
    vob,
    updatePatientFormik,
    updateVOBOnPatientSelection,
    updateVobFormik,
    handleEstimatorTabClick
  } = props;

  const handleSearchModalCancel = () => {
    setSearchPatientModalOpen(false);
  }

  const state = {
      allClients: useSelector(
          (state: RootState) =>
              state.implementationContext?.implementationSpecialistClient
                  .allClientsWithFacillities
      ),
      estimatorState: useSelector(
          (state: RootState) =>
              state.admissionsAdvisorContext.estimatorContext.estimator
      ),
      vobState: useSelector(
          (state: RootState) => state.admissionsAdvisorContext?.vobContext.vob
      ),
  };

  const { allClients, estimatorState, vobState } = state;

  useEffect(() => {
      if (estimatorState?.client?.clientId || vobState?.client?.clientId) {
          const currentClientId: number =
              estimatorState?.client?.clientId! || vobState?.client?.clientId!;
          const clientItem: ClientStatusCardViewModel = allClients?.find(
              (client: ClientStatusCardViewModel) =>
                  client.clientId === currentClientId!
          )!;
          const clientCrm: ClientCrm[] = clientItem?.clientCrm!;
          if (clientCrm && clientCrm.length > 0) {
              setHideStartOver(true);
          } else {
              setHideStartOver(false);
          }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClients, estimatorState, vobState]);

  return (
    <>
      <Grid item>
        <strong style={{ 
          color: "#bebebe", 
          paddingLeft: "20px", 
          fontSize: "14px", 
          paddingRight: "5px"
        }}>|</strong>
        <IconButton
          title="Search Patient"
          color="primary"
          style={{ 
            fontSize: "1.8rem", 
            padding: "0px 10px 0 0", 
            marginLeft: "16px"
          }}
          onClick={() => setSearchPatientModalOpen(true)}
          size="large">
          <PersonSearchIcon /> <strong>Search Patient</strong>
        </IconButton>
        { (vob.isValid && !hideStartOver) &&
          <>
            <strong style={{ 
              color: "#bebebe", 
              fontSize: "12px"
            }}>|</strong>
            <IconButton
              title="Clear Patient, VOB, Estimator and start over"
              color="primary"
              style={{ 
                fontSize: "1.8rem", 
                paddingLeft: "8px"
              }}
              onClick={() => clearAAState()}
              size="large">
              <RestartAltIcon /> <strong>Start Over</strong>
            </IconButton>
          </>
        } 
      </Grid>
        {searchPatientModalOpen && (
          <AApatientSearchModal
            open={searchPatientModalOpen}
            handlePatSearchModalCancel={handleSearchModalCancel}
            handleUpdatePatientFormik={updatePatientFormik}
            updateVOBOnPatientSelection={updateVOBOnPatientSelection}
            handleUpdateVobFormik= {updateVobFormik}
            handleEstimatorTabClick={handleEstimatorTabClick}
          />
        )}
    </>
  );
}
