import React, { useState, ReactNode } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";

import { Button } from "@finpay-development/shared-components";

interface OpenSearchModalProps {
  children: ReactNode;
  resetState: () => void;
  actionButton: ReactNode;
}

const OpenSearchModal = (props: OpenSearchModalProps) => {
  const { children, resetState, actionButton } = props;
  const [searchPatientModalOpen, setSearchPatientModalOpen] = useState(false);

  const handleSearchModalCancel = () => {
    setSearchPatientModalOpen(false);
    resetState();
  };

  return (
    <>
      <Grid item>
        <IconButton
          title="Search Patient"
          color="primary"
          style={{
            fontSize: "1.8rem",
            padding: "0px 10px 0 0",
            marginLeft: "16px",
          }}
          onClick={() => setSearchPatientModalOpen(true)}
          size="large"
        >
          <PersonSearchIcon /> <strong>Search Patient</strong>
        </IconButton>
      </Grid>
      {searchPatientModalOpen && (
        <Dialog
          className="modal"
          sx={{ padding: "4rem" }}
          open={searchPatientModalOpen}
          fullWidth={true}
          maxWidth="md"
          scroll="body"
        >
          <DialogTitle>Search for Patient</DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleSearchModalCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent sx={{ paddingBottom: "1rem" }}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} direction="column">
                {children}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button type="secondary" onClick={() => handleSearchModalCancel()}>
              Cancel
            </Button>
            {actionButton}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default OpenSearchModal;
