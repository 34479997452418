import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { TextField, DialogActionButton } from "@finpay-development/shared-components";
import React, { useState } from "react";
import { ConfigRiskClassSetting } from "../../models/risk-class-setting";
import { showErrorStatus } from "../../../security/state/user-slice";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus } from "../../state/admin-configuration-slice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { configSaveRiskClassSetting } from "../../state/admin-configuration-thunk";

interface RiskClassSettingConfigModalProps {
    open: boolean;
    isEdit: boolean;
    riskClassSettingItem: ConfigRiskClassSetting;
    handleRiskClassSettingCancel: () => void;
    handleRiskClassSettingSubmit: (isEdit: boolean) => void;
}

export function RiskClassSettingConfigModal(props: RiskClassSettingConfigModalProps) {
    const {
        open,
        isEdit,
        riskClassSettingItem,
        handleRiskClassSettingCancel,
        handleRiskClassSettingSubmit,
    } = props;

    const saveStatus = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.modalSaveStatus
    );

    const isSaveSuccessful = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.isSaveSuccessful
    );

    const errorMessage = useSelector(
        (state: RootState) =>
            state.adminContext.adminConfigurationContext.errorMessage
    );

    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const paramId: number = -2;

    const initialRiskSettingValues: ConfigRiskClassSetting = {
        paramId: paramId,
        riskClassSettingId: riskClassSettingItem.riskClassSettingId,
        fpRiskClassId: riskClassSettingItem.fpRiskClassId,
        riskClassName: riskClassSettingItem.riskClassName,
        riskClassCleared: riskClassSettingItem.riskClassCleared,
        riskClassHre: riskClassSettingItem.riskClassHre,
    };

    const validationSchema = Yup.object({
        riskClassCleared: Yup.number().required("Risk Class Cleared").min(.01).max(100),
        riskClassHre: Yup.number().required("High Risk Exception is required").min(.01).max(100),
    });

    const riskSettingFormik = useFormik({
        initialValues: initialRiskSettingValues,
        enableReinitialize: true,
        onSubmit: () => { },
        validationSchema: validationSchema,
        validate: checkIfIsValid,
    });

    function checkIfIsValid(value: {}) {
        validationSchema
            .validate(value)
            .then(() => {
                setEnableSaveButton(true);
            })
            .catch((err) => {
                setEnableSaveButton(false);
            });
    }

    async function handleSave() {

        const requestBody: ConfigRiskClassSetting = {
            paramId: paramId,
            riskClassSettingId: riskSettingFormik.values.riskClassSettingId,
            fpRiskClassId: riskSettingFormik.values.fpRiskClassId,
            riskClassName: riskSettingFormik.values.riskClassName,
            riskClassCleared: riskSettingFormik.values.riskClassCleared,
            riskClassHre: riskSettingFormik.values.riskClassHre,
        };

        await dispatch(configSaveRiskClassSetting(requestBody));
    }

    function handleSaveCallback() {
        if (isSaveSuccessful) {
            handleRiskClassSettingSubmit(isEdit);
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    function handleCancelCallback() {
        handleRiskClassSettingCancel();
    }

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>{ isEdit ? 'Edit' : 'Add'} Risk Class Setting: <br /><span style={{fontSize: "16pt", fontWeight: "normal"}}> {riskClassSettingItem.riskClassName}</span></DialogTitle>
            <DialogContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={4} className="mt-4">
                            <TextField
                                name="riskClassCleared"
                                label="Cleared"
                                type="number"
                                variant="outlined"
                                value={
                                    riskSettingFormik.values.riskClassCleared
                                      ? riskSettingFormik.values.riskClassCleared
                                      : null
                                }
                                onChange={riskSettingFormik.handleChange}
                                onBlur={riskSettingFormik.handleBlur}
                                placeholder=""
                                endAdornment="%"
                                error={
                                    riskSettingFormik.touched["riskClassCleared"] &&
                                    riskSettingFormik.errors["riskClassCleared"]
                                      ? riskSettingFormik.errors["riskClassCleared"]
                                      : ""
                                }
                            />
                        </Grid>
                        <Grid item xs={4} className="mt-4">
                            <TextField
                                name="riskClassHre"
                                label="High Risk Exception HRE"
                                type="number"
                                variant="outlined"
                                value={
                                    riskSettingFormik.values.riskClassHre
                                      ? riskSettingFormik.values.riskClassHre
                                      : null
                                }
                                onChange={riskSettingFormik.handleChange}
                                onBlur={riskSettingFormik.handleBlur}
                                placeholder=""
                                endAdornment="%"
                                error={
                                    riskSettingFormik.touched["riskClassHre"] &&
                                    riskSettingFormik.errors["riskClassHre"]
                                      ? riskSettingFormik.errors["riskClassHre"]
                                      : ""
                                }
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={enableSaveButton}
                    savebuttonText={isEdit ? "Update" : "Save"}
                    saveStatus={saveStatus}
                    spinnerLeftPosition={5}
                    executeSave={handleSave}
                    handleCallbackSave={handleSaveCallback}
                    handleCallbackCancel={handleCancelCallback}
                />
            </DialogActions>
        </Dialog>
    );
}