import React from 'react';
import { Grid } from "@mui/material";
import {FieldArray, FormikProvider} from 'formik';
import {Checkbox} from "@finpay-development/shared-components";

interface CheckboxGroupProps {
  data: any[];
  idField: string;
  labelField: string;
  name: string;
  direction?: "row" | "column";
  horizontalSpacing?: number; // padding from 1-8 to the right of every item when direction is 'row'
  formik: any;
}

/**
 * @component CheckboxGroup
 * @example
 * return (
 *  <CheckboxGroup
 *     name="clients"
 *     idField="clientId"
 *     labelField="clientName"
 *     data={allClients}
 *     direction="column"
 *     formik={formik}
 *  />
 * )
 *
 * @param {any[]} data: contents data for the checkboxGroup
 * @param {string} idField: checkbox id;
 * @param {string} labelField: checkbox label;
 * @param {string} name: name;
 * @param {string} direction?: "row" | "column";
 * @param {number} horizontalSpacing?: padding from 1-8 to the right of every item when direction is 'row'
 * @param {formikShape} formik: pass formik object here
 */

const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    data,
    idField,
    labelField,
    name,
    direction = "column",
    horizontalSpacing = 4,
    formik,
  } = props;

  return (
  <FormikProvider value={formik}>
      <FieldArray name={name}>
        {(arrayHelpers) => (
          <>
            <Grid container direction={direction}>
              {data?.map((checkbox: any) => (
                <Grid
                  item
                  key={checkbox[idField]}
                  className={`mr-${horizontalSpacing}`}
                >
                  <Checkbox
                    name={name}
                    id={`${name}-${checkbox[idField]}`}
                    label={checkbox[labelField]}
                    checked={formik?.values[name]?.includes(checkbox[idField])}
                    onChange={(e: Event) => {
                      if ((e.target as HTMLInputElement).checked) {
                        // When is All is checked clear out other check boxes
                        if (checkbox["isAll"]) {
                          formik.values[name] = [];
                        } else {
                          // Check to see if "All" is checked and clear it.
                          if (formik.values[name].length) {
                            const allItem = formik.values[name][0]
                            if (allItem === 0) {
                              formik.values[name].shift();
                            }
                          }
                        }
                        arrayHelpers.push(checkbox[idField]);
                      } else {
                        const idx = formik.values[name].indexOf(
                          checkbox[idField]
                        );
                        arrayHelpers.remove(idx);
                      }
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </FieldArray>
    </FormikProvider>
  );
}

export default CheckboxGroup;
