import { createAsyncThunk } from "@reduxjs/toolkit";
import { paynowHelper } from "../misc/helpers";
import { PayNowMakePaymentRequest } from "../models/pay-now-make-payment";
import { paynowService } from '../services/paynow-service';
import { showErrorStatus } from "../../security/state/user-slice";
import { LaunchPayloadHelper } from "../misc/launch-payload-helper";
import { RootState } from '../../shared/state/root-reducer';
import { Payment, StripeErrorMessage } from '../../patient/components/models/payment';
import { stripePaymentErrors } from "../../shared/enums";

export const checkEmail = createAsyncThunk(
    "guestContext/checkEmail",
    async(guestData:{clientId: string | number, email: string, authToken: string}, thunkAPI) => {
        const response = await paynowService.checkEmail(guestData);
        
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }        
    }

)

export const getLaunchPayload = createAsyncThunk(
    "guestContext/getLaunchPayload",
    async(request:{encryptedValue: string, authToken: string}, thunkAPI) => {
        const response = await paynowService.getLaunchPayload(request.encryptedValue, request.authToken);    
        
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            const payloadHelper: LaunchPayloadHelper = new LaunchPayloadHelper(response);
            switch(true){
              case payloadHelper.hasKey("encId"):
              case payloadHelper.hasKey("facId"):
                return {"facilityId": payloadHelper.getValue("facId"), "patientEncounterId": payloadHelper.getValue("encId"), "isInvalidForPayNow": false, "isProcessingError": false };
              default:
                return {"facilityId": "", "isInvalidForPayNow": true, "isProcessingError": true };
            }
        }        
    }

)

export const getConfig = createAsyncThunk(
    "guestContext/getConfig",
    async (configData:{configId: string, configIdSource: string, configKey: string, authToken: string}, thunkAPI) => {
        const response = await paynowService.getConfig(configData.configId, configData.configIdSource, configData.configKey, configData.authToken);
        if (response?.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }   
    }
)

export const getPaymentChannels = createAsyncThunk(
    "guestContext/getPaymentChannels",
    async (configData:{authToken: string}, thunkAPI) => {
        const response = await paynowService.getPaymentChannels(configData.authToken);
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }   
    }

)

export const getAnonymousRequestToken = createAsyncThunk(
    "guestContext/setAuthToken",
    async (thunkAPI) => {
        const response = await paynowService.getAnonymousRequestToken();
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }   
    }

)

export const getMakePaymentAuthToken = createAsyncThunk(
    "guestContext/setPaymentAuthToken",
    async (thunkAPI) => {
        const response = await paynowService.getAnonymousRequestToken();
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }   
    }
)

export const getEmailLookupAuthToken = createAsyncThunk(
    "guestContext/setEmailLookupAuthToken",
    async (thunkAPI) => {
        const response = await paynowService.getAnonymousRequestToken();
        if (response.hasErrors) {
            paynowHelper.robustErrorHandler(response, thunkAPI);
        } else {
            return response;
        }
    }
)

export const submitPayment = createAsyncThunk(
    "guestContext/payment",
    async (paymentData: {
        payment: Payment | PayNowMakePaymentRequest | undefined,
        authToken: string,
        isOptimal?: Boolean,
        extraData?: any,
    }, thunkAPI) => {

      const state = thunkAPI.getState() as RootState;
      let response;
      let description: string = '';

      if(state.guestContext.channelType === 'mobile'){
        response = await paynowService.submitMobilePayment(
            paymentData.payment as Payment, state.guestContext.mobilePayment.patientInfo, !!paymentData.isOptimal, paymentData?.extraData,
        );
      }else{
        response = await paynowService.submitPayment(paymentData?.payment as PayNowMakePaymentRequest | undefined, paymentData?.authToken);
      }
      
      if (response.paymentStatus?.toLowerCase() === 'failed') { 
        const parsedError: StripeErrorMessage = JSON.parse(response.paymentStatusMessage);
        if(parsedError.message === stripePaymentErrors.generic_decline){
            description = `${parsedError.message} - ${parsedError.code}:${parsedError.declineCode}`
        } else {
            description = parsedError.message;
        }
        thunkAPI.dispatch(showErrorStatus(description));
      } else if (response.hasErrors) {
          thunkAPI.dispatch(
              showErrorStatus(
                  response.paymentStatusMessage
                      ? response.paymentStatusMessage
                      : stripePaymentErrors.generic_decline
              )
          );
      } else {
          const successful =
              response.paymentStatus?.toLowerCase() === 'success' ||
              response.entity?.paymentStatus?.toLowerCase() === 'success';
          if (!successful) {
              thunkAPI.dispatch(showErrorStatus(response.paymentStatusMessage));
          }
          return response;
      }
    }
)

export const eventPayment = createAsyncThunk(
    "guestContext/eventPayment",
    async (paymentData:{payment: any, authToken: string}, thunkAPI) => {
        let response = await paynowService.eventPayment(paymentData.payment, paymentData.authToken);
        if (response.hasErrors) {
            thunkAPI.dispatch(showErrorStatus("An error happened while sending payment information to CRM. Please check client setup. "));
        }
    }
)

export const getClientFacility = createAsyncThunk(
    "guestContext/getClientFacility",
    async (data: {facilityId: string, authToken: string}, thunkAPI) => {
      const response = await paynowService.getClientFacility(data.facilityId, data.authToken);
      if (!response) {
        thunkAPI.dispatch(showErrorStatus("An error happened while validating the client's facility. Please contact system administrator."));
        return;
      }

      return response

    }
);

export const getClientFacilities = createAsyncThunk(
    "guestContext/getClientFacilities",
    async (data: {clientId: number, authToken: string}, thunkAPI) => {
      const response = await paynowService.getClientFacilities(data.clientId, data.authToken);
      if (!response) {
        thunkAPI.dispatch(showErrorStatus("An error happened while loading the client's facilities. Please contact system administrator."));
        return;
      }
      return response
    }
);
