import { createSlice} from '@reduxjs/toolkit';
import {getAlerts, getEmployers, getMetaData, getPaymentMetadata} from './meta-data-thunk';
import {CRMObjectTypeMetaData, CRMTypeMetaData,PaymentChannelMetaData, TimingRiskMetaData, Alerts, emptyAlerts, Employers, emptyEmployers,  RuleParamsMetadata,} from '../models/metaData';
import { getCRMMetaData } from './crm-meta-data-thunk';

export interface MetaDataState {
  paymentChannel: PaymentChannelMetaData[],
  timingRisk: TimingRiskMetaData[],
  crmObjectType: CRMObjectTypeMetaData[],
  crmType: CRMTypeMetaData[],
  alerts: Alerts,
  employers: Employers,
  ruleParams: RuleParamsMetadata[]
}

const initialState: MetaDataState = {
  paymentChannel: [],
  timingRisk: [],
  crmObjectType: [],
  crmType:[],
  alerts: emptyAlerts,
  employers: emptyEmployers,
  ruleParams: []
};

export const metaDataSlice = createSlice({
  name: "metaData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMetaData.fulfilled, (state, action) => {
      state.paymentChannel = action.payload.paymentChannel;
      state.timingRisk = action.payload.timingRisk;
    });
    builder.addCase(getCRMMetaData.fulfilled, (state, action) => {
      state.crmObjectType = action.payload.crmObjectType
      state.crmType = action.payload.crmType
    })
    builder.addCase(getAlerts.fulfilled, (state, action) => {
      state.alerts[+action.payload.clientId] = action.payload.alerts.entity
    })
    builder.addCase(getEmployers.fulfilled, (state, action) => {
      state.employers[+action.payload.clientId] = action.payload.employers.entity
    })
    builder.addCase(getPaymentMetadata.fulfilled, (state, action) => {
      Object.keys(action.payload).forEach((metadataKey) => {
        state[metadataKey as keyof typeof state] = action.payload[metadataKey]
      })
    })
  },
});

export default metaDataSlice.reducer;