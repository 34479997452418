export class LaunchPayloadItem {
    public constructor(key: string, value: string) {
        this.key = key;
        this.value = value;
    }
    key: string;
    value: string;
}



export class LaunchPayloadHelper {

    items: LaunchPayloadItem[];

    constructor(payload: string) {
        this.items = [];
        this.parsePayload(payload);
    }

    parsePayload(payload: string) {
        //  facilityId=100104;testId=45

        const terms = payload.trim().split('&')
        for (let term of terms) {
            term = term.trim();
            const termParts = term.split('=');
            if (termParts.length === 2) {
                if (termParts[0].trim().length > 0) {
                    this.items.push(new LaunchPayloadItem(termParts[0].trim(), termParts[1].trim()));
                }
            }
        }
    }

    hasKey(key: string): boolean {
        const item = this.items.find(x => x.key === key.trim());
        return !!item;
    }

    getValue(key: string): string {
        const item = this.items.find(x => x.key === key.trim());
        if (!!item) {
            return item.value;
        } else {
            return "";
        }
    }
}


