import "../../../../scss/components/_list-header.scss";
import "../../../../scss/pages/admin/_admin-clients.scss";

import {
  Button,
  DialogActionButton,
  RadioListItemGroup,
  saveCallbackStatus,
} from "@finpay-development/shared-components";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";

import { RootState } from "../../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../../shared/calculators";
import { FullPaymentPfrListHeader } from "../../../../shared/components/full-payment-pfr-list-header";
import * as Yup from "yup";
import { paymentProgramV2 } from "../../../../shared/validation/schemas";

export interface deletePatientPaymentProgramModel {
  patientId: number;
  encounterId: number;
  paymentProgramId: number;
}
interface PaymentProgramsModalProps {
  open: boolean;
  handleModalClose: () => void;
  isEdit: boolean;
}

export function PaymentProgramsV2Modal(props: PaymentProgramsModalProps) {
  const { open, handleModalClose } = props;

  const stateFields = {
    isSaving: useSelector((state: RootState) => {
      return state?.patientContext?.isLoading?.paymentProgram;
    }),
  };

  const {
    isSaving,
  } = stateFields;

  function handleSavePaymentProgramsV2() {
    handleModalClose();
  }

  const header = (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Total Patient Financial Responsibility (PFR)
          </Typography>
        </Grid>
        <Box
          justifyContent="flex-end"
          className="pr-1 mt-2"
          style={{ marginTop: "16px" }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {formatNumberToUSD(10000)}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={8}>
          <Typography variant="subtitle2">PFR taken so far</Typography>
        </Grid>
        <Box
          justifyContent="flex-end"
          className="pr-1 mt-2"
          style={{ marginTop: "16px" }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">{formatNumberToUSD(0)}</Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Remaining PFR for Payment Program
          </Typography>
        </Grid>
        <Box
          justifyContent="flex-end"
          className="pr-1 mt-2"
          style={{ marginTop: "16px" }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {formatNumberToUSD(10000)}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </>
  );

  const listData: any = [
    {
      id: 0,
      label: "5%",
      value: "5%",
      secondaryLabel: "$10,000.00",
      selectColor: "error",
      tertiaryLabel: "$10,000.00",
    },
  ];

  const getInitialValues = () => {
    return {
      remainingPfr: "",
      balance: "",
      discount: "",
    };
  };

  const validationSchema = Yup.object(paymentProgramV2);

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Payment Program</span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <div className="px-2 py-2 w-100">
            <Formik
              initialValues={getInitialValues()}
              validationSchema={validationSchema}
              onSubmit={() => {}}
            >
              {(formik) => (
                <Form>
                  <Grid container spacing={2} className="list-header">
                    <Grid xs={12} item>
                      {header}
                    </Grid>
                    <Grid xs={12} item>
                      <Typography variant="subtitle2">
                        Full Payment Option
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      {<FullPaymentPfrListHeader />}
                      <RadioListItemGroup
                        data={listData}
                        name="customTerm"
                        direction="column"
                        formik={formik}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Box display="flex" justifyContent="center">
                        <Button type="secondary">Select Full Payment</Button>
                      </Box>
                    </Grid>
                    <Grid xs={6} item>
                      <Box display="flex" justifyContent="center">
                        <Button type="secondary">View Programs</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={!isSaving}
          savebuttonText="Save"
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSavePaymentProgramsV2}
          handleCallbackCancel={handleModalClose}
          showDeleteButton={false} // Always hide delete button for now. Future use.
        />
      </DialogActions>
    </Dialog>
  );
}
