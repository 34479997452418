export interface ErrorState {
  patientTransactions: boolean;
  paymentMethods: boolean;
  accountHolderDashboard: boolean;
  convertedPatients: boolean;
  convert: boolean;
  patientEncounter: boolean;
  hellosign: boolean;
  missingAddress: boolean;
  paymentProgramNeedsUpdate: boolean;
  releaseCharge: boolean;
}

export const emptyErrorState: ErrorState = {
  patientTransactions: false,
  paymentMethods: false,
  accountHolderDashboard: false,
  convertedPatients: false,
  convert: false,
  patientEncounter: false,
  hellosign: false,
  missingAddress: false,
  paymentProgramNeedsUpdate: false,
  releaseCharge: false
}
