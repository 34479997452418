export interface TabComplete {
    entitySettings: boolean;
    facilities: boolean;
    rulesEngine: boolean;
    admissionsAdvisor: boolean;
    pesScript: boolean;
    authDocs: boolean;
    crm: boolean;
}

export const emptyTabComplete: TabComplete = {
    entitySettings: false,
    facilities: false,
    rulesEngine: false,
    admissionsAdvisor: false,
    pesScript: false,
    authDocs: false,
    crm: false
}