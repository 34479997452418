import { createAsyncThunk } from "@reduxjs/toolkit";
import { showErrorStatus } from "../../../security/state/user-slice";
import { RootState } from "../../../shared/state/root-reducer";
import { Utils } from "../../../shared/utils";
import { ImplementationPESScript } from "../../components/implementation-clients/details/models/implementation-pes-script";
import { PESScriptForm } from "../../models/pes-script-form";
import { pesScriptService } from "../../services/implementation-pes-script-service";
import _ from "lodash";
import {
  addPesScriptWindowObjectReference,
  resetPesScriptWindowObjectReference,
} from "./implementation-pesscript-slice";
export const savePESScript = createAsyncThunk(
  "implementationPESScriptContext/savePESScript",
  async (data: { clientId: number; facilityId?: number | null; formValues: PESScriptForm, existingRiskClasses: Set<number> }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const fullClientPESScript = Utils.deepClone(
      state.pesScriptContext.implementationPesScript.apiResponsePESScript
    );
    const allClientPESScripts = Utils.deepClone(
      state?.pesScriptContext?.implementationPesScript?.pesScripts
    );

    const response = await pesScriptService.savePESScript(
      data.clientId,
      data.facilityId,
      data.formValues,
      fullClientPESScript,
      allClientPESScripts,
      data.existingRiskClasses,
    );
    if (response?.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }

    return response?.entity;
  }
);

export const getClientPESScripts = createAsyncThunk(
  "implementationPESScriptContext/getClientPESScripts",
  async (data:{clientId: number, facilityId: number | null}, thunkAPI) => {
    const response = await pesScriptService.getClientPESScripts(data.clientId,data.facilityId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
  }
);

export const getClientPESScript = createAsyncThunk(
  "implementationPESScriptContext/getClientPESScript",
  async (data: { pesScriptId: number }, thunkAPI) => {
    const response = await pesScriptService.getClientPESScript(
      data.pesScriptId
    );
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
  }
);

export const getPesScriptDocument = createAsyncThunk(
  "implementationPESScriptContext/PESScript",
  async (pesScript: ImplementationPESScript, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (
      state.pesScriptContext.implementationPesScript
        .pesScriptWindowObjectReference.length > 0
    ) {
      state.pesScriptContext.implementationPesScript.pesScriptWindowObjectReference[0].close();
      thunkAPI.dispatch(resetPesScriptWindowObjectReference());
    }

    if (!_.isEmpty(pesScript)) {
      if (!pesScript) {
        thunkAPI.dispatch(showErrorStatus("No Script Document to Show"));
      } else {
        const pesScriptWindow = window.open(
          pesScript?.scriptUrl,
          "",
          "width=800, height=800, menubar=0, toolbar=0"
        );
        thunkAPI.dispatch(addPesScriptWindowObjectReference(pesScriptWindow));
      }
    }
  }
);
