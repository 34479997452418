import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import { Grid, Typography, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../shared/state/root-reducer';
import { getPatientDemographics, getPatientMasterLedger } from '../../../patient/state/patient-thunk';
import { AppDispatch } from '../../../shared/state/store';
import { getClient } from '../../../implementation-specialist/state/clients/implementation-clients-thunk';
import { MasterPatientLedger } from '../../components/models/payment';
import { RolePageNames } from '../../../security/model/role-page-names';
import { checkPermissions } from '../../../security/components/access-control';
import { patientLedgerErrorMessages } from "../../../shared/enums";
import MasterLedgerGrid from './master-ledger-grid';

interface ClientPatientInfo {
  clientId: number; 
  patientId: number; 
}

function MasterPatientView() {
  const [patientLedger, setPatientLedger] = useState<MasterPatientLedger[]>([]);
  const [facilitiesList, setFacilitiesList] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ id?: string, cid?: string }>();
  const ledgerCallComplete = useRef(false);

  const stateFields = {
    isLoadingState: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.isLoading
    ),
    selectedPatient: useSelector(
      (state: RootState) =>
        state.patientContext.selectedPatient
    ),
    client: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
    ),
    userProfile: useSelector(
      (state: RootState) =>
        state.userContext.userProfile
    ),
    errorMessage: useSelector(
      (state: RootState) =>
      state.patientContext.errorMessage
    ),
  }

  const { isLoadingState, selectedPatient, client, userProfile, errorMessage } = stateFields;
  const [isLoadingLedger, setIsLoadingLedger] = useState(true);
  const roleAccess = checkPermissions(userProfile.userRole.userRolePages, RolePageNames.PatientRecords, "Patient Ledger", false)
  const patientId = parseInt(params.id || '') || 0;
  const clientId = parseInt(params.cid || '') || 0;
  
  useEffect(() => {
      dispatch(getPatientDemographics(patientId));
      dispatch(getClient(clientId));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, patientId])

  useEffect(() => {
    if(clientId > 0 && selectedPatient.patientId > 0 && !ledgerCallComplete.current) {
      ledgerCallComplete.current = true;
      const clientPatientInfo : ClientPatientInfo = {
        clientId: clientId,
        patientId: patientId
      }
      getPatientLedger(clientPatientInfo)
      const uniqueFacilityNames = [...new Set(selectedPatient.patientEncounters.map(encounter => encounter.facilityName))].join(', ');
      setFacilitiesList(uniqueFacilityNames)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient, ledgerCallComplete])

  const getPatientLedger = async (clientPatientIds : ClientPatientInfo) => {
    const response : any = await dispatch(getPatientMasterLedger(clientPatientIds));
    setPatientLedger(response.payload.entity)
    setIsLoadingLedger(false);
  }

  const formatAddress = (address: any) => {
    const { streetAddress1, streetAddress2, city, state, zipCode } = address;
    const formattedAddress = (
      <>
        Address: <br />
        {streetAddress1} <br />
        {streetAddress2 && <>{streetAddress2} <br /></>} 
        {city && <>{city}, </>}
        {state?.stateName && <>{state.stateName}, </>}
        {zipCode}
      </>
    );
    return formattedAddress;
  };

  const renderPatientInfo = (contact: any) => {
    return (
      <>
        <strong style={{ textTransform: 'capitalize' }}>
          {contact.firstName}{" "}{contact.lastName}
        </strong>
        {contact.birthDate && <><br /> DOB: {contact.birthDate}</>}
        {contact.email && <><br /> Email: {contact.email}</>}
      </>
    );
  };

  return (
    <Grid>
      {roleAccess && !isLoadingState &&
        <main className="patient-details">
          <Box className="content extra-padding">
            <Grid sx={{ marginTop: '2rem', marginBottom: '2rem', display: 'flex' }}>
              <Grid item xs={10}>
                <Typography variant="h3" className="pt-0_5" style={{ display: 'flex', alignContent: 'center', paddingLeft: '6px', fontSize: '2rem' }}>
                  Master Patient Ledger
                </Typography>
              </Grid>
            </Grid>
            <Box
              border={1}
              borderColor="primary.main"
              borderRadius="5px"
              className="mb-5"
              sx={{ marginTop: '1rem', marginBottom: '4rem !important'  }}
            >
              <Box style={{ margin: "0.5em", padding: '12px' }}>
                {selectedPatient.patientId > 0 && !isLoadingState ?
                  <Grid container spacing={2} direction="row">
                    <Grid item xs={4}>
                      {selectedPatient?.contact && renderPatientInfo(selectedPatient?.contact)}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography style={{textTransform: 'capitalize'}}>
                        {selectedPatient?.contact?.primaryAddress && formatAddress(selectedPatient.contact.primaryAddress)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {client.clientName ? <>{client.clientName}<br /></> : ''}
                      {facilitiesList}
                    </Grid>
                  </Grid>
                :
                <Typography>{patientLedgerErrorMessages.patientError}</Typography>
                }
              </Box>
            </Box>
            {(!isLoadingState && client.clientName && selectedPatient.patientId > 0) &&
              <Grid container spacing={2}>
                <MasterLedgerGrid patientLedgerData={patientLedger} loadStatus={isLoadingLedger} />
              </Grid> 
            }
            { !client.clientName && !isLoadingState && !isLoadingLedger &&
              <Typography>{patientLedgerErrorMessages.clientError}</Typography>
            }
          </Box>
        </main>
      }
      {!roleAccess && !isLoadingState && !isLoadingLedger &&
        <Typography className="access-error">
          <span className="message">
            <LockIcon style={{paddingRight: '8px', fontSize: '1.6em'}} />
            {patientLedgerErrorMessages.authentication}
          </span>
        </Typography>
      }
      { isLoadingLedger || isLoadingState && <LoadingOverlay />}
    </Grid>
  );
}
export default MasterPatientView;
