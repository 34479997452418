import {FC, memo} from 'react';
import { Status, Button } from '@finpay-development/shared-components';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import HeaderWithDetails from '../../../../shared/components/header-with-details';
import { StatusColors, LoginVerifiedStatus } from "../../../../shared/enums";
import { PatientEncounter, VerifiedStatuses } from '../../models/patient-encounter';
import { userService } from 'src/admin/services/admin-user-service';
import { showErrorStatus, showStatus } from 'src/security/state/user-slice';
import { AppDispatch } from 'src/shared/state/store';
import _ from 'lodash/fp';

interface AccountStatusAccordianProps {
  encounter: PatientEncounter;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  isPatient?: boolean | null;
  userId?: string | null;
  dispatch?: AppDispatch;
}

interface DetailProps {
  status: EmailVerificationStatus;
  loginVerified: VerifiedStatuses 
  patientEncounterId: number;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
  isPatient?: boolean | null;
  userId?: string | null;
  dispatch?: AppDispatch;
}


interface EmailVerificationStatus {
  color: StatusColors,
  text: string
}

const Details: FC<DetailProps> = ({ status, loginVerified, patientEncounterId, firstName, lastName, userName, isPatient, userId }) => {

  const dispatch = useDispatch<AppDispatch>();

  if (loginVerified === null || loginVerified === undefined) {
    return (<Status
      typographyVariant="h3"
      statusColor={status.color}
      text={status.text}
    />)
  }

  const handleResendClick = async () => {
    if (userId) {
      const result = await userService.sendVerificationEmail(userId, patientEncounterId)
      if (result.hasErrors) {
          dispatch(showErrorStatus(`Email failed to send. Please resend`))

      }else{
          dispatch(showStatus('Verification email sent.'))
      }
    }
  }

  const accountHolderType = isPatient ? 'patient' : 'guarantor';
  const accountHolderHeaderContent = `${firstName} ${lastName} (${accountHolderType}): ${userName}`
  const accountHolderHeader = (firstName && lastName && userName && accountHolderType) ? <h4 style={{ marginTop: '10px' }}>{accountHolderHeaderContent}</h4> : null
  const button = loginVerified === LoginVerifiedStatus.pending && userId ? <Button style={{ marginTop: '20px', marginLeft: '30px' }} onClick={handleResendClick}>Re-send</Button> : null;

  return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Status
            typographyVariant="h3"
            statusColor={status.color}
            text={status.text}
          />
        {accountHolderHeader}
        </div>
        <div>
        {button}
        </div>
      </div>
  )
}

const DetailsCached = memo(Details, (prevProps, nextProps)=>{
  return _.isEqual(prevProps, nextProps);
})

const AccountStatusAccordian: FC<AccountStatusAccordianProps> = ({ encounter, firstName, lastName, userName, isPatient, userId }) => {

  let status; 
    switch (encounter.loginVerified) {
      case LoginVerifiedStatus.pending:
        status = { text: "Pending", color: StatusColors.warning };
        break;
      case LoginVerifiedStatus.verified:
        status = { text: "Verified", color: StatusColors.success };
        break;
      case null:
      default:
        status = { text: "No Champion Selection Made", color: StatusColors.gray };
    }

  return (
    <div className="w-100">
      <Grid container justifyContent="space-between">
        <HeaderWithDetails
          header=""
          width="full"
          details={<DetailsCached status={status} loginVerified={encounter.loginVerified} patientEncounterId={encounter.patientEncounterId} firstName={firstName} lastName={lastName} userName={userName} isPatient={isPatient} userId={userId}/>
          }
        />

      </Grid>
    </div>
  )

}

export default AccountStatusAccordian;
