import {createAsyncThunk} from '@reduxjs/toolkit';
import moment from 'moment';
import {
  Filters,
} from '../../../implementation-specialist/models/reportsFilters';
import {patientHelper} from '../../../patient/services/patient-service';
import {clientService} from '../../../security/services/client-service';
import {
  showErrorStatus,
  updateApplicationStatus,
} from '../../../security/state/user-slice';
import {ApiBatchLimits, reportsType} from '../../../shared/enums';
import {
  allInstanceOfCareFilters,
  automatedPaymentFilters,
  mergedTransactionsFilters,
  patientFinancialClearenceFilters,
  pesActivityLogReportFilters,
  riskClassReportFilters,
  settlementDetailFilters,
  settlementSummaryV2Filters,
  summaryTransactionsFilters,
  transactionFilters,
} from '../../../shared/Mocks/reportsFilters';
import {getReportsName} from '../../../shared/Mocks/reportsType';
import {AxiosResultStatus} from '../../../shared/service/axios-result-status';
import {RootState} from '../../../shared/state/root-reducer';
import {Utils} from '../../../shared/utils';
import {AdminUserState} from '../../models/admin-user-state';
import {FilteredUserInfo} from '../../models/filtered-user-info';
import {emptyReportInfo, ReportsInfo} from '../../models/reports-info';
import {UserInfo} from '../../models/user-info';
import {UserInfoClient} from '../../models/user-info-client';
import {adminRoleService} from '../../services/admin-role-services';
import {adminUserHelper, userService} from '../../services/admin-user-service';

export const handleGetUsers = createAsyncThunk("handleGetUsers", async (data:void, thunkAPI) => {
  let offset = 0;
  const limit = ApiBatchLimits.users;

  let response: AxiosResultStatus;
    let adminUserState = new AdminUserState()
    adminUserState.allUsers = []
    adminUserState.clientsFilter = []
    adminUserState.rolesFilter = []
    adminUserState.filteredUsers = []
    const allUsers = [];
    do {
      response = await userService.getAllUsers(offset, limit, {});
      if (response.hasErrors) {
        thunkAPI.dispatch(showErrorStatus(response.errorMessage))
        throw new Error(response.errorMessage);
      }
      allUsers.push(...response.entity);

      offset = offset + limit;
    } while (response.entity.length === limit);

    adminUserState.allUsers = allUsers;
    adminUserState.filteredUsers = adminUserHelper.filteredSearchResults(allUsers);
    const { clients, roles } = adminUserHelper.getUsersFilterData(allUsers);

    adminUserState.clientsFilter = clients ? clients : [];
    adminUserState.rolesFilter = roles ? roles : []

    let formatStringValue = (value:string | any) =>{
      if(value !== null){
        value = value?.trimStart()
        value = value?.charAt(0)?.toLocaleUpperCase() + value?.slice(1)
        return value
      }
    }

    adminUserState.clientsFilter.sort(function(clientA: UserInfoClient, clientB: UserInfoClient){
      if(formatStringValue(clientA.clientName) < formatStringValue(clientB.clientName)) return -1;
      if(formatStringValue(clientA.clientName) > formatStringValue(clientB.clientName)) return 1;
      return 0;
    })
    return adminUserState;
});

export const handleGetLogs = createAsyncThunk("handleGetLogs", async () => {
  return await userService.getLogs();
});

export const handleGetReports = createAsyncThunk("handleGetReports", async () => {
  return await userService.getReports();
});

export const handleGetFilteredReports = createAsyncThunk("handleGetFilteredReports", async (reportsFilters: Filters,thunkAPI) => {
  let offset = 0;
  let limit = ApiBatchLimits.reports;
  let reportTypeValues: Array<Object> = []
  let response: AxiosResultStatus;
  let reportFiltered:ReportsInfo = emptyReportInfo;
  let data: string[][] = []
  if (reportsFilters.startDate) {
    const startDate = moment(reportsFilters.startDate).startOf('day').utc();
    reportsFilters.startDate = startDate.toISOString();
  }
  if (reportsFilters.endDate) {
    const endDate = moment(reportsFilters.endDate).endOf('day').utc();
    reportsFilters.endDate = endDate.toISOString();
  }
  if (reportsFilters.admissionDate) {
    const admissionDate = new Date(reportsFilters.admissionDate || '').setUTCHours(23,59,59,999);
    reportsFilters.admissionDate = new Date(admissionDate || '').toISOString();
  }
  if (reportsFilters.dischargeDate) {
    const dischargeDate = new Date(reportsFilters.dischargeDate || '').setUTCHours(23,59,59,999);
    reportsFilters.dischargeDate = new Date(dischargeDate || '').toISOString();
  }

  let reportTypeKey = '';

  switch (reportsFilters.reportType) {
    case reportsType.AllInstanceofCare:
      reportTypeKey = 'AllInstanceofCare';
      reportTypeValues = allInstanceOfCareFilters
      limit = ApiBatchLimits.allInstanceofCareReports
      break;

    case reportsType.PatientFinancialClearance:
      reportTypeKey = 'PatientFinancialClearance';
      reportTypeValues = patientFinancialClearenceFilters
      break;

    case reportsType.SettlementDetail:
      reportTypeKey = 'SettlementDetail';
      reportTypeValues = settlementDetailFilters
      break;

    case reportsType.RiskClassReports:
      reportTypeKey = 'RiskClassReports';
      reportTypeValues = riskClassReportFilters
      break;

    case reportsType.PESActivityLog:
      reportTypeKey = 'PESActivityLog';
      reportTypeValues = pesActivityLogReportFilters
      limit = ApiBatchLimits.pesActivityLogReports;
      break;

    case reportsType.SummaryTransactions:
      reportTypeKey = 'SummaryTransactions';
      reportTypeValues = summaryTransactionsFilters
      break;

    case reportsType.MergedTransactions:
      reportTypeKey = 'MergedTransactions';
      reportTypeValues = mergedTransactionsFilters
      limit = ApiBatchLimits.mergedTransactionsReports;
      break;

    case reportsType.AutomatedPayment:
      reportTypeKey = 'AutomatedPayment';
      reportTypeValues = automatedPaymentFilters;
      limit = ApiBatchLimits.automatedPaymentReports;
      break;

    case reportsType.SettlementSummary:
      reportTypeKey = 'SettlementSummary';
      reportTypeValues = settlementSummaryV2Filters;
      limit = ApiBatchLimits.settlementSummaryReports;
      break;

    default:
      break;
  }

  reportsFilters.reportType = reportTypeKey;
  const reportName = getReportsName(reportsFilters.reportType)

  do {
    response = await userService.getReportsInfo(reportsFilters, offset, limit);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    reportFiltered = response.entity
    data = [...data, ...response.entity.data]
    offset = offset + limit;
    //need to set >= as opposed to === because for PES activity log there are multiple report entries per one query result
  } while (response.entity.data.length >= limit);

  reportFiltered.data = data
  reportFiltered.isInitialCall = true

  if (reportFiltered.data.length === 0){
    thunkAPI.dispatch(
      showErrorStatus(
        `No ${reportName?.toLowerCase()?.includes('report') ? reportName : reportName + ' reports'} found for filter criteria entered`
      )
    )
  }

  return {reportFiltered,reportTypeValues}
});

export const handleGetDetailedFilteredReports = createAsyncThunk("handleGetDetailedFilteredReports", async (detailedReportFilters: any,thunkAPI) => {
  let offset = 0;
  const limit = ApiBatchLimits.reports;
  let response: AxiosResultStatus;
  let reportFiltered = emptyReportInfo;
  let data: string[][] = []
  detailedReportFilters.startDate= new Date(detailedReportFilters.startDate||'').toISOString()
  const endDate = new Date(detailedReportFilters.endDate || '').setUTCHours(23,59,59,999);
  detailedReportFilters.endDate= new Date(endDate || '').toISOString();

  if (detailedReportFilters.admissionDate) {
    const admissionDate = new Date(detailedReportFilters.admissionDate || '').setUTCHours(23,59,59,999);
    detailedReportFilters.admissionDate = new Date(admissionDate || '').toISOString();
  }

  if (detailedReportFilters.dischargeDate) {
    const dischargeDate = new Date(detailedReportFilters.dischargeDate || '').setUTCHours(23,59,59,999);
    detailedReportFilters.dischargeDate = new Date(dischargeDate || '').toISOString();
  }

  let reportType

  for (const [key, value] of Object.entries(reportsType)) {
    if(value === detailedReportFilters.reportType){
      reportType = key
    }
  }
  detailedReportFilters.reportType = reportType
  const reportName = getReportsName(detailedReportFilters.reportType)

  do {
    response = await userService.getReportsInfo(detailedReportFilters, offset, limit);

    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }

    reportFiltered = response.entity
    data = [...data, ...response.entity.data]
    offset = offset + limit;
  } while (response.entity.data.length === limit);

  reportFiltered.data = data
  reportFiltered.isInitialCall = false;

  if (reportFiltered.data.length === 0){
    thunkAPI.dispatch(
      showErrorStatus(
        `No ${reportName?.toLowerCase()?.includes('report') ? reportName : reportName + ' reports'} found for filter criteria entered`
        )
      )
  }

   return reportFiltered
});

export const handleGetDetailedSettlementReport = createAsyncThunk("handleGetDetailedSettlementReport", async (detailedReportFilters: any,thunkAPI) => {
  let offset = 0;
  const limit = ApiBatchLimits.reports;
  let response: AxiosResultStatus;
  let reportFiltered = emptyReportInfo;
  let data: string[][] = []
  detailedReportFilters.startDate= new Date(detailedReportFilters.startDate||'').toISOString()
  const endDate = new Date(detailedReportFilters.endDate || '').setUTCHours(23,59,59,999);
  detailedReportFilters.endDate= new Date(endDate || '').toISOString();
  let reportType

  for (const [key, value] of Object.entries(reportsType)) {
    if(value === detailedReportFilters.reportType){
      reportType = key
    }
  }
  detailedReportFilters.reportType = reportType
  const reportName = getReportsName(detailedReportFilters.reportType)

  do {
    response = await userService.getReportsInfo(detailedReportFilters, offset, limit);
    if (response.hasErrors) {
      patientHelper.robustErrorHandler(response, thunkAPI);
    }
    reportFiltered = response.entity
    data = [...data, ...response.entity.data]
    offset = offset + limit;
  } while (response.entity.data.length === limit);

  reportFiltered.data = data
  reportFiltered.isInitialCall = false;

  if (reportFiltered.data.length === 0){
    thunkAPI.dispatch(
      showErrorStatus(
        `No ${reportName?.toLowerCase()?.includes('report') ? reportName : reportName + ' reports'} found for filter criteria entered`
        )
      )
  }

   return reportFiltered
});

export const getUser = createAsyncThunk("getUser",
  async (userId: number) => {
    return await userService.getUser(userId);
});

export const deActivateUser = createAsyncThunk("dectivateUser",
  async (userId: number, thunkAPI) => {
    const response = await userService.deactivateUser(userId);
    if(response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage))
      throw new Error(response.errorMessage);
    }
   const state = thunkAPI.getState() as RootState;
   const allUsers: UserInfo[] = Utils.deepClone(state.adminContext.adminUserContext.userSearch.allUsers)
   const filteredUsers: FilteredUserInfo[] = Utils.deepClone(state.adminContext.adminUserContext.filteredUsers)

   allUsers.map(user=>{
     if(user.userId === userId) {
       user.isActive = false
       return user
     }
     return user
   })
   filteredUsers.map(user => {
     if(user.userId === userId){
       user.status = "Inactive"
       return user
     }
     return user
   })
    return {allUsers,filteredUsers};
});

export const reActivateUser = createAsyncThunk("reactivateUser",
  async (userId: number, thunkAPI) => {
    const response = await userService.reactivateUser(userId);
    if(response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage))
      throw new Error(response.errorMessage);
    }
   const state = thunkAPI.getState() as RootState;
   const allUsers: UserInfo[] = Utils.deepClone(state.adminContext.adminUserContext.userSearch.allUsers)
   const filteredUsers: FilteredUserInfo[] = Utils.deepClone(state.adminContext.adminUserContext.filteredUsers)

   allUsers.forEach(user=>{
     if(user.userId === userId) {
       user.isActive = true
       return user
     }
     return user
   })
   filteredUsers.forEach(user => {
     if(user.userId === userId){
       user.status = "Active"
       return user
     }
     return user
   })
    return { allUsers, filteredUsers };
});

export const getUserCognitoStatus = createAsyncThunk("getUserCognitoStatus", async (userId: number) => {
  const response = await userService.getUserCognitoStatus(userId);
  if (response.hasErrors) {
    updateApplicationStatus(response.errorMessage)
  }
  return response.entity;
})

// for checkbox list of clients in add/edit User modal
export const handleGetClients = createAsyncThunk(
  "handleGetClients",
  async () => {
    return await clientService.getClients();
  }
);

export const getPaymentChannels = createAsyncThunk(
  "getPaymentChannels",
  async () => {
    return await userService.getPaymentChannels();
  }
)

export const filterUsers = createAsyncThunk(
  "filterUsers",
  async (data: void, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const filter = state.adminContext.adminUserContext.currentFilterUserValues;
    const allUsers = state.adminContext.adminUserContext.userSearch.allUsers;
    return userService.filterUsers(filter, allUsers);
  }
);

export const filterLogs = createAsyncThunk(
  "filterLogs",
  async (data: void, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const filter = state.adminContext.adminUserContext.currentFilterLogsValues;
    const allPatients = state.adminContext.adminUserContext.logsSearch.allLogs;
    return userService.filterLogs(filter, allPatients);
  }
);

export const filteReports = createAsyncThunk(
  "filteReports",
  async (data: void, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const filter = state.adminContext.adminUserContext.currentFilterLogsValues;
    const allPatients = state.adminContext.adminUserContext.logsSearch.allLogs;
    return userService.filterLogs(filter, allPatients);
  }
);

export const saveUser = createAsyncThunk("saveUser", async (data: UserInfo, thunkAPI) => {

  // Get Required Meta Data for Clients and User Roles
  const state = thunkAPI.getState() as RootState;
  const userRoles = await adminRoleService.getUserRolesFromCache();

  // Save the User
  const fullUser = state.adminContext.adminUserContext.fullUser;
  const savedUserStatus = await userService.saveUser(fullUser, userRoles, data);

  // Update Cached User Search Results with New/Update user
  const allUsers = state.adminContext.adminUserContext.userSearch.allUsers;
  const userInfo: UserInfo = {...savedUserStatus.entity};

  if (savedUserStatus?.hasErrors) {
    if (savedUserStatus?.statusCode === 400 && typeof savedUserStatus?.entity === 'string') {
      throw new Error(savedUserStatus?.entity);
    } else {
      throw new Error(savedUserStatus?.errorMessage);
    }
  }
  else {
    let newUserList: UserInfo[] = [...allUsers];
    let userToUpdateIndex = newUserList.findIndex((e: UserInfo ) => e.userId === userInfo.userId);

    if (userToUpdateIndex  > -1) {
      newUserList[userToUpdateIndex] = userInfo;
      } else {
      newUserList.push(userInfo);
    }

    return newUserList;
  }
});
