import { GuestModel, PaymentInfo } from "../models/anonymous-guest";
import { PayNowMakePaymentRequest } from "../models/pay-now-make-payment";



export function createPayNowMakePaymentRequest(facilityId: string, guestInfo: GuestModel, paymentInfo: PaymentInfo): PayNowMakePaymentRequest {
    const paymentRequest = {} as PayNowMakePaymentRequest;

    paymentRequest.clientFacilityId = guestInfo.clientFacilityId ? guestInfo.clientFacilityId : parseInt(facilityId);
    paymentRequest.externalPaymentMethodId = 'card';
    paymentRequest.externalTokenPaymentId = paymentInfo.stripeToken ? paymentInfo.stripeToken : null;
    paymentRequest.paymentAmt = parseFloat(paymentInfo.paymentAmount as string);
    paymentRequest.receiptEmail = paymentInfo.payerEmail;
    paymentRequest.nameOnCard = paymentInfo.nameOnCard;
    paymentRequest.clientPatientBillingId = guestInfo.accountNumber ? guestInfo.accountNumber : null;

    paymentRequest.patientFirstName = guestInfo.firstName;
    paymentRequest.patientMiddleName = !!guestInfo.middleInitial ? guestInfo.middleInitial : null;
    paymentRequest.patientLastName = guestInfo.lastName;
    paymentRequest.patientEmail = guestInfo.email;
    paymentRequest.patientPrimaryPhoneNum = !!guestInfo.cellPhone ? guestInfo.cellPhone : null;

    paymentRequest.billingAddress1 = guestInfo.billingAddress1;
    paymentRequest.billingAddress2 = !!guestInfo.billingAddress2 ? guestInfo.billingAddress2 : null;
    paymentRequest.billingCity = guestInfo.billingCity;
    paymentRequest.billingStateId = parseInt(guestInfo.billingState);
    paymentRequest.billingZipCode = guestInfo.billingZip;

    paymentRequest.clientsPatientAccountId = guestInfo.clientsPatientAccountId ? guestInfo.clientsPatientAccountId : null;
    paymentRequest.clientsPatientIOCId = guestInfo.clientsPatientIOCId ? guestInfo.clientsPatientIOCId : null;
    paymentRequest.paymentChannelId = guestInfo.paymentChannelId ? guestInfo.paymentChannelId : null;
    paymentRequest.extClientFacilityId = guestInfo.extClientFacilityId ? guestInfo.extClientFacilityId :  null;

    paymentRequest.userName = guestInfo.userName ? guestInfo.userName :  undefined;
    paymentRequest.paymentReason = guestInfo.paymentReason ? guestInfo.paymentReason :  undefined;  

    if (guestInfo.isPatientAddrSameAsBilling) {
        paymentRequest.patientAddress1 = guestInfo.billingAddress1;
        paymentRequest.patientAddress2 = guestInfo.billingAddress2;
        paymentRequest.patientCity = guestInfo.billingCity;
        paymentRequest.patientStateId = parseInt(guestInfo.billingState);
        paymentRequest.patientZipCode = guestInfo.billingZip;
        paymentRequest.billingFirstName = guestInfo.firstName;
        paymentRequest.billingMiddleName = !guestInfo.middleInitial ? null : guestInfo.middleInitial;
        paymentRequest.billingLastName = guestInfo.lastName;
        paymentRequest.billingEmail = guestInfo.email;
    } else {
        paymentRequest.patientAddress1 = guestInfo.patientAddress1;
        paymentRequest.patientAddress2 = !!guestInfo.patientAddress2 ? guestInfo.patientAddress2 : null;
        paymentRequest.patientCity = guestInfo.patientCity;
        paymentRequest.patientStateId = parseInt(guestInfo.patientState);
        paymentRequest.patientZipCode = guestInfo.patientZip;
        const nameParts = parseNameString(paymentInfo.nameOnCard);
        paymentRequest.billingFirstName = !!nameParts[0] ? nameParts[0] : null;
        paymentRequest.billingMiddleName = !!nameParts[1] ? nameParts[1] : null;
        paymentRequest.billingLastName = !!nameParts[2] ? nameParts[2] : null;
        paymentRequest.billingEmail = paymentInfo.payerEmail;
    }

    paymentRequest.paymentMethodTypeId = 1;
    paymentRequest.isManual = true;
    paymentRequest.isAuthExpired = false;

    return paymentRequest;
}

export function parseNameString(nameString: string): string[] {
    const nameParts = Array(3).fill('');
    let tempParts = nameString.split(' ');
    tempParts = tempParts.filter(x => x !== '');
    if (tempParts.length === 3) {
        nameParts[0] = tempParts[0];
        nameParts[1] = tempParts[1];
        nameParts[2] = tempParts[2];
    } else if (tempParts.length === 2) {
        nameParts[0] = tempParts[0];
        nameParts[2] = tempParts[1];
    } else {
        nameParts[0] = nameString;
    }
    return nameParts;
}