import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { AxiosResultStatus } from "../../shared/service/axios-result-status";
import { axiosSaveHelper } from "../../shared/service/axios-save-helper";
import { AxiosSavePayload } from "../../shared/service/axios-save-payload";
import { AxiosDeletePayload } from "../../shared/service/axios-delete-payload";
import { axiosDeleteHelper } from "../../shared/service/axios-delete-helper";
import { getState } from "../../shared/misc/us-states";
import { Address } from "../../shared/model/address";
import {
  ClientContactForm,
  ClientDeficiencyNotification,
  ClientEntityForm,
  ClientForm,
  emptyClientDeficiencyNotification,
} from "../models/client";
import { ClientStatusCardViewModel } from "../../shared/model/client-status-card";
import { client, ClientBusinessRules } from "../../shared/model/client";
import { PrimaryContact } from "../../shared/model/primary-contact";
import { Utils } from "../../shared/utils";
import { FacilityRiskThreshold, PayerClaimRate } from "../../admissions-advisor/models/risk-assessment";
import {
  ClientReferralSource,
  FacilityLOCReferralSource,
  FacilityLOCReferralSourceForm,
  FacilityReferralSourceModel,
  SelectedFacilityReferralSource,
} from "../models/referral-source";
import { EstLevelOfCare } from "../../admissions-advisor/models/estimator";
import {ClientPaymentTransactionFee} from '../models/payment-transaction-fee';
import { getClientRiskAssessmentConfigs } from "../state/clients/implementation-clients-thunk";
import { ClientRiskAssessmentPostBody } from "src/shared/model/client-risk-assessment-config";


export const ImplementationClientHelper = {
  removeNotFoundClients(
    clientsFromCookie: number[] | undefined,
    clientsFromState: ClientStatusCardViewModel[]
  ) {
    return clientsFromCookie?.filter((clientId) => {
      let returnClient;
      clientsFromState.forEach((client) => {
        if (clientId === client.clientId) {
          returnClient = client;
        }
      });
      return returnClient;
    });
  },
};

export class SpecialistClientService {
  async getClients(includeFacility: boolean): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client?includeFacility=${includeFacility}`,
    };
    const responseData = await axiosReadHelper(readPayload);

    let allClients: ClientStatusCardViewModel[] = responseData.entity;

    // Always ignore ClientId 0
    allClients = allClients.filter((client) => client.clientId !== 0);

    responseData.entity = this.filterClients(allClients);
    return responseData;
  }

  async getClientKPIs(
    clientId: number,
    months: number
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url:
        months === 1
          ? `client/${clientId}/kpi`
          : `client/${clientId}/kpi?months=${months}`,
    };
    return await axiosReadHelper(payload);
  }

  async getClient(clientId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client/${clientId}/detail`,
    };

    const responseData = await axiosReadHelper(readPayload);

    if (!responseData.hasErrors) {
      let client = responseData.entity;

      const clientEntityDetails: ClientForm =
        this.mapToClientEntityDetails(client);
      const clientBusinessRules: ClientBusinessRules =
        this.mapToClientBusinessRules(client);

      responseData.entity = {
        clientEntityDetails,
        clientBusinessRules,
        fullClient: client,
      };
    }
    return responseData;
  }

  async addClient(
    client: ClientForm,
    allClients: ClientStatusCardViewModel[]
  ): Promise<AxiosResultStatus> {
    client = Utils.trimFormFields(client);

    const primaryAddress = {
      streetAddress1: client.streetAddress1,
      streetAddress2: client.streetAddress2,
      city: client.city,
      state: getState(client.state),
      zipCode: client.zipCode,
    } as Address;

    const primaryContact = {
      email: client.email,
      primPhoneExtn: client.ext,
      firstName: client.firstName,
      lastName: client.lastName,
      primPhoneNum: client.mobilePhone,
      primPhoneType: "mobile",
      secPhoneNum: client.phone,
      secPhoneType: "home",
      primaryAddress: primaryAddress,
    } as PrimaryContact;

    const clientToSave = {
      clientName: client.companyName,
      websiteUrl: client.website,
      clientBusRules: {
        useClientTemplate: "All",
      },
      primaryContact: primaryContact,
    } as client;

    const payload: AxiosSavePayload = {
      dataToSave: clientToSave,
      dataId: -2,
      url: "clients/v2/client/",
    };

    const saveResult = await axiosSaveHelper(payload);
    const returnedClient = saveResult.entity;

    allClients.push(returnedClient);
    const filteredClients = this.filterClients(allClients);
    saveResult.entity = {
      returnedClient,
      ...filteredClients,
    };

    return saveResult;
  }

  clientMap(payload: any){
    return{
      workflowId: payload.workflowId,
      workflowStatusId: payload.workflowStatus.workflowStatusId,
      workflowSubStatusId: payload.workflowSubStatus.workflowSubStatusId
    }
  }

  async saveClient(data: {
    clientId: number,
    payload: any
  }): Promise<AxiosResultStatus>{

    const payload: AxiosSavePayload = {
      dataToSave: this.clientMap(data.payload),
      dataId: data.clientId,
      url: `clients/v2/client`
    }

   return await axiosSaveHelper(payload);

  }

  async saveClientPaymentTransactionFee(data: {
    clientId: number,
    payload: ClientPaymentTransactionFee
  }): Promise<AxiosResultStatus>{

    const payload: AxiosSavePayload = {
      dataId: 0,
      dataToSave: data.payload,
      url: `clients/v2/client/${data.clientId}/client-payment-transaction-fee`
    }

    return await axiosSaveHelper(payload)
  }

  async getClientPaymentTransactionFee(clientId: number): Promise<AxiosResultStatus>{

    const payload: AxiosReadPayload = {
      url: `clients/v2/client/${clientId}/client-payment-transaction-fee`
    }

    return await axiosReadHelper(payload)
  }

  async saveClientEntity(
    formValues: ClientEntityForm,
    originalClient: any
  ): Promise<AxiosResultStatus> {
    formValues = Utils.trimFormFields(formValues);
    const primaryAddress = {
      ...originalClient.primaryContact.primaryAddress,
      streetAddress1: formValues.streetAddress1,
      streetAddress2: formValues.streetAddress2,
      city: formValues.city,
      state: getState(formValues.state),
      zipCode: formValues.zipCode,
    };

    const primaryContact = {
      primaryAddress: primaryAddress,
    };

    const clientToSave = {
      clientName: formValues.companyName,
      websiteUrl: formValues.website,
      primaryContact,
    };

    const payload: AxiosSavePayload = {
      dataToSave: clientToSave,
      dataId: -2,
      isPatch: true,
      url: `clients/v2/client/${originalClient.clientId}`,
    };

    const response = await axiosSaveHelper(payload);

    if (!response.hasErrors) {
      const returnedClient = response.entity;
      const clientEntityDetails = this.mapToClientEntityDetails(returnedClient);

      response.entity = {
        clientEntityDetails,
        fullClient: returnedClient,
      };
    }
    return response;
  }

  async saveClientContact(
    formValues: ClientContactForm,
    originalClient: any
  ): Promise<AxiosResultStatus> {
    formValues = Utils.trimFormFields(formValues);
    const primaryContact = {
      ...originalClient.primaryContact,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      primPhoneNum: formValues.phone,
      primPhoneExtn: formValues.ext,
      secPhoneNum: formValues.mobilePhone,
    };

    const clientToSave = {
      primaryContact,
    };

    const payload: AxiosSavePayload = {
      dataToSave: clientToSave,
      dataId: -2,
      isPatch: true,
      url: `clients/v2/client/${originalClient.clientId}`,
    };

    const response = await axiosSaveHelper(payload);
    if (!response.hasErrors) {
      const returnedClient: client = response.entity;
      const clientEntityDetails = this.mapToClientEntityDetails(returnedClient);

      response.entity = {
        clientEntityDetails,
        fullClient: returnedClient,
      };
    }
    return response;
  }

  async generateClientSpreadsheet(
    clientId: number,
    spreadsheetType: string,
    facilityIds: number[],
    facilityLevelOfCareIds: number[],
    payorIds: number[]
  ): Promise<AxiosResultStatus> {
    const LOCfilters = {
      clientFacilityIds: facilityIds,
      facilityLevelOfCareIds: facilityLevelOfCareIds,
    };
    const payorFilters = {
      clientFacilityIds: facilityIds,
      payorIds: payorIds,
      facilityLevelOfCareIds: facilityLevelOfCareIds
    };

    const clientToSave = {
      filters: spreadsheetType === "levelOfCare" ? LOCfilters : payorFilters,
      type: spreadsheetType,
    };

    const payload: AxiosSavePayload = {
      dataToSave: clientToSave,
      dataId: -2,
      url: `clients/v2/client/${clientId}/spreadsheet`,
    };
    const responseData = await axiosSaveHelper(payload);

    if (!responseData.hasErrors) {
      const link = document.createElement("a");
      link.href = responseData.entity.url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    return responseData;
  }

  async notifyClient(clientId: number): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataId: 0,
      url: `client/${clientId}/notify`,
    };
    const responseData = await axiosSaveHelper(payload);
    if (!responseData.hasErrors) {
      let client = responseData.entity;
      responseData.entity = {
        updatedClient: client,
      };
    }
    return responseData;
  }

  async resendInvitation(userId: number): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      url: `user/v2/user/${userId}/welcome-email`,
    };
    return await axiosSaveHelper(payload);
  }

  async saveClientBusinessRules(
    formValues: ClientBusinessRules
  ): Promise<AxiosResultStatus> {
    const clientId = formValues.clientId;

    const businessRulesToSave = {
      clientBusRules: {
        ...formValues,
        clientId: formValues.clientId,
      },
    };

    const payload: AxiosSavePayload = {
      dataToSave: businessRulesToSave,
      dataId: -2,
      isPatch: true,
      url: `clients/v2/client/${clientId}/detail`,
    };
    const response = await axiosSaveHelper(payload);

    if (!response.hasErrors) {
      const client: client = response.entity;
      response.entity = {
        clientBusinessRules: client.clientBusRules,
        fullClient: client,
      };
    }
    return response;
  }

  async createFacilityRiskSetting(
    formValues: FacilityRiskThreshold
  ): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = Utils.deepClone(formValues);
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.facilityRiskThresholdId;
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: formValues.paramId,
      url: `clients/v2/facility/${formValues.clientFacilityId}/risk-threshold`,
    };
    const result = await axiosSaveHelper(payload);
    return result;
  }

  async updatePayerClaimRate(formValues: PayerClaimRate): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = Utils.deepClone(formValues);
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.clientPayorClaimrateId;
    delete newRiskThresholdPayload.clientId;
    if (newRiskThresholdPayload.cfgPayorPlanId === null) {
      delete newRiskThresholdPayload.cfgPayorPlanId;
    }
    if (newRiskThresholdPayload.cfgPayorId === null) {
      delete newRiskThresholdPayload.cfgPayorId;
    }
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: formValues.paramId,
      isPatch: true,
      url: `clients/v2/client/payor-claimrate/${formValues.clientPayorClaimrateId}`
    }
    const result = await axiosSaveHelper(payload);
    return result;
  }

  async createPayerClaimRate(formValues: PayerClaimRate): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = Utils.deepClone(formValues);
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.clientPayorClaimrateId;
    if (newRiskThresholdPayload.cfgPayorPlanId === null) {
      delete newRiskThresholdPayload.cfgPayorPlanId;
    }
    if (newRiskThresholdPayload.cfgPayorPlanId === null) {
      delete newRiskThresholdPayload.cfgPayorPlanId;
    }
    if (newRiskThresholdPayload.cfgPayorId === null) {
      delete newRiskThresholdPayload.cfgPayorId;
    }
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: formValues.paramId,
      url: `clients/v2/client/${formValues.clientId}/payor-claimrate`
    }
    const result = await axiosSaveHelper(payload);
    return result;
  }

  async updateFacilityRiskSetting(formValues: FacilityRiskThreshold): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = Utils.deepClone(formValues);
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.facilityRiskThresholdId;
    delete newRiskThresholdPayload.clientFacilityId;
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: formValues.paramId,
      isPatch: true,
      url: `clients/v2/facility/risk-threshold/${formValues.facilityRiskThresholdId}`,
    };
    const result = await axiosSaveHelper(payload);
    return result;
  }

  isNotFilterByAll(payload: any): boolean {
    const payloadCopy = { ...payload };
    delete payloadCopy.all;
    const payloadValues = Object.values(payloadCopy);
    return payloadValues.some((value) => value === true);
  }

  async getReferralSources(
    facilityId: number,
    clientId: number
  ): Promise<AxiosResultStatus> {
    let referralSourceName = "clientReferralSourceName";
    let endPoint = "clients/v2/client/" + clientId + "/referral-source";
    if (facilityId > 0) {
      endPoint = "clients/v2/facility/" + facilityId + "/referral-source";
      referralSourceName = "facilityReferralSourceName";
    }

    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: endPoint,
    };

    const responseData = await axiosReadHelper(readPayload);

    if (!responseData.hasErrors) {
      const newSortedData = Utils.sortAlphabetically(
        responseData.entity,
        referralSourceName
      );

      if (facilityId > 0) {
        const mappedData = this.mapToSelectedFacilityReferralSource(
          newSortedData,
          clientId
        );
        responseData.entity = mappedData;
      } else {
        responseData.entity = newSortedData;
      }
    }
    return responseData;
  }

  async getFacilityLOCReferralSources(
    facilityId: number,
    clientId: number,
    facilityReferralSources: SelectedFacilityReferralSource[],
    facilityLevelsOfCare: EstLevelOfCare[],
    levelOfCareId: number
  ): Promise<AxiosResultStatus> {
    let endpoint = "clients/v2/facility/" + facilityId + "/referral-source-loc";

    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: endpoint,
    };

    const responseData = await axiosReadHelper(readPayload);

    if (!responseData.hasErrors) {
      //manipulate response with facilityReferralSources to add the property facilityReferralSourceName and levelOfCareRevenue for each row
      let facilityLOCRefSources = responseData.entity;

      const facilityLOCReferralSourceForms: FacilityLOCReferralSourceForm[] =
        this.mapToFacilityLOCReferralSourceForm(
          facilityLOCRefSources,
          clientId,
          facilityLevelsOfCare,
          facilityReferralSources,
          levelOfCareId
        );
      responseData.entity = {
        facilityLOCReferralSourceForms,
        apiFacilityLOCReferralSources: facilityLOCRefSources,
      };
    }

    return responseData;
  }

  async saveClientReferralSource(
    formValues: ClientReferralSource
  ): Promise<AxiosResultStatus> {
    const clientId = formValues.clientId;
    const clientReferralSourceId = formValues.clientReferralSourceId;
    const isEdit =
      clientReferralSourceId !== undefined && clientReferralSourceId > 0;

    const clientRefToSave = {
      clientReferralSourceName: formValues.clientReferralSourceName,
    };

    let endPoint = `clients/v2/client/${clientId}/referral-source`;
    if (isEdit) {
      endPoint = `clients/v2/client/${clientId}/referral-source/${clientReferralSourceId}`;
    }

    const payload: AxiosSavePayload = {
      dataToSave: clientRefToSave,
      dataId: -2,
      isPatch: isEdit,
      url: endPoint,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }

  async deleteReferralSource(
    referralSourceId: number,
    clientId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    let endPoint = `clients/v2/client/${clientId}/referral-source/${referralSourceId}`;
    if (facilityId !== undefined && facilityId > 0) {
      endPoint = `clients/v2/facility/${facilityId}/referral-source/${referralSourceId}`;
    }
    const deletePayload: AxiosDeletePayload = {
      dataId: -2,
      url: endPoint,
    };

    return await axiosDeleteHelper(deletePayload);
  }

  async saveFacilityReferralSource(
    formValues: FacilityReferralSourceModel
  ): Promise<AxiosResultStatus> {
    const facilityReferralSourceId = formValues.facilityReferralSourceId;
    const facilityId = formValues.clientFacilityId;

    const refToSave = {
      facilityReferralSourceName: formValues.facilityReferralSourceName,
    };

    const payload: AxiosSavePayload = {
      dataToSave: refToSave,
      dataId: -2,
      isPatch: true,
      url: `clients/v2/facility/${facilityId}/referral-source/${facilityReferralSourceId}`,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }

  async addFacilityReferralSource(
    formValues: FacilityReferralSourceModel
  ): Promise<AxiosResultStatus> {
    const facilityId = formValues.clientFacilityId;

    let endPoint = `clients/v2/facility/${facilityId}/referral-source`;

    const facilityRefToSave = {
      facilityReferralSourceName: formValues.facilityReferralSourceName,
      clientReferralSourceId: formValues.clientReferralSourceId,
    };
    const payload: AxiosSavePayload = {
      dataToSave: facilityRefToSave,
      dataId: -2,
      isPatch: false,
      url: endPoint,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }

  async saveFacilityLOCReferralSource(
    formValues: FacilityLOCReferralSource
  ): Promise<AxiosResultStatus> {
    const facilityId = formValues.clientFacilityId;
    const facilityLOCReferralSourceId = formValues.facilityLocReferralSourceId;
    const isEdit =
      facilityLOCReferralSourceId !== undefined &&
      facilityLOCReferralSourceId > 0;

    let endPoint = `clients/v2/facility/${facilityId}/referral-source-loc`;
    if (isEdit) {
      endPoint = `clients/v2/facility/${facilityId}/referral-source-loc/${facilityLOCReferralSourceId}`;
    }

    const refToSave = {
      facilityReferralSourceId: formValues.facilityReferralSourceId,
      facilityLevelOfCareId: formValues.facilityLevelOfCareId,
      locReferralRevenue: formValues.locReferralRevenue,
    };

    const payload: AxiosSavePayload = {
      dataToSave: refToSave,
      dataId: -2,
      isPatch: isEdit,
      url: endPoint,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }

  async getClientDeficiencyNotification(
    clientId: number
  ): Promise<AxiosResultStatus> {
    const endpoint = `clients/v2/client/${clientId}/deficiency-notification`;

    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: endpoint,
    };

    const responseData = await axiosReadHelper(readPayload);

    if (!responseData.hasErrors) {
      const newValue: ClientDeficiencyNotification =
        this.mapToClientDeficiencyNotification(clientId, responseData.entity);
      responseData.entity = newValue;
    }

    return responseData;
  }

  async saveClientDeficiencyNotification(
    formValues: ClientDeficiencyNotification
  ): Promise<AxiosResultStatus> {
    const clientId = formValues.clientId;
    const clientDeficiencyNotifId = formValues.clientDeficiencyNotificationId;

    const isEdit = clientDeficiencyNotifId > 0 ? true : false;

    let endPoint = `clients/v2/client/${clientId}/deficiency-notification`;
    const formValuesToSave = {
      isEiv: formValues.isEiv,
      downpaymentPct: formValues.downpaymentPct,
      addedToDownpayment: formValues.addedToDownpayment,
    };

    const payload: AxiosSavePayload = {
      dataToSave: formValuesToSave,
      dataId: -2,
      isPatch: isEdit,
      url: endPoint,
    };
    const response = await axiosSaveHelper(payload);
    return response;
  }

  // private supporting functions

  filterClients(allClients: ClientStatusCardViewModel[]) {
    // workflow statuses, ids, etc: https://anexinet.atlassian.net/wiki/spaces/FNP/pages/2716730103/Master+Workflow+Database+List
    return {
      allClients: allClients,
      setupIncomplete: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 1
      ),
      stripeSetupIncomplete: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 2
      ),
      readyForActivation: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 3
      ),
      isActive: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 4
      ),
      stripeActionNeeded: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 5
      ),
      closed: allClients.filter(
        (client) => client.workflowSubStatus.workflowSubStatusId === 6
      ),
    };
  }

  mapToClientEntityDetails(client: client): ClientForm {
    return {
      clientId: client.clientId ? client.clientId : 0,
      companyName: client.clientName ? client.clientName : "",
      website: client.websiteUrl ? client.websiteUrl : "",
      streetAddress1: client.primaryContact?.primaryAddress?.streetAddress1
        ? client.primaryContact.primaryAddress.streetAddress1
        : "",
      streetAddress2: client.primaryContact?.primaryAddress?.streetAddress2
        ? client.primaryContact.primaryAddress.streetAddress2
        : "",
      city: client.primaryContact?.primaryAddress?.city
        ? client.primaryContact.primaryAddress.city
        : "",
      state: client.primaryContact?.primaryAddress?.state?.stateId
        ? client.primaryContact.primaryAddress.state.stateId
        : 0,
      zipCode: client.primaryContact?.primaryAddress?.zipCode
        ? client.primaryContact.primaryAddress.zipCode
        : "",
      firstName: client.primaryContact?.firstName
        ? client.primaryContact.firstName
        : "",
      lastName: client.primaryContact?.lastName
        ? client.primaryContact.lastName
        : "",
      email: client.primaryContact?.email ? client.primaryContact.email : "",
      phone: client.primaryContact?.primPhoneNum
        ? client.primaryContact.primPhoneNum
        : "",
      ext: client.primaryContact?.primPhoneExtn
        ? client.primaryContact.primPhoneExtn
        : "",
      mobilePhone: client.primaryContact?.secPhoneNum
        ? client.primaryContact.secPhoneNum
        : "",
    };
  }

  mapToClientBusinessRules(client: client): ClientBusinessRules {
    return {
      clientId: client.clientBusRules?.clientId || 0,
      preCareFullPayMaxDiscount:
        client.clientBusRules?.preCareFullPayMaxDiscount === 0
          ? 0
          : client.clientBusRules?.preCareFullPayMaxDiscount || "",
      postCareFullPayMaxDiscount:
        client.clientBusRules?.postCareFullPayMaxDiscount === 0
          ? 0
          : client.clientBusRules?.postCareFullPayMaxDiscount || "",
      clientBusinessRuleId: client.clientBusRules?.clientBusinessRuleId || 0,
      refundsIssuedBy: client.clientBusRules?.refundsIssuedBy || "",
      missedPmtsForDefault: client.clientBusRules?.missedPmtsForDefault || "",
      pfrVarianceTolerance:
        client.clientBusRules?.pfrVarianceTolerance === 0
          ? 0
          : client.clientBusRules?.pfrVarianceTolerance || "",
      automationFee:
        client.clientBusRules?.automationFee === 0
          ? 0
          : client.clientBusRules?.automationFee || "",
      merchantProcessingFee:
        client.clientBusRules?.merchantProcessingFee === 0
          ? 0
          : client.clientBusRules?.merchantProcessingFee || "",
      customDocTokenId: client.clientBusRules?.customDocTokenId || "",
      useClientTemplate: client.clientBusRules?.useClientTemplate || "All",
      realTimeUpdates: client.clientBusRules?.realTimeUpdates || false,
      paynowFee:
        client.clientBusRules?.paynowFee === 0
          ? 0
          : client.clientBusRules?.paynowFee || "",
      sendPFRDocs: client.clientBusRules?.sendPFRDocs || false,
    };
  }

  mapToSelectedFacilityReferralSource(
    facilityRefSources: FacilityReferralSourceModel[],
    clientId: number
  ): SelectedFacilityReferralSource[] {
    let newSelectedFacilityRefSources: SelectedFacilityReferralSource[] = [];
    facilityRefSources.forEach((refItem: FacilityReferralSourceModel) => {
      const newRefItem: SelectedFacilityReferralSource = {
        facilityId: refItem.clientFacilityId,
        clientReferralSourceId: refItem.clientReferralSourceId,
        facilityReferralSourceId: refItem.facilityReferralSourceId,
        clientId: clientId,
        facilityReferralSourceName: refItem.facilityReferralSourceName,
        clientReferralSourceName:
          refItem.clientReferralSourceId === undefined ||
          refItem.clientReferralSourceId === null
            ? ""
            : refItem.facilityReferralSourceName,
      };
      newSelectedFacilityRefSources.push(newRefItem);
    });
    return newSelectedFacilityRefSources;
  }

  mapToFacilityLOCReferralSourceForm(
    facilityLOCRefSources: FacilityLOCReferralSource[],
    clientId: number,
    facilityLevelsOfCare: EstLevelOfCare[],
    facilityReferralSources: SelectedFacilityReferralSource[],
    levelOfCareId: number
  ): FacilityLOCReferralSourceForm[] {
    let newFacilityLOCRefSources: FacilityLOCReferralSourceForm[] = [];
    facilityReferralSources.forEach(
      (refItem: SelectedFacilityReferralSource) => {
        const facilityRefSourceId = refItem.facilityReferralSourceId;
        let newFacilityLOCReferralForm: FacilityLOCReferralSourceForm = {
          clientId: clientId,
          facilityReferralSourceId: facilityRefSourceId,
          facilityReferralSourceName: refItem.facilityReferralSourceName,
          clientFacilityId: refItem.facilityId,
          facilityLocReferralSourceId: undefined,
          facilityLevelOfCareId: levelOfCareId,
          locReferralRevenue: 0,
          levelOfCareName: Utils.getLevelOfCareName(
            facilityLevelsOfCare,
            levelOfCareId
          ),
          levelOfCareCode: Utils.getLevelOfCareCode(
            facilityLevelsOfCare,
            levelOfCareId
          ),
        };
        let facilityLOCRefSourceItem = facilityLOCRefSources.find(
          (item: FacilityLOCReferralSource) =>
            item.facilityReferralSourceId === facilityRefSourceId &&
            item.facilityLevelOfCareId === levelOfCareId
        );
        if (facilityLOCRefSourceItem !== undefined) {
          newFacilityLOCReferralForm.facilityLocReferralSourceId =
            facilityLOCRefSourceItem.facilityLocReferralSourceId;
          newFacilityLOCReferralForm.locReferralRevenue =
            facilityLOCRefSourceItem.locReferralRevenue;
        }
        newFacilityLOCRefSources.push(newFacilityLOCReferralForm);
      }
    );

    return newFacilityLOCRefSources;
  }

  mapToClientDeficiencyNotification(
    clientId: number,
    responseEntity: ClientDeficiencyNotification
  ): ClientDeficiencyNotification {
    let retDeficiencyNotification: ClientDeficiencyNotification;
    if (responseEntity === null) {
      //return empty object with valid ClientId
      retDeficiencyNotification = { ...emptyClientDeficiencyNotification };
      retDeficiencyNotification.clientId = clientId;
    } else {
      retDeficiencyNotification = { ...responseEntity };
    }
    return retDeficiencyNotification;
  }

  async getClientRiskAssessmentConfigs(
    clientId: number,
    facilityId?: number
  ): Promise<AxiosResultStatus> {
    let endpoint = `clients/v2/client/${clientId}/risk-assessment-config`;

    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: endpoint,
    };

    const responseData = await axiosReadHelper(readPayload);

    return responseData;
  }
  
  async createClientRiskAssessmentConfig(clientId: number, formValues: any, facilityId?: number | null): Promise<AxiosResultStatus> {
    let endPoint = `clients/v2/client/${clientId}/risk-assessment-config`;

    const formValuesToSave: ClientRiskAssessmentPostBody = {
      isEiv: true,
      isFacilityCost: true,
      variableCostPct: formValues.variableCostPct,
      monthlyOperatingCostIp: formValues.monthlyOperatingCostIp,  
      monthlyOperatingCostOp: formValues.monthlyOperatingCostOp, 
      bedCapacityIp: formValues.bedCapacityIp, 
      bedCapacityOp: formValues.bedCapacityOp 
    };

    if(facilityId) {
      formValuesToSave.clientFacilityId = facilityId
    }

    const payload: AxiosSavePayload = {
      dataToSave: formValuesToSave,
      dataId: 0,
      url: endPoint,
    };

    const responseData = await axiosSaveHelper(payload);

    return responseData
  }

  async updateClientRiskAssessmentConfig(marginId: number, formValues: any, facilityId?: number | null): Promise<AxiosResultStatus> {
    let endPoint = `clients/v2/client/risk-assessment-config/${marginId}`;

    const formValuesToSave: ClientRiskAssessmentPostBody = {
      isEiv: true,
      isFacilityCost: true,
      variableCostPct: formValues.variableCostPct,
      monthlyOperatingCostIp: formValues.monthlyOperatingCostIp,  
      monthlyOperatingCostOp: formValues.monthlyOperatingCostOp, 
      bedCapacityIp: formValues.bedCapacityIp, 
      bedCapacityOp: formValues.bedCapacityOp
    };

    if(facilityId) {
      formValuesToSave.clientFacilityId = facilityId
    }

    const payload: AxiosSavePayload = {
      dataToSave: formValuesToSave,
      dataId: -2,
      isPatch: true,
      url: endPoint,
    };

    const responseData = await axiosSaveHelper(payload);

    return responseData
  }

  async deleteClientRiskAssessmentConfig(marginId: number): Promise<AxiosResultStatus> {
    let endPoint = `clients/v2/client/risk-assessment-config/${marginId}`;

    const payload: AxiosDeletePayload = {
      dataId: -2,
      url: endPoint,
    };

    const responseData = await axiosDeleteHelper(payload);

    return responseData
  }
}

export const specialistClientService = new SpecialistClientService();
