import HelloSign from "hellosign-embedded";

const client = new HelloSign();
export interface ConfigurationState {
  aws_region: string;
  aws_userPoolId: string;
  aws_userPoolWebClientId: string;
  aws_patientPoolId: string;
  aws_patientPoolWebClientId: string;
  aws_cognito_server_uri: string;
  server_uri: string;
  aws_gateway_server_uri_build: string;
  aws_gateway_server_uri: string;
  aws_gateway_patient_encounter_uri: string;
  env: string;
  smartystreets_url: string;
  smartystreets_key: string;
  stripe_key: string;
  dns: string;
  patientPortalUrl1: string;
  patientPortalUrl2: string;
  signDocUrl: string;
  helloSignCallbackKey: string;
  REACT_APP_patient_locking_countdown_max: number;
  REACT_APP_patient_locking_polling_interval: number;
  build_number: string;
  app_environment: string;
  unified_aws_app_gateway_uri: string;
  home_uri: string;
  aws_gateway_server_uri_temp: string;
}

export const configSettings: ConfigurationState = {
  app_environment: process.env.REACT_APP_HOST_ENV
    ? process.env.REACT_APP_HOST_ENV
    : "",
  aws_region: process.env.REACT_APP_AWS_USER_POOL_REGION
    ? process.env.REACT_APP_AWS_USER_POOL_REGION
    : "",
  aws_userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID
    ? process.env.REACT_APP_AWS_USER_POOL_ID
    : "",
  aws_userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
    ? process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID
    : "",
  aws_patientPoolId: process.env.REACT_APP_AWS_PATIENT_POOL_ID
    ? process.env.REACT_APP_AWS_PATIENT_POOL_ID
    : "",
  aws_patientPoolWebClientId: process.env
    .REACT_APP_AWS_PATIENT_POOL_WEB_CLIENT_ID
    ? process.env.REACT_APP_AWS_PATIENT_POOL_WEB_CLIENT_ID
    : "",
  aws_cognito_server_uri: process.env.REACT_APP_AWS_COGNITO_SERVER_URI
    ? process.env.REACT_APP_AWS_COGNITO_SERVER_URI
    : "",
  server_uri: process.env.REACT_APP_HOST_SERVER_URI
    ? process.env.REACT_APP_HOST_SERVER_URI
    : "",
  aws_gateway_server_uri_build: process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI
    ? process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI
    : "",
  aws_gateway_server_uri: process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI
    ? process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI
    : "", //This fixes FPP-936
  aws_gateway_patient_encounter_uri: process.env.REACT_APP_AWS_APPGATEWAY_PATIENT_ENCOUNTER_URI
    ? process.env.REACT_APP_AWS_APPGATEWAY_PATIENT_ENCOUNTER_URI
    : "",
  smartystreets_url: process.env.REACT_APP_SMARTYSTREETS_URL
    ? process.env.REACT_APP_SMARTYSTREETS_URL
    : "",
  smartystreets_key: process.env.REACT_APP_SMARTYSTREETS_API_KEY
    ? process.env.REACT_APP_SMARTYSTREETS_API_KEY
    : "",
  stripe_key: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    : "",
  helloSignCallbackKey: process.env.REACT_APP_HELLO_SIGN_PLATFORM_CLIENT_ID
    ? process.env.REACT_APP_HELLO_SIGN_PLATFORM_CLIENT_ID
    : "",
  build_number: process.env.REACT_APP_BUILD_NUMBER
    ? process.env.REACT_APP_BUILD_NUMBER
    : "",
  unified_aws_app_gateway_uri: process.env.REACT_APP_UNIFIED_AWS_APPGATEWAY_URI
    ? process.env.REACT_APP_UNIFIED_AWS_APPGATEWAY_URI
    : "",
  env: process.env.REACT_APP_HOST_ENV
  ? process.env.REACT_APP_HOST_ENV
  : "",
  home_uri: process.env.REACT_APP_HOME_URI
  ? process.env.REACT_APP_HOME_URI
    : "",
  aws_gateway_server_uri_temp: process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI_TEMP
      ? process.env.REACT_APP_AWS_APPGATEWAY_SERVER_URI_TEMP
    : "",
  dns: window.location.href, // TODO change when build is updated
  patientPortalUrl1: "patient.", // TODO change when build is updated
  patientPortalUrl2: "myfinpay.",
  signDocUrl: "sign-doc",
  REACT_APP_patient_locking_countdown_max: 30,
  REACT_APP_patient_locking_polling_interval: 5000
};

const patientPoolConfigSettings = {
  ...configSettings,
  aws_region: configSettings.aws_region,
  aws_userPoolId: configSettings.aws_patientPoolId,
  aws_userPoolWebClientId: configSettings.aws_patientPoolWebClientId,
};

const userPoolConfigSettings = {
  ...configSettings,
  aws_region: configSettings.aws_region,
  aws_userPoolId: configSettings.aws_userPoolId,
  aws_userPoolWebClientId: configSettings.aws_userPoolWebClientId,
};

export const getConfiguration = () => {
  if (configSettings.dns.includes(configSettings.patientPortalUrl1) || configSettings.dns.includes(configSettings.patientPortalUrl2)) {
    if (configSettings.dns.includes(configSettings.signDocUrl)) {
      return userPoolConfigSettings; // use userpool on the patient portal if the user is an account holder trying to sign a doc.
    } else {
      return patientPoolConfigSettings;
    }
  } else {
    return userPoolConfigSettings;
  }
};

export const openHelloSignDoc = (url: string) => {
  client.open(url, {
    clientId: configSettings.helloSignCallbackKey,
    allowCancel: false,
    locale: HelloSign.locales.EN_US,
  });
};
