import '../../../../scss/components/_list-header.scss';

import { listItemData, LoadingOverlay, RadioListItemGroup } from '@finpay-development/shared-components';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { calculateDownByPercent, calculateTermPmt, formatNumberToUSD } from '../../../../shared/calculators';
import { PfrListHeader } from '../../../../shared/components/pfr-list-header';
import { RootState } from '../../../../shared/state/root-reducer';
import { preauthorizedView } from '../../../../shared/validation/schemas';
import { PfrRowOutlineColor } from '../../misc/pfr-row-outline-color';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PatientPaymentSchedule } from '../../models/patient-payment.schedule';
import { PfrDownPaymentAmt } from '../../../../shared/components/pfr-downpayment';

interface preauthorizedViewProps {
  enableSaveButtonCallback: (enableSave: boolean) => void,
  handleSavePreauthorized: (paymentProgram: PatientPaymentProgram) => void,
  preauthorizedSaveClicked: boolean,
  isLoading: boolean,
  isEdit: boolean,
}

export function PreauthorizedView(props: preauthorizedViewProps) {
  const { enableSaveButtonCallback, handleSavePreauthorized, preauthorizedSaveClicked, isLoading, isEdit } = props;


  const stateFields = {
    selectedRule: useSelector((state: RootState) => {
      return state.patientContext.matchingRule
    }),
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    })
  }

  const { selectedEncounter, selectedRule, patientPaymentProgram } = stateFields;

  const terms = selectedRule?.availableTerms?.split(',');
  const pfrAmt = selectedEncounter?.pfrAmt ? selectedEncounter.pfrAmt : 0;
  const minimumDownPmt = selectedRule?.minDownPmtType === '%'
    ? +selectedRule?.minDownPmt/100
    : selectedRule?.minDownPmt;
  const optimalDownPmt = selectedRule?.optimalDownPmtType === '%'
    ? +selectedRule?.optimalDownPmt/100
    : selectedRule?.optimalDownPmt;

  const formRef: any = useRef();

  const termIndex = terms && terms.findIndex((term: string) => (
    term === selectedRule?.optimalTerm
  ));
  const termIndexEdit = terms && terms.findIndex((term: string) => (
    parseInt(term) === patientPaymentProgram?.patientPaymentSchedule?.terms
  ));

  const indexNum = termIndex + 500;
  const indexNumEdit = termIndexEdit + 500;
  const initialValOptDownPmt = selectedRule?.optimalDownPmtType === '%' ? calculateDownByPercent(optimalDownPmt, pfrAmt) : optimalDownPmt;
  const initialValuesObj = { // default the list to the 'optimal' term on 'add'
    value: initialValOptDownPmt + ' ,' + indexNum,
    label: selectedRule?.optimalTerm,
    secondaryLabel: 'Opt ' + formatNumberToUSD(initialValOptDownPmt),
    tertiaryLabel: '' +  formatNumberToUSD(calculateTermPmt(pfrAmt, initialValOptDownPmt, parseInt(selectedRule?.optimalTerm))),
    color: PfrRowOutlineColor.success
  }

  const initialValues = {
    preauthorizedTerm: JSON.stringify(initialValuesObj)
  }

  const editInitialValuesObj = {
    value: patientPaymentProgram?.downPmtAmt + ' ,' + indexNumEdit,
    label: patientPaymentProgram?.patientPaymentSchedule?.terms?.toString(),
    secondaryLabel: 'Opt ' + formatNumberToUSD(patientPaymentProgram?.downPmtAmt),
    tertiaryLabel: '' +  formatNumberToUSD(calculateTermPmt(pfrAmt, patientPaymentProgram?.downPmtAmt, patientPaymentProgram?.patientPaymentSchedule?.terms)),
    color: (parseInt(selectedRule?.optimalTerm) === patientPaymentProgram?.patientPaymentSchedule?.terms) ? PfrRowOutlineColor.success : undefined
  }

  const initialValuesEdit = {
    preauthorizedTerm: JSON.stringify(editInitialValuesObj)
  }

  const validationSchema = Yup.object(
    preauthorizedView
  );

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        enableSaveButtonCallback(true);
      })
      .catch((err) => {
        enableSaveButtonCallback(false);
      });
  }

 // on load of the component, we are checking if the form is already valid.
 // the form should be valid because we are initializing it with valid initial values. (by default, optimal payment is selected)
  useEffect(() => {
    if ((selectedRule?.ruleId === -1) || (pfrAmt === 0) || (!(selectedRule?.optimalTerm))) {
      enableSaveButtonCallback(false);
    } else {
      enableSaveButtonCallback(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pfrAmt, selectedRule])


  const isRuleValid = selectedRule.clientId && selectedRule.facilityId

  const mapToPatientPaymentProgram = useCallback(() => {
    const currentDate = new Date();
    const formValues = formRef?.current?.values?.preauthorizedTerm
    const formValuesObj = formValues ? JSON.parse(formValues) : {}
    const downPaymentAmt = parseFloat(formValuesObj.secondaryLabel.slice(formValuesObj.secondaryLabel.indexOf('$') + 1, formValuesObj.secondaryLabel.length).replace(/,/g, ''))
    const terms = parseInt(formValuesObj.label);
    const recurringPaymentAmt = parseFloat(formValuesObj.tertiaryLabel.slice(formValuesObj.tertiaryLabel.indexOf('$') + 1, formValuesObj.tertiaryLabel.length).replace(/,/g, ''))
    const pfrAmtBalance = patientPaymentProgram?.patientPaymentSchedule?.pfrBalance > 0 ? patientPaymentProgram?.patientPaymentSchedule?.pfrBalance : pfrAmt;

    const patientPaymentSchedule = {
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId ?? undefined,
      pfrAmt: pfrAmt,
      pfrBalance: pfrAmtBalance,
      paymentFreq: 'M',
      downPmtAmt: downPaymentAmt,
      terms: terms,
      remainingTerms: terms,
      paymentDueAmt: recurringPaymentAmt,
      scheduleStatus: 'Pending',
      scheduleStartDt: isEdit ? patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt || currentDate.toISOString() : currentDate.toISOString(),
    } as PatientPaymentSchedule

    const paymentProgram = {
      patientPaymentProgramId: isEdit ? patientPaymentProgram?.patientPaymentProgramId : 0,
      isHighRisk: false,
      isPaidInFull: false, // TODO: DEPRECATED - use patientPaymentSchedule?.paymentFreq === 'F' for full pay plans
      downPmtAmt: downPaymentAmt,
      isACH: false,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram

    return paymentProgram;
    // eslint-disable-next-line
  }, [
      pfrAmt, isEdit, patientPaymentProgram?.patientPaymentProgramId,
      patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt
    ])

  useEffect(() => {
    if (preauthorizedSaveClicked) {
      const paymentProgram = mapToPatientPaymentProgram();
      handleSavePreauthorized(paymentProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preauthorizedSaveClicked, mapToPatientPaymentProgram])

  const header = (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Patient Financial Responsibility
          </Typography>
        </Grid>
        <Box justifyContent="flex-end" className="pr-1 mt-2" style={{marginTop: '16px' }}>
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {formatNumberToUSD(pfrAmt)}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
      >
        <PfrDownPaymentAmt selectedEncounter={selectedEncounter} amountMargin={0} amountPadding={16} />
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={12} className="pl-2" style={{marginTop: '14px' }}>
          <Typography variant="h4">
            Preauthorized Payment Options
          </Typography>
        </Grid>
      </Grid>
    </>
  )

const buildData = () => {
  const listData: listItemData[] = [];
  terms && terms.forEach((term: string, index: number) => {
    const minDownPmt = selectedRule?.minDownPmtType === '%' ? calculateDownByPercent(minimumDownPmt, pfrAmt) : minimumDownPmt;
    const optDownPmt = selectedRule?.optimalDownPmtType === '%' ? calculateDownByPercent(optimalDownPmt, pfrAmt) : optimalDownPmt;
    const minRecurringPmt = calculateTermPmt(pfrAmt, minDownPmt, parseInt(term));
    const optRecurringPmt = calculateTermPmt(pfrAmt, optDownPmt, parseInt(term));
    const selectColor = (selectedRule?.optimalTerm === term) ? PfrRowOutlineColor.success : undefined
    listData.push(
      {
        id: index,
        label: term.toString(),
        secondaryLabel: `Min ${formatNumberToUSD(minDownPmt)}`,
        tertiaryLabel: `${formatNumberToUSD(minRecurringPmt)}`,
        value: minDownPmt.toString() + ` ,${index}`,
      }
    )
    listData.push(
      {
        id: (index + 500),
        label: term.toString(),
        secondaryLabel: `Opt ${formatNumberToUSD(optDownPmt)}`,
        tertiaryLabel: `${formatNumberToUSD(optRecurringPmt)}`,
        value: optDownPmt.toString() + ` ,${index + 500}`, // index allows formik to track correctly',
        selectColor: selectColor, //preauth view should always show success color
      }
    )
  })
  return listData;
}

const preauthorizedViewContents = (
  <div className="px-2 py-2 list-header">
    {header}
    {isRuleValid && (<PfrListHeader />)}
    <Formik
      innerRef={formRef}
      initialValues={isEdit ? initialValuesEdit : initialValues}
      validationSchema={validationSchema}
      validate={checkIfIsValid}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Form>
          <Grid
            container
          >
            {isRuleValid ? (
              <Grid item xs={12}>
                <RadioListItemGroup
                  data={buildData()}
                  name="preauthorizedTerm"
                  direction="column"
                  formik={formik}
                />
              </Grid>
            ) : (
              <Grid item xs={12} className='pt-4'>
                <Box display="flex" justifyContent="center">
                  <Typography variant="h4">
                    No Preauthorized Payment Options exist for Payment Program and Values Entered
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
      </Form>
      )}
    </Formik>
  </div>
)

  return (
    <div>
      {isLoading ?
      (
        <Box display="flex" justifyContent="center">
          <LoadingOverlay />
        </Box>
      ) : preauthorizedViewContents}
    </div>
  );
}

export default PreauthorizedView;
