//For default login
export class Credentials {
  email!: string;
  password?: string;
  error?: string;
}

//For values variable
export interface ValueForm{
  email: string;
  password?: string;

}


export class Username {
    email!: string;
}