import { Typography, Box } from '@mui/material'
import { Alert } from 'src/meta-data/models/metaData'

const consolidateAlerts = (alerts: Alert[]) => {
    let newAlert: Alert = {
        ...alerts[0]
    }

    for(let i = 1; i < alerts.length; i++) {
        newAlert.note_body = `${newAlert.note_body} 
        
        ${alerts[i].note_body}
        `
    }

    return newAlert
}

const AlertBoxes = (props: {alerts: Alert[]}) => {
    const { alerts } = props;

    if(!alerts) return null;
    if(alerts.length === 0) return null;

    const newAlert = consolidateAlerts(alerts)

    return (
        <>
            <AlertBox alert={newAlert} />
        </>
    )
}

const AlertBox = (props: {alert: Alert}) => {
    const {alert} = props;

    const styles: any = {
        marginBottom: "1rem"
    };

    let title;

    switch(alert.alert_type_id) {
        case 1:
            styles.backgroundColor = "#F8E5D8"
            styles.borderColor = "#F8E5D8" 
            title = `Payer: ${alert.payor_name}`
            break;
        case 2:
            styles.backgroundColor = "#E0EAF6"
            styles.borderColor = "#E0EAF6"
            title = `Prefix: ${alert.prefix}`
            break;
        case 3:
            styles.backgroundColor = "#E4EFDC"
            styles.borderColor = "#E4EFDC" 
            title = `Group: ${alert.group_code}`
            break;
        case 4:
            styles.backgroundColor = "#FDF3D0"
            styles.borderColor = "#FDF3D0"
            title = `Employer: ${alert.employer_name}`
            break;
        default: 
            styles.backgroundColor = "grey"
            title = `No alerts`
    }

    return (
        <Box
            border={1}
            style={styles}
        >
            <Box
                style={{
                color: "white",
                backgroundColor: styles.backgroundColor,
                padding: "1rem"
                }}
            >
                <Typography variant='h3'>{title}</Typography>
            </Box>
            <Box
                style={{
                    backgroundColor: "white",
                    padding: "1rem"
                }}
            >
                <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>{alert.note_body}</Typography>
            </Box>
        </Box>
    )
}

export default AlertBoxes