import { Button, TextField, Toggle } from '@finpay-development/shared-components';
import { Grid, Link, MenuItem, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { statesList } from '../../shared/misc/us-states';
import { State } from '../../shared/model/state';
import { Utils } from '../../shared/utils';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { AccountHelpModal } from '../modal/account-help-modal';
import { BillingForm, GuestModel } from '../models/anonymous-guest';
import { setClientFacilityDetails, setIsPayNowOverviewActive, setSelectedGuest } from '../state/guest-slice';
import { billInfoSchema, billInfoSchemaOmniChannel, patientAllInfoSchema, patientAllInfoSchemaOmniChannel } from '../validation/schema';
import FinDigitalPlatformFooter from '../../shared/components/findigital-platform-footer';
import { getClientFacilities } from '../state/guest-thunk';
import { UserInfoFacility } from '../../admin/models/user-info-facility';

function BillingSameAddress() {
  const [isAccountHelpModalOpen, setIsAccountHelpModalOpen] = useState(false);
  const [isEmailFooter, setIsEmailFooter] = useState(false);
  const [filteredFacilities, setFilteredFacilities] = useState<UserInfoFacility[]>([]);

  const stateFields = {
    selectedGuest: useSelector((state: RootState) => {
      return state.guestContext.selectedGuest;
    }),
    isLoading: useSelector(
      (state: RootState) => state.guestContext.isLoading
    ),
    isPublicModeLoggedIn: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isPublicModeLoggedIn
    ),
    isPublicMode: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isPublicMode
    ),
    userIsAuthenticated: useSelector(
      (state: RootState) => state.userContext.userIsAuthenticated
    ),
    configId: useSelector((state: RootState) => {
      return state.guestContext.configId;
    }),
    configIdSource: useSelector((state: RootState) => {
      return state.guestContext.configIdSource;
    }),
    guestContext: useSelector((state: RootState) => {
      return state.guestContext;
    }),
    isChannel: useSelector(
      (state: RootState) => state.guestContext.isChannel
    ),
    clientFacilites: useSelector(
      (state: RootState) => state.guestContext.clientFacilites
    ),
    channelType: useSelector(
      (state: RootState) => state.guestContext.channelType
    ),
    paymentChannels: useSelector((state: RootState) =>  state.guestContext.paymentChannels),
    authToken: useSelector(
      (state: RootState) =>  state.guestContext?.authToken
    ),
    clientFacilityDetails: useSelector(
      (state: RootState) => state.guestContext.clientFacilityDetails
    ),
  }

  const { selectedGuest, isPublicModeLoggedIn, guestContext, configId, isChannel, channelType, paymentChannels, authToken, clientFacilites, clientFacilityDetails } = stateFields;

  const initialFieldsTouched = {
    billingAddress1: true,
    billingCity: true,
    billingState: true,
    billingZip: true,
    accountNumber: true,
    confirmAcctNumber: true
  }

  const initialBillInfo = {
    email: selectedGuest?.email,
    firstName: selectedGuest?.firstName,
    lastName: selectedGuest?.lastName,
    middleInitial: selectedGuest?.middleInitial,
    cellPhone: selectedGuest?.cellPhone,
    billingAddress1: selectedGuest?.billingAddress1,
    billingAddress2: selectedGuest?.billingAddress2,
    billingCity: selectedGuest?.billingCity,
    billingState: selectedGuest?.billingState,
    billingZip: selectedGuest?.billingZip,
    accountNumber: selectedGuest?.accountNumber,
    confirmAcctNumber:selectedGuest?.confirmAcctNumber,
    saveInfoForLater: false,
    isPatientAddrSameAsBilling: selectedGuest?.isPatientAddrSameAsBilling,
    patientAddress1: selectedGuest?.patientAddress1,
    patientAddress2: selectedGuest?.patientAddress2,
    patientCity: selectedGuest?.patientCity,
    patientState: selectedGuest?.patientState,
    patientZip: selectedGuest?.patientZip,
    clientsPatientAccountId: selectedGuest?.clientsPatientAccountId,
    clientsPatientIOCId: selectedGuest?.clientsPatientIOCId,
    paymentChannelId: selectedGuest?.paymentChannelId,
    userName: selectedGuest?.userName,
    paymentReason: selectedGuest?.paymentReason,
    clientFacilityId: clientFacilityDetails?.clientFacilityId
  } as BillingForm;

  const [isPatientAddrSameAsBilling, setIsPatientAddrSameAsBilling] = useState(initialBillInfo.isPatientAddrSameAsBilling);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const payorString = guestContext.bannerText?.substring(guestContext.bannerText?.indexOf(',') + 1);

  useEffect(() => {
    dispatch(setIsPayNowOverviewActive(false));
    if (!configId) {
      window.location.href = window.location.href.substring(0, window.location.href.indexOf('/webpayment'));
    }
  }, [
    configId, dispatch
  ]);

  useEffect(() => {
    let updatedGuest = Utils.deepClone(selectedGuest);
    paymentChannels?.forEach((channel) => {
      if (channel.shortName === channelType) {
        updatedGuest.paymentChannelId = channel.paymentChannelId
        dispatch(setSelectedGuest(updatedGuest));
      }
    });
    if (updatedGuest.extClientFacilityId && clientFacilites?.length > 0) {
        const matchingFacility: UserInfoFacility | undefined =
            clientFacilites.find(
                facility =>
                    facility.extClientFacilityId ===
                    updatedGuest.extClientFacilityId
            );
        if (matchingFacility) {
            let updatedFacility: UserInfoFacility = {
                ...matchingFacility,
                clientFacilityId: matchingFacility.facilityId
            };
            dispatch(setClientFacilityDetails(updatedFacility));
        }
    }
    if (clientFacilites?.length > 0) {
        let formatFacilities: UserInfoFacility[] | undefined =
            clientFacilites?.filter(
                facility =>
                    !facility.facilityName.toLowerCase().includes('inactive')
            );
        setFilteredFacilities(formatFacilities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentChannels, clientFacilites]);

  useEffect(() => {
    if(channelType === 'vt' || channelType === 'crm') {
      const clientId = Number(guestContext?.clientFacilityDetails?.clientId);
      dispatch(getClientFacilities({"clientId": clientId, "authToken": authToken}))
      setIsEmailFooter(true);
    }
    // eslint-disable-next-line
  }, [
    channelType
  ]);

  const getValidationSchema = () => {
    let schema;
    if (isPatientAddrSameAsBilling) {
      schema = isChannel ? billInfoSchemaOmniChannel : billInfoSchema;
    } else {
      schema = isChannel ? patientAllInfoSchemaOmniChannel : patientAllInfoSchema;
    }
    return schema;
  }

  const handleFacilitySelect = (e: any) => {
    billFormik.handleChange(e);
    const matchingFacility: UserInfoFacility | undefined =
      clientFacilites.find(
        facility =>
            facility.facilityId ===
            e.target?.value
      );
      const updatedFacility: any = {
        ...matchingFacility,
        clientFacilityId: e.target.value
      };
    dispatch(setClientFacilityDetails(updatedFacility));
  }

  let validationBillSchema = Yup.object(getValidationSchema());

  const billFormik = useFormik({
    initialValues: initialBillInfo,
    enableReinitialize: true,
    onSubmit: (values) => { handleContinue() },
    validationSchema: validationBillSchema,
    initialTouched: initialFieldsTouched
  });

  const sameBillingAddress = (
    <></>
  )

  const channelFields = (
    <Grid container direction="row" spacing={1}>
      <Grid
        item
        direction="column"
        xs={6} md={6}
        style={{
          paddingTop: "1em"
        }}
      >
        <TextField
          label="Patient ID"
          name="clientsPatientAccountId"
          value={billFormik.values.clientsPatientAccountId}
          onChange={billFormik.handleChange}
          onBlur={billFormik.handleBlur}
          required={false}
          placeholder=""
          helperText=""
          error={billFormik.touched.clientsPatientAccountId && billFormik.errors.clientsPatientAccountId}
        />
      </Grid>
      <Grid
        item
        direction="column"
        xs={6} md={6}
        style={{
          paddingTop: "1em"
        }}
      >
        <TextField
          label="Patient IOC Id"
          name="clientsPatientIOCId"
          value={billFormik.values.clientsPatientIOCId}
          onChange={billFormik.handleChange}
          onBlur={billFormik.handleBlur}
          required={false}
          placeholder=""
          helperText=""
          error={billFormik.touched.clientsPatientIOCId && billFormik.errors.clientsPatientIOCId}
        />
      </Grid>
    </Grid>
  )

  const differentBillingAddress = (
    <>
      <Grid container direction="row" spacing={1}>
        <Grid
          item
          direction="column"
          xs={12} md={12}
          style={{
            paddingTop: "1em"
          }}
        >
          <TextField
            label="Patient Address 1"
            name="patientAddress1"
            value={billFormik.values.patientAddress1}
            onChange={billFormik.handleChange}
            onBlur={billFormik.handleBlur}
            placeholder=""
            helperText=""
            error={billFormik.touched.patientAddress1 && billFormik.errors.patientAddress1}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1}>
        <Grid
          item
          direction="column"
          xs={12} md={12}
          style={{
            paddingTop: "1em"
          }}
        >
          <TextField
            label="Patient Address 2"
            name="patientAddress2"
            required={false}
            value={billFormik.values.patientAddress2}
            onChange={billFormik.handleChange}
            onBlur={billFormik.handleBlur}
            placeholder=""
            helperText=""
            error={billFormik.touched.patientAddress2 && billFormik.errors.patientAddress2}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
      >
        <Grid item direction="column" xs={6} md={6}
          style={{
            paddingTop: "1em",
            paddingBottom: "1.3em"
          }}
        >
          <TextField
            required
            name="patientCity"
            label="Patient City"
            variant="outlined"
            value={billFormik.values.patientCity}
            onChange={billFormik.handleChange}
            onBlur={billFormik.handleBlur}
            placeholder=""
            error={
              billFormik.touched.patientCity &&
              billFormik.errors.patientCity
            }
          />
        </Grid>
        <Grid item direction="column" xs={6} md={6}>
          <Grid item container direction="row" spacing={1} alignItems="flex-start" >
            <Grid
              item
              direction="column"
              xs={5} md={4}
              style={{
                paddingTop: "1em",
              }}
            >
              <TextField
                select={true}
                error={
                  billFormik.touched.patientState && billFormik.errors.patientState
                    ? billFormik.errors.patientState
                    : ""
                }
                className="state-field"
                label="Patient State"
                name="patientState"
                value={billFormik.values.patientState}
                onChange={billFormik.handleChange}
                onBlur={billFormik.handleBlur}
              >
                <MenuItem value="-1">Choose One</MenuItem>
                {statesList.map((state: State) => (
                  <MenuItem key={`ps-${state.stateId}`} value={state.stateId}>
                    {state.stateCode}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              direction="column"
              xs={7} md={8}
              style={{
                paddingTop: "1em",
              }}
            >
              <TextField
                required
                label="Patient Zip"
                name="patientZip"
                variant="outlined"
                value={billFormik.values.patientZip}
                onChange={billFormik.handleChange}
                onBlur={billFormik.handleBlur}
                placeholder=""
                helperText=""
                error={
                  billFormik.touched.patientZip && billFormik.errors.patientZip
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  const handleIsPatientAddrSameAsBillingChange = () => {
    //Toggle deficiency - need to negate previous value to get updated value
    const updatedValue = !isPatientAddrSameAsBilling;

    setIsPatientAddrSameAsBilling(updatedValue);

    if (updatedValue === true)   // i.e. Patient Address is same as Billing Address
    {
      billFormik.setTouched({
        'billingAddress1': true,
        'billingCity': true,
        billingState: true,
        billingZip: true,
        accountNumber: true,
        confirmAcctNumber: true,
        patientAddress1: false,
        patientCity: false,
        patientState: false,
        patientZip: false
      })
    } else {
      // i.e. Patient Address is different from Billing Address, then show section of patient address
      billFormik.setTouched({
        'billingAddress1': true,
        'billingCity': true,
        billingState: true,
        billingZip: true,
        accountNumber: true,
        confirmAcctNumber: true,
        patientAddress1: true,
        patientCity: true,
        patientState: true,
        patientZip: true
      })

      // below code is needed to initialize PatientInfoBill form values
      billFormik.setFieldValue("patientAddress1", "");
      billFormik.setFieldValue("patientAddress2", "");
      billFormik.setFieldValue("patientCity", "");
      billFormik.setFieldValue("patientState", "");
      billFormik.setFieldValue("patientZip", "");
    }
  };

  const mapToSaveModel = () => {
    const formValues = billFormik?.values;
    let selectedFacility: UserInfoFacility | undefined;
    if(channelType === 'vt' || channelType === 'crm') {
      selectedFacility =
          clientFacilites.find(
              facility =>
                  facility.facilityId ===
                  formValues.clientFacilityId
          );
    }
    return {
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleInitial: formValues.middleInitial,
      cellPhone: formValues.cellPhone,
      billingAddress1: formValues.billingAddress1,
      billingAddress2: formValues.billingAddress2,
      billingCity: formValues.billingCity,
      billingState: formValues.billingState,
      billingZip: formValues.billingZip,
      accountNumber: formValues.accountNumber,
      confirmAcctNumber: formValues.confirmAcctNumber,
      isPatientAddrSameAsBilling: formValues.isPatientAddrSameAsBilling,
      saveInfoForLater: formValues.saveInfoForLater,
      patientAddress1: (formValues.isPatientAddrSameAsBilling ? '' : formValues.patientAddress1),
      patientAddress2: (formValues.isPatientAddrSameAsBilling ? '' : formValues.patientAddress2),
      patientCity: (formValues.isPatientAddrSameAsBilling ? '' : formValues.patientCity),
      patientState: (formValues.isPatientAddrSameAsBilling ? '' : formValues.patientState),
      patientZip: (formValues.isPatientAddrSameAsBilling ? '' : formValues.patientZip),
      clientsPatientAccountId: formValues.clientsPatientAccountId,
      clientsPatientIOCId: formValues.clientsPatientIOCId,
      paymentChannelId: selectedGuest?.paymentChannelId,
      extClientFacilityId: selectedFacility?.extClientFacilityId,
      userName: formValues.userName,
      paymentReason: formValues.paymentReason,
      clientFacilityId: formValues.clientFacilityId
    } as GuestModel;
  };

  const handleIconHelpClick = () => () => {
    setIsAccountHelpModalOpen(true);
  }

  function handleModalCancel() {
    setIsAccountHelpModalOpen(false);
  }

  // save form values and navigate to the next page
  function handleContinue() {
    const saveGuestModel = mapToSaveModel();
    dispatch(setSelectedGuest(saveGuestModel));
    // navigate to next page
    // alert(JSON.stringify(saveGuestModel, null, 2));
    navigate('/webpayment/payment-info');
  }

  return (
    <>
      {isPublicModeLoggedIn &&  (
        <>
          <Grid container direction="row" style={{ backgroundColor: "#F5F7F9" }}>
            <Grid
              item direction="column" md={12} xs={12}
              style={{ alignItems: "center", paddingTop: "1em", display: "flex" }}
            >
              <h1>
                Make a Payment
                {isChannel && paymentChannels.length > 0 && paymentChannels.map((channel) => (
                  channel.shortName === channelType ? ' - ' + channel.paymentChannelName : ''
                ))}
              </h1>
            </Grid>
            <Grid item direction="column" md={12} xs={12} style={{ alignItems: "center", paddingTop: "1.1em", display: "flex" }}>
              Powered by FinPay, {payorString}
            </Grid>
          </Grid>
          <Paper
            style={{
              maxWidth: "600px",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "10px",
              marginTop: "0.5em",
              paddingLeft: "35px",
              paddingRight: "35px"
            }}
          >
            <Typography variant="h3" className="pt-2" style={{paddingTop: "25px"}}>
              Patient Information
            </Typography>

            <form onSubmit={billFormik.handleSubmit}>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item direction="column" xs={5} md={5}
                  style={{
                  paddingTop: "1em",
                  }}
                >
                  <TextField
                    name="firstName"
                    label="First Name"
                    variant="outlined"
                    value={billFormik.values.firstName}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder="First Name"
                    error={
                      billFormik.touched.firstName &&
                      billFormik.errors.firstName
                    }
                  />
                </Grid>
                <Grid
                  item
                  direction="column"
                  xs={5} md={5}
                  style={{
                    paddingTop: "1em",
                  }}
                >
                  <TextField
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    value={billFormik.values.lastName}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder="Last Name"
                    helperText=""
                    error={
                      billFormik.touched.lastName && billFormik.errors.lastName
                    }
                  />
                </Grid>
                <Grid
                  item
                  direction="column"
                  xs={2} md={2}
                  style={{
                    paddingTop: "1em",
                  }}
                >
                  <TextField
                    label="Middle Initial"
                    name="middleInitial"
                    required={false}
                    variant="outlined"
                    value={billFormik.values.middleInitial}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={
                      billFormik.touched.middleInitial && billFormik.errors.middleInitial
                    }
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={8} md={8}
                  style={{
                    paddingTop: "1em"
                  }}
                >
                  <TextField
                    label="Patient's Email"
                    name="email"
                    required
                    disabled={
                      isChannel ? false : true
                    }
                    value={billFormik.values.email}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder="Enter Your Email Address"
                    helperText=""
                    error={billFormik.touched.email && billFormik.errors.email}
                  />
                </Grid>
                <Grid
                  item
                  direction="column"
                  xs={4} md={4}
                  style={{
                    paddingTop: "1em"
                  }}
                >
                  <TextField
                    label="Patient's Cell Phone"
                    name="cellPhone"
                    required={false}
                    value={billFormik.values.cellPhone}
                    onChange={(e: Event) => {
                      billFormik.handleChange(e);
                      billFormik.setFieldValue("cellPhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={billFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={billFormik.touched.cellPhone && billFormik.errors.cellPhone}
                  />
                </Grid>
              </Grid>
              { isChannel &&
                (channelFields)
              }
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12} md={12}
                  style={{
                    paddingTop: "1em"
                  }}
                >
                  <TextField
                    label="Billing Address 1"
                    name="billingAddress1"
                    value={billFormik.values.billingAddress1}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    error={billFormik.touched.billingAddress1 && billFormik.errors.billingAddress1}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={12} md={12}
                  style={{
                    paddingTop: "1em"
                  }}
                >
                  <TextField
                    label="Billing Address 2"
                    name="billingAddress2"
                    required={false}
                    value={billFormik.values.billingAddress2}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={billFormik.touched.billingAddress2 && billFormik.errors.billingAddress2}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="row"
              >
                <Grid item direction="column" xs={6} md={6}
                  style={{
                    paddingTop: "1em",
                  }}
                >
                  <TextField
                    required
                    name="billingCity"
                    label="Billing City"
                    variant="outlined"
                    value={billFormik.values.billingCity}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    error={
                      billFormik.touched.billingCity &&
                      billFormik.errors.billingCity
                    }
                  />
                </Grid>
                <Grid item direction="column" xs={6} md={6}>
                  <Grid item container direction="row" spacing={1} alignItems="flex-start" >
                    <Grid
                      item
                      direction="column"
                      xs={5} md={4}
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      <TextField
                        select={true}
                        error={
                          billFormik.touched.billingState && billFormik.errors.billingState
                            ? billFormik.errors.billingState
                            : ""
                        }
                        label="Billing State"
                        name="billingState"
                        className="state-field"
                        value={billFormik.values.billingState}
                        onChange={billFormik.handleChange}
                        onBlur={billFormik.handleBlur}
                      >
                        <MenuItem value="-1">Choose One</MenuItem>
                        {statesList.map((state: State) => (
                          <MenuItem key={state.stateId} value={state.stateId}>
                            {state.stateCode}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      direction="column"
                      xs={7} md={8}
                      style={{
                        paddingTop: "1em",
                      }}
                    >
                      <TextField
                        required
                        label="Billing Zip"
                        name="billingZip"
                        variant="outlined"
                        value={billFormik.values.billingZip}
                        onChange={billFormik.handleChange}
                        onBlur={billFormik.handleBlur}
                        placeholder=""
                        helperText=""
                        error={
                          billFormik.touched.billingZip && billFormik.errors.billingZip
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1}>
                <Grid
                  item
                  direction="column"
                  xs={6} md={6}
                >
                  <Link href="#" onClick={handleIconHelpClick()} style={{ fontSize: "small", paddingLeft: "130px" }}>What's this?</Link>
                  <TextField
                    required={
                      isChannel ? false : true
                    }
                    label={guestContext.accountLabel}
                    name="accountNumber"
                    value={billFormik.values.accountNumber}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={billFormik.touched.accountNumber && billFormik.errors.accountNumber}
                  />
                </Grid>
                <Grid
                  item
                  direction="column"
                  xs={6} md={6}
                  style={{
                    paddingTop: "1.95em"
                  }}
                >
                  <TextField
                    required={
                      isChannel ? false : true
                    }
                    label={`Confirm ${guestContext.accountLabel}`}
                    name="confirmAcctNumber"
                    value={billFormik.values.confirmAcctNumber}
                    onChange={billFormik.handleChange}
                    onBlur={billFormik.handleBlur}
                    placeholder=""
                    helperText=""
                    error={billFormik.touched.confirmAcctNumber && billFormik.errors.confirmAcctNumber}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={1} style={{paddingTop: "1em"}}>
                <Grid
                  item
                  direction="column"
                  xs={9} md={9}
                  style={{
                    paddingTop: "0",
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  Is Patient Address The Same as Billing Address?
                </Grid>
                <Grid item direction="column" xs={3} md={3}
                  style={{
                    paddingTop: "0",
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Toggle
                    name="isPatientAddrSameAsBilling"
                    leftText="Yes"
                    rightText="No"
                    value={billFormik.values.isPatientAddrSameAsBilling}
                    formik={billFormik}
                    onChange={handleIsPatientAddrSameAsBillingChange}
                  />
                </Grid>
              </Grid>
              {!isPatientAddrSameAsBilling ?
                (differentBillingAddress) :
                (sameBillingAddress)
              }
              {isEmailFooter && 
                <>
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      direction="column"
                      xs={6} md={6}
                      style={{
                        paddingTop: "1.5em"
                      }}
                    >
                      <TextField
                        data-testid="facilityId"
                        required
                        select={true}
                        label="Facility"
                        name="clientFacilityId"
                        className="state-field"
                        value={billFormik.values.clientFacilityId}
                        onChange={(e: any) => {
                          handleFacilitySelect(e);
                        }}
                        onBlur={billFormik.handleBlur}
                        error={
                          billFormik.touched.clientFacilityId && billFormik.errors.clientFacilityId
                        }
                      >
                        <MenuItem value="-1">Select Facility</MenuItem>
                        {filteredFacilities?.map((facility: UserInfoFacility) => (
                          <MenuItem
                            key={facility.facilityId}
                            value={facility.facilityId}
                          >
                            {facility.facilityName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      direction="column"
                      xs={6} md={6}
                      style={{
                        paddingTop: "1.5em"
                      }}
                    >
                      <TextField
                        required={false}
                        label={`User`}
                        name="userName"
                        value={billFormik.values.userName}
                        onChange={billFormik.handleChange}
                        onBlur={billFormik.handleBlur}
                        placeholder=""
                        helperText=""
                        error={billFormik.touched.userName && billFormik.errors.userName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      direction="column"
                      xs={12} md={12}
                      style={{
                        paddingTop: "1em"
                      }}
                    >
                      <TextField
                        required={false}
                        label="Payment Reason"
                        name="paymentReason"
                        value={billFormik.values.paymentReason}
                        onChange={billFormik.handleChange}
                        multiline={true}
                        rows={2}
                        onBlur={billFormik.handleBlur}
                        error={billFormik.touched.paymentReason && billFormik.errors.paymentReason}
                      />
                    </Grid>
                  </Grid>
                </>
              }
              <Grid
                item
                container
                direction="row"
                alignContent="flex-end"
                justifyContent="flex-end"
                spacing={2}
                style={{ paddingTop: "30px" }}
              >
                <Grid
                  item
                  style={{
                    paddingBottom: "40px",
                    marginRight: "30px",
                  }}
                >
                  <Button
                    type="primary"
                    isSubmitButton
                  >
                    Continue To Payment
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <FinDigitalPlatformFooter showEmailFooter={isEmailFooter} channelType={channelType} />
          <AccountHelpModal
            isOpen={isAccountHelpModalOpen}
            handleModalCancel={handleModalCancel}
            fieldLabel={guestContext.accountLabel}
            fieldTextDesc={guestContext.configHelpText}
          />
        </>
      )}
    </>
  )
}


export default BillingSameAddress
