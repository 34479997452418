export interface FacilityDetails {
  facilityId: number;
  facilityName: string;
  outpatientEnabled: boolean;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipCode: string;
  website: string;
  phone: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactEmail: string;
  primaryContactAddress1: string;
  primaryContactAddress2: string;
  primaryContactCity: string;
  primaryContactState: string;
  primaryContactZip: string;
  primaryContactPhone: string;
  primaryContactMobilePhone: string;
  ssn: string;
  dob: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  externalVerifyStatus: string;
  externalAccount: string | number;
  customDocTokenId?: string;
  payNowExternalAccount: string | number;
  referenceNumberName: string;
  referenceNumberHelpText: string;
  bannerText: string;
  monthlyOperatingCostIp: number;
  monthlyOperatingCostOp: number;
  bedCapacityIp: number;
  bedCapacityOp: number;
}

export const blankFacilityDetails: FacilityDetails = {
  facilityId: 0,
  facilityName: "",
  outpatientEnabled: false,
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: "",
  zipCode: "",
  website: "",
  phone: "",
  primaryContactFirstName: "",
  primaryContactLastName: "",
  primaryContactEmail: "",
  primaryContactAddress1: "",
  primaryContactAddress2: "",
  primaryContactCity: "",
  primaryContactState: "",
  primaryContactZip: "",
  primaryContactPhone: "",
  primaryContactMobilePhone: "",
  ssn: "",
  dob: "",
  bankName: "",
  routingNumber: "",
  accountNumber: "",
  externalVerifyStatus: "",
  externalAccount: "",
  customDocTokenId: "",
  payNowExternalAccount: "",
  referenceNumberName: "",
  referenceNumberHelpText: "",
  bannerText: "",
  monthlyOperatingCostIp: 0,
  monthlyOperatingCostOp: 0,
  bedCapacityIp: 0,
  bedCapacityOp: 0,
};
