import { combineReducers } from "@reduxjs/toolkit";
import counter from "../../playground/state/simple-slice";
import patientContext from "../../patient/state/patient-slice";
import patientRecordLockingContext from "../../patient/state/patient-record-locking-slice";
import userContext from "../../security/state/user-slice";
import adminUserContext from "../../admin/state/users/admin-slice";
import adminRoleContext from "../../admin/state/admin-roles-slice";
import implementationSpecialistClientsContext from "../../implementation-specialist/state/clients/implementation-clients-slice";
import implementationFacilityContext from "../../implementation-specialist/state/facility/implementation-facility-slice";
import adminConfigurationContext from "../../admin-configuration/state/admin-configuration-slice";
import implementationRulesEngineContext from "../../implementation-specialist/state/rules-engine/rules-engine-slice";
import vobContext from "../../admissions-advisor/state/vob-slice";
import vobPatientContext from "../../admissions-advisor/state/vob-patient-slice";
import estimatorContext from "../../admissions-advisor/state/estimator-slice";
import riskAssessmentContext from "../../admissions-advisor/state/risk-assessment-slice";
import guestContext from "../../guest/state/guest-slice";
import implementationPESScriptContext from "../../implementation-specialist/state/pes-script/implementation-pesscript-slice";
import metaData from "../../meta-data/state/meta-data-slice"
import implementationAuthDocsContext from "../../implementation-specialist/state/auth-docs/implementation-auth-docs-slice"
import implementationCRM from "../../implementation-specialist/state/crm/implementation-crm-slice"

const adminReducer = combineReducers({
  adminRoleContext: adminRoleContext,
  adminConfigurationContext: adminConfigurationContext,
  adminUserContext: adminUserContext,
});

const implementationReducer = combineReducers({
  implementationSpecialistClient: implementationSpecialistClientsContext,
  implementationFacility: implementationFacilityContext,
  implementationRulesEngineContext: implementationRulesEngineContext,
});

const admissionsAdvisorReducer = combineReducers({
  vobContext: vobContext,
  vobPatientContext: vobPatientContext,
  estimatorContext: estimatorContext,
  riskAssessmentContext: riskAssessmentContext,
});

const pesScriptReducer = combineReducers({
  implementationPesScript: implementationPESScriptContext,
});

const authDocsReducer = combineReducers({
  implementationAuthDocs : implementationAuthDocsContext
})

const crmReducer = combineReducers({
  implementationCRM : implementationCRM
})

const rootReducer = combineReducers({
  counter: counter,
  patientContext: patientContext,
  patientRecordLockingContext: patientRecordLockingContext,
  userContext: userContext,
  adminContext: adminReducer,
  implementationContext: implementationReducer,
  admissionsAdvisorContext: admissionsAdvisorReducer,
  guestContext: guestContext,
  pesScriptContext: pesScriptReducer,
  metaData: metaData,
  authDocsContext: authDocsReducer,
  crmContext: crmReducer,
});
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
