import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@finpay-development/shared-components';
import {
  Paper,
  Container,
  Grid,
  Typography,
  Button as MUIButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { formatNumberToUSD } from 'src/shared/calculators';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../shared/state/root-reducer';
import '../../scss/components/mobile-payments.scss';
import { AppDispatch } from 'src/shared/state/store';
import { paynowService } from '../services/paynow-service';
import { setMatchingRule } from 'src/patient/state/patient-slice';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%', // Set width to 100% on mobile
    [theme.breakpoints.up('md')]: {
      width: '80%', // Set width to 80% on screens larger than laptop
    },
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}));

const MobilePaymentProgramModal = () => {
  const [step, setStep] = useState(1); // 1: mobile payment programs, 2: when selecting other programs
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch<AppDispatch>();

  const patient = useSelector((state: RootState) => (state?.patientContext?.selectedPatient));
  const patientEncounter = useSelector((state: RootState) => (state?.patientContext?.selectedEncounter));
  const matchingRule = useSelector((state: RootState) => (state?.patientContext?.matchingRule));
  const authToken = useSelector((state: RootState) =>  state?.guestContext?.authToken);

  const patientId = patientEncounter.patientId;
  const encounterId = patientEncounter.patientEncounterId;
  const pfrAmt = patientEncounter.pfrAmt;
  // const pfrCollected = patientEncounter?.patientPaymentProgram?.length
  //   ? patientEncounter?.patientPaymentProgram[0].patientPaymentSchedule?.pfrBalance
  //   : 0;
  const pfrCollected = 0;
  const remainingPfr = patientEncounter?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.pfrCapturedBalance || pfrAmt;
  const optDownPayment = matchingRule?.optimalDownPmtType === '%'
    ? matchingRule?.optimalDownPmt * pfrAmt / 100
    : matchingRule?.optimalDownPmt;
  const recurringAmt = (pfrAmt - optDownPayment) / +matchingRule?.optimalTerm;

  useEffect(() => {
    (async () => {
      if (patientId && encounterId) {
        const matchingRuleRes = await paynowService.getEvaluateRule(patientEncounter, authToken);
        dispatch(setMatchingRule(matchingRuleRes));
      }
    })();
  }, [encounterId]);

  const handleSelectFullPay = () => {
    navigate('/webpayment/mobile-payment');
  }

  const haneleBack = () => {
    setStep(1);
  }

  const handleOtherPrograms = () => {
    setStep(2);
  }

  const handleSelectProgram = () => {
    navigate('/webpayment/mobile-payment?program=optimal');
  }

  const handleSelectOther = () => {
    navigate('/webpayment/no-payment-programs');
  }

  const FullPaymentOption = () => (
    <>
      <Grid container className="mt-4">
        <Grid container direction="row" className="table-header">
          <Grid item xs={6} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="center">Total Estimate</Typography>
          </Grid>
          <Grid item xs={6} className="mt-2 pr-2">
            <Typography variant="subtitle2" textAlign="center">Balance</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="center">{formatNumberToUSD(pfrAmt)}</Typography>
          </Grid>
          <Grid item xs={6} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="center">{formatNumberToUSD(pfrAmt)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-around" spacing={1}>
        <Grid item xs={12} md={6} className="mt-2">
          <Button onClick={handleSelectFullPay} fullWidth>
            Pay in Full
          </Button>
        </Grid>
        {matchingRule?.ruleId !== 0 && (
          <Grid item xs={12} md={6} className="mt-2">
            <MUIButton onClick={handleOtherPrograms} fullWidth variant="text" sx={{ textTransform: 'none' }}>
              Can't afford?
            </MUIButton>
          </Grid>
        )}
      </Grid>
    </>
  )

  const OptimalPaymentProgram = () => (
    <>
      <Grid container className="mt-4">
        <Grid container direction="row" className="table-header">
          <Grid item xs={4} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="left">Down Payment</Typography>
          </Grid>
          <Grid item xs={4} className="mt-2">
            <Typography variant="subtitle2" textAlign="center">Term</Typography>
          </Grid>
          <Grid item xs={4} className="mt-2 pr-2">
            <Typography variant="subtitle2" textAlign="right">Recurring Amount</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={4} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="left">{formatNumberToUSD(optDownPayment)}</Typography>
          </Grid>
          <Grid item xs={4} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="center">
              {matchingRule?.optimalTerm} {+matchingRule?.optimalTerm > 1 ? 'months' : 'month'}
            </Typography>
          </Grid>
          <Grid item xs={4} className="mt-2 pl-2">
            <Typography variant="subtitle2" textAlign="right">{formatNumberToUSD(recurringAmt)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center">
        <Typography variant="subtitle2" className={classes.fullWidth} textAlign="center">
          The first recurring payment will occur on {moment().add(1, 'months').format('MM/DD/YYYY')} and each month thereafter until the balance is paid in full
        </Typography>
      </Grid>
      <Grid container justifyContent="space-around" spacing={1}>
        <Grid item xs={12} md={6} className="mt-2">
          <Button onClick={handleSelectProgram} fullWidth>
            Pay with Plan
          </Button>
        </Grid>
        <Grid item xs={12} md={6} className="mt-2">
          <MUIButton onClick={handleSelectOther} fullWidth variant="text" sx={{ textTransform: 'none' }} >
            Can't afford?
          </MUIButton>
        </Grid>
      </Grid>
    </>
  )

  return (
    <Paper className="payment-wrapper">
      <Container>
        {step > 1 && (
          <MUIButton variant="text" onClick={haneleBack}>
            <ArrowBackIosIcon /> Back
          </MUIButton>
        )}
        <Typography variant="h3" className="pt-2" align="center" color="primary">
          Payment Program
        </Typography>
        <Grid container>
          <Grid item xs={6} className="mt-2">
            <Typography variant="subtitle2">
              Total Estimate
            </Typography>
          </Grid>
          <Grid item xs={6} className="mt-2">
            <Typography variant="subtitle2" align="right">
              {formatNumberToUSD(pfrAmt)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              Taken To Date
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" align="right">
              {formatNumberToUSD(pfrCollected)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">
              Remaining Balance
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" align="right">
              {formatNumberToUSD(remainingPfr)}
            </Typography>
          </Grid>
        </Grid>
        {step === 1
          ? <FullPaymentOption />
          : <OptimalPaymentProgram />
        }
      </Container>
    </Paper>
  )
}

export default MobilePaymentProgramModal;