
export interface PatientNotes {
  archivedByUserId?: string,
  completedByUserId?: string
  patientNotesId:number,
  patientId: number,
  patientEncounterId: number,
  noteText?: string,
  isReminder?: boolean,
  isCompleted?: boolean,
  isArchived?:boolean,
  completionDueDt: string,
  noteDt: string,
  completionAtDt?: string,
  isVobReminder?: boolean;
}

export const emptyPatientNote: PatientNotes = {
  patientId: 0,
  patientEncounterId: 0,
  noteText:"",
  noteDt: "",
  completionDueDt: "",
  patientNotesId:0,
  isReminder:false,
  completionAtDt: "",
  isVobReminder: false,
}

