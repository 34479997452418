import { AlertModal, listItemData, LoadingOverlay, RadioListItemGroup } from '@finpay-development/shared-components';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getClient } from '../../../../implementation-specialist/state/clients/implementation-clients-thunk';
import { calculateDiscount, formatNumberToUSD } from '../../../../shared/calculators';
import { blankClientBusinessRules, ClientBusinessRules } from '../../../../shared/model/client';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { fullPay } from '../../../../shared/validation/schemas';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PatientPaymentSchedule } from '../../models/patient-payment.schedule';
import { PfrDownPaymentAmt } from '../../../../shared/components/pfr-downpayment';

interface fullPayProps {
  enableSaveButtonCallback: (enableSave: boolean) => void,
  isRuleLoading: boolean,
  isEdit: boolean,
  fullPaySaveClicked: boolean,
  handleSaveFullPay: (paymentProgram: PatientPaymentProgram) => void,
};

const FullPay = (props: fullPayProps) => {
  const {
    enableSaveButtonCallback, isRuleLoading, isEdit, fullPaySaveClicked, handleSaveFullPay
  } = props;

  const [listOptions, setListOptions] = useState([{} as listItemData]);
  const [selectedValuesEdit, setSelectedValuesEdit] = useState({} as any);
  const [isRemindDiscountModalOpen, setIsRemindDiscountModalOpen] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    currentClientBusinessRule: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules as ClientBusinessRules
    }),
    businessRulesLoading: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoading
    }),
  }
  const {
    selectedEncounter, patientPaymentProgram,
    currentClientBusinessRule, businessRulesLoading
  } = stateFields;

  const isPrecare = ((selectedEncounter?.timingRisk?.timingRiskId === 1) || (selectedEncounter?.timingRisk?.timingRiskId === 2))
  const pfrAmt = selectedEncounter?.pfrAmt ? selectedEncounter.pfrAmt : 0;
  const getDiscount = () => {
    if (isPrecare) {
      return currentClientBusinessRule.preCareFullPayMaxDiscount
    } else { // discharged
      return currentClientBusinessRule.postCareFullPayMaxDiscount
    }
  }
  const maxDiscount = getDiscount();

  const formRef: any = useRef();

  const isLoading = isRuleLoading || businessRulesLoading;
  const doesBusinessRulesExist = !!((currentClientBusinessRule !== blankClientBusinessRules) &&
    (currentClientBusinessRule?.postCareFullPayMaxDiscount || currentClientBusinessRule?.preCareFullPayMaxDiscount))

  const generateListData = () => {
    const optionsList = []
    const pfrAmt = selectedEncounter?.preDiscountPfrAmt ? selectedEncounter?.preDiscountPfrAmt : selectedEncounter?.pfrAmt;
    if (isPrecare) {
      const precareDiscount = calculateDiscount(pfrAmt, maxDiscount ? maxDiscount : 0)
      optionsList.push(
        {
          id: 1,
          label: `${currentClientBusinessRule?.preCareFullPayMaxDiscount}% Full Pay Discount`,
          endOfRowLabel: `${formatNumberToUSD(precareDiscount)}`,
          value: '1'
        }
      )
    } else {

      let index = 0;
      for(let item = 0; item <= currentClientBusinessRule.postCareFullPayMaxDiscount; item += 10) {
        const gap = currentClientBusinessRule.postCareFullPayMaxDiscount as number - item;
        const dischargedDiscount = calculateDiscount(pfrAmt, item)
        optionsList.push(
          {
            id: index,
            label: `${item}% Full Pay Discount`,
            endOfRowLabel: `${formatNumberToUSD(dischargedDiscount)}`,
            value: `${index}`
          }
        )
        if (gap < 10) {
          // we need to push another item, and it will be the last one. (but we don't want to push a duplicate, so we have duplicate checking.)
          item += gap;
          const recalculatedDiscount = calculateDiscount(pfrAmt, item)
          if (
            optionsList.some(
              (discount: {
                id: number;
                label: string;
                endOfRowLabel: string;
                value: string;
              }) =>
                discount.endOfRowLabel !== `${formatNumberToUSD(recalculatedDiscount)}`
            )
          ) {
            optionsList.push({
              id: (index += 1),
              label: `${item}% Full Pay Discount`,
              endOfRowLabel: `${formatNumberToUSD(recalculatedDiscount)}`,
              value: `${(index += 1)}`,
            });
          }
        }
        index ++;
      }
    }
    return optionsList;
  }

  const mapToPatientPaymentProgram = useCallback(() => {
    const currentDate = new Date();
    const formValues = formRef?.current?.values?.discount
    const formValuesObj = formValues ? JSON.parse(formValues) : {}
    const downPaymentAmt = formValuesObj?.endOfRowLabel &&
      parseFloat(formValuesObj.endOfRowLabel.slice(formValuesObj.endOfRowLabel.indexOf('$') + 1, formValuesObj.endOfRowLabel.length).replace(/,/g, ''))

    const terms = 0;
    const recurringPaymentAmt = 0;
    const pfrAmtBalance = patientPaymentProgram?.patientPaymentSchedule?.pfrBalance > 0 ? patientPaymentProgram?.patientPaymentSchedule?.pfrBalance : pfrAmt;

    const patientPaymentSchedule = {
      patientPaymentScheduleId: patientPaymentProgram?.patientPaymentSchedule?.patientPaymentScheduleId ?? undefined,
      pfrAmt: selectedEncounter?.pfrAmt,
      pfrBalance: pfrAmtBalance,
      paymentFreq: 'F',
      downPmtAmt: downPaymentAmt,
      terms: terms,
      remainingTerms: terms,
      paymentDueAmt: recurringPaymentAmt,
      scheduleStatus: 'Pending',
      scheduleStartDt: isEdit ? patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt || currentDate.toISOString() : currentDate.toISOString(),
    } as PatientPaymentSchedule

    const paymentProgram = {
      patientPaymentProgramId: isEdit ? patientPaymentProgram?.patientPaymentProgramId : 0,
      isHighRisk: false,
      isPaidInFull: true, // TODO: DEPRECATED - use patientPaymentSchedule?.paymentFreq === 'F' for full pay plans
      downPmtAmt: downPaymentAmt,
      isACH: false,
      areDocsSigned: false,
      patientPaymentSchedule: patientPaymentSchedule,
    } as PatientPaymentProgram

    return paymentProgram;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
      pfrAmt, isEdit, patientPaymentProgram?.patientPaymentProgramId,
      patientPaymentProgram?.patientPaymentSchedule?.scheduleStartDt
    ])

  const initialValuesEdit = {
    discount: JSON.stringify({
      ...selectedValuesEdit
    })
  }

  const initialValues = {
    discount: JSON.stringify({
      id: 1,
      label: '0% Full Pay Discount',
      endOfRowLabel: `${formatNumberToUSD(selectedEncounter?.pfrAmt)}`,
      value: '1'
    }),
  }

  useEffect(() => {
    if (fullPaySaveClicked) {
      const paymentProgram = mapToPatientPaymentProgram();
      handleSaveFullPay(paymentProgram);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPaySaveClicked, mapToPatientPaymentProgram])

  useEffect(() => {
    if (!doesBusinessRulesExist) {
      dispatch(getClient(selectedEncounter.clientId))
    }
  }, [dispatch, doesBusinessRulesExist, selectedEncounter])

  useEffect(() => {
    if (doesBusinessRulesExist && selectedEncounter?.timingRisk && selectedEncounter?.payorRisk &&
        ((currentClientBusinessRule?.preCareFullPayMaxDiscount > 0) || (currentClientBusinessRule?.postCareFullPayMaxDiscount > 0))) {
      setListOptions(() => generateListData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doesBusinessRulesExist, currentClientBusinessRule?.preCareFullPayMaxDiscount, currentClientBusinessRule?.postCareFullPayMaxDiscount])

  useEffect(() => {
    if ((!doesBusinessRulesExist && !isLoading) || isEdit) {
      enableSaveButtonCallback(true) // enable save button so that the user can save the default initialValues (no discount)
    } else if (isEdit) {
      enableSaveButtonCallback(false) // business rules exist, not loading. Make user select an option instead of allowing them to submit the initial values.
    }
  }, [doesBusinessRulesExist, isLoading, isEdit, enableSaveButtonCallback]);

  useEffect(() => {
    if (listOptions?.length > 0 && listOptions[0]?.endOfRowLabel) {
      const matchingInitialVal = listOptions.find((listOption: listItemData) => (
        listOption?.endOfRowLabel === `${formatNumberToUSD(patientPaymentProgram?.downPmtAmt)}`)
      )
      if (matchingInitialVal) {
        const initialValsObj = {
          value: matchingInitialVal.value,
          label: matchingInitialVal.label,
          endOfRowLabel: matchingInitialVal.endOfRowLabel
        }
        setSelectedValuesEdit(initialValsObj)
      }
    }
  }, [listOptions, patientPaymentProgram?.downPmtAmt]);



  const validationSchema = Yup.object(
    fullPay
  );

  function checkIfIsValid(value: {discount: any}) {
    validationSchema
      .validate(value)
      .then(() => {
        if (JSON.parse(value?.discount)?.value > 0) {
          setIsRemindDiscountModalOpen(true); // warn the user to adjust PFR if they are applying a discount.
        }
        enableSaveButtonCallback(true);
      })
      .catch((err) => {
        enableSaveButtonCallback(false);
      });
  }

  const header = (
    <>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item xs={8}>
          <Typography variant="subtitle2">
            Patient Financial Responsibility
          </Typography>
        </Grid>
        <Box justifyContent="flex-end" className="pr-1 mt-2">
          <Grid item xs={4}>
            <Typography variant="subtitle2">
              {`${formatNumberToUSD(selectedEncounter?.preDiscountPfrAmt ? selectedEncounter?.preDiscountPfrAmt : selectedEncounter?.pfrAmt)}`}
            </Typography>
          </Grid>
        </Box>
        <PfrDownPaymentAmt selectedEncounter={selectedEncounter} labelPadding={2} amountMargin={2} amountPadding={0} />
      </Grid>
    </>
  )

  const fullPayContents = (
    <div className={'px-2 py-2 w-100'}>
      {header}
      <Divider />
      <Formik
        innerRef={formRef}
        initialValues={isEdit ? initialValuesEdit : initialValues}
        validationSchema={validationSchema}
        validate={checkIfIsValid}
        onSubmit={() => {}}
      >
        {(formik) => (
          selectedEncounter?.pfrAmt && doesBusinessRulesExist && listOptions?.length > 0 && (
            <Form>
              <RadioListItemGroup
                disableShading
                data={listOptions}
                name="discount"
                direction="column"
                formik={formik}
              />
            </Form>
          )
        )}
      </Formik>
    </div>
  )

  return (
    <>
      {isLoading ? ( // loading overlay if business rules or rule is being fetched
        <Box display="flex" justifyContent="center">
          <LoadingOverlay />
        </Box>
      ) : fullPayContents}
      {isRemindDiscountModalOpen && (
        <AlertModal
          isOpen={isRemindDiscountModalOpen}
          handleCloseCallback={() => setIsRemindDiscountModalOpen(false)}
          includeCancelButton={false}
          contentText="The discount for a full pay is not automatically calculated in the payment charge. Remember to adjust the PFR to account for the full pay discount."
          titleText="PFR Discount Reminder"
          subtitleText="Action Needed"
        />
      )}
    </>
  )
}

export default FullPay;
