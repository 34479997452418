import { Paper, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Utils } from '../../utils'
import { StatusColors } from "../../enums";
import "./../../../scss/components/_statusCard.scss";
import favicon from "src/assets/images/favicon.png";

interface StatusCardProps {
  statusText?: string | number;
  statusColor: StatusColors;
  title: string;
  subtitle: string;
  createdDate?: string;
  onClick?: () => void;
  reminderDate?: string;
  isPreConverted?: boolean;
  admissionDate?: string;
  pfrTimingRiskPayorRisk?: string;
  riskClassName?: string;
  typeOfCareName?: string;
  handleTakePayment?: () => void;
  testId?: string;
}

function StatusCard(props: StatusCardProps) {
  const {
    statusText,
    statusColor,
    title,
    subtitle,
    onClick,
    createdDate,
    reminderDate,
    isPreConverted,
    admissionDate,
    pfrTimingRiskPayorRisk,
    riskClassName,
    typeOfCareName,
    handleTakePayment,
    testId
  } = props;

  return (
    <Paper
      onClick={onClick}
      className="status-card p-2"
      style={{ borderColor: statusColor }}
      test-id={testId}
    >
      <h3 className="subtitle2 mb-1">
        {title}
      </h3>
      <div className="card-body">
        {statusText && (
            <p>
              {statusText}
            </p>
        )}
        {createdDate && isPreConverted && (
            <p>
              {`Created ${Utils.convertISODate(new Date(createdDate), true)}`}
            </p>
        )}
        {admissionDate && (
            <p>
              {`Admitted ${Utils.convertISODate(new Date(admissionDate), true)}`}
            </p>
        )}
        {pfrTimingRiskPayorRisk && (
            <p>
              {pfrTimingRiskPayorRisk}
            </p>
        )}
        {riskClassName && (
            <p>
              {riskClassName}
            </p>
        )}
        {typeOfCareName && (
            <p>
              {typeOfCareName}
            </p>
        )}
      </div>
      {reminderDate && (
        <>
          <Divider />
          <p className="card-body pt-2">
            {`Reminder: ${Utils.convertISODate(new Date(reminderDate), true)}`}
          </p>
        </>
      )}
      {subtitle !== "None" &&
        <>
          <Divider />
          <p className="card-footer">{subtitle}</p>
        </>
      }
      {handleTakePayment && (
          <Tooltip title="Take a Payment">
              <div
                  className="tp-position"
                  onClick={e => {
                      e.stopPropagation();
                      handleTakePayment();
                  }}
              >
                <img src={favicon} alt="favicon" />
              </div>
          </Tooltip>
      )}
    </Paper>
  );
}

export default StatusCard;
