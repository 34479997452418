import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import _ from "lodash";
import { TableColumn } from "../../../../../shared/model/table-column";
import { AppDispatch } from "../../../../../shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/state/root-reducer";
import {
  TextField,
  saveCallbackStatus,
  LoadingOverlay,
} from "@finpay-development/shared-components";
import EditIcon from "@mui/icons-material/Edit";
import { PayerClaimRatesModal } from "./payer-claim-rates-modal";
import { showStatus } from "../../../../../security/state/user-slice";
import { getClientClaimRates } from "../../../../../admissions-advisor/state/risk-assessment-thunk";
import { clearClaimRatesStatus } from "../../../../../admissions-advisor/state/risk-assessment-slice";
import {
  PayerPlans,
  PayerRow,
} from "../../../../../admin-configuration/models/payers";
import { PayerClaimRate } from "../../../../../admissions-advisor/models/risk-assessment";
import { configGetPayers } from "../../../../../admin-configuration/state/admin-configuration-thunk";

function PayerClaimRates() {
  const paramId = -2;
  const [isPayerClaimRatesModalOpen, setIsPayerClaimRatesModalOpen] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState<PayerRow>();
  const [selectedPayerPlan, setSelectedPayerPlan] = useState<PayerPlans | null | undefined>();
  const [selectedClaimRate, setSelectedClaimRate] = useState<PayerClaimRate>();
  const [selectedClaimRateDescription, setSelectedClaimRateDescription] = useState("");
  const [openSubTable, setOpenSubTable] = React.useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [searched, setSearched] = useState<string>("");
  const [originalRows, setOriginalRows] = useState<PayerRow[]>();
  const [rows, setRows] = useState<PayerRow[]>();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [open] = React.useState(false);
  const [payersIsLoaded, setPayersIsLoaded] = useState(false);
  const [claimRatesIsLoaded, setClaimRatesIsLoaded] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    payers: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext?.payers
    ),
    currentClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          ?.currentClientEntityDetails
    ),
    fullClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
    ),
    clientPayerClaimRatesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.clientPayerClaimRates
    ),
    isLoadingClientClaimRatesState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingClientClaimRates
    ),
    clientClaimRatesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .clientClaimRatesStatus
    ),
  };

  const {
    payers,
    currentClient,
    clientPayerClaimRatesState,
    isLoadingClientClaimRatesState,
    clientClaimRatesStatusState,
  } = selectors;

  const columns: TableColumn[] = [
    { id: "col-payername", label: "Payer Name", minWidth: 200 },
    { id: "col-payerstate", label: "State", minWidth: 200 },
    { id: "col-payerrate", label: "Rate", minWidth: 200 },
    { id: "col-menu", label: "", minWidth: 50 },
  ];

  const getPayers = () => {
    dispatch(configGetPayers(paramId));
    dispatch(
      getClientClaimRates({
        paramId: paramId,
        clientId: currentClient.clientId,
      })
    );
  };

  useEffect(() => {
    getPayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (payers && payers.length) {
      setOriginalRows(payers);
      setRows(payers);
      setPayersIsLoaded(true);
    }
  }, [payers]);

  useEffect(() => {
    if (
      !isLoadingClientClaimRatesState &&
      clientClaimRatesStatusState === saveCallbackStatus.success
    ) {
      setClaimRatesIsLoaded(true);
      clearClaimRatesStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientClaimRatesStatusState]);

  useEffect(() => {
    if (payersIsLoaded && claimRatesIsLoaded) {
      setIsLoading(false);
      setOriginalRows(payers);
      setRows(payers);
    }
  }, [payersIsLoaded, claimRatesIsLoaded, payers]);

  const handleExpandSubTable = (rowId: number) => {
    if (openSubTable === rowId) {
      setOpenSubTable(-1);
    } else {
      setOpenSubTable(rowId);
    }
  };

  const getPayerClaimRate = (payorId: number): number | undefined | null => {
    let claimRate: number | undefined | null = 0;
    let payerClaimRate = clientPayerClaimRatesState?.find(
      (claimrt) => claimrt.cfgPayorId === payorId && !claimrt.cfgPayorPlanId
    );
    if (payerClaimRate) {
      claimRate = payerClaimRate.claimRatePct;
    }
    return claimRate;
  };

  const getPlanClaimRate = (payorPlanId: number, payorId: number) => {
    let claimRateJsx = <></>;
    let planClaimRate = clientPayerClaimRatesState?.find(
      (claimrt) => claimrt.cfgPayorPlanId === payorPlanId
    );

    if (planClaimRate) {
      claimRateJsx = planClaimRate.claimRatePct ? (
        <>{planClaimRate.claimRatePct.toFixed(1)} %</>
      ) : (
        <></>
      );
    } else {
      let payerClaimRate = clientPayerClaimRatesState?.find(
        (claimrt) => claimrt.cfgPayorId === payorId
      );
      if (payerClaimRate) {
        claimRateJsx = payerClaimRate.claimRatePct ? (
          <>
            {payerClaimRate.claimRatePct?.toFixed(1)} %{" "}
            <small>(inherited from Payer)</small>
          </>
        ) : (
          <></>
        );
      } else {
        claimRateJsx = (
          <>
            0.0 % <small>(inherited from Payer)</small>
          </>
        );
      }
    }
    return claimRateJsx;
  };

  const handleEditPayerClaimRate = (payerId: number) => {
    let payerClaimRate = clientPayerClaimRatesState?.find(
      (claimrt) => claimrt.cfgPayorId === payerId && !claimrt.cfgPayorPlanId
    );
    let payerItem = payers?.find((pay) => pay.payorId === payerId);
    if (payerClaimRate) {
      setSelectedClaimRate(payerClaimRate);
    } else {
      setSelectedClaimRate({
        clientId: currentClient.clientId,
        cfgPayorId: payerItem ? payerItem.payorId : undefined,
        cfgPayorPlanId: null,
        claimRatePct: undefined,
      });
    }
    if (payerItem) {
      setSelectedPayerPlan(null);
      setSelectedPayer(payerItem);
      setSelectedClaimRateDescription(`Payer: ${payerItem.payorName}`);
    }
    setIsPayerClaimRatesModalOpen(true);
  };

  const handleEditPlanClaimRate = (payerId: number, planId: number) => {
    let planClaimRate = clientPayerClaimRatesState?.find(
      (claimrt) => claimrt.cfgPayorPlanId === planId
    );
    let payerItem = payers?.find((pay) => pay.payorId === payerId);
    if (planClaimRate) {
      setSelectedClaimRate(planClaimRate);
    } else {
      setSelectedClaimRate({
        clientId: currentClient.clientId,
        cfgPayorId: payerItem ? payerItem.payorId : undefined,
        cfgPayorPlanId: planId,
        claimRatePct: undefined,
      });
    }
    if (payerItem) {
      setSelectedPayer(payerItem);
      let selectedPlan = payerItem.payorPlans.find(
        (plan) => plan.payorPlanId === planId
      );
      setSelectedPayerPlan(selectedPlan);
      setSelectedClaimRateDescription(
        `Payer: ${payerItem.payorName}, Plan: ${selectedPlan?.planName}`
      );
    }
    setIsPayerClaimRatesModalOpen(true);
  };

  function getStripedStyle(index: number) {
    return { background: index % 2 ? "#F5F7F9" : "white" };
  }

  const requestSearch = (searchedVal: string) => {
    if (typeof searchedVal === "string") {
      setSearched(searchedVal);
    }
    const filteredRows = originalRows?.filter((row) => {
      return row?.payorName
        ?.toLowerCase()
        ?.includes(searchedVal?.toLowerCase());
    });
    setRows(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  function handlePayerClaimRatesModalCancel() {
    setIsPayerClaimRatesModalOpen(false);
  }

  async function handlePayerClaimRatesModalSubmit() {
    setIsPayerClaimRatesModalOpen(false);
    dispatch(showStatus("Claim Rate Updated Successfully"));
  }

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <Box
            style={{
              margin: "0.5em",
              padding: "1.0em",
              borderBottom: "1px solid #cccccc",
            }}
          >
            <Grid container justifyContent="space-between">
              <Grid>
                <Box>
                  <Typography variant="subtitle2">
                    Claim Rate by Payer
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Search by payer name"
                  name="searched"
                  value={searched}
                  onChange={(event: any) => requestSearch(event?.target?.value)}
                  onBlur={() => cancelSearch()}
                  startAdornment={<SearchIcon />}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            style={{
              margin: "0.5em",
              padding: "1.0em",
              borderBottom: "1px solid #cccccc",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                aria-label="collapsible table"
                className="table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          padding: "0.5em",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.slice!(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row: PayerRow, index: number) => (
                    <React.Fragment>
                      <TableRow
                        style={{ ...getStripedStyle(index) }}
                        key={index}
                      >
                        <TableCell component="th" scope="row">
                          {row?.payorName}
                        </TableCell>
                        <TableCell>{row?.stateCode}</TableCell>
                        <TableCell>
                          {getPayerClaimRate(row?.payorId)?.toFixed(1)} %
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            disabled={row?.payorPlans?.length === 0}
                            onClick={() => handleExpandSubTable(row.payorId)}
                            style={{ fontSize: "1.5rem" }}
                          >
                            {row?.payorPlans?.length} Insurance plans{" "}
                            {open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>

                          <IconButton
                            color="primary"
                            title="Edit"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleEditPayerClaimRate(row?.payorId);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={openSubTable === row.payorId}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Table size="small" aria-label="purchases">
                                <TableHead>
                                  <TableRow>
                                    <TableCell width="50%">Plan name</TableCell>
                                    <TableCell width="40%">Rate</TableCell>
                                    <TableCell width="10%"></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {_.sortBy(row.payorPlans, "planName").map(
                                    (planRow: PayerPlans) => (
                                      <TableRow key={planRow.payorPlanId}>
                                        <TableCell component="td" scope="row">
                                          {planRow.planName}
                                        </TableCell>
                                        <TableCell component="td" scope="row">
                                          {getPlanClaimRate(
                                            planRow?.payorPlanId,
                                            row.payorId
                                          )}
                                        </TableCell>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          align="right"
                                        >
                                          <IconButton
                                            color="primary"
                                            title="Edit"
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                              handleEditPlanClaimRate(
                                                row?.payorId,
                                                planRow?.payorPlanId
                                              );
                                            }}
                                            size="large"
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>

              <Grid container justifyContent="space-between">
                <Grid item></Grid>
                <Grid item>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      50,
                      100,
                      { value: rows?.length!, label: "All" },
                    ]}
                    component="div"
                    count={rows?.length!}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"View"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TableContainer>
            {isPayerClaimRatesModalOpen && (
              <PayerClaimRatesModal
                open={isPayerClaimRatesModalOpen}
                clientId={currentClient.clientId}
                handlePayerClaimRatesModalCancel={
                  handlePayerClaimRatesModalCancel
                }
                handlePayerClaimRatesModalSubmit={
                  handlePayerClaimRatesModalSubmit
                }
                claimRateItem={selectedClaimRate!}
                payerItem={selectedPayer!}
                payerPlanItem={selectedPayerPlan}
                claimRateDescription={selectedClaimRateDescription}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default PayerClaimRates;
