 export const planType = {
    name: "Plan Type",
    fullPayment: {label: "Full Payment", value:"Full Payment"},
    monthly: {label: "Monthly Payment", value:"Month"},
    weekly: {label: "Weekly Payment", value:"Week"},
    exception: {label: "Exception", value:"Exception"},
    objectName: "planType"
}

export const sentToProvider = {
    name: "Sent To Provider",
    true: {label: "True", value:"True"},
    false: {label: "False", value:"False"},
    objectName: "sentToProvider"
}

export const automatedPaymentStatus = {
    name: "Automated Payment Status",
    sent: {label: "Sent", value:"Sent"},
    retrying: {label: "Retrying", value:"Retrying"},
    failed: {label: "Failed", value:"Failed"},
    objectName: "automatedPaymentStatus"
}

export const transactionType = { 
    name: "Transaction Type",
    atFacility:{label: "At Facility", value: "At Facility"},
    downPayment:{label: "Down Payment", value: "Down Payment"},
    fullPayment: {label: "Full Payment", value: "Full Payment"},
    lostdispute: {label: "Lost Dispute", value: "Lost Dispute"},
    recurring:  {label: "Recurring", value: "Recurring"},
    refund: {label: "Refund", value: "Refund"},
    specialist: {label: "Specialist", value: "Specialist"},
    objectName: "transactionType"
}



export const patientFinancialStatus = {
    name: "Patient Financial Status",
    new: {label: "New - Potential Patient, Not Contacted", value:"New - Potential Patient, Not Contacted"}, 
    converted: {label: "Converted", value:"Converted"},
    convertedAwaitingAuth: {label: "Converted - Pending Awaiting Authorizations", value:"Converted, Pending - Awaiting Authorizations"},
    convertedAwaitingVer: {label: "Converted - Pending Awaiting ACH Verification", value:"Converted, Pending - Awaiting ACH Verification"},
    closedUnqualified: {label: "Closed - Unqualified", value:"Closed - Unqualified"},
    closedNeverAdmitted: {label: "Closed - Patient Never Admitted", value:"Closed - Patient Never Admitted"},
    closedFailedToEngage: {label: "Closed - Patient Failed to Engage", value:"Closed - Patient Failed to Engage"},
    objectName: "patientFinancialStatus"
}


export const paymentWorkflowStatus = {
    name: "Payment Workflow Status",
    new: {label: "New - Not Started", value:"13,7"},
    followUpEducationCurriculum: {label: "Follow Up Needed - Education Curriculum", value:"14,8"},
    followUpPaymentCurriculum: {label: "Follow Up Needed - Payment Curriculum", value:"14,10"},
    followUpAwaitingConsent: {label: "Follow Up Needed - Awaiting Consent Docs", value:"14,11"},
    followUpPatientChampion: {label: "Follow Up Needed - Patient Champion", value:"14,12"},
    followUpAwaitingHRE: {label: "Follow Up Needed - Awaiting HRE Approval", value:"14,13"},
    followUpPaymentMethods: {label: "Follow Up Needed - Payment Methods Needed", value:"14,14"},
    followUpUserSelectable: {label: "Follow Up Needed - User Selectable", value:"14,9"},
    followUp2ndAttempt: {label: "Follow Up Needed - No Contact 2nd Attempt", value:"14,15"},
    followUp3ndAttempt: {label: "Follow Up Needed - No Contact 3nd Attempt", value:"14,16"},
    followUpAwaitingClient: {label: "Follow Up Needed - Awaiting Client", value:"14,17"},
    closedErrorBadFile: {label: "Closed - Error (Bad File)", value:"15,18"},
    closedPatientFailed: {label: "Closed - Patient Failed to Engage", value:"15,19"},
    closedRejectedByPatient: {label: "Closed - HRE Rejected by Patient", value:"15,20"},
    closedRejectedByClient: {label: "Closed - HRE Rejected by Client", value:"15,21"},
    closedNeverAddmitted: {label: "Closed - Patient Never Admitted", value:"15,22"},
    objectName: "paymentWorkflowStatus"
}



export const riskClass = {
    name: "Risk Class",
    inNetworkPreCare:  {label: "In Network - Pre Care", value:"1,1"},
    inNetworkInCare: {label: "In Network - In Care", value:"1,2"},
    inNetworkDischarged: {label: "In Network - Discharged", value:"1,3"},
    outNetworkPreCare: {label: "Out of Network - Pre Care", value:"2,1"},
    outNetworkInCare: {label: "Out of Network - In Care", value:"2,2"},
    outNetworkDischarged: {label: "Out of Network - Discharged", value:"2,3"},
    selfPayPreCare: {label: "Self-Pay - Pre Care", value:"3,1"},
    selfPayInCare: {label: "Self-Pay - In Care", value:"3,2"},
    selfPayDischarged: {label: "Self-Pay - Discharged", value:"3,3"},
    objectName: "riskClass"
}
export const initialRiskClasses = {
    name: "Initial Risk Classes",
    SelfPay: {label: "Risk Class 1 - Self Pay", value: "1"},
    InNetworkHighPFR: {label: "Risk Class 2 - In Network High PFR", value: "2"},
    ConvertingToSelfPay: {label: "Risk Class 3 - Converting to Self Pay", value: "3"},
    ScheduledBeforeClearance: {label: "Risk Class 4 - Scheduled Prior to Financial Clearance", value: "4"},
    AdmittedBeforeClearance: {label: "Risk Class 5 - Admitted Prior to Financial Clearance", value: "5"},
    OutOfNetworkHighPFR: {label: "Risk Class 6 - Out of Network High PFR", value: "6"},
    PriorBalance: {label: "Risk Class 7 - Prior Balance", value: "7"},
    LowPFR: {label: "Risk Class 8 - Low PFR", value: "8"},
    LicensedProfessionalRecoveryProgram: {label: "Risk Class 9 - Licensed Professional Recovery Program", value: "9"},
    DischargedBeforeFinalClearance: {label: "Risk Class 10 - Discharged Prior to Financial Clearance", value: "10"},
    StepDownToOutpatient: {label: "Risk Class 11 - Step Down to Outpatient", value: "11"},
    PaidToPatient: {label: "Risk Class 12 - Paid to Patient (P2P)", value: "12"},
    objectName: "initialRiskClasses"
}
 export const modifiedRiskClasses = {
     name: "Modified Risk Classes",
     SelfPay: {label: "Risk Class 1 - Self Pay", value: "1"},
     InNetworkHighPFR: {label: "Risk Class 2 - In Network High PFR", value: "2"},
     ConvertingToSelfPay: {label: "Risk Class 3 - Converting to Self Pay", value: "3"},
     ScheduledBeforeClearance: {label: "Risk Class 4 - Scheduled Prior to Financial Clearance", value: "4"},
     AdmittedBeforeClearance: {label: "Risk Class 5 - Admitted Prior to Financial Clearance", value: "5"},
     OutOfNetworkHighPFR: {label: "Risk Class 6 - Out of Network High PFR", value: "6"},
     PriorBalance: {label: "Risk Class 7 - Prior Balance", value: "7"},
     LowPFR: {label: "Risk Class 8 - Low PFR", value: "8"},
     LicensedProfessionalRecoveryProgram: {label: "Risk Class 9 - Licensed Professional Recovery Program", value: "9"},
     DischargedBeforeFinalClearance: {label: "Risk Class 10 - Discharged Prior to Financial Clearance", value: "10"},
     StepDownToOutpatient: {label: "Risk Class 11 - Step Down to Outpatient", value: "11"},
     PaidToPatient: {label: "Risk Class 12 - Paid to Patient (P2P)", value: "12"},
     objectName: "modifiedRiskClasses"
 }
 export const convertedRiskClasses = {
     name: "Converted Risk Classes",
     SelfPay: {label: "Risk Class 1 - Self Pay", value: "1"},
     InNetworkHighPFR: {label: "Risk Class 2 - In Network High PFR", value: "2"},
     ConvertingToSelfPay: {label: "Risk Class 3 - Converting to Self Pay", value: "3"},
     ScheduledBeforeClearance: {label: "Risk Class 4 - Scheduled Prior to Financial Clearance", value: "4"},
     AdmittedBeforeClearance: {label: "Risk Class 5 - Admitted Prior to Financial Clearance", value: "5"},
     OutOfNetworkHighPFR: {label: "Risk Class 6 - Out of Network High PFR", value: "6"},
     PriorBalance: {label: "Risk Class 7 - Prior Balance", value: "7"},
     LowPFR: {label: "Risk Class 8 - Low PFR", value: "8"},
     LicensedProfessionalRecoveryProgram: {label: "Risk Class 9 - Licensed Professional Recovery Program", value: "9"},
     DischargedBeforeFinalClearance: {label: "Risk Class 10 - Discharged Prior to Financial Clearance", value: "10"},
     StepDownToOutpatient: {label: "Risk Class 11 - Step Down to Outpatient", value: "11"},
     PaidToPatient: {label: "Risk Class 12 - Paid to Patient (P2P)", value: "12"},
     objectName: "convertedRiskClasses"
 }
 export const finalRiskClasses = {
     name: "Final Risk Classes",
     SelfPay: {label: "Risk Class 1 - Self Pay", value: "1"},
     InNetworkHighPFR: {label: "Risk Class 2 - In Network High PFR", value: "2"},
     ConvertingToSelfPay: {label: "Risk Class 3 - Converting to Self Pay", value: "3"},
     ScheduledBeforeClearance: {label: "Risk Class 4 - Scheduled Prior to Financial Clearance", value: "4"},
     AdmittedBeforeClearance: {label: "Risk Class 5 - Admitted Prior to Financial Clearance", value: "5"},
     OutOfNetworkHighPFR: {label: "Risk Class 6 - Out of Network High PFR", value: "6"},
     PriorBalance: {label: "Risk Class 7 - Prior Balance", value: "7"},
     LowPFR: {label: "Risk Class 8 - Low PFR", value: "8"},
     LicensedProfessionalRecoveryProgram: {label: "Risk Class 9 - Licensed Professional Recovery Program", value: "9"},
     DischargedBeforeFinalClearance: {label: "Risk Class 10 - Discharged Prior to Financial Clearance", value: "10"},
     StepDownToOutpatient: {label: "Risk Class 11 - Step Down to Outpatient", value: "11"},
     PaidToPatient: {label: "Risk Class 12 - Paid to Patient (P2P)", value: "12"},
     objectName: "finalRiskClasses"
 }


export const accountHolderStatus = {
    name: "Account Holder Status",
    converted: {label: "Converted", value:"12,31"},
    goodStanding: {label: "Good Standing", value:"8,32"},
    followUpBalanceAdjustment: {label: "Follow Up Needed - Balance Adjustment Required", value:"6,36"},
    followUpPendingAuthorization: {label: "Follow Up Needed - Converted Pending Authorization", value:"6,30"},
    atRiskMissedPayment: {label: "At Risk - Missed Payment", value:"10,25"},
    atRiskPastDye: {label: "At Risk - Has Past Due Amount", value:"10,24"},
    atRiskOnHold: {label: "At Risk - On Hold", value:"10,26"},
    atRiskBalanceAdj: {label: "At Risk - Balance Adjustment Required", value:"10,27"},
    atRiskAwaitingAuth: {label: "At Risk - Awaiting Authorization", value:"17,33"},
    closedDefault: {label: "Closed - Default", value:"7,37"},
    closedCanceled: {label: "Closed - Canceled", value:"7,38"},
    closedError: {label: "Closed - Error (Bad File)", value:"7,27"},
    closedPatientFailedToEngage: {label: "Closed - Patient Failed to Engage", value:"7,28"},
    closedPatientNeverAddmitted: {label: "Closed - Patient Never Admitted", value:"7,29"},
    paid: {label: "Paid", value:"16,23"},
    objectName: "accountHolderStatus"
}


