import {Button, Divider, Grid, IconButton, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useState} from "react";
import {PatientEncounter} from "../../models/patient-encounter";
import {PatientS3document} from "../../models/patient-s3document";
import {useDispatch, useSelector} from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { downloadS3Document, deleteS3Document } from "../../../state/patient-thunk";
import {RootState} from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import { Utils } from "../../../../shared/utils";

interface DocumentS3ListProps {
  documents: any;
  selectedEncounter: PatientEncounter;
}

export const S3DocumentsListItem = ({documents, selectedEncounter} : DocumentS3ListProps) => {

  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isUploadingS3 } = useSelector((state: RootState) => state.patientContext);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectDocument, setSelectedDocument] = useState<string>()

  const handleS3DocumentClick = (document: string) => {
    dispatch(downloadS3Document({
      patientId: selectedEncounter.patientId,
      encounterId: selectedEncounter.patientEncounterId,
      s3DocumentName: document,
    }))
  }

  const handleS3DocumentDelete = () => {
    dispatch(deleteS3Document({
      patientId: selectedEncounter.patientId,
      encounterId: selectedEncounter.patientEncounterId,
      s3DocumentName: selectDocument,
    }))
    setIsOpen(false)
  }

  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement>,
    status: boolean
  ) => {
    setIsOpen(status)
  }

  const handleS3DocMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: string
  ) => {
    setSelectedDocument(documentId)
    setIsOpen(true)
  }

  return <>
  {documents?.length > 0 && documents?.map((document: PatientS3document, index: number) => (
    <Grid container spacing={2} className="py-2" key={index}>
      <Grid item xs={3}>
        <Typography variant="body1">
          { Utils.convertDate(new Date(document.timeUploadedToS3), true) }
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography className="link"
          noWrap={true}
          color="primary" variant="body1"
          onClick={() => handleS3DocumentClick(document.s3DocumentName)}>
            {document.documentName}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">
          { 'N/A' }
        </Typography>
      </Grid>
      <Grid item xs={2} style={{maxHeight: 10}}>
        <Typography variant="body1" noWrap={true}>
          {document.nameOfDocumentUploader}
        </Typography>
      </Grid>
      <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}}>
        <>
          <IconButton
            aria-haspopup="true"
            onClick={(e) => handleS3DocMenuClick(e, document.s3DocumentName)}
            size="large">
            <DeleteIcon/>
          </IconButton>
        </>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  ))}
  <Dialog
    open={isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Delete File"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete this file?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleS3DocumentDelete} color="primary">
        Delete
      </Button>
      <Button onClick={(e) => handleClose(e, false)} color="primary" autoFocus>
        Cancel
      </Button>
    </DialogActions>
  </Dialog>
  </>;
}
