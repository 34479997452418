import {Paper, Typography} from '@mui/material';

interface ErrorMessageProps {
    errorMessageHeader: string;
    errorMessageContent: JSX.Element;
    errorStatus: boolean;
}
  
function FinMobileErrorMsg(props: ErrorMessageProps) {
    
    const { errorMessageHeader, errorMessageContent, errorStatus } = props;

    return (
        <>
        {errorStatus && 
            <Paper className="payment-wrapper">
                <Typography variant="h3" className="mobile-heading pt-2">
                    {errorMessageHeader}
                </Typography>
                <Typography className="mobile-subhead pt-2">
                    {errorMessageContent}
                </Typography>
            </Paper>
        }
        </>
    );
}

export default FinMobileErrorMsg;
