import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { formula } from '../../../shared/validation/schemas';
import { RuleCriteriaParam } from '../../models/rule';
import { clearStatus } from '../../state/admin-configuration-slice';
import { saveRuleCriteriaParam } from '../../state/admin-configuration-thunk';

interface FormulaModalProps {
  open: boolean;
  isEdit?: boolean;
  handleFormulaModalCancel: () => void;
  handleFormulaModalSubmit: (isEdit: boolean) => void;
}

export function FormulaModal(props: FormulaModalProps) {
  const { open, isEdit = false, handleFormulaModalCancel, handleFormulaModalSubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const currentRuleCriteriaParam = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.currentRuleCriteriaParam
  );

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );

  async function handleSave() {
    const formValues: RuleCriteriaParam = formRef.current.values;
    await dispatch(saveRuleCriteriaParam(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleFormulaModalSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleFormulaModalCancel();
  }

  const initialValues = {
    paramId: currentRuleCriteriaParam?.paramId,
    paramName: currentRuleCriteriaParam?.paramName,
    dataType: currentRuleCriteriaParam?.dataType,
    compareBy: currentRuleCriteriaParam?.compareBy,
    paramPropertyName: currentRuleCriteriaParam?.paramPropertyName,
    sortOrder: currentRuleCriteriaParam?.sortOrder
  };

  const validationSchema = Yup.object(formula);

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      className="modal user-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Formula</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <TextField
                    error={
                      formik.touched["paramName"] && formik.errors["paramName"]
                        ? formik.errors["paramName"]
                        : ""
                    }
                    label="Formula Name"
                    name="paramName"
                    value={formik.values.paramName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <TextField
                    select
                    error={
                      formik.touched["dataType"] &&
                      formik.errors["dataType"]
                        ? formik.errors["dataType"]
                        : ""
                    }
                    label="Data Type"
                    name="dataType"
                    value={formik.values.dataType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select a Data Type</MenuItem>
                    <MenuItem value="Integer">Integer</MenuItem>
                    <MenuItem value="String">String</MenuItem>
                    <MenuItem value="Whole Number">Whole Number</MenuItem>
                    <MenuItem value="Date">Date</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} className="mt-4">
                  <TextField
                    select
                    error={
                      formik.touched["compareBy"] && formik.errors["compareBy"]
                        ? formik.errors["compareBy"]
                        : ""
                    }
                    label="Compare By"
                    name="compareBy"
                    value={formik.values.compareBy}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select Comparison Method</MenuItem>
                    <MenuItem value="<=,>=,<,>,<>,=">&le; &ge; &lt; &gt; &ne; =</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
