import React from 'react';

interface queryStringListProps {
  urlParams: any,
}

function QueryStringList(props: queryStringListProps) {
  const { urlParams } = props;

  const queryParams = urlParams.substr(1).split('&');
  const queryStringValues = queryParams.map((param: any) => {
    const [key, value] = param.split('=');
    return { key, value };
  });

  return (
    <div>
      <h2>Query String Values:</h2>
      <ul>
        {queryStringValues.map((queryValue: any) => (
          <li key={queryValue.key}>
            {decodeURI(queryValue.key)}: {decodeURI(queryValue.value)}
          </li>
        ))}
      </ul>
    </div>
  );
}
export default QueryStringList;
