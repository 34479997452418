import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import { ClientsAndFacilities, PatientsAndAccountHolders } from '../../../implementation-specialist/models/reportsFilters';
import { formatNumberToUSDWithNegativeValues } from '../../../shared/calculators';
import { orderDirections, reportResultType, reportsType } from '../../../shared/enums';
import { Column } from '../../../shared/model/column';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { Utils } from '../../../shared/utils';
import { ChannelInfo } from '../../models/channel-payments';
import { ReportsInfo } from '../../models/reports-info';
import { adminUserHelper } from '../../services/admin-user-service';
import { setDetailedClientAndFacilityNames, setDetailedPatientAndAccountHoldersNames } from '../../state/users/admin-slice';
import { ReportFilterModal } from './report-filters-modal';


interface DetailReportModalProps {
  transactionNumber: string;
  title: string;
  open: boolean;
  handleDetailReportCancel: () => void;
  initalLoadHandler: (status: boolean) => void;
  initialLoadStatus: boolean;
}

export function DetailReportModal(props: DetailReportModalProps) {
  const {transactionNumber, title, open, handleDetailReportCancel, initialLoadStatus, initalLoadHandler} = props
  const stateFields = {
    filteredDetailedReport: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredDetailedReport
    ),
    isSettlementDetailedLoading: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isSettlementDetailedLoading
    ),
    paymentChannels: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.paymentChannels
    )
  }

  const {filteredDetailedReport, isSettlementDetailedLoading, paymentChannels} = stateFields
  let columns: Column[] = []
  let reports: any = []
  let patientsNames:string[] = []
  let accountHoldersNames:string[] = []
  let clientNames: string[] = []
  let facilityNames: string[] = []
  columns = adminUserHelper.mapToColumns(filteredDetailedReport)

  //FPS-3330 - changed column header to Billing Name for reportType="SettlementDetail" and Webpayment report with headers length=19
  if (filteredDetailedReport?.filters?.reportType === "SettlementDetail" && filteredDetailedReport?.headers?.length === 19) {
    let acctHolderNameColumn = columns.find(x => x.id === "AccountHolderName");
    if (acctHolderNameColumn !== undefined) {
      acctHolderNameColumn.label = "Billing Name";
    }
  }

  reports = adminUserHelper.mapToData(columns, filteredDetailedReport)
  const dispatch = useDispatch<AppDispatch>()
  const [columnType1, setColumnType1] = useState("")
  const [orderDirection, setOrderDirection] = useState(orderDirections.asc)
  const [valueToOrderBy, setValueToOrderBy] = useState("")
  const [availableChannelFilters, setAvailableChannelFilters] = useState<ChannelInfo[]>([]);
  const [showReportFiltersModal, setShowReportFiltersModal] = useState(false)
  let [csvData, setCsvData] = useState<Array<Array<string>>>([])
  if (
    filteredDetailedReport.filters.reportType === "SettlementSummary" ||
    filteredDetailedReport.filters.reportType === "SettlementSummaryV2"
  ) {
    const column = {
      id : "link",
      label: "Link",
      minWidth: 15
    } as Column
    columns.unshift(column)
  }

  const descendingComparator = (a: any, b: any, orderBy: any) => {
    //a and b are the complete objects and orderBy is the object property
    let firstValue = a[orderBy]
    let secondValue = b[orderBy]
    switch (columnType1) {
      case reportResultType.string:
        if(!firstValue) firstValue = ""
        if(!secondValue) secondValue = ""
        firstValue = formatStringValue(firstValue.toString())
        secondValue = formatStringValue(secondValue.toString())
        break;
      case reportResultType.integer:
        if(!firstValue) firstValue = 0
        if(!secondValue) secondValue = 0
        break;

      default:
        break;
    }

    if(secondValue === "" || secondValue === null || secondValue === undefined) return -1
    if(secondValue < firstValue) return -1
    if(secondValue > firstValue ) return 1
    if(secondValue === firstValue ) return 0
    return 1
  }

  const getComparator = (order:any , orderBy: any) => {
    return order === "desc"
      ? (a: any,b: any) => descendingComparator(a, b, orderBy)
      : (a: any,b: any) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray: any, comparator: any) => {
    const stabilizedRowArray = rowArray.map((el: any, index: number) => [el, index])
    stabilizedRowArray.sort((a: any,b: any) => {
      const order = comparator(a[0], b[0])
      if(order !== 0) return order
      return a[1] - b[1]
    })
    return stabilizedRowArray.map((el: any) => el[0])
  }

  const handleRequestSort = async (event: any, property: any) => {
    const isAscending = (valueToOrderBy === property && orderDirection === "asc")
    await setValueToOrderBy(property)
    await setOrderDirection(isAscending ? orderDirections.desc : orderDirections.asc)
    setSortedCsvData(isAscending ? orderDirections.desc : orderDirections.asc, property)
  }

  const createSortHandler = (property: any, type?: string) => (event: any) => {
    if(type) setColumnType1(type)
    handleRequestSort(event, property)
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  let formatStringValue = (value:string | any) =>{
    if(value !== null){
      value = value.trimStart()
      value = value.toUpperCase()
      return value
    }
  }

  const formatValue = (label:string, type:string | any) =>{
    switch (type) {
      case reportResultType.timeStamp:
        if(label){
          let date = new Date(label)
          label = Utils.convertISODate(date, true)
        }
        break;

      case reportResultType.bigDecimal:
        label = formatNumberToUSDWithNegativeValues(Number(label)).toString()
        break;

      default:
        break;
    }
    return label
  }

  useEffect(() => {
    let csvData: Array<Array<string>>= [];
    reports.forEach((report: ReportsInfo)=>{
      let tableValues:string[] = []
      columns.forEach(column=>{
        if (column.id !== "link"){
          let value = (report as any)[column.id];
          value = formatValue(value, column.type)
          tableValues.push(value)
          if(column.id === "PatientName" && !patientsNames.includes(formatStringValue(value)) && value !== null ){
            value = formatStringValue(value)
            patientsNames.push(value)
          }
          if(column.id === "AccountHolderName" && !accountHoldersNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            accountHoldersNames.push(value)
          }
          if(column.id === "ClientName" && !clientNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            clientNames.push(value)
          }
          if(column.id === "FacilityName" && !facilityNames.includes(formatStringValue(value)) && value !== null){
            value = formatStringValue(value)
            facilityNames.push(value)
          }
        }
      })
      csvData.push(tableValues)
    })

    const patientsAndAccountHoldersNames= {
      patientsNames: [...patientsNames.sort()],
      accountHoldersNames: [...accountHoldersNames.sort()]
    } as PatientsAndAccountHolders

    const clientAndFacilityNames= {
      clientNames: clientNames.sort(),
      facilityNames: facilityNames.sort()
    } as ClientsAndFacilities

    dispatch(setDetailedPatientAndAccountHoldersNames(patientsAndAccountHoldersNames))
    dispatch(setDetailedClientAndFacilityNames(clientAndFacilityNames));

    let tableHeaders: string[] = []
    filteredDetailedReport.headers.forEach(header => {
      tableHeaders.push(header.columnName)
    })

    //FPS-3330 - for csv data, change column header to 'Billing Name' for reportType = SettlementDetail and Webpayment report with headers length=19
    if (filteredDetailedReport?.filters?.reportType === "SettlementDetail" && filteredDetailedReport?.headers?.length === 19) {
      const idx = tableHeaders.indexOf("Account Holder Name");
      if (idx > -1) {
        tableHeaders[idx] = "Billing Name";
      }
    }

    csvData.unshift(tableHeaders)
    setCsvData(csvData)

    if(title === reportsType.SettlementDetail.toString() && reports.length > 0 && !initialLoadStatus) {
      let channelOptions: ChannelInfo[] = [];
      paymentChannels?.forEach((channel: ChannelInfo) => {
        const foundChannelType = reports.find((el: { ChannelName: string; }) => el.ChannelName === channel.paymentChannelName);
        if (foundChannelType !== undefined) {
          channelOptions.push(channel);
        };
      });
      setAvailableChannelFilters(channelOptions);
      initalLoadHandler(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDetailedReport]);

  const setSortedCsvData = async (orderDirection: string, valueToOrderBy: string) => {
    let csvData: Array<Array<string>>= [];
    const sortedReports = await sortedRowInformation(reports, getComparator(orderDirection, valueToOrderBy))
    sortedReports.forEach((report: ReportsInfo)=>{
      let tableValues:string[] = []
        columns.forEach(column=>{
          if (column.id !== "link"){
            let value = (report as any)[column.id];
            value = formatValue(value, column.type)
            tableValues.push(value)
          }
      })
      csvData.push(tableValues)
    })
    let tableHeaders:string[] = []
    filteredDetailedReport.headers.forEach(header=>{
      tableHeaders.push(header.columnName)
    })
    csvData.unshift(tableHeaders)
    setCsvData(csvData)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function handleFilterReportCancel() {
    setShowReportFiltersModal(false);
  }

  function handleFilterReportSubmit() {
    setShowReportFiltersModal(false);
  }


  return <>
    <Dialog
    className="modal user-modal"
    open={open}
    fullWidth={true}
    maxWidth="xl"
    scroll="body"
    >
      <DialogTitle>{title} Report</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item justifyContent="flex-start" >
            <CSVLink data={csvData} filename={title}>
              <Button>Download Report</Button>
            </CSVLink>
          </Grid>

          <Grid item alignContent="flex-end" >
            <Button onClick={()=> setShowReportFiltersModal(true)}>Filters</Button>
          </Grid>

        </Grid>
        {isSettlementDetailedLoading ?
          (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <LoadingOverlay />
            </Grid>
          )
          :
          (
            <Paper className="mt-8">
              <>
                <TablePagination
                rowsPerPageOptions={[10, 50, 100, { value: reports?.length, label: 'All'  }]}
                component="div"
                count={reports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={"View"}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TableContainer style={{maxHeight:"60vh"}}>

                  <Table
                    data-testid="results"
                    className="table alternating-row"
                    size="small"
                    stickyHeader
                  >
                    <TableHead >
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            style={{ minWidth: column.id === "link" ? column.minWidth : 170, maxWidth:300} }
                            align="center"
                          >
                          {
                            column.id !== "link" ?
                              (
                              <TableSortLabel
                                active={valueToOrderBy === column.id}
                                direction={valueToOrderBy === column.id ? orderDirection : "asc"}
                                onClick={createSortHandler(column.id, column.type)}
                              >
                                {column.label}
                              </TableSortLabel>
                              )
                              :
                              (
                                column.label
                              )
                          }

                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedRowInformation(reports, getComparator(orderDirection, valueToOrderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((report: ReportsInfo, indexReport: number) => {
                          return (
                            <TableRow
                              data-testid="result-row"
                              hover
                              tabIndex={-1}
                              key={indexReport}
                            >
                              {columns.map((column) => {
                                let value = (report as any)[column.id];
                                value = formatValue(value, column.type)
                                return (
                                  <TableCell key={column.id} align="center">
                                    <Grid container className="word-wrap">
                                      <Grid item xs={12} className="word-wrap">
                                        <Typography className="py-3" variant="body1">{value}</Typography>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>

                </TableContainer>
              </>
            </Paper>
          )

        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDetailReportCancel}>Close</Button>
      </DialogActions>
  </Dialog>

  <ReportFilterModal
      headersLength={filteredDetailedReport?.headers?.length}
      transactionNumber = {transactionNumber}
      title={title}
      open={showReportFiltersModal}
      handleFilterReportCancel={handleFilterReportCancel}
      handleFilterReportSubmit={handleFilterReportSubmit}
      availableChannelFilters={availableChannelFilters}
    />
  </>;
}
