import { DialogActionButton } from '@finpay-development/shared-components';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../shared/state/root-reducer';

interface ModalProps {
  open: boolean;
  title: string;
  subTitle: string;
  okButtonText: string;
  sendEmailReminderNotification: () => void;
  handleNotifyCancel: () => void;
}

function NotifyModal(props: ModalProps) {
  const {
    open,
    title,
    subTitle,
    okButtonText,
    sendEmailReminderNotification,
    handleNotifyCancel,
  } = props;

  const saveStatus = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.apiStatus
  );

  const user = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.selectedUser
  );


  function handleCancelCallback() {
    handleNotifyCancel();
  }

  return (
    <Dialog
      className="modal notify-modal"
      scroll="body"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent  >
        <Grid container direction="column" alignItems="center">
          <Grid xs={12} item className="mb-4 mt-2">
            <NotificationImportantIcon color="primary" />
          </Grid>
          <Grid xs={12} item className="mb-2">
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid xs={12} item>
            {user ?
              <Typography  variant="body1">{subTitle} {user.fullName}</Typography>
              :
              <Typography variant="body1">{subTitle}</Typography>
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled= {true}
          savebuttonText={okButtonText}
          saveStatus={saveStatus}
          executeSave={sendEmailReminderNotification}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}

export default NotifyModal;
