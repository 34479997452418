import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {VOBModalContents} from '@finpay-development/shared-components';

import {RootState} from 'src/shared/state/root-reducer';
import {AppDispatch} from 'src/shared/state/store';
import {
    Vob,
    vobClassificationsItem,
    VobPostBody,
    FacilityPayers,
} from 'src/admissions-advisor/models/vob';
import {getVOBAndEstimateForFinpass, saveVOBForFinpass, updateIOC} from 'src/patient/state/patient-thunk';
import {PatientEncounter} from '../../models/patient-encounter';
import { admissionsAdvisorUtils } from 'src/admissions-advisor/utils/admission-advisor-utils';
import { saveEstimate } from 'src/admissions-advisor/state/estimator-thunk';
import { SaveEstimatorPostBody } from 'src/admissions-advisor/models/estimator';

interface FinpassVOBModalProps {
    open: boolean;
    isEdit: boolean;
    classifications: vobClassificationsItem[] | null;
    facilityPayersState?: FacilityPayers[];
    handleVOBModalCancel: () => void;
    handleVOBModalSubmit: (isEdit: boolean) => void;
    isFinpass?: boolean;
}

export function FinpassVOBModal(props: FinpassVOBModalProps) {
    const {
        open,
        isEdit,
        classifications,
        facilityPayersState,
        handleVOBModalCancel,
        handleVOBModalSubmit,
    } = props;

    const finpassVOB = useSelector((state: RootState) => {
        return state.patientContext.selectedVOB;
    });

    const estimator = useSelector((state: RootState) => {
        return state.patientContext.selectedEstimate.estimator
    });

    const currentPatient = useSelector((state: RootState) => {
        return state.patientContext.selectedPatient
    });

    const isEstimateFromFinAdvisor = useSelector((state: RootState) => {
        const totalEstimatePfr = state.patientContext.selectedEstimate.estimator.totalEstimatedPfr
        return Boolean(totalEstimatePfr !== undefined && totalEstimatePfr >= 0 )
    });

    const selectedPatient = useSelector((state: RootState) => {
        return state.patientContext.selectedPatient;
    });

    const selectedEncounter = useSelector((state: RootState) => {
        return state.patientContext.selectedEncounter
    });

    const selectedEstimate = useSelector((state:RootState) => {
        return state.patientContext.selectedEstimate.estimator
    })

    const dispatch = useDispatch<AppDispatch>();

    const createVobBody = (
        formik: any,
        selectedEncounter: PatientEncounter,
        vob: Vob = {} as Vob
    ): Vob => {
        const {values, isValid} = formik;
        const currentDateTime = new Date();

        return {
            isValid,
            selfPay: vob?.selfPay || false,
            digitalVerificationMethod: vob?.digitalVerificationMethod || false,
            client: {
                clientId: selectedEncounter.clientId,
                clientName: selectedEncounter.clientName,
            },
            facility: {
                facilityId: selectedEncounter.facilityId,
                facilityName: selectedEncounter.facilityName,
            },
            payer: {...values.payer},
            plan: {...values.plan},
            groupNum: values.groupNum,
            policyNum: values.policyNum,
            liveVOB: values.liveVOB,
            activePolicy: values.activePolicy,
            isCarryover: values.isCarryover,
            policyBeginDate: values.policyBeginDate,
            policyEndDate: values.policyEndDate,
            inNetwDeductible: values.inNetwDeductible!,
            inNetwDeductibleRemaining: values.inNetwDeductibleRemaining!,
            inNetwFamilyDeductible: values.inNetwFamilyDeductible!,
            inNetwFamilyDeductibleRemaining:
                values.inNetwFamilyDeductibleRemaining!,
            inNetwOopIncluded: values.inNetwOopIncluded,
            inNetwOopMax: values.inNetwOopMax!,
            inNetwOopMaxRemaining: values.inNetwOopMaxRemaining!,
            inNetwFamilyOopMax: values.inNetwFamilyOopMax!,
            inNetwFamilyOopMaxRemaining: values.inNetwFamilyOopMaxRemaining!,
            inNetwVobClassifications: values.inNetwVobClassifications,
            ooNetwDeductible: values.ooNetwDeductible!,
            ooNetwDeductibleRemaining: values.ooNetwDeductibleRemaining!,
            ooNetwFamilyDeductible: values.ooNetwFamilyDeductible!,
            ooNetwFamilyDeductibleRemaining:
                values.ooNetwFamilyDeductibleRemaining!,
            ooNetwOopIncluded: values.ooNetwOopIncluded,
            ooNetwOopMax: values.ooNetwOopMax!,
            ooNetwOopMaxRemaining: values.ooNetwOopMaxRemaining!,
            ooNetwFamilyOopMax: values.ooNetwFamilyOopMax!,
            ooNetwFamilyOopMaxRemaining: values.ooNetwFamilyOopMaxRemaining!,
            ooNetwVobClassifications: values.ooNetwVobClassifications,
            vobId: vob?.vobId,
            patientNotes: values.noteText
                ? [
                      {
                          noteText: values.noteText,
                          noteDt: currentDateTime.toISOString(),
                      },
                  ]
                : [],
        };
    };

    async function handleSave(formik: any, didPayorPlanChange?: boolean) {
        const vobPostCopy = createVobBody(
            formik,
            selectedEncounter,
            finpassVOB
        );

        const vobPostBody: VobPostBody = {
            advisorPatientId: selectedPatient.advisorPatientId,
            fpClientId: selectedEncounter.clientId,
            fpClientFacilityId: selectedEncounter.facilityId,
            vobBody: {
                ...vobPostCopy,
            },
        };

        const doesLOCExist = !!(selectedEstimate.pfrEstimateId && Array.isArray(selectedEstimate?.selectedLevelsOfCare) && selectedEstimate?.selectedLevelsOfCare.length > 0 && selectedEstimate?.selectedLevelsOfCareFacilityType);

        await dispatch(saveVOBForFinpass({
            vob: vobPostBody,
            resetLOC: didPayorPlanChange && doesLOCExist          //Compare to see if LOC dependency changed. If so, need to reset the LOC.
        }));

        const keysToCompare = [
            "inNetwDeductible",
            "inNetwDeductibleRemaining",
            "inNetwFamilyDeductible",
            "inNetwFamilyDeductibleRemaining",
            "inNetwOopIncluded",
            "inNetwOopMax",
            "inNetwOopMaxRemaining",
            "inNetwFamilyOopMax",
            "inNetwFamilyOopMaxRemaining",
            "ooNetwDeductible",
            "ooNetwDeductibleRemaining",
            "ooNetwFamilyDeductible",
            "ooNetwFamilyDeductibleRemaining",
            "ooNetwOopIncluded",
            "ooNetwOopMax",
            "ooNetwOopMaxRemaining",
            "ooNetwFamilyOopMax",
            "ooNetwFamilyOopMaxRemaining"
        ]

        const shouldRecalcPFR = didVOBPFRValuesChange(finpassVOB, vobPostCopy, keysToCompare)

        if(shouldRecalcPFR && isEstimateFromFinAdvisor) {
            const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(
                estimator,
                vobPostCopy
            );

              const estimatePayload: SaveEstimatorPostBody = {
                paramId: -2,
                estimateId: estimator.pfrEstimateId,
                vobId: finpassVOB.vobId!,
                advisorPatientId: currentPatient.advisorPatientId!,
                estimateBody: {
                    ...estimator!,
                    totalEstimatedPfr: financialSummary.totalPFR,
                },
              };

            await dispatch(saveEstimate(estimatePayload))
            await dispatch(updateIOC({
                pfrAmt: financialSummary.totalPFR,
            }))
            await dispatch(getVOBAndEstimateForFinpass({estimateId: estimator.pfrEstimateId!}))

        }

        handleSaveCallback();
    }

    const didVOBPFRValuesChange = (
        originalVOB: Vob,
        updatedVOB: Vob,
        keysToCompare: string[],
    ) => {
        const checkKeys = (element: string, index: number, array: any[]) => {
            return originalVOB[element as keyof typeof originalVOB] !== updatedVOB[element as keyof typeof updatedVOB]
        }
        const didOOPorDeductiblesChange = keysToCompare.some(checkKeys)

        if(didOOPorDeductiblesChange) return true

        // co insurance, max days, and co pays are all nested under
        // vob classifications
        const originalOONetwVobClassifications = originalVOB.ooNetwVobClassifications
        const originalINNetwVobClassifications = originalVOB.inNetwVobClassifications
        const updatedOONetwVobClassifications = updatedVOB.ooNetwVobClassifications
        const updatedINNetwVobClassifications = updatedVOB.inNetwVobClassifications

        // if the length changes, we know we'll need to recalc
        if(originalOONetwVobClassifications!.length !== updatedOONetwVobClassifications!.length) return true
        if(originalINNetwVobClassifications!.length !== updatedINNetwVobClassifications!.length) return true

        // need to loop through the calssifications independently
        // for co-ins, max days, and co-pays
        for(let i = 0; i < originalINNetwVobClassifications!.length; i++) {
            const didCoInsuranceChange = originalINNetwVobClassifications![i].coInsurance !== updatedINNetwVobClassifications![i].coInsurance
            const didCoPayChange = originalINNetwVobClassifications![i].coPay !== updatedINNetwVobClassifications![i].coPay
            const didMaxDaysChange = originalINNetwVobClassifications![i].maxDays !== updatedINNetwVobClassifications![i].maxDays
            const didCoPaySelectionChange = originalINNetwVobClassifications![i].coPaySelection !== updatedINNetwVobClassifications![i].coPaySelection

            if(didCoInsuranceChange || didCoPayChange || didMaxDaysChange || didCoPaySelectionChange) {
                return true
            }
        }

        for(let i = 0; i < originalOONetwVobClassifications!.length; i++) {
            const didCoInsuranceChange = originalOONetwVobClassifications![i].coInsurance !== updatedOONetwVobClassifications![i].coInsurance
            const didCoPayChange = originalOONetwVobClassifications![i].coPay !== updatedOONetwVobClassifications![i].coPay
            const didMaxDaysChange = originalOONetwVobClassifications![i].maxDays !== updatedOONetwVobClassifications![i].maxDays
            const didCoPaySelectionChange = originalOONetwVobClassifications![i].coPaySelection !== updatedOONetwVobClassifications![i].coPaySelection

            if(didCoInsuranceChange || didCoPayChange || didMaxDaysChange || didCoPaySelectionChange) {
                return true
            }
        }

        // nothing changed
        return false
    }

    function handleSaveCallback() {
        handleVOBModalSubmit(isEdit);
    }

    return (
        <VOBModalContents
            open={open}
            isEdit={isEdit}
            classifications={classifications}
            vob={finpassVOB}
            facilityPayersState={facilityPayersState}
            handleSave={handleSave}
            handleVOBModalCancel={handleVOBModalCancel}
            handleVOBModalSubmit={handleVOBModalSubmit}
        />
    );
}
