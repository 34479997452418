import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import { Filters } from '../../implementation-specialist/models/reportsFilters';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { clearReportInfo } from '../state/users/admin-slice';
import { handleGetFilteredReports } from '../state/users/admin-thunk';
import AdminReports from './admin-users/admin-reports-filter';
import ReportsResults from './admin-users/admin-reports-filter-results';
import { ReportFilterModal } from './admin-users/report-filters-modal';

interface ReportsProps {
  page: "admin" | "client-owner";
}

function ReportsView(props: ReportsProps) {

  const dispatch = useDispatch<AppDispatch>();
  // eslint-disable-next-line
  const [showReportFiltersModal, setShowReportFiltersModal] = useState(false)
  const [exportedReportName, setExportedReportName] = useState("")

  const stateFields = {
    filteredReport: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.filteredReport
    ),
    allReports: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.allReports
    ),
    isLoading: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.isReportsLoading
    ),
    csvData: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.csvData
    ),
    reportFilters: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.reportFilters
    )
  }

  const { filteredReport, reportFilters } = stateFields

  const [currentFilteredReport, setCurrentFilteredReport] = useState(stateFields.filteredReport)

  function handleFilterReportCancel() {
    setShowReportFiltersModal(false);
  }

  function handleFilterReportSubmit() {
    setShowReportFiltersModal(false);
  }

  const filterByPayoutStatus = (payoutStatus: [string]) => {
    const idx = filteredReport.headers.findIndex((v) => (v.columnName === 'Payout Status'));
    const newFilteredReportData = filteredReport.data.filter((report) => (!payoutStatus.length || payoutStatus.includes(report[idx])));
    setCurrentFilteredReport({ ...filteredReport, data: newFilteredReportData });
  }


  useEffect(() => {
    dispatch(clearReportInfo())
  }, [dispatch])

  function handleFilterReportType(reportsFilters: Filters) {
    dispatch(handleGetFilteredReports(reportsFilters));
  }

  useEffect(() => {
    const {reportType, startDate, endDate} = reportFilters;
    if (!startDate || !endDate) setExportedReportName(reportType);
    else setExportedReportName(`${reportType} from ${startDate} to ${endDate}`);
  }, [reportFilters])

  useEffect(() => {
    if (reportFilters.reportType === 'Settlement Reports') {
      filterByPayoutStatus(['Completed']);
    } else {
      setCurrentFilteredReport(filteredReport);
    }
  }, [filteredReport])

  const reportsUsersView = (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <AdminReports handleFilterReportType={handleFilterReportType} />
      </Grid>
      <Grid item xs={9}>
        {stateFields.isLoading ? (
          <LoadingOverlay />
        ) : stateFields.filteredReport.data.length === 0 ? (
          <Typography variant="h1" align="center">
            Select report type and date range
          </Typography>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item justifyContent="flex-start">
                <CSVLink
                  data={stateFields.csvData}
                  filename={exportedReportName}
                >
                  <Button>Download Report</Button>
                </CSVLink>
              </Grid>
              <Grid item alignContent="flex-end">
                <Button
                  disabled={
                    stateFields.reportFilters.reportType ===
                    "PES Activity Log Report" ||
                    stateFields.reportFilters.reportType === "All Instance of Care"
                  }
                  onClick={() => setShowReportFiltersModal(true)}
                >
                  Filters
                </Button>
              </Grid>
            </Grid>
            <ReportsResults dataReports={currentFilteredReport} />
          </>
        )}
      </Grid>
    </Grid>
  );

  return (
    <main>
      <div className="content fullwidth">
        {reportsUsersView }
      </div>

      <ReportFilterModal
        title= {stateFields.reportFilters.reportType}
        open={showReportFiltersModal}
        handleFilterReportCancel={handleFilterReportCancel}
        handleFilterReportSubmit={handleFilterReportSubmit}
        filterByPayoutStatus={filterByPayoutStatus}
      />
    </main>
  );
}

export default ReportsView;
