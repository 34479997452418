import { Status } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumberToUSD } from '../../../shared/calculators';
import { StatusColors } from '../../../shared/enums';
import { RootState } from '../../../shared/state/root-reducer';
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';

export function EstDetails() {
  const [totalPFR, setTotalPFR] = useState(0);

  const state = {
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    newEstimate: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.newEstimate
    ),

  };
  const { vobState, vobPatientState, estimatorState, newEstimate } = state;

  const isClientConfiguredForNewEstimate = useIsClientConfiguredForNewEstimate();

  function lengthOfStay() {
    if (isClientConfiguredForNewEstimate) {
      const losSummary: any = newEstimate?.summary;
      const los = losSummary?.lengthOfStayCovered;
      return los ?? 0;
    } else {
      const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);
      return financialSummary.lengthOfStayCovered!;
    }
  }

  function totalEstimatedPFR() {
    if (isClientConfiguredForNewEstimate) {
      const pfrSummary: any = newEstimate?.summary;
      const pfrAmt = pfrSummary?.totalPFR;
      return pfrAmt ?? 0;
    } else {
      return admissionsAdvisorUtils.getSelectedPFR(estimatorState);
    }
  }

  useEffect(() => {
    setTotalPFR(totalEstimatedPFR());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
<>
        <Grid container spacing={2}>

          <Grid item direction="column" md={4} sm={4}>
            <strong>
              {vobPatientState?.patient?.firstName}{" "}
              {vobPatientState?.patient?.lastName}
            </strong>
            /{vobPatientState?.patient?.advisorPatientId}
            <Typography variant="body2">
              {vobState?.payer?.payorName}/{vobState?.plan?.planName}
            </Typography>
          </Grid>
          <Grid item direction="column" md={4} sm={4} >
            {vobState.client.clientName}
            <Typography variant="body2">{vobState.facility.facilityName}</Typography>
          </Grid>
          <Grid item direction="column" md={4} sm={4}>
            <strong>{formatNumberToUSD(totalPFR)}</strong>
            <Typography variant="body2">EST. PFR</Typography>
          </Grid>
        </Grid>
				<Grid container spacing={2} style={{marginTop: '1px'}}>
				  <Grid item direction="column" md={4} sm={4}>
            {!vobState?.selfPay && (
              <Status
                text={admissionsAdvisorUtils.isPolicyActive(vobState) ? "Active Policy" : "Inactive Policy"}
                statusColor={
                  admissionsAdvisorUtils.isPolicyActive(vobState) ? StatusColors.success : StatusColors.error
                }
              />
            )}
          </Grid>
					<Grid item direction="column" md={4} sm={4}>
            {!vobState?.selfPay && (
              <Status
                text={vobState?.payer?.inNetwork? "In Network" : "Out of Network"}
                statusColor={
                  vobState?.payer?.inNetwork? StatusColors.success : StatusColors.error
                }
              />
            )}
					</Grid>
					<Grid item direction="column" md={4} sm={4}>
					<Typography variant="body2">LOS {lengthOfStay()} days</Typography>
					</Grid>
				</Grid>

</>
  );
}
