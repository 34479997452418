export interface TypeOfCare {
    typeOfCareId: number;
    typeOfCare: string;
}
// TODO: FPS-1644
// Add typeOfCare end-point that will read the type of care value.
export const typeOfCare: TypeOfCare[] = [
    {
        typeOfCareId: 1,
        typeOfCare: "Inpatient",
    },
    {
        typeOfCareId: 2,
        typeOfCare: "Outpatient",
    },
    {
        typeOfCareId: 3,
        typeOfCare: "Home Health Care",
    }
];

export const emptyTypeOfCare: TypeOfCare = {
    typeOfCareId: 0,
    typeOfCare: "",
};

export const typeOfCareLookup = (id: number) => {
    const foundTypeOfCare = typeOfCare.find(
        (typeOfCare: TypeOfCare) => typeOfCare.typeOfCareId === id
    );
    return foundTypeOfCare?.typeOfCare;
};
