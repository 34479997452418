import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton
} from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import { AppDispatch } from "../../../shared/state/store";
import { setEstimator } from "../../state/estimator-slice";
import { EstPriorCareSummaryModal } from "./estimator-prior-care-summary-modal";
import { PriorCarePFR } from "../../models/estimator";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import useIsClientConfiguredForNewEstimate from "src/admissions-advisor/utils/useClientConfigHook";
import NewPriorCareSummaryModal from "./new-estimator-prior-care-summary-modal";
import { CrossoverSelectionEnum, SummarySelections } from "@finpay/estimation-types";
import { Utils } from "../../../shared/utils";
import { callNewEstimatePut } from "../../../admissions-advisor/state/estimator-thunk";

export function SidebarPriorCareAdjustment() {
  const [includePriorCharges, setIncludePriorCharges] = useState(false);
  const [priorCareSummaryModalOpen, setPriorCareSummaryModalOpen] = useState(false);
  const [adjustedPriorCharges, setAdjustedPriorCharges] = useState<number>(0);

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    newEstimate: useSelector(
      (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext.newEstimate
    ),
  };

  const { vobState, estimatorState, newEstimate } = state;

  const dispatch = useDispatch<AppDispatch>();

  const isClientConfiguredForNewEstimate =
        useIsClientConfiguredForNewEstimate();

  const PFRName = "Prior Care Adjusted PFR";
  const newPFRName = SummarySelections.PRIORCARE;

  useEffect(() => {
		let estimatorStateCopy = { ...estimatorState };
    if (estimatorStateCopy.priorCareAdjustmentPfr !== adjustedPriorCharges) {
      estimatorStateCopy.priorCareAdjustmentPfr = adjustedPriorCharges;
      dispatch(setEstimator(estimatorStateCopy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustedPriorCharges]);

	useEffect(() => {
    const priorCareAdjustment: PriorCarePFR = admissionsAdvisorUtils.getPriorCareAdjChg(estimatorState, vobState);
    const adjustedPFR: number = (priorCareAdjustment?.adjustedCurrentPFR?.totalPFR > 0)? priorCareAdjustment.adjustedCurrentPFR?.totalPFR : 0.00;
		setAdjustedPriorCharges(adjustedPFR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState, vobState]);

	const getSideBarColor = (): string => {
    let sideBarColor = (estimatorState.selectedPFRName === PFRName)? "#6EACDE" : "#999999";
    return sideBarColor;
  };

  const getNewSideBarColor = (): string => {
      let sideBarColor = (newEstimate?.summarySelection === newPFRName)? "#6EACDE" : "#999999";
      return sideBarColor;
  };

  function handleIncludeChargesChange() {
    setIncludePriorCharges(!includePriorCharges);
    if (estimatorState.selectedPFRName !== PFRName) {
      let estimatorStateCopy = { ...estimatorState };
      estimatorStateCopy.selectedPFRName = PFRName;
      dispatch(setEstimator(estimatorStateCopy));
    }
  }

  const handleSetSummarySelection = () => {
      if (newEstimate?.summarySelection !== newPFRName) {
          let newEstimateClone = Utils.deepClone(newEstimate);
          newEstimateClone = {
              ...newEstimateClone,
              summarySelection: newPFRName,
          };
          const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
              vobState.vobId!,
              vobState.client?.clientId!,
              vobState.facility?.facilityId!,
              newEstimateClone,
              Number(newEstimate?.advisorPatientId!),
              newEstimate?.patientEncounterId as string
          );
          dispatch(
              callNewEstimatePut({
                  estimate: postEstimateBody,
                  estimateId: newEstimateClone.estimateId,
              })
          );
      }
  };

  const openSummaryModal = () => {
    setPriorCareSummaryModalOpen(true);
  }

  const handlePriorCareSummaryModalCancel = () => {
    setPriorCareSummaryModalOpen(false);
  };

  const priorLevelsOfCareSummary = admissionsAdvisorUtils.getPriorLevelsOfCare(estimatorState?.priorCare);

  return (
      <>
          {!isClientConfiguredForNewEstimate &&
              estimatorState?.hasPriorCare &&
              priorLevelsOfCareSummary.priorLevelsOfCare?.length! > 0 && (
                  <>
                      <Box
                          border={1}
                          borderColor={getSideBarColor()}
                          style={{
                              backgroundColor: 'white',
                              marginBottom: '1.5em',
                          }}
                      >
                          <Grid container direction="row">
                              <Grid item direction="column" md={12}></Grid>
                          </Grid>
                          <Grid container direction="row">
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  xs={12}
                                  style={{
                                      color: 'white',
                                      backgroundColor: getSideBarColor(),
                                      padding: '0.25em',
                                  }}
                              >
                                  Prior Care Adjustment
                              </Grid>
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  style={{
                                      padding: '1em',
                                  }}
                              >
                                  <Typography
                                      variant="body2"
                                      style={{float: 'left'}}
                                  >
                                      Estimated PFR Adjusted for Prior Care
                                  </Typography>

                                  <FormControlLabel
                                      checked={
                                          estimatorState.selectedPFRName ===
                                          PFRName
                                      }
                                      control={<Checkbox color="primary" />}
                                      id="alimonyIncome"
                                      name="alimonyIncome"
                                      label={
                                          <strong>
                                              {formatNumberToUSD(
                                                  adjustedPriorCharges
                                              )}
                                          </strong>
                                      }
                                      labelPlacement="start"
                                      style={{float: 'right'}}
                                      onChange={() => {
                                          handleIncludeChargesChange();
                                      }}
                                  />
                              </Grid>
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                  <IconButton
                                      color="primary"
                                      title="Open Details"
                                      style={{fontSize: '1.5rem'}}
                                      onClick={openSummaryModal}
                                  >
                                      <OpenInNewIcon fontSize="small" /> Open
                                      Details
                                  </IconButton>
                              </Grid>
                          </Grid>
                      </Box>
                      {priorCareSummaryModalOpen && (
                          <EstPriorCareSummaryModal
                              open={priorCareSummaryModalOpen}
                              handleSummaryModalCancel={
                                  handlePriorCareSummaryModalCancel
                              }
                          />
                      )}
                  </>
              )}

          {isClientConfiguredForNewEstimate &&
              newEstimate &&
              newEstimate.priorCareSummary && (
                  <>
                      <Box
                          border={1}
                          borderColor={getNewSideBarColor()}
                          style={{
                              backgroundColor: 'white',
                              marginBottom: '1.5em',
                          }}
                      >
                          <Grid container direction="row">
                              <Grid item direction="column" md={12}></Grid>
                          </Grid>
                          <Grid container direction="row">
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  xs={12}
                                  style={{
                                      color: 'white',
                                      backgroundColor: getNewSideBarColor(),
                                      padding: '0.25em',
                                  }}
                              >
                                  {isClientConfiguredForNewEstimate
                                      ? `Patient Financial Responsibility with Prior Care${
                                            newEstimate?.isPlanYearCrossover
                                                ? newEstimate?.crossoverSelection ===
                                                  CrossoverSelectionEnum.BEFORE
                                                    ? ' before Crossover'
                                                    : ' with Crossover'
                                                : ''
                                        }`
                                      : 'Prior Care Adjustment'}
                              </Grid>

                              <Grid
                                  item
                                  display="flex"
                                  justifyContent={'space-between'}
                                  direction="row"
                                  md={12}
                                  style={{
                                      padding: '1em',
                                  }}
                              >
                                  <Typography
                                      variant="body2"
                                      style={{float: 'left'}}
                                  >
                                      Estimated PFR Adjusted for Prior Care
                                  </Typography>
                                  <Box sx={{fontWeight: 'bold'}}>
                                      <FormControlLabel
                                          checked={
                                              newEstimate?.summarySelection! ===
                                              newPFRName
                                          }
                                          control={<Checkbox color="primary" />}
                                          id="totalAdjustedPFR"
                                          name="totalAdjustedPFR"
                                          label={
                                              <strong test-id="sidebar-prior-care-pfr-value">
                                                  {formatNumberToUSD(
                                                      newEstimate
                                                          .priorCareSummary
                                                          ?.totalPFR
                                                  )}
                                              </strong>
                                          }
                                          labelPlacement="start"
                                          style={{float: 'right'}}
                                          onChange={() => {
                                              handleSetSummarySelection();
                                          }}
                                      />
                                  </Box>
                              </Grid>
                              <Grid
                                  item
                                  direction="column"
                                  md={12}
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                              >
                                  <IconButton
                                      color="primary"
                                      test-id="sidebar-prior-care-pfr-open-details"
                                      title="Open Details"
                                      style={{fontSize: '1.5rem'}}
                                      onClick={openSummaryModal}
                                  >
                                      <OpenInNewIcon fontSize="small" /> Open
                                      Details
                                  </IconButton>
                              </Grid>
                          </Grid>
                      </Box>
                      <NewPriorCareSummaryModal
                          open={priorCareSummaryModalOpen}
                          handleSummaryModalCancel={
                              handlePriorCareSummaryModalCancel
                          }
                          priorCare={newEstimate.priorCare!}
                          priorCareSummary={newEstimate.priorCareSummary}
                          summary={newEstimate.nonAdjustedSummary!}
                      />
                  </>
              )}
      </>
  );
}
