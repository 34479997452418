import "../../../scss/components/_forms.scss";

import { Grid } from "@mui/material";

import AddPatientRecord from "./add-patient-record";
import OpenSearchPatient from "./open-search-patient";

interface SpecialistHeaderProps {
  canUseOpenSearch: boolean;
}

const SpecialistHeader = (props: SpecialistHeaderProps) => {
  const { canUseOpenSearch } = props;

  return (
    <Grid container direction="row" className="mb-8">
      <Grid item xs={6}>
        {canUseOpenSearch && <OpenSearchPatient />}
      </Grid>
      <Grid item container xs={6} justifyContent="flex-end">
        <AddPatientRecord />
      </Grid>
    </Grid>
  );
};

export default SpecialistHeader;
