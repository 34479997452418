import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  getDefaultRuleCriteria,
  getDefaultRuleEdit,
  Rule,
  ruleCriteria,
  RuleCriteriaParam,
  RuleEditViewModel,
} from '../models/rule';
import {EditlevelOfCare, LevelOfCare} from '../models/level-of-care';
import {EditPayer, EditPayerPlans, PayorModalForm} from '../models/payers';
import {
  adminConfigurationService,
} from '../services/admin-configuration-service';
import {RootState} from '../../shared/state/root-reducer';
import {
  showErrorStatus,
  showStatus,
  updateApplicationStatus,
} from '../../security/state/user-slice';
import {rulesService} from '../../shared/service/rules-service';
import _ from 'lodash';
import {errorCodes, errorMessages} from '../../shared/enums';
import {Scholarship} from '../models/scholarship';
import {ConfigRiskThreshold} from '../models/risk-threshold';
import {ConfigRiskClassSetting} from '../models/risk-class-setting';
import {Utils} from '../../shared/utils';

export const configurationHelper = {
  errorHandler: async (
    response: {
      entity: any;
      errorMessage: string;
      hasErrors: boolean;
    },
    thunkAPI: any
  ) => {
    const { entity, errorMessage, hasErrors } = response;

    if (!hasErrors) {
      return response;
    }

    // TODO: update api response bodies to contain code information on errors
    const code = Number(
      errorMessage.match(/Request failed with status code (\d{3})/)?.[1] ||
        "500"
    );
    if (code < 500) {
      // handle external error
      await thunkAPI.dispatch(showErrorStatus(entity));
      throw new Error(entity);
    } else {
      // handle internal error
      await thunkAPI.dispatch(showErrorStatus(errorMessage));
      throw new Error(errorMessage);
    }
  },
};

export const getRules = createAsyncThunk(
  "adminConfigurationContext/getRules",
  async (data: void, thunkAPI) => {
    const response = await rulesService.getRules();
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      return;
    }

    return response.entity;
  }
);

export const configGetRule = createAsyncThunk(
  "adminConfigurationContext/configGetRule",
  async (ruleId: number, thunkAPI) => {
    const response = await rulesService.configGetRule(ruleId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      return;
    }
    return response.entity;
  }
);

export const configDeleteRule = createAsyncThunk(
  "adminConfigurationContext/configDeleteRule",
  async (ruleId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allRules = state.adminContext.adminConfigurationContext.allRules;
    const response = await rulesService.configDeleteRule(ruleId, allRules);
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    } else {
      return response.entity;
    }
  }
);

export const configSaveRule = createAsyncThunk(
  "adminConfigurationContext/configSaveRule",
  async (formPayload: RuleEditViewModel, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const fullRule = state.adminContext.adminConfigurationContext.fullRule;
    const allRules = state.adminContext.adminConfigurationContext.allRules;
    const ruleParamsMetadata = state.metaData.ruleParams
    const isDuplicated = verifyDuplicateRule(formPayload, allRules);
    if (isDuplicated) {
      throw new Error(errorMessages.duplicateRule);
    }
    const response = await rulesService.configSaveRule(
        formPayload,
        fullRule,
        [...allRules],
        ruleParamsMetadata
        );

    if (response.hasErrors) {
      updateApplicationStatus(errorMessages.duplicateRule);
      let errorMessage = response.errorMessage;
      if (response.entity.code === errorCodes.duplicateRule) {
        errorMessage = errorMessages.duplicateRule;
      }
      throw new Error(errorMessage);
    } else {
      return response.entity;
    }
  }
);

export const reOrderRule = createAsyncThunk(
  "adminConfigurationContext/reOrderRule",
  async (payload: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allRules = state.adminContext.adminConfigurationContext.allRules;
    const response = await rulesService.reOrderRule(
      allRules,
      payload.data,
      payload.dndSourceIndex,
      payload.dndDestinationIndex
    );
    // no need to send back anything to Slice. Just handle any error.
    // "reOrderRuleInUI()" function below independently does reorder in state cache and updates UI
    // (for purpose of instant snappy reorder experience without async API lag)
    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    }
  }
);

// Similar function to above. This reorders rule in state memory for instant snappy reordering experience
// The function above actually does async save to API
export const reOrderRuleInUI = createAsyncThunk(
  "adminConfigurationContext/reOrderRule",
  async (payload: any, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allRuleSets = state.adminContext.adminConfigurationContext.rules;
    return await rulesService.reOrderRuleInUI(
        allRuleSets,
        payload.data,
        payload.dndSourceIndex,
        payload.dndDestinationIndex,
        payload.ruleSetIndex
    );
  }
);

// note: a ruleCriteriaParam can be thought of as a "formula"
export const getRuleCriteriaParams = createAsyncThunk(
  "adminConfigurationContext/getRuleCriteriaParams",
  async (data: void, thunkAPI) => {
    const response = await adminConfigurationService.getRuleCriteriaParams();
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const getRuleCriteriaParam = createAsyncThunk(
  "adminConfigurationContext/getRuleCriteriaParam",
  async (paramId: number, thunkAPI) => {
    const response = await adminConfigurationService.getRuleCriteriaParam(
      paramId
    );
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const saveRuleCriteriaParam = createAsyncThunk(
  "saveRuleCriteriaParam",
  async (payload: RuleCriteriaParam, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const response = await adminConfigurationService.saveRuleCriteriaParam(
      payload
    );

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }

    const allRuleCriteriaParams = [
      ...state.adminContext.adminConfigurationContext.allRuleCriteriaParams,
    ];
    const indexToSave = allRuleCriteriaParams.findIndex(
      (item) => item.paramId === response.entity.paramId
    );
    if (indexToSave > -1) {
      allRuleCriteriaParams.splice(indexToSave, 1, response.entity);
    } else {
      allRuleCriteriaParams.push(response.entity);
    }
    return allRuleCriteriaParams;
  }
);

export const deleteRuleCriteriaParam = createAsyncThunk(
  "deleteRuleCriteriaParam",
  async (paramId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const response = await adminConfigurationService.deleteRuleCriteriaParam(
      paramId
    );

    if (response.hasErrors) {
      updateApplicationStatus(response.errorMessage);
      throw new Error(response.errorMessage);
    }

    const allRuleCriteriaParams = [
      ...state.adminContext.adminConfigurationContext.allRuleCriteriaParams,
    ];
    const indexToRemove = allRuleCriteriaParams.findIndex(
      (item) => item.paramId === paramId
    );
    if (indexToRemove > -1) {
      allRuleCriteriaParams.splice(indexToRemove, 1);
    }
    return allRuleCriteriaParams;
  }
);

const verifyDuplicateRule = (
  formData: RuleEditViewModel,
  allRules: Rule[]
): Boolean => {
  formData.sortOrder = 0;
  formData.isDefaultRule = true;
  let ruleToTest: RuleEditViewModel = getDefaultRuleEdit();
  let isDuplicated = false;

  for (let rule of allRules) {
    ruleToTest.timingRisk = rule.timingRisk.timingRiskId;
    ruleToTest.payorRisk = rule.payorRisk.payorRiskId;
    ruleToTest.availableTerms = rule.availableTerms;
    ruleToTest.optimalTerm = rule.optimalTerm;
    ruleToTest.minDownPmt = rule.minDownPmt.toString();
    ruleToTest.minDownPmtType = rule.minDownPmtType;
    ruleToTest.optimalDownPmt = rule.optimalDownPmt.toString();
    ruleToTest.optimalDownPmtType = rule.optimalDownPmtType;
    ruleToTest.sortOrder = 0;

    let ruleCriteria: ruleCriteria = getDefaultRuleCriteria();

    let ruleCriteriaList: ruleCriteria[] = [];
    rule.ruleCriteria.forEach((ruleCriteriaToTest) => {
      ruleCriteria.paramName = ruleCriteriaToTest.param.paramId;
      ruleCriteria.comparator = ruleCriteriaToTest.comparator;
      ruleCriteria.paramValue = ruleCriteriaToTest.paramValue;
      ruleCriteriaList.push(ruleCriteria);
    });
    ruleToTest.ruleCriteria = ruleCriteriaList;

    if (_.isEqual(formData, ruleToTest)) {
      isDuplicated = true;
      break;
    }
  }
  return isDuplicated;
};

export const configGetLOC = createAsyncThunk(
  "adminConfigurationContext/configGetLOC",
  async (paramId: number, thunkAPI) => {
    const response = await adminConfigurationService.configGetLOC(paramId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const configSaveLOC = createAsyncThunk(
  "adminConfigurationContext/configSaveLOC",
  async (formPayload: EditlevelOfCare, thunkAPI) => {
    const response = configurationHelper.errorHandler(
      await adminConfigurationService.configSaveLOC(formPayload),
      thunkAPI
    );
    return (await response).entity;
  }
);

export const configNewLOC = createAsyncThunk(
  "adminConfigurationContext/configNewLOC",
  async (formPayload: LevelOfCare, thunkAPI) => {
    const response = await configurationHelper.errorHandler(
      await adminConfigurationService.configNewLOC(formPayload),
      thunkAPI
    );
    return response.entity;
  }
);

export const configDeleteLOC = createAsyncThunk(
  "adminConfigurationContext/configDeleteLOC",
  async (formPayload: LevelOfCare, thunkAPI) => {
    return (
      await configurationHelper.errorHandler(
        await adminConfigurationService.configDeleteLOC(formPayload),
        thunkAPI
      )
    ).entity;
  }
);

export const configGetPayers = createAsyncThunk(
  "adminConfigurationContext/configGetPayers",
  async (paramId: number, thunkAPI) => {
    const response = await adminConfigurationService.configGetPayers(paramId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const configGetStates = createAsyncThunk(
  "adminConfigurationContext/configGetStates",
  async (paramId: number, thunkAPI) => {
    const response = await adminConfigurationService.configGetStates(paramId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

// create a new payor and any associated plans
export const configNewPayer = createAsyncThunk(
  "adminConfigurationContext/configNewPayer",
  async (formPayload: PayorModalForm, thunkAPI) => {
    const newPayerData = await adminConfigurationService.configNewPayer(
      formPayload.payerData
    );
    if (newPayerData.hasErrors) {
      await configurationHelper.errorHandler(newPayerData, thunkAPI);
    }

    if (formPayload.newPlans.length > 0) {
      await Promise.all(
        formPayload.newPlans.map(async (item: EditPayerPlans): Promise<any> => {
          item.payorId = newPayerData.entity.payorId;
          return adminConfigurationService.configNewPlan(item);
        })
      );
    }
  }
);

// edit an existing payor and/or their plans
export const configSavePayer = createAsyncThunk(
  "adminConfigurationContext/configSavePayer",
  async (formPayload: PayorModalForm, thunkAPI) => {
    const savePayorData = await adminConfigurationService.configSavePayer(
      formPayload.payerData
    );
    if (savePayorData.hasErrors) {
      await configurationHelper.errorHandler(savePayorData, thunkAPI);
    }
    await Promise.all(
      formPayload.newPlans.map(async (item: EditPayerPlans): Promise<any> => {
        return adminConfigurationService.configNewPlan(item);
      })
    );
    await Promise.all(
      formPayload.editedPlans.map(
        async (item: EditPayerPlans): Promise<any> => {
          return adminConfigurationService.configSavePlan(item);
        }
      )
    );
    await Promise.all(
      formPayload.deletedPlans.map(
        async (item: EditPayerPlans): Promise<any> => {
          return adminConfigurationService.configDeletePlan(item);
        }
      )
    );
  }
);

export const configDeletePayer = createAsyncThunk(
  "adminConfigurationContext/configDeletePayer",
  async (formPayload: EditPayer) => {
    const response = await adminConfigurationService.configDeletePayer(
      formPayload
    );
    return response.entity;
  }
);

export const configGetScholarships = createAsyncThunk(
  "adminConfigurationContext/configGetScholarships",
  async (data: {paramId: number, clientId: number}, thunkAPI) => {
    const response = await adminConfigurationService.configGetScholarships(data.paramId, data.clientId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const configSaveScholarship = createAsyncThunk(
  "adminConfigurationContext/configSaveScholarship",
  async (formPayload: Scholarship, thunkAPI) => {
    const response = await configurationHelper.errorHandler(
      await adminConfigurationService.configSaveScholarship(formPayload),
      thunkAPI
    );

    if (!response.hasErrors) {
      thunkAPI.dispatch(showStatus("Scholarship Updated Successfully"));
    }
    //return (await response).entity;
    return response.entity;
  }
);

export const configNewScholarship = createAsyncThunk(
  "adminConfigurationContext/configNewScholarship",
  async (formPayload: Scholarship, thunkAPI) => {
    const response = await configurationHelper.errorHandler(
      await adminConfigurationService.configNewScholarship(formPayload),
      thunkAPI
    );

    if (!response.hasErrors) {
      thunkAPI.dispatch(showStatus("Scholarship Added Successfully"));
    }
    return response.entity;
  }
);

export const configDeleteScholarship = createAsyncThunk(
  "adminConfigurationContext/configDeleteScholarship",
  async (formPayload: Scholarship, thunkAPI) => {
    return (
      await configurationHelper.errorHandler(
        await adminConfigurationService.configDeleteScholarship(formPayload),
        thunkAPI
      )
    ).entity;
  }
);

export const configGetRiskThresholds = createAsyncThunk(
  "adminConfigurationContext/configGetRiskThresholds",
  async (data: {paramId: number}, thunkAPI) => {
    const response = await adminConfigurationService.configGetRiskThresholds(data.paramId);
    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
    } else {
      return response.entity;
    }
  }
);

export const configSaveRiskThreshold = createAsyncThunk(
  "adminConfigurationContext/configSaveRiskThreshold",
  async (formPayload: ConfigRiskThreshold, thunkAPI) => {
    const response = await configurationHelper.errorHandler(
      await adminConfigurationService.configSaveRiskThreshold(formPayload),
      thunkAPI
    );

    if (!response.hasErrors) {
      thunkAPI.dispatch(showStatus("Risk Threshold Updated Successfully"));
    }
    return response.entity;
  }
);

export const configNewRiskThreshold = createAsyncThunk(
  "adminConfigurationContext/configNewRiskThreshold",
  async (formPayload: ConfigRiskThreshold, thunkAPI) => {
    const response = await configurationHelper.errorHandler(
      await adminConfigurationService.configNewRiskThreshold(formPayload),
      thunkAPI
    );

    if (!response.hasErrors) {
      thunkAPI.dispatch(showStatus("Risk Threshold Added Successfully"));
    }
    return response.entity;
  }
);

export const configDeleteRiskThreshold = createAsyncThunk(
  "adminConfigurationContext/configDeleteScholarship",
  async (formPayload: ConfigRiskThreshold, thunkAPI) => {
    return (
      await configurationHelper.errorHandler(
        await adminConfigurationService.configDeleteRiskThreshold(formPayload),
        thunkAPI
      )
    ).entity;
  }
);

export const configSaveRiskClassSetting = createAsyncThunk(
  "adminConfigurationContext/configSaveRiskClassSetting",
  async (formPayload: ConfigRiskClassSetting, thunkAPI) => {
    let action = 'Added';
    if (formPayload.riskClassSettingId !== undefined && formPayload.riskClassSettingId > 0) {
      action = 'Updated';
    }
    const response = await adminConfigurationService.configSaveRiskClassSetting(formPayload);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus("Risk Class Setting " + action + " Successfully"));
      return response.entity;
    }
  }
);

export const configGetRiskClassSettings = createAsyncThunk(
  "adminConfigurationContext/configGetRiskClassSettings",
  async (data: {paramId: number}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const finPayRiskClasses = state.adminContext.adminConfigurationContext.finPayRiskClasses;

    const response = await adminConfigurationService.configGetRiskClassSettings(data.paramId, finPayRiskClasses);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);


export const configGetFinPayRiskClasses = createAsyncThunk(
  "adminConfigurationContext/configGetFinPayRiskClasses",
  async (data: {paramId: number}, thunkAPI) => {
    const response = await adminConfigurationService.configGetFinPayRiskClasses(data.paramId);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);
