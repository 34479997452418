import React, { useEffect, useMemo, useState } from "react";
import { Grid, Paper, Box, Typography, MenuItem, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import { ImplementationFacility } from "../models/implementation-facility";
import { RootState } from "../../../../../shared/state/root-reducer";
import { LoadingOverlay, TextField } from "@finpay-development/shared-components";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { OperatingCostsModal } from "./operating-costs-modal";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import GroupsIcon from '@mui/icons-material/Groups';
import { getClientRiskAssessmentConfigs } from "src/implementation-specialist/state/clients/implementation-clients-thunk";
import { ClientRiskAssessmentFields } from "src/shared/model/client-risk-assessment-config";
import { BarChart } from "@mui/icons-material";
import { clearClientRiskAssessmentConfigs } from "src/implementation-specialist/state/clients/implementation-clients-slice";
import { DeleteOperatingCostsModal } from "./delete-operating-costs-modal";

const iconColor = "#505f79"

function OperatingCosts() {

    const selectors = {
        currentClient: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient?.currentClientEntityDetails
        ),
        facilities: useSelector(
            (state: RootState) => state.implementationContext.implementationFacility?.facilities.flat()
        ),
        isLoadingFacilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility?.isLoading
        ),
        clientRiskAssessmentConfigs: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient?.clientRiskAssessmentConfigs
        )
    };

    const {
        facilities,
        isLoadingFacilities,
        currentClient,
        clientRiskAssessmentConfigs
    } = selectors;

    const dispatch = useDispatch<AppDispatch>();

    const [selectedId, setSelectedId] = useState(currentClient.clientId || "");
    const [selectedIndex, setSelectedIndex] = useState(1000)

    const [showEditOperatingCostsModal, setShowEditOperatingCostsModal] = useState(false);
    const [showDeleteOperatingCostsModal, setShowDeleteOperatingCostsModal] = useState(false)

    const selectedFacilityRiskAssessmentFields: ClientRiskAssessmentFields = useMemo(() => {
        const facilityRiskAssessmentFields = clientRiskAssessmentConfigs?.find((riskAssessmentConfigs: ClientRiskAssessmentFields) => {
            return riskAssessmentConfigs.clientFacilityId === Number(selectedId)
        })

        if(!facilityRiskAssessmentFields) {
            return clientRiskAssessmentConfigs?.find((riskAssessmentConfigs: ClientRiskAssessmentFields) => {
                return riskAssessmentConfigs.clientFacilityId === null
            })!
        }

        return facilityRiskAssessmentFields
    }, [selectedId, clientRiskAssessmentConfigs])

    const grossCostPerUnitIp = useMemo(() => {
        let retAmount: number = 0;

        if (selectedFacilityRiskAssessmentFields?.bedCapacityIp !== undefined && selectedFacilityRiskAssessmentFields?.bedCapacityIp !== null && selectedFacilityRiskAssessmentFields?.bedCapacityIp !== 0) {
            if (selectedFacilityRiskAssessmentFields?.monthlyOperatingCostIp !== undefined) {
                retAmount = Number(((selectedFacilityRiskAssessmentFields?.monthlyOperatingCostIp / selectedFacilityRiskAssessmentFields?.bedCapacityIp) / 30).toFixed(2));
            }
        }

        return retAmount;
    }, [selectedFacilityRiskAssessmentFields])

    const grossCostPerUnitOp = useMemo(() => {
        let retAmount: number = 0;

        if (selectedFacilityRiskAssessmentFields?.bedCapacityOp !== undefined && selectedFacilityRiskAssessmentFields?.bedCapacityOp !== null && selectedFacilityRiskAssessmentFields?.bedCapacityOp !== 0) {
            if (selectedFacilityRiskAssessmentFields?.monthlyOperatingCostOp !== undefined) {
                retAmount = Number(((selectedFacilityRiskAssessmentFields?.monthlyOperatingCostOp / selectedFacilityRiskAssessmentFields?.bedCapacityOp) / 30).toFixed(2));
            }
        }

        return retAmount;
    }, [selectedFacilityRiskAssessmentFields])

    const isSelectedIdTheClientId = selectedId === currentClient.clientId

    const showDeleteTrashCan = Boolean(selectedFacilityRiskAssessmentFields?.clientFacilityId === selectedId || (isSelectedIdTheClientId && selectedFacilityRiskAssessmentFields?.clientPatientEpisodeMarginId))

    useEffect(() => {
        dispatch(getClientRiskAssessmentConfigs({clientId: currentClient.clientId}))

        return () => {
            dispatch(clearClientRiskAssessmentConfigs())
        }
    }, [currentClient])

    const handleFacilityChange = (e: Event) => {
        const index = Number((e.target as HTMLInputElement).value)
        if(index === 1000) {
            setSelectedId(currentClient.clientId);
        } else {
            setSelectedId(facilities[index].facilityId)
        }

        setSelectedIndex(index)
    };

    function handleEditOperatingCostsClose() {
        setShowEditOperatingCostsModal(false);
    }

    function handleEditCostsModalSubmit() {
        setShowEditOperatingCostsModal(false);
    }

    function handleDeleteOperatingCostsClose() {
        setShowDeleteOperatingCostsModal(false);
    }

    function handleDeleteCostsModalSubmit() {
        setShowDeleteOperatingCostsModal(false);
    }

    const handleBlur = () => { }

    return (
        <Paper>
            {isLoadingFacilities ? (
                <LoadingOverlay />
            ) : (
                <Box
                    style={{
                        margin: "0.5em",
                        padding: "1.0em",
                        borderBottom: "1px solid #cccccc",
                    }}
                >
                    <Grid container justifyContent="space-between" direction="row">
                        <Grid item justifyContent="center"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}
                        >
                            <Typography variant="subtitle2">Facility Costs</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                select={true}
                                test-id="operating-cost-facility-select"
                                label="Facility"
                                name="facility"
                                value={selectedIndex}
                                loading={isLoadingFacilities}
                                onChange={handleFacilityChange}
                                onBlur={handleBlur}
                            >
                                <MenuItem
                                    key={currentClient.clientId}
                                    value={1000}
                                >
                                    Client Default
                                </MenuItem>
                                {facilities
                                    .map((facility: ImplementationFacility, index) => (
                                        <MenuItem
                                            key={facility.facilityId}
                                            value={index}
                                        >
                                            {facility.facilityName}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        <Grid item xs={12}>&nbsp;&nbsp;</Grid>
                    </Grid>
                    <br />
                    {Number(selectedId) > 0 && (
                        <Grid container direction="column">
                            <Grid container justifyContent="space-between" className="mb-4">
                                <Grid item xs={4} >
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">In-Patient Capacity (# Beds)</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <AirlineSeatFlatIcon style={{color: iconColor}} />
                                            <Typography variant="subtitle2" className="ml-4" test-id="ip-bed-capacity">
                                                {selectedFacilityRiskAssessmentFields?.bedCapacityIp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={4}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Monthly Operating Costs</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <AttachMoneyIcon style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="ip-monthly-cost">
                                                {selectedFacilityRiskAssessmentFields?.monthlyOperatingCostIp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Gross Costs per Bed per Day</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <AttachMoneyIcon style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="ip-cost-per-unit">
                                                {grossCostPerUnitIp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={1}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => setShowEditOperatingCostsModal(true)}
                                        className="edit-button"
                                        size="large"><EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Grid container justifyContent="space-between" className="mb-4">
                                <Grid item xs={4} >
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Out-Patient Capacity (# Patients)</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <GroupsIcon style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="op-bed-capacity">
                                                {selectedFacilityRiskAssessmentFields?.bedCapacityOp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={4}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Monthly Operating Costs</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <AttachMoneyIcon style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="op-monthly-cost">
                                                {selectedFacilityRiskAssessmentFields?.monthlyOperatingCostOp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={showDeleteTrashCan ? 3 : 4}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Gross Costs per Patient per Day</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <AttachMoneyIcon style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="op-cost-per-unit">
                                                {grossCostPerUnitOp}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {showDeleteTrashCan && 
                                    <Grid item xs={1}>
                                        <IconButton
                                            color="error"
                                            onClick={() => setShowDeleteOperatingCostsModal(true)}
                                            size="large"><DeleteForeverIcon />
                                        </IconButton>
                                    </Grid>
                                }
                            </Grid>

                            <Grid container justifyContent="space-between">
                                <Grid item xs={4}>
                                    <Grid container direction="column">
                                        <Grid item>
                                            <Typography variant="body2">Variable Cost Percentage</Typography>
                                        </Grid>
                                        <Grid item display={"flex"} justifyContent={"flex-start"}>
                                            <BarChart style={{color: iconColor}}/>
                                            <Typography variant="subtitle2" className="ml-4" test-id="variable-cost-pct">
                                                {selectedFacilityRiskAssessmentFields?.variableCostPct}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    )}

                    {showEditOperatingCostsModal && (
                        <OperatingCostsModal
                            open={showEditOperatingCostsModal}
                            selectedFacilityRiskAssessmentFields={selectedFacilityRiskAssessmentFields}
                            selectedId={Number(selectedId)}
                            handleOperatingCostsModalCancel={handleEditOperatingCostsClose}
                            handleOperatingCostsModalSubmit={handleEditCostsModalSubmit}
                        />
                    )}

                    {showDeleteOperatingCostsModal && (
                        <DeleteOperatingCostsModal
                            open={showDeleteOperatingCostsModal}
                            selectedFacilityRiskAssessmentFields={selectedFacilityRiskAssessmentFields}
                            handleDeleteOperatingCostsModalCancel={handleDeleteOperatingCostsClose}
                            handleDeleteOperatingCostsModalSubmit={handleDeleteCostsModalSubmit}
                        />
                    )}
                </Box>
            )
            }
        </Paper>
    );
}

export default OperatingCosts;