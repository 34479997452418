import { State } from "../model/state";

export const statesList: State[] = [
  {
    stateId: 1,
    stateName: "Alaska",
    stateCode: "AK"
  },
  {
    stateId: 2,
    stateName: "Alabama",
    stateCode: "AL"
  },
  {
    stateId: 3,
    stateName: "Arizona",
    stateCode: "AZ"
  },
  {
    stateId: 4,
    stateName: "Arkansas",
    stateCode: "AR"
  },
  {
    stateId: 5,
    stateName: "California",
    stateCode: "CA"
  },
  {
    stateId: 6,
    stateName: "Colorado",
    stateCode: "CO"
  },
  {
    stateId: 7,
    stateName: "Connecticut",
    stateCode: "CT"
  },
  {
    stateId: 8,
    stateName: "Delaware",
    stateCode: "DE"
  },
  {
    stateId: 9,
    stateName: "District Of Columbia",
    stateCode: "DC"
  },
  {
    stateId: 10,
    stateName: "Florida",
    stateCode: "FL"
  },
  {
    stateId: 11,
    stateName: "Georgia",
    stateCode: "GA"
  },
  {
    stateId: 12,
    stateName: "Hawaii",
    stateCode: "HI"
  },
  {
    stateId: 13,
    stateName: "Idaho",
    stateCode: "ID"
  },
  {
    stateId: 14,
    stateName: "Illinois",
    stateCode: "IL"
  },
  {
    stateId: 15,
    stateName: "Indiana",
    stateCode: "IN"
  },
  {
    stateId: 16,
    stateName: "Iowa",
    stateCode: "IA"
  },
  {
    stateId: 17,
    stateName: "Kansas",
    stateCode: "KS"
  },
  {
    stateId: 18,
    stateName: "Kentucky",
    stateCode: "KY"
  },
  {
    stateId: 19,
    stateName: "Louisiana",
    stateCode: "LA"
  },
  {
    stateId: 20,
    stateName: "Maine",
    stateCode: "ME"
  },
  {
    stateId: 21,
    stateName: "Maryland",
    stateCode: "MD"
  },
  {
    stateId: 22,
    stateName: "Massachusetts",
    stateCode: "MA"
  },
  {
    stateId: 23,
    stateName: "Michigan",
    stateCode: "MI"
  },
  {
    stateId: 24,
    stateName: "Minnesota",
    stateCode: "MN"
  },
  {
    stateId: 25,
    stateName: "Mississippi",
    stateCode: "MS"
  },
  {
    stateId: 26,
    stateName: "Missouri",
    stateCode: "MO"
  },
  {
    stateId: 27,
    stateName: "Montana",
    stateCode: "MT"
  },
  {
    stateId: 28,
    stateName: "Nebraska",
    stateCode: "NE"
  },
  {
    stateId: 29,
    stateName: "Nevada",
    stateCode: "NV"
  },
  {
    stateId: 30,
    stateName: "New Hampshire",
    stateCode: "NH"
  },
  {
    stateId: 31,
    stateName: "New Jersey",
    stateCode: "NJ"
  },
  {
    stateId: 32,
    stateName: "New Mexico",
    stateCode: "NM"
  },
  {
    stateId: 33,
    stateName: "New York",
    stateCode: "NY"
  },
  {
    stateId: 34,
    stateName: "North Carolina",
    stateCode: "NC"
  },
  {
    stateId: 35,
    stateName: "North Dakota",
    stateCode: "ND"
  },
  {
    stateId: 36,
    stateName: "Ohio",
    stateCode: "OH"
  },
  {
    stateId: 37,
    stateName: "Oklahoma",
    stateCode: "OK"
  },
  {
    stateId: 38,
    stateName: "Oregon",
    stateCode: "OR"
  },
  {
    stateId: 39,
    stateName: "Pennsylvania",
    stateCode: "PA"
  },
  {
    stateId: 40,
    stateName: "Puerto Rico",
    stateCode: "PR"
  },
  {
    stateId: 41,
    stateName: "Rhode Island",
    stateCode: "RI"
  },
  {
    stateId: 42,
    stateName: "South Carolina",
    stateCode: "SC"
  },
  {
    stateId: 43,
    stateName: "South Dakota",
    stateCode: "SD"
  },
  {
    stateId: 44,
    stateName: "Tennessee",
    stateCode: "TN"
  },
  {
    stateId: 45,
    stateName: "Texas",
    stateCode: "TX"
  },
  {
    stateId: 46,
    stateName: "Utah",
    stateCode: "UT"
  },
  {
    stateId: 47,
    stateName: "Vermont",
    stateCode: "VT"
  },
  {
    stateId: 48,
    stateName: "Virginia",
    stateCode: "VA"
  },
  {
    stateId: 49,
    stateName: "Washington",
    stateCode: "WA"
  },
  {
    stateId: 50,
    stateName: "West Virginia",
    stateCode: "WV"
  },
  {
    stateId: 51,
    stateName: "Wisconsin",
    stateCode: "WI"
  },
  {
    stateId: 52,
    stateName: "Wyoming",
    stateCode: "WY"
  }
]

export const stateAbbrevs = statesList.map(state => state.stateCode);
export const stateNames = statesList.map(state => state.stateName);
export const stateIds = statesList.map(state => state.stateId);

export const stateAbbrevLookup = (stateAbbreviation: string) => { // lookup states by abbrev and return the state's name.
  const foundState = statesList.find(state => state.stateCode === stateAbbreviation)
  return { name: foundState?.stateName, id: foundState?.stateId };
}

export const getState = (stateId: number) => {
  return statesList.find((state) => state.stateId === stateId);
}

export const getStateByName = (stateName: string) => {
  return statesList.find((state) => state.stateName === stateName);
}

export const getStateByAbbrev = (stateCode: string) => {
  return statesList.find((state) => state.stateCode === stateCode);
}