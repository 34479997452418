import { PayOutFrequency } from "../../implementation-specialist/models/payOutFreq";
import { Address } from "./address";
import { Column, emptyColumn } from "./column";
import { ExternalAccount, PaynowExternalAccount } from "./external-account";
import { Workflow } from "./client"
import { PrimaryContact } from "./primary-contact";
import { CustomConfig } from "./client-details";

export interface ClientFacility { // implementation and client onboarding
  userFacilityScopeId: number;
  facilityId: number;
  clientId?: number;
  customDocTokenId?: string;
  facilityName: string;
  patientPmtFreq: string;
  payOutFreq: PayOutFrequency;
  payOutDay: number;
  addressId: number;
  address?: Address; // for frontend only
  phoneNum?: string;
  facilityType?: string;
  isRuleSetupComplete?: boolean;
  outpatientEnabled?: boolean;
  taxId: string;
  taxIdLast4: string;
  rcInNetworkPfrHigh?: number;
  rcInNetworkPfrLow?: number;
  rcOutOfNetworkPfrHigh?: number;
  rcOutOfNetworkPfrLow?: number;
  vboPfrThreshold?: number;
  vboTimeThreshold?: number;
  websiteUrl?: string;
  externalAccount: ExternalAccount;
  paynowExternalAccount?: PaynowExternalAccount;
  primaryContact?: PrimaryContact;
  SSN: string;
  DOB: string;
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  connectedAccount: string;
  documentation: string;
  status?: string; // frontend only
  column?: Column[]; // frontend only
  workflow?: Workflow;
  customConfigs?: CustomConfig[];
  workflowStatusDt?: string;
}


export const emptyClientFacility: ClientFacility = {
  userFacilityScopeId: 0,
  facilityId: 0,
  facilityName: "",
  patientPmtFreq: "",
  payOutFreq: "daily",
  payOutDay: -1,
  addressId: -1,
  facilityType: "",
  taxId: "",
  taxIdLast4: "",
  externalAccount: {
    externalAccountId: "",
    externalStatus: ""
  },
  DOB: "",
  SSN: "",
  bankName: "",
  routingNumber: "",
  accountNumber: "",
  connectedAccount: "",
  documentation: "accepted",
  status: "",
  column: [emptyColumn]
};

// this is being used to mock out data to the store.
// TODO: remove this once services are being used / we have all the data we need.
export const valuedClientFacility: ClientFacility = {
  userFacilityScopeId: 1,
  facilityId: 1,
  facilityName: "Test Facility",
  patientPmtFreq: "D,W,M,E",
  payOutFreq: "daily",
  payOutDay: 1,
  addressId: 1,
  facilityType: "Facility Type",
  taxId: "09-876543",
  taxIdLast4: "6543",
  externalAccount: {
    externalAccountId: "",
    externalStatus: ""
  },
  SSN: "•••••1368",
  DOB: "01/24/1979",
  bankName: "Bank of America",
  routingNumber: "•••••789",
  accountNumber: "•••••••••••1234",
  connectedAccount: "act_28459948582910458",
  documentation: "accepted",
  status: "pending",
  column: [
    { id: "name", label: "Name", minWidth: 450 },
    { id: "status", label: "Status", minWidth: 130 },
    { id: "menu", label: "", minWidth: 25 }
  ]
}
