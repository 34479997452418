import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import {showErrorStatus, showStatus} from "../../../security/state/user-slice";
import { errorCodes, ExternalAccountType } from "../../../shared/enums";
import { RootState } from "../../../shared/state/root-reducer";
import { Utils } from "../../../shared/utils";
import { ImplementationFacility, OperatingCost} from "../../components/implementation-clients/details/models/implementation-facility";
import { ExternalAccountFormPayload } from "../../models/external-account";
import { FacilityForm } from "../../models/facility-form";
import { specialistFacilityService } from "../../services/implementation-facility-service";
import { setRulesEngineTabComplete } from "../clients/implementation-clients-slice";

export const getClientFacilities = createAsyncThunk(
  "implementationFacilityContext/getClientFacilities",
  async (clientId: number, thunkAPI) => {
    const response = await specialistFacilityService.getClientFacilities(clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
});

export const getClientConnectAccounts = createAsyncThunk(
  "implementationFacilityContext/getClientConnectAccounts",
  async (clientId: number, thunkAPI) => {
    const response = await specialistFacilityService.getClientConnectAccounts(clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
});

export const getClientFacilityById = createAsyncThunk(
  "implementationFacilityContext/getClientFacilityById",
  async (facilityId: number, thunkAPI) => {
    const response = await specialistFacilityService.getClientFacilityById(facilityId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
});

export const getClientFacility = createAsyncThunk(
  "implementationSpecialistClientContext/getClientFacility",
  async (data: {clientId: number, facilityId: number}, thunkAPI) => {
    const response = await specialistFacilityService.getClientFacility(data.clientId, data.facilityId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
  }
);

//payNow
export const getEncryptFacilityPayload = createAsyncThunk(
  "implementationSpecialistClientContext/getEncryptFacilityPayload",
  async (data: string, thunkAPI) => {
    const response = await specialistFacilityService.getEncryptFacilityPayload(data);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
      return;
    }
    return response.entity;
  }
)

export const getFacilityKPIs = createAsyncThunk(
  "implementationSpecialistClientContext/getFacilityKPIs",
  async (data: {facility: ImplementationFacility, months: number}, thunkAPI) => {
    const { facility, months } = data;
    const response = await specialistFacilityService.getFacilityKPIs(facility?.clientId, facility?.facilityId, months);

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error();
    } else {
      // we need to map the KPI onto the existing facility.
      // Get index of facility group. Now we are have the sub-array of facilities. Once we are in the sub-array of facilities,
      // attach the facilityKPIs to every facility in that sub-array.
      const state = thunkAPI.getState() as RootState;
      const groupedFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities)
      const indexOfFacilityGroup = groupedFacilities.findIndex((facilityList: ImplementationFacility[]) => (
        facilityList[0]?.externalAccount?.externalAccountId === facility?.externalAccount?.externalAccountId
      ))
      const newGroupedFacilities = groupedFacilities[indexOfFacilityGroup].map((facility: ImplementationFacility) => (
        { ...facility, facilityKPIs: response.entity}
      ))

      groupedFacilities[indexOfFacilityGroup] = newGroupedFacilities;

      response.entity = {
        newGroupedFacilities: groupedFacilities
      }

      return response.entity;

    }
  }
)

export const addClientFacilityExternalAccount = createAsyncThunk(
  "implementationSpecialistClientContext/addClientFacilityExternalAccount",
  async (data: {facilityId: number, externalAccountType: ExternalAccountType}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allClientFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities).flat();

    const response = await specialistFacilityService.addClientFacilityExternalAccount(data.facilityId, data.externalAccountType, allClientFacilities);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Patient Connect Account added successfully.'));
      return response.entity;
    }
  }
);

export const addPayNowClientFacilityExternalAccount = createAsyncThunk(
  "implementationSpecialistClientContext/addPayNowClientFacilityExternalAccount",
  async (data: {facilityId: number, externalAccountType: ExternalAccountType}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allClientFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities).flat();

    const response = await specialistFacilityService.addClientFacilityExternalAccount(data.facilityId, data.externalAccountType, allClientFacilities);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Web Payment Connect Account added successfully.'));
      return response.entity;
    }
  }
);


export const saveClientFacility = createAsyncThunk(
  "implementationSpecialistClientContext/saveClientFacility",
  async (data: {clientId: number, formValues: FacilityForm}, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const fullClientFacility = Utils.deepClone(state.implementationContext.implementationFacility.apiResponsefacility);
    const allClientFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities).flat();
    const response = await specialistFacilityService.saveClientFacility(data.clientId, data.formValues, fullClientFacility, allClientFacilities);

    if (response.hasErrors) {
     throw Utils.robustErrorHandler(response, thunkAPI);
    }

    if (data?.formValues?.facilityId === 0) {
      thunkAPI.dispatch(setRulesEngineTabComplete(false))
    }

    return response.entity;
  }
);

export const saveExternalAccount = createAsyncThunk(
  "implementationSpecialistClientContext/saveExternalAccount",
  async (data: ExternalAccountFormPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const allClientFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities).flat();

    const response = await specialistFacilityService.saveExternalAccount(data, allClientFacilities);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Setup Completed Successfully.'));
      return response.entity;
    }
  }
)

export const saveClientFacilityVob = createAsyncThunk(
    "implementationSpecialistClientContext/saveClientFacilityVob",
    async (data: {clientId: number, facilityToSave: ImplementationFacility}, thunkAPI) => {
      const state = thunkAPI.getState() as RootState;
      const allClientFacilities = Utils.deepClone(state.implementationContext.implementationFacility.facilities).flat();
      const response = await specialistFacilityService.saveClientFacilityVob(data.clientId, data.facilityToSave, allClientFacilities);

      if (response.hasErrors) {
        Utils.robustErrorHandler(response, thunkAPI);
        return;
      }
      thunkAPI.dispatch(showStatus('Outpatient Thresholds Saved'));

      return response.entity;
    }
);

export const deleteClientFacilityConfig = createAsyncThunk(
  "implementationSpecialistClientContext/deleteClientFacilityConfig",
  async (data: {facilityId: number, configKey: string, configValue: string}, thunkAPI) => {
      const response = await specialistFacilityService.deleteClientFacilityConfig(data.facilityId, data.configKey, data.configValue);

      if (response.hasErrors) {
        let errorMessage = response.errorMessage
        if(response.entity.code === errorCodes.externalServiceError){
          errorMessage = response.entity.message.substring(0, response.entity.message.indexOf(";"))
        }
        throw new Error(errorMessage);
      }

      return response.entity;
  }
);

export const saveFacilityOperatingCosts = createAsyncThunk(
  'implementationSpecialistClientContext/saveFacilityOperatingCosts',
  async(data: {facilityId: number, dataToSave: OperatingCost}, thunkAPI) => {

    const response = await specialistFacilityService.saveFacilityOperatingCosts(data.facilityId, data.dataToSave);

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      thunkAPI.dispatch(showStatus('Facility Operating Costs updated successfully.'))
      return response.entity;
    }
  }
);

export const activateFacilities = createAsyncThunk(
        "implementationFacilityContext/activateFacilities",
    async(data: {clientId: number, payload: any}, thunkAPI) => {

      const state = thunkAPI.getState() as RootState;

      const updateFacilityPromises = state.implementationContext.implementationFacility.facilities
        // facilities is a list of facility groups
        .flat()
        // sanity check that we are trying to update the correct facility
        .filter(facility => (+facility.clientId === +data.clientId))
        .map(facility => specialistFacilityService.updateFacility({clientFacilityId: facility.facilityId, payload: data.payload}));

      const updatedFacilities = _.chain(await Promise.all(updateFacilityPromises))
        .map((resolvedPromise: any) => {
          if (resolvedPromise.hasErrors) {
            thunkAPI.dispatch(showErrorStatus(resolvedPromise.errorMessage));
            throw new Error(resolvedPromise.errorMessage);
          }
          return resolvedPromise.entity as ImplementationFacility
        })
        // updatedFacilities should be a list of facility groups
        .groupBy((facility) => facility.facilityName.toUpperCase().charAt(0))
        .values()
        .value()

      return {
        updatedFacilities
      }

    }
)


