import { StatusColors } from "../../shared/enums";

interface ClientFacilityStatus {
  status: string;
  type: "primary-status" | "sub-status";
  color: StatusColors;
}

export const statusNames = {
  ACTIVE: "Active",
  ACCOUNT_ACTIVE: "Account Active",
  FOLLOW_UP: "Follow Up",
  SETUP_INCOMPLETE: "Setup Incomplete",
  READY_FOR_ACTIVATION: "Ready for Activation",
  STRIPE_SETUP_INCOMPLETE: "Stripe Setup Incomplete",
  CLOSED: "Closed",
  ACCOUNT_CLOSED_DEACTIVATED: "Account Closed / Deactivated",
  ALERT: "Alert",
  STRIPE_ACTION_NEEDED: "Stripe Action Needed",
};


export const clientFacilityStatus: ClientFacilityStatus[] = [
  {
    status: statusNames.ACTIVE,
    type: "primary-status",
    color: StatusColors.success,
  },
  {
    status: statusNames.ACCOUNT_ACTIVE,
    type: "sub-status",
    color: StatusColors.success,
  },
  {
    status: statusNames.FOLLOW_UP,
    type: "primary-status",
    color: StatusColors.warning,
  },
  {
    status: statusNames.SETUP_INCOMPLETE,
    type: "sub-status",
    color: StatusColors.warning,
  },
  {
    status: statusNames.READY_FOR_ACTIVATION,
    type: "sub-status",
    color: StatusColors.warning,
  },
  {
    status: statusNames.STRIPE_SETUP_INCOMPLETE,
    type: "sub-status",
    color: StatusColors.warning,
  },
  {
    status: statusNames.CLOSED,
    type: "primary-status",
    color: StatusColors.error,
  },
  {
    status: statusNames.ACCOUNT_CLOSED_DEACTIVATED,
    type: "sub-status",
    color: StatusColors.error,
  },
  {
    status: statusNames.ALERT,
    type: "primary-status",
    color: StatusColors.error,
  },
  {
    status: statusNames.STRIPE_ACTION_NEEDED,
    type: "sub-status",
    color: StatusColors.error,
  },
];