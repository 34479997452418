import '../../../../../scss/components/_table.scss';
import '../../../../../scss/pages/admin/_facilities-view.scss';

import { Button, LoadingOverlay, Status } from '@finpay-development/shared-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { statusNames } from '../../../../../implementation-specialist/misc/client-and-facility-status';
import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from '../../../../../security/model/role-page-names';
import { showStatus } from '../../../../../security/state/user-slice';
import { StatusColors } from '../../../../../shared/enums';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { clientFacilityStatus } from '../../../../misc/client-and-facility-status';
import { clearFacilityForm, clearStatus } from '../../../../state/facility/implementation-facility-slice';
import { getClientFacility } from '../../../../state/facility/implementation-facility-thunk';
import { ImplementationFacility } from '../models/implementation-facility';
import { CompleteSetupModal } from './complete-setup-modal';
import { FacilityDetailsModal } from './details-modal';
import { FacilityModal } from './facility-modal';

type MenuActionType = "edit" | "details" | "complete";

function Facilities() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isFacilityEditModalOpen, setIsFacilityEditModalOpen] = useState(false);
  const [isFacilityAddModalOpen, setIsFacilityAddModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [facility, setFacility] = useState<ImplementationFacility>();

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    currentClientId: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.currentClientEntityDetails.clientId
    ),
    implementationFacilities: useSelector( // facilities grouped by external account Id
      (state: RootState) => state.implementationContext.implementationFacility.facilities
    ),
    isLoading: useSelector(
      (state: RootState) => state.implementationContext.implementationFacility.isLoading
    ),
  }

  function handleCompleteCancel() {
    setIsCompleteModalOpen(false);
  }

  function handleCompleteSubmit() {
    setIsCompleteModalOpen(false);
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    facility: ImplementationFacility
  ) => {
    setAnchorEl(event.currentTarget);
    setFacility(facility);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);

    switch (menuActionType) {
      case "edit":
        setIsFacilityEditModalOpen(true);
        dispatch(getClientFacility({clientId: selectors.currentClientId, facilityId: facility!.facilityId}));
        break;
      case "details":
        setIsDetailsModalOpen(true);
        dispatch(getClientFacility({clientId: selectors.currentClientId, facilityId: facility!.facilityId}));
        break;
      case "complete":
        setIsCompleteModalOpen(true);
        break;
    }
  };

  function handleFacilityModalCancel() {
    setIsFacilityEditModalOpen(false);
    setIsFacilityAddModalOpen(false);
  }

  function handleFacilityModalSubmit(isEdit: boolean) {
    setIsFacilityEditModalOpen(false);
    setIsFacilityAddModalOpen(false);

    if (isEdit) {
      dispatch(showStatus("Facility Updated"));
    } else {
      dispatch(showStatus("Facility created"));
    }
  }

  function isStripeCompleted() {
    return ((facility?.workflow?.workflowStatus?.workflowStatusDesc === statusNames.ACTIVE)
      || (facility?.workflow?.workflowSubStatus?.workflowSubStatusDesc === statusNames.READY_FOR_ACTIVATION));
  }

  const addFacilitiesMessage = (
    <Grid container justifyContent="center">
      <Typography variant="h1">Add a Facility</Typography>
    </Grid>
  );

  const tableCellHeader = (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 250 }}>Name</TableCell>
        <TableCell style={{ minWidth: 130 }}>Connect Account</TableCell>
        <TableCell style={{ minWidth: 130 }}>Status</TableCell>
        <TableCell style={{ minWidth: 25 }}></TableCell>
      </TableRow>
    </TableHead>
  );

  const facilityItem = (facilityGroup: any, key: number) => {

    const getConnectAccounts = (facility: ImplementationFacility) => {
      return (
        <div>{facility.externalAccount.externalAccountId}&nbsp;<br/>{facility.paynowExternalAccount.externalAccountId}&nbsp;</div>
      );
    };

    const getStatus = (facility: ImplementationFacility) => {
      let statusColor: StatusColors;
      const foundStatus = clientFacilityStatus.find((status) => {
        return status.status === facility?.workflow?.workflowSubStatus?.workflowSubStatusDesc;
      })
      if (foundStatus) {
        statusColor = foundStatus.color;
      } else {
        statusColor = StatusColors.warning; // TODO(JB): force this color for now until backend supports sending back a status
      }

      return (
        <Status
          text={
            facility.workflow?.workflowSubStatus?.workflowSubStatusDesc
          }
          statusColor={statusColor}
        />
      )
    }

    const getTitleDisplay = (facilityName: string) => {
      let titleDisplay = '';
      titleDisplay = facilityName ? facilityName.slice(0, 1).toUpperCase() : 'Facility Name Not Found';
      return titleDisplay;
    }

    return (
      <Accordion defaultExpanded key={key}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="accordion-entity">
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">
                {getTitleDisplay(facilityGroup[0].facilityName)}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table
              data-testid="results"
              className="table alternating-row"
              size="small"
              stickyHeader
            >
              {tableCellHeader}
              <TableBody>
                {facilityGroup.map(
                  (facility: ImplementationFacility, index: number) => (
                    <TableRow
                      data-testid="result-row"
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell style={{ minWidth: 250 }}>
                        <Typography variant="body1">
                          {facility.facilityName}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 130 }}>
                        {getConnectAccounts(facility)}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 130 }}>
                        {getStatus(facility)}
                      </TableCell>
                      <TableCell align="right" style={{ minWidth: 25 }}>
                        <>
                          <IconButton
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, facility)}
                            size="large">
                            <MoreHorizIcon />
                          </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className="facilities-view">
      <Grid container justifyContent="flex-end">
        <Grid item>
          <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Facility"
            renderNoAccess={() => <Button disabled>Add New Facility</Button>}>
            <Button onClick={() => {
              dispatch(clearStatus());
              dispatch(clearFacilityForm());
              setIsFacilityAddModalOpen(true);
            }}
              marginBottom={4}
            >
              Add New Facility</Button>
          </AccessControl>
        </Grid>
      </Grid>
      {selectors.implementationFacilities?.length === 0 && addFacilitiesMessage}
      {selectors.isLoading ? <LoadingOverlay /> :
        selectors.implementationFacilities?.map((facilityAccountGroup, index) =>
        facilityItem(facilityAccountGroup, index)
        )
      }

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
      >
        <div>
          <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Facility"
            renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}>
            <MenuItem className="primary" onClick={() => handleMenuClose("edit")}>Edit</MenuItem>
          </AccessControl>
          <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Facility"
            renderNoAccess={() => <MenuItem disabled>Details</MenuItem>}>
            <MenuItem className="primary" onClick={() => handleMenuClose("details")}>Details</MenuItem>
          </AccessControl>
          <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Facility"
            renderNoAccess={() => <MenuItem disabled>Complete</MenuItem>}>
            {!isStripeCompleted() && <MenuItem className="primary" onClick={() => handleMenuClose("complete")}>Complete</MenuItem>}
          </AccessControl>
        </div>
      </Menu>

      {isFacilityAddModalOpen && (
        <FacilityModal
          open={isFacilityAddModalOpen}
          handleFacilityModalCancel={handleFacilityModalCancel}
          handleFacilityModalSubmit={handleFacilityModalSubmit}
          isEdit={false}
        />
      )}

      {isFacilityEditModalOpen && (
        <FacilityModal
          open={isFacilityEditModalOpen}
          handleFacilityModalCancel={handleFacilityModalCancel}
          handleFacilityModalSubmit={handleFacilityModalSubmit}
          isEdit={true}
        />
      )}

      {isCompleteModalOpen && (
        <CompleteSetupModal
          open={isCompleteModalOpen}
          facilityId={facility!.facilityId}
          facilityName={facility!.facilityName}
          isClientOwner={false}
          handleCompleteCancel={handleCompleteCancel}
          handleCompleteSubmit={handleCompleteSubmit}
        />
      )}

      {isDetailsModalOpen && (
        <FacilityDetailsModal
          open={isDetailsModalOpen}
          handleModalClose={() => setIsDetailsModalOpen(false)}
        />
      )}
    </div>
  );
}

export default Facilities;
