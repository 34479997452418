import {
    DeleteModal,
    LoadingOverlay,
    saveCallbackStatus,
  } from "@finpay-development/shared-components";
  import {
    Box,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import Paper from "@mui/material/Paper";
  import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
  import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
  import TablePagination from "@mui/material/TablePagination";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { TableColumn } from "../../../shared/model/table-column";
  import { RootState } from "../../../shared/state/root-reducer";
  import { AppDispatch } from "../../../shared/state/store";
  import {
    ConfigRiskThreshold,
    RiskThresholdSymbols,
  } from "../../models/risk-threshold";
  import {
    configDeleteRiskThreshold,
    configGetRiskThresholds,
  } from "../../state/admin-configuration-thunk";
  import { RiskThresholdConfigModal } from "./risk-threshold-config-modal";
  import { Utils } from "../../../shared/utils";
  import { riskAssessmentUtils } from "../../../admissions-advisor/utils/risk-assessment-utils"; 
  import { clearStatus } from "../../state/admin-configuration-slice";
  
  type MenuActionType = "add" | "edit" | "delete";
  
  const columns: TableColumn[] = [
    { id: "thresholdName", label: "Threshold Name", minWidth: 200 },
    { id: "sortOrder", label: "Sort Order", minWidth: 100 },
    { id: "lowRisk", label: "Low Risk", minWidth: 100 },
    { id: "mediumRisk", label: "Medium Risk", minWidth: 100 },
    { id: "highRisk", label: "High Risk", minWidth: 100 },
    { id: "menu", label: "", minWidth: 25 },
  ];
  
  function RiskThresholdConfigGrid() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isRiskThresholdConfigModalOpen, setIsRiskThresholdConfigModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [originalRows, setOriginalRows] = useState<ConfigRiskThreshold[]>();
    const [rows, setRows] = useState<ConfigRiskThreshold[]>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditModal, setIsEditModal] = useState(true);
    const [selectedRiskThreshold, setSelectedRiskThreshold] = useState<ConfigRiskThreshold>();
    const [deleteTitle, setDeleteTitle] = useState("");
    const paramId: number = -2;
  
    const dispatch = useDispatch<AppDispatch>();
  
    const state = {
      riskThresholds: useSelector(
        (state: RootState) =>
          state.adminContext.adminConfigurationContext?.riskThresholds
      ),
      isLoadingRiskThresholdsState: useSelector(
        (state: RootState) =>
          state.adminContext.adminConfigurationContext?.isLoading
            .isLoadingRiskThresholds
      ),
      getRiskThresholdsStatus: useSelector(
        (state: RootState) =>
          state.adminContext.adminConfigurationContext?.isLoading
            .getRiskThresholdsStatus
      ),
    };
    const {
      riskThresholds,
      isLoadingRiskThresholdsState,
      getRiskThresholdsStatus,
    } = state;
  
    useEffect(() => {
      if (!isLoadingRiskThresholdsState && getRiskThresholdsStatus === saveCallbackStatus.success) {
        if (riskThresholds !== undefined) {
          const dataCopy: ConfigRiskThreshold[] = Utils.deepClone(riskThresholds);
          setIsLoading(false);
          setOriginalRows(dataCopy);
          setRows(dataCopy);
          dispatch(clearStatus());
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingRiskThresholdsState]);
  
    const getRiskThresholds = async () => {
      dispatch(configGetRiskThresholds({ paramId: paramId }));
    };
  
    useEffect(() => {
      getRiskThresholds();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    const handleNewRiskThresholdClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setIsEditModal(false);
      // get highest existing sort order, add 5
      let maxSort: number | undefined = originalRows?.reduce((acc, value) => {
        return (acc = acc > value.sortOrder ? acc : value.sortOrder);
      }, 0);
      setSelectedRiskThreshold({
        paramId: paramId,
        riskThresholdId: -1,
        riskThresholdName: "",
        riskThresholdCode: "",
        sortOrder: maxSort! + 5,
        lowRiskDefault: 0,
        mediumRiskDefault: 0,
        highRiskDefault: 0,
      });
      setIsRiskThresholdConfigModalOpen(true);
    };
  
    const handleMenuClose = (menuActionType?: MenuActionType) => {
      setAnchorEl(null);
  
      switch (menuActionType) {
        case "edit":
          setIsEditModal(true);
          setIsRiskThresholdConfigModalOpen(true);
          break;
        case "delete":
          const deleteTitle = `Delete Risk Threshold: ${selectedRiskThreshold?.riskThresholdName} ?`;
          setDeleteTitle(deleteTitle);
          setIsDeleteModalOpen(true);
          break;
      }
    };
  
    const handleMenuClick = (
      event: React.MouseEvent<HTMLButtonElement>,
      thresholdId: number
    ) => {
      setAnchorEl(event.currentTarget);
      let thisRiskThreshold = originalRows?.find(
        (el) => el.riskThresholdId === thresholdId
      );
      if (thisRiskThreshold) {
        let riskThresholdCopy: ConfigRiskThreshold =
          Utils.deepClone(thisRiskThreshold);
        setSelectedRiskThreshold(riskThresholdCopy);
      }
    };
  
    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    function handleRiskThresholdConfigModalCancel() {
      setIsRiskThresholdConfigModalOpen(false);
    }
  
    async function handleRiskThresholdConfigModalSubmit(isEditMode: boolean) {
      setIsRiskThresholdConfigModalOpen(false);
  
      setIsLoading(true);
      await getRiskThresholds();
    }
  
    function handleDeleteCancel() {
      setIsDeleteModalOpen(false);
    }
  
    async function handleDeleteOk() {
      const deletePostBody: any = { ...selectedRiskThreshold, paramId: paramId };
      await dispatch(configDeleteRiskThreshold(deletePostBody));
      setIsDeleteModalOpen(false);
      setIsLoading(true);
      await getRiskThresholds();
    }
  
    return (
      <Paper>
        {isLoading ? (
          <LoadingOverlay />
        ) : (
          <Box style={{ padding: "1em", marginBottom: "1.5em" }}>
            <Grid container justifyContent="space-between">
              <Grid>
                <Box style={{ padding: "1em 0" }}>
                  <Typography variant="subtitle2">Risk Thresholds</Typography>
                </Box>
              </Grid>
              <Grid></Grid>
            </Grid>
            <TableContainer component={Paper}>
              <Table
                data-testid="results"
                className="table alternating-row"
                size="small"
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    {columns &&
                      columns?.map((column) => (
                        <TableCell
                          key={column?.id}
                          align={column?.align}
                          style={{
                            padding: "0.5em",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.slice!(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
                    .map((row: ConfigRiskThreshold) => {
                      const symbols: RiskThresholdSymbols =
                        riskAssessmentUtils.getRiskThresholdSymbols(
                          row?.riskThresholdCode
                        );
  
                      return (
                        <TableRow key={row?.riskThresholdId}>
                          <TableCell align="left" style={{ padding: "0.5em" }}>
                            {row?.riskThresholdName}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "0.5em" }}>
                            {row?.sortOrder}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "0.5em" }}>
                            {symbols.lowRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {row?.lowRiskDefault}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "0.5em" }}>
                            {symbols.mediumRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {row?.mediumRiskDefault}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}
                          </TableCell>
                          <TableCell align="left" style={{ padding: "0.5em" }}>
                            {symbols.highRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {row?.highRiskDefault}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              aria-haspopup="true"
                              onClick={(e) => {
                                handleMenuClick(e, row.riskThresholdId);
                              }}
                              size="large"
                            >
                              <MoreHorizIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <IconButton
                    title="Add Risk Threshold"
                    color="primary"
                    onClick={(e) => handleNewRiskThresholdClick(e)}
                    size="large"
                  >
                    <AddBoxOutlinedIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <TablePagination
                    rowsPerPageOptions={[
                      10,
                      50,
                      100,
                      { value: rows?.length! | 0, label: "All" },
                    ]}
                    component="div"
                    count={rows?.length! | 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={"View"}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </TableContainer>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => handleMenuClose()}
            >
              <MenuItem
                className="primary"
                onClick={() => handleMenuClose("edit")}
              >
                Edit
              </MenuItem>
              <MenuItem
                className="danger"
                onClick={() => handleMenuClose("delete")}
              >
                Delete
              </MenuItem>
            </Menu>
            {isRiskThresholdConfigModalOpen && (
              <RiskThresholdConfigModal
                open={isRiskThresholdConfigModalOpen}
                isEdit={isEditModal}
                handleScholarshipCancel={handleRiskThresholdConfigModalCancel}
                handleScholarshipSubmit={handleRiskThresholdConfigModalSubmit}
                riskThresholdItem={selectedRiskThreshold!}
              />
            )}
            {isDeleteModalOpen && (
              <DeleteModal
                open={isDeleteModalOpen}
                title={deleteTitle}
                subTitle="This action cannot be undone"
                okButtonText="Delete Forever"
                handleDeleteCancel={handleDeleteCancel}
                handleDeleteOk={handleDeleteOk}
              />
            )}
          </Box>
        )}
      </Paper>
    );
  }
  
  export default RiskThresholdConfigGrid;
  