export interface AuthDocs {
    authorizationDocumentsId?: number | undefined;
    typeOfDoc?: string;
    documentUrl?: string;
}

export const emptyAuthDocs: AuthDocs = {
    authorizationDocumentsId: 0,
    typeOfDoc: "",
    documentUrl: "",
}

export interface AuthDocsForm {
    authorizationDocumentsId: number | undefined;
    typeOfDoc: string;
    documentUrl: string;
    clientId?:number,
    clientFacilityId: number;
    isSigReq: boolean;
}

export const blankAuthDocsForm: AuthDocsForm = {
    authorizationDocumentsId: 0,
    typeOfDoc: "",
    documentUrl: "",
    clientFacilityId: 0,
    isSigReq: true
}

export interface AuthDocsDetails {
    clientId: number;
    authorizationDocumentsId: number | undefined;
    typeOfDoc: string;
    documentUrl: string;
    clientFacilityId: number;
    isSigReq: boolean;
}


export const blankAuthDocsDetails: AuthDocsDetails = {
    clientId: 0,
    authorizationDocumentsId: 0,
    typeOfDoc: "",
    documentUrl:"",
    clientFacilityId: 0,
    isSigReq: true,
}


export interface AuthDocsUIForm {
    clientId?: number;
    authorizationDocumentsId?: number | undefined;
    typeOfDoc?: string | undefined;
    documentUrl?: string | undefined;
    clientFacilityId?: number | null;

}