import '../../../../scss/components/_icons.scss';

import { ProgressBar } from '@finpay-development/shared-components';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { formatNumberToUSD } from '../../../../shared/calculators';
import HeaderWithDetails from '../../../../shared/components/header-with-details';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { getInitialEncounter } from '../../../state/patient-slice';
import { getInstanceOfCare } from '../../../state/patient-thunk';
import { emptyPatientEncounter } from '../../models/patient-encounter';
import { PatientViewModel } from '../../models/patient-view-model';

interface expectedProps {
  currentPatient: PatientViewModel,
  openPatientModal: (isOpen: boolean) => void,
}

const Demographics = (props: expectedProps) => {
  const {currentPatient, openPatientModal} = props;

  const stateFields = {
    selectedPatientEncounter: useSelector((state: RootState) => state.patientContext.selectedEncounter),
    isIOCLoading: useSelector((state: RootState) => state.patientContext.isLoading.patientIOC),
    selectedPatient: useSelector((state: RootState) => state.patientContext.selectedPatient),
  }

  const {selectedPatientEncounter, isIOCLoading, selectedPatient} = stateFields;

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    async function fetchData() {
      if ((selectedPatientEncounter === emptyPatientEncounter) && !isIOCLoading) {
        await dispatch(getInitialEncounter())
      }
    }
    fetchData()
  }, [dispatch, selectedPatientEncounter, isIOCLoading])

  useEffect(() => {
    const patientId = selectedPatientEncounter?.patientId;
    const encounterId = selectedPatientEncounter?.patientEncounterId
    if (
      selectedPatientEncounter && (selectedPatientEncounter !== emptyPatientEncounter)
      && patientId && encounterId && (encounterId !== 0) && (selectedPatient?.patientEncounters?.length > 0)
      ) {
      dispatch(getInstanceOfCare({patientId: patientId, encounterId: encounterId}))
    }
    // eslint-disable-next-line
  }, [dispatch])

  const sectionHeader = (outputString: string) => (
    <Grid item xs={12}>
      <Typography variant="h3">
        {outputString}
      </Typography>
    </Grid>
  )

  const sectionContent = (outputString: string) => (
    <Grid item xs={12} style={{marginBottom: -13}}>
      <Typography variant="h3">
        {outputString}
      </Typography>
    </Grid>
  )

  const nameEditButtonHeader = (
    <>
      <Grid item xs={10}>
        <Typography variant="body2">
          Name
        </Typography>
      </Grid>
      <Box justifyContent="flex-end">
        <Grid item xs={2}>
          <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add New Account"
            renderNoAccess={() =>
            <IconButton disabled size="large">
              <EditIcon className="colorDisabled" />
            </IconButton>
          }>
            <IconButton color="primary" onClick={() => openPatientModal(true)} size="large">
              <EditIcon className="icon" />
            </IconButton>
          </AccessControl>
        </Grid>
      </Box>
      <Grid item xs={12} style={{ marginTop: -25 }}>
        <Typography variant="h3">
          {`${currentPatient?.contact?.title === '-1' || currentPatient?.contact?.title === null ? '' : currentPatient.contact.title }
          ${currentPatient?.contact?.firstName || ''} ${currentPatient?.contact?.middleName || ''} ${currentPatient?.contact?.lastName}`}
        </Typography>
      </Grid>
    </>
  )

  const convertBoolToYesNo = (value: boolean) => (
    value ? 'Yes' : 'No'
  );

  return (
    <div className="icon-colors">
      <Paper className="px-4 py-4" >
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
        >
          {nameEditButtonHeader}
          {sectionHeader('Contact Information')}
          <HeaderWithDetails header={"Email"} details={currentPatient?.contact?.email} width="full" />
          <HeaderWithDetails header={"Primary Phone"} details={currentPatient?.contact?.primPhoneNum} width="half"/>
          <HeaderWithDetails header={"Secondary Phone"} details={currentPatient?.contact?.secPhoneNum ? currentPatient?.contact?.secPhoneNum : 'N/A'} width="half"/>
          <HeaderWithDetails header={"Leave Voicemail"} details={convertBoolToYesNo(currentPatient?.contact?.leaveVMFl)} width="half"/>
          <HeaderWithDetails header={"Receive SMS Messages"} details={convertBoolToYesNo(currentPatient?.contact?.smsConsentFl)} width="half"/>
          {sectionHeader('Address')}
          {sectionContent(currentPatient?.contact?.primaryAddress?.streetAddress1)}
          {currentPatient?.contact?.primaryAddress?.streetAddress2 && sectionHeader(currentPatient?.contact?.primaryAddress?.streetAddress2)}
          {sectionHeader(`${currentPatient?.contact?.primaryAddress?.city}, ${currentPatient?.contact?.primaryAddress?.state?.stateCode} ${currentPatient?.contact?.primaryAddress?.zipCode}`)}
          {sectionHeader('Personal Information')}
          <HeaderWithDetails header={"Date of Birth"} details={currentPatient?.contact?.birthDate} width="half" />
          {currentPatient?.contact?.ssnLast4 &&
              <HeaderWithDetails header={"Social Security Number"} details={`●●●-●●-${currentPatient?.contact?.ssnLast4}`} width="half" />}
          {sectionHeader('Employment Information')}
          <HeaderWithDetails header={"Currently Employed"} details={convertBoolToYesNo(currentPatient?.contact?.contactIncome?.isEmployed)} width="full" />
          <HeaderWithDetails header={"Employer"} details={currentPatient?.contact?.contactIncome?.employer} width="third" />
          <HeaderWithDetails header={"Salary"} details={formatNumberToUSD(+currentPatient?.contact?.contactIncome?.yearlySalary)} width="third" />
          <HeaderWithDetails header={"Years at Employer"} details={currentPatient?.contact?.contactIncome?.yearsAtEmployer?.toString()} width="third" />
          {/* Powerlytics data below */}
          <Grid item xs={6} >
          <ProgressBar
              headerText="Verification score"
              completeness={currentPatient?.contact?.contactIncome?.isEmployed ?
                currentPatient?.contact?.contactIncome?.verificationScore || 0 : 0}
            />
          </Grid>
          <Grid item xs={6}>
            <ProgressBar
              headerText="Capacity to Pay"
              completeness={currentPatient?.contact?.contactIncome?.isEmployed ?
                currentPatient?.contact?.contactIncome?.capacityToPay || 0 : 0}
            />
          </Grid>
          <HeaderWithDetails
            header={"Disposable Income"}
            details={`${formatNumberToUSD(
              currentPatient?.contact?.contactIncome?.isEmployed ?
                currentPatient?.contact?.contactIncome?.disposableIncome || 0 : 0)}`}
            width="half"
          />
          <HeaderWithDetails
            header={"Retirement Income"}
            details={`${formatNumberToUSD(
              currentPatient?.contact?.contactIncome?.isEmployed ?
                currentPatient?.contact?.contactIncome?.retirementIncome || 0 : 0)}`}
            width="half"
          />
          <HeaderWithDetails
            header={"Business Income"}
            details={`${formatNumberToUSD(currentPatient?.contact?.contactIncome?.isEmployed ?
              currentPatient?.contact?.contactIncome?.businessIncome || 0 : 0)}`}
            width="half"
          />
          <HeaderWithDetails
            header={"Wealth Income"}
            details={`${formatNumberToUSD(currentPatient?.contact?.contactIncome?.isEmployed ?
              currentPatient?.contact?.contactIncome?.wealthIncome || 0 : 0)}`}
            width="half"
          />
          <HeaderWithDetails
            header={"Wages and Earnings"}
            details={`${formatNumberToUSD(currentPatient?.contact?.contactIncome?.isEmployed ?
              currentPatient?.contact?.contactIncome?.wagesAndEarnings || 0 : 0)}`}
            width="full"
          />
        </Grid>
      </ Paper>
    </div>
  );
}

export default Demographics;
