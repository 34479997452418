import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import { Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetFormulaForm } from '../../../../admin-configuration/state/admin-configuration-slice';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { CommentModal } from '../../../../shared/components/comment-modal';
import { commentsPage } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import CommentsValidation from './comments-validation';
import RemindersValidation from './reminder-validation';

interface CommentProps {
  page: string
}

function Comments(props: CommentProps) {
  const {page} = props
  const [showCommentModal, setShowCommentModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>()

  const selectors = {
    comments: useSelector(
      (state: RootState) =>
        state.patientContext.comments
    ),
    clientComments: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.comments
    ),
    reminders: useSelector(
      (state: RootState) =>
        state.patientContext.reminders
    ),
    isLoadingPatientNotes: useSelector(
      (state: RootState) =>
        state.patientContext.isLoading.comments
    ),
    isLoadingClientComments: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.isLoadingComments
    )
  }

  let isPatientNotesEmpty = false
  let isTheRemindersEmpty = false
  let isClientCommentsEmpty = false

  if(selectors.reminders.length===0  && selectors.comments.length===0) {
    isPatientNotesEmpty = true
  }

  if(selectors.clientComments.length===0) {
    isClientCommentsEmpty = true
  }

  if(selectors.reminders.length===0) {
    isTheRemindersEmpty = true
  }

  // Validate if the client comment or the patients notes are empty
  const isCommentsEmpty = ()=> {
    if((!isClientCommentsEmpty && page===commentsPage.clients) || (!isPatientNotesEmpty && page===commentsPage.patients)) {
        return false
      } else
        return true
  }

  const isRemindersEmpty = ()=> {
    if(!isTheRemindersEmpty && page===commentsPage.patients){
      return false
    } else
      return true
  }

  const isLoadingComments= () => {
    if((!selectors.isLoadingClientComments && page===commentsPage.clients)
      || (!selectors.isLoadingPatientNotes && page===commentsPage.patients)) {
        return false
      } else
        return true
  }

  function handleCommentModalCancel() {
    setShowCommentModal(false);
    dispatch(resetFormulaForm());
  }

  function handleCommentModalSubmit() {
    setShowCommentModal(false);
  }

  return (
    <div>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle1">Reminder</Typography>
        </Grid>
        <Grid item>
          <AccessControl rolePageName={page === commentsPage.patients ? RolePageNames.PatientRecords: RolePageNames.Clients} actionName="Add Notes"
                         renderNoAccess={() => <Button disabled paddingRight={0} type="text" icon={<AddIcon />}>Add</Button>}>
            <Button onClick={() => setShowCommentModal(true)} paddingRight={0} type="text" icon={<AddIcon />}>Add</Button>
          </AccessControl>
        </Grid>
      </Grid>
      <Divider className="mb-4" />
      {!isRemindersEmpty() ?
          <RemindersValidation page={page} />
          :
          <Typography variant="subtitle2">No Reminders</Typography>

      }
      <Divider className="mb-4" />
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="subtitle1">Comments</Typography>
        </Grid>
        <Grid item>
          <AccessControl rolePageName={page === commentsPage.patients ? RolePageNames.PatientRecords: RolePageNames.Clients} actionName="Add Notes"
            renderNoAccess={() => <Button disabled paddingRight={0} type="text" icon={<AddIcon />}>Add</Button>}>
            <Button onClick={() => setShowCommentModal(true)} paddingRight={0} type="text" icon={<AddIcon />}>Add</Button>
          </AccessControl>
        </Grid>
      </Grid>

      <Divider className="mb-4" />

      {!isCommentsEmpty() ?
      <CommentsValidation page={page} />
      :
        !isLoadingComments() ?
          <Typography variant="subtitle2">No Comments</Typography>
        :
          <LoadingOverlay whiteBackground />
      }

      {showCommentModal && (
        <CommentModal
          page={page}
          open={showCommentModal}
          handleCommentModalCancel={handleCommentModalCancel}
          handleCommentModalSubmit={handleCommentModalSubmit}
        />
      )}
    </div>
  );
}

export default Comments;
