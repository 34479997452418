import '../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, saveCallbackStatus, TextField, Toggle } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { insurance } from '../../../../shared/validation/schemas';
import { saveInsurance } from '../../../state/patient-thunk';
import { PatientInsurance } from '../../models/patient-insurance';

interface insuranceModalProps {
  open: boolean;
  handleModalClose: () => void;
  isEdit: boolean;
  selectedInsuranceIndex?: number;
}

export function InsuranceModal(props: insuranceModalProps) {
  const { open, handleModalClose, isEdit, selectedInsuranceIndex } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    selectedPatientId: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient.patientId
    }),
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    })
  }
  const { selectedPatientId, selectedEncounter } = stateFields;

  const selectedEncounterId = selectedEncounter?.patientEncounterId

  const selectedInsurance = (selectedInsuranceIndex !== undefined) && selectedEncounter?.patientInsurance
    && selectedEncounter?.patientInsurance.length > 0 && selectedEncounter?.patientInsurance[selectedInsuranceIndex]
    ? selectedEncounter.patientInsurance[selectedInsuranceIndex] : {} as PatientInsurance

  const mapToSaveModel = () => {
    const formValues = formRef.current.values;
    return {
      patientInsuranceId: isEdit ? selectedInsurance?.patientInsuranceId : 0,
      patientEncounterId: selectedEncounterId,
      insuranceCompanyName: formValues.provider,
      insurancePlanName: formValues.plan,
      isInNetwork: formValues.inNetwork,
      deductibleAmt: formValues.deductible,
      remDeductibleAmt: formValues.remainingDeductible,
      familyDeductibleAmt: formValues.familyDeductible,
      familyRemDeductibleAmt: formValues.remainingFamilyDeductible,
      coinsuranceAmt: formValues.coinsurance,
      coinsuranceType: formValues.percentOrDollars,
      isSubjectToCopay: formValues.subjectToCopay,
      copayAmt: formValues.copayAmt,
      maxDays: formValues.maxDays,
      deductibleIncInOOPMaxFl: formValues.deductibleIncluded,
      remainingOOPMaxAmt: formValues.remainingOopMax,
      familyOOPMaxAmt: formValues.familyOopMax,
      familyRemainingOOPMaxAmt: formValues.remainingFamilyOopMax,
      oopmaxAmt: formValues.oopMax,
      policyNumber: formValues.policyNumber,
      groupNumber: formValues.groupNumber,
    } as PatientInsurance;
  };

  async function handleSave() {
    const insuranceToSave = mapToSaveModel();
    await dispatch(saveInsurance({insurance: insuranceToSave, patientId: selectedPatientId, encounterId: selectedEncounterId}))
    handleModalClose();
  }

  const handleSaveCallback = () => {
    handleModalClose();
  };

  const initialValuesAdd = {
    provider: '',
    plan: '',
    inNetwork: true,
    deductible: '',
    remainingDeductible: '',
    familyDeductible: '',
    remainingFamilyDeductible: '',
    coinsurance: '',
    percentOrDollars: '',
    subjectToCopay: true,
    copayAmt: '',
    maxDays: '',
    deductibleIncluded: true,
    oopMax: '',
    remainingOopMax: '',
    familyOopMax: '',
    remainingFamilyOopMax: '',
    policyNumber: '',
    groupNumber: '',
  };

  const initialValuesEdit = {
    provider: selectedInsurance?.insuranceCompanyName || '',
    plan: selectedInsurance?.insurancePlanName || '',
    inNetwork: selectedInsurance?.isInNetwork || false,
    deductible: selectedInsurance?.deductibleAmt === 0 ? 0 : selectedInsurance?.deductibleAmt || '',
    remainingDeductible: selectedInsurance?.remDeductibleAmt === 0 ? 0 : selectedInsurance?.remDeductibleAmt || '',
    familyDeductible: selectedInsurance?.familyDeductibleAmt === 0 ? 0 : selectedInsurance?.familyDeductibleAmt || '',
    remainingFamilyDeductible: selectedInsurance?.familyRemDeductibleAmt === 0 ? 0 : selectedInsurance?.familyRemDeductibleAmt || '',
    coinsurance: selectedInsurance?.coinsuranceAmt === 0 ? 0 : selectedInsurance?.coinsuranceAmt || '',
    percentOrDollars: selectedInsurance?.coinsuranceType || '',
    subjectToCopay: selectedInsurance?.isSubjectToCopay || false,
    copayAmt: selectedInsurance?.copayAmt === 0 ? 0 :  selectedInsurance?.copayAmt || '',
    maxDays: selectedInsurance?.maxDays === 0 ? 0 : selectedInsurance?.maxDays || '',
    deductibleIncluded: selectedInsurance?.deductibleIncInOOPMaxFl || false,
    oopMax: selectedInsurance?.oopmaxAmt === 0 ? 0 : selectedInsurance?.oopmaxAmt || '',
    remainingOopMax: selectedInsurance?.maxDays === 0 ? 0 : selectedInsurance?.remainingOOPMaxAmt || '',
    familyOopMax: selectedInsurance?.remainingOOPMaxAmt === 0 ? 0 : selectedInsurance?.familyOOPMaxAmt || '',
    remainingFamilyOopMax: selectedInsurance?.familyRemainingOOPMaxAmt === 0 ? 0 : selectedInsurance?.familyRemainingOOPMaxAmt || '',
    policyNumber: selectedInsurance?.policyNumber || '',
    groupNumber: selectedInsurance?.groupNumber || '',
  };

  const validationSchema = Yup.object(
    insurance
  );

  function checkIfIsValid(value: any) {
    if (!value.coinsurance) {
      formRef.current.values.percentOrDollars = "";
    }
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
        // invalidate form if $/% toggle is chosen and coinsurance is blank
        // cannot do in Yup schema.ts because of a Cyclic dependency error between coinsurance and percentOrDollars fields
        if (value.coinsurance && !value.percentOrDollars) {
          setEnableSaveButton(false);
        }
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="subtitle">{isEdit ? "Edit Insurance" : "Insurance"}</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={isEdit ? initialValuesEdit : initialValuesAdd}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Verification Of Benefits</Typography>
                </Grid>


                <Grid xs={6} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["provider"] && formik.errors["provider"]
                    }
                    label="Provider"
                    placeholder="Enter Insurance Provider"
                    name="provider"
                    value={formik.values.provider}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["plan"] && formik.errors["plan"]
                    }
                    label="Plan"
                    name="plan"
                    placeholder="Enter Insurance Plan"
                    value={formik.values.plan}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                      error={
                        formik.touched["policyNumber"] && formik.errors["policyNumber"]
                      }
                      label="Policy Number"
                      placeholder="Enter Policy Number"
                      name="policyNumber"
                      value={formik.values.policyNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required={false}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                      error={
                        formik.touched["groupNumber"] && formik.errors["groupNumber"]
                      }
                      label="Group Number"
                      name="groupNumber"
                      placeholder="Enter Group Number"
                      value={formik.values.groupNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required={false}
                  />
                </Grid>
                <Grid xs={12} item container className="mb-6" alignItems="center">
                  <Typography variant="h4" className="mr-2">
                    In Network?
                  </Typography>
                  <Toggle
                    name="inNetwork"
                    formik={formik}
                    value={formik.values.inNetwork}
                  />
                </Grid>

                <Grid xs={6} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["deductible"] && formik.errors["deductible"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Deductible"
                    placeholder="Enter Deductible"
                    name="deductible"
                    value={formik.values.deductible}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["remainingDeductible"] && formik.errors["remainingDeductible"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Remaining Deductible"
                    name="remainingDeductible"
                    placeholder="Enter Remaining Deductible"
                    value={formik.values.remainingDeductible}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["familyDeductible"] && formik.errors["familyDeductible"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Family Deductible"
                    placeholder="Enter Family Deductible"
                    name="familyDeductible"
                    value={formik.values.familyDeductible}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["remainingFamilyDeductible"] && formik.errors["remainingFamilyDeductible"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Remaining Family Deductible"
                    name="remainingFamilyDeductible"
                    placeholder="Enter Remaining Family Deductible"
                    value={formik.values.remainingFamilyDeductible}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={4} item className="mb-2">
                  <TextField
                    error={
                      formik.touched["coinsurance"] && formik.errors["coinsurance"]
                    }
                    label="Coinsurance"
                    name="coinsurance"
                    value={formik.values.coinsurance}
                    onChange={(e: Event) => {
                      if(Utils.handleNumericValues(e))
                        formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid >
                <Grid xs={2} item>
                  <div className="buttongroup">
                    <input
                      id="percentOrDollars-2"
                      type="radio"
                      value="%"
                      name="percentOrDollars"
                      checked={formik.values.percentOrDollars === "%"}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="percentOrDollars-2">%</label>
                    <input
                      id="percentOrDollars-1"
                      type="radio"
                      value="$"
                      name="percentOrDollars"
                      checked={formik.values.percentOrDollars === "$"}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="percentOrDollars-1">$</label>
                  </div>
                </Grid>
                <Grid xs={6} item />
                <Grid xs={12} item >
                  <Typography variant="h4" style={{ marginBottom: -12 }} className="ml-2">
                    Subject to Copay
                  </Typography>
                </Grid>
                <Grid xs={12} item className="ml-2 mt-2" >
                  <Toggle
                    name="subjectToCopay"
                    value={formik.values.subjectToCopay}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["copayAmt"] && formik.errors["copayAmt"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Copay"
                    placeholder="Enter Copay"
                    name="copayAmt"
                    value={formik.values.copayAmt}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["maxDays"] && formik.errors["maxDays"]
                    }
                    label="Max Days"
                    name="maxDays"
                    placeholder="Enter MaxDays"
                    value={formik.values.maxDays}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} item container className="mt-4 mb-6" alignItems="center">
                  <Typography variant="h4" className="mr-2">
                    Deductible Included in Out of Pocket Max?
                  </Typography>
                  <Toggle
                    name="deductibleIncluded"
                    value={formik.values.deductibleIncluded}
                    formik={formik}
                  />
                </Grid>

                <Grid xs={6} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["oopMax"] && formik.errors["oopMax"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Out of Pocket Max"
                    placeholder="Enter OOP Max"
                    name="oopMax"
                    value={formik.values.oopMax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["remainingOopMax"] && formik.errors["remainingOopMax"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Remaining Out of Pocket Max"
                    placeholder="Enter Remaining OOP Max"
                    name="remainingOopMax"
                    value={formik.values.remainingOopMax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["familyOopMax"] && formik.errors["familyOopMax"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Family Out of Pocket Max"
                    placeholder="Enter Family OOP Max"
                    name="familyOopMax"
                    value={formik.values.familyOopMax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["remainingFamilyOopMax"] && formik.errors["remainingFamilyOopMax"]
                    }
                    startAdornment={<AttachMoneyIcon />}
                    label="Remaining Family Out of Pocket Max"
                    name="remainingFamilyOopMax"
                    placeholder="Enter Remaining Family OOP Max"
                    value={formik.values.remainingFamilyOopMax}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
    </Dialog>
  );
}
