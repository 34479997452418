import { Button } from '@finpay-development/shared-components';
import { Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../shared/state/root-reducer';

export function PaymentSubmittedView() {

  const [thankYouMessage, setThankYouMessage ] = useState(<></>);

  const stateFields = {
    isPublicModeLoggedIn: useSelector(
      (state: RootState) =>
        state.adminContext.adminUserContext.isPublicModeLoggedIn
    ),
    configId: useSelector(
      (state: RootState) =>
        state.guestContext.configId
    ),
    channelType: useSelector(
      (state: RootState) => state.guestContext.channelType
    ),
  };

  const { isPublicModeLoggedIn, configId, channelType} = stateFields;

  const thankYouMsgPayer = (<>Your payment has processed successfully.<br />The payor will receive an emailed receipt of the transaction.</>);
  const thankYouMsgMobile = (<>A receipt was sent to the email address you provided.</>);
  const thankYouMsgWebPayment = (<>Unable to pay in full?<br />Want to set up an interest free payment plan for future bills?</>);
  const learnMoreEl = (<>
    <Grid container direction="row" spacing={1}>
      <Grid
        item
        direction="column"
        xs={12}
        md={12}
        style={{
          paddingTop: "1em",
        }}
      >
        Click here to learn more
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1}>
      <Grid
        item
        direction="column"
        xs={12}
        md={12}
        style={{
          paddingTop: "1em",
        }}
      >
        <a
          href="https://www.finpay.com/learn-more"
          target="_blank"
          rel="noreferrer"
        >
          <Button type="primary">Learn More</Button>
        </a>
      </Grid>
    </Grid>
  </>);

  useEffect(() => {
    if (!configId) {
      window.location.href = window.location.href.substring(0, window.location.href.indexOf('/webpayment'));
    }
  }, [
    configId
  ]);

  useEffect(() => {
    switch(channelType) {
      case 'crm':
        return setThankYouMessage(thankYouMsgPayer);
      case 'mobile':
        return setThankYouMessage(thankYouMsgMobile);
      case 'vt':
        return setThankYouMessage(thankYouMsgPayer);
      case 'webpayments':
        return setThankYouMessage(thankYouMsgWebPayment);
      default:
        return setThankYouMessage(thankYouMsgWebPayment);
    }
    // eslint-disable-next-line
  }, [channelType]);

  return (
    <>
      {isPublicModeLoggedIn && (
        <>
          <Grid container direction="row">
            <Grid
              item
              direction="column"
              md={12}
              xs={12}
              style={{
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Paper
                style={{
                  maxWidth: "800px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px",
                  marginTop: "0.5em",
                  padding: "34px 35px 50px 35px"
                }}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid
                    item
                    direction="column"
                    xs={12}
                    md={12}
                    style={{
                    paddingTop: "0.8em",
                    paddingBottom: "0.2em",
                    fontWeight: "bold",
                    fontSize: "1.5em"
                    }}
                  >
                    {channelType === 'crm' || channelType === 'vt' ? 'Thank you!' : 'Thank you for your payment!' }
                  </Grid>
                </Grid>
                <Grid container direction="row" spacing={1}>
                    <Grid
                      item
                      direction="column"
                      xs={12}
                      md={12}
                      style={{
                        paddingTop: "1em",
                        fontSize: "1.2em",
                        lineHeight: "1.5em"
                      }}
                    >
                      { thankYouMessage }
                    </Grid>
                </Grid>
                { channelType === 'webpayments' && learnMoreEl }
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default PaymentSubmittedView;
