import { Checkbox, DialogActionButton, TextField } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FormControl, FormGroup, Grid, InputAdornment, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { clearCapacityToPayStatus } from "../../state/vob-patient-slice";
import { States } from '../../../admin-configuration/models/us-states';
import { clearStatus } from '../../../admin-configuration/state/admin-configuration-slice';
import { configGetStates, configGetScholarships } from '../../../admin-configuration/state/admin-configuration-thunk';
import { getCapacityToPay, getNationalPovertyThreshold } from "../../state/vob-patient-thunk";
import { showErrorStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { finAssistSchema } from '../../../shared/validation/schemas';
import { EstFinancialAssistance } from '../../models/estimator';
import { Scholarship } from '../../../admin-configuration/models/scholarship';
import { vobPatientPostBody } from '../../models/patient';
import { setPatient } from '../../state/vob-patient-slice';
import { saveVobPatient } from '../../state/vob-patient-thunk';
import { setEstimator } from '../../state/estimator-slice';
import { saveCallbackStatus } from '@finpay-development/shared-components';
import AddressValidator from "../../../shared/components/address-validator";
import { Utils } from '../../../shared/utils';

interface EstFinAssistModalProps {
  open: boolean;
  isEdit: boolean;
  handleFinAssistModalCancel: () => void;
  handleFinAssistModalSubmit: (isEdit: boolean) => void;
}

export function EstFinAssistModal(props: EstFinAssistModalProps) {
  const {
    open,
    isEdit,
    handleFinAssistModalCancel,
    handleFinAssistModalSubmit,
  } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
	const [incomeSources, setIncomeSources] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const paramId = -2;

  const state = {
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    capacityToPayState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext?.vobPatientContext.powerlyticsData
    ),
    isLoadingCapacityToPayState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext?.vobPatientContext.isPatientLoading.capacityToPay
    ),
    capacityToPayStatus: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.capacityToPayStatus
    ),
    usStates: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.usStates
    ),
    natlPovertyThreshold: useSelector(
      (state: RootState) => state.admissionsAdvisorContext?.vobPatientContext.nationalPovertyThreshold
    ),
    isLoadingNationalPovertyThreshold: useSelector(
      (state: RootState) => state.admissionsAdvisorContext?.vobPatientContext.isPatientLoading.nationalPovertyThreshold
    ),
    scholarshipsState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.scholarships
    )
  };
  const { estimatorState, vobPatientState, capacityToPayState, isLoadingCapacityToPayState, capacityToPayStatus, scholarshipsState, usStates, natlPovertyThreshold, isLoadingNationalPovertyThreshold } = state;

  useEffect(() => {
    dispatch(configGetStates(paramId));
    dispatch(configGetScholarships({ paramId: paramId, clientId: estimatorState.client?.clientId! }));
    setIncomeSources(estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingCapacityToPayState && (capacityToPayStatus === saveCallbackStatus.success)
        && !isLoadingNationalPovertyThreshold) {
      // we have capacity to pay data
      dispatch(clearCapacityToPayStatus());
      updateFinancialAssistanceState();
    }
    if (!isLoadingCapacityToPayState && (capacityToPayStatus === saveCallbackStatus.error)
        && !isLoadingNationalPovertyThreshold) {
      // we have a Powerlytics error
      dispatch(clearCapacityToPayStatus());
      dispatch(showErrorStatus("Powerlytics data insufficient to determine capacity to pay."));
    }
    //dispatch(showErrorStatus(errorMessage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCapacityToPayState, isLoadingNationalPovertyThreshold]);

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );

	const handleRevenueSourceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let index;
    let incomeSourceCopy = [...incomeSources];
		if (event.target.checked) {
			if (!incomeSourceCopy.includes(event.target.name)) {
				incomeSourceCopy.push(event.target.name);
			}
		} else {
			index = incomeSourceCopy.findIndex(
				(el) => el === event.target.name
			);
			if (index > -1) {
				incomeSourceCopy.splice(incomeSourceCopy.findIndex((el) => {return el === event.target.name;}), 1);
			}
		}
		setIncomeSources(incomeSourceCopy);
	}

  async function handleSave() {

    // get required zipPlu4 from
    let zipCode4: String = "";
    const addressValidator = new AddressValidator();
    zipCode4 = await addressValidator.getZipCodePlus4({
      streetAddress1: financialAssistFormik.values.streetAddress1,
      streetAddress2: financialAssistFormik.values.streetAddress2,
      city: financialAssistFormik.values.city,
      state: financialAssistFormik.values.stateCode,
      zipCode: financialAssistFormik.values.zip,
    });

    const currentYear = new Date().getFullYear();
    const numHouseHoldSize = financialAssistFormik.values.houseHoldSize === undefined ? -1 : financialAssistFormik.values.houseHoldSize;

    await
    Promise.all([
      dispatch(getCapacityToPay({
        paramId: paramId,
        income: financialAssistFormik.values.monthlyIncome! * 12,
        zipCode: financialAssistFormik.values.zip,
        zipCode4: zipCode4 !== "0"? zipCode4 : "1234"
      })),
      dispatch(getNationalPovertyThreshold({
        paramId: paramId,
        houseHoldSize: numHouseHoldSize,
        state: financialAssistFormik.values.stateCode,
        year: currentYear}))
    ]);
  }

  const getExpenseIncomeRatio = (income: number, expenses: number) => {
    // income expense ratio is (expense ÷ income) x 100
    let expIncThreshold: number = 0.0;
    if (income && expenses) {
      expIncThreshold = (expenses / income) * 100;
    }
    return expIncThreshold;
  };

  const updateFinancialAssistanceState = async () => {

    let financialAssistanceIsValid: boolean = true;
    let scholarshipName: string = "Financial Assistance";
    let estimateInvalidReason: string = "";
    let scholarshipPercentage: number = 0.00;
    let scholarshipApprovalThreshold: number = 0.00;
    let scholarshipIncomeExpenseThreshold: number = 0.00;
    let qualifiesForAssistance: boolean = false;

    if (!Array.isArray(scholarshipsState) || !scholarshipsState.length) {
      // scholarshipsState does not exist, is not an array, or is empty
      financialAssistanceIsValid = false;
      estimateInvalidReason += " No client scholarship data.";
    } else {
      let incomeExpenseRatio = 0.00;
      let qualifiesScholarshipId: number = -1;
      let scholarShipCopy: Scholarship[] = Utils.deepClone(scholarshipsState);
      // sort ascending so patient can qualify for highest amt
      scholarShipCopy.sort((a, b) => Number(a.scholarshipPercentage) - Number(b.scholarshipPercentage));
      // populate lowest for data if patient doesn't qualify
      scholarshipApprovalThreshold = scholarShipCopy[0].fplApprovalThreshold;
      scholarshipIncomeExpenseThreshold = scholarShipCopy[0].incomeThreshold;
      scholarShipCopy.forEach((scholarship) => {
        // scholarship qualification based on incomeRange OR incomeExpenseRatio
        let incomeRange: number = natlPovertyThreshold * (scholarship.fplApprovalThreshold! / 100);
        if ((financialAssistFormik?.values?.monthlyIncome! * 12) <= incomeRange) {
          // patient qualifies based on income/natl poverty rate
          qualifiesForAssistance = true;
          qualifiesScholarshipId = scholarship.clientScholarshipLevelsId!;
        }
        incomeExpenseRatio = (getExpenseIncomeRatio(financialAssistFormik?.values?.monthlyIncome!, financialAssistFormik?.values?.monthlyExpenses!));
        if (incomeExpenseRatio >= scholarship.incomeThreshold) {
          // patient qualifies based on income/expense ratio
          qualifiesForAssistance = true;
          qualifiesScholarshipId = scholarship.clientScholarshipLevelsId!;
        }
      });
      if (qualifiesForAssistance && qualifiesScholarshipId !== -1) {
        const selectedScholarship = scholarShipCopy?.find(
          (scholarship) => scholarship?.clientScholarshipLevelsId === qualifiesScholarshipId
        );
        scholarshipName = selectedScholarship?.clientScholarshipLevelDesc!;
        scholarshipPercentage = (selectedScholarship?.scholarshipPercentage! / 100);
        scholarshipApprovalThreshold = selectedScholarship?.fplApprovalThreshold!;
        scholarshipIncomeExpenseThreshold = selectedScholarship?.incomeThreshold!;
      }
    }

		let financialAssistanceBody: EstFinancialAssistance = {
			isValid: financialAssistanceIsValid,
      inValidReason: estimateInvalidReason,
			houseHoldSize: financialAssistFormik.values.houseHoldSize,
			patientIncomeExpenses: {
				income: financialAssistFormik.values.monthlyIncome,
				expenses: financialAssistFormik.values.monthlyExpenses,
				incomeSources: [...incomeSources]
			},
      scholarshipName: scholarshipName,
      natlPovertyThreshold: natlPovertyThreshold,
      clientFplApprovalThreshold: scholarshipApprovalThreshold,
      clientIncomeExpenseThreshold: scholarshipIncomeExpenseThreshold,
      capacityToPayScore: capacityToPayState?.capacity_to_pay? capacityToPayState?.capacity_to_pay: 0.00,
      incomeVerificationScore: capacityToPayState?.hh_iv_score? capacityToPayState?.hh_iv_score : 0.00,
      disposableIncome: capacityToPayState?.avg_disposable_income? parseFloat(capacityToPayState?.avg_disposable_income) : 0.00,
      retirementIncome: capacityToPayState?.avg_retirement_income? parseFloat(capacityToPayState?.avg_retirement_income) : 0.00,
      businessIncome: capacityToPayState?.avg_business_income? parseFloat(capacityToPayState?.avg_business_income) : 0.00,
      avgWealthIncome: capacityToPayState?.avg_wealth_income? parseFloat(capacityToPayState?.avg_wealth_income) : 0.00,
      scholarshipPercentage: scholarshipPercentage,
      qualifiesForAssistance: qualifiesForAssistance,
		}

    let estimatorStateCopy = Utils.deepClone(estimatorState);
    estimatorStateCopy.financialAssistance = financialAssistanceBody;
    dispatch(setEstimator(estimatorStateCopy));

    // update vob patient state with address
    let vobPatientStateCopy = Utils.deepClone(vobPatientState.patient);
    const stateToSave: States | undefined = usStates?.find(
      (usState) => usState?.stateCode === financialAssistFormik.values.stateCode
    );
    // check if advisorPatientBody section exists
    if (vobPatientStateCopy.advisorPatientBody) {
      vobPatientStateCopy.advisorPatientBody.primaryAddress.streetAddress1 = financialAssistFormik.values.streetAddress1;
      vobPatientStateCopy.advisorPatientBody.primaryAddress.streetAddress2 = financialAssistFormik.values.streetAddress2;
      vobPatientStateCopy.advisorPatientBody.primaryAddress.city = financialAssistFormik.values.city;
      vobPatientStateCopy.advisorPatientBody.primaryAddress.state = {stateCode: stateToSave?.stateCode, stateName: stateToSave?.stateName};
      vobPatientStateCopy.advisorPatientBody.primaryAddress.zipCode = financialAssistFormik.values.zip;
    } else {
        Object.assign(vobPatientStateCopy, {
          advisorPatientBody: {
            primaryAddress: {
              streetAddress1: financialAssistFormik.values.streetAddress1,
              streetAddress2: financialAssistFormik.values.streetAddress2,
              city: financialAssistFormik.values.city,
              state: {
                  stateCode: stateToSave?.stateCode,
                  stateName: stateToSave?.stateName,
              },
              zipCode: financialAssistFormik.values.zip,
            }
          },
        });
    }

    dispatch(setPatient(vobPatientStateCopy));
    const patientPayload: vobPatientPostBody = {
      firstName: vobPatientStateCopy.firstName,
      lastName: vobPatientStateCopy.lastName,
      birthDate: vobPatientStateCopy.birthDate,
      advisorPatientId: vobPatientStateCopy.advisorPatientId,
      fpPatientId: vobPatientStateCopy.fpPatientId,
      advisorPatientBody: vobPatientStateCopy.advisorPatientBody,
      clientId: vobPatientStateCopy.clientId
    };
    await dispatch(saveVobPatient(patientPayload));
    handleSaveCallback(true);
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleFinAssistModalSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleFinAssistModalCancel();
  }

  const financialAssistSchema = Yup.object(finAssistSchema);

  function checkIsFormValid(value: {}) {
    financialAssistSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const financialAssistFormik = useFormik({
    initialValues: {
      streetAddress1: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.streetAddress1!,
      streetAddress2: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.streetAddress2!,
      city: vobPatientState?.patient?.advisorPatientBody?.primaryAddress.city!,
      stateCode: vobPatientState?.patient?.advisorPatientBody?.primaryAddress?.state?.stateCode!,
      zip: vobPatientState?.patient?.advisorPatientBody?.primaryAddress.zipCode!,
      houseHoldSize: estimatorState?.financialAssistance?.houseHoldSize,
      monthlyIncome: estimatorState?.financialAssistance?.patientIncomeExpenses?.income,
      monthlyExpenses: estimatorState?.financialAssistance?.patientIncomeExpenses.expenses,
			wagesAndEarnings: estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.includes("wagesAndEarnings"),
			businessIncome: estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.includes("businessIncome"),
			investmentIncome: estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.includes("investmentIncome"),
			alimonyIncome: estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.includes("alimonyIncome"),
			retirementIncome: estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.includes("retirementIncome"),
    },
    validate: checkIsFormValid,
    validationSchema: financialAssistSchema,
    onSubmit: () => {},
  });

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Financial Assistance Eligibility</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item direction="column" md={12}>
            <TextField
              label="Street Address 1"
              type="text"
              value={
                financialAssistFormik.values.streetAddress1
                  ? financialAssistFormik.values.streetAddress1
                  : ""
              }
              name="streetAddress1"
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean (financialAssistFormik.touched["streetAddress1"] && financialAssistFormik.errors["streetAddress1"])
              }
            />
          </Grid>
          <Grid item direction="column" md={12}>
            <TextField
              label="Street Address 2"
              type="text"
              value={
                financialAssistFormik.values.streetAddress2
                  ? financialAssistFormik.values.streetAddress2
                  : ""
              }
              name="streetAddress2"
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean(financialAssistFormik.touched["streetAddress2"] && financialAssistFormik.errors["streetAddress2"])
              }
              required={false}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ borderBottom: "1px solid #cccccc" }}
        >
          <Grid item direction="column" md={4}>
            <TextField
              label="City"
              type="text"
              value={
                financialAssistFormik.values.city
                  ? financialAssistFormik.values.city
                  : ""
              }
              name="city"
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean (financialAssistFormik.touched["city"] && financialAssistFormik.errors["city"] )
              }
            />
          </Grid>
          <Grid item direction="column" md={4}>
            <TextField
              select
              label="State"
              name="stateCode"
              value={financialAssistFormik.values.stateCode}
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              className="state-field"
            >
              {usStates?.map((row: States) => (
                <MenuItem key={row.stateCode} value={row.stateCode}>
                  {row.stateCode}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item direction="column" md={4}>
            <TextField
              label="Zip Code"
              type="text"
              value={
                financialAssistFormik.values.zip
                  ? financialAssistFormik.values.zip
                  : ""
              }
              name="zip"
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean (financialAssistFormik.touched["zip"] && financialAssistFormik.errors["zip"])
              }
            />
          </Grid>
          <Grid item direction="column" md={4}>
            <TextField
              label="Household Size"
              type="number"
              value={
                financialAssistFormik.values.houseHoldSize
                  ? financialAssistFormik.values.houseHoldSize
                  : ""
              }
              name="houseHoldSize"
              onChange={financialAssistFormik.handleChange}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean (financialAssistFormik.touched["houseHoldSize"] && financialAssistFormik.errors["houseHoldSize"])
              }
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ marginTop: "1em" }}
        >
          <Grid item direction="column" md={12}>
            <Typography variant="h4">Patient Stated Income and Expenses</Typography>
          </Grid>
          <Grid item direction="column" md={6}>
            <TextField
              label="Monthly Income"
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              value={financialAssistFormik.values.monthlyIncome
								? financialAssistFormik.values.monthlyIncome
								: ""}
              name="monthlyIncome"
              //onChange={(e) => setValue(parseFloat(e.target.value).toFixed(1))}
              onChange={(e: Event) => {
                financialAssistFormik.handleChange(e);
              }}
              onBlur={financialAssistFormik.handleBlur}
              error={ Boolean(financialAssistFormik.touched["monthlyIncome"] && financialAssistFormik.errors["monthlyIncome"])}
            />

          </Grid>
          <Grid item direction="column" md={6}>
            <TextField
              label="Monthly Expenses"
              type="number"
              startAdornment={
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              }
              value={financialAssistFormik.values.monthlyExpenses
								? financialAssistFormik.values.monthlyExpenses
								: ""}
              name="monthlyExpenses"
              onChange={(e: Event) => {
                financialAssistFormik.handleChange(e);
              }}
              onBlur={financialAssistFormik.handleBlur}
              error={
                Boolean(financialAssistFormik.touched["monthlyExpenses"] && financialAssistFormik.errors["monthlyExpenses"])
              }
            />
				</Grid>
				<Grid item md={12}>
					<FormControl component="fieldset">
						<FormGroup>
							<Checkbox
								checked={financialAssistFormik.values.wagesAndEarnings}
								id="wagesAndEarnings"
								name="wagesAndEarnings"
								label="Wages and Earnings"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									financialAssistFormik.handleChange(e);
									handleRevenueSourceChange(e);
								}}
							/>
							<Checkbox
								checked={financialAssistFormik.values.businessIncome}
								id="businessIncome"
								name="businessIncome"
								label="Business Income"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									financialAssistFormik.handleChange(e);
									handleRevenueSourceChange(e);
								}}
							/>
							<Checkbox
								checked={financialAssistFormik.values.investmentIncome}
								id="investmentIncome"
								name="investmentIncome"
								label="Wealth/Investment Income"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									financialAssistFormik.handleChange(e);
									handleRevenueSourceChange(e);
								}}
							/>
						</FormGroup>
					</FormControl>
					<FormControl component="fieldset">
						<FormGroup>
							<Checkbox
								checked={financialAssistFormik.values.alimonyIncome}
								id="alimonyIncome"
								name="alimonyIncome"
								label="Alimony Income"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									financialAssistFormik.handleChange(e);
									handleRevenueSourceChange(e);
								}}
							/>
							<Checkbox
								checked={financialAssistFormik.values.retirementIncome}
								id="retirementIncome"
								name="retirementIncome"
								label="Retirement Income"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									financialAssistFormik.handleChange(e);
									handleRevenueSourceChange(e);
								}}
							/>
						</FormGroup>
					</FormControl>
				</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
