import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { Navigation } from "../model/navigation";
import { emptyUserProfile, UserProfile } from "../model/user-profile";
import { UserProfileStatus } from "../model/user-profile-status";
import { UserRolePage } from "../model/user-role-page";
import { authService } from "./authorization-service";
import { getStaticRoleNavigation, setStaticRolePageAction } from "../model/role-navigation";
import {convertToToken, Tokens} from "./SSO";
import {
  setConvertedFilterFormValues,
  setPreconvertedFilterFormValues,
} from '../../patient/state/patient-slice';
import _ from 'lodash';

export class UserService {

  async getUserProfileWrapper(params : {isSSO ?: boolean, tokens ?: Tokens, thunkAPI: any}){

    //Wrapper function that decouples the function that fetches the user profile object with the Redux side effect operation

    const {isSSO, tokens, thunkAPI} = params;

    const result = await this.loginUserGetProfile(isSSO, tokens);

    if(!_.isNil(result.userProfile?.userRole?.patientDashboardFilters?.preConvertedFilterFormValues)){
      thunkAPI.dispatch(
          setPreconvertedFilterFormValues(result.userProfile.userRole.patientDashboardFilters!.preConvertedFilterFormValues)
      )
    }
    if(!_.isNil(result.userProfile?.userRole?.patientDashboardFilters?.convertedFilterFormValues)){
      thunkAPI.dispatch(
          setConvertedFilterFormValues(result.userProfile.userRole.patientDashboardFilters!.convertedFilterFormValues)
      )
    }

    return result;
  }

  async loginUserGetProfile(isSSO : boolean = false, tokens ?: Tokens): Promise<UserProfileStatus> {

    const result: UserProfileStatus = new UserProfileStatus();

    const payload = {
      dataId: 0,
      url: "user/v2/userprofile"
    };

    const profile = await axiosReadHelper(payload)
    const userProfile: UserProfile = profile.entity;

    if (userProfile) {
       userProfile.SubNav = this.getSubNavigationForLoggedInUser(userProfile.userRole?.userRolePages);
      const staticRoleNavigationSubNav = getStaticRoleNavigation(userProfile.userRole?.roleName);
      if(staticRoleNavigationSubNav) {
        userProfile.SubNav =  staticRoleNavigationSubNav;
        setStaticRolePageAction(userProfile.userRole);
      }
      userProfile.defaultRoute =  userProfile.SubNav.length > 0 ? userProfile.SubNav[0].route : "";
      if(isSSO && tokens){
      userProfile.tokenState = {
        jwtAccessToken : tokens.access_token,
        jwtIdToken : tokens.id_token
       }
      }
      else{
        userProfile.tokenState = await authService.fetchSecurityToken();
      }
      result.userProfile = userProfile;

    } else {
      result.userProfileNotFound = true;
      result.userProfile = emptyUserProfile;
    }

    return result;
  }


  //Get user profile information from secure code
  async getTokensFromCode(code : string): Promise<Tokens> {

    const response = await convertToToken(code);

    //to run with custom redirect uri
    // const response = await convertToToken(code, {body : {redirect_uri: "http://localhost:3000"}});

    let tokens = new Tokens();
    if(response.entity) {
      tokens = response.entity;
    }
    else{
      tokens.error = response.errorMessage;
    }
    return tokens;

  }

  // Build Sub Navigation for Logged In User
  getSubNavigationForLoggedInUser(userRolePages: UserRolePage[]): Navigation[] {

    const userNavigation: Navigation[] = [];

    let nextKey: number = 1;
    const clientPage = "Client Portal";
    const patientPage = "Patient Dashboard";

    userRolePages.forEach(function(userRolePage: UserRolePage) {
      if (userRolePage.isEnabled ) {

        const sysPage = userRolePage.sysRolePage;

        if (sysPage && !(sysPage.rolePageName === clientPage ||   sysPage.rolePageName === patientPage )) {
          const subNavItem = new Navigation();
          subNavItem.key = nextKey++;
          subNavItem.displayValue = sysPage.rolePageName;
          subNavItem.route = sysPage.rolePageRoute;
          userNavigation.push(subNavItem);
        }
      }
    });
    return userNavigation;
  }
}

export const userService = new UserService();
