
export interface Comment {
    clientCommentsId: number,
    clientId: number,
    commentText?: string,
    commentDt: string,
    commentUserId?: number,
    isArchived?: boolean,
    archivedByUserId?: number
  }

  export const emptyComment: Comment = {
    clientCommentsId:0,
    clientId: 0,
    commentDt: "",
  }
