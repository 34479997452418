import { Button, TextField } from '@finpay-development/shared-components';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Dialog, DialogActions, DialogContent, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { UserInfo } from '../../admin/models/user-info';
import { UserInfoClient } from '../../admin/models/user-info-client';
import { saveUser } from '../../admin/state/users/admin-thunk';
import { RootState } from '../state/root-reducer';
import { AppDispatch } from '../state/store';
import { Utils } from '../utils';
import { commonFields } from '../validation/schemas';

interface ChangeContactModalProps {
  open: boolean;
  handleChangeContactCancel: () => void;
  handleChangeContactOk: () => void;
}

function ChangeContactModal(props: ChangeContactModalProps) {
  const {open,handleChangeContactCancel,handleChangeContactOk} = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();
  const selectors = {
    allUsers: useSelector((state: RootState) => {
      return state.adminContext.adminUserContext.userSearch.allUsers
    }),
    selectedClient: useSelector((state: RootState) => state.implementationContext.implementationSpecialistClient.client)
  }
  const {allUsers, selectedClient} = selectors

  const filteredUsers = allUsers?.filter((user: UserInfo) => (user.isActive && user.userRole.roleId === 3));

  function handleSave() {
    const formValues = formRef.current.values;
    const clonedUsers = Utils.deepClone(filteredUsers)
    const userToUpdate:UserInfo = Utils.deepClone(clonedUsers.find((user: UserInfo) => user.userId = formValues.userId))
    const clientToInsert = {
      clientId: selectedClient.clientId,
      clientName: selectedClient.clientName,
      allowedFacilities:[]
    } as UserInfoClient

    userToUpdate.userRole.roleId = 2
    userToUpdate.userRole.roleName = "Client Owner"
    userToUpdate.clients = []
    userToUpdate.clients.push(clientToInsert)
    dispatch(saveUser(userToUpdate));
    handleChangeContactOk()
    setIsDisabled(true)
  }

  function handleCancel(){
    handleChangeContactCancel()
    setIsDisabled(true)
  }

  const validationSchema = Yup.object({
    userId: commonFields.REQUIRED_DROPDOWN
  });

  const initialValues = {
    userId: "-1",
  };

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(true);
      });
  }

  return (
    <Dialog
      className="modal delete-modal"
      scroll="body"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid xs={12} item className="mb-4 mt-2">
            <WarningRoundedIcon color="error" />
          </Grid>
          <Grid xs={12} item className="mb-2">
            <Typography variant="subtitle1">Change Primary Contact</Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="body1">Changing this contact may require new contact to add their legal identification</Typography>
          </Grid>
        </Grid>
        <Grid container className="mt-5" xs={12} item alignItems="center">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={() => {}}
              validate={checkIfIsValid}
              innerRef={formRef}
            >
              {(formik) => (
                <Form data-testid="filter-form" noValidate>
                  <TextField
                    select
                    error=""
                    label="User"
                    name="userId"
                    value={formik.values.userId}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem selected value="-1" disabled> Select Authorized User</MenuItem>
                    {filteredUsers.map((user: any) => (
                      <MenuItem key={user.userId} value={user.userId}>
                        {user.fullName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Form>
              )}
            </Formik>
          </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} type="secondary">
          Cancel
        </Button>
        <Button disabled={isDisabled} onClick={handleSave} marginLeft={0}>
          Change Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeContactModal;
