import {AxiosResultStatus} from "../../shared/service/axios-result-status";
import axios, {AxiosError} from "axios";
import _ from "lodash";
import {configSettings} from "../../shared/configuration/config-settings";

// Identity Provider object
export class IdentityProvider {
    idp_identifier!: string;
    id_provider!: string;
}

export interface EndpointOptions {
    uri?: string;
    url?: string;
}
//Identity provider format
export interface IdProviderFormat{
    idp_identifier : string;
    id_provider ?: string;
}
// For use in specifying idp id types in saml url
interface CustomIDP{
    type ?: string;
    value  ?: string;
}
//Format for saml urls
export interface SamlUrl{
    cognitoDomain?: string;
    responseType?: string;
    IDP?: CustomIDP;
    clientID?: string;
    redirectURI?: string;
}
// For creating saml options to pass to generateSamlUrl({options})
export class SamlOptions{
    cognitoDomain?: string;
    responseType?: string;
    IDP?: CustomIDP;
    clientID?: string;
    redirectURI?: string;
}
export interface Tokens{
    id_token : string;
    access_token : string;
    refresh_token : string;
    expires_in : number;
    token_type : string;
    error ?: any;
}
export interface GuestToken{
    expires_on: string;
    issued_on: string;
    refresh_token: string;
    token: string;
}
export class Tokens{
    id_token !: string;
    access_token !: string;
    refresh_token !: string;
    expires_in !: number;
    token_type !: string;
    error ?: any;
}

/**
 * Example of how a json database could store identity provider information.
 */
export const sampleIdDB : IdProviderFormat[]= [
    {
        idp_identifier : "finpay.net",
    },
    {
        idp_identifier : "amedisys.com",
    },
]

class AuthPayloadForm {
    url ?: string;
    code ?: string;
    body?: any;
    header?: {[key: string]: string};
}

export const convertToToken = async function(code : string, options : AuthPayloadForm = {}): Promise<AxiosResultStatus> {
    const Instance = axios.create();
    const defaults = {
        url: `https://finpass-${configSettings.env}.auth.us-east-1.amazoncognito.com/oauth2/token`,
        body: {
            grant_type: "authorization_code",
            client_id: configSettings.aws_userPoolWebClientId,
            code: code,
            redirect_uri: `${configSettings.env !== "prod" ? configSettings.home_uri : "https://admin.finpay.net"}`
        },
        header: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }
    const values = _.merge({}, defaults, options);

    const saveResult = new AxiosResultStatus();
    const body = Object.keys(values.body)
        .map((key) => `${key}=${values.body[key]}`)
        .join("&");

    const header = values.header;
    await Instance
        .post(
            values.url, body, header &&  {headers: header}
        )
        .then(function (response) {
            saveResult.entity = response.data;
        })
        .catch(function (error : AxiosError) {
            saveResult.entity = error;
            saveResult.errorMessage = error.message;
            if(error.response!.status === 400){
                console.error('code use expired');
            }else {
                console.error(error);
            }
        });
    return saveResult;
}
