import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
interface ModalProps {
  title: string;
  isOpen: boolean;
  buttons: any; // One or more <Button /> components
  children: any; // The content between the title and the buttons
}

function FppModal(props: ModalProps) {
  const { title, isOpen, buttons, children: content } = props;

  return (
    <Dialog
      className="modal"
      scroll="body"
      open={isOpen}
      fullWidth={true}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{buttons}</DialogActions>
    </Dialog>
  );
}

export default FppModal;
