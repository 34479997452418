import { saveCallbackStatus } from "@finpay-development/shared-components";
import { createSlice } from "@reduxjs/toolkit";

import { configSettings } from "../../../shared/configuration/config-settings";
import { emptyLoadingState, LoadingState } from "../../models/loading-state";
import { UNKNOWN_SERVER_ERROR } from "../../../shared/state/saving-status";

import {
  emptyFacilityType,
  emptyImplementationFacility,
  FacilityType,
  ImplementationFacility,
} from "../../components/implementation-clients/details/models/implementation-facility";
import {
  blankFacilityDetails,
  FacilityDetails,
} from "../../models/facility-details";
import { blankFacilityForm, FacilityForm } from "../../models/facility-form";
import {
  addClientFacilityExternalAccount,
  addPayNowClientFacilityExternalAccount,
  getClientConnectAccounts,
  getClientFacilities,
  getClientFacility,
  getClientFacilityById,
  getEncryptFacilityPayload,
  getFacilityKPIs,
  saveClientFacility,
  saveClientFacilityVob,
  saveExternalAccount,
  saveFacilityOperatingCosts,
  activateFacilities
} from "./implementation-facility-thunk";

interface ImplementationSpecialistFacilityState {
  facility: FacilityType;
  facilities: ImplementationFacility[][];
  currentFacilityForm: Partial<FacilityForm>;
  currentFacilityDetails: FacilityDetails;
  apiResponsefacility: ImplementationFacility;
  uniqueAccountIds: string[];
  // common state for most/all slices
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
  isLoadingKPIs: boolean;
  isGettingEncryptedFacId: boolean;
  paynowURL: string;
  finpassExternalAccountIds: string[];
  payNowExternalAccountIds: string[];
  isAddClientConnectAccountSuccessful: boolean;
  isAddPayNowClientConnectAccountSuccessful: boolean;
  isClientLoading: LoadingState;
}

const initialState: ImplementationSpecialistFacilityState = {
  facilities: [],
  facility: emptyFacilityType,
  currentFacilityForm: blankFacilityForm,
  currentFacilityDetails: blankFacilityDetails,
  apiResponsefacility: emptyImplementationFacility,
  uniqueAccountIds: [],
  apiStatus: saveCallbackStatus.none,
  errorMessage: "",
  isLoading: false,
  isLoadingKPIs: false,
  isGettingEncryptedFacId: false,
  paynowURL: "",
  finpassExternalAccountIds: [],
  payNowExternalAccountIds: [],
  isAddClientConnectAccountSuccessful: false,
  isClientLoading: emptyLoadingState,
  isAddPayNowClientConnectAccountSuccessful: false,
};

const specialistFacilitySlice = createSlice({
  name: "specialistFacilityContext",
  initialState,
  reducers: {
    clearFacilityForm: (state) => {
      state.currentFacilityForm = blankFacilityForm;
    },
    clearStatus: (state) => {
      state.apiStatus = saveCallbackStatus.none;
      state.errorMessage = "";
    },
    clearFacilities: (state) => {
      state.facilities = [];
    },
    resetSpecialistFacilityContext() {
      return initialState;
    },
    resetAddClientAccountStatus: (state) => {
      state.isClientLoading.addClientConnectAccount = false;
      state.isAddClientConnectAccountSuccessful = false;
      state.isClientLoading.addPayNowClientConnectAccount = false;
      state.isAddPayNowClientConnectAccountSuccessful = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientFacilities.fulfilled, (state, action) => {
      state.facilities = action.payload.groupedFacilities;
      state.uniqueAccountIds = action.payload.uniqueAccountIds;
      state.isLoading = false;
    });
    builder.addCase(getClientFacilities.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientFacilities.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getFacilityKPIs.pending, (state, action) => {
      state.isLoadingKPIs = true;
    });
    builder.addCase(getFacilityKPIs.fulfilled, (state, action) => {
      state.isLoadingKPIs = false;
      state.facilities = action.payload.newGroupedFacilities;
    });
    builder.addCase(getFacilityKPIs.rejected, (state, action) => {
      state.isLoadingKPIs = false;
    });
    builder.addCase(getClientFacility.fulfilled, (state, action) => {
      state.currentFacilityForm = action.payload?.facilityForm;
      state.currentFacilityDetails = action.payload?.facilityDetails;
      state.apiResponsefacility = action.payload?.apiResponseFacility;
    });
    builder.addCase(getClientFacility.pending, (state) => {});
    builder.addCase(getClientFacility.rejected, (state) => {
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(getClientFacilityById.fulfilled, (state, action) => {
      state.facility = action.payload;
      state.isClientLoading.clientFacilityById = false;
    });
    builder.addCase(getClientFacilityById.pending, (state) => {
      state.isClientLoading.clientFacilityById = true;
    });
    builder.addCase(getClientFacilityById.rejected, (state) => {
      state.isClientLoading.clientFacilityById = false;
    });
    builder.addCase(saveClientFacility.fulfilled, (state, action) => {
      state.facilities = action.payload.groupedFacilities;
      state.uniqueAccountIds = action.payload.uniqueAccountIds;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveClientFacility.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveClientFacility.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveClientFacilityVob.fulfilled, (state, action) => {
      state.facilities = action.payload.groupedFacilities;
      state.uniqueAccountIds = action.payload.uniqueAccountIds;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveClientFacilityVob.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveClientFacilityVob.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });

    builder.addCase(saveExternalAccount.fulfilled, (state, action) => {
      state.facilities = action.payload.groupedFacilities;
      state.uniqueAccountIds = action.payload.uniqueAccountIds;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveExternalAccount.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveExternalAccount.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(getEncryptFacilityPayload.fulfilled, (state, action) => {
      const encrypFacilityId = action.payload;
      // eslint-disable-next-line no-useless-concat
      const newUrl =
        "https://" +
        `${configSettings.env}` +
        ".myfinpay.net/webpayment/launch/" +
        encrypFacilityId;

      state.isGettingEncryptedFacId = false;
      state.paynowURL = newUrl;
    });
    builder.addCase(getEncryptFacilityPayload.pending, (state) => {
      state.isGettingEncryptedFacId = true;
    });
    builder.addCase(getEncryptFacilityPayload.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.isGettingEncryptedFacId = false;
    });
    builder.addCase(
      addClientFacilityExternalAccount.fulfilled,
      (state, action) => {
        state.facilities = action.payload.groupedFacilities;
        state.uniqueAccountIds = action.payload.uniqueAccountIds;
        state.currentFacilityForm = action.payload.updatedForm;

        state.isClientLoading.addClientConnectAccount = false;
        state.isAddClientConnectAccountSuccessful = true;
      }
    );
    builder.addCase(addClientFacilityExternalAccount.pending, (state) => {
      state.isClientLoading.addClientConnectAccount = true;
    });
    builder.addCase(
      addClientFacilityExternalAccount.rejected,
      (state, action) => {
        state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        state.isClientLoading.addClientConnectAccount = false;
        state.isAddClientConnectAccountSuccessful = false;
      }
    );
    builder.addCase(
      addPayNowClientFacilityExternalAccount.fulfilled,
      (state, action) => {
        state.facilities = action.payload.groupedFacilities;
        state.uniqueAccountIds = action.payload.uniqueAccountIds;
        state.currentFacilityForm = action.payload.updatedForm;

        state.isClientLoading.addPayNowClientConnectAccount = false;
        state.isAddPayNowClientConnectAccountSuccessful = true;
      }
    );
    builder.addCase(addPayNowClientFacilityExternalAccount.pending, (state) => {
      state.isClientLoading.addPayNowClientConnectAccount = true;
    });
    builder.addCase(
      addPayNowClientFacilityExternalAccount.rejected,
      (state, action) => {
        state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
        state.isClientLoading.addPayNowClientConnectAccount = false;
        state.isAddPayNowClientConnectAccountSuccessful = false;
      }
    );
    builder.addCase(getClientConnectAccounts.fulfilled, (state, action) => {
      state.finpassExternalAccountIds =
        action.payload.finpassExternalAccountIds;
      state.payNowExternalAccountIds = action.payload.payNowExternalAccountIds;
      state.isClientLoading.clientConnectAccounts = false;
    });
    builder.addCase(getClientConnectAccounts.pending, (state) => {
      state.isClientLoading.clientConnectAccounts = true;
    });
    builder.addCase(getClientConnectAccounts.rejected, (state) => {
      state.isClientLoading.clientConnectAccounts = false;
    });

    builder.addCase(saveFacilityOperatingCosts.fulfilled, (state, action) => {
      state.currentFacilityForm = action.payload?.facilityForm;
      state.currentFacilityDetails = action.payload?.facilityDetails;
      state.apiResponsefacility = action.payload?.apiResponseFacility;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveFacilityOperatingCosts.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveFacilityOperatingCosts.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(activateFacilities.fulfilled, (state, action) => {
      state.facilities = action.payload.updatedFacilities;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(activateFacilities.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(activateFacilities.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });

  },
});

export const {
  clearStatus,
  clearFacilityForm,
  clearFacilities,
  resetSpecialistFacilityContext,
  resetAddClientAccountStatus,
} = specialistFacilitySlice.actions;
export default specialistFacilitySlice.reducer;
