import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { getBlankRule, getDefaultRuleEdit, Rule, RuleEditViewModel } from '../../../admin-configuration/models/rule';
import { rulesService } from '../../../shared/service/rules-service';
import { UNKNOWN_SERVER_ERROR } from '../../../shared/state/saving-status';
import {
  emptyImplementationFacility,
  ImplementationFacility,
} from '../../components/implementation-clients/details/models/implementation-facility';
import {
  applyCommonRuleSet,
  clearRules,
  deleteRule,
  getFacilityRules,
  getPaymentFrequency,
  reOrderRuleInUI,
  savePaymentFrequency,
  saveRule,
} from './rules-engine-thunk';

interface RulesEngineState {
  facilities: ImplementationFacility[];
  facilitiesWithRules: ImplementationFacility[];
  currentRuleEdit: RuleEditViewModel;
  currentRuleFull: Rule;
  allRules: Rule[];
  currentFacility: ImplementationFacility;
  isPaymentFreqComplete: boolean;
  isRiskRulesComplete: boolean;
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
}

const initialState = {
  facilities: [emptyImplementationFacility],
  facilitiesWithRules: [emptyImplementationFacility],
  currentRuleEdit: getDefaultRuleEdit(),
  currentRuleFull: getBlankRule(),
  allRules: [],
  isPaymentFreqComplete: false,
  isRiskRulesComplete: false,
  currentFacility: emptyImplementationFacility,
  apiStatus: saveCallbackStatus.none,
  errorMessage: "",
  isLoading: false,
} as RulesEngineState;

const rulesEngineSlice = createSlice({
  name: 'rulesEngineContext',
  initialState,
  reducers: {
    setCurrentFacility: (state, action: PayloadAction<ImplementationFacility>) => {
      state.currentFacility = action.payload;
    },
    resetRules: (state) => {
      state.currentRuleEdit = getDefaultRuleEdit();
      state.currentRuleFull = getBlankRule();
    },
    getRule: (state, action: PayloadAction<number>) => {
      const allFacilities = [...current(state.facilities)];
      const allRulesFromAllFacilities = allFacilities.flatMap((facility) => facility.rulesUngrouped);

      const foundRule = allRulesFromAllFacilities.find((rule) => rule?.ruleId === action.payload);
      if (foundRule) {
        state.currentRuleFull = rulesService.mappingHelper.mapToRuleForm(foundRule).fullRule;
        state.currentRuleEdit = rulesService.mappingHelper.mapToRuleForm(foundRule).ruleEditView;
      }
    },
    setIsPaymentFreqComplete: (state, action: PayloadAction<boolean>) => {
      state.isPaymentFreqComplete = action.payload;
    },
    setIsRiskRulesComplete: (state, action: PayloadAction<ImplementationFacility[]>) => {
      state.isRiskRulesComplete = (action.payload.some(facility => facility.rulesGrouped?.length > 0) || action.payload.some(facility => facility.isRuleSetupComplete))
    },
    clearStatus: (state) => {
      state.apiStatus = saveCallbackStatus.none;
      state.errorMessage = "";
    },
    resetRulesEngineContext() {
      return initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      reOrderRuleInUI.fulfilled, (state, action) => {
        state.isLoading = false;
        state.facilities = action.payload;
      }
    );
    builder.addCase(getPaymentFrequency.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getPaymentFrequency.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPaymentFrequency.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(savePaymentFrequency.fulfilled, (state, action) => {
      state.facilities = action.payload.allFacilities;
      state.isPaymentFreqComplete = action.payload.isPmtFreqCompleted;
      state.isLoading = false;
    });
    builder.addCase(savePaymentFrequency.rejected, (state, action) => {
      state.facilities = current(state.facilities);
    });

    builder.addCase(applyCommonRuleSet.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
    });
    builder.addCase(applyCommonRuleSet.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(applyCommonRuleSet.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getFacilityRules.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getFacilityRules.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFacilityRules.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(clearRules.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
    });
    builder.addCase(clearRules.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(clearRules.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(saveRule.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(saveRule.pending, (state, action) => {
      state.isLoading = true;
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(saveRule.rejected, (state, action) => {
      state.isLoading = false;
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });

    builder.addCase(deleteRule.fulfilled, (state, action) => {
      state.facilities = action.payload;
      state.isLoading = false;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(deleteRule.pending, (state, action) => {
      state.isLoading = true;
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(deleteRule.rejected, (state, action) => {
      state.isLoading = false;
      state.apiStatus = saveCallbackStatus.error;
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
    });
  }
});
export const {
  setCurrentFacility,
  getRule,
  resetRules,
  setIsPaymentFreqComplete,
  setIsRiskRulesComplete,
  clearStatus,
  resetRulesEngineContext
} = rulesEngineSlice.actions;
export default rulesEngineSlice.reducer;
