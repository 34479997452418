import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../../shared/state/root-reducer';
import {
    setIsVOBModalOpen,
} from '../../../state/patient-record-locking-slice';
import {AppDispatch} from '../../../../shared/state/store';
import React, {useState} from 'react';
import {Grid, IconButton} from '@mui/material';
import AccessControl from '../../../../security/components/access-control';
import {RolePageNames} from '../../../../security/model/role-page-names';
import EditIcon from '@mui/icons-material/Edit';
import {Vob} from '../../../../admissions-advisor/models/vob';
import {VOBSummary} from '@finpay-development/shared-components';
import {FinpassVOBModal} from './finpass-vob-modal';
import {FacilityPayers} from '../../../../admissions-advisor/models/vob';

interface VOBAccordionContentsProp {
    vobClassification?: any[] | null;
    facilityPayersState?: FacilityPayers[] | undefined;
}

export const VerificationOfBenefitsAccordionContents = (
    props: VOBAccordionContentsProp
) => {
    const [isEditModal, setIsEditModal] = useState(false);

    const {vobClassification, facilityPayersState} = props;

    const state = {
        vob: useSelector(
            (state: RootState) => state.admissionsAdvisorContext?.vobContext.vob
        ),
        selectedPatientEncounter: useSelector(
            (state: RootState) => state.patientContext.selectedEncounter
        ),
        patientModalOpenStatus: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.patientModalOpenStatus
        ),
        patientVOBState: useSelector(
            (state: RootState) => state.patientContext.selectedVOB
        ),
    };

    const {
        vob,
        selectedPatientEncounter,
        patientModalOpenStatus,
        patientVOBState,
    } = state;

    const dispatch = useDispatch<AppDispatch>();

    const handleVOBModalClose = () => {
        dispatch(setIsVOBModalOpen(false));
    };

    const handleEditModalClick = async (vob: Vob) => {
        dispatch(setIsVOBModalOpen(true));
        setIsEditModal(true);
    };

    return (
        <>
            <Grid container>
                {!selectedPatientEncounter?.isConverted && (
                    <Grid item container justifyContent="flex-end">
                        <AccessControl
                            rolePageName={RolePageNames.PatientRecords}
                            actionName="Add Instance of Care"
                            renderNoAccess={() => {
                                return (
                                    <IconButton disabled size="large">
                                        <EditIcon className="colorDisabled" />
                                    </IconButton>
                                );
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <IconButton
                                    onClick={() => handleEditModalClick(vob)}
                                    size="large"
                                >
                                    <EditIcon className="icon" />
                                </IconButton>
                            </div>
                        </AccessControl>
                    </Grid>
                )}
                <VOBSummary vob={patientVOBState} />
            </Grid>
            {vobClassification && patientModalOpenStatus.isVOBModalOpen && (
                <FinpassVOBModal
                    open={patientModalOpenStatus?.isVOBModalOpen}
                    isEdit={isEditModal}
                    classifications={vobClassification}
                    facilityPayersState={facilityPayersState}
                    handleVOBModalCancel={handleVOBModalClose}
                    handleVOBModalSubmit={handleVOBModalClose}
                />
            )}
        </>
    );
};
