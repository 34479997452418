import React from 'react';
import {
    Typography,
    TableContainer,
    Paper,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import { formatNumberToUSD } from '../calculators';
import { CrossoverSelectionEnum, EstimateSummary, Estimate } from '@finpay/estimation-types';

interface CrossoverViewProps {
    estimate?: Estimate | null;
    summary?: EstimateSummary;  
    isPriorCareSummary?: boolean;
}

export const CrossoverView: React.FC<CrossoverViewProps> = ({ estimate, summary, isPriorCareSummary = false }) => {
    const displaySummary = isPriorCareSummary ? summary : estimate?.summary;

    if (displaySummary) {
        return (
            <>
                <Typography variant="subtitle2" className="mt-2" style={{marginTop: "16px"}}>
                    {isPriorCareSummary ? "Adjusted PFR Details" : "PFR Details"}
                    {estimate?.isPlanYearCrossover && (
                        <Typography variant="h4">
                            {estimate?.crossoverSelection ===
                            CrossoverSelectionEnum.BEFORE
                                ? 'Before Crossover'
                                : 'With Crossover'}
                        </Typography>
                    )}
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Pay</TableCell>
                                <TableCell test-id="prior-care-summary-modal-copay" align="right">
                                    {formatNumberToUSD(displaySummary.patientTotalCoPays)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Deductible</TableCell>
                                <TableCell test-id="prior-care-summary-modal-deductible" align="right">
                                    {formatNumberToUSD(displaySummary.deductibleSpent)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Insurance</TableCell>
                                <TableCell test-id="prior-care-summary-modal-coinsurance" align="right">
                                    {formatNumberToUSD(displaySummary.patientTotalCoInsurance)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell></TableCell>
                                <TableCell align="right" sx={{ borderTop: "2px solid #999" }}>
                                    <strong test-id="prior-care-summary-modal-current-covered-pfr-bold">
                                        {formatNumberToUSD(displaySummary.coveredSvcsPfr)}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Out of Pocket Max Remaining</TableCell>
                                <TableCell test-id="prior-care-summary-modal-current-oopm-remaining" align="right">
                                    {displaySummary.includeOOPmax
                                        ? formatNumberToUSD(displaySummary.remainingOopMaxFromVob)
                                        : 'N/A'}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>PFR for Covered Services</TableCell>
                                <TableCell test-id="prior-care-summary-modal-current-covered-pfr" align="right">
                                    {formatNumberToUSD(displaySummary.netPFR)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Uncovered Services</TableCell>
                                <TableCell test-id="prior-care-summary-modal-current-uncovered-charges" align="right">
                                    {formatNumberToUSD(displaySummary.unCoveredCharges)}
                                </TableCell>
                            </TableRow>
                            {displaySummary.totalSurcharges > 0 && (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell test-id="prior-care-summary-modal-subtotal-pfr" align="right">
                                            {formatNumberToUSD(displaySummary.subtotalPFR)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>Admission Assessment</TableCell>
                                        <TableCell test-id="prior-care-summary-modal-surcharge" align="right">
                                            {formatNumberToUSD(displaySummary.totalSurcharges)}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                            <TableRow className="py-3">
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        Estimated Patient Financial Responsibility
                                    </Typography>
                                </TableCell>
                                <TableCell test-id="prior-care-summary-modal-current-pfr" align="right">
                                    <Typography variant="subtitle2">
                                        {formatNumberToUSD(displaySummary.totalPFR)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }
    return (
        <Typography variant="subtitle2" className="mt-4">
            No summary available
        </Typography>
    );
}