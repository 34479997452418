import '../../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, saveCallbackStatus, StartAndEndText, TextField } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { formatNumberToUSD } from '../../../../../shared/calculators';
import { CustomFormikProps } from '../../../../../shared/model/formik-props';
import { RootState } from '../../../../../shared/state/root-reducer';
import { balanceAdjustment } from '../../../../../shared/validation/schemas';
import { adjustmentReasons, TypeOfChange } from '../../../misc/adjustment-reasons';
import { AdjustmentReason } from '../../../models/adjustment-reason';
import { BalanceAdjustment } from '../../../models/balance-adjustment';

interface BalanceAdjustmentModalProps {
  open: boolean;
  balanceAdjustmentModalValues: BalanceAdjustment,
  handleModalClose: () => void;
  handleModalSave: (data: BalanceAdjustment) => void;
}

export const  BalanceAdjustmentModal = (props: BalanceAdjustmentModalProps) => {
  const { open, balanceAdjustmentModalValues, handleModalClose, handleModalSave } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [maxAdjustment, setMaxAdjustment] = useState(0);

  const formRef: any = useRef();

  const stateFields = {
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    })
  }
  const { selectedEncounter } = stateFields;
  const patientPaymentProgram = selectedEncounter.patientPaymentProgram?.[selectedEncounter.patientPaymentProgram.length - 1];
  const doPFRAdjustmentsExist = selectedEncounter?.doPFRAdjustmentsExist;
  const calculatedRemainingBalance = selectedEncounter?.calculatedRemainingBalance || 0;

  function handleSave() {
    const formik = formRef.current;
    if (+formik.values.selectChangeType === TypeOfChange["Patient Paid in Full"]) {
      formik.values.PFRAdjustmentAmt = patientPaymentProgram.patientPaymentSchedule.pfrBalance;
    }

    handleModalSave({
      openingPFR: patientPaymentProgram.patientPaymentSchedule.pfrAmt,
      pfrBalance: patientPaymentProgram.patientPaymentSchedule.pfrBalance,
      changeType: formik.values.selectChangeType,
      reasonForChangeId: formik.values.reasonForChange,
      adjustmentAmount: positiveOrNegativeAmount(formik),
    });
  }

  function positiveOrNegativeAmount(formik: CustomFormikProps) {
    const PFRAdjustmentAmt = formik.values.PFRAdjustmentAmt;
    const selectChangeType = formik.values.selectChangeType;
    const typeOfChange = TypeOfChange[selectChangeType];
    let positiveOrNegativeAmount: number;
    if (typeOfChange !== TypeOfChange[TypeOfChange["Increase PFR by"]]) {
      positiveOrNegativeAmount = -Math.abs(PFRAdjustmentAmt);
    } else {
      positiveOrNegativeAmount = PFRAdjustmentAmt;
    }
    return positiveOrNegativeAmount;
  }

  function handleSaveCallback(saveSuccessful: boolean) {}

  function handleCancelCallback() {
    handleModalClose();
  }

  function getReasonDropdownChoices(typeOfChange: TypeOfChange) {
    let filteredChoices = adjustmentReasons.filter((reason) => {
      return reason.adjustmentDescription.toLowerCase()
        .includes(TypeOfChange[typeOfChange].toLowerCase())
    });
    filteredChoices = filteredChoices.map((choice) => {
      return {
        ...choice,
        adjustmentDescription: choice.adjustmentDescription.includes("-") ? choice.adjustmentDescription.split(" - ")[1] : choice.adjustmentDescription
      }
    })
    return filteredChoices;
  }

  const initialValues = {
    selectChangeType: balanceAdjustmentModalValues.changeType,
    reasonForChange: balanceAdjustmentModalValues.reasonForChangeId,
    PFRAdjustmentAmt: typeof balanceAdjustmentModalValues.adjustmentAmount === "number" ? Math.abs(balanceAdjustmentModalValues.adjustmentAmount) : 0,
  };

  const validationSchema = Yup.object(balanceAdjustment(maxAdjustment));

  function checkIfIsValid(value: any) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const changeTypes = [TypeOfChange["Patient Paid in Full"], TypeOfChange["Patient overpaid by"], TypeOfChange["Decrease PFR by"], TypeOfChange["Increase PFR by"]];

  const getReasonsForChange = () => {
    let result: any[] = []; // 'any' otherwise TS complains that the 'find' (below) 'could' be undefined
    switch (formRef?.current?.values?.selectChangeType) {
      case TypeOfChange["Patient Paid in Full"]:
        result = getReasonDropdownChoices(TypeOfChange["Patient Paid in Full"]);
        break;
      case TypeOfChange["Patient overpaid by"]:
        result = getReasonDropdownChoices(TypeOfChange["Patient overpaid by"]);
        break;
      case TypeOfChange["Decrease PFR by"]:
        result = getReasonDropdownChoices(TypeOfChange["Decrease PFR by"]);
        break;
      case TypeOfChange["Increase PFR by"]:
        result = getReasonDropdownChoices(TypeOfChange["Increase PFR by"]);
        break;
    }
    return result;
  }

  // when we are decreasing the PFR, prevent the user from decreasing the PFR past 0. (negative PFRs are prohibited - negative balances are OK)
  useEffect(() => {
    if (formRef?.current?.values?.selectChangeType !== TypeOfChange["Increase PFR by"] && (selectedEncounter?.adjustedPFR || selectedEncounter?.pfrAmt)) {
      setMaxAdjustment(doPFRAdjustmentsExist ? selectedEncounter?.adjustedPFR || 0 : selectedEncounter.pfrAmt);
    }
  }, [formRef?.current?.values?.selectChangeType, selectedEncounter?.adjustedPFR, selectedEncounter?.pfrAmt, doPFRAdjustmentsExist])

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <>
          <span className="title">Patient Financial Responsibility Adjustment</span>
          <div className="pt-4" />
          <StartAndEndText startText="Estimated PFR" endText={selectedEncounter?.pfrAmt} />
          {doPFRAdjustmentsExist && (
            <StartAndEndText startText="Current PFR" endText={selectedEncounter?.adjustedPfrAmt || 0} />
          )}
          <StartAndEndText startText="Pending Remaining Balance" endText={formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule?.pfrPendingBalance)} />
          <StartAndEndText startText="Remaining Balance" endText={formatNumberToUSD(patientPaymentProgram?.patientPaymentSchedule?.pfrCapturedBalance)} />
        </>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} className="pt-3">
                  <Typography variant="subtitle2">
                    Adjust PFR
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["selectChangeType"] && formik.errors["selectChangeType"]
                    }
                    label="PFR Action"
                    name="selectChangeType"
                    value={formik.values.selectChangeType}
                    className="pb-4"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select Change Type</MenuItem>
                    {changeTypes.map((type: TypeOfChange, index) => (
                      <MenuItem key={index} value={type}>
                        {TypeOfChange[type]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["reasonForChange"] && formik.errors["reasonForChange"]
                    }
                    label="PFR Reason"
                    name="reasonForChange"
                    value={formik.values.reasonForChange}
                    disabled={+formik.values.selectChangeType === -1}
                    onChange={(e: Event) => {
                      formik.setFieldTouched('PFRAdjustmentAmt', true);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select Reason for Change</MenuItem>
                    {getReasonsForChange().map((reason: AdjustmentReason) => (
                      <MenuItem key={reason.adjustmentReasonId} value={reason.adjustmentReasonId}>
                        {reason.adjustmentDescription}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["PFRAdjustmentAmt"] && formik.errors["PFRAdjustmentAmt"]
                    }
                    type="number"
                    disabled={+formik.values.selectChangeType === TypeOfChange["Patient Paid in Full"]}
                    startAdornment={<AttachMoneyIcon />}
                    label="Amount"
                    placeholder="Enter PFR Adjustment Amount"
                    name="PFRAdjustmentAmt"
                    value={+formik.values.selectChangeType === TypeOfChange["Patient Paid in Full"] ? patientPaymentProgram.patientPaymentSchedule.pfrBalance.toString() : formik.values.PFRAdjustmentAmt}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Continue'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
