export interface State {
  stateId: number,
  stateCode: string,
  stateName: string,
}

export const emptyState = {
  stateId: 0,
  stateCode: '',
  stateName: ''
}
