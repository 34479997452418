import { Button, Checkbox, TextField, Toggle } from '@finpay-development/shared-components';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  MenuItem,
  TableContainer,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import {
  resetConvertedFilterFormValues,
  resetPreConvertedFilterFormValues,
  setConvertedClientCheckboxChecked,
  setConvertedFacilityCheckboxChecked,
  setConvertedFilterFormValues,
  setIsPreConverted,
  setPreConvertedClientCheckboxChecked,
  setPreConvertedFacilityCheckboxChecked,
  setPreconvertedFilterFormValues,
} from '../../state/patient-slice';
import { getConvertedPatients, getPreconvertedPatients } from '../../state/patient-thunk';
import { convertedPatientFilter, PatientClientFilter, preConvertedPatientFilter } from '../models/patient-filter';
import { PatientType } from '../models/patientType';

interface PatientFilterProps {
  preConvertedFilterCounts: {[key: string]: number},
  convertedFilterCounts: {[key: string]: number},
}

function PatientFilter(props: PatientFilterProps) {
  const { preConvertedFilterCounts, convertedFilterCounts } = props;

  const dispatch = useDispatch<AppDispatch>();
  const formRefPreconverted: any = useRef();
  const formRefConverted: any = useRef();

  const stateFields = {
    preConvertedClientCheckboxesChecked: useSelector((state: RootState) => {
      return state.patientContext.preConvertedClientCheckboxesChecked
    }),
    preConvertedFacilityCheckboxesChecked: useSelector((state: RootState) => {
      return state.patientContext.preConvertedFacilityCheckboxesChecked
    }),
    convertedClientCheckboxesChecked: useSelector((state: RootState) => {
      return state.patientContext.convertedClientCheckboxesChecked
    }),
    convertedFacilityCheckboxesChecked: useSelector((state: RootState) => {
      return state.patientContext.convertedFacilityCheckboxesChecked
    }),

    // client and facility data for generating client/facility checkbox filters
    preConvertedClientAndFacilityData: useSelector((state: RootState) => {
      return state.patientContext.preConvertedClientAndFacilityData;
    }),
    convertedClientAndFacilityData: useSelector((state: RootState) => {
      return state.patientContext.convertedClientAndFacilityData;
    }),

    preConvertedFilterFormValues: useSelector((state: RootState) => {
      return state.patientContext.preConvertedFilterFormValues
    }),

    convertedFormValues: useSelector((state: RootState) => {
      return state.patientContext.convertedFilterFormValues
    }),

    isPreConverted: useSelector((state: RootState) => {
      return state.patientContext.isPreConverted
    })
  }

  const {
    preConvertedFacilityCheckboxesChecked,
    preConvertedClientCheckboxesChecked,
    convertedClientCheckboxesChecked,
    convertedFacilityCheckboxesChecked,
    preConvertedFilterFormValues,
    preConvertedClientAndFacilityData,
    convertedClientAndFacilityData,
    convertedFormValues,
    isPreConverted,
  } = stateFields;

  const [ newCheckboxChecked, setNewCheckboxChecked] = useState(preConvertedFilterFormValues.newPatients);
  const [ followUpCheckboxChecked, setFollowUpCheckboxChecked ] = useState(false);
  const [ closedCheckboxChecked, setClosedCheckboxChecked ] = useState(false);
  const [ closedCheckboxChecked_converted, setClosedCheckboxChecked_converted ] = useState(false);
  const [ priorityCheckboxChecked, setPriorityCheckboxChecked ] = useState(false);
  const [ atRiskCheckboxChecked, setAtRiskCheckboxChecked ] = useState(false);

  const handleValidate = (formValues: preConvertedPatientFilter) => {
    // send form values to Store. Values to be read in filter results component for hide/show of each filter group
    dispatch(setPreconvertedFilterFormValues(formValues))
  }

  const handleValidateConverted = (formValues: convertedPatientFilter) => {
    dispatch(setConvertedFilterFormValues(formValues))
  }

  const handleReset = () => {
    dispatch(resetPreConvertedFilterFormValues());
    if (formRefPreconverted.current.values.dateRange !== "3") {
      handleDateRangeChanged("3");
    }
  }

  const handleConvertedReset = () => {
    dispatch(resetConvertedFilterFormValues());
    if (formRefConverted.current.values.dateRange_converted !== "3") {
      handleDateRangeChanged("3");
    }
  }

  const handleNewClick = () => {
    dispatch(setPreconvertedFilterFormValues({
      dateRange: preConvertedFilterFormValues.dateRange,
      recentlyViewed: preConvertedFilterFormValues.recentlyViewed,
      reminders: preConvertedFilterFormValues.reminders,
      // new
      newPatients: !newCheckboxChecked,
      newNone: !newCheckboxChecked,
      newPendingPaymentAtFacility: !newCheckboxChecked,
      // follow up
      followUp: preConvertedFilterFormValues.followUp,
      educationalCurriculum: preConvertedFilterFormValues.educationalCurriculum,
      paymentCurriculum: preConvertedFilterFormValues.paymentCurriculum,
      awaitingConsentDocs: preConvertedFilterFormValues.awaitingConsentDocs,
      awaitingHREApproval: preConvertedFilterFormValues.awaitingHREApproval,
      secondContactAttempt: preConvertedFilterFormValues.secondContactAttempt,
      thirdContactAttempt: preConvertedFilterFormValues.thirdContactAttempt,
      awaitingClient: preConvertedFilterFormValues.awaitingClient,
      patientChampion: preConvertedFilterFormValues.patientChampion,
      paymentMethodNeeded: preConvertedFilterFormValues.paymentMethodNeeded,
      noStatus: preConvertedFilterFormValues.noStatus,
      hreApproved: preConvertedFilterFormValues.hreApproved,
      hreApprovedPaymentConfirmationRequired: preConvertedFilterFormValues.hreApprovedPaymentConfirmationRequired,
      hreApprovedDirectBillFollowUp: preConvertedFilterFormValues.hreApprovedDirectBillFollowUp,
      mobilePaymentFailureFollowUp: preConvertedFilterFormValues.mobilePaymentFailureFollowUp,
      mobilePaymentRetryLimit: preConvertedFilterFormValues.mobilePaymentRetryLimit,
      followUpNeededPendingPaymentAtFacility: preConvertedFilterFormValues.followUpNeededPendingPaymentAtFacility,
      // closed
      closed: preConvertedFilterFormValues.closed,
      fileError: preConvertedFilterFormValues.fileError,
      patientFailedToEngage: preConvertedFilterFormValues.patientFailedToEngage,
      hreRejectedByPatient: preConvertedFilterFormValues.hreRejectedByPatient,
      hreRejectedByClient: preConvertedFilterFormValues.hreRejectedByClient,
      patientNeverAdmitted: preConvertedFilterFormValues.patientNeverAdmitted,
      isPreConverted: preConvertedFilterFormValues.isPreConverted,
      finpayFailedToEngage: preConvertedFilterFormValues.finpayFailedToEngage,
      duplicateRecord: preConvertedFilterFormValues.duplicateRecord,
      patientCoveredAt100Percent: preConvertedFilterFormValues.patientCoveredAt100Percent,
      hreApprovedForPaymentAtFacilityPaymentConfirmed: preConvertedFilterFormValues.hreApprovedForPaymentAtFacilityPaymentConfirmed,
      hreApprovedForFullScholarship: preConvertedFilterFormValues.hreApprovedForFullScholarship,
      mobilePaymentFailureClosed: preConvertedFilterFormValues.mobilePaymentFailureClosed,
      mobilePaymentRetryLimitClosed: preConvertedFilterFormValues.mobilePaymentRetryLimitClosed,
    }))
  }

  const handleFollowUpClick = () => {
    dispatch(setPreconvertedFilterFormValues({
      dateRange: preConvertedFilterFormValues.dateRange,
      recentlyViewed: preConvertedFilterFormValues.recentlyViewed,
      reminders: preConvertedFilterFormValues.reminders,
      // new
      newPatients: preConvertedFilterFormValues.newPatients,
      newNone: preConvertedFilterFormValues.newNone,
      newPendingPaymentAtFacility: preConvertedFilterFormValues.newPendingPaymentAtFacility,
      // follow up
      followUp: !followUpCheckboxChecked,
      educationalCurriculum: !followUpCheckboxChecked,
      paymentCurriculum: !followUpCheckboxChecked,
      awaitingConsentDocs: !followUpCheckboxChecked,
      awaitingHREApproval: !followUpCheckboxChecked,
      secondContactAttempt: !followUpCheckboxChecked,
      thirdContactAttempt: !followUpCheckboxChecked,
      awaitingClient: !followUpCheckboxChecked,
      patientChampion: !followUpCheckboxChecked,
      paymentMethodNeeded: !followUpCheckboxChecked,
      noStatus: !followUpCheckboxChecked,
      hreApproved: !followUpCheckboxChecked,
      hreApprovedPaymentConfirmationRequired: !followUpCheckboxChecked,
      hreApprovedDirectBillFollowUp: !followUpCheckboxChecked,
      mobilePaymentFailureFollowUp: !followUpCheckboxChecked,
      mobilePaymentRetryLimit: !followUpCheckboxChecked,
      followUpNeededPendingPaymentAtFacility: !followUpCheckboxChecked,
      // closed
      closed: preConvertedFilterFormValues.closed,
      fileError: preConvertedFilterFormValues.fileError,
      patientFailedToEngage: preConvertedFilterFormValues.patientFailedToEngage,
      hreRejectedByPatient: preConvertedFilterFormValues.hreRejectedByPatient,
      hreRejectedByClient: preConvertedFilterFormValues.hreRejectedByClient,
      patientNeverAdmitted: preConvertedFilterFormValues.patientNeverAdmitted,
      isPreConverted: preConvertedFilterFormValues.isPreConverted,
      finpayFailedToEngage: preConvertedFilterFormValues.finpayFailedToEngage,
      duplicateRecord: preConvertedFilterFormValues.duplicateRecord,
      patientCoveredAt100Percent: preConvertedFilterFormValues.patientCoveredAt100Percent,
      hreApprovedForPaymentAtFacilityPaymentConfirmed: preConvertedFilterFormValues.hreApprovedForPaymentAtFacilityPaymentConfirmed,
      hreApprovedForFullScholarship: preConvertedFilterFormValues.hreApprovedForFullScholarship,
      mobilePaymentFailureClosed: preConvertedFilterFormValues.mobilePaymentFailureClosed,
      mobilePaymentRetryLimitClosed: preConvertedFilterFormValues.mobilePaymentRetryLimitClosed,
    }))
  }

  const handleClosedClick = () => {
    dispatch(setPreconvertedFilterFormValues({
      dateRange: preConvertedFilterFormValues.dateRange,
      recentlyViewed: preConvertedFilterFormValues.recentlyViewed,
      reminders: preConvertedFilterFormValues.reminders,
      // new
      newPatients: preConvertedFilterFormValues.newPatients,
      newNone: preConvertedFilterFormValues.newNone,
      newPendingPaymentAtFacility: preConvertedFilterFormValues.newPendingPaymentAtFacility,
      // follow up
      followUp: preConvertedFilterFormValues.followUp,
      educationalCurriculum: preConvertedFilterFormValues.educationalCurriculum,
      paymentCurriculum: preConvertedFilterFormValues.paymentCurriculum,
      awaitingConsentDocs: preConvertedFilterFormValues.awaitingConsentDocs,
      awaitingHREApproval: preConvertedFilterFormValues.awaitingHREApproval,
      secondContactAttempt: preConvertedFilterFormValues.secondContactAttempt,
      thirdContactAttempt: preConvertedFilterFormValues.thirdContactAttempt,
      awaitingClient: preConvertedFilterFormValues.awaitingClient,
      patientChampion: preConvertedFilterFormValues.patientChampion,
      paymentMethodNeeded: preConvertedFilterFormValues.paymentMethodNeeded,
      noStatus: preConvertedFilterFormValues.noStatus,
      hreApproved: preConvertedFilterFormValues.hreApproved,
      hreApprovedPaymentConfirmationRequired: preConvertedFilterFormValues.hreApprovedPaymentConfirmationRequired,
      hreApprovedDirectBillFollowUp: preConvertedFilterFormValues.hreApprovedDirectBillFollowUp,
      mobilePaymentFailureFollowUp: preConvertedFilterFormValues.mobilePaymentFailureFollowUp,
      mobilePaymentRetryLimit: preConvertedFilterFormValues.mobilePaymentRetryLimit,
      followUpNeededPendingPaymentAtFacility: preConvertedFilterFormValues.followUpNeededPendingPaymentAtFacility,
      // closed
      closed: !closedCheckboxChecked,
      fileError: !closedCheckboxChecked,
      patientFailedToEngage: !closedCheckboxChecked,
      hreRejectedByPatient: !closedCheckboxChecked,
      hreRejectedByClient: !closedCheckboxChecked,
      patientNeverAdmitted: !closedCheckboxChecked,
      finpayFailedToEngage: !closedCheckboxChecked,
      duplicateRecord: !closedCheckboxChecked,
      patientCoveredAt100Percent: !closedCheckboxChecked,
      hreApprovedForPaymentAtFacilityPaymentConfirmed: !closedCheckboxChecked,
      hreApprovedForFullScholarship: !closedCheckboxChecked,
      isPreConverted: preConvertedFilterFormValues.isPreConverted,
      mobilePaymentFailureClosed: preConvertedFilterFormValues.mobilePaymentFailureClosed,
      mobilePaymentRetryLimitClosed: preConvertedFilterFormValues.mobilePaymentRetryLimitClosed,
    }))
  }

  const handlePriorityClick = () => {
    dispatch(setConvertedFilterFormValues({
      dateRange_converted: convertedFormValues.dateRange_converted,
      recentlyViewedPatientsConverted: convertedFormValues.recentlyViewedPatientsConverted,
      reminders: convertedFormValues.reminders,
      goodStanding: convertedFormValues.goodStanding,
      paid: convertedFormValues.paid,
      // priority
      priority: !priorityCheckboxChecked,
      awaitingAuthorizations: !priorityCheckboxChecked,
      awaitingACHVerification: !priorityCheckboxChecked,
      // at risk
      atRisk: convertedFormValues.atRisk,
      missedPayment: convertedFormValues.missedPayment,
      missingFundingSource: convertedFormValues.missingFundingSource,
      pastDueBalance: convertedFormValues.pastDueBalance,
      balanceAdjustmentRequired: convertedFormValues.balanceAdjustmentRequired,
      onHold: convertedFormValues.onHold,
      // closed
      fileError_converted: !closedCheckboxChecked,
      closed_converted: convertedFormValues.closed_converted,
      patientFailedToEngage_converted: convertedFormValues.patientFailedToEngage_converted,
      patientNeverAdmitted_converted: convertedFormValues.patientNeverAdmitted_converted,
      default: convertedFormValues.default,
      finpayFailedToEngage: convertedFormValues.finpayFailedToEngage,
      duplicateRecord: convertedFormValues.duplicateRecord,
      insurancePaidInFull: convertedFormValues.insurancePaidInFull,
      accountHolderPaidBalanceOwed: convertedFormValues.accountHolderPaidBalanceOwed,
      accountHolderPaidBalanceOwedDueARefund: convertedFormValues.accountHolderPaidBalanceOwedDueARefund,
      revokedPaymentPlan: convertedFormValues.revokedPaymentPlan,
      providersRequest: convertedFormValues.providersRequest,
      refinanced: convertedFormValues.refinanced,
      hreApprovedDirectBillPaymentMadeToFacility: convertedFormValues.hreApprovedDirectBillPaymentMadeToFacility,
      hreApprovedPaymentAtFacility: convertedFormValues.hreApprovedPaymentAtFacility,
      hreApprovedFullScholarship: convertedFormValues.hreApprovedFullScholarship,
      isPreConverted: convertedFormValues.isPreConverted,
    }))
  }

  const handleAtRiskClick = () => {
    dispatch(setConvertedFilterFormValues({
      dateRange_converted: convertedFormValues.dateRange_converted,
      recentlyViewedPatientsConverted: convertedFormValues.recentlyViewedPatientsConverted,
      reminders: convertedFormValues.reminders,
      goodStanding: convertedFormValues.goodStanding,
      paid: convertedFormValues.paid,
      // priority
      priority: convertedFormValues.priority,
      awaitingAuthorizations: convertedFormValues.awaitingAuthorizations,
      awaitingACHVerification: convertedFormValues.awaitingACHVerification,
      // at risk
      atRisk: !atRiskCheckboxChecked,
      missedPayment: !atRiskCheckboxChecked,
      pastDueBalance: !atRiskCheckboxChecked,
      balanceAdjustmentRequired: !atRiskCheckboxChecked,
      onHold: !atRiskCheckboxChecked,
      missingFundingSource: !atRiskCheckboxChecked,
      // closed
      fileError_converted: !closedCheckboxChecked,
      closed_converted: convertedFormValues.closed_converted,
      patientFailedToEngage_converted: convertedFormValues.patientFailedToEngage_converted,
      patientNeverAdmitted_converted: convertedFormValues.patientNeverAdmitted_converted,
      default: convertedFormValues.default,
      finpayFailedToEngage: convertedFormValues.finpayFailedToEngage,
      duplicateRecord: convertedFormValues.duplicateRecord,
      insurancePaidInFull: convertedFormValues.insurancePaidInFull,
      accountHolderPaidBalanceOwed: convertedFormValues.accountHolderPaidBalanceOwed,
      accountHolderPaidBalanceOwedDueARefund: convertedFormValues.accountHolderPaidBalanceOwedDueARefund,
      revokedPaymentPlan: convertedFormValues.revokedPaymentPlan,
      providersRequest: convertedFormValues.providersRequest,
      refinanced: convertedFormValues.refinanced,
      hreApprovedDirectBillPaymentMadeToFacility: convertedFormValues.hreApprovedDirectBillPaymentMadeToFacility,
      hreApprovedPaymentAtFacility: convertedFormValues.hreApprovedPaymentAtFacility,
      hreApprovedFullScholarship: convertedFormValues.hreApprovedFullScholarship,
      isPreConverted: convertedFormValues.isPreConverted,
    }))
  }

  const handleClosedClick_converted = () => {
    dispatch(setConvertedFilterFormValues({
      dateRange_converted: convertedFormValues.dateRange_converted,
      recentlyViewedPatientsConverted: convertedFormValues.recentlyViewedPatientsConverted,
      reminders: convertedFormValues.reminders,
      goodStanding: convertedFormValues.goodStanding,
      paid: convertedFormValues.paid,
      // priority
      priority: convertedFormValues.priority,
      awaitingAuthorizations: convertedFormValues.awaitingAuthorizations,
      awaitingACHVerification: convertedFormValues.awaitingACHVerification,
      // at risk
      atRisk: convertedFormValues.atRisk,
      missedPayment: convertedFormValues.missedPayment,
      pastDueBalance: convertedFormValues.pastDueBalance,
      balanceAdjustmentRequired: convertedFormValues.balanceAdjustmentRequired,
      onHold: convertedFormValues.onHold,
      missingFundingSource: convertedFormValues.missingFundingSource,
      // closed
      fileError_converted: !closedCheckboxChecked,
      closed_converted: !closedCheckboxChecked_converted,
      patientFailedToEngage_converted: !closedCheckboxChecked_converted,
      patientNeverAdmitted_converted: !closedCheckboxChecked_converted,
      default: !closedCheckboxChecked_converted,
      finpayFailedToEngage: !closedCheckboxChecked_converted,
      duplicateRecord: !closedCheckboxChecked_converted,
      insurancePaidInFull: !closedCheckboxChecked_converted,
      accountHolderPaidBalanceOwed: !closedCheckboxChecked_converted,
      accountHolderPaidBalanceOwedDueARefund: !closedCheckboxChecked_converted,
      revokedPaymentPlan: !closedCheckboxChecked_converted,
      providersRequest: !closedCheckboxChecked_converted,
      refinanced: !closedCheckboxChecked_converted,
      hreApprovedDirectBillPaymentMadeToFacility: !closedCheckboxChecked_converted,
      hreApprovedPaymentAtFacility: !closedCheckboxChecked_converted,
      hreApprovedFullScholarship: !closedCheckboxChecked_converted,
      isPreConverted: convertedFormValues.isPreConverted,
    }))
  }

  const initialValuesPreConverted: preConvertedPatientFilter = {
    dateRange: preConvertedFilterFormValues.dateRange,
    recentlyViewed: preConvertedFilterFormValues.recentlyViewed,
    reminders: preConvertedFilterFormValues.reminders,
    // new
    newPatients: preConvertedFilterFormValues.newPatients,
    newNone: preConvertedFilterFormValues.newNone,
    newPendingPaymentAtFacility: preConvertedFilterFormValues.newPendingPaymentAtFacility,
    // follow up
    followUp: preConvertedFilterFormValues.followUp,
    educationalCurriculum: preConvertedFilterFormValues.educationalCurriculum,
    paymentCurriculum: preConvertedFilterFormValues.paymentCurriculum,
    awaitingConsentDocs: preConvertedFilterFormValues.awaitingConsentDocs,
    awaitingHREApproval: preConvertedFilterFormValues.awaitingHREApproval,
    secondContactAttempt: preConvertedFilterFormValues.secondContactAttempt,
    thirdContactAttempt: preConvertedFilterFormValues.thirdContactAttempt,
    awaitingClient: preConvertedFilterFormValues.awaitingClient,
    patientChampion: preConvertedFilterFormValues.patientChampion,
    paymentMethodNeeded: preConvertedFilterFormValues.paymentMethodNeeded,
    noStatus: preConvertedFilterFormValues.noStatus,
    hreApproved: preConvertedFilterFormValues.hreApproved,
    hreApprovedPaymentConfirmationRequired: preConvertedFilterFormValues.hreApprovedPaymentConfirmationRequired,
    hreApprovedDirectBillFollowUp: preConvertedFilterFormValues.hreApprovedDirectBillFollowUp,
    mobilePaymentFailureFollowUp: preConvertedFilterFormValues.mobilePaymentFailureFollowUp,
    mobilePaymentRetryLimit: preConvertedFilterFormValues.mobilePaymentRetryLimit,
    followUpNeededPendingPaymentAtFacility: preConvertedFilterFormValues.followUpNeededPendingPaymentAtFacility,
    // closed
    closed: preConvertedFilterFormValues.closed,
    fileError: preConvertedFilterFormValues.fileError,
    patientFailedToEngage: preConvertedFilterFormValues.patientFailedToEngage,
    hreRejectedByPatient: preConvertedFilterFormValues.hreRejectedByPatient,
    hreRejectedByClient: preConvertedFilterFormValues.hreRejectedByClient,
    patientNeverAdmitted: preConvertedFilterFormValues.patientNeverAdmitted,
    isPreConverted: preConvertedFilterFormValues.isPreConverted,
    finpayFailedToEngage: preConvertedFilterFormValues.finpayFailedToEngage,
    duplicateRecord: preConvertedFilterFormValues.duplicateRecord,
    patientCoveredAt100Percent: preConvertedFilterFormValues.patientCoveredAt100Percent,
    hreApprovedForPaymentAtFacilityPaymentConfirmed: preConvertedFilterFormValues.hreApprovedForPaymentAtFacilityPaymentConfirmed,
    hreApprovedForFullScholarship: preConvertedFilterFormValues.hreApprovedForFullScholarship,
    mobilePaymentFailureClosed: preConvertedFilterFormValues.mobilePaymentFailureClosed,
    mobilePaymentRetryLimitClosed: preConvertedFilterFormValues.mobilePaymentRetryLimitClosed,

  }

  const initialValuesConverted: convertedPatientFilter = {
    dateRange_converted: convertedFormValues.dateRange_converted,
    recentlyViewedPatientsConverted: convertedFormValues.recentlyViewedPatientsConverted,
    reminders: convertedFormValues.reminders,
    goodStanding: convertedFormValues.goodStanding,
    paid: convertedFormValues.paid,
    // priority
    priority: convertedFormValues.priority,
    awaitingAuthorizations: convertedFormValues.awaitingAuthorizations,
    awaitingACHVerification: convertedFormValues.awaitingACHVerification,
    // at risk
    atRisk: convertedFormValues.atRisk,
    missedPayment: convertedFormValues.missedPayment,
    pastDueBalance: convertedFormValues.pastDueBalance,
    balanceAdjustmentRequired: convertedFormValues.balanceAdjustmentRequired,
    onHold: convertedFormValues.onHold,
    missingFundingSource: convertedFormValues.missingFundingSource,
    // closed
    fileError_converted: convertedFormValues.fileError_converted,
    closed_converted: convertedFormValues.closed_converted,
    patientFailedToEngage_converted: convertedFormValues.patientFailedToEngage_converted,
    patientNeverAdmitted_converted: convertedFormValues.patientNeverAdmitted_converted,
    default: convertedFormValues.default,
    isPreConverted: convertedFormValues.isPreConverted,
    finpayFailedToEngage: convertedFormValues.finpayFailedToEngage,
    duplicateRecord: convertedFormValues.duplicateRecord,
    insurancePaidInFull: convertedFormValues.insurancePaidInFull,
    accountHolderPaidBalanceOwed: convertedFormValues.accountHolderPaidBalanceOwed,
    accountHolderPaidBalanceOwedDueARefund: convertedFormValues.accountHolderPaidBalanceOwedDueARefund,
    revokedPaymentPlan: convertedFormValues.revokedPaymentPlan,
    providersRequest: convertedFormValues.providersRequest,
    refinanced: convertedFormValues.refinanced,
    hreApprovedDirectBillPaymentMadeToFacility: convertedFormValues.hreApprovedDirectBillPaymentMadeToFacility,
    hreApprovedPaymentAtFacility: convertedFormValues.hreApprovedPaymentAtFacility,
    hreApprovedFullScholarship: convertedFormValues.hreApprovedFullScholarship
  }

  const handleDateRangeChanged = (range: string) => {
    if (isPreConverted) {
      (async()=> {
        await dispatch(getPreconvertedPatients(range));
        // ensures filter results take into account any checked clients/facility filters
        // dispatch(setPreConvertedFacilityCheckboxChecked(-1));
      })()
    } else {
      (async() => {
        await dispatch(getConvertedPatients(range));
        // ensures filter results take into account any checked clients/facility filters
        // dispatch(setConvertedFacilityCheckboxChecked(-1));
      })()
    }
  }

  const clientFacilityCheckboxMapper = (
    clientFilterData: PatientClientFilter[],
    clientCheckboxesChecked: Number[],
    facilityCheckboxesChecked: Number[],
    patientType: PatientType) => {
    return (
    <>
      <TableContainer style={{maxHeight: 175}}>
        {clientFilterData?.map((client: any, index: number) => (
          <React.Fragment key={index}>
          {client.facilities?.length > 0 &&
            <div key={client.clientId}>
            <Checkbox
              checked={clientCheckboxesChecked.includes(client.clientId)}
              id={`${client.clientName}-${client.clientId}`}
              label={client.clientName}
              name={client.clientName}
              onChange={(e: Event) => {
                const checkboxElement = e.target as HTMLInputElement;
                const clientId = checkboxElement.id.split("-")[2];
                if (patientType === PatientType.preconverted) {
                  dispatch(setPreConvertedClientCheckboxChecked({
                    clientId: +clientId,
                    isChecked: checkboxElement.checked
                  }));
                } else {
                  dispatch(setConvertedClientCheckboxChecked({
                    clientId: +clientId,
                    isChecked: checkboxElement.checked
                  }));
                }

                if (patientType === PatientType.preconverted) {
                  dispatch(setPreConvertedFacilityCheckboxChecked(-1)); // -1 to nudge facility checkboxes to do filtering when this client checkbox is checked
                } else {
                  dispatch(setConvertedFacilityCheckboxChecked(-1));
                }

              }}
            />
            <div className="ml-6">
              {client.facilities?.map((facility: any, index2: number) => (
                <div key={index2}>
                  <Checkbox
                    checked={facilityCheckboxesChecked.includes(facility.clientFacilityId)}
                    id={`${facility.clientFacilityName}-${facility.clientFacilityId}`}
                    name={facility.clientFacilityName}
                    label={facility.clientFacilityName}
                    onChange={(e: Event) => {
                      const facilityId = (e.target as HTMLInputElement).id.split("-")[2];
                      if (patientType === PatientType.preconverted) {
                        dispatch(setPreConvertedFacilityCheckboxChecked(+facilityId));
                      } else {
                        dispatch(setConvertedFacilityCheckboxChecked(+facilityId));
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          }
          </ React.Fragment>
        ))}
      </TableContainer>
    </>
  )}

  const clientsAccordion = (patientType: PatientType) => {
    return (
    <Accordion defaultExpanded={true} elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="accordion-entity"
      >
        <Typography variant="subtitle1" className="mb-6">
          Clients
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {clientFacilityCheckboxMapper(
          patientType === PatientType.preconverted ? preConvertedClientAndFacilityData : convertedClientAndFacilityData,
          patientType === PatientType.preconverted ? preConvertedClientCheckboxesChecked : convertedClientCheckboxesChecked,
          patientType === PatientType.preconverted ? preConvertedFacilityCheckboxesChecked : convertedFacilityCheckboxesChecked,
          patientType
          )}
      </AccordionDetails>
    </Accordion>
  )}

  const preconvertedFilters = ( // preconverted filters. todo: add converted filters and have a ternary or similar.
    <div className="client-filters">
      {clientsAccordion(PatientType.preconverted)}
      <Formik
        innerRef={formRefPreconverted}
        enableReinitialize={true}
        initialValues={initialValuesPreConverted}
        validate={handleValidate}
        onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
      >
        {(formik) => (
          <Form>
            <Divider className="mb-4" />
            <Typography variant="body1" className="mb-1">Account Status</Typography>
            <Toggle
              leftText="Preconverted"
              rightText="&nbsp;&nbsp;&nbsp;&nbsp;Converted&nbsp;&nbsp;&nbsp;&nbsp;"
              name="isPreConverted"
              value={isPreConverted}
              formik={formik}
              onChange={() => {
                dispatch(setIsPreConverted(!isPreConverted));
              }}
            />
            <Typography variant="subtitle1" className="mb-6 mt-4">
              Filters
            </Typography>
            <Box mb={4}>
              <TextField
                  select={true}
                  label="Date Range"
                  name="dateRange"
                  value={formik.values.dateRange}
                  onChange={(e: Event) => {
                    formik.handleChange(e);
                    handleDateRangeChanged((e.target as HTMLInputElement).value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="3">Current + 3 Months</MenuItem>
                  <MenuItem value="6">Current + 6 Months</MenuItem>
                  <MenuItem value="12">Current + 12 Months</MenuItem>
                  <MenuItem value="0">All</MenuItem>
              </TextField>
            </Box>
            <Checkbox
              checked={formik.values.recentlyViewed}
              id="recentlyViewed"
              label={`Recently Viewed (${preConvertedFilterCounts.recentlyViewedPatients})`}
              name="recentlyViewed"
              onChange={formik.handleChange}
            />
            <Checkbox
              checked={formik.values.reminders}
              id="reminders"
              label={`Reminders (${preConvertedFilterCounts.reminders})`}
              name="reminders"
              onChange={formik.handleChange}
            />

            <Divider className="mt-4"></Divider>
            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  id="accordion-entity"
              >
                <Checkbox
                    checked={formik.values.newPatients}
                    id="newPatients"
                    label={`New (${preConvertedFilterCounts.newPatients})`}
                    name="newPatients"
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setNewCheckboxChecked(!newCheckboxChecked);
                      handleNewClick();
                    }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                      checked={formik.values.newNone}
                      id="newNone"
                      name="newNone"
                      label={`None (${preConvertedFilterCounts.newNone})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.newPendingPaymentAtFacility}
                      id="newPendingPaymentAtFacility"
                      name="newPendingPaymentAtFacility"
                      label={`Pending Payment at Facility (${preConvertedFilterCounts.newPendingPaymentAtFacility})`}
                      onChange={formik.handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Divider className="mt-4"></Divider>
            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.followUp}
                    id="followUp"
                    name="followUp"
                    label={`Follow Up (${preConvertedFilterCounts.followUpPatients})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setFollowUpCheckboxChecked(!followUpCheckboxChecked);
                      handleFollowUpClick();
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                    checked={formik.values.educationalCurriculum}
                    id="educationalCurriculum"
                    name="educationalCurriculum"
                    label={`Educational Curriculum (${preConvertedFilterCounts.educationalCurriculum})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.paymentCurriculum}
                    id="paymentCurriculum"
                    name="paymentCurriculum"
                    label={`Payment Curriculum (${preConvertedFilterCounts.paymentCurriculum})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.awaitingConsentDocs}
                    id="awaitingConsentDocs"
                    name="awaitingConsentDocs"
                    label={`Awaiting Consent Docs (${preConvertedFilterCounts.awaitingConsentDocs})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.patientChampion}
                    id="patientChampion"
                    name="patientChampion"
                    label={`Patient Champion (${preConvertedFilterCounts.patientChampion})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.awaitingHREApproval}
                    id="awaitingHREApproval"
                    name="awaitingHREApproval"
                    label={`Awaiting HRE Approval (${preConvertedFilterCounts.awaitingHREApproval})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.paymentMethodNeeded}
                    id="paymentMethodNeeded"
                    name="paymentMethodNeeded"
                    label={`Payment Method Needed (${preConvertedFilterCounts.paymentMethodNeeded})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.noStatus}
                    id="noStatus"
                    name="noStatus"
                    label={`No Status (${preConvertedFilterCounts.noStatus})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.hreApproved}
                    id="hreApproved"
                    name="hreApproved"
                    label={`HRE Approved (${preConvertedFilterCounts.hreApproved})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.secondContactAttempt}
                    id="secondContactAttempt"
                    name="secondContactAttempt"
                    label={`Second Contact Attempt (${preConvertedFilterCounts.secondContactAttempt})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.thirdContactAttempt}
                    id="thirdContactAttempt"
                    name="thirdContactAttempt"
                    label={`Third Contact Attempt (${preConvertedFilterCounts.thirdContactAttempt})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.awaitingClient}
                    id="awaitingClient"
                    name="awaitingClient"
                    label={`Awaiting Client (${preConvertedFilterCounts.awaitingClient})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                    }}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedPaymentConfirmationRequired}
                      id="hreApprovedPaymentConfirmationRequired"
                      name="hreApprovedPaymentConfirmationRequired"
                      label={`HRE Approved By Client For Payment At Facility, Payment Confirmation Required (${preConvertedFilterCounts.hreApprovedPaymentConfirmationRequired})`}
                      onChange={(e: Event) => {
                        formik.handleChange(e);
                      }}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedDirectBillFollowUp}
                      id="hreApprovedDirectBillFollowUp"
                      name="hreApprovedDirectBillFollowUp"
                      label={`HRE Approved By Client For Direct Bill Follow Up (${preConvertedFilterCounts.hreApprovedDirectBillFollowUp})`}
                      onChange={(e: Event) => {
                        formik.handleChange(e);
                      }}
                  />
                  <Checkbox
                    checked={formik.values.mobilePaymentFailureFollowUp}
                    id="mobilePaymentFailureFollowUp"
                    name="mobilePaymentFailureFollowUp"
                    label={`Mobile Payment Unsuccessful (${preConvertedFilterCounts.mobilePaymentFailureFollowUp})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                    }}
                  />
                  <Checkbox
                    checked={formik.values.mobilePaymentRetryLimit}
                    id="mobilePaymentRetryLimit"
                    name="mobilePaymentRetryLimit"
                    label={`Mobile Payment Retry Limit (${preConvertedFilterCounts.mobilePaymentRetryLimit})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                    }}
                  />
                  <Checkbox
                      checked={formik.values.followUpNeededPendingPaymentAtFacility}
                      id="followUpNeededPendingPaymentAtFacility"
                      name="followUpNeededPendingPaymentAtFacility"
                      label={`Pending Payment at Facility (${preConvertedFilterCounts.followUpNeededPendingPaymentAtFacility})`}
                      onChange={(e: Event) => {
                        formik.handleChange(e);
                      }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Divider className="mt-4"></Divider>
            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.closed}
                    id="closed"
                    name="closed"
                    label={`Closed (${preConvertedFilterCounts.closedPatients})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setClosedCheckboxChecked(!closedCheckboxChecked);
                      handleClosedClick();
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                    checked={formik.values.fileError}
                    id="fileError"
                    name="fileError"
                    label={`File Error (${preConvertedFilterCounts.fileError})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.patientFailedToEngage}
                    id="patientFailedToEngage"
                    name="patientFailedToEngage"
                    label={`Patient Failed To Engage (${preConvertedFilterCounts.patientFailedToEngage})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.hreRejectedByPatient}
                    id="hreRejectedByPatient"
                    name="hreRejectedByPatient"
                    label={`HRE Rejected by Patient (${preConvertedFilterCounts.hreRejectedByPatient})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.hreRejectedByClient}
                    id="hreRejectedByClient"
                    name="hreRejectedByClient"
                    label={`HRE Rejected By Client (${preConvertedFilterCounts.hreRejectedByClient})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.patientNeverAdmitted}
                    id="patientNeverAdmitted"
                    name="patientNeverAdmitted"
                    label={`Patient Never Admitted (${preConvertedFilterCounts.patientNeverAdmitted})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.finpayFailedToEngage}
                      id="finpayFailedToEngage"
                      name="finpayFailedToEngage"
                      label={`Finpay Failed to Engage (${preConvertedFilterCounts.finpayFailedToEngage})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.duplicateRecord}
                      id="duplicateRecord"
                      name="duplicateRecord"
                      label={`Duplicate Record (${preConvertedFilterCounts.duplicateRecord})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.patientCoveredAt100Percent}
                      id="patientCoveredAt100Percent"
                      name="patientCoveredAt100Percent"
                      label={`Patient Covered at 100% (${preConvertedFilterCounts.patientCoveredAt100Percent})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedForPaymentAtFacilityPaymentConfirmed}
                      id="hreApprovedForPaymentAtFacilityPaymentConfirmed"
                      name="hreApprovedForPaymentAtFacilityPaymentConfirmed"
                      label={`HRE Approved by Client for Payment at Facility, Payment Confirmed (${preConvertedFilterCounts.hreApprovedForPaymentAtFacilityPaymentConfirmed})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedForFullScholarship}
                      id="hreApprovedForFullScholarship"
                      name="hreApprovedForFullScholarship"
                      label={`HRE Approved by Client for Full Scholarship (${preConvertedFilterCounts.hreApprovedForFullScholarship})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.mobilePaymentFailureClosed}
                      id="mobilePaymentFailureClosed"
                      name="mobilePaymentFailureClosed"
                      label={`Mobile Payment Failure (${preConvertedFilterCounts.mobilePaymentFailureClosed})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.mobilePaymentRetryLimitClosed}
                      id="mobilePaymentRetryLimitClosed"
                      name="mobilePaymentRetryLimitClosed"
                      label={`Mobile Payment Retry Limit (${preConvertedFilterCounts.mobilePaymentRetryLimitClosed})`}
                      onChange={formik.handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Button
              onClick={handleReset}
              type="text"
              marginTop={8}
              paddingLeft={0}
              icon={<ClearIcon />}
            >
              Reset Filter
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );

  const convertedFilters = (

    <div className="client-filters">
      {clientsAccordion(PatientType.converted)}
      <Formik
        innerRef={formRefConverted}
        enableReinitialize={true}
        initialValues={initialValuesConverted}
        validate={handleValidateConverted}
        onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
      >
        {(formik) => (
          <Form>
            <Divider className="mb-4" />
            <Toggle
              leftText="Preconverted"
              rightText="&nbsp;&nbsp;&nbsp;&nbsp;Converted&nbsp;&nbsp;&nbsp;&nbsp;"
              name="isPreConverted"
              value={isPreConverted}
              formik={formik}
              onChange={() => {
                dispatch(setIsPreConverted(!isPreConverted));
              }}
            />
            <Typography variant="subtitle1" className="mb-6 mt-4">
              Filters
            </Typography>
            <Box mb={4}>
              <TextField
                  select={true}
                  label="Date Range"
                  name="dateRange_converted"
                  value={formik.values.dateRange_converted}
                  onChange={(e: Event) => {
                    formik.handleChange(e);
                    handleDateRangeChanged((e.target as HTMLInputElement).value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="3">Current + 3 Months</MenuItem>
                  <MenuItem value="6">Current + 6 Months</MenuItem>
                  <MenuItem value="12">Current + 12 Months</MenuItem>
                  <MenuItem value="0">All</MenuItem>
              </TextField>
            </Box>
            <Checkbox
              checked={formik.values.recentlyViewedPatientsConverted}
              id="recentlyViewedPatientsConverted"
              label={`Recently Viewed (${convertedFilterCounts.recentlyViewedPatientsConverted})`}
              name="recentlyViewedPatientsConverted"
              onChange={formik.handleChange}
            />
            <Checkbox
              checked={formik.values.reminders}
              id="reminders"
              label={`Reminders (${convertedFilterCounts.reminders})`}
              name="reminders"
              onChange={formik.handleChange}
            />
            <Checkbox
              checked={formik.values.goodStanding}
              id="goodStanding"
              label={`Good Standing (${convertedFilterCounts.goodStanding})`}
              name="goodStanding"
              onChange={formik.handleChange}
            />
            <Checkbox
              checked={formik.values.paid}
              id="paid"
              label={`Paid (${convertedFilterCounts.paid})`}
              name="paid"
              onChange={formik.handleChange}
            />
            <Divider className="mt-4"></Divider>
            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.priority}
                    id="priority"
                    name="priority"
                    label={`Priority (${convertedFilterCounts.priority})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setPriorityCheckboxChecked(!priorityCheckboxChecked);
                      handlePriorityClick();
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                    checked={formik.values.awaitingAuthorizations}
                    id="awaitingAuthorizations"
                    name="awaitingAuthorizations"
                    label={`Awaiting Authorizations (${convertedFilterCounts.awaitingAuthorizations})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.awaitingACHVerification}
                    id="awaitingACHVerification"
                    name="awaitingACHVerification"
                    label={`Awaiting ACH Verification (${convertedFilterCounts.awaitingACHVerification})`}
                    onChange={formik.handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Divider className="mt-4"></Divider>
            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.atRisk}
                    id="atRisk"
                    name="atRisk"
                    label={`At Risk (${convertedFilterCounts.atRisk})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setAtRiskCheckboxChecked(!atRiskCheckboxChecked);
                      handleAtRiskClick();
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                    checked={formik.values.missedPayment}
                    id="missedPayment"
                    name="missedPayment"
                    label={`Missed Payment (${convertedFilterCounts.missedPayment})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.pastDueBalance}
                    id="pastDueBalance"
                    name="pastDueBalance"
                    label={`Past Due Balance (${convertedFilterCounts.pastDueBalance})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.balanceAdjustmentRequired}
                    id="balanceAdjustmentRequired"
                    name="balanceAdjustmentRequired"
                    label={`Balance Adjustment Required (${convertedFilterCounts.balanceAdjustmentRequired})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.onHold}
                    id="onHold"
                    name="onHold"
                    label={`On Hold (${convertedFilterCounts.onHold})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.missingFundingSource}
                    id="missingFundingSource"
                    name="missingFundingSource"
                    label={`Missing Funding Source (${convertedFilterCounts.missingFundingSource})`}
                    onChange={formik.handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.closed_converted}
                    id="closed"
                    name="closed"
                    label={`Closed (${convertedFilterCounts.closed_converted})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setClosedCheckboxChecked_converted(!closedCheckboxChecked_converted);
                      handleClosedClick_converted();
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                <Checkbox
                    checked={formik.values.fileError_converted}
                    id="fileError_converted"
                    name="fileError_converted"
                    label={`File Error (${convertedFilterCounts.fileError_converted})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.patientFailedToEngage_converted}
                    id="patientFailedToEngage_converted"
                    name="patientFailedToEngage_converted"
                    label={`Patient Failed to Engage (${convertedFilterCounts.patientFailedToEngage_converted})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.patientNeverAdmitted_converted}
                    id="patientNeverAdmitted_converted"
                    name="patientNeverAdmitted_converted"
                    label={`Patient Never Admitted (${convertedFilterCounts.patientNeverAdmitted_converted})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                    checked={formik.values.default}
                    id="default"
                    name="default"
                    label={`Default (${convertedFilterCounts.default})`}
                    onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.finpayFailedToEngage}
                      id="finpayFailedToEngage"
                      name="finpayFailedToEngage"
                      label={`FinPay Failed to Engage (${convertedFilterCounts.finpayFailedToEngage})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.duplicateRecord}
                      id="duplicateRecord"
                      name="duplicateRecord"
                      label={`Duplicate Record (${convertedFilterCounts.duplicateRecord})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.insurancePaidInFull}
                      id="insurancePaidInFull"
                      name="insurancePaidInFull"
                      label={`Insurance Paid in Full (${convertedFilterCounts.insurancePaidInFull})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.accountHolderPaidBalanceOwed}
                      id="accountHolderPaidBalanceOwed"
                      name="accountHolderPaidBalanceOwed"
                      label={`Account Holder Paid Balance Owed (${convertedFilterCounts.accountHolderPaidBalanceOwed})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.accountHolderPaidBalanceOwedDueARefund}
                      id="accountHolderPaidBalanceOwedDueARefund"
                      name="accountHolderPaidBalanceOwedDueARefund"
                      label={`Account Holder Paid Balance Owed and is Due a Refund (${convertedFilterCounts.accountHolderPaidBalanceOwedDueARefund})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.revokedPaymentPlan}
                      id="revokedPaymentPlan"
                      name="revokedPaymentPlan"
                      label={`Revoked Payment Plan (${convertedFilterCounts.revokedPaymentPlan})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.providersRequest}
                      id="providersRequest"
                      name="providersRequest"
                      label={`Provider's Request (${convertedFilterCounts.providersRequest})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.refinanced}
                      id="refinanced"
                      name="refinanced"
                      label={`Refinanced (${convertedFilterCounts.refinanced})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedDirectBillPaymentMadeToFacility}
                      id="hreApprovedDirectBillPaymentMadeToFacility"
                      name="hreApprovedDirectBillPaymentMadeToFacility"
                      label={`HRE Approved by Client for Direct Bill, Payment Made to Facility (${convertedFilterCounts.hreApprovedDirectBillPaymentMadeToFacility})`}
                      onChange={formik.handleChange}
                  />
                  <Checkbox
                      checked={formik.values.hreApprovedPaymentAtFacility}
                      id="hreApprovedPaymentAtFacility"
                      name="hreApprovedPaymentAtFacility"
                      label={`HRE Approved by Client for Payment at Facility (${convertedFilterCounts.hreApprovedPaymentAtFacility})`}
                      onChange={formik.handleChange}
                  /><Checkbox
                      checked={formik.values.hreApprovedFullScholarship}
                      id="hreApprovedFullScholarship"
                      name="hreApprovedFullScholarship"
                      label={`HRE Approved by Client for Full Scholarship (${convertedFilterCounts.hreApprovedFullScholarship})`}
                      onChange={formik.handleChange}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
            <Button
              onClick={handleConvertedReset}
              type="text"
              marginTop={8}
              paddingLeft={0}
              icon={<ClearIcon />}
            >
              Clear Filters
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )

  return (
    <>
      {isPreConverted ? preconvertedFilters : convertedFilters}
    </>
  )
}

export default PatientFilter;
