export const calculateDownByPercent = (downPercentage: number, pfr: number) => { // enter .02 for 2%, .2 for 20%, etc. Don't enter '20%'
  return roundToTwoDecPlaces(pfr * downPercentage);
};

export const calculateTermPmt = (pfr: number, downPayment: number, term: number) => {
  return roundToTwoDecPlaces((pfr - downPayment) / term);
};

export const calculateDownPmt = (pfr: number, termPayment: number, term: number) => {
  return roundToTwoDecPlaces(pfr - (termPayment * term));
}

export const calculateTerm = (pfr: number, downPayment: number, termPayment: number) => {
  return roundToTwoDecPlaces((pfr - downPayment) / termPayment);
}

export const calculatePFR = (downPayment: number, termPayment: number, term: number) => {
  return roundToTwoDecPlaces(downPayment + (termPayment * term));
}

export const roundToTwoDecPlaces = (num: number) => {
  return (Math.round(num * 100) / 100);
}

export const formatNumberToUSD = (num?: number) => {
  if (typeof num === 'number' && !isNaN(num)) {
    let formattedNum = (Math.abs(num)).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    if (num < 0) {
      formattedNum = "(" + formattedNum + ")";
    }
    return formattedNum;
  } else { // input was not a number. invalid input. return 0.
    return 0;
  }
}

export const formatNumberToUSDWithNegativeValues = (num?: number) => {
  if (typeof num === 'number' && !isNaN(num)) {
    let formattedNum = num.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formattedNum;
  } else { // input was not a number. invalid input. return 0.
    return 0;
  }
}

export const calculateDiscount = (pfrAmt: number, discountAmt: number) => {
  return roundToTwoDecPlaces(pfrAmt * (1 - (discountAmt / 100)))
}

export const eighteenYearsAgo = (): Date => {
  const today = new Date();
  return new Date(today.setMonth(today.getMonth() - 12 * 18));
}

export const getOrdinalSuffix = (number: number) => {

  const lastDigit = number % 10;
  const lastTwoDigits = number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) return 'th';
  if (lastDigit === 1) return 'st';
  if (lastDigit === 2) return 'nd';
  if (lastDigit === 3) return 'rd';
  return 'th';
}

