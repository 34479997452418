export class ApplicationStatusState {
    showMessage!: boolean;
    hasErrorOccurred!: boolean;
    message!: string;
    messageType!: "error" | "success";
  }

  export const emptyApplicationStatus: ApplicationStatusState  = {
    showMessage: false,
    hasErrorOccurred: false,
    messageType: "success",
    message: ""
  }

export function GetNotification(message: string): ApplicationStatusState {
  return {
    showMessage: true,
    hasErrorOccurred: false,
    messageType: "success",
    message: message
  }
}

export function GetError(message: string): ApplicationStatusState {
    return {
      showMessage: true,
      hasErrorOccurred: true,
      messageType: "error",
      message: message
    }
}

