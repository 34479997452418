import {createAsyncThunk} from '@reduxjs/toolkit';
import {metaDataService} from '../service/meta-data-service';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {patientHelper} from '../../patient/services/patient-service';

export const getMetaData = createAsyncThunk(
    "metaData/getMetaData",
    async (param: void, thunkAPI) => {

    const response = await Promise.all([
        metaDataService.getPatientEncounterMetaData(),
        metaDataService.getPaymentChannels()
    ])

    response.forEach((metaDataResponse:Awaited<AxiosResultStatus>) => {
       if(metaDataResponse.hasErrors){
           patientHelper.robustErrorHandler(metaDataResponse, thunkAPI);
       }
    })

    const [patientEncounterMetaData, paymentChannelMetaData] = response;

    return{
        ...patientEncounterMetaData.entity,
        paymentChannel: paymentChannelMetaData.entity
    }
    }
)

export const getAlerts = createAsyncThunk("metaData/getAlerts", async (clientId: number | string, thunkAPI) => {
    const alerts = await metaDataService.getAlerts(clientId);
    return {
        clientId,
        alerts
    }
})

export const getEmployers = createAsyncThunk("metaData/getEmployers", async (clientId: number | string, thunkAPI) => {
    const employers = await metaDataService.getEmployers(clientId);
    return {
        clientId,
        employers
    }
})

export const getPaymentMetadata = createAsyncThunk(
    "metadata/getPaymentMetadata",
    async (sources: string[] | undefined, thunkAPI) =>{

        const response = await metaDataService.getPaymentMetadata(sources);

        if(response.hasErrors)  patientHelper.robustErrorHandler(response, thunkAPI);

        return response.entity;

    })