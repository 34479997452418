export interface StripeBankData {
  country: string, // always 'US'
  currency: string, // always 'usd'
  routing_number: string,
  account_number: string,
  bank_name: string,
  account_holder_name: string,
  account_holder_type: string, // Probably always 'individual' ? Other option is 'company'.
}

export const emptyStripeBankData: StripeBankData = {
  country: '',
  currency: '',
  routing_number: '',
  account_number: '',
  bank_name: '',
  account_holder_name: '',
  account_holder_type: ''
}