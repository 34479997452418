import './../../../../../scss/pages/admin/_admin-clients.scss';

import { DatePicker, DialogActionButton, TextField } from '@finpay-development/shared-components';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import { completeSetup } from '../../../../../shared/validation/schemas';
import {
  ExternalAccountDetails,
  ExternalAccountForm,
  ExternalAccountFormPayload,
} from '../../../../models/external-account';
import { clearStatus } from '../../../../state/facility/implementation-facility-slice';
import { saveExternalAccount } from '../../../../state/facility/implementation-facility-thunk';


interface CompleteSetupModalProps {
  open: boolean;
  isClientOwner: boolean;
  facilityId: number;
  facilityName: string;
  handleCompleteCancel: () => void;
  handleCompleteSubmit: () => void;
  showPrivacyText?: boolean;
}

export function CompleteSetupModal(props: CompleteSetupModalProps) {
  const { open, isClientOwner, facilityId, facilityName, handleCompleteCancel, handleCompleteSubmit, showPrivacyText = true } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [idDocFront, setidDocFront] = useState<any>(null); // can't get around use of 'any'
  const [idDocBack, setidDocBack] = useState<any>(null); // can't get around use of 'any'

  const dispatch = useDispatch<AppDispatch>();

  const formRef: any = useRef();
  const idFrontFilePicker: any = useRef();
  const idBackFilePicker: any = useRef();

  const selectors = {
    clientId: useSelector(
      (state: RootState) => {
        if (isClientOwner) {
          return state.userContext.userProfile.allowedClients[0].clientId
        } else { // implementation specialist
          return state.implementationContext.implementationSpecialistClient.client.clientId
        }
      }
    ),
    saveStatus: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.apiStatus
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.errorMessage
    ),
  };

  async function handleSave() {
    const formValues: ExternalAccountForm = formRef.current.values;
    const formData = new FormData();
    let formatedBirthDate = ""
    if(formValues.birthDate){
      let newDate = new Date(formValues.birthDate)
      formatedBirthDate = Utils.convertDate(newDate)
    }

    formData.append("idDocFront", idDocFront);
    formData.append("idDocBack", idDocBack);
    formData.append("externalAccountDetailString", JSON.stringify({
      ssn: formValues.ssn,
      birthDate: formatedBirthDate,
      bankName: formValues.bankName,
      routingNumber: formValues.routingNumber,
      accountNumber: formValues.accountNumber.padStart(12, '0')
    } as ExternalAccountDetails));

    await dispatch(saveExternalAccount({
      clientId: selectors.clientId,
      facilityId: facilityId,
      formData: formData
    } as ExternalAccountFormPayload))
    handleCompleteSubmit();

  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleCompleteCancel();
  }

  const handleFilePickerChange = (e: Event, field: "idDocFront" | "idDocBack") => {
    const inputEl = e.target as HTMLInputElement;
    if (!inputEl.files) return;
    formRef.current.setFieldValue(field, inputEl.files[0].name); // for showing filename in textbox
    if (field === "idDocFront") {
      setidDocFront(inputEl.files[0]); // actual 'file' to send on submit
    } else if (field === "idDocBack") {
      setidDocBack(inputEl.files[0]); // actual 'file' to send on submit
    }
  }

  const validationSchema = Yup.object(completeSetup);

  function checkIfIsValid(value: ExternalAccountForm) {
    validationSchema
      .validate(value)
      .then(() => {
        if (Utils.isValidDate(value?.birthDate)) {
          setEnableSaveButton(true);
        }
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const initialValues: ExternalAccountForm = {
    ssn: "",
    birthDate: null,
    idDocBack: "",
    idDocFront: "",
    bankName: "",
    routingNumber: "",
    accountNumber: "",
    retypeAccountNumber: ""
  }

  return (
    <Dialog
      className="modal complete-setup-modal"
      scroll="body"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Complete {!isClientOwner && facilityName} Setup</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Complete Legal Identification
                  </Typography>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid xs={6} item className="mb-4">
                    <TextField
                      error={formik.touched['ssn'] && formik.errors['ssn']}
                      maxLength={11}
                      label="Social Security Number"
                      name="ssn"
                      value={formik.values.ssn}
                      onChange={(e: Event) => {
                        formik.handleChange(e);
                        formik.setFieldValue("ssn", Utils.formatSSN((e.target as HTMLInputElement).value))
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Social Security Number"
                    />
                  </Grid>
                </Grid>

                <Grid container item spacing={2}>
                  <Grid xs={6} item className="mb-4">
                    <DatePicker
                      error={Utils.isValidDate(formik.values.birthDate) && (formik.touched['birthDate'] && formik.errors['birthDate'])}
                      label="Date of Birth"
                      value={formik.values.birthDate}
                      onChange={(date: Date) => {
                        if (!Utils.isFutureDate(date)) formik.setFieldValue('birthDate', date)
                        else formik.setFieldValue('birthDate', moment())
                      }}
                      minDate={Utils.getMinDate()}
                      maxDate={moment()}
                    />
                  </Grid>
                </Grid>

                <Grid xs={6} item>
                  <input ref={idFrontFilePicker} type="file" className="hide" onChange={(e: any) => handleFilePickerChange(e, "idDocFront")} />
                  <TextField
                    error={formik.touched['idDocFront'] && formik.errors['idDocFront']}
                    label="Identification Document Front"
                    name="idDocFront"
                    value={formik.values.idDocFront}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onClick={() => idFrontFilePicker.current.click()}
                    startAdornment={<BackupOutlinedIcon />}
                    placeholder="Upload"
                  />
                </Grid>
                <Grid xs={6} item>
                  <input ref={idBackFilePicker} type="file" className="hide" onChange={(e: any) => handleFilePickerChange(e, "idDocBack")} />
                  <TextField
                    error={formik.touched['idDocBack'] && formik.errors['idDocBack']}
                    label="Identification Document Back"
                    name="idDocBack"
                    value={formik.values.idDocBack}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onClick={() => idBackFilePicker.current.click()}
                    startAdornment={<BackupOutlinedIcon />}
                    placeholder="Upload"
                  />
                </Grid>

                <Grid container spacing={2} direction="column" className="ml-0">
                  <Grid item>
                    <Typography variant="body2" className="mt-4">
                      Acceptable Identification
                    </Typography>
                    <ul className="bulleted-list">
                      <li>
                        <Typography variant="body2">
                          Color scan of front and back of state issued drivers
                          license
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="body2">Passport</Typography>
                      </li>
                      <li>
                        <Typography variant="body2">
                          Other state issued identification
                        </Typography>
                      </li>
                    </ul>
                    {showPrivacyText && (
                      <>
                        <Typography variant="body1" color="secondary" className="mt-8">
                          FinPay respects your privacy
                        </Typography>
                        <div className="pb-2"></div>
                        <Typography variant="body2">
                          We offer services that enable our clients to run
                          businesses, and to safely conduct online payment
                          transactions. We need to collect some personal data to
                          set up your merchant account on Stripe.
                        </Typography>
                        <Typography variant="body2" className="mt-2">
                          This data will not be stored or shared with anyone.
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Pay to Account
                  </Typography>
                  {showPrivacyText && (
                    <>
                      <Typography variant="body1">
                        Enter the bank account details where the funds will be
                        sent to
                      </Typography>
                      <div className="pb-2"></div>
                    </>
                  )}
                </Grid>
                <Grid xs={6} item className="mb-4">
                  <TextField
                    error={formik.touched['bankName'] && formik.errors['bankName']}
                    label="Bank Name"
                    name="bankName"
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Bank Name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={formik.touched['routingNumber'] && formik.errors['routingNumber']}
                    label="Routing Number"
                    name="routingNumber"
                    value={formik.values.routingNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Routing Number"
                  />
                </Grid>

                <Grid xs={6} item>
                  <TextField
                  error={formik.touched['accountNumber'] && formik.errors['accountNumber']}
                    label="Account Number"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Account Number"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={formik.touched['retypeAccountNumber'] && formik.errors['retypeAccountNumber']}
                    label="Retype Account Number"
                    name="retypeAccountNumber"
                    value={formik.values.retypeAccountNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Reenter Account Number"
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
            isEnabled={enableSaveButton}
            savebuttonText="Save"
            saveStatus={selectors.saveStatus}
            executeSave={handleSave}
            handleCallbackSave={handleSaveCallback}
            handleCallbackCancel={handleCancelCallback}
          />
      </DialogActions>
    </Dialog>
  );
}
