import { saveCallbackStatus } from "@finpay-development/shared-components";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationStatusState, emptyApplicationStatus, GetError } from "../../../security/model/application-status";
import { UNKNOWN_SERVER_ERROR } from "../../../shared/state/saving-status";
import {
  emptyPESScriptType,
  ImplementationPESScript,
  PESScriptType,
} from "../../components/implementation-clients/details/models/implementation-pes-script";
import {
  blankPESScriptDetails,
  blankPESScriptForm,
  PESScriptDetails,
  PESScriptForm,
} from "../../models/pes-script-form";
import {
  getClientPESScript,
  getClientPESScripts,
  savePESScript,
} from "./implementation-pesscript-thunk";

interface ImplementationPESScriptState {
  pesScript: PESScriptType;
  pesScripts: ImplementationPESScript[];
  currentPESScriptForm: Partial<PESScriptForm>;
  currentPESScriptDetails: PESScriptDetails;
  apiResponsePESScript: ImplementationPESScript;
  pesScriptWindowObjectReference: Array<any>;

  // common state for most/all slices
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
  applicationStatus: ApplicationStatusState;}

export const initialState: ImplementationPESScriptState = {
  pesScript: emptyPESScriptType,
  pesScripts: [],
  apiResponsePESScript: emptyPESScriptType,
  apiStatus: saveCallbackStatus.none,
  errorMessage: "",
  isLoading: false,
  currentPESScriptForm: blankPESScriptForm,
  currentPESScriptDetails: blankPESScriptDetails,
  pesScriptWindowObjectReference: [],
  applicationStatus: emptyApplicationStatus,
};

const pesScriptSlice = createSlice({
  name: "pesScriptSliceContext",
  initialState,
  reducers: {
    clearPESScriptForm: (state) => {
      state.currentPESScriptForm = blankPESScriptForm;
    },
    clearStatus: (state) => {
      state.apiStatus = saveCallbackStatus.none;
      state.errorMessage = "";
    },
    clearPESScripts: (state) => {
      state.pesScripts = [];
    },
    resetPESScriptContext: (state) => {
      return initialState;
    },
    resetAddPESScript: (state) => {
      state.isLoading = false;
    },
    addPesScriptWindowObjectReference(state, action: PayloadAction<any>) {
      state.pesScriptWindowObjectReference = [action.payload];
    },
    resetPesScriptWindowObjectReference(state) {
      state.pesScriptWindowObjectReference =
        initialState.pesScriptWindowObjectReference;
    },
    showPesScriptErrorStatus(state, action: PayloadAction<string>) {
      state.applicationStatus = GetError(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClientPESScripts.fulfilled, (state, action) => {
      state.pesScripts = action.payload.apiResponsePESScript;
      state.isLoading = false;
    });
    builder.addCase(getClientPESScripts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getClientPESScripts.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getClientPESScript.fulfilled, (state, action) => {
      state.currentPESScriptForm = action.payload.pesScriptForm;
      state.currentPESScriptDetails = action.payload.pesScriptDetails;
      state.apiResponsePESScript = action.payload.apiResponsePESScript;
    });
    builder.addCase(getClientPESScript.pending, (state) => {});
    builder.addCase(getClientPESScript.rejected, (state) => {
      state.apiStatus = saveCallbackStatus.error;
    });
    builder.addCase(savePESScript.fulfilled, (state, action) => {
      state.pesScripts = action?.payload?.apiResponsePESScript;
      state.apiStatus = saveCallbackStatus.success;
    });
    builder.addCase(savePESScript.pending, (state) => {
      state.apiStatus = saveCallbackStatus.none;
    });
    builder.addCase(savePESScript.rejected, (state, action) => {
      state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR;
      state.apiStatus = saveCallbackStatus.error;
    });
  },
});

export const {
  clearPESScriptForm,
  resetAddPESScript,
  clearStatus,
  addPesScriptWindowObjectReference,
  resetPesScriptWindowObjectReference,
  showPesScriptErrorStatus
} = pesScriptSlice.actions;

export default pesScriptSlice.reducer;
