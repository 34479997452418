/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ProgressBar } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import LockModal from '../../../../implementation-specialist/components/locks/lock-modal';
import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import { showStatus } from '../../../../security/state/user-slice';
import { formatNumberToUSD } from '../../../../shared/calculators';
import HeaderWithDetails from '../../../../shared/components/header-with-details';
import { configSettings } from '../../../../shared/configuration/config-settings';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientService } from '../../../services/patient-service';
import {
  resetPatientRecordLockingContext,
  setInUseModalOpen_patientChampions,
  setIsPatientChampionsModalOpen,
  setLockRequestedById,
  setLockStatusReleased_patientChampions,
  setRequestDeniedModalOpen_patientChampions,
} from '../../../state/patient-record-locking-slice';
import {
  denyRequestOk,
  editModalPoll,
  releaseRecordOk,
  requestReleaseOk,
} from '../../../state/patient-record-locking-thunk';
import { resetPatientContext } from '../../../state/patient-slice';
import { createPhiHelloSignRequest, lockIOC } from '../../../state/patient-thunk';
import { LockablePatientRecordSection } from '../../models/lockable-patient-record-section';
import { PatientChampion, phiChampion } from '../../models/patient-champion';
import { ChampionModal } from './patient-champion-modal';

let editModalPollingInterval: any; // an interval assigned via setInterval

const ChampionAccordionContents = () => {
  const countdownMax = configSettings.REACT_APP_patient_locking_countdown_max;
  const lockPollingInterval = configSettings.REACT_APP_patient_locking_polling_interval;

  const [selectedChampionIndex, setSelectedChampionIndex] = useState(0);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isRequestReleaseButtonDisabled, setIsRequestReleaseButtonDisabled] = useState(false);

  const handleChampionModalClose = () => {
    releasePatientLock();
    dispatch(setIsPatientChampionsModalOpen(false));
    clearInterval(editModalPollingInterval);
  }

  const stateFields = {
    championsList: useSelector((state: RootState) => state.patientContext.selectedEncounter.patientChampion),
    userName: useSelector((state: RootState) => state.userContext.userProfile.userName),
    hasChampions: useSelector((state: RootState) => state.patientContext.selectedEncounter.hasChampions),
    selectedPatientEncounter: useSelector((state: RootState) => state.patientContext.selectedEncounter),
    lockingModalOpenStatus: useSelector((state: RootState) => state.patientRecordLockingContext.lockingModalOpenStatus),
    patientModalOpenStatus: useSelector((state: RootState) => state.patientRecordLockingContext.patientModalOpenStatus),
    isLockStatusReleased_patientChampions: useSelector((state: RootState) => state.patientRecordLockingContext.isLockStatusReleased_patientChampions),
    lockRequestedById: useSelector((state: RootState) => state.patientRecordLockingContext.lockRequestedById),
    selectedPatient: useSelector((state: RootState) => state.patientContext.selectedPatient),
    isLoadingPhiDocument: useSelector((state: RootState) => state.patientContext.isLoading.phiDocument),
  }

  const {
    championsList, userName, hasChampions, lockingModalOpenStatus, selectedPatientEncounter,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    patientModalOpenStatus, isLockStatusReleased_patientChampions, lockRequestedById, selectedPatient, isLoadingPhiDocument
  } = stateFields;

  // if hasChampions is false but there are champions, then we want to ignore hasChampions and show them anyway.
  const hasChampionsOverride = hasChampions || championsList?.length > 0

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const releasePatientLock = () => {
    setTimeout( async () => {
      await dispatch(lockIOC({lock: false})) // unlock the IOC
    }, 500);
  }

  // const getPhiStatus = (status: string) => {
  //     const index: number = Object.keys(PhiStatus).indexOf(status);
  //     return Object.values(PhiStatus)[index];
  // }

  const doModalPolling = useCallback(async (patientId: number, encounterId: number) => {
    editModalPollingInterval = setInterval(async () => {
      dispatch(editModalPoll({
        patientId,
        encounterId,
        userName,
        lockablePatientRecordSection: LockablePatientRecordSection.patientChampions
      }))
    }, lockPollingInterval);
  }, [dispatch, userName])

  useEffect(() => {
    if (isLockStatusReleased_patientChampions) {
      doModalPolling(selectedPatientEncounter.patientId, selectedPatientEncounter.patientEncounterId);
      dispatch(setLockStatusReleased_patientChampions(false));
    }
  }, [dispatch, doModalPolling, isLockStatusReleased_patientChampions, selectedPatientEncounter.patientId, selectedPatientEncounter.patientEncounterId])

  const sectionHeader = (outputString: string) => (
    <Grid item xs={12}>
      <Typography variant="h3">
        {outputString}
      </Typography>
    </Grid>
  )

  const sectionContent = (outputString: string) => (
    <Grid item xs={12} style={{marginBottom: -13}}>
      <Typography variant="h3">
        {outputString}
      </Typography>
    </Grid>
  )

  const handleEditModalClick = async (champion: PatientChampion) => {
    dispatch(setIsPatientChampionsModalOpen(true));
    setIsEditModal(true);
    const championIndex = championsList.findIndex(
      (item: PatientChampion) => item.patientChampionId === champion.patientChampionId
    );
    setSelectedChampionIndex(championIndex);

    const patientId = selectedPatientEncounter.patientId;
    const encounterId = selectedPatientEncounter.patientEncounterId;

    const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});
    const {isLocked} = instanceOfCare.entity || false; // Needed to add the default value to avoid undefined for brand new patient

    if (isLocked && instanceOfCare.entity.lockedByUserId !== userName && instanceOfCare.entity.lockedByUserId !== null) { // extra checks in case of bad data
      setIsRequestReleaseButtonDisabled(false);
      dispatch(setLockRequestedById(instanceOfCare.entity.lockedByUserId));
      dispatch(setInUseModalOpen_patientChampions(true));
    } else {
      // patient not locked by anyone. Go ahead and lock it for me so others are locked out
      dispatch(setLockRequestedById(undefined));
      await dispatch(lockIOC({lock: true, username: userName}))

      await doModalPolling(patientId, encounterId);
    }
  }

  const handleAddModalClick = () => {
    dispatch(setIsPatientChampionsModalOpen(true));
    setIsEditModal(false);
    const championIndex = 0;
    setSelectedChampionIndex(championIndex);
  }

  const handleRequestReleaseOk = async () => {
    setIsRequestReleaseButtonDisabled(true);
    dispatch(requestReleaseOk({
      userName: userName,
      lockablePatientRecordSection: LockablePatientRecordSection.patientChampions
    }));
  }

  const handleRequestReleaseCancel = () => {
    navigate("/specialist/dashboard");
    resetRedux();
    dispatch(setInUseModalOpen_patientChampions(false));
  }

  const handleReleaseRecordOk = async () => {
    dispatch(releaseRecordOk({lockablePatientRecordSection: LockablePatientRecordSection.patientChampions}));
    dispatch(setIsPatientChampionsModalOpen(false));
    clearInterval(editModalPollingInterval);
  }

  const handleRequestDeniedClose = () => {
    resetRedux();
    navigate("/specialist/dashboard");
    dispatch(setRequestDeniedModalOpen_patientChampions(false));
  }

  const resetRedux = () => {
    dispatch(resetPatientContext());
    dispatch(resetPatientRecordLockingContext());
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCreatePhiRequest = (champions: PatientChampion[]) => {
      // @ts-ignore
      const champs: phiChampion[] = [];
      champions.forEach(champion => {
          if (champion.phiDocumentStatus) {
              champs.push({
                  patientChampionId: champion?.patientChampionId,
                  relationship: champion?.relationshipToPatient,
                  phone: champion?.contact?.primPhoneNum,
                  championFirstName: champion?.contact?.firstName,
                  championLastName: champion?.contact?.lastName,
              });
         }
      });
      if (champs.length > 0 ) {
          // @ts-ignore
          dispatch(createPhiHelloSignRequest({
              patientEncounterId: selectedPatientEncounter.patientEncounterId,
              patientEmail: selectedPatient?.contact?.email,
              patientName: `${selectedPatient?.contact?.firstName} ${selectedPatient?.contact?.lastName}`,
              clientId: selectedPatientEncounter?.clientId,
              champions: champs
          }))
      } else {
          dispatch(showStatus("Champions are not included in PHI Consent"))
      }
  }

  const addChampionButton = (
    <AccessControl
        rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
        renderNoAccess={() => <Button disabled>Add Champion</Button>}>
        {hasChampionsOverride && <Grid xs={12} item>
          <Button
            disabled={isLoadingPhiDocument}
            loading={isLoadingPhiDocument}
            spinnerLeftPosition={13}
            onClick={() => handleCreatePhiRequest(championsList)}
          >
            Send Phi Consent to Patient
          </Button>
        </Grid>
        }

        <Button
          type="text"
          icon={<AddIcon/>}
          paddingLeft={0}
          onClick={() => handleAddModalClick()}
        >
        {hasChampionsOverride ? 'Additional Champion' : 'Add Champion'}
      </Button>
    </AccessControl>
  )

  const noPatientChampionsView = (
    <>
      <Grid item>
        <Typography variant="h3">
          No Patient Champions
        </Typography>
      </Grid>
      <Box justifyContent="flex-end">
        <Grid item>
          {addChampionButton}
        </Grid>
      </Box>
    </>
  )

  const contents = (champion: PatientChampion, key: number) => (
    <React.Fragment key={key}>
      <Grid item>
        <Typography variant="h3">
          Champion
        </Typography>
      </Grid>
      <Box justifyContent="flex-end">
        <Grid item>
          {addChampionButton && (
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
                           renderNoAccess={() => <IconButton disabled size="large"> <EditIcon
                             className="colorDisabled"/></IconButton>}>
              <IconButton onClick={() => handleEditModalClick(champion)} size="large"> <EditIcon className="icon"/> </IconButton>
            </AccessControl>
          )}
        </Grid>
      </Box>
      <HeaderWithDetails header={"Champion Name"}
                         details={`${champion?.contact?.firstName} ${champion?.contact?.lastName}`} width="full"/>
      <HeaderWithDetails header={"Relationship to Patient"}
                         details={champion?.relationshipToPatient === '-1' ? 'N/A' : champion.relationshipToPatient}
                         width="full"/>
      <HeaderWithDetails header={"Email"} details={champion?.contact?.email} width="full"/>
      <HeaderWithDetails header={"Primary Phone"} details={champion?.contact?.primPhoneNum} width="half"/>
      <HeaderWithDetails header={"Secondary Phone"}
                         details={champion?.contact?.secPhoneNum === '' ? 'N/A' : champion?.contact?.secPhoneNum}
                         width="half"/>
      <HeaderWithDetails header={"Leave Voicemail"}
                         details={Utils.convertBoolToYesNo(champion?.contact?.leaveVMFl || false)} width="half"/>
      <HeaderWithDetails header={"Receive SMS Messages"}
                         details={Utils.convertBoolToYesNo(champion?.contact?.smsConsentFl || false)} width="half"/>
      <HeaderWithDetails header={"Phi Consent Status"}
                         details={champion?.phiDocumentStatus ? 'Yes' : 'No'}
                           width="half"/>
      <HeaderWithDetails header={"Guarantor"} details={Utils.convertBoolToYesNo(champion?.isGuarantor || false)}
                         width="full"/>
      {sectionHeader('Address')}
      <Typography variant="h4">
        {!champion?.contact?.primaryAddress?.streetAddress1 ? sectionHeader('N/A') : sectionContent(champion?.contact?.primaryAddress?.streetAddress1)}
      </Typography>
      <Typography variant="h4" className="pt-4">
        {champion?.contact?.primaryAddress?.streetAddress2 || ''}
      </Typography>
      {!champion?.contact?.primaryAddress?.city ? '' : sectionHeader(`${champion?.contact?.primaryAddress?.city}, ${champion?.contact?.primaryAddress?.state?.stateCode} ${champion?.contact?.primaryAddress?.zipCode}`)}
      {sectionHeader('Personal Information')}
      <HeaderWithDetails header={"Date of Birth"}
                         details={champion?.contact.birthDate !== "1970-01-01" ? champion?.contact.birthDate : 'N/A'}
                         width="half"/>
      <HeaderWithDetails header={"Social Security Number"}
                         details={`${champion?.contact?.ssnLast4 ? '●●●-●●-' + champion.contact.ssnLast4 : 'N/A'}`}
                         width="half"/>
      {sectionHeader('Employment Information')}
      <HeaderWithDetails header={"Currently Employed"}
                         details={Utils.convertBoolToYesNo(champion?.contact?.contactIncome?.isEmployed)} width="full"/>
      <HeaderWithDetails header={"Employer"}
                         details={champion?.contact?.contactIncome?.employer === '' ? 'N/A' : champion?.contact?.contactIncome?.employer}
                         width="half"/>
      <HeaderWithDetails header={"Years at Employer"}
                         details={champion?.contact?.contactIncome?.yearsAtEmployer?.toString()} width="half"/>
      {/* Powerlytics data below */}
      <Grid item xs={6}>
        <ProgressBar
          headerText="Verification score"
          completeness={
            champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
              ? champion?.contact?.contactIncome?.verificationScore || 0
              : 0
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ProgressBar
          headerText="Capacity to Pay"
          completeness={
            champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
              ? champion?.contact?.contactIncome?.capacityToPay || 0
              : 0
          }
        />
      </Grid>
      <HeaderWithDetails
        header={"Disposable Income"}
        details={`${formatNumberToUSD(
          champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
            ? champion?.contact?.contactIncome?.disposableIncome || 0
            : 0
        )}`}
        width="half"
      />
      <HeaderWithDetails
        header={"Retirement Income"}
        details={`${formatNumberToUSD(
          champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
            ? champion?.contact?.contactIncome?.retirementIncome || 0
            : 0
        )}`}
        width="half"
      />
      <HeaderWithDetails
        header={"Business Income"}
        details={`${formatNumberToUSD(
          champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
            ? champion?.contact?.contactIncome?.businessIncome || 0
            : 0
        )}`}
        width="half"
      />
      <HeaderWithDetails
        header={"Wealth Income"}
        details={`${formatNumberToUSD(
          champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
            ? champion?.contact?.contactIncome?.wealthIncome || 0
            : 0
        )}`}
        width="half"
      />
      <HeaderWithDetails
        header={"Wages and Earnings"}
        details={`${formatNumberToUSD(
          champion?.isGuarantor === true && champion?.contact?.contactIncome?.isEmployed
            ? champion?.contact?.contactIncome?.wagesAndEarnings || 0
            : 0
        )}`}
        width="full"
      />
      <Grid item xs={12} className="mt-2 mb-4">
        <Divider/>
      </Grid>
    </ React.Fragment>
  )

  return <>
    <Grid
      container
      justifyContent="space-between"
      className="pb-2"
    >
      {(hasChampionsOverride !== false) && championsList && championsList?.length > 0 &&
        championsList.map((champion: PatientChampion) => (
          contents(champion, champion.patientChampionId)
        ))
      }
      {(hasChampionsOverride !== false) && (
         addChampionButton
      )}
      {(hasChampionsOverride === false) && (
        noPatientChampionsView
      )}
    </Grid>
    {patientModalOpenStatus.isPatientChampionsModalOpen && <ChampionModal
        open={patientModalOpenStatus.isPatientChampionsModalOpen}
        handleModalClose={handleChampionModalClose}
        championsIndex={selectedChampionIndex}
        isEdit={isEditModal}
    />}
    {lockingModalOpenStatus.isInUseModalOpen_patientChampions && <LockModal
        open={lockingModalOpenStatus.isInUseModalOpen_patientChampions}
        title="Instance of Care In Use"
        subTitle1={`A release request will be sent to ${lockRequestedById}`}
        subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
        hasCountdown={false}
        countdownSeconds={countdownMax}
        primaryButtonType="primary"
        primaryButtonText="Request Release"
        handlePrimaryButton={handleRequestReleaseOk}
        secondaryButtonType="secondary"
        secondaryButtonText="Cancel"
        handleSecondaryButton={handleRequestReleaseCancel}
        icon={<LockIcon/>}
        modalType="info"
        justifyButtons="flex-end"
        primaryButtonDisabled={isRequestReleaseButtonDisabled}
    />}

    {lockingModalOpenStatus.isRequestDeniedModalOpen_patientChampions && <LockModal
        open={lockingModalOpenStatus.isRequestDeniedModalOpen_patientChampions}
        title="Request Denied"
        subTitle1={`${lockRequestedById} will retain access to this record`}
        hasCountdown={false}
        primaryButtonType="secondary"
        primaryButtonText="Close"
        handlePrimaryButton={handleRequestDeniedClose}
        icon={<LockIcon/>}
        modalType="error"
        justifyButtons="flex-end"
    />}

    {(lockingModalOpenStatus.isLockRequestedModalOpen_patientChampions) && <LockModal
        open={lockingModalOpenStatus.isLockRequestedModalOpen_patientChampions}
        title="Instance of Care Requested"
        subTitle1={`${lockRequestedById} requested this record`}
        subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
        hasCountdown
        countdownSeconds={countdownMax}
        primaryButtonType="primary"
        primaryButtonText="Release Record"
        handlePrimaryButton={handleReleaseRecordOk}
        secondaryButtonType="danger"
        secondaryButtonText="Deny Request"
        handleSecondaryButton={() => dispatch(denyRequestOk({lockablePatientRecordSection: LockablePatientRecordSection.patientChampions}))}
        icon={<WarningRoundedIcon/>}
        modalType="warning"
        justifyButtons="space-between"
    />}
  </>;
}

export default ChampionAccordionContents;
