import { Button } from '@finpay-development/shared-components';
import { useState } from 'react';

import PrivacyModal from './privacy-policy-modal';

const PrivacyPolicyView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalCancel = () => {
    setIsModalOpen(false);
  }

  return (
    <main>
      <div className={'content px-4 py-4'}>
        <Button
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Open Privacy Policy Modal
        </Button>
      </div>
      {isModalOpen && (
        <PrivacyModal
          handleModalCancel={handleModalCancel}
          isOpen={isModalOpen}
        />
      )}
    </main>
  )
}

export default PrivacyPolicyView;
