import { Button, TextField } from "@finpay-development/shared-components";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { CustomFormikProps } from "../../../shared/model/formik-props";
import { UserRoleViewModel } from ".././../../security/model/user-role";
import { RootState } from "src/shared/state/root-reducer";
import { UserSearchFormValues } from "./admin-user-search-form";
import { handleSearchGetUsers } from "src/admin/services/admin-user-service";
import {
  ClientFacility,
  ClientStatusCardViewModel,
} from "src/shared/model/client-status-card";

export interface AdminUsersFilterProp {
  setIsUserSearchActive: (value: boolean) => void;
  setUserData: (data: any) => void;
  onError: (error: string) => void;
  onResetFilters: () => void;
  onSearch: () => void;
}

function AdminUsersFilter(props: AdminUsersFilterProp) {
  const [selectedData, setSelectedData] = useState<{
    client: Partial<ClientStatusCardViewModel> | null;
    clientFacilities: ClientFacility[];
  }>({ client: null, clientFacilities: [] });

  const {
    setIsUserSearchActive,
    setUserData,
    onResetFilters,
    onError,
    onSearch,
  } = props;

  const selectors = {
    allClientsWithFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .allClientsWithFacillities
    ),
    roles: useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.userRoles
    ),
    searchUserForm: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.searchUserForm
    ),
  };

  const { allClientsWithFacilities, roles, searchUserForm } = selectors;

  const allClients = allClientsWithFacilities?.map((clientInfo) => ({
    clientId: clientInfo.clientId,
    clientName: clientInfo.clientName,
  }));

  const allUserRoles = [...roles.internalUserRoles, ...roles.externalUserRoles];

  const handleAllClientChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedOptions = Number(event?.target?.value);
    if (!selectedOptions) {
      throw new Error("No selected options");
    }
    const selectedClient = allClientsWithFacilities?.find(
      (client) => client.clientId === selectedOptions
    );
    if (selectedClient) {
      setSelectedData({
        client: selectedClient,
        clientFacilities: selectedClient?.clientFacilities || [],
      });
    } else {
      setSelectedData({ client: null, clientFacilities: [] });
    }
  };

  // handle reset here instead of parent so can directly reset form
  function handleResetFilter(formik: CustomFormikProps) {
    formik.resetForm();
    setIsUserSearchActive(false);
    onResetFilters();
  }

  // no real validation rules yet
  const validationSchema = Yup.object({
    clientId: Yup.number(),
    facilityId: Yup.number(),
    userName: Yup.string().email().max(254, "Must be 254 characters or less"),
    roleId: Yup.number(),
    firstName: Yup.string(),
    lastName: Yup.string(),
  });

  const initialValues = {
    clientId: searchUserForm?.clientId ?? 0,
    facilityId: searchUserForm?.facilityId ?? 0,
    userName: searchUserForm?.userName ?? "",
    roleId: searchUserForm?.roleId ?? 0,
    firstName: searchUserForm?.firstName ?? "",
    lastName: searchUserForm?.lastName ?? "",
  };

  const handleButtonClick = async (values: UserSearchFormValues) => {
    const { clientId, facilityId, firstName, lastName, userName, roleId } =
      values;

    const formValues: UserSearchFormValues = {
      clientId,
      facilityId,
      firstName,
      lastName,
      userName,
      roleId,
    };

    const validFormValues: Partial<UserSearchFormValues> = Object.fromEntries(
      Object.entries(formValues).filter(
        ([, value]) =>
          value !== "" && value !== 0 && value !== -1 && value !== null
      )
    );

    if (Object.keys(validFormValues).length !== 0) {
      setIsUserSearchActive(true);
      onSearch();
      try {
        const data = await handleSearchGetUsers(validFormValues);
        setUserData(data);
      } catch (error: any) {
        onError(error.message);
      }
    }
  };

  return (
    <div>
      <Typography variant="subtitle2" className="mb-8">
        Filters
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {(formik) => (
          <Form test-id="filter-form" noValidate>
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <TextField
                  select={true}
                  error=""
                  label="Client"
                  name="clientId"
                  value={formik.values.clientId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(e);
                    formik.setFieldValue("allFacility", "");
                    handleAllClientChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="-1" disabled>
                    Select Client
                  </MenuItem>

                  {allClients?.map((client) => (
                    <MenuItem key={client.clientId} value={client.clientId}>
                      {client.clientName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid xs={12} item>
                <TextField
                  data-testid="facility"
                  select={true}
                  error=""
                  disabled={!selectedData?.client}
                  label="Facility"
                  name="facilityId"
                  value={formik.values.facilityId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="-1" disabled>Select Facility</MenuItem>
                  {selectedData.clientFacilities.map((facility) => (
                    <MenuItem
                      key={facility.facilityId}
                      value={facility.facilityId}
                    >
                      {facility.facilityName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid xs={12} item>
                <TextField
                  error={
                    formik.touched["firstName"] && formik.errors["firstName"]
                  }
                  placeholder="Search by First Name"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  startAdornment={<SearchIcon />}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  error={
                    formik.touched["lastName"] && formik.errors["lastName"]
                  }
                  placeholder="Search by Last Name"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  startAdornment={<SearchIcon />}
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  error={
                    formik.touched["userName"] && formik.errors["userName"]
                  }
                  placeholder="Search by Email"
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  startAdornment={<SearchIcon />}
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  select={true}
                  error=""
                  label="Role"
                  name="roleId"
                  value={formik.values.roleId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem selected value="-1" disabled>
                    {" "}
                    Select Role
                  </MenuItem>
                  {allUserRoles.map((role: UserRoleViewModel) => (
                    <MenuItem key={role.userRoleId} value={role.userRoleId}>
                      {role.roleName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Stack direction="row" spacing={2}>
              <Button
                marginTop={8}
                onClick={() => handleButtonClick(formik?.values)}
              >
                Search
              </Button>
              <Button
                onClick={() => handleResetFilter(formik)}
                type="text"
                marginTop={8}
                icon={<ClearIcon />}
              >
                Reset Filters
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AdminUsersFilter;
