import '../../../../scss/components/_list-header.scss';
import '../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, saveCallbackStatus, TextField } from '@finpay-development/shared-components';
import { Box, Divider, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { formatNumberToUSD } from '../../../../shared/calculators';
import { createCommentSuccesMessages } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { createPatientNote, savePatientPaymentProgram } from '../../../state/patient-thunk';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PatientPaymentSchedule } from '../../models/patient-payment.schedule';

interface HighRiskModalProps {
  open: boolean;
  handleModalClose: () => void;
}

export function HighRiskModal(props: HighRiskModalProps) {
  const { open, handleModalClose } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const stateFields = {
    paymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    selectedEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter
    }),
    selectedPatient: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient
    }),
  }
  const {
    paymentProgram, selectedEncounter, selectedPatient
  } = stateFields;


  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  async function handleSave() {


    const currentDate = new Date();
    const formValues = formRef.current.values;
    formValues.noteDt = currentDate.toISOString();
    formValues.patientEncounterId = selectedEncounter.patientEncounterId;

    const patientPaymentSchedule = {
      patientPaymentScheduleId: selectedEncounter?.patientPaymentProgram[0]?.patientPaymentSchedule?.patientPaymentScheduleId,
      pfrAmt: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrAmt,
      pfrBalance: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.pfrBalance,
      paymentFreq: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.paymentFreq,
      downPmtAmt: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.downPmtAmt,
      terms: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.terms,
      remainingTerms: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.remainingTerms,
      paymentDueAmt: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.paymentDueAmt,
      scheduleStatus: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.scheduleStatus,
      scheduleDt: selectedEncounter.patientPaymentProgram[0].patientPaymentSchedule.scheduleDt,
    } as PatientPaymentSchedule


    const paymentProgram = {
      patientPaymentProgramId: selectedEncounter.patientPaymentProgram[0].patientPaymentProgramId,
      areDocsSigned: selectedEncounter.patientPaymentProgram[0].areDocsSigned,
      downPmtAmt: selectedEncounter.patientPaymentProgram[0].downPmtAmt,
      isACH: selectedEncounter.patientPaymentProgram[0].isACH,
      isHighRisk: selectedEncounter.patientPaymentProgram[0].isHighRisk,
      highRiskApprovalDt: currentDate.toISOString(),
      isPaidInFull: selectedEncounter.patientPaymentProgram[0].isPaidInFull, // TODO - remove; DEPRECATED
      patientPaymentSchedule: patientPaymentSchedule

    } as PatientPaymentProgram


    dispatch(createPatientNote({message: createCommentSuccesMessages.comment, comment: formValues}))
    await dispatch(savePatientPaymentProgram(
      {
        paymentProgram: paymentProgram,
        patientId: selectedPatient.patientId,
        encounterId: selectedEncounter.patientEncounterId
      }
    ))

    handleModalClose();
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    handleModalClose()
  }

  const initialValues = {
    patientId:selectedPatient?.patientId,
    patientEncounterId: selectedPatient?.patientEncounterId,
    noteText:"",
    completionDueDt: "",
    noteDt:"",
    isReminder: false
  };

  const validationSchema = Yup.object({
    noteText: Yup.string().required("Required")
  });


  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal list-header"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <>
          <span className="title">High Risk Approval</span>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={8} className="mt-2">
              <Typography variant="subtitle2">
                Patient Financial Responsibility
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end" className="pr-1 mt-2">
                <Typography variant="subtitle2">
                  {formatNumberToUSD(selectedEncounter.pfrAmt)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid item xs={12} className="pt-3">
                <Typography variant="subtitle2">
                  Payments
                </Typography>
              </Grid>
              <Grid container spacing={2} className="header mt-6 px-4 mb-1 pr-3">
                <Grid item xs={2}>
                  <Typography variant="h4">
                    Terms
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="h4">
                    Down Payment
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    Per Month
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="py-3 px-4">
                <Grid item xs={2}>
                  <Typography variant="body1">
                    {paymentProgram?.patientPaymentSchedule?.terms}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {formatNumberToUSD(paymentProgram?.downPmtAmt)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                  {formatNumberToUSD(paymentProgram?.patientPaymentSchedule?.paymentDueAmt)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} className="pt-8">
                  <Typography variant="subtitle2">
                    Comments
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={
                      formik.touched["noteText"] && formik.errors["noteText"]
                    }
                    name="noteText"
                    multiline
                    value={formik.values.noteText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
    </Dialog>
  );
}
