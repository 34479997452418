import '../../../../../scss/pages/admin/_rules-engine.scss';

import { Checkbox, saveCallbackStatus } from '@finpay-development/shared-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from '../../../../../security/model/role-page-names';
import { showStatus } from '../../../../../security/state/user-slice';
import { CustomFormikProps } from '../../../../../shared/model/formik-props';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { setIsPaymentFreqComplete, setIsRiskRulesComplete } from '../../../../state/rules-engine/rules-engine-slice';
import { getPaymentFrequency } from '../../../../state/rules-engine/rules-engine-thunk';
import { emptyImplementationFacility, ImplementationFacility } from '../models/implementation-facility';
import EditPaymentFrequencyModal from './edit-payment-frequency-modal';
import RiskRulesAccordion from './risk-rules-accordion';
import { RulesModal } from './rules-modal';
import VobRulesAccordion from './vob-rules-accordion';


function RulesEngineTab() {
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
  const [isShowEditPaymentFrequencyModalOpen, setIsShowEditPaymentFrequencyModalOpen] = useState(false);
  const [paymentFrequencyToEdit, setPaymentFrequencyToEdit] = useState<{[key: string]: boolean}>({"": false});
  const [facilityToEdit, setFacilityToEdit] = useState<ImplementationFacility>(emptyImplementationFacility);

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    clientId: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.client.clientId
    ),
    facilities: useSelector(
      (state: RootState) => state.implementationContext.implementationRulesEngineContext.facilities
    ),

    isPmtFreqComplete: useSelector(
      (state: RootState) => state.implementationContext.implementationRulesEngineContext.isPaymentFreqComplete
    ),
    isRiskRulesComplete: useSelector(
      (state: RootState) => state.implementationContext.implementationRulesEngineContext.isRiskRulesComplete
    ),

    saveStatus: useSelector(
      (state: RootState) => state.implementationContext.implementationRulesEngineContext.apiStatus
    )
  }

  const {clientId, facilities, isPmtFreqComplete, isRiskRulesComplete, saveStatus} = stateFields;


  if (facilities.length > 0) {
    const isPaymentProgramsOfferedComplete = facilities.every((facility) => !!facility.patientPmtFreq);
    dispatch(setIsPaymentFreqComplete(isPaymentProgramsOfferedComplete));
  }

  useEffect(() => {
      if (clientId) dispatch(getPaymentFrequency(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    dispatch(setIsRiskRulesComplete(facilities));
  }, [dispatch, facilities])

  const formRef: any = useRef();

  const handleFormChange = (values: {}) => {}

  const setRuleModal = (booleanVal: boolean) => (
    setIsRuleModalOpen(booleanVal)
  );

  const handleRuleModalCancel = () => (
    setIsRuleModalOpen(false)
  );

  const handlePaymentFrequencyClose = () => (
    setIsShowEditPaymentFrequencyModalOpen(false)
  )

  const handleRuleModalSubmit = (isEditMode: boolean) => {
    if(saveStatus === saveCallbackStatus.success){
      if (isEditMode) {
        dispatch(showStatus("Rule Updated Successfully"));
      } else {
        dispatch(showStatus("Rule Added Successfully"));
      }
    }
    setIsRuleModalOpen(false)
  };

  const handlePaymentFrequencyEdit = (index: number, formik: CustomFormikProps) => {

    const frequency = {
      F: formik.values.paymentFrequencyGroup?.[index].F,
      M: formik.values.paymentFrequencyGroup?.[index].M,
      W: formik.values.paymentFrequencyGroup?.[index].W,
      E: formik.values.paymentFrequencyGroup?.[index].E
    }
    setPaymentFrequencyToEdit(frequency);
    setFacilityToEdit(formik.values.paymentFrequencyGroup?.[index].facility);
    setIsShowEditPaymentFrequencyModalOpen(true);
  }

  const initialValues = {
    paymentFrequencyGroup: facilities.map((facility) => {
      return {
        facility: facility, // Not a form control
        F: facility.patientPmtFreq?.includes("F"),
        M: facility.patientPmtFreq?.includes("M"),
        W: facility.patientPmtFreq?.includes("W"),
        E: facility.patientPmtFreq?.includes("E")
      };
    }),
  }

  const accordionHeader = (accordionHeaderText: string, isComplete: boolean) => (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon className="primary-color" />}
      id="payment-programs-offered"
    >
      <Grid container spacing={1}>
        <Grid item>
          {isComplete && <CheckCircleOutlineIcon className="complete-icon"/>}
          {!isComplete && <RadioButtonUncheckedIcon className="incomplete-icon" />}
        </Grid>
        <Grid item>
          <Typography variant="h3" className="pt-0_5">
            {accordionHeaderText}
          </Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
  )

  const accordionDetails = (
    <AccordionDetails>
      <Typography variant="h3">
        Payment Frequency
      </Typography>
    </AccordionDetails>
  )


  return <>
    {facilities.length > 0 &&
    <>
    <Accordion
      defaultExpanded={!isRiskRulesComplete}
      className="px-0_5 py-0_5 rules-engine"
    >
      {accordionHeader("Payment Programs Offered", isPmtFreqComplete)}
      {accordionDetails}
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validate={handleFormChange}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        {formik => (
          <Form>
            <FieldArray name="paymentFrequencyGroup">
              {() => (
                <>
                {formik.values.paymentFrequencyGroup?.map((item, i: number) => (
                  <React.Fragment key={i}>
                  <Accordion elevation={0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} id="facility-details">
                    <Typography variant="body1">
                      {formik.values.paymentFrequencyGroup[i].facility.facilityName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container direction="row" justifyContent="space-between">
                      <Grid item>
                          {/*
                          F = Full Payment
                          M = Monthly Payment Program
                          W = Weekly Payment Program
                          E = Exception Payment Program
                          */}
                        <Checkbox
                          id={`1.${i}.F`}
                          disabled
                          name={`paymentFrequencyGroup.${i}.F`}
                          label="Full Payment"
                          checked={formik.values.paymentFrequencyGroup?.[i].F}
                          onChange={formik.handleChange}
                          />
                        <Checkbox
                          id={`2.${i}.M`}
                          disabled
                          name={`paymentFrequencyGroup.${i}.M`}
                          label="Monthly Payment Program"
                          checked={formik.values.paymentFrequencyGroup?.[i].M}
                          onChange={formik.handleChange}
                          />
                          <Checkbox
                          id={`3.${i}.W`}
                          disabled
                          name={`paymentFrequencyGroup.${i}.W`}
                          label="Weekly Payment Program"
                          checked={formik.values.paymentFrequencyGroup?.[i].W}
                          onChange={formik.handleChange}
                          />
                        <Checkbox
                          id={`4.${i}.E`}
                          disabled
                          name={`paymentFrequencyGroup.${i}.E`}
                          label="Exception Payment Program"
                          checked={formik.values.paymentFrequencyGroup?.[i].E}
                          onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item>
                          <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
                            renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
                            <IconButton
                              color="primary"
                              onClick={() => handlePaymentFrequencyEdit(i, formik)}
                              className="edit-button"
                              size="large"> <EditIcon /></IconButton>
                          </AccessControl>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                  </Accordion>
                  </React.Fragment>
                ))}
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Accordion>
    <Accordion
      defaultExpanded={!isRiskRulesComplete}
      className="px-0_5 py-0_5 rules-engine"
    >
      {accordionHeader("Risk Rules", isRiskRulesComplete)}
      {facilities.map((facility) => (
        <RiskRulesAccordion
          key={facility.facilityId}
          facility={facility}
          setRuleModal={setRuleModal}
        />)
      )}
    </Accordion>
      <Accordion
          defaultExpanded={!isRiskRulesComplete}
          className="px-0_5 py-0_5 rules-engine"
      >
        {accordionHeader("Outpatient Thresholds", isRiskRulesComplete)}
        {facilities.map((facility) => (
            <VobRulesAccordion
                key={facility.facilityId}
                facility={facility}
            />
            )
        )}
      </Accordion>
    </>}
    {isRuleModalOpen && (
      <RulesModal
        open={isRuleModalOpen}
        handleRuleModalCancel={handleRuleModalCancel}
        handleRuleModalSubmit={handleRuleModalSubmit}
      />
    )}
    {isShowEditPaymentFrequencyModalOpen && (
      <EditPaymentFrequencyModal
        open={isShowEditPaymentFrequencyModalOpen}
        frequency={paymentFrequencyToEdit}
        facility={facilityToEdit}
        handlePaymentFrequencyClose={handlePaymentFrequencyClose}
      />
    )}
  </>;
}

export default RulesEngineTab;
