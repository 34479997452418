import { Box } from "@mui/material";
import { useSelector } from 'react-redux';
import { RootState } from '../../shared/state/root-reducer';
import { Navigation } from "../../security/model/navigation";
import  DesktopHeader from "./desktop-header";
import  MobileHeader from "./mobile-header";
import  MobilePaymentsHeader from "./mobile-payments-header";

interface HeaderProps {
  showLinks?: boolean;
  subNav?: Navigation[];
}

function FppHeader(props: HeaderProps) {

  const stateFields = {
    channelType: useSelector(
      (state: RootState) => state.guestContext.channelType
    )
  };
  const { channelType } = stateFields;

  return (
    <>   
    <Box display={{ xs: 'none', md: 'block' }}>
        <DesktopHeader {...props}/>
    </Box>
    <Box display={{ xs: 'block', md: 'none' }}>
      { channelType === 'mobile' ? 
        <MobilePaymentsHeader /> 
      : 
        <MobileHeader {...props}/> 
      }
    </Box>
    </>
  );
}

export default FppHeader;
