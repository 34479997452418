import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TokenState } from '../../stripe/models/stripe-token-state';
import { emptyGuestModel, GuestModel, PaymentInfo, ChannelInfo, ClientFacilities } from '../models/anonymous-guest';
import { emptyErrorState, ErrorState } from '../models/error-state';
import { emptyLoadingState, LoadingState } from '../models/loading-state';
import { PayNowMakePaymentRequest, PayNowMakePaymentResponse } from '../models/pay-now-make-payment';
import {
  checkEmail,
  getAnonymousRequestToken,
  getConfig,
  getEmailLookupAuthToken,
  getLaunchPayload,
  getMakePaymentAuthToken,
  submitPayment,
  getPaymentChannels,
  getClientFacility,
  getClientFacilities
} from './guest-thunk';
import {PatientInfo} from '../components/mobile-payment-view';
import {Tokens} from '../../security/services/SSO';
import { UserInfoFacility } from '../../admin/models/user-info-facility';

export interface GuestState {
    selectedGuest: GuestModel,
    paymentInfo: PaymentInfo,
    saveGuestModalSaveStatus: saveCallbackStatus,
    guestTakePaymentTokens: TokenState,
    clientId: string,
    configId: string,
    configIdSource: string,
    isConfigLoadComplete: boolean,
    bannerText: string,
    accountLabel: string,
    configHelpText: string,
    launchToken: string,
    authToken: string,
    paymentAuthToken: string,
    paymentAuthTokens: Tokens,
    isExistingPatient: boolean | undefined,
    emailLookupAuthToken: string,
    isPayNowOverviewActive: boolean,
    paymentRequest: PayNowMakePaymentRequest | undefined,
    isPaymentSuccessful: boolean,
    paymentProcessingError: string | undefined,
    clientFacilityDetails: ClientFacilities,
    mobilePayment: {
      patientInfo : PatientInfo
    }
    // common state for most/all slices
    errorMessage: string,
    isLoading: LoadingState,
    isError: ErrorState,
    isInvalidForPayNow: boolean,
    isChannel: boolean;
    channelType: string;
    paymentChannels: ChannelInfo[];
    isMobileConverted: boolean;
    clientFacilites: UserInfoFacility[];
    submittedPaymentResponse: PayNowMakePaymentResponse;
}

const initialState = {
    selectedGuest: emptyGuestModel,
    saveGuestModalSaveStatus: saveCallbackStatus.none,
    clientId: '',
    configId: '',
    configIdSource: 'finpay.client_facilities',
    bannerText: '',
    accountLabel: '',
    configHelpText: '',
    paymentRequest: undefined,
    isLoading: emptyLoadingState,
    isPaymentSuccessful: false,
    paymentProcessingError: undefined,
    isError: emptyErrorState,
    errorMessage: '',
    launchToken: '',
    authToken: '',
    paymentAuthToken: '',
    paymentAuthTokens: {},
    isExistingPatient: undefined,
    isConfigLoadComplete: false,
    emailLookupAuthToken: '',
    isPayNowOverviewActive: false,
    isInvalidForPayNow: false,
    isChannel: false,
    channelType: 'webpayments',
    paymentChannels: [{}],
    clientFacilityDetails: {},
    mobilePayment: {
      patientInfo:{}
    },
    isMobileConverted: false,
} as GuestState;

// Slice
export const guestContextSlice = createSlice({
    name: "guestContext",
    initialState,
    reducers: {
      clearStatus: (state) => {
        state.saveGuestModalSaveStatus = saveCallbackStatus.none;
        state.errorMessage = "";
      },
      setSelectedGuest: (state: { selectedGuest: any; }, action: { payload: any; }) => {
        state.selectedGuest = action.payload;
      },
      setPaymentInfo: (state: { paymentInfo: any; }, action: { payload: any; }) => {
        state.paymentInfo = action.payload;
      },
      resetGuestContext() {
        return initialState;
      },
      setGuestTakePaymentToken(state, action: PayloadAction<TokenState>) {
        state.guestTakePaymentTokens = action.payload;
      },
      setLaunchToken(state, action: PayloadAction<string> ) {
        state.launchToken = action.payload;
      },
      setConfigId(state, action) {
        state.configId = action.payload;
      },
      setIsPayNowOverviewActive(state, action) {
        state.isPayNowOverviewActive = action.payload;
      },
      setAuthToken(state, action) {
        state.authToken = action.payload;
      },
      setPaymentAuthToken(state, action) {
        state.paymentAuthToken = action.payload;
      },
      setEmailLookupAuthToken(state, action) {
        state.emailLookupAuthToken = action.payload;
      },
      setIsAuthTokenLoading(state, action) {
        state.isLoading.getAuthToken = action.payload;
      },
      setPaymentRequest(state, action) {
        state.paymentRequest = action.payload;
      },
      setPaymentIsProcessing(state, action) {
        state.isLoading.paymentProcessing = action.payload;
      },
      setIsExistingPatient(state, action) {
        state.isExistingPatient = action.payload;
      },
      setIsInvalidForPayNow(state, action) {
        state.isInvalidForPayNow = action.payload;
      },
      setIsChannel(state, action) {
        state.isChannel = action.payload;
      },
      setChannelType(state, action) {
        state.channelType = action.payload;
      },
      setPaymentChannels(state, action) {
        state.paymentChannels = action.payload;
      },
      setPatientInfo(state, action){
        state.mobilePayment.patientInfo = action.payload
      },
      setIsMobileConverted(state, action) {
        state.isMobileConverted = action.payload;
      },
      setClientFacilityDetails(state, action) {
        state.clientFacilityDetails = action.payload;
      },
    },
    extraReducers: (builder) => {
      // config
      builder.addCase(getConfig.pending, (state, action) => {
        state.isLoading.getConfig = true;
        state.isError.getConfig = false;
      });
      builder.addCase(getConfig.fulfilled, (state, action) => {
        switch(action.payload?.configKey) {
          case "BANNER_TEXT":
            state.bannerText = action.payload.configValue;
            break;
          case "ACCT_ID":
            state.accountLabel = action.payload.configValue;
            break;
          case "ACCT_HELP_TEXT":
              state.configHelpText = action.payload.configValue;
              break;
          default:
        }
        if (state.bannerText && state.accountLabel && state.configHelpText) {
          state.isConfigLoadComplete = true;
          state.isError.getConfig = false;
        }
        state.isLoading.getConfig = false;
      });
      builder.addCase(getConfig.rejected, (state, action) => {
        state.isLoading.getConfig = false;
        state.isError.getConfig = true;
        state.isInvalidForPayNow = true;
      });
      // anonymous token
      builder.addCase(getAnonymousRequestToken.pending, (state, action) => {
        state.isLoading.getAuthToken = true;
        state.isError.getAuthToken = false;
      });
      builder.addCase(getAnonymousRequestToken.fulfilled, (state, action) => {
        state.isLoading.getAuthToken = false;
        state.isError.getAuthToken = false;
        state.authToken = action.payload.token;
      });
      builder.addCase(getAnonymousRequestToken.rejected, (state, action) => {
        state.isLoading.getAuthToken = false;
        state.isError.getAuthToken = true;
        state.authToken = "";
      });

      builder.addCase(getMakePaymentAuthToken.pending, (state, action) => {
        state.isLoading.getAuthToken = true;
      });
      builder.addCase(getMakePaymentAuthToken.fulfilled, (state, action) => {
        state.isLoading.getAuthToken = false;
        state.paymentAuthToken = action.payload.token;
        state.paymentAuthTokens = action.payload;
      });
      builder.addCase(getMakePaymentAuthToken.rejected, (state, action) => {
        state.isLoading.getAuthToken = false;
        state.paymentAuthToken = "";
      });

      builder.addCase(getEmailLookupAuthToken.pending, (state, action) => {
        state.isLoading.getEmailLookupAuthToken = true;
        state.isError.getEmailLookupAuthToken = false;
      });
      builder.addCase(getEmailLookupAuthToken.fulfilled, (state, action) => {
        state.isLoading.getEmailLookupAuthToken = false;
        state.isError.getEmailLookupAuthToken = false;
        state.emailLookupAuthToken = action.payload.token;
      });
      builder.addCase(getEmailLookupAuthToken.rejected, (state, action) => {
        state.isLoading.getEmailLookupAuthToken = false;
        state.isError.getEmailLookupAuthToken = true;
        state.emailLookupAuthToken = "";
      });

      builder.addCase(submitPayment.pending, (state, action) => {
        state.isLoading.paymentProcessing = true;
        state.isError.paymentProcessing = false;
      });
      builder.addCase(submitPayment.fulfilled, (state, action) => {
        state.submittedPaymentResponse = action.payload;
        state.isLoading.paymentProcessing = false;
        state.isError.paymentProcessing = false;
        state.isPaymentSuccessful = action.payload ?? false;
        state.paymentRequest = undefined;
        state.paymentAuthToken = '';

      });
      builder.addCase(submitPayment.rejected, (state, action) => {
        state.isLoading.paymentProcessing = false;
        state.isError.paymentProcessing = true;
        state.paymentRequest = undefined;
        state.paymentAuthToken = '';
      });
      // check email
      builder.addCase(checkEmail.pending, (state, action) => {
        state.isLoading.checkEmailProcessing = true;
        state.isError.checkEmailProcessing = false;
      });
      builder.addCase(checkEmail.fulfilled, (state, action) => {
        state.isLoading.checkEmailProcessing = false;
        state.isError.checkEmailProcessing = false;
        const numPatientsFound = action.payload;

        state.isExistingPatient = numPatientsFound?.length > 0;
      });
      builder.addCase(checkEmail.rejected, (state, action) => {
        state.isLoading.checkEmailProcessing = false;
        state.isError.checkEmailProcessing = true;
        state.isExistingPatient = false;
      });

      builder.addCase(getLaunchPayload.pending, (state, action) => {
        state.isLoading.launchPayloadProcessing = true;
        state.isError.launchPayloadProcessing = false;
      });
      builder.addCase(getLaunchPayload.fulfilled, (state, action) => {
        state.isLoading.launchPayloadProcessing = false;
        state.configId = action.payload?.facilityId || action.payload?.patientEncounterId || "";
        state.isError.launchPayloadProcessing = action.payload?.isProcessingError ?? false;
        state.isInvalidForPayNow = action.payload?.isInvalidForPayNow ?? false;

      });
      builder.addCase(getLaunchPayload.rejected, (state, action) => {
        state.isLoading.launchPayloadProcessing = false;
        state.isError.launchPayloadProcessing = true;
        state.isInvalidForPayNow = true;
      });

      // get channel info
      builder.addCase(getPaymentChannels.pending, (state, action) => {
        state.isLoading.getPaymentChannels = true;
        state.isError.getPaymentChannels = false;
      });
      builder.addCase(getPaymentChannels.fulfilled, (state, action) => {
        state.isLoading.getPaymentChannels = false;
        state.paymentChannels = action.payload;
        state.isError.getPaymentChannels = false;
        state.authToken = action.payload.token;
      });
      builder.addCase(getPaymentChannels.rejected, (state, action) => {
        state.isLoading.getPaymentChannels = false;
        state.isError.getPaymentChannels = true;
        state.paymentChannels = [];
      });
      builder.addCase(getClientFacility.fulfilled, (state, action) => {
        state.clientFacilityDetails = action.payload
      });
      builder.addCase(getClientFacility.pending, (state, action) => {
        state.isLoading.getClientFacility = true;

      });
      builder.addCase(getClientFacility.rejected, (state, action) => {
        state.isError.getPaymentChannels = true;
      });

      builder.addCase(getClientFacilities.fulfilled, (state, action) => {
        state.clientFacilites = action.payload
      });
      builder.addCase(getClientFacilities.pending, (state, action) => {
        state.isLoading.getClientFacilities = true;
      });
      builder.addCase(getClientFacilities.rejected, (state, action) => {
        state.isError.getClientFacilities = true;
      });
  }
})

export const {
    clearStatus,
    setSelectedGuest,
    setPaymentInfo,
    setGuestTakePaymentToken,
    setLaunchToken,
    setPaymentRequest,
    setIsExistingPatient,
    setEmailLookupAuthToken,
    setIsPayNowOverviewActive,
    setIsInvalidForPayNow,
    setIsChannel,
    setChannelType,
    setPaymentIsProcessing,
    setPatientInfo,
    setIsMobileConverted,
    setClientFacilityDetails,
    setAuthToken,
} = guestContextSlice.actions;

export default guestContextSlice.reducer;
