import React from "react";
import { Grid, Typography } from "@mui/material";
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state/root-reducer";
import { Link } from "react-router-dom";
import { Navigation } from "../../security/model/navigation";
import { logUserOut } from "../../security/state/user-thunk";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import logo from "./../../assets/images/logo.png";
import HeaderStatus from "./header-status";
import { changeStatus } from "../../security/state/user-slice";
import { CookieExpiration } from "../enums";
import Cookies from "js-cookie";
import { AppDispatch } from "../state/store";

interface HeaderProps {
  showLinks?: boolean;
  subNav?: Navigation[];
}

function DesktopHeader(props: HeaderProps) {
  const { showLinks = true } = props;
  const [open] = React.useState(false);
  const [openStatus, setOpenStatus] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const anchorStatusRef: any = React.useRef(null);

  const isUserActive = useSelector(
    (state: RootState) => state.userContext.isActive
  )

  const isNotPatient = useSelector((state: RootState) => !state.userContext.userProfile.isPatient)

  const handleStatusToggle = () => {
    setOpenStatus((prevOpenStatus) => !prevOpenStatus);
  };

  const handleStatusClose = (event: any) => {
    if (anchorStatusRef.current?.contains(event.target)) {
      return;
    }
    setOpenStatus(false)
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpenStatus = React.useRef(openStatus);
  React.useEffect(() => {
    if (prevOpenStatus.current === true && openStatus === false) {
      anchorStatusRef.current.focus();
    }
    prevOpenStatus.current = openStatus;
  }, [openStatus]);

  const dispatch = useDispatch<AppDispatch>();
  function handleLogout() {
    dispatch(logUserOut());
  }

  const handleChangeStatus = ()=> {
    const userStatus = Cookies.getJSON('userStatus')
    Cookies.set("userStatus", {isActive:!userStatus.isActive}, { expires: CookieExpiration.days })
    dispatch(changeStatus())
  }

  return (
    <header>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="px-4"
      >
        <Grid item className="logo">
          <img src={logo} alt="logo" />
        </Grid>
        {showLinks && (
          <Grid item>
            <nav className="header-nav">
              <Typography variant="body1">
                <>

                  {
                    isNotPatient && (
                    <span className="visually-hidden">
                      <a
                          href="https://sites.google.com/finpay.net/training-center/home"
                        title="Help Desk"
                        target="_blank"
                        rel="noopener noreferrer">
                        FinTraining
                      </a>
                    </span>
                )}

                  <span
                    ref={anchorStatusRef}
                    aria-controls={openStatus ? 'menu-list-status-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleStatusToggle}
                    className="link light"
                  >
                    <FiberManualRecordIcon color="secondary" className="pt-3" fontSize="small"></FiberManualRecordIcon>
                    <HeaderStatus isActive={isUserActive}></HeaderStatus>
                  </span>

                  <Popper open={openStatus} anchorEl={anchorStatusRef.current} placement="bottom-end"  transition disablePortal>
                      {({ TransitionProps}) => (
                        <Grow
                          {...TransitionProps}
                        >
                        <Paper>
                          <ClickAwayListener onClickAway={handleStatusClose}>
                            <MenuList autoFocusItem={openStatus} id="menu-list-status-grow">
                              <MenuItem className="primary" onClick={handleStatusClose}>
                                <span className="pl-0 menu-item" onClick={handleChangeStatus}>
                                  {isUserActive ? "Away" : "Active"}
                                </span>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </>

                <Link to="" onClick={handleLogout}>
                  Log Out
                </Link>
              </Typography>
            </nav>
          </Grid>
        )}
      </Grid>
    </header>
  );
}

export default DesktopHeader;
