import { Button } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HeaderWithDetails from '../../../../../shared/components/header-with-details';
import FppModal from '../../../../../shared/components/modal';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import { FacilityDetails } from '../../../../models/facility-details';
import { FacilityForm } from '../../../../models/facility-form';
import { getEncryptFacilityPayload } from '../../../../state/facility/implementation-facility-thunk';

interface FacilityDetailProps {
  open: boolean;
  handleModalClose: () => void;
}

export function FacilityDetailsModal(props: FacilityDetailProps) {
  const { open, handleModalClose } = props;

  const dispatch = useDispatch<AppDispatch>();

  const facility: FacilityDetails = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationFacility.currentFacilityDetails
  );

  const facilityForm: Partial<FacilityForm> = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationFacility.currentFacilityForm
  );

  const paynowURL: string = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationFacility.paynowURL
  );

  const isGettingEncryptedFacId: boolean = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationFacility.isGettingEncryptedFacId
  );

  useEffect(() => {  
    const facilityId = facility.facilityId;
    const paramString = "facId=" + facilityId;
    const getPayNowURL = async () => {      
      dispatch(getEncryptFacilityPayload(paramString));
    }
 
    if (facilityId > 0  && !isGettingEncryptedFacId) {
      getPayNowURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facility?.facilityId
  ]);
 

  const sectionHeader = (outputString: string) => (
    <Grid item xs={12}>
      <Typography variant="h4" className="mb-2">
        {outputString}
      </Typography>
    </Grid>
  );

  const isStripeSetupInfoExists = (): boolean => {
    return (
      !!facility.accountNumber ||
      !!facility.routingNumber ||
      !!facility.bankName ||
      !!facility.externalVerifyStatus ||
      !!facility.dob ||
      !!facility.ssn
    );
  };

  return (
    <FppModal
      title="Details"
      isOpen={open}
      buttons={
        <Button type="secondary" onClick={handleModalClose}>
          Done
        </Button>
      }
    >

      <Typography variant="subtitle2">Outpatient Facility</Typography>
      <Typography variant="h4" className="mb-4">
        {Utils.convertBoolToYesNo(facility.outpatientEnabled || false)}
      </Typography>
      <Typography variant="subtitle2">Facility Address</Typography>
      <Typography variant="h4" className="mb-0">
        {facility.streetAddress1}
      </Typography>
      <Typography variant="h4">{`${facility.city}, ${facility.state}  ${facility.zipCode}`}</Typography>

      <Grid container className="mt-4">
        <HeaderWithDetails
          header="Website"
          details={facility.website}
          width="half"
        />
        <HeaderWithDetails
          header="Phone"
          details={facility.phone}
          width="half"
        />
      </Grid>

      <Typography variant="subtitle2" className="mt-4">
        Contact
      </Typography>
      <Grid container>
        <HeaderWithDetails
          header="Name"
          details={`${facility.primaryContactFirstName} ${facility.primaryContactLastName}`}
          width="half"
        />

        {facility.primaryContactEmail && (
          <HeaderWithDetails
            header="Email"
            details={facility.primaryContactEmail}
            width="half"
          />
        )}
      </Grid>

      {facility.primaryContactAddress1 && (
        <>
          <Typography variant="subtitle2" className="mb-0">
            {facility.primaryContactAddress1}
          </Typography>
          <Typography variant="subtitle2">{`${facility.primaryContactCity}, ${facility.primaryContactState}  ${facility.primaryContactZip}`}</Typography>
        </>
      )}

      <Grid container>
        {facility.primaryContactPhone && (
          <HeaderWithDetails
            header="Phone"
            details={facility.primaryContactPhone}
            width="half"
          />
        )}

        {facility.primaryContactMobilePhone && (
          <HeaderWithDetails
            header="Mobile"
            details={facility.primaryContactMobilePhone}
            width="half"
          />
        )}
      </Grid>

      <Typography variant="subtitle2" className="mt-4">
        Payments
      </Typography>
      {isStripeSetupInfoExists() && sectionHeader("Pay To Account")}
      <Grid container>
        {facility.ssn && (
          <HeaderWithDetails
            header="Social Security Number"
            details={`●●●-●●-${facility.ssn}`}
            width="half"
          />
        )}

        {facility.dob && (
          <HeaderWithDetails
            header="Date of Birth"
            details={facility.dob}
            width="half"
          />
        )}

        {facility.externalVerifyStatus && (
          <HeaderWithDetails
            header="Identification Documentation"
            details={facility.externalVerifyStatus}
            width="full"
          />
        )}

        {facility.bankName && (
          <HeaderWithDetails
            header="Bank Name"
            details={facility.bankName}
            width="half"
          />
        )}
      </Grid>

      <Grid container>
        {facility.routingNumber && (
          <HeaderWithDetails
            header="Routing Number"
            details={`●●●●●●${facility.routingNumber.slice(-4)}`}
            width="half"
          />
        )}

        {facility.accountNumber && (
          <HeaderWithDetails
            header="Account Number"
            details={`●●●●●●●●${facility.accountNumber.slice(-4)}`}
            width="half"
          />
        )}
      </Grid>

      {sectionHeader("Pay from Account")}
      <Grid container>
        {facility.externalAccount && (
          <HeaderWithDetails
            header="Connect Account"
            details={facility.externalAccount}
            width="half"
          />
        )}

        {facility.customDocTokenId && (
          <HeaderWithDetails
            header="Compliance Document Token ID"
            details={facility.customDocTokenId}
            width="half"
          />
        )}
      </Grid>

      <Typography variant="subtitle2" className="mt-4">
        Web Payments
      </Typography>
      <Grid container>
        <HeaderWithDetails 
          header="Connect Account"
            details={facility.payNowExternalAccount}
            width="half"      
        />        
      </Grid>
      <HeaderWithDetails 
          header="Web Payments URL"
            details={paynowURL}
            width="full"      
        />
      <HeaderWithDetails 
        header="Welcome Banner Text"
          details={facilityForm.bannerText}
          width="full"      
      />
      <HeaderWithDetails 
        header="Reference Number Name"
          details={facilityForm.referenceNumberName}
          width="half"      
      />
      <HeaderWithDetails 
        header="Reference Number Help Text"
          details={facilityForm.referenceNumberHelpText}
          width="full"      
      />
      

    </FppModal>
  );
}


