export class RolePageActionState {
    actionKey!: string;
    isEnabled!: boolean;
  }

export class RolePageState {
    sysRoleKey!: string;
    isEnabled!: boolean;
    isReadOnly!: boolean;
    isEditable!: boolean;
    parent!: string;
    actions!: RolePageActionState[];
  }

  export const emptyPageState = {
    sysRoleKey: "",
    isEnabled: false,
    isReadOnly: false,
    isEditable: false,
    parent: "",
    actions: []
  }