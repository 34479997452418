import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { payorRisk, timingRisk } from "../../../shared/model/timing-and-payor-risk";

function RiskClasses() {
  return (
    <div className="risk-classes">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="accordion-entity">
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1" className="mb-1">
                Payer Risk
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" className="striped-row-container">
            {payorRisk.map((risk, index) => (
              <Grid key={index} item>
                <Typography key={risk.payorRiskId} variant="subtitle2">
                  {risk.payorRiskName}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="accordion-entity">
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle1" className="mb-1">
                Timing Risk
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column" className="striped-row-container">
            {timingRisk.map((risk, index) => (
              <Grid key={index} item>
                <Typography key={risk.timingRiskId} variant="subtitle2">
                  {risk.timingRiskName}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default RiskClasses;
