import React, {useEffect, useState} from 'react';
import {
  getAnonymousRequestToken, getLaunchPayload,
} from '../../state/guest-thunk';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../shared/state/store';
import {useParams} from 'react-router';
import {paynowService} from '../../services/paynow-service';
import {
  emptyPatientEncounter, PatientEncounter,
} from '../../../patient/components/models/patient-encounter';
import {Snackbar} from '@finpay-development/shared-components';
import {
  CircularProgress,
  Grid,
} from '@mui/material';
import {
  ApplicationStatusState,
  emptyApplicationStatus, GetError,
} from '../../../security/model/application-status';
import {
  PatientPaymentSchedule,
} from '../../../patient/components/models/patient-payment.schedule';
import {logUserOut} from '../../../security/state/user-thunk';
import {GuestToken} from '../../../security/services/SSO';
import ErrorMsg from '../../../shared/components/finmobile-error-msg';
import {EnrollmentApprovalView} from './enrollment-approval-view';

export interface AuthorizationDocumentStatus {
  authorizationDocumentStatusId: number,
  patientId: number,
  patientChampionId: number | null,
  documentTypeId: number,
  documentStatus: 'created' | 'sent' | 'signed',
  externalSignatureRequestId: string,
  wasPfrAdjustmentSent: null,
  authorizationDocuments: {
    typeOfDoc: 'inst' | 'spa'
  }
}

export const PaymentProgramEnrollmentPage = () => {

  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{ token?: string }>();

  const [patientEncounter, setPatientEncounter] = useState<PatientEncounter>(
      emptyPatientEncounter);
  const [msg, setMsg] = useState<ApplicationStatusState>(
      emptyApplicationStatus);
  const [guestToken, setGuestToken] = useState<GuestToken | null>(null);
  const [authDocStatus, setAuthDocStatus] = useState<AuthorizationDocumentStatus | null>(
      null);
  const [isAuthDocAlreadyApproved, setIsAuthDocAlreadyApproved] = useState(
      false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {

    const getTokenAndPatientEncounter = async () => {

      const tokenResponse = await dispatch(getAnonymousRequestToken());

      const launchPayloadResponse: any = await dispatch(getLaunchPayload(({
        'encryptedValue': params.token as string,
        'authToken': tokenResponse?.payload?.token,
      })));

      if (launchPayloadResponse.payload?.isProcessingError) {
        throw Error('Error decrypting patient encounter id');
      }

      setGuestToken(tokenResponse?.payload);

      const patientEncounterResponse = await paynowService.getPatientEncounter(
          +launchPayloadResponse?.payload?.patientEncounterId,
          tokenResponse?.payload?.token,
          {cacheMode: 'no-cache'}
      );

      const typeOfDoc = patientEncounterResponse?.patientPaymentProgram?.[0]?.patientPaymentSchedule?.paymentFreq ===
      'F' ? 'spa' : 'inst';

      const selectedAuthDoc = patientEncounterResponse.authorizationDocumentStatus.find(
          (authDoc: AuthorizationDocumentStatus) => {
            return authDoc.authorizationDocuments.typeOfDoc ===
                typeOfDoc && authDoc.documentStatus !== 'signed';
          });

      if (!selectedAuthDoc) {
        setIsAuthDocAlreadyApproved(true);
        return;
      }
      checkPaymentProgramData(
          patientEncounterResponse.patientPaymentProgram[0].patientPaymentSchedule);

      setPatientEncounter(patientEncounterResponse);
      setAuthDocStatus(selectedAuthDoc);
    };

    /**
     * A temporary measure to log out user session before loading the page.
     * If a patient portal session exists, it interferes with the loading of all guest pages.
     * */
    dispatch(logUserOut());

    getTokenAndPatientEncounter().then(() => {
      setIsPageLoading(false);
    }).catch((err) => {
      console.error(err);
      setMsg(GetError(GENERIC_ERROR_MSG));
    });

  }, []);

  return (
      <Grid
          container
          justifyContent="center"
          sx={{width: '100vw'}}
      >
        {(()=>{

          switch (true) {

            case isPageLoading:
              return (
                  <Grid
                      item
                      container
                      justifyContent="center"
                  >
                    <Grid item sx={{marginTop: '3rem'}}>
                      <CircularProgress/>
                    </Grid>
                  </Grid>
              );

            case isAuthDocAlreadyApproved:
              return (
                  <Grid>
                    <ErrorMsg
                        errorStatus={true}
                        errorMessageHeader={''}
                        errorMessageContent={(
                            <>
                              This payment program has already been approved.
                              <br/>
                              For further support, please contact FinPay at
                              support@finpay.com.
                            </>
                        )}
                    />
                  </Grid>
              );

            case !!patientEncounter:
              return (
                  <EnrollmentApprovalView
                      patientEncounter={patientEncounter}
                      selectedAuthDocStatus={authDocStatus}
                      guestToken={guestToken}
                      setMessage={(msg: ApplicationStatusState) => setMsg(msg)}
                  />
              );

            default:
              return (
                  <Grid>
                    <ErrorMsg
                        errorStatus={true}
                        errorMessageHeader={''}
                        errorMessageContent={(
                            <>{GENERIC_ERROR_MSG}</>
                        )}
                    />
                  </Grid>
              );
          }
        })()}

        <Snackbar
            open={!!msg?.showMessage}
            message={msg.message}
            type={msg?.messageType}
            onClose={() => {
              setMsg(emptyApplicationStatus);
            }}
        />
      </Grid>
  );
};

const checkPaymentProgramData = (patientPaymentSchedule: PatientPaymentSchedule) => {

  if (!patientPaymentSchedule) {
    console.error('No Payment Schedule exists: ', patientPaymentSchedule);
    throw Error();
  }

  if (patientPaymentSchedule.terms < 0) {
    console.error('Payment Program should not have a negative term value',
        patientPaymentSchedule?.terms);
    throw Error();
  }

};

const GENERIC_ERROR_MSG = 'There is something wrong with your payment program. If the problem persists, please reach out to a FinPay administrator at support@finpay.com.';
