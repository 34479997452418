import React from 'react';
import { Grid, Paper } from '@mui/material';
import PreselectLevelsOfCare from '../preselect-loc/preselect-loc-details';

function PreselectLevelsOfCareView() {
  return (
    <Paper>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <PreselectLevelsOfCare />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PreselectLevelsOfCareView;
