import './index.scss';

import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import reportWebVitals from './reportWebVitals';
import HttpInterceptor from './shared/Http/http-interceptor';
import store from './shared/state/store';
import { getConfiguration } from './shared/configuration/config-settings';
import { theme } from './shared/theme';

Sentry.init({
  dsn: "https://49a04049b7824f129a2e7e955741afcc@o4504078815002624.ingest.sentry.io/4504078855766016",
  integrations: [new BrowserTracing()],
  release: "finpass-ui@" + process.env.npm_package_version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Setup Http Interceptor to handle Security Token and Global HTTP Error Handling
HttpInterceptor();

// As of React 18
// const root = createRoot(document.getElementById('root'))
const element = document.getElementById('root');
const root = createRoot(element!);
const App = require('./App').default as React.FC

const getPromise = () => {
  // note - Stripe Elements requires a promise, so initializing to an empty promise
  let promise: Promise<Stripe | null> = new Promise(() => {});
  if (config.stripe_key) {
    promise = loadStripe(config.stripe_key);
  }
  return promise;
};

const config = getConfiguration();
const promise = getPromise();

// react 18 update
const render = () => root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Elements stripe={promise}>
          <Provider store={store}>
            <App />
          </Provider>
        </Elements>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
)

render();


reportWebVitals();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}
