import React from "react";
import LevelsofCareGrid from "../levels-of-care/levels-of-care-grid";
import PayersGrid from "../payers/payers-grid";
import ScholarshipGrid from "../scholarship/scholarship-grid";



function MasterLists() {

  return (
    <>
      <LevelsofCareGrid />
      <PayersGrid />
      <ScholarshipGrid />
    </>
  );
}

export default MasterLists;
