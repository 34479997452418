export interface PaymentType {
  paymentTypeId: number;
  paymentTypeName: string;
};

export const emptyPaymentType = {
  paymentTypeId: 0,
  paymentTypeName: '',
}

export const PAYMENT_TYPES: {[key: string]: PaymentType} = {
  DOWN_PAYMENT: {
    paymentTypeId: 1,
    paymentTypeName: "Down Payment"
  },
  RECURRING: {
    paymentTypeId: 2,
    paymentTypeName: "Recurring"
  },
  SPECIALIST: {
    paymentTypeId: 3,
    paymentTypeName: "Specialist"
  },
  SELF: {
    paymentTypeId: 4,
    paymentTypeName: "Self"
  },
  AT_FACILITY: {
    paymentTypeId: 5,
    paymentTypeName: "Paid at Facility"
  }
}

export const mapToPaymentType = (paymentTypeName: string) => {
  const standardizedTypeName = paymentTypeName.toLowerCase();
  switch (standardizedTypeName) {
    case "down payment":
      return PAYMENT_TYPES.DOWN_PAYMENT;
    case "recurring":
      return PAYMENT_TYPES.RECURRING;
    case "specialist":
      return PAYMENT_TYPES.SPECIALIST;
    case "self":
      return PAYMENT_TYPES.SELF;
    case "paid at facility":
      return PAYMENT_TYPES.AT_FACILITY;
    default:
      return PAYMENT_TYPES.DOWN_PAYMENT;
  }
}

export const getPaymentTypeById = (paymentTypeId: number) => {
  switch (paymentTypeId) {
    case 1:
      return PAYMENT_TYPES.DOWN_PAYMENT;
    case 2:
      return PAYMENT_TYPES.RECURRING;
    case 3:
      return PAYMENT_TYPES.SPECIALIST;
    case 4:
      return PAYMENT_TYPES.SELF;
    case 5:
      return PAYMENT_TYPES.AT_FACILITY;
    default:
      return PAYMENT_TYPES.DOWN_PAYMENT;
  }
}