import { DialogActionButton, TextField, Toggle } from '@finpay-development/shared-components';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {useFormik } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { ClientDeficiencyNotification } from '../../../../models/client';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { clearModalEditStatus } from '../../../../state/clients/implementation-clients-slice';
import { saveClientDeficiencyNotification } from '../../../../state/clients/implementation-clients-thunk';
import { Utils } from '../../../../../shared/utils';

interface ClientDeficiencyNotificationModalProps {
  open: boolean;
  handleEditDeficiencyNotifCancel: () => void;
  handleEditDeficiencyNotifSubmit: () => void;
}

export function ClientDeficiencyNotificationModal(props: ClientDeficiencyNotificationModalProps) {
  const { open, handleEditDeficiencyNotifCancel, handleEditDeficiencyNotifSubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    currentClientDeficiencyNotification: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.currentClientDeficiencyNotification
    ),
    saveStatus: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.modalSaveStatus
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    )
  }
  const {
    currentClientDeficiencyNotification
  } = selectors;

  const initialValues: ClientDeficiencyNotification = {
    isEiv: currentClientDeficiencyNotification?.isEiv,
    addedToDownpayment: currentClientDeficiencyNotification?.addedToDownpayment,
    downpaymentPct: currentClientDeficiencyNotification?.downpaymentPct,
    clientId: currentClientDeficiencyNotification?.clientId,
    clientDeficiencyNotificationId: currentClientDeficiencyNotification?.clientDeficiencyNotificationId
  };

  const validationSchema = Yup.object({
    isEiv: Yup.boolean(),
    addedToDownPayment: Yup.boolean(),
    downpaymentPct: Yup.number()
      .when("addedToDownpayment", {
        is: true,
        then: Yup.number().required("Downpayment Amount is required").min(.01).max(100)
      })
  });

  const deficiencyNotificationFormik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => { },
    validationSchema: validationSchema,
    validate: checkIfIsValid
  });

  const [selectedIsEiv, setSelectedIsEiv] = useState(initialValues.isEiv);
  const [selectedAddedToDownpayment, setSelectedAddedToDownpayment] = useState(initialValues.addedToDownpayment);

  function checkIfIsValid(value: ClientDeficiencyNotification) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  async function handleSave() {
    const formValues = deficiencyNotificationFormik?.values;

    await dispatch(saveClientDeficiencyNotification(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleEditDeficiencyNotifSubmit();
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearModalEditStatus());
  }

  const handleDeficiencyNotificationIsEivChange = (e: ChangeEvent) => {
    //Toggle deficiency - need to negate previous value to get updated value
    const updatedValue = !selectedIsEiv;
    setSelectedIsEiv(updatedValue);
  };

  const handleDeficiencyNotificationAddedToDownpaymentChange = (e: ChangeEvent) => {
    const updatedValue = !selectedAddedToDownpayment;
    setSelectedAddedToDownpayment(updatedValue);
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Edit Deficiency Notification</span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={deficiencyNotificationFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Basis</Typography>
            </Grid>
            <Grid xs={12} item style={{ paddingTop: "5px" }}>
              <Toggle
                name="isEiv"
                leftText="Estimated Insurance Value"
                rightText="Net Patient Revenue"
                value={deficiencyNotificationFormik.values.isEiv}
                formik={deficiencyNotificationFormik}
                onChange={handleDeficiencyNotificationIsEivChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Added to Downpayment</Typography>
            </Grid>
            <Grid xs={12} item style={{ paddingTop: "5px" }}>
              <Toggle
                name="addedToDownpayment"
                leftText="Yes"
                rightText="No"
                value={deficiencyNotificationFormik.values.addedToDownpayment}
                formik={deficiencyNotificationFormik}
                onChange={handleDeficiencyNotificationAddedToDownpaymentChange}
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "30px" }}>
              <TextField
                type="text"
                error={
                  deficiencyNotificationFormik.touched["downpaymentPct"] &&
                    deficiencyNotificationFormik.errors["downpaymentPct"]
                    ? deficiencyNotificationFormik.errors["downpaymentPct"]
                    : ""
                }
                label="Downpayment Amount"
                name="downpaymentPct"
                value={deficiencyNotificationFormik.values.downpaymentPct}
                onChange={(e: Event) => {
                  if (Utils.handleNumericValues(e))
                    deficiencyNotificationFormik.handleChange(e)
                }}
                onBlur={deficiencyNotificationFormik.handleBlur}
                placeholder=""
                endAdornment="%"
                disabled={deficiencyNotificationFormik.values.addedToDownpayment === false}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          spinnerLeftPosition={5}
          savebuttonText="Update"
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleEditDeficiencyNotifCancel}
        />
      </DialogActions>
    </Dialog>
  );
}
