import { Button, DialogActionButton } from '@finpay-development/shared-components';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatNumberToUSD } from '../../../shared/calculators';
import { RootState } from '../../../shared/state/root-reducer';
import { PFRSummary } from '../../models/estimator';
import { EstSummaryModalCrossover } from "./estimator-summary-modal/crossover-summary";
import { admissionsAdvisorUtils } from '../../utils/admission-advisor-utils';

interface EstPlanYearCrossoverModalProps {
  open: boolean;
  handlePlanYearCrossoverModalSubmit: (
    selectedPFRName: string,
    selectedPFRAmount: number
  ) => void;
  handlePlanYearCrossoverModalCancel: () => void;
  currentYearPFR: PFRSummary | undefined;
  crossOverPFR: PFRSummary | undefined;
}

export function EstPlanYearCrossoverModal(
  props: EstPlanYearCrossoverModalProps
) {
  const {
    open,
    handlePlanYearCrossoverModalSubmit,
    handlePlanYearCrossoverModalCancel,
    currentYearPFR,
    crossOverPFR,
  } = props;
  const [enableSaveButton] = useState(true);
  const [selectedPFRName, setSelectedPFRName] = useState("TotalPFR");
  const [selectedPFRAmount, setSelectedPFRAmount] = useState(0);

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

  const { vobState, estimatorState } = state;

  useEffect(() => {
    setSelectedPFRAmount(financialSummary.totalPFR);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(
    estimatorState,
    vobState
  );

  function handleModalSubmit() {
    handlePlanYearCrossoverModalSubmit(selectedPFRName, selectedPFRAmount);
  }

  function handleModalCancel() {
    handlePlanYearCrossoverModalCancel();
  }

  function handleCancelCallback() {
    handlePlanYearCrossoverModalCancel();
  }

  const cellPadding = { padding: "0.5em" };

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle style={{paddingBottom: "2px"}}>Crossover PFR Decisioning</DialogTitle>
      <Typography variant="subtitle2" className="mt-3" style={{padding: "0px 0px 4px 28px", fontSize: "16px", paddingBottom: "15px", paddingTop: "0px", marginTop: "-1px"}}>
          Select the PFR amount you want to use in this estimate
      </Typography>
      <IconButton
        aria-label="close"
        onClick={handleModalCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <EstSummaryModalCrossover financialSummary={financialSummary} selectedPFRName={selectedPFRName} currentYearPFR={currentYearPFR} crossOverPFR={crossOverPFR} setSelectedPFRName={setSelectedPFRName} setSelectedPFRAmount={setSelectedPFRAmount} />
      </DialogContent>

      <DialogActions>
        {estimatorState.planYearCrossover?.planYearCrossoverConfirmed ? (
          <>
            <Button onClick={() => handleModalCancel()}>Close</Button>
          </>
        ) : (
          <DialogActionButton
            saveStatus={saveStatus}
            isEnabled={enableSaveButton}
            savebuttonText="Confirm"
            spinnerLeftPosition={5}
            executeSave={handleModalSubmit}
            handleCallbackCancel={handleCancelCallback}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
