import {Route, Routes} from 'react-router-dom';
import PayNowOverview from "./PayNowOverview";
import BillingSameAddress from "./billing-info";
import PaymentInfoView from "./payment-info-view";
import PaymentSubmittedView from "./payment-submitted-view";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { getConfiguration } from "../../shared/configuration/config-settings";

import InvalidRoute from "./invalid-route";
import MobilePaymentView from './mobile-payment-view';
import MobilePaymentProgramView from './mobile-payment-program-view';
import NoPaymentProgramView from './no-payment-program-view';
import ForgetPassword from '../../security/components/forget-password';
import {
  PaymentProgramEnrollmentPage
} from './payment-program-enrollment/payment-program-enrollment-page';

export const PublicPagesWrapper = () => {

  const config = getConfiguration();
  const getPromise = () => {
    // note - Stripe Elements requires a promise, so initializing to an empty promise
    let promise: Promise<Stripe | null> = new Promise(() => {});
    if (config.stripe_key) {
      promise = loadStripe(config.stripe_key);
    }
    return promise;
  };
  const [promise] = useState(() => getPromise());

  return (
    <>
      <Elements stripe={promise}> 
        <Routes>
          <Route 
            path={"/webpayment"} 
            element={<PayNowOverview />}   
          />
          <Route
            path={"/webpayment/launch"} 
            element={<PayNowOverview />}
          />  
          <Route
            path={"/webpayment/launch/:token"} 
            element={<PayNowOverview />}
          />  
          <Route                   
            path={'/webpayment/billing-info'}
            element={<BillingSameAddress />}
          />
          <Route
              path={'/webpayment/mobile-payment'}
              element={<MobilePaymentView />}
          />
          <Route
              path={'/webpayment/mobile-payment-program'}
              element={<MobilePaymentProgramView />}
          />
          <Route
              path={'/webpayment/no-payment-programs'}
              element={<NoPaymentProgramView />}
          />
          <Route
            path={"/webpayment/payment-info"}
            element={<PaymentInfoView />}
          />
          <Route
            path={"/webpayment/payment-submitted"}
            element={<PaymentSubmittedView />}
          /> 
          <Route
            path={"/webpayment/*"}
            element={<InvalidRoute path='/webpayment'/>}
          />
          <Route
            path={"/forgot-password"}
            element={<ForgetPassword showPasswordChange={true}/>}
            />
          <Route
              path={"/payment-program-enrollment/:token"}
              element={<PaymentProgramEnrollmentPage />}
          />
          <Route
              path={"/payment-program-enrollment/*"}
              element={<InvalidRoute path='/payment-program-enrollment'/>}
          />
        </Routes>
      </Elements>
    </>
  );
};