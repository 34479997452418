import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { testCallService } from './simple-thunk';

interface CounterState {
  value: number,
  text: string
}

const initialState = { value:  1, text: '' } as CounterState

// Slice
const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      state.value  = state.value + 1;
    },
    decrement: (state) =>  {
      state.value  = state.value - 1;
    },
    incrementByAmount(state, action: PayloadAction<number>) {
      state.value += action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(testCallService.pending, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
     // state.text = action.payload;
    })
    builder.addCase(testCallService.fulfilled, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      state.text = action.payload;
    })

    builder.addCase(testCallService.rejected, (state, action) => {
      // both `state` and `action` are now correctly typed
      // based on the slice state and the `pending` action creator
      //state.text = action.payload;
    })
  }
});

export const { increment, decrement, incrementByAmount } = counterSlice.actions
export default counterSlice.reducer;
