import React from 'react';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { UserRoleItem, UserRoleViewModel } from '../../../security/model/user-role';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "./../../../scss/pages/admin/_admin-roles.scss";
import "./../../../scss/components/_table.scss";
import { RoleTypes } from '../../models/role-types';

export interface RoleCardContainerProps {
    role: UserRoleViewModel;
    handleClick: any;
}

function RoleCardContainer(props: RoleCardContainerProps) {
    const role = props.role;
    const shouldRenderMenu = role.roleType === "I" || role.roleType === "S";

    return (
        <Grid container direction="column" className="role-card mb-4">
            <TableContainer component={role.roleType !== RoleTypes.External ? Paper : 'div'}>
                <Grid container item justifyContent="space-between">
                    <Grid xs={8}  item >
                        <Typography variant="body1">{role.roleName}</Typography>
                        <Typography variant="body2" className="mb-2">{role.roleDesc}</Typography>

                    </Grid>
                    <Grid  item >
                    {shouldRenderMenu &&
                        <IconButton
                            onClick={(e) => props.handleClick(e, role.userRoleId)}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            size="large">
                            <MoreHorizIcon />
                        </IconButton>
                    }
                    </Grid>
                </Grid>
                <Table className="table alternating-row" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Navigation</TableCell>
                            <TableCell align="left">Access Rights</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {role.navigation.map((role: UserRoleItem, index) => {
                            return (role.id !== 47) && (
                                <TableRow key={index}>
                                    <TableCell align="left"><Typography variant="body1">{role.pageName}</Typography></TableCell>
                                    <TableCell align="left"><Typography variant="body1">{role.accessRights}</Typography></TableCell>
                                    <TableCell align="left">
                                        {role.actions.map((action, index) => (
                                            <Typography key={index} variant="body1">{action.value}</Typography>
                                        ))}
                                    </TableCell>
                                </TableRow>
                        )})}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default RoleCardContainer;
