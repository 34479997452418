export interface LoadingState {
    clientFacilityById: boolean;
    addClientConnectAccount: boolean;
    addPayNowClientConnectAccount: boolean;
    clientConnectAccounts: boolean;
}

export const emptyLoadingState: LoadingState = {
    clientFacilityById: false,
    addClientConnectAccount: false,
    addPayNowClientConnectAccount: false,
    clientConnectAccounts: false,
}