import { ClientType, emptyClientType } from "../../../../../patient/components/models/patient-encounter";

export interface ImplementationAuthDocs {
    authorizationDocumentsId: number | undefined;
    typeOfDoc: string;
    documentUrl: string;
    clientId: number;
    clientFacilityId: number;
    client: ClientType;
    isSigReq: boolean;
}

export interface AuthDocsType {
    authorizationDocumentsId: number | undefined;
    typeOfDoc: string;
    documentUrl: string;
    clientId: number;
    clientFacilityId: number;
    client: ClientType

}

export const emptyAuthDocsType: ImplementationAuthDocs = {
    authorizationDocumentsId: -1,
    typeOfDoc: "",
    documentUrl: "",
    clientId: -1,
    clientFacilityId: -1,
    client: emptyClientType,
    isSigReq: true
}