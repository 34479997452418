import './../../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { statesList } from '../../../../../shared/misc/us-states';
import { State } from '../../../../../shared/model/state';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { clientEntity } from '../../../../../shared/validation/schemas';
import { ClientEntityForm } from '../../../../models/client';
import { clearStatus } from '../../../../state/clients/implementation-clients-slice';
import { saveClientEntity } from '../../../../state/clients/implementation-clients-thunk';

interface ClientEditEntityModalProps {
  open: boolean;
  handleEditEntityCancel: () => void;
  handleEditEntitySubmit: () => void;
}

export function ClientEditEntityModal(props: ClientEditEntityModalProps) {
  const { open, handleEditEntityCancel, handleEditEntitySubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const selectors = {
    currentClientEntityDetails: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.currentClientEntityDetails
    ),
    saveStatus: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.apiStatus
    ),
      errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    )
  }

  async function handleSave() {
    const formValues: ClientEntityForm = formRef.current.values;
    await dispatch(saveClientEntity(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleEditEntitySubmit();
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleEditEntityCancel();
  }

  const initialValues: ClientEntityForm = {
    companyName: selectors.currentClientEntityDetails?.companyName,
    website: selectors.currentClientEntityDetails?.website,
    streetAddress1: selectors.currentClientEntityDetails?.streetAddress1,
    streetAddress2: selectors.currentClientEntityDetails?.streetAddress2,
    city: selectors.currentClientEntityDetails?.city,
    state: selectors.currentClientEntityDetails?.state,
    zipCode: selectors.currentClientEntityDetails?.zipCode,
  };

  const validationSchema = Yup.object(clientEntity);

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title" test-id="edit-entity-title">Edit Entity</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Entity</Typography>
                </Grid>

                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["companyName"] &&
                      formik.errors["companyName"]
                        ? formik.errors["companyName"]
                        : ""
                    }
                    label="Company Name"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["website"] && formik.errors["website"]
                        ? formik.errors["website"]
                        : ""
                    }
                    label="Website"
                    name="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Primary Address
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress1"] &&
                      formik.errors["streetAddress1"]
                        ? formik.errors["streetAddress1"]
                        : ""
                    }
                    label="Street Address 1"
                    name="streetAddress1"
                    value={formik.values.streetAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    test-id="edit-entity-street-address-1"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["streetAddress2"] &&
                      formik.errors["streetAddress2"]
                        ? formik.errors["streetAddress2"]
                        : ""
                    }
                    label="Street Address 2"
                    name="streetAddress2"
                    value={formik.values.streetAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["city"] && formik.errors["city"]
                        ? formik.errors["city"]
                        : ""
                    }
                    label="City"
                    name="city"
                    test-id="edit-entity-city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["state"] && formik.errors["state"]
                        ? formik.errors["state"]
                        : ""
                    }
                    label="State"
                    name="state"
                    test-id="edit-entity-state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    <MenuItem value="-1">Choose One</MenuItem>
                    {statesList.map((state: State) => (
                      <MenuItem key={state.stateId} value={state.stateId} test-id={`edit-entity-state-option-${state.stateCode}`}>
                        {state.stateCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["zipCode"] && formik.errors["zipCode"]
                        ? formik.errors["zipCode"]
                        : ""
                    }
                    label="Zip Code"
                    name="zipCode"
                    test-id="edit-entity-zip-code"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={5}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          spinnerLeftPosition={5}
          savebuttonText="Update"
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
