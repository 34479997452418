import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import FppComment from '../../../../shared/components/comment';
import { commentsPage } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer'

interface RemindersValidationProps {
    page: string
  }

function RemindersValidation(props: RemindersValidationProps) {
    const { page } = props;
    const selectors = {
        reminders: useSelector(
          (state: RootState) =>
            state.patientContext.reminders
        ),
      }
    return (
      <>
        {page===commentsPage.patients &&
          (
            <>
              {selectors.reminders.map((comment) => (
              <FppComment page={page} comment={comment} commentId={comment.patientNotesId} key={comment.patientNotesId} />
              ))}
            </>
          )
        }
        </>
    )
}

export default RemindersValidation
