import './login.scss';

import { Button, PasswordField, Snackbar, TextField } from '@finpay-development/shared-components';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { ButtonBase, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { configSettings } from '../../shared/configuration/config-settings';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { Credentials, ValueForm } from '../model/credentials';
import { changeAuthState } from '../services/authorization-service';
import { clearForm, hideStatus } from '../state/user-slice';
import { checkForCode, clearAllState, logUserIn, ssoRedirect } from '../state/user-thunk';

function Login() {
  const dispatch = useDispatch<AppDispatch>();

  const loginFlowStatus = useSelector(
    (state: RootState) => state.userContext.applicationStatus
  );

  const userContextClearForm = useSelector(
    (state: RootState) => state.userContext.clearForm
  );

  const isPublicMode = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.isPublicMode
  );
  const userIsAuthenticated = useSelector(
    (state: RootState) => state.userContext.userIsAuthenticated
  );

  const isPublicModeLoggedIn = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.isPublicModeLoggedIn
  )

  const guestContext = useSelector((state: RootState) => state.guestContext)

  const showLogin = useSelector((state: RootState) => state.adminContext.adminUserContext.showLogin)

  // Hook for switching to SSO page
  const [useSSO, setSSO] = useState(false);
  const url = window.location.href;
  const isUrlPatientPortal = ((url?.includes(
          configSettings.patientPortalUrl1) ||
      url?.includes(configSettings.patientPortalUrl2)))

// Hook for calling <Formik>
  const formRef: any = useRef();

  useEffect(() => {
    formRef.current?.resetForm();
  }, [userContextClearForm]);

  useEffect(() => {
    if (isPublicMode && !userIsAuthenticated && !isPublicModeLoggedIn && !showLogin) { // don't allow the url to reset when going back to the login page.
      window.history.replaceState(null, "", "/webpayment")
    }
  });


  useEffect(() => {
    dispatch(clearAllState());
  }, [dispatch]);

  useEffect(() => {
    dispatch(checkForCode());
  }, [dispatch]);


  function handleCloseSnackbar() {
    dispatch(hideStatus());
  }

  const initialValues = {
    email: "",
    password: "",
  };
  const initialUsername ={
    email: ""
  };
  //default login
  function handleSubmit(values: ValueForm) {
    const credentials: Credentials = new Credentials();
    credentials.email = values?.email?.trim();
    if (typeof values?.password !== "undefined") {
    credentials.password = values?.password;
   }
    dispatch(logUserIn(credentials));
  }
  // SSO login
  function handleSSOSubmit(values: ValueForm) {
    const credentials: Credentials = new Credentials();
    credentials.email = values?.email;
    dispatch(ssoRedirect(credentials));
  }

// forgotpassword
  function handleForgotpassword() {
    dispatch(clearForm(true));
    changeAuthState("forgotpassword");
  }

  const showNeedHelp = (
    <Grid item>
      <Grid container alignContent="center" justifyContent="center" style={{ backgroundColor: "#F5F7F9" }} spacing={1}>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            paddingLeft: "40%"
          }}
        >
          Need Help?
        </Grid>
        <Grid item xs={12} md={12} style={{ textAlign: "center" }} spacing={1}>
          <PhoneIcon className="icon" fontSize="medium" />
          <span style={{ verticalAlign: "top" }}>855-4finpay (434-6729)</span>
        </Grid>
        <Grid item xs={12} md={12} style={{ textAlign: "center" }}spacing={1}>
          <EmailIcon className="icon" />
          <span style={{ verticalAlign: "top" }}>support@finpay.net</span>
        </Grid>
      </Grid>
    </Grid>

  );

  // JSX for sso login screen
  const SSOLogin = (
    <div className="content fullwidth loginflow">
      <Grid
          container
          alignContent="center"
          justifyContent="center"
          direction="column"
          spacing={4}
      >
        <Paper>
          <Formik
              innerRef={formRef}
              initialValues={initialUsername}
              enableReinitialize={true}
              onSubmit={(values) => handleSSOSubmit(values)}
          >
            {(formik) => (
                <Form>
                  <Grid
                      container
                      direction="column"
                      alignContent="center"
                      justifyContent="center"
                      spacing={2}
                  >
                    <Grid item>
                      <Typography className="mb-6" variant="h1">
                        SSO Login to your Account
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                          label="Email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Enter Your Email Address"
                          helperText="Use the email associated with your SSO account"
                      />
                    </Grid>
                    <Grid item>
                      <Link
                          to={""}
                          className={"basicLogin"}
                          // default login toggle
                          onClick={() => setSSO(!useSSO)}
                      >
                        Use Standard Sign-in
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid
                      item
                      container
                      direction="column"
                      alignContent="flex-end"
                      justifyContent="flex-end"
                      spacing={2}
                  >
                    <Grid item>
                      <Button
                          type="primary"
                          isSubmitButton
                          disabled={!(formik.isValid && formik.dirty)}
                      >
                        Continue
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
            )}
          </Formik>
        </Paper>
        <Grid item className="terms-conditions">
          <Grid container alignContent="center" justifyContent="center">
            <Typography
              color={'#0093d5'}
              onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
            >
              Terms & Conditions
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
);

// JSX fro default login screen
  const defaultLogin = (
    <div className="content fullwidth loginflow">
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        direction="column"
        spacing={4}
      >
        <Paper>
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {(formik) => (
                <Form>
                  <Grid
                    container
                    direction="column"
                    alignContent="center"
                    justifyContent="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography className="mb-6" variant="h1">
                        Log In To Your Account
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Your Email Address"
                        helperText="Use the email associated with your FinPay account"
                      />
                    </Grid>
                    <Grid item>
                      <PasswordField
                        label="Password"
                        name="password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Your Password"
                        helperText=""
                      />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        spacing={2}
                    >
                      <Grid item>
                        <ButtonBase
                          onClick={handleForgotpassword}
                          disableRipple
                        >
                          <Link
                              to=""
                              className="forgot-password"
                          >
                            Forgot Password
                          </Link>
                        </ButtonBase>
                      </Grid>
                      {!isUrlPatientPortal && (
                        <Grid item>
                          <ButtonBase
                            onClick={() => {
                              setSSO(!useSSO)
                            }}
                            disableRipple
                          >
                            <Link
                                to={""}
                                className={"ssoLogin"}
                            >
                              Sign in with SSO
                            </Link>
                          </ButtonBase>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                        item
                        container
                        direction="column"
                        alignContent="flex-end"
                        justifyContent="flex-end"
                        spacing={2}
                    >
                      <Grid item>
                        <Button
                            type="primary"
                            isSubmitButton
                            disabled={!(formik.isValid && formik.dirty)}
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Form>
            )}
          </Formik>
        </Paper>

        {!isPublicModeLoggedIn && isPublicMode && !userIsAuthenticated && (guestContext?.isExistingPatient === true) && (
            showNeedHelp
        )}

        {!isPublicMode  &&
        <Grid item className="terms-conditions">
          <Grid container alignContent="center" justifyContent="center">
            <Typography
              color={'#0093d5'}
              onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
            >
              Terms & Conditions
            </Typography>
          </Grid>
        </Grid>
        }
      </Grid>
    </div>

  );



  return(
    <div className="page-container login-container" slot="sign-in">
      <main className="no-padding" style={{overflow: "hidden"}}>
        {/*state switch between sso and default login pages*/}
        {useSSO? SSOLogin : defaultLogin}
        <Snackbar
            open={loginFlowStatus.showMessage}
            message={loginFlowStatus.message}
            type={loginFlowStatus.messageType}
            onClose={handleCloseSnackbar}
        />
      </main>
    </div>
  )
}
export default Login;


