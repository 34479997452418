import { CustomConfig } from "../models/custom-config";

export const customConfigHelper = {
    getConfigValue: (configKeyVal: string, configObjs: CustomConfig[]) => {
      let retValue = '';
      const workElement = configObjs.find(x => x.configKey === configKeyVal);
      if ( (workElement !== null) && (workElement !== undefined) ) {
        if (workElement.hasOwnProperty('configValue')) {
          retValue = workElement.configValue;
        }        
      }
      return retValue;
    }
  }