import { Paper, Typography } from '@mui/material';
import '../../scss/components/mobile-payments.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'src/shared/state/root-reducer';

export default () => {
  const supportEmail = useSelector((state: RootState) =>
    state.guestContext?.clientFacilityDetails?.client?.supportEmail
  );

  return (
    <Paper className="payment-wrapper">
      <Typography variant="subtitle2" className="mt-2" align="center">
        No alternative payment programs are currently available via mobile.
        <br />
        <br />
        For further help please contact {supportEmail || 'support@finpay.net'}
      </Typography>
    </Paper>
  );
}
