import _ from "lodash";
import { ClientConnectAccounts, ExternalAccountItem, ImplementationFacility, OperatingCost } from "../components/implementation-clients/details/models/implementation-facility";
import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { AxiosResultStatus } from "../../shared/service/axios-result-status";
import { FacilityForm } from "../models/facility-form";
import { FacilityDetails } from "../models/facility-details";
import { getState } from "../../shared/misc/us-states";
import { PrimaryAddress } from "../../shared/model/primary-address";
import { PrimaryContact } from "../../shared/model/primary-contact";
import { ExternalAccount } from "../../shared/model/external-account";
import { AxiosSavePayload } from "../../shared/service/axios-save-payload";
import { axiosSaveHelper } from "../../shared/service/axios-save-helper";
import { ExternalAccountFormPayload } from "../models/external-account";
import { Utils } from "../../shared/utils";
import { AxiosDeletePayload } from "../../shared/service/axios-delete-payload";
import { axiosDeleteHelper } from "../../shared/service/axios-delete-helper";
import { customConfigHelper } from "../misc/custom-config-helper";
import { ExternalAccountType } from "../../shared/enums";



export class SpecialistFacilityService {

  async getClientFacilities(clientId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -2,
      // url: `client/${clientId}/facility`,
      url: `clients/v2/client/${clientId}/facility`,
    };
    const responseData = await axiosReadHelper(readPayload);

    const groupedFacilities = this.groupFacilityByFirstCharFacilityName(responseData.entity);
    responseData.entity = groupedFacilities;
    return responseData;
  }

  async getClientConnectAccounts(clientId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/externalaccount/client/${clientId}`,
      // url: `externalaccount/client/${clientId}`,

    };
    const responseData = await axiosReadHelper(readPayload);

    const allConnectAccounts = this.parseClientConnectAccounts(responseData.entity);
    responseData.entity = allConnectAccounts;
    return responseData;
  }

  async getClientFacilityById(facilityId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -1,
      url: `patient-encounter/v2/patient/encounter/client-facility/${facilityId}`,
    };
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async getFacilityKPIs(clientId: number, facilityId: number, months: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: (months === 1) ? `client/${clientId}/facility/${facilityId}/kpi` : `client/${clientId}/facility/${facilityId}/kpi?months=${months}`
    }
    const response = await axiosReadHelper(payload);

    return response;
  }

  async getClientFacility(
    clientId: number,
    facilityId: number
  ): Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client/facility/${facilityId}`,
    };

    const response = await axiosReadHelper(readPayload);

    const facility = response.entity;

    if (!response.hasErrors) {
      const facilityForm = this.mapFacilityToForm(facility);
      const facilityDetails = this.mapFacilityToDetails(facility);
      response.entity = {
        facilityForm: facilityForm,
        facilityDetails: facilityDetails,
        apiResponseFacility: facility
      }
    }
    return response;
  }

  // payNow encrypted URL
  async getEncryptFacilityPayload(payloadIn: string) {

    const dataToSave = "\"" + payloadIn + "\"";
    const paramId = 0;
    const serviceURL = `clients/v2/client/paynow/enc-url-params`;
    const payload: AxiosSavePayload = {
      dataToSave: dataToSave,
      dataId: paramId,
      url: serviceURL,
      customHeader: {"Content-Type": "text/plain"}
    }
    //make sure it's POST with content-type='text/plain'
    const response = await axiosSaveHelper(payload);
    return response;

  }

  async saveClientFacility(
    clientId: number,
    formValues: FacilityForm,
    fullClientFacility: ImplementationFacility,
    allClientFacilities: ImplementationFacility[]
  ) {
    const isEdit = formValues.facilityId !== 0
    const clientFacilityToSave = this.mapToClient(formValues, fullClientFacility, isEdit);
    const facilityId = formValues.facilityId;

    const updateFacilityURL = `clients/v2/client/facility/${facilityId}`;
    const addFacilityURL = `clients/v2/client/${clientId}/facility`;

    const payload: AxiosSavePayload = {
      dataToSave: clientFacilityToSave,
      dataId: -2,
      isPatch: isEdit,
      url: isEdit ? updateFacilityURL : addFacilityURL
    }

    const response = await axiosSaveHelper(payload);

    if (!response.hasErrors) {
      // Group facilities differently depending if facility was a POST vs PUT
      const REST_type = formValues.facilityId === 0 ? "POST" : "PUT";
      const facilityResponse = response.entity;

      if (REST_type === "POST") {
          // Response is object with each key set to array of facilities
          // Not ideal to return to UI. So from this create own array of facility arrays
          const groupedFacilitiesUnsorted: ImplementationFacility[][] = [];
          for (let facilityGroup in facilityResponse) {
            groupedFacilitiesUnsorted.push(facilityResponse[facilityGroup])
          }
          const uniqueAccountIds = Object.keys(facilityResponse);

          const groupedFacilities = groupedFacilitiesUnsorted;   // data returned by API is already sorted

          response.entity = {
            groupedFacilities,
            uniqueAccountIds
          }
      } else if (REST_type === "PUT") {
        // response is a single facility. Splice it into full existing facility array and then group them
        response.entity = this.updateFacilityAndRegroup(response.entity, allClientFacilities);
      }
    }
    return response;
  }

  async saveExternalAccount(data: ExternalAccountFormPayload, allClientFacilities: ImplementationFacility[]): Promise<AxiosResultStatus> {
    const {clientId, facilityId, formData} = data;
    const savePayload: AxiosSavePayload = {
      dataToSave: formData,
      dataId: facilityId,
      url: "payment/v2/externalaccount/type/0/facility",
      customHeader: {"Content-Type": "multipart/form-data"}
    }
    let response = await axiosSaveHelper(savePayload);
    if (!response.hasErrors) {
      // response has no errors, then load facility from Node API. Otherwise, skip this block and proceed with displaying error
      response = await this.getClientFacility(clientId, facilityId);
      // update facility entry inside of facility list with new information about workflow status
      let facility = allClientFacilities.filter(facility=>facility.facilityId === response.entity.apiResponseFacility.facilityId);
      if (facility.length > 0) facility[0].workflow = response.entity.apiResponseFacility.workflow;
      response.entity = this.updateFacilityAndRegroup(response.entity, allClientFacilities);
    }
    return response;
  }

  async saveClientFacilityVob(
    clientId: number,
    facilityToSave: ImplementationFacility,
    allClientFacilities: ImplementationFacility[]
  ) {
    const isEdit = facilityToSave.facilityId !== 0;
    const facilityId = facilityToSave.facilityId;

    const payload: AxiosSavePayload = {
      dataToSave: facilityToSave,
      dataId: -2,
      isPatch: isEdit,
      url: `clients/v2/client/facility/${facilityId}`
    }

    const response = await axiosSaveHelper(payload);
    if (!response.hasErrors) {
      response.entity = this.updateFacilityAndRegroup(response.entity, allClientFacilities);
    }
    return response;
  }

  async saveClientFacilityConfig(
      addMode: boolean,
      facilityId: number,
      configKeyIn:  string,
      configValueIn: string,
      displayOrderIn: number
  ) {

    const updateConfigUrl = `configuration/v2/configuration/customconfig/${facilityId}/finpay.client_facilities/${configKeyIn}`;
    const addConfigUrl = `configuration/v2/configuration/customconfig`;

    const isEdit = ( facilityId !== 0 && !addMode);

    const updateDataLoad = { configValue:  configValueIn, displayOrder: displayOrderIn, ormVersion: 0 }
    const addDataLoad = { configIdSource: 'finpay.client_facilities',
                        configId: facilityId.toString(),
                        configKey: configKeyIn,
                        configValue:  configValueIn,
                        displayOrder: displayOrderIn,
                        ormVersion: 0
                      }


    const payload: AxiosSavePayload = {
      dataToSave: isEdit ? JSON.stringify(updateDataLoad) : JSON.stringify(addDataLoad),
      dataId: -2,
      isPatch: isEdit,
      url: isEdit ? updateConfigUrl : addConfigUrl
    }

    const response = await axiosSaveHelper(payload);
    return response;

  }

  async deleteClientFacilityConfig(
    facilityId: number,
    configKeyIn:  string,
    configValueIn: string
  ) {

    const deleteConfigUrl = `configuration/v2/configuration/customconfig/${facilityId}/finpay.client_facilities/${configKeyIn}`;

    const payload: AxiosDeletePayload = {
      dataId: -2,
      url: deleteConfigUrl
    }

    const response = await axiosDeleteHelper(payload);
    return response;
  }

  async addClientFacilityExternalAccount(facilityId: number, externalAccountType: ExternalAccountType, allClientFacilities: ImplementationFacility[]): Promise<AxiosResultStatus> {

    const savePayload: AxiosSavePayload = {
      dataToSave: {},
      dataId: -2,
      url: `payment/v2/externalaccount/type/${externalAccountType}/facility/${facilityId}`,

    }
    const response = await axiosSaveHelper(savePayload);
    if (!response.hasErrors) {
      response.entity = this.updateFacilityExternalAccountProperty(response.entity, facilityId, externalAccountType, allClientFacilities);
    }
    return response;
  }

  async saveFacilityOperatingCosts(facilityId: number, formValues: OperatingCost): Promise<AxiosResultStatus> {
    const savePayload: AxiosSavePayload = {
      dataToSave: formValues,
      dataId: -2,
      isPatch: true,
      url: `clients/v2/client/facility/${facilityId}`,
    }
    const response = await axiosSaveHelper(savePayload);

    const facility = response.entity;

    if (!response.hasErrors) {
      const facilityForm = this.mapFacilityToForm(facility);
      const facilityDetails = this.mapFacilityToDetails(facility);
      response.entity = {
        facilityForm: facilityForm,
        facilityDetails: facilityDetails,
        apiResponseFacility: facility
      }
    }

    return response;
  }


  // private supporting functions

  private parseClientConnectAccounts = (availableAccounts: ClientConnectAccounts) => {
    const finpassAccts = availableAccounts.finpass;
    const payNowAccts = availableAccounts.webpayment;

    let finpassExternalAccountIds: string[] = [];
    finpassAccts.forEach((workItem: ExternalAccountItem) => {
      finpassExternalAccountIds.push(workItem.externalId)
      }
    )
    finpassExternalAccountIds = finpassExternalAccountIds.filter(x => x !== '-1');

    let payNowExternalAccountIds: string[] = [];
    payNowAccts.forEach((workItem: ExternalAccountItem) => {
      payNowExternalAccountIds.push(workItem.externalId)
      }
    )
    payNowExternalAccountIds = payNowExternalAccountIds.filter(x => x !== '-1');

    return {finpassExternalAccountIds, payNowExternalAccountIds};

  }

  private updateFacilityExternalAccountProperty = (externalAccountId: string, facilityId: number, accountType: ExternalAccountType, allClientFacilities: ImplementationFacility[]) => {

    const allClientFacilitiesCopy = Utils.deepClone(allClientFacilities);
    const facilityIndex = allClientFacilities.findIndex((facility) => {
      return facility.facilityId === facilityId;
    });
    const workFacility = allClientFacilitiesCopy[facilityIndex];

    let updatedForm: any;
    if (workFacility !== undefined) {
      if (accountType === ExternalAccountType.Patient) {
        workFacility.externalAccount.externalAccountId = externalAccountId;
      } else if (accountType === ExternalAccountType.WebPayment) {
        workFacility.paynowExternalAccount.externalAccountId = externalAccountId;
      }
      // copy workFacility to Form
      updatedForm = this.mapFacilityToForm(workFacility);
    }
    // restore group by first char of facility name
    const workKeys = allClientFacilitiesCopy.map((facility: ImplementationFacility) => facility.facilityName.slice(0,1).toUpperCase());
    const uniqueAccountIds = _.uniq(workKeys)
    const groupedFacilities = this.groupFacilityByGroupIds(allClientFacilitiesCopy, uniqueAccountIds, updatedForm);

    return groupedFacilities;

  }


  // groups facilities into an array of sub-arrays where the facilities in the sub-arrays have matching Stripe ExternalAccountIds.
  private groupFacilityByExtAcctId = (facilities: ImplementationFacility[]) => {
    const externalAccountIds = facilities.map((facility: ImplementationFacility) => facility.externalAccount?.externalAccountId)
    const uniqueAccountIds = _.uniq(externalAccountIds);
    const groupedFacilities: ImplementationFacility[][] = [];
    uniqueAccountIds.forEach((accountId) => {
      let extAccountGroup: ImplementationFacility[] = [];
      facilities.forEach((facility: ImplementationFacility) => {
        if (facility.externalAccount?.externalAccountId === accountId) {
          // if the account Id matches a facility's externalAccountId, then we push that facility to an array of grouped facilities.
          extAccountGroup.push(facility)
        }
      })
      extAccountGroup = Utils.sortByNumber(extAccountGroup, "facilityId");
      groupedFacilities.push(extAccountGroup);
    });
    return { groupedFacilities, uniqueAccountIds };
  }

  private updateFacilityAndRegroup = (facilityResponse: ImplementationFacility, allClientFacilities: ImplementationFacility[]) => {
    const facilityIndex = allClientFacilities.findIndex((facility) => {
      return facility.facilityId === facilityResponse.facilityId;
    });
    const allClientFacilitiesCopy = Utils.deepClone(allClientFacilities);
    if (facilityIndex > -1) {
      allClientFacilitiesCopy.splice(facilityIndex, 1, facilityResponse);
    };

    // restore group by first char of facility name
    const workKeys = allClientFacilitiesCopy.map((facility: ImplementationFacility) => facility.facilityName.slice(0,1).toUpperCase());
    const uniqueAccountIds = _.uniq(workKeys)
    const groupedFacilities = this.groupFacilityByGroupIds(allClientFacilitiesCopy, uniqueAccountIds);
    return groupedFacilities;
  }

  private groupFacilityByFirstCharFacilityName = (facilities: ImplementationFacility[]) =>{
    const workKeys = facilities.map((facility: ImplementationFacility) => facility.facilityName.slice(0,1).toUpperCase());
    const uniqueGroupIds = _.uniq(workKeys)
    const groupedFacilities = this.groupFacilityByGroupIds(facilities, uniqueGroupIds);
    return groupedFacilities;
  }

  private groupFacilityByGroupIds = (facilities: ImplementationFacility[], uniqueAccountIds: any[], updatedForm?: FacilityForm) => {

    const groupedFacilities: ImplementationFacility[][] = [];
    uniqueAccountIds.forEach((groupId) => {
      let extAccountGroup: ImplementationFacility[] = [];
      facilities.forEach((facility: ImplementationFacility) => {
        if (facility.facilityName.toUpperCase().startsWith(groupId)) {
          extAccountGroup.push(facility)
        }
      })

      groupedFacilities.push(extAccountGroup);
    });

    if (updatedForm !== undefined) {
      return { groupedFacilities, uniqueAccountIds, updatedForm };
    } else {
      return { groupedFacilities, uniqueAccountIds };
    }
  }

  private mapToClient = (formValues: FacilityForm, fullClientFacility: ImplementationFacility, isEdit: boolean) => {
    let facilityToReturn = {};
    if (isEdit) {
      // merge with values from the GET from the API so we're not missing any data.
      facilityToReturn = {
        ...fullClientFacility,
        clientId: formValues.clientId,
        facilityName: formValues.facilityName,
        outpatientEnabled: formValues.outpatientEnabled,
        websiteUrl: formValues.website,
        phoneNum: formValues.phone,
        payOutFreq: formValues.payOutFreq,
        payOutDay: formValues.payOutDay === -1 ? null : formValues.payOutDay,
        customDocTokenId: formValues.customDocTokenId,
        vboPfrThreshold: formValues.vboPfrThreshold,
        vboTimeThreshold: formValues.vboTimeThreshold,
        monthlyOperatingCostIp: formValues.monthlyOperatingCostIp,
        monthlyOperatingCostOp: formValues.monthlyOperatingCostOp,
        bedCapacityIp: formValues.bedCapacityIp,
        bedCapacityOp: formValues.bedCapacityOp,
        primaryContact: {
          ...fullClientFacility.primaryContact,
          firstName: formValues.primaryContactFirstName,
          lastName: formValues.primaryContactLastName,
          primaryAddress: {
            ...fullClientFacility.primaryContact.primaryAddress,
            streetAddress1: formValues.primaryContactAddress1,
            streetAddress2: formValues.primaryContactAddress2,
            city: formValues.primaryContactCity,
            state: getState(formValues.primaryContactState),
            zipCode: formValues.primaryContactZipCode,
          } as PrimaryAddress,
          email: formValues.primaryContactEmail,
          primPhoneNum: formValues.primaryContactPhone,
          primPhoneExtn: formValues.primaryContactExt,
          secPhoneNum: formValues.primaryContactMobilePhone,
        } as PrimaryContact,
        address: {
          ...fullClientFacility.address,
          streetAddress1: formValues.streetAddress1,
          streetAddress2: formValues.streetAddress2,
          city: formValues.city,
          state: getState(formValues.state),
          zipCode: formValues.zipCode,
        } as PrimaryAddress,
        taxId: Utils.unformatIdNumber(formValues.taxIdNumber),
        taxIdLast4: formValues.taxIdNumber.slice(-4),
        externalAccount: {
          ...fullClientFacility.externalAccount,
          externalAccountId: formValues.externalAccount,
        } as ExternalAccount,
        referenceNumberName: formValues.referenceNumberName,
        referenceNumberHelpText: formValues.referenceNumberHelpText,
        paynowExternalAccount: {
          ...fullClientFacility.paynowExternalAccount,
          externalAccountId: formValues.payNowExternalAccount,
        } as ExternalAccount,
        bannerText: formValues.bannerText,
        customConfigs: [
          {
            "configKey": "ACCT_HELP_TEXT",
            "configValue": formValues.referenceNumberHelpText,
            "displayOrder": 15
          },
          {
            "configKey": "ACCT_ID",
            "configValue": formValues.referenceNumberName,
            "displayOrder": 10
          },
          {
            "configKey": "BANNER_TEXT",
            "configValue": formValues.bannerText,
            "displayOrder": 5
          },
        ]
      };
    } else {
      // don't merge w/ the API data because then we could cause an overwrite. Use form data only
      facilityToReturn = {
        clientId: formValues.clientId,
        facilityName: formValues.facilityName,
        outpatientEnabled: formValues.outpatientEnabled,
        websiteUrl: formValues.website,
        phoneNum: formValues.phone,
        payOutFreq: formValues.payOutFreq,
        payOutDay: formValues.payOutDay === -1 ? null : formValues.payOutDay,
        primaryContact: {
          firstName: formValues.primaryContactFirstName,
          lastName: formValues.primaryContactLastName,
          primaryAddress: {
            streetAddress1: formValues.primaryContactAddress1,
            streetAddress2: formValues.primaryContactAddress2,
            city: formValues.primaryContactCity,
            state: getState(formValues.primaryContactState),
            zipCode: formValues.primaryContactZipCode,
          } as PrimaryAddress,
          email: formValues.primaryContactEmail,
          primPhoneNum: formValues.primaryContactPhone,
          primPhoneExtn: formValues.primaryContactExt,
          secPhoneNum: formValues.primaryContactMobilePhone,
        } as PrimaryContact,
        address: {
          streetAddress1: formValues.streetAddress1,
          streetAddress2: formValues.streetAddress2,
          city: formValues.city,
          state: getState(formValues.state),
          zipCode: formValues.zipCode,
        } as PrimaryAddress,
        taxId: Utils.unformatIdNumber(formValues.taxIdNumber),
        taxIdLast4: formValues.taxIdNumber.slice(-4),
        externalAccount: {
          externalAccountId: formValues.externalAccount,
        } as ExternalAccount,
        referenceNumberName: formValues.referenceNumberName,
        referenceNumberHelpText: formValues.referenceNumberHelpText,
        paynowExternalAccount: {
          externalAccountId: formValues.payNowExternalAccount,
        } as ExternalAccount,
        bannerText: formValues.bannerText,
        customConfigs: [
          {
            "configKey": "ACCT_HELP_TEXT",
            "configValue": formValues.referenceNumberHelpText,
            "displayOrder": 15
          },
          {
            "configKey": "ACCT_ID",
            "configValue": formValues.referenceNumberName,
            "displayOrder": 10
          },
          {
            "configKey": "BANNER_TEXT",
            "configValue": formValues.bannerText,
            "displayOrder": 5
          },
        ]
      };
    };

    // check if null or empty value set for custom_config properties then set customConfigs to empty array
    const isNullBanner = Utils.isNullStringValue(formValues.bannerText);
    const isNullReferenceNumberName = Utils.isNullStringValue(formValues.referenceNumberName);
    const isNullReferenceNumHelpText = Utils.isNullStringValue(formValues.referenceNumberHelpText);
    if (isNullBanner && isNullReferenceNumberName && isNullReferenceNumHelpText) {
      (facilityToReturn as ImplementationFacility).customConfigs = [];
    }


    return facilityToReturn;
  }

  private mapFacilityToForm = (facility: ImplementationFacility) => {

    let bannerTextValue = '';
    let refNumberNameValue = '';
    let refNumberHelpTextValue = '';
    if (facility.customConfigs !== undefined) {
      bannerTextValue = customConfigHelper.getConfigValue('BANNER_TEXT', facility.customConfigs);
      refNumberNameValue = customConfigHelper.getConfigValue('ACCT_ID', facility.customConfigs);
      refNumberHelpTextValue = customConfigHelper.getConfigValue('ACCT_HELP_TEXT', facility.customConfigs);
    }

    const facilityForm: FacilityForm = {
      clientId: facility.clientId,
      facilityId: facility.facilityId,
      facilityName: facility.facilityName || "",
      outpatientEnabled: facility.outpatientEnabled || false,
      streetAddress1: facility.address?.streetAddress1 || "",
      streetAddress2: facility.address?.streetAddress2 || "",
      city: facility.address?.city || "",
      state: facility.address?.state?.stateId || 0,
      zipCode: facility.address?.zipCode || "",
      website: facility.websiteUrl || "",
      phone: facility.phoneNum || "",
      primaryContactFirstName: facility.primaryContact?.firstName || "",
      primaryContactLastName: facility.primaryContact?.lastName || "",
      primaryContactAddress1: facility.primaryContact?.primaryAddress?.streetAddress1 || "",
      primaryContactAddress2: facility.primaryContact?.primaryAddress?.streetAddress2 || "",
      primaryContactCity: facility.primaryContact?.primaryAddress?.city || "",
      primaryContactState: facility.primaryContact?.primaryAddress?.state?.stateId || 0,
      primaryContactZipCode: facility.primaryContact?.primaryAddress?.zipCode || "",
      primaryContactEmail: facility.primaryContact?.email || "",
      primaryContactPhone: facility.primaryContact?.primPhoneNum || "",
      primaryContactExt: facility.primaryContact?.primPhoneExtn || "",
      primaryContactMobilePhone: facility.primaryContact?.secPhoneNum || "",
      taxIdNumber: Utils.formatTaxIdNumber(facility.taxId) || "",
      taxIdDisplay: Utils.formatTaxIdNumberDisplayed(facility.taxId) || "",
      externalAccount: facility.externalAccount?.externalAccountId || "",
      payOutFreq: facility.payOutFreq || "daily",
      payOutDay: facility.payOutDay || -1,
      customDocTokenId: facility.customDocTokenId,
      payNowExternalAccount: facility.paynowExternalAccount?.externalAccountId || "",
      referenceNumberName: refNumberNameValue,
      referenceNumberHelpText: refNumberHelpTextValue,
      bannerText: bannerTextValue,
      taxIdNumber_lastFour: facility.taxIdLast4,
      monthlyOperatingCostIp: facility.monthlyOperatingCostIp || -1,
      monthlyOperatingCostOp: facility.monthlyOperatingCostOp || -1,
      bedCapacityIp: facility.bedCapacityIp || -1,
      bedCapacityOp: facility.bedCapacityOp || -1,
    };
    return facilityForm;
  }

  private mapFacilityToDetails = (facility: ImplementationFacility) => {

    let bannerTextValue = '';
    let refNumberNameValue = '';
    let refNumberHelpTextValue = '';
    if (facility.customConfigs !== undefined) {
      bannerTextValue = customConfigHelper.getConfigValue('BANNER_TEXT', facility.customConfigs);
      refNumberNameValue = customConfigHelper.getConfigValue('ACCT_ID', facility.customConfigs);
      refNumberHelpTextValue = customConfigHelper.getConfigValue('ACCT_HELP_TEXT', facility.customConfigs);
    }

    const facilityDetails: FacilityDetails = {
      facilityId: facility.facilityId,
      facilityName: facility.facilityName || "",
      outpatientEnabled: facility.outpatientEnabled || false,
      streetAddress1: facility.address?.streetAddress1 || "",
      streetAddress2: facility.address?.streetAddress2 || "",
      city: facility.address?.city || "",
      state: facility.address?.state?.stateCode || "",
      zipCode: facility.address?.zipCode || "",
      website: facility.websiteUrl || "",
      phone: facility.phoneNum || "",
      primaryContactFirstName: facility.primaryContact?.firstName || "",
      primaryContactLastName: facility.primaryContact?.lastName || "",
      primaryContactEmail: facility.primaryContact?.email || "",
      primaryContactAddress1: facility.primaryContact?.primaryAddress?.streetAddress1 || "",
      primaryContactAddress2: facility.primaryContact?.primaryAddress?.streetAddress2 || "",
      primaryContactCity: facility.primaryContact?.primaryAddress?.city || "",
      primaryContactState: facility.primaryContact?.primaryAddress?.state?.stateCode || "",
      primaryContactZip: facility.primaryContact?.primaryAddress?.zipCode || "",
      primaryContactPhone: facility.primaryContact?.primPhoneNum || "",
      primaryContactMobilePhone: facility.primaryContact?.secPhoneNum || "",
      ssn: facility.primaryContact?.ssnLast4 || "",
      dob: facility.primaryContact?.birthDate || "",
      externalVerifyStatus: facility.primaryContact.externalVerifyStatus || "",
      bankName: facility.primaryContact.bankName || "",
      routingNumber: facility.primaryContact.routingNumber || "",
      accountNumber: facility.primaryContact.accountNumber || "",
      externalAccount: facility.externalAccount.externalAccountId || "",
      customDocTokenId: facility.customDocTokenId || "",
      payNowExternalAccount: facility.paynowExternalAccount?.externalAccountId || "",
      referenceNumberName: refNumberNameValue,
      referenceNumberHelpText: refNumberHelpTextValue,
      bannerText: bannerTextValue,
      monthlyOperatingCostIp: (facility?.monthlyOperatingCostIp === undefined || facility?.monthlyOperatingCostIp === null) ? 0 : facility.monthlyOperatingCostIp,
      monthlyOperatingCostOp: (facility?.monthlyOperatingCostOp === undefined || facility?.monthlyOperatingCostOp === null) ? 0 : facility.monthlyOperatingCostOp,
      bedCapacityIp: (facility?.bedCapacityIp === undefined || facility?.bedCapacityIp === null) ? 0 : facility.bedCapacityIp,
      bedCapacityOp: (facility?.bedCapacityOp === undefined || facility?.bedCapacityOp === null) ? 0 : facility.bedCapacityOp,
    };
    return facilityDetails;
  }

  private sortGroupedFacililtiesByAccountId(groupedFacilitiesUnsorted: ImplementationFacility[][], uniqueAccountIds: string[]): ImplementationFacility[][] {
    uniqueAccountIds.sort((a, b) => {
      if (a.toString().toLowerCase() < b.toString().toLowerCase() ) return -1;
      if (a.toString().toLowerCase() > b.toString().toLowerCase() ) return 1;
      return 0;
    })

    let groupedFacilitiesSorted: ImplementationFacility[][] = [];
    uniqueAccountIds.forEach((accountId) => {
      groupedFacilitiesUnsorted.forEach((facilityGroup) => {
        facilityGroup.every((facility) => { // 'every' as alt to 'forEach'. This allows escaping the loop in a conditional which forEach doesn't support
          if (facility.externalAccount.externalAccountId === accountId) {
            groupedFacilitiesSorted.push(facilityGroup);
            return false;
          }
          return true;
        })
      })
    })
    return groupedFacilitiesSorted;
  }

  facilityMap(payload: any){
    return{
      workflowId: payload.workflowId,
      workflowStatusId: payload.workflowStatus.workflowStatusId,
      workflowSubStatusId: payload.workflowSubStatus.workflowSubStatusId
    }
  }

  async updateFacility(data: {
    clientFacilityId: number,
    payload: any
  }): Promise<AxiosResultStatus>{

    const payload: AxiosSavePayload = {
      dataToSave: this.facilityMap(data.payload),
      dataId: data.clientFacilityId,
      url: `clients/v2/client/facility`,
    }

    return await axiosSaveHelper(payload);

  }

}

export const specialistFacilityService = new SpecialistFacilityService();
