import { DialogActionButton, saveCallbackStatus, TextField} from "@finpay-development/shared-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { FacilityLOCReferralSource, FacilityLOCReferralSourceForm  } from "../../../../models/referral-source";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../../../../shared/state/store";
import { RootState } from "../../../../../shared/state/root-reducer";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearModalEditStatus } from "../../../../state/clients/implementation-clients-slice";
import * as Yup from 'yup';
import { saveFacilityLOCReferralSource } from "../../../../state/clients/implementation-clients-thunk";
import { ImplementationFacility } from "../models/implementation-facility";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

interface FacilityLOCReferralSourceProps {
    open: boolean;
    isEdit: boolean;
    referralSourceItem: FacilityLOCReferralSourceForm | undefined;
    handleReferralSourceCancel: () => void;
    handleReferralSourceSubmit: (isEdit: boolean) => void;
  }


export function FacilityLOCReferralSourceModal(props: FacilityLOCReferralSourceProps) {
  const {
    open,
    isEdit,
    referralSourceItem,
    handleReferralSourceCancel,
    handleReferralSourceSubmit,
  } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState<ImplementationFacility>();
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    saveStatus: useSelector(
        (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.modalSaveStatus
    ),
    errorMessage: useSelector(
        (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    ),
    facilities: useSelector(
        (state: RootState) =>
        state.implementationContext.implementationFacility?.facilities
    )
};

const {
    saveStatus,
    errorMessage,
    facilities,
} = selectors;

    useEffect(() => {
        if (facilities.length > 0) {
            const facility = facilities.flat().find((x: ImplementationFacility) => x.facilityId === referralSourceItem?.clientFacilityId);
            if (facility !== undefined) {
                setSelectedFacility(facility);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const initialLOCValues = {
        locReferralRevenue: referralSourceItem?.locReferralRevenue,
    } as FacilityLOCReferralSource;

    const validationSchema = Yup.object({
        locReferralRevenue: Yup.number().required("Revenue is required"),
    });

    const facLOCReferralFormik = useFormik({
        initialValues: initialLOCValues,
        enableReinitialize: true,
        onSubmit: () => { },
        validationSchema: validationSchema,
        validate: checkIfIsValid
    });

    function checkIfIsValid(value: {}) {
        validationSchema
            .validate(value)
            .then(() => {
                setEnableSaveButton(true);
            })
            .catch((err) => {
                setEnableSaveButton(false);
            });
    }

    async function handleFacilityLOCSave() {
        const requestBody: FacilityLOCReferralSource = {
            clientId: referralSourceItem?.clientId,
            facilityLocReferralSourceId: referralSourceItem?.facilityLocReferralSourceId,
            facilityReferralSourceId: referralSourceItem?.facilityReferralSourceId,
            facilityLevelOfCareId: referralSourceItem?.facilityLevelOfCareId,
            locReferralRevenue: facLOCReferralFormik.values.locReferralRevenue,
            clientFacilityId: referralSourceItem?.clientFacilityId,
            facilityReferralSourceName: referralSourceItem?.facilityReferralSourceName,
        }

        if (isEdit) {
            await dispatch(saveFacilityLOCReferralSource(requestBody));
        }
    }

    function handleFacilityLOCRefSourceSaveCallback() {
        if (saveStatus === saveCallbackStatus.success) {
            handleReferralSourceSubmit(isEdit);
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearModalEditStatus());
    }

    function handleFacilityLOCRefSourceCancelCallback() {
        handleReferralSourceCancel();
    }


    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>{isEdit ? "Edit" : "Add"} Revenue</DialogTitle>
            <DialogContent>
                <form>
                    <Grid container spacing={2} direction="row">
                        <Grid xs={12} item className="ml-2">
                            <Typography variant="h4" style={{ marginBottom: -12 }}>
                                Facility: {selectedFacility?.facilityName},
                                Referral Source: {referralSourceItem?.facilityReferralSourceName},
                                Level of Care:  {referralSourceItem?.levelOfCareCode} - { referralSourceItem?.levelOfCareName}
                            </Typography>
                            <br />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="mt-4">
                            <TextField
                                name="locReferralRevenue"
                                label="Revenue"
                                variant="outlined"
                                value={facLOCReferralFormik.values.locReferralRevenue}
                                onChange={facLOCReferralFormik.handleChange}
                                onBlur={facLOCReferralFormik.handleBlur}
                                placeholder=""
                                error={
                                    facLOCReferralFormik.touched.locReferralRevenue &&
                                    facLOCReferralFormik.errors.locReferralRevenue
                                }
                                startAdornment={<AttachMoneyIcon />}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={enableSaveButton}
                    savebuttonText={isEdit ? "Update" : "Save"}
                    saveStatus={saveStatus}
                    spinnerLeftPosition={5}
                    executeSave={handleFacilityLOCSave}
                    handleCallbackSave={handleFacilityLOCRefSourceSaveCallback}
                    handleCallbackCancel={handleFacilityLOCRefSourceCancelCallback}
                />
            </DialogActions>
        </Dialog>
    );

}