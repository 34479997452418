import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import ToggleCheck from "./toggle-check";
import { PasswordRuleState } from "../model/password-rule-state";

function PasswordRules(props: PasswordRuleState) {
  return (
    <List component="nav" aria-label="main mailbox folders">
      <ListItem>
        <ToggleCheck Checked={props.length} />
        <ListItemText primary="8-64 characters" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.hasUpperCase} />
        <ListItemText primary="One uppercase character" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.hasLowerCase} />
        <ListItemText primary="One lowercase character" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.hasSpecialCharacter} />
        <ListItemText primary="One special character" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.hasNumber} />
        <ListItemText primary="One number" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.hasNoConsecutiveLetters} />
        <ListItemText primary="No three or more consecutive characters" />
      </ListItem>
      <ListItem>
        <ToggleCheck Checked={props.passwordMatch} />
        <ListItemText primary="Passwords must match" />
      </ListItem>
    </List>
  );
}

export default PasswordRules;
