import { AdjustmentReason } from "../models/adjustment-reason";

export const adjustmentReasons: AdjustmentReason[] = [
  {
    adjustmentReasonId: 1,
    adjustmentDescription: "Patient Paid in Full - Patient Canceled Service",
  },
  {
    adjustmentReasonId: 2,
    adjustmentDescription: "Patient Paid in Full - Patient paid in full",
  },
  {
    adjustmentReasonId: 3,
    adjustmentDescription: "Patient Paid in Full - New Plan was created",
  },
  {
    adjustmentReasonId: 4,
    adjustmentDescription: "Patient overpaid by - Insurance Paid",
  },
  {
    adjustmentReasonId: 5,
    adjustmentDescription: "Patient overpaid by - Patient cancelled service",
  },
  {
    adjustmentReasonId: 6,
    adjustmentDescription: "Patient overpaid by - Services not rendered",
  },
  {
    adjustmentReasonId: 7,
    adjustmentDescription: "Patient overpaid by - New plan was created",
  },
  {
    adjustmentReasonId: 8,
    adjustmentDescription: "Decrease PFR by - Insurance Paid",
  },
  {
    adjustmentReasonId: 9,
    adjustmentDescription: "Decrease PFR by - Patient canceled service",
  },
  {
    adjustmentReasonId: 10,
    adjustmentDescription: "Decrease PFR by - Services not rendered",
  },
  {
    adjustmentReasonId: 11,
    adjustmentDescription: "Decrease PFR by - New plan was created",
  },
  {
    adjustmentReasonId: 12,
    adjustmentDescription: "Increase PFR by - Insurance denied payment",
  },
  {
    adjustmentReasonId: 13,
    adjustmentDescription: "Increase PFR by - Patient extended services",
  }
];

export const getReasonById = (id: number) => {
  const reason = adjustmentReasons.find((reason) => {
    return reason.adjustmentReasonId === id;
  })
  return reason?.adjustmentDescription.split(" - ")[1];
};

export enum TypeOfChange {
  "Patient Paid in Full",
  "Patient overpaid by",
  "Decrease PFR by",
  "Increase PFR by",
}
