

export interface ReportsFiltersInitialValues {
  patientName: string,
  accountHolderName: string,
  collabId: string,
  clientName: string,
  facilityName: string,
  planType: Array<string>,
  sentToProvider: Array<string>,
  automatedPaymentStatus: Array<string>,
  transactionType: Array<string>,
  paymentTypeIds: Array<string>,
  patientFinancialStatus: Array<string>,
  paymentWorkflowStatus: Array<string>,
  levelOfCare: Array<string>,
  accountHolderStatus: Array<string>,
  riskClass: Array<string>,
  initialRiskClasses: Array<string>,
  modifiedRiskClasses: Array<string>,
  convertedRiskClasses:Array<string>,
  finalRiskClasses: Array<string>,
  payoutStatus: Array<string>,
  channelNames: Array<string>,
}

export interface FacilityChecked {
  facilityId: number
}
export interface ClientAndFacilitiesChecked {
  clientId: number[],
  facilityId: number[]
}
export interface selectedClientAndFacilities {
    clientId: number,
    facilities: number[],
    totalFacilities: number,
    checked: boolean,
}
export interface selectedClientFacilitiesInfo {
  clientFacilitiesInfo: selectedClientAndFacilities[]
}

export interface Filters {
  clientsAndFacilities?: any
  startDate?: string,
  endDate?: string,
  admissionDate?: string,
  dischargeDate?: string,
  reportType?: any,
  groupTransactionNumber?: string
  patientName?: string,
  clientName?: string,
  facilityName?: string,
  payoutStatus?: string[],
  channelNames?: string[]
  clientId?: number[],
  facilityId?: number[],
  includeFacilityTotals?: boolean
}
export interface PatientsAndAccountHolders{
  patientsNames: string[];
  accountHoldersNames: string[];
}

export interface ClientBillingIds {
  collabIds: string[];
}

export interface ClientsAndFacilities{
  clientNames: string[];
  facilityNames: string[];
}

export const payoutStatusDropdown = [
  "Failed",
  "Completed",
  "Pending",
  "Created",
  "Reversed"
];
