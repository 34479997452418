import React from 'react';
import { ListItemIcon } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';

export interface ToggleCheckProps {
    Checked?: boolean;
}

function ToggleCheck(props: ToggleCheckProps) {

     return (
        <ListItemIcon>
            {props.Checked   && <CheckCircleIcon color="primary" />}
            {!props.Checked   && <RadioButtonUncheckedRoundedIcon color="secondary" />}
        </ListItemIcon>
    )
}

export default ToggleCheck;

