export interface LockingModalOpenStatus {
    isLockRequestedModalOpen_overview: boolean;
    isLockRequestedModalOpen_insurance: boolean;
    isLockRequestedModalOpen_patientChampions: boolean;
    isLockRequestedModalOpen_paymentPrograms: boolean;

    isInUseModalOpen_overview: boolean;
    isInUseModalOpen_insurance: boolean;
    isInUseModalOpen_patientChampions: boolean;
    isInUseModalOpen_paymentPrograms: boolean;

    isRequestDeniedModalOpen_overview: boolean;
    isRequestDeniedModalOpen_insurance: boolean;
    isRequestDeniedModalOpen_patientChampions: boolean;
    isRequestDeniedModalOpen_paymentPrograms: boolean;
}

export const blankLockingModalOpenStatus: LockingModalOpenStatus = {
    isLockRequestedModalOpen_overview: false,
    isLockRequestedModalOpen_insurance: false,
    isLockRequestedModalOpen_patientChampions: false,
    isLockRequestedModalOpen_paymentPrograms: false,

    isInUseModalOpen_overview: false,
    isInUseModalOpen_insurance: false,
    isInUseModalOpen_patientChampions: false,
    isInUseModalOpen_paymentPrograms: false,

    isRequestDeniedModalOpen_overview: false,
    isRequestDeniedModalOpen_insurance: false,
    isRequestDeniedModalOpen_patientChampions: false,
    isRequestDeniedModalOpen_paymentPrograms: false,
}