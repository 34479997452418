import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { logUserOut } from "../../../../security/state/user-thunk";
import { openHelloSignDoc } from "../../../../shared/configuration/config-settings";
import { RootState } from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import { getForwardedSignUrl } from "../../../state/patient-thunk";

export const ForwardDocumentsView = () => {

    const signurl = useSelector((state: RootState) => {
        return state.patientContext.patientEncounterSignUrl;
    })

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const {helloSignRequestId} = useParams<{helloSignRequestId?: string}>();

    useEffect(() => {
        if (signurl?.urlToSign) {
            openHelloSignDoc(signurl.urlToSign);
            dispatch(logUserOut());
            navigate("/");
        }
    }, [dispatch, navigate, signurl])

    useEffect(() => {
        if (helloSignRequestId) dispatch(getForwardedSignUrl(helloSignRequestId));
    }, [dispatch, helloSignRequestId])
    
    return (
        // return blank UI. HelloSign window will overlay
        <></>
    )
}