import {
  EditRule,
  Rule,
  RuleCriteria,
  RuleEditViewModel,
  RuleSet,
  RuleTableViewModel,
} from '../../admin-configuration/models/rule';
import {Utils} from '../utils';
import {AxiosSavePayload} from './axios-save-payload';
import {axiosSaveHelper} from './axios-save-helper';
import {AxiosResultStatus} from './axios-result-status';
import {AxiosDeletePayload} from './axios-delete-payload';
import {axiosDeleteHelper} from './axios-delete-helper';
import {AxiosResultDeleteStatus} from './axios-result-delete-status';
import {AxiosReadPayload} from './axios-read-payload';
import {axiosReadHelper} from './axios-read-helper';
import {
  ImplementationFacility,
} from '../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import {
  PayorRisk,
  payorRisk,
  TimingRisk,
  timingRisk,
} from '../model/timing-and-payor-risk';
import _ from 'lodash';
import {RuleParamsMetadata} from '../../meta-data/models/metaData';

interface RulesReturnType {
    ungroupedRules: Rule[];
    groupedRules: RuleSet;
}

// This service is SHARED between the "Configuration -> Common Rule Set", and "Risk Rules" section in the Rules Engine
export class RulesService {
  mappingHelper = {
    // map full rules to View Model used to display the rules in UI table
    mapToRuleTableViewModel(rules: Rule[]): any[] {
      return rules.map((rule) => {
        return {
          ruleId: rule.ruleId,
          timingRisk: rule.timingRisk.timingRiskName,
          payorRisk: rule.payorRisk.payorRiskName,
          condition: this.concatConditions(rule.ruleCriteria),
          minDown: `${rule.minDownPmtType === "$" ? rule.minDownPmtType : ""}${rule.minDownPmt}${rule.minDownPmtType === "%" ? rule.minDownPmtType : ""}`,
          optimalDown: `${rule.optimalDownPmtType === "$" ? rule.optimalDownPmtType : ""}${rule.optimalDownPmt}${rule.optimalDownPmtType === "%" ? rule.optimalDownPmtType : ""}`,
          monthTerms: rule.availableTerms,
          optimalTerm: rule.optimalTerm,
          sortOrder: rule.sortOrder,
        };
      });
    },

    mapToRule(formPayload: RuleEditViewModel, fullRule: Rule, ruleParamsMetaData: RuleParamsMetadata[]): Rule {
      const ruleToSave: Rule = {
        ...fullRule,
      };

      const timingRiskToSave = timingRisk.find(
        (risk) => risk.timingRiskId === formPayload.timingRisk
      ) as TimingRisk;

      const payorRiskToSave = payorRisk.find(
        (risk) => risk.payorRiskId === formPayload.payorRisk
      ) as PayorRisk;

      ruleToSave.timingRisk = timingRiskToSave;
      ruleToSave.payorRisk = payorRiskToSave;
      ruleToSave.ruleName = `${timingRiskToSave.timingRiskName}/${payorRiskToSave.payorRiskName}`;
      ruleToSave.availableTerms = formPayload.availableTerms;
      ruleToSave.optimalTerm = formPayload.optimalTerm;

      ruleToSave.ruleCriteria = formPayload.ruleCriteria.map(
        (ruleCriteria) => {
          const newRuleCriteria = new RuleCriteria();

          const ruleParam = ruleParamsMetaData.find((ruleParam) => {
            return ruleParam.ruleParamId === ruleCriteria.paramName
          })

          if(!ruleParam) throw Error("Rule param not found");

          newRuleCriteria.param = {
            paramId: ruleParam.ruleParamId,
            paramName: ruleParam.paramName,
            paramPropertyName: "",
            dataType: ruleParam.dataType,
            compareBy: ruleParam.comparators,
            isList: ruleParam.isList,
            sortOrder: ruleParam.sortOrder
          }
          newRuleCriteria.comparator = ruleCriteria.comparator;
          newRuleCriteria.paramValue = ruleCriteria.paramValue;
          newRuleCriteria.sortOrder = 1;
          return newRuleCriteria;
        }
      );

      ruleToSave.minDownPmt = +formPayload.minDownPmt;
      ruleToSave.minDownPmtType = formPayload.minDownPmtType;
      ruleToSave.optimalDownPmt = +formPayload.optimalDownPmt;
      ruleToSave.optimalDownPmtType = formPayload.optimalDownPmtType;
      ruleToSave.sortOrder = formPayload.sortOrder;
      return ruleToSave;
    },

    mapToRuleForm(rule: Rule) {
      return {
        ruleEditView: {
          ruleId: rule.ruleId,
          timingRisk: rule.timingRisk.timingRiskId,
          payorRisk: rule.payorRisk.payorRiskId,
          availableTerms: rule.availableTerms,
          optimalTerm: rule.optimalTerm,
          isDefaultRule: rule.isDefaultRule,
          ruleCriteria: rule.ruleCriteria.map((ruleCriteria) => ({
            paramName: ruleCriteria.param.paramId,
            comparator: ruleCriteria.comparator,
            paramValue: ruleCriteria.paramValue,
            param: {
              dataType: ruleCriteria.param.dataType,
              compareBy: ruleCriteria.param.compareBy
            }
          })),
          minDownPmt: rule.minDownPmt.toString(),
          minDownPmtType: rule.minDownPmtType,
          optimalDownPmt: rule.optimalDownPmt.toString(),
          optimalDownPmtType: rule.optimalDownPmtType,
          sortOrder: rule.sortOrder,
        },
        fullRule: rule,
      } as EditRule;
    },

    concatConditions(conditions: RuleCriteria[]) {
      const conditionsAsStringArray = conditions.map((condition) => {
        return `${condition.param.paramName} ${condition.comparator} ${condition.paramValue}`;
      });
      return conditionsAsStringArray.join(" and ");
    },
  };

  async getRules(): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: "administration/configuration/rules",
    };
    const result = await axiosReadHelper(payload);

    if (!result.hasErrors) {
      let allRules: Rule[] = Utils.sortBySortOrder(result.entity);
      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(
        allRules
      );

      const groupedRules = this.groupIntoRuleSets(
        allRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      result.entity = {
        ungroupedRules: allRules,
        groupedRules: groupedRules,
      };
    }
    return result;
  }

  // gathers all unique timing risk and payor risk combinations
  getTimingRiskPayorRiskCombo(allRules: Rule[]) {
    const timingRiskPayorRiskCombo = new Set<string>();
    allRules.forEach((rule: Rule) => {
      timingRiskPayorRiskCombo.add(
        `${rule.timingRisk.timingRiskName}|${rule.payorRisk.payorRiskName}`
      );
    });
    return timingRiskPayorRiskCombo;
  }

  // Get Rule in Configuration
  async configGetRule(ruleId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: ruleId,
      url: "administration/configuration/rule",
    };

    const response = await axiosReadHelper(payload);

    if (!response.hasErrors) {
      const rule: Rule = response.entity;
      response.entity = this.mappingHelper.mapToRuleForm(rule);
    }
    return response;
  }


  // Get Rule in Implementation Specialist Rules Engine - Risk Rules
  async getRule(ruleId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: ruleId,
      url: "administration/configuration/rule",
    };

    const response = await axiosReadHelper(payload);

    if (!response.hasErrors) {
      const rule: Rule = response.entity;
      response.entity = this.mappingHelper.mapToRuleForm(rule);
    }
    return response;
  }


  // Save Rule in Configuration
  async configSaveRule(
    formPayload: RuleEditViewModel,
    fullRule: Rule,
    allRules: Rule[],
    ruleParamsMetadata: RuleParamsMetadata[]
  ): Promise<AxiosResultStatus> {
    let ruleToSave = this.mappingHelper.mapToRule(formPayload, fullRule, ruleParamsMetadata);

    ruleToSave.isDefaultRule = true;

    const payload: AxiosSavePayload = {
      dataToSave: ruleToSave,
      dataId: ruleToSave.ruleId,
      url: "administration/configuration/rule",
    };

    const saveResult = await axiosSaveHelper(payload);

    if (!saveResult.hasErrors) {
      const savedRule: Rule = saveResult.entity;

      const ruleToUpdateIndex = allRules.findIndex((rule) => {
        return rule.ruleId === savedRule.ruleId;
      });

      if (ruleToUpdateIndex > -1) {
        allRules[ruleToUpdateIndex] = savedRule;
      } else {
        allRules.push(savedRule);
      }

      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(
        allRules
      );

      const groupedRules = this.groupIntoRuleSets(
        allRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      saveResult.entity = {
        ungroupedRules: allRules,
        groupedRules: groupedRules,
      } as RulesReturnType;
    }
    return saveResult;
  }

  // Save Rule Implementation Specialist Rules Engine - Risk Rules
  async saveRule(
    formPayload: RuleEditViewModel,
    fullRule: Rule,
    clientId: number,
    facility: ImplementationFacility,
    ruleParamsMetaData: RuleParamsMetadata[]
  ): Promise<AxiosResultStatus> {
    const allFacilityRules = [...facility.rulesUngrouped];
    let ruleToSave = this.mappingHelper.mapToRule(formPayload, fullRule, ruleParamsMetaData);
    ruleToSave.isDefaultRule = false;

    const isPost = ruleToSave.ruleId <= 0;
    let facilityId = isPost ? facility.facilityId : fullRule.facilityId

    const postPayload: AxiosSavePayload = {
      dataToSave: _.omit({
        ...ruleToSave,
        clientId,
        facilityId,
      }, ["ruleId"]),
      dataId: ruleToSave.ruleId,
      url: `payment/v2/rule`,
    }

    const patchPayload: AxiosSavePayload = {
      dataToSave: _.omit(ruleToSave, ["ruleId", "clientId", "facilityId"]),
      dataId: ruleToSave.ruleId,
      url: `payment/v2/rule/${ruleToSave.ruleId}`,
      isPatch: true,
    }

    const saveResult = await axiosSaveHelper(isPost ? postPayload : patchPayload);

    if (!saveResult.hasErrors) {
      const savedRule: Rule = saveResult.entity;

      const ruleToUpdateIndex = allFacilityRules.findIndex((rule) => {
        return rule.ruleId === savedRule.ruleId;
      });

      if (ruleToUpdateIndex > -1) {
        allFacilityRules[ruleToUpdateIndex] = savedRule;
      } else {
        allFacilityRules.push(savedRule);
      }

      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(
        allFacilityRules
      );

      const groupedRules = this.groupIntoRuleSets(
        allFacilityRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      saveResult.entity = {
        ungroupedRules: allFacilityRules,
        groupedRules: groupedRules,
      } as RulesReturnType;
    }
    return saveResult;
  }

  async configDeleteRule(
    ruleId: number,
    allRules: Rule[]
  ): Promise<AxiosResultDeleteStatus> {
    const payload: AxiosDeletePayload = {
      dataId: ruleId,
      url: `administration/configuration/rule`,
    };

    const result = await axiosDeleteHelper(payload);

    if (!result.hasErrors) {
      const updatedAllRules = allRules.filter((rule) => {
        return rule.ruleId !== ruleId;
      });

      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(
        updatedAllRules
      );

      const groupedRules = this.groupIntoRuleSets(
        updatedAllRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      result.entity = {
        ungroupedRules: updatedAllRules,
        groupedRules: groupedRules,
      };
    }
    return result;
  }

  async deleteRule(ruleId: number, facility: ImplementationFacility): Promise<AxiosResultDeleteStatus> {
    const payload: AxiosDeletePayload = {
      dataId: ruleId,
      url: `payment/v2/rule`,
    };

    const result = await axiosDeleteHelper(payload);

    if (!result.hasErrors) {
      const allFacilityRules = [...facility.rulesUngrouped];

      const updatedAllRules = allFacilityRules.filter((rule) => rule.ruleId !== ruleId);
      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(updatedAllRules);

      const groupedRules = this.groupIntoRuleSets(
        updatedAllRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      result.entity = {
        ungroupedRules: updatedAllRules,
        groupedRules: groupedRules,
      };
    }
    return result;
  }

  // Reorder rule in state cache for snappy UX
  async reOrderRuleInUI(
    ruleSet: RuleSet,
    currentRuleGroup: RuleTableViewModel[],
    dndSourceIndex: number,
    dndDestinationIndex: number,
    ruleSetIndex: number): Promise<RuleSet> {

    // reorder the rule in the rule set
    const rules = [...currentRuleGroup];
    const [reorderedRule] = rules.splice(dndSourceIndex, 1);
    rules.splice(dndDestinationIndex, 0, reorderedRule);


    // iterate the Rules array and overwrite sortOrders to be in order starting from 1
    // Result to get sent back to the UI
    const sortedRulesForUI = rules.map((rule, index) => {
      return {
        ...rule,
        sortOrder: index + 1,
      };
    });

    // replace ruleset with the modified rule array, and return it
    const mutableCopy = [...ruleSet];
    mutableCopy[ruleSetIndex] = sortedRulesForUI;

    return mutableCopy;
  }

  // Reorder rule permanently with API. Will not update UI. Preceding function updates the UI
  async reOrderRule(
    allRules: Rule[], // full rule objects
    currentRuleSet: RuleTableViewModel[],
    dndSourceIndex: number,
    dndDestinationIndex: number,
    facility?: ImplementationFacility): Promise<AxiosResultStatus> {

    // reorder the rule in the rule set
    const rules = [...currentRuleSet];
    const [reorderedRule] = rules.splice(dndSourceIndex, 1);
    rules.splice(dndDestinationIndex, 0, reorderedRule);

    // Iterate through sorted array of rules (not full rule objects)
    // find their full Rule counterpart and update those rules with the sortOrder value from the sorted array
    // The result will be array of full Rule objects (with sortOrder set) as the payload to the API
    const rulePayload: Rule[] = [];

    rules.forEach((rule, index) => {
      const foundRule = allRules.find((_rule) => {
        return _rule.ruleId === rule.ruleId;
      });

      if (foundRule) {
        const mutableCopy = {...foundRule};
        mutableCopy.sortOrder = index + 1;
        rulePayload.push(mutableCopy);
      }
    })

    let url: string;
    if (facility) {
        url = `client/${facility.clientId}/facility/${facility.facilityId}/rules`;
    } else {
        url = `administration/configuration/rules`;
    }

    const payload: AxiosSavePayload = {
      dataToSave: rulePayload,
      dataId: 1,
      isPatch: true,
      url: url
    }

    return await axiosSaveHelper(payload);
  }


  async applyCommonRuleSet(facility: ImplementationFacility): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataId: 0,
      dataToSave: {}, // this is a POST without needing payload
      url: `client/${facility.clientId}/facility/${facility.facilityId}/rules/import`,
    };
    const response = await axiosSaveHelper(payload);

    let allRules: Rule[] = Utils.sortBySortOrder(response.entity);
    const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(
      allRules
    );

    const groupedRules = this.groupIntoRuleSets(
      allRules,
      Array.from(timingRiskPayorRiskCombo)
    );

    response.entity = {
      rulesGrouped: groupedRules,
      rulesUngrouped: allRules,
    };
    return response;
  }


  async getFacilityRules(facility: ImplementationFacility): Promise<any> {
    const payload: AxiosReadPayload = {
      url: `payment/v2/rules?facilityId=${facility.facilityId}`,
    };

    const result = await axiosReadHelper(payload);

    if (!result.hasErrors) {
      const allRules = Utils.sortBySortOrder(result.entity);
      const timingRiskPayorRiskCombo = this.getTimingRiskPayorRiskCombo(allRules);
      const rulesGrouped = this.groupIntoRuleSets(
        allRules,
        Array.from(timingRiskPayorRiskCombo)
      );

      result.entity = {
        rulesUngrouped: allRules,
        rulesGrouped: rulesGrouped
      }
    }
    return result;
  }


  /**
   * Supporting private methods
   */

  private groupIntoRuleSets(
    allRules: Rule[],
    timingRiskPayorRiskCombo: string[]
  ): RuleSet {
    const risks = timingRiskPayorRiskCombo.map((item) => {
      const [timingRisk, payorRisk] = item.split("|");
      return {
        timingRisk,
        payorRisk,
      };
    });

    return risks.map((risk) => {
      const allRulesFiltered: Rule[] = allRules.filter((rule) => {
        return (
            rule.timingRisk.timingRiskName === risk.timingRisk &&
            rule.payorRisk.payorRiskName === risk.payorRisk
        );
      });
      const ruleTableView: RuleTableViewModel[] = this.mappingHelper.mapToRuleTableViewModel(
          allRulesFiltered
      );

      return ruleTableView;
    });
  }
}

export const rulesService = new RulesService();
