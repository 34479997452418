import React from "react";
import { Button, GenericLevelOfCare } from "@finpay-development/shared-components";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "lodash";
import { useSelector } from "react-redux";
import { formatNumberToUSD } from "../../../shared/calculators";
import { TableColumn } from "../../../shared/model/table-column";
import { RootState } from "../../../shared/state/root-reducer";
import { PriorCarePFR } from "../../models/estimator";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";

interface EstPriorCareSummaryModalProps {
  open: boolean;
  handleSummaryModalCancel: () => void;
}

export function EstPriorCareSummaryModal(props: EstPriorCareSummaryModalProps) {
  const { open, handleSummaryModalCancel } = props;

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

  const { vobState, estimatorState } = state;

  const locTableHeaders: TableColumn[] = [
    { id: "levelOfCareCode", label: "Code", minWidth: 200 },
    { id: "levelOfCareName", label: "Name", minWidth: 200 },
    { id: "colLocRate", label: "Rate" },
    { id: "colLocDays", label: "Days/Sessions" },
  ];

  const priorLevelsOfCare = admissionsAdvisorUtils.getPriorLevelsOfCare(
    estimatorState?.priorCare
  );
  const genericPriorLoc = admissionsAdvisorUtils.getGenericPriorAdjChg(
    priorLevelsOfCare,
    estimatorState,
    vobState
  );
  const priorCareAdjustment: PriorCarePFR =
    admissionsAdvisorUtils.getPriorCareAdjChg(estimatorState, vobState);

  function handleModalCancel() {
    handleSummaryModalCancel();
  }

  function adjustedChgs() {
    let charges = 0.0;
    genericPriorLoc.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      charges += loc?.rate * loc?.days;
    });
    return formatNumberToUSD(charges);
  }

  function estimatedLos() {
    let los = 0;
    genericPriorLoc.forEach((loc) => {
      if(loc.cfgLocType === 1) return
      los += loc?.days;
    });
    return los;
  }

  function getDeductibleSpentPct() {
    let spentPct: string =
      (
        (priorCareAdjustment?.priorCarePFR.deductibleSpent /
          priorCareAdjustment?.priorCarePFR.deductible) *
        100
      ).toFixed(0) + "%";
    return spentPct;
  }

  function getOopSpentPct() {
    let spentPct: string = "N/A";
    if (priorCareAdjustment?.priorCarePFR?.includeOOPmax) {
      spentPct =
      (
        (((priorCareAdjustment?.priorCarePFR?.oopMaxFromVob -
          priorCareAdjustment?.priorCarePFR?.remainingOopMaxFromVob) + priorCareAdjustment.priorCarePFR.coveredSvcsPfr) /
          priorCareAdjustment?.priorCarePFR?.oopMaxFromVob) *
        100
      ).toFixed(0) + "%";
    }
    return spentPct;
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Prior Care Summary</DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleModalCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography variant="subtitle2">Prior Care Plan Details</Typography>

        {estimatorState.hasPriorCare && (
          <>
            {genericPriorLoc?.length && (
              <>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        {locTableHeaders.map((column) => (
                          <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.sortBy(genericPriorLoc, "name")
                      .filter((loc: GenericLevelOfCare) => {
                        if(loc.cfgLocType === 1) return false
                        return true;
                      }).map((row, index) => {
                        return (
                          <TableRow key={`${row.code}.${index}`}>
                            <TableCell>{row.code}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.rate}</TableCell>
                            <TableCell>{row.days}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow className="py-3 light-green-row">
                        <TableCell colSpan={3}>
                          Estimated Prior Care Adjusted Charges
                        </TableCell>
                        <TableCell align="right">{adjustedChgs()}</TableCell>
                      </TableRow>
                      <TableRow className="py-3 light-green-row">
                        <TableCell colSpan={3}>
                          Estimated Prior Care Length of Stay
                        </TableCell>
                        <TableCell align="right">{estimatedLos()}</TableCell>
                      </TableRow>
                      <TableRow className="py-3 light-green-row">
                        <TableCell colSpan={3}>
                          Estimated Prior Care PFR
                        </TableCell>
                        <TableCell align="right">
                          {formatNumberToUSD(
                            priorCareAdjustment?.priorCarePFR?.netPFR
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className="py-3 light-green-row">
                        <TableCell
                          colSpan={3}
                        >{`Estimated Prior Care Deductible Spent (${getDeductibleSpentPct()})`}</TableCell>
                        <TableCell align="right">
                          {formatNumberToUSD(
                            priorCareAdjustment?.priorCarePFR?.deductibleSpent
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow className="py-3 light-green-row">
                        <TableCell
                          colSpan={3}
                        >{`Estimated Prior Care Max OOP Met (${getOopSpentPct()})`}</TableCell>
                        <TableCell align="right">

                          {priorCareAdjustment?.priorCarePFR?.includeOOPmax?
                            formatNumberToUSD(
                              (priorCareAdjustment?.priorCarePFR?.oopMaxFromVob -
                                priorCareAdjustment?.priorCarePFR?.remainingOopMaxFromVob) + priorCareAdjustment.priorCarePFR.coveredSvcsPfr
                            )
														:
														<>N/A</>
													}
                        </TableCell>
                      </TableRow>
                      {priorCareAdjustment?.priorCarePFR?.remainingOopMaxAfterServices > 0 && (
                        <TableRow className="py-3 light-green-row">
                          <TableCell
                            colSpan={3}
                          >{`Estimated Prior Care Max OOP Remaining`}</TableCell>
                          <TableCell align="right">
                            {priorCareAdjustment?.priorCarePFR?.includeOOPmax?
                              formatNumberToUSD(
                                  priorCareAdjustment?.priorCarePFR?.remainingOopMaxAfterServices
                              )
                              :
                              <>N/A</>
                            }
                          </TableCell>
                        </TableRow>
                      )}

                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}

        <Typography variant="subtitle2" className="mt-2">
          Adjusted PFR Details
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" className="alternating-row">
            <TableBody>
              <TableRow className="py-3">
                <TableCell>Estimated Co-Pay</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment.adjustedCurrentPFR?.patientTotalCoPays
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Estimated Deductible</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment.adjustedCurrentPFR?.deductibleSpent
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Estimated Co-Insurance</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment.adjustedCurrentPFR
                      ?.patientTotalCoInsurance
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell></TableCell>
                <TableCell align="right" sx={{ borderTop: "2px solid #999" }}>
                  <strong>
                    {formatNumberToUSD(
                      priorCareAdjustment.adjustedCurrentPFR?.coveredSvcsPfr
                    )}
                  </strong>
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Out of Pocket Max Remaining</TableCell>
                <TableCell align="right">
                  {priorCareAdjustment.adjustedCurrentPFR?.includeOOPmax?
                    formatNumberToUSD(priorCareAdjustment.adjustedCurrentPFR?.remainingOopMaxFromVob - priorCareAdjustment.priorCarePFR.netPFR)
                    :
                    <>N/A</>
                  }
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>PFR for Covered Services</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment.adjustedCurrentPFR?.netPFR
                  )}
                </TableCell>
              </TableRow>
              <TableRow className="py-3">
                <TableCell>Uncovered Services</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    priorCareAdjustment.adjustedCurrentPFR?.unCoveredCharges
                  )}
                </TableCell>
              </TableRow>
              {priorCareAdjustment.adjustedCurrentPFR.totalSurcharges > 0 &&
              <>
                  <TableRow className="py-3">
                    <TableCell>PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(priorCareAdjustment.adjustedCurrentPFR.subtotalPFR)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(priorCareAdjustment.adjustedCurrentPFR.totalSurcharges)}
                    </TableCell>
                  </TableRow>
                </>
              }
              <TableRow className="py-3">
                <TableCell>
                  <Typography variant="subtitle2">
                    Estimated Patient Financial Responsibility
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {formatNumberToUSD(
                      priorCareAdjustment.adjustedCurrentPFR?.totalPFR
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleModalCancel()}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
