import { SysRolePage } from "../../security/model/sys-role-page";
import { SysRolePageAction } from "../../security/model/sys-role-page-action";
import { UserRole } from "../../security/model/user-role";
import { UserRolePage } from "../../security/model/user-role-page";
import { UserRolePageAction } from "../../security/model/user-role-page-action";
import { AdminNavigationState } from "../models/admin-navigation-state";
import { RolePageActionState, RolePageState } from "../models/role-page-state";

  // Build dynamic field state for formik to use for pages & actions
  export function buildUserRolePages(systemRoles: SysRolePage[], currentUserRole: UserRole): AdminNavigationState {

    const result: AdminNavigationState = new AdminNavigationState();

    result.isEditMode = currentUserRole.userRoleId != null;
    result.userRoleId = currentUserRole.userRoleId;
    result.roleName = currentUserRole.roleName;
    result.roleDescription = currentUserRole.roleDesc;
    result.pageKeys = [
      "navRoles",
      "navConfiguration",
      "navUsers",
      "navLogs",
      "navReports",
      "navClients",
      "navPatientRecords",
      "navClientPortal",
      "navPatientDashboard",
      "navAdmissionsAdvisor",
      ]

    result.navConfiguration = mapRulePageState(systemRoles, currentUserRole, "navConfiguration");
    result.navRoles = mapRulePageState(systemRoles, currentUserRole, "navRoles");
    result.navUsers = mapRulePageState(systemRoles, currentUserRole, "navUsers");
    result.navLogs = mapRulePageState(systemRoles, currentUserRole, "navLogs");
    result.navReports = mapRulePageState(systemRoles, currentUserRole, "navReports");
    result.navClients = mapRulePageState(systemRoles, currentUserRole, "navClients");
    result.navPatientRecords = mapRulePageState(systemRoles, currentUserRole, "navPatientRecords");
    result.navClientPortal = mapRulePageState(systemRoles, currentUserRole, "navClientPortal");
    result.navPatientDashboard = mapRulePageState(systemRoles, currentUserRole, "navPatientDashboard");
    result.navAdmissionsAdvisor = mapRulePageState(systemRoles, currentUserRole, "navAdmissionsAdvisor");

    return result;
  }

  // Build Navigation Page State (set flags based on save role data or defaults for new user roles)
  function mapRulePageState(systemRoles: SysRolePage[], currentUserRole: UserRole, sysRoleKey: string ) : RolePageState {

    const pageState: RolePageState =
    {
        sysRoleKey: sysRoleKey,
        isEnabled: false,
        isReadOnly: false,
        isEditable: false,
        parent: "",
        actions: []
    };

    const pageItem = systemRoles.find(
      (pageState: SysRolePage) => pageState.sysRoleKey === sysRoleKey
    );

    if (pageItem) {
      const userPageRole = currentUserRole.userRolePages.find(
        (pageRole: UserRolePage) => pageRole.sysRolePage.sysRolePageId === pageItem.sysRolePageId
      );

      if (userPageRole) {
        pageState.isEnabled = userPageRole.isEnabled;
        pageState.isReadOnly = userPageRole.isReadOnly;
        pageState.isEditable = pageState.isEnabled ? !userPageRole.isReadOnly : false;
      }

      if(pageItem.sysRolePageAction) {
        pageItem.sysRolePageAction.forEach(function (actionItem: SysRolePageAction) {
            const action: RolePageActionState = {
                actionKey: actionItem.actionKey,
                isEnabled: false
            };
  
            const userAction = userPageRole?.userRolePageActions.find(
              (pageAction: UserRolePageAction) => pageAction.sysRolePageAction.sysRolePageActionId === actionItem.sysRolePageActionId
            );
  
            if (userAction) {
              action.isEnabled = userAction.isEnabled;
            }
  
            pageState.actions.push(action);
        })
      }

    }

    return pageState;
  }

