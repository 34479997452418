import { emptyPrimaryContact, PrimaryContact } from "../../../shared/model/primary-contact";

export interface PatientChampion {
  patientChampionId: number;
  patientEncounterId: number;
  contact: PrimaryContact;
  relationshipToPatient: string;
  isGuarantor: boolean;
  phiDocumentStatus: boolean;
  userId?: number;
}

export const emptyPatientChampion: PatientChampion = {
  patientChampionId: 0,
  patientEncounterId: 0,
  contact: emptyPrimaryContact,
  relationshipToPatient: '',
  isGuarantor: false,
  phiDocumentStatus: true,
}
export interface miniChampion { // used in down-payment-modal
  id: number,
  name: string,
  email: string,
  isGuarantor: boolean
}
export interface phiChampion {
  patientChampionId: number;
  relationship: string;
  phone: string;
  championFirstName: string;
  championLastName: string;
}
export interface PhiHelloSignRequest {
  champions: phiChampion[];
  patientEmail: string;
  patientName: string;
  clientId: number;
  patientEncounterId: number;
}

export enum PhiStatus {
  included = 'Included',
  sent = 'Pending Signature',
  signed = 'Signature Complete',
  excluded = 'Excluded',
}