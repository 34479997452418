import axios from "axios";
import { configSettings } from "../configuration/config-settings";
import { AxiosDeletePayload } from "./axios-delete-payload";
import { AxiosResultDeleteStatus } from "./axios-result-delete-status";

 export const axiosDeleteHelper = async function(payload: AxiosDeletePayload): Promise<AxiosResultDeleteStatus> {

    const saveResult = new AxiosResultDeleteStatus();

    let url: string;
    switch(true) {
      case (payload.dataId === -2):
          url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
          break;
      case (payload.dataId === -1):
          url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
          break;
      case (payload.dataId === 0):
          url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
          break;
      case (payload.dataId > 0):
          url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
          break;
      default:
          url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
          break;
    }


    await axios
      .delete(url)
      .then(function (response) {
        saveResult.status = response.status;
        saveResult.entity = response.data
      })
      .catch(function (error: Error) {
        saveResult.errorMessage = error.message;
        console.log(error);
      });
 
    saveResult.hasErrors = saveResult.errorMessage?.length > 0;
    console.log(saveResult)
    return saveResult;
 }