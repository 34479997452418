import React, { useEffect, useState } from "react";
import AccessControl from "../../../../../security/components/access-control";
import { RolePageNames } from "../../../../../security/model/role-page-names";
import {
  Box,
  Divider,
  Grid,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Container,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import { Button, LoadingOverlay } from "@finpay-development/shared-components";
import { RootState } from "../../../../../shared/state/root-reducer";
import { showStatus } from "../../../../../security/state/user-slice";
import PESScriptModal from "./pes-script-modal";
import { ImplementationPESScript } from "../models/implementation-pes-script";
import {
  getClientPESScript,
  getClientPESScripts,
  getPesScriptDocument,
} from "../../../../state/pes-script/implementation-pesscript-thunk";
import { clearPESScriptForm } from "../../../../state/pes-script/implementation-pesscript-slice";
import { ClientStatusCardViewModel } from "../../../../../shared/model/client-status-card";

interface PESScriptColumn {
  id: string;
  label: string;
  align?: "right";
  inlineSize?: number;
}
const columns: PESScriptColumn[] = [
  { id: "riskClass", label: "Risk Class Name", inlineSize: 170 },
  { id: "scriptUrl", label: "Script URL", inlineSize: 200 },
  { id: "menu", label: "Menu", inlineSize: 25 },
];

type PESScriptActionType = "edit" | "test";



export default function PESScriptView() {

  const [addPESScriptModalOpen, setAddPESScriptModalOpen] = useState(false);
  const [editPESScriptModalOpen, setEditPESScriptModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showEditPESScript, setHideEditPESScript] = useState(true);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [pesScript, setPESScript] = useState<ImplementationPESScript>();
  const [facilities, setFacilities] = useState<ClientStatusCardViewModel[]>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<any>(null)

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client;
    }),
    currentClientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .currentClientEntityDetails.clientId
    ),
    allClientsWithFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .allClientsWithFacillities
    ),
    implementationPESScripts: useSelector(
      (state: RootState) =>
        state.pesScriptContext.implementationPesScript.pesScripts
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.pesScriptContext.implementationPesScript.isLoading
    ),
  };

  const {
    currentClientId,
    isLoading,
    implementationPESScripts,
    allClientsWithFacilities,
  } = selectors;

  const [selectedValue, setSelectedValue] = useState<any>({ clientId: currentClientId });


  const handleOpenMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    pesScript: ImplementationPESScript
  ) => {
    setAnchorElement(event.currentTarget);
    setPESScript(pesScript);
  };

  useEffect(() => {
    setSelectedValue({ clientId: currentClientId });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentFacilityId(selectedValue?.facilityId || null);

    if (!currentClientId && !isLoading) {
      setSelectedValue({ clientId: currentClientId });
    }
  }, [selectedValue, currentClientId, isLoading]);


  useEffect(() => {
    const fetchPesScriptData = async () => {
      if (currentClientId && !isLoading) {
        await dispatch(getClientPESScripts({ clientId: currentClientId, facilityId: currentFacilityId }));
      }
    };
    fetchPesScriptData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClientId, currentFacilityId,dispatch]);

  useEffect(() => {
    if (allClientsWithFacilities) {
      setFacilities(allClientsWithFacilities);
    }
  }, [allClientsWithFacilities]);


  const handleOpenPesScript = () => {
    if (pesScript) {
      dispatch(getPesScriptDocument(pesScript));
    }
  };

  const handleCloseMenu = (menuActionType?: PESScriptActionType) => {
    setAnchorElement(null);
    setHideEditPESScript(true);

    switch (menuActionType) {
      case "edit":
        setEditPESScriptModalOpen(true);
        dispatch(getClientPESScript({ pesScriptId: pesScript!.pesScriptId }));
        break;
      case "test":
        handleOpenPesScript();

        break;
    }
  };

  const handlePESScriptModalCancel = () => {
    setEditPESScriptModalOpen(false);
    setAddPESScriptModalOpen(false);
  };

  const handlePESScriptModalSubmit = (isEditPESScript: boolean) => {
    setEditPESScriptModalOpen(false);
    setAddPESScriptModalOpen(false);

    if (isEditPESScript) {
      dispatch(showStatus("PES Script Updated"));
    } else {
      dispatch(showStatus("PES Script Created"));
    }
  };

  const selectedClient = facilities.find(
    (client) => client.clientId === currentClientId
  );

  const handleChange = (event: any) => {

    const selectedValueArr = event?.target.value.split("-")
    const selectedOptions: any = +selectedValueArr[1];

    if (!selectedOptions) {
      throw new Error("No selected options")
    }

    const selectedObj = selectedValueArr[0] === 'client' ?
        facilities.find((client) =>
    client.clientId === selectedOptions) : facilities.find((client) =>
            client?.clientFacilities?.some((facility) => facility.facilityId === selectedOptions)
        )

  if (selectedObj?.clientFacilities) {
    const selectedFacility = selectedObj.clientFacilities.find((facility) => facility.facilityId === selectedOptions);
    setSelectedValue({ ...selectedObj, facilityId: selectedFacility?.facilityId });
    setCurrentFacilityId(selectedFacility?.facilityId);
  } else {
    setSelectedValue(selectedObj);
    setCurrentFacilityId(null);
  }

    const selectedFacilityNames = Array.from(selectedOptions, (option: any) => option.value);

    const newFacilities = facilities.map((facility) => {
      return {
        ...facility,
        isActive: selectedFacilityNames?.includes(facility?.clientFacilities?.map((f) => f.facilityId)),
      };
    });
    setFacilities(newFacilities);
  };


  const addPESScriptMessage = (
    <Box
    sx={{
      bgcolor: 'background.paper',
      pt: 8,
      pb: 6,
    }}
  >
    <Container maxWidth="sm">
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
      >
        Please add a PES Script.
      </Typography>
    </Container>
  </Box>
  );

  return (
    <>
      <div className="pesscript-view" style={{
        display: 'flex',
      }}>
        <Grid>
          <Box display="flex" justifyContent="flex-start">
            <FormControl
              variant="standard"
              style={
                {
                  width: 220,
                  paddingLeft:7
                }
              }
            >
              <InputLabel
                id="client-view-select-label"
                style={
                  {
                    paddingLeft:7
                  }
                }
              >
                {selectedClient?.clientName}
              </InputLabel>
              <Select
                labelId="client-view-select-label"
                id="client-view-select"
                value={selectedClient?.clientName}
                onChange={handleChange}
                label="Select Client"
                test-id="pes-script-client-view-select"
              >
                <MenuItem
                  value={`client-${selectedClient?.clientId}`}
                  key={`client-${selectedClient?.clientId}`}
                  test-id={`client-${selectedClient?.clientId}`}
                >
                  {selectedClient?.clientName}
                </MenuItem>
                {selectedClient &&
                  selectedClient.clientFacilities?.map((facility) => (
                    <MenuItem
                        key={`facility-${facility?.facilityId}`}
                        value={`facility-${facility?.facilityId}`}
                        test-id={`facility-${facility?.facilityId}`}
                    >
                      {facility?.facilityName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <AccessControl
              rolePageName={RolePageNames.Clients}
              actionName="Add New Client"
              renderNoAccess={() => <Button disabled>Add New Client</Button>}
            >
              <Button
                onClick={() => {
                  dispatch(clearPESScriptForm());
                  setSelectedValue(selectedValue)
                  setAddPESScriptModalOpen(true);
                }}
                marginLeft={4}
              >
                Add New PES Script
              </Button>
            </AccessControl>
          </Grid>
        </Grid>

        <Menu
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
          keepMounted
          open={Boolean(anchorElement)}
          anchorEl={anchorElement}
          onClose={() => handleCloseMenu()}
        >
          <div>
            <AccessControl
              rolePageName={RolePageNames.Clients}
              actionName="Add New Client"
              renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}
            >
              <MenuItem
                className="primary"
                onClick={() => handleCloseMenu("edit")}
              >
                Edit
              </MenuItem>
            </AccessControl>

            <AccessControl
              rolePageName={RolePageNames.Clients}
              actionName="Add New Client"
              renderNoAccess={() => <MenuItem disabled>Test</MenuItem>}
            >
              <MenuItem
                className="primary"
                onClick={() => handleCloseMenu("test")}
              >
                Test
              </MenuItem>
            </AccessControl>
          </div>
        </Menu>

        {addPESScriptModalOpen && (
          <PESScriptModal
            open={addPESScriptModalOpen}
            handlePESScriptModalCancel={handlePESScriptModalCancel}
            handlePESScriptModalSubmit={handlePESScriptModalSubmit}
            isEditPESScript={false}
            selectedValue={selectedValue}
          />
        )}

        {editPESScriptModalOpen && (
          <PESScriptModal
            open={editPESScriptModalOpen}
            handlePESScriptModalCancel={handlePESScriptModalCancel}
            handlePESScriptModalSubmit={handlePESScriptModalSubmit}
            isEditPESScript={true}
            selectedValue={selectedValue}
          />
        )}
      </div>

      {selectors.isLoading ? (
        <LoadingOverlay />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          spacing={2}
        >
          <Grid item xs={12}>
            <Paper className="mt-8">
              <Divider />

              <>
                {implementationPESScripts &&
                implementationPESScripts.length > 0 ? (
                  <TableContainer>
                    <Table
                      data-testid="results"
                      className="table alternating-row"
                      size="small"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ inlineSize: column.inlineSize }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {implementationPESScripts.map((pesScript, index) => {
                          return (
                            <TableRow
                              data-testid="result-row"
                              hover
                              tabIndex={-1}
                              key={index}
                            >
                              {columns.map((column) => {
                                return (
                                  <>
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{ inlineSize: column.inlineSize }}
                                    >
                                      <Typography variant="body1">
                                        {column.id === "scriptUrl" &&
                                          pesScript?.scriptUrl}
                                      </Typography>
                                      <Typography variant="body1">
                                        {column.id === "riskClass" &&
                                          pesScript?.riskClass?.riskClassName}
                                      </Typography>
                                      {column.id === "menu" && (
                                        <>
                                          <IconButton
                                            aria-controls="simple-menu"
                                            aria-haspopup="true"
                                            onClick={(e) =>
                                              handleOpenMenu(e, pesScript)
                                            }
                                            size="large"
                                          >
                                            <MoreHorizIcon />
                                          </IconButton>
                                        </>
                                      )}
                                    </TableCell>
                                  </>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  addPESScriptMessage
                )}
              </>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}
