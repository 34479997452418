import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SysRolePage } from "../../security/model/sys-role-page";
import { UserRoleListViewModel } from "../../security/model/user-role";
import { AddEditRoleState, emptyAddEditRoleState } from "../models/add-edit-role-state";
import { getAdminUserRoles, getUserRole, saveUserRole } from "./admin-roles-thunk";

interface AdminRolesState {
  counterRoles: number;
  userRoles: UserRoleListViewModel;
  systemRoles: SysRolePage[];
  currentUserRole: AddEditRoleState;
  isLoading: boolean;
}
const emptyRoles: UserRoleListViewModel = {
  internalUserRoles: [],
  externalUserRoles: []
};

const emptySystemRoles: SysRolePage[] = [];

const initialState = {
  counterRoles: 1,
  userRoles: emptyRoles,
  systemRoles: emptySystemRoles,
  currentUserRole: emptyAddEditRoleState,
  isLoading: false
} as AdminRolesState;

const adminRolesSlice = createSlice({
  name: "adminRoleContext",
  initialState,
  reducers: {
    setCounter: (state, action: PayloadAction<number>) => {
      state.counterRoles = action.payload
    },
    clearAddEditState: (state) => {
      state.systemRoles = emptySystemRoles;
      state.currentUserRole = emptyAddEditRoleState;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => { // may not be needed. (unused currently). re-evaluate later
      state.isLoading = action.payload
    },
    resetAdminRoleContext() {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminUserRoles.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAdminUserRoles.fulfilled, (state, action) => {
      state.userRoles = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAdminUserRoles.rejected, (state, action) => {
      state.isLoading = false;
    });
    // builder.addCase(getPageRolesMetaData.fulfilled, (state, action) => {
    //   state.systemRoles = action.payload;
    // });
    builder.addCase(saveUserRole.pending, (state, action) => {
      state.counterRoles = 0;
      state.isLoading = false;
      // TODO: Handle Saved User Role - Sync to Store for Role List Screen
    });
    builder.addCase(saveUserRole.fulfilled, (state, action) => {
      state.counterRoles = 1;
      state.userRoles = action.payload.entity;
      state.isLoading = true;
      // TODO: Handle Saved User Role - Sync to Store for Role List Screen
    });
    builder.addCase(saveUserRole.rejected, (state, action) => {
      state.isLoading = false;
      // TODO: Handle Saved User Role - Sync to Store for Role List Screen
    });
    builder.addCase(getUserRole.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.systemRoles = action.payload.systemRoles;
      state.currentUserRole = action.payload;
    });
    builder.addCase(getUserRole.rejected, (state, action) => {
      state.isLoading = false;
    });

  }
});

export const {
  setCounter,
  clearAddEditState,
  resetAdminRoleContext
} = adminRolesSlice.actions;
export default adminRolesSlice.reducer;
