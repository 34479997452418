import { Button, Checkbox } from '@finpay-development/shared-components';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomFormikProps } from '../../../shared/model/formik-props';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { ClientFilter as Filter } from '../../models/client-filter';
import {
  resetFilterFormValues,
  setAllCheckboxFilterChecked,
  setFilterFormValues,
} from '../../state/clients/implementation-clients-slice';

interface ClientFilterProps {
  filterCounts: {[key: string]: number}
}

function ClientFilter(props: ClientFilterProps) {
  const { filterCounts } = props;

  const [ followUpCheckboxChecked, setFollowUpCheckboxChecked ] = useState(false);
  const [ allCheckboxChecked, setAllCheckboxChecked ] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  const filterFormValues = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filterFormValues;
  })

  const handleReset = () => {
    dispatch(resetFilterFormValues())
  }

  const handleFollowUpClick = (isFollowUpChecked: boolean, formik: CustomFormikProps) => {
    dispatch(setFilterFormValues({
      recentlyViewed: filterFormValues.recentlyViewed,
      all: filterFormValues.all,
      active: filterFormValues.active,
      closed: filterFormValues.closed,
      stripeActionNeeded: filterFormValues.stripeActionNeeded,
      followUp: isFollowUpChecked,
      readyForActivation: isFollowUpChecked ? true : false,
      setUpIncomplete: isFollowUpChecked ? true : false,
      stripeSetupIncomplete: isFollowUpChecked ? true : false,
    }))
  }

  const handleAllClick = () => {
    dispatch(setAllCheckboxFilterChecked())
  }

  // for all filter checkboxes OTHTER than "all"
  const handleFilterCheckboxChange = (field: string, isChecked: boolean, formik: CustomFormikProps) => {
    dispatch(setFilterFormValues({
      ...formik.values,
      [field]: isChecked
    }))
  }

  const initialValues: Filter = {
    recentlyViewed: filterFormValues.recentlyViewed,
    all: filterFormValues.all,
    active: filterFormValues.active,
    closed: filterFormValues.closed,
    stripeActionNeeded: filterFormValues.stripeActionNeeded,
    followUp: filterFormValues.followUp,
    readyForActivation: filterFormValues.readyForActivation,
    setUpIncomplete: filterFormValues.setUpIncomplete,
    stripeSetupIncomplete: filterFormValues.stripeSetupIncomplete,
  };

  return (
    <div className="client-filters">
      <Typography variant="subtitle1" className="mb-6">
        Filters
      </Typography>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
      >
        {(formik) => (
          <Form>
            <Checkbox
              checked={formik.values.recentlyViewed}
              id="recently-viewed"
              label="Recently Viewed"
              name="recentlyViewed"
              onChange={(e: Event) => {
                formik.handleChange(e);
                handleFilterCheckboxChange("recentlyViewed", (e.target as HTMLInputElement).checked, formik);
              }}
            />

            <Checkbox
              checked={formik.values.all}
              id="all"
              label={`All (${filterCounts.allClients})`}
              name="all"
              onChange={(e: Event) => {
                formik.handleChange(e);
                setAllCheckboxChecked(!allCheckboxChecked);
                handleAllClick();
              }}
            />

            <Checkbox
              checked={formik.values.active}
              id="active"
              label={`Active (${filterCounts.filteredByIsActive})`}
              name="active"
              onChange={(e: Event) => {
                formik.handleChange(e);
                handleFilterCheckboxChange("active", (e.target as HTMLInputElement).checked, formik);
              }}
            />

            <Checkbox
              checked={formik.values.closed}
              id="closed"
              label={`Closed (${filterCounts.filteredByClosed})`}
              name="closed"
              onChange={(e: Event) => {
                formik.handleChange(e);
                handleFilterCheckboxChange("closed", (e.target as HTMLInputElement).checked, formik);
              }}
            />

            <Checkbox
              checked={formik.values.stripeActionNeeded}
              id="stripeActionNeeded"
              label={`Stripe Action Needed (${filterCounts.filteredByStripeActionNeeded})`}
              name="stripeActionNeeded"
              onChange={(e: Event) => {
                formik.handleChange(e);
                handleFilterCheckboxChange("stripeActionNeeded", (e.target as HTMLInputElement).checked, formik);
              }}
            />

            <Divider className="mt-4"></Divider>

            <Accordion defaultExpanded={true} className="mt={4}" elevation={0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="accordion-entity"
              >
                  <Checkbox
                    checked={formik.values.followUp}
                    id="followUp"
                    name="followUp"
                    label={`Follow Up (${filterCounts.filteredByReadyForActivation
                        + filterCounts.filteredBySetUpIncomplete
                        + filterCounts.filteredByStripeSetupIncomplete})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      setFollowUpCheckboxChecked(!followUpCheckboxChecked);
                      handleFollowUpClick((e.target as HTMLInputElement).checked, formik);
                    }}
                  />
              </AccordionSummary>
              <AccordionDetails>
                <div className="ml-6">
                  <Checkbox
                    checked={formik.values.readyForActivation}
                    id="readyForActivation"
                    name="readyForActivation"
                    label={`Ready for Activation (${filterCounts.filteredByReadyForActivation})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      handleFilterCheckboxChange("readyForActivation", (e.target as HTMLInputElement).checked, formik);
                    }}
                  />
                  <Checkbox
                    checked={formik.values.setUpIncomplete}
                    id="setUpIncomplete"
                    name="setUpIncomplete"
                    label={`Set Up Incomplete (${filterCounts.filteredBySetUpIncomplete})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      handleFilterCheckboxChange("setUpIncomplete", (e.target as HTMLInputElement).checked, formik);
                    }}
                  />
                  <Checkbox
                    checked={formik.values.stripeSetupIncomplete}
                    id="stripeSetupIncomplete"
                    name="stripeSetupIncomplete"
                    label={`Stripe Set Up Incomplete (${filterCounts.filteredByStripeSetupIncomplete})`}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      handleFilterCheckboxChange("stripeSetupIncomplete", (e.target as HTMLInputElement).checked, formik);
                    }}
                  />
                </div>
              </AccordionDetails>
            </Accordion>

            <Button
              onClick={handleReset}
              type="text"
              marginTop={8}
              paddingLeft={0}
              icon={<ClearIcon />}
            >
              Clear Filters
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ClientFilter;
