import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  Chip,
} from "@mui/material";
import { AppDispatch } from "../../../../../shared/state/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/state/root-reducer";
import { ImplementationFacility } from "../models/implementation-facility";
import {
  TextField,
  saveCallbackStatus,
} from "@finpay-development/shared-components";
import { getClientFacilities } from "../../../../state/facility/implementation-facility-thunk";
import EditIcon from "@mui/icons-material/Edit";
import { RiskSettingsModal } from "./risk-settings-modal";
import { showStatus } from "../../../../../security/state/user-slice";
import { getFacilityRiskThresholds } from "../../../../../admissions-advisor/state/risk-assessment-thunk";
import { clearFacilityRiskThresholdsStatus } from "../../../../../admissions-advisor/state/risk-assessment-slice";
import {
  ConfigRiskThreshold,
  RiskThresholdSymbols,
} from "../../../../../admin-configuration/models/risk-threshold";
import { FacilityRiskThreshold } from "../../../../../admissions-advisor/models/risk-assessment";
import { configGetRiskThresholds } from "../../../../../admin-configuration/state/admin-configuration-thunk";
import { clearStatus } from "../../../../../admin-configuration/state/admin-configuration-slice";
import { Utils } from "../../../../../shared/utils";
import { riskAssessmentUtils } from "../../../../../admissions-advisor/utils/risk-assessment-utils";

function RiskSettings() {
  const paramId = -2;
  const [isRiskSettingsModalOpen, setIsRiskSettingsModalOpen] = useState(false);
  const [selectedRiskSetting, setSelectedRiskSetting] = useState<FacilityRiskThreshold>();
  const [selectedConfigRiskSetting, setSelectedConfigRiskSetting] = useState<ConfigRiskThreshold>();
  const [riskThresholdConfigData, setRiskThresholdConfigData] = useState<ConfigRiskThreshold[]>([]);
  const [riskThresholdData, setRiskThresholdData] = useState<FacilityRiskThreshold[]>([]);
  const [selectedFacilityId, setSelectedFacilityId] = useState(-1);
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    fullClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
    ),
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.facilities
    ),
    configRiskThresholds: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.riskThresholds
    ),
    isLoadingConfigRiskThresholdsState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .isLoadingRiskThresholds
    ),
    getConfigRiskThresholdsStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading
          .getRiskThresholdsStatus
    ),
    facilityRiskThresholds: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext
          ?.facilityRiskThresholds
    ),
    isLoadingFacilityRiskThresholdsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .isLoadingFacilityRiskThresholds
    ),
    facilityRiskThresholdsStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.riskAssessmentContext?.isLoading
          .facilityRiskThresholdsStatus
    ),
  };

  const {
    facilities,
    configRiskThresholds,
    isLoadingConfigRiskThresholdsState,
    getConfigRiskThresholdsStatus,
    facilityRiskThresholds,
    isLoadingFacilityRiskThresholdsState,
    facilityRiskThresholdsStatusState,
  } = selectors;

  useEffect(() => {
    if (selectors.fullClient.clientId > 0) {
      dispatch(getClientFacilities(selectors.fullClient.clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchFacilityThresholds = async () => {
      dispatch(
        getFacilityRiskThresholds({
          paramId: paramId,
          facilityId: Number(selectedFacilityId),
        })
      );
    };
    if (Number(selectedFacilityId) > 0) {
      fetchFacilityThresholds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId]);

  useEffect(() => {
    if (
      !isLoadingConfigRiskThresholdsState &&
      getConfigRiskThresholdsStatus === saveCallbackStatus.success
    ) {
      if (configRiskThresholds !== undefined) {
        const dataCopy: ConfigRiskThreshold[] = Utils.deepClone(
          configRiskThresholds
        );
        setRiskThresholdConfigData(dataCopy);
        dispatch(clearStatus());
      }
    }

    if (
      !isLoadingFacilityRiskThresholdsState &&
      facilityRiskThresholdsStatusState === saveCallbackStatus.success
    ) {
      if (facilityRiskThresholds !== undefined) {
        const mergedRiskThresholds: FacilityRiskThreshold[] =
          riskAssessmentUtils.mergeConfigFacilityRiskThresholds(
            configRiskThresholds,
            facilityRiskThresholds,
            selectedFacilityId
          );
        setRiskThresholdData(mergedRiskThresholds);
        dispatch(clearFacilityRiskThresholdsStatus());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingConfigRiskThresholdsState,
    isLoadingFacilityRiskThresholdsState,
  ]);

  const getConfigRiskThresholds = async () => {
    dispatch(configGetRiskThresholds({ paramId: paramId }));
  };

  useEffect(() => {
    getConfigRiskThresholds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFacilityChange = (e: Event) => {
    const selectedFacId = (e.target as HTMLInputElement).value;
    setSelectedFacilityId(+selectedFacId);
  };

  const getFacilityValue = (riskThresholdId: number, threshHold: string): string => {
    let returnValue: string = "";

    const facilityData = riskThresholdData?.find(
      (facThreshold) => facThreshold.cfgRiskThresholdId === riskThresholdId
    );
    if (facilityData) {
      switch (threshHold) {
        case "low":
          returnValue = facilityData?.lowRisk!.toString();
          break;
        case "medium":
          returnValue = facilityData?.mediumRisk!.toString();
          break;
        case "high":
          returnValue = facilityData?.highRisk!.toString();
          break;
        default:
        // nothing
      }
    }

    return returnValue;
  };

  const handleBlur = () => {};

  const handleEditRiskClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    riskThresholdId: number
  ) => {
    setIsRiskSettingsModalOpen(true);
    let selectedConfig = riskThresholdConfigData.find(
      (el) => el.riskThresholdId === riskThresholdId
    );
    if (selectedConfig) {
      setSelectedConfigRiskSetting(selectedConfig);
    }
    setSelectedRiskSetting(
      riskThresholdData?.find((el) => el.cfgRiskThresholdId === riskThresholdId)
    );
  };

  function handleRiskSettingsModalCancel() {
    setIsRiskSettingsModalOpen(false);
  }

  async function handleRiskSettingsModalSubmit() {
    setIsRiskSettingsModalOpen(false);
    dispatch(showStatus("Risk Setting Updated Successfully"));
  }

  return (
    <Paper>
      <Box
        style={{
          margin: "0.5em",
          padding: "1.0em",
          borderBottom: "1px solid #cccccc",
        }}
      >
        <Grid container justifyContent="space-between" direction="row">
          <Grid
            item
            justifyContent="center"
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography variant="subtitle2">
              Risk Assessment Thresholds
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <TextField
              select={true}
              label="Facility"
              name="facility"
              loading={isLoadingFacilityRiskThresholdsState}
              value={selectedFacilityId.toString()}
              onChange={handleFacilityChange}
              onBlur={handleBlur}
            >
              {facilities.flat().map((facility: ImplementationFacility) => (
                <MenuItem key={facility.facilityId} value={facility.facilityId}>
                  {facility.facilityName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          margin: "0.5em",
          padding: "1.0em",
          borderBottom: "1px solid #cccccc",
        }}
      >
        {Number(selectedFacilityId) > 0 && (
          <TableContainer component={Paper}>
            <Table
              data-testid="results"
              className="table alternating-row"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    key="lbl_riskType"
                    align="center"
                    style={{
                      padding: "0.5em",
                    }}
                  ></TableCell>
                  <TableCell
                    key="lbl_lowRisk"
                    align="center"
                    style={{
                      padding: "0.5em",
                    }}
                  >
                    <Chip label="Low Risk" color="success" />
                  </TableCell>
                  <TableCell
                    key="lbl_mediumRisk"
                    align="center"
                    style={{
                      padding: "0.5em",
                    }}
                  >
                    <Chip label="Medium Risk" color="warning" />
                  </TableCell>
                  <TableCell
                    key="lbl_highRisk"
                    align="center"
                    style={{
                      padding: "0.5em",
                    }}
                  >
                    <Chip label="High Risk" color="error" />
                  </TableCell>
                  <TableCell
                    key="action"
                    align="center"
                    style={{
                      padding: "0.5em",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...configRiskThresholds]
                  .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
                  .map((row: ConfigRiskThreshold) => {
                    const symbols: RiskThresholdSymbols =
                      riskAssessmentUtils.getRiskThresholdSymbols(
                        row?.riskThresholdCode
                      );

                    return (
                      <TableRow key={row?.riskThresholdId}>
                        <TableCell align="left" style={{ padding: "0.5em" }}>
                          {row?.riskThresholdName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ paddingLeft: "0.5em" }}
                        >
                            {symbols.lowRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {getFacilityValue(row.riskThresholdId, "low")}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}

                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ paddingLeft: "0.5em" }}
                        >
                            {symbols.mediumRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {getFacilityValue(row.riskThresholdId, "medium")}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}

                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ paddingLeft: "0.5em" }}
                        >
                            {symbols.highRiskSymbol}{" "}
                            {symbols.position === 0 ? symbols.fieldAdornment : ""}
                            {getFacilityValue(row.riskThresholdId, "high")}
                            {symbols.position === 1 ? symbols.fieldAdornment : ""}

                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            color="primary"
                            title="Edit"
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleEditRiskClick(e, row.riskThresholdId);
                            }}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isRiskSettingsModalOpen && (
          <RiskSettingsModal
            open={isRiskSettingsModalOpen}
            handleRiskSettingsModalCancel={handleRiskSettingsModalCancel}
            handleRiskSettingsModalSubmit={handleRiskSettingsModalSubmit}
            riskSettingItem={selectedRiskSetting!}
            configRiskSettingItem={selectedConfigRiskSetting!}
          />
        )}
      </Box>
    </Paper>
  );
}

export default RiskSettings;
