import {createAsyncThunk} from '@reduxjs/toolkit';
import {metaDataService} from '../service/meta-data-service';
import { Utils } from 'src/shared/utils';


export const getCRMMetaData = createAsyncThunk("metaData/getCRMMetaData", async (param: void,thunkAPI) => {

    const response = await metaDataService.getCRMMetaData();

    if (response.hasErrors) {
        return Utils.robustErrorHandler(response,thunkAPI)
    }

    return response.entity
})