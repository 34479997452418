import { Box, FormControl, Grid, MenuItem, Select } from "@mui/material";
import React from "react";
import { ClientStatusCardViewModel } from "../model/client-status-card";

interface FacilityProps {
  selectedClient: ClientStatusCardViewModel;
  handleChange: (e: SelectLOCEvent) => void;
  initFacility: number;
}

interface SelectLOCEvent extends Event {
    target: EventTarget & {
      checked?: boolean;
    };
  }

function ClientFacilityDropDown(props: FacilityProps) {
  return (
    <>   
        <Grid>
            <Box display="flex" justifyContent="flex-start">
                <FormControl
                    variant="standard"
                    style={{width: "auto", paddingLeft:7}}
                >
                    {/* Placeholder for integrating clientname to list                    
                        <InputLabel id="client-view-select-label" style={{paddingLeft:7}}>
                            {props.selectedClient?.clientName}
                        </InputLabel> 
                    */}
                    <Select
                        labelId="client-view-select-label"
                        id="client-view-select"
                        value={props.initFacility || ""}
                        onChange={event => props.handleChange(event as SelectLOCEvent)}
                        label="Select Client"
                        test-id="client-facility-dropdown"
                    >
                        {props.selectedClient && props.selectedClient.clientFacilities?.map((facility, index) => (
                            <MenuItem key={`${facility?.facilityId}${index}`} value={facility?.facilityId}>
                                {facility?.facilityName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Grid>
    </>
  );
}

export default ClientFacilityDropDown;
