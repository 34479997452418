import { Grid, Typography } from '@mui/material';

interface PlatformFooterProps {
    showEmailFooter: boolean;
    channelType: string;
}
  
function FinDigitalPlatformFooter(props: PlatformFooterProps) {
    const { showEmailFooter, channelType } = props;

    const handleFooterClick = () => {
        if (showEmailFooter) {
            return window.location.replace("mailto:support@finpay.com");
        } else {
           return window.open("https://www.finpay.com/finpay-website-user-agreement/", "_blank");
        }
    }

    return (
        <>
            <Grid 
                container direction="row" 
                style={channelType === 'mobile' ? {backgroundColor: "#FFF" } : {backgroundColor: "#F5F7F9"}} 
                className="terms-conditions pb-5 platform-footer"
            >
                <Grid item direction="column" xs={12} md={12} style={{ textAlign: "center" }}>
                <Typography
                    color={'#0093d5'}
                    onClick={() => handleFooterClick()}
                >
                    {showEmailFooter ? <>Having trouble? Contact FinPay at support@finpay.com</> : <>Terms & Conditions</>}
                </Typography>
                </Grid>
            </Grid>
        </>
    );
}

export default FinDigitalPlatformFooter;
