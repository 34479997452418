import { DeleteModal, saveCallbackStatus } from '@finpay-development/shared-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { showErrorStatus, showStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { RuleCriteriaParam } from '../../models/rule';
import { clearStatus, resetFormulaForm } from '../../state/admin-configuration-slice';
import { deleteRuleCriteriaParam, getRuleCriteriaParam } from '../../state/admin-configuration-thunk';
import { FormulaModal } from './formula-modal';


interface FormulaProps {
  name: string;
  ruleCriteriaParam: RuleCriteriaParam;
}

type MenuActionType = "edit" | "delete";

function Formula(props: FormulaProps) {
  const { name, ruleCriteriaParam } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [paramId, setParamId] = useState<number>(0);

  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [showDeleteFormulaModal, setShowDeleteFormulaModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const deleteStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalDeleteStatus
  );

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    formulaId: number
  ) => {
    setParamId(formulaId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);
    switch (menuActionType) {
      case "edit":
        setShowFormulaModal(true);
        dispatch(getRuleCriteriaParam(paramId));
        break;
      case "delete":
        setShowDeleteFormulaModal(true);
        break;
    }
  };

  function handleFormulaModalCancel() {
    setShowFormulaModal(false);
    dispatch(resetFormulaForm());
  }

  function handleFormulaModalSubmit(isEditMode: boolean) {
    setShowFormulaModal(false);
    dispatch(resetFormulaForm());

    if (isEditMode) {
      dispatch(showStatus("Formula Updated"));
    } else {
      dispatch(showStatus("Formula created"));
    }
  }

  function handleDeleteCancel() {
    setShowDeleteFormulaModal(false);
  }

  function handleDeleteOk() {
    dispatch(deleteRuleCriteriaParam(paramId));
    setShowDeleteFormulaModal(false);
  }

  useEffect(() => {
    if (deleteStatus === saveCallbackStatus.success) {
      dispatch(showStatus("Formula deleted"));
      dispatch(clearStatus());
    } else if (deleteStatus === saveCallbackStatus.error) {
      dispatch(showErrorStatus("There is a problem deleting"));
    }
  }, [dispatch, deleteStatus]);

  return (
    <div className="formula">
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="accordion-entity">
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle2" className="mb-1">
                {name}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer className="mb-8">
            <Table
              data-testid="results"
              className="table alternating-row"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: 200 }}>Formula Name</TableCell>
                  <TableCell style={{ minWidth: 200 }}>Data Type</TableCell>
                  <TableCell style={{ minWidth: 200 }}>Compare By</TableCell>
                  <TableCell style={{ minWidth: 30 }}>
                    {/* Blank table heading cell. This column is for menu buttons */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover tabIndex={-1}>
                  <TableCell>
                    <Typography variant="body1">{ruleCriteriaParam?.paramName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{ruleCriteriaParam?.dataType}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                    {ruleCriteriaParam?.compareBy}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">
                      <IconButton
                        aria-haspopup="true"
                        onClick={(e) => handleClick(e, ruleCriteriaParam?.paramId)}
                        size="large">
                        <MoreHorizIcon />
                      </IconButton>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
      >
        <AccessControl rolePageName={RolePageNames.Configuration} actionName="Add New Formula"
          renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}>
          <MenuItem className="primary" onClick={() => handleMenuClose("edit")}>Edit</MenuItem>
        </AccessControl>
        <AccessControl rolePageName={RolePageNames.Configuration} actionName="Add New Formula"
          renderNoAccess={() => <MenuItem disabled>Delete</MenuItem>}>
          <MenuItem className="danger" onClick={() => handleMenuClose("delete")}>
            Delete
          </MenuItem>
        </AccessControl>
      </Menu>

      {showFormulaModal && (
        <FormulaModal
          open={showFormulaModal}
          isEdit={true}
          handleFormulaModalCancel={handleFormulaModalCancel}
          handleFormulaModalSubmit={handleFormulaModalSubmit}
        />
      )}

      {showDeleteFormulaModal && (
        <DeleteModal
          open={showDeleteFormulaModal}
          title="Delete Formula"
          subTitle="This action cannot be undone"
          okButtonText="Delete Forever"
          handleDeleteCancel={handleDeleteCancel}
          handleDeleteOk={handleDeleteOk}
        />
      )}
    </div>
  );
}

export default Formula;
