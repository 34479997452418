import { Auth, Hub} from "aws-amplify";
import { CognitoUserSession, CognitoUser, CognitoUserPool} from 'amazon-cognito-identity-js';
import { UserPasswordStatus } from "../model/update-password-status";
import { ChangePasswordState } from "../model/change-password-state";
import { AwsTokenState } from "../model/aws-token-state";
import {configSettings} from '../../shared/configuration/config-settings';


export interface UserInfo {
    isAuthenticated: boolean;
    email: string | undefined;
}

export interface LoginValues {
    email: string,
    password: string
}

export const GENERIC_AUTHENICATION_ERROR: string = "There is an issue with your Login. Please contact your system administrator.";
export const VERIFICATIONCODE_AUTHENICATION_ERROR: string = "Verification failed. Log in again to proceed.";
export const PASSWORD_UPDATED_SUCCESS: string = "Password Updated. Log in with your new password.";
export const PASSWORD_UPDATED_ERROR: string = "There is an issue updating your password please contact system administrator.";
export const PASSWORD_VERIFICATION_ERROR: string = "Verification Failed.  Log in again to proceed.";
export const INVALID_DOMAIN_ERROR : string = "Please use a valid email.";


/* Amplify Auth State and UI Slots Mapping
    signin: "sign-in",
    confirmSignIn: "confirm-sign-in",
    signup: "sign-up",
    confirmSignUp: "confirm-sign-up",
    forgotpassword: "forgot-password"
    TOTPSetup: "totp-setup"
    confirmSignIn: "confirm-sign-in"
    confirmSignUp: "confirm-sign-up"
    forgotpassword: "forgot-password"
    loading: "loading"
    resettingpassword: "require-new-password"
    signin: "sign-in"
    signup: "sign-up"
    verifyContact: "verify-contact"
*/
export function changeAuthState(authState: string) {
  Hub.dispatch('UI Auth', {
    event: 'AuthStateChange',
    message: authState
  });
};

class AuthorizationService {

    async getLoggedInUser(): Promise<UserInfo | undefined> {
        const currentUser = await Auth.currentUserInfo();

        const useData: UserInfo = {
            isAuthenticated: currentUser ? true : false,
            email: currentUser?.attributes.email
        };

        return useData;
    }

    private currentUser: any;
    private currentUserSession: CognitoUserSession | undefined;

    async checkForUsersession() : Promise<UserInfo | undefined> {
        return await this.getLoggedInUser();
    }

    async fetchSecurityToken() {
      /* Auth.currentSession will return a new token (among other things)
       * if the current token is expired. Otherwise it returns the current token.
      */
     let securityToken = {} as AwsTokenState;

      await Auth.currentSession().then(response => {
        let accessToken = response.getAccessToken().getJwtToken();
        let jwtIdToken = response.getIdToken().getJwtToken()

        securityToken = {
          jwtAccessToken: accessToken,
          jwtIdToken: jwtIdToken,
        };

      }).catch((err) => {
          console.log(`Amplify failed to fetch current session : `, err)
      });
      return securityToken;
    }

    async getRoute() {
      let response2;
       await Auth.currentSession().then(response =>
        response2 = response);
      return response2;
    }



    // TODO: Determine what other challenge states can be returned from Cognito
    // Example challengeName: "NEW_PASSWORD_REQUIRED"
    checkforChallengeAndRedirect(authenicatedUser: any) {
        const checkForChallenge: boolean  = authenicatedUser.hasOwnProperty("challengeName");

        if (checkForChallenge) {
            const challengeName = authenicatedUser.challengeName;
            if (challengeName === "NEW_PASSWORD_REQUIRED") {
                // Handle Return State for showing change password screen
                // Return entered in email for reference
                changeAuthState("resettingpassword");
                return false;
            }

            if (challengeName === "SMS_MFA") {
                // Handle Return State for showing change password screen
                // Return entered in email for reference
                changeAuthState("confirmSignIn");
                return false;
            }

        }
        return true;
    }

    async login(username: string, password: string ): Promise<boolean> {
        try {
            // Depending on if the user get authenicated different types are returened.
            // Initially we treay the result as any until after the challenge check.
            const authenicatedUser: any = await Auth.signIn(username, password);
            this.currentUser = authenicatedUser;
            let isUserAuthenticated = this.checkforChallengeAndRedirect(authenicatedUser);

            if (isUserAuthenticated) {
                // When no challenge is returned we should be able to get the user session tokens
                const signedInUser: CognitoUser = authenicatedUser;
                const signedInUserSession = signedInUser.getSignInUserSession();

                if (signedInUserSession) {
                    this.currentUserSession = signedInUserSession;
                } else {
                    this.currentUserSession = undefined;
                    isUserAuthenticated = false;
                    throw new Error (GENERIC_AUTHENICATION_ERROR);
                }
            }

            return isUserAuthenticated;

        } catch (error: any) {
            // TODO: Log error for Audit
            throw new Error (error.message);
        }
    }

    async verifySigninCode(code: string): Promise<boolean> {
        try {
            await Auth.confirmSignIn(this.currentUser, code);
            return true;
        } catch (error) {
            // TODO: Log error for Audit
            return false; // Thunk will handle navigation back to login screen
        }
    }

    async logout(): Promise<boolean> {
        try {
            Auth.signOut();
            return true;
        } catch (error) {
            // TODO: Log error for Audit
            throw new Error (GENERIC_AUTHENICATION_ERROR);
        }
    }

    async sendAccHolderNotification(username: string): Promise<UserPasswordStatus>{

        return new Promise((resolve, reject) => {

            const passwordStatus = new UserPasswordStatus();
            const userPool = new CognitoUserPool({
                UserPoolId: configSettings.aws_patientPoolId,
                ClientId: configSettings.aws_patientPoolWebClientId
            })
            const cognitoUser = new CognitoUser({
                Username: username,
                Pool: userPool
            })
            cognitoUser.forgotPassword({
                onSuccess: () => {
                    passwordStatus.passwordUpdated = true;
                    passwordStatus.statusMessage = "";
                    resolve(passwordStatus);
                },
                onFailure: (err) => {
                    console.error('Error in password reset request:', err);
                    passwordStatus.passwordUpdated = false;
                    passwordStatus.statusMessage = GENERIC_AUTHENICATION_ERROR;
                    reject(passwordStatus)
                }
            })
        })
    }
    async sendNotification(username: string){

        const passwordStatus = new UserPasswordStatus();

        try {
            await Auth.forgotPassword(username);
            passwordStatus.passwordUpdated = true;
            passwordStatus.statusMessage = "";
            return passwordStatus;
        } catch (error) {
            passwordStatus.passwordUpdated = false;
            passwordStatus.statusMessage = GENERIC_AUTHENICATION_ERROR;
            return passwordStatus;
        }
    }

    async sendChangePasswordNotification(params: {username: string, userRoleId?: number}): Promise<UserPasswordStatus> {

        const {username, userRoleId} = params;

        if(userRoleId === 4){
            return await this.sendAccHolderNotification(username)
        }else{
            return await this.sendNotification(username)
        }

    }

    async SetPassword(newPassword: string) : Promise<UserPasswordStatus> {
        const passwordStatus = new UserPasswordStatus();
        try {
            await Auth.completeNewPassword(this.currentUser, newPassword);

            passwordStatus.passwordUpdated = true;
            passwordStatus.statusMessage = PASSWORD_UPDATED_SUCCESS;

            return passwordStatus;
        } catch (error) {
            // TODO: Log error for Audit
            passwordStatus.passwordUpdated = false;
            passwordStatus.statusMessage = PASSWORD_UPDATED_ERROR;

            return passwordStatus;
        }
    }

    async SetPasswordWithVerificationCode(username: string, changePassword: ChangePasswordState) : Promise<UserPasswordStatus> {
        const passwordStatus = new UserPasswordStatus();
        try {
            await Auth.forgotPasswordSubmit(username, changePassword.verificationCode, changePassword.newPassword);

            passwordStatus.passwordUpdated = true;
            passwordStatus.statusMessage = PASSWORD_UPDATED_SUCCESS;

            return passwordStatus;
        } catch (error) {
            // TODO: Log error for Audit
            passwordStatus.passwordUpdated = false;
            passwordStatus.statusMessage = PASSWORD_VERIFICATION_ERROR;

            return passwordStatus;
        }
    }
}



export const authService = new AuthorizationService();
