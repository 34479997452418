import { Checkbox, DialogActionButton, saveCallbackStatus, TextField } from "@finpay-development/shared-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TableContainer, Typography } from "@mui/material";
import { ClientReferralSource, ClientReferralSourceForm, FacilityReferralSourceForm, FacilityReferralSourceModel, SelectedFacilityReferralSource } from "../../../../models/referral-source";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useEffect, useState } from "react";
import { AppDispatch } from "../../../../../shared/state/store";
import { RootState } from "../../../../../shared/state/root-reducer";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearModalEditStatus } from "../../../../state/clients/implementation-clients-slice";
import * as Yup from 'yup';
import { addFacilityClientReferralSource, addFacilityReferralSource, saveFacilityReferralSource } from "../../../../state/clients/implementation-clients-thunk";
import { Utils } from "../../../../../shared/utils";


interface FacilityReferralSourceProps {
    open: boolean;
    isEdit: boolean;
    referralSourceItem: SelectedFacilityReferralSource | undefined;
    referralSourceType: string;
    handleReferralSourceCancel: () => void;
    handleReferralSourceSubmit: (isEdit: boolean) => void;
  }


export function FacilityReferralSourceModal(props: FacilityReferralSourceProps) {
    const {
        open,
        isEdit,
        referralSourceItem,
        referralSourceType,
        handleReferralSourceCancel,
        handleReferralSourceSubmit,
    } = props;

    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const [selectedClientResourcesList, setSelectedClientResourcesList] = useState<ClientReferralSourceForm[]>([]);
    const [selectedReferralSourceTypeModal, setSelectedReferralSourceTypeModal] = useState('');
    const dispatch = useDispatch<AppDispatch>();

    const selectors = {
        saveStatus: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.modalSaveStatus
        ),
        errorMessage: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.errorMessage
        ),
        clientReferralSources: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient?.clientReferralSources
        ),
        facilityReferralSources: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient?.facilityReferralSources
        )
    };

    const {
        saveStatus,
        errorMessage,
        clientReferralSources,
        facilityReferralSources,
    } = selectors;


    useEffect(() => {
        if (selectedClientResourcesList?.length === 0) {
            getUIClientResourcesList();
        }
        if (isEdit) {
            if (Utils.isNullStringValue(referralSourceItem?.clientReferralSourceId) || (referralSourceItem?.clientReferralSourceId === 0)) {
                setSelectedReferralSourceTypeModal('facilitySource');
            } else {
                setSelectedReferralSourceTypeModal('clientSource');
            }
        } else {
            setSelectedReferralSourceTypeModal('clientSource');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, referralSourceItem?.clientReferralSourceId, selectedClientResourcesList?.length])

    const initialFacilityValues = {
        clientReferralSourceId: referralSourceItem?.clientReferralSourceId,
        clientReferralSourceName: referralSourceItem?.clientReferralSourceName,
        facilityReferralSourceId: referralSourceItem?.facilityReferralSourceId,
        facilityReferralSourceName: referralSourceItem?.facilityReferralSourceName,
        facilityId: referralSourceItem?.facilityId,
        clientId: referralSourceItem?.clientId,
        selectedReferralSourceTypeModal: selectedReferralSourceTypeModal,
        selectedClientReferralSources: selectedClientResourcesList,
    } as FacilityReferralSourceForm;

    const facilityReferralInfoSchema = {
        facilityReferralSourceName: Yup.string().required("Referral Source Name is required"),
    };

    const getValidationSchema = () => {
        let schema;
        if (selectedReferralSourceTypeModal === 'facilitySource') {
            schema = facilityReferralInfoSchema;
        } else {
            schema = {
                selectedClientReferralSources: Yup.array().min(1, "Must select one or more client referral source")
            };
        }
        return schema;
    }

    let validationSchema = Yup.object(getValidationSchema());

    const facRefSourceFormik = useFormik({
        initialValues: initialFacilityValues,
        enableReinitialize: true,
        onSubmit: (values) => { handleFacilityRefSourceSave() },
        validationSchema: validationSchema,
        validate: checkIfIsValid
    });

    function validateReferralSourceTypeModal(value: FacilityReferralSourceForm) : boolean {
        if (selectedReferralSourceTypeModal === 'facilitySource') {
            if (value?.facilityReferralSourceName?.trim().length ===0) {
                return false;
            }
        } else {
            const checkedSources = value?.selectedClientReferralSources.filter(x => x.isChecked);
            if (checkedSources.length === 0) {
                return false;
            }
        }
        return true;
      }

    function checkIfIsValid(value: FacilityReferralSourceForm) {
        validationSchema
            .validate(value)
            .then(() => {
                const bPassRequiredData = validateReferralSourceTypeModal(value);
                if (!bPassRequiredData) {
                    setEnableSaveButton(false);
                } else {
                    setEnableSaveButton(true);
                }
            })
            .catch((err) => {
                setEnableSaveButton(false);
            });
    }

    const mapToSaveModel = (clientReferralSourceId: number, clientReferralSourceItem?: ClientReferralSourceForm) => {
        const formValues = facRefSourceFormik?.values;
        return {
            facilityReferralSourceId: (isEdit ? formValues.facilityReferralSourceId : undefined),
            clientFacilityId: formValues.facilityId,
            facilityReferralSourceName: (clientReferralSourceId > 0 ? clientReferralSourceItem?.clientReferralSourceName : formValues.facilityReferralSourceName),
            clientReferralSourceId: clientReferralSourceId === 0 ? undefined : clientReferralSourceId,
            clientId: formValues.clientId,
        } as FacilityReferralSourceModel;
    };

    async function handleFacilityRefSourceSave() {
        //first check which referralSourceType was selected in dialog
        //if 'facilitySource', then save the facilityReferralSourceName to database
        let saveFacilityReferralModel = null;
        if (selectedReferralSourceTypeModal === 'facilitySource') {
            saveFacilityReferralModel = mapToSaveModel(0);
            if (isEdit) {
                await dispatch(saveFacilityReferralSource(saveFacilityReferralModel));
            } else {
                await dispatch(addFacilityReferralSource(saveFacilityReferralModel));
            }
        } else {
            //if 'clientSource', then get all checked clientReferralSourceIds and call API to save each of those into the table facility_referral_source
            const clientRefSourcesSelected = facRefSourceFormik.values.selectedClientReferralSources.filter(x => x.isChecked === true);
            let newClientReferralSources: FacilityReferralSourceModel[] = [];
            if (!isEdit) {
                for (let i = 0; i < clientRefSourcesSelected.length; i++) {
                    let refSourceId = clientRefSourcesSelected[i].clientReferralSourceId;
                    if (refSourceId !== -999) {
                        newClientReferralSources.push(mapToSaveModel(refSourceId, clientRefSourcesSelected[i]));
                    }
                }
                await dispatch(addFacilityClientReferralSource(newClientReferralSources));
            }
        }
    }

    function handleFacilityRefSourceSaveCallback() {
        if (saveStatus === saveCallbackStatus.success) {
            handleReferralSourceSubmit(isEdit);
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearModalEditStatus());
    }

    function handleFacilityRefSourceCancelCallback() {
        handleReferralSourceCancel();
    }

    const handleFacilityReferralSourceTypeChange = (e: ChangeEvent) => {
        //Toggle deficiency - need to negate previous value to get updated value
        const oldValue = selectedReferralSourceTypeModal;
        let updatedValue = '';
        if (oldValue === 'clientSource') {
            updatedValue = 'facilitySource';
            // clear all checked client resources
            facRefSourceFormik.values.selectedClientReferralSources.forEach((item: ClientReferralSourceForm) => { item.isChecked = false })
        } else {
            updatedValue = 'clientSource';
            // clear facilityReferralSourceName
            facRefSourceFormik.values.facilityReferralSourceName = '';
        }

        //  AND disable Save button
        setEnableSaveButton(false);

        setSelectedReferralSourceTypeModal(updatedValue);
    };

    const getUIClientResourcesList = () => {
        if (clientReferralSources?.length > 0) {
            if (selectedClientResourcesList.length === 0) {
                let filteredClientList: ClientReferralSource[] = Utils.deepClone(clientReferralSources);
                // filter the client referral sources list and only keep the ones that havent been used
                if (facilityReferralSources?.length > 0) {
                    const usedList = facilityReferralSources.filter( (item: SelectedFacilityReferralSource) => (item.clientReferralSourceId !== undefined && item.clientReferralSourceId !== null));
                    if (usedList !== undefined) {
                        usedList.forEach( (facItem: SelectedFacilityReferralSource) => {
                            let clientReferralSourceId = facItem.clientReferralSourceId;
                            filteredClientList = filteredClientList.filter( (y: ClientReferralSource) => y.clientReferralSourceId !== clientReferralSourceId);
                        })
                    }
                }

                let workClientResources: ClientReferralSourceForm[] = [];
                const currentClientId = clientReferralSources[0].clientId;
                filteredClientList?.forEach((refItem: ClientReferralSource) => {
                    const clientRefRow = {
                        clientId: refItem.clientId,
                        clientReferralSourceName: refItem.clientReferralSourceName,
                        isChecked: false,
                        clientReferralSourceId: refItem.clientReferralSourceId,
                    } as ClientReferralSourceForm
                    workClientResources.push(clientRefRow);
                })

                Utils.sortAlphabetically(workClientResources, "clientReferralSourceName");

                //add All Payers: value -999 is used for checkbox with label 'All Payers' to help set the checked/unchecked of each row on the UI
                workClientResources.unshift({ clientId: currentClientId, clientReferralSourceId: -999, clientReferralSourceName: 'Select All', isChecked: false });
                setSelectedClientResourcesList(workClientResources);
            }
        }
    }

    function setClientResourceChecked(refSourceId: number, bChecked: boolean) {
        const itemIndex = facRefSourceFormik.values.selectedClientReferralSources.findIndex((item: any) => item.clientReferralSourceId === refSourceId);
        facRefSourceFormik.values.selectedClientReferralSources[itemIndex].isChecked = bChecked;

        //if 'Select All' is checked/unchecked then set checked/unchecked to each ref source
        if (refSourceId === -999) {
            facRefSourceFormik.values.selectedClientReferralSources.forEach((item: ClientReferralSourceForm) => { item.isChecked = bChecked })
        } else {
            //uncheck 'Select All' if any ref source is checked
            if (refSourceId !== -999) {
                const allRefSourceItem = facRefSourceFormik.values.selectedClientReferralSources.find((item: any) => item.clientReferralSourceId === -999);
                if (allRefSourceItem !== undefined && allRefSourceItem.isChecked) {
                    allRefSourceItem.isChecked = false;
                }
            }
        }
    }

    function handleClientResourceRowSelectionChange(e: any, item: ClientReferralSourceForm) {
        facRefSourceFormik.handleChange(e);
        if (e.target.checked) {
            setClientResourceChecked(item.clientReferralSourceId, true)
        } else {
            setClientResourceChecked(item.clientReferralSourceId, false)
        }
    }

    const clientSourceBox = (
        <Grid container spacing={2} direction="row">
            <TableContainer style={{ maxHeight: "200", marginTop: "20px" }}>
                <div className="ml-6">
                    {selectedClientResourcesList.map((item: ClientReferralSourceForm, index: number) => (
                        <div key={item.clientReferralSourceId}>
                            <Checkbox
                                checked={item.isChecked}
                                id={`${item.clientId}_ReferralSource_${item.clientReferralSourceId}`}
                                name={item.clientReferralSourceName}
                                label={item.clientReferralSourceName}
                                onChange={(e: Event) => handleClientResourceRowSelectionChange(e, item)}
                            />
                        </div>
                    ))
                    }
                </div>
            </TableContainer>
        </Grid>
    )

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>{isEdit ? "Edit Referral Source for " : "Add Referral Source To "}{referralSourceType}</DialogTitle>
            <DialogContent>
                <form onSubmit={facRefSourceFormik.handleSubmit}>
                    <Grid container spacing={2} direction="row">
                        <Grid xs={6} item className="ml-2">
                            <Typography variant="h4" style={{ marginBottom: -12 }}>
                                Referral Source:
                            </Typography>
                        </Grid>
                        <Grid xs={6} item>
                            <div className={`buttongroup`}>
                                {
                                    <>
                                        <input
                                            id="1"
                                            type="radio"
                                            value="clientSource"
                                            name="selectedReferralSourceTypeModal"
                                            checked={facRefSourceFormik.values.selectedReferralSourceTypeModal === "clientSource"}
                                            onChange={(e: any) => {
                                                facRefSourceFormik.handleChange(e);
                                                setSelectedReferralSourceTypeModal("clientSource");
                                                handleFacilityReferralSourceTypeChange(e);
                                            }}
                                            disabled={isEdit && (referralSourceItem?.clientReferralSourceId === undefined || referralSourceItem?.clientReferralSourceId === null)}
                                        />
                                        <label htmlFor="1">Client</label>
                                        <input
                                            id="2"
                                            type="radio"
                                            value="facilitySource"
                                            name="selectedReferralSourceTypeModal"
                                            checked={facRefSourceFormik.values.selectedReferralSourceTypeModal === "facilitySource"}
                                            onChange={(e: any) => {
                                                facRefSourceFormik.handleChange(e);
                                                setSelectedReferralSourceTypeModal("facilitySource");
                                                handleFacilityReferralSourceTypeChange(e);
                                            }}
                                            disabled={isEdit && referralSourceItem?.clientReferralSourceId !== undefined}
                                        />
                                        <label htmlFor="2">Facility</label>
                                    </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <br />
                    <hr />
                    {selectedReferralSourceTypeModal === 'facilitySource' && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} className="mt-4">
                                <TextField
                                    name="facilityReferralSourceName"
                                    label="Name"
                                    variant="outlined"
                                    value={facRefSourceFormik.values.facilityReferralSourceName}
                                    onChange={facRefSourceFormik.handleChange}
                                    onBlur={facRefSourceFormik.handleBlur}
                                    placeholder=""
                                    error={
                                        facRefSourceFormik.touched.facilityReferralSourceName &&
                                        facRefSourceFormik.errors.facilityReferralSourceName
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                    {selectedReferralSourceTypeModal === 'clientSource' && (
                        clientSourceBox
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={enableSaveButton}
                    savebuttonText={isEdit ? "Update" : "Save"}
                    saveStatus={saveStatus}
                    spinnerLeftPosition={5}
                    executeSave={handleFacilityRefSourceSave}
                    handleCallbackSave={handleFacilityRefSourceSaveCallback}
                    handleCallbackCancel={handleFacilityRefSourceCancelCallback}
                />
            </DialogActions>
        </Dialog>
    );

}
