import { DatePicker, DialogActionButton, TextField, TimePicker } from '@finpay-development/shared-components';
import { Checkbox, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { createClientComment } from '../../implementation-specialist/state/clients/implementation-clients-thunk';
import { Comment, emptyComment } from '../../patient/components/models/comment';
import { clearStatus } from '../../patient/state/patient-slice';
import { createPatientNote } from '../../patient/state/patient-thunk';
import { showErrorStatus } from '../../security/state/user-slice';
import { commentsPage, createCommentSuccesMessages } from '../enums';
import { RootState } from '../state/root-reducer';
import { AppDispatch } from '../state/store';
interface CommentModalProps {
  page: string;
  open: boolean;
  handleCommentModalCancel: () => void;
  handleCommentModalSubmit: () => void;
}

export function CommentModal(props: CommentModalProps) {
  const { page ,open,  handleCommentModalCancel, handleCommentModalSubmit } = props;

  const [showReminder, setShowReminder] = useState(false)
  const [isClientPage, setIsClientPage] = useState(false)

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [isChecked, setIsChecked] = useState(false)


  useEffect(() => {
    switch (page) {
      case commentsPage.patients:
        setShowReminder(true)
      break;
      case commentsPage.clients:
        setIsClientPage(true)
      break;
      default:
        break;
    }
  }, [page])

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();


  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const selectedPatient = useSelector(
    (state: RootState) =>
      state.patientContext.selectedEncounter
  );

  const seletedClient = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.client
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );


  function handleSave() {
    const currentLocalTime = new Date().toISOString()
    const formValues = formRef?.current?.values || {};

    if (isChecked && formValues.completionDueDt && formValues.completionDueTime) {
      const date = new Date(formValues?.completionDueDt);
      const time = new Date(formValues?.completionDueTime);
      const dateWithLocalTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
      const dateTimeString = dateWithLocalTime.toISOString();
      formValues.completionDueDt = dateTimeString;
    } else {
      formValues.completionDueDt = ""
    }
    formValues.isReminder = isChecked;
    formValues.noteDt = currentLocalTime;

    if (isClientPage) {
      const clientComment: Comment = emptyComment;
      clientComment.clientId = seletedClient.clientId;
      clientComment.commentText = formValues.noteText;
      clientComment.commentDt = formValues.noteDt;

      dispatch(createClientComment({ message: createCommentSuccesMessages.comment, comment: clientComment }));
    } else {
      dispatch(createPatientNote({ message: createCommentSuccesMessages.comment, comment: formValues }));
    }

    handleCommentModalSubmit();

  }


  const handleCheckBox = () =>{
    setIsChecked(!isChecked)
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleCommentModalSubmit();
      setEnableSaveButton(false);

    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleCommentModalCancel();
  }

  const initialValues = {
    patientId:selectedPatient?.patientId,
    patientEncounterId: selectedPatient?.patientEncounterId,
    clientId: seletedClient?.clientId,
    noteText:"",
    completionDueDt: new Date(),
    completionDueTime: new Date(),
    noteDt:"",
    isReminder: false
  };

  const getValidationSchema = (isChecked?: boolean) => (
    Yup.object(
      !isChecked ? {
        noteText: Yup.string().required("Required"),
      } : {
        noteText: Yup.string().required("Required"),
        completionDueTime: Yup.string().min(1),
        completionDueDt: Yup.string().min(1),
      }
    )
  );

  let validationSchema = getValidationSchema(); // init validation schema

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      className="modal user-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Comment</DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <TextField

                    error={
                      formik.touched["noteText"] && formik.errors["noteText"]
                        ? formik.errors["noteText"]
                        : ""
                    }
                    label="Comment"
                    name="noteText"
                    multiline
                    value={formik.values.noteText}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      checkIfIsValid(formik.values);
                    }}
                    onBlur={formik.handleBlur}
                    helperText="A time stamp and your name will be added automatically"
                  />
                </Grid>
                {showReminder && (
                  <Fragment>
                    <Grid xs={4} item className="mt-4">
                      <Checkbox className=""
                        checked={isChecked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleCheckBox();
                          validationSchema = getValidationSchema(!isChecked);
                          checkIfIsValid(formik.values);
                        }}
                      />
                      <Typography display="inline" >Add a reminder</Typography>
                    </Grid>
                    <Grid xs={4} item className="mt-4">
                      <DatePicker
                        label="Remind On Date"
                        value={formik.values.completionDueDt}
                        onChange={(date: Date) => {
                          formik.setFieldValue('completionDueDt', date)
                        }}
                        disabled={!isChecked}
                      />
                    </Grid>
                    <Grid xs={4} item className="mt-4">
                      <TimePicker
                        label="Remind On Time"
                        value={formik.values.completionDueTime}
                        onChange={(time: Date) => {
                          formik.setFieldValue('completionDueTime', time)
                        }}
                        disabled={!isChecked}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText= "Save"
          saveStatus={saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
