import "../../../scss/components/_forms.scss";

import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from "@mui/material";

import {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { Button } from "@finpay-development/shared-components";

import { RootState } from "../../../shared/state/root-reducer";
import { RecentlyViewedTypes } from "../../../shared/enums";
import { AppDispatch } from "../../../shared/state/store";
import { clearSelectedEncounter } from "../../state/patient-slice";
import {
  getInstanceOfCare,
  setRecentlyViewedPatient,
  savePatient,
} from "../../state/patient-thunk";

import { BaseAPIRequiredPatient } from "../models/patient-view-model";

import OpenSearchInput from "./open-search-input";
import OpenSearchModal from "./open-search-modal";
import OpenSearchPatientRow from "./open-search-patient-row";

import { patientSearchResult } from "src/admissions-advisor/models/patient";

import { admissionsAdvisorUtils } from "../../../admissions-advisor/utils/admission-advisor-utils";

import { getAllPatientOpenSearchDocuments } from "../../state/patient-thunk";
import { AxiosGetPayload } from "src/shared/service/axios-get-payload";
import { unwrapResult } from "@reduxjs/toolkit";

interface OpenSearchResult {
  data: [];
  total: number;
}

export interface OpenSearchController {
  page: number;
  rowsPerPage: number;
  paramId: number;
}

export interface APIConfig {
  paramId: number;
  page: number;
  limit: number;
  searchTerms: string;
  isClicked: boolean;
}

const tableCellStyle = { borderBottom: "none", padding: "5px 5px" };
const cellIconWidth = "5%";
const cellNameWidth = "25%";
const cellDOBWidth = "15%";
const cellEmailWidth = "55%";

const OpenSearchPatient = () => {
  const [openSearchResult, setOpenSearchResult] = useState<OpenSearchResult>({
    data: [],
    total: 0,
  });
  const [openSearchController, setOpenSearchController] =
    useState<OpenSearchController>({ page: 0, rowsPerPage: 10, paramId: -2 });
  const [patientIdToOpen, setPatientIdToOpen] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTermLength, setSearchTermLength] = useState(0);
  const [prvSearchTerm, setPrvSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const stateFields = {
    isPreConverted: useSelector((state: RootState) => {
      return state.patientContext.isPreConverted;
    }),
  };

  const { isPreConverted } = stateFields;

  useEffect(() => {
    getData();
  }, [
    dispatch,
    openSearchController.page,
    openSearchController.rowsPerPage,
    openSearchController.paramId,
    searchTerm,
    prvSearchTerm,
  ]);

  const getData = async (isClicked = false) => {
    const { page, rowsPerPage, paramId } = openSearchController;

    try {
      if (searchTerm !== prvSearchTerm) {
        setPrvSearchTerm(searchTerm);
        setOpenSearchController({
          page: 0,
          rowsPerPage: 10,
          paramId: -2,
        });
        return;
      }
      if (searchTerm !== "" && searchTerm.length >= 3) {
        let config = {
          paramId: paramId,
          page: page * rowsPerPage,
          limit: rowsPerPage,
          searchTerms: searchTerm,
          isClicked,
        } as APIConfig;

        const response = await searchOpenSearch(config);

        handleOpenSearchResult(response);

        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    
    setIsLoading(false);
  };

  const searchOpenSearch = async (config: APIConfig) => {
    try {
      const response: AxiosGetPayload = await dispatch(
        getAllPatientOpenSearchDocuments(config)
      );
      return response.payload.entity;
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const searchString = (e.target as HTMLInputElement).value;

    const sanitizedInput = admissionsAdvisorUtils.sanitizeInput(searchString);

    setInputValue(sanitizedInput);

    debouncedChangeHandler(sanitizedInput);
  };

  const debouncedChangeHandler = useCallback(
    debounce((sanitizedInput) => {
      setSearchTerm(sanitizedInput);
      setSearchTermLength(sanitizedInput.length);

      if (sanitizedInput.length > 2) {
        setIsLoading(true);
      }
    }, 500),
    []
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOpenSearchController({
      ...openSearchController,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  const handleOpenSearchResult = useCallback((result: any) => {
    setOpenSearchResult(result);
  }, []);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setIsLoading(true);
    setOpenSearchController({
      ...openSearchController,
      page: newPage,
    });
  };

  const handleExpandPatientTable = (documentId: string) => {
    if (patientIdToOpen === documentId) {
      setPatientIdToOpen("");
    } else {
      setPatientIdToOpen(documentId);
    }
  };

  const handleNavigateToIOC = (patientId: number, encounterId: number) => {
    dispatch(clearSelectedEncounter());
    dispatch(getInstanceOfCare({ patientId, encounterId }));

    if (isPreConverted) {
      const type = RecentlyViewedTypes.preConvertedPatients;
      dispatch(setRecentlyViewedPatient({ patientId, encounterId, type }));
    } else {
      const type = RecentlyViewedTypes.convertedPatients;
      dispatch(setRecentlyViewedPatient({ patientId, encounterId, type }));
    }

    navigate(`/specialist/dashboard/${patientId}`, {
      state: {
        tabIndex: 1,
      },
    });
  };

  const handleNavigateToPatientDetails = async (
    patient: patientSearchResult
  ) => {
    const {
      _source: {
        fpPatientId,
        advisorPatientId,
        firstName,
        lastName,
        birthDate,
      },
    } = patient;

    if (fpPatientId === null && advisorPatientId) {
      const patientToSave = {
        patientId: 0,
        advisorPatientId,
        contact: {
          firstName,
          lastName,
          birthDate,
        },
      } as BaseAPIRequiredPatient;
      await dispatch(savePatient({ patient: patientToSave }));
    }

    if (fpPatientId) {
      navigate(`/specialist/dashboard/${fpPatientId}`);
    }
  };

  const resetState = () => {
    setIsLoading(false);
    setSearchTermLength(0);
    setPatientIdToOpen("");
    setOpenSearchResult({ data: [], total: 0 });
    setInputValue("");
    setSearchTerm("");
  };

  const handleKeyUp = async (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      setIsLoading(true);
      await getData(true);
    }
  };

  const ActionButton = (
    <Button
      onClick={() => {
        setIsLoading(true);
        getData(true);
      }}
    >
      Submit
    </Button>
  );

  return (
    <OpenSearchModal resetState={resetState} actionButton={ActionButton}>
      <OpenSearchInput
        inputValue={inputValue}
        isLoading={isLoading}
        handleInputChange={handleInputChange}
        handleKeyUp={handleKeyUp}
      />
      {openSearchResult?.data.length > 0 && (
        <>
          <Table size="medium" sx={{ marginTop: "10px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={tableCellStyle}
                  width={cellIconWidth}
                ></TableCell>
                <TableCell sx={tableCellStyle} width={cellNameWidth}>
                  Name
                </TableCell>
                <TableCell sx={tableCellStyle} width={cellDOBWidth}>
                  DOB
                </TableCell>
                <TableCell sx={tableCellStyle} width={cellEmailWidth}>
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {openSearchResult?.data.map((patient: patientSearchResult) => {
                return (
                  <OpenSearchPatientRow
                    patient={patient}
                    patientIdToOpen={patientIdToOpen}
                    key={patient._id}
                    handleExpandPatientTable={handleExpandPatientTable}
                    handleNavigateToPatientDetails={
                      handleNavigateToPatientDetails
                    }
                    handleNavigateToIOC={handleNavigateToIOC}
                  />
                );
              })}
            </TableBody>
          </Table>
          {openSearchResult?.total > 0 && (
            <TablePagination
              component="div"
              onPageChange={handlePageChange}
              page={openSearchController.page}
              count={openSearchResult.total}
              rowsPerPage={openSearchController.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      {openSearchResult?.data.length === 0 &&
        !isLoading &&
        searchTermLength > 2 && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <b>No Matching Results To Show</b>
          </div>
        )}
    </OpenSearchModal>
  );
};

export default OpenSearchPatient;
