import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	EstLevelOfCare,
	PfrEstimator,
	FetchedEstimate,
	apiStatus,
	Estimate,
	emptyEstimate,
} from '../models/estimator';
import {FacilityLevelOfCare, Estimate as NewEstimate} from "@finpay/estimation-types"
import { ClientDetail } from '../../shared/model/client-details';
import { LevelOfCare } from "../../admin-configuration/models/level-of-care";
import { getFacilityLevelsOfCareByPlan, getClientLevelsOfCare, getSavedEstimates, saveEstimate, getEstimate, getClientDetails, callNewEstimatePost, callNewEstimatePut, callNewEstimateGet, getNewFacilityLevelsOfCare} from './estimator-thunk';
import { configGetLOC } from "../../admin-configuration/state/admin-configuration-thunk";

export interface estimatorSliceShape {
	estimator: Estimate,
	facilityLevelsOfCareByPlan: EstLevelOfCare[],
	facilityAllLevelsOfCare: EstLevelOfCare[],
	clientLevelsOfCare: EstLevelOfCare[],
	priorLevelsOfCare: LevelOfCare[],
	savedEstimations: PfrEstimator[],
	fetchedEstimate: FetchedEstimate,
	clientDetails?: ClientDetail,
	isLoading: {
		isLoadingFacilityLocByPlan: boolean;
		facilityLocByPlanStatus: apiStatus,
		isLoadingMasterLoc: boolean,
		isLoadingClientLoc: boolean,
		configGetLOCStatus: apiStatus,
		getClientLOCStatus: apiStatus,
		isLoadingEstimates: boolean;
		isLoadingGetEstimate: boolean;
		isLoadingSaveEstimates: boolean,
		getEstimateStatus: apiStatus,
		saveEstimateStatus: apiStatus,
		isLoadingFacilityLoc: boolean;
		isLoadingClientDetails: boolean,
		clientDetailsStatus: apiStatus,
	},
	newEstimate: NewEstimate | null,
	newFacilityLevelsOfCareList: FacilityLevelOfCare[] | null
}

const initialState: estimatorSliceShape = {
	estimator: emptyEstimate,
	facilityLevelsOfCareByPlan: [],
	facilityAllLevelsOfCare: [],
	clientLevelsOfCare: [],
	priorLevelsOfCare: [],
	savedEstimations: [],
	fetchedEstimate: {
		ormVersion: undefined,
		createUserId: "",
		createDt: "",
		pfrEstimateId: undefined,
		vobId: undefined,
		advisorPatientId: undefined,
		estimateBody: undefined,
		lastUpdateUserId: "",
		lastUpdateDt: ""
	},
	clientDetails: undefined,
	isLoading: {
		isLoadingFacilityLocByPlan: false,
		facilityLocByPlanStatus: apiStatus.none,
		isLoadingMasterLoc: false,
		isLoadingClientLoc: false,
		configGetLOCStatus: apiStatus.none,
		getClientLOCStatus: apiStatus.none,
		isLoadingEstimates: false,
		isLoadingGetEstimate: false,
		isLoadingSaveEstimates: false,
		getEstimateStatus: apiStatus.none,
		saveEstimateStatus: apiStatus.none,
		isLoadingFacilityLoc: false,
		isLoadingClientDetails: false,
		clientDetailsStatus: apiStatus.none,
	},
	newEstimate: null,
	newFacilityLevelsOfCareList: null
};

export const estimatorSlice = createSlice({
	name: "estimatorContext",
	initialState,
	reducers: {
		setEstimator: (state, action) => {
			state.estimator = action.payload;
		},
		setLevelsOfCare: (state, action) => {
			state.clientLevelsOfCare = action.payload;
		},
		setfacilityAllLevelsOfCare: (state, action) => {
			state.facilityAllLevelsOfCare = action.payload;
		},
		clearEstimator: (state) => {
			state.estimator = initialState.estimator;
		},
		clearSaveEstimatorStatus: (state) => {
			state.isLoading.saveEstimateStatus = initialState.isLoading.saveEstimateStatus;
		},
		clearGetEstimateStatus: (state) => {
			state.isLoading.getEstimateStatus = initialState.isLoading.getEstimateStatus;
		},
		clearConfigGetLOCStatus: (state) => {
			state.isLoading.configGetLOCStatus = initialState.isLoading.configGetLOCStatus;
		},
		clearFacilityLocByPlanStatus: (state) => {
			state.isLoading.facilityLocByPlanStatus = initialState.isLoading.facilityLocByPlanStatus;
		},
		clearClientDetailsStatus: (state) => {
			state.isLoading.clientDetailsStatus = initialState.isLoading.clientDetailsStatus;
		},
		clearClientLocStatus: (state) => {
			state.isLoading.getClientLOCStatus = initialState.isLoading.getClientLOCStatus;
		},
		clearNewEstimate: (state) => {
			state.newEstimate = initialState.newEstimate 
		},
		setNewEstimate: (state, action) => {
			state.newEstimate = action.payload;
		},
	},
  extraReducers: (builder) => {
    builder.addCase(getFacilityLevelsOfCareByPlan.pending, (state, action) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.none;
      state.isLoading.isLoadingFacilityLocByPlan = true;
    });
    builder.addCase(getFacilityLevelsOfCareByPlan.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.success;
			state.isLoading.isLoadingFacilityLocByPlan = false;
      state.facilityLevelsOfCareByPlan = action.payload;
    });
    builder.addCase(getFacilityLevelsOfCareByPlan.rejected, (state, action) => {
			state.isLoading.facilityLocByPlanStatus = apiStatus.error;
      state.isLoading.isLoadingFacilityLocByPlan = false;
    });
    builder.addCase(configGetLOC.pending, (state, action) => {
			state.isLoading.configGetLOCStatus = apiStatus.none;
      state.isLoading.isLoadingMasterLoc = true;
    });
    builder.addCase(configGetLOC.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.configGetLOCStatus = apiStatus.success;
      state.isLoading.isLoadingMasterLoc = false;
      state.priorLevelsOfCare = action.payload;
    });
    builder.addCase(configGetLOC.rejected, (state, action) => {
			state.isLoading.configGetLOCStatus = apiStatus.error;
      state.isLoading.isLoadingMasterLoc = false;
    });
    builder.addCase(getEstimate.pending, (state, action) => {
      state.isLoading.isLoadingGetEstimate = true;
			state.isLoading.getEstimateStatus = apiStatus.none;
    });


    builder.addCase(getClientLevelsOfCare.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.getClientLOCStatus = apiStatus.success;
      state.isLoading.isLoadingClientLoc = false;
      state.clientLevelsOfCare = action.payload;
    });
    builder.addCase(getClientLevelsOfCare.rejected, (state, action) => {
			state.isLoading.getClientLOCStatus = apiStatus.error;
      state.isLoading.isLoadingClientLoc = false;
    });
    builder.addCase(getClientLevelsOfCare.pending, (state, action) => {
			state.isLoading.getClientLOCStatus = apiStatus.none;
      state.isLoading.isLoadingClientLoc = true;
    });
		

    builder.addCase(getEstimate.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingGetEstimate = false;
			state.isLoading.getEstimateStatus = apiStatus.success;
			state.fetchedEstimate = action.payload;
    });
    builder.addCase(getEstimate.rejected, (state, action) => {
      state.isLoading.isLoadingGetEstimate = false;
			state.isLoading.getEstimateStatus = apiStatus.error;
    });
    builder.addCase(getSavedEstimates.pending, (state, action) => {
      state.isLoading.isLoadingEstimates = true;
    });
    builder.addCase(getSavedEstimates.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingEstimates = false;
      state.savedEstimations = action.payload;
    });
    builder.addCase(getSavedEstimates.rejected, (state, action) => {
      state.isLoading.isLoadingEstimates = false;
    });
    builder.addCase(saveEstimate.pending, (state, action) => {
      state.isLoading.isLoadingSaveEstimates = true;
			state.isLoading.saveEstimateStatus = apiStatus.none;
    });
    builder.addCase(saveEstimate.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingSaveEstimates = false;
      state.savedEstimations = action.payload;
			state.estimator.pfrEstimateId = action.payload.pfrEstimateId;
			state.isLoading.saveEstimateStatus = apiStatus.success;
    });
    builder.addCase(saveEstimate.rejected, (state, action) => {
      state.isLoading.isLoadingSaveEstimates = false;
			state.isLoading.saveEstimateStatus = apiStatus.error;
    });

    builder.addCase(getClientDetails.pending, (state, action) => {
      state.isLoading.isLoadingClientDetails = true;
			state.isLoading.clientDetailsStatus = apiStatus.none;
    });
    builder.addCase(getClientDetails.fulfilled, (state, action: PayloadAction<any>) => {
			state.isLoading.isLoadingClientDetails = false;
			state.isLoading.clientDetailsStatus = apiStatus.success;
			state.clientDetails = action.payload;
    });
    builder.addCase(getClientDetails.rejected, (state, action) => {
      state.isLoading.isLoadingClientDetails = false;
			state.isLoading.clientDetailsStatus = apiStatus.error;
    });
	builder.addCase(callNewEstimatePost.fulfilled, (state, action) => {
		state.newEstimate = action.payload
	});
	builder.addCase(callNewEstimatePut.fulfilled, (state, action) => {
		state.newEstimate = action.payload
	});
	builder.addCase(callNewEstimateGet.fulfilled, (state, action) => {
		state.newEstimate = action.payload
	});
	builder.addCase(getNewFacilityLevelsOfCare.fulfilled, (state, action) => {
		state.newFacilityLevelsOfCareList = action.payload
	});
  },
});

export const { setEstimator, setLevelsOfCare, setfacilityAllLevelsOfCare, clearEstimator, clearSaveEstimatorStatus, clearGetEstimateStatus, clearConfigGetLOCStatus, clearFacilityLocByPlanStatus, clearClientDetailsStatus, clearClientLocStatus, clearNewEstimate, setNewEstimate } = estimatorSlice.actions;

export default estimatorSlice.reducer;