export const workFlowStatus = {
  "preconvert": [
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 13,
          "workflowStatusName":"New",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 7,
          "workflowSubStatusName":"None"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 8,
          "workflowSubStatusName":"Educational Curriculum"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 10,
          "workflowSubStatusName":"Payment Curriculum"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 11,
          "workflowSubStatusName":"Awaiting Consent Docs"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 12,
          "workflowSubStatusName":"Patient Champion"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 13,
          "workflowSubStatusName":"Awaiting HRE Approval"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 14,
          "workflowSubStatusName":"Payment Methods Needed"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 39,
        "workflowSubStatusName":"No Status"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 40,
        "workflowSubStatusName":"HRE Approved"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 15,
          "workflowSubStatusName":"No Contact 2nd Attempt"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 16,
          "workflowSubStatusName":"No Contact 3rd Attempt"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 14,
          "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 17,
          "workflowSubStatusName":"Awaiting Client"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 46,
        "workflowSubStatusName": "HRE Approved by Client for Payment at Facility, Payment Confirmation Required"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 47,
        "workflowSubStatusName": "HRE Approved by Client for Direct Bill Follow Up"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 15,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 18,
          "workflowSubStatusName":"Error (Bad File)"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 15,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 19,
          "workflowSubStatusName":"Patient Failed to Engage"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 15,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 20,
          "workflowSubStatusName":"HRE Rejected by Patient"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 15,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 21,
          "workflowSubStatusName":"HRE Rejected by Client"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
          "workflowStatusId": 15,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 22,
          "workflowSubStatusName":"Patient Never Admitted"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 41,
        "workflowSubStatusName": "FinPay Failed to Engage"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 42,
        "workflowSubStatusName": "Duplicate Record"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 43,
        "workflowSubStatusName": "Patient Covered at 100%"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 44,
        "workflowSubStatusName": "HRE Approved by Client for Payment at Facility, Payment Confirmed"
      }
    },

    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 45,
        "workflowSubStatusName": "HRE Approved by Client for Full Scholarship"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 63,
        "workflowSubStatusName": "Mobile Payment Unsuccessful"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 65,
        "workflowSubStatusName": "Mobile Payment Retry Limit"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 62,
        "workflowSubStatusName": "Mobile Payment Failure"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 15,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 64,
        "workflowSubStatusName": "Mobile Payment Retry Limit"
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 13,
        "workflowStatusName":"New",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 66,
        "workflowSubStatusName": 'Pending Payment at Facility'
      }
    },
    {
      "workflowId": 2,
      "workflowStatus": {
        "workflowStatusId": 14,
        "workflowStatusName":"Follow Up Needed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 67,
        "workflowSubStatusName": 'Pending Payment at Facility'
      }
    }

  ],
  "Converted":[
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 8,
          "workflowStatusName":"Good Standing",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 32,
          "workflowSubStatusName":""
    }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 10,
          "workflowStatusName":"At Risk",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 25,
          "workflowSubStatusName":"Missed Payment"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 10,
          "workflowStatusName":"At Risk",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 24,
          "workflowSubStatusName":"Has Past Due Amount"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 10,
          "workflowStatusName":"At Risk",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 26,
          "workflowSubStatusName":"On Hold"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 7,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 37,
          "workflowSubStatusName":"Default"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 7,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 27,
          "workflowSubStatusName":"Error (Bad File)"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 7,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 28,
          "workflowSubStatusName":"Patient Failed to Engage"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
          "workflowStatusId": 7,
          "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
          "workflowSubStatusId": 29,
          "workflowSubStatusName":"Patient Never Admitted"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 48,
        "workflowSubStatusName":"FinPay Failed to Engage"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 49,
        "workflowSubStatusName":"Duplicate Record"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 50,
        "workflowSubStatusName":"Insurance Paid in Full"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 51,
        "workflowSubStatusName":"Account Holder Paid Balance Owed"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 52,
        "workflowSubStatusName":"Account Holder Paid Balance Owed and is Due a Refund"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 53,
        "workflowSubStatusName":"Revoked Payment Plan"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 54,
        "workflowSubStatusName":"Provider's Request"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 55,
        "workflowSubStatusName":"Refinanced"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 56,
        "workflowSubStatusName":"HRE Approved by Client for Direct Bill, Payment Made to Facility"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 57,
        "workflowSubStatusName":"HRE Approved by Client for Payment at Facility"
      }
    },

    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 7,
        "workflowStatusName":"Closed",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 58,
        "workflowSubStatusName":"HRE Approved by Client for Full Scholarship"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 10,
        "workflowStatusName":"At Risk",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 59,
        "workflowSubStatusName":"Missing Funding Source"
      }
    },
    {
      "workflowId": 3,
      "workflowStatus": {
        "workflowStatusId": 16,
        "workflowStatusName":"Paid",
      },
      "workflowSubStatus": {
        "workflowSubStatusId": 23,
        "workflowSubStatusName":""
      }
    }

  ]
};
