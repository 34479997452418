import { zipCodeValidator } from "../../shared/misc/regex";
import * as Yup from "yup";
import { commonFields } from "../../shared/validation/schemas";


export const billInfoSchema = {
    email: Yup.string()
            .email('Enter a valid email')
            .required('Email is required'),
    firstName: Yup.string()
                  .min(1).max(50, "Must be 50 characters or less")
                  .required('First Name is required'),
    lastName:  Yup.string()
                  .min(1).max(50, "Must be 50 characters or less")
                  .required('Last Name is required'),
    middleInitial: Yup.string(),
    cellPhone: commonFields.PHONE,
    billingAddress1: Yup.string()
                      .min(3, "Must be at least 3 characters or longer")
                      .required('Billing Address 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string()
                      .min(1, "Must be at least 1 character or longer")
                      .required('Billing City is required'),
    billingState: Yup.string()
                      .min(1, "Must select a value")
                      .required('State is required'),
    billingZip: Yup.string()
                    .min(5)
                    .matches(zipCodeValidator, "Must be at least 5 characters")
                    .required('Zip is required'),
    accountNumber: Yup.string()
                    .min(1).max(30, "Can be up to 30 characters")
                    .required('Field is required'),
    confirmAcctNumber: Yup.string()
                          .required('Confirm field is required')
                          .oneOf([Yup.ref("accountNumber"), null], "Account Mismatch"),
    isPatientAddrSameAsBilling: Yup.boolean(),
    saveInfoForLater: Yup.boolean(),
    patientAddress1: Yup.string()
                        .min(3, "Must be at least 3 characters or longer"),
    patientAddress2: Yup.string(),
    patientCity: Yup.string()
                    .min(1, "Must be at least 1 character or longer"),
    patientState: Yup.string()
                    .min(1, "Must select a value"),
    patientZip: Yup.string()
                    .min(5)
                    .matches(zipCodeValidator, "Must be at least 5 characters"),
}

export const billInfoSchemaOmniChannel = {
        email: Yup.string()
                .email('Enter a valid email')
                .required('Email is required'),
        firstName: Yup.string()
                      .min(1).max(50, "Must be 50 characters or less")
                      .required('First Name is required'),
        lastName:  Yup.string()
                      .min(1).max(50, "Must be 50 characters or less")
                      .required('Last Name is required'),
        middleInitial: Yup.string(),
        cellPhone: commonFields.PHONE,
        billingAddress1: Yup.string()
                          .min(3, "Must be at least 3 characters or longer")
                          .required('Billing Address 1 is required'),
        billingAddress2: Yup.string(),
        billingCity: Yup.string()
                          .min(1, "Must be at least 1 character or longer")
                          .required('Billing City is required'),
        billingState: Yup.string()
                          .min(1, "Must select a value")
                          .required('State is required'),
        billingZip: Yup.string()
                        .min(5)
                        .matches(zipCodeValidator, "Must be at least 5 characters")
                        .required('Zip is required'),
        accountNumber: Yup.string()
                        .min(1).max(30, "Can be up to 30 characters"),
        confirmAcctNumber: Yup.string()
                              .oneOf([Yup.ref("accountNumber"), null], "Account Mismatch"),
        isPatientAddrSameAsBilling: Yup.boolean(),
        saveInfoForLater: Yup.boolean(),
        patientAddress1: Yup.string()
                            .min(3, "Must be at least 3 characters or longer"),
        patientAddress2: Yup.string(),
        patientCity: Yup.string()
                        .min(1, "Must be at least 1 character or longer"),
        patientState: Yup.string()
                        .min(1, "Must select a value"),
        patientZip: Yup.string()
                        .min(5)
                        .matches(zipCodeValidator, "Must be at least 5 characters"),
        clientFacilityId: commonFields.REQUIRED_DROPDOWN,
        userName: Yup.string().optional().min(1).max(256, "Must be 256 characters or less"),
    }

export const patientAllInfoSchema = {
    email: Yup.string()
            .email('Enter a valid email')
            .required('Email is required'),
    firstName: Yup.string()
                  .min(1).max(50, "Must be 50 characters or less")
                  .required('First Name is required'),
    lastName:  Yup.string()
                  .min(1).max(50, "Must be 50 characters or less")
                  .required('Last Name is required'),
    middleInitial: Yup.string(),
    cellPhone: commonFields.PHONE,
    billingAddress1: Yup.string()
                      .min(3, "Must be at least 3 characters or longer")
                      .required('Billing Address 1 is required'),
    billingAddress2: Yup.string(),
    billingCity: Yup.string()
                      .min(1, "Must be at least 1 character or longer")
                      .required('Billing City is required'),
    billingState: Yup.string()
                      .min(1, "Must select a value")
                      .required('State is required'),
    billingZip: Yup.string()
                    .min(5)
                    .matches(zipCodeValidator, "Must be at least 5 characters")
                    .required('Zip is required'),
    accountNumber: Yup.string()
                    .min(1).max(30, "Can be up to 30 characters")
                    .required('Field is required'),
    confirmAcctNumber: Yup.string()
                          .required('Confirm field is required')
                          .oneOf([Yup.ref("accountNumber"), null], "Account Mismatch"),
    isPatientAddrSameAsBilling: Yup.boolean(),
    saveInfoForLater: Yup.boolean(),
    patientAddress1: Yup.string()
                        .min(3, "Must be at least 3 characters or longer")
                        .required('Patient Address 1 is required'),
    patientAddress2: Yup.string(),
    patientCity: Yup.string()
                    .min(1, "Must be at least 1 character or longer")
                    .required('Patient City is required'),
    patientState: Yup.string()
                    .min(1, "Must select a value")
                    .required('State is required'),
    patientZip: Yup.string()
                    .min(5)
                    .matches(zipCodeValidator, "Must be at least 5 characters")
                    .required('Zip is required'),
}

export const patientAllInfoSchemaOmniChannel = {
        email: Yup.string()
                .email('Enter a valid email')
                .required('Email is required'),
        firstName: Yup.string()
                      .min(1).max(50, "Must be 50 characters or less")
                      .required('First Name is required'),
        lastName:  Yup.string()
                      .min(1).max(50, "Must be 50 characters or less")
                      .required('Last Name is required'),
        middleInitial: Yup.string(),
        cellPhone: commonFields.PHONE,
        billingAddress1: Yup.string()
                          .min(3, "Must be at least 3 characters or longer")
                          .required('Billing Address 1 is required'),
        billingAddress2: Yup.string(),
        billingCity: Yup.string()
                          .min(1, "Must be at least 1 character or longer")
                          .required('Billing City is required'),
        billingState: Yup.string()
                          .min(1, "Must select a value")
                          .required('State is required'),
        billingZip: Yup.string()
                        .min(5)
                        .matches(zipCodeValidator, "Must be at least 5 characters")
                        .required('Zip is required'),
        accountNumber: Yup.string()
                        .min(1).max(30, "Can be up to 30 characters"),
        confirmAcctNumber: Yup.string()
                        .oneOf([Yup.ref("accountNumber"), null], "Account Mismatch"),
        isPatientAddrSameAsBilling: Yup.boolean(),
        saveInfoForLater: Yup.boolean(),
        patientAddress1: Yup.string()
                            .min(3, "Must be at least 3 characters or longer")
                            .required('Patient Address 1 is required'),
        patientAddress2: Yup.string(),
        patientCity: Yup.string()
                        .min(1, "Must be at least 1 character or longer")
                        .required('Patient City is required'),
        patientState: Yup.string()
                        .min(1, "Must select a value")
                        .required('State is required'),
        patientZip: Yup.string()
                        .min(5)
                        .matches(zipCodeValidator, "Must be at least 5 characters")
                        .required('Zip is required'),
        clientFacilityId: commonFields.REQUIRED_DROPDOWN,
        userName: Yup.string().optional().min(1).max(256, "Must be 256 characters or less"),
    }

export const guestPaymentInfoSchema = {
        payerEmail: Yup.string()
                .email('Enter a valid email')
                .required('Payer Email is required'),
        nameOnCard: Yup.string()
                .min(1).max(200, "Must be 200 characters or less")
                .required('Name On Card is required'),
        paymentAmount: Yup.number()
                .min(1, "Pay Amount must be at least $1.00")
                // .max(10000, "Pay Amount must be $10000 or less")
                .required("Pay Amount is required")
                .typeError("You must specify a number"),
        acknowledgeSPA: Yup.boolean()
            .required("Must agree to the Single Payment Agreement")
}