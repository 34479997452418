export interface PatientModalOpenStatus {
    isIOCModalOpen: boolean;
    isInsuranceModalOpen: boolean;
    isPatientChampionsModalOpen: boolean;
    isPaymentProgramModalOpen: boolean;
    isVOBModalOpen: boolean;
    isLocModalOpen: boolean;
}

export const blankPatientModalOpenStatus: PatientModalOpenStatus = {
    isIOCModalOpen: false,
    isInsuranceModalOpen: false,
    isPatientChampionsModalOpen: false,
    isPaymentProgramModalOpen: false,
    isVOBModalOpen: false,
    isLocModalOpen: false,
}