import { CustomFormikProps } from "../../shared/model/formik-props";
import { SysRolePageAction } from "../../security/model/sys-role-page-action";
import { UserRole } from "../../security/model/user-role";
import { RolePageActionState, RolePageState } from "../models/role-page-state";
import { SysRolePage } from "../../security/model/sys-role-page";
import { UserRolePage } from "../../security/model/user-role-page";
import { UserRolePageAction } from "../../security/model/user-role-page-action";

// Handle Syncing formik changes to User Role to Save
// Map Formik State for Admin Role Screen into Save Model
export function mapRoleToSave(formik: CustomFormikProps, userRole: UserRole, systemRolePages: SysRolePage[]): UserRole {

    const userRoleToSave = new UserRole();
    userRoleToSave.userRoleId = formik.values.userRoleId;
    userRoleToSave.isActive = true;
    userRoleToSave.roleName = formik.values.roleName;
    userRoleToSave.roleDesc = formik.values.roleDescription;
    userRoleToSave.roleType = "I"; // Only Internal User Roles can be created/editted.
    userRoleToSave.userRolePages = [];
    formik.values.pageKeys.forEach(function (pageKey: string) {

        const pageState: RolePageState =  formik.values[pageKey];

        const systemRolePage: SysRolePage | undefined = systemRolePages.find(
            (systemPage: SysRolePage) => systemPage.sysRoleKey === pageKey
        );

        let userRolePageId;
        userRole.userRolePages.forEach(function(e:any){
            if(systemRolePage?.sysRolePageId === e.sysRolePage.sysRolePageId) {
                userRolePageId = e.userRolePageId;
            }
        })

        const userSysRoleToSave = new UserRolePage();
        if(userRolePageId !== undefined) userSysRoleToSave.userRolePageId = userRolePageId
        /* userSysRoleToSave.userRolePageId = 0; */

        userSysRoleToSave.userRolePageActions = [];
        if (systemRolePage) {
            userSysRoleToSave.sysRolePage = systemRolePage;

            pageState.actions.forEach(function (rolePageAction: RolePageActionState) {

                const rolePageActionToSave = new UserRolePageAction();
                /* rolePageActionToSave.userPageActionId = 0; */
                rolePageActionToSave.isEnabled = rolePageAction.isEnabled;

                const systemRolePage: SysRolePageAction | undefined = userSysRoleToSave.sysRolePage.sysRolePageAction.find(
                    (systemAction: SysRolePageAction) => systemAction.actionKey === rolePageAction.actionKey
                );

                if (systemRolePage) {
                    rolePageActionToSave.sysRolePageAction = systemRolePage;
                }

                userSysRoleToSave.userRolePageActions.push(rolePageActionToSave);
            });
        }

        userSysRoleToSave.isReadOnly = pageState.isReadOnly;
        userSysRoleToSave.isEnabled = pageState.isEnabled;

        userRoleToSave.userRolePages.push(userSysRoleToSave);
    });

    return userRoleToSave;
}