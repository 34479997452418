import './../../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import { clientContact } from '../../../../../shared/validation/schemas';
import { ClientContactForm } from '../../../../models/client';
import { clearStatus } from '../../../../state/clients/implementation-clients-slice';
import { saveClientContact } from '../../../../state/clients/implementation-clients-thunk';

interface ClientEditContactModalProps {
  open: boolean;
  handleEditContactCancel: () => void;
  handleEditContactSubmit: () => void;
}

export function ClientEditContactModal(props: ClientEditContactModalProps) {
  const { open, handleEditContactCancel, handleEditContactSubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const selectors = {
    currentClientEntityDetails: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.currentClientEntityDetails
    ),
    saveStatus: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.apiStatus
    ),
      errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    )
  }

  async function handleSave() {
    const formValues: ClientContactForm = formRef.current.values;
    await dispatch(saveClientContact(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleEditContactSubmit();
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleEditContactCancel();
  }

  const initialValues: ClientContactForm = {
    firstName: selectors.currentClientEntityDetails?.firstName,
    lastName: selectors.currentClientEntityDetails?.lastName,
    email: selectors.currentClientEntityDetails?.email,
    retypeEmail: selectors.currentClientEntityDetails?.email,
    phone: selectors.currentClientEntityDetails?.phone,
    ext: selectors.currentClientEntityDetails?.ext,
    mobilePhone: selectors.currentClientEntityDetails?.mobilePhone,
    retypeMobilePhone: selectors.currentClientEntityDetails?.mobilePhone,
  };

  const validationSchema = Yup.object(clientContact);

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Edit Primary Contact</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Primary Contact
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["firstName"] && formik.errors["firstName"]
                        ? formik.errors["firstName"]
                        : ""
                    }
                    label="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["lastName"] && formik.errors["lastName"]
                        ? formik.errors["lastName"]
                        : ""
                    }
                    label="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["email"] && formik.errors["email"]
                        ? formik.errors["email"]
                        : ""
                    }
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeEmail"] &&
                      formik.errors["retypeEmail"]
                        ? formik.errors["retypeEmail"]
                        : ""
                    }
                    label="Retype Email"
                    name="retypeEmail"
                    value={formik.values.retypeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["phone"] && formik.errors["phone"]
                        ? formik.errors["phone"]
                        : ""
                    }
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("phone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    maxLength={12}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["ext"] && formik.errors["ext"]
                        ? formik.errors["ext"]
                        : ""
                    }
                    label="EXT"
                    name="ext"
                    value={formik.values.ext}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["mobilePhone"] &&
                      formik.errors["mobilePhone"]
                        ? formik.errors["mobilePhone"]
                        : ""
                    }
                    label="Mobile Phone"
                    name="mobilePhone"
                    value={formik.values.mobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("mobilePhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    helperText="Used for multifactor account authorization"
                    maxLength={12}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeMobilePhone"] &&
                      formik.errors["retypeMobilePhone"]
                        ? formik.errors["retypeMobilePhone"]
                        : ""
                    }
                    label="Retype Mobile Phone"
                    name="retypeMobilePhone"
                    value={formik.values.retypeMobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("retypeMobilePhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    maxLength={12}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          spinnerLeftPosition={5}
          savebuttonText="Update"
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
