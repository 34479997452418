
export type PaymentChannelAndFeesObj = {
  "crm": number,
  "vt": number,
  "fms": number,
  "mobile": number,
  "web": number
}

export type ClientPaymentTransactionFee = {
  "pre"?: PaymentChannelAndFeesObj,
  "in"?: PaymentChannelAndFeesObj,
  "dis"?: PaymentChannelAndFeesObj,
  "ebo"?: PaymentChannelAndFeesObj,
  "bdr"?: PaymentChannelAndFeesObj
}

const initialPaymentChannelAndFeesObj: PaymentChannelAndFeesObj = {
  "crm": 0,
  "vt": 0,
  "fms": 0,
  "mobile": 0,
  "web": 0
}

export const intialClientPaymentTransactionFee: ClientPaymentTransactionFee = {
  "pre": initialPaymentChannelAndFeesObj,
  "in": initialPaymentChannelAndFeesObj,
  "dis": initialPaymentChannelAndFeesObj,
  "ebo": initialPaymentChannelAndFeesObj,
  "bdr": initialPaymentChannelAndFeesObj
}