import { Button, LoadingOverlay, saveCallbackStatus } from '@finpay-development/shared-components';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { handleGetUsers } from '../../../../../admin/state/users/admin-thunk';
import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from '../../../../../security/model/role-page-names';
import { showStatus } from '../../../../../security/state/user-slice';
import ChangeContactModal from '../../../../../shared/components/change-contact-modal';
import SectionCompleteStatus from '../../../../../shared/components/section-complete-status';
import { getState } from '../../../../../shared/misc/us-states';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { statusNames } from '../../../../misc/client-and-facility-status';
import {
  setEntitySettingsTabComplete,
  setFacilityTabComplete,
  setRulesEngineTabComplete,
} from '../../../../state/clients/implementation-clients-slice';
import { getClient } from '../../../../state/clients/implementation-clients-thunk';
import { getClientConnectAccounts, getClientFacilities } from '../../../../state/facility/implementation-facility-thunk';
import { ClientEditContactModal } from './edit-contact';
import { ClientEditEntityModal } from './edit-entity';

function EntityDetails() {
  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [showEditEntityModal, setShowEditEntityModal] = useState(false);
  const [showChangeContactModal, setShowChangeContactModal] = useState(false)

  const params = useParams<{ id: string }>()
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const selectors = {
    currentClientEntityDetails: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.currentClientEntityDetails;
    }),
    implementationFacilities: useSelector( // facilities grouped by external account Id
      (state: RootState) => state.implementationContext.implementationFacility.facilities
    ),
    isLoading: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoading;
    }),
    clientStatus: useSelector((state: RootState)=> {
      return state.implementationContext.implementationSpecialistClient.client?.workflow?.workflowSubStatus
    }),
    clientBusinessRules: useSelector((state: RootState)=> {
      return state.implementationContext.implementationSpecialistClient.currentClientBusinessRules
    }),
    apiStatus: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.apiStatus;
    }),
    allUsers: useSelector((state: RootState) => {
      return state.adminContext.adminUserContext.userSearch.allUsers
    }),
  }

  function handleEditEntityClose() {
    setShowEditEntityModal(false);
  }

  function handleEditContactClose() {
    setShowEditContactModal(false);
  }

  function handleChangeContactClose() {
    setShowChangeContactModal(false);
  }

  function handleChangeContactOk(){
    setShowChangeContactModal(false);
    dispatch(showStatus("Primary Contact Changed"));
  }

  const getStateCodeFromId = (): string | undefined => {
    const currentStateId = selectors.currentClientEntityDetails.state;
    return getState(currentStateId)?.stateCode;
  }

  useEffect(() => {
    if (selectors.apiStatus === saveCallbackStatus.error) {
      navigate("/clients");
    }

  }, [selectors.apiStatus, navigate])

  useEffect(() => {
    if (selectors.currentClientEntityDetails.clientId === -1 && !!params?.id && selectors.apiStatus !== saveCallbackStatus.error) {
      dispatch(getClient(+params.id));
    }
  }, [dispatch, params.id, selectors.currentClientEntityDetails.clientId, selectors.apiStatus])

  useEffect(() => {
    if (selectors.currentClientEntityDetails.clientId !== -1) {
      dispatch(getClientFacilities(selectors.currentClientEntityDetails.clientId));
      dispatch(getClientConnectAccounts(selectors.currentClientEntityDetails.clientId));
      if(selectors.allUsers.length === 0) {
        dispatch(handleGetUsers());
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectors.currentClientEntityDetails.clientId])

  useEffect(() => {
    if (selectors?.implementationFacilities?.length > 0) {
      dispatch(setFacilityTabComplete(true));
    }
    const isPaymentProgramsOfferedComplete = selectors.implementationFacilities.flat().every((facility) => !!facility.patientPmtFreq);
    const isRiskRulesComplete = selectors.implementationFacilities.flat().some((facility) => facility.isRuleSetupComplete);
    const isRulesEngineTabComplete = isPaymentProgramsOfferedComplete && isRiskRulesComplete;
    dispatch(setRulesEngineTabComplete(isRulesEngineTabComplete));
  }, [dispatch, selectors.implementationFacilities])

  useEffect(() => {
    if(selectors.clientBusinessRules.customDocTokenId){
      dispatch(setEntitySettingsTabComplete(true))
    }
  }, [dispatch,selectors.clientBusinessRules.customDocTokenId])



  return (
    <div className="entity-details">
      {selectors.isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          {/* Entity Accordion */}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="accordion-entity"
            >
              <SectionCompleteStatus label="Entity" isComplete={true} />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid container justifyContent="space-between">
                  <Grid item xs={11}>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body2">Company Name</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" className="mb-6" test-id="company-name">
                          {selectors.currentClientEntityDetails?.companyName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                    <Grid item xs={1}>
                      <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
                        renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
                        <IconButton
                          color="primary"
                          onClick={() => setShowEditEntityModal(true)}
                          className="edit-button"
                          size="large"
                          test-id="edit-entity-button"> <EditIcon /></IconButton>
                      </AccessControl>
                    </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="body2">Website</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2" className="mb-6 breaklongstring">
                    {selectors.currentClientEntityDetails?.website}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2">Address</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2">
                    <address>
                      {selectors.currentClientEntityDetails?.streetAddress1}
                      <br />
                      {selectors.currentClientEntityDetails?.streetAddress2 ||
                        ""}
                      <br />
                      {`${selectors.currentClientEntityDetails?.city}, ${getStateCodeFromId()} ${selectors.currentClientEntityDetails?.zipCode}`}
                    </address>
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          {/* Primary Contact Accordion */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="accordion-primary-contact"
            >
              <SectionCompleteStatus
                label="Primary Contact"
                isComplete={true}
              />
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body2">Name</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2" className="mb-6">
                          {selectors.currentClientEntityDetails?.firstName}{" "}
                          {selectors.currentClientEntityDetails?.lastName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                    <Grid item>
                      <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
                        renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
                        <IconButton
                          color="primary"
                          onClick={() => setShowEditContactModal(true)}
                          className="edit-button"
                          size="large"> <EditIcon /></IconButton>
                      </AccessControl>
                    </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="body2">Email</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2" className="mb-6">
                    {selectors.currentClientEntityDetails?.email}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2">Phone</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2">
                    {selectors.currentClientEntityDetails?.phone}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2">Mobile Phone</Typography>
                </Grid>
                <Grid>
                  <Typography variant="subtitle2">
                    {selectors.currentClientEntityDetails?.mobilePhone}
                  </Typography>
                </Grid>

                <Grid container item justifyContent="flex-end">
                    <Grid item>
                      {selectors.clientStatus?.workflowSubStatusDesc === statusNames.ACCOUNT_ACTIVE &&
                      (
                        <AccessControl rolePageName={RolePageNames.Clients} actionName="Change Primary Contact"
                        renderNoAccess={() => <Button disabled>Change Primary Contact</Button>}>
                          <Button  onClick={() => setShowChangeContactModal(true)} type="danger">Change Primary Contact</Button>
                        </AccessControl>
                      )
                      }

                    </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {showEditEntityModal && (
        <ClientEditEntityModal
          open={showEditEntityModal}
          handleEditEntityCancel={handleEditEntityClose}
          handleEditEntitySubmit={handleEditEntityClose}
        />
      )}
      {showEditContactModal && (
        <ClientEditContactModal
          open={showEditContactModal}
          handleEditContactCancel={handleEditContactClose}
          handleEditContactSubmit={handleEditContactClose}
        />
      )}

      <ChangeContactModal
        open={showChangeContactModal}
        handleChangeContactCancel={handleChangeContactClose}
        handleChangeContactOk={handleChangeContactOk}
      />
    </div>
  );
}

export default EntityDetails;
