import { PayOutFrequency } from "./payOutFreq";

export interface FacilityForm {
  clientId: number;
  facilityId: number;
  facilityName: string;
  outpatientEnabled: boolean;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: number;
  zipCode: string;
  website: string;
  phone: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactAddress1: string;
  primaryContactAddress2: string;
  primaryContactCity: string;
  primaryContactState: number;
  primaryContactZipCode: string;
  primaryContactEmail: string;
  reTypePrimaryContactEmail?: string;
  primaryContactPhone: string;
  primaryContactExt: string;
  primaryContactMobilePhone: string;
  reTypePrimaryContactMobilePhone?: string;
  taxIdNumber: string;
  taxIdDisplay: string;
  externalAccount: string | number;
  payOutFreq: PayOutFrequency;
  payOutDay: number;
  customDocTokenId?: string;
  vboTimeThreshold?: number;
  vboPfrThreshold?: number;
  payNowExternalAccount: string | number;
  referenceNumberName: string;
  referenceNumberHelpText: string;
  bannerText: string;
  taxIdNumber_lastFour: string;
  monthlyOperatingCostIp?: number;
  monthlyOperatingCostOp?: number;
  bedCapacityIp?: number;
  bedCapacityOp?: number;
}

export const blankFacilityForm: FacilityForm = {
  clientId: 0,
  facilityId: 0,
  facilityName: "",
  outpatientEnabled: false,
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: 0,
  zipCode: "",
  website: "",
  phone: "",
  primaryContactFirstName: "",
  primaryContactLastName: "",
  primaryContactAddress1: "",
  primaryContactAddress2: "",
  primaryContactCity: "",
  primaryContactState: 0,
  primaryContactZipCode: "",
  primaryContactEmail: "",
  reTypePrimaryContactEmail: "",
  primaryContactPhone: "",
  primaryContactExt: "",
  primaryContactMobilePhone: "",
  reTypePrimaryContactMobilePhone: "",
  taxIdNumber: "",
  taxIdDisplay: "",
  externalAccount: "",
  payOutFreq: "daily",
  payOutDay: -1,
  customDocTokenId: "",
  vboTimeThreshold: 0,
  vboPfrThreshold: 0,
  payNowExternalAccount: "",
  referenceNumberName: "",
  referenceNumberHelpText: "",
  bannerText: "",
  taxIdNumber_lastFour: "",
  monthlyOperatingCostIp: -1,
  monthlyOperatingCostOp: -1,
  bedCapacityIp: -1,
  bedCapacityOp: -1,
};

export interface FacilityUIForm {
  clientId: number,
  facilityId: number | undefined,
  facilityName: string  | undefined,
  outpatientEnabled: boolean  | undefined,
  streetAddress1: string  | undefined,
  streetAddress2: string  | undefined,
  city: string | undefined,
  state: number | undefined,
  zipCode: string | undefined,
  website: string | undefined,
  phone: string | undefined,
  primaryContactFirstName: string | undefined,
  primaryContactLastName: string | undefined,
  primaryContactAddress1: string | undefined,
  primaryContactAddress2: string | undefined,
  primaryContactCity: string | undefined,
  primaryContactState: number | undefined,
  primaryContactZipCode: string | undefined,
  primaryContactEmail: string | undefined,
  reTypePrimaryContactEmail: string | undefined,
  primaryContactPhone: string | undefined,
  primaryContactExt: string | undefined,
  primaryContactMobilePhone: string | undefined,
  reTypePrimaryContactMobilePhone: string | undefined,

  taxIdNumber: string | undefined,
  externalAccount: string | number | undefined,
  payOutFreq: PayOutFrequency,
  payOutDay: number | undefined,
  customDocTokenId: string | undefined,

  payNowExternalAccount: string  | number | undefined,
  payNowUrl: string | undefined,
  referenceNumberName: string | undefined,
  referenceNumberHelpText: string | undefined,
  bannerText: string | undefined,
  monthlyOperatingCostIp: number | undefined,
  monthlyOperatingCostOp: number | undefined,
  bedCapacityIp: number | undefined,
  bedCapacityOp: number | undefined,
}

