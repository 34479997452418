import {SysRolePageAction} from '../../security/model/sys-role-page-action';
import {
  emptyUserRole,
  UserRole,
  UserRoleAction,
  UserRoleItem,
  UserRoleListViewModel,
  UserRoleViewModel,
} from '../../security/model/user-role';
import {
  AddEditRoleState,
  UserRoleFieldState,
} from '../models/add-edit-role-state';
import {UserRolePage} from '../../security/model/user-role-page';
import {UserRolePageAction} from '../../security/model/user-role-page-action';
import {SysRolePage} from '../../security/model/sys-role-page';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {Utils} from '../../shared/utils';
import {AccessRights} from '../models/role-access-rights';
import {RoleTypes} from '../models/role-types';

// Local Cache for Mocking API
const localCache: any = {
  userRoles: []
}

// these roles are not to be included in Roles dashboard
const rolePagesToIgnore = ["Client Portal", "Patient Dashboard"];

const userRolesHelper = {
  mapUserRoles(userRoles: any): UserRoleListViewModel {
    const result: UserRoleListViewModel = new UserRoleListViewModel();
    result.internalUserRoles = [];
    result.externalUserRoles = [];

    userRoles.forEach(function (item: UserRole) {
      const rolesViewModel = new UserRoleViewModel();
      rolesViewModel.userRoleId = item.userRoleId;
      rolesViewModel.roleName = item.roleName;
      rolesViewModel.roleDesc = item.roleDesc;
      rolesViewModel.roleType = item.roleType;

      rolesViewModel.navigation = [];

      item.userRolePages.forEach(function (userRolePageItem: UserRolePage) {

        if (userRolePageItem.isEnabled) {

          const rolePage = new UserRoleItem();
          rolePage.id = userRolePageItem.userRolePageId
          rolePage.pageName = userRolePageItem.sysRolePage?.rolePageName;

          rolePage.accessRights =
            userRolePageItem.isEnabled && userRolePageItem.isReadOnly
              ? AccessRights.readOnly : AccessRights.edit;

          rolePage.actions = [];
          if (!rolePagesToIgnore.includes(rolePage.pageName)) {
            rolesViewModel.navigation.push(rolePage);
          }

          if (rolePage.accessRights === AccessRights.readOnly) {
            const action = new UserRoleAction();
            action.id = 0;
            action.value = "- -";
            rolePage.actions.push(action);
          } else if (rolePage.accessRights === AccessRights.notAvailable) {
            const action = new UserRoleAction();
            action.id = 0;
            action.value = AccessRights.notAvailable;
            rolePage.actions.push(action);
          } else {
            userRolePageItem.userRolePageActions.forEach(function (userRolePageActionItem: UserRolePageAction) {

              if (userRolePageActionItem.isEnabled) {
                const action = new UserRoleAction();
                action.id = userRolePageActionItem.userPageActionId;
                action.value = userRolePageActionItem.sysRolePageAction?.actionName;
                rolePage.actions.push(action);
              }
            });
          }

        }
      });

      if (rolesViewModel.roleType !== RoleTypes.External) { // Internal and Super User (not External User). A Super User "is" an Internal User also
        result.internalUserRoles.push(rolesViewModel);
      } else {
        if(rolesViewModel.roleName !== "Account Holder")
          result.externalUserRoles.push(rolesViewModel)
      }

      Utils.sortAlphabetically(result.internalUserRoles, "roleName");
      Utils.sortAlphabetically(result.externalUserRoles, "roleName");
    });

    return result;
  }
}
export class AdminRoleService {

  async getUserRolesFromCache(): Promise<UserRole[]> {

    const readPayload: AxiosReadPayload = {
      url: 'user/v2/roles'
    }

    const response = await axiosReadHelper(readPayload);
    let roleId = 0;

    response.entity.forEach(function (e: any) {
      e.id = roleId + 1;
    });

    const userRoles: UserRole[] = response.entity;

    localCache.userRoles = userRoles;
    return userRoles;
  }

  // Get Rollup User Role for List View
  async getUserRoles(): Promise<UserRoleListViewModel> {
    const userRoles: UserRole[] = await this.getUserRolesFromCache();

    return userRolesHelper.mapUserRoles(userRoles);
  }

  // Get System Role (Used to Layout Add/Edit Role Screen)
  async GetSystemRolePages(): Promise<SysRolePage[]> {
    const readPayload: AxiosReadPayload = {
      url: 'administration/sysrole/listing'
    }
    
    const responseData = await axiosReadHelper(readPayload);
    let i = 0;

    let systemPageRoles: SysRolePage[] = responseData.entity;

    systemPageRoles.forEach(function (pageItem: SysRolePage) {

      // Build Key for formik with no spaces
      pageItem.sysRoleKey = "nav" + pageItem.rolePageName.split(" ").join("");

      if (pageItem.sysRolePageAction) {
        pageItem.sysRolePageAction.forEach(function (actionItem: SysRolePageAction) {
          actionItem.actionKey = pageItem.sysRoleKey + actionItem.actionName.split(" ").join("");
        });
        pageItem.sysRolePageActionCount = pageItem.sysRolePageAction.length;
      }
      
      i++
      pageItem.id = i;
    });

    return systemPageRoles;
  }

  async getUserRole(userRoleId: number): Promise<AddEditRoleState> {

    // Get Latest Page System Role Pages Meta Data
    const systemRoles = await adminRoleService.GetSystemRolePages();

    // Get Existing User Role or Default to Empty for Add New Role
    let currentUserRole: UserRole = emptyUserRole;
    if (userRoleId > 0) {

      // /administration/user/{userId

      const readPayload: AxiosReadPayload = {
        url: `administration/role/${userRoleId}`
      }

      const response = await axiosReadHelper(readPayload);
      currentUserRole = response.entity

    }

    // Setup Standard User Role Fields
    const userRoleFieldState: UserRoleFieldState = new UserRoleFieldState();
    userRoleFieldState.userRoleId = currentUserRole.userRoleId;
    userRoleFieldState.roleName = currentUserRole.roleName;
    userRoleFieldState.roleDescription = currentUserRole.roleDesc;

    const addEditRoleState: AddEditRoleState = new AddEditRoleState();
    addEditRoleState.fieldState = userRoleFieldState;
    addEditRoleState.userRole = currentUserRole;

    addEditRoleState.systemRoles = systemRoles;
    addEditRoleState.roleEnableState = [];

    return addEditRoleState;
  }

  async saveUserRole(originalUserRoles: any, data: UserRole): Promise<AxiosResultStatus> {
    let roleToSave = new UserRole();
    roleToSave.userRoleId = data.userRoleId || 0;
    roleToSave.roleName = data.roleName;
    roleToSave.roleDesc = data.roleDesc;
    roleToSave.roleType = data.roleType;
    roleToSave.isActive = data.isActive;
    roleToSave.userRolePages = data.userRolePages;

    const copyOfUserRoles = JSON.parse(JSON.stringify(originalUserRoles));

    const payload: AxiosSavePayload = {
      dataToSave: roleToSave,
      dataId: roleToSave.userRoleId,
      url: "administration/role"
    }

    const saveResult = await axiosSaveHelper(payload);

    if (!saveResult.hasErrors) {
      if (data.userRoleId === undefined) {
        data.userRoleId = saveResult.entity.userRoleId;
        const mappedUserRole = userRolesHelper.mapUserRoles([data]);
        copyOfUserRoles.internalUserRoles.push(mappedUserRole.internalUserRoles[0])

      } else {
        const index = copyOfUserRoles.internalUserRoles.findIndex((e: UserRole) => e.userRoleId === data.userRoleId);
        const mappedUserRole = userRolesHelper.mapUserRoles([data]);

        copyOfUserRoles.internalUserRoles[index] = mappedUserRole.internalUserRoles[0]
      }
    }

    saveResult.entity = copyOfUserRoles
    return saveResult;
  }



}

export const adminRoleService = new AdminRoleService();
