import './../../scss/components/_icons.scss';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import CustomizeableAccordion from './customizeable-accordion';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
  },
  link: {
    [theme.breakpoints.down('lg')]: {
      display: 'block !important',
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-block !important',
    }
  }
}));

interface footerProps {
  isMobile?: boolean
}

function FppFooter(props: footerProps) {
  const { isMobile } = props;
  const contents = (classes: any, year: number) => (
    <Grid container justifyContent="center" alignItems="center" className="py-4 px-6">
      <Grid container spacing={2} justifyContent="center" className="icon-colors">
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2} className={classes.root} >
            <Grid item>
              <Box display="flex" alignItems="center">
                <PhoneIcon className="icon" fontSize="large" />
                <Typography variant="body1" className="pl-1">
                  855-4finpay (434-6729)
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex">
                <EmailIcon className="icon" />
                <Typography variant="body1" className="pl-2 pr-8">
                  support@finpay.net
              </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2} className={classes.root} >
            <Grid item>
              <Typography
                color={'#0093d5'}
                className={classes.link}
                onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
              >
                Terms & Conditions
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                color={'#0093d5'}
                className={classes.link}
                onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
              >
                Privacy Policy
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {`FinPay, All Rights Reserved © ${year}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )

  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <div className="mt-8">
    <div className="mt-8 py-2">
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
        {isMobile ? (
          <CustomizeableAccordion
            headerText="Support Contact Information"
            details={contents(classes, year)}
            />
          ) : (
            contents(classes, year)
          )
        }
      </Paper>
    </div>
    </div>
  );
}

export default FppFooter;
