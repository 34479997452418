// import { boolean } from "yup";
import { showErrorStatus } from "../../security/state/user-slice";
import { configSettings } from "../../shared/configuration/config-settings";
import { GuestParameterInfo } from '../models/anonymous-guest';

export type UrlConfigure = {
  isCurrentUrlPublic?: boolean,
  channelStatus?: boolean,
  channelType?: string | null,
  guestInfo?: GuestParameterInfo
}

// setting object of bools based on url params
export const isCurrentUrlPublic = (url: string) => {
  let urlData: UrlConfigure = {};
  const queryString = window.location.search;
  const channelCheck = url.includes('ch=');

  const isUrlPatientPortal = ((url.includes(configSettings.patientPortalUrl1) || url.includes(configSettings.patientPortalUrl2)))
  const isGuestPayment = isUrlPatientPortal && url.includes('webpayment');
  const isForgotPassword = url.includes('forgot-password');
  const isPaymentProgramEnrollment = isUrlPatientPortal && url.includes('payment-program-enrollment')

  urlData.isCurrentUrlPublic = isGuestPayment || isForgotPassword || isPaymentProgramEnrollment
  urlData.channelStatus = channelCheck;

  if (queryString && channelCheck) {
    const urlParams = new URLSearchParams(queryString);
    const channel = urlParams.get('ch');
    urlData.guestInfo = Object.fromEntries(urlParams);
    urlData.channelType = channel;
  }
  return  urlData;
}

export const paynowHelper = {
  robustErrorHandler: (error: { entity: { code: string, message: string }, errorMessage: string, hasErrors: boolean }, thunkAPI: any) => {
    const { entity, errorMessage } = error;
    if (((entity?.code !== "INTERNAL_SERVER_ERROR") && entity?.message) || (entity?.message)) {
      // handle external error
      thunkAPI.dispatch(showErrorStatus(entity?.message))
      throw new Error(entity?.message)
    } else {
      if (entity) {
        const errorMessage = entity.toString();
        thunkAPI.dispatch(showErrorStatus(errorMessage));
        throw new Error(errorMessage);
      } else {
        // handle internal error
        thunkAPI.dispatch(showErrorStatus(errorMessage))
        throw new Error(errorMessage);
      }
    }
  }
}