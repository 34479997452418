import { LoadingOverlay } from '@finpay-development/shared-components';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ImplementationFacility,
} from '../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import { getClient } from '../../implementation-specialist/state/clients/implementation-clients-thunk';
import { getClientFacilities } from '../../implementation-specialist/state/facility/implementation-facility-thunk';
import { blankClient } from '../../shared/model/client';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import FacilityListItem from './facility-list-item';

function ClientFacilities() {
  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    allowedClient: useSelector((state: RootState) => {
      return state.userContext.userProfile.allowedClients[0]
    }),
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client
    }),
    isLoadingClient: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.isLoading
    }),
    facilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationFacility.facilities
    }),
    isLoadingFacilities: useSelector((state: RootState) => {
      return state.implementationContext.implementationFacility.isLoading
    })
  }
  const { allowedClient, client, facilities, isLoadingClient, isLoadingFacilities } = selectors;

  const isLoading = (isLoadingClient || isLoadingFacilities);

  useEffect(() => {
    if (allowedClient?.clientId && !isLoadingClient && ((_.isEqual(client, blankClient)))) {
      dispatch(getClient(allowedClient.clientId));
    }
    // eslint-disable-next-line
  }, [dispatch, allowedClient]);

  useEffect(() => {
    if (client?.clientId && !isLoadingFacilities) {
      dispatch(getClientFacilities(client.clientId));
    }
    // eslint-disable-next-line
  }, [dispatch, client])

  return (
    <main>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <div className="content">
          {(facilities?.length > 0) && facilities.map((facilityList: ImplementationFacility[], index: number) => (
            <div className="py-2" key={index}>
              <FacilityListItem
                facilities={facilityList}
              />
            </div>
          ))}
        </div>
      )}
    </main>
  )
}

export default ClientFacilities
