import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
  useCallback,
} from 'react';
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import {useFormik} from 'formik';
import {
  TextField,
  DialogActionButton,
  Toggle,
} from '@finpay-development/shared-components';
import { RootState } from "../../../shared/state/root-reducer";
import {CircularProgress, MenuItem, Typography} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import {
  filterUsers,
  saveUser,
} from "../../state/users/admin-thunk";
import "./../../../scss/pages/admin/_admin-users.scss";
import { ValidationError } from "yup";
import { UserInfo } from "../../models/user-info";
import { UserInfoRole } from "../../models/user-info-role";
import { UserInfoClient } from "../../models/user-info-client";
import { userFormikValues } from "../../models/user-formik-values";
import { showErrorStatus } from "../../../security/state/user-slice";
import { clearStatus } from "../../state/users/admin-slice";
import { DomainAccess } from "../../../shared/enums";
import { userForm } from "../../../shared/validation/schemas";
import { Utils } from "../../../shared/utils";
import { getAdminUserRoles } from "../../state/admin-roles-thunk";
import { externalUserRoles } from "../../data/external-user-roles";
import { AppDispatch } from "../../../shared/state/store";
import TimingRiskSelect from "../../../shared/components/timing-risk";
import { TimingRisk } from "../../../meta-data/models/metaData";
import _ from 'lodash';

const ClientFacilityCheckbox = React.lazy(()=> import('../common/client-facility-checkbox'))

interface EditUserModalProps {
  open: boolean;
  handleEditUserModalCancel: () => void;
  handleEditUserModalSubmit: (isEditMode: boolean) => void;
}

const compareFunction = (prevValue: any, nextValue: any) =>
    _.isEqual(prevValue, nextValue);

export const buildClientsArr = (
    accessAllClients: boolean,
    userFormClientsObj: Record<string, UserInfoClient>
): Array<UserInfoClient> => {

  if(accessAllClients){
    return [{
      clientId: 0,
      clientName: "All Clients",
      isFacilityLevel: true,
      allowedFacilities: []
    }]
  }else{
    return Object.values(userFormClientsObj).map((allowedClient) => {
      if(allowedClient.isFacilityLevel) {
        allowedClient.allowedFacilities = []
      }
      /**
       * FPS-7436:
       * Below is to correct user records with data issues created before the
       * clients/facility checkbox component was implemented.
       * If the existing user has user_facility_scope records in the db,
       * then we assume the user does not have facility level access.
       * */
      if(_.isNil(allowedClient.isFacilityLevel)){
        allowedClient.isFacilityLevel = allowedClient.allowedFacilities.length <= 0;
      }
      return allowedClient
    })
  }
}


export function EditUserModal(props: EditUserModalProps) {
  const { open, handleEditUserModalCancel, handleEditUserModalSubmit } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const allUsers = useSelector((state: RootState) => {
    return state.adminContext.adminUserContext.userSearch.allUsers;
  });

  const userRoles = useSelector((state: RootState) => {
    return state.adminContext.adminRoleContext.userRoles
  });

  const allClientsWithFacilitiesMap = useSelector(
      (state: RootState) =>
          state.implementationContext.implementationSpecialistClient
              .allClientsWithFacilitiesMap!
  )

  const user = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.selectedUser,
      compareFunction
  );

  const saveStatus = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.modalSaveStatus
  );

  const modelErrorMessage = useSelector(
    (state: RootState) => state.adminContext.adminUserContext.modelErrorMessage
  );

  const timingRiskMetaData = useSelector(
    (state: RootState) => state.metaData.timingRisk
  );

  useEffect(() => {
    dispatch(getAdminUserRoles());
  }, [dispatch]);

  let allUserRoles = [...userRoles.internalUserRoles, ...userRoles.externalUserRoles];
  let isUserAccountHolder: boolean = false;
   // if user is account holder, then we need their role to stay as account holder when we save the payload.
   // otherwise, we will exclude account holder to prevent super admins from changing their role to the account holder role.
  if (user?.userRole?.roleId === 4) {
    isUserAccountHolder = true;
    allUserRoles.push(externalUserRoles[2]);
  }

  const selectedClientsAndFacilitiesMap = useMemo(()=>{
    return user.clients.reduce((clientsObj: Record<string, UserInfoClient>, currClient: UserInfoClient)=>{
      clientsObj[currClient.clientId] = currClient;
      return clientsObj
    }, {})
  }, [user.clients]);

  const filteredClientsAndFacilitiesMap = useMemo(()=>{
    //filtering out clients without any facilities
    const filteredArr = Array.from(allClientsWithFacilitiesMap.entries()).filter(([_, clientObj])=>{
      return Array.isArray(clientObj.clientFacilities) && clientObj.clientFacilities.length > 0 && clientObj.clientId !== 0
    })
    return new Map(filteredArr)
  }, [allClientsWithFacilitiesMap])


  const initialValues: userFormikValues = {
    userId: user.userId,
    isEditMode: user.userId > 0,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    confirmEmail: user.email,
    phoneNumber: user.phoneNumber,
    confirmPhone: user.phoneNumber,
    isActive: user.isActive,
    domainAccess: user.domainAccess === DomainAccess.Finpay,
    role: user.userRole?.roleId || 1,
    clients: Array.isArray(user.clients) && user.clients.every((client: UserInfoClient) => client.clientId !==0) ? selectedClientsAndFacilitiesMap: {},
    accessAllClients: Array.isArray(user.clients) ? user.clients.some((client: UserInfoClient) => client.clientId ===0) : false,
    saveButtonText: user.userId > 0 ? "Update User" : "Add User",
    isSSO: user.isSSO,
    timingRisk: user.userTimingRisk ? user.userTimingRisk?.map((timingRisk) => timingRisk?.timingRiskId) : [],
    accessAllTimingRisks: user.hasAllTimingRisks
  };

  const validationSchema = Yup.object(userForm);

  async function validateForm(values: any) : Promise<boolean> {
    await validationSchema
      .validate(values, { abortEarly: false })
        .then(function () {

          if((values.accessAllTimingRisks && values.timingRisk!.length !==0) ||
              (!values.accessAllTimingRisks && values.timingRisk.length === 0)
          ) throw Error("Error with timing risk");

          if(!_.isPlainObject(values.clients) || (values.accessAllClients && Object.keys(values.clients).length !==0) ||
              (!values.accessAllClients && Object.keys(values.clients).length ===0)
          ) throw Error("Error with clients")

          setEnableSaveButton(true);

        })
        .catch(function (err: ValidationError) {
          setEnableSaveButton(false);
        });

    return true;
  }

  // Map formik value to save model
  function mapToSaveModel(values: userFormikValues, initialValues?: UserInfo): UserInfo {
    const userToSave = new UserInfo();

    userToSave.userId = values.userId;
    userToSave.firstName = values.firstName;
    userToSave.lastName = values.lastName;
    userToSave.fullName = `${userToSave.firstName} ${userToSave.lastName}`;
    userToSave.phoneNumber =  values.phoneNumber;
    userToSave.email =  values.email;
    userToSave.isActive =  values.isActive;
    userToSave.isPatient = isUserAccountHolder
    if(values.domainAccess){
      userToSave.domainAccess =  DomainAccess.Finpay
    } else  {
      userToSave.domainAccess =  DomainAccess.Client;
    }
    userToSave.isSSO = values.isSSO
    userToSave.hasAllTimingRisks = values.accessAllTimingRisks;

    // Map selected role to role type
    const selectedRole = allUserRoles.find((role) => role.userRoleId === values.role);
    const roleToSave = new UserInfoRole();

    roleToSave.roleId = selectedRole?.userRoleId || 0;
    roleToSave.roleName = selectedRole?.roleName || "";
    userToSave.userRole = roleToSave;

    // Map Clients from Selected Checkboxes
    userToSave.clients =  buildClientsArr(values.accessAllClients, values.clients)

    userToSave.userTimingRisk = [];
    values.timingRisk?.forEach(function (item: number) {
      const timingRiskItem = timingRiskMetaData.find(
          (timingRisk: TimingRisk) => timingRisk.timingRiskId === item
      );
      userToSave?.userTimingRisk?.push(timingRiskItem!);
    });

    if (initialValues) {
      userToSave.userTimingRisk = initialValues.userTimingRisk;
    }

    return  userToSave;
  }

  async function handleSave() {
    const userToSave: UserInfo = mapToSaveModel(userModalFormik.values);
    const doesPhoneNumberAlreadyExist = (user: UserInfo) => (
      user.phoneNumber === userToSave.phoneNumber
    );
    const doesEmailAlreadyExist = (user: UserInfo) => (
      user.email === userToSave.email
    );

    let isEmailError = false;
    let isPhoneNumberError = false;

    if (initialValues?.isEditMode) { // if we are editing a user, we need to exclude their email/phone number from the duplicate check.
      const exceptCurrentUser = allUsers.filter((user:UserInfo) => (user.userId !== userToSave.userId));
      isEmailError = exceptCurrentUser.some(doesEmailAlreadyExist);
      isPhoneNumberError = exceptCurrentUser.some(doesPhoneNumberAlreadyExist);
    } else {
      isEmailError = allUsers.some(doesEmailAlreadyExist);
      isPhoneNumberError = allUsers.some(doesPhoneNumberAlreadyExist);
    }
  if (isPhoneNumberError && !isUserAccountHolder) {
      dispatch(showErrorStatus("Duplicate mobile phone is already on file, please try again"));
    } else if (isEmailError) {
      dispatch(showErrorStatus("Duplicate email is already on file, please try again or update the existing user record"));
    } else {
      await dispatch(saveUser(userToSave));
    }
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleEditUserModalSubmit(initialValues.isEditMode);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(modelErrorMessage));
    }

    dispatch(clearStatus());
    dispatch(filterUsers());
  }

  function handleCancelCallback() {
    handleEditUserModalCancel();
  }

  // if edit mode and if the user is an account holder, then we need to hide certain fields.
  const shouldHideClientAndRoleControls = initialValues.isEditMode && (user.userRole?.roleId === 4)

  const userModalFormik = useFormik({
    innerRef: formRef,
    initialValues: initialValues,
    validationSchema: validationSchema,
    validate: validateForm,
    onSubmit: () => {}
  })

  const handleClientAndFacilityCheckboxOnChange = useCallback((selectedClientsAndFacilities: Record<string, UserInfoClient>)=>{
    userModalFormik.setFieldValue('clients', selectedClientsAndFacilities);
  }, [userModalFormik.values.clients])

  return (
    <Dialog
      scroll="body"
      className="modal"
      open={open}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
          {userModalFormik.initialValues.isEditMode && <span>Edit User</span>}
          {!userModalFormik.initialValues.isEditMode && <span>Add User</span>}
      </DialogTitle>
      <DialogContent>
          <form>
            <Grid container spacing={2}>
                <Grid xs={6} item>
                  <TextField
                    error={userModalFormik.touched['firstName'] &&
                    userModalFormik.touched['firstName'] &&
                      userModalFormik.errors['firstName']}
                    label="First Name"
                    name="firstName"
                    value={userModalFormik.values.firstName}
                    onChange={userModalFormik.handleChange}
                    onBlur={userModalFormik.handleBlur}
                    placeholder="Enter First Name"
                    test-id="user-modal-first-name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={userModalFormik.touched['lastName'] &&
                    userModalFormik.touched['lastName'] &&
                      userModalFormik.errors['lastName']}
                    label="Last Name"
                    name="lastName"
                    value={userModalFormik.values.lastName}
                    onChange={userModalFormik.handleChange}
                    onBlur={userModalFormik.handleBlur}
                    placeholder="Enter Last Name"
                    test-id="user-modal-last-name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    disabled={userModalFormik.values.isEditMode}
                    error={userModalFormik.touched['email'] &&
                    userModalFormik.touched['email'] &&
                      userModalFormik.errors['email']}
                    label="Email"
                    name="email"
                    maxLength={128}
                    value={userModalFormik.values.email}
                    onChange={userModalFormik.handleChange}
                    onBlur={userModalFormik.handleBlur}
                    placeholder="Enter Email"
                    test-id="user-modal-email"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    disabled={userModalFormik.values.isEditMode}
                    error={userModalFormik.touched['confirmEmail'] &&
                    userModalFormik.touched['confirmEmail'] &&
                      userModalFormik.errors['confirmEmail']}
                    label="Confirm Email"
                    name="confirmEmail"
                    value={userModalFormik.values.confirmEmail}
                    onChange={userModalFormik.handleChange}
                    onBlur={userModalFormik.handleBlur}
                    placeholder="Reenter Email"
                    test-id="user-modal-confirm-email"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={userModalFormik.touched['phoneNumber'] &&
                    userModalFormik.touched['phoneNumber'] &&
                      userModalFormik.errors['phoneNumber']}
                    label="Mobile Phone"
                    name="phoneNumber"
                    value={userModalFormik.values.phoneNumber}
                    onChange={(e: Event) => {
                      userModalFormik.handleChange(e);
                      userModalFormik.setFieldValue("phoneNumber", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={userModalFormik.handleBlur}
                    maxLength={12}
                    placeholder="Enter Mobile Phone"
                    test-id="user-modal-mobile-phone"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={userModalFormik.touched['confirmPhone'] &&
                    userModalFormik.touched['confirmPhone'] &&
                      userModalFormik.errors['confirmPhone']}
                    label="Confirm Mobile Phone"
                    name="confirmPhone"
                    value={userModalFormik.values.confirmPhone}
                    onChange={(e: Event) => {
                      userModalFormik.handleChange(e);
                      userModalFormik.setFieldValue("confirmPhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={userModalFormik.handleBlur}
                    maxLength={12}
                    placeholder="Reenter Mobile Phone"
                    test-id="user-modal-confirm-mobile-phone"
                  />
                </Grid>
                <div hidden={true}>
                  <Grid item xs={12}>
                  <Typography className="mb-2" variant="body2">
                    Domain Access
                  </Typography>
                  <Toggle
                    leftText="FinPay"
                    rightText="Client"
                    name="domainAccess"
                    formik={userModalFormik}
                    value={userModalFormik.values.domainAccess}
                  />
                </Grid>
                </div>
                {(!shouldHideClientAndRoleControls) && (
                  <>
                    <Grid item xs={12} className="mt-4">
                      <TextField
                        select={true}
                        required={true}
                        error={false}
                        label="Role"
                        name="role"
                        value={userModalFormik.values.role}
                        onChange={userModalFormik.handleChange}
                        onBlur={userModalFormik.handleBlur}
                      >
                        {allUserRoles.map((role) => (
                          <MenuItem key={role.userRoleId} value={role.userRoleId}>
                            {role.roleName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <TimingRiskSelect formik={userModalFormik} timingRiskData={timingRiskMetaData} />
                    <Grid item xs={12}>
                      <Typography className="mb-4 mt-4" variant="h3">
                        Client Access
                      </Typography>
                      <Typography className="mb-2" variant="body2">
                        Select All Clients
                      </Typography>
                      <Toggle
                          name="accessAllClients"
                          value={userModalFormik.values.accessAllClients}
                          formik={userModalFormik}
                          onChange={async ()=>{
                            await userModalFormik.setFieldValue("clients", {})
                            await userModalFormik.validateForm();
                          }}
                      />
                        <Grid item xs={12} className="mt-3" style={{
                          display: userModalFormik.values.accessAllClients ? 'none' : 'block',
                          height: '30vh'
                        }}>
                          <Suspense fallback={ <CircularProgress className="mt-3"/>}>
                            <ClientFacilityCheckbox
                                allClientsWithFacilitiesMap={filteredClientsAndFacilitiesMap}
                                selectedClientsAndFacilitiesObj={userModalFormik.values.clients}
                                handleCheckBoxOnChange={handleClientAndFacilityCheckboxOnChange}
                            />
                          </Suspense>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className="mb-2" variant="body2">
                        LogIn with SSO
                      </Typography>
                      <Toggle
                          name="isSSO"
                          value={userModalFormik.values.isSSO}
                          formik={userModalFormik}
                        />
                    </Grid>
                  </>
                )}
              </Grid>
          </form>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={initialValues.saveButtonText}
          saveStatus={saveStatus}
          spinnerLeftPosition={5.5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
