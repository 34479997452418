import '../../scss/components/mobile-payments.scss';

import {useSelector} from 'react-redux';
import {RootState} from '../../shared/state/root-reducer';
import {TextField} from '@finpay-development/shared-components';
import {useFormik} from 'formik';
import {Utils} from '../../shared/utils';
import { Paper, Typography, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {PaymentInfoView} from './payment-info-view'
import FinDigitalPlatformFooter from '../../shared/components/findigital-platform-footer';
import FinMobileErrorMsg from '../../shared/components/finmobile-error-msg';
import { useNavigate } from 'react-router';

export interface PatientInfo{
  firstName: string;
  lastName: string;
  clientName: string;
  email: string;
  mobileNumber: string;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  acknowledgeSPA: boolean;
  pfrAmt: number;
  patientEncounterId: number;
  patientId: number;
}

export default function MobilePaymentView(){
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const programId = queryParams.get('program');

  const stateFields = {
    patientEncounter: useSelector((state: RootState) => (state?.patientContext?.selectedEncounter)),
    matchingRule: useSelector((state: RootState) => (state?.patientContext?.matchingRule)),
    isPublicModeLoggedIn: useSelector(
        (state: RootState) => state.adminContext.adminUserContext.isPublicModeLoggedIn
    ),
    patientInfo: useSelector(
        (state: RootState) => state.guestContext.mobilePayment.patientInfo
    ),
    channelType: useSelector(
      (state: RootState) => state.guestContext.channelType
    ),
    isMobileConverted: useSelector(
      (state: RootState) => state.guestContext.isMobileConverted
    )
  }
  const {
    isPublicModeLoggedIn, patientInfo, channelType, isMobileConverted, patientEncounter, matchingRule
  } = stateFields;

  let paymentAmount = null;
  if (programId === 'optimal') {
    paymentAmount = matchingRule?.optimalDownPmtType === '%'
      ? matchingRule?.optimalDownPmt * patientEncounter.pfrAmt / 100
      : matchingRule?.optimalDownPmt;
  } else {
    const pfrAmt = patientEncounter.pfrAmt;
    const pfrCollected = patientEncounter?.patientPaymentProgram?.length
      ? patientEncounter?.patientPaymentProgram[0].patientPaymentSchedule?.pfrBalance
      : 0;
    paymentAmount = pfrAmt - pfrCollected;
  }

  const initialValues = {
    firstName: patientInfo.firstName ? patientInfo.firstName : "",
    lastName: patientInfo.lastName ? patientInfo.lastName : "",
    mobileNumber: patientInfo.mobileNumber ? patientInfo.mobileNumber : "",
    email: patientInfo.email? patientInfo.email : "",
    client: patientInfo.clientName ? patientInfo.clientName : "",
    acknowledgeSPA: patientInfo.acknowledgeSPA ? patientInfo.acknowledgeSPA : false,
    paymentAmount,
  }

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const duplicatePaymentErrorHeader = "Sorry, we aren't able to process your payment."
  const duplicatePaymentErrorContent = (
    <>
      A payment for this transaction has already been recieved. For further support, please contact FinPay at <a href="mailto:support@finpay.com">support@finpay.com</a>
    </>
  )

  return(
      <>
        {isPublicModeLoggedIn && !isMobileConverted && (
          <Paper className="payment-wrapper">
            <Button variant="text" className="pl-0" onClick={() => {navigate(-1)}}>
              <ArrowBackIosIcon /> Back
            </Button>
            {channelType !== 'mobile' && (
              <Typography variant="h3" className="mobile-header pt-2">
                {formik.values.client}
              </Typography>
            )}
            <Typography variant="h3" className="mobile-heading pt-2">
              {`${formik.values.firstName} ${formik.values.lastName}`}
            </Typography>
            <TextField
                required
                error={
                    formik.touched["mobileNumber"] && formik.errors["mobileNumber"]
                }
                name="mobileNumber"
                label="Mobile Number"
                placeholder="Enter Mobile Phone Number"
                value={formik.values.mobileNumber}
                onChange={(e: Event) => {
                  formik.handleChange(e);
                  formik.setFieldValue("mobileNumber", Utils.formatPhoneNumber((e.target as HTMLInputElement).value));
                }}
                onBlur={formik.handleBlur}
                className="input-item"
            />
            <PaymentInfoView parentFormValues={formik.values}/>
          </Paper>
        )}
        <FinMobileErrorMsg 
          errorStatus={isMobileConverted}
          errorMessageHeader={duplicatePaymentErrorHeader}
          errorMessageContent={duplicatePaymentErrorContent}
        />
        <FinDigitalPlatformFooter showEmailFooter={false} channelType={channelType} />
      </>
  )
}