export interface PFRAdjustment {
	patientId: number;
	patientEncounterId: number;
	patientPFRId: number;
	adjustmentDt: string;
	adjustmentAmt: number;
	adjustmentReasonId: number;
	adjustmentReason?: string;
	preAdjustmentPfrAmt?: number;
	patientPFRAdjustmentId: number;
}

export const emptyPFRAdjustment: PFRAdjustment = {
	patientId: -1,
	patientEncounterId: -1,
	patientPFRId: -1,
	adjustmentDt: '',
	adjustmentAmt: 0,
	adjustmentReasonId: -1,
	adjustmentReason: '',
	patientPFRAdjustmentId: -1,
}
