import { commonFields } from "../../shared/validation/schemas";

//For guest anonymous login
  export interface GuestModel {
    email: string;
    confirmEmail: string;
    firstName: string;
    lastName: string;
    middleInitial: string;
    cellPhone: string;  
    billingAddress1: string;
    billingAddress2: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    accountNumber: string;
    confirmAcctNumber: string;    
    saveInfoForLater: boolean;
    patientAddress1: string;
    patientAddress2: string;
    patientCity: string;
    patientState: string;
    patientZip: string;
    isPatientAddrSameAsBilling: boolean;
    clientsPatientAccountId?: string;
    clientsPatientIOCId?: string;
    paymentChannelId?: number;
    extClientFacilityId?: string;
    userName?: string;
    paymentReason?: string;
    clientFacilityId?: number;
    crmObjectId?: string;
  }

  export const emptyGuestModel: GuestModel = {
    email: '',
    confirmEmail: '',
    firstName: '',
    lastName: '',
    middleInitial: "",
    cellPhone: "",
    billingAddress1: "",
    billingAddress2: "",
    billingCity: "",
    billingState: "",
    billingZip: "",
    accountNumber: "",
    confirmAcctNumber: "",    
    saveInfoForLater: false,
    patientAddress1: "",
    patientAddress2: "",
    patientCity: "",
    patientState: "",
    patientZip: "",
    isPatientAddrSameAsBilling: true,
    clientsPatientAccountId: "",
    clientsPatientIOCId: "",
    paymentChannelId: 1,
    extClientFacilityId: "",
    userName: "",
    paymentReason: "",
    clientFacilityId: undefined
  }

  export const guestInfo = {
    email: commonFields.EMAIL,
    firstName: commonFields.FIRST_NAME,
    lastName: commonFields.LAST_NAME,
    confirmEmail: commonFields.RETYPE_EMAIL
  }
  
  //For values variable
  export interface GuestForm{
    email: string;   
    firstName: string;
    lastName:  string
  }

  export class Billing {
    email!: string;    
    firstName?: string;
    lastName?: string;  
    middleInitial?: string;
    cellPhone?: string;  
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: string;
    billingZip?: string;
    accountNumber?: string;
    confirmAcctNumber?: string;  
    saveInfoForLater?: boolean;
    patientAddress1?: string;
    patientAddress2?: string;
    patientCity?: string;
    patientState?: string;
    patientZip?: string;
    isPatientAddrSameAsBilling?: true;
    error?: string;
    patientId?: string;
    clientsPatientIOCId?: string;
  }
  
  //For values variable
  export interface BillingForm{
    email: string;
    firstName: string;
    lastName:  string;
    middleInitial: string;
    cellPhone: string;
    billingAddress1: string;
    billingAddress2: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    accountNumber: string,
    confirmAcctNumber: string,    
    saveInfoForLater: boolean,
    patientAddress1: string;
    patientAddress2: string;
    patientCity: string;
    patientState: string;
    patientZip: string;
    isPatientAddrSameAsBilling: boolean;
    clientsPatientAccountId?: string;
    clientsPatientIOCId?: string;
    userName?: string;
    paymentReason?: string;
    clientFacilityId: number;
  }

  export const emptyPaymentInfo: PaymentInfo = {
    nameOnCard: '',
    payerEmail: '',
    paymentAmount: '',
    stripeToken: '',
    stripeCardToken: ''
  }


  export interface PaymentInfo {
    nameOnCard: string;
    payerEmail: string;
    paymentAmount: string | number;
    stripeToken: string;
    stripeCardToken: string;
    acknowledgeSPA?: boolean;
  }

  export interface PayNowPaymentRequest {
    clientFacilityId: number;
    billingContactId: number;
    patientContactId:  number;
    externalPaymentMethodId: string;
    paymentMethodTypeId: number;
    externalLedgerId: string;
    externalPaymentId: string;
    paymentAmt: number;
    automationFee: number;
    merchantProcessingFee: number;
    thirdPartyFee: number;
    paymentAmtWithoutFees: number,    
    remPaymentAmt: number;
    paymentTypeId: number;
    paymentInitDate: string | null;
    authCode: string | null;
    authExpirationDt: string | null;
    isAuthExpired: boolean;
    isManual: boolean; 
    paymentStatus: string | null;
    paymentStatusDt: string | null;
    paymentAuthDt: string | null;
    paymentCaptureDt: string | null;
    paymentGroupId: string | null;
    externalResponse: string | null;
    patientLedgerId: string | null;
    externalPayoutId: string | null;
    ormVersion: number;
  }

  export const defaultPayNowPaymentRequest: PayNowPaymentRequest = {
    clientFacilityId: 0,
    billingContactId: 0,
    patientContactId: 0,
    externalPaymentMethodId: '',
    paymentMethodTypeId: 1,
    externalLedgerId: '',
    externalPaymentId: '',
    paymentAmt: 0,
    automationFee: 0,
    merchantProcessingFee: 0,
    thirdPartyFee: 0,
    paymentAmtWithoutFees: 0,    
    remPaymentAmt: 0,
    paymentTypeId: 2,
    paymentInitDate: null,
    authCode: null,
    authExpirationDt: null,
    isAuthExpired: false,
    isManual: true,
    paymentStatus: null,
    paymentStatusDt: null,
    paymentAuthDt: null,
    paymentCaptureDt: null,
    paymentGroupId: null,
    externalResponse: null,
    patientLedgerId: null,
    externalPayoutId: null,
    ormVersion: 0
  }

  export interface GuestParameterInfo {
    ch?: string;
    crmObjectId?: string;
    firstName?: string;
    lastName?: string;
    billingAddress1?: string;
    billingAddress2?: string;
    billingCity?: string;
    billingState?: any;
    billingZip?: string;
    clientsPatientAccountId?: string;
  }

  export interface ChannelInfo {
    paymentChannelId: number;
    paymentChannelName: string;
    shortName: string;
  }

export interface ClientFacilities {
  clientFacilityId?: string | number;
  facilityName?: string;
  clientId?: string | number;
  phoneNum?: string;
  websiteUrl?: string;
  client?: {
    paymentProgramVersion?: number;
    supportEmail?: string;
  }
}