import { saveCallbackStatus } from "@finpay-development/shared-components";
import { createSlice } from "@reduxjs/toolkit";
import { ApplicationStatusState, emptyApplicationStatus } from "src/security/model/application-status";
import { getAllClientCRM, getClientCRM, saveCRM } from "./implementation-crm-thunk";
import { CRMType, ImplementationCRM, emptyCRMType, emptyImplementationCRM } from "src/implementation-specialist/components/implementation-clients/details/models/implementation-crm";
import { CRMDetails, CRMForm, emptyCRMDetails, emptyCRMForm } from "src/implementation-specialist/models/crm-form";
import { UNKNOWN_SERVER_ERROR } from "src/shared/state/saving-status";


interface CRMState {
    crm: CRMType,
    implementationCRM: ImplementationCRM[],
    currentCRMForm: Partial<CRMForm>
    currentCRMDetails: CRMDetails,
    apiResponseCRM: ImplementationCRM,
    lastCreatedCRMId:number|undefined,

    // common state for most/all slices
  apiStatus: saveCallbackStatus;
  errorMessage: string;
  isLoading: boolean;
  applicationStatus: ApplicationStatusState;
}

const initialState: CRMState = {
    crm: emptyCRMType,
    implementationCRM: [],
    currentCRMForm: emptyCRMForm,
    currentCRMDetails: emptyCRMDetails,
    apiResponseCRM:emptyImplementationCRM,
    apiStatus: saveCallbackStatus.none,
    errorMessage: "",
    isLoading: false,
    applicationStatus: emptyApplicationStatus,
    lastCreatedCRMId: 0
}

const crmSlice = createSlice({
    name: "crmSlice",
    initialState,
    reducers: {
        clearCRMForm: (state) => {
            state.currentCRMForm = emptyCRMForm
        },
        clearStatus: (state) => {
            state.apiStatus = saveCallbackStatus.none;
            state.errorMessage = ""
        },
        clearImplementationCRM: (state) => {
            state.implementationCRM = []
        },
        resetCRMContext: (state) => {
            return initialState
        },
        resetAddCRM: (state) => {
            state.isLoading = false
        },

    },

    extraReducers: (builder) => {
        builder.addCase(saveCRM.fulfilled, (state, action) => {
            state.implementationCRM = action.payload.apiResponseCRM
            state.apiStatus = saveCallbackStatus.success
        });
        builder.addCase(saveCRM.pending, (state) => {
            state.apiStatus = saveCallbackStatus.none
        });
        builder.addCase(saveCRM.rejected, (state, action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR
            state.apiStatus = saveCallbackStatus.error
        });

        builder.addCase(getClientCRM.fulfilled, (state, action) => {
            state.currentCRMForm = action.payload.crmForm
            state.currentCRMDetails = action.payload.crmDetails
            state.apiResponseCRM = action.payload.apiResponseCRM
        })
        builder.addCase(getClientCRM.pending, (state) => {
            state.isLoading = false
        })
        builder.addCase(getClientCRM.rejected, (state,action) => {
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR
            state.apiStatus = saveCallbackStatus.error
        })

        builder.addCase(getAllClientCRM.fulfilled, (state, action) => {
            state.implementationCRM = action.payload.apiResponseCRM
            state.isLoading = false
        })
        builder.addCase(getAllClientCRM.pending, (state) => {
            state.isLoading = true
        })
        builder.addCase(getAllClientCRM.rejected, (state,action) => {
            state.isLoading = false
            state.errorMessage = action.error.message || UNKNOWN_SERVER_ERROR
        })

    }
})

export const {
    clearCRMForm,
    resetAddCRM,
    clearStatus,
} = crmSlice.actions;

export default crmSlice.reducer