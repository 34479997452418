import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MasterLists from "./master-lists/master-lists-view";
import Licensing  from "./licensing/licensing-view";
import RiskAssessmentMasterListView from "./risk-assessment-masterlist/risk-assessment-masterlist-view";
import "./../../scss/components/_tabs.scss";
import "./../../scss/pages/admin/_admin-configuration.scss";
import CommonRuleSet from "./common-rule-set/common-rule-set-view";
import ParameterSetting from "./parameter-setting/parameter-setting-view";
import RiskClasses from "./risk-classes/risk-classes-view";
import { getRules } from "../state/admin-configuration-thunk";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../shared/state/store";

function AdminConfigurationView() {
  const [fpViewTabIndex, setFpViewTabIndex] = useState(0);
  const [aaViewTabIndex, setAaViewTabIndex] = useState(0);
  const [configView, setConfigView] = useState('FinPass');

  const dispatch = useDispatch<AppDispatch>();

  function handleFpViewChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setFpViewTabIndex(newTabIndex);
  }

  function handleAaViewChange(event: React.ChangeEvent<{}>, newTabIndex: number) {
    setAaViewTabIndex(newTabIndex);
  }


  useEffect(() => {
    dispatch(getRules());
  }, [dispatch]);

  return (
    <>
      <main className="admin-configuration">
        <div className="content">

        <Box display="flex" justifyContent="flex-end">
          <Select
            className="MuiSelect-outlined"
            style={{
              border: "2px solid #b4becc",
            }}
            variant="outlined"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value = {configView}
            onChange={event => setConfigView(event.target.value as string)}
          >
            <MenuItem value={'FinPass'}>FinPass</MenuItem>
            <MenuItem value={'FinAdvisor'}>FinAdvisor</MenuItem>
          </Select>
        </Box>

        {/* Default View */}
        {configView === "FinPass" &&
          <Grid container spacing={2}>
            <Grid item xs={12} className="tabs">
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
                value={fpViewTabIndex}
                onChange={handleFpViewChange}
              >
                <Tab
                  disableRipple={true}
                  icon={<CheckCircleOutlineIcon />}
                  label="Common Rule Set"
                />
                <Tab
                  disableRipple={true}
                  icon={<CheckCircleOutlineIcon />}
                  label="Parameter Setting"
                />
                <Tab
                  disableRipple={true}
                  icon={<CheckCircleOutlineIcon />}
                  label="Risk Classes"
                />
              </Tabs>
            </Grid>

            <Grid item xs={12}>
              <div>
                {fpViewTabIndex === 0 && <CommonRuleSet />}
                {fpViewTabIndex === 1 && <ParameterSetting />}
                {fpViewTabIndex === 2 && <RiskClasses />}
              </div>
            </Grid>
          </Grid>
        }

        {/* FinAdvisor View */}
        {configView === "FinAdvisor" &&
            <Grid container spacing={2}>
              <Grid item xs={12} className="tabs">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  value={aaViewTabIndex}
                  onChange={handleAaViewChange}
                >
                  <Tab
                    disableRipple={true}
                    label="Licensing"
                  />
                  <Tab
                    disableRipple={true}
                    label="FinPass Master Lists"
                  />
                  <Tab
                    disableRipple={true}
                    label="Risk Assessment Master Lists"
                  />
                </Tabs>
              </Grid>

              <Grid item xs={12}>
                <div>
                  {aaViewTabIndex === 0 && <Licensing />}
                  {aaViewTabIndex === 1 && <MasterLists />}
                  {aaViewTabIndex === 2 && <RiskAssessmentMasterListView />}
                </div>
              </Grid>
            </Grid>
        }

        </div>
      </main>
    </>
  );
}

export default AdminConfigurationView;
