import React from 'react';
import { Button } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface SendToMobileQueueErrorHandlerProps {
  isOpen: boolean;
  handleModalCancel: () => void;
  requirementList: any[];
}

export function SendToMobileQueueErrorModal(props: SendToMobileQueueErrorHandlerProps) {
  const { isOpen, handleModalCancel, requirementList} = props;
  return (
      <Dialog
          scroll="paper"
          className="modal user-modal requirements-modal"
          open={isOpen}
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
      >
        <DialogTitle className="spa-modal-title">
          Missing FinMobile Requirements
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="row" style={{ padding: "1.5rem 0 2rem 0" }}>
            <Typography className="pt-2" style={{ paddingTop: "0", fontSize: "16px", paddingBottom: "20px", fontFamily: "Inter", lineHeight: "25px", fontWeight: "400", color: "#808080" }}>
              The following items need to be addressed in order to send this Instance of Care to the mobile queue:
            </Typography>
            {
              requirementList.map((item: string)=> {
                return (
                    <Grid container direction="row" wrap="nowrap" style={{ fontFamily: 'Inter', fontWeight: "400", fontSize: "16px", padding: "8px 0", alignItems: "center" }}>
                      <FiberManualRecordIcon style={{ color: "#df1e44", border: "1px solid red", borderRadius: "50%", paddingTop: "0px", marginRight: "8px", fontSize: "12px" }} className="pt-3" fontSize="small"></FiberManualRecordIcon>
                      <span>{item}</span>
                    </Grid>
                )
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions style={{ borderTop: "", marginTop: "0", padding: "2rem" }}>
          <Button onClick={handleModalCancel}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  )
}

