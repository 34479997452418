import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import StatusCard from "../../../shared/components/cards/status-card";
import { RootState } from "../../../shared/state/root-reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClientStatusCardViewModel } from "../../../shared/model/client-status-card";
import { clearCurrentClient, setRecentlyViewedClients } from "../../state/clients/implementation-clients-slice";
import Cookies from 'js-cookie'
import { clearFacilities } from "../../state/facility/implementation-facility-slice";
import { RecentlyViewedUser, UsersCookiesList } from "../../../shared/model/recentlyViewed";
import { setRecentlyViewedClient } from "../../state/clients/implementation-clients-thunk";
import { Utils } from "../../../shared/utils";
import { AppDispatch } from "../../../shared/state/store";
interface ClientFilterResultsProps {
  clients: {[filterByType: string]: ClientStatusCardViewModel[]}
}

function ClientFilterResults(props: ClientFilterResultsProps) {
  const { clients } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const filterFormValues = useSelector((state: RootState) => {
    return state.implementationContext.implementationSpecialistClient.filterFormValues;
  })

  const userName: string = useSelector((state: RootState) => {
    return state.userContext.userProfile.userName
  })

  const handleOnClick = (clientId: number) => {
    dispatch(setRecentlyViewedClient(clientId))
    navigate(`/clients/${clientId}`);
  }

  const getFilterResults = (filterBy: string) => (
    <Grid container spacing={2} className="mb-8">
      {clients[filterBy].map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.clientId}>
          <StatusCard
            onClick={() => handleOnClick(item.clientId)}
            statusText={item.clientId}
            statusColor={Utils.getStatusColor(item)}
            title={item.clientName}
            subtitle={item.workflowSubStatus.subStatusName}
            testId={`${item.clientName}-client-card`}
          />
        </Grid>
      ))}
    </Grid>
  );

  useEffect(() => {
    dispatch(clearCurrentClient());
    dispatch(clearFacilities())
  })

  useEffect(() => {
    const recentlyViewedListFromCookie: UsersCookiesList = Cookies.getJSON('recentlyViewed')
    if(recentlyViewedListFromCookie) {
      const {usersCookiesList} = recentlyViewedListFromCookie
      const index = (usersCookiesList?.length > 0) && usersCookiesList.findIndex((recentlyViewed:RecentlyViewedUser) => (
        recentlyViewed.userName === userName
        ))

        if (typeof index === "number" && index >= 0) {
          dispatch(setRecentlyViewedClients(usersCookiesList[index].clients))
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <div className="client-filter-results">

    {((filterFormValues.recentlyViewed || filterFormValues.all))  && (
        <>
        {clients.recentViewedClients.length>0 &&
          <Typography variant="h1" className="mb-4">
            Recently Viewed
          </Typography>
        }
          {getFilterResults("recentViewedClients")}
        </>
      )}

      {(filterFormValues.active || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Active
          </Typography>
          {getFilterResults("filteredByIsActive")}
        </>
      )}

      {(filterFormValues.closed || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Closed
          </Typography>
          {getFilterResults("filteredByClosed")}
        </>
      )}

      {(filterFormValues.stripeActionNeeded || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Stripe Action Needed
          </Typography>
          {getFilterResults("filteredByStripeActionNeeded")}
        </>
      )}

      {(filterFormValues.readyForActivation || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Ready for Activation
          </Typography>
          {getFilterResults("filteredByReadyForActivation")}
        </>
      )}

      {(filterFormValues.setUpIncomplete || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Set Up Incomplete
          </Typography>
          {getFilterResults("filteredBySetUpIncomplete")}
        </>
      )}

      {(filterFormValues.stripeSetupIncomplete || filterFormValues.all) && (
        <>
          <Typography variant="h1" className="mb-4">
            Stripe Alerts
          </Typography>
          {getFilterResults("filteredByStripeSetupIncomplete")}
        </>
      )}
    </div>
  );
}

export default ClientFilterResults;
