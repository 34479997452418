import {
    Button,
} from '@finpay-development/shared-components';
import {
    Estimate,
    CrossoverSelectionEnum,
    FacilityLevelOfCare,
    FacilityLevelOfCareWithCrossoverDays,
} from '@finpay/estimation-types';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {formatNumberToUSD} from 'src/shared/calculators';
import {TableColumn} from 'src/shared/model/table-column';

interface CrossoverSummaryModalProps {
    estimate: Estimate;
    open: boolean;
    handleCancel: () => void;
}

const CrossoverSummaryModal = (props: CrossoverSummaryModalProps) => {
    const {estimate, open, handleCancel} = props;

    const isBeforeCrossover =
        estimate.crossoverSelection! === CrossoverSelectionEnum.BEFORE;

    const summary = estimate.nonAdjustedSummary;

    const locTableHeaders: TableColumn[] = [
        {id: 'colLocName', label: 'Levels of Care', align: 'left'},
        {
            id: 'colLocDays',
            label: 'Length of Stay Sessions/Days',
            align: 'left',
        },
        {id: 'colLocChgs', label: 'Adjusted Charges', align: 'right'},
    ];

    const tableRows = JSON.parse(JSON.stringify(estimate.facilityLevelOfCare))
        ?.sort(
            (a: FacilityLevelOfCare, b: FacilityLevelOfCare) =>
                a.sortOrder - b.sortOrder
        )
        .filter((loc: FacilityLevelOfCare) => {
            if (loc.locType === 1) return false;
            return true;
        })
        .map((row: FacilityLevelOfCareWithCrossoverDays, index: number) => {
            const days = isBeforeCrossover
                ? row.losDaysBeforeCrossover
                : row.losDaysAfterCrossover + row.losDaysBeforeCrossover;

            return (
                <TableRow key={index} className="py-3">
                    <TableCell>
                        {row?.facilityLevelOfCareCode}-
                        {row?.facilityLevelOfCareName}
                    </TableCell>
                    <TableCell test-id={`crossover-summary-modal-${row?.facilityLevelOfCareCode}-days`}>{days}</TableCell>
                    <TableCell align="right" test-id={`crossover-summary-modal-${row?.facilityLevelOfCareCode}-value`}>
                        {formatNumberToUSD(days * row?.pdrRate)}
                    </TableCell>
                </TableRow>
            );
        });

    const subTitle = isBeforeCrossover ? 'Before Crossover' : 'With Crossover';

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
            scroll="body"
        >
            <DialogTitle>
                Patient Financial Responsibility Summary
                <Typography variant="h4">{subTitle}</Typography>
            </DialogTitle>

            <IconButton
                aria-label="close"
                onClick={handleCancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent>
                <Typography variant="subtitle2">Care Plan Details</Typography>

                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableHead>
                            <TableRow>
                                {locTableHeaders.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableRows}
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Adjusted Charges
                                </TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-loc-adj-charges">
                                    {formatNumberToUSD(summary?.coveredCharges)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3 light-green-row">
                                <TableCell colSpan={2}>
                                    Estimated Length of Stay
                                </TableCell>
                                <TableCell align="right">
                                    {summary?.lengthOfStay}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="subtitle2" className="mt-2">
                    PFR Details
                </Typography>
                <TableContainer component={Paper}>
                    <Table size="small" className="alternating-row">
                        <TableBody>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Pay</TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-copay">
                                    {formatNumberToUSD(
                                        summary?.patientTotalCoPays
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Deductible</TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-deductible">
                                    {formatNumberToUSD(summary?.deductibleSpent)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Estimated Co-Insurance</TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-coinsurance">
                                    {formatNumberToUSD(
                                        summary?.patientTotalCoInsurance
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell></TableCell>
                                <TableCell
                                    align="right"
                                    test-id="crossover-summary-modal-covered-svcs-pfr"
                                    sx={{borderTop: '2px solid #999'}}
                                >
                                    <strong>
                                        {formatNumberToUSD(
                                            summary?.coveredSvcsPfr
                                        )}
                                    </strong>
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>
                                    Out of Pocket Max Remaining
                                </TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-oopm-remaining">
                                    {summary?.includeOOPmax ? (
                                        formatNumberToUSD(
                                            summary?.remainingOopMaxFromVob
                                        )
                                    ) : (
                                        <>N/A</>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>PFR for Covered Services</TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-pfr-covered-services">
                                    {formatNumberToUSD(summary?.netPFR)}
                                </TableCell>
                            </TableRow>
                            <TableRow className="py-3">
                                <TableCell>Uncovered Services</TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-uncovered-services">
                                    {formatNumberToUSD(
                                        summary?.unCoveredCharges
                                    )}
                                </TableCell>
                            </TableRow>
                            {summary?.totalSurcharges! > 0 && (
                                <>
                                    <TableRow className="py-3">
                                        <TableCell>PFR Subtotal</TableCell>
                                        <TableCell align="right" test-id="crossover-summary-modal-pfr-subtotal">
                                            {formatNumberToUSD(
                                                summary?.subtotalPFR
                                            )}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="py-3">
                                        <TableCell>
                                            Admission Assessment
                                        </TableCell>
                                        <TableCell align="right" test-id="crossover-summary-modal-admission-assessment">
                                            {formatNumberToUSD(
                                                summary?.totalSurcharges
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </>
                            )}
                            <TableRow className="py-3">
                                <TableCell>
                                    <Typography variant="subtitle2">
                                        Estimated Patient Financial
                                        Responsibility
                                    </Typography>
                                </TableCell>
                                <TableCell align="right" test-id="crossover-summary-modal-total-pfr">
                                    <Typography variant="subtitle2">
                                        {formatNumberToUSD(summary?.totalPFR)}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button test-id="crossover-summary-close-button" onClick={() => handleCancel()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CrossoverSummaryModal;
