import {styled} from '@mui/material/styles';
import {Box, Chip, Paper, Grid, IconButton} from '@mui/material';
import {formatNumberToUSD} from '../../../shared/calculators';
import InfoIcon from '@mui/icons-material/Info';
import {
    FacilityRiskAssessment,
    AdmitStatus,
    RiskStatus,
} from '@finpay/estimation-types';

interface RiskMidSectionProps {
    riskAssessment?: FacilityRiskAssessment;
    riskAssessmentHre?: FacilityRiskAssessment;
    deficiencySettingEiv: boolean;
    hasEivdeficiencies: boolean;
    hasNprdeficiencies: boolean;
    isClientConfiguredForRiskScoring: boolean;
}

type GridItemProps = {
    txtAlign: string;
    txtColor?: string;
    underline?: boolean;
    background?: boolean;
};

const NewRiskAssessmentMidSection = (props: RiskMidSectionProps) => {
    const {
        riskAssessment,
        riskAssessmentHre,
        deficiencySettingEiv,
        hasEivdeficiencies,
        hasNprdeficiencies,
        isClientConfiguredForRiskScoring,
    } = props;

    const displayAsPct = (value: number): string => {
        let returnPct = '0%';
        if (value) {
            returnPct = value.toFixed(0) + '%';
        }
        return returnPct;
    };

    const renderAdmissionRiskScoring = (riskAssessment: AdmitStatus) => {
        switch (riskAssessment) {
            case AdmitStatus.FAST:
                return (
                    <Chip
                        label={AdmitStatus.FAST}
                        sx={{backgroundColor: '#d0e7e1', color: '#5e8e6b'}}
                    />
                );

            case AdmitStatus.ADMIT:
                return (
                    <Chip
                        label={AdmitStatus.ADMIT}
                        sx={{backgroundColor: '#d0e7e1', color: '#5e8e6b'}}
                    />
                );

            case AdmitStatus.PENDING:
                return (
                    <Chip
                        label={AdmitStatus.PENDING}
                        sx={{backgroundColor: '#f1e6c9', color: '#9c7427'}}
                    />
                );

            case AdmitStatus.NOADMIT:
                return (
                    <Chip
                        label={AdmitStatus.NOADMIT}
                        sx={{backgroundColor: '#e6cbd4', color: '#D13D3F'}}
                    />
                );

            default:
                return (
                    <Chip
                        label={AdmitStatus.NOADMIT}
                        sx={{backgroundColor: '#e6cbd4', color: '#D13D3F'}}
                    />
                );
        }
    };

    // renders High/Medium/Low Risk
    const riskStatusChip = (statusCode: RiskStatus) => {
        switch (statusCode) {
            case RiskStatus.HIGH:
                return (
                    <Chip
                        label="High Risk"
                        sx={{backgroundColor: '#e6cbd4', color: '#D13D3F'}}
                    />
                );
            case RiskStatus.MEDIUM:
                return (
                    <Chip
                        label="Medium Risk"
                        sx={{backgroundColor: '#f1e6c9', color: '#9c7427'}}
                    />
                );
            case RiskStatus.LOW:
                return (
                    <Chip
                        label="Low Risk"
                        sx={{backgroundColor: '#d0e7e1', color: '#5e8e6b'}}
                    />
                );
            default:
                return <Chip label="N/A" color="secondary" />;
        }
    };

    const GridItem = styled(Box)<GridItemProps>(
        ({theme, txtAlign, txtColor, underline, background}) => ({
            ...theme.typography.body2,
            padding: theme.spacing(1),
            height: '2.5em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: txtAlign === 'center' ? 'center' : 'start',
            borderBottom: underline ? '1px solid #cccccc' : 'none',
            color:
                txtColor === '' || txtColor === undefined
                    ? theme.palette.text.secondary
                    : txtColor,
            backgroundColor: background ? '#f5f7f9' : '',
        })
    );

    const firstColumnWidth = 8;
    const facilityColumnWidth = 2;
    const admitStatusFullWidth = 4;

    // client needs to be configured to see admit status
    const selectedFacilityAdmitStatus =
        isClientConfiguredForRiskScoring &&
        renderAdmissionRiskScoring(riskAssessment?.admitStatus!);

    const selectedHREFacilityAdmitStatus =
        isClientConfiguredForRiskScoring &&
        renderAdmissionRiskScoring(riskAssessmentHre?.admitStatus!);

    return (
        <>
            {riskAssessment && riskAssessmentHre && (
                <Paper className="mt-5">
                    <Box className="p-4">
                        {/* top level container (parent) */}
                        <Grid container>
                            {/* header and titles row */}
                            <Grid item xs={firstColumnWidth} />

                            <Grid
                                item
                                xs={facilityColumnWidth}
                                textAlign="center"
                            >
                                <h4 className="my-0">
                                    {riskAssessment.facilityName}
                                </h4>
                                <small>
                                    <span style={{color: '#009900'}}>
                                        Cleared
                                    </span>
                                </small>
                            </Grid>

                            <Grid
                                item
                                xs={facilityColumnWidth}
                                textAlign="center"
                            >
                                <h4 className="my-0">
                                    {riskAssessmentHre.facilityName}
                                </h4>
                                <small>
                                    <span style={{color: 'red'}}>
                                        High Risk Exception
                                    </span>
                                </small>
                            </Grid>

                            {/* admit status row */}
                            {isClientConfiguredForRiskScoring && (
                                <>
                                    <Grid xs={firstColumnWidth} />
                                    <Grid
                                        test-id="admit-status"
                                        item
                                        xs={admitStatusFullWidth}
                                    >
                                        <GridItem txtAlign="center">
                                            {selectedHREFacilityAdmitStatus}
                                        </GridItem>
                                    </Grid>
                                </>
                            )}

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="left">
                                    Patient Days to Reimbursement
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="days-to-reimbursement-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {Math.ceil(
                                        riskAssessment.daysToReimbursement.value
                                    )}
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="days-to-reimbursement-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {Math.ceil(
                                        riskAssessmentHre.daysToReimbursement
                                            .value
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" underline background>
                                    <div>
                                        <strong>AMA Risk</strong>
                                        <IconButton
                                            size="small"
                                            title="The risk of the patient leaving AMA before insurance reimbursement kicks in."
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="ama-risk-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessment.daysToReimbursement.risk
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="ama-risk-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessmentHre.daysToReimbursement
                                            .risk
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    PFR As a Percentage of Adjusted Charges
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="pfr-percent-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {displayAsPct(
                                        riskAssessment.pfrRiskSettings.value
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="pfr-percent-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {displayAsPct(
                                        riskAssessmentHre.pfrRiskSettings.value
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" underline background>
                                    <div>
                                        <strong>PFR Concentration Risk</strong>
                                        <IconButton
                                            size="small"
                                            title="The risk associated with the percentage of the total that is due by the patient versus the insurance payer."
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="pfr-concentration-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessment.pfrRiskSettings.risk
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="pfr-concentration-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessmentHre.pfrRiskSettings.risk
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    Estimated Adjusted Charges
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="adjusted-charges-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(
                                        riskAssessment.estAdjChgs
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="adjusted-charges-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(
                                        riskAssessmentHre.estAdjChgs
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" background>
                                    Estimated Insurance Value ("EIV")
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="eiv-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessment.estInsVal
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="eiv-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessmentHre.estInsVal
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            {deficiencySettingEiv && hasEivdeficiencies && (
                                <>
                                    <Grid item xs={firstColumnWidth}>
                                        <GridItem txtAlign="start">
                                            EIV Deficiency
                                        </GridItem>
                                    </Grid>
                                    <Grid
                                        test-id="eiv-deficiency-selected"
                                        item
                                        xs={facilityColumnWidth}
                                    >
                                        <GridItem
                                            txtAlign="center"
                                            txtColor={
                                                riskAssessment.eivDeficiency > 0
                                                    ? 'red'
                                                    : ''
                                            }
                                            underline={false}
                                        >
                                            {formatNumberToUSD(
                                                Math.round(
                                                    riskAssessment.eivDeficiency
                                                )
                                            )}
                                        </GridItem>
                                    </Grid>

                                    <Grid item xs={facilityColumnWidth}>
                                        <GridItem
                                            test-id="eiv-deficiency-selected-hre"
                                            txtAlign="center"
                                            txtColor={
                                                riskAssessmentHre.eivDeficiency >
                                                0
                                                    ? 'red'
                                                    : ''
                                            }
                                        >
                                            {formatNumberToUSD(
                                                Math.round(
                                                    riskAssessmentHre.eivDeficiency
                                                )
                                            )}
                                        </GridItem>
                                    </Grid>
                                </>
                            )}

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    Patient Financial Responsibility
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="pfr-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(riskAssessment.pfr)}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="pfr-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(riskAssessmentHre.pfr)}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" background>
                                    Net Patient Revenue Estimate (Paid EIV +
                                    Paid PFR)
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="net-patient-revenue-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        Math.round(
                                            riskAssessment.netPatientRevenueEst
                                        )
                                    )}
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="net-patient-revenue-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        Math.round(
                                            riskAssessmentHre.netPatientRevenueEst
                                        )
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            {!deficiencySettingEiv && hasNprdeficiencies && (
                                <>
                                    <Grid item xs={firstColumnWidth}>
                                        <GridItem txtAlign="start">
                                            Net Patient Revenue Deficiency
                                        </GridItem>
                                    </Grid>
                                    <Grid
                                        test-id="net-patient-revenue-deficiency-selected"
                                        item
                                        xs={facilityColumnWidth}
                                    >
                                        <GridItem
                                            txtAlign="center"
                                            txtColor={
                                                riskAssessment.netPatientRevenueDeficiency >
                                                0
                                                    ? 'red'
                                                    : ''
                                            }
                                        >
                                            {formatNumberToUSD(
                                                Math.round(
                                                    riskAssessment.netPatientRevenueDeficiency
                                                )
                                            )}
                                        </GridItem>
                                    </Grid>

                                    <Grid
                                        test-id="net-patient-revenue-deficiency-selected-hre"
                                        item
                                        xs={facilityColumnWidth}
                                    >
                                        <GridItem
                                            txtAlign="center"
                                            txtColor={
                                                riskAssessmentHre.netPatientRevenueDeficiency >
                                                0
                                                    ? 'red'
                                                    : ''
                                            }
                                        >
                                            {formatNumberToUSD(
                                                Math.round(
                                                    riskAssessmentHre.netPatientRevenueDeficiency
                                                )
                                            )}
                                        </GridItem>
                                    </Grid>
                                </>
                            )}

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    Patient Episode Margin
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="patient-episode-margin-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {displayAsPct(
                                        riskAssessment.patientEpisodeMargin
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="patient-episode-margin-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {displayAsPct(
                                        riskAssessmentHre.patientEpisodeMargin
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" underline background>
                                    <div>
                                        <strong>Profitability Risk</strong>
                                        <IconButton
                                            size="small"
                                            title="The financial risk associated with the admission based on operating and census costs."
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="profitability-risk-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessment.profitabilityRisk.risk
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="profitability-risk-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem
                                    txtAlign="center"
                                    underline
                                    background
                                >
                                    {riskStatusChip(
                                        riskAssessmentHre.profitabilityRisk.risk
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    Variable Cost Coverage %
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="variable-cost-percent-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {Math.round(
                                        riskAssessment.variableCostCoveragePercent
                                    ) + '%'}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="variable-cost-percent-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {Math.round(
                                        riskAssessmentHre.variableCostCoveragePercent
                                    ) + '%'}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" background>
                                    Variable Cost Coverage Amount
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="variable-cost-amount-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessment.totalVariableCost
                                    )}
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="variable-cost-amount-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessmentHre.totalVariableCost
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start">
                                    Minimum Down Payment Required
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="minimum-down-payment-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(
                                        riskAssessment.minimumDownPayment as number
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="minimum-down-payment-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center">
                                    {formatNumberToUSD(
                                        riskAssessmentHre.minimumDownPayment as number
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" background>
                                    Revenue Estimate from Referral Source Data
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="revenue-estimate-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessment.referralSourceRisk.value
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="revenue-estimate-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" background>
                                    {formatNumberToUSD(
                                        riskAssessmentHre.referralSourceRisk
                                            .value
                                    )}
                                </GridItem>
                            </Grid>

                            {/* row */}
                            <Grid item xs={firstColumnWidth}>
                                <GridItem txtAlign="start" underline>
                                    <div>
                                        <strong>Referral Source Risk</strong>
                                        <IconButton
                                            size="small"
                                            title="The risk associated with the referral source of the patient in terms of historical reimbursement numbers."
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                </GridItem>
                            </Grid>
                            <Grid
                                test-id="referral-source-risk-selected"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" underline>
                                    {riskStatusChip(
                                        riskAssessment.referralSourceRisk.risk
                                    )}
                                </GridItem>
                            </Grid>

                            <Grid
                                test-id="referral-source-risk-selected-hre"
                                item
                                xs={facilityColumnWidth}
                            >
                                <GridItem txtAlign="center" underline>
                                    {riskStatusChip(
                                        riskAssessmentHre.referralSourceRisk
                                            .risk
                                    )}
                                </GridItem>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            )}
        </>
    );
};

export default NewRiskAssessmentMidSection;
