import React from 'react';
import { Navigate } from 'react-router-dom';
interface GuardedComponentProps {
  exact: boolean;
  path: string;
  component: any;
  isAuthenticated: boolean;
  hasAccess: boolean;
}

interface CompToRenderProps {
  Component: any;
  isAuthenticated: boolean;
  hasAccess: boolean;
}

const compToRender = (props: CompToRenderProps) => {

  const { isAuthenticated, hasAccess, Component } = props
    if (!isAuthenticated && !hasAccess) {
      return "";
    } else if (hasAccess) {
      return <Component {...props} />
    } else {
      return <Navigate to='/accessdenied' />
    }
  }

const GuardedComponent = ({ component: Component, isAuthenticated, hasAccess, ...rest }: GuardedComponentProps) => {
  return (
    <div>
      {compToRender({Component, isAuthenticated, hasAccess})}
    </div>
  )
};

export default GuardedComponent;
