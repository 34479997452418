import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { CustomFormikProps } from '../model/formik-props';
import { TimingRiskMetaData } from "../../meta-data/models/metaData";
import CheckboxGroup from "../components/checkbox-group";
import {Toggle} from '@finpay-development/shared-components';
import React from 'react';

interface TimingRiskProps {
    formik: CustomFormikProps;
    timingRiskData: TimingRiskMetaData[];
}

function TimingRiskSelect(props: TimingRiskProps) {
    const { formik, timingRiskData } = props;

  return (
    <Grid item xs={12}>
        <Typography className="mb-4 mt-4" variant="h3">
            Timing Risk
        </Typography>
        <Typography className="mb-2" variant="body2">
          Select All Timing Risks
        </Typography>
        <Toggle
            name="accessAllTimingRisks"
            value={formik.values.accessAllTimingRisks}
            formik={formik}
            onChange={async()=>{
              await formik.setFieldValue("timingRisk", []);
              await formik.validateForm();
            }}
        />
        <div>
        {(timingRiskData && timingRiskData.length > 0 && !formik.values.accessAllTimingRisks) && (
            <CheckboxGroup
                name="timingRisk"
                idField="timingRiskId"
                labelField="timingRiskName"
                data={timingRiskData}
                direction="row"
                formik={formik}
            />
        )}
        </div>
    </Grid>
  );
}

export default TimingRiskSelect;