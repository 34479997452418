import { saveCallbackStatus } from '@finpay-development/shared-components';

export interface SavingStatus {
    modalSaveStatus: saveCallbackStatus;
    modelErrorMessage: string;
}

export const GENERIC_SERVER_ERROR: string = "Error return from the server. Please contact support.";
export const UNKNOWN_SERVER_ERROR: string = "Unknown error has occurred.";

