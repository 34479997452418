import '../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, saveCallbackStatus } from '@finpay-development/shared-components';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getClientFacility } from '../../../../implementation-specialist/state/facility/implementation-facility-thunk';
import { showErrorStatus } from '../../../../security/state/user-slice';
import { calculateDownByPercent } from '../../../../shared/calculators';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { clearStatus } from '../../../state/patient-slice';
import { deletePatientPaymentProgram, getEvaluateRule, savePatientPaymentProgram } from '../../../state/patient-thunk';
import { emptyPatientEncounter } from '../../models/patient-encounter';
import { PatientPaymentProgram } from '../../models/patient-payment-program';
import { PaymentProgramTabs } from '../../models/payment-program-tabs';
import { CustomPFRView } from './custom-view';
import FullPay from './full-pay';
import { PreauthorizedView } from './preauthorized-view';

export interface deletePatientPaymentProgramModel {
  patientId: number;
  encounterId: number;
  paymentProgramId: number;
}
interface PaymentProgramsModalProps {
  open: boolean;
  handleModalClose: () => void;
  isEdit: boolean;
}

export function PaymentProgramsModal(props: PaymentProgramsModalProps) {
  const { open, handleModalClose, isEdit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [originalTabIndex, setOriginalTabIndex] = useState(0);
  const [preauthorizedSaveClicked, setPreauthorizedSaveClicked] = useState(false);
  const [fullPaySaveClicked, setFullPaySaveClicked] = useState(false);
  const [customSaveClicked, setCustomSaveClicked] = useState(false);
  const [isPreauthDisabled, setIsPreauthDisabled] = useState(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  }

  const stateFields = {
    errorMessage: useSelector(
      (state: RootState) =>
        state.patientContext.errorMessage
    ),
    selectedEncounter: useSelector(
      (state: RootState) =>
        state.patientContext.selectedEncounter
    ),
    selectedPatient: useSelector(
      (state: RootState) =>
        state.patientContext.selectedPatient
    ),
    selectedRule: useSelector((state: RootState) => {
      return state.patientContext.matchingRule
    }),
    isLoadingRule: useSelector((state: RootState) => {
      return state.patientContext.isLoading.evaluateRule
    }),
    patientPaymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    isSaving: useSelector((state: RootState) => {
      return state?.patientContext?.isLoading?.paymentProgram
    }),
    isFullPayDisabled: !(useSelector((state: RootState) => state?.implementationContext?.implementationFacility?.apiResponsefacility?.patientPmtFreq?.includes('F'))),
    paymentProgramUpdateRequired: useSelector((state: RootState) => state?.patientContext?.isError?.paymentProgramNeedsUpdate),
  }

  const {
    errorMessage,
    selectedEncounter,
    selectedPatient,
    selectedRule,
    isLoadingRule,
    patientPaymentProgram,
    isSaving,
    isFullPayDisabled,
    paymentProgramUpdateRequired,
  } = stateFields;

  const dispatch = useDispatch<AppDispatch>();
  const doesSelectedPatientEncounterExist = selectedEncounter !== emptyPatientEncounter;

  useEffect(() => {
    dispatch(
      getClientFacility({
        clientId: selectedEncounter.clientId,
        facilityId: selectedEncounter.facilityId,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToTabOnLoad = useCallback(() => {

    const selectedTerm = patientPaymentProgram?.patientPaymentSchedule?.terms;
    const availableTerms = selectedRule.availableTerms?.replace(" ", "").split(",").map((termAsString) => +termAsString);

    let minDownPmt;
    let optDownPmt;

    if (selectedRule?.minDownPmtType === '%') {
      minDownPmt = calculateDownByPercent(selectedRule?.minDownPmt, patientPaymentProgram?.patientPaymentSchedule?.pfrAmt)/100;
    } else {
      minDownPmt = selectedRule?.minDownPmt
    }

    // FPS-2453: this handles an edge case. if the minDownPmt is > the pfrAmt, it will break all the recurring pmt calculations.
    // To fix this, we need to disable the preauth tab in this case.
    if (minDownPmt >= selectedEncounter.pfrAmt) {
      setIsPreauthDisabled(true);
      setTabIndex(PaymentProgramTabs.Custom);
      setOriginalTabIndex(PaymentProgramTabs.Custom);
      return;
    } else {
      setIsPreauthDisabled(false);
    }

    if (selectedEncounter?.patientPaymentProgram?.length === 0 || !selectedTerm) return;

    if (selectedRule?.optimalDownPmtType === '%') {
      optDownPmt = calculateDownByPercent(selectedRule?.optimalDownPmt, patientPaymentProgram?.patientPaymentSchedule?.pfrAmt)/100;
    } else {
      optDownPmt = selectedRule?.optimalDownPmt;
    }

    const termIsOneOfAvailable = availableTerms?.includes(selectedTerm);
    if (isEdit && patientPaymentProgram) {
      if (patientPaymentProgram?.patientPaymentSchedule?.paymentFreq === 'F') {
        setTabIndex(PaymentProgramTabs.FullPay);
        setOriginalTabIndex(PaymentProgramTabs.FullPay);
      }

      if (!termIsOneOfAvailable || (minDownPmt !== patientPaymentProgram?.downPmtAmt && optDownPmt !== patientPaymentProgram?.downPmtAmt)) {
        setTabIndex(PaymentProgramTabs.Custom);
        setOriginalTabIndex(PaymentProgramTabs.Custom);
      } else {
        setTabIndex(PaymentProgramTabs.PreAuth);
        setOriginalTabIndex(PaymentProgramTabs.PreAuth);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedEncounter?.patientPaymentProgram?.length, selectedRule.availableTerms, selectedRule?.minDownPmt,
    selectedRule?.minDownPmtType, selectedRule?.optimalDownPmt, selectedRule?.optimalDownPmtType, patientPaymentProgram
  ])



  async function handleSavePreauthorized(paymentProgram: PatientPaymentProgram) {
    setPreauthorizedSaveClicked(false);
    setCustomSaveClicked(false);
    await dispatch(savePatientPaymentProgram(
      {
        paymentProgram: paymentProgram,
        patientId: selectedPatient.patientId,
        encounterId: selectedEncounter.patientEncounterId
      }
    ))
    handleModalClose();
  }

  async function handleSaveCustom(paymentProgram: PatientPaymentProgram) {
    setPreauthorizedSaveClicked(false);
    setCustomSaveClicked(false);
    await dispatch(savePatientPaymentProgram(
      {
        paymentProgram: paymentProgram,
        patientId: selectedPatient.patientId,
        encounterId: selectedEncounter.patientEncounterId
      }
    ))
    handleModalClose();
  }

  async function handleSaveFullPay(paymentProgram: PatientPaymentProgram) {
    setFullPaySaveClicked(false);

    const paymentProgramToSave = {
      ...paymentProgram,
      downPmtAmt: paymentProgram.patientPaymentSchedule.pfrAmt
    }

    await dispatch(savePatientPaymentProgram(
      {
        paymentProgram: paymentProgramToSave,
        patientId: selectedPatient.patientId,
        encounterId: selectedEncounter.patientEncounterId
      }
    ))
    handleModalClose();
  }

  function handleSavePaymentPrograms() {
    if (tabIndex === 0) {
      setPreauthorizedSaveClicked(true);
      setCustomSaveClicked(false);
      setFullPaySaveClicked(false);
    } else if (tabIndex === 1) {
      setPreauthorizedSaveClicked(false);
      setCustomSaveClicked(true);
      setFullPaySaveClicked(false);
    } else {
      setPreauthorizedSaveClicked(false);
      setCustomSaveClicked(false);
      setFullPaySaveClicked(true);
    }
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      setEnableSaveButton(false);

      handleModalClose();
      setPreauthorizedSaveClicked(false);
      setCustomSaveClicked(false);
      setFullPaySaveClicked(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleDelete() {
    if (selectedEncounter.patientPaymentProgram !== null && selectedEncounter.patientPaymentProgram.length > 0) {
      dispatch(deletePatientPaymentProgram({
        patientId: selectedPatient.patientId,
        encounterId: selectedEncounter.patientEncounterId,
        paymentProgramId: selectedEncounter.patientPaymentProgram[0].patientPaymentProgramId
      } as deletePatientPaymentProgramModel))
    }
  }


  const enableSaveButtonCallback = (enableSave: boolean) => {
    setEnableSaveButton(enableSave)
  }


  useEffect(() => {
    if (doesSelectedPatientEncounterExist && open) {
      dispatch(getEvaluateRule(selectedEncounter));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (selectedRule?.ruleId) {
      goToTabOnLoad();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRule?.ruleId])

  const tabs = (
    <Grid item xs={12} className="tabs">
      <Tabs
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        value={tabIndex}
        onChange={handleTabChange}
      >
        <Tab
          className="py-3"
          disableRipple
          disabled={isPreauthDisabled}
          label="Preauthorized"
        />
        <Tab
          disableRipple
          label="Custom"
        />
        <Tab
          className="py-3"
          disableRipple
          disabled={isFullPayDisabled}
          label="Full Pay"
        />
      </Tabs>
    </Grid>
  )

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      test-id="payment-program-start-modal"
    >
      <DialogTitle>
        <span className="title">Patient Financial Responsibility</span>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {tabs}
          {tabIndex === PaymentProgramTabs.PreAuth && (
            <Box style={{minWidth: '100%'}}>
              <PreauthorizedView
                enableSaveButtonCallback={enableSaveButtonCallback}
                handleSavePreauthorized={handleSavePreauthorized}
                preauthorizedSaveClicked={preauthorizedSaveClicked}
                isLoading={isLoadingRule}
                isEdit={isEdit}
              />
            </Box>
          )}
          {tabIndex === PaymentProgramTabs.Custom && (
            <CustomPFRView
              enableSaveButtonCallback={enableSaveButtonCallback}
              handleSaveCustom={handleSaveCustom}
              customSaveClicked={customSaveClicked}
              isRuleLoading={isLoadingRule}
              originalTabIndex={originalTabIndex}
              isEdit={isEdit}
            />
          )}
          {tabIndex === PaymentProgramTabs.FullPay && (
            <Box style={{minWidth: '100%'}}>
              <FullPay
                enableSaveButtonCallback={enableSaveButtonCallback}
                isRuleLoading={isLoadingRule}
                isEdit={isEdit}
                fullPaySaveClicked={fullPaySaveClicked}
                handleSaveFullPay={handleSaveFullPay}
              />
            </Box>
          )}
          {paymentProgramUpdateRequired && (
            <Grid item xs={12}>
              <Box style={{border: '2px solid red', borderRadius: '5px'}}>
                <Typography variant="h4" color="error" className="py-2 px-1">
                  You must update the payment program. The current payment program has an outdated PFR amount.
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton && !isSaving}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSavePaymentPrograms}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
          showDeleteButton={false} // Always hide delete button for now. Future use.
          executeDelete={handleDelete}
          showCancelButton={!paymentProgramUpdateRequired}
        />
      </DialogActions>
    </Dialog>
  );
}
