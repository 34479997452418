import { UserInfoFacility } from "./user-info-facility";

export class UserInfoClient {
  clientId!: number;
  clientName!: string;
  allowedFacilities!: UserInfoFacility[];
  isFacilityLevel?: boolean | null;
}

export const emptyUserInfoClient: UserInfoClient = {
  clientId: 0,
  clientName: "",
  allowedFacilities: [],
  isFacilityLevel: false
};
