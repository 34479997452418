import './../../../scss/pages/admin/_admin-roles-edit.scss';

import { DialogActionButton, saveCallbackStatus, TextField } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SysRolePage } from '../../../security/model/sys-role-page';
import { SysRolePageAction } from '../../../security/model/sys-role-page-action';
import { UserRole } from '../../../security/model/user-role';
import { CustomFormikProps } from '../../../shared/model/formik-props';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { AdminNavigationState, emptyAdminNavigationState } from '../../models/admin-navigation-state';
import { RolePageActionState, RolePageState } from '../../models/role-page-state';
import { buildUserRolePages } from '../../services/build-user-role-pages';
import { mapRoleToSave } from '../../services/map-role-to-save';
import { saveUserRole } from '../../state/admin-roles-thunk';
import RoleAccessGroup from './role-access-group';

const sysRoleKeysToIgnore = ["navClientPortal", "navPatientDashboard", "navForwardDocument"];

const accessSectionVisibilityDefault = {
    navRoles: false,
    navConfiguration: false,
    navUsers: false,
    navReports: false,
    navClients: false,
    navPatientRecords: false,
    navAdmissionsAdvisor: false,
}

interface AdminRoleModalProps {
  open: boolean;
  handleEditModalCancel: () => void;
  handleEditModalSubmit: (isEditMode: boolean, isError: boolean) => void;
}

export function AdminRoleModal(props: AdminRoleModalProps) {
  const { open, handleEditModalSubmit, handleEditModalCancel } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [accessSectionVisibility, setAccessSectionVisibility] = useState<{[key: string]: boolean}>(accessSectionVisibilityDefault);

  const systemRolePages = useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.systemRoles
    );

  const currentUserRole = useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.currentUserRole
  );

  const userRoleNames = useSelector(
    (state: RootState) => {
      return [...state.adminContext.adminRoleContext.userRoles.externalUserRoles,
        ...state.adminContext.adminRoleContext.userRoles.internalUserRoles].map(role => role.roleName)
    }
  )

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

    let initialValues: AdminNavigationState = emptyAdminNavigationState;

    const userRoleState = buildUserRolePages(systemRolePages, currentUserRole.userRole);

    if(!resetValues) {
        initialValues.isEditMode = !!userRoleState.userRoleId;
        initialValues.userRoleId = userRoleState.userRoleId;
        initialValues.roleName = userRoleState.roleName;
        initialValues.roleDescription = userRoleState.roleDescription;
        initialValues.pageKeys = userRoleState.pageKeys;
        initialValues.navRoles = userRoleState.navRoles;
        initialValues.navConfiguration = userRoleState.navConfiguration;
        initialValues.navUsers = userRoleState.navUsers;
        initialValues.navLogs = userRoleState.navLogs;
        initialValues.navReports = userRoleState.navReports;
        initialValues.navClients = userRoleState.navClients;
        initialValues.navPatientRecords = userRoleState.navPatientRecords;
        initialValues.navClientPortal = userRoleState.navClientPortal;
        initialValues.navPatientDashboard = userRoleState.navPatientDashboard;
        initialValues.navAdmissionsAdvisor = userRoleState.navAdmissionsAdvisor;
    }

    useEffect(() => {
        setResetValues(false);

        setAccessSectionVisibility({
            navRoles: initialValues.navRoles.isEnabled,
            navConfiguration: initialValues.navConfiguration.isEnabled,
            navUsers: initialValues.navUsers.isEnabled,
            navReports: initialValues.navReports.isEnabled,
            navClients: initialValues.navClients.isEnabled,
            navPatientRecords: initialValues.navPatientRecords.isEnabled,
            navAdmissionsAdvisor: initialValues.navAdmissionsAdvisor.isEnabled,
        })
    }, [currentUserRole, initialValues])

  // Handle State Management in formik between different levels of Pages/Actions
  const handleRoleSelection = (formik: CustomFormikProps, fieldName: string, newState: RolePageState) => {
        setAccessSectionVisibility({
            ...accessSectionVisibility,
            [newState.sysRoleKey]: newState.isEnabled
        })

      // When a checkbox has children check to see how to handle peers and children
      if (newState.parent === "") {
          if (!newState.isEnabled) {
            // When Page is disable clear out read-only/editable state for actions.
            newState.isReadOnly = false;
            newState.isEditable = false;
            clearActionState(formik, fieldName, newState);
        } else {
            if (newState.isReadOnly) {
                clearActionState(formik, fieldName, newState);
            }
        }
    }
  }

  // Clear out Page Action State when page is not enabled or read-only
  function clearActionState(formik: CustomFormikProps, fieldName: string, newState: RolePageState) {
      newState.actions.forEach(function (actionItem: RolePageActionState) {
          const childItem = newState.actions.find(
              (pageAction: RolePageActionState) => pageAction.actionKey === actionItem.actionKey
          );

          if (childItem)
              childItem.isEnabled = false
      });

      // Sync State Changes for Action to formik
      formik.setFieldValue(fieldName, newState)
  }

  function validateFormChanges(values: any) {
    // Check if all required fields exist
    const nameLength = values.roleName.length > 0;
    const descLength = values.roleDescription.length > 0;

    let isSaveButtonEnabled: boolean = false;
    if (nameLength && descLength) {
        let enabledFlagCount: number = 0;
        const pageKeys = values.pageKeys;

        if (pageKeys) {
            pageKeys.forEach(function (actionItem: string) {
                const pageItem: RolePageState = values[actionItem]
                if (pageItem && pageItem.isEnabled) {
                    enabledFlagCount++;
                }
            });

            if (enabledFlagCount > 0 )
                isSaveButtonEnabled = true;
            if(enabledFlagCount === 0) {
                setResetValues(true);
                initialValues = emptyAdminNavigationState;
            }

        }
    }
    setEnableSaveButton(isSaveButtonEnabled);
  }

  function showAccessSection() {
      let oneOrMoreNavTypesSelected = false;
      for (let roleSection in accessSectionVisibility) {
        if (accessSectionVisibility[roleSection] === true && roleSection !== "navLogs") {
            oneOrMoreNavTypesSelected = true;
        }
      }
      return oneOrMoreNavTypesSelected;
  }

  async function handleSave() {
    // Save User Role
    const formik = formRef.current;
    const roleToSave: UserRole = mapRoleToSave(formik, currentUserRole.userRole, systemRolePages)

    const doesUserRoleNameAlreadyExist = (userRoleName: string) => (
       userRoleName.toLowerCase() === roleToSave.roleName.toLowerCase()
    );
    const isError = userRoleNames.some(doesUserRoleNameAlreadyExist) && !initialValues.isEditMode

    // if the role name isn't already taken, we can save the role
    !isError && (await dispatch(saveUserRole(roleToSave)));

    // Close Dialog
    handleEditModalSubmit(initialValues.isEditMode, isError);
  }

  function handleClose(){
      setResetValues(true)
  }

  return (
      <Dialog
        className="modal role-modal"
        open={open}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        onClose = {handleClose}
      >
        <DialogTitle>
            {initialValues.isEditMode && <span>Edit Role</span>}
            {!initialValues.isEditMode && <span>Add Role</span>}
          </DialogTitle>
        <DialogContent>

        <main className="admin-roles-edit">
              <div className="content">
              <Formik
                  initialValues={initialValues}
                  validate={validateFormChanges}
                  innerRef={formRef}
                  onSubmit={() => {initialValues = emptyAdminNavigationState }}
              >
              {(formik) => (
                  <Form>
                      <Grid container spacing={2} direction="column" className="role-card">
                          <Grid item>
                              <TextField
                                      disabled={formik.values.isEditMode}
                                      error={
                                          formik.touched["roleName"] && formik.errors["roleName"]
                                          ? formik.errors["roleName"]
                                          : ""
                                      }
                                      label="Role Name"
                                      name="roleName"
                                      value={formik.values.roleName}
                                      maxLength={60}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      placeholder="Enter Role Name"
                              />
                          </Grid>
                          <Grid item>
                              <TextField
                              error={
                                  formik.touched["roleDescription"] && formik.errors["roleDescription"]
                                  ? formik.errors["roleDescription"]
                                  : ""
                              }
                              maxLength={256}
                              label="Role Description"
                              name="roleDescription"
                              helperText="Enter a short description of this role and its responsibilities"
                              value={formik.values.roleDescription}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Enter Role Description"
                              />
                          </Grid>

                          <Grid container item direction="column">
                              <Grid container item>
                                  <Grid item>
                                      <Typography variant="body1">Navigation</Typography>
                                  </Grid>
                              </Grid>
                              <Grid container item direction="column">
                                  <Grid container item className="card-header">
                                      <Grid item xs={8}><div>Type</div></Grid>
                                      <Grid container item xs={4} justifyContent="center" alignContent="center">
                                          <Grid container item xs={6} justifyContent="center" alignContent="center">
                                          </Grid>
                                          <Grid container item xs={6} justifyContent="center" alignContent="center">
                                              Enable
                                          </Grid>
                                      </Grid>
                                  </Grid>
                                  {systemRolePages.map((role: SysRolePage) => (
                                      <RoleAccessGroup
                                          key={role.sysRolePageId}
                                          type="Enable-Only"
                                          hidden={sysRoleKeysToIgnore.includes(role.sysRoleKey)}
                                          formik={formik}
                                          itemName={role.rolePageName}
                                          itemKey={role.sysRoleKey}
                                          handleRoleSelection={handleRoleSelection}
                                      />
                                  ))}
                              </Grid>
                          </Grid>


                          {showAccessSection() && (
                          <Grid container item direction="column">
                              <Grid container item>
                                  <Grid item>
                                      <Typography variant="body1">Access</Typography>
                                  </Grid>
                              </Grid>

                              {systemRolePages.map((rolePage: SysRolePage) => (

                                  ((rolePage.sysRolePageActionCount > 0 && accessSectionVisibility[rolePage.sysRoleKey]) &&
                                  <Grid container item direction="column" key={rolePage.sysRoleKey}>
                                    <Grid container item className="card-header">
                                        <Grid item xs={8}><div>View</div></Grid>
                                        <Grid container item xs={4} justifyContent="center" alignContent="center">
                                            <Grid container item xs={6} justifyContent="center" alignContent="center">
                                                Read Only
                                            </Grid>
                                            <Grid container item xs={6} justifyContent="center" alignContent="center">
                                                Edit
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <RoleAccessGroup
                                      type="Full"
                                      formik={formik}
                                      itemName={rolePage.rolePageName}
                                      itemKey={rolePage.sysRoleKey}
                                      handleRoleSelection={handleRoleSelection}
                                    />
                                      <div style={{ paddingLeft: 50 }}>
                                          <Grid container item>
                                                  <Grid container item xs={12} className="card-header" >
                                                      <Grid item xs={8}><div>Actions</div></Grid>
                                                      <Grid container item xs={4} justifyContent="center" alignContent="center">
                                                          <Grid container item xs={3} justifyContent="center" alignContent="center">
                                                          </Grid>
                                                          <Grid container item xs={3} justifyContent="center" alignContent="center">
                                                              Enable
                                                          </Grid>
                                                      </Grid>
                                                  </Grid>
                                                      {rolePage.sysRolePageAction.map((action: SysRolePageAction) => (
                                                          <RoleAccessGroup
                                                              key={action.sysRolePageActionId}
                                                              type="Enable-Only"
                                                              formik={formik}
                                                              itemName={action.actionName}
                                                              itemKey={rolePage.sysRoleKey}
                                                              childKey={action.actionKey}
                                                              isChild={true}
                                                              handleRoleSelection={handleRoleSelection}
                                                          />
                                                  ))}

                                          </Grid>
                                      </div>
                                  </Grid>
                                  )
                              ))}
                      </Grid>)}
                  </Grid>
              </Form>
              )}
          </Formik>
        </div>
      </main>


      </DialogContent >
        <DialogActions>
          <DialogActionButton
              isEnabled={enableSaveButton}
              savebuttonText="Save"
              saveStatus={saveCallbackStatus.none}
              executeSave={handleSave}
              handleCallbackSave={handleSave}
              handleCallbackCancel={handleEditModalCancel}
              />
        </DialogActions>
      </Dialog>
  );
}
