import { emptyPrimaryAddress, PrimaryAddress } from "./primary-address";

export enum PhoneType {
  "home" = "home",
  "mobile" = "mobile"
}

export interface PrimaryContact {
  contactId?: number;
  title: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  primaryAddress: PrimaryAddress;
  email: string;
  primPhoneNum: string;
  primPhoneType: PhoneType;
  primPhoneExtn?: string;
  secPhoneNum: string;
  secPhoneType: PhoneType;
  leaveVMFl: boolean;
  smsConsentFl: boolean;
  ssn?: string;
  ssnLast4: string;
  birthDate: string;
  contactIncome: ContactIncome;
  externalId?: string; // Stripe person Id
  externalVerifyStatus?: string;
  bankName?: string;
  routingNumber?: string;
  accountNumber?: string;
}

export interface ContactIncome {
  contactIncomeId: number;
  yearlySalary: number;
  employer: string;
  yearsAtEmployer: number;
  verificationScore: number;
  retirementIncome: number;
  capacityToPay: number;
  disposableIncome: number;
  businessIncome: number;
  wealthIncome: number;
  wagesAndEarnings: number;
  isEmployed: boolean;
}

export const emptyContactIncome: ContactIncome = {
  contactIncomeId: 0,
  yearlySalary: 0,
  employer: '',
  yearsAtEmployer: 0,
  verificationScore: 0,
  retirementIncome: 0,
  capacityToPay: 0,
  disposableIncome: 0,
  businessIncome: 0,
  wealthIncome: 0,
  wagesAndEarnings: 0,
  isEmployed: false,
}

export const emptyPrimaryContact: PrimaryContact = {
  contactId: 0,
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  primaryAddress: emptyPrimaryAddress,
  email: "",
  primPhoneNum: "",
  primPhoneType: PhoneType.mobile,
  primPhoneExtn: "",
  secPhoneNum: "",
  secPhoneType: PhoneType.home,
  leaveVMFl: false,
  smsConsentFl: false,
  ssn: "",
  ssnLast4: "",
  birthDate: "",
  contactIncome: emptyContactIncome,
  externalId: "",
  externalVerifyStatus: "",
  bankName: "",
  routingNumber: "",
  accountNumber: ""
};
