import Cookies from "js-cookie";
import { CookieExpiration } from "../../shared/enums";
import { recentlyViewedPatientsObj } from "../../shared/model/recentlyViewed";

export class CookiesService {
  async setRecentlyViewed(recentlyViewed:Array<number> | Array<recentlyViewedPatientsObj>, userName:string, type:string){
    let recentlyViewedCookieUser = await Cookies.getJSON('recentlyViewed');
    let emptyRecentlyViewed:any = {
      userName:"",
      clients:[],
      convertedPatients:[],
      preConvertedPatients:[]
    }

    let emptyUsersCookiesList:any = {
      usersCookiesList: []
    }

    if(!recentlyViewedCookieUser){
      emptyRecentlyViewed.userName = userName
      emptyRecentlyViewed[type] = recentlyViewed
      recentlyViewedCookieUser = emptyRecentlyViewed
      emptyUsersCookiesList.usersCookiesList.push(emptyRecentlyViewed)
      Cookies.set("recentlyViewed", emptyUsersCookiesList, {expires: CookieExpiration.days})

      return emptyUsersCookiesList.usersCookiesList[0];
    } else {
      const index = recentlyViewedCookieUser.usersCookiesList?.findIndex((recentlyViewed:any) => (
      recentlyViewed.userName === userName
      ))

      if(index>=0){
        recentlyViewedCookieUser.usersCookiesList[index][type] = recentlyViewed
      } else {
        emptyRecentlyViewed.userName = userName
        emptyRecentlyViewed[type] = recentlyViewed
        recentlyViewedCookieUser.usersCookiesList.push(emptyRecentlyViewed)
      }
      Cookies.set("recentlyViewed", recentlyViewedCookieUser, {expires: CookieExpiration.days})

      return recentlyViewedCookieUser.usersCookiesList[index];
    }
  }
}

export const cookiesService = new CookiesService();

