import { PayerPlans } from "../../../../../admin-configuration/models/payers";

export interface ClientPayerSelected {
    clientId: number,
    payerId: number,
    payerName: string,
    checked: boolean,
    payorPlans: PayerPlans[];
  }

  export interface PayersInfo {
    clientPayersList: ClientPayerSelected[]
  }

  export const emptyPayersInfo: PayersInfo = {
    clientPayersList: []
  }