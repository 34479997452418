import { LoadingOverlay, saveCallbackStatus} from "@finpay-development/shared-components";
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableColumn } from "../../../shared/model/table-column";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { clearRiskClassSettingsStatus } from "../../state/admin-configuration-slice";
import { ConfigRiskClassSetting } from "../../models/risk-class-setting";
import EditIcon from '@mui/icons-material/Edit';
import { RiskClassSettingConfigModal } from "./risk-class-setting-config-modal";
import { configGetFinPayRiskClasses, configGetRiskClassSettings } from "../../state/admin-configuration-thunk";


const columns: TableColumn[] = [
    { id: "riskClass", label: "Risk Class", minWidth: 100 },
    { id: "riskDesc", label: "Description", minWidth: 200 },
    { id: "cleared", label: "Cleared", minWidth: 200 },
    { id: "highRisk", label: "High Risk Exception", minWidth: 200 },
    { id: "menu", label: "", minWidth: 25 },
];

function RiskClassSettingConfigGrid() {
    const [isRiskSettingConfigModalOpen, setIsRiskSettingConfigModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditModal, setIsEditModal] = useState(true);
    const [selectedRiskClassSetting, setSelectedRiskClassSetting] = useState<ConfigRiskClassSetting>();

    const paramId: number = -2;

    const dispatch = useDispatch<AppDispatch>();

    const state = {
        riskClassSettings: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.riskClassSettings
        ),
        isLoadingRiskClassSettingState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .isLoadingRiskClassSettings
        ),
        getRiskClassSettingStatus: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading
                    .getRiskClassSettingsStatus
        ),
        finPayRiskClasses: useSelector(
            (state: RootState) => {
                return state.adminContext.adminConfigurationContext?.finPayRiskClasses;
        }),
    };
    const {
        riskClassSettings,
        isLoadingRiskClassSettingState,
        getRiskClassSettingStatus,
        finPayRiskClasses,
    } = state;

    useEffect(() => {
        if (!isLoadingRiskClassSettingState && getRiskClassSettingStatus === saveCallbackStatus.success) {
            if (riskClassSettings !== undefined) {
                setIsLoading(false);
                dispatch(clearRiskClassSettingsStatus());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingRiskClassSettingState]);

    const getRiskClassSettings = async () => {
        dispatch(configGetRiskClassSettings({ paramId: paramId}));
    };

    useEffect(() => {
        const fetchFinPayRiskClasses = async () => {
            dispatch(configGetFinPayRiskClasses({ paramId: paramId}));
        }
        if (finPayRiskClasses.length === 0) {
            fetchFinPayRiskClasses();
        } else {
            getRiskClassSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, finPayRiskClasses]);

    const handleEditRiskClassSettingClick = (event: React.MouseEvent<HTMLButtonElement>, riskClassId: number) => {
        setIsEditModal(true);
        setIsRiskSettingConfigModalOpen(true);
        let thisRiskClassSetting = riskClassSettings?.find(
            (el) => el.fpRiskClassId === riskClassId
        );
        if (thisRiskClassSetting?.riskClassSettingId === undefined) {
            setIsEditModal(false);
        }
        setSelectedRiskClassSetting(thisRiskClassSetting);
    };

    function handleRiskSettingConfigModalCancel() {
        setIsRiskSettingConfigModalOpen(false);
    }

    async function handleRiskSettingConfigModalSubmit(isEditMode: boolean) {
        setIsRiskSettingConfigModalOpen(false);
        setIsLoading(true);
        await getRiskClassSettings();
    }

    return (
        <Paper>
            {isLoading ? (
                <LoadingOverlay />
            ) : (
                <Box style={{ padding: "1em", marginBottom: "1.5em" }}>
                    <Grid container justifyContent="space-between">
                        <Grid>
                            <Box style={{ padding: "1em 0" }}>
                                <Typography variant="subtitle2">Risk Class</Typography>
                            </Box>
                        </Grid>
                        <Grid></Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table
                            data-testid="results"
                            className="table alternating-row"
                            size="small"
                            stickyHeader
                        >
                            <TableHead>
                                <TableRow>
                                    {columns &&
                                        columns?.map((column) => (
                                            <TableCell
                                                key={column?.id}
                                                align={column?.align}
                                                style={{
                                                    padding: "0.5em",
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {riskClassSettings?.map((row: ConfigRiskClassSetting) => (
                                    <TableRow key={row?.fpRiskClassId}>
                                        <TableCell align="left" style={{ padding: "0.5em" }}>
                                            {row?.fpRiskClassId}
                                        </TableCell>
                                        <TableCell align="left" style={{ padding: "0.5em", width: "40%", }}>
                                            {row?.riskClassName}
                                        </TableCell>
                                        <TableCell align="left" style={{ padding: "0.5em" }}>
                                            {row?.riskClassCleared}{row?.riskClassCleared && ('%')}
                                        </TableCell>
                                        <TableCell align="left" style={{ padding: "0.5em" }}>
                                            {row?.riskClassHre}{row?.riskClassHre && ('%')}
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton
                                                aria-haspopup="true"
                                                onClick={(e) => {
                                                    handleEditRiskClassSettingClick(e, row.fpRiskClassId);
                                                }}
                                                size="large"
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>

                    {isRiskSettingConfigModalOpen && (
                        <RiskClassSettingConfigModal
                            open={isRiskSettingConfigModalOpen}
                            isEdit={isEditModal}
                            handleRiskClassSettingCancel={handleRiskSettingConfigModalCancel}
                            handleRiskClassSettingSubmit={handleRiskSettingConfigModalSubmit}
                            riskClassSettingItem={selectedRiskClassSetting!}
                        />
                    )}

                </Box>
            )}
        </Paper>
    );
}

export default RiskClassSettingConfigGrid;
