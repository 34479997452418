import React from "react";
import { Grid, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import "./../../scss/components/_section-complete-status.scss";

interface SectionCompleteStatusProps {
  label: string;
  isComplete: boolean;
  'test-id'?: string;
}

function SectionCompleteStatus(props: SectionCompleteStatusProps) {
  const { label, isComplete, 'test-id': testId = "" } = props;

  return (
    <div className="section-complete-status">
      <Grid container>
        <Grid item className={`icon ${isComplete ? "complete" : "incomplete"}`}>
          {isComplete ? (
            <CheckCircleOutlineIcon />
          ) : (
            <RadioButtonUncheckedIcon />
          )}
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className="mb-0" test-id={testId} >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default SectionCompleteStatus;
