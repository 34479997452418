export interface RiskClass {
  riskClassId: number;
  riskClassName: string;
}

export const riskClass: RiskClass[] = [
  {
    riskClassId: 1,
    riskClassName: "Risk Class 1: Self Pay",
  },
  {
    riskClassId: 2,
    riskClassName: "Risk Class 2: In Network High PFR",
  },
  {
    riskClassId: 3,
    riskClassName: "Risk Class 3: Converting to Self Pay",
  },
  {
    riskClassId: 4,
    riskClassName:
      "Risk Class 4: Scheduled Prior to Financial Clearance",
  },
  {
    riskClassId: 5,
    riskClassName:
      "Risk Class 5: Admitted Prior to Financial Clearance",
  },
  {
    riskClassId: 6,
    riskClassName: "Risk Class 6: Out of Network High PFR",
  },
  // {
  //   riskClassId: 7,
  //   riskClassName:
  //     "Risk Class 7: Prior Balance",
  // },
  {
    riskClassId: 8,
    riskClassName: "Risk Class 8: Low PFR",
  },
  // {
  //   riskClassId: 9,
  //   riskClassName: "Risk Class 9: Licensed Professional Recovery Program",
  // },
  {
    riskClassId: 10,
    riskClassName: "Risk Class 10: Discharged Prior to Financial Clearance",
  },
  // {
  //   riskClassId: 11,
  //   riskClassName: "Risk Class 11: Step Down to Outpatient"
  // },
  {
    riskClassId: 12,
    riskClassName: "Risk Class 12: Paid to Patient (P2P)"
  },
  {
    riskClassId: 13,
    riskClassName: "Risk Class 13: EBO"
  },
  {
    riskClassId: 14,
    riskClassName: "Risk Class 14: BDR"
  },
];

export const emptyRiskClass: RiskClass = {
  riskClassId: 0,
  riskClassName: "",
};

export const riskClassNameLookup = (id: any) => {
  const foundRiskClass = riskClass.find(
    (riskClass: RiskClass) => riskClass.riskClassId === id
  );
  return foundRiskClass?.riskClassName;
};
