import './../../../../../scss/pages/admin/_admin-clients.scss';

import { Button, DialogActionButton, TextField, Toggle } from '@finpay-development/shared-components';
import { MenuItem, Tooltip, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { statesList } from '../../../../../shared/misc/us-states';
import { State } from '../../../../../shared/model/state';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import { commonFields, facility } from '../../../../../shared/validation/schemas';
import { FacilityForm, FacilityUIForm } from '../../../../models/facility-form';
import { PayOutFrequency } from '../../../../models/payOutFreq';
import { setFacilityTabComplete } from '../../../../state/clients/implementation-clients-slice';
import { clearFacilityForm, clearStatus, resetAddClientAccountStatus} from '../../../../state/facility/implementation-facility-slice';
import { addClientFacilityExternalAccount, addPayNowClientFacilityExternalAccount, getClientConnectAccounts, getEncryptFacilityPayload, saveClientFacility } from '../../../../state/facility/implementation-facility-thunk';
import { ExternalAccountType } from '../../../../../shared/enums';
import { getClients } from 'src/implementation-specialist/state/clients/implementation-clients-thunk';

interface FacilityModalProps {
  open: boolean;
  isEdit: boolean;
  handleFacilityModalCancel: () => void;
  handleFacilityModalSubmit: (isEdit: boolean) => void;
}

export function FacilityModal(props: FacilityModalProps) {
  const { open, handleFacilityModalCancel, handleFacilityModalSubmit, isEdit } =
    props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const selectors = {
    clientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
          .clientId
    ),
    useClientTemplate: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
          .clientBusRules?.useClientTemplate
    ),
    currentFacilityForm: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.currentFacilityForm
    ),
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.facilities
    ),
    saveStatus: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.apiStatus
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.errorMessage
    ),
    uniqueAccountIds: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.uniqueAccountIds
    ),
    isGettingEncryptedFacId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isGettingEncryptedFacId
    ),
    paynowURL: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.paynowURL
    ),
    currentClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.currentClientEntityDetails
    ),
    finpassExternalAccountIds: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.finpassExternalAccountIds
    ),
    payNowExternalAccountIds: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.payNowExternalAccountIds
    ),
    isAddClientConnectAccountSuccessful: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isAddClientConnectAccountSuccessful
    ),
    isAddClientConnectAccountLoading: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isClientLoading.addClientConnectAccount
    ),
    isAddPayNowClientConnectAccountSuccessful: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isAddPayNowClientConnectAccountSuccessful
    ),
    isAddPayNowClientConnectAccountLoading: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.isClientLoading.addPayNowClientConnectAccount
    ),
  };

  const facilityId = selectors.currentFacilityForm?.facilityId || 0;

  useEffect(() => {
    const paramString = "facId=" + facilityId;
    const getPayNowURL = async () => {
      dispatch(getEncryptFacilityPayload(paramString));
    }

    if (isEdit && (facilityId > 0) && !selectors.isGettingEncryptedFacId) {
      getPayNowURL();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEdit, facilityId
  ]);

  useEffect(() => {
    const getAllClientConnectAccounts = async () => {
      dispatch(getClientConnectAccounts(selectors.clientId))
    }

    if ((selectors.isAddClientConnectAccountSuccessful && !selectors.isAddClientConnectAccountLoading) ||
        (selectors.isAddPayNowClientConnectAccountSuccessful && !selectors.isAddPayNowClientConnectAccountLoading)) {
      checkIfIsValid(formRef);
      getAllClientConnectAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectors.isAddClientConnectAccountSuccessful,
    selectors.isAddClientConnectAccountLoading,
    selectors.isAddPayNowClientConnectAccountSuccessful,
    selectors.isAddPayNowClientConnectAccountLoading
  ]);

  function handleAddFinPassExternalAccount() {
    dispatch(addClientFacilityExternalAccount({facilityId: facilityId, externalAccountType: ExternalAccountType.Patient}));
  }

  function handleAddPayNowExternalAccount() {
    dispatch(addPayNowClientFacilityExternalAccount({facilityId: facilityId, externalAccountType: ExternalAccountType.WebPayment}));
  }

  async function handleSave() {
    const facilityId = isEdit ? formRef?.current?.values?.facilityId : 0;
    const formValues: FacilityForm = {
      ...formRef.current.values,
      facilityId: facilityId,
    };

    if (formValues.payOutFreq === "daily") {
      formValues.payOutDay = -1;
    }

    try {
      await dispatch(saveClientFacility({ clientId: selectors.clientId, formValues }))

      await dispatch(getClients({includeFacility: true}));

    } catch (e: any) {
      await dispatch(showErrorStatus(e?.message));
    }

  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleFacilityModalSubmit(isEdit);
      setEnableSaveButton(false);
      dispatch(setFacilityTabComplete(true));
      dispatch(resetAddClientAccountStatus());
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    dispatch(resetAddClientAccountStatus());
    handleFacilityModalCancel();
  }

  useEffect(() => {
    if (!isEdit) dispatch(clearFacilityForm());
  });

  const setPayOutDayDropdown = (payOutFrequency: PayOutFrequency) => {
    let days = 0;
    switch (payOutFrequency) {
      case "weekly":
        days = 7;
        break;
      case "monthly":
        days = 31;
        break;
    }
    return Array(days)
      .fill("")
      .map((day: string, i: number) => (
        <MenuItem key={i + 1} value={i + 1}>
          {payOutFrequency === "weekly"
            ? Utils.getDayOfWeekFromItsNumber(i)
            : i + 1}
        </MenuItem>
      ));
  };

  const initialValues = {
    clientId: selectors.clientId,
    facilityId: selectors.currentFacilityForm?.facilityId,
    facilityName: selectors.currentFacilityForm?.facilityName,
    outpatientEnabled: selectors.currentFacilityForm?.outpatientEnabled,
    streetAddress1: selectors.currentFacilityForm?.streetAddress1,
    streetAddress2: selectors.currentFacilityForm?.streetAddress2,
    city: selectors.currentFacilityForm?.city,
    state: selectors.currentFacilityForm?.state || -1,
    zipCode: selectors.currentFacilityForm?.zipCode,
    website: selectors.currentFacilityForm?.website,
    phone: selectors.currentFacilityForm?.phone,

    primaryContactFirstName:
      selectors.currentFacilityForm?.primaryContactFirstName,
    primaryContactLastName:
      selectors.currentFacilityForm?.primaryContactLastName,
    primaryContactAddress1:
      selectors.currentFacilityForm?.primaryContactAddress1,
    primaryContactAddress2:
      selectors.currentFacilityForm?.primaryContactAddress2,
    primaryContactCity: selectors.currentFacilityForm?.primaryContactCity,
    primaryContactState:
      selectors.currentFacilityForm?.primaryContactState || -1,
    primaryContactZipCode: selectors.currentFacilityForm?.primaryContactZipCode,
    primaryContactEmail: selectors.currentFacilityForm?.primaryContactEmail,
    reTypePrimaryContactEmail:
      selectors.currentFacilityForm?.primaryContactEmail,
    primaryContactPhone: selectors.currentFacilityForm?.primaryContactPhone,
    primaryContactExt: selectors.currentFacilityForm?.primaryContactExt,
    primaryContactMobilePhone:
      selectors.currentFacilityForm?.primaryContactMobilePhone,
    reTypePrimaryContactMobilePhone:
      selectors.currentFacilityForm?.primaryContactMobilePhone,

    taxIdNumber: Utils.formatTaxIdNumber(selectors.currentFacilityForm.taxIdNumber || "") || "",
    taxIdDisplay: Utils.formatTaxIdNumberDisplayed(selectors.currentFacilityForm.taxIdNumber || "") || "",
    externalAccount: selectors.currentFacilityForm?.externalAccount || "-1",
    payOutFreq: selectors.currentFacilityForm?.payOutFreq,
    payOutDay: selectors.currentFacilityForm?.payOutDay,
    customDocTokenId: selectors.currentFacilityForm?.customDocTokenId || "",

    payNowExternalAccount: selectors.currentFacilityForm?.payNowExternalAccount || "-1",
    payNowUrl: isEdit ? selectors.paynowURL : "",
    referenceNumberName: isEdit ? selectors.currentFacilityForm?.referenceNumberName : "",
    referenceNumberHelpText: isEdit ? selectors.currentFacilityForm?.referenceNumberHelpText : "",
    bannerText: isEdit ? selectors.currentFacilityForm?.bannerText : "",

    monthlyOperatingCostIp: selectors?.currentFacilityForm?.monthlyOperatingCostIp,
    monthlyOperatingCostOp: selectors?.currentFacilityForm?.monthlyOperatingCostOp,
    bedCapacityIp: selectors?.currentFacilityForm?.bedCapacityIp,
    bedCapacityOp: selectors?.currentFacilityForm?.bedCapacityOp,
  };

  const allClientTemplateValidationSchema = {
    ...facility,
  };

  const partialClientTemplateValidationSchema = {
    ...facility,
    customDocTokenId: commonFields.REQUIRED_STRING,
  };

  const getValidationSchema = () => {
    if (selectors.useClientTemplate === "All") {
      return allClientTemplateValidationSchema;
    } else {
      // "Partial"
      return partialClientTemplateValidationSchema;
    }
  }

  let validationSchema = Yup.object(getValidationSchema());

  useEffect(() => {
    // eslint-disable-next-line
    validationSchema = Yup.object(getValidationSchema());
  }, [dispatch, selectors.useClientTemplate])

  function checkIfIsValid(value: FacilityUIForm) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true)
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">
          {isEdit ? "Edit Facility" : "Add New Facility"}
        </span>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formRef}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form autoComplete="off">
              <Grid container spacing={2}>
                {!isEdit && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">Entity</Typography>
                  </Grid>
                )}
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["facilityName"] &&
                      formik.errors["facilityName"]
                        ? formik.errors["facilityName"]
                        : ""
                    }
                    label="Facility Name"
                    name="facilityName"
                    value={formik.values.facilityName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Facility Name"
                  />
                </Grid>

                <Grid xs={12} item>
                  <Typography variant="subtitle2" className="mt-6">
                    Outpatient Facility?
                  </Typography>

                  <Toggle
                    name="outpatientEnabled"
                    value={formik.values.outpatientEnabled}
                    formik={formik}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Facility Address
                  </Typography>
                </Grid>
                <Grid xs={12} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["streetAddress1"] &&
                      formik.errors["streetAddress1"]
                        ? formik.errors["streetAddress1"]
                        : ""
                    }
                    label="Street Address 1"
                    name="streetAddress1"
                    value={formik.values.streetAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Street Address"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["streetAddress2"] &&
                      formik.errors["streetAddress2"]
                        ? formik.errors["streetAddress2"]
                        : ""
                    }
                    label="Street Address 2"
                    name="streetAddress2"
                    value={formik.values.streetAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["city"] && formik.errors["city"]
                        ? formik.errors["city"]
                        : ""
                    }
                    label="City"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter City"
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["state"] && formik.errors["state"]
                        ? formik.errors["state"]
                        : ""
                    }
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    <MenuItem value="-1">Select State</MenuItem>
                    {statesList.map((state: State) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["zipCode"] && formik.errors["zipCode"]
                        ? formik.errors["zipCode"]
                        : ""
                    }
                    label="Zip Code"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Zip Code"
                    maxLength={5}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["website"] && formik.errors["website"]
                        ? formik.errors["website"]
                        : ""
                    }
                    label="Facility Website"
                    name="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Website"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["phone"] && formik.errors["phone"]
                        ? formik.errors["phone"]
                        : ""
                    }
                    label="Facility phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "phone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Facility Phone Number"
                    maxLength={12}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Contact
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactFirstName"] &&
                      formik.errors["primaryContactFirstName"]
                        ? formik.errors["primaryContactFirstName"]
                        : ""
                    }
                    label="First Name"
                    name="primaryContactFirstName"
                    value={formik.values.primaryContactFirstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter First Name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactLastName"] &&
                      formik.errors["primaryContactLastName"]
                        ? formik.errors["primaryContactLastName"]
                        : ""
                    }
                    label="Last Name"
                    name="primaryContactLastName"
                    value={formik.values.primaryContactLastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Last Name"
                  />
                </Grid>
                <Grid xs={12} item className="mb-4">
                  <TextField
                    error={
                      formik.touched["primaryContactAddress1"] &&
                      formik.errors["primaryContactAddress1"]
                        ? formik.errors["primaryContactAddress1"]
                        : ""
                    }
                    label="Street Address 1"
                    name="primaryContactAddress1"
                    value={formik.values.primaryContactAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Street Address"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["primaryContactAddress2"] &&
                      formik.errors["primaryContactAddress2"]
                        ? formik.errors["primaryContactAddress2"]
                        : ""
                    }
                    label="Street Address 2"
                    name="primaryContactAddress2"
                    value={formik.values.primaryContactAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactCity"] &&
                      formik.errors["primaryContactCity"]
                        ? formik.errors["primaryContactCity"]
                        : ""
                    }
                    label="City"
                    name="primaryContactCity"
                    value={formik.values.primaryContactCity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter City"
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["primaryContactState"] &&
                      formik.errors["primaryContactState"]
                        ? formik.errors["primaryContactState"]
                        : ""
                    }
                    label="State"
                    name="primaryContactState"
                    value={formik.values.primaryContactState}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    <MenuItem value="-1">Select State</MenuItem>
                    {statesList.map((state: State) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["primaryContactZipCode"] &&
                      formik.errors["primaryContactZipCode"]
                        ? formik.errors["primaryContactZipCode"]
                        : ""
                    }
                    label="Zip Code"
                    name="primaryContactZipCode"
                    value={formik.values.primaryContactZipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Zip Code"
                    maxLength={5}
                  />
                </Grid>

                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactEmail"] &&
                      formik.errors["primaryContactEmail"]
                        ? formik.errors["primaryContactEmail"]
                        : ""
                    }
                    label="Email Address"
                    name="primaryContactEmail"
                    value={formik.values.primaryContactEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["reTypePrimaryContactEmail"] &&
                      formik.errors["reTypePrimaryContactEmail"]
                        ? formik.errors["reTypePrimaryContactEmail"]
                        : ""
                    }
                    label="Retype Email Address"
                    name="reTypePrimaryContactEmail"
                    value={formik.values.reTypePrimaryContactEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Confirm email"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactPhone"] &&
                      formik.errors["primaryContactPhone"]
                        ? formik.errors["primaryContactPhone"]
                        : ""
                    }
                    label="Phone"
                    name="primaryContactPhone"
                    value={formik.values.primaryContactPhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "primaryContactPhone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Phone"
                    maxLength={12}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["primaryContactExt"] &&
                      formik.errors["primaryContactExt"]
                        ? formik.errors["primaryContactExt"]
                        : ""
                    }
                    label="EXT"
                    name="primaryContactExt"
                    value={formik.values.primaryContactExt}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="ext"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["primaryContactMobilePhone"] &&
                      formik.errors["primaryContactMobilePhone"]
                        ? formik.errors["primaryContactMobilePhone"]
                        : ""
                    }
                    label="Mobile Phone"
                    name="primaryContactMobilePhone"
                    value={formik.values.primaryContactMobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "primaryContactMobilePhone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                    helperText="Used for multifactor account authorization"
                    placeholder="Mobile phone"
                    maxLength={12}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["reTypePrimaryContactMobilePhone"] &&
                      formik.errors["reTypePrimaryContactMobilePhone"]
                        ? formik.errors["reTypePrimaryContactMobilePhone"]
                        : ""
                    }
                    label="Retype Mobile Phone"
                    name="reTypePrimaryContactMobilePhone"
                    value={formik.values.reTypePrimaryContactMobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "reTypePrimaryContactMobilePhone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="confirm mobile phone"
                    maxLength={12}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Payments
                  </Typography>
                  <Typography className="mb-0" variant="h4">
                    Pay to Account
                  </Typography>
                </Grid>
                <Grid xs={6} item aria-autocomplete="none">
                  <TextField
                    autoComplete={false}
                    error={Utils.getErrorText(formik, "taxIdNumber")}
                    label="Tax Identification Number"
                    name="taxIdDisplay"
                    value={ formik.values.taxIdDisplay}
                    onChange={(e: Event) => {

                      formik.handleChange(e);
                      formik.setFieldValue(
                        "taxIdDisplay",
                        Utils.formatTaxIdNumberDisplayedMixin(
                          (e.target as HTMLInputElement).value
                        )
                      )
                    }}
                    onBlur={(e: Event) => {
                      if (formik.values.taxIdDisplay.includes("●")) {
                        // they intend to keep the blurred out value
                        formik.setFieldValue("taxIdDisplay", Utils.formatTaxIdNumberDisplayed(formik.values.taxIdNumber))
                      } else {
                        formik.setFieldValue("taxIdNumber", formik.values.taxIdDisplay);
                        formik.setFieldTouched("taxIdNumber", true);
                      }
                      formik.handleBlur(e);
                    }}
                    placeholder="Enter Tax Identification Number"
                    maxLength={10}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6 mb-0" variant="h4">
                    Pay from Account
                  </Typography>
                </Grid>

                <Grid xs={6} item aria-autocomplete="none">
                  <TextField
                    autoComplete={false}
                    select={true}
                    error={
                      formik.touched["externalAccount"] &&
                      formik.errors["externalAccount"]
                        ? formik.errors["externalAccount"]
                        : ""
                    }
                    label={
                      isEdit ? "Existing FinPass Connect Accounts" : "Connect Accounts"
                    }
                    name="externalAccount"
                    value={formik.values.externalAccount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=""
                  >
                    <MenuItem value="-1">Select Connect Account</MenuItem>
                    {selectors.finpassExternalAccountIds.map((accountId) => (
                      <MenuItem key={accountId} value={accountId}>
                        {accountId}
                      </MenuItem>))
                    }

                  </TextField>
                </Grid>
                { isEdit &&
                  <Grid item xs={6}>
                    <Button
                      onClick={handleAddFinPassExternalAccount}
                      type="secondary"
                      loading={selectors.isAddClientConnectAccountLoading}
                      disabled={selectors.isAddClientConnectAccountLoading}
                      spinnerLeftPosition={9}>
                      Add New Account
                    </Button>
                  </Grid>
                }
                { !isEdit &&
                  <Grid item xs={6}>
                    <Tooltip title='You must Add this facility before you can add a new Connect Account'>
                      <span>
                        <Button disabled>Add New Account</Button>
                      </span>
                    </Tooltip>
                  </Grid>
                }

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Web Payments
                  </Typography>
                  <Typography className="mb-0" variant="h4">
                    Pay to Account
                  </Typography>
                </Grid>
                <Grid xs={6} item aria-autocomplete="none">
                  <TextField
                    autoComplete={false}
                    select={true}
                    error={
                      formik.touched["payNowExternalAccount"] &&
                      formik.errors["payNowExternalAccount"]
                        ? formik.errors["payNowExternalAccount"]
                        : ""
                    }
                    label={
                      isEdit ? "Web Payments Connect Accounts" : "Connect Accounts"
                    }
                    name="payNowExternalAccount"
                    value={formik.values.payNowExternalAccount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=""
                  >
                    <MenuItem value="-1" selected>Select Connect Account</MenuItem>
                    {selectors.payNowExternalAccountIds.map((accountId) => (
                      <MenuItem key={accountId} value={accountId}>
                        {accountId}
                      </MenuItem>))
                    }
                  </TextField>
                </Grid>
                { isEdit &&
                  <Grid item xs={6}>
                    <Button
                      onClick={handleAddPayNowExternalAccount}
                      type="secondary"
                      loading={selectors.isAddPayNowClientConnectAccountLoading}
                      disabled={selectors.isAddPayNowClientConnectAccountLoading}
                      spinnerLeftPosition={9}>
                      Add New Account
                    </Button>
                  </Grid>
                }
                { !isEdit &&
                  <Grid item xs={6}>
                    <Tooltip title='You must Add this facility before you can add a new Connect Account'>
                      <span>
                        <Button disabled>Add New Account</Button>
                      </span>
                    </Tooltip>
                  </Grid>
                }

                <Grid xs={12} item aria-autocomplete="none" style={{ paddingTop: "25px"}}>
                  <TextField
                    disabled={true}
                    autoComplete={false}
                    error={
                      formik.touched["payNowUrl"] &&
                      formik.errors["payNowUrl"]
                        ? formik.errors["payNowUrl"]
                        : ""
                    }
                    label="Web Payments URL"
                    name="payNowUrl"
                    value={formik.values.payNowUrl}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=""
                    maxLength={256}
                  />
                </Grid>

                <Grid xs={12} item aria-autocomplete="none" style={{ paddingTop: "25px"}}>
                  <TextField
                    required={Utils.isPopulatedValue(formik.values.payNowExternalAccount) || Utils.isPopulatedValue(formik.values.referenceNumberName) || Utils.isPopulatedValue(formik.values.bannerText) || Utils.isPopulatedValue(formik.values.referenceNumberHelpText)}
                    autoComplete={false}
                    error={Utils.getErrorText(formik, "bannerText", ["bannerText", "referenceNumberName", "referenceNumberHelpText"])}
                    multiline={true}
                    label="Welcome Banner Text"
                    name="bannerText"
                    value={formik.values.bannerText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={`Welcome to FinPay, the patient financial management partner of` + formik.values.facilityName}
                    maxLength={1000}
                  />
                </Grid>

                <Grid xs={8} item aria-autocomplete="none" style={{ paddingTop: "25px"}} >
                  <TextField
                    required={Utils.isPopulatedValue(formik.values.payNowExternalAccount) || Utils.isPopulatedValue(formik.values.referenceNumberName) || Utils.isPopulatedValue(formik.values.bannerText) || Utils.isPopulatedValue(formik.values.referenceNumberHelpText)}
                    autoComplete={false}
                    error={Utils.getErrorText(formik, "referenceNumberName", ["bannerText", "referenceNumberName", "referenceNumberHelpText"])}
                    label="Reference Number Name"
                    name="referenceNumberName"
                    value={formik.values.referenceNumberName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Account Number"
                    maxLength={256}
                  />
                </Grid>

                <Grid item aria-autocomplete="none" xs={12} style={{ paddingTop: "25px"}} >
                  <TextField
                    required={Utils.isPopulatedValue(formik.values.payNowExternalAccount) || Utils.isPopulatedValue(formik.values.referenceNumberName) || Utils.isPopulatedValue(formik.values.bannerText) || Utils.isPopulatedValue(formik.values.referenceNumberHelpText)}
                    multiline={true}
                    autoComplete={false}
                    error={Utils.getErrorText(formik, "referenceNumberHelpText", ["bannerText", "referenceNumberName", "referenceNumberHelpText"])}
                    label="Reference Number Help Text"
                    name="referenceNumberHelpText"
                    value={formik.values.referenceNumberHelpText}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=""
                    maxLength={2400}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6 mb-0" variant="subtitle2">
                    Payout Schedule
                  </Typography>
                </Grid>
                <Grid container item xs={9} alignItems="flex-end">
                  <Grid xs={isEdit ? 9 : 6} item>
                    <Typography className="mb-0 ml-2" variant="body2">
                      Issued By
                    </Typography>
                    <div className={`buttongroup`}>
                      {
                        <>
                          {/* Note: Not iterating an array because of issues with required 'key' */}
                          <input
                            id="1"
                            type="radio"
                            value="monthly"
                            name="payOutFreq"
                            checked={formik.values.payOutFreq === "monthly"}
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.setFieldValue("payOutDay", "-1");
                            }}
                          />
                          <label htmlFor="1">Monthly</label>
                          <input
                            id="2"
                            type="radio"
                            value="weekly"
                            name="payOutFreq"
                            checked={formik.values.payOutFreq === "weekly"}
                            onChange={(e: any) => {
                              formik.handleChange(e);
                              formik.setFieldValue("payOutDay", "-1");
                            }}
                          />
                          <label htmlFor="2">Weekly</label>
                          <input
                            id="3"
                            type="radio"
                            value="daily"
                            name="payOutFreq"
                            checked={formik.values.payOutFreq === "daily"}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="3">Daily</label>
                        </>
                      }
                    </div>
                  </Grid>
                  <Grid xs={isEdit ? 3 : 4} item>
                    <TextField
                      disabled={formik.values.payOutFreq === "daily"}
                      select={true}
                      label="Pay Out Day"
                      name="payOutDay"
                      value={formik.values.payOutDay}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem value="-1">Select</MenuItem>
                      {setPayOutDayDropdown(formik.values.payOutFreq)}
                    </TextField>
                  </Grid>
                  {selectors.useClientTemplate !== "All" && (
                    <Grid item xs={12} lg={8} className="mt-8">
                      <TextField
                        error={
                          formik.touched["customDocTokenId"] &&
                          formik.errors["customDocTokenId"]
                            ? formik.errors["customDocTokenId"]
                            : ""
                        }
                        label="Compliance Document Token ID"
                        name="customDocTokenId"
                        value={formik.values.customDocTokenId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Compliance Document Token ID"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography className="mt-6 mb-0" variant="subtitle2">
                    Operating Costs
                  </Typography>
                </Grid>
                <Grid container item xs={12} alignItems="flex-end">
                  <Grid xs={6} className="mt-6" item>
                    <TextField
                      required={false}
                      label="In-Patient Monthly Operating Costs"
                      name="monthlyOperatingCostIp"
                      value={formik.values.monthlyOperatingCostIp && formik.values.monthlyOperatingCostIp > 0 ? formik.values.monthlyOperatingCostIp: ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="In-Patient Monthly Operating Costs"
                      maxLength={10}
                    />
                  </Grid>
                  <Grid xs={5} className="ml-4" item>
                    <TextField
                      required={false}
                      label="In-Patient Bed Capacity"
                      name="bedCapacityIp"
                      value={formik.values.bedCapacityIp && formik.values.bedCapacityIp > 0 ? formik.values.bedCapacityIp : ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="In-Patient Bed Capacity"
                      maxLength={10}
                    />
                  </Grid>
                  <Grid xs={6} className="mt-6" item>
                    <TextField
                      required={false}
                      label="Out-Patient Monthly Operating Costs"
                      name="monthlyOperatingCostOp"
                      value={formik.values.monthlyOperatingCostOp && formik.values.monthlyOperatingCostOp > 0 ? formik.values.monthlyOperatingCostOp: ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Out-Patient Monthly Operating Costs"
                      maxLength={10}
                    />
                  </Grid>
                  <Grid xs={5} className="ml-4" item>
                    <TextField
                      required={false}
                      label="Out-Patient Bed Capacity"
                      name="bedCapacityOp"
                      value={formik.values.bedCapacityOp && formik.values.bedCapacityOp > 0 ? formik.values.bedCapacityOp: null}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Out-Patient Bed Capacity"
                      maxLength={10}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Add"}
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}