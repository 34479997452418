import { Button } from '@finpay-development/shared-components';
import React from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from '../../../../shared/state/store';
import { getScriptDocument } from '../../../state/patient-thunk';
import { PatientEncounter } from '../../models/patient-encounter';


interface expectedProps {
    selectedEncounter: PatientEncounter,
    disable: boolean,
    environment?: string,
}

const ScriptButton = (props: expectedProps) => {
    const {selectedEncounter, disable} = props;
    const dispatch = useDispatch<AppDispatch>();
    const handleOpenScript = () => {
        if(selectedEncounter) {
            dispatch(getScriptDocument(selectedEncounter));
        }
    }

    return (
        <Button onClick={() => handleOpenScript()}
             type="secondary"
             disabled={disable}
             marginRight={6}
        >
            Script
        </Button>
    )
}
export default ScriptButton;
