import { Checkbox, DialogActionButton, saveCallbackStatus } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { AppDispatch } from '../../../../../shared/state/store';
import { savePaymentFrequency } from '../../../../state/rules-engine/rules-engine-thunk';
import { ImplementationFacility } from '../models/implementation-facility';

interface EditPaymentFrequencyModalProps {
  open: boolean;
  handlePaymentFrequencyClose: ()=>void;
  facility: ImplementationFacility;
  frequency: {[key: string]: boolean};

}

function EditPaymentFrequencyModal(props: EditPaymentFrequencyModalProps) {
  const { open, handlePaymentFrequencyClose, frequency, facility } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const handleSave = async() => {
      const formValue = formRef.current.values;

      let paymentFrequencyString: string[] = [];

      if (formValue.F) paymentFrequencyString.push("F"); // F = Full Payment
      if (formValue.M) paymentFrequencyString.push("M"); // M = Monthly Payment Program
      if (formValue.W) paymentFrequencyString.push("W"); // W = Weekly Payment Program
      if (formValue.E) paymentFrequencyString.push("E"); // E = Exception Payment Program

      const facilityToEdit = {
        ...facility,
      };
      if (facilityToEdit.customConfigs.length > 0) {
        if (facilityToEdit.customConfigs[0].configKey === undefined) {
          facilityToEdit.customConfigs = [];
        }
      }
      if (facilityToEdit.vboPfrThreshold === null) {
        delete facilityToEdit.vboPfrThreshold;
      }
      if (facilityToEdit.vboTimeThreshold === null) {
        delete facilityToEdit.vboTimeThreshold;
      }
      facilityToEdit.patientPmtFreq = paymentFrequencyString.join(",");
      // facilityToEdit.taxId = "12-3456789";   //why hard coded here??
      await dispatch(savePaymentFrequency(facilityToEdit));
      handlePaymentFrequencyClose();
  }

  const initialValues = {
      F: frequency.F,
      M: frequency.M,
      W: frequency.W,
      E: frequency.E
  }

  const validationSchema = Yup
  .object({
    F: Yup.boolean(),
    M: Yup.boolean(),
    W: Yup.boolean(),
    E: Yup.boolean()
  })
  .test(
    'checkAtLeastOne',
    (obj) => {
      if ( obj.F || obj.M || obj.W || obj.E ) {
        setEnableSaveButton(true);
        return true;
      }

      setEnableSaveButton(false);
      return new Yup.ValidationError('At least one must be checked');
    }
  );

  return (
    <Dialog
      className={`modal`}
      scroll="body"
      open={open}
    >
      <DialogTitle>
        <span className="title">Edit Payment Frequency</span>
      </DialogTitle>
      <DialogContent>

        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={() => {}}
        >
            {formik => (
            <Form>
                <>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid item>
                        <Checkbox
                            id="1"
                            name="F"
                            label="Full Payment"
                            checked={formik.values.F}
                            onChange={formik.handleChange}
                        />
                        <Checkbox
                            id="2"
                            name="M"
                            label="Monthly Payment Program"
                            checked={formik.values.M}
                            onChange={formik.handleChange}
                        />
                        <Checkbox
                            id="3"
                            name="W"
                            label="Weekly Payment Program"
                            checked={formik.values.W}
                            onChange={formik.handleChange}
                        />
                        <Checkbox
                            id="4"
                            name="E"
                            label="Exception Payment Program"
                            checked={formik.values.E}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Grid>
                </>
            </Form>
            )}
        </Formik>

      </DialogContent>
      <DialogActions>

        <DialogActionButton
            isEnabled={enableSaveButton}
            savebuttonText="Save"
            saveStatus={saveCallbackStatus.none}
            executeSave={handleSave}
            handleCallbackSave={handlePaymentFrequencyClose}
            handleCallbackCancel={handlePaymentFrequencyClose}
          />
      </DialogActions>
    </Dialog>
  );
}

export default EditPaymentFrequencyModal;
