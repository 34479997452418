import { DeleteModal, LoadingOverlay, saveCallbackStatus, TextField } from '@finpay-development/shared-components';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getVobClassifications } from '../../../admissions-advisor/state/vob-thunk';
import { showStatus } from '../../../security/state/user-slice';
import { TableColumn } from '../../../shared/model/table-column';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { LevelOfCare } from '../../models/level-of-care';
import { clearStatus } from '../../state/admin-configuration-slice';
import { configDeleteLOC, configGetLOC } from '../../state/admin-configuration-thunk';
import { LevelOfCareModal } from './levels-of-care-modal';

type MenuActionType = "add" | "edit" | "delete";

const columns: TableColumn[] = [
  { id: "levelOfCareCode", label: "Code", minWidth: 200 },
  { id: "levelOfCareName", label: "Name", minWidth: 200 },
  { id: "levelOfCareDesc", label: "Description", minWidth: 200 },
  { id: "menu", label: "", minWidth: 25 },
];

function LevelsofCareGrid() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [locId, setLocId] = useState(0);
  const [searched, setSearched] = useState<string>("");
  const [isLOCmodalOpen, setIsLOCmodalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [originalRows, setOriginalRows] = useState<LevelOfCare[]>();
  const [rows, setRows] = useState<LevelOfCare[]>();
  const [selectedLoc, setSelectedLoc] = useState<LevelOfCare>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModal, setIsEditModal] = useState(true);
  const paramId: number = -2;

  const dispatch = useDispatch<AppDispatch>();

  const state = {
    levelsOfCare: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.levelsOfCare
    ),
    isLoadingLevelsOfCareState: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading.isLoadingLevelsOfCare
    ),
    levelsOfCareLoadingStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext?.isLoading.getLevelsOfCareStatus
    ),
    vobClassificationsState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext?.vobClassifications
    ),
  };

  const { levelsOfCare, isLoadingLevelsOfCareState, levelsOfCareLoadingStatus, vobClassificationsState } = state;

  const requestSearch = (searchedVal: string) => {
    if (typeof searchedVal === 'string') {
      setSearched(searchedVal);
    }
    const filteredRows = originalRows?.filter((row) => {
      return (
        row.levelOfCareName.toLowerCase()?.includes(searchedVal?.toLowerCase()) ||
        row.levelOfCareDesc.toLowerCase()?.includes(searchedVal?.toLowerCase()) ||
        row.levelOfCareCode
          .toString()
          .toLowerCase()
          .includes(searchedVal?.toLowerCase())
      );
    });
    setRows(filteredRows);
    setPage(0);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  function handleLOCModalCancel() {
    setIsLOCmodalOpen(false);
  }

  async function handleLOCModalSubmit(isEditMode: boolean) {
    setIsLOCmodalOpen(false);

    if (isEditMode) {
      dispatch(showStatus("Level of Care Updated Successfully"));
      setIsLoading(true);
      await getLevelsOfCare();
    } else {
      dispatch(showStatus("Level of Care Added Successfully"));
      setIsLoading(true);
      await getLevelsOfCare();
    }
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  async function handleDeleteOk() {
    const deletePostBody: any = { ...selectedLoc, paramId: paramId };
    await dispatch(configDeleteLOC(deletePostBody));
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    await getLevelsOfCare();
  }

  const getLevelsOfCare = async () => {
    dispatch(configGetLOC(paramId));
  };

  const handleNewLocClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEditModal(false);
    setSelectedLoc({
      paramId: paramId,
      levelOfCareId: 0,
      levelOfCareCode: "",
      levelOfCareName: "",
      levelOfCareDesc: "",
      thirdPartyAverageInnLos: undefined,
      thirdPartyAverageInnPdr: undefined,
      thirdPartyAverageOnnLos: undefined,
      thirdPartyAverageOnnPdr: undefined,
      vobClassificationId: undefined,
      createUserId: "",
      createDt: "",
      lastUpdateUserId: "",
      lastUpdateDt: "",
      lastFunctionName: "",
    });
    setIsLOCmodalOpen(true);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    levelOfCareId: number
  ) => {
    setLocId(levelOfCareId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);
    setSelectedLoc(
      originalRows?.find(
        (el) => el.levelOfCareId.toString() === locId.toString()
      )
    );

    switch (menuActionType) {
      case "edit":
        setIsEditModal(true);
        setIsLOCmodalOpen(true);
        break;
      case "delete":
        setIsDeleteModalOpen(true);
        break;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getLevelsOfCare();
    dispatch(getVobClassifications(paramId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingLevelsOfCareState && levelsOfCareLoadingStatus === saveCallbackStatus.success) {
      const dataCopy:LevelOfCare[] = [...levelsOfCare];
      dataCopy.sort((a: LevelOfCare, b: LevelOfCare) => (a.levelOfCareCode > b.levelOfCareCode) ? 1 : -1);
      setIsLoading(false);
      setOriginalRows(dataCopy);
      setRows(dataCopy);
      dispatch(clearStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingLevelsOfCareState, levelsOfCareLoadingStatus]);

  return (
    <Paper>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <Box style={{ padding: "1em", marginBottom: "1.5em" }}>
          <Grid container justifyContent="space-between">
            <Grid>
              <Box style={{ padding: "1.5em" }}>
                <Typography variant="subtitle2">Levels of Care</Typography>
              </Box>
            </Grid>
            <Grid item>
              <TextField
                placeholder="Search by name or code"
                name="searched"
                value={searched}
                onChange={(event: any) => requestSearch(event?.target?.value)}
                onBlur={() => cancelSearch()}
                startAdornment={<SearchIcon />}
              />
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table
              data-testid="results"
              className="table alternating-row"
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {columns && columns?.map((column) => (
                    <TableCell
                      key={column?.id}
                      align={column?.align}
                      style={{
                        padding: "0.5em",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.slice!(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row: any) => (
                  <TableRow key={row?.levelOfCareId}>
                    <TableCell align="left" style={{ padding: "0.5em" }}>
                      {row?.levelOfCareCode}
                    </TableCell>
                    <TableCell align="left" style={{ padding: "0.5em" }}>
                      {row?.levelOfCareName}
                    </TableCell>
                    <TableCell align="left" style={{ padding: "0.5em" }}>
                      {row?.levelOfCareDesc}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleMenuClick(e, row.levelOfCareId);
                        }}
                        size="large">
                        <MoreHorizIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Grid container justifyContent="space-between">
              <Grid item>
                <IconButton
                  title="Add Level of Care"
                  color="primary"
                  onClick={(e) => handleNewLocClick(e)}
                  size="large">
                  <AddBoxOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <TablePagination
                  rowsPerPageOptions={[
                    10,
                    50,
                    100,
                    { value: rows?.length!, label: "All" },
                  ]}
                  component="div"
                  count={rows?.length!}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={"View"}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </TableContainer>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose()}
          >
            <MenuItem
              className="primary"
              onClick={() => handleMenuClose("edit")}
            >
              Edit
            </MenuItem>
            <MenuItem
              className="danger"
              onClick={() => handleMenuClose("delete")}
            >
              Delete
            </MenuItem>
          </Menu>
          {isLOCmodalOpen && (
            <LevelOfCareModal
              open={isLOCmodalOpen}
              isEdit={isEditModal}
              handleLevelOfCareModalCancel={handleLOCModalCancel}
              handleLevelOfCareModalSubmit={handleLOCModalSubmit}
              locItem={selectedLoc!}
              vobClassifications={vobClassificationsState}
            />
          )}
          {isDeleteModalOpen && (
            <DeleteModal
              open={isDeleteModalOpen}
              title="Delete Level of Care"
              subTitle="This action cannot be undone"
              okButtonText="Delete Forever"
              handleDeleteCancel={handleDeleteCancel}
              handleDeleteOk={handleDeleteOk}
            />
          )}
        </Box>
      )}
    </Paper>
  );
}

export default LevelsofCareGrid;
