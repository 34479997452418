import '../../scss/components/_facility-list-item.scss';

import { Button, IconLabel, LoadingOverlay, Toggle, TwoRowDetailsGrid } from '@finpay-development/shared-components';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  CompleteSetupModal,
} from '../../implementation-specialist/components/implementation-clients/details/facilities/complete-setup-modal';
import {
  ImplementationFacility,
} from '../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import { statusNames } from '../../implementation-specialist/misc/client-and-facility-status';
import {
  getClientFacility,
  getFacilityKPIs,
} from '../../implementation-specialist/state/facility/implementation-facility-thunk';
import AccessControl from '../../security/components/access-control';
import { RolePageNames } from '../../security/model/role-page-names';
import { showStatus } from '../../security/state/user-slice';
import { formatNumberToUSD } from '../../shared/calculators';
import CustomizeableAccordion from '../../shared/components/customizeable-accordion';
import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';

interface FacilityListItemProps {
  facilities: ImplementationFacility[]
}

function FacilityListItem(props: FacilityListItemProps) {
  const {
    facilities
  } = props;

  const [isFacilityModalOpen, setFacilityModalOpen] = useState(false);
  const [isCurrentDay, setIsCurrentDay] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const facilityKPIs = facilities[0]?.facilityKPIs
  const formRef: any = useRef();

  const stateFields = {
    isFacilityKPILoading: useSelector((state: RootState) => {
      return state.implementationContext.implementationFacility.isLoadingKPIs
    })
  }
  const { isFacilityKPILoading } = stateFields;

  const isFacilitySetUp = (facilities?.length > 0) &&
  ((facilities[0]?.workflow?.workflowStatus?.workflowStatusDesc === statusNames.ACTIVE) || (facilities[0]?.workflow?.workflowSubStatus?.workflowSubStatusDesc === statusNames.READY_FOR_ACTIVATION));

  const handleCompleteCancel = () => {
    setFacilityModalOpen(false);
  };

  const handleCompleteSubmit = () => {
    setFacilityModalOpen(false);
    dispatch(showStatus("Save successful"));
  };

  const handleCompleteSetupClick = () => {
    const firstFacility = facilities[0];
    if (firstFacility?.clientId && firstFacility?.facilityId) {
      dispatch(
        getClientFacility({
          clientId: firstFacility.clientId,
          facilityId: firstFacility.facilityId,
        })
      );
    }
    setFacilityModalOpen(true);
  };

  const fetchFacilityKPI = (facility: ImplementationFacility) => {
    if (facility?.clientId && facility?.facilityId) {
      dispatch(getFacilityKPIs({ facility: facility, months: (isCurrentDay ? 1 : 12) }))
    }
  }

  useEffect(() => { // on toggle switch, this function will fire.
    if (facilities[0]?.clientId && facilities[0]?.facilityId) {
      dispatch(getFacilityKPIs({ facility: facilities[0], months: (isCurrentDay ? 1 : 12) }))
    }
    // eslint-disable-next-line
  }, [dispatch, isCurrentDay])

  const validationSchema = Yup.object({
    isFilteredForToday: Yup.boolean(),
  })

  function checkIfIsValid(value: { isFilteredForToday: boolean }) {
    setIsCurrentDay(value.isFilteredForToday);
    validationSchema
      .validate(value)
  }

  const facilitiesGroup = (
    <>
      <Typography variant="h6">
        <Box fontWeight="fontWeightBold" className="pb-5">
          Associated Facilities
        </Box>
      </Typography>
      {facilities && (facilities?.length > 0) &&  facilities.map((facility: ImplementationFacility, index: number) => (
        <Box className="text-style icon-label-inline" key={index}>
          <IconLabel
            key={facility?.facilityId}
            icon={<LocationOnIcon />}
            label={facility?.facilityName}
          />
        </Box>
      ))}
    </>
  );

  const initialValues = {
    isFilteredForToday: true,
  }

  const facilityDetails = ( // todo - mock
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={checkIfIsValid}
        onSubmit={() => {}}
      >
        {(formik) => (
          <Form>
            <div className="mb-6">
              <Toggle
                leftText="Today"
                rightText="Last 12 Months"
                name="isFilteredForToday"
                formik={formik}
                value={formik.values.isFilteredForToday}
              />
            </div>
          </Form>
        )}
      </Formik>
      {isFacilityKPILoading ? (
        <LoadingOverlay
          whiteBackground
        />
      ) : (
        <>
          <Divider />
          <TwoRowDetailsGrid
            column1Title="Down Payments"
            column1Value={`${formatNumberToUSD(facilityKPIs?.downPmts)}`}
            column2Title="Transactions"
            column2Value={`${formatNumberToUSD(facilityKPIs?.transactions)}`}
          />
          <TwoRowDetailsGrid
            column1Title="Converted"
            column1Value={(`${facilityKPIs?.converted || 0}`)}
            column2Title="Full Payments"
            column2Value={(`${facilityKPIs?.fullPayments || 0}`)}
            column3Title="Paid Off"
            column3Value={(`${facilityKPIs?.paidOff || 0}`)}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <Paper className={"pt-4 px-4 facility-list-item"}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {facilitiesGroup}
          </Grid>
          <Grid item xs={4}>
            <div className={"Button-container"}>
              {!isFacilitySetUp && (
                <AccessControl rolePageName={RolePageNames.ClientPortal} actionName="Complete Setup"
                  renderNoAccess={() => <Button disabled>Complete Setup</Button>}>
                  <Button type="danger" onClick={handleCompleteSetupClick}>Complete Setup</Button>
                </AccessControl>
              )}
            </div>
          </Grid>
        </Grid>
        {isFacilityModalOpen && (
          <CompleteSetupModal
            open={isFacilityModalOpen}
            isClientOwner={true}
            facilityId={(facilities && (facilities?.length > 0) && (facilities[0].facilityId)) || 0}
            facilityName={(facilities && (facilities?.length > 0) && (facilities[0].facilityName)) || ''}
            handleCompleteCancel={handleCompleteCancel}
            handleCompleteSubmit={handleCompleteSubmit}
            showPrivacyText
          />
        )}
      </Paper>
      {isFacilitySetUp && (
        <CustomizeableAccordion
          details={
            <div className="w-100">
              {facilityDetails}
            </div>
          }
          expandCallback={() => fetchFacilityKPI(facilities[0])}
        />
      )}
    </>
  );
}

export default FacilityListItem;
