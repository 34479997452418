import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {CreateTokenCardData, StripeCardElementChangeEvent} from "@stripe/stripe-js";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStripeCardData} from "../../patient/state/patient-slice";
import {RootState} from "../../shared/state/root-reducer";
import { AppDispatch } from "../../shared/state/store";
import "../scss/_stripe-card-field.scss"

interface stripeCardFieldProps {
  succeeded?: boolean
  disabledCallback: (value: boolean) => void // let consuming component know whether to disable save button
  tokenCallback: (data: { token?: string, cardToken?: string, bankToken?: string }) => void // pass tokens to consuming component
  data?: CreateTokenCardData // data that can be passed along with the Stripe Element
  recurringPayment?: boolean
  recurringPaymentTokenCallback?: (data: { token?: string, cardToken?: string, bankToken?: string }) => void // pass tokens to consuming component
  clearStripeField?: (value: any) => void
}

const StripeCardField = (props: stripeCardFieldProps) => {

  // eslint-disable-next-line
  const [error, setError] = useState('');
  // eslint-disable-next-line
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    stripeCardData: useSelector(
      (state: RootState) => state.patientContext.stripeCardData
    )
  }

  const {stripeCardData} = stateFields;

  const stripe = useStripe();
  const elements = useElements();

  const {disabledCallback, tokenCallback, data, recurringPayment, recurringPaymentTokenCallback} = props;

  const stripeHandleChange = async (event: StripeCardElementChangeEvent) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details

    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
    // call parent and update its state.
    // Use in combination with formik to determine if save button should be emabled or not.
    disabledCallback(!!(event.empty || event.error));
    if (event.complete) {
      // save card tokens to the store
      stripeHandleDataChange();
    }
  };

  // This code is to clear the stripe field after payment is submitted. We will comment this out for now.
  // if(typeof props?.clearStripeField === 'function') props.clearStripeField(elements)

  const cardStyle = { // this is the only way you can pass styles into the Stripe Elements iframe.
    style: {
      border: '2px solid #afbecd',
      base: {
        color: "#32325d",
        fontFamily: 'Inter, "Helvetica Neue", sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "1.2rem",
        "::placeholder": {
          color: "#afbecd"
        }
      },
      invalid: {
        color: "#df1e44",
        iconColor: "#fa755a"
      }
    }
  };

  function stripeHandleDataChange() {
    const cardElement = elements && elements.getElement("card");

    if (cardElement) {
      stripe && stripe.createToken(cardElement, data && data)
        .then((payload) => {
          if (payload && payload.token && payload.token.card) {
            tokenCallback({token: payload.token.id, cardToken: payload.token.card.id})
          }
        });
      if (recurringPayment) {
        stripe && stripe.createToken(cardElement, data && data)
          .then((payload) => {
            if (payload && payload.token && payload.token.card) {
              if (recurringPaymentTokenCallback) {
                recurringPaymentTokenCallback({
                  token: payload.token.id,
                  cardToken: payload.token.card.id
                })
              }
            }
          });
      }
    }
  }

  useEffect(() => {
    if (!disabled && !error && data && (data !== {}) && (data?.name !== stripeCardData?.name)) {
      dispatch(setStripeCardData(data));
      stripeHandleDataChange();
    }
    // eslint-disable-next-line
  }, [dispatch, data, disabled, error])

  return (
    <div className="stripe">
      <div className="px-2 py-2 stripe-card-field" aria-autocomplete='none'>
        <CardElement
          id="cardElement"
          options={cardStyle}
          onChange={(e: StripeCardElementChangeEvent) => stripeHandleChange(e)}
        />
      </div>
    </div>
  )
}

export default StripeCardField;