import { createAsyncThunk } from '@reduxjs/toolkit';
import { admissionsAdvisorService } from '../services/admissions-advisor-services';
import { specialistClientService } from '../../implementation-specialist/services/implementation-client-service';
import { MultiFacilityRiskThreshold, MultiFacilityRiskClassSetting, ReferralSourceRevenue } from "../models/risk-assessment";
import { SelectedFacilityReferralSource } from "../../implementation-specialist/models/referral-source";
import { Utils } from "../../shared/utils";

export const getFacilityRiskThresholds = createAsyncThunk(
  "riskAssessmentContext/getFacilityRiskThresholds",
  async (data: {paramId: number, facilityId: number}, thunkAPI) => {
    const response = await admissionsAdvisorService.getFacilityRiskThresholds(data.paramId, data.facilityId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getMultiFacilityRiskThresholds = createAsyncThunk(
  "riskAssessmentContext/getMultiFacilityRiskThresholds",
  async (data: {paramId: number, facilityIds: number[]}, thunkAPI) => {
    
    let facilityRiskThresholds: MultiFacilityRiskThreshold[] = [];

    await Promise.all(
      data.facilityIds.map(async (facilityId: number): Promise<any> => {
        let response = await admissionsAdvisorService.getFacilityRiskThresholds(data.paramId, facilityId);
        if (response.hasErrors) {
          Utils.robustErrorHandler(response, thunkAPI);
        } else {
          facilityRiskThresholds.push(
            {
              clientFacilityId: facilityId,
              facilityRiskThresholds: response.entity
            })
        }
      })
    );
    return facilityRiskThresholds;
  }
);

export const getClientClaimRates = createAsyncThunk(
  "riskAssessmentContext/getClientClaimRates",
  async (data: {paramId: number, clientId: number}, thunkAPI) => {
    const response = await admissionsAdvisorService.getClientClaimRates(data.paramId, data.clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getClientRiskClassSettings = createAsyncThunk(
  "riskAssessmentContext/getClientRiskClassSettings",
  async (data: {paramId: number, clientId: number}, thunkAPI) => {
    const response = await admissionsAdvisorService.getClientRiskClassSettings(data.paramId, data.clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getFacilityRiskClassSettings = createAsyncThunk(
  "riskAssessmentContext/getFacilityRiskClassSettings",
  async (data: {paramId: number, facilityId: number}, thunkAPI) => {
    const response = await admissionsAdvisorService.getFacilityRiskClassSettings(data.paramId, data.facilityId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);

export const getMultiFacilityiskClassSettings = createAsyncThunk(
  "riskAssessmentContext/getMultiFacilityiskClassSettings",
  async (data: {paramId: number, facilityIds: number[]}, thunkAPI) => {

    let facilityRiskClassSettings: MultiFacilityRiskClassSetting[] = [];

    await Promise.all(
      data.facilityIds.map(async (facilityId: number): Promise<any> => {
        let response = await admissionsAdvisorService.getFacilityRiskClassSettings(data.paramId, facilityId);
        if (response.hasErrors) {
          Utils.robustErrorHandler(response, thunkAPI);
        } else {
          facilityRiskClassSettings.push(
            {
              clientFacilityId: facilityId,
              facilityRiskClassSettings: response.entity
            })
        }
      })
    );
    return facilityRiskClassSettings;
  }
);

export const getMultiFacilityReferralSources = createAsyncThunk(
  "riskAssessmentContext/getMultiFacilityReferralSources",
  async (data: {paramId: number, clientId: number, facilityIds: number[]}, thunkAPI) => {

    let facilityReferralSources: SelectedFacilityReferralSource[] = [];

    await Promise.all(
      data.facilityIds.map(async (facilityId: number): Promise<any> => {
        //let response = await admissionsAdvisorService.getFacilityReferralSourceRevenue(data.paramId, facilityId);
        const response = await specialistClientService.getReferralSources(facilityId, data.clientId);
        if (response.hasErrors) {
          Utils.robustErrorHandler(response, thunkAPI);
        } else {
          facilityReferralSources = facilityReferralSources.concat(response.entity)
        }
      })
    );
    return facilityReferralSources;
  }
);

export const getMultiFacilityReferralSourceRevenue = createAsyncThunk(
  "riskAssessmentContext/getMultiFacilityReferralSourceRevenue",
  async (data: {paramId: number, facilityIds: number[]}, thunkAPI) => {

    let facilityReferralSourceRevenue: ReferralSourceRevenue[] = [];

    await Promise.all(
      data.facilityIds.map(async (facilityId: number): Promise<any> => {
        let response = await admissionsAdvisorService.getFacilityReferralSourceRevenue(data.paramId, facilityId);
        if (response.hasErrors) {
          Utils.robustErrorHandler(response, thunkAPI);
        } else {
          facilityReferralSourceRevenue = facilityReferralSourceRevenue.concat(response.entity)
        }
      })
    );
    return facilityReferralSourceRevenue;
  }
);

export const getPatientEpisodeMarginSettings = createAsyncThunk(
  "riskAssessmentContext/getPatientEpisodeMarginSettings",
  async (data: {paramId: number, clientId: number}, thunkAPI) => {
    const response = await admissionsAdvisorService.getPatientEpisodeMarginSettings(data.paramId, data.clientId);
    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    } else {
      return response.entity;
    }
  }
);
