import "../../../../scss/pages/admin/_admin-clients.scss";

import {
  AlertModal,
  DialogActionButton,
  saveCallbackStatus,
  TextField,
  Toggle,
} from "@finpay-development/shared-components";
import { MenuItem, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import AddressValidator from "../../../../shared/components/address-validator";
import {
  stateAbbrevLookup,
  stateAbbrevs,
} from "../../../../shared/misc/us-states";
import { PrimaryAddress } from "../../../../shared/model/primary-address";
import {
  ContactIncome,
  PhoneType,
  PrimaryContact,
} from "../../../../shared/model/primary-contact";
import { emptyState, State } from "../../../../shared/model/state";
import { RootState } from "../../../../shared/state/root-reducer";
import { AppDispatch } from "../../../../shared/state/store";
import { Utils } from "../../../../shared/utils";
import {
  championInfo,
  championsToggle,
} from "../../../../shared/validation/schemas";
import { showErrorStatus } from "src/security/state/user-slice";
import {
  deletePatientPaymentMethod,
  getInstanceOfCare,
  savePatientChampion,
  updateIOC,
} from "../../../state/patient-thunk";
import { setAccountHolderContext } from "../../../state/patient-slice";
import { PatientChampion } from "../../models/patient-champion";
import { StripePaymentMethod } from "../../models/stripe-payment-method";
import { userService } from "src/admin/services/admin-user-service";
import { LoginVerifiedStatus } from "src/shared/enums";

interface ChampionModalProps {
  open: boolean;
  handleModalClose: () => void;
  championsIndex?: number;
  isEdit?: boolean;
}

export function ChampionModal(props: ChampionModalProps) {
  const { open, handleModalClose, championsIndex, isEdit } = props;

  const addressValidator = new AddressValidator();

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [includeChampions, setIncludeChampions] = useState(false);
  const [anotherGuarantorExists, setAnotherGuarantorExists] = useState(false);
  const [aGuarantorExists, setAGuarantorExists] = useState(false);
  const [showChampionWarning, setShowChampionWarning] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    selectedPatientEncounter: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter;
    }),
    selectedPatientId: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient.patientId;
    }),
    patientChampions: useSelector((state: RootState) => {
      return state.patientContext.selectedEncounter?.patientChampion;
    }),
    selectedPatientContact: useSelector((state: RootState) => {
      return state.patientContext.selectedPatient.contact;
    }),
    accountHolderContext: useSelector(
      (state: RootState) => state.patientContext?.accountHolderContext
    ),
  };
  const {
    selectedPatientEncounter,
    selectedPatientId,
    patientChampions,
    selectedPatientContact,
    accountHolderContext,
  } = stateFields;
  const isPreconverted = !selectedPatientEncounter.isConverted;

  const selectedChampion =
    championsIndex !== undefined &&
    selectedPatientEncounter?.patientChampion &&
    selectedPatientEncounter?.patientChampion.length > 0 &&
    selectedPatientEncounter?.patientChampion[championsIndex]
      ? selectedPatientEncounter.patientChampion[championsIndex]
      : ({} as PatientChampion);

  const guarantorRecurringPayment = useSelector((state: RootState) => {
    if (
      state.patientContext.selectedEncounter?.patientPaymentMethods?.length > 0
    ) {
      const recurringPayment =
        state.patientContext.selectedEncounter.patientPaymentMethods.find(
          (method) => {
            return (
              (method.metadata?.metaData_paymentMethodType?.includes(
                "RECURRING"
              ) ||
                method?.metadata?.isRecurring) &&
              method?.metadata?.metaData_payorId?.toString() ===
                selectedChampion?.patientChampionId?.toString()
            );
          }
        );
      return recurringPayment;
    } else {
      return {} as StripePaymentMethod;
    }
  });

  const doMultiplePatientChampionsExist =
    patientChampions && patientChampions.length > 1;
  const doesAPatientChampionExist =
    patientChampions &&
    patientChampions.length > 0 &&
    patientChampions[0].patientChampionId !== 0;

  useEffect(() => {
    if (patientChampions) {
      patientChampions.forEach((patientChampion) => {
        if (
          patientChampion.isGuarantor &&
          patientChampion?.patientChampionId !==
            selectedChampion?.patientChampionId
        ) {
          // a guarantor that is not the current champion exists. we can't have > 1 guarantor, so disable adding more than 1.
          setAnotherGuarantorExists(true);
        }
        if (patientChampion.isGuarantor) {
          setAGuarantorExists(true);
        }
      });
    }
  }, [patientChampions, selectedChampion?.patientChampionId]);

  useEffect(() => {
    if (open && doesAPatientChampionExist) {
      setIncludeChampions(true);
    }
  }, [open, championsIndex, doesAPatientChampionExist, includeChampions]);

  useEffect(() => {
    if (open && doMultiplePatientChampionsExist) {
      setIncludeChampions(true);
    }
  }, [open, championsIndex, doMultiplePatientChampionsExist, includeChampions]);

  if (!open && includeChampions) {
    setIncludeChampions(false); // on leave modal, we need to reset the toggle back to 'false' or else it will be sticky
  }

  // delete the guarantor's recurring payment method if we are setting them to NOT be a guarantor anymore and a guarantor payment method exists.
  const removeGuarantorsRecurringPaymentMethod = () => {
    setShowChampionWarning(false);

    if (guarantorRecurringPayment?.id) {
      const paymentId = guarantorRecurringPayment?.id || "";
      dispatch(
        deletePatientPaymentMethod({
          patientId: selectedPatientEncounter?.patientId,
          encounterId: selectedPatientEncounter?.patientEncounterId,
          stripeId: paymentId,
          paymentTypeId: 2, // recurring
        })
      );
    }
  };

  const mapToSaveModel = () => {
    const formValues = formRef.current.values;
    const stateInfo = stateAbbrevLookup(formValues.state);
    let state = {
      stateId: stateInfo.id,
      stateCode: formValues.state,
      stateName: stateInfo.name,
    } as State;

    if (state.stateCode === "-1") {
      state = emptyState;
    }

    const address: PrimaryAddress = {
      streetAddress1: formValues.streetAddress1,
      streetAddress2: formValues.streetAddress2,
      city: formValues.city,
      state: state,
      zipCode: formValues.zipCode,
      zipCode4: formValues.zipCode4Plus,
      addressType: "Primary",
    } as PrimaryAddress;

    const contactIncome = {
      yearlySalary: formValues.salary,
      employer: formValues.employerName,
      yearsAtEmployer: formValues.yearsAtEmployer,
      isEmployed: formValues.isCurrentlyEmployed,
    } as ContactIncome;

    const contact = {
      contactId: isEdit ? selectedChampion.contact?.contactId : undefined, // contactId will be blank upon initialization
      title: formValues.title,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      primaryAddress: address,
      externalId: isEdit ? selectedChampion.contact?.externalId : undefined,
      email: formValues.email,
      primPhoneNum: formValues.firstPhone,
      primPhoneType: formValues.isFirstPhoneMobile
        ? PhoneType.mobile
        : PhoneType.home,
      secPhoneNum: formValues.secondPhone,
      secPhoneType: formValues.isSecondPhoneMobile
        ? PhoneType.mobile
        : PhoneType.home,
      leaveVMFl: formValues.leaveVoicemail,
      smsConsentFl: formValues.receiveSMSMessages,
      ssn: !formValues.socialSecurityNumber?.includes("●")
        ? formValues.socialSecurityNumber
        : "",
      ssnLast4: !formValues.socialSecurityNumber?.includes("●")
        ? formValues.socialSecurityNumber.slice(-4)
        : "",
      birthDate:
        formValues.dateOfBirth &&
        Utils.convertDate(new Date(formValues.dateOfBirth)),

      contactIncome: contactIncome,
    } as PrimaryContact;

    return {
      patientChampionId: isEdit ? selectedChampion?.patientChampionId : 0,
      patientEncounterId: selectedPatientEncounter.patientEncounterId,
      contact: contact,
      relationshipToPatient: formValues.relationshipToPatient,
      isGuarantor: formValues.isGuarantor,
      phiDocumentStatus: formValues.phiDocumentStatus,
    } as PatientChampion;
  };

  const updateAccountHolder = async (
    contactInfo: PrimaryContact,
    isPatient: boolean
  ) => {
    const accountHolderContext = {
      userName: contactInfo.email,
      isPatient,
      firstName: contactInfo.firstName,
      lastName: contactInfo.lastName,
    };

    if (!contactInfo.email) {
      dispatch(
        setAccountHolderContext({
          ...accountHolderContext,
          isExistingEmail: null,
          userId: null,
        })
      );
      return;
    }

    const response = await userService.getAllUsers(0, 1000, {
      userName: contactInfo.email,
    });

    const { entity } = response;
    const isExistingEmail = entity.length >= 1;
    const userId = entity[0]?.userId || null;

    const data = {
      ...accountHolderContext,
      isExistingEmail,
      userId,
    };

    if (isExistingEmail) {
      const result = await userService.sendVerificationEmail(
        userId,
        selectedPatientEncounter.patientEncounterId
      );
      if (result.hasErrors) {
        dispatch(showErrorStatus(`Email failed to send. Please resend`));
      }
      await dispatch(updateIOC({ loginVerified: LoginVerifiedStatus.pending }));
      dispatch(setAccountHolderContext(data));
    } else {
      await dispatch(
        updateIOC({ loginVerified: LoginVerifiedStatus.verified })
      );
      dispatch(setAccountHolderContext(data));
    }
  };

  async function handleSave() {
    if (includeChampions && formRef.current.values.streetAddress1 !== "") {
      await getZipCodePlus4();
    }
    if (includeChampions || doesAPatientChampionExist) {
      const championToSave = mapToSaveModel(); // we need to POST a champion to the API
      await dispatch(
        savePatientChampion({
          champion: championToSave,
          patientId: selectedPatientId,
          encounterId: selectedPatientEncounter.patientEncounterId,
        })
      );
      if (championToSave.isGuarantor) {
        await updateAccountHolder(championToSave.contact, false);
      } else {
        // choosing a champion, but not a guarantor
        // if no account holder, update, but if there is, don't update (assume email was sent)
        if (!accountHolderContext.userName)
          await updateAccountHolder(selectedPatientContact, true);
      }

      // need to re-fetch this encounter to update the Redux hasChampions field.
      if (!selectedPatientEncounter.hasChampions) {
        await dispatch(
          getInstanceOfCare({
            patientId: selectedPatientId,
            encounterId: selectedPatientEncounter.patientEncounterId,
          })
        );
      }
    } else {
      // didn't choose a champion
      await updateAccountHolder(selectedPatientContact, true);

      // need to update just the IOC since the champion has not been modified
      await dispatch(
        updateIOC({
          hasChampions: doesAPatientChampionExist || includeChampions,
        })
      );
    }

    handleModalClose();
  }

  function handleSaveCallback() {
    handleModalClose();
  }

  // don't change guarantor. set toggle back to true state.
  const handleWarningModalCancelCallback = () => {
    formRef?.current?.setFieldValue("isGuarantor", true);
    setShowChampionWarning(false);
  };

  const initialValues = {
    includePatientChampions: false,
    title: "-1",
    firstName: "",
    middleName: "",
    lastName: "",
    relationshipToPatient: "-1",
    email: "",
    retypeEmail: "",
    firstPhone: "",
    isFirstPhoneMobile: true,
    secondPhone: "",
    isSecondPhoneMobile: false,
    leaveVoicemail: false,
    receiveSMSMessages: false,
    isGuarantor: false,
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "-1",
    zipCode: "",
    zipCodePlus4: "",
    dateOfBirth: "",
    socialSecurityNumber: "",
    isCurrentlyEmployed: false,
    salary: "",
    employerName: "",
    yearsAtEmployer: "",
    phiDocumentStatus: true,
  };

  const initialValuesEdit = {
    includePatientChampions: true,
    title: selectedChampion?.contact?.title
      ? selectedChampion.contact.title
      : "-1",
    firstName: selectedChampion?.contact?.firstName,
    middleName: selectedChampion?.contact?.middleName || "",
    lastName: selectedChampion?.contact?.lastName,
    relationshipToPatient: selectedChampion?.relationshipToPatient
      ? selectedChampion.relationshipToPatient
      : "-1",
    email: selectedChampion?.contact?.email,
    retypeEmail: selectedChampion?.contact?.email,
    firstPhone: selectedChampion?.contact?.primPhoneNum,
    isFirstPhoneMobile:
      selectedChampion?.contact?.primPhoneType === PhoneType.mobile,
    secondPhone: selectedChampion?.contact?.secPhoneNum,
    isSecondPhoneMobile:
      selectedChampion?.contact?.secPhoneType === PhoneType.mobile,
    leaveVoicemail: selectedChampion?.contact?.leaveVMFl || false,
    receiveSMSMessages: selectedChampion?.contact?.smsConsentFl || false,
    isGuarantor: selectedChampion?.isGuarantor,
    streetAddress1: selectedChampion?.contact?.primaryAddress?.streetAddress1 || '',
    streetAddress2: selectedChampion?.contact?.primaryAddress?.streetAddress2 || '',
    city: selectedChampion?.contact?.primaryAddress?.city || '',
    state: selectedChampion?.contact?.primaryAddress?.state?.stateCode
      ? selectedChampion?.contact?.primaryAddress?.state?.stateCode
      : "-1",
    zipCode: selectedChampion?.contact?.primaryAddress?.zipCode || '',
    zipCodePlus4: selectedChampion?.contact?.primaryAddress?.zipCode4 || '',
    dateOfBirth: selectedChampion?.contact?.birthDate,
    socialSecurityNumber: `${
      selectedChampion?.contact?.ssnLast4
        ? "●●●-●●-" + selectedChampion?.contact?.ssnLast4
        : ""
    }`,
    isCurrentlyEmployed: selectedChampion?.contact?.contactIncome?.isEmployed
      ? selectedChampion?.contact?.contactIncome?.isEmployed
      : false,
    salary: selectedChampion?.contact?.contactIncome?.yearlySalary
      ? selectedChampion?.contact?.contactIncome?.yearlySalary
      : "",
    employerName: selectedChampion?.contact?.contactIncome?.employer
      ? selectedChampion?.contact?.contactIncome?.employer
      : "",
    yearsAtEmployer: selectedChampion?.contact?.contactIncome?.yearsAtEmployer
      ? selectedChampion?.contact?.contactIncome?.yearsAtEmployer
      : "",
    phiDocumentStatus: selectedChampion?.phiDocumentStatus,
  };

  const getValidationSchema = () => {
    if (!includeChampions) {
      return championsToggle;
    } else {
      return {
        ...championInfo,
      };
    }
  };

  let validationSchema = Yup.object(getValidationSchema());

  useEffect(() => {
    // eslint-disable-next-line
    validationSchema = Yup.object(getValidationSchema());
  }, [
    dispatch,
    includeChampions,
    doesAPatientChampionExist,
    formRef?.current?.values,
  ]);

  const titles = ["Mr.", "Mrs.", "Ms.", "Dr."];
  const relationshipToPatientValues = [
    "Spouse",
    "Partner",
    "Parent",
    "Grand Parent",
    "Relative",
    "Guardian",
    "Friend",
    "Other",
  ];

  // warn that the guarnator has a recurring payment and their payment method will be deleted if the user clicks 'ok'.
  const showWarningModal = () => {
    if (
      formRef?.current?.values?.isGuarantor &&
      guarantorRecurringPayment?.id
    ) {
      setShowChampionWarning(true);
    }
  };

  async function getZipCodePlus4() {
    formRef.current.values.zipCode4Plus =
      await addressValidator.getZipCodePlus4(formRef.current.values);
  }

  function checkIfIsValid(value: any) {
    validationSchema = Yup.object(getValidationSchema());
    validationSchema
      .validate(value)
      .then(() => {
        if (
          !value.isGuarantor ||
          Utils.isValidDateTextField(value?.dateOfBirth)
        )
          setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  useEffect(() => {
    checkIfIsValid(formRef?.current?.values);
    // eslint-disable-next-line
  }, [includeChampions, formRef?.current?.values, open]);

  // @ts-ignore
  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      test-id="patient-champion-modal"
    >
      <DialogTitle>
        <span className="title">
          {isEdit ? "Edit Patient Champion" : "Patient Champion"}
        </span>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize={true}
          innerRef={formRef}
          initialValues={isEdit ? initialValuesEdit : initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                {championsIndex === 0 &&
                  !isEdit && ( // if we are index 0 of champions and not editing, we want to keep showing the toggle.
                    <>
                      <Grid xs={12} item className="ml-2">
                        <Typography variant="h4" style={{ marginBottom: -12 }}>
                          Include Patient Champions
                        </Typography>
                      </Grid>
                      <Grid xs={12} item>
                        <Toggle
                          name="includePatientChampions"
                          value={includeChampions}
                          formik={formik}
                          onChange={() =>
                            setIncludeChampions(!includeChampions)
                          }
                        />
                      </Grid>
                    </>
                  )}
                {(includeChampions || doesAPatientChampionExist) && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Champion Details
                      </Typography>
                    </Grid>
                    <Grid xs={3} item>
                      <TextField
                        select={true}
                        error={
                          formik.touched["title"] && formik.errors["title"]
                        }
                        label="Title"
                        name="title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={false}
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {titles.map((title: string) => (
                          <MenuItem key={title} value={title}>
                            {title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={3} item>
                      <TextField
                        error={
                          formik.touched["firstName"] &&
                          formik.errors["firstName"]
                        }
                        label="First Name"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={2} item>
                      <TextField
                        error={
                          formik.touched["middleName"] &&
                          formik.errors["middleName"]
                        }
                        label="M.I."
                        maxLength={2}
                        name="middleName"
                        value={formik.values.middleName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={false}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <TextField
                        error={
                          formik.touched["lastName"] &&
                          formik.errors["lastName"]
                        }
                        label="Last Name"
                        name="lastName"
                        placeholder="Enter Last Name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        select={true}
                        error={
                          formik.touched["relationshipToPatient"] &&
                          formik.errors["relationshipToPatient"]
                        }
                        label="Relationship To Patient"
                        name="relationshipToPatient"
                        value={formik.values.relationshipToPatient}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <MenuItem value="-1">
                          Select Relationship to Patient
                        </MenuItem>
                        {relationshipToPatientValues.map(
                          (relationship: string) => (
                            <MenuItem key={relationship} value={relationship}>
                              {relationship}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography variant="h4">Add to PHI Consent</Typography>
                      <Toggle
                        leftText="Included"
                        rightText="Excluded"
                        name="phiDocumentStatus"
                        value={formik.values.phiDocumentStatus}
                        formik={formik}
                      />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Contact Information
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["email"] && formik.errors["email"]
                        }
                        label="Email"
                        name="email"
                        placeholder="Enter Email Address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["retypeEmail"] &&
                          formik.errors["retypeEmail"]
                        }
                        label="Retype Email"
                        name="retypeEmail"
                        placeholder="Re-Enter Email Address"
                        value={formik.values.retypeEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["firstPhone"] &&
                          formik.errors["firstPhone"]
                        }
                        label="Primary Phone"
                        name="firstPhone"
                        placeholder="Enter Phone Number"
                        value={formik.values.firstPhone}
                        onChange={(e: Event) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "firstPhone",
                            Utils.formatPhoneNumber(
                              (e.target as HTMLInputElement).value
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                        maxLength={12}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Toggle
                        leftText="Mobile"
                        rightText="Home"
                        name="isFirstPhoneMobile"
                        value={formik.values.isFirstPhoneMobile}
                        formik={formik}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["secondPhone"] &&
                          formik.errors["secondPhone"]
                        }
                        label="Secondary Phone"
                        name="secondPhone"
                        placeholder="Enter Phone Number"
                        value={formik.values.secondPhone}
                        onChange={(e: Event) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "secondPhone",
                            Utils.formatPhoneNumber(
                              (e.target as HTMLInputElement).value
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                        required={false}
                        maxLength={12}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <Toggle
                        leftText="Mobile"
                        rightText="Home"
                        name="isSecondPhoneMobile"
                        value={formik.values.isSecondPhoneMobile}
                        formik={formik}
                      />
                    </Grid>
                    <Grid xs={3} item style={{ marginBottom: -12 }}>
                      <Typography variant="h4">Leave Voicemail</Typography>
                    </Grid>
                    <Grid xs={9} item style={{ marginBottom: -12 }}>
                      <Typography variant="h4">Receive SMS Messages</Typography>
                    </Grid>
                    <Grid xs={3} item>
                      <Toggle
                        name="leaveVoicemail"
                        value={formik.values.leaveVoicemail}
                        formik={formik}
                      />
                    </Grid>
                    <Grid xs={9} item>
                      <Toggle
                        name="receiveSMSMessages"
                        value={formik.values.receiveSMSMessages}
                        formik={formik}
                      />
                    </Grid>

                    {isPreconverted && (
                      <>
                        <Grid xs={12} item style={{ marginBottom: -12 }}>
                          <Typography variant="h4">Guarantor</Typography>
                        </Grid>
                        <Grid xs={12} item>
                          <Toggle
                            onClick={() => showWarningModal()}
                            /* if we're editing we need to check if a different champion is the guarantor (excluding the currently selected champion).
                              But if we are adding then we need to check ALL champions to see if any of them are a guarantor. */
                            disabled={
                              isEdit ? anotherGuarantorExists : aGuarantorExists
                            }
                            name="isGuarantor"
                            value={formik.values.isGuarantor}
                            formik={formik}
                          />
                        </Grid>
                        <Grid xs={12} item style={{ marginTop: -12 }}>
                          <Typography variant="h4">
                            Guarantor is the party responsible for payments and
                            will be assigned the role of account holder
                          </Typography>
                        </Grid>
                      </>
                    )}

                    <Grid xs={12} item>
                      <Typography variant="subtitle2">Address</Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        error={
                          formik.touched["streetAddress1"] &&
                          formik.errors["streetAddress1"]
                        }
                        label="Street Address 1"
                        name="streetAddress1"
                        minLength={3}
                        placeholder="Enter Street Address"
                        value={formik.values.streetAddress1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={formik?.values?.isGuarantor === true}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <TextField
                        error={
                          formik.touched["streetAddress2"] &&
                          formik.errors["streetAddress2"]
                        }
                        label="Street Address 2"
                        name="streetAddress2"
                        minLength={3}
                        value={formik.values.streetAddress2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={formik?.values?.isGuarantor === true}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={formik.touched["city"] && formik.errors["city"]}
                        label="City"
                        name="city"
                        placeholder="Enter City"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={formik?.values?.isGuarantor === true}
                      />
                    </Grid>
                    <Grid xs={3} item>
                      <TextField
                        select={true}
                        error={
                          formik.touched["state"] && formik.errors["state"]
                        }
                        label="State"
                        name="state"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={formik?.values?.isGuarantor === true}
                        className="state-field"
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {stateAbbrevs.map((state: string) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid xs={3} item>
                      <TextField
                        error={
                          formik.touched["zipCode"] && formik.errors["zipCode"]
                        }
                        label="Zip Code"
                        minLength={5}
                        maxLength={5}
                        name="zipCode"
                        placeholder="Enter Zip Code"
                        value={formik.values.zipCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required={formik?.values?.isGuarantor === true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Personal Information
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="date"
                        label="Date Of Birth"
                        error={
                          formik.touched["dateOfBirth"] &&
                          formik.errors["dateOfBirth"]
                        }
                        placeholder="Date Range"
                        name="dateOfBirth"
                        value={formik.values.dateOfBirth}
                        maxLength={10}
                        onChange={(e: Event) => {
                          const updatedDate = Utils.dateYearValidate(e);
                          formik.handleChange(e);
                          formik.setFieldValue("dateOfBirth", updatedDate);
                        }}
                        onBlur={(e: Event) => {
                          const updatedDate = Utils.formatDate(
                            (e.target as HTMLInputElement).value
                          );
                          formik.handleBlur(e);
                          formik.setFieldValue("dateOfBirth", updatedDate);
                        }}
                        startAdornment={<SearchIcon />}
                        required={formik.values.isGuarantor}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        error={
                          formik.touched["socialSecurityNumber"] &&
                          formik.errors["socialSecurityNumber"]
                        }
                        label="Social Security Number"
                        maxLength={11}
                        name="socialSecurityNumber"
                        placeholder="Enter Social Security Number"
                        value={formik.values.socialSecurityNumber}
                        onChange={(e: Event) => {
                          formik.handleChange(e);
                          formik.setFieldValue(
                            "socialSecurityNumber",
                            Utils.formatSSN(
                              (e.target as HTMLInputElement).value
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                        required={false}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Typography variant="subtitle2">
                        Employment Information
                      </Typography>
                    </Grid>
                    <Grid xs={12} item className="ml-2">
                      <Typography variant="h4" style={{ marginBottom: -12 }}>
                        Currently Employed
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Toggle
                        name="isCurrentlyEmployed"
                        value={formik.values.isCurrentlyEmployed}
                        formik={formik}
                      />
                    </Grid>
                    {formik.values.isCurrentlyEmployed && (
                      <>
                        <Grid xs={12} item>
                          <TextField
                            error={
                              formik.touched["salary"] &&
                              formik.errors["salary"]
                            }
                            label="Salary"
                            placeholder="Enter Salary"
                            name="salary"
                            value={formik.values.salary}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid xs={6} item>
                          <TextField
                            error={
                              formik.touched["employerName"] &&
                              formik.errors["employerName"]
                            }
                            label="Employer Name"
                            name="employerName"
                            value={formik.values.employerName}
                            placeholder="Enter Employer Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                        <Grid xs={6} item>
                          <TextField
                            error={
                              formik.touched["yearsAtEmployer"] &&
                              formik.errors["yearsAtEmployer"]
                            }
                            label="Years At Employer"
                            name="yearsAtEmployer"
                            value={formik.values.yearsAtEmployer}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={
            includeChampions || doesAPatientChampionExist
              ? enableSaveButton
              : true
          }
          savebuttonText="Save"
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
      {showChampionWarning && (
        <AlertModal
          isOpen={showChampionWarning}
          handleSaveCallback={removeGuarantorsRecurringPaymentMethod}
          handleCloseCallback={handleWarningModalCancelCallback}
          titleText="Alert"
          contentText="This Guarantor has a recurring payment. This recurring payment will be removed as a result of changing their Guarantor status."
        />
      )}
    </Dialog>
  );
}
