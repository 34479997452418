import {useStripe} from "@stripe/react-stripe-js";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStripeBankData} from "../../patient/state/patient-slice";
import {RootState} from "../../shared/state/root-reducer";
import {StripeBankData} from "../models/stripe-bank-data";
import "../scss/_stripe-card-field.scss"
import _ from "lodash";
import { AppDispatch } from "../../shared/state/store";

interface stripeBankFieldProps {
  tokenCallback: (data: { token?: string, cardToken?: string, bankToken?: string }) => void // pass tokens to consuming component
  bankData?: StripeBankData,
  recurringPayment?: boolean,
  recurringPaymentTokenCallback?: (data: { token?: string, cardToken?: string, bankToken?: string }) => void // pass tokens to consuming component
}

const StripeBankField = (props: stripeBankFieldProps) => {
  const {tokenCallback, bankData, recurringPayment, recurringPaymentTokenCallback} = props;

  const stateFields = {
    stripeBankData: useSelector(
      (state: RootState) => state.patientContext.stripeBankData
    )
  }

  const {stripeBankData} = stateFields;

  const stripe = useStripe();
  const dispatch = useDispatch<AppDispatch>();

  function stripeHandleDataChange() {
    if (bankData
      && (bankData?.bank_name?.length > 1) && (bankData?.account_number?.length >= 6) && !!bankData?.account_number
      && (bankData?.routing_number?.length >= 9) && bankData?.account_holder_name) {
      stripe && stripe.createToken('bank_account', {
        ...bankData,
      }).then((payload) => {
        if (payload && payload?.token && payload.token?.id) {
          tokenCallback({bankToken: payload?.token?.id})
        }
      });
      if (recurringPayment) {
        stripe && stripe.createToken('bank_account', {
          ...bankData,
        }).then((payload) => {
          if (payload && payload?.token && payload.token?.id) {
            if (recurringPaymentTokenCallback) {
              recurringPaymentTokenCallback({bankToken: payload?.token?.id})
            }
          }
        });
      }
    }
  }

  useEffect(() => {
    if (bankData && (!(_.isEqual(stripeBankData, bankData)))) {
      dispatch(setStripeBankData(bankData))
      stripeHandleDataChange();
    }
    // eslint-disable-next-line
  }, [dispatch, bankData?.account_number, bankData?.routing_number, bankData?.account_holder_name, bankData?.bank_name])

  return (
    <>
      { /* We don't want to actually serve a UI. We just want to call Stripe. */}
    </>
  )
}

export default StripeBankField;
