export interface CRM {
    clientCrmId?: number;
    clientId?: number;
    clientFacilityId?: number;
    crmTypeId?: number;
    isIntEnabled?: boolean;
    isPolledInt?: boolean;
    isEventInt?: boolean;
    apiUrl?: string;
    apiUserName?: string;
    apiPassword?: string;
    preadmitTypeId?: number|string;
    admitTypeId?: number|string;
    isAdmitIntEnabled?: boolean;
    isPolledAdmitInt?: boolean;
    isEventAdmitInt?: boolean;
    apiFinpayId?: string;
    apiFinpayToken?: string;
    oauthGrantType?: number | string;
    oauthToken?: string
    authUrl?: string;
    accessTokenUrl?: string | null;
    tokenExpireDt?: Date
}

export const emptyCRM: CRM = {
    clientCrmId: 0,
    clientId: 0,
    clientFacilityId: 0,
    crmTypeId: 0,
    isIntEnabled: false,
    isPolledInt: false,
    isEventInt: false,
    apiUrl: "",
    apiUserName: "",
    apiPassword: "",
    preadmitTypeId: 0,
    admitTypeId: 0,
    isAdmitIntEnabled: false,
    isPolledAdmitInt: false,
    isEventAdmitInt: false,
    apiFinpayId: "",
    apiFinpayToken: "",
    oauthGrantType: "",
    oauthToken: "",
    authUrl: "",
    accessTokenUrl: "",
    tokenExpireDt: new Date(),
}

export interface CRMForm {
    clientCrmId?: number;
    clientId?: number;
    clientFacilityId?: number;
    crmTypeId?: number;
    isIntEnabled?: boolean;
    isPolledInt?: boolean;
    isEventInt?: boolean;
    apiUrl?: string;
    apiUserName?: string;
    apiPassword?: string;
    preadmitTypeId?: number|string;
    admitTypeId?: number|string;
    isAdmitIntEnabled?: boolean;
    isPolledAdmitInt?: boolean;
    isEventAdmitInt?: boolean;
    apiFinpayId?: string;
    apiFinpayToken?: string;
    oauthGrantType?: number | string;
    oauthToken?: string;
    authUrl?: string;
    accessTokenUrl?: string;
    tokenExpireDt?: Date
}

export const emptyCRMForm: CRMForm = {
    clientCrmId: 0,
    clientId: 0,
    clientFacilityId: 0,
    crmTypeId: 0,
    isIntEnabled: false,
    isPolledInt: false,
    isEventInt: false,
    apiUrl: "",
    apiUserName: "",
    apiPassword: "",
    preadmitTypeId: 0,
    admitTypeId: 0,
    isAdmitIntEnabled: false,
    isPolledAdmitInt: false,
    isEventAdmitInt: false,
    apiFinpayId: "",
    apiFinpayToken: "",
    oauthGrantType: "",
    oauthToken: "",
    authUrl: "",
    accessTokenUrl: "",
    tokenExpireDt: new Date(),
}

export interface CRMDetails {
    clientCrmId: number;
    clientId: number;
    clientFacilityId: number;
    crmTypeId: number;
    isIntEnabled: boolean;
    isPolledInt: boolean;
    isEventInt: boolean;
    apiUrl: string;
    apiUserName: string;
    apiPassword: string;
    preadmitTypeId: number|string;
    admitTypeId: number|string;
    isAdmitIntEnabled: boolean;
    isPolledAdmitInt: boolean;
    isEventAdmitInt: boolean;
    apiFinpayId: string;
    apiFinpayToken: string;
    oauthGrantType: number | string;
    oauthToken: string
    authUrl: string;
    accessTokenUrl: string;
    tokenExpireDt: Date
}


export const emptyCRMDetails: CRMDetails = {
    clientCrmId: 0,
    clientId: 0,
    clientFacilityId: 0,
    crmTypeId: 0,
    isIntEnabled: false,
    isPolledInt: false,
    isEventInt: false,
    apiUrl: "",
    apiUserName: "",
    apiPassword: "",
    preadmitTypeId: 0,
    admitTypeId: 0,
    isAdmitIntEnabled: false,
    isPolledAdmitInt: false,
    isEventAdmitInt: false,
    apiFinpayId: "",
    apiFinpayToken: "",
    oauthGrantType: "",
    oauthToken: "",
    authUrl: "",
    accessTokenUrl: "",
    tokenExpireDt: new Date(),
}