import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { Grid, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { vobClassificationsItem } from '../../../admissions-advisor/models/vob';
import { showErrorStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { EditlevelOfCare, LevelOfCare } from '../../models/level-of-care';
import { clearStatus } from '../../state/admin-configuration-slice';
import { configNewLOC, configSaveLOC } from '../../state/admin-configuration-thunk';

interface LevelOfCareModalProps {
  open: boolean;
  isEdit: boolean;
  locItem: LevelOfCare;
  vobClassifications: vobClassificationsItem[];
  handleLevelOfCareModalCancel: () => void;
  handleLevelOfCareModalSubmit: (isEdit: boolean) => void;
}

export function LevelOfCareModal(props: LevelOfCareModalProps) {
  const {
    open,
    isEdit,
    locItem,
    vobClassifications,
    handleLevelOfCareModalCancel,
    handleLevelOfCareModalSubmit,
  } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();
  const paramId: number = -2;

  const initialValues: EditlevelOfCare = {
    paramId: paramId,
    levelOfCareId: locItem.levelOfCareId,
    levelOfCareCode:
      locItem.levelOfCareCode === "" ? "" : locItem.levelOfCareCode,
    levelOfCareName: locItem.levelOfCareName,
    levelOfCareDesc: locItem.levelOfCareDesc,
    thirdPartyAverageInnLos: locItem.thirdPartyAverageInnLos,
    thirdPartyAverageInnPdr: locItem.thirdPartyAverageInnPdr,
    thirdPartyAverageOnnLos: locItem.thirdPartyAverageOnnLos,
    thirdPartyAverageOnnPdr: locItem.thirdPartyAverageOnnPdr,
    vobClassificationId: locItem.vobClassificationId
  };

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const isSaveSuccessful = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.isSaveSuccessful
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.errorMessage
  );

  async function handleSave() {
    const formValues: any = formRef.current.values;
    if (isEdit) {
      await dispatch(configSaveLOC(formValues));
    } else {
      await dispatch(configNewLOC(formValues));
    }
  }

  function handleSaveCallback() {
    if (isSaveSuccessful) {
      handleLevelOfCareModalSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleLevelOfCareModalCancel();
  }

  const validationSchema = Yup.object({
    levelOfCareCode: Yup.string().required("Code is required"),
    levelOfCareName: Yup.string()
      .min(3, "Name should be of minimum 3 characters length")
      .required("Name is required"),
    levelOfCareDesc: Yup.string()
      .min(3, "Description should be of minimum 3 characters length")
      .required("Description is required"),
    thirdPartyAverageInnLos: Yup.number().required("In Network Length of Stay is required"),
    thirdPartyAverageInnPdr: Yup.number().required("In Network Perdiem Rate is required"),
    thirdPartyAverageOnnLos: Yup.number().required("Out of Network Length of Stay is required"),
    thirdPartyAverageOnnPdr: Yup.number().required("Out of Network Perdiem Rate is required"),
    vobClassificationId: Yup.number().required("Vob Classification is required"),
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Level of Care</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formRef}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>

                <Grid item xs={2} className="mt-4">
                  <TextField
                    label="Code"
                    type="text"
                    value={formik.values.levelOfCareCode}
                    name="levelOfCareCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={5} className="mt-4">
                  <TextField
                    label="Name"
                    name="levelOfCareName"
                    value={formik.values.levelOfCareName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={5} className="mt-4">
                  <TextField
                    label="Description"
                    name="levelOfCareDesc"
                    value={formik.values.levelOfCareDesc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={3} className="mt-4">
                  <TextField
                    label="Avg In-net Los"
                    name="thirdPartyAverageInnLos"
                    value={
                      formik.values.thirdPartyAverageInnLos ? formik.values.thirdPartyAverageInnLos : null
                    }
                    error={
                      formik.touched["thirdPartyAverageInnLos"] &&
                      formik.errors["thirdPartyAverageInnLos"]
                        ? formik.errors["thirdPartyAverageInnLos"]
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={3} className="mt-4">
                  <TextField
                    label="Avg In-net Rate"
                    name="thirdPartyAverageInnPdr"
                    value={
                      formik.values.thirdPartyAverageInnPdr ? formik.values.thirdPartyAverageInnPdr : null
                    }
                    error={
                      formik.touched["thirdPartyAverageInnPdr"] &&
                      formik.errors["thirdPartyAverageInnPdr"]
                        ? formik.errors["thirdPartyAverageInnPdr"]
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={3} className="mt-4">
                  <TextField
                    label="Avg Out-net Los"
                    name="thirdPartyAverageOnnLos"
                    value={
                      formik.values.thirdPartyAverageOnnLos ? formik.values.thirdPartyAverageOnnLos : null
                    }
                    error={
                      formik.touched["thirdPartyAverageOnnLos"] &&
                      formik.errors["thirdPartyAverageOnnLos"]
                        ? formik.errors["thirdPartyAverageOnnLos"]
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={3} className="mt-4">
                  <TextField
                    label="Avg Out-net Rate"
                    name="thirdPartyAverageOnnPdr"
                    value={
                      formik.values.thirdPartyAverageOnnPdr ? formik.values.thirdPartyAverageOnnPdr : null
                    }
                    error={
                      formik.touched["thirdPartyAverageOnnPdr"] &&
                      formik.errors["thirdPartyAverageOnnPdr"]
                        ? formik.errors["thirdPartyAverageOnnPdr"]
                        : ""
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <TextField
                    select
                    label="Vob Classification"
                    name="vobClassificationId"
                    value={formik.values.vobClassificationId}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.vobClassificationId &&
                      formik.errors.vobClassificationId
                    }
                  >
                    {vobClassifications &&
                      [...vobClassifications]
                        ?.sort((a, b) => (a.vobClassificationName > b.vobClassificationName ? 1 : -1))
                        .map((vobClassification: vobClassificationsItem) => (
                          <MenuItem key={vobClassification.vobClassificationId} value={vobClassification.vobClassificationId}>
                            {vobClassification.vobClassificationName}
                          </MenuItem>
                        ))
                      }
                  </TextField>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
