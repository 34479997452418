import { Button, DeleteModal, LoadingOverlay } from '@finpay-development/shared-components';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PatientEncounter } from '../../../patient/components/models/patient-encounter';
import { PAYMENT_TYPES } from '../../../patient/components/models/payment-type';
import { StripePaymentMethod } from '../../../patient/components/models/stripe-payment-method';
import {
  RecurringPaymentModal,
} from '../../../patient/components/patient-drilldown/payment-programs/recurring-payment-modal';
import { deletePatientPaymentMethod, getPaymentMethods } from '../../../patient/state/patient-thunk';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';

interface paymentSourcesAccordionContentsProps {
  selectedEncounter: PatientEncounter,
  isCompleteOrCancelled?: boolean
}

const PaymentSourcesAccordionContents = (props: paymentSourcesAccordionContentsProps) => {
  const { selectedEncounter, isCompleteOrCancelled } = props;
  const dispatch = useDispatch<AppDispatch>();

  const recurringPayments =
    selectedEncounter?.patientPaymentMethods?.length > 0
      ? selectedEncounter?.patientPaymentMethods.filter(
          (paymentMethod: StripePaymentMethod) =>
            paymentMethod?.metadata.metaData_paymentMethodType?.includes("RECURRING") || paymentMethod?.metadata?.isRecurring
        )
      : ([] as StripePaymentMethod[]);

  const stateFields = {
    isRecurringPaymentLoading: useSelector((state: RootState) => {
      return state.patientContext.isLoading.paymentMethods
    }),
    isRecurringPaymentFetchError: useSelector((state: RootState) => {
      return state.patientContext.isError.paymentMethods
    })
  }
  const {
    isRecurringPaymentLoading, isRecurringPaymentFetchError
  } = stateFields;

  const handleRefreshPaymentMethods = () => {
    dispatch(getPaymentMethods({
      patientId: selectedEncounter?.patientId,
      encounterId: selectedEncounter?.patientEncounterId,
      isAccountHolder: true,
      supressErrors: true
    }));
  }

  const isOnePaymentMethodLeft = !!(recurringPayments?.length === 1);

  const [isRecurringPaymentModalOpen, setIsRecurringPaymentModalOpen] = useState(false);
  const [isRecurringPaymentDeleteModalOpen, setIsRecurringPaymentDeleteModalOpen] = useState(false)
  const [recurringPaymentId, setRecurringPaymentId] = useState('');

  const handleRecurringPaymentModalOpen = (isOpen: boolean) => {
    setIsRecurringPaymentModalOpen(isOpen);
  }

  function handleDeleteRecurringPayment() {
    if (recurringPaymentId) {
      dispatch(deletePatientPaymentMethod({
        patientId: selectedEncounter?.patientId,
        encounterId: selectedEncounter?.patientEncounterId,
        stripeId: recurringPaymentId,
        paymentTypeId: PAYMENT_TYPES.RECURRING.paymentTypeId,
        isAccountHolder: true
      }))
    }
    setIsRecurringPaymentDeleteModalOpen(false);
  }

  const recurringPaymentOccurenceItem = (payment: StripePaymentMethod) => (
    <>
      <Grid container spacing={2} className="mx-1 pr-2 px-1 mt-0_5 w-100"> {/* todo horizontal overflow */}
        <>
          <Grid item xs={1}>
            {(payment?.object === "card") ? (<CreditCardIcon className="gray-icon" />) : (<AccountBalanceIcon className="gray-icon"/>)}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">
              {`${payment?.name || 'N/A'}`}
            </Typography>
          </Grid>
        </>
        <Grid item xs={2} >
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h4" color="error">
              {payment?.status === 'expired' ? 'Expired' : ''}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h4">
            {payment?.object === "card" ? `●●●● ●●●● ●●●● ` : `●●●●●●●● `}
            {`${payment?.last4}`}
          </Typography>
        </Grid>
        {!isCompleteOrCancelled && (
          <Grid item xs={1} style={{marginTop: -12, maxHeight: 10}} className="pr-3">
            <>
              <IconButton
                aria-haspopup="true"
                onClick={(e) => {
                  setRecurringPaymentId(payment?.id?.toString())
                  setIsRecurringPaymentDeleteModalOpen(true)
                }}
                size="large">
                <DeleteIcon color={"primary"}/>
              </IconButton>
            </>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </>
  );

  const recurringPaymentList = (recurringPayment: StripePaymentMethod) => {
    return (
      <>
        <Grid
          container
          spacing={2}
          className="px-2 pt-2"
        >
          {recurringPaymentOccurenceItem(
            recurringPayment
          )}
        </Grid>
      </>
    )
  }

  const accordionContents = (
    <>
      <Grid item xs={12} className="mt-2">
        <Divider />
      </Grid>
      {isRecurringPaymentFetchError ? (
          <>
            <Grid item xs={10}>
              <Typography variant="h4">
                Payment Methods Pending. Use Refresh button to update.
              </ Typography>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
                <IconButton onClick={() => handleRefreshPaymentMethods()} size="large">
                  <RefreshIcon className="icon" />
                </IconButton>
              </ Box>
            </Grid>
          </>
        ) : (
          (recurringPayments && recurringPayments?.length > 0) ? recurringPayments?.map((recurringPayment: StripePaymentMethod, index: number) => (
            <div className="w-100" key={index}>
              {recurringPaymentList(recurringPayment)}
            </div>
          )) : (
            (!isRecurringPaymentLoading && !(recurringPayments && recurringPayments?.length > 0)) ? (
              <Typography variant="h4">
                No Payment Methods found.
              </ Typography>
            ) : (
            isRecurringPaymentLoading && (
              <Grid item xs={12}>
                <LoadingOverlay whiteBackground />
              </Grid>
              )
            )
          )
        )
      }
      {(!isCompleteOrCancelled) && (
        <Button
          type="text"
          icon={<AddIcon />}
          paddingLeft={0}
          onClick={() => handleRecurringPaymentModalOpen(true)}
        >
          Additional Payment Source
        </Button>
      )}
    </>
  )

  return (
    <div className="px-2 list-header">
      <Grid
        container
        justifyContent="space-between"
        spacing={2}
        className="pb-2"
      >
        {accordionContents}
      </Grid>
      {isRecurringPaymentDeleteModalOpen && (
        <DeleteModal
          open={isRecurringPaymentDeleteModalOpen}
          title="Delete Payment Method"
          subTitle={isOnePaymentMethodLeft ?
            "This is your last payment method. If you remove it without adding a new payment method, then your account may go into default."
            : "This action cannot be undone" }
          okButtonText="Delete"
          handleDeleteCancel={() => setIsRecurringPaymentDeleteModalOpen(false)}
          handleDeleteOk={handleDeleteRecurringPayment}
        />
      )}
      {isRecurringPaymentModalOpen && (
        <RecurringPaymentModal
          isAccountHolder
          open={isRecurringPaymentModalOpen}
          handleModalClose={() => handleRecurringPaymentModalOpen(false)}
        />
      )}
    </div>
  );
}

export default PaymentSourcesAccordionContents;
