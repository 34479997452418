import React from 'react';

function ClientView() {
  return (
    <main className="no-padding">
      <div className="content fullwidth">
          Client View
      </div>
    </main>
  );
}
export default ClientView;