export interface ReportsInfo {
    filters: DetailedReportFilters;
    headers: Array<ReportHeaders>;
    data: Array<Array<string>>;
    isInitialCall?:boolean
}


export interface DetailedReportFilters{
    reportType: string,
    clients?: Array<ReportClients>
    startDate: string|null,
    endDate: string|null,
    admissionDate: string,
    dischargeDate: string,
    patientName?: any,
    accountHolderName?: any,
    collabId?: any,
    planType?: any,
    patientFinancialStatus?: any,
    payorRiskIds?: any,
    timingRiskIds?: any,
    paymentWorkflowStatusIds?: any,
    paymentWorkflowSubStatusIds?: any,
    paymentType?: any,
    isInitialCall?:boolean,
    groupTransactionNumber?: string
    clientName?: string,
    facilityName?: string
}

interface ReportHeaders{
    columnName: string,
    dataType: string
}

export interface ReportClients{
    clientId: number,
    clientName: string,
    facilities: Array<ReportFacilities>
}

interface ReportFacilities {
    facilityId: number,
    facilityName: string
}

export interface ReportFilter{
    clientsAndFacilities?: any;
    reportType: string,
    startDate: string | null,
    endDate: string | null,
    admissionDate: string,
    dischargeDate: string,
    patientName: string,
    clientId?: number[],
    facilityId?: number[],
    includeFacilityTotals?: boolean
}

const emptyReportFilters: DetailedReportFilters = {
    reportType: "",
    startDate: null,
    endDate: null,
    admissionDate: "",
    dischargeDate: "",
    patientName: "",
    clientName: "",
    facilityName: ""
}
export const emptyReportInfo: ReportsInfo = {
    filters: emptyReportFilters,
    headers:[],
    data:[]
}

export const emptyReportFilter: ReportFilter = {
    reportType: "-1",
    startDate: null,
    endDate: null,
    admissionDate: "",
    dischargeDate: "",
    patientName: "",
    clientId: [],
    facilityId: [],
    includeFacilityTotals: false
}
