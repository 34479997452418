import axios from "axios";
import { configSettings } from "../configuration/config-settings";
import { AxiosReadPayload } from "./axios-read-payload";
import { AxiosResultStatus } from "./axios-result-status";

// Function to check if a string is a valid base64 format
function isBase64(str: string) {
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return base64Regex.test(str);
}

 export const axiosDownloadFileHelper = async function(payload: AxiosReadPayload): Promise<AxiosResultStatus> {

  const downloadResult = new AxiosResultStatus();

  // Build URL for Getting Data
  let url: string = "";
  if (payload.dataId === 0) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
  } else if (payload.dataId > 0) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}/${payload.dataId}`;
  } else if (payload.dataId === null || payload.dataId === undefined) {
    url = `${configSettings.unified_aws_app_gateway_uri}/${payload.url}`;
  }

  // Execute Download Get And Handle Errors
  await axios({
    url: url,
    method: 'GET',
    headers: payload.customHeader,
  }).then((response) => {
    if(isBase64(response.data)){
      response.data = Buffer.from(response.data, 'base64');
    }
    downloadResult.entity = response;
  })
  .catch((error: Error) => {
    downloadResult.errorMessage = error.message;
  })

  downloadResult.hasErrors = downloadResult.errorMessage?.length > 0;
  return downloadResult;
 }

