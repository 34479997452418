import {StatusColors} from "../../../shared/enums";

export const statusNames = {
  NEW: "New",
  CONVERTED: "Converted",
  CONVERTED_PENDING: "Converted, Pending",
  AWAITING_AUTHORIZATIONS: "Awaiting authorizations",
  AWAITING_ACH_VERIFICATION: "Awaiting ACH Verification",
  CLOSED: "Closed",
  PATIENT_NEVER_ADMITTED: "Patient never admitted",
  PATIENT_FAILED_TO_ENGAGE: "Patient failed to engage",
  COMPLETE: "Complete",
  FOLLOW_UP_NEEDED: "Follow Up Needed",
  EDUCATIONAL_CURRICULUM: "Educational curriculum",
  PAYMENT_CURRICULUM: "Payment curriculum",
  AWAITING_CONSENT_DOCS: "Awaiting consent docs",
  PATIENT_CHAMPION: "Patient champion",
  AWAITING_HRE_APPROVAL: "Awaiting hre approval",
  PAYMENT_METHODS_NEEDED: "Payment methods needed",
  NO_CONTACT_MAKE_2ND_ATTEMPT: "No contact, make 2nd attempt",
  NO_CONTACT_MAKE_3RD_ATTEMPT: "No contact, make 3rd attempt",
  AWAITING_CLIENT: "Awaiting client",
  CONVERTED_PENDING_AUTHORIZATION: "Converted, pending authorization",
  ERROR_BAD_FILE: "Error (bad file)",
  HRE_REJECTED_BY_PATIENT: "HRE rejected by patient",
  HRE_REJECTED_BY_CLIENT: "HRE rejected by client",
  GOOD_STANDING: 'Good Standing',
  AT_RISK: 'At Risk',
  MISSED_PAYMENT: 'Missed Payment',
  HAS_PAST_DUE_AMOUNT: 'Has Past Due Amount',
  ON_HOLD: 'On Hold',
  USER_SELECTABLE: 'User Selectable',
  PAID: 'Paid',
  DEFAULT: 'Default',
  CANCELED: 'Canceled',
  NO_STATUS: 'No Status',
  HRE_APPROVED: 'HRE Approved',
  FINPAY_FAILED_TO_ENGAGE: "FinPay Failed to Engage",
  DUPLICATE_RECORD: "Duplicate Record",
  PATIENT_COVERED_AT_100_PERCENT: "Patient Covered at 100%",
  HRE_APPROVED_PAYMENT_AT_FACILITY_PAYMENT_CONFIRMED: "HRE Approved by Client for Payment at Facility, Payment Confirmed",
  HRE_APPROVED_FULL_SCHOLARSHIP: "HRE Approved by Client for Full Scholarship",
  HRE_APPROVED_PAYMENT_AT_FACILITY_PAYMENT_CONFIRMATION_REQUIRED: "HRE Approved by Client for Payment at Facility, Payment Confirmation Required",
  HRE_APPROVED_DIRECT_BILL_FOLLOW_UP: "HRE Approved by Client for Direct Bill Follow Up",
  INSURANCE_PAID_IN_FULL: "Insurance Paid in Full",
  ACCOUNT_HOLDER_PAID_BALANCE_OWED: "Account Holder Paid Balance Owed",
  ACCOUNT_HOLDER_PAID_BALANCE_OWED_DUE_REFUND: "Account Holder Paid Balance Owed and is Due a Refund",
  REVOKED_PAYMENT_PLAN: "Revoked Payment Plan",
  PROVIDERS_REQUEST: "Provider's Request",
  REFINANCED: "Refinanced",
  HRE_APPROVED_DIRECT_BILL_PAYMENT_MADE_TO_FACILITY: "HRE Approved by Client for Direct Bill, Payment Made to Facility",
  HRE_APPROVED_PAYMENT_AT_FACILITY: "HRE Approved by Client for Payment at Facility",
  MISSING_FUNDING_SOURCE: 'Missing Funding Source',
}

export const patientFinancialClearanceStatus = { // when using these, do .toLowerCase for comparisons.
  NEW_POTENTIAL_PATIENT: 'new potential patient - not contacted',
  AWAITING_AUTHORIZATIONS: 'awaiting authorizations',
  AWAITING_ACH_VERIFICATION: 'awaiting ach verification',
  CONVERTED: 'converted',
  UNQUALIFIED: 'unqualified',
  PATIENT_NEVER_ADMITTED: 'patient never admitted',
  PATIENT_FAILED_TO_ENGAGE: 'patient failed to engage',
}

interface PatientStatus {
  workflowId: WorkflowId;
  statusId: number | null; // null id for now is used as "NEW" until we get proper data
  statusDesc: string;
  type: StatusType;
  color: StatusColors;
}

export enum WorkflowId {
  "Preconverted" = 2,
  "Converted" = 3,
  "Financial Clearance" = 4
}

export enum StatusType {
  "primary-status",
  "sub-status"
}

const blankPatientStatus = {
  workflowId: -1,
  statusId: -1,
  statusDesc: "",
  type: StatusType["sub-status"],
  color: StatusColors.gray
}



export const patientStatus: PatientStatus[] = [
  // ##### PRECONVERTED STATUSES

  // primary-statuses
  {
    workflowId: WorkflowId.Preconverted,
    statusId: null, // For now think of null as the same as NEW
    statusDesc: statusNames.NEW,
    type: StatusType["primary-status"],
    color: StatusColors.blue,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 13,
    statusDesc: statusNames.NEW,
    type: StatusType["primary-status"],
    color: StatusColors.blue,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 14,
    statusDesc: statusNames.FOLLOW_UP_NEEDED,
    type: StatusType["primary-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 15,
    statusDesc: statusNames.CLOSED,
    type: StatusType["primary-status"],
    color: StatusColors.error
  },


  // sub-statuses
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 8,
    statusDesc: statusNames.EDUCATIONAL_CURRICULUM,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 9,
    statusDesc: statusNames.USER_SELECTABLE,
    type: StatusType["sub-status"],
    color: StatusColors.blue,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 10,
    statusDesc: statusNames.PAYMENT_CURRICULUM,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 11,
    statusDesc: statusNames.AWAITING_CONSENT_DOCS,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 12,
    statusDesc: statusNames.PATIENT_CHAMPION,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },

  {
    workflowId: WorkflowId.Preconverted,
    statusId: 13,
    statusDesc: statusNames.AWAITING_HRE_APPROVAL,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 14,
    statusDesc: statusNames.PAYMENT_METHODS_NEEDED,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 15,
    statusDesc: statusNames.NO_CONTACT_MAKE_2ND_ATTEMPT,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 16,
    statusDesc: statusNames.NO_CONTACT_MAKE_3RD_ATTEMPT,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 17,
    statusDesc: statusNames.AWAITING_CLIENT,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 18,
    statusDesc: statusNames.ERROR_BAD_FILE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 19,
    statusDesc: statusNames.PATIENT_FAILED_TO_ENGAGE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 20,
    statusDesc: statusNames.HRE_REJECTED_BY_PATIENT,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 21,
    statusDesc: statusNames.HRE_REJECTED_BY_CLIENT,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 22,
    statusDesc: statusNames.PATIENT_NEVER_ADMITTED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 39,
    statusDesc: statusNames.NO_STATUS,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 40,
    statusDesc: statusNames.HRE_APPROVED,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },

  {
    workflowId: WorkflowId.Preconverted,
    statusId: 41,
    statusDesc: statusNames.FINPAY_FAILED_TO_ENGAGE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 42,
    statusDesc: statusNames.DUPLICATE_RECORD,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 43,
    statusDesc: statusNames.PATIENT_COVERED_AT_100_PERCENT,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 44,
    statusDesc: statusNames.HRE_APPROVED_PAYMENT_AT_FACILITY_PAYMENT_CONFIRMED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 45,
    statusDesc: statusNames.HRE_APPROVED_FULL_SCHOLARSHIP,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 46,
    statusDesc: statusNames.HRE_APPROVED_PAYMENT_AT_FACILITY_PAYMENT_CONFIRMATION_REQUIRED,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 47,
    statusDesc: statusNames.HRE_APPROVED_DIRECT_BILL_FOLLOW_UP,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 62,
    statusDesc: statusNames.CLOSED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 63,
    statusDesc: statusNames.FOLLOW_UP_NEEDED,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 64,
    statusDesc: statusNames.CLOSED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 65,
    statusDesc: statusNames.FOLLOW_UP_NEEDED,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 67,
    statusDesc: statusNames.FOLLOW_UP_NEEDED,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },
  {
    workflowId: WorkflowId.Preconverted,
    statusId: 66,
    statusDesc: statusNames.NEW,
    type: StatusType["sub-status"],
    color: StatusColors.blue,
  },

  // ##### CONVERTED STATUSES

  // primary-statuses
  {
    workflowId: WorkflowId.Converted,
    statusId: 6,
    statusDesc: statusNames.FOLLOW_UP_NEEDED,
    type: StatusType["primary-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 8,
    statusDesc: statusNames.GOOD_STANDING,
    type: StatusType["primary-status"],
    color: StatusColors.success
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 12,
    statusDesc: statusNames.CONVERTED,
    type: StatusType["primary-status"],
    color: StatusColors.success
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 10,
    statusDesc: statusNames.AT_RISK,
    type: StatusType["primary-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 7,
    statusDesc: statusNames.CLOSED,
    type: StatusType["primary-status"],
    color: StatusColors.error
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 16,
    statusDesc: statusNames.PAID,
    type: StatusType["primary-status"],
    color: StatusColors.purple,
  },

  // sub-statuses
  {
    workflowId: WorkflowId.Converted,
    statusId: 25,
    statusDesc: statusNames.MISSED_PAYMENT,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 24,
    statusDesc: statusNames.HAS_PAST_DUE_AMOUNT,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 26,
    statusDesc: statusNames.ON_HOLD,
    type: StatusType["sub-status"],
    color: StatusColors.error
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 30,
    statusDesc: statusNames.CONVERTED_PENDING_AUTHORIZATION,
    type: StatusType["sub-status"],
    color: StatusColors.warning,
  },

  {
    workflowId: WorkflowId.Converted,
    statusId: 27,
    statusDesc: statusNames.ERROR_BAD_FILE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 28,
    statusDesc: statusNames.PATIENT_FAILED_TO_ENGAGE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 29,
    statusDesc: statusNames.PATIENT_NEVER_ADMITTED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 37,
    statusDesc: statusNames.DEFAULT,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 38,
    statusDesc: statusNames.CANCELED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 48,
    statusDesc: statusNames.FINPAY_FAILED_TO_ENGAGE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 49,
    statusDesc: statusNames.DUPLICATE_RECORD,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 50,
    statusDesc: statusNames.INSURANCE_PAID_IN_FULL,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 51,
    statusDesc: statusNames.ACCOUNT_HOLDER_PAID_BALANCE_OWED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 52,
    statusDesc: statusNames.ACCOUNT_HOLDER_PAID_BALANCE_OWED_DUE_REFUND,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 53,
    statusDesc: statusNames.REVOKED_PAYMENT_PLAN,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 54,
    statusDesc: statusNames.PROVIDERS_REQUEST,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 55,
    statusDesc: statusNames.REFINANCED,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 56,
    statusDesc: statusNames.HRE_APPROVED_DIRECT_BILL_PAYMENT_MADE_TO_FACILITY,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 57,
    statusDesc: statusNames.HRE_APPROVED_PAYMENT_AT_FACILITY,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 58,
    statusDesc: statusNames.HRE_APPROVED_FULL_SCHOLARSHIP,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  {
    workflowId: WorkflowId.Converted,
    statusId: 59,
    statusDesc: statusNames.MISSING_FUNDING_SOURCE,
    type: StatusType["sub-status"],
    color: StatusColors.error,
  },
  // ##### FINANCIAL CLEARANCE STATUSES
  {
    workflowId: WorkflowId["Financial Clearance"],
    statusId: 18,
    statusDesc: statusNames.CONVERTED,
    type: StatusType["primary-status"],
    color: StatusColors.success
  },
  {
    workflowId: WorkflowId["Financial Clearance"],
    statusId: 17,
    statusDesc: statusNames.CONVERTED_PENDING,
    type: StatusType["primary-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId["Converted"],
    statusId: 17,
    statusDesc: statusNames.CONVERTED_PENDING,
    type: StatusType["primary-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId["Financial Clearance"],
    statusId: 34,
    statusDesc: statusNames.AWAITING_ACH_VERIFICATION,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId["Converted"],
    statusId: 34,
    statusDesc: statusNames.AWAITING_ACH_VERIFICATION,
    type: StatusType["sub-status"],
    color: StatusColors.warning
  },
  {
    workflowId: WorkflowId["Financial Clearance"],
    statusId: 33,
    statusDesc: statusNames.AWAITING_AUTHORIZATIONS,
    type: StatusType["sub-status"],
    color: StatusColors.orange
  },
]

export const getPatientStatusByStatusId = (statusId: number, statusType: StatusType = StatusType["primary-status"], workflowId: WorkflowId) => {
  let foundPatientStatus;
  foundPatientStatus = patientStatus.find((patientStatus) => patientStatus.statusId === statusId && patientStatus.type === statusType && patientStatus.workflowId === workflowId);
  if (foundPatientStatus) {
    return foundPatientStatus;
  }
  return blankPatientStatus; // TS complains of a potentially undefined result unless we fall back on this blank object
}

export const getFinancialStatusColor = (financialStatusText: string) => {
  let color = StatusColors.gray;
  if (financialStatusText.toLowerCase() === patientFinancialClearanceStatus.CONVERTED) {
    color = StatusColors.success
  } else if (financialStatusText.toLowerCase().includes(patientFinancialClearanceStatus.AWAITING_ACH_VERIFICATION)) {
    color = StatusColors.warning
  } else if (financialStatusText.toLowerCase().includes(patientFinancialClearanceStatus.AWAITING_AUTHORIZATIONS)) {
    color = StatusColors.warning
  } else if (financialStatusText.toLowerCase() === patientFinancialClearanceStatus.NEW_POTENTIAL_PATIENT) {
    color = StatusColors.blue
  } else if (financialStatusText.toLowerCase() === patientFinancialClearanceStatus.UNQUALIFIED) {
    color = StatusColors.error
  } else if (financialStatusText.toLowerCase() === patientFinancialClearanceStatus.PATIENT_NEVER_ADMITTED) {
    color = StatusColors.error
  } else if (financialStatusText.toLowerCase() === patientFinancialClearanceStatus.PATIENT_FAILED_TO_ENGAGE) {
    color = StatusColors.error
  } else {
    color = StatusColors.blue
  }
  return color;
}
