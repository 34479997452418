import {
  emptyPrimaryContact,
  PrimaryContact,
} from "../../../shared/model/primary-contact";
import { emptyPatientEncounter, PatientEncounter } from "./patient-encounter";

export interface PatientViewModel {
    patientId: number;
    contact: PrimaryContact;
    patientMasterId: number;
    patientStatus: string;
    patientStatusDt: string;
    patientEncounterId: number;
    clientId?: string;
    advisorPatientId?: number;
    workflowSubStatus?: {
        subStatusName: string;
    };
    patientEncounters: PatientEncounter[];
}

interface BaseAPIRequiredContactInfo {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  brithDate?: string | null | undefined;
}

export interface BaseAPIRequiredPatient {
  patientId: number;
  contact: BaseAPIRequiredContactInfo;
}

export const emptyPatientViewModel: PatientViewModel = {
  patientId: 0,
  contact: emptyPrimaryContact,
  patientMasterId: 0,
  patientStatus: "",
  patientStatusDt: "",
  patientEncounterId: 0,
  clientId: "",
  workflowSubStatus: {
    subStatusName: "",
  },
  patientEncounters: [emptyPatientEncounter],
};
