import { Button } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplayIcon from '@mui/icons-material/Replay';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from '../../../../../security/model/role-page-names';
import { AppDispatch } from '../../../../../shared/state/store';
import { resetRules, setCurrentFacility } from '../../../../state/rules-engine/rules-engine-slice';
import { applyCommonRuleSet, clearRules, getFacilityRules } from '../../../../state/rules-engine/rules-engine-thunk';
import { ImplementationFacility } from '../models/implementation-facility';
import RuleSet from './rule-set';
import {
  getPaymentMetadata
} from '../../../../../meta-data/state/meta-data-thunk';
import {RootState} from '../../../../../shared/state/root-reducer';

interface riskRulesAccordionProps {
  facility: ImplementationFacility;
  setRuleModal: (booleanVal: boolean) => void;
}

const RiskRulesAccordion = (props: riskRulesAccordionProps) => {
  const { facility, setRuleModal } = props;

  const {ruleParamsMetadata} = {
    ruleParamsMetadata: useSelector(
        (state: RootState) => state.metaData.ruleParams
    )}

  const dispatch = useDispatch<AppDispatch>();

  const handleAddClick = async () => {
    dispatch(setCurrentFacility(facility));
    dispatch(resetRules());
    if(ruleParamsMetadata.length ===0) await dispatch(getPaymentMetadata(["ruleParams"]))
    setRuleModal(true);
  }

  const handleApplyCommonRuleSet = () => {
    dispatch(applyCommonRuleSet(facility));
  }

  const handleClearRules = () => {
    dispatch(clearRules(facility));
  }

  const handleRiskRulesFacilityAccordionClick = () => {
    if (facility.rulesGrouped === undefined) {
      dispatch(getFacilityRules({facility: facility}));
    }
  }

  return <>
    <Accordion key={facility.facilityId} elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="facility-details" onClick={() => handleRiskRulesFacilityAccordionClick()}>
        <Typography variant="body1">{facility.facilityName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item>
            {(facility.rulesGrouped?.length === 0) &&
              <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Rule"
                renderNoAccess={() => <Button disabled>Apply Common Rule Set</Button>}>
                <Button
                  type="text"
                  onClick={handleApplyCommonRuleSet}
                  icon={<AddIcon />}
                  marginLeft={0}
                  paddingLeft={0}
                >
                  Apply Common Rule Set
                </Button>
              </AccessControl>
            }
            {facility.rulesGrouped?.length > 0 &&
              <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Rule"
                renderNoAccess={() => <Button disabled>Clear Rules</Button>}>
                <Button
                  type="text"
                  onClick={handleClearRules}
                  icon={<ReplayIcon />}
                  marginLeft={0}
                  paddingLeft={0}
                >
                  Clear Rules
                </Button>
              </AccessControl>
            }
          </Grid>
          <Grid item>
            <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Rule"
              renderNoAccess={() => <Button disabled>Add New Rule</Button>}>
              <Button type="secondary" onClick={async() => await handleAddClick()}>Add New Rule</Button>
            </AccessControl>
          </Grid>
          <Grid item xs={12}>
            {facility.rulesGrouped?.length > 0 && (
              <div>
                {facility.rulesGrouped.map((rulesInGroup, index: number) => (
                  <Box mt={2}>
                    <RuleSet key={index} rulesInGroup={rulesInGroup} ruleSetIndex={index} facility={facility} />
                  </Box>
                ))}
              </div>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  </>;
}

export default RiskRulesAccordion
