import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from "@mui/material";

export const theme = createMuiTheme(adaptV4Theme({
  palette: {
    primary: {
      main: "#297ec1",
    },
    secondary: {
      main: "#505f79",
    },
    error: {
      main: "#df1e44",
    },
    warning: {
      main: "#f2db00",
    },
    info: {
      main: "#7356cc",
    },
    success: {
      main: "#38b079",
    },
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "2.4rem",
      lineHeight: "2.9rem",
      fontWeight: 600,
      color: "#000000",
    },
    h2: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "2rem",
      lineHeight: "2.4rem",
      fontWeight: 500,
      color: "#000000",
    },
    h3: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
      fontWeight: 500,
      color: "#505f79",
    },
    h4: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      color: "#505f79",
    },
    subtitle1: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "2rem",
      lineHeight: "2.4rem",
      fontWeight: 500,
      color: "#000000",
      marginBottom: "1rem",
    },
    subtitle2: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
      fontWeight: 500,
      color: "#505f79",
      marginBottom: "1rem",
    },
    body1: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "1.6rem",
      lineHeight: "2.2rem",
      color: "#000000",
    },
    body2: {
      fontFamily: 'Inter, "Helvetica Neue", sans-serif',
      fontSize: "1.4rem",
      lineHeight: "2rem",
      color: "#505f79",
    },
  },
  props: {
    MuiButton: {
      variant: "contained",
      disableRipple: true,
    },
    MuiTextField: {
      variant: "outlined",
      InputLabelProps: {
        shrink: true,
        required: false,
      },
    },
  },
}));
