import './login.scss';

import {
  Button,
  PasswordField,
  TextField,
} from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../shared/state/root-reducer';
import { AppDispatch } from '../../shared/state/store';
import { ChangePasswordState } from '../model/change-password-state';
import { initPasswordRules, PasswordFieldState, RulesCheck } from '../model/password-rule-state';
import { validatePassword } from '../services/validate-password';
import {
  updatePassword,
  updatePasswordWithVerificationCode,
  updatePasswordWithVerificationCodev2,
} from '../state/user-thunk';
import PasswordRules from './password-rules';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  setPathName,
  setShowLogin,
} from '../../admin/state/users/admin-slice';

interface UpdatePasswordViewProps {
  type: "create" | "forget";
}

function UpdatePasswordView(props: UpdatePasswordViewProps) {
  const [enabledPasswordSave, setEnabledPasswordSave] = useState(false);
  const [passwordRules, setPasswordRules] = useState(initPasswordRules);
  const [email, setEmail] = useState<null|string>(null);
  const [verificationCode, setVerificationCode] = useState<null|string>(null);

  const initialValues: PasswordFieldState = {
    newPassword: "",
    confirmPassword: "",
    verificationCode: verificationCode || "",
  };

  const userContextClearForm = useSelector(
    (state: RootState) => state.userContext.clearForm
  );

  // Hook for calling <Formik>
  const formRef: any = useRef();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setEnabledPasswordSave(false);
    setPasswordRules(initPasswordRules);
    formRef.current?.resetForm();
  }, [userContextClearForm]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get('email');
    const verCode = queryParams.get('code');

    if(props.type === 'forget' && userEmail && verCode){
      setEmail(decodeURIComponent(userEmail))
      setVerificationCode(verCode)
    }
  }, [dispatch, location, props.type]);


  function handleSubmit(values: PasswordFieldState) {
    if (props.type === "forget") {
      const changePassword = new ChangePasswordState();
      changePassword.newPassword = values.newPassword;
      changePassword.verificationCode = values.verificationCode;
      if(email && verificationCode){
        changePassword.email = email;
        dispatch(updatePasswordWithVerificationCodev2(changePassword))
        dispatch(setShowLogin(true))
        dispatch(setPathName(""))
        navigate('/')
      }else{
        dispatch(updatePasswordWithVerificationCode(changePassword));
      }
    } else {
      dispatch(updatePassword(values.newPassword));
    }
  }

  function validateForm(values: PasswordFieldState) {
    let error;

    validatePassword(values).then(function (rulesCheckResult: RulesCheck) {
      if (props.type === "forget") {
        if (values.verificationCode.length > 0) {
          setEnabledPasswordSave(rulesCheckResult.isValid);
        } else {
          setEnabledPasswordSave(false);
        }
      } else {
        setEnabledPasswordSave(rulesCheckResult.isValid);
      }

      setPasswordRules(rulesCheckResult.rules);
    });

    return error;
  }

  return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
            validate={validateForm}
        >
          {({ handleChange, handleBlur, values }) => (
              <Form>
                <Grid
                    container
                    direction="column"
                    alignContent="center"
                    justifyContent="center"
                    spacing={2}
                >
                  <Grid item>
                    <Typography className="mb-6" variant="h1">
                      Update Your Password
                    </Typography>
                  </Grid>

                  {props.type === "forget" && (
                      <Grid item>
                        <TextField
                            error=""
                            label="Verification Code"
                            name="verificationCode"
                            value={values.verificationCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter Verification Code"
                            helperText=""
                        />
                      </Grid>
                  )}
                  <Grid item>
                    <PasswordField
                        error=""
                        label="Password"
                        name="newPassword"
                        type="password"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Password"
                        helperText=""
                    />
                  </Grid>
                  <Grid
                      item
                      container
                      direction="column"
                      alignContent="flex-start"
                      justifyContent="flex-start"
                      spacing={1}
                  >
                    <Grid item>
                      <span>Password Requirements</span>
                    </Grid>
                    <Grid item>
                      <PasswordRules
                          length={passwordRules.length}
                          hasUpperCase={passwordRules.hasUpperCase}
                          hasLowerCase={passwordRules.hasLowerCase}
                          hasSpecialCharacter={passwordRules.hasSpecialCharacter}
                          hasNumber={passwordRules.hasNumber}
                          hasNoConsecutiveLetters={
                            passwordRules.hasNoConsecutiveLetters
                          }
                          passwordMatch={passwordRules.passwordMatch}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <PasswordField
                        error=""
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Reenter Password"
                        helperText=""
                    />
                  </Grid>
                  <Grid
                      item
                      container
                      direction="column"
                      alignContent="flex-end"
                      justifyContent="flex-end"
                  >
                    <Button
                        type="primary"
                        isSubmitButton
                        disabled={!enabledPasswordSave}
                    >
                      Update Password
                    </Button>
                  </Grid>
                </Grid>
              </Form>
          )}
        </Formik>
  );
}

export default UpdatePasswordView;
