import './../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import { MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { showErrorStatus, showStatus } from '../../../security/state/user-slice';
import { statesList } from '../../../shared/misc/us-states';
import { State } from '../../../shared/model/state';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { Utils } from '../../../shared/utils';
import { clientContact, clientEntity } from '../../../shared/validation/schemas';
import { ClientForm } from '../../models/client';
import { clearStatus } from '../../state/clients/implementation-clients-slice';
import { addClient } from '../../state/clients/implementation-clients-thunk';

interface AddEditClientModalProps {
  open: boolean;
  handleModalClose: () => void;
}

export function AddEditClientModal(props: AddEditClientModalProps) {
  const { open, handleModalClose } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();


  const selectors = {
    saveStatus: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.apiStatus
    ),
    addClientForm: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.addClientForm
    ),
    currentClient: useSelector(
      (state: RootState) => state.implementationContext.implementationSpecialistClient.currentClient
    ),

  }


  function handleSave() {
    const formik = formRef.current;

    const clientData: ClientForm = formik.values;

  // TODO: do we want to have validation to check if the client name is already in the list of clients?

    setEnableSaveButton(false);
    dispatch(addClient(clientData));

  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      dispatch(showStatus("New client created"))
      setEnableSaveButton(false);
      navigate(`/clients/${selectors.currentClient.clientId}`);
    } else {
      dispatch(showErrorStatus('There was an error saving the client.'));
    }

    dispatch(clearStatus());
    handleModalClose()
  }

  const initialValues: ClientForm = {
    clientId: selectors.addClientForm.clientId,
    companyName: selectors.addClientForm.companyName,
    website: selectors.addClientForm.website,
    streetAddress1: selectors.addClientForm.streetAddress1,
    streetAddress2: selectors.addClientForm.streetAddress2,
    city: selectors.addClientForm.city,
    state: selectors.addClientForm.state,
    zipCode: selectors.addClientForm.zipCode,
    firstName: selectors.addClientForm.firstName,
    lastName: selectors.addClientForm.lastName,
    email: selectors.addClientForm.email,
    retypeEmail: selectors.addClientForm.retypeEmail,
    phone: selectors.addClientForm.phone,
    ext: selectors.addClientForm.ext,
    mobilePhone: selectors.addClientForm.mobilePhone,
    retypeMobilePhone: selectors.addClientForm.retypeMobilePhone,    
  };

  const validationSchema = Yup.object({
    ...clientEntity,
    ...clientContact,
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Add New Client</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Entity</Typography>
                </Grid>

                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["companyName"] &&
                      formik.errors["companyName"]
                        ? formik.errors["companyName"]
                        : ""
                    }
                    label="Company Name"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Company Name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["website"] && formik.errors["website"]
                        ? formik.errors["website"]
                        : ""
                    }
                    label="Website"
                    name="website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter URL eg. www.companyname.com"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Primary Address
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress1"] &&
                      formik.errors["streetAddress1"]
                        ? formik.errors["streetAddress1"]
                        : ""
                    }
                    label="Street Address 1"
                    name="streetAddress1"
                    value={formik.values.streetAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Street Address"
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["streetAddress2"] &&
                      formik.errors["streetAddress2"]
                        ? formik.errors["streetAddress2"]
                        : ""
                    }
                    label="Street Address 2"
                    name="streetAddress2"
                    value={formik.values.streetAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["city"] && formik.errors["city"]
                        ? formik.errors["city"]
                        : ""
                    }
                    label="City"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter City"
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["state"] && formik.errors["state"]
                        ? formik.errors["state"]
                        : ""
                    }
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Select State"
                    className="state-field"
                  >
                      <MenuItem value="-1">Choose a State</MenuItem>
                    {statesList.map((state: State) => (
                      <MenuItem key={state.stateId} value={state.stateId}>
                        {state.stateCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["zipCode"] && formik.errors["zipCode"]
                        ? formik.errors["zipCode"]
                        : ""
                    }
                    label="Zip Code"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={5}
                    placeholder="Enter Zip Code"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Primary Contact
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["firstName"] && formik.errors["firstName"]
                        ? formik.errors["firstName"]
                        : ""
                    }
                    label="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter First Name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["lastName"] && formik.errors["lastName"]
                        ? formik.errors["lastName"]
                        : ""
                    }
                    label="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Last Name"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["email"] && formik.errors["email"]
                        ? formik.errors["email"]
                        : ""
                    }
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Email Address"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeEmail"] &&
                      formik.errors["retypeEmail"]
                        ? formik.errors["retypeEmail"]
                        : ""
                    }
                    label="Retype Email"
                    name="retypeEmail"
                    value={formik.values.retypeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Re-Enter Email Address"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["phone"] && formik.errors["phone"]
                        ? formik.errors["phone"]
                        : ""
                    }
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("phone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Phone Number"
                    maxLength={12}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    required={false}
                    error={
                      formik.touched["ext"] && formik.errors["ext"]
                        ? formik.errors["ext"]
                        : ""
                    }
                    label="EXT"
                    name="ext"
                    value={formik.values.ext}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Extension"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["mobilePhone"] &&
                      formik.errors["mobilePhone"]
                        ? formik.errors["mobilePhone"]
                        : ""
                    }
                    label="Mobile Phone"
                    maxLength={12}
                    name="mobilePhone"
                    value={formik.values.mobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("mobilePhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    helperText="Used for multifactor account authorization"
                    placeholder="Enter Mobile Phone"
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeMobilePhone"] &&
                      formik.errors["retypeMobilePhone"]
                        ? formik.errors["retypeMobilePhone"]
                        : ""
                    }
                    label="Retype Mobile Phone"
                    name="retypeMobilePhone"
                    value={formik.values.retypeMobilePhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue("retypeMobilePhone", Utils.formatPhoneNumber((e.target as HTMLInputElement).value))
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Re-Enter Mobile Phone"
                    maxLength={12}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Save'
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleModalClose}
        />
      </DialogActions>
    </Dialog>
  );
}
