import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { formatNumberToUSD } from "../../../shared/calculators";
import { RootState } from "../../../shared/state/root-reducer";

export function RiskAssessmentBottomSection() {
  const [progressBarColor, setProgressBarColor] = useState("#36b37e");

  const state = {
    estimatorState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.estimator
    ),
  };

  const { estimatorState } = state;

  useEffect(() => {
    finAssistFormik.values.finAssistYesNo =
      estimatorState.hasFinancialAssistance;

    if (estimatorState?.financialAssistance?.qualifiesForAssistance) {
      setProgressBarColor("#36b37e");
    } else {
      setProgressBarColor("#BF3549");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: 10,
        borderRadius: 5,
      },
      colorPrimary: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === "light" ? 200 : 700],
      },
      bar: {
        borderRadius: 5,
        backgroundColor: progressBarColor,
      },
    })
  )(LinearProgress);

  const finAssistFormik = useFormik({
    initialValues: {
      finAssistYesNo: estimatorState.hasFinancialAssistance,
    },
    onSubmit: () => {},
  });

  const formatIncomeSourceData = (
    incomeSourceData: number | undefined | null
  ): string | 0 => {
    let incomeAmt = 0.0;
    if (incomeSourceData) {
      // powerlytics data represents thousands
      incomeAmt = parseFloat(incomeSourceData.toString()) * 100;
    }
    return formatNumberToUSD(incomeAmt);
  };

  const renderIncomeSource = (incomeSource: string) => {
    switch (incomeSource) {
      case "businessIncome":
        return (
          <>
            <Typography variant="body2">Business Income</Typography>
            {formatIncomeSourceData(
              estimatorState?.financialAssistance?.businessIncome
            )}
          </>
        );
      case "investmentIncome":
        return (
          <>
            <Typography variant="body2">Investment Income</Typography>
            {formatIncomeSourceData(
              estimatorState?.financialAssistance?.avgWealthIncome
            )}
          </>
        );
      case "alimonyIncome":
        return (
          <>
            <Typography variant="body2">Alimony Income</Typography>
            {formatIncomeSourceData(
              estimatorState?.financialAssistance?.alimonyIncome
            )}
          </>
        );
      case "retirementIncome":
        return (
          <>
            <Typography variant="body2">Retirement Income</Typography>
            {formatIncomeSourceData(
              estimatorState?.financialAssistance?.retirementIncome
            )}
          </>
        );
      default:
      // nothing
    }
  };

  const wagesEarnings = () => {
    let income: number = 0.0;
    if (estimatorState?.financialAssistance?.patientIncomeExpenses?.income) {
      income =
        estimatorState.financialAssistance.patientIncomeExpenses.income * 12;
    }
    return formatNumberToUSD(income - 10000);
  };

  return (
    <>
      {estimatorState.hasFinancialAssistance && (
        <Paper className="mt-5">
          <Box
            style={{
              padding: "1.5em",
              borderBottom: "1px solid #cccccc",
              marginBottom: "1.5em",
            }}
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid
                item
                justifyContent="center"
                direction="column"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Capacity to Pay Assessment
                </Typography>
              </Grid>
              <Grid item></Grid>
            </Grid>

            {estimatorState.financialAssistance.isValid ? (
              <>
                <Grid container direction="row" spacing={2} className="mt-2">
                  <Grid item direction="column" md={6}>
                    <div>
                      <Typography style={{ float: "left" }} variant="body2">
                        Income Verification Score
                      </Typography>
                      <Typography style={{ float: "right" }} variant="body2">
                        {(estimatorState?.financialAssistance?.incomeVerificationScore!).toFixed(0)}/10
                      </Typography>
                    </div>
                    <div style={{ clear: "both" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={(
                          estimatorState?.financialAssistance
                            ?.incomeVerificationScore! * 10
                        ).toFixed(2)}
                        className="success"
                      />
                    </div>
                  </Grid>

                  <Grid item direction="column" md={6}>
                    <div>
                      <Typography style={{ float: "left" }} variant="body2">
                        Capacity to Pay Score
                      </Typography>
                      <Typography style={{ float: "right" }} variant="body2">
                        {estimatorState?.financialAssistance?.capacityToPayScore!.toFixed(0)}/10
                      </Typography>
                    </div>
                    <div style={{ clear: "both" }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={(
                          estimatorState?.financialAssistance
                            ?.capacityToPayScore! * 10
                        ).toFixed(2)}
                        className="success"
                      />
                    </div>
                  </Grid>
                </Grid>

                <p className="mt-3">Demographic Income Data</p>

                <Grid container direction="row" spacing={2} className="mt-2">
                  <Grid item direction="column" md={6}>
                    <Typography variant="body2">Disposable Income</Typography>
                    {formatNumberToUSD(
                      estimatorState?.financialAssistance?.disposableIncome! *
                        100
                    )}
                  </Grid>
                  {estimatorState?.financialAssistance?.patientIncomeExpenses?.incomeSources?.map(
                    (incSource: string, i) => (
                      <Grid item direction="column" md={6}>
                        {renderIncomeSource(incSource)}
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid container direction="row" spacing={2} className="mt-2">
                  <Grid item direction="column" md={6}>
                    <Typography variant="body2">Wages and Earnings</Typography>
                    {wagesEarnings()}
                  </Grid>
                  <Grid item direction="column" md={6}></Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container direction="row">
                  <>
                    <Grid
                      item
                      xs={12}
                      style={{ textAlign: "center", padding: "1em" }}
                    >
                      {estimatorState.financialAssistance?.inValidReason &&
                        estimatorState.financialAssistance?.inValidReason !==
                          "" && (
                          <>
                            <Alert severity="warning" className="mb-3">
                              {estimatorState.financialAssistance.inValidReason}
                            </Alert>
                          </>
                        )}
                    </Grid>
                  </>
                </Grid>
              </>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
}
