import {
    planType,
    patientFinancialStatus,
    riskClass,
    accountHolderStatus,
    transactionType,
    paymentWorkflowStatus,
    initialRiskClasses,
    modifiedRiskClasses,
    convertedRiskClasses,
    finalRiskClasses,
    sentToProvider,
    automatedPaymentStatus
} from "./reportsFiltersData";

export const transactionFilters =[
    planType,
    transactionType
]

export const settlementDetailFilters = [
    planType
]

export const pesActivityLogReportFilters = [
    planType,
    riskClass,
    patientFinancialStatus,
    accountHolderStatus,
    paymentWorkflowStatus
]

export const riskClassReportFilters = [
    initialRiskClasses,
    modifiedRiskClasses,
    convertedRiskClasses,
    finalRiskClasses
]

export const patientFinancialClearenceFilters = [
    planType,
    patientFinancialStatus,
    accountHolderStatus,
    paymentWorkflowStatus
]

export const allInstanceOfCareFilters = [
    planType,
    riskClass,
    patientFinancialStatus,
    accountHolderStatus,
    paymentWorkflowStatus
]

export const summaryTransactionsFilters = []

export const mergedTransactionsFilters = [
    planType,
    transactionType
]

export const automatedPaymentFilters = [
    sentToProvider,
    automatedPaymentStatus
]

export const settlementSummaryV2Filters = []