import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Box, Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import { admissionsAdvisorUtils } from "../../utils/admission-advisor-utils";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { EstFinancialSummaryModal } from "./estimator-financial-summary-modal";
import { AppDispatch } from "../../../shared/state/store";
import { setEstimator } from "../../state/estimator-slice";
import useIsClientConfiguredForNewEstimate from "src/admissions-advisor/utils/useClientConfigHook";
import { CrossoverSelectionEnum, SummarySelections } from "@finpay/estimation-types";
import CrossoverSummaryModal from "./estimator-crossover-summary-modal";
import { Utils } from "src/shared/utils";
import { callNewEstimatePut } from "src/admissions-advisor/state/estimator-thunk";

export function SidebarTotalEstimatedPfr() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    newEstimate: useSelector((state: RootState) => {
      return state.admissionsAdvisorContext.estimatorContext.newEstimate
    })
  };

	const {
		vobState,
		estimatorState,
    newEstimate
	} = state;

  const isClientConfiguredForNewEstimates = useIsClientConfiguredForNewEstimate()

	const [financialSummaryModalOpen, setFinancialSummaryModalOpen] = useState(false);
	const [totalEstimatedPfrSelected, setTotalEstimatedPfrSelected] = useState(false);
  const [isCrossoverSummaryModalOpen, setIsCrossoverSummaryModalOpen] = useState(false)
	const [totalPFR, setTotalPFR] = useState<number>(0.00);
	const dispatch = useDispatch<AppDispatch>();
	const PFRName = "Total Estimated PFR";
	const newPFRName = SummarySelections.NONADJUSTED;

	useEffect(() => {
    getPFR();
    if (totalPFR !== estimatorState.totalEstimatedPfr && !isClientConfiguredForNewEstimates) {
      let estimatorStateCopy = {...estimatorState};
      estimatorStateCopy.totalEstimatedPfr = totalPFR;
      dispatch(setEstimator(estimatorStateCopy));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState, newEstimate]);

	const handleTotalEstimatedPfrSelected = () => {
    
    setTotalEstimatedPfrSelected(!totalEstimatedPfrSelected);
		if (estimatorState.selectedPFRName !== PFRName && !isClientConfiguredForNewEstimates) {
			let estimatorStateCopy = { ...estimatorState };
			estimatorStateCopy.selectedPFRName = PFRName;
			dispatch(setEstimator(estimatorStateCopy));
		}
    if (newEstimate && newEstimate.summarySelection !== newPFRName) {
        // we need put
        let newEstimateClone = Utils.deepClone(newEstimate);
        newEstimateClone = {...newEstimateClone, summarySelection: newPFRName};
        const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
            vobState.vobId!,
            vobState.client?.clientId!,
            vobState.facility?.facilityId!,
            newEstimateClone,
            Number(newEstimate.advisorPatientId!),
            newEstimate.patientEncounterId as string
        );
        dispatch(
            callNewEstimatePut({
                estimate: postEstimateBody,
                estimateId: newEstimateClone.estimateId,
            })
        );
    }
    
  }

	const getSideBarColor = (): string => {
    let sideBarColor = (estimatorState.selectedPFRName === PFRName)? "#6EACDE" : "#999999";
    return sideBarColor;
  };

  const getNewSideBarColor = (): string => {
    let sideBarColor = (newEstimate?.summarySelection === newPFRName)? "#6EACDE" : "#999999";
    return sideBarColor;
  };

	const handleFinSumModalCancel = () => {
    setFinancialSummaryModalOpen(false);
  };

	const openFinancialSummaryModal = () => {
    // if new estimate and crossover is true..  
    if (newEstimate && newEstimate.isPlanYearCrossover) {
      setIsCrossoverSummaryModalOpen(true)
    } else {
      setFinancialSummaryModalOpen(true);
    }
	}

  const getPFR = () => {
    const financialSummary = isClientConfiguredForNewEstimates ? newEstimate?.nonAdjustedSummary : admissionsAdvisorUtils.calculateFinancialSummary(estimatorState, vobState);
    if (financialSummary?.totalPFR !== totalPFR && !isClientConfiguredForNewEstimates) {
      setTotalPFR(financialSummary?.totalPFR!);
    }
    if (isClientConfiguredForNewEstimates && newEstimate) {                 
      const crossoverPFRToShow = newEstimate.nonAdjustedSummary?.totalPFR;
      setTotalPFR(crossoverPFRToShow!)
    }
  }

  const summaryExists = (estimatorState?.selectedLevelsOfCare?.length > 0 && !estimatorState.planYearCrossover?.hasPlanYearCrossover) || (isClientConfiguredForNewEstimates && newEstimate?.facilityLevelOfCare && newEstimate?.facilityLevelOfCare?.length > 0)

	return (
        <>
            {summaryExists && (
                <>
                    <Box
                        border={1}
                        borderColor={isClientConfiguredForNewEstimates 
                          ? getNewSideBarColor() 
                          : getSideBarColor()
                        }
                        style={{
                            backgroundColor: 'white',
                            marginBottom: '1.5em',
                        }}
                    >
                        <Grid container direction="row">
                            <Grid item direction="column" md={12}></Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                xs={12}
                                style={{
                                    color: 'white',
                                    backgroundColor: isClientConfiguredForNewEstimates 
                                        ? getNewSideBarColor() 
                                        : getSideBarColor(),
                                    padding: '0.25em',
                                }}
                            >
                                Patient Financial Responsibility
                                {isClientConfiguredForNewEstimates && (
                                    newEstimate?.isPlanYearCrossover 
                                      ? newEstimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
                                          ? ' before Crossover'
                                          : ' with Crossover'
                                      : ''
                                )}
                            </Grid>
                            <Grid
                                item
                                direction="column"
                                md={12}
                                style={{
                                    padding: '1em',
                                }}
                            >
                                <Typography
                                    variant="body2"
                                    style={{float: 'left'}}
                                >
                                    Total Estimated PFR
                                </Typography>

                                <FormControlLabel
                                    checked={
                                      isClientConfiguredForNewEstimates
                                          ? newEstimate?.summarySelection! ===
                                            newPFRName
                                          : estimatorState.selectedPFRName ===
                                            PFRName
                                    }
                                    control={<Checkbox color="primary" />}
                                    id="totalPFR"
                                    name="totalPFR"
                                    label={
                                        <strong test-id="sidebar-estimate-pfr-value">
                                            {formatNumberToUSD(totalPFR)}
                                        </strong>
                                    }
                                    labelPlacement="start"
                                    style={{float: 'right'}}
                                    onChange={() => {
                                        handleTotalEstimatedPfrSelected();
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <IconButton
                                    color="primary"
                                    title="Open Details"
                                    style={{fontSize: '1.5rem'}}
                                    onClick={openFinancialSummaryModal}
                                    test-id="sidebar-estimate-pfr-open-details"
                                >
                                    <OpenInNewIcon fontSize="small" /> Open
                                    Details
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    {isCrossoverSummaryModalOpen && (
                        <CrossoverSummaryModal open={true} estimate={newEstimate!} handleCancel={() => {setIsCrossoverSummaryModalOpen(false)}}/>
                    )}
                    {financialSummaryModalOpen && (
                        <EstFinancialSummaryModal
                            open={financialSummaryModalOpen}
                            handleFinancialSummaryModalCancel={
                                handleFinSumModalCancel
                            }
                        />
                    )}
                </>
            )}
        </>
    );
	


}