import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import { RootState } from "../../../../../shared/state/root-reducer";
import {
  AuthDocs,
  AuthDocsForm,
  AuthDocsUIForm,
} from "../../../../models/auth-docs-form";
import { saveAuthDocs } from "../../../../state/auth-docs/implementation-auth-docs-thunk";
import { setAuthDocsTabComplete } from "../../../../state/clients/implementation-clients-slice";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import {
  clearAuthDocsForm,
  clearStatus,
  resetAddAuthDocs,
} from "../../../../state/auth-docs/implementation-auth-docs-slice";
import { authDocsSchema } from "../../../../../shared/validation/schemas";
import { Form, Formik } from "formik";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  Grid,
  MenuItem,
  TextField,
   Checkbox
} from "@mui/material";
import { DialogActionButton } from "@finpay-development/shared-components";
import { authorizationDocuments } from "../../../../../shared/model/auth-docs";
import { ImplementationAuthDocs } from "../models/implementation-auth-docs";
interface AuthDocsModalProps {
  open: boolean;
  isEditAuthDocs: boolean;
  isAddAuthDocs?: boolean;
  selectedAuthDocs: ImplementationAuthDocs | AuthDocs | undefined;
  handleAuthDocsModalCancel: () => void;
  handleAuthDocsModalSubmit: (isEditAuthDocs: boolean) => void;
  handleAuthDocsDeleted: () => void;
  selectedValue: any;
}

export default function AuthDocsModal(props: AuthDocsModalProps) {
  const {
    open,
    isEditAuthDocs,
    selectedAuthDocs,
    handleAuthDocsModalCancel,
    handleAuthDocsModalSubmit,
    selectedValue,
  } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const selectors = {
    clientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
          .clientId
    ),
    authDoc: useSelector(
      (state: RootState) => state.authDocsContext.implementationAuthDocs.authDoc
    ),
    currentAuthDocsForm: useSelector(
      (state: RootState) =>
        state.authDocsContext.implementationAuthDocs.currentAuthDocsForm
    ),
    authDocs: useSelector(
      (state: RootState) =>
        state.authDocsContext.implementationAuthDocs.authDocs
    ),
    saveStatus: useSelector(
      (state: RootState) =>
        state.authDocsContext.implementationAuthDocs.apiStatus
    ),
    errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility.errorMessage
    ),
  };

  const { authDocs, clientId } = selectors;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTypeOfDoc, setSelectedTypeOfDoc] = useState("");

  const existingAuthDocsClientLevel = new Set(
    authDocs
      .filter((authDoc) => authDoc?.clientFacilityId === null)
      .map((authDoc) => authDoc?.typeOfDoc)
  );

  const existingAuthDocsAtClientFacilityLevel = new Set(
    authDocs
      .filter((authDoc) => authDoc?.clientFacilityId !== null)
      .map((authDoc) => authDoc?.typeOfDoc)
  );

  const handleSave = async () => {
    const authorizationDocumentsId = isEditAuthDocs
      ? formRef?.current?.values?.authorizationDocumentsId
      : 0;

    const clientId = selectedValue?.clientId;
    const clientFacilityId = selectedValue?.facilityId;

    const formValues: AuthDocsForm = {
      ...formRef.current.values,
      authorizationDocumentsId: authorizationDocumentsId,
      clientFacilityId: clientFacilityId,
    };

    if (clientFacilityId) {
      await dispatch(
        saveAuthDocs({
          clientId,
          clientFacilityId,
          formValues,
          existingAuthDocs: existingAuthDocsAtClientFacilityLevel,
        })
      );
    } else if (clientId) {
      await dispatch(
        saveAuthDocs({
          clientId,
          clientFacilityId: null,
          formValues,
          existingAuthDocs: existingAuthDocsClientLevel,
        })
      );
    } else {
      await dispatch(
        saveAuthDocs({
          clientId,
          clientFacilityId,
          formValues,
          existingAuthDocs: existingAuthDocsClientLevel,
        })
      );
    }
  };

  const handleSaveCallback = (saveSuccessful: boolean) => {
    if (saveSuccessful) {
      handleAuthDocsModalSubmit(isEditAuthDocs);
      setEnableSaveButton(false);
      dispatch(setAuthDocsTabComplete(true));
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  };

  const handleCancelCallback = () => {
    dispatch(resetAddAuthDocs());
    handleAuthDocsModalCancel();
  };

  useEffect(() => {
    if (!isEditAuthDocs) {
      dispatch(clearAuthDocsForm());
    }
  }, [isEditAuthDocs, dispatch]);

  const initialValues = {
    clientId,
    authorizationDocumentsId:
      selectors.currentAuthDocsForm?.authorizationDocumentsId,
    typeOfDoc: selectors.currentAuthDocsForm?.typeOfDoc,
    documentUrl: selectors.currentAuthDocsForm?.documentUrl,
    clientFacilityId: selectors.currentAuthDocsForm?.clientFacilityId,
    isSigReq: selectors.currentAuthDocsForm?.isSigReq,
  };

  const validationSchema = authDocsSchema;

  function checkIfIsValid(value: AuthDocsUIForm) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  const getErrorMessage = (fieldName: string, errorMessage: any) => {
    const fieldMap: { [key: string]: string } = {
      documentUrl: "Document URL",
    };
    const displayName = fieldMap[fieldName] || fieldName;
    return errorMessage?.replace(fieldName, displayName);
  };

  return (
    <Dialog
      open={open}
      scroll="body"
      className="modal client-modal"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">
        {isEditAuthDocs && selectedAuthDocs?.authorizationDocumentsId ? "Edit Authorization Document" : "Add Authorization Document"}
        </span>
      </DialogTitle>

        <DialogContent>
          <Formik
              innerRef={formRef}
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              validate={checkIfIsValid}
              onSubmit={() => {
              }}
          >
            {(formik) => {
              return (
                  <Form autoComplete="off">
                    <Grid container className="mb-4">
                      <Grid item xs={6}>
                        <TextField
                            fullWidth
                            select={true}
                            error={
                                formik.touched['typeOfDoc'] &&
                                Boolean(formik.errors['typeOfDoc'])
                            }
                            label="Type of Document"
                            name="typeOfDoc"
                            value={formik.values.typeOfDoc}
                            onChange={(event) => {
                              setSelectedTypeOfDoc(event.target.value);
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                        >
                          <MenuItem value="-1">Select Type Of
                            Document</MenuItem>

                          {authorizationDocuments.map(
                              (authDoc: { typeOfDoc: string }) => (
                                  <MenuItem
                                      key={authDoc.typeOfDoc}
                                      value={authDoc.typeOfDoc}
                                      disabled={
                                        selectedValue?.facilityId
                                            ?
                                            existingAuthDocsAtClientFacilityLevel.has(
                                                authDoc.typeOfDoc,
                                            )
                                            :
                                            existingAuthDocsClientLevel.has(
                                                authDoc.typeOfDoc)
                                      }
                                  >
                                    {authDoc?.typeOfDoc.toUpperCase()}
                                  </MenuItem>
                              ),
                          )}
                        </TextField>
                      </Grid>
                      <Grid container item xs={6} alignItems="center">
                        <Grid item sx={{paddingLeft: 3}}>
                          <FormControlLabel
                              control={<Checkbox onChange={formik.handleChange}
                                                 checked={formik.values.isSigReq}
                                                 id="isSigReq"/>}
                              label="Require Signature"/>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid xs={12} item className="mb-4">
                        <TextField
                            fullWidth
                            error={
                                formik.touched['documentUrl'] &&
                                Boolean(formik.errors['documentUrl'])
                            }
                            helperText={
                              formik.touched['documentUrl']
                                  ? getErrorMessage(
                                      'documentUrl',
                                      formik.errors['documentUrl'],
                                  )
                                  : ''
                            }
                            label="Document URL"
                            name={'documentUrl'}
                            value={formik.values.documentUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Document URL"
                        />
                      </Grid>
                    </Grid>
                  </Form>
              );
            }}
          </Formik>
        </DialogContent>

      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEditAuthDocs && selectedAuthDocs?.authorizationDocumentsId ? "Update" : "Add"}
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
