export interface PatientPfr {
    patientPfrId: number;
    patientId: number;
    patientEncounterId: number;
    pfrAmt: number;
    conversionDt: string;
    patientLedgerId: number;
    convRunNum: number;
}

export const emptyPatientPfr: PatientPfr = {
    patientPfrId: -1,
    patientId: -1,
    patientEncounterId: -1,
    pfrAmt: -1,
    conversionDt: "",
    patientLedgerId: -1,
    convRunNum: -1,
}