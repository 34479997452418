import { ActionCreatorWithPayload, createAsyncThunk } from '@reduxjs/toolkit'
import { configSettings } from '../../shared/configuration/config-settings';
import { LockStatus } from '../../shared/enums';
import { RootState } from '../../shared/state/root-reducer';
import { LockablePatientRecordSection } from '../components/models/lockable-patient-record-section';
import { patientService } from '../services/patient-service';

import { setLockRequestedModalOpen_overview, setRequestDeniedModalOpen_overview, setInUseModalOpen_overview,
  setLockRequestedModalOpen_patientChampions, setLockRequestedModalOpen_paymentPrograms, setLockRequestedModalOpen_insurance,
  setInUseModalOpen_insurance, setInUseModalOpen_patientChampions, setInUseModalOpen_paymentPrograms,
  setRequestDeniedModalOpen_insurance, setRequestDeniedModalOpen_patientChampions, setRequestDeniedModalOpen_paymentPrograms,
  setLockStatusReleased_overview, setLockStatusReleased_insurance, setLockStatusReleased_patientChampions,
  setLockStatusReleased_paymentPrograms,
  setLockRequestedById,
} from './patient-record-locking-slice';

  type ActionCreator = {[key: string]: ActionCreatorWithPayload<boolean, string>};
  const lockPollingInterval = configSettings.REACT_APP_patient_locking_polling_interval;

  export const editModalPoll = createAsyncThunk(
    'patientContext',
    async (data: {patientId: number, encounterId: number, userName: string, lockablePatientRecordSection: LockablePatientRecordSection}, thunkAPI) =>  {
      const patientId = data.patientId;
      const encounterId = data.encounterId;
      const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});

      const modalType_lockRequest = {
        overview: setLockRequestedModalOpen_overview,
        insurance: setLockRequestedModalOpen_insurance,
        patientChampions: setLockRequestedModalOpen_patientChampions,
        paymentPrograms: setLockRequestedModalOpen_paymentPrograms
      } as ActionCreator

      if (instanceOfCare?.entity?.lockRequestedById !== null && instanceOfCare?.entity?.lockRequestedById !== data.userName) {
        thunkAPI.dispatch(setLockRequestedById(instanceOfCare?.entity?.lockRequestedById));
        thunkAPI.dispatch(modalType_lockRequest[data.lockablePatientRecordSection](true));
      }
    }
  );

  export const denyRequestOk = createAsyncThunk(
    'patientContext',
    async (data: {lockablePatientRecordSection: LockablePatientRecordSection}, thunkAPI) =>  {
      const state = thunkAPI.getState() as RootState;
      const encounterId = state.patientContext.selectedEncounter.patientEncounterId;

      const modalType_lockRequest = {
        overview: setLockRequestedModalOpen_overview,
        insurance: setLockRequestedModalOpen_insurance,
        patientChampions: setLockRequestedModalOpen_patientChampions,
        paymentPrograms: setLockRequestedModalOpen_paymentPrograms
      } as ActionCreator

      await patientService.saveIOCWithPartialObject(
        { lockStatus: LockStatus.deny },
        encounterId
      );

      thunkAPI.dispatch(modalType_lockRequest[data.lockablePatientRecordSection](false));
    }
  );

  export const releaseRecordOk = createAsyncThunk(
    'patientContext',
    async (data: {lockablePatientRecordSection: LockablePatientRecordSection}, thunkAPI) =>  {
      const state = thunkAPI.getState() as RootState;
      const patientId = state.patientContext.selectedEncounter.patientId;
      const encounterId = state.patientContext.selectedEncounter.patientEncounterId;
      const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});

      const modalType_lockRequest = {
        overview: setLockRequestedModalOpen_overview,
        insurance: setLockRequestedModalOpen_insurance,
        patientChampions: setLockRequestedModalOpen_patientChampions,
        paymentPrograms: setLockRequestedModalOpen_paymentPrograms
      } as ActionCreator

      await patientService.saveIOCWithPartialObject(
        {

          lockedByUserId: instanceOfCare.entity.lockRequestedById,
          lockRequestedById: null,
          lockStatus: LockStatus.release
        },
        encounterId
      );
      thunkAPI.dispatch(modalType_lockRequest[data.lockablePatientRecordSection](false));
    }
  );

  export const requestReleaseOk = createAsyncThunk(
    'patientContext',
    async (data: {userName: string, lockablePatientRecordSection: LockablePatientRecordSection}, thunkAPI) =>  {

        const modalType_inUse = {
            overview: setInUseModalOpen_overview,
            insurance: setInUseModalOpen_insurance,
            patientChampions: setInUseModalOpen_patientChampions,
            paymentPrograms: setInUseModalOpen_paymentPrograms
            } as ActionCreator
            const modalType_requestDenied = {
            overview: setRequestDeniedModalOpen_overview,
            insurance: setRequestDeniedModalOpen_insurance,
            patientChampions: setRequestDeniedModalOpen_patientChampions,
            paymentPrograms: setRequestDeniedModalOpen_paymentPrograms
            } as ActionCreator

            const modalType_lockStatusReleased = {
            overview: setLockStatusReleased_overview,
            insurance: setLockStatusReleased_insurance,
            patientChampions: setLockStatusReleased_patientChampions,
            paymentPrograms: setLockStatusReleased_paymentPrograms
            } as ActionCreator

      const state = thunkAPI.getState() as RootState;
      const patientId = state.patientContext.selectedEncounter.patientId;
      const encounterId = state.patientContext.selectedEncounter.patientEncounterId;

      await patientService.saveIOCWithPartialObject(
        { lockRequestedById: data.userName },
        encounterId
      );
      // On each interval (e.g. 5 seconds) check to see if patient lock owner has set lock status to 'deny' or 'release'
      let pollingLockStatusInterval = setInterval(async() => {
        const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});
        if (instanceOfCare.entity.lockStatus === LockStatus.deny ) {
          clearInterval(pollingLockStatusInterval);

          await patientService.saveIOCWithPartialObject(
            { lockStatus: null },
            encounterId
          );
          thunkAPI.dispatch(modalType_inUse[data.lockablePatientRecordSection](false));

          thunkAPI.dispatch(setLockRequestedById(instanceOfCare.entity.lockedByUserId)); // TODO
          thunkAPI.dispatch(modalType_requestDenied[data.lockablePatientRecordSection](true));
        } else if (instanceOfCare.entity.lockStatus === LockStatus.release) {
          clearInterval(pollingLockStatusInterval);
          if (instanceOfCare.entity.isLocked && instanceOfCare.entity.lockedByUserId !== data.userName) {
            // we shouldn't do anything in this case. The IOC is locked by another user.
            clearInterval(pollingLockStatusInterval);

          } else {
            await patientService.saveIOCWithPartialObject(
              {
                lockedByUserId: data.userName,
                lockRequestedById: null,
                lockStatus: null
              },
              encounterId
            );
          }

          thunkAPI.dispatch(modalType_inUse[data.lockablePatientRecordSection](false));
          thunkAPI.dispatch(modalType_lockStatusReleased[data.lockablePatientRecordSection](true));
        }
      }, lockPollingInterval);
    }
  );



