import { AxiosSavePayload } from "src/shared/service/axios-save-payload";
import { ImplementationCRM } from "../components/implementation-clients/details/models/implementation-crm";
import { CRMDetails, CRMForm } from "../models/crm-form";
import { axiosSaveHelper } from "src/shared/service/axios-save-helper";
import { AxiosResultStatus } from "src/shared/service/axios-result-status";
import { AxiosReadPayload } from "src/shared/service/axios-read-payload";
import { axiosReadHelper } from "src/shared/service/axios-read-helper";

export class CRMService {

    private mapClientCRMToSave = (
        formValues: CRMForm,
        fullClientCRM: ImplementationCRM,
        isEditCRM: boolean
    ) => {

        return {
            clientFacilityId: formValues.clientFacilityId || null,
            crmTypeId: formValues.crmTypeId,
            isIntEnabled: formValues.isIntEnabled,
            isPolledInt: formValues.isPolledInt,
            isEventInt: formValues.isEventInt,
            apiUrl: formValues.apiUrl,
            apiUserName: formValues.apiUserName,
            apiPassword: formValues.apiPassword,
            preadmitTypeId: formValues.preadmitTypeId,
            admitTypeId: formValues.admitTypeId,
            isAdmitIntEnabled: formValues.isAdmitIntEnabled,
            isPolledAdmitInt: formValues.isPolledAdmitInt,
            isEventAdmitInt: formValues.isEventAdmitInt,
            apiFinpayId: formValues.apiFinpayId || null,
            apiFinpayToken: formValues.apiFinpayToken || null,
            oauthGrantType: formValues.oauthGrantType || null,
            authUrl: formValues.authUrl || null,
            accessTokenUrl: formValues.accessTokenUrl || null,
            oauthToken: formValues.oauthToken || null,
            tokenExpireDt: formValues.tokenExpireDt || null,
        }
    }

    private mapCRMToForm = (implementationCRM: ImplementationCRM) => {

        const crmForm: CRMForm = {
            clientCrmId: implementationCRM.clientCrmId || undefined,
            clientFacilityId: implementationCRM.clientFacilityId,
            crmTypeId: implementationCRM.crmTypeId,
            isIntEnabled: implementationCRM.isIntEnabled,
            isPolledInt: implementationCRM.isPolledInt,
            isEventInt: implementationCRM.isEventInt,
            apiUrl: implementationCRM.apiUrl,
            apiUserName: implementationCRM.apiUserName,
            apiPassword: implementationCRM.apiPassword,
            preadmitTypeId: implementationCRM.preadmitTypeId,
            admitTypeId: implementationCRM.admitTypeId,
            isAdmitIntEnabled: implementationCRM.isAdmitIntEnabled,
            isPolledAdmitInt: implementationCRM.isPolledAdmitInt,
            isEventAdmitInt: implementationCRM.isEventAdmitInt,
            apiFinpayId: implementationCRM.apiFinpayId,
            apiFinpayToken: implementationCRM.apiFinpayToken,
            oauthGrantType: implementationCRM.oauthGrantType,
            authUrl: implementationCRM.authUrl,
            accessTokenUrl: implementationCRM.accessTokenUrl,
            oauthToken: implementationCRM.oauthToken,
            tokenExpireDt: implementationCRM.tokenExpireDt,
        }
        return crmForm
    }

    private mapCRMToDetails = (implementationCRM: ImplementationCRM) => {

        const crmDetails: CRMDetails = {
            clientId: implementationCRM.clientId,
            clientCrmId: implementationCRM.clientCrmId,
            clientFacilityId: implementationCRM.clientFacilityId,
            crmTypeId: implementationCRM.crmTypeId,
            isIntEnabled: implementationCRM.isIntEnabled,
            isPolledInt: implementationCRM.isPolledInt,
            isEventInt: implementationCRM.isEventInt,
            apiUrl: implementationCRM.apiUrl,
            apiUserName: implementationCRM.apiUserName,
            apiPassword: implementationCRM.apiPassword,
            preadmitTypeId: implementationCRM.preadmitTypeId,
            admitTypeId: implementationCRM.admitTypeId,
            isAdmitIntEnabled: implementationCRM.isAdmitIntEnabled,
            isPolledAdmitInt: implementationCRM.isPolledAdmitInt,
            isEventAdmitInt: implementationCRM.isEventAdmitInt,
            apiFinpayId: implementationCRM.apiFinpayId,
            apiFinpayToken: implementationCRM.apiFinpayToken,
            oauthGrantType: implementationCRM.oauthGrantType,
            authUrl: implementationCRM.authUrl,
            accessTokenUrl: implementationCRM.accessTokenUrl,
            oauthToken: implementationCRM.oauthToken,
            tokenExpireDt: implementationCRM.tokenExpireDt,

        }
        return crmDetails
    }

    async saveCRM(
        clientId: number,
        clientFacilityId: number|null,
        formValues: CRMForm,
        fullClientCRM: ImplementationCRM,
        allClientCRM: ImplementationCRM[]
    ) {

        const clientCrmId = formValues?.clientCrmId

        const isEditCRM = clientCrmId !== 0 && clientCrmId !== undefined

        const clientCRMToSave = this.mapClientCRMToSave(
            formValues,
            fullClientCRM,
            isEditCRM
        )

        const updateCRMUrl = `clients/v2/client/crm/${clientCrmId}`

        const addCRMUrl = `clients/v2/client/${clientId}/crm`

        const payload: AxiosSavePayload = {
            dataToSave: clientCRMToSave,
            dataId: -2,
            isPatch: isEditCRM,
            url: isEditCRM ? updateCRMUrl : addCRMUrl
        }

        const response = await axiosSaveHelper(payload)

        if (!response.hasErrors) {

            const crmResponse = response.entity
            let apiResponseCRM: ImplementationCRM[] = []

            const crmIndex = allClientCRM.findIndex((crm) => {
                return (
                    crm.clientCrmId === crmResponse.clientCrmId
                )
            })

            if (crmIndex > -1) {
                allClientCRM.splice(crmIndex, 1,crmResponse)
            } else {
                allClientCRM.push(crmResponse)
            }

            apiResponseCRM = allClientCRM

            response.entity = {
                apiResponseCRM
            }
        }
        return response

    }

    async getClientCRM(clientCrmId: number): Promise<AxiosResultStatus> {

        const payload: AxiosReadPayload = {
            dataId: -2,
            url: `clients/v2/client/crm/${clientCrmId}`
        }

        const response = await axiosReadHelper(payload)
        const crm = response.entity

        if (!response.hasErrors) {
            const crmForm = this.mapCRMToForm(crm)
            const crmDetails = this.mapCRMToDetails(crm)

            response.entity = {
                crmForm: crmForm,
                apiResponseCRM: crm,
                crmDetails: crmDetails
            }
        }

        return response

    }

    async getAllClientCRM(clientId: number, clientFacilityId: number | null): Promise<AxiosResultStatus> {

        const queryParameters = clientFacilityId ? `?clientFacilityId=${clientFacilityId}` : `?clientFacilityId=${null}`

        const payload: AxiosReadPayload = {
            dataId: -2,
            url: `clients/v2/client/${clientId}/crm${queryParameters}`
        }

        const response = await axiosReadHelper(payload)
        const crm = response.entity

        if (!response.hasErrors) {
            const crmForm = this.mapCRMToForm(crm)
            response.entity = {
                crmForm: crmForm,
                apiResponseCRM: crm,
            }
        }

        return response
    }
}

export const crmService = new CRMService();