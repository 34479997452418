import { DeleteModal, LoadingOverlay, saveCallbackStatus, Toggle } from '@finpay-development/shared-components';
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Menu,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TablePagination from '@mui/material/TablePagination';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableColumn } from '../../../shared/model/table-column';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { Scholarship } from '../../models/scholarship';
import { configDeleteScholarship, configGetScholarships } from '../../state/admin-configuration-thunk';

import { ScholarshipModal } from './scholarship-modal';
import { clearStatus } from '../../state/admin-configuration-slice';

import * as Yup from 'yup';
import {useFormik} from 'formik';
import { Utils } from '../../../shared/utils';
import { ClientStatusCardViewModel } from '../../../shared/model/client-status-card';

type MenuActionType = "add" | "edit" | "delete";

const columns: TableColumn[] = [
    { id: "scholarshipName", label: "Scholarship Levels", minWidth: 200 },
    { id: "scholarshipPercent", label: "Scholarship %", minWidth: 100 },
    { id: "fplThreshold", label: "FPL Threshold", minWidth: 100 },
    { id: "expenseThreshold", label: "Expense: Income Threshold ", minWidth: 100 },
    { id: "menu", label: "", minWidth: 25 },
];

function ScholarshipGrid() {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [isScholarshipModalOpen, setIsScholarshipModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [originalRows, setOriginalRows] = useState<Scholarship[]>();
    const [rows, setRows] = useState<Scholarship[]>();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isEditModal, setIsEditModal] = useState(true);
    const [sortedClients, setSortedClients] = useState<ClientStatusCardViewModel[]>();

    const [selectedScholarship, setSelectedScholarship] = useState<Scholarship>();
    const [selectedClientId, setSelectedClientId] = useState('');
    const [deleteTitle, setDeleteTitle] = useState('');
    const paramId: number = -2;

    const dispatch = useDispatch<AppDispatch>();

    const state = {
        scholarships: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.scholarships
        ),
        isLoadingScholarshipState: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading.isLoadingScholarship
        ),
        getScholarshipsStatus: useSelector(
            (state: RootState) =>
                state.adminContext.adminConfigurationContext?.isLoading.getScholarshipsStatus
        ),
        clients: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationSpecialistClient.filteredByIsActive
        ),
        isLoadingClients: useSelector(
            (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.isLoading
        ),
    };
    const {
        clients,
        scholarships,
        isLoadingScholarshipState,
        getScholarshipsStatus,
        isLoadingClients,
    } = state;

    const initialScholarshipValues = {
        scholarshipClient: 0,
        supportForScholarship: true,
    }

    const validationScholarshipSchema = Yup.object({
        scholarshipClient: Yup.number(),
        supportForScholarship: Yup.boolean(),
    })

    const scholarshipFormik = useFormik({
        initialValues: initialScholarshipValues,
        enableReinitialize: true,
        onSubmit: () => {},
        validationSchema: validationScholarshipSchema
    })

    useEffect(() => {
        if (!isLoadingScholarshipState && (getScholarshipsStatus === saveCallbackStatus.success)) {
            if (scholarships !== undefined) {
                const dataCopy: Scholarship[] = [...scholarships];
                Utils.sortAlphabetically(dataCopy, "clientScholarshipLevelDesc");
                setOriginalRows(dataCopy);
                setRows(dataCopy);
                dispatch(clearStatus());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingScholarshipState]);

    useEffect(() => {
        if (clients?.length > 0) {
            let workClients: ClientStatusCardViewModel[] = Utils.deepClone(clients);
            Utils.sortAlphabetically(workClients, "clientName");
            setSortedClients(workClients);
        }
    }, [clients])

    const getScholarships = async () => {
        dispatch(configGetScholarships({ paramId: paramId, clientId: Number(selectedClientId) }));
    }

    useEffect(() => {
        if (Number(selectedClientId) > 0) {
            getScholarships();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClientId]);

    const handleClientChange = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        setSelectedClientId(event.target.value);
    }

    const handleNewScholarshipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsEditModal(false);
        setSelectedScholarship({
            paramId: paramId,
            clientScholarshipLevelsId: 0,
            clientScholarshipLevelDesc: "",
            clientId: Number(selectedClientId),
            scholarshipPercentage: 0,
            fplApprovalThreshold: 0,
            incomeThreshold: 0,
        })
        setIsScholarshipModalOpen(true);
    };

    const handleMenuClose = (menuActionType?: MenuActionType) => {
        setAnchorEl(null);

        switch (menuActionType) {
            case "edit":
                setIsEditModal(true);
                setIsScholarshipModalOpen(true);
                break;
            case "delete":
                const deleteTitle = "Delete Scholarship: " + selectedScholarship?.clientScholarshipLevelDesc + "?";
                setDeleteTitle(deleteTitle);
                setIsDeleteModalOpen(true);
                break;
        }
    };

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        scholarshipId: number
      ) => {
        setAnchorEl(event.currentTarget);
        setSelectedScholarship(
            originalRows?.find(
                (el) => el.clientScholarshipLevelsId === scholarshipId
            )
        );
      };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
        ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function handleScholarshipModalCancel() {
        setIsScholarshipModalOpen(false);
    }

    async function handleScholarshipModalSubmit(isEditMode: boolean) {
        setIsScholarshipModalOpen(false);

        await getScholarships();
    }

    function handleDeleteCancel() {
        setIsDeleteModalOpen(false);
    }

    async function handleDeleteOk() {
        const deletePostBody: any = { ...selectedScholarship, paramId: paramId };
        await dispatch(configDeleteScholarship(deletePostBody));
        setIsDeleteModalOpen(false);
        await getScholarships();
    }

  return (
    <Paper>
        {isLoadingClients ? (
              <LoadingOverlay />
        ) : (
            <Box style={{ padding: "1em", marginBottom: "1.5em" }}>
                <Grid container justifyContent="space-between">
                    <Grid>
                        <Box style={{ padding: "1.5em" }}>
                            <Typography variant="subtitle2">Financial Assistance</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item  xs={12} sm={6}>
                        <FormControl sx={{width: '60%'}} >
                            {selectedClientId ? <InputLabel style={{fontSize: '18px'}} id="custom-select-label">Client</InputLabel>: 'Select a client'}
                            <Select
                                labelId="custom-select-label"
                                id="custom-select-label"
                                value={selectedClientId}
                                label="Client"
                                onChange={handleClientChange}
                            >
                                { sortedClients?.map((clientItem) => {
                                return <MenuItem key={clientItem.clientId} value={clientItem.clientId}>{clientItem.clientName}</MenuItem>
                                })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}></Grid>
                    <Grid item xs={12} sm={3} alignItems="top">
                        <Typography variant='body2'>
                            Support Scholarship
                        </Typography>
                        <Toggle
                            leftText="Yes"
                            rightText="No"
                            name="supportForScholarship"
                            value={ scholarshipFormik.values.supportForScholarship}
                            formik={scholarshipFormik}
                        />
                    </Grid>
                </Grid>
                <br />
                <TableContainer component={Paper}>
                    <Table
                        data-testid="results"
                        className="table alternating-row"
                        size="small"
                        stickyHeader
                        >
                        <TableHead>
                            <TableRow>
                            {columns && columns?.map((column) => (
                                <TableCell
                                key={column?.id}
                                align={column?.align}
                                style={{
                                    padding: "0.5em",
                                }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.slice!(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            ).map((row: any) => (
                                <TableRow key={row?.clientScholarshipLevelsId}>
                                    <TableCell align="left" style={{ padding: "0.5em" }}>
                                        {row?.clientScholarshipLevelDesc}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: "0.5em" }}>
                                        {row?.scholarshipPercentage}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: "0.5em" }}>
                                        {row?.fplApprovalThreshold}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: "0.5em" }}>
                                        {row?.incomeThreshold}
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                                handleMenuClick(e, row.clientScholarshipLevelsId);
                                            }}
                                            size="large">
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <IconButton
                            title="Add Scholarship"
                            color="primary"
                            onClick={(e) => handleNewScholarshipClick(e)}
                            size="large">
                            <AddBoxOutlinedIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <TablePagination
                            rowsPerPageOptions={[
                                10,
                                50,
                                100,
                                { value: rows?.length! | 0, label: "All" },
                            ]}
                            component="div"
                            count={rows?.length! | 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={"View"}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Grid>
                </TableContainer>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleMenuClose()}
                >
                    <MenuItem
                        className="primary"
                        onClick={() => handleMenuClose("edit")}
                    >
                        Edit
                    </MenuItem>
                    <MenuItem
                        className="danger"
                        onClick={() => handleMenuClose("delete")}
                    >
                        Delete
                    </MenuItem>
                </Menu>
                {isScholarshipModalOpen && (
                    <ScholarshipModal
                        open={isScholarshipModalOpen}
                        isEdit={isEditModal}
                        handleScholarshipCancel={handleScholarshipModalCancel}
                        handleScholarshipSubmit={handleScholarshipModalSubmit}
                        scholarshipItem={selectedScholarship!}
                    />
                )}
                {isDeleteModalOpen && (
                    <DeleteModal
                        open={isDeleteModalOpen}
                        title={ deleteTitle }
                        subTitle="This action cannot be undone"
                        okButtonText="Delete Forever"
                        handleDeleteCancel={handleDeleteCancel}
                        handleDeleteOk={handleDeleteOk}
                    />
                )}
            </Box>
          )
        }
    </Paper>
  )
}

export default ScholarshipGrid;