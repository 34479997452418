import {Button} from '@finpay-development/shared-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {formatNumberToUSD} from '../../../shared/calculators';
import {RootState} from '../../../shared/state/root-reducer';
import {AppDispatch} from '../../../shared/state/store';
import {Utils} from '../../../shared/utils';
import {PFRSummary, CrossoverPolicies} from '../../models/estimator';
import {setEstimator} from '../../state/estimator-slice';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import {EstFinancialSummaryModal} from './estimator-financial-summary-modal';
import {EstPlanYearCrossoverModal} from './estimator-plan-year-crossover-modal';
import CrossoverSelectionModal from './estimator-crossover-selection-modal';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';
import {
    CrossoverSelectionEnum,
    Estimate as NewEstimate,
    QuoteMethod,
} from '@finpay/estimation-types';
import {
    callNewEstimatePut,
} from 'src/admissions-advisor/state/estimator-thunk';

interface Crossover {
    isCrossover: boolean;
    crossoverDays: number;
}

export function SidebarPlanYearCrossover() {
    const state = {
        vobState: useSelector(
            (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
        ),
        estimatorState: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.estimator
        ),
        newEstimate: useSelector(
            (state: RootState) =>
                state.admissionsAdvisorContext.estimatorContext.newEstimate
        ),
    };

    const {vobState, estimatorState, newEstimate} = state;

    const [crossOverModalOpen, setCrossOverModalOpen] = useState(false);
    const [financialSummaryModalOpen, setFinancialSummaryModalOpen] =
        useState(false);
    const [currentPolicyPFR, setCurrentPolicyPFR] = useState<PFRSummary>();
    const [nextPolicyPFR, setNextPolicyPFR] = useState<PFRSummary>();
    const [showPlanYearCrossover, setShowPlanYearCrossover] = useState(false);
    const [crossOverPfrSelected, setCrossoverPfrSelected] = useState(false);
    const [lengthOfStay, setLengthOfStay] = useState(0);
    const [crossOverDays, setCrossOverDays] = useState(0);
    const [nonCrossoverPfr, setNonCrossoverPfr] = useState<number>(0.0);
    const [isCrossoverSelectionModalOpen, setIsCrossoverSelectionModalOpen] =
        useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const PFRName = 'Plan Crossover';

    const isClientConfiguredForNewEstimate =
        useIsClientConfiguredForNewEstimate();

    useEffect(() => {
        const planCrossover: Crossover = doesPlanCrossover();
        setShowPlanYearCrossover(planCrossover.isCrossover);
        setCrossOverDays(planCrossover.crossoverDays);
        getPFR();

        // update state
        if (
            estimatorState.planYearCrossover?.hasPlanYearCrossover !==
            planCrossover.isCrossover
        ) {
            let estimatorStateCopy = Utils.deepClone(estimatorState);
            Object.assign(estimatorStateCopy, {
                planYearCrossover: {
                    hasPlanYearCrossover: planCrossover.isCrossover,
                    crossoverDays: planCrossover.crossoverDays,
                    usingPlanYearCrossoverPfr:
                        estimatorState.planYearCrossover
                            ?.usingPlanYearCrossoverPfr,
                    planYearCrossoverConfirmed:
                        estimatorState.planYearCrossover
                            ?.planYearCrossoverConfirmed,
                },
            });
            dispatch(setEstimator(estimatorStateCopy));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estimatorState]);

    useEffect(() => {
        if (
            showPlanYearCrossover &&
            nonCrossoverPfr !== estimatorState.totalEstimatedPfr
        ) {
            let estimatorStateCopy = Utils.deepClone(estimatorState);
            estimatorStateCopy.totalEstimatedPfr = nonCrossoverPfr;
            dispatch(setEstimator(estimatorStateCopy));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nonCrossoverPfr]);

    const financialSummary = admissionsAdvisorUtils.calculateFinancialSummary(
        estimatorState,
        vobState
    );

    const doesPlanCrossover = (): Crossover => {
        let isCrossover: boolean = false;
        let crossOverDays: number = 0;
        const admissionBeginDate = new Date(estimatorState.admissionDate);
        const policyEndDate = new Date(vobState.policyEndDate);
        let los = 0;
        estimatorState?.selectedLevelsOfCare.forEach(loc => {
            los += loc?.facilityPayorLocRateLos?.losDays!;
        });
        let admissionEndDate = new Date(estimatorState.admissionDate);
        admissionEndDate.setDate(admissionBeginDate.getDate() + los);
        if (admissionEndDate > policyEndDate) {
            isCrossover = true;
        }
        if (isCrossover) {
            const diffTime = Math.abs(+admissionEndDate - +policyEndDate);
            crossOverDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            setLengthOfStay(los);
            const crossOverPolicies: CrossoverPolicies =
                admissionsAdvisorUtils.calculateCrossoverPfr(
                    crossOverDays,
                    estimatorState,
                    vobState
                );
            setCurrentPolicyPFR(
                crossOverPolicies.currentPolicyFinancialSummary
            );
            setNextPolicyPFR(crossOverPolicies.nextPolicyFinancialSummary);
        }
        return {
            isCrossover: isCrossover,
            crossoverDays: crossOverDays,
        };
    };

    const handleCallbackCancel = () => {
        setIsCrossoverSelectionModalOpen(false);
    };

    const handleCrossoverModalSubmit = (
        selectedPFRName: string,
        selectedPFRAmount: number
    ) => {
        let estimatorStateCopy = Utils.deepClone(estimatorState);
        estimatorStateCopy.selectedPFRName = PFRName;
        estimatorStateCopy.crossoverAdjustmentPfr = selectedPFRAmount;
        switch (selectedPFRName) {
            case 'AdjustedPFR':
                Object.assign(estimatorStateCopy, {
                    planYearCrossover: {
                        hasPlanYearCrossover:
                            estimatorStateCopy.planYearCrossover
                                .hasPlanYearCrossover,
                        crossoverDays:
                            estimatorStateCopy.planYearCrossover.crossoverDays,
                        usingPlanYearCrossoverPfr: true,
                        planYearCrossoverConfirmed: true,
                    },
                });
                break;
            default:
                Object.assign(estimatorStateCopy, {
                    planYearCrossover: {
                        hasPlanYearCrossover:
                            estimatorStateCopy.planYearCrossover
                                .hasPlanYearCrossover,
                        crossoverDays:
                            estimatorStateCopy.planYearCrossover.crossoverDays,
                        usingPlanYearCrossoverPfr: false,
                        planYearCrossoverConfirmed: true,
                    },
                });
        }
        dispatch(setEstimator(estimatorStateCopy));
        setCrossOverModalOpen(false);
    };

    const handleCrossoverModalCancel = () => {
        setCrossOverModalOpen(false);
    };

    const openCrossoverModal = () => {
        setCrossOverModalOpen(true);
    };

    const openFinancialSummaryModal = () => {
        if (estimatorState.planYearCrossover?.usingPlanYearCrossoverPfr) {
            setCrossOverModalOpen(true);
        } else {
            setFinancialSummaryModalOpen(true);
        }
    };

    const handleFinSumModalCancel = () => {
        setFinancialSummaryModalOpen(false);
    };

    const getSideBarColor = (): string => {
        let sideBarColor =
            estimatorState.selectedPFRName === PFRName ? '#6EACDE' : '#999999';
        return sideBarColor;
    };

    const getPFR = () => {
        const financialSummary =
            admissionsAdvisorUtils.calculateFinancialSummary(
                estimatorState,
                vobState
            );
        if (financialSummary.totalPFR !== nonCrossoverPfr) {
            setNonCrossoverPfr(financialSummary.totalPFR);
        }
    };

    const handlePlanCrossoverPfrSelected = () => {
        setCrossoverPfrSelected(!crossOverPfrSelected);
        if (estimatorState.selectedPFRName !== PFRName) {
            let estimatorStateCopy = {...estimatorState};
            estimatorStateCopy.selectedPFRName = PFRName;
            dispatch(setEstimator(estimatorStateCopy));
        }
    };

    let totalLengthOfStayWithCrossover = 0;
    let daysExceedCrossover = 0;

    if (newEstimate && newEstimate.crossOverSummary) {
        totalLengthOfStayWithCrossover =
            newEstimate.crossOverSummary.summaryWithCrossover.lengthOfStay;
        daysExceedCrossover =
            totalLengthOfStayWithCrossover -
            newEstimate.crossOverSummary.summaryBeforeCrossover.lengthOfStay;
    }

    const crossoverPFRToShow =
        newEstimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
            ? newEstimate.crossOverSummary?.summaryBeforeCrossover.totalPFR
            : newEstimate?.crossOverSummary?.summaryWithCrossover.totalPFR;

    const handleSaveUpdateCrossover = async (
        selection: CrossoverSelectionEnum
    ) => {
        if (newEstimate) {
            const putEstimateBody: NewEstimate = {
                vobId: newEstimate.vobId,
                clientId: newEstimate.clientId,
                facilityId: newEstimate.facilityId,
                advisorPatientId: newEstimate.advisorPatientId,
                ...(newEstimate.patientEncounterId && { patientEncounterId: newEstimate.patientEncounterId }),
                description: newEstimate.description,
                quoteMethod: newEstimate.quoteMethod || QuoteMethod.AVGLOS,
                anticipatedAdmitDate: newEstimate.anticipatedAdmitDate,
                facilityLevelOfCare: newEstimate.facilityLevelOfCare,
                isPlanYearCrossover: newEstimate.isPlanYearCrossover,
                isActive: true,
                crossoverSelection: selection,
                summarySelection: newEstimate.summarySelection,
            };

            dispatch(callNewEstimatePut({estimate: {...newEstimate, crossoverSelection: selection}, estimateId: newEstimate.estimateId}));
            setIsCrossoverSelectionModalOpen(false);
        }
    };

    return (
        <>
            {showPlanYearCrossover && !isClientConfiguredForNewEstimate && (
                <>
                    {estimatorState.planYearCrossover
                        ?.planYearCrossoverConfirmed ? (
                        <>
                            <Box
                                border={1}
                                borderColor={getSideBarColor()}
                                style={{
                                    backgroundColor: 'white',
                                    marginBottom: '1.5em',
                                }}
                            >
                                <Grid container direction="row">
                                    <Grid
                                        item
                                        direction="column"
                                        md={12}
                                    ></Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid
                                        item
                                        direction="column"
                                        md={12}
                                        xs={12}
                                        style={{
                                            color: 'white',
                                            backgroundColor: getSideBarColor(),
                                            padding: '0.25em',
                                        }}
                                    >
                                        Patient Financial Responsibility (w
                                        crossover)
                                    </Grid>
                                    <Grid
                                        item
                                        direction="row"
                                        md={12}
                                        style={{
                                            padding: '1em',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            style={{float: 'left'}}
                                        >
                                            Total Estimated PFR
                                        </Typography>

                                        <FormControlLabel
                                            checked={
                                                estimatorState.selectedPFRName ===
                                                PFRName
                                            }
                                            control={
                                                <Checkbox color="primary" />
                                            }
                                            id="totalPFR"
                                            name="totalPFR"
                                            label={
                                                estimatorState.planYearCrossover
                                                    ?.usingPlanYearCrossoverPfr ? (
                                                    <strong>
                                                        {formatNumberToUSD(
                                                            estimatorState.crossoverAdjustmentPfr
                                                        )}
                                                    </strong>
                                                ) : (
                                                    <strong>
                                                        {formatNumberToUSD(
                                                            estimatorState.totalEstimatedPfr
                                                        )}
                                                    </strong>
                                                )
                                            }
                                            labelPlacement="start"
                                            style={{float: 'right'}}
                                            onChange={() => {
                                                handlePlanCrossoverPfrSelected();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid
                                        item
                                        direction="column"
                                        md={12}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <IconButton
                                            color="primary"
                                            title="Open Details"
                                            style={{fontSize: '1.5rem'}}
                                            onClick={() =>
                                                openFinancialSummaryModal()
                                            }
                                        >
                                            <OpenInNewIcon fontSize="small" />{' '}
                                            Open Details
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    ) : (
                        <Box
                            border={1}
                            borderColor={'#E8DB47'}
                            style={{
                                backgroundColor: 'white',
                                marginBottom: '1.5em',
                            }}
                        >
                            <Grid container direction="row">
                                <Grid item direction="column" md={12}></Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    direction="column"
                                    md={12}
                                    xs={12}
                                    style={{
                                        backgroundColor: '#E8DB47',
                                        padding: '0.25em',
                                    }}
                                >
                                    Adjust PFR for Plan Year Crossover
                                </Grid>
                                <Grid
                                    item
                                    direction="column"
                                    md={12}
                                    style={{
                                        padding: '1em',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        style={{float: 'left'}}
                                    >
                                        Total length of stay of {lengthOfStay}{' '}
                                        days exceeds insurance plan end date (
                                        {Utils.convertISODateAsUTC(
                                            new Date(
                                                vobState.policyEndDate
                                            ).toISOString()
                                        )}
                                        ) by{' '}
                                        <strong>{crossOverDays} days</strong>.
                                    </Typography>

                                    <Typography align="right">
                                        <strong>
                                            {formatNumberToUSD(
                                                financialSummary.totalPFR
                                            )}
                                        </strong>
                                        <ReportProblemIcon
                                            sx={{
                                                fontSize: '1.25em',
                                                display: 'inline',
                                                color: '#E8DB47',
                                                marginLeft: '0.5em',
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid
                                    item
                                    direction="column"
                                    md={12}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        padding: '1em',
                                    }}
                                >
                                    <Button
                                        onClick={() => openCrossoverModal()}
                                    >
                                        Estimate Options
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    {crossOverModalOpen && (
                        <EstPlanYearCrossoverModal
                            open={crossOverModalOpen}
                            handlePlanYearCrossoverModalSubmit={
                                handleCrossoverModalSubmit
                            }
                            handlePlanYearCrossoverModalCancel={
                                handleCrossoverModalCancel
                            }
                            currentYearPFR={currentPolicyPFR}
                            crossOverPFR={nextPolicyPFR}
                        />
                    )}

                    {financialSummaryModalOpen && (
                        <EstFinancialSummaryModal
                            open={financialSummaryModalOpen}
                            handleFinancialSummaryModalCancel={
                                handleFinSumModalCancel
                            }
                        />
                    )}
                </>
            )}

            {isClientConfiguredForNewEstimate &&
                newEstimate &&
                newEstimate.isPlanYearCrossover && (
                    <Box
                        border={1}
                        borderColor={"#6EACDE"}
                        style={{
                            backgroundColor: "#FEEA00",
                            marginBottom: '1.5em',
                        }}
                    >
                        <Grid container direction="row">
                            <Grid item direction="column" md={12}></Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="column"
                                md={12}
                                xs={12}
                                style={{
                                    color: '#006fcf',
                                    backgroundColor: "#FEEA00",
                                    padding: '10px'
                                }}
                            >
                                Crossover Selection Options
                                {/* {`Crossover Options ${
                                    newEstimate?.crossoverSelection ===
                                    CrossoverSelectionEnum.BEFORE
                                        ? 'Before Crossover'
                                        : 'With Crossover'
                                } ${newEstimate?.priorCareSummary ? "& Prior Care Adjustment" : ""}`} */}
                            </Grid>
                            {/* <Grid
                                item
                                display="flex"
                                justifyContent={'space-between'}
                                direction="row"
                                md={12}
                                style={{
                                    padding: '1em',
                                }}
                            >
                                <Typography variant="body1">
                                    Total Estimated PFR
                                </Typography>
                                <Box sx={{fontWeight: 'bold'}}>
                                    {formatNumberToUSD(crossoverPFRToShow)}
                                </Box>
                            </Grid> */}
                        </Grid>
                        <Grid container direction="row">
                            <Grid
                                item
                                direction="row"
                                md={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    flexDirection: "column",
                                    padding: "1em 0px 0px 0px"
                                }}
                                sx={{
                                    padding: '1em',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    title="Open Crossover Selection"
                                    style={{
                                        fontSize: "1.5rem",
                                        width: "100%",
                                        backgroundColor: "#FEEA00",
                                        borderRadius: 0
                                    }}
                                    onClick={() => {
                                        setIsCrossoverSelectionModalOpen(true);
                                    }}
                                    test-id="crossover-selection-open-details"
                                >
                                    <OpenInNewIcon fontSize="small" />
                                    Open Crossover Selection
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                )}

            {isCrossoverSelectionModalOpen &&
                newEstimate &&
                isClientConfiguredForNewEstimate &&
                newEstimate.isPlanYearCrossover && (
                    <CrossoverSelectionModal
                        open={true}
                        estimate={newEstimate}
                        handleCallbackCancel={handleCallbackCancel}
                        handleSave={handleSaveUpdateCrossover}
                    />
                )}
        </>
    );
}
