import { Button } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Grid, Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AccessControl from '../../../../security/components/access-control';
import { RolePageNames } from '../../../../security/model/role-page-names';
import LockModal from '../../../../implementation-specialist/components/locks/lock-modal';
import { formatNumberToUSD } from '../../../../shared/calculators';
import HeaderWithDetails from '../../../../shared/components/header-with-details';
import { configSettings } from '../../../../shared/configuration/config-settings';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { Utils } from '../../../../shared/utils';
import { patientService } from '../../../services/patient-service';
import {
    resetPatientRecordLockingContext,
    setInUseModalOpen_insurance,
    setIsInsuranceModalOpen,
    setLockRequestedById,
    setLockStatusReleased_insurance,
    setRequestDeniedModalOpen_insurance,
} from '../../../state/patient-record-locking-slice';  
import {
  denyRequestOk,
  editModalPoll,
  releaseRecordOk,
  requestReleaseOk,
} from '../../../state/patient-record-locking-thunk';
import { resetPatientContext } from '../../../state/patient-slice';
import { lockIOC } from '../../../state/patient-thunk';
import { LockablePatientRecordSection } from '../../models/lockable-patient-record-section';
import { PatientInsurance } from '../../models/patient-insurance';
import { InsuranceModal } from './insurance-modal';

let editModalPollingInterval: any; // an interval assigned via setInterval

const InsuranceAccordionContents = () => {
    const [selectedInsuranceIndex, setSelectedInsuranceIndex] = useState(0);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isRequestReleaseButtonDisabled, setIsRequestReleaseButtonDisabled] =
        useState(false);

    const stateFields = {
        insuranceList: useSelector(
            (state: RootState) =>
                state.patientContext.selectedEncounter.patientInsurance
        ),
        userName: useSelector(
            (state: RootState) => state.userContext.userProfile.userName
        ),
        selectedPatientEncounter: useSelector(
            (state: RootState) => state.patientContext.selectedEncounter
        ),
        lockingModalOpenStatus: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.lockingModalOpenStatus
        ),
        patientModalOpenStatus: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.patientModalOpenStatus
        ),
        isLockStatusReleased_insurance: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.isLockStatusReleased_insurance
        ),
        lockRequestedById: useSelector(
            (state: RootState) =>
                state.patientRecordLockingContext.lockRequestedById
        ),
    };

    const {
        insuranceList,
        selectedPatientEncounter,
        userName,
        lockingModalOpenStatus,
        patientModalOpenStatus,
        isLockStatusReleased_insurance,
        lockRequestedById,
    } = stateFields;

    const countdownMax = configSettings.REACT_APP_patient_locking_countdown_max;
    const lockPollingInterval =
        configSettings.REACT_APP_patient_locking_polling_interval;

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const releasePatientLock = () => {
        setTimeout(async () => {
            await dispatch(lockIOC({lock: false})); // unlock the IOC
        }, 500);
    };

    const doModalPolling = useCallback(
        async (patientId: number, encounterId: number) => {
            editModalPollingInterval = setInterval(async () => {
                dispatch(
                    editModalPoll({
                        patientId,
                        encounterId,
                        userName,
                        lockablePatientRecordSection:
                            LockablePatientRecordSection.insurance,
                    })
                );
            }, lockPollingInterval);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [dispatch, userName]
    );

    useEffect(() => {
        if (isLockStatusReleased_insurance) {
            doModalPolling(
                selectedPatientEncounter?.patientId,
                selectedPatientEncounter?.patientEncounterId
            );
            dispatch(setLockStatusReleased_insurance(false));
        }
    }, [
        dispatch,
        doModalPolling,
        isLockStatusReleased_insurance,
        selectedPatientEncounter?.patientId,
        selectedPatientEncounter?.patientEncounterId,
    ]);

    const handleInsuranceModalClose = () => {
        releasePatientLock();
        dispatch(setIsInsuranceModalOpen(false));
        clearInterval(editModalPollingInterval);
    };

    // const handleEditModalClick = async(insurance: PatientInsurance) => {
    //     dispatch(setIsInsuranceModalOpen(true));
    //     setIsEditModal(true);
    //     const insuranceIndex = insuranceList?.findIndex(
    //       (item: PatientInsurance) => item?.patientInsuranceId === insurance?.patientInsuranceId
    //     );
    //     setSelectedInsuranceIndex(insuranceIndex);
    
    
    //     const patientId = selectedPatientEncounter.patientId;
    //     const encounterId = selectedPatientEncounter.patientEncounterId;
    
    //     const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId, encounterId});
    //     const {isLocked} = instanceOfCare?.entity || false; // Needed to add the default value to avoid undefined for brand new patient
    
    //     if (isLocked && instanceOfCare?.entity?.lockedByUserId !== userName && instanceOfCare?.entity?.lockedByUserId !== null) { // extra checks in case of bad data
    //       setIsRequestReleaseButtonDisabled(false);
    //       dispatch(setLockRequestedById(instanceOfCare?.entity?.lockedByUserId));
    //       dispatch(setInUseModalOpen_insurance(true));
    //     } else {
    //       // patient not locked by anyone. Go ahead and lock it for me so others are locked out
    //       dispatch(setLockRequestedById(undefined));
    //       await dispatch(lockIOC({lock: true, username: userName}))
    
    //       await doModalPolling(patientId, encounterId);
    //     }
    //   }
    

    const handleAddModalClick = () => {
        dispatch(setIsInsuranceModalOpen(true));
        setIsEditModal(false);
        const insuranceIndex = insuranceList?.length > 0 ? 1 : 0;
        setSelectedInsuranceIndex(insuranceIndex);
    };

    const handleRequestReleaseOk = async () => {
        setIsRequestReleaseButtonDisabled(true);
        dispatch(
            requestReleaseOk({
                userName: userName,
                lockablePatientRecordSection:
                    LockablePatientRecordSection?.insurance,
            })
        );
    };

    const handleRequestReleaseCancel = () => {
        navigate('/specialist/dashboard');
        resetRedux();
        dispatch(setInUseModalOpen_insurance(false));
    };

    const handleReleaseRecordOk = async () => {
        dispatch(
            releaseRecordOk({
                lockablePatientRecordSection:
                    LockablePatientRecordSection?.insurance,
            })
        );
        dispatch(setIsInsuranceModalOpen(false));
        clearInterval(editModalPollingInterval);
    };

    const handleRequestDeniedClose = () => {
        resetRedux();
        navigate('/specialist/dashboard');
        dispatch(setRequestDeniedModalOpen_insurance(false));
    };

    const resetRedux = () => {
        dispatch(resetPatientContext());
        dispatch(resetPatientRecordLockingContext());
    };

    // const addInsuranceButton = (
    //     <div className="ml-2">
    //       <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
    //         renderNoAccess={() => <Button disabled>Additional Insurance</Button>}>
    //         <Button
    //           type="text"
    //           icon={<AddIcon />}
    //           paddingLeft={0}
    //           onClick={() => handleAddModalClick()}
    //         >
    //           Additional Insurance
    //         </Button>
    //       </AccessControl>
    //       <Box justifyContent="flex-end" className="px-4 py-4">
    //         <Typography variant="h4" style={{ marginTop: -5 }}>
    //           Optional
    //         </Typography>
    //       </Box>
    //     </div>
    //   )


    const accordionContents = (insurance: PatientInsurance) => (
        <React.Fragment key={insurance?.patientInsuranceId}>
            <Grid item xs={10}>
                <Typography variant="subtitle2">
                    Verification Of Benefits
                </Typography>
            </Grid>
            {/* <Box justifyContent="flex-end">
        <Grid item xs={2}>
          {!(selectedPatientEncounter?.isConverted) && (
            <AccessControl rolePageName={RolePageNames.PatientRecords} actionName="Add Instance of Care"
              renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
              <IconButton onClick={() => handleEditModalClick(insurance)} size="large"> <EditIcon className="icon" /></IconButton>
            </AccessControl>
          )}
        </Grid>
      </Box> */}
            <HeaderWithDetails
                header="Provider"
                details={insurance?.insuranceCompanyName}
                width="half"
            />
            <HeaderWithDetails
                header="Plan"
                details={insurance?.insurancePlanName}
                width="half"
            />
            <HeaderWithDetails
                header="Policy Number"
                details={insurance?.policyNumber}
                width="half"
            />
            <HeaderWithDetails
                header="Group Number"
                details={insurance?.groupNumber}
                width="half"
            />
            <HeaderWithDetails
                header="In Network?"
                details={Utils.convertBoolToYesNo(insurance?.isInNetwork)}
                width="full"
            />
            <HeaderWithDetails
                header="Deductible"
                details={formatNumberToUSD(insurance?.deductibleAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Remaining Deductible"
                details={formatNumberToUSD(insurance?.remDeductibleAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Family Deductable"
                details={formatNumberToUSD(insurance?.familyDeductibleAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Remaining Family Deductable"
                details={formatNumberToUSD(insurance?.familyRemDeductibleAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Coinsurance"
                details={
                    insurance?.coinsuranceType === '$'
                        ? formatNumberToUSD(insurance?.coinsuranceAmt)
                        : insurance?.coinsuranceAmt
                        ? `${insurance?.coinsuranceAmt}%`
                        : 'N/A'
                }
                width="full"
            />
            <HeaderWithDetails
                header="Subject to Copay?"
                details={Utils.convertBoolToYesNo(insurance?.isSubjectToCopay)}
                width="half"
            />
            <HeaderWithDetails
                header="Copay"
                details={formatNumberToUSD(insurance?.copayAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Max Days"
                details={insurance?.maxDays?.toString()}
                width="half"
            />
            <HeaderWithDetails
                header="OOP MAX"
                details={formatNumberToUSD(insurance?.oopmaxAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Remaining OOP MAX "
                details={formatNumberToUSD(insurance?.remainingOOPMaxAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Family OOP MAX "
                details={formatNumberToUSD(insurance?.familyOOPMaxAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Family Remaining OOP MAX "
                details={formatNumberToUSD(insurance?.familyRemainingOOPMaxAmt)}
                width="half"
            />
            <HeaderWithDetails
                header="Deductible Included in Out of Pocket Max?"
                details={Utils.convertBoolToYesNo(
                    insurance?.deductibleIncInOOPMaxFl
                )}
                width="half"
            />
        </React.Fragment>
    );

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
            >
                {insuranceList.map((insurance: PatientInsurance) => (
                    accordionContents(insurance)
                ))}
              {/* {(!(selectedPatientEncounter?.isConverted)) && addInsuranceButton} */}
            </Grid>
            <InsuranceModal
                open={patientModalOpenStatus?.isInsuranceModalOpen}
                handleModalClose={handleInsuranceModalClose}
                selectedInsuranceIndex={selectedInsuranceIndex}
                isEdit={isEditModal}
            />

            {lockingModalOpenStatus?.isInUseModalOpen_insurance && (
                <LockModal
                    open={lockingModalOpenStatus?.isInUseModalOpen_insurance}
                    title="Instance of Care In Use"
                    subTitle1={`A release request will be sent to ${lockRequestedById}`}
                    subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
                    hasCountdown={false}
                    countdownSeconds={countdownMax}
                    primaryButtonType="primary"
                    primaryButtonText="Request Release"
                    handlePrimaryButton={handleRequestReleaseOk}
                    secondaryButtonType="secondary"
                    secondaryButtonText="Cancel"
                    handleSecondaryButton={handleRequestReleaseCancel}
                    icon={<LockIcon />}
                    modalType="info"
                    justifyButtons="flex-end"
                    primaryButtonDisabled={isRequestReleaseButtonDisabled}
                />
            )}

            {lockingModalOpenStatus?.isRequestDeniedModalOpen_insurance && (
                <LockModal
                    open={
                        lockingModalOpenStatus?.isRequestDeniedModalOpen_insurance
                    }
                    title="Request Denied"
                    subTitle1={`${lockRequestedById} will retain access to this record`}
                    hasCountdown={false}
                    primaryButtonType="secondary"
                    primaryButtonText="Close"
                    handlePrimaryButton={handleRequestDeniedClose}
                    icon={<LockIcon />}
                    modalType="error"
                    justifyButtons="flex-end"
                />
            )}

            {lockingModalOpenStatus?.isLockRequestedModalOpen_insurance && (
                <LockModal
                    open={
                        lockingModalOpenStatus?.isLockRequestedModalOpen_insurance
                    }
                    title="Instance of Care Requested"
                    subTitle1={`${lockRequestedById} requested this record`}
                    subTitle2={`Access will be granted in ${countdownMax} seconds or less`}
                    hasCountdown
                    countdownSeconds={countdownMax}
                    primaryButtonType="primary"
                    primaryButtonText="Release Record"
                    handlePrimaryButton={handleReleaseRecordOk}
                    secondaryButtonType="danger"
                    secondaryButtonText="Deny Request"
                    handleSecondaryButton={() =>
                        dispatch(
                            denyRequestOk({
                                lockablePatientRecordSection:
                                    LockablePatientRecordSection?.insurance,
                            })
                        )
                    }
                    icon={<WarningRoundedIcon />}
                    modalType="warning"
                    justifyButtons="space-between"
                />
            )}
        </>
    );
};

export default InsuranceAccordionContents
