
export interface AccountHolderContext {
    userName?: string | null;
    userId?: string | null;
    isPatient?: boolean | null;
    isExistingEmail?: boolean | null;
    firstName?: string | null;
    lastName?: string | null;
}

export const emptyAccountHolderContext: AccountHolderContext = {
    userName: null,
    userId: null,
    isPatient: null,
    isExistingEmail: null,
    firstName: null,
    lastName: null
}