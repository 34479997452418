import { Button, Status } from '@finpay-development/shared-components';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Divider, IconButton, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { showErrorStatus } from '../../../security/state/user-slice';
import { StatusColors, userRoles } from '../../../shared/enums';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { FilteredUserInfo } from '../../models/filtered-user-info';
import { setUsersCsvData } from '../../state/users/admin-slice';
import {CognitoStatus} from '../../models/cognito-statuses';

type UserActionType = "edit" | "details"  |  "resend" | "deactivate" | "reactivate";
interface AdminUsersResultsProps {
  data: FilteredUserInfo[];
  onRowMenuEdit: (selectedUserId: number) => void;
  onRowMenuDetails: (selectedUserId: number) => void;
  onRowMenuResendInvitation: (selectedUserId: number) => void;
  onRowMenuDeactivate: (selectedUserId: number) => void;
  onRowMenuReactivate: (selectedUserId: number) => void;
  handleGetUserCognitoStatus: (selectedUserId: number) => void;
  isUserSearchActive: boolean;
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: Column[] = [
  // id must be the key in the FilteredUserInfo model
  { id: "fullName", label: "Name", minWidth: 170 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "userRole", label: "Role", minWidth: 170 },
  { id: "status", label: "Status", minWidth: 170 },
  { id: "menu", label: "", minWidth: 25 },
];

function AdminUsersFilterResults(props: AdminUsersResultsProps) {
  const {
    data,
    onRowMenuEdit,
    onRowMenuDetails,
    onRowMenuResendInvitation,
    onRowMenuDeactivate,
    onRowMenuReactivate,
    handleGetUserCognitoStatus,
    isUserSearchActive
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    allUsers: useSelector((state: RootState) => {
      return state.adminContext.adminUserContext.userSearch.allUsers;
    }),
    usersCSVData: useSelector((state: RootState) => {
      return state.adminContext.adminUserContext.usersCsvData;
    }),
    currentUserRole: useSelector((state: RootState) => {
      return state.adminContext.adminRoleContext?.currentUserRole?.userRole
    }),
    selectedUserCognitoStatus: useSelector((state: RootState) => {
      return state.adminContext?.adminUserContext?.selectedUser?.cognitoStatus
    }),
    isLoadingCognitoStatus: useSelector((state: RootState) => {
      return state.adminContext?.adminUserContext?.isLoadingCognito
    }),
  }
  const { usersCSVData, currentUserRole, isLoadingCognitoStatus, selectedUserCognitoStatus } = stateFields;

  useEffect(() => {
    setPage(0)
     if(data.length === 0 && isUserSearchActive){
      dispatch(showErrorStatus("No users found for filter criteria entered"))
    }
  }, [data,isUserSearchActive, dispatch])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showEditOption, setHideEditOption] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    userId: number,
    userRole: string
  ) => {
    if (
      currentUserRole?.roleName?.toLowerCase() === userRoles?.accountHolder?.toLowerCase() ||
      currentUserRole?.roleName?.toLowerCase() === userRoles?.clientOwner?.toLowerCase()
    ){
      setHideEditOption(false)
    }
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
    handleGetUserCognitoStatus(userId);
  };

  const getUserById = (userId: number) => (
    data.find((user: FilteredUserInfo) => (
      user.userId === userId
    ))
  );

  const handleClose = (actionType?: UserActionType) => {
    setAnchorEl(null);
    setHideEditOption(true)

    switch (actionType) {
      case "edit":
        onRowMenuEdit(selectedUserId);
        break;
      case "details":
        onRowMenuDetails(selectedUserId);
        break;
      case "resend":
        onRowMenuResendInvitation(selectedUserId);
        break;
      case "deactivate":
        onRowMenuDeactivate(selectedUserId);
        break;
      case "reactivate":
        onRowMenuReactivate(selectedUserId);
        break;
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (data && (data?.length > 0)) {
      let fullTable = [];
      let rowValues: string[] = []
      rowValues.push('Name', 'Email', 'Role', 'Status')
      fullTable.push(rowValues)
      data.forEach((user: any) => {
        rowValues = [];
        const status = user?.isActive ? "Active" : "Inactive";
        rowValues.push(
          `${user?.fullName || ''}`,
          `${user?.email || ''}`,
          `${user?.userRole?.roleName || ''}`,
          `${status}`,
        )
        fullTable.push(rowValues)
      })

      dispatch(setUsersCsvData(fullTable))
    }
  }, [data, dispatch])

  const disabledDownloadButton = (<Button
      type="text"
      icon={<GetAppIcon />}
      paddingLeft={0}
      disabled
    >
      Download File
  </Button>)

  return (
    <Paper className="mt-8">
      <Box display="flex" justifyContent="flex-end" marginRight={-2}>
        <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
          renderNoAccess={() => (disabledDownloadButton)}
        >
          {usersCSVData ? (
            <CSVLink data={usersCSVData} filename={'Users_Data.csv'}>
            <Button
              type="text"
              icon={<GetAppIcon />}
              paddingLeft={0}
            >
              Download File
            </Button>
          </ CSVLink>
          ) : (disabledDownloadButton)
        }
        </AccessControl>
      </Box>
      <Divider />
      <>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, { value: data.length, label: 'All'  }]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"View"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table
            test-id="results"
            className="table alternating-row"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user: FilteredUserInfo) => {
                  return (
                    <TableRow
                      test-id="result-row"
                      hover
                      tabIndex={-1}
                      key={user.userId}
                    >
                      {columns.map((column) => {
                        const value = (user as any)[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {
                              column.id!== "status" && (
                                <Typography variant="body1">{value}</Typography>
                              )
                            }
                            {
                              column.id === "status" &&
                              (
                                <Status text={value} statusColor={
                                  value === "Active" ? StatusColors.success :
                                  (value === "Pending" ? StatusColors.warning
                                  : StatusColors.error)
                                } />
                              )
                            }
                            {column.id === "menu" && (
                              <>
                                <IconButton
                                  aria-controls="simple-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(e, user.userId, user.userRole)}
                                  size="large">
                                  <MoreHorizIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>

          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
          >
            {showEditOption &&
              <div>
                <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
                  renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}>
                  <MenuItem onClick={() => handleClose("edit")}>
                    <Typography color='primary' variant='inherit' >
                      Edit
                    </Typography>
                  </MenuItem>
                </AccessControl>
              </div>
              }
              {/* hide user details modal until it can be populated with data. see POR-929 */}
                  {/* <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
                    renderNoAccess={() => <MenuItem disabled>Details</MenuItem>}>
                    <MenuItem onClick={() => handleClose("details")}>
                      <Typography color='primary' variant='inherit'>
                        Details
                      </Typography>
                    </MenuItem>
                  </AccessControl> */}
              {isLoadingCognitoStatus ? (
                  <div>
                    <LinearProgress variant="indeterminate" />
                  </div>
              ) : (
                <div>
                  {selectedUserId && data?.length > 0 && (selectedUserCognitoStatus?.UserStatus === CognitoStatus.confirmed || selectedUserCognitoStatus?.UserStatus === CognitoStatus.forceChangePw)
                      && (
                    <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
                      renderNoAccess={() => <MenuItem disabled>Resend</MenuItem>}>
                      <MenuItem onClick={() => handleClose("resend")}>
                        <Typography color='primary' variant='inherit' >
                          Resend
                        </Typography>
                      </MenuItem>
                    </AccessControl>
                  )}
                </div>
              )}
              <div>
                {selectedUserId && data?.length > 0 && getUserById(selectedUserId)?.status === "Active" && ( // active users can be deactivated
                  <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
                    renderNoAccess={() => <MenuItem disabled>Deactivate</MenuItem>}>
                    <MenuItem onClick={() => handleClose("deactivate")}>
                      <Typography color='error' variant='inherit'>
                        Deactivate
                      </Typography>
                    </MenuItem>
                  </AccessControl>
                )}
                {selectedUserId && data?.length > 0 && getUserById(selectedUserId)?.status !== "Active" && ( // deactivated users can be activated
                  <AccessControl rolePageName={RolePageNames.Users} actionName="Add New User"
                    renderNoAccess={() => <MenuItem disabled>Reactivate</MenuItem>}>
                    <MenuItem onClick={() => handleClose("reactivate")}>
                      <Typography color='primary' variant='inherit'>
                        Reactivate
                      </Typography>
                    </MenuItem>
                  </AccessControl>
                )}
              </div>
          </Menu>
        </TableContainer>
      </>
    </Paper>
  );
}

export default AdminUsersFilterResults;
