import { Grid, Typography } from "@mui/material";
import React from "react";

export function PfrListHeader() {
    return (
        <div className="pt-6 px-2 pb-2 pr-3 mb-4">
            <Grid container spacing={2} className="header">
            <Grid item xs={2} className="ml-8">
                <Typography variant="h4">
                Terms
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <Typography variant="h4">
                Total Down Payment
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h4">
                Recurring
                </Typography>
            </Grid>
            </Grid>
        </div>
    )
}