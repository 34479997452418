import { saveCallbackStatus } from '@finpay-development/shared-components';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    emptyVob,
    FacilityPayers,
    Vob,
    vobClassificationsItem,
} from '../models/vob';
import { getEstimatorFacilityPayers, getVob, getVobClassifications, saveVob } from './vob-thunk';

interface vobSliceShape {
  vob: Vob,
  vobClassifications: vobClassificationsItem[],
  facilityPayers: FacilityPayers[],
  isLoading: {
    vobLoadingStatus: saveCallbackStatus,
    isLoadingVob: boolean,
    isLoadingGetVob: boolean,
    isLoadingVobClassifications: boolean,
    vobClassificationsLoadingStatus: saveCallbackStatus,
    isLoadingFacilityLocPayers: boolean,
  }
  isError: boolean,
  urlParams?: any
}

const initialState: vobSliceShape = {
    vob: emptyVob,
    vobClassifications: [],
    facilityPayers: [],
    isLoading: {
        vobLoadingStatus: saveCallbackStatus.none,
        isLoadingVob: false,
        isLoadingGetVob: false,
        isLoadingVobClassifications: false,
        vobClassificationsLoadingStatus: saveCallbackStatus.none,
        isLoadingFacilityLocPayers: false,
    },
    isError: false,
    urlParams: undefined,
};

export const vobSlice = createSlice({
	name: "vobContext",
	initialState,
	reducers: {
		setVOB: (state, action) => {
			state.vob = {
        ...state.vob,
        ...action.payload};
		},
		clearVOB: (state) => {
			state.vob = initialState.vob;
		},
		clearFacilityPayers: (state) => {
			state.facilityPayers = initialState.facilityPayers;
		},
		clearVobClassificationsLoadingStatus: (state) => {
			state.isLoading.vobClassificationsLoadingStatus = initialState.isLoading.vobClassificationsLoadingStatus;
		},
    clearPaymentURLParams: (state) => {
        if (state.vob.urlParams.hasOwnProperty('op')) {
           delete state.vob.urlParams.op;
        }
		},
	},
  extraReducers: (builder) => {
    builder.addCase(saveVob.pending, (state, action) => {
      state.isLoading.isLoadingGetVob = true;
    });
    builder.addCase(saveVob.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading.isLoadingGetVob = false;
      state.vob = action.payload.vobBody;
      state.vob.fpClientId = action.payload.fpClientId
      state.vob.vobId = action.payload.vobId;
    });
    builder.addCase(saveVob.rejected, (state, action) => {
      state.isLoading.isLoadingGetVob = false;
      state.isError = true;
    });
    builder.addCase(getVob.pending, (state, action) => {
      state.isLoading.isLoadingVob = true;
    });
    builder.addCase(getVob.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading.isLoadingVob = false;
      state.vob = action.payload.vobBody;
      state.vob.vobId = action.payload.vobId;
    });
    builder.addCase(getVob.rejected, (state, action) => {
      state.isLoading.isLoadingVob = false;
      state.isError = true;
    });
    builder.addCase(getVobClassifications.pending, (state, action) => {
      state.isLoading.isLoadingVobClassifications = true;
      state.isLoading.vobClassificationsLoadingStatus = saveCallbackStatus.none;
    });
    builder.addCase(getVobClassifications.fulfilled, (state, action) => {
      state.isLoading.isLoadingVobClassifications = false;
      state.isLoading.vobClassificationsLoadingStatus = saveCallbackStatus.success;
      state.vobClassifications = action.payload;
    });
    builder.addCase(getVobClassifications.rejected, (state, action) => {
      state.isLoading.isLoadingVobClassifications = false;
      state.isLoading.vobClassificationsLoadingStatus = saveCallbackStatus.error;
      state.isError = true;
    });
    builder.addCase(getEstimatorFacilityPayers.pending, (state, action) => {
      state.isLoading.isLoadingFacilityLocPayers = true;
    });
    builder.addCase(getEstimatorFacilityPayers.fulfilled, (state, action) => {
      state.isLoading.isLoadingFacilityLocPayers = false;
      state.facilityPayers = [...action.payload!].sort((a, b) => a.facilityPayorName! > b.facilityPayorName! ? 1 : -1)
    });
    builder.addCase(getEstimatorFacilityPayers.rejected, (state, action) => {
      state.isLoading.isLoadingFacilityLocPayers = false;
      state.isError = true;
    });
  },
});

export const { setVOB, clearVOB, clearFacilityPayers, clearVobClassificationsLoadingStatus, clearPaymentURLParams } = vobSlice.actions;

export default vobSlice.reducer;
