import { Button } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

interface AccountHelpModalProps {
    isOpen: boolean;
    fieldLabel: string;
    fieldTextDesc: string;
    handleModalCancel: () => void;
  }

export function AccountHelpModal(props: AccountHelpModalProps) {

    const { isOpen, fieldLabel, fieldTextDesc, handleModalCancel } = props;

    return (
        <Dialog
            scroll="paper"
            className="modal user-modal"
            open={isOpen}
            aria-labelledby="customized-dialog-title"
            maxWidth="md"
        >
            <DialogTitle>
                {fieldLabel} Help
            </DialogTitle>
            <DialogContent dividers>
                <Grid container direction="row" >
                    <Grid item direction="column" md={12} xs={12} style={{textAlign: "center", padding: "2em"}}>
                        <h2>Need help finding your {fieldLabel}? <br/> Here are a few tips:</h2>
                    </Grid>
                </Grid>
                <Grid container direction="row" >
                    <Grid item md={1} xs={12}></Grid>
                    <Grid item direction="column" md={11} xs={12} style={{textAlign: "left", fontStyle: "italic", fontSize: "18px"}}>
                        {fieldLabel}:
                    </Grid>
                </Grid>
                <Grid container direction="row" >
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item direction="column" md={10} xs={12} style={{textAlign: "left", fontStyle: "italic", fontSize: "12px"}}>
                    {fieldTextDesc}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ borderTop: "", marginTop: "0", padding: "2rem" }}>
                <Button onClick={handleModalCancel}>
                Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

