import { emptyPageState, RolePageState } from "./role-page-state";

export class AdminNavigationState {
    isEditMode!: boolean;
    userRoleId?: number;
    roleName!: string;
    roleDescription!: string;
    pageKeys!: string[];
    navConfiguration!: RolePageState
    navRoles!: RolePageState;
    navUsers!: RolePageState;
    navLogs!: RolePageState;
    navReports!: RolePageState;
    navClients!: RolePageState;
    navPatientRecords!: RolePageState;
    navClientPortal!: RolePageState;
    navPatientDashboard!: RolePageState;
    navAdmissionsAdvisor!: RolePageState;
}

export const emptyAdminNavigationState: AdminNavigationState = {
    isEditMode: false,
    roleName: "",
    roleDescription: "",
    pageKeys: [],
    navConfiguration: emptyPageState,
    navRoles: emptyPageState,
    navUsers: emptyPageState,
    navLogs: emptyPageState,
    navReports: emptyPageState,
    navClients: emptyPageState,
    navPatientRecords: emptyPageState,
    navClientPortal: emptyPageState,
    navPatientDashboard: emptyPageState,
    navAdmissionsAdvisor: emptyPageState,
}