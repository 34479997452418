export interface PatientPaymentSchedule {
  patientPaymentScheduleId?: number;
  pfrAmt: number;
  pfrBalance: number;
  paymentFreq: string;
  paymentStartDay: string;
  scheduleStartDt: string;
  downPmtAmt: number;
  downPmtDt: string;
  terms: number;
  remainingTerms: number;
  paymentDueAmt: number;
  nextPaymentDueDt: string;
  lastPaymentSuccessDt: string;
  lastPaymentFailureDt?: string;
  missedPmts: number;
  totalMissedPmts: number;
  scheduleStatus: string;
  scheduleDt: string;
  holdStatusEndDate: string;
  pfrCapturedBalance?: number;
  pfrPendingBalance?: number;
}

export const emptyPatientPaymentSchedule: PatientPaymentSchedule = {
  pfrAmt: 0,
  pfrBalance: 0,
  paymentFreq: '',
  paymentStartDay: '',
  scheduleStartDt: '',
  downPmtAmt: 0,
  downPmtDt: '',
  terms: 0,
  remainingTerms: 0,
  paymentDueAmt: 0,
  nextPaymentDueDt: '',
  lastPaymentSuccessDt: '',
  lastPaymentFailureDt: '',
  missedPmts: 0,
  totalMissedPmts: 0,
  scheduleStatus: '',
  scheduleDt: '',
  holdStatusEndDate: '',
}