import "../../../scss/components/_forms.scss";
import { LoadingOverlay } from "@finpay-development/shared-components";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RecentlyViewedTypes } from "../../../shared/enums";
import {
  RecentlyViewedUser,
  UsersCookiesList,
} from "../../../shared/model/recentlyViewed";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { patientHelper } from "../../services/patient-service";
import {
  clearSelectedEncounter,
  convertedClientFacilityCheckboxData,
  preConvertedClientFacilityCheckboxData,
  setConvertedFacilityCheckboxChecked,
  setPreConvertedFacilityCheckboxChecked,
  setRedirectToId,
} from "../../state/patient-slice";
import {
  getConvertedPatients,
  getInstanceOfCare,
  getPreconvertedPatients,
  setRecentlyViewedPatient,
} from "../../state/patient-thunk";
import { WorkflowId } from "../misc/patient-status";
import { PatientEncounterCard } from "../models/patient-encounter-card";
import PatientFilterResults from "./patient-filter-results";
import PatientFilter from "./patient-filters";

function PatientTiles() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const stateFields = {
    preconvertedPatients: useSelector((state: RootState) => {
      return state.patientContext.preconvertedPatients;
    }),
    convertedPatients: useSelector((state: RootState) => {
      return state.patientContext.convertedPatients;
    }),
    preconvertedPatientsFiltered: useSelector((state: RootState) => {
      return state.patientContext.preconvertedPatientsFiltered;
    }),
    convertedPatientsFiltered: useSelector((state: RootState) => {
      return state.patientContext.convertedPatientsFiltered;
    }),
    isPreConverted: useSelector((state: RootState) => {
      return state.patientContext.isPreConverted;
    }),
    isLoadingPreconverted: useSelector((state: RootState) => {
      return state.patientContext.isLoading.preconvertedPatients;
    }),
    isLoadingConverted: useSelector((state: RootState) => {
      return state.patientContext.isLoading.convertedPatients;
    }),
    redirectId: useSelector((state: RootState) => {
      return state.patientContext.redirectToId;
    }),
    userName: useSelector((state: RootState) => {
      return state.userContext.userProfile.userName;
    }),
  };

  const {
    preconvertedPatients,
    preconvertedPatientsFiltered,
    convertedPatientsFiltered,
    convertedPatients,
    isPreConverted,
    isLoadingPreconverted,
    isLoadingConverted,
    redirectId,
    userName,
  } = stateFields;

  const isLoading = isLoadingPreconverted || isLoadingConverted;

  useEffect(() => {
    if (redirectId !== 0) {
      const idToRedirectTo = redirectId;
      dispatch(setRedirectToId(0));
      navigate(`/specialist/dashboard/${idToRedirectTo}`); // if we add a Patient, we want to load their Encounter object and redirect to Demographics.
    }
  }, [dispatch, redirectId, navigate, preconvertedPatients]);

  useEffect(() => {
    if (isPreConverted) {
      dispatch(
        preConvertedClientFacilityCheckboxData(
          preconvertedPatients &&
            patientHelper.getClientsAndFacilities(preconvertedPatients)
        )
      );
    } else {
      dispatch(
        convertedClientFacilityCheckboxData(
          convertedPatients &&
            patientHelper.getClientsAndFacilities(convertedPatients)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preconvertedPatients, convertedPatients]); // Not including 'isPreConverted' as dependency. If it is then above relatively expensive function is called twice, once for each dependency

  useEffect(() => {
    if (isPreConverted) {
      (async () => {
        await dispatch(getPreconvertedPatients("3")); // range in months
        // ensures filter results take into account any checked clients/facility filters
        dispatch(setPreConvertedFacilityCheckboxChecked(-1));
      })();
    } else {
      (async () => {
        await dispatch(getConvertedPatients("3")); // range in months
        // ensures filter results take into account any checked clients/facility filters
        dispatch(setConvertedFacilityCheckboxChecked(-1));
      })();
    }
  }, [dispatch, isPreConverted]);

  let recentlyViewedPatients: PatientEncounterCard[] | any = [];
  let recentlyViewedPatientsConverted: PatientEncounterCard[] | any = [];
  const recentlyViewedListFromCookie: UsersCookiesList =
    Cookies.getJSON("recentlyViewed");

  if (recentlyViewedListFromCookie) {
    const { usersCookiesList } = recentlyViewedListFromCookie;
    const index = usersCookiesList?.findIndex(
      (recentlyViewed: RecentlyViewedUser) =>
        recentlyViewed.userName === userName
    );
    if (index >= 0 && preconvertedPatients.length > 0) {
      const preConvertedCookiPatients = patientHelper.removeNotFoundPatients(
        usersCookiesList[index].preConvertedPatients,
        preconvertedPatients
      );
      recentlyViewedPatients = preConvertedCookiPatients?.map(
        (recentlyViewedPatient) => {
          return preconvertedPatients.find(
            (patient: PatientEncounterCard) =>
              patient.patientId === recentlyViewedPatient.patientId &&
              patient.patientEncounterId === recentlyViewedPatient.encounterId
          );
        }
      );
      recentlyViewedPatients = patientHelper.setStatusColor(
        recentlyViewedPatients,
        WorkflowId.Preconverted
      );

      if (convertedPatients.length > 0 && convertedPatients.length > 0) {
        const convertedCookiePatients = patientHelper.removeNotFoundPatients(
          usersCookiesList[index].convertedPatients,
          convertedPatients
        );
        recentlyViewedPatientsConverted = convertedCookiePatients?.map(
          (recentlyViewedPatient) => {
            return convertedPatients.find(
              (patient: PatientEncounterCard) =>
                patient.patientId === recentlyViewedPatient.patientId &&
                patient.patientEncounterId === recentlyViewedPatient.encounterId
            );
          }
        );
        recentlyViewedPatientsConverted = patientHelper.setStatusColor(
          recentlyViewedPatientsConverted,
          WorkflowId.Converted
        );
      }
    }
  }

  const addPatientRecordMessage = (
    <Grid container justifyContent="center">
      <Typography variant="h1">Add Patient Record</Typography>
    </Grid>
  );

  const PatientsView = (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <PatientFilter
          preConvertedFilterCounts={{
            recentlyViewedPatients: recentlyViewedPatients?.length,
            reminders: preconvertedPatientsFiltered.reminders?.length,
            // new
            newPatients: preconvertedPatientsFiltered.newPatients?.length,
            newNone: preconvertedPatientsFiltered.newNone?.length,
            newPendingPaymentAtFacility: preconvertedPatientsFiltered.newPendingPaymentAtFacility?.length,
            // follow up
            followUpPatients:
              preconvertedPatientsFiltered.followUpPatients?.length,
            educationalCurriculum:
              preconvertedPatientsFiltered.educationalCurriculum?.length,
            paymentCurriculum:
              preconvertedPatientsFiltered.paymentCurriculum?.length,
            awaitingConsentDocs:
              preconvertedPatientsFiltered.awaitingConsentDocs?.length,
            awaitingHREApproval:
              preconvertedPatientsFiltered.awaitingHREApproval?.length,
            secondContactAttempt:
              preconvertedPatientsFiltered.secondContactAttempt?.length,
            thirdContactAttempt:
              preconvertedPatientsFiltered.thirdContactAttempt?.length,
            awaitingClient: preconvertedPatientsFiltered.awaitingClient?.length,
            patientChampion:
              preconvertedPatientsFiltered.patientChampion?.length,
            paymentMethodNeeded:
              preconvertedPatientsFiltered.paymentMethodNeeded?.length,
            noStatus: preconvertedPatientsFiltered.noStatus?.length,
            hreApproved: preconvertedPatientsFiltered.hreApproved?.length,
            hreApprovedPaymentConfirmationRequired:
              preconvertedPatientsFiltered
                .hreApprovedPaymentConfirmationRequired?.length,
            hreApprovedDirectBillFollowUp:
              preconvertedPatientsFiltered.hreApprovedDirectBillFollowUp
                ?.length,
            mobilePaymentFailureFollowUp:
              preconvertedPatientsFiltered.mobilePaymentFailureFollowUp?.length,
            mobilePaymentRetryLimit:
              preconvertedPatientsFiltered.mobilePaymentRetryLimit?.length,
            followUpNeededPendingPaymentAtFacility:
              preconvertedPatientsFiltered
                .followUpNeededPendingPaymentAtFacility?.length,
            // closed
            closedPatients: preconvertedPatientsFiltered.closedPatients?.length,
            fileError: preconvertedPatientsFiltered.fileError?.length,
            patientFailedToEngage:
              preconvertedPatientsFiltered.patientFailedToEngage?.length,
            hreRejectedByPatient:
              preconvertedPatientsFiltered.hreRejectedByPatient?.length,
            hreRejectedByClient:
              preconvertedPatientsFiltered.hreRejectedByClient?.length,
            patientNeverAdmitted:
              preconvertedPatientsFiltered.patientNeverAdmitted?.length,
            finpayFailedToEngage:
              preconvertedPatientsFiltered.finpayFailedToEngage?.length,
            duplicateRecord:
              preconvertedPatientsFiltered.duplicateRecord?.length,
            patientCoveredAt100Percent:
              preconvertedPatientsFiltered.patientCoveredAt100Percent?.length,
            hreApprovedForPaymentAtFacilityPaymentConfirmed:
              preconvertedPatientsFiltered
                .hreApprovedForPaymentAtFacilityPaymentConfirmed?.length,
            hreApprovedForFullScholarship:
              preconvertedPatientsFiltered.hreApprovedForFullScholarship
                ?.length,
            mobilePaymentFailureClosed:
              preconvertedPatientsFiltered.mobilePaymentFailureClosed?.length,
            mobilePaymentRetryLimitClosed:
              preconvertedPatientsFiltered.mobilePaymentRetryLimitClosed
                ?.length,
          }}
          convertedFilterCounts={{
            recentlyViewedPatientsConverted:
              recentlyViewedPatientsConverted?.length,
            reminders: convertedPatientsFiltered.reminders?.length,
            goodStanding: convertedPatientsFiltered?.goodStanding?.length,
            paid: convertedPatientsFiltered.paid?.length,
            // priority
            priority:
              convertedPatientsFiltered.awaitingAuthorizations?.length +
              convertedPatientsFiltered.awaitingACHVerification?.length,
            awaitingAuthorizations:
              convertedPatientsFiltered.awaitingAuthorizations?.length,
            awaitingACHVerification:
              convertedPatientsFiltered.awaitingACHVerification?.length,
            // at risk
            atRisk: convertedPatientsFiltered.atRisk?.length,
            missedPayment: convertedPatientsFiltered.missedPayment?.length,
            pastDueBalance: convertedPatientsFiltered.pastDueBalance?.length,
            onHold: convertedPatientsFiltered.onHold?.length,
            // closed
            fileError_converted:
              convertedPatientsFiltered.fileError_converted?.length,
            closed_converted: convertedPatientsFiltered.closed?.length,
            patientFailedToEngage_converted:
              convertedPatientsFiltered.patientFailedToEngage_converted?.length,
            patientNeverAdmitted_converted:
              convertedPatientsFiltered.patientNeverAdmitted_converted?.length,
            balanceAdjustmentRequired:
              convertedPatientsFiltered.balanceAdjustmentRequired?.length,
            default: convertedPatientsFiltered.default?.length,

            finpayFailedToEngage:
              convertedPatientsFiltered.finpayFailedToEngage?.length,
            duplicateRecord: convertedPatientsFiltered.duplicateRecord?.length,
            insurancePaidInFull:
              convertedPatientsFiltered.insurancePaidInFull?.length,
            accountHolderPaidBalanceOwed:
              convertedPatientsFiltered.accountHolderPaidBalanceOwed?.length,
            accountHolderPaidBalanceOwedDueARefund:
              convertedPatientsFiltered.accountHolderPaidBalanceOwedDueARefund
                ?.length,
            revokedPaymentPlan:
              convertedPatientsFiltered.revokedPaymentPlan?.length,
            providersRequest:
              convertedPatientsFiltered.providersRequest?.length,
            refinanced: convertedPatientsFiltered.refinanced?.length,
            hreApprovedDirectBillPaymentMadeToFacility:
              convertedPatientsFiltered
                .hreApprovedDirectBillPaymentMadeToFacility?.length,
            hreApprovedPaymentAtFacility:
              convertedPatientsFiltered.hreApprovedPaymentAtFacility?.length,
            hreApprovedFullScholarship:
              convertedPatientsFiltered.hreApprovedFullScholarship?.length,
            missingFundingSource:
              convertedPatientsFiltered.missingFundingSource?.length,
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container direction="row" className="mb-8">
          <Grid item xs={6} className="mb-4">
            <Autocomplete
              id="search-typeahead"
              isOptionEqualToValue={(option, value) =>
                option.patientId === value.patientId
              }
              onChange={(event, value) => {
                navigate(`/specialist/dashboard/${value?.patientId}`);
                dispatch(clearSelectedEncounter());
                const patientId = value?.patientId;
                const encounterId = value?.patientEncounterId;
                if (isPreConverted) {
                  const type = RecentlyViewedTypes.preConvertedPatients;
                  dispatch(
                    setRecentlyViewedPatient({
                      patientId: patientId,
                      encounterId: encounterId,
                      type,
                    })
                  );
                } else {
                  const type = RecentlyViewedTypes.convertedPatients;
                  dispatch(
                    setRecentlyViewedPatient({
                      patientId: patientId,
                      encounterId: encounterId,
                      type,
                    })
                  );
                }
                if (value?.patientId && value?.patientEncounterId) {
                  dispatch(
                    getInstanceOfCare({
                      patientId: value?.patientId,
                      encounterId: value?.patientEncounterId,
                    })
                  );
                }
              }}
              options={
                isPreConverted ? preconvertedPatients : convertedPatients
              }
              groupBy={(option) => option.clientName}
              getOptionLabel={(option) =>
                `${
                  option?.patientEncounterId
                    ? option?.patientEncounterId +
                      " - " +
                      option?.clientFacilityName +
                      " - "
                    : " "
                }                ${option?.contactCard?.firstName} ${
                  option?.contactCard?.lastName
                } - ${option?.contactCard?.email} ${
                  option?.contactCard?.primPhoneNumber ? " - " : ""
                }
                ${
                  option?.contactCard?.primPhoneNumber
                    ? option?.contactCard?.primPhoneNumber?.replaceAll("-", "")
                    : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="search"
                  placeholder="Search Patients"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        {<SearchIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end"></Grid>
        </Grid>
        {preconvertedPatientsFiltered ? (
          <PatientFilterResults
            preconvertedPatients={{
              recentlyViewedPatients,
              ...preconvertedPatientsFiltered,
            }}
            convertedPatients={{
              recentlyViewedPatientsConverted,
              ...convertedPatientsFiltered,
            }}
          />
        ) : (
          addPatientRecordMessage
        )}
      </Grid>
    </Grid>
  );

  return <>{isLoading ? <LoadingOverlay /> : PatientsView}</>;
}

export default PatientTiles;
