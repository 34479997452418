import {configSettings} from "../configuration/config-settings";

export default class AddressValidator
{
  SmartyStreetsSDK = require("smartystreets-javascript-sdk");
  SmartyStreetsCore = this.SmartyStreetsSDK.core;
  Lookup = this.SmartyStreetsSDK.usStreet.Lookup;

  key = configSettings.smartystreets_key;
  credentials = new this.SmartyStreetsCore.SharedCredentials(this.key);

  client = this.SmartyStreetsCore.buildClient.usStreet(this.credentials);

  async getZipCodePlus4(value: any) : Promise<String>{
    let lookup = new this.Lookup();
    lookup.street = value.streetAddress1;
    lookup.street2 = value.streetAddress2;
    lookup.city = value.city;
    lookup.state = value.state;
    lookup.zipCode = value.zipCode;
    lookup.maxCandidates = 1;
    lookup.match = "strict";

    let response = await this.client.send(lookup)
      .then((response: any) => {
        if (response?.lookups[0]?.result?.length !== 0) {
          return response.lookups[0].result[0].components?.plus4Code;
        } else {
          return "0";
        }
      })
      .catch(function (error: Error) {
        return "1";
      });

    return response;
  }
}