
export interface ImplementationCRM {
    clientCrmId: number;
    clientId: number;
    clientFacilityId: number;
    crmTypeId: number;
    isIntEnabled: boolean;
    isPolledInt: boolean;
    isEventInt: boolean;
    apiUrl: string;
    apiUserName: string;
    apiPassword: string;
    preadmitTypeId: number;
    admitTypeId: number;
    isAdmitIntEnabled: boolean;
    isPolledAdmitInt: boolean;
    isEventAdmitInt: boolean;
    apiFinpayId: string;
    apiFinpayToken: string;
    oauthGrantType: number;
    authUrl: string;
    accessTokenUrl: string;
    oauthToken: string;
    tokenExpireDt: Date
}


export interface CRMType {
    clientCrmId: number;
    clientId: number;
    clientFacilityId: number;
    crmTypeId: number;
    isIntEnabled: boolean;
    isPolledInt: boolean;
    isEventInt: boolean;
    apiUrl: string;
    apiUserName: string;
    apiPassword: string;
    preadmitTypeId: number;
    admitTypeId: number;
    isAdmitIntEnabled: boolean;
    isPolledAdmitInt: boolean;
    isEventAdmitInt: boolean;
    apiFinpayId: string;
    apiFinpayToken: string;
    oauthGrantType: number;
    authUrl:string,
    accessTokenUrl: string;
    oauthToken: string;
    tokenExpireDt: Date
}

export const emptyImplementationCRM: ImplementationCRM = {
    clientCrmId: 0,
    clientId: 0,
    clientFacilityId: 0,
    crmTypeId: 0,
    isIntEnabled: false,
    isPolledInt: false,
    isEventInt: false,
    apiUrl: "",
    apiUserName: "",
    apiPassword: "",
    preadmitTypeId: 0,
    admitTypeId: 0,
    isAdmitIntEnabled: false,
    isPolledAdmitInt: false,
    isEventAdmitInt: false,
    apiFinpayId: "",
    apiFinpayToken: "",
    oauthGrantType: 0,
    authUrl:"",
    accessTokenUrl: "",
    oauthToken: "",
    tokenExpireDt: new Date()

}

export const emptyCRMType: CRMType = {
    clientCrmId: 0,
    clientId: 0,
    clientFacilityId: 0,
    crmTypeId: 0,
    isIntEnabled: false,
    isPolledInt: false,
    isEventInt: false,
    apiUrl: "",
    apiUserName: "",
    apiPassword: "",
    preadmitTypeId: 0,
    admitTypeId: 0,
    isAdmitIntEnabled: false,
    isPolledAdmitInt: false,
    isEventAdmitInt: false,
    apiFinpayId: "",
    apiFinpayToken: "",
    oauthGrantType: 0,
    authUrl:"",
    accessTokenUrl: "",
    oauthToken: "",
    tokenExpireDt: new Date()

}