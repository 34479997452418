export interface UserSearchFormValues {

    clientId: number;
    facilityId: number;
    firstName: string;
    lastName: string;
    userName: string
    roleId: number;
}


export const blankUserSearchFormValues : UserSearchFormValues = {
    clientId: -1,
    facilityId: -1,
    firstName: "",
    lastName: "",
    userName: "",
    roleId: -1,
}