import { Box, Grid, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Navigation } from "../../security/model/navigation";
import { logUserOut } from "../../security/state/user-thunk";
import { RootState } from "../state/root-reducer";
import logo from "./../../assets/images/logo.png";
import "./../../scss/layout/_mobile-header.scss";
import { AppDispatch } from "../state/store";

interface HeaderProps {
  showLinks?: boolean;
  subNav?: Navigation[];
}

function MobileHeader(props: HeaderProps) {
  const { showLinks = true } = props;
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const subNav = useSelector(
    (state: RootState) => state.userContext.userProfile.SubNav
  );

  const getMenuChoices = () => {
    return (<><nav className="mobile-sub-navigation">
      <div className="content">
        <ul className="menu">
          {subNav?.map((nav: Navigation) => (
            <li key={nav.key} className="menu-item">
              <NavLink className="subtitle1 mb-0" to={nav.route} onClick={()=>{setOpen(false);}}>
                {nav.displayValue}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
      <nav className="mobile-sub-navigation">
        <div className="content">
          <ul className="menu">
            <li className="menu-item">
              <Link className="subtitle1 mb-0" to="" onClick={handleLogout}>
                Logout
              </Link>
            </li>

          </ul>
        </div>
      </nav>

    </>);
  };

  const dispatch = useDispatch<AppDispatch>();

  function handleLogout() {
    setOpen(false);
    dispatch(logUserOut());
  }

  return (
    <header>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="px-4 hamburger-menu"
      >
        <Grid item className="logo">
          <img src={logo} alt="logo" />
        </Grid>
        {showLinks && (
          <IconButton onClick={handleToggle} size="large">
            <MenuIcon />
          </IconButton>
        )}
        <Dialog
          scroll="body"
          className="modal client-modal"
          open={open}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                {...{
                  edge: "end",
                  color: "inherit",
                  onClick: handleToggle,
                }}
                size="large">
                <CloseIcon />
              </IconButton>
            </Box>
            {getMenuChoices()}
          </DialogContent>
        </Dialog>
      </Grid>
    </header>
  );
}

export default MobileHeader;
