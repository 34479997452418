import { Button, TextField } from '@finpay-development/shared-components';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { CustomFormikProps } from '../../../shared/model/formik-props';
import { AppDispatch } from '../../../shared/state/store';
import { resetUsersFilter } from '../../state/users/admin-slice';
import { handleGetUsers } from '../../state/users/admin-thunk';



interface LogsFilterProps {
  handlePatientFilterChange: (patient: string) => void;
  handleUserFilterChange: (user: string) => void;
  handleClientFilterChange: (user: string) => void;
}

function AdminLogs(props: LogsFilterProps) {
  const {
    handlePatientFilterChange,
    handleUserFilterChange,
    handleClientFilterChange
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  // handle reset here instead of parent so can directly reset form
  function handleResetFilter(formik: CustomFormikProps) {
    dispatch(handleGetUsers());
    dispatch(resetUsersFilter());
    formik.resetForm();
  }

  // no real validation rules yet
  const validationSchema = Yup.object({
    patient: Yup.string().max(32, "Must be 32 characters or less"),
  });

  const initialValues = {
    patient: "",
    user:"",
    client:"",
  };

  return (
    <div>
      <Typography variant="subtitle2" className="mb-8">
        Filters
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {(formik) => (
          <Form data-testid="filter-form" noValidate>
            <Grid container spacing={3}>


              <Grid xs={12} item>
                  <TextField
                    error={formik.touched['user'] &&
                    formik.touched['user'] &&
                      formik.errors['user']}
                    placeholder="Search by User"
                    name="user"
                    value={formik.values.user}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      handleUserFilterChange(
                        (e.target as HTMLInputElement).value
                      );
                    }}
                    onBlur={formik.handleBlur}
                    startAdornment={<SearchIcon />}
                  />
                </Grid>

              <Grid xs={12} item>
                <TextField
                  error={formik.touched['patient'] &&
                  formik.touched['patient'] &&
                    formik.errors['patient']}
                  placeholder="Search by Patient Name"
                  name="patient"
                  value={formik.values.patient}
                  onChange={(e: Event) => {
                    formik.handleChange(e);
                    handlePatientFilterChange(
                      (e.target as HTMLInputElement).value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  startAdornment={<SearchIcon />}
                />
              </Grid>

              <Grid xs={12} item>
                <TextField
                  error={formik.touched['client'] &&
                  formik.touched['client'] &&
                    formik.errors['client']}
                  placeholder="Search by Client Name"
                  name="client"
                  value={formik.values.client}
                  onChange={(e: Event) => {
                    formik.handleChange(e);
                    handleClientFilterChange(
                      (e.target as HTMLInputElement).value
                    );
                  }}
                  onBlur={formik.handleBlur}
                  startAdornment={<SearchIcon />}
                />
              </Grid>
            </Grid>



            <div>
              <Button
                onClick={() => handleResetFilter(formik)}
                type="text"
                marginTop={8}
                paddingLeft={0}
                icon={<ClearIcon />}
              >
                Reset Filters
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AdminLogs;
