import { DialogActionButton, saveCallbackStatus, TextField } from "@finpay-development/shared-components";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { ClientReferralSource } from "../../../../models/referral-source";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AppDispatch } from "../../../../../shared/state/store";
import { RootState } from "../../../../../shared/state/root-reducer";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearModalEditStatus } from "../../../../state/clients/implementation-clients-slice";
import * as Yup from 'yup';
import { saveClientReferralSource } from "../../../../state/clients/implementation-clients-thunk";


interface ClientReferralSourceProps {
    open: boolean;
    isEdit: boolean;
    referralSourceItem: ClientReferralSource | undefined;
    referralSourceType: string;
    handleReferralSourceCancel: () => void;
    handleReferralSourceSubmit: (isEdit: boolean) => void;
  }


export function ClientReferralSourceModal(props: ClientReferralSourceProps) {
  const {
    open,
    isEdit,
    referralSourceItem,
    referralSourceType,
    handleReferralSourceCancel,
    handleReferralSourceSubmit,
  } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const saveStatus = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.errorMessage
  );

  const initialClientValues: ClientReferralSource = {
    clientReferralSourceId: referralSourceItem?.clientReferralSourceId,
    clientReferralSourceName: referralSourceItem?.clientReferralSourceName,
    clientId: referralSourceItem?.clientId,
  }

  const validationSchema = Yup.object({
    clientReferralSourceName: Yup.string().required("Referral Source Name is required"),
  });

  const refSourceFormik = useFormik({
    initialValues: initialClientValues,
    enableReinitialize: true,
    onSubmit: (values) => { handleSave() },
    validationSchema: validationSchema,
    validate: checkIfIsValid
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  async function handleSave() {
    const requestBody: ClientReferralSource = {
      clientId: referralSourceItem?.clientId,
      clientReferralSourceName: refSourceFormik.values.clientReferralSourceName,
      clientReferralSourceId: (isEdit ? referralSourceItem?.clientReferralSourceId : undefined),
    }

    await dispatch(saveClientReferralSource(requestBody));
  }

  function handleSaveCallback() {
    if (saveStatus === saveCallbackStatus.success) {
      handleReferralSourceSubmit(isEdit);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearModalEditStatus());
  }

  function handleCancelCallback() {
    handleReferralSourceCancel();
  }

    return (
        <Dialog
          className="modal"
          open={open}
          fullWidth={true}
          maxWidth="md"
          scroll="body"
        >
          <DialogTitle>{isEdit ? "Edit Referral Source for " : "Add Referral Source To "}{referralSourceType}</DialogTitle>
          <DialogContent>
            <form  onSubmit={refSourceFormik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} className="mt-4">
                  <TextField
                    name="clientReferralSourceName"
                    label="Name"
                    variant="outlined"
                    value={refSourceFormik.values.clientReferralSourceName}
                    onChange={refSourceFormik.handleChange}
                    onBlur={refSourceFormik.handleBlur}
                    placeholder=""
                    error={
                      refSourceFormik.touched.clientReferralSourceName &&
                      refSourceFormik.errors.clientReferralSourceName
                    }
                  />
                </Grid>
              </Grid>
              </form>
          </DialogContent>
          <DialogActions>
          <DialogActionButton
            isEnabled={enableSaveButton}
            savebuttonText={isEdit ? "Update" : "Save"}
            saveStatus={saveStatus}
            spinnerLeftPosition={5}
            executeSave={handleSave}
            handleCallbackSave={handleSaveCallback}
            handleCallbackCancel={handleCancelCallback}
          />
        </DialogActions>
    </Dialog>
  );

}
