import './../../../../../scss/components/_rule-set.scss';
import './../../../../../scss/components/_table.scss';

import { DeleteModal, saveCallbackStatus } from '@finpay-development/shared-components';
import MenuIcon from '@mui/icons-material/Menu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';

import { RuleTableViewModel } from '../../../../../admin-configuration/models/rule';
import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from '../../../../../security/model/role-page-names';
import { showErrorStatus, showStatus } from '../../../../../security/state/user-slice';
import { TableColumn } from '../../../../../shared/model/table-column';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { getRule, resetRules, setCurrentFacility } from '../../../../state/rules-engine/rules-engine-slice';
import { deleteRule, reOrderRule, reOrderRuleInUI } from '../../../../state/rules-engine/rules-engine-thunk';
import { ImplementationFacility } from '../models/implementation-facility';
import { RulesModal } from './rules-modal';
import {
  getPaymentMetadata
} from '../../../../../meta-data/state/meta-data-thunk';

type MenuActionType = "edit" | "delete";

const columns: TableColumn[] = [
  { id: "condition", label: "Condition", minWidth: 200 },
  { id: "minDown", label: "Min Down", minWidth: 200 },
  { id: "optimalDown", label: "Optimal Down", minWidth: 200 },
  { id: "monthTerms", label: "Month Terms", minWidth: 200 },
  { id: "menu", label: "", minWidth: 25 },
];

interface RuleSetProps {
  rulesInGroup: RuleTableViewModel[];
  facility: ImplementationFacility;
  ruleSetIndex: number;
}

function RuleSet(props: RuleSetProps) {
  const { rulesInGroup, facility, ruleSetIndex } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ruleId, setRuleId] = useState(0);

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    deleteStatus: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext.modalDeleteStatus
    ),
    ruleParamsMetadata: useSelector(
        (state: RootState) => state.metaData.ruleParams
    )
  }

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    ruleId: number
  ) => {
    setRuleId(ruleId);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);
    dispatch(setCurrentFacility(facility));

    switch (menuActionType) {
      case "edit":
        dispatch(getRule(ruleId));
        if(selectors.ruleParamsMetadata.length ===0) dispatch(getPaymentMetadata(["ruleParams"]))
        setIsRuleModalOpen(true);
        break;
      case "delete":
        setIsDeleteModalOpen(true);
        break;
    }
  };

  function handleRuleModalCancel() {
    dispatch(resetRules());
    setIsRuleModalOpen(false);
  }

  function handleRuleModalSubmit(isEditMode: boolean) {
    setIsRuleModalOpen(false);
    dispatch(resetRules());

    if (isEditMode) {
      dispatch(showStatus("Rule Updated Successfully"));
    } else {
      dispatch(showStatus("Rule Added Successfully"));
    }
  }

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
  }

  function handleDeleteOk() {
    dispatch(deleteRule({ruleId: ruleId, currentFacility: facility}));
    setIsDeleteModalOpen(false);
  }

  function handleOnDragEnd(dndResult: DropResult) {
    const dndSourceIndex = dndResult.source?.index;
    const dndDestinationIndex = dndResult.destination?.index;

    if (!dndResult.destination || dndSourceIndex === dndDestinationIndex) {
      return;
    }

    dispatch(
      reOrderRuleInUI({
        facility,
        rulesInGroup,
        dndSourceIndex,
        dndDestinationIndex,
        ruleSetIndex,
      })
    );
    dispatch(
      reOrderRule({
        facility,
        rulesInGroup,
        dndSourceIndex,
        dndDestinationIndex,
      })
    );
  }

  useEffect(() => {
    if (selectors.deleteStatus === saveCallbackStatus.success) {
      dispatch(showStatus("Rule deleted"));
    } else if (selectors.deleteStatus === saveCallbackStatus.error) {
      dispatch(showErrorStatus("There is a problem deleting"));
    }
  }, [dispatch, selectors.deleteStatus]);

  return (
    <div className="rule-set mb-6">
      <Grid container justifyContent="space-between">
        <Grid>
          <Typography variant="subtitle2">
            {rulesInGroup[0]?.timingRisk} | &nbsp;
            {rulesInGroup[0]?.payorRisk}
          </Typography>
        </Grid>
        <Grid item>
          <Grid item>
            <Typography variant="body2">
              Optimal Terms
              <span className="subtitle1 ml-2">
                {rulesInGroup[0]?.optimalTerm} months
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Table
        rulesInGroup-testid="results"
        className="table alternating-row"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="rule" direction="vertical">
            {(provided) => (
              <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                {rulesInGroup.map((rule: RuleTableViewModel, index: number) => {
                  return (
                    <Draggable
                      key={rule.ruleId}
                      draggableId={rule.ruleId.toString()}
                      index={index}
                    >
                      {(provided: DraggableProvided) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          {columns.map((column, i) => {
                            const value = (rule as any)[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                <Typography
                                  variant="body1"
                                  className="center-y"
                                >
                                  {i === 0 ? (
                                    <span {...provided.dragHandleProps}>
                                      <MenuIcon className="dnd-icon" />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {value}
                                </Typography>
                                {column.id === "menu" && (
                                  <>
                                    <IconButton
                                      aria-haspopup="true"
                                      onClick={(e) =>
                                        handleMenuClick(e, rule.ruleId)
                                      }
                                      size="large">
                                      <MoreHorizIcon />
                                    </IconButton>
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleMenuClose()}
      >
        <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Rule"
          renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}>
          <MenuItem className="primary" onClick={() => handleMenuClose("edit")}>Edit</MenuItem>
        </AccessControl>
        <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Rule"
          renderNoAccess={() => <MenuItem disabled>Delete</MenuItem>}>
          <MenuItem className="danger" onClick={() => handleMenuClose("delete")}>Delete</MenuItem>
        </AccessControl>
      </Menu>

      {isRuleModalOpen && (
        <RulesModal
          open={isRuleModalOpen}
          handleRuleModalCancel={handleRuleModalCancel}
          handleRuleModalSubmit={handleRuleModalSubmit}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          open={isDeleteModalOpen}
          title="Delete Rule"
          subTitle="This action cannot be undone"
          okButtonText="Delete Forever"
          handleDeleteCancel={handleDeleteCancel}
          handleDeleteOk={handleDeleteOk}
        />
      )}
    </div>
  );
}

export default RuleSet;
