import { useFormik } from "formik";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import * as Yup from "yup";
import {
  TextField,
  DialogActionButton,
  saveCallbackStatus,
} from "@finpay-development/shared-components";
import { RootState } from "../../../../../shared/state/root-reducer";
import { savePayerClaimRate } from "../../../../state/clients/implementation-clients-thunk";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearRiskSettingEditStatus } from "../../../../state/clients/implementation-clients-slice";
import { getClientClaimRates } from "../../../../../admissions-advisor/state/risk-assessment-thunk";
import { PayerClaimRate } from "../../../../../admissions-advisor/models/risk-assessment";
import {
  PayerPlans,
  PayerRow,
} from "../../../../../admin-configuration/models/payers";
import { Utils } from '../../../../../shared/utils';

interface PayerClaimRatesModalProps {
  open: boolean;
  clientId: number;
  claimRateItem: PayerClaimRate;
  payerItem: PayerRow;
  payerPlanItem: PayerPlans | null | undefined;
  claimRateDescription: string;
  handlePayerClaimRatesModalCancel: () => void;
  handlePayerClaimRatesModalSubmit: () => void;
}

export function PayerClaimRatesModal(props: PayerClaimRatesModalProps) {
  const {
    open,
    clientId,
    claimRateItem,
    payerItem,
    payerPlanItem,
    claimRateDescription,
    handlePayerClaimRatesModalCancel,
    handlePayerClaimRatesModalSubmit,
  } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  const initialSettingValues: PayerClaimRate = {
    paramId: paramId,
    clientPayorClaimrateId: claimRateItem.clientPayorClaimrateId,
    clientId: clientId,
    cfgPayorId: payerItem.payorId,
    cfgPayorPlanId: payerPlanItem ? payerPlanItem.payorPlanId : null,
    claimRatePct: claimRateItem.claimRatePct,
  };

  const validationSchema = Yup.object({
    claimRatePct: Yup.number().required("Claim Rate Amount is required").min(.01).max(100),
  });


  const claimRateFormik = useFormik({
    initialValues: initialSettingValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSave();
    },
    validationSchema: validationSchema,
    validate: checkIfIsValid,
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const saveStatus = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.errorMessage
  );

  async function handleSave() {
    const requestBody: PayerClaimRate = {
      paramId: paramId,
      clientPayorClaimrateId: claimRateItem.clientPayorClaimrateId,
      clientId: clientId,
      cfgPayorId: payerPlanItem?.payorPlanId !== undefined? null : payerItem.payorId,
      cfgPayorPlanId: payerPlanItem ? payerPlanItem.payorPlanId : null,
      claimRatePct: claimRateFormik.values.claimRatePct,
    };
    await dispatch(savePayerClaimRate(requestBody));
  }

  function handleSaveCallback() {
    if (saveStatus === saveCallbackStatus.success) {
      handlePayerClaimRatesModalSubmit();
      setEnableSaveButton(false);
      dispatch(getClientClaimRates({ paramId: paramId, clientId: clientId }));
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearRiskSettingEditStatus());
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Edit Claim Rate</DialogTitle>
      <DialogContent>
        <form onSubmit={claimRateFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="mt-6" variant="subtitle2">
                {claimRateDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <TextField
                error={Boolean(
                  claimRateFormik.touched["claimRatePct"] &&
                    claimRateFormik.errors["claimRatePct"]
                    ? claimRateFormik.errors["claimRatePct"]
                    : ""
                )}
                helperText={
                  claimRateFormik.touched["claimRatePct"] &&
                  claimRateFormik.errors["claimRatePct"]
                    ? claimRateFormik.errors["claimRatePct"]
                    : ""
                }
                label="Claim Rate"
                name="claimRatePct"
                placeholder=""
                type="number"
                value={
                  claimRateFormik.values.claimRatePct
                    ? claimRateFormik.values.claimRatePct
                    : null
                }
                onChange={(e: Event) => {
                  if (Utils.handleNumericValues(e))
                  claimRateFormik.handleChange(e)
                }}
                onBlur={claimRateFormik.handleBlur}
                endAdornment={"%"}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={"Update"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handlePayerClaimRatesModalCancel}
        />
      </DialogActions>
    </Dialog>
  );
}
