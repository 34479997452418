import './../../../scss/pages/admin/_admin-roles.scss';

import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import { Grid, Menu, MenuItem, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { UserRoleViewModel } from '../../../security/model/user-role';
import { showErrorStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { externalUserRoles } from '../../data/external-user-roles';
import { clearAddEditState } from '../../state/admin-roles-slice';
import { getAdminUserRoles, getUserRole } from '../../state/admin-roles-thunk';
import { AdminRoleModal } from './admin-role-modal';
import RoleCardContainer from './role-card-container';

function AdminRolesView() {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [editUserRoleId, setEditUserRoleId] = useState(0);

  const stateFields = {
    userRoles: useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.userRoles
    ),
    isLoading: useSelector(
      (state: RootState) => state.adminContext.adminRoleContext.isLoading
    ),
    userRolePages: useSelector(
      (state: RootState) => state.userContext.userProfile.userRole.userRolePages
    )
  }

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(getAdminUserRoles());
  }, [dispatch]);


  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    userRoleId: number
  ) => {
    setEditUserRoleId(userRoleId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (actionType?: string) => {
    setAnchorEl(null);
    dispatch(clearAddEditState());

    if (actionType === "edit") {
      dispatch(getUserRole(editUserRoleId));
      setEditUserModalOpen(true);
    }
  };

  function showAddNewDialog() {
    dispatch(getUserRole(0)); // Pass Zero To Load New User Role
    setEditUserModalOpen(true);
  }

  function handleEditModalCancel() {
    dispatch(clearAddEditState());
    setEditUserModalOpen(false);
  }

  function handleEditModalSubmit(isEditMode: boolean, isError: boolean) {
    setEditUserModalOpen(false);
    if (isError) {
      dispatch(showErrorStatus("A Role with this name already exists, please change role name or cancel"))
    }
  }

  const userRolesView = (stateFields.userRoles.internalUserRoles.length > 0) ? (
    <div className="content">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <AccessControl rolePageName={RolePageNames.Roles} actionName="Add New Role"
              renderNoAccess={() => <Button disabled>Add New Role</Button>}>
              <Button onClick={() => showAddNewDialog()}>Add New Role</Button>
            </AccessControl>
          </Grid>
            {/* First show Super User role (userRoleId=1) */}
            <Grid container className="mt-6">
              {stateFields.userRoles.internalUserRoles.filter((roles) => roles.userRoleId === 1).map((role: UserRoleViewModel) => (
               <RoleCardContainer key={role.userRoleId} role={role} handleClick={handleClick} />
              ))}
            </Grid>
            {/* Then show all internal roles that is NOT Super User (userRoleId !==1) */}
            <Grid container>
              {stateFields.userRoles.internalUserRoles.filter((roles) => roles.userRoleId !== 1).map((role: UserRoleViewModel) => (
               <RoleCardContainer key={role.userRoleId} role={role} handleClick={handleClick} />
              ))}
            </Grid>
          <Paper className="mt-8">
            <Typography variant="h1" className="mb-6">External Roles</Typography>
            <Grid container className="external-role-list">
              {externalUserRoles.map((role: UserRoleViewModel, index) => (
                <RoleCardContainer key={index} role={role} handleClick={handleClick} />
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Menu className="edit-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        <div> {/* div wrapper prevents (harmless) console 'ref' error. */}
          <AccessControl rolePageName={RolePageNames.Roles} actionName="Add New Role"
            renderNoAccess={() => <MenuItem disabled>Edit</MenuItem>}>
            <MenuItem className="primary" onClick={() => handleClose("edit")}>Edit</MenuItem>
          </AccessControl>
        </div>
      </Menu>
    </div>
  ) : (
    <Grid container justifyContent="flex-end">
      <Button onClick={() => showAddNewDialog()}>Add New Role</Button>
    </Grid>
  )

  return (
    <main className="admin-roles">
      {stateFields.isLoading ? <LoadingOverlay /> : userRolesView}
      <AdminRoleModal
        handleEditModalSubmit={handleEditModalSubmit}
        handleEditModalCancel={handleEditModalCancel}
        open={editUserModalOpen}
      />
    </main>
  );
}

export default AdminRolesView;

