import React, { useState } from "react";

import * as Yup from "yup";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import { Form, Formik, ErrorMessage } from "formik";
import { Typography } from "@mui/material";
import { RootState } from "../../../../shared/state/root-reducer";
import { createS3File } from "../../../state/patient-thunk";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../shared/state/store";
import "../../../../scss/components/_documents-modal.scss"
interface DocumentUploadProps {
  patientID: number;
  encounterId: number;
  open: boolean;
  onClose(status: boolean): void;
}

const DocumentsModal = ({
  patientID,
  encounterId,
  open,
  onClose,
}: DocumentUploadProps) => {
  const initialValues = {
    file: [],
    patientID: patientID,
    encounterId: encounterId,
  };

  const { isUploadingS3 } = useSelector(
    (state: RootState) => state.patientContext
  );

  const [selectedFile, setSelectedFile] = useState([]);

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          file: Yup.mixed(),
        })}
        onSubmit={(values, { resetForm }) => {
          dispatch(createS3File(values));
          if (!isUploadingS3) {
            resetForm();
            onClose(false);
            setSelectedFile([]);
          }
        }}
      >
        {(formik) => (
          <Dialog
            scroll="body"
            className="modal client-modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
          >
            <DialogTitle id="form-dialog-title">
              <Grid item xs={12}>
                <Typography variant="h6">Attached Documents</Typography>
              </Grid>
            </DialogTitle>

            <DialogContent>
              <Form>
                <Grid container spacing={2} className="px-2 pb-2">
                  <Grid item xs={12}>
                    <label htmlFor="upload-photo">
                      <input
                        style={{ display: "none" }}
                        id="upload-photo"
                        name="file"
                        type="file"
                        onChange={(e: any) => {
                          // @ts-ignore

                          formik.setFieldValue(
                            "file",
                            e.currentTarget.files[0]
                          );

                          // @ts-ignore

                          setSelectedFile(e.currentTarget.files[0].name);
                        }}
                      />

                      <Button
                        className="doc-modal"
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        Select File
                      </Button>
                    </label>

                    <ErrorMessage name="file" />
                  </Grid>

                  <Grid item xs={12}>
                    {selectedFile}
                  </Grid>

                  {isUploadingS3 && (
                    <Grid item xs={12}>
                      <LinearProgress />
                    </Grid>
                  )}
                </Grid>
              </Form>
            </DialogContent>

            <DialogActions>
              <Button className="doc-modal" onClick={() => onClose(false)} color="primary" variant="contained">
                Close
              </Button>
              <Button
                className="doc-modal"
                onClick={formik.submitForm}
                disabled={formik.isSubmitting}
              >
                Upload File
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </div>
  );
};

export default DocumentsModal;
