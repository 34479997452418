import '../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, saveCallbackStatus, TextField } from '@finpay-development/shared-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Box, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { PatientEncounter } from '../../../patient/components/models/patient-encounter';
import { PAYMENT_METHOD_TYPES } from '../../../patient/components/models/payment-method';
import { StripePaymentMethod } from '../../../patient/components/models/stripe-payment-method';
import { verifyBankAccount } from '../../../patient/state/patient-thunk';
import { AppDispatch } from '../../../shared/state/store';
import { verifyBankModal } from '../../../shared/validation/schemas';
import { ACHVerification, MicrodepositVerification } from '../../models/verify-ach-payment-method';

interface VerifyBankModalProps {
  open: boolean;
  handleModalClose: () => void;
  paymentToVerify: StripePaymentMethod;
  encounter: PatientEncounter
}

export const VerifyBankModal = (props: VerifyBankModalProps) => {

  const { open, handleModalClose, paymentToVerify, encounter } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
  }, [dispatch])

  const mapToPostBody = () => {
    const currentValues = formRef?.current?.values;

    const verification = {
      ...PAYMENT_METHOD_TYPES.ACH,
      externalPaymentId: paymentToVerify?.id,
      achFirstPayment: parseFloat(currentValues?.firstPayment),
      achSecondPayment: parseFloat(currentValues?.secondPayment),
      payorId: paymentToVerify?.metadata?.metaData_payorId,
    } as MicrodepositVerification

    const fullVerificationShape = {
      paymentMethods: [
        verification
      ]
    } as ACHVerification

    return fullVerificationShape;
  }

  async function handleSave() {
    const dataToSave = mapToPostBody();
    const currentPaymentProgramId = encounter.patientPaymentProgram[0]?.patientPaymentProgramId;
    await dispatch(verifyBankAccount({
      patientId: encounter?.patientId, 
      encounterId: encounter?.patientEncounterId, 
      microdeposits: dataToSave,
      paymentProgramId: currentPaymentProgramId
    }));
    handleModalClose();
  }

  function handleCancelCallback() {
    handleModalClose();
  }

  const initialValues = {
    firstPayment: 0.00,
    secondPayment: 0.00,
  };

  const validationSchema = Yup.object(
    verifyBankModal
  );

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Verify Bank Account</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h4" className="pb-3">
                    {`Enter the amounts listed as [Deposit From] in your bank account ending in ${paymentToVerify?.last4}.`}
                  </Typography>
                </Grid>
                <Grid xs={6} sm={8} item className="pb-2">
                  <Typography variant="body2">
                    First Payment
                  </Typography>
                </Grid>
                <Grid xs={6} sm={4} item>
                  <Box display="flex" justifyContent="flex-end">
                    <TextField
                      error={
                        formik.touched["firstPayment"] && formik.errors["firstPayment"]
                      }
                      label="First Payment"
                      placeholder="0.00"
                      name="firstPayment"
                      startAdornment={<AttachMoneyIcon />}
                      value={formik.values.firstPayment}
                      minLength={1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                </Grid>
                <Grid xs={6} sm={8} item>
                  <Typography variant="body2">
                    Second Payment
                  </Typography>
                </Grid>
                <Grid xs={6} sm={4} item>
                  <Box display="flex" justifyContent="flex-end">
                    <TextField
                      error={
                        formik.touched["secondPayment"] && formik.errors["secondPayment"]
                      }
                      label="Second Payment"
                      placeholder="0.00"
                      name="secondPayment"
                      startAdornment={<AttachMoneyIcon />}
                      value={formik.values.secondPayment}
                      minLength={1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Save'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSave}
          handleCallbackSave={handleCancelCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
