import React, { Fragment } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material';
import { useSelector } from "react-redux";
import { RootState } from "../state/root-reducer";

interface headerStatusProps {
    isActive: boolean;
}

function HeaderStatus(props: headerStatusProps) {
    const { isActive } = props
    const theme = useTheme();
    let color = theme.palette.success.main;

    const isAccountHolderUser = useSelector(
        (state: RootState) => state.userContext.userProfile.userRole.roleName === "Account Holder"
    );

    if (!isActive) color = theme.palette.error.main
    return (
        <Fragment>
            {!isAccountHolderUser && (
                <>
                    <FiberManualRecordIcon style={{ color: color }} className="pt-3" fontSize="small"></FiberManualRecordIcon>
                    {isActive ? "Active" : "Away"}
                    <ExpandMoreIcon className="pt-3" ></ExpandMoreIcon>
                </>
            )}
        </Fragment>
    )
}

export default HeaderStatus
