import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import FppComment from '../../../../shared/components/comment';
import { commentsPage } from '../../../../shared/enums';
import { RootState } from '../../../../shared/state/root-reducer'

interface CommentsValidationProps {
    page: string
  }

function CommentsValidation(props: CommentsValidationProps) {
    const { page } = props;
    const selectors = {
        comments: useSelector(
          (state: RootState) =>
            state.patientContext.comments
        ),
        clientComments: useSelector(
          (state: RootState) =>
            state.implementationContext.implementationSpecialistClient.comments
        ),
      }
    return (
      <>
        {page===commentsPage.patients ?
          (  
            <>
              {selectors.comments.map((comment) => (
              <FppComment page={page} comment={comment} commentId={comment.patientNotesId} key={comment.patientNotesId} />
              ))}
            </>
          ) : 
          (  
            <>
              { selectors.clientComments.map((comment) => (
              <FppComment page={page} comment={comment} commentId={comment.clientCommentsId} key={comment.clientCommentsId} />
              ))}
            </> 
          )
        }
        </>
    )
}

export default CommentsValidation
