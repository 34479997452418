import './../../../scss/pages/admin/_admin-roles-edit.scss';

import { Checkbox } from '@finpay-development/shared-components';
import { Grid, Typography } from '@mui/material';
import React from 'react';

import { CustomFormikProps } from '../../../shared/model/formik-props';
import { RolePageActionState, RolePageState } from '../../models/role-page-state';

// Enable-Only: Show Only Enable Checkbox
// Full: Show Both Read-Only and Editable Checkboxes that are toggled
export interface RoleAccessGroupProps {
    type: "Enable-Only" | "Full"
    formik: CustomFormikProps;
    itemName: string;
    itemKey: string;
    hidden?: boolean;
    childKey?: string;
    isChild?: boolean;

    handleRoleSelection:
        (formik: CustomFormikProps,
        fieldName: string,
        newState: RolePageState) => void;
}

function RoleAccessGroup(props: RoleAccessGroupProps) {

    const {type, formik, itemName, itemKey, hidden = false, childKey, isChild, handleRoleSelection} = props;
    // Setup Checkbox names for Read-Only vs Enabled/Editable
    const controlKey = isChild ? childKey ? childKey : itemName : itemName;
    const enabledItemName = type === "Full" ? `${controlKey}_editable` : controlKey;
    const readOnlyItemName = `${controlKey}_readonly`;

    // Get The checked value for Enabled/Editable
    const getEnabledState = (): boolean => {
        if (!formik.values[itemKey])
            return false;

        if (isChild) {
            const childItem = formik.values[itemKey].actions.find(
                (pageAction: RolePageActionState) => pageAction.actionKey === childKey
              );
            return childItem ? childItem.isEnabled : false;
        } else {
            return type === "Full" ? formik.values[itemKey].isEditable: formik.values[itemKey].isEnabled;
        }
    }

    // Get The checked value for Read-Only
    const getReadOnlyState = (): boolean => {
        const checkedState = formik.values[itemKey] ? formik.values[itemKey].isReadOnly : false;
        return checkedState;
    }

    return (
        <Grid container item justifyContent="center" alignItems="center" className={`data-container ${hidden ? "hidden" : ""}`}>
            <Grid item xs={8}>
                <Typography variant="body1">{itemName}</Typography>
            </Grid>

          <Grid container item xs={4} justifyContent="center" alignItems="center">
            <Grid container item xs={6} justifyContent="center" alignItems="center">
                {type === "Full" &&  <Checkbox
                                id={readOnlyItemName}
                                name={readOnlyItemName}
                                label=""
                                checked={getReadOnlyState()}
                                onChange={(e: Event) =>  {
                                    const newState = formik.values[itemKey];
                                    if (newState) {
                                        newState.isReadOnly = !newState.isReadOnly;
                                        newState.isEditable = !newState.isReadOnly;
                                        formik.setFieldValue(itemKey, newState);
                                        handleRoleSelection(formik, itemKey, newState);
                                    }
                                }}
                            />
                }
                </Grid>
                <Grid container item xs={6} justifyContent="center" alignItems="center">
                    <Checkbox
                            id={enabledItemName}
                            name={enabledItemName}
                            label=""
                            checked={getEnabledState()}
                            onChange={(e: Event) =>  {
                                const newState = formik.values[itemKey];

                                if (newState) {
                                    if (isChild) {
                                        // if Action (Child) Toggle checked state when page is not read-only
                                        if (!newState.isReadOnly && newState.isEditable) {
                                            newState.actions.forEach(function (pageAction: RolePageActionState) {
                                                if (pageAction.actionKey === childKey) {
                                                    pageAction.isEnabled = !pageAction.isEnabled;
                                                }
                                            });

                                            formik.setFieldValue(itemKey, newState)
                                        }
                                        } else {
                                               if (type === "Full") {
                                                // Handle Toggling between Read-Only and Editable
                                                newState.isEditable = !newState.isEditable
                                                newState.isReadOnly = !newState.isEditable
                                            } else {
                                                newState.isEnabled = !newState.isEnabled
                                                newState.isReadOnly = newState.isEnabled;
                                            }

                                            formik.setFieldValue(itemKey, newState)
                                            handleRoleSelection(formik, itemKey, newState);
                                    }

                                }


                            }}
                    />
                </Grid>
          </Grid>

        </Grid>
    );
}

export default RoleAccessGroup;
