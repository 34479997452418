export interface loadingState {
  preconvertedPatients: boolean;
  convertedPatients: boolean;
  patientDemographics: boolean;
  patientIOC: boolean;
  patientInsurance: boolean;
  patientChampion: boolean;
  patientDocuments: boolean;
  patientTransactions: boolean;
  paymentProgram: boolean;
  paymentMethods: boolean;
  evaluateRule: boolean;
  comments: boolean;
  accountHolderDashboard: boolean;
  hellosign: boolean;
  calculatedRiskClass: boolean;
  phiDocument: boolean;
  spaDocument: boolean;
  pfrDocument: boolean;
}

export const emptyLoadingState: loadingState = {
  preconvertedPatients: false,
  convertedPatients: false,
  patientDemographics: false,
  patientIOC: false,
  patientInsurance: false,
  patientChampion: false,
  paymentProgram: false,
  patientDocuments: false,
  patientTransactions: false,
  paymentMethods: false,
  evaluateRule: false,
  comments: false,
  accountHolderDashboard: false,
  hellosign: false,
  calculatedRiskClass: false,
  phiDocument: false,
  spaDocument: false,
  pfrDocument: false,
}