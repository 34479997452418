import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, ButtonBase, Grid, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import "../../scss/components/_icons.scss";
import AccessControl from '../../security/components/access-control';
import "../../scss/components/_customizeable-accordion.scss";

interface customizeableAccordionProps {
  icon?: any,
  headerText?: string,
  details?: any, // if this is a 'start' modal, we assume it has no details. Once there are details, it will become expandable.
  isDefaultExpanded?: boolean,
  rolePageName?: string,
  actionName?: string,
  isStart?: boolean, // makes this a 'start' accordion where you can click 'start' and it will open a modal. (must pass in a callback along with isStart.)
  hasScroll?: boolean,
  openModalCallback?: () => void, // pass in a callback function to toggle opening a modal on click of the accordion 'start' button
  expandCallback?: () => void // pass in callback function that fires when the accordion is expanded
  isDisabled?: boolean;
  dataTestId?: string;
}

const CustomizeableAccordion = (props: customizeableAccordionProps) => {
  const {
    icon,
    headerText,
    details,
    isDefaultExpanded,
    isStart,
    hasScroll,
    openModalCallback,
    expandCallback,
    isDisabled = false,
    dataTestId,
  } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isDefaultExpanded && details) {
      setOpen(true);
    }
    if (!details || details === false) { // explicit check against false since !details when details = false, is true.
      setOpen(false);
    }
  }, [details, isDefaultExpanded])

  const safeModalCallback = () => {
    if (openModalCallback) {
      openModalCallback();
    }
    return;
  };

  const handleClick = () => {
    if (expandCallback && !open) { // only fire this on open of the accordion. !open accomplishes this
      expandCallback();
    }
    if (details) {
      setOpen(!open);
    }
    return;
  }

  const renderStart = () => {
    if (props.rolePageName && props.actionName) {
      return (
          <AccessControl
              rolePageName={props.rolePageName}
              actionName={props.actionName}
              renderNoAccess={() => <ButtonBase disabled children={<Typography className="colorDisabled">Start</Typography>} />}
          >
            <ButtonBase
                onClick={isStart ? () => safeModalCallback() : () => setOpen(true)}
                children={<Typography className={isDisabled ? "colorDisabled" : "icon"}>Start</Typography>}
                disabled={isDisabled}
            />
      </AccessControl>)
    }
    else {
      return (
          <ButtonBase
              onClick={isStart ? () => safeModalCallback() : () => setOpen(true)}
              children={<Typography className={isDisabled ? "colorDisabled" : "icon"}>Start</Typography>}
              disabled={isDisabled}
          />)
    }
  }

  return (
    <Accordion
      className="icon-colors"
      defaultExpanded={isDefaultExpanded}
      expanded={open}
    >
      <AccordionSummary
        onClick={() => handleClick()}
        expandIcon={
          (isStart && !details && !open) ?
            renderStart()
          : <ExpandMoreIcon className="icon" />
        }
        data-testid={dataTestId}
        id="payment-programs-offered"
      >
        <Grid container spacing={1}>
          <Grid item>
            {icon}
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h3" className="pt-0_5">
              {headerText}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {details && (
        <div className={hasScroll ? "customizeable-accordion scroll-container" : ""}>
          <div className={hasScroll ? "customizeable-accordion inner-scroll" : ""}>
            <AccordionDetails>
              <div>
                {details}
              </div>
            </AccordionDetails>
          </div>
        </div>
      )}
    </Accordion>
  )
}

export default CustomizeableAccordion
