import { TimingRisk } from "../../meta-data/models/metaData";
import { UserInfoClient } from "./user-info-client";
import { UserInfoRole } from "./user-info-role";

export class UserInfo {
  userId!: number;
  firstName!: string;
  lastName!: string;
  fullName!: string;
  phoneNumber!: string;
  email!: string;
  isActive!: boolean;
  cognitoStatus?: Record<string, any>;
  domainAccess!: string;
  userRole!: UserInfoRole;
  clients!: UserInfoClient[];
  isPatient!: boolean;
  isSSO!:boolean;
  userTimingRisk?:TimingRisk[];
  hasAllTimingRisks!: boolean;
}

// Factory for Creating Default User Info
export function  getEmptyUserInfo() : UserInfo {
  return {
    userId: 0,
    firstName: "",
    lastName: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    isActive: false,
    domainAccess: "FINPAY",
    isPatient: false,
    isSSO:false,
    hasAllTimingRisks: false,
    userRole: {
      roleId: 0,
      roleName: ""
    },
    clients: [],
    userTimingRisk:[]
  }
}

  // Factory for Creating Default User Info
  export function  getDefaultUserInfo() : UserInfo {
    const defaultUserInfo = getEmptyUserInfo();
    defaultUserInfo.isActive = true;

    defaultUserInfo.userRole = {
      roleId: 1, // Currently Defaulting to Super User
      roleName: "Super User"
    };

    defaultUserInfo.clients = [];
    return defaultUserInfo;
  }
