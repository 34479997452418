export const websiteValidator: RegExp = /(?=.*\..+\..+)(?!.*\.\.)^((https?):\/\/)?([a-zA-Z0-9@:%._~#=]{1,256}\.)+([a-zA-Z]){2,}/;
export const websiteWwwRequiredValidator: RegExp = /^((https?):\/\/)?(www\..*)/;
export const specialCharsValidator: RegExp = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
export const termsValidator: RegExp = /^([1-9]|[1-2]\d|3[0-6])(,([1-9]|[1-2]\d|3[0-6]))*$/;
export const phoneNumberValidator = /^(\(?\d{3}\)?) ?-? ?(\d{3}) ?-? ?(\d{4})$/;
export const phoneNumberOptValidator = /^$|^(\(?\d{3}\)?) ?-? ?(\d{3}) ?-? ?(\d{4})$/;
export const emailAddressValidator = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const SSNValidator = /^(?!000|666)[0-9]{3}([-])(?!00)[0-9]{2}\1(?!0000)[0-9]{4}$/; // note: SSNs first 3 digits cannot be 000 or 666, or be between 900-999.
export const SSNValidatorTesting = /^(?!666)[0-9]{3}([-])[0-9]{2}\1[0-9]{4}$/; //This is just for testing purposes
export const SSNOptValidator = /^$|^(?!000|666)[0-9●]{3}([-])(?!00)[0-9●]{2}\1(?!0000)[0-9●]{4}$/;
export const zipCodeValidator = /^\d{5}$/;
export const zipCodeValidatorOptional = /(^\d{5}$)|(^$)/; // this can be blank, but if its not, has to be at least 5 cars
export const consecutiveLettersValidator = /^(?!.*([!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~A-Za-z0-9])\1{2})[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~A-Za-z0-9]+$/;
export const atLeastOneCharsLongOrBlank = /(.{1,})|(^$)/;// valid if field is blank (makes field optional), or if not blank is valid with 1 or more chars
export const atLeastThreeCharsLongOrBlank = /([A-Za-z0-9]{3,})|(^$)/; // valid if field is blank (makes field optional), or if not blank is valid with 3 or more chars
export const notNegativeOneValidator: RegExp = /^(?!-1).*$/; // for use in dropdowns. make sure that the dropdown is populated and not just set to default value
export const oneHundredLimit: RegExp = /^[1-9][0-9]?$|^99$/;
export const taxIdValidator: RegExp = /^\d{2}-\d{7}$/;
export const taxIdValidatorDisplay: RegExp = /^$|^(?!000|666)[0-9●]{2}([-])(?!00)[0-9●]{3}(?!0000)[0-9]{4}$/;