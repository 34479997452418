export interface preConvertedPatientFilter {
  dateRange: string;
  recentlyViewed: boolean;
  reminders: boolean;
  newPatients: boolean;
  newNone: boolean;
  newPendingPaymentAtFacility: boolean;
  followUp: boolean;
  educationalCurriculum: boolean;
  paymentCurriculum: boolean;
  awaitingConsentDocs: boolean;
  awaitingHREApproval: boolean;
  secondContactAttempt: boolean;
  thirdContactAttempt: boolean;
  awaitingClient: boolean;
  patientChampion: boolean;
  paymentMethodNeeded: boolean;
  closed: boolean;
  fileError: boolean;
  patientFailedToEngage: boolean;
  hreRejectedByPatient: boolean;
  hreRejectedByClient: boolean;
  patientNeverAdmitted: boolean;
  isPreConverted: boolean;
  noStatus: boolean;
  hreApproved: boolean;
  hreApprovedPaymentConfirmationRequired: boolean;
  hreApprovedDirectBillFollowUp: boolean;
  finpayFailedToEngage: boolean;
  duplicateRecord: boolean;
  patientCoveredAt100Percent: boolean;
  hreApprovedForPaymentAtFacilityPaymentConfirmed: boolean;
  hreApprovedForFullScholarship: boolean;
  mobilePaymentFailureFollowUp: boolean;
  mobilePaymentFailureClosed: boolean;
  mobilePaymentRetryLimit: boolean;
  mobilePaymentRetryLimitClosed: boolean;
  followUpNeededPendingPaymentAtFacility: boolean;
}

export interface convertedPatientFilter {
  dateRange_converted: string;
  recentlyViewedPatientsConverted: boolean;
  reminders: boolean;
  goodStanding: boolean;
  paid: boolean;
  priority: boolean;
  awaitingAuthorizations: boolean;
  awaitingACHVerification: boolean;
  atRisk: boolean;
  missedPayment: boolean;
  pastDueBalance: boolean;
  balanceAdjustmentRequired: boolean;
  onHold: boolean;
  closed_converted: boolean;
  fileError_converted: boolean;
  patientFailedToEngage_converted: boolean;
  patientNeverAdmitted_converted: boolean;
  default: boolean;
  isPreConverted: boolean;
  finpayFailedToEngage: boolean;
  duplicateRecord: boolean;
  insurancePaidInFull: boolean;
  accountHolderPaidBalanceOwed: boolean;
  accountHolderPaidBalanceOwedDueARefund: boolean;
  revokedPaymentPlan: boolean;
  providersRequest: boolean;
  refinanced: boolean;
  hreApprovedDirectBillPaymentMadeToFacility: boolean;
  hreApprovedPaymentAtFacility: boolean;
  hreApprovedFullScholarship: boolean;
  missingFundingSource: boolean;
}

export interface PatientClientFilter {
  clientName: string;
  clientId: number;
  facilities?: PatientFacilityFilter[];
}

export interface PatientFacilityFilter {
  clientFacilityId: number;
  clientFacilityName: string;
}

export const emptyPreConvertedPatientFilter: preConvertedPatientFilter = {
  dateRange: "3",
  recentlyViewed: true,
  reminders: false,
  newPatients: true,
  newNone: true,
  newPendingPaymentAtFacility: true,
  followUp: false,
  educationalCurriculum: false,
  paymentCurriculum: false,
  awaitingConsentDocs: false,
  awaitingHREApproval: false,
  secondContactAttempt: false,
  thirdContactAttempt: false,
  awaitingClient: false,
  patientChampion: false,
  paymentMethodNeeded: false,
  closed: false,
  fileError: false,
  patientFailedToEngage: false,
  hreRejectedByPatient: false,
  hreRejectedByClient: false,
  patientNeverAdmitted: false,
  isPreConverted: false,
  noStatus: false,
  hreApproved: false,
  hreApprovedPaymentConfirmationRequired: false,
  hreApprovedDirectBillFollowUp: false,
  finpayFailedToEngage: false,
  duplicateRecord: false,
  patientCoveredAt100Percent: false,
  hreApprovedForPaymentAtFacilityPaymentConfirmed: false,
  hreApprovedForFullScholarship: false,
  mobilePaymentFailureFollowUp: false,
  mobilePaymentFailureClosed: false,
  mobilePaymentRetryLimit: false,
  mobilePaymentRetryLimitClosed: false,
  followUpNeededPendingPaymentAtFacility: false,
};

export const emptyConvertedPatientFilter: convertedPatientFilter = {
  dateRange_converted: "3",
  recentlyViewedPatientsConverted: true,
  reminders: false,
  goodStanding: false,
  paid: false,
  priority: false,
  awaitingAuthorizations: false,
  awaitingACHVerification: false,
  atRisk: false,
  missedPayment: false,
  pastDueBalance: false,
  balanceAdjustmentRequired: false,
  onHold: false,
  closed_converted: false,
  fileError_converted: false,
  patientFailedToEngage_converted: false,
  patientNeverAdmitted_converted: false,
  default: false,
  isPreConverted: false,
  finpayFailedToEngage: false,
  duplicateRecord: false,
  insurancePaidInFull: false,
  accountHolderPaidBalanceOwed: false,
  accountHolderPaidBalanceOwedDueARefund: false,
  revokedPaymentPlan: false,
  providersRequest: false,
  refinanced: false,
  hreApprovedDirectBillPaymentMadeToFacility: false,
  hreApprovedPaymentAtFacility: false,
  hreApprovedFullScholarship: false,
  missingFundingSource: false,
};

export const preconvertedFilterTypes = {
  recentlyViewed: 'recentlyViewedPatients',
  reminders: 'reminders',
  // new
  newPatients: 'newPatients',
  newNone: 'newNone',
  newPendingPaymentAtFacility: 'newPendingPaymentAtFacility',
  // follow up
  educationalCurriculum: 'educationalCurriculum',
  paymentCurriculum: 'paymentCurriculum',
  awaitingConsentDocs: 'awaitingConsentDocs',
  awaitingHREApproval: 'awaitingHREApproval',
  secondContactAttempt: 'secondContactAttempt',
  thirdContactAttempt: 'thirdContactAttempt',
  awaitingClient: 'awaitingClient',
  patientChampion: 'patientChampion',
  paymentMethodNeeded: 'paymentMethodNeeded',
  noStatus: 'noStatus',
  hreApproved: 'hreApproved',
  hreApprovedPaymentConfirmationRequired: 'hreApprovedPaymentConfirmationRequired',
  hreApprovedDirectBillFollowUp: 'hreApprovedDirectBillFollowUp',
  mobilePaymentFailureFollowUp: 'mobilePaymentFailureFollowUp',
  mobilePaymentRetryLimit: 'mobilePaymentRetryLimit',
  followUpNeededPendingPaymentAtFacility: 'followUpNeededPendingPaymentAtFacility',
  
  // closed
  fileError: 'fileError',
  patientFailedToEngage: 'patientFailedToEngage',
  patientNeverAdmitted: 'patientNeverAdmitted',
  hreRejectedByPatient: 'hreRejectedByPatient',
  hreRejectedByClient: 'hreRejectedByClient',
  finpayFailedToEngage: 'finpayFailedToEngage',
  duplicateRecord: 'duplicateRecord',
  patientCoveredAt100Percent: 'patientCoveredAt100Percent',
  hreApprovedForPaymentAtFacilityPaymentConfirmed: 'hreApprovedForPaymentAtFacilityPaymentConfirmed',
  hreApprovedForFullScholarship: 'hreApprovedForFullScholarship',
  mobilePaymentFailureClosed: 'mobilePaymentFailureClosed',
  mobilePaymentRetryLimitClosed: 'mobilePaymentRetryLimitClosed',
};

export const convertedFilterTypes = {
  recentlyViewedPatientsConverted: 'recentlyViewedPatientsConverted',
  reminders: 'reminders',
  goodStanding: 'goodStanding',
  paid: 'paid',
  // priority
  awaitingAuthorizations: 'awaitingAuthorizations',
  awaitingACHVerification: 'awaitingACHVerification',
  // at risk
  missedPayment: 'missedPayment',
  pastDueBalance: 'pastDueBalance',
  balanceAdjustmentRequired: 'balanceAdjustmentRequired',
  onHold: 'onHold',
  // closed
  fileError_converted: 'fileError_converted',
  patientFailedToEngage: 'patientFailedToEngage_converted',
  patientNeverAdmitted: 'patientNeverAdmitted_converted',
  default: 'default',
  finpayFailedToEngage: 'finpayFailedToEngage',
  duplicateRecord: 'duplicateRecord',
  insurancePaidInFull: 'insurancePaidInFull',
  accountHolderPaidBalanceOwed: 'accountHolderPaidBalanceOwed',
  accountHolderPaidBalanceOwedDueARefund: 'accountHolderPaidBalanceOwedDueARefund',
  revokedPaymentPlan: 'revokedPaymentPlan',
  providersRequest: 'providersRequest',
  refinanced: 'refinanced',
  hreApprovedDirectBillPaymentMadeToFacility: 'hreApprovedDirectBillPaymentMadeToFacility',
  hreApprovedPaymentAtFacility: 'hreApprovedPaymentAtFacility',
  hreApprovedFullScholarship: 'hreApprovedFullScholarship',
  missingFundingSource: 'missingFundingSource',
};
