import { Button, ButtonType } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, Grid, SvgIconProps, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface LockModalProps {
  open: boolean;
  title: string;
  subTitle1: string; // line 1
  subTitle2?: string; // optional line 2
  hasCountdown?: boolean;
  countdownSeconds?: number;
  primaryButtonText: string;
  primaryButtonType: ButtonType;
  handlePrimaryButton: () => void;
  secondaryButtonText?: string;
  secondaryButtonType?: ButtonType;
  handleSecondaryButton?: () => void;
  icon: SvgIconProps; // e.g. <LockIcon />
  primaryButtonDisabled?: boolean;
  modalType: "error" | "warning" | "info"; // Top 10px border in red, yellow, blue
  justifyButtons: "space-between" | "flex-end";
}

function LockModal(props: LockModalProps) {
  const { open, title, subTitle1, subTitle2, hasCountdown, countdownSeconds = 30, primaryButtonText, secondaryButtonText, icon, primaryButtonDisabled,
    handlePrimaryButton, handleSecondaryButton, primaryButtonType, secondaryButtonType, modalType, justifyButtons } = props;

    let [countdown, setCountdown] = useState(countdownSeconds);
    const [countdownText, setCountdownText] = useState(subTitle2);

    useEffect(() => {
      let interval: any;
      if (hasCountdown) {
        interval = setInterval(() => {
          if (countdown === 1) {
            handlePrimaryButton();
          }
          // eslint-disable-next-line
          setCountdown(countdown -= 1)
          const updatedCountdownText = subTitle2?.replace(/\d{2}/, countdown.toString())
          setCountdownText(updatedCountdownText);
        }, 1000)
      }

      return () =>{
        clearInterval(interval);
      }

    }, [])

  return (
    <Dialog
      className={`modal lock-modal ${modalType}`}
      scroll="body"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogContent>
        <Grid container direction="column" alignItems="center">
          <Grid xs={12} item className="mb-4 mt-2">
            <>{icon}</>
          </Grid>
          <Grid xs={12} item className="mb-2">
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography variant="body1">{subTitle1}</Typography>
          </Grid>
          <Grid xs={12} item>
            {subTitle2 &&
              <Typography variant="body1">{hasCountdown ? countdownText : subTitle2}</Typography>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={justifyButtons}>
          <Grid item>
            {secondaryButtonText &&
              <Button onClick={handleSecondaryButton} type={secondaryButtonType}>
                {secondaryButtonText}
              </Button>}
          </Grid>
          <Grid item>
            <Button disabled={primaryButtonDisabled} type={primaryButtonType} onClick={handlePrimaryButton} marginLeft={6}>
              {primaryButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default LockModal;
