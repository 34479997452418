import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthDocs, AuthDocsForm } from "../../models/auth-docs-form";
import { RootState } from "../../../shared/state/root-reducer";
import { Utils } from "../../../shared/utils";
import { authDocsService } from "../../services/implementation-auth-docs-service";
import { ImplementationAuthDocs } from "../../components/implementation-clients/details/models/implementation-auth-docs";
import {
  addAuthDocsWindowObjectReference,
  resetAuthDocsWindowObjectReference,
} from "./implementation-auth-docs-slice";
import _ from "lodash";
import { showErrorStatus } from "../../../security/state/user-slice";

export const saveAuthDocs = createAsyncThunk(
  "implementationAuthDocsContext/saveAuthDocs",
  async (
    data: {
      clientId: number | null;
      clientFacilityId?: number | null;
      formValues: AuthDocsForm;
      existingAuthDocs: Set<string>;
    },
    thunkAPI
  ) => {
    const state = thunkAPI.getState() as RootState;

    const fullClientAuthDocs = Utils.deepClone(
      state?.authDocsContext?.implementationAuthDocs?.apiResponseAuthDocs
    );

    const allClientAuthDocs = Utils.deepClone(
      state?.authDocsContext?.implementationAuthDocs?.authDocs
    );

    const response = await authDocsService.saveAuthDocs(
      data.clientId,
      data.clientFacilityId,
      data.formValues,
      fullClientAuthDocs,
      allClientAuthDocs,
      data.existingAuthDocs
    );

    if (response?.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    }
    return response?.entity;
  }
);

export const getClientAuthDocs = createAsyncThunk(
  "implementationAuthDocsContext/getClientAuthDocs",
  async (
    data: { clientId: number; clientFacilityId: number | null },
    thunkAPI
  ) => {
    const response = await authDocsService.getClientAuthDocs(
      data.clientId,
      data.clientFacilityId
    );

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
);

export const getClientAuthDoc = createAsyncThunk(
  "implementationAuthDocsContext/getClientAuthDoc",
  async (data: { authorizationDocumentsId: number }, thunkAPI) => {
    const response = await authDocsService.getClientAuthDoc(
      data.authorizationDocumentsId
    );

    if (response.hasErrors) {
      Utils.robustErrorHandler(response, thunkAPI);
    }
    return response.entity;
  }
);

export const getAuthDocumentsWindow = createAsyncThunk(
  "implementationAuthDocsContext/getAuthDocuments",
  async (authDocs: ImplementationAuthDocs | AuthDocs, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (
      state.authDocsContext.implementationAuthDocs.authDocsWindowObjectReference
        .length > 0
    ) {
      state.authDocsContext.implementationAuthDocs.authDocsWindowObjectReference[0].close();

      thunkAPI.dispatch(resetAuthDocsWindowObjectReference());
    }

    if (!_.isEmpty(authDocs)) {
      if (!authDocs) {
        thunkAPI.dispatch(
          showErrorStatus("No Authorization Documents Available")
        );
      } else {
        const authDocsWindow = window.open(
          authDocs?.documentUrl,
          "",
          "width=800, height=800, menubar=0, toolbar=0"
        );
        thunkAPI.dispatch(addAuthDocsWindowObjectReference(authDocsWindow));
      }
    }
  }
);

export const deleteAuthDocs = createAsyncThunk(
  "implementationAuthDocsContext/deleteAuthDocuments",
  async (
    data: {
      authorizationDocumentsId: number;
    },
    thunkAPI
  ) => {
    const response = await authDocsService.deleteAuthDocs(
      data.authorizationDocumentsId
    );

    if (response.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }

    return response.entity;
  }
);

export const sendAuthDocsTest = createAsyncThunk(
  "implementationAuthDocsContext/sendAuthDocsTest",
  async (
    data: {
      formValues: AuthDocsForm;
    },
    thunkAPI
  ) => {
    const response = await authDocsService.sendDocumentsTest(
      data.formValues,
    );

    if (response?.hasErrors) {
      thunkAPI.dispatch(showErrorStatus(response.errorMessage));
      throw new Error(response.errorMessage);
    }
    return response?.entity;
  }
);
