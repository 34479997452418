import { TimingRiskMetaData } from "../../meta-data/models/metaData";
import { Client } from "../../shared/model/client";
import { UserRole, emptyUserRole } from "./user-role";

export class User {
    userId!: number;
    userName!: string;
    firstName!: string;
    lastName!: string;
    mobilePhone!: string;
    externalId!: string;
    userScope!: string;
    isPatient!: boolean;
    scopeClientId!: number;
    scopeEntityId!: number;
    clientId!: number;
    entityId!: number;
    landingPageRoute!: string;
    hasAgreedToTc!: boolean;
    hasFacilityLvlAccess!: boolean;
    allowedClients!: Client[];
    isActive!: boolean;
    cognitoStatus?: Record<string, any>;
    userRole!: UserRole;
    isSSO!: boolean;
    allowedTimingRisks!: TimingRiskMetaData[];
    userTimingRisk!: TimingRiskMetaData[];
    hasAllTimingRisks!: boolean;

}

export const emptyUser: User = {
    userId: 0,
    userName: "",
    firstName: "",
    lastName: "",
    isPatient: false,
    mobilePhone: "",
    externalId: "",
    userScope: "",
    scopeClientId: 0,
    scopeEntityId: 0,
    clientId: 0,
    entityId: 0,
    landingPageRoute: "",
    hasAgreedToTc: false,
    hasFacilityLvlAccess: false,
    isSSO: false,
    allowedClients: [],
    isActive: false,
    userRole: emptyUserRole,
    allowedTimingRisks: [],
    userTimingRisk:[],
    hasAllTimingRisks: false

}

// Get Default Values for Add New User Dialog
export function  getDefaultUser() : User {

    const newUser: User = {...emptyUser};
    newUser.isActive = true;
    newUser.userScope = "FINPAY";
    newUser.hasFacilityLvlAccess = true;
    newUser.isSSO = false;
    newUser.allowedClients = [];
    newUser.allowedClients.push({
        isAll: true,
        userClientScopeId: 0,
        clientId: 0,
        clientName: "All Clients",
        allowedFacilities: []
    });
    newUser.allowedTimingRisks = [];
    newUser.userTimingRisk = [];

    return newUser;
  }
