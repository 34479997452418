export interface AuthDocs {
    authorizationDocumentsId?: number;
    typeOfDoc: string;
    documentUrl: string;
}



export const authorizationDocuments: AuthDocs[] = [
    {
        authorizationDocumentsId:0,
        typeOfDoc: "phi",
        documentUrl: "",
    },
    {
        authorizationDocumentsId:0,
        typeOfDoc: "spa",
        documentUrl: "",
    },
    {
        authorizationDocumentsId:0,
        typeOfDoc: "pfr",
        documentUrl: "",
    },
    {
        authorizationDocumentsId:0,
        typeOfDoc: "inst",
        documentUrl: "",
    }
]

export const emptyAuthDocsType: AuthDocs = {
    authorizationDocumentsId: 0,
    typeOfDoc: "",
    documentUrl: "",
}