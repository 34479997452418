import "../../../scss/components/_forms.scss";

import { useSelector } from "react-redux";
import _ from "lodash/fp";

import { RootState } from "../../../shared/state/root-reducer";

import SpecialistHeader from "./specialist-header";
import PatientTiles from "./patient-tiles";

import { RolePageNames } from "../../../security/model/role-page-names";
import { checkPermissions } from "../../../security/components/access-control";

const SpecialistDashboard = () => {
  const userProfile = useSelector(
    (state: RootState) => state.userContext.userProfile,
    _.isEqual
  );

  const canUseOpenSearch = checkPermissions(
    userProfile.userRole.userRolePages,
    RolePageNames.PatientRecords,
    "All Patient Search",
    false
  );

  return (
    <main>
      <div className="content fullwidth px-4 py-4">
        <SpecialistHeader canUseOpenSearch={canUseOpenSearch} />
        <PatientTiles />
      </div>
    </main>
  );
};

export default SpecialistDashboard;
