import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { LogsInfo } from "../../models/log-info";

interface AdminUsersResultsProps {
  dataLogs: LogsInfo[]
}

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
}

const columns: Column[] = [
  // id must be the key in the FilteredUserInfo model
  { id: "date", label: "Date", minWidth: 170 },
  { id: "user", label: "User", minWidth: 100 },
  { id: "patient", label: "Patient", minWidth: 170 },
  { id: "client", label: "Client", minWidth: 170 },
];

function LogsResults(props: AdminUsersResultsProps) {
  const { dataLogs } = props;

  const allUsers = useSelector((state: RootState) => {
    return state.adminContext.adminUserContext.userSearch.allUsers;
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className="mt-8">
      <>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100, { value: allUsers.length, label: 'All'  }]}
          component="div"
          count={dataLogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"View"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table
            data-testid="results"
            className="table alternating-row"
            size="small"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLogs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((log: LogsInfo) => {
                  return (
                    <TableRow
                      data-testid="result-row"
                      hover
                      tabIndex={-1}
                      key={log.userId}
                    >
                      {columns.map((column) => {
                        const value = (log as any)[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                                <Typography className="py-3" variant="body1">{value}</Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Paper>
  );
}

export default LogsResults;
