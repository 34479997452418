import { useFormik } from "formik";
import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import * as Yup from "yup";
import {
  TextField,
  DialogActionButton,
  saveCallbackStatus,
} from "@finpay-development/shared-components";
import { RootState } from "../../../../../shared/state/root-reducer";
import { saveFacilityRiskSetting } from "../../../../state/clients/implementation-clients-thunk";
import { showErrorStatus } from "../../../../../security/state/user-slice";
import { clearRiskSettingEditStatus } from "../../../../state/clients/implementation-clients-slice";
import { FacilityRiskThreshold } from "../../../../../admissions-advisor/models/risk-assessment";
import { getFacilityRiskThresholds } from "../../../../../admissions-advisor/state/risk-assessment-thunk";
import {
  ConfigRiskThreshold,
  RiskThresholdSymbols,
} from "../../../../../admin-configuration/models/risk-threshold";
import { riskAssessmentUtils } from "../../../../../admissions-advisor/utils/risk-assessment-utils";

interface RiskSettingsModalProps {
  open: boolean;
  riskSettingItem: FacilityRiskThreshold;
  configRiskSettingItem: ConfigRiskThreshold;
  handleRiskSettingsModalCancel: () => void;
  handleRiskSettingsModalSubmit: () => void;
}

export function RiskSettingsModal(props: RiskSettingsModalProps) {
  const {
    open,
    riskSettingItem,
    configRiskSettingItem,
    handleRiskSettingsModalCancel,
    handleRiskSettingsModalSubmit,
  } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const symbols: RiskThresholdSymbols =
    riskAssessmentUtils.getRiskThresholdSymbols(
      configRiskSettingItem?.riskThresholdCode
    );

  const paramId: number = -2;

  const initialSettingValues: FacilityRiskThreshold = {
    paramId: paramId,
    facilityRiskThresholdId: riskSettingItem.facilityRiskThresholdId,
    clientFacilityId: riskSettingItem.clientFacilityId,
    cfgRiskThresholdId: riskSettingItem.cfgRiskThresholdId,
    lowRisk: riskSettingItem.lowRisk,
    mediumRisk: riskSettingItem.mediumRisk,
    highRisk: riskSettingItem.highRisk,
  };

  const validationSchema = Yup.object({
    lowRisk: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number())),
    mediumRisk: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number())),
    highRisk: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number()))
  });

  const riskFormik = useFormik({
    initialValues: initialSettingValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSave();
    },
    validationSchema: validationSchema,
    validate: checkIfIsValid,
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  const saveStatus = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.modalSaveStatus
  );

  const errorMessage = useSelector(
    (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.errorMessage
  );

  async function handleSave() {
    const requestBody: FacilityRiskThreshold = {
      paramId: paramId,
      facilityRiskThresholdId: riskSettingItem.facilityRiskThresholdId,
      clientFacilityId: riskSettingItem.clientFacilityId,
      cfgRiskThresholdId: riskSettingItem.cfgRiskThresholdId,
      lowRisk: (riskFormik.values.lowRisk?.toString() !== "")? riskFormik.values.lowRisk : null,
      mediumRisk: (riskFormik.values.mediumRisk?.toString() !== "")? riskFormik.values.mediumRisk : null,
      highRisk: (riskFormik.values.highRisk?.toString() !== "")? riskFormik.values.highRisk : null,
    }
    await dispatch(saveFacilityRiskSetting(requestBody));
  }

  function handleSaveCallback() {
    if (saveStatus === saveCallbackStatus.success) {
      handleRiskSettingsModalSubmit();
      setEnableSaveButton(false);
      dispatch(
        getFacilityRiskThresholds({
          paramId: paramId,
          facilityId: riskSettingItem.clientFacilityId!,
        })
      );
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearRiskSettingEditStatus());
  }

  function handleCancelCallback() {
    handleRiskSettingsModalCancel();
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>Edit Risk Setting</DialogTitle>
      <DialogContent>
        <form onSubmit={riskFormik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="mt-6" variant="subtitle2">
                {configRiskSettingItem.riskThresholdName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid xs={4} item>
              <TextField
                error={Boolean(
                  riskFormik.touched["lowRisk"] && riskFormik.errors["lowRisk"]
                    ? riskFormik.errors["lowRisk"]
                    : ""
                )}
                helperText={
                  riskFormik.touched["lowRisk"] && riskFormik.errors["lowRisk"]
                    ? riskFormik.errors["lowRisk"]
                    : ""
                }
                label="Low Risk"
                name="lowRisk"
                type="number"
                value={riskFormik.values.lowRisk}
                onChange={riskFormik.handleChange}
                onBlur={riskFormik.handleBlur}
                endAdornment={symbols.fieldAdornment}
              />
            </Grid>
            <Grid xs={4} item>
              <TextField
                error={Boolean(
                  riskFormik.touched["mediumRisk"] &&
                    riskFormik.errors["mediumRisk"]
                    ? riskFormik.errors["mediumRisk"]
                    : ""
                )}
                helperText={
                  riskFormik.touched["mediumRisk"] &&
                  riskFormik.errors["mediumRisk"]
                    ? riskFormik.errors["mediumRisk"]
                    : ""
                }
                label="Medium Risk"
                name="mediumRisk"
                type="number"
                value={riskFormik.values.mediumRisk}
                onChange={riskFormik.handleChange}
                onBlur={riskFormik.handleBlur}
                endAdornment={symbols.fieldAdornment}
              />
            </Grid>
            <Grid xs={4} item>
              <TextField
                error={Boolean(
                  riskFormik.touched["highRisk"] &&
                    riskFormik.errors["highRisk"]
                    ? riskFormik.errors["highRisk"]
                    : ""
                )}
                helperText={
                  riskFormik.touched["highRisk"] &&
                  riskFormik.errors["highRisk"]
                    ? riskFormik.errors["highRisk"]
                    : ""
                }
                label="High Risk"
                name="highRisk"
                type="number"
                value={riskFormik.values.highRisk}
                onChange={riskFormik.handleChange}
                onBlur={riskFormik.handleBlur}
                endAdornment={symbols.fieldAdornment}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={"Update"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
