import { Button, DialogActionButton, TextField } from '@finpay-development/shared-components';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, MenuItem, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FieldArray, Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../security/state/user-slice';
import { oneHundredLimit, termsValidator } from '../../../shared/misc/regex';
import { payorRisk, timingRisk } from '../../../shared/model/timing-and-payor-risk';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { RuleEditViewModel } from '../../models/rule';
import { clearStatus } from '../../state/admin-configuration-slice';
import { configSaveRule } from '../../state/admin-configuration-thunk';

interface ClientModalProps {
  open: boolean;
  handleRuleModalCancel: () => void;
  handleRuleModalSubmit: (isEditMode: boolean) => void;
}

export function RuleModal(props: ClientModalProps) {
  const { open, handleRuleModalCancel, handleRuleModalSubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const currentRule = useSelector(
    (state: RootState) => {
      return state.adminContext.adminConfigurationContext.currentRule;
    }
  );

  const saveStatus = useSelector(
    (state: RootState) => {
      return state.adminContext.adminConfigurationContext.modalSaveStatus;
    }
  );

  const errorMessage = useSelector(
    (state: RootState) => {
      return state.adminContext.adminConfigurationContext.errorMessage;
    }
  );

  async function handleSave() {
    const formValues: RuleEditViewModel = formRef.current.values;
    await dispatch(configSaveRule(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleRuleModalSubmit(isEditMode);
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleRuleModalCancel();
  }

  const initialValues: RuleEditViewModel = {
    ruleId: currentRule.ruleId,
    timingRisk: currentRule.timingRisk,
    payorRisk: currentRule.payorRisk,
    availableTerms: currentRule.availableTerms,
    optimalTerm: currentRule.optimalTerm,
    ruleCriteria: currentRule.ruleCriteria.map((rule) => {
      return {
        paramName: rule.paramName,
        comparator: rule.comparator,
        paramValue: rule.paramValue,
      };
    }),
    minDownPmt: currentRule.minDownPmt,
    minDownPmtType: currentRule.minDownPmtType,
    optimalDownPmt: currentRule.optimalDownPmt,
    optimalDownPmtType: currentRule.optimalDownPmtType,
    sortOrder: currentRule.sortOrder,
  };

  const isEditMode = currentRule.ruleId > 0;

  const validationSchema = Yup.object({
    timingRisk: Yup.number().positive("Required"), // 'required' validation type doesn't work with dropdowns. Instead check for not -1 which is placeholder top value
    payorRisk: Yup.number().positive("Required"), // 'required' validation type doesn't work with dropdowns. Instead check for not -1 which is placeholder top value
    availableTerms: Yup.string()
      .matches(termsValidator, "Separate terms with commas and maximum 36"),
    optimalTerm: Yup.string()
      .matches(/\d/, "Must be a number")
      .test(
        "match-approved-terms",
        "Must be one of the terms",
        function (value) {
          if (this.parent.availableTerms) {
            const availableTermsArray = this.parent.availableTerms
              .replaceAll(" ", "")
              .split(",");
            return availableTermsArray.includes(value);
          } else {
            return false;
          }
        }
      ),
    ruleCriteria: Yup.array().of(
      Yup.object({
        paramName: Yup.string().required("Required"),
        comparator: Yup.string().required("Required"),
        paramValue: Yup.string()
          .matches(/\d/, "Must be a number"),
      })
    ),
    minDownPmt: Yup.string()
      .when("minDownPmtType", {
        is:"%",
        then: Yup.string().matches(oneHundredLimit, "Must be greater than 0 and maximum 99")
      })
      .matches(/\d/, "Must be a number"),
    optimalDownPmt: Yup.string()
      .when("optimalDownPmtType", {
        is:"%",
        then: Yup.string().matches(oneHundredLimit, "Must be greater than 0 and maximum 99")
      })
      .matches(/\d/, "Must be a number"),
  });

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal rule-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">{isEditMode ? "Edit" : "Add New"} Rule</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={1}>
                <Grid xs={6} item className="mb-6">
                  <TextField
                    disabled={isEditMode}
                    select
                    error={
                      formik.touched["timingRisk"] &&
                      formik.errors["timingRisk"]
                        ? formik.errors["timingRisk"]
                        : ""
                    }
                    label="Timing Risk"
                    name="timingRisk"
                    value={formik.values.timingRisk}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select Timing Risk</MenuItem>
                    {timingRisk.map((risk) => (
                      <MenuItem
                        key={risk.timingRiskId}
                        value={risk.timingRiskId}
                      >
                        {risk.timingRiskName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    disabled={isEditMode}
                    select
                    error={
                      formik.touched["payorRisk"] && formik.errors["payorRisk"]
                        ? formik.errors["payorRisk"]
                        : ""
                    }
                    label="Payer Risk"
                    name="payorRisk"
                    value={formik.values.payorRisk}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Select Payer Risk"
                  >
                    <MenuItem value="-1">Select Payer Risk</MenuItem>
                    {payorRisk.map((risk) => (
                      <MenuItem key={risk.payorRiskId} value={risk.payorRiskId}>
                        {risk.payorRiskName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid xs={8} item>
                  <TextField
                    error={
                      formik.touched["availableTerms"] &&
                      formik.errors["availableTerms"]
                        ? formik.errors["availableTerms"]
                        : ""
                    }
                    label="Approved Terms"
                    name="availableTerms"
                    value={formik.values.availableTerms}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Approved Terms"
                    helperText="Separate terms with commas"
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    error={
                      formik.touched["optimalTerm"] &&
                      formik.errors["optimalTerm"]
                        ? formik.errors["optimalTerm"]
                        : ""
                    }
                    label="Optimal Term"
                    name="optimalTerm"
                    value={formik.values.optimalTerm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Optimal Term"
                    helperText="Applied to this risk class"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography className="mt-6" variant="subtitle2">
                    Condition
                  </Typography>
                </Grid>
                <FieldArray name="ruleCriteria">
                  {({ remove, push }) => (
                    <>
                      {formik.values.ruleCriteria?.map((_, i: number) => (
                        <div className="w-100" key={i}>
                          {i > 0 && (
                            <Grid
                              className="mt-4"
                              container
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="subtitle2">And</Typography>
                              </Grid>
                              <Grid item>
                                <IconButton color="primary" onClick={() => remove(i)} size="large">
                                  <ClearIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )}

                          <Grid container spacing={1} key={i}>
                            <Grid item xs>
                              <TextField
                                select
                                label="if"
                                name={`ruleCriteria.${i}.paramName`}
                                value={
                                  formik.values.ruleCriteria?.[i].paramName
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <MenuItem value="-1">Select</MenuItem>
                                <MenuItem value={1}>PFR</MenuItem>
                              </TextField>
                            </Grid>
                            <Grid item>
                              <div
                                className={`buttongroup ${ formik.values.ruleCriteria?.[i].paramName < 0 ? "disabled" : "" }`}
                              >
                                <input
                                  id={`1.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value="="
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === "="
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`1.${i}.comparator`}>=</label>
                                <input
                                  id={`2.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value="!="
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === "!="
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`2.${i}.comparator`}>
                                  &ne;
                                </label>

                                <input
                                  id={`3.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value="<"
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === "<"
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`3.${i}.comparator`}>
                                  &lt;
                                </label>

                                <input
                                  id={`4.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value=">"
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === ">"
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`4.${i}.comparator`}>
                                  &gt;
                                </label>

                                <input
                                  id={`5.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value="<="
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === "<="
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`5.${i}.comparator`}>
                                  &le;
                                </label>

                                <input
                                  id={`6.${i}.comparator`}
                                  disabled={
                                    formik.values.ruleCriteria?.[i]
                                      .paramName === 0
                                  }
                                  type="radio"
                                  value=">="
                                  name={`ruleCriteria.${i}.comparator`}
                                  checked={
                                    formik.values.ruleCriteria?.[i]
                                      .comparator === ">="
                                  }
                                  onChange={formik.handleChange}
                                />
                                <label htmlFor={`6.${i}.comparator`}>
                                  &ge;
                                </label>
                              </div>
                            </Grid>
                            <Grid item xs>
                              <TextField
                                disabled={
                                  formik.values.ruleCriteria?.[i].paramName < 0
                                }
                                label="Value"
                                name={`ruleCriteria.${i}.paramValue`}
                                value={
                                  formik.values.ruleCriteria?.[i].paramValue
                                }
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                startAdornment="$"
                              />
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                      <Grid item xs={12} className="mt-6 mb-6">
                        <Button
                          type="text"
                          icon={<AddIcon />}
                          paddingLeft={0}
                          onClick={() =>
                            push({
                              paramName: -1,
                              comparator: "",
                              paramValue: "",
                            })
                          }
                        >
                          Add And Statement
                        </Button>
                      </Grid>
                    </>
                  )}
                </FieldArray>

                <Grid container spacing={1}>
                  <Grid xs item>
                    <TextField
                      error={
                        formik.touched["minDownPmt"] &&
                        formik.errors["minDownPmt"]
                          ? formik.errors["minDownPmt"]
                          : ""
                      }
                      label="Min Down"
                      name="minDownPmt"
                      value={formik.values.minDownPmt}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      startAdornment={formik.values.minDownPmtType === "$" && "$"}
                      endAdornment={formik.values.minDownPmtType === "%" && "%"}
                    />
                  </Grid>
                  <Grid item>
                    <div className="buttongroup">
                      <input
                        id="min-down-2"
                        type="radio"
                        value="%"
                        name="minDownPmtType"
                        checked={formik.values.minDownPmtType === "%"}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="min-down-2">%</label>
                      <input
                        id="min-down-1"
                        type="radio"
                        value="$"
                        name="minDownPmtType"
                        checked={formik.values.minDownPmtType === "$"}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="min-down-1">$</label>
                    </div>
                  </Grid>
                  <Grid xs item>
                    <TextField
                      error={
                        formik.touched["optimalDownPmt"] &&
                        formik.errors["optimalDownPmt"]
                          ? formik.errors["optimalDownPmt"]
                          : ""
                      }
                      label="Optimal Down"
                      name="optimalDownPmt"
                      value={formik.values.optimalDownPmt}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      startAdornment={formik.values.optimalDownPmtType === "$" && "$"}
                      endAdornment={formik.values.optimalDownPmtType === "%" && "%"}
                    />
                  </Grid>
                  <Grid item>
                    <div className="buttongroup">
                      <input
                        id="optimal-down-2"
                        type="radio"
                        value="%"
                        name="optimalDownPmtType"
                        checked={formik.values.optimalDownPmtType === "%"}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="optimal-down-2">%</label>
                      <input
                        id="optimal-down-1"
                        type="radio"
                        value="$"
                        name="optimalDownPmtType"
                        checked={formik.values.optimalDownPmtType === "$"}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="optimal-down-1">$</label>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText= "Save"
          saveStatus={saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
