import "../../../scss/pages/admin/_admin-clients.scss";

import {
  DatePicker,
  DialogActionButton,
  TextField,
  Toggle,
} from "@finpay-development/shared-components";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { MenuItem, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import moment from "moment";

import AddressValidator from "../../../shared/components/address-validator";
import {
  stateAbbrevLookup,
  stateAbbrevs,
} from "../../../shared/misc/us-states";
import { ClientStatusCardViewModel } from "../../../shared/model/client-status-card";
import { PrimaryAddress } from "../../../shared/model/primary-address";
import {
  ContactIncome,
  PhoneType,
  PrimaryContact,
} from "../../../shared/model/primary-contact";
import { State } from "../../../shared/model/state";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { Utils } from "../../../shared/utils";
import { patientInfo } from "../../../shared/validation/schemas";
import { savePatient } from "../../state/patient-thunk";
import { PatientViewModel } from "../models/patient-view-model";

interface PatientModalProps {
  open: boolean;
  isEdit: boolean;
  handleModalCancel: () => void;
  handleModalSubmit: () => void;
}

export function PatientModal(props: PatientModalProps) {
  const selectors = {
    clients: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .filteredByIsActive
    ),
    selectedPatient: useSelector(
      (state: RootState) => state.patientContext.selectedPatient
    ),
    modalSaveStatus: useSelector(
      (state: RootState) => state.patientContext.savePatientModalSaveStatus
    ),
    errorMessage: useSelector(
      (state: RootState) => state.patientContext.errorMessage
    ),
    shouldReFetchClients: useSelector((state: RootState) => {
      return !(
        state.implementationContext?.implementationSpecialistClient?.allClients
          ?.length > 0
      );
    }),
  };

  const { clients, modalSaveStatus, shouldReFetchClients } = selectors;
  const { open, isEdit, handleModalCancel, handleModalSubmit } = props;

  const addressValidator = new AddressValidator();

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  const mapToSaveModel = () => {
    const patient = selectors.selectedPatient;
    const formValues = formRef.current.values;
    const stateInfo = stateAbbrevLookup(formValues.state);
    const state = {
      stateId: stateInfo.id,
      stateCode: formValues.state,
      stateName: stateInfo.name,
    } as State;

    const address: PrimaryAddress = {
      streetAddress1: formValues.streetAddress1,
      streetAddress2: formValues.streetAddress2,
      city: formValues.city,
      state: state,
      zipCode: formValues.zipCode,
      zipCode4: formValues.zipCode4Plus,
      addressType: "Primary",
    };

    const contactIncome = {
      isEmployed: formValues.isCurrentlyEmployed,
      yearlySalary: formValues.salary,
      employer: formValues.employerName,
      yearsAtEmployer: formValues.yearsAtEmployer,
    } as ContactIncome;
    const birthday = formValues.dateOfBirth;
    const formattedBirthday = Utils.convertDate(new Date(birthday));
    const contact = {
      title: formValues.title,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleName: formValues.middleName,
      primaryAddress: address,
      email: formValues.email,
      externalId: isEdit ? patient?.contact?.externalId : undefined,
      primPhoneNum: formValues.firstPhone,
      primPhoneType: formValues.isFirstPhoneMobile
        ? PhoneType.mobile
        : PhoneType.home,
      secPhoneNum: formValues.secondPhone,
      secPhoneType: formValues.isSecondPhoneMobile
        ? PhoneType.mobile
        : PhoneType.home,
      leaveVMFl: formValues.leaveVoicemail,
      smsConsentFl: formValues.receiveSMSMessages,
      ssn: !formValues.socialSecurityNumber?.includes("●")
        ? formValues.socialSecurityNumber
        : "",
      ssnLast4: !formValues.socialSecurityNumber?.includes("●")
        ? formValues.socialSecurityNumber.slice(-4)
        : "",
      birthDate: formattedBirthday,
      contactIncome: contactIncome,
    } as PrimaryContact;

    const patientToSave = {
        contact: contact,
        patientId: formValues?.patientId,
        clientId: formValues.clientId,
    } as PatientViewModel;

    if(isEdit) {
      delete patientToSave.clientId
    }

    return patientToSave
  };

  async function handleSave() {
    await getZipCodePlus4();
    const patientToSave = mapToSaveModel();
    await dispatch(savePatient({ patient: patientToSave }));
    handleModalSubmit();
  }

  function handleCancelCallback() {
    handleModalCancel();
  }

  const patientContact = selectors.selectedPatient.contact;

  const initialValuesEdit = {
    clientId: selectors.selectedPatient.clientId,
    patientId: selectors.selectedPatient.patientId,
    title: `${patientContact?.title ? patientContact.title : ""}`,
    firstName: patientContact.firstName,
    middleName: `${
      patientContact?.middleName ? patientContact.middleName : ""
    }`,
    lastName: patientContact.lastName,
    email: patientContact.email,
    retypeEmail: patientContact.email,
    firstPhone: patientContact.primPhoneNum,
    isFirstPhoneMobile: patientContact.primPhoneType === PhoneType.mobile,
    secondPhone: patientContact.secPhoneNum,
    isSecondPhoneMobile: patientContact.secPhoneType === PhoneType.mobile,
    leaveVoicemail: patientContact.leaveVMFl,
    receiveSMSMessages: patientContact.smsConsentFl,
    streetAddress1: patientContact?.primaryAddress?.streetAddress1,
    streetAddress2: `${
      patientContact?.primaryAddress?.streetAddress2
        ? patientContact.primaryAddress.streetAddress2
        : ""
    }`,
    city: patientContact.primaryAddress?.city,
    state: patientContact.primaryAddress?.state?.stateCode,
    zipCode: patientContact.primaryAddress?.zipCode,
    dateOfBirth: patientContact.birthDate,
    socialSecurityNumber: `${
      patientContact.ssnLast4 ? "●●●-●●-" + patientContact.ssnLast4 : ""
    }`,
    isCurrentlyEmployed: patientContact.contactIncome?.isEmployed || false,
    salary: patientContact.contactIncome?.yearlySalary || "",
    employerName: patientContact.contactIncome?.employer || "",
    yearsAtEmployer: patientContact.contactIncome?.yearsAtEmployer || "",
  };

  const initialValuesAdd = {
    clientId: null,
    patientId: 0,
    title: "-1",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    retypeEmail: "",
    firstPhone: "",
    isFirstPhoneMobile: false,
    secondPhone: "",
    isSecondPhoneMobile: false,
    leaveVoicemail: false,
    receiveSMSMessages: false,
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: "-1",
    zipCode: "",
    dateOfBirth: null,
    socialSecurityNumber: "",
    isCurrentlyEmployed: false,
    salary: "",
    employerName: "",
    yearsAtEmployer: "",
  };

  const validationSchema = Yup.object(patientInfo);

  const titles = ["Mr.", "Mrs.", "Ms.", "Dr."];

  async function getZipCodePlus4() {
    formRef.current.values.zipCode4Plus =
      await addressValidator.getZipCodePlus4(formRef.current.values);
  }

  function checkIfIsValid(value: any) {
    validationSchema
      .validate(value)
      .then(() => {
        if (Utils.isValidDate(value?.dateOfBirth)) {
          setEnableSaveButton(true);
        }
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">
          {`${isEdit ? "Edit" : "Add"}`} Patient Record
        </span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={isEdit ? initialValuesEdit : initialValuesAdd}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Treatment Provider
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    select={true}
                    error={
                      formik.touched["clientId"] && formik.errors["clientId"]
                    }
                    disabled={isEdit}
                    label="Client"
                    name="clientId"
                    value={formik.values.clientId}
                    placeholder="Select Client"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {clients.map((client: ClientStatusCardViewModel) => (
                      <MenuItem key={client.clientId} value={client.clientId}>
                        {client.clientName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Name</Typography>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={formik.touched["title"] && formik.errors["title"]}
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    placeholder="Select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {titles.map((title: string) => (
                      <MenuItem key={title} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["firstName"] && formik.errors["firstName"]
                    }
                    label="First Name"
                    placeholder="Enter First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={2} item>
                  <TextField
                    error={
                      formik.touched["middleName"] &&
                      formik.errors["middleName"]
                    }
                    label="M.I."
                    maxLength={2}
                    name="middleName"
                    value={formik.values.middleName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    error={
                      formik.touched["lastName"] && formik.errors["lastName"]
                    }
                    label="Last Name"
                    name="lastName"
                    placeholder="Enter Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Contact Information
                  </Typography>
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={formik.touched["email"] && formik.errors["email"]}
                    label="Email"
                    name="email"
                    placeholder="Enter Email Address"
                    value={formik.values.email}
                    maxLength={128}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeEmail"] &&
                      formik.errors["retypeEmail"]
                    }
                    label="Retype Email"
                    name="retypeEmail"
                    placeholder="Re-Enter Email Address"
                    value={formik.values.retypeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["firstPhone"] &&
                      formik.errors["firstPhone"]
                    }
                    label="Phone"
                    maxLength={12}
                    name="firstPhone"
                    placeholder="Enter Phone Number"
                    value={formik.values.firstPhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "firstPhone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Toggle
                    leftText="Mobile"
                    rightText="Home"
                    name="isFirstPhoneMobile"
                    value={formik.values.isFirstPhoneMobile}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["secondPhone"] &&
                      formik.errors["secondPhone"]
                    }
                    label="Phone"
                    name="secondPhone"
                    placeholder="Enter Phone Number"
                    maxLength={12}
                    value={formik.values.secondPhone}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "secondPhone",
                        Utils.formatPhoneNumber(
                          (e.target as HTMLInputElement).value
                        )
                      );
                    }}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={6} item>
                  <Toggle
                    leftText="Mobile"
                    rightText="Home"
                    name="isSecondPhoneMobile"
                    value={formik.values.isSecondPhoneMobile}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={3} item style={{ marginBottom: -12 }}>
                  <Typography variant="h4">Leave Voicemail</Typography>
                </Grid>
                <Grid xs={9} item style={{ marginBottom: -12 }}>
                  <Typography variant="h4">Receive SMS Messages</Typography>
                </Grid>
                <Grid xs={3} item>
                  <Toggle
                    name="leaveVoicemail"
                    value={formik.values.leaveVoicemail}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={9} item>
                  <Toggle
                    name="receiveSMSMessages"
                    value={formik.values.receiveSMSMessages}
                    formik={formik}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="subtitle2">Address</Typography>
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress1"] &&
                      formik.errors["streetAddress1"]
                    }
                    label="Street Address 1"
                    name="streetAddress1"
                    minLength={3}
                    placeholder="Enter Street Address"
                    value={formik.values.streetAddress1}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={12} item>
                  <TextField
                    error={
                      formik.touched["streetAddress2"] &&
                      formik.errors["streetAddress2"]
                    }
                    label="Street Address 2"
                    name="streetAddress2"
                    minLength={3}
                    value={formik.values.streetAddress2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={formik.touched["city"] && formik.errors["city"]}
                    label="City"
                    name="city"
                    placeholder="Enter City"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    select={true}
                    error={formik.touched["state"] && formik.errors["state"]}
                    label="State"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    <MenuItem value="-1">Select</MenuItem>
                    {stateAbbrevs.map((state: string) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid xs={3} item>
                  <TextField
                    error={
                      formik.touched["zipCode"] && formik.errors["zipCode"]
                    }
                    label="Zip Code"
                    minLength={5}
                    maxLength={5}
                    name="zipCode"
                    placeholder="Enter Zip Code"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Personal Information
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    data="date-input"
                    error={
                      Utils.isValidDate(formik.values.dateOfBirth) &&
                      formik.touched["dateOfBirth"] &&
                      formik.errors["dateOfBirth"]
                    }
                    label="Date of Birth"
                    value={formik.values.dateOfBirth}
                    onChange={(date: Date) => {
                      if (!Utils.isFutureDate(date))
                        formik.setFieldValue("dateOfBirth", date);
                      else formik.setFieldValue("dateOfBirth", moment());
                    }}
                    minDate={Utils.getMinDate()}
                    maxDate={moment()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={
                      formik.touched["socialSecurityNumber"] &&
                      formik.errors["socialSecurityNumber"]
                    }
                    label="Social Security Number"
                    maxLength={11}
                    name="socialSecurityNumber"
                    placeholder="Enter Social Security Number"
                    value={formik.values.socialSecurityNumber}
                    onChange={(e: Event) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "socialSecurityNumber",
                        Utils.formatSSN((e.target as HTMLInputElement).value)
                      );
                    }}
                    onBlur={formik.handleBlur}
                    required={false}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Typography variant="subtitle2">
                    Employment Information
                  </Typography>
                </Grid>
                <Grid xs={12} item className="ml-2">
                  <Typography variant="h4" style={{ marginBottom: -12 }}>
                    Currently Employed
                  </Typography>
                </Grid>
                <Grid xs={12} item>
                  <Toggle
                    name="isCurrentlyEmployed"
                    value={formik.values.isCurrentlyEmployed}
                    formik={formik}
                    marginBottom={4}
                  />
                </Grid>
                {formik.values.isCurrentlyEmployed && (
                  <>
                    <Grid xs={12} item>
                      <TextField
                        error={
                          formik.touched["salary"] && formik.errors["salary"]
                        }
                        type="number"
                        label="Salary"
                        placeholder="Enter Salary"
                        startAdornment={<AttachMoneyIcon />}
                        name="salary"
                        value={formik.values.salary}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["employerName"] &&
                          formik.errors["employerName"]
                        }
                        label="Employer Name"
                        name="employerName"
                        value={formik.values.employerName}
                        placeholder="Enter Employer Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        error={
                          formik.touched["yearsAtEmployer"] &&
                          formik.errors["yearsAtEmployer"]
                        }
                        type="number"
                        label="Years At Employer"
                        name="yearsAtEmployer"
                        placeholder="Enter Years at Employer"
                        value={formik.values.yearsAtEmployer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText="Save"
          saveStatus={modalSaveStatus}
          executeSave={handleSave}
          handleCallbackSave={() => {}} // handled in thunk
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
