
export class Column { // implementation and client onboarding
  id!: string;
  label?: string;
  minWidth?: number;
  align?: string;
  type?: string;
}

export const emptyColumn: Column = {
  id: "",
  label: "",
  minWidth: 0,
  align: "",
  type: ""
};
