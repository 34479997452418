
export interface ReleasePaymentErrorContext {
    paymentId?: number | null;
    encounterId?: number | null;
    patientId?: number | null;
    errorMsg?: string | null;
}

export const emptyReleasePaymentErrorContext: ReleasePaymentErrorContext = {
    paymentId: null,
    encounterId: null,
    patientId: null,
    errorMsg: null
}