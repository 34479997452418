import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserRole } from "../../security/model/user-role";
import { adminRoleService } from "../services/admin-role-services";
import { RootState } from "../../shared/state/root-reducer";
import { showErrorStatus, showStatus } from "../../security/state/user-slice";

export const getAdminUserRoles = createAsyncThunk("getAdminUserRoles", async () => {
  const response = await adminRoleService.getUserRoles();
  return response;
});

export const getPageRolesMetaData = createAsyncThunk("getPageRolesMetaData", async () => {
  const response = await adminRoleService.GetSystemRolePages();
  return response;
});

export const saveUserRole = createAsyncThunk("saveUserRole", async (data: UserRole, thunkAPI) => {
  const state = thunkAPI.getState() as RootState;
  const originalUserRoles = { ...state.adminContext.adminRoleContext.userRoles }


  const response = await adminRoleService.saveUserRole(originalUserRoles, data);

  if (response.hasErrors) {
    thunkAPI.dispatch(showErrorStatus(response.errorMessage))
    throw new Error(response.errorMessage);
  } else if(data.userRoleId) {
      thunkAPI.dispatch(showStatus("Role Updated"));
    } else {
      thunkAPI.dispatch(showStatus("New Role created"));
    }
  thunkAPI.dispatch(getAdminUserRoles());
  return response;
});

export const getUserRole = createAsyncThunk("getUserRole", async (userRoleId: number) => {
  const response = await adminRoleService.getUserRole(userRoleId);
  return response;
});


