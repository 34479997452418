import './../../../../../scss/pages/admin/_admin-clients.scss';

import { DialogActionButton, TextField, Toggle } from '@finpay-development/shared-components';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { showErrorStatus } from '../../../../../security/state/user-slice';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { Utils } from '../../../../../shared/utils';
import { clientBusinessRules } from '../../../../../shared/validation/schemas';
import { clearStatus, setEntitySettingsTabComplete } from '../../../../state/clients/implementation-clients-slice';
import { saveClientBusinessRules } from '../../../../state/clients/implementation-clients-thunk';

interface EditClientBusinessRulesModalProps {
  open: boolean;
  isEdit: boolean;
  handleEditCancel: () => void;
  handleEditSubmit: () => void;
}

export function EditClientBusinessRulesModal(props: EditClientBusinessRulesModalProps) {
  const { open, isEdit, handleEditCancel, handleEditSubmit } = props;

  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const formRef: any = useRef();

  const missedPmtsForDefault = {
    "1": 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
  };


  const selectors = {
    currentClientBusinessRules: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.currentClientBusinessRules
    ),
    saveStatus: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.apiStatus
    ),
      errorMessage: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.errorMessage
    )
  }

  async function handleSave() {
    const formValues = {
      ...formRef.current.values,
      useClientTemplate: formRef.current.values.useClientTemplate ? "All" : "Partial"
    };

    await dispatch(saveClientBusinessRules(formValues));
  }

  function handleSaveCallback(saveSuccessful: boolean) {
    if (saveSuccessful) {
      handleEditSubmit();
      dispatch(setEntitySettingsTabComplete(true));
      setEnableSaveButton(false);
    } else {
      dispatch(showErrorStatus(selectors.errorMessage));
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handleEditCancel();
  }


  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  function calculateProcessingTotalFee(
    automationFee: number,
    merchantProcessingFee: number
  ) {
    return `${automationFee + merchantProcessingFee}%`;
  }

  const initialValues = {
    preCareFullPayMaxDiscount: selectors.currentClientBusinessRules.preCareFullPayMaxDiscount,
    postCareFullPayMaxDiscount: selectors.currentClientBusinessRules.postCareFullPayMaxDiscount,
    refundsIssuedBy: selectors.currentClientBusinessRules.refundsIssuedBy,
    clientBusinessRuleId: selectors.currentClientBusinessRules.clientBusinessRuleId,
    missedPmtsForDefault: selectors.currentClientBusinessRules.missedPmtsForDefault,
    pfrVarianceTolerance: selectors.currentClientBusinessRules.pfrVarianceTolerance,
    automationFee: selectors.currentClientBusinessRules.automationFee,
    merchantProcessingFee: selectors.currentClientBusinessRules.merchantProcessingFee,
    customDocTokenId: selectors.currentClientBusinessRules.customDocTokenId,
    useClientTemplate: selectors.currentClientBusinessRules.useClientTemplate === "All",
    realTimeUpdates: selectors.currentClientBusinessRules.realTimeUpdates,
    paynowFee: (selectors.currentClientBusinessRules.paynowFee === "" || selectors.currentClientBusinessRules.paynowFee === undefined) ? 0 : selectors.currentClientBusinessRules.paynowFee,
    sendPFRDocs: selectors.currentClientBusinessRules.sendPFRDocs
  };

  const validationSchema = Yup.object(clientBusinessRules);

  return (
    <Dialog
      scroll="body"
      className="modal client-modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">{`${isEdit ? 'Edit' : 'Add'}`} Business Rules</span>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h3" className="mb-2">
                    Full Pay Discount
                  </Typography>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        error={
                          formik.touched["preCareFullPayMaxDiscount"] &&
                          formik.errors["preCareFullPayMaxDiscount"]
                            ? formik.errors["preCareFullPayMaxDiscount"]
                            : ""
                        }
                        label="Pre/During Care"
                        name="preCareFullPayMaxDiscount"
                        value={formik.values.preCareFullPayMaxDiscount}
                        onChange={(e: Event) => {
                          if(Utils.handleNumericValues(e))
                            formik.handleChange(e)
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Pre/During Care Discount"
                        endAdornment="%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        error={
                          formik.touched["postCareFullPayMaxDiscount"] &&
                          formik.errors["postCareFullPayMaxDiscount"]
                            ? formik.errors["postCareFullPayMaxDiscount"]
                            : ""
                        }
                        label="Post Care Max"
                        name="postCareFullPayMaxDiscount"
                        value={formik.values.postCareFullPayMaxDiscount}
                        onChange={(e: Event) => {
                          if(Utils.handleNumericValues(e))
                            formik.handleChange(e)
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Post Care Max Discount"
                        endAdornment="%"
                      />
                    </Grid>
                </Grid>

                <Grid item>
                  <Typography variant="h3">Refunds</Typography>
                </Grid>

                <Grid item>
                  <Typography className="mb-2" variant="body2">
                    Issued By
                  </Typography>
                  <div className="buttongroup">
                    {
                      <>
                        <input
                          id="finpay"
                          type="radio"
                          value="FinPay"
                          name="refundsIssuedBy"
                          checked={formik.values.refundsIssuedBy === "FinPay"}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="finpay">FinPay</label>

                        <input
                          id="client"
                          type="radio"
                          value="Client"
                          name="refundsIssuedBy"
                          checked={formik.values.refundsIssuedBy === "Client"}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="client">Client</label>
                      </>
                    }
                  </div>
                </Grid>

                <Grid item>
                  <Typography variant="h3">Account Defaulted</Typography>
                </Grid>

                <Grid item>
                  <Typography className="mb-2" variant="body2">
                    Consecutive Missed Payments
                  </Typography>

                  <div className="buttongroup">
                    {
                      <>
                        <input
                          id="missedPmtsForDefault-1"
                          type="radio"
                          value={missedPmtsForDefault[1]}
                          name="missedPmtsForDefault"
                          checked={+formik.values.missedPmtsForDefault === missedPmtsForDefault[1]}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="missedPmtsForDefault-1">{missedPmtsForDefault[1]}</label>

                        <input
                          id="missedPmtsForDefault-2"
                          type="radio"
                          value={missedPmtsForDefault[2]}
                          name="missedPmtsForDefault"
                          checked={+formik.values.missedPmtsForDefault === missedPmtsForDefault[2]}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="missedPmtsForDefault-2">{missedPmtsForDefault[2]}</label>

                        <input
                          id="missedPmtsForDefault-3"
                          type="radio"
                          value={missedPmtsForDefault[3]}
                          name="missedPmtsForDefault"
                          checked={+formik.values.missedPmtsForDefault === missedPmtsForDefault[3]}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="missedPmtsForDefault-3">{missedPmtsForDefault[3]}</label>

                        <input
                          id="missedPmtsForDefault-4"
                          type="radio"
                          value={missedPmtsForDefault[4]}
                          name="missedPmtsForDefault"
                          checked={+formik.values.missedPmtsForDefault === missedPmtsForDefault[4]}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="missedPmtsForDefault-4">{missedPmtsForDefault[4]}</label>

                        <input
                          id="missedPmtsForDefault-5"
                          type="radio"
                          value={missedPmtsForDefault[5]}
                          name="missedPmtsForDefault"
                          checked={+formik.values.missedPmtsForDefault === missedPmtsForDefault[5]}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="missedPmtsForDefault-5">{missedPmtsForDefault[5]}</label>
                      </>
                    }
                  </div>
                </Grid>

                <Grid item>
                  <Typography variant="h3" className="mb-2">
                    PFR Variance
                  </Typography>
                  <Typography variant="subtitle2">
                    Contact patient when variance exceeds
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    type="text"
                    error={
                      formik.touched["pfrVarianceTolerance"] &&
                      formik.errors["pfrVarianceTolerance"]
                        ? formik.errors["pfrVarianceTolerance"]
                        : ""
                    }
                    label="Variance"
                    name="pfrVarianceTolerance"
                    value={formik.values.pfrVarianceTolerance}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Variance"
                    endAdornment="%"
                  />
                </Grid>

                <Grid item>
                  <Typography variant="h3" className="mb-2">
                    Fees
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid item container xs={8} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        error={
                          formik.touched["automationFee"] &&
                          formik.errors["automationFee"]
                            ? formik.errors["automationFee"]
                            : ""
                        }
                        label="Automation Fee"
                        name="automationFee"
                        value={formik.values.automationFee}
                        onChange={(e: Event) => {
                          if(Utils.handleNumericValues(e))
                            formik.handleChange(e)
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Fee"
                        endAdornment="%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        type="text"
                        error={
                          formik.touched["merchantProcessingFee"] &&
                          formik.errors["merchantProcessingFee"]
                            ? formik.errors["merchantProcessingFee"]
                            : ""
                        }
                        label="Merchant Processing"
                        name="merchantProcessingFee"
                        value={formik.values.merchantProcessingFee}
                        onChange={(e:any) => {
                          if(Utils.handleNumericValues(e))
                            formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Fee"
                        endAdornment="%"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    className="processing-fee-total"
                    item
                    container
                    xs={4}
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                  >
                    <Typography variant="body2">
                      Processing Fee Total
                    </Typography>
                    <p>
                      {calculateProcessingTotalFee(
                        +formik.values.automationFee,
                        +formik.values.merchantProcessingFee
                      )}
                    </p>
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item container xs={8} spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        type="number"
                        error={
                          formik.touched["paynowFee"] &&
                          formik.errors["paynowFee"]
                            ? formik.errors["paynowFee"]
                            : ""
                        }
                        label="Web Payments Fee"
                        name="paynowFee"
                        value={formik.values.paynowFee}
                        onChange={(e: Event) => {
                          if(Utils.handleNumericValues(e))
                            formik.handleChange(e)
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="Enter Fee"
                        endAdornment="%"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <span>&nbsp;</span>
                    </Grid>
                  </Grid>
                  <Grid
                    className="processing-fee-total"
                    item
                    container
                    xs={4}
                    direction="column"
                    alignItems="flex-end"
                    justifyContent="flex-start"
                  >
                    <Typography variant="body2">
                      Total Web Payments Fees
                    </Typography>
                    <p>
                      {calculateProcessingTotalFee(
                        +formik.values.paynowFee,
                        +formik.values.merchantProcessingFee
                      )}
                    </p>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="mt-6">
                  <Grid item xs={12} lg={6} className="mt-6">
                    <TextField
                      error={
                        formik.touched["customDocTokenId"] &&
                        formik.errors["customDocTokenId"]
                          ? formik.errors["customDocTokenId"]
                          : ""
                      }
                      label="Compliance Document Token ID"
                      name="customDocTokenId"
                      value={formik.values.customDocTokenId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Compliance Document Token ID"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} className="mt-7">
                      <Toggle
                          leftText="&nbsp; &nbsp; All &nbsp; &nbsp;"
                          rightText="Partial"
                          name="useClientTemplate"
                          formik={formik}
                          value={formik.values.useClientTemplate}
                        />
                    </Grid>

                </Grid>
                <Grid item xs={6} lg={4} className="mt-7">
                  <Typography variant='h4'>
                    Real Time Updates?
                  </Typography>
                  <Toggle
                      name="realTimeUpdates"
                      formik={formik}
                      value={formik.values.realTimeUpdates}
                  />
                </Grid>
                <Grid item xs={6} lg={4} className="mt-7">
                  <Typography variant='h4'>
                    Send PFR Adjustment Documents?
                  </Typography>
                  <Toggle
                      name="sendPFRDocs"
                      formik={formik}
                      value={formik.values.sendPFRDocs}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText="Update"
          saveStatus={selectors.saveStatus}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
