import { createAsyncThunk } from '@reduxjs/toolkit'
import { testService } from '../services/test-service'

export const testCallService = createAsyncThunk(
    'testCallService',
    async (userId: string, thunkAPI) => {
      const response = await testService.getSampleData(userId)
      return response;
    }
  )

  