export interface PatientInsurance {
  patientInsuranceId: number;
  patientEncounterId: number;
  sortOrder?: number;
  insuranceCompanyName: string;
  insurancePlanName: string;
  isInNetwork: boolean;
  deductibleAmt: number;
  remDeductibleAmt: number;
  familyDeductibleAmt: number;
  familyRemDeductibleAmt: number;
  coinsuranceAmt: number;
  coinsuranceType: string;
  isSubjectToCopay: boolean;
  copayAmt: number;
  maxDays: number;
  deductibleIncInOOPMaxFl: boolean;
  oopmaxAmt: number;
  remainingOOPMaxAmt: number;
  familyOOPMaxAmt: number;
  familyRemainingOOPMaxAmt: number;
  policyNumber: string;
  groupNumber: string;
}

export const emptyPatientInsurance: PatientInsurance = {
  patientInsuranceId: 0,
  patientEncounterId: 0,
  sortOrder: 0,
  insuranceCompanyName: '',
  insurancePlanName: '',
  isInNetwork: false,
  deductibleAmt: 0,
  remDeductibleAmt: 0,
  familyDeductibleAmt: 0,
  familyRemDeductibleAmt: 0,
  coinsuranceAmt: 0,
  coinsuranceType: '',
  isSubjectToCopay: false,
  copayAmt: 0,
  maxDays: 0,
  deductibleIncInOOPMaxFl: false,
  oopmaxAmt: 0,
  remainingOOPMaxAmt: 0,
  familyOOPMaxAmt: 0,
  familyRemainingOOPMaxAmt: 0,
  policyNumber: '',
  groupNumber: '',
}