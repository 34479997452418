import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/shared/state/root-reducer";
import CRMModal from "./crm-modal";
import { ClientStatusCardViewModel } from "src/shared/model/client-status-card";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import { AppDispatch } from "src/shared/state/store";
import { getAllClientCRM, getClientCRM } from "src/implementation-specialist/state/crm/implementation-crm-thunk";
import { clearCRMForm } from "src/implementation-specialist/state/crm/implementation-crm-slice";
import { ImplementationCRM } from "../models/implementation-crm";

export default function CRMView() {
  const [facilities, setFacilities] = useState<ClientStatusCardViewModel[]>([]);
  const [currentFacilityId, setCurrentFacilityId] = useState<any>(null);
  const [crm, setCRM] = useState<ImplementationCRM | null>();

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    client: useSelector((state: RootState) => {
      return state.implementationContext.implementationSpecialistClient.client;
    }),
    currentClientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .currentClientEntityDetails.clientId
    ),
    allClientsWithFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .allClientsWithFacillities
    ),

    implementationCRM: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.implementationCRM
    ),
    currentCRMForm: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.currentCRMForm
    ),
    saveStatus: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.apiStatus
    ),
    errorMessage: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.errorMessage
    ),

    isLoading: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.isLoading
    ),
  };

  const { currentClientId, allClientsWithFacilities, isLoading } = selectors;

  const selectedClient = facilities.find(
    (client) => client.clientId === currentClientId
  );

  const [selectedValue, setSelectedValue] = useState<any>({
    clientId: currentClientId,
  });

  useEffect(() => {
    setCurrentFacilityId(selectedValue?.facilityId || null);

    if (!currentClientId && !isLoading) {
      setSelectedValue({ clientId: currentClientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, currentClientId, isLoading]);

  useEffect(() => {
    if (allClientsWithFacilities) {
      setFacilities(allClientsWithFacilities);
    }
  }, [allClientsWithFacilities]);

  useEffect(() => {
    const fetchAllCRM = async () => {
      if (currentClientId && !isLoading) {
        let res;
        let relevantCRM;

        if (currentFacilityId) {
          res = await dispatch(getAllClientCRM({
            clientId: currentClientId,
            clientFacilityId: currentFacilityId
          }));
          relevantCRM = res?.payload?.apiResponseCRM.find((crm: any) => crm.clientFacilityId === currentFacilityId);
        } else {
          res = await dispatch(getAllClientCRM({
            clientId: currentClientId,
            clientFacilityId: null
          }));
          relevantCRM = res?.payload?.apiResponseCRM.find((crm: any) => !crm?.clientFacilityId);
        }

        if (relevantCRM) {
          dispatch(getClientCRM({ clientCrmId: relevantCRM?.clientCrmId }));
          dispatch(clearCRMForm());
          setCRM(relevantCRM);
        } else {
          setCRM(null);
          dispatch(clearCRMForm());
        }
      }
    };

    fetchAllCRM();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClientId, currentFacilityId, dispatch]);


  const handleChange = (event: any) => {
    const selectedOptions = event?.target?.value;

    if (!selectedOptions) {
      throw new Error("No selected options");
    }

    const selectedObj = facilities.find(
      (client) =>
        client.clientId === selectedOptions ||
        client?.clientFacilities?.some(
          (facility) => facility.facilityId === selectedOptions
        )
    );

    if (selectedObj?.clientFacilities) {
      const selectedFacility = selectedObj.clientFacilities.find(
        (facility) => facility.facilityId === selectedOptions
      );
      setSelectedValue({
        ...selectedObj,
        facilityId: selectedFacility?.facilityId,
      });
      setCurrentFacilityId(selectedFacility?.facilityId);
    } else {
      setSelectedValue(selectedObj);
      setCurrentFacilityId(null);
    }

    const selectedFacilityNames = Array.from(
      selectedOptions,
      (option: any) => option.value
    );

    const newFacilities = facilities.map((facility) => {
      return {
        ...facility,
        isActive: selectedFacilityNames?.includes(
          facility?.clientFacilities?.map((f) => f.facilityId)
        ),
      };
    });
    setFacilities(newFacilities);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={6} sm={8} md={12}>
          <Paper style={{ boxShadow: "none" }}>
            <Box
              style={{
                padding: "2em",
                marginBottom: "1.5em",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                direction="row"
                style={{ margin: "8px 0px 24px", width: "100%" }}
              >
                <Grid
                  item
                  justifyContent="center"
                  direction="column"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingBottom: "0.5em",
                    flexDirection: "row",
                    justifyContent: "normal",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      flex: "1",
                      marginBottom: "0",
                      display: "flex",
                    }}
                  >
                    <PermDataSettingIcon sx={{ marginRight: "0.25em" }} />
                    CRM Configuration
                  </Typography>
                  <Grid>
                    <Box display="flex" justifyContent="flex-start">
                      <FormControl
                        variant="standard"
                        style={{ width: 220, paddingLeft: 7 }}
                      >
                        <InputLabel sx={{ml:5}} id="select-standard-label">Client Default</InputLabel>
                        <Select
                          labelId="authDocs-view-select-label"
                          id="authDocs-view-select"
                          value={selectedClient?.clientName}
                          onChange={handleChange}
                          label="Select Client"
                        >
                          <MenuItem
                            value={selectedClient?.clientId}
                            key={selectedClient?.clientId}
                          >
                            {"Client Default"}
                          </MenuItem>

                          {selectedClient &&
                            selectedClient.clientFacilities?.map((facility) => (
                              <MenuItem
                                key={facility?.facilityId}
                                value={facility.facilityId}
                              >
                                {facility?.facilityName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <CRMModal selectedValue={selectedValue}/>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
