import { Button } from '@finpay-development/shared-components';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Dialog, DialogActions, DialogContent, Grid, Slide, SlideProps, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

interface SessionTimeoutDialogProps {
	open: boolean;
	countdown: number;
	onLogout: () => void;
	onContinue: () => void;
}

const Transition = React.forwardRef<TransitionProps, SlideProps>((props, ref) => {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SessionTimeoutDialog({ open, countdown, onLogout, onContinue }: SessionTimeoutDialogProps) {
	return (
		<Dialog
			className="modal delete-modal"
			scroll="body"
			open={open}
			fullWidth={true}
			maxWidth="md"
			TransitionComponent={Transition}
		>
			<DialogContent>
				<Grid container direction="column" alignItems="center">
					<Grid xs={12} item className="mb-4 mt-2">
						<QueryBuilderIcon color="error" />
					</Grid>
					<Grid xs={12} item className="mb-2">
						<Typography variant="subtitle1"> Session Timeout in [{countdown}] Seconds.</Typography>
					</Grid>
					<Grid xs={12} item>
						<Typography variant="body1">{`You will be logged out due to inactivity`}</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={onLogout} type="secondary">
					Log Out
        </Button>
				<Button type="primary" onClick={onContinue} marginLeft={0}>
					Stay Logged In
        </Button>
			</DialogActions>
		</Dialog>
	);
};
