export interface RiskClass {
    riskClassId?: number;
    riskClassName?: string;
}

export const emptyRiskClass: RiskClass = {
    riskClassId: 0,
    riskClassName: "",
  };


export interface PESScriptForm {
    pesScriptId: number;
    scriptUrl: string ;
    facilityId: number;
    riskClassId?: number | null ;
}


export const blankPESScriptForm: PESScriptForm = {
    pesScriptId:0,
    scriptUrl: "",
    facilityId: 0,
    riskClassId: 0,
}

export interface PESScriptDetails {
    clientId:number;
    pesScriptId: number;
    scriptUrl: string ;
    facilityId: number;
    riskClass?: RiskClass | null ;
}


export const blankPESScriptDetails: PESScriptDetails = {
    pesScriptId:0,
    scriptUrl: "",
    clientId: 0,
    facilityId: 0,
    riskClass: emptyRiskClass,
}


export interface PESScriptUIForm {
    clientId?:number;
    pesScriptId?: number | undefined;
    scriptUrl?: string  | undefined;
    facilityId?: number | undefined;
    riskClassId? : number | null ;

}

