import {
  ChangeEvent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { VariableSizeList as List} from "react-window"
import {UserInfoFacility} from '../../models/user-info-facility';
import {
  ClientStatusCardViewModel,
} from '../../../shared/model/client-status-card';
import {UserInfoClient} from '../../models/user-info-client';
import _ from 'lodash/fp';
import {TextField} from '@finpay-development/shared-components';
import "../../../scss/components/_client-facility-checkbox.scss"

type ClientFacilityCheckboxFormProps = {
  allClientsWithFacilitiesMap: Map<number, ClientStatusCardViewModel>
  selectedClientsAndFacilitiesObj: Record<string, UserInfoClient>
  handleCheckBoxOnChange: (selectedClientsAndFacilities: Record<string, UserInfoClient>)=>void
}

const CHECKBOX_ROW_HEIGHT_PX = 25;

const ClientFacilityCheckbox = memo((props: ClientFacilityCheckboxFormProps) => {

  const {
    allClientsWithFacilitiesMap,
    selectedClientsAndFacilitiesObj,
    handleCheckBoxOnChange
  } = props;

  const [filteredClientsAndFacility, setFilteredClientsAndFacility] = useState<Map<number, ClientStatusCardViewModel>>(allClientsWithFacilitiesMap)
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  const [rowHeightObj, setRowHeightObj] = useState<Record<string, number>>({})

  useEffect(()=>{
    setFilteredClientsAndFacility(allClientsWithFacilitiesMap)
  }, [allClientsWithFacilitiesMap])

  const clientFacilityCheckboxListMemo = useMemo(()=>{

    let rowHeightObj = {}

    const jsx = Array.from(filteredClientsAndFacility.values()).map((client, index) => {

      const selectedClient = selectedClientsAndFacilitiesObj[client.clientId];

      //need to keep track of number of list items per client to dynamically set the React Window list height
      rowHeightObj = Object.assign(rowHeightObj, {
        [index]: 1+client.clientFacilities!.length
      })

      return(
          <div key={`client-${client.clientId}`}>
            <div style={{height: `${CHECKBOX_ROW_HEIGHT_PX}px`}}>
              <input
                  type='checkbox'
                  id={`client-${client.clientId}`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {

                    const { checked } = event.target;

                    if (checked) {

                      const clientToSave = new UserInfoClient();
                      clientToSave.clientId = client.clientId;
                      clientToSave.clientName = client.clientName;
                      clientToSave.isFacilityLevel = true;
                      clientToSave.allowedFacilities = allClientsWithFacilitiesMap.get(client.clientId)!.clientFacilities!.map((facility)=>{
                        const facilityToSave = new UserInfoFacility()
                        facilityToSave.facilityId = facility.facilityId
                        return facilityToSave
                      });

                      handleCheckBoxOnChange({
                        ...selectedClientsAndFacilitiesObj,
                        [client.clientId]: clientToSave
                      })

                    } else {
                      const {[client.clientId]: omitted, ...rest} = selectedClientsAndFacilitiesObj;
                      handleCheckBoxOnChange(rest)
                    }
                  }
                  }
                  checked={!!selectedClient?.isFacilityLevel}
              />
              <label htmlFor={`client-${client.clientId}`} className="checkbox-item" >
                {client.clientName}
              </label>
            </div>
            {client.clientFacilities!.map((facility)=>{

              const allowedFacilitiesArr = selectedClient?.allowedFacilities;

              return (
                  <div
                      key={`facility-${facility.facilityId}`}
                      style={{
                        marginLeft: '2rem', height: `${CHECKBOX_ROW_HEIGHT_PX}px`}}
                  >
                    <input
                        type='checkbox'
                        id={`facility-${facility.facilityId}`}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {

                          const { checked } = event.target;

                          if (checked) {

                            const facilityToSave = new UserInfoFacility()
                            facilityToSave.facilityId = facility.facilityId

                            if(Array.isArray(allowedFacilitiesArr)){
                              //client level object exists

                              handleCheckBoxOnChange({
                                ...selectedClientsAndFacilitiesObj,
                                [client.clientId] : {
                                  ...selectedClient,
                                  allowedFacilities : [
                                    ...allowedFacilitiesArr,
                                    facilityToSave
                                  ]
                                }
                              })

                            }else{
                              //client level object does not exist

                              const clientToSave = new UserInfoClient();
                              clientToSave.clientId = client.clientId;
                              clientToSave.clientName = client.clientName;
                              clientToSave.isFacilityLevel = false;
                              clientToSave.allowedFacilities = [facilityToSave];

                              handleCheckBoxOnChange({
                                ...selectedClientsAndFacilitiesObj,
                                [client.clientId] : clientToSave
                              })
                            }

                          } else {

                            handleCheckBoxOnChange({
                              ...selectedClientsAndFacilitiesObj,
                              [client.clientId] : {
                                ...selectedClient,
                                isFacilityLevel: false,
                                allowedFacilities : allowedFacilitiesArr.filter((allowedFacility: UserInfoFacility)=>{
                                  return allowedFacility.facilityId !== facility.facilityId;
                                })
                              }
                            })

                          }
                        }}
                        checked={
                          !!(
                              (selectedClient?.isFacilityLevel) ||
                              (Array.isArray(allowedFacilitiesArr) && allowedFacilitiesArr.find(
                                      (allowedFacility: UserInfoFacility) => {
                                        return allowedFacility.facilityId === facility.facilityId
                                      }
                                  )
                              )
                          )
                        }
                    />
                    <label htmlFor={`facility-${facility.facilityId}`}  className="checkbox-item">
                      {facility.facilityName}
                    </label>
                  </div>
              )
            })}
          </div>
      )
    })
    setRowHeightObj(rowHeightObj)
    return jsx;
  }, [filteredClientsAndFacility, selectedClientsAndFacilitiesObj, allClientsWithFacilitiesMap])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyWord(e.target.value);
  };

  const allClientsWithFacilitiesArr = useMemo(()=>{
    return Array.from(allClientsWithFacilitiesMap)
  }, [allClientsWithFacilitiesMap])

  const debounceFilter = _.debounce(300, ()=>{

    const filteredClientsArray = allClientsWithFacilitiesArr.filter(([_, clientObj])=>{

      const matchesClient = (
          clientObj.clientName &&
          clientObj.clientName.toLowerCase().includes(searchKeyWord.toLowerCase())
      );
      if(matchesClient) return true;

      return (
          Array.isArray(clientObj.clientFacilities) &&
          clientObj.clientFacilities.some((facility) => {
            if (facility.facilityName) {
              return facility.facilityName.toLowerCase().includes(searchKeyWord.toLowerCase())
            }
          })
      );
    })

    setFilteredClientsAndFacility(
        new Map(filteredClientsArray)
    );
  })

  useEffect(() => {

  if(searchKeyWord) {
    debounceFilter()
  }else{
    setFilteredClientsAndFacility(allClientsWithFacilitiesMap)
  }

  return () => debounceFilter.cancel()

  }, [searchKeyWord]);

  return (
      <form className="client-facility-checkbox">
        <div style={{margin: '5px 0'}}>
          <TextField
              label="Search Client"
              name="search"
              placeholder="Search Client"
              value={searchKeyWord}
              onChange={handleSearch}
              onBlur={handleSearch}
          />
        </div>
        <div className="clients"
            /*
            * Need the key to force a rerender so the variable size list
            * itemSize function gets the latest rowHeightObj state
            * */
             key={JSON.stringify(rowHeightObj)}
        >
            <List
                itemCount={filteredClientsAndFacility.size}
                height={250}
                width={500}
                itemSize={(index)=>{
                  const numberOfItems= rowHeightObj[index]
                  return numberOfItems *CHECKBOX_ROW_HEIGHT_PX as number
                }}
            >
              {({index, style})=>{
                return (
                    <div style={style}>
                      {clientFacilityCheckboxListMemo[index]}
                    </div>
                )
              }}
            </List>
        </div>
      </form>

  )
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps, nextProps)
})

export default ClientFacilityCheckbox