export interface ClientFilter {
    [key: string]: boolean
  }

  export function emptyClientFilter(): ClientFilter {
      return {
        recentlyViewed: false,
        all: true,
        active: false,
        closed: false,
        followUp: false,
        readyForActivation: false,
        setUpIncomplete: false,
        stripeSetupIncomplete: false,
        stripeActionNeeded: false
      }
  }
  