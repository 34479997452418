import {Status} from '@finpay-development/shared-components';
import {Divider, Grid} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import HeaderWithDetails
  from '../../../../shared/components/header-with-details';
import {RootState} from '../../../../shared/state/root-reducer';
import {Utils} from '../../../../shared/utils';
import {DocumentHeaderWithDetail} from './document-header-with-detail';
import {patientService} from '../../../services/patient-service';
import {authDocDispatchMethod, authDocType} from '../../../../shared/enums';
import {
  showErrorStatus,
  showStatus,
} from '../../../../security/state/user-slice';
import {AppDispatch} from '../../../../shared/state/store';

const DocumentsStatusAccordion = () => {
  const selectedEncounter = useSelector((state: RootState) => state.patientContext.selectedEncounter);
  const selectedPatient = useSelector((state: RootState) => state.patientContext.selectedPatient);
  const allClientsMap = useSelector((state: RootState) =>  state.implementationContext?.implementationSpecialistClient?.allClientsMap)

  const dispatch = useDispatch<AppDispatch>();

  const authorizationDocumentStatus = selectedEncounter?.authorizationDocumentStatus;
  const installmentDocuments = selectedEncounter?.patientDocument

  const doPfrAdjustmentDocumentsExist = !!authorizationDocumentStatus?.doPfrAdjustmentDocumentsExist;
  const doPhiConsentDocumentsExist = !!authorizationDocumentStatus?.doPhiConsentDocumentsExist;
  const doFullPayDocumentsExist = !!authorizationDocumentStatus?.doFullPayDocumentsExist;
  const doInstallmentDocumentsExist = !!(installmentDocuments && installmentDocuments?.length > 0)
  const doInstallmentDocumentsExistNew =  !!authorizationDocumentStatus?.doInstallmentDocumentsExist;

  // if none of the above document types exist then we don't have an overall document type status.
  const doesAuthorizationDocumentOverallStatusExist = doPfrAdjustmentDocumentsExist || doPhiConsentDocumentsExist || doFullPayDocumentsExist || doInstallmentDocumentsExist || doInstallmentDocumentsExistNew

  const guarantor = Array.isArray(selectedEncounter?.patientChampion) ? selectedEncounter?.patientChampion?.find((champion) => champion?.isGuarantor === true) : undefined
  const accountHolderContact = guarantor ? guarantor.contact : selectedPatient.contact
  const eligibleForSms =
      accountHolderContact.smsConsentFl &&
      (accountHolderContact.primPhoneType === 'mobile' || accountHolderContact.secPhoneType === 'mobile')

  const paymentProgramVersion = allClientsMap.get(selectedEncounter.clientId)?.paymentProgramVersion
  const isPaymentProgramVersionGreaterOrEqualTo4 = typeof paymentProgramVersion === 'number' && paymentProgramVersion >= 4

  const unsignedSpaDoc = authorizationDocumentStatus?.spaDocuments?.find((authDocStatus) => authDocStatus?.documentStatus === 'sent')
  const unsignedInstDoc = authorizationDocumentStatus?.installmentDocuments?.find((authDocStatus) => authDocStatus?.documentStatus === 'sent')

  const handleButtonOnClick = (docType: authDocType ) => async (dispatchMethod: authDocDispatchMethod) => {

    let apiResponse;

    switch(docType){
      case authDocType.inst:
        apiResponse = await patientService.installmentDocument(selectedEncounter.patientEncounterId, dispatchMethod)
        break;
      case authDocType.spa:
        apiResponse = await patientService.spaDocument(selectedEncounter.patientEncounterId, dispatchMethod)
        break;
    }

    if (apiResponse?.hasErrors) {
      dispatch(showErrorStatus(apiResponse?.entity?.message || apiResponse.errorMessage));
    } else {
      dispatch(showStatus("Authorization document sent successfully"));
    }

  }

  return (
    <div className="w-100">
      <Grid container justifyContent="space-between">
        <HeaderWithDetails
          header="Authorization Documents Status"
          width="full"
          details={
            <Status
              typographyVariant="h3"
              statusColor={Utils.getDocumentStatusColor(doesAuthorizationDocumentOverallStatusExist, doesAuthorizationDocumentOverallStatusExist ? authorizationDocumentStatus?.overallDocumentStatus : '')}
              text={doesAuthorizationDocumentOverallStatusExist ? authorizationDocumentStatus?.overallDocumentStatus : 'No documents exist'}
            />
          }
        />
        <Grid item xs={12} className="pb-2">
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <HeaderWithDetails
              header="PHI Consent Status"
              width="half"
              details={
                <Status
                    typographyVariant="h3"
                    statusColor={Utils.getDocumentStatusColor(doPhiConsentDocumentsExist, doPhiConsentDocumentsExist ? authorizationDocumentStatus?.phiConsentDocuments[0]?.displayedDocumentStatus : '')}
                    text={doPhiConsentDocumentsExist ? authorizationDocumentStatus?.phiConsentDocuments[0]?.displayedDocumentStatus || '' : 'No documents exist'}
                />
              }
          />
        </Grid>
        <Grid container item xs={6}>
          <DocumentHeaderWithDetail
              header="Full Payment Agreement Status"
              doesDocumentExist={doFullPayDocumentsExist}
              documentStatusForText={authorizationDocumentStatus?.spaDocuments?.[0]?.displayedDocumentStatus}
              documentStatusForColor={authorizationDocumentStatus?.spaDocuments?.[0]?.displayedDocumentStatus}
              resendButtons={{
                displayEmailButton: isPaymentProgramVersionGreaterOrEqualTo4 && !!unsignedSpaDoc,
                displaySmsButton: eligibleForSms && unsignedSpaDoc?.authorizationDocuments?.isSigReq === false
              }}
              handleButtonOnClick={handleButtonOnClick(authDocType.spa)}
          />
        </Grid>
        <Grid container item xs={6}>
          <DocumentHeaderWithDetail
              header="Installment Agreement Status"
              doesDocumentExist={doInstallmentDocumentsExistNew || doInstallmentDocumentsExist}
              documentStatusForText={doInstallmentDocumentsExistNew ? authorizationDocumentStatus?.installmentDocuments?.[0]?.displayedDocumentStatus : installmentDocuments?.[0]?.documentStatus}
              documentStatusForColor={doInstallmentDocumentsExistNew ? authorizationDocumentStatus?.installmentDocuments?.[0]?.displayedDocumentStatus : installmentDocuments?.[0]?.signatureTracking?.signatureStatus}
              resendButtons={{
                /**
                 * Payment program v4 clients track installment documents through both patientDocuments and authorizationDocumentStatus tables.
                 * We only want to show the resend button if the installment document is being tracked through the authorizationDocumentStatus table
                 * */
                displayEmailButton: isPaymentProgramVersionGreaterOrEqualTo4 && doInstallmentDocumentsExistNew && !!unsignedInstDoc,
                displaySmsButton: eligibleForSms && unsignedInstDoc?.authorizationDocuments?.isSigReq === false
              }}
              handleButtonOnClick={handleButtonOnClick(authDocType.inst)}
          />
        </Grid>
        <Grid item xs={6}>
          <HeaderWithDetails
              header="PFR Adjustment Agreement Status"
              width="half"
              details={
                <Status
                    typographyVariant="h3"
                    statusColor={Utils.getDocumentStatusColor( doPfrAdjustmentDocumentsExist, doPfrAdjustmentDocumentsExist ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.displayedDocumentStatus : '')}
                    text={doPfrAdjustmentDocumentsExist ? authorizationDocumentStatus?.pfrAdjustmentDocuments[0]?.displayedDocumentStatus || '' : 'No documents exist'}
                />
              }
          />
        </Grid>
      </Grid>
    </div>
  )

}

export default DocumentsStatusAccordion;
