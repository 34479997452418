import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import { Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { showStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { resetRuleForm } from '../../state/admin-configuration-slice';
import { RuleModal } from './rule-modal';
import RuleSet from './rule-set';
import {getPaymentMetadata} from '../../../meta-data/state/meta-data-thunk';

function CommonRuleSet() {
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    rules: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext.rules
    ),
    isLoading: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext.isLoading.isLoadingRules
    ),
    ruleParamsMetadata: useSelector(
        (state: RootState) => state.metaData.ruleParams
    )

  }

  function handleRuleModalCancel() {
    setIsRuleModalOpen(false);
    dispatch(resetRuleForm());
  }

  function handleRuleModalSubmit(isEditMode: boolean) {
    setIsRuleModalOpen(false);
    dispatch(resetRuleForm());

    if (isEditMode) {
      dispatch(showStatus("Rule Updated Successfully"));
    } else {
      dispatch(showStatus("Rule Added Successfully"));
    }
  }

  const addRulesMessage = (
    <Grid container justifyContent="center">
      <Typography variant="h1">Add a Rule</Typography>
    </Grid>
  );

  return (
    <div className="common-rule-set">
      <Grid item container xs={12} justifyContent="flex-end">
        <AccessControl rolePageName={RolePageNames.Configuration} actionName="Add New Rule"
          renderNoAccess={() => <Button disabled>Add New Rule</Button>}>
          <Button onClick={async () => {
            if(stateFields.ruleParamsMetadata.length ===0) await dispatch(getPaymentMetadata(["ruleParams"]))
            setIsRuleModalOpen(true)
          }}>Add New Rule</Button>
        </AccessControl>
      </Grid>
      {stateFields.rules?.length === 0 && addRulesMessage}


      {stateFields.isLoading ? <LoadingOverlay /> : (
         <>
          {stateFields.rules?.map((ruleSet, i) => (
            <Paper key={i} className="px-4 py-4 mt-6">
              <RuleSet data={ruleSet} ruleSetIndex={i} />
            </Paper>
          ))}
        </>
      )}
      {isRuleModalOpen && (
        <RuleModal
          open={isRuleModalOpen}
          handleRuleModalCancel={handleRuleModalCancel}
          handleRuleModalSubmit={handleRuleModalSubmit}
        />
      )}
    </div>
  );
}

export default CommonRuleSet;
