import { ApplicationStatusState, emptyApplicationStatus } from "./application-status";
import { emptyUserProfile, UserProfile } from "./user-profile";

export interface UserContextState {
    userProfile: UserProfile;
    isSSO: boolean;
    isSSOLoading: boolean;
    userIsAuthenticated: boolean;
    homeRoute: string;
    onLoginScreen: boolean;
    hasErrorOccurred: boolean;
    applicationStatus: ApplicationStatusState;
    clearForm: boolean;
    userEmail: string;
    isActive: boolean;
    isLoading: boolean;
  }

  // Factory for Creating Default User Context
  // This is required to handle state management when a user logs in/out
  export function  getDefaultUserContextState() : UserContextState {
    return {
      userProfile: emptyUserProfile,
      isSSO: false,
      isSSOLoading: false,
      homeRoute: "",
      onLoginScreen: true,
      userIsAuthenticated: false,
      hasErrorOccurred: false,
      applicationStatus: emptyApplicationStatus,
      clearForm: false,
      userEmail: "",
      isActive: true,
      isLoading: false,
    }
  }