import { Grid, Typography } from "@mui/material";

export function FullPaymentPfrListHeader() {
  return (
    <div className="pt-6 px-2 pb-2 pr-3 mb-4">
      <Grid container spacing={2} className="header">
        <Grid item xs={2} className="ml-8">
          <Typography variant="h4">Discount</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4">Remaining PFR</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">Balance</Typography>
        </Grid>
      </Grid>
    </div>
  );
}
