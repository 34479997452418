import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { Form, Formik, useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { showErrorStatus } from '../../../security/state/user-slice';
import { TableColumn } from '../../../shared/model/table-column';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { EditPayer, EditPayerPlans, PayorModalForm } from '../../models/payers';
import { clearStatus } from '../../state/admin-configuration-slice';
import { configGetStates, configNewPayer, configSavePayer } from '../../state/admin-configuration-thunk';
import { Utils } from "../../../shared/utils";

interface PayersModalProps {
  open: boolean;
  isEdit: boolean;
  handlePayersModalCancel: () => void;
  handlePayersModalSubmit: (isEdit: boolean) => void;
  payerItem: EditPayer;
}

interface States {
  stateCode: string;
  stateName: string;
}

export function PayersModal(props: PayersModalProps) {
  const {
    open,
    isEdit,
    payerItem,
    handlePayersModalCancel,
    handlePayersModalSubmit,
  } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [enablePlanSaveButton, setEnablePlanSaveButton] = useState(false);
  const [showAddPlanRow, setShowAddPlanRow] = useState(false);
  const [rows, setRows] = useState<EditPayerPlans[]>();
  const [newPlans, setNewPlans] = useState<EditPayerPlans[]>([]);
  const [editedPlans, setEditedPlans] = useState<EditPayerPlans[]>([]);
  const [deletedPlans, setDeletedPlans] = useState<EditPayerPlans[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [states, setStates] = useState<States[]>();
  const [itemId, setItemId] = useState(-1);
  const [isPlanEdit, setIsPlanEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const payerFormRef: any = useRef();
  const paramId: number = -2;

  const state = {
    payers: useSelector(
      (state: RootState) => state.adminContext.adminConfigurationContext?.payers
    )
  };

  const {
    payers
  } = state;

  useEffect(() => {
    setRows(payerItem.payorPlans);
    checkIfPayerIsValid(initialPayerValues);
    getStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type MenuActionType = "add" | "edit" | "delete";

  const initialPayerValues: EditPayer = {
    paramId: paramId,
    payorId: payerItem.payorId,
    externalPayorId: payerItem.externalPayorId,
    payorName: payerItem.payorName,
    stateCode: payerItem.stateCode,
    payorPlans: payerItem.payorPlans,
  };

  let initialPlanValues: EditPayerPlans = {
    payorPlanId: -1,
    externalPayorPlanId: "",
    payorId: "",
    planName: "",
    planDesc: "",
  };

  const planHeaders: TableColumn[] = [
    { id: "col-plan-name", label: "Plan Name" },
    { id: "col-plan-descrip", label: "Description" },
    { id: "col-ext-plan-id", label: "Plan ID" },
    { id: "col-menu", label: "", minWidth: 50 },
  ];

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  const isSaveSuccessful = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.isSaveSuccessful
  );

  async function handleSave() {
    const formValues: PayorModalForm = {
      payerData: {
        paramId: paramId,
        payorId: payerFormRef.current.values.payorId,
        payorName: payerFormRef.current.values.payorName,
        payorDesc: payerFormRef.current.values.payorDesc,
        stateCode: payerFormRef.current.values.stateCode,
        externalPayorId: payerFormRef.current.values.externalPayorId,
      },
      newPlans: newPlans,
      editedPlans: editedPlans,
      deletedPlans: removeDuplicatePlans(deletedPlans),
    };

    if (isEdit) {
      // save edits to payers and plans
      return dispatch(configSavePayer(formValues));
    }
    return dispatch(configNewPayer(formValues));
  }

  const removeDuplicatePlans = (plans: EditPayerPlans[]): EditPayerPlans[] => {
    const result = [...new Set(plans.map((a) => JSON.stringify(a)))].map((a) => JSON.parse(a));
    return result;
  }

  const validatPlanSave = () => {
    // prevent duplicate externalPayorPlanId
    const formValues: EditPayerPlans = plansFormik.values;
    const externalPayorPlanIdToFind = formValues.externalPayorPlanId.toString().trim();
    let dupeCodeInNewPlans = newPlans.find(newplan => newplan.externalPayorPlanId.toString() === externalPayorPlanIdToFind && newplan.payorPlanId.toString() !== formValues.payorPlanId.toString());
    let dupeCodeInEditedPlans = editedPlans.find(edplan => edplan.externalPayorPlanId.toString() === externalPayorPlanIdToFind && edplan.payorPlanId.toString() !== formValues.payorPlanId.toString());
    let dupeCodeInPayerPlans = rows?.find(row => row.externalPayorPlanId.toString() === externalPayorPlanIdToFind && row.payorPlanId.toString() !== formValues.payorPlanId.toString());
    let dupeCodeInAllPlans = payers?.find(payer => payer.payorPlans.find(plan => plan.externalPayorPlanId.toString() === externalPayorPlanIdToFind && plan.payorPlanId.toString() !== formValues.payorPlanId.toString()));
    if (dupeCodeInNewPlans || dupeCodeInEditedPlans || dupeCodeInPayerPlans || dupeCodeInAllPlans) {
      dispatch(showErrorStatus("Plan ID must be unique"));
    } else {
      handlePlanSave()
    }
  }

  // update grid added/edited plans
  async function handlePlanSave() {
    const formValues: EditPayerPlans = plansFormik.values;
    formValues.paramId = paramId;

    if (isPlanEdit) {

      let selectedPlan = rows?.find((el) => el.payorPlanId === itemId);

      setRows(
        rows?.map((r) =>
          r?.payorPlanId !== selectedPlan?.payorPlanId ? r : formValues
        )
      );
      let editedPlansCopy: EditPayerPlans[] = [...editedPlans!];
      let index = editedPlansCopy?.findIndex(
        (plan) => plan.payorPlanId === itemId
      );
      if (index! > -1) {
        editedPlansCopy?.splice(index!, 1);
      }
      editedPlansCopy.push(formValues);
      setEditedPlans([...editedPlansCopy]);
    } else {
      let newPlansCopy: EditPayerPlans[] = [...newPlans!];
      newPlansCopy.push(formValues);
      setNewPlans(newPlansCopy);
      let rowsCopy: EditPayerPlans[] = [...rows!];
      rowsCopy.push(formValues);
      setRows(rowsCopy);
      setPage(0);
    }
    setShowAddPlanRow(false);
  }

  function handleSaveCallback() {
    if (isSaveSuccessful) {
      handlePayersModalSubmit(isEdit);
      setEnableSaveButton(false);
    }
    dispatch(clearStatus());
  }

  function handleCancelCallback() {
    handlePayersModalCancel();
  }

  function handlePlanCancelCallback() {
    setShowAddPlanRow(false);
  }

  const handleAddPlanClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // temporary plan_id - needed for edit filter but will be replaced on save
    plansFormik.setFieldValue(
      "payorPlanId",
      Math.floor(1000 + Math.random() * 9000)
    );
    plansFormik.setFieldValue("payorId", payerItem?.payorId);
    plansFormik.setFieldValue("planName", "");
    plansFormik.setFieldValue("planDesc", "");
    plansFormik.setFieldValue("externalPayorPlanId", "");
    setIsPlanEdit(false);
    setShowAddPlanRow(true);
  };

  const handleSaveModalClose = () => {
    setShowAddPlanRow(false);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemId: number
  ) => {
    event.stopPropagation();
    setItemId(itemId);
    var el: HTMLElement = event.currentTarget;
    setAnchorEl(el);
  };

  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);
    let selectedPlan = rows?.find((el) => el.payorPlanId === itemId);
    let selectedPlanCopy = Utils.deepClone(selectedPlan);
    selectedPlanCopy.paramId = paramId;

    switch (menuActionType) {
      case "edit":
        plansFormik.setFieldValue("planName", selectedPlanCopy!.planName);
        plansFormik.setFieldValue("planDesc", selectedPlanCopy!.planDesc);
        plansFormik.setFieldValue(
          "externalPayorPlanId",
          selectedPlanCopy!.externalPayorPlanId
        );
        plansFormik.setFieldValue("payorId", selectedPlanCopy!.payorId);
        plansFormik.setFieldValue("payorPlanId", selectedPlanCopy!.payorPlanId);
        plansFormik.validateForm();
        setIsPlanEdit(true);
        setShowAddPlanRow(true);
        break;
      case "delete":
        let deletedPlanisNew = false;
        const filteredRows: EditPayerPlans[] = rows!.filter(
          (item) => item.payorPlanId !== itemId
        );
        setRows([...filteredRows]);
        // delete from new plans
        let index = newPlans.findIndex((plan) => plan.payorPlanId === itemId);
        if (index! > -1) {
          deletedPlanisNew = true;
        }
        let newPlansCopy: EditPayerPlans[] = [...newPlans!];
        const filteredNewPlans: EditPayerPlans[] = newPlansCopy!.filter(
          (item) => item.payorPlanId !== itemId
        );
        setNewPlans([...filteredNewPlans]);
        // delete from edited plans
        let editedPlansCopy: EditPayerPlans[] = [...editedPlans!];
        const filteredEditedPlans: EditPayerPlans[] = editedPlansCopy!.filter(
          (item) => item.payorPlanId !== itemId
        );
        setEditedPlans([...filteredEditedPlans]);
        // add to deletedPlans
        if (!deletedPlanisNew) {
          let deletedPlansCopy: EditPayerPlans[] = [...deletedPlans!];
          deletedPlansCopy.push(selectedPlanCopy!);
          setDeletedPlans(deletedPlansCopy);
        }
        break;
    }
  };

  const getStates = async () => {
    const response: any = await dispatch(configGetStates(paramId));
    if (response.payload) {
      const data: any = response.payload;
      setStates(data);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const payerValidationSchema = Yup.object({
    externalPayorId: Yup.string().required("Payer Id is required"),
    payorName: Yup.string()
      .min(3, "Name should be of minimum 3 characters length")
      .required("Name is required"),
    stateCode: Yup.string()
      .min(2, "Please enter a valid state code")
      .required("State is required"),
  });

  const planValidationSchema = Yup.object({
    externalPayorPlanId: Yup.string().required("Plan Id is required"),
    planName: Yup.string()
      .min(3, "Name should be of minimum 3 characters length")
      .required("Name is required"),
    planDesc: Yup.string()
      .min(3, "Description should be of minimum 3 characters length")
      .required("Description is required"),
  });

  function checkIfPayerIsValid(value: {}) {
    payerValidationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch((err) => {
        setEnableSaveButton(false);
      });
  }

  function checkIfPlanIsValid(value: {}) {
    planValidationSchema
      .validate(value)
      .then(() => {
        setEnablePlanSaveButton(true);
      })
      .catch((err) => {
        setEnablePlanSaveButton(false);
      });
  }

  // formik for add/edit plan
  const plansFormik = useFormik({
    initialValues: initialPlanValues,
    validate: checkIfPlanIsValid,
    validationSchema: planValidationSchema,
    onSubmit: (values) => {},
  });

  return <>
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle>{isEdit ? "Edit" : "Add"} Payer</DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialPayerValues}
          innerRef={payerFormRef}
          validationSchema={payerValidationSchema}
          validate={checkIfPayerIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={6} className="mt-4">
                  <TextField
                    label="Name"
                    name="payorName"
                    value={formik.values.payorName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={3} className="mt-4">
                  <TextField
                    label="Payer Id"
                    type="text"
                    value={formik.values.externalPayorId}
                    name="externalPayorId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>

                <Grid item xs={3} className="mt-4">
                  <TextField
                    select
                    label="State"
                    name="stateCode"
                    value={formik.values.stateCode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="state-field"
                  >
                    {states?.map((row: States) => (
                      <MenuItem key={row.stateCode} value={row.stateCode}>
                        {row.stateCode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} className="mt-2">
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {planHeaders.map((column) => (
                            <TableCell key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.sortBy(rows, "planName").slice!(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((row: EditPayerPlans) => (
                          <TableRow key={row.payorPlanId}>
                            <TableCell>{row.planName}</TableCell>
                            <TableCell>{row.planDesc}</TableCell>
                            <TableCell>{row.externalPayorPlanId}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-haspopup="true"
                                onClick={(e) =>
                                  handleMenuClick(e, row?.payorPlanId)
                                }
                                size="large">
                                <MoreHorizIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <IconButton
                          title="Add Plan"
                          color="primary"
                          onClick={(e) => handleAddPlanClick(e)}
                          size="large">
                          <AddBoxOutlinedIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <TablePagination
                          component="div"
                          rowsPerPageOptions={[
                            5,
                            10,
                            50,
                            { value: rows?.length!, label: "All" },
                          ]}
                          count={rows?.length!}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          labelRowsPerPage={"View"}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </Grid>
                    </Grid>
                  </TableContainer>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleMenuClose()}
                  >
                    <MenuItem
                      className="primary"
                      onClick={() => handleMenuClose("edit")}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem
                      className="danger"
                      onClick={() => handleMenuClose("delete")}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText={isEdit ? "Update" : "Save"}
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackSave={handleSaveCallback}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
    {showAddPlanRow && (
      <Dialog
        open={open}
        onClose={handleSaveModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isPlanEdit ? "Edit" : "Add"}
          {" a plan"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form>
              <Grid container spacing={2}>
                <Grid item xs={5} className="mt-4">
                  <TextField
                    label="Name"
                    name="planName"
                    value={plansFormik.values.planName}
                    onChange={plansFormik.handleChange}
                    onBlur={plansFormik.handleBlur}
                  />
                </Grid>

                <Grid item xs={5} className="mt-4">
                  <TextField
                    label="Description"
                    type="text"
                    value={plansFormik.values.planDesc}
                    name="planDesc"
                    onChange={plansFormik.handleChange}
                    onBlur={plansFormik.handleBlur}
                  />
                </Grid>
                <Grid item xs={2} className="mt-4">
                  <TextField
                    label="Plan Id"
                    type="text"
                    value={plansFormik.values.externalPayorPlanId}
                    name="externalPayorPlanId"
                    onChange={plansFormik.handleChange}
                    onBlur={plansFormik.handleBlur}
                  />
                </Grid>
              </Grid>
            </form>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <DialogActionButton
            isEnabled={enablePlanSaveButton}
            savebuttonText={"Save"}
            saveStatus={saveStatus}
            spinnerLeftPosition={5}
            executeSave={validatPlanSave}
            handleCallbackSave={handleSaveCallback}
            handleCallbackCancel={handlePlanCancelCallback}
          />
        </DialogActions>
      </Dialog>
    )}
  </>;
}
