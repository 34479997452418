import React from 'react';
import './login.scss';
import { Grid, Paper, Typography } from '@mui/material';
import UpdatePasswordView from './update-password-view';

function CreatePassword() {
    return (
        <div className="page-container login-container" slot="require-new-password">
            <main className="no-padding">
                <div className="content fullwidth loginflow">
                    <Grid container alignContent="center" justifyContent="center" direction="column" spacing={4}>
                        <Paper>
                            <UpdatePasswordView type="create" />
                        </Paper>
                        <Grid item className="terms-conditions">
                            <Grid container alignContent="center" justifyContent="center">
                              <Typography
                                color={'#0093d5'}
                                onClick={() => window.location.replace("https://www.finpay.com/finpay-website-user-agreement/")}
                              >
                                Terms & Conditions
                              </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
             </main>
        </div>
    );
}

export default CreatePassword;

