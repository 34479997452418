import { LoadingOverlay } from '@finpay-development/shared-components';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openHelloSignDoc } from '../../../../shared/configuration/config-settings';
import { RootState } from '../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../shared/state/store';
import { clearSignUrl } from '../../../state/patient-slice';
import { getEncounterSignUrl, getInstanceOfCare } from '../../../state/patient-thunk';
import { PatientPaymentProgram } from '../../models/patient-payment-program';

// component for clients to sign docs (on patient domain)
const ClientDocumentSign = () => {
  const dispatch = useDispatch<AppDispatch>();

  const pullIdsFromUrl = () => {
    let patientId = 0;
    let encounterId = 0;
    if (window.location.pathname.includes('sign-doc')) {
      const uriSplit = window.location.pathname.split('/');
      patientId = parseInt(uriSplit[uriSplit.length - 3]);
      encounterId = parseInt(uriSplit[uriSplit.length - 1]);
    }
    return { patientId, encounterId }
  };

  const stateFields = {
    selectedEncounter: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter
    }),
    paymentProgram: useSelector((state: RootState) => {
      return state?.patientContext?.selectedEncounter?.patientPaymentProgram?.length > 0 ?
      state.patientContext.selectedEncounter.patientPaymentProgram[state.patientContext.selectedEncounter.patientPaymentProgram.length - 1] : {} as PatientPaymentProgram
    }),
    signUrl: useSelector((state: RootState) => {
      return state?.patientContext?.patientEncounterSignUrl;
    }),
    isError: useSelector((state: RootState) => {
      return state?.patientContext?.isError?.hellosign || state.patientContext.isError.patientEncounter
    }),
    isLoading: useSelector((state: RootState) => {
      return state?.patientContext?.isLoading?.patientIOC || state.patientContext.isLoading?.hellosign
    })
  };

  const { selectedEncounter, paymentProgram, signUrl, isLoading, isError } = stateFields;

  const getIOC = async (patientId: number, encounterId: number) => {
    await dispatch(getInstanceOfCare({patientId, encounterId}));
  }

  useEffect(() => {
    const { patientId, encounterId } = pullIdsFromUrl();
    getIOC(patientId, encounterId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedEncounter?.patientEncounterId !== 0) {
      if (!paymentProgram?.areDocsSigned) {
        dispatch(getEncounterSignUrl({ patientId: selectedEncounter?.patientId, encounterId: selectedEncounter?.patientEncounterId }));
      }
    }
  }, [dispatch, selectedEncounter?.patientId, paymentProgram?.areDocsSigned, selectedEncounter?.patientEncounterId] )

  useEffect(() => {
    if (signUrl && signUrl?.urlToSign) {
      openHelloSignDoc(signUrl?.urlToSign);
      dispatch(clearSignUrl());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUrl])

  const refreshData = () => {
    const { patientId, encounterId } = pullIdsFromUrl();
    getIOC(patientId, encounterId)
  }

  return (
    <main>
      <div className="content">
        {isLoading && (
          <LoadingOverlay />
        )}
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {paymentProgram?.areDocsSigned && (
            <Typography variant='h1' className='pt-8'>
              Document Signing Complete
            </Typography>
          )}
          {isError && (
            <>
              <Grid item xs={10}>
                <Typography variant="h1">
                  Error fetching documents. Please refresh.
                </ Typography>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" marginTop="-1.2rem" marginRight="-1.5rem">
                  <IconButton onClick={() => refreshData()} size="large">
                    <RefreshIcon fontSize='large' className="icon" style={{color: '297ec1'}} />
                  </IconButton>
                </ Box>
              </Grid>
            </>
          )}
        </ Grid>
      </div>
    </main>
  );
}

export default ClientDocumentSign;
