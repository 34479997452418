
export interface ClientForm extends ClientEntityForm, ClientContactForm {
    clientId: number;
    supportEmail?: string;
}

export interface ClientEntityForm {
    companyName: string;
    website: string;
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: number;
    zipCode: string;
}

export interface ClientContactForm {
    firstName: string;
    lastName: string;
    email: string;
    retypeEmail?: string;
    phone: string;
    ext: string;
    mobilePhone: string;
    retypeMobilePhone?: string;
}

export const blankClientEntityForm: ClientEntityForm = {
    companyName: "",
    website: "",
    streetAddress1: "",
    streetAddress2: "",
    city: "",
    state: -1,
    zipCode: ""
}

export const blankClientContactForm: ClientContactForm = {
    firstName: "",
    lastName: "",
    email: "",
    retypeEmail: "",
    phone: "",
    ext: "",
    mobilePhone: "",
    retypeMobilePhone: ""
}

export const blankClientForm: ClientForm = {
    clientId: -1,
    ...blankClientEntityForm,
    ...blankClientContactForm
}

export interface ClientDeficiencyNotification {
    clientId: number,
    clientDeficiencyNotificationId: number
    isEiv: boolean,
    addedToDownpayment: boolean,
    downpaymentPct: number
}

export const emptyClientDeficiencyNotification : ClientDeficiencyNotification = {
    clientId: 0,
    clientDeficiencyNotificationId: 0,
    isEiv: false,
    addedToDownpayment: false,
    downpaymentPct: 0
}