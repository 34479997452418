import { axiosDeleteHelper } from "../../shared/service/axios-delete-helper";
import { AxiosDeletePayload } from "../../shared/service/axios-delete-payload";
import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { AxiosResultStatus } from "../../shared/service/axios-result-status";
import { axiosSaveHelper } from "../../shared/service/axios-save-helper";
import { AxiosSavePayload } from "../../shared/service/axios-save-payload";
import { RuleCriteriaParam } from "../models/rule";
import { LevelOfCare } from "../models/level-of-care";
import { EditlevelOfCare } from "../models/level-of-care";
import { EditPayer, EditPayerPlans, PayerRow } from "../models/payers";
import { Scholarship } from "../models/scholarship";
import { ConfigRiskThreshold } from '../models/risk-threshold';
import { ConfigRiskClassSetting, FinPayRiskClass } from "../models/risk-class-setting";

export class AdminConfigurationService {
  // tip: a RuleCriteriaParam is basically a 'formula'
  async getRuleCriteriaParams(): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      url: "administration/configuration/formula/listing",
    };
    const result = await axiosReadHelper(payload);
    return result;
  }

  async getRuleCriteriaParam(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: "administration/configuration/formula",
    };
    const result = await axiosReadHelper(payload);
    return result;
  }

  async saveRuleCriteriaParam(
    ruleCriteriaParam: RuleCriteriaParam
  ): Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: ruleCriteriaParam,
      dataId: ruleCriteriaParam.paramId,
      url: "administration/configuration/formula",
    };

    const result = await axiosSaveHelper(payload);
    return result;
  }

  async deleteRuleCriteriaParam(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: paramId,
      url: `administration/configuration/formula`,
    };
    const result = await axiosDeleteHelper(payload);
    return result;
  }

  async configGetLOC(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: "configuration/v2/configuration/levelofcare",
    };

    return axiosReadHelper(payload);
  }

  async configSaveLOC(
    editlevelOfCareParam: EditlevelOfCare
  ): Promise<AxiosResultStatus> {
    //async configSaveLOC(editlevelOfCareParam: EditlevelOfCare): Promise<any> {
    let newLocPayload: any = { ...editlevelOfCareParam };
    delete newLocPayload.paramId;
    delete newLocPayload.levelOfCareId;
    const payload: AxiosSavePayload = {
      dataToSave: newLocPayload,
      dataId: editlevelOfCareParam.paramId,
      isPatch: true,
      url:
        "configuration/v2/configuration/levelofcare/" +
        editlevelOfCareParam.levelOfCareId,
    };
    return axiosSaveHelper(payload);
  }

  async configNewLOC(
    levelOfCareParam: LevelOfCare
  ): Promise<AxiosResultStatus> {
    let newLocPayload: any = { ...levelOfCareParam };
    delete newLocPayload.paramId;
    delete newLocPayload.levelOfCareId;
    const payload: AxiosSavePayload = {
      dataToSave: newLocPayload,
      dataId: levelOfCareParam.paramId,
      url: "configuration/v2/configuration/levelofcare/",
    };

    return axiosSaveHelper(payload);
  }

  async configDeleteLOC(
    deletelevelOfCareParam: LevelOfCare
  ): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: deletelevelOfCareParam.paramId!,
      url:
        "configuration/v2/configuration/levelofcare/" +
        deletelevelOfCareParam.levelOfCareId,
    };

    return axiosDeleteHelper(payload);
  }

  async configGetPayers(paramId: number): Promise<AxiosResultStatus> {
    const baseUrl: string = "configuration/v2/configuration/payor";
    let lastResult: AxiosResultStatus | undefined = undefined;
    const limit = 1000;
    let offset = 0;
    let results: Array<PayerRow> = [];

    while (lastResult === undefined || lastResult.entity.length === limit) {
      const result: AxiosResultStatus = await axiosReadHelper({
        dataId: paramId,
        url: `${baseUrl}?offset=${offset}&limit=${limit}`,
      });
      offset += limit;
      lastResult = result;
      if (result.hasErrors) {
        return result;
      }
      results.push(...(result.entity as Array<PayerRow>));
    }

    lastResult.entity = results;
    return lastResult;
  }

  // url: "clients/v2/client/${clientId}/scholarship",
  async configGetScholarships(paramId: number, clientId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/client/${clientId}/scholarship`,
    };

    const responseData = await axiosReadHelper(payload);
    return responseData;

  }

  async configSaveScholarship(
    editScholarshipParam: Scholarship
  ): Promise<AxiosResultStatus> {
    let newScholarshipPayload: any = { ...editScholarshipParam };
    delete newScholarshipPayload.paramId;
    delete newScholarshipPayload.clientScholarshipLevelsId;
    delete newScholarshipPayload.clientId;
    //url: `clients/v2/client/${editScholarshipParam.clientId}/scholarship/${editScholarshipParam.scholarshipId}`,
    const payload: AxiosSavePayload = {
      dataToSave: newScholarshipPayload,
      dataId: editScholarshipParam.paramId,
      isPatch: true,
      url: `clients/v2/client/scholarship/${editScholarshipParam.clientScholarshipLevelsId}`,
    };
    return axiosSaveHelper(payload);
  }

  async configNewScholarship(
    scholarshipParam: Scholarship
  ): Promise<AxiosResultStatus> {
    let newScholarshipPayload: any = { ...scholarshipParam };
    delete newScholarshipPayload.paramId;
    delete newScholarshipPayload.clientScholarshipLevelsId;
    const payload: AxiosSavePayload = {
      dataToSave: newScholarshipPayload,
      dataId: scholarshipParam.paramId,
      url: `clients/v2/client/${scholarshipParam.clientId}/scholarship`
    };

    return axiosSaveHelper(payload);
  }

  async configDeleteScholarship(
    deleteScholarshipParam: Scholarship
  ): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: deleteScholarshipParam.paramId!,
      url: `clients/v2/client/scholarship/${deleteScholarshipParam.clientScholarshipLevelsId}`
    };
    return axiosDeleteHelper(payload);
  }

  async configGetStates(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: "configuration/v2/configuration/states",
    };

    return axiosReadHelper(payload);
  }

  async configNewPayer(newPayerParam: EditPayer): Promise<AxiosResultStatus> {
    let newPayerPayload: any = { ...newPayerParam };
    delete newPayerPayload.paramId;
    delete newPayerPayload.payorId;
    const payload: AxiosSavePayload = {
      dataToSave: newPayerPayload,
      dataId: newPayerParam.paramId,
      url: "configuration/v2/configuration/payor",
    };

    return axiosSaveHelper(payload);
  }

  async configSavePayer(newPayerParam: EditPayer): Promise<AxiosResultStatus> {
    let newPayerPayload: any = { ...newPayerParam };
    delete newPayerPayload.paramId;
    delete newPayerPayload.payorId;
    const payload: AxiosSavePayload = {
      dataToSave: newPayerPayload,
      dataId: newPayerParam.paramId,
      isPatch: true,
      url: `configuration/v2/configuration/payor/${newPayerParam.payorId}`,
    };
    const result = axiosSaveHelper(payload);
    return result;
  }

  async configNewPlan(
    newPlanParam: EditPayerPlans
  ): Promise<AxiosResultStatus> {
    let newPlanPayload: any = { ...newPlanParam };
    delete newPlanPayload.paramId;
    delete newPlanPayload.payorId;
    delete newPlanPayload.payorPlanId;

    const payload: AxiosSavePayload = {
      dataToSave: newPlanPayload,
      dataId: newPlanParam.paramId,
      url: `configuration/v2/configuration/payor/${newPlanParam.payorId}/plan`,
    };

    return axiosSaveHelper(payload);
  }

  async configSavePlan(
    newPlanParam: EditPayerPlans
  ): Promise<AxiosResultStatus> {
    let newPlanPayload: any = { ...newPlanParam };
    delete newPlanPayload.paramId;
    delete newPlanPayload.payorId;
    delete newPlanPayload.payorPlanId;

    const payload: AxiosSavePayload = {
      dataToSave: newPlanPayload,
      dataId: newPlanParam.paramId,
      isPatch: true,
      url: `configuration/v2/configuration/payor/plan/${newPlanParam.payorPlanId}`,
    };
    return axiosSaveHelper(payload);
  }

  async configDeletePayer(
    deletePayerParam: EditPayer
  ): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: deletePayerParam.paramId!,
      url: "configuration/v2/configuration/payor/" + deletePayerParam.payorId,
    };

    return axiosDeleteHelper(payload);
  }

  async configDeletePlan(
    deletePlanParam: EditPayerPlans
  ): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: deletePlanParam.paramId!,
      url: `configuration/v2/configuration/payor/plan/${deletePlanParam.payorPlanId}`,
    };

    return axiosDeleteHelper(payload);
  }

  async configGetRiskThresholds(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `configuration/v2/configuration/risk-threshold`,
    };

    const responseData = await axiosReadHelper(payload);
    return responseData;
  }

  async configSaveRiskThreshold(
    editRiskThresholdParam: ConfigRiskThreshold
  ): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = { ...editRiskThresholdParam };
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.riskThresholdId;
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: editRiskThresholdParam.paramId,
      isPatch: true,
      url: `configuration/v2/configuration/risk-threshold/${editRiskThresholdParam.riskThresholdId}`,
    };
    return axiosSaveHelper(payload);
  }

  async configNewRiskThreshold(
    editRiskThresholdParam: ConfigRiskThreshold
  ): Promise<AxiosResultStatus> {
    let newRiskThresholdPayload: any = { ...editRiskThresholdParam };
    delete newRiskThresholdPayload.paramId;
    delete newRiskThresholdPayload.riskThresholdId;
    const payload: AxiosSavePayload = {
      dataToSave: newRiskThresholdPayload,
      dataId: editRiskThresholdParam.paramId,
      url: `configuration/v2/configuration/risk-threshold`
    };

    return axiosSaveHelper(payload);
  }

  async configDeleteRiskThreshold(
    editRiskThresholdParam: ConfigRiskThreshold
  ): Promise<AxiosResultStatus> {
    const payload: AxiosDeletePayload = {
      dataId: editRiskThresholdParam.paramId!,
      url: `configuration/v2/configuration/risk-threshold/${editRiskThresholdParam.riskThresholdId}`
    };
    return axiosDeleteHelper(payload);
  }

  async configSaveRiskClassSetting(
    editRiskClassSettingParam: ConfigRiskClassSetting
  ): Promise<AxiosResultStatus> {
    let newRiskClassSettingPayload: any = { ...editRiskClassSettingParam };
    let isEdit = false;
    let endPoint = `configuration/v2/configuration/risk-class-setting/`;
    if (editRiskClassSettingParam.riskClassSettingId !== undefined && editRiskClassSettingParam.riskClassSettingId > 0) {
      endPoint = `configuration/v2/configuration/risk-class-setting/${editRiskClassSettingParam.riskClassSettingId}`;
      isEdit = true;

    }
    delete newRiskClassSettingPayload.paramId;
    delete newRiskClassSettingPayload.riskClassName;
    delete newRiskClassSettingPayload.riskClassSettingId;

    const payload: AxiosSavePayload = {
      dataToSave: newRiskClassSettingPayload,
      dataId: editRiskClassSettingParam.paramId,
      isPatch: isEdit,
      url: endPoint,
    };

    return axiosSaveHelper(payload);
  }


  async configGetFinPayRiskClasses(paramId: number): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `clients/v2/risk-class`,
    };

    const responseData = await axiosReadHelper(payload);
    return responseData;
  }

  async configGetRiskClassSettings(paramId: number, finPayRiskClasses: FinPayRiskClass[]): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: paramId,
      url: `configuration/v2/configuration/risk-class-setting`,
    };

    const response = await axiosReadHelper(payload);
    const riskClassSettings = response.entity;

    if (!response.hasErrors) {
      const retData = this.mapToConfigRiskClassSettingForm(riskClassSettings, finPayRiskClasses);
      response.entity = retData;
    }

    return response;
  }

  mapToConfigRiskClassSettingForm(responseData: ConfigRiskClassSetting[], totalFinancialRiskClasses: FinPayRiskClass[]): ConfigRiskClassSetting[] {
    // eslint-disable-next-line no-lone-blocks
    {
      let workFinancialRiskClasses: ConfigRiskClassSetting[] = [];
      totalFinancialRiskClasses.forEach((x: FinPayRiskClass) => {
        const workRiskClassId = x.riskClassId;
        let newItem: ConfigRiskClassSetting = {
          fpRiskClassId: workRiskClassId,
          riskClassName: x.riskClassName,
          riskClassSettingId: undefined,
          riskClassCleared: undefined,
          riskClassHre: undefined,
        };

        let dataItem = responseData.find(item => item.fpRiskClassId === workRiskClassId);
        if (dataItem !== undefined) {
          newItem.riskClassCleared = dataItem.riskClassCleared;
          newItem.riskClassHre = dataItem.riskClassHre;
          newItem.riskClassSettingId = dataItem.riskClassSettingId;
        }
        workFinancialRiskClasses.push(newItem);
      })
      return workFinancialRiskClasses;
    }

  }

}

export const adminConfigurationService = new AdminConfigurationService();
