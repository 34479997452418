import { Button, TextField } from '@finpay-development/shared-components';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { AppDispatch } from '../../../../shared/state/store';
import { forwardDocument } from '../../../../shared/validation/schemas';
import { helloSignForward } from '../../../state/patient-thunk';
import { PatientEncounter } from '../../models/patient-encounter';

interface DocumentForwardModalProps {
  open: boolean;
  patientEncounter: PatientEncounter;
  handleCloseModal: () => void;
}

function DocumentForwardModal(props: DocumentForwardModalProps) {
  const { open, patientEncounter, handleCloseModal } = props;

  const [enableSaveButton, setEnableOkButton] = useState(false)

  const formRef: any = useRef();
  const dispatch = useDispatch<AppDispatch>();

  async function handleSave() {
    await dispatch(helloSignForward({
      patientEncounter: patientEncounter,
      email: formRef?.current?.values?.email
    }));
    handleCloseModal();
  }

  const initialValues= {
    email: "",
    retypeEmail: ""
  };

  const validationSchema = Yup.object(forwardDocument);

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableOkButton(true);
      })
      .catch((err) => {
        setEnableOkButton(false);
      });
  }

  return (
    <Dialog
      className="modal"
      scroll="body"
      open={open}
      maxWidth="md"
    >
      <DialogTitle>
        <span className="title">Forward Document</span>
      </DialogTitle>
      <DialogContent>
      <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => {}}
        >
          {(formik) => (
            <Form>
              <Grid container spacing={2}>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["email"] && formik.errors["email"]
                        ? formik.errors["email"]
                        : ""
                    }
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    error={
                      formik.touched["retypeEmail"] &&
                      formik.errors["retypeEmail"]
                        ? formik.errors["retypeEmail"]
                        : ""
                    }
                    label="Retype Email"
                    name="retypeEmail"
                    value={formik.values.retypeEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseModal}
          type="secondary">
            Cancel
        </Button>
        <Button
          disabled={!enableSaveButton}
          onClick={handleSave} marginLeft={0}>
            Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DocumentForwardModal;
