import {Comment} from '../../patient/components/models/comment';
import {PatientNotes} from '../../patient/components/models/patient-note';
import {axiosDeleteHelper} from '../../shared/service/axios-delete-helper';
import {AxiosDeletePayload} from '../../shared/service/axios-delete-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';
import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {
  AxiosResultDeleteStatus,
} from '../../shared/service/axios-result-delete-status';
import {AxiosResultStatus} from '../../shared/service/axios-result-status';
import {axiosSaveHelper} from '../../shared/service/axios-save-helper';
import {AxiosSavePayload} from '../../shared/service/axios-save-payload';
import _ from 'lodash';

export class CommentsService {


async createPatientNote(comment: Partial<PatientNotes>):Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: comment,
      dataId: 0,
      url: `patient-encounter/v2/note`
    }
  return await axiosSaveHelper(payload);
  }

  async getPatientNotes(patientId: number, encounterId: number):Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `patient-encounter/v2/notes?patientEncounterId=${encounterId}`
    }
    return await axiosReadHelper(readPayload);
  }

  async deletePatientNote(noteToDelete: Partial<PatientNotes>, commentId: number): Promise<AxiosResultStatus> {
    const readPayload: AxiosSavePayload = {
      dataToSave: noteToDelete,
      dataId: commentId,
      url: `patient-encounter/v2/note/${commentId}`,
      isPatch: true,
    }
    return await axiosSaveHelper(readPayload);
  }

  async dismissReminder(reminderToRemove: Partial<PatientNotes>, commentId: number):Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: _.omit(reminderToRemove, ['patientNotesId']),
      dataId: commentId,
      url: `patient-encounter/v2/note/${commentId}`,
      isPatch: true
    }
    return await axiosSaveHelper(payload);
  }


  async getClientComments(clientId: number):Promise<AxiosResultStatus> {
    const readPayload: AxiosReadPayload = {
      dataId: 0,
      url: `client/${clientId}/comment/listing`
    }
    const responseData = await axiosReadHelper(readPayload);
    return responseData;
  }

  async createClientComment(comment: Comment, clientId: number):Promise<AxiosResultStatus> {
    const payload: AxiosSavePayload = {
      dataToSave: comment,
      dataId: 0,
      url: `client/${clientId}/comment`
    }
    const saveResult = await axiosSaveHelper(payload);
    return saveResult;
  }

  async deleteClientComment(clientId: number, commentId:number): Promise<AxiosResultDeleteStatus> {
    const readPayload: AxiosDeletePayload = {
      dataId: commentId,
      url: `client/${clientId}/comment`
    }
    const responseData = await axiosDeleteHelper(readPayload);
    return responseData;
  }
}

export const commentsService = new CommentsService();