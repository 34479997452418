import { axiosReadHelper } from "../../shared/service/axios-read-helper";
import { AxiosReadPayload } from "../../shared/service/axios-read-payload";
import { AxiosResultStatus } from "../../shared/service/axios-result-status";
import { axiosSaveHelper } from "../../shared/service/axios-save-helper";
import { AxiosSavePayload } from "../../shared/service/axios-save-payload";
import { ImplementationPESScript } from "../components/implementation-clients/details/models/implementation-pes-script";
import {
  PESScriptDetails,
  PESScriptForm,
} from "../models/pes-script-form";

export class PESScriptService {
  private mapPESScriptToClient = (
    formValues: PESScriptForm,
    fullClientPESScript: ImplementationPESScript,
    isEditPESScript: boolean
  ) => {
      return {
        riskClassId: formValues?.riskClassId,
        scriptUrl: formValues.scriptUrl,
        facilityId: formValues.facilityId || null,
      };
  };

  private mapPESScriptToForm = (pesScript: ImplementationPESScript) => {
    const pesScriptForm: PESScriptForm = {
      pesScriptId: pesScript?.pesScriptId,
      facilityId: pesScript?.facilityId,
      scriptUrl: pesScript?.scriptUrl || "",
      riskClassId: typeof pesScript?.riskClass?.riskClassId === 'number' ? pesScript?.riskClass?.riskClassId : null,

    };
    return pesScriptForm;
  };

  private mapPESScriptToDetails = (pesScript: ImplementationPESScript) => {
    const pesScriptDetails: PESScriptDetails = {
      clientId: pesScript?.clientId,
      pesScriptId: pesScript?.pesScriptId,
      facilityId: pesScript?.facilityId,
      scriptUrl: pesScript?.scriptUrl || "",
      riskClass: pesScript?.riskClass || "",
    };
    return pesScriptDetails;
  };

  async getClientPESScripts(clientId: number, facilityId: number | null): Promise<AxiosResultStatus> {
    const facilityQueryParameters = facilityId ? `?facilityId=${facilityId}` : `?facilityId=${null}`
    const payload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client/${clientId}/pes-script${facilityQueryParameters}`,
    };

    const response = await axiosReadHelper(payload);
    const pesScript = response.entity;

    if (!response.hasErrors) {
      const pesScriptForm = this.mapPESScriptToForm(pesScript);
      response.entity = {
        pesScriptForm: pesScriptForm,
        apiResponsePESScript: pesScript,
      };
    }

    return response;
  }

  async getClientPESScript(
    pesScriptId: number,
  ): Promise<AxiosResultStatus> {
    const payload: AxiosReadPayload = {
      dataId: -2,
      url: `clients/v2/client/pes-script/${pesScriptId}`,
    };

    const response = await axiosReadHelper(payload);
    const pesScript = response.entity;

    if (!response.hasErrors) {
      const pesScriptForm = this.mapPESScriptToForm(pesScript);
      const pesScriptDetails = this.mapPESScriptToDetails(pesScript);

      response.entity = {
        pesScriptForm: pesScriptForm,
        apiResponsePESScript: pesScript,
        pesScriptDetails: pesScriptDetails,
      };
    }

    return response;
  }


  async savePESScript(
    clientId: number,
    facilityId:number | null | undefined,
    formValues: PESScriptForm,
    fullClientPESScript: ImplementationPESScript,
    allClientPESScripts: ImplementationPESScript[],
    existingRiskClasses: Set<number>,
    ) {
    const isEditPESScript = formValues.pesScriptId !== 0;
    const clientPESScriptToSave = this.mapPESScriptToClient(
      formValues,
      fullClientPESScript,
      isEditPESScript
    );
    const pesScriptId = formValues?.pesScriptId;
    const updatePESScriptUrl = `clients/v2/client/pes-script/${pesScriptId}`;
    const addPESScriptUrl = `clients/v2/client/${clientId}/pes-script`;

    const payload: AxiosSavePayload = {
      dataToSave: clientPESScriptToSave,
      dataId: -2,
      isPatch: isEditPESScript,
      url: isEditPESScript ? updatePESScriptUrl : addPESScriptUrl,
    };

    const response = await axiosSaveHelper(payload);

    if (!response.hasErrors) {
      const pesScriptResponse = response.entity;
      let apiResponsePESScript: ImplementationPESScript[] = [];

      const pesScriptIndex = allClientPESScripts.findIndex((pesScript) => {
        return pesScript.pesScriptId === pesScriptResponse.pesScriptId;
      });

      if (pesScriptIndex > -1) {
        allClientPESScripts.splice(pesScriptIndex, 1, pesScriptResponse);
      } else {
        allClientPESScripts.push(pesScriptResponse);
      }
      apiResponsePESScript = allClientPESScripts;
      response.entity = {
        apiResponsePESScript,
      };
    }

    return response;
  }
}

export const pesScriptService = new PESScriptService();
