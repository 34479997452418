import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from '@mui/material';
import { DialogActionButton, TextField } from '@finpay-development/shared-components';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../shared/state/root-reducer';
import { AppDispatch } from '../../../../../shared/state/store';
import { PESScriptForm, PESScriptUIForm } from '../../../../models/pes-script-form';
import { savePESScript} from '../../../../state/pes-script/implementation-pesscript-thunk';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { pesScriptSchema } from '../../../../../shared/validation/schemas';
import { clearPESScriptForm, resetAddPESScript } from '../../../../state/pes-script/implementation-pesscript-slice';
import { Utils } from '../../../../../shared/utils';
import { riskClass } from '../../../../../shared/model/risk-class';
import { showErrorStatus } from '../../../../../security/state/user-slice';
import {clearStatus} from "../../../../state/pes-script/implementation-pesscript-slice"
import { setPESScriptTabComplete } from '../../../../state/clients/implementation-clients-slice';

interface PESScriptModalProps {
    open: boolean;
    isEditPESScript: boolean;
    handlePESScriptModalCancel: () => void;
    handlePESScriptModalSubmit: (isEditPESScript: boolean) => void;
    selectedValue: any
}

export default function PESScriptModal(props: PESScriptModalProps) {

    const {
        open,
        isEditPESScript,
        handlePESScriptModalCancel,
        handlePESScriptModalSubmit,
        selectedValue
    } = props
    const [enableSaveButton, setEnableSaveButton] = useState(false);

    const dispatch = useDispatch<AppDispatch>()
    const formRef: any = useRef()

    const selectors = {
        clientId: useSelector(
            (state : RootState) => state.implementationContext.implementationSpecialistClient.client.clientId
        ),
        useClientTemplate: useSelector(
            (state: RootState) =>
              state.implementationContext.implementationSpecialistClient.client
                .clientBusRules?.useClientTemplate
        ),
        isClientsLoading: useSelector(
            (state : RootState) => state.pesScriptContext.implementationPesScript.isLoading
        ),
        pesScript: useSelector(
            (state : RootState) => state.pesScriptContext.implementationPesScript.pesScript
        ),
        currentPESScriptForm: useSelector(
            (state : RootState) => state.pesScriptContext.implementationPesScript.currentPESScriptForm
        ),
        pesScripts: useSelector(
            (state : RootState) => state.pesScriptContext.implementationPesScript.pesScripts
        ),
        saveStatus: useSelector(
            (state: RootState) => state.pesScriptContext.implementationPesScript.apiStatus
        ),
        userProfile: useSelector((state: RootState) => {
            return state.userContext.userProfile;
          }),
        errorMessage: useSelector(
            (state: RootState) =>
              state.implementationContext.implementationFacility.errorMessage
        ),

    }

    const {isClientsLoading,pesScripts} = selectors

    let { userProfile } = selectors


    const [enableRiskClassDropDown] = useState(() => {
        if (Utils.isUserRoleEditableRiskClass(userProfile.userRole.roleName))
        return false
    })

    const [disableFields] = useState({
        addPESScriptModalFields : isClientsLoading
    })

    const existingRiskClasses = new Set(pesScripts.map((script) => script?.riskClass?.riskClassId))


    const handleSave = async () => {
        const pesScriptId = isEditPESScript ? formRef?.current?.values?.pesScriptId : 0

        const clientId = selectedValue?.clientId;

        const facilityId = selectedValue?.facilityId;

        const formValues: PESScriptForm = {
            ...formRef.current.values,
            pesScriptId: pesScriptId,
            facilityId:facilityId,
        }

        if (facilityId) {
            await dispatch(savePESScript({ clientId, facilityId,formValues,existingRiskClasses }));
          } else if (clientId) {
            await dispatch(savePESScript({ clientId, facilityId:null, formValues, existingRiskClasses }));
          } else {
            // Handle any other cases (e.g., when no client or facility is selected)
            await dispatch(savePESScript({ clientId, facilityId, formValues, existingRiskClasses }));
          }

    }


    const handleSaveCallback = (saveSuccessful: boolean) => {
        if (saveSuccessful) {
            handlePESScriptModalSubmit(isEditPESScript)
            setEnableSaveButton(false)
            dispatch(setPESScriptTabComplete(true))
        } else {
            dispatch(showErrorStatus(selectors.errorMessage))
        }

        dispatch(clearStatus())
    }

    const handleCancelCallback = () => {
        dispatch(resetAddPESScript())
        handlePESScriptModalCancel()
    }

    useEffect(() => {
        if (!isEditPESScript) {
            dispatch(clearPESScriptForm())
        }
    },[isEditPESScript,dispatch])


    const initialValues = {
        clientId: selectors.clientId,
        pesScriptId: selectors.currentPESScriptForm.pesScriptId,
        // facilityId: selectors.currentPESScriptForm?.facilityId,
        scriptUrl: selectors.currentPESScriptForm?.scriptUrl,
        riskClassId: selectors.currentPESScriptForm?.riskClassId || -1,

    }

    const validationSchema = Yup.object(pesScriptSchema)


  function checkIfIsValid(value: PESScriptUIForm) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }



  return (

      <Dialog
          open={open}
          scroll='body'
          className="modal client-modal"
          fullWidth={true}
          maxWidth="md"
      >
          <DialogTitle>
            <span className="title">
                {isEditPESScript ? "Edit PES Script" : "Add PES Script"}
            </span>
          </DialogTitle>

          <DialogContent>
              <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  enableReinitialize
                  validationSchema={validationSchema}
                  validate={checkIfIsValid}
                  onSubmit={() => {}}
              >
                  {
                      (formik) => (
                          <Form autoComplete="off">
                              <Grid container spacing={2}>
                                  <Grid xs={12} item className="mb-4">
                                        <TextField
                                            error={
                                            formik.touched["scriptUrl"] &&
                                            formik.errors["scriptUrl"]
                                                ? formik.errors["scriptUrl"]
                                                : ""
                                            }
                                            label="Script Url"
                                            name="scriptUrl"
                                            value={formik.values.scriptUrl}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="Script URL"

                                        />

                                  </Grid>

                              </Grid>

                              <Grid xs={12} item className="mb-4">
                                  <TextField
                                      select={true}
                                    error={
                                        formik.touched["riskClassId"] && formik.errors["riskClassId"]
                                      }
                                      label="Risk Class"
                                      name="riskClassId"
                                      value={formik.values.riskClassId}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      disabled={enableRiskClassDropDown || disableFields.addPESScriptModalFields}
                                  >
                                      <MenuItem value="-1">
                                          Select Risk Class
                                      </MenuItem>

                                      {riskClass.map((risk: { riskClassName: string, riskClassId: number }) => (

                                          <MenuItem
                                              key={risk.riskClassId}
                                              value={risk.riskClassId}
                                              disabled={existingRiskClasses.has(risk.riskClassId)}
                                              test-id={`risk-class-drop-down-risk-class-${risk.riskClassId}`}
                                          >
                                              {risk.riskClassName}
                                          </MenuItem>
                                      ))}


                                  </TextField>
                              </Grid>

                          </Form>
                      )
                  }

              </Formik>

          </DialogContent>

          <DialogActions>
              <DialogActionButton
                  isEnabled={enableSaveButton}
                  savebuttonText={isEditPESScript ? "Update" : "Add"}
                  saveStatus={selectors.saveStatus}
                  executeSave={handleSave}
                  handleCallbackSave={handleSaveCallback}
                  handleCallbackCancel={handleCancelCallback}
              />
          </DialogActions>


      </Dialog>
  );
}
