import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import * as Yup from 'yup';
import { DialogActionButton, saveCallbackStatus } from "@finpay-development/shared-components";
import { RootState } from "../../../../../shared/state/root-reducer";

import { showErrorStatus } from "../../../../../security/state/user-slice";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupsIcon from '@mui/icons-material/Groups';
import { OperatingCost } from "../models/implementation-facility";
import { Utils } from "../../../../../shared/utils";
import { ClientRiskAssessmentFields } from "src/shared/model/client-risk-assessment-config";
import { createClientRiskAssessmentConfig, updateClientRiskAssessmentConfig } from "src/implementation-specialist/state/clients/implementation-clients-thunk";
import { clearStatus } from "src/implementation-specialist/state/clients/implementation-clients-slice";

interface OperatingCostsModalProps {
    open: boolean;
    selectedFacilityRiskAssessmentFields: ClientRiskAssessmentFields;
    selectedId?: number;
    handleOperatingCostsModalCancel: () => void;
    handleOperatingCostsModalSubmit: () => void;
  }

export function OperatingCostsModal(props: OperatingCostsModalProps) {
    const {
        open,
        selectedFacilityRiskAssessmentFields,
        selectedId,
        handleOperatingCostsModalCancel,
        handleOperatingCostsModalSubmit,
    } = props;

    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const selectors = {
        currentClient: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient?.currentClientEntityDetails
        ),
        saveStatus: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient.apiStatus
        ),
        errorMessage: useSelector(
            (state: RootState) => state.implementationContext.implementationSpecialistClient.errorMessage
        ),
    };
    const {
        currentClient,
        saveStatus,
        errorMessage,
    } = selectors;

    const initialCostValues: OperatingCost = {
        bedCapacityIp: (selectedFacilityRiskAssessmentFields?.bedCapacityIp === undefined || selectedFacilityRiskAssessmentFields?.bedCapacityIp === null) ? 1 : selectedFacilityRiskAssessmentFields?.bedCapacityIp,
        bedCapacityOp: (selectedFacilityRiskAssessmentFields?.bedCapacityOp === undefined || selectedFacilityRiskAssessmentFields?.bedCapacityOp === null) ? 1 : selectedFacilityRiskAssessmentFields?.bedCapacityOp,
        monthlyOperatingCostIp: (selectedFacilityRiskAssessmentFields?.monthlyOperatingCostIp === undefined || selectedFacilityRiskAssessmentFields.monthlyOperatingCostIp === null) ? 1 : selectedFacilityRiskAssessmentFields?.monthlyOperatingCostIp,
        monthlyOperatingCostOp: (selectedFacilityRiskAssessmentFields?.monthlyOperatingCostOp === undefined || selectedFacilityRiskAssessmentFields.monthlyOperatingCostOp === null) ? 1 : selectedFacilityRiskAssessmentFields?.monthlyOperatingCostOp,
        variableCostPct: selectedFacilityRiskAssessmentFields?.variableCostPct
    };

    const validationSchema = Yup.object({
        bedCapacityIp: Yup.number().positive().required("In-Patient Capacity is required"),
        bedCapacityOp: Yup.number().positive().required("Out-Patient Capacity is required"),
        monthlyOperatingCostIp: Yup.number().positive().required("In-Patient Monthly Operating Costs is required"),
        monthlyOperatingCostOp: Yup.number().positive().required("Out-Patient Monthly Operating Costs is required"),
        variableCostPct: Yup.number().positive().min(1).max(100).required("Variable Cost Percentage is required")
    });

    const operatingCostFormik = useFormik({
        initialValues: initialCostValues,
        enableReinitialize: true,
        onSubmit: () => { },
        validationSchema: validationSchema,
        validate: checkIfIsValid
    });

    function checkIfIsValid(value: {}) {
        validationSchema
            .validate(value)
            .then(() => {
                setEnableSaveButton(true);
            })
            .catch((err) => {
                setEnableSaveButton(false);
            });
    }

    async function handleSave() {
        const formValues = operatingCostFormik?.values;

        const dataToSave: OperatingCost = Utils.deepClone(formValues);
        // brand new client with no configuration set up at all
        // selecting the default client
        if(currentClient.clientId === selectedId && selectedFacilityRiskAssessmentFields == null) {
            await dispatch(createClientRiskAssessmentConfig({ clientId: currentClient?.clientId, formValues: dataToSave})); 

        // selecting a facility without a default client config
        } else if(currentClient.clientId !== selectedId && selectedFacilityRiskAssessmentFields == null) {
            await dispatch(createClientRiskAssessmentConfig({ clientId: currentClient?.clientId, formValues: dataToSave, facilityId: selectedId}));  
        }

        // client has at least the default set up
        // choosing a facility to create a new racord
        if(selectedFacilityRiskAssessmentFields?.clientFacilityId === null && selectedId !== selectedFacilityRiskAssessmentFields.clientId) {
            await dispatch(createClientRiskAssessmentConfig({ clientId: selectedFacilityRiskAssessmentFields?.clientId, formValues: dataToSave, facilityId: selectedId}));

        // updating existing condfig
        } else if(selectedFacilityRiskAssessmentFields?.clientPatientEpisodeMarginId) {
            await dispatch(updateClientRiskAssessmentConfig({marginId: selectedFacilityRiskAssessmentFields.clientPatientEpisodeMarginId, formValues: dataToSave} ))
        }

    }

    function handleSaveCallback() {
        if (saveStatus === saveCallbackStatus.success) {
            handleOperatingCostsModalSubmit();
            setEnableSaveButton(false);
        } else {
            dispatch(showErrorStatus(errorMessage));
        }
        dispatch(clearStatus());
    }

    function handleCancelCallback() {
        handleOperatingCostsModalCancel();
    }

    return (
        <Dialog
            scroll="body"
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle>
                <span className="title">Edit Facility Costs</span>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={operatingCostFormik.handleSubmit}>
                    <Grid container spacing={2} direction="row">
                        <Grid xs={6} item>
                            <TextField
                                error={
                                    Boolean(operatingCostFormik.touched["bedCapacityIp"] &&
                                        operatingCostFormik.errors["bedCapacityIp"]
                                        ? operatingCostFormik.errors["bedCapacityIp"]
                                        : "")
                                }
                                helperText={
                                    operatingCostFormik.touched["bedCapacityIp"] &&
                                        operatingCostFormik.errors["bedCapacityIp"]
                                        ? operatingCostFormik.errors["bedCapacityIp"]
                                        : ""
                                }
                                label="In-Patient Capacity (# Beds)"
                                name="bedCapacityIp"
                                type="number"
                                value={operatingCostFormik.values.bedCapacityIp}
                                onChange={operatingCostFormik.handleChange}
                                onBlur={operatingCostFormik.handleBlur}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment disableTypography position="start">
                                            <AirlineSeatFlatIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField
                                error={
                                    Boolean(operatingCostFormik.touched["monthlyOperatingCostIp"] &&
                                        operatingCostFormik.errors["monthlyOperatingCostIp"]
                                        ? operatingCostFormik.errors["monthlyOperatingCostIp"]
                                        : "")
                                }
                                helperText={
                                    operatingCostFormik.touched["monthlyOperatingCostIp"] &&
                                        operatingCostFormik.errors["monthlyOperatingCostIp"]
                                        ? operatingCostFormik.errors["monthlyOperatingCostIp"]
                                        : ""
                                }
                                label=" In-Patient Monthly Operating Costs"
                                name="monthlyOperatingCostIp"
                                type="number"
                                value={operatingCostFormik.values.monthlyOperatingCostIp}
                                onChange={operatingCostFormik.handleChange}
                                onBlur={operatingCostFormik.handleBlur}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment disableTypography position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField
                                error={
                                    Boolean(operatingCostFormik.touched["bedCapacityOp"] &&
                                        operatingCostFormik.errors["bedCapacityOp"]
                                        ? operatingCostFormik.errors["bedCapacityOp"]
                                        : "")
                                }
                                helperText={
                                    operatingCostFormik.touched["bedCapacityOp"] &&
                                        operatingCostFormik.errors["bedCapacityOp"]
                                        ? operatingCostFormik.errors["bedCapacityOp"]
                                        : ""
                                }
                                label="Out-Patient Capacity (# Patients)"
                                name="bedCapacityOp"
                                type="number"
                                value={operatingCostFormik.values.bedCapacityOp}
                                onChange={operatingCostFormik.handleChange}
                                onBlur={operatingCostFormik.handleBlur}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment disableTypography position="start">
                                            <GroupsIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField
                                error={
                                    Boolean(operatingCostFormik.touched["monthlyOperatingCostOp"] &&
                                        operatingCostFormik.errors["monthlyOperatingCostOp"]
                                        ? operatingCostFormik.errors["monthlyOperatingCostOp"]
                                        : "")
                                }
                                helperText={
                                    operatingCostFormik.touched["monthlyOperatingCostOp"] &&
                                        operatingCostFormik.errors["monthlyOperatingCostOp"]
                                        ? operatingCostFormik.errors["monthlyOperatingCostOp"]
                                        : ""
                                }
                                label=" Out-Patient Monthly Operating Costs"
                                name="monthlyOperatingCostOp"
                                type="number"
                                value={operatingCostFormik.values.monthlyOperatingCostOp}
                                onChange={operatingCostFormik.handleChange}
                                onBlur={operatingCostFormik.handleBlur}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment disableTypography position="start">
                                            <AttachMoneyIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid xs={6} item>
                            <TextField
                                error={
                                    Boolean(operatingCostFormik.touched["variableCostPct"] &&
                                        operatingCostFormik.errors["variableCostPct"]
                                        ? operatingCostFormik.errors["variableCostPct"]
                                        : "")
                                }
                                helperText={
                                    operatingCostFormik.touched["variableCostPct"] &&
                                        operatingCostFormik.errors["variableCostPct"]
                                        ? operatingCostFormik.errors["variableCostPct"]
                                        : ""
                                }
                                label="Variable Cost %"
                                name="variableCostPct"
                                type="number"
                                value={operatingCostFormik.values.variableCostPct}
                                onChange={operatingCostFormik.handleChange}
                                onBlur={operatingCostFormik.handleBlur}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment disableTypography position="start">
                                            <BarChartIcon />
                                        </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={enableSaveButton}
                    spinnerLeftPosition={5}
                    savebuttonText="Update"
                    saveStatus={saveStatus}
                    executeSave={handleSave}
                    handleCallbackSave={handleSaveCallback}
                    handleCallbackCancel={handleCancelCallback}
                />
            </DialogActions>
        </Dialog>

    );

}