import {Status} from '@finpay-development/shared-components';
import {Utils} from '../../../../shared/utils';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HeaderWithDetails
  from '../../../../shared/components/header-with-details';
import {
  Button,
  ButtonProps,
  CircularProgress, CircularProgressProps,
  Grid,
  SvgIconProps,
} from '@mui/material';
import {authDocDispatchMethod} from '../../../../shared/enums';
import {useState} from 'react';

interface DocumentHeaderWithDetailProps {
  doesDocumentExist: boolean;
  documentStatusForText?: string;
  documentStatusForColor?: string;
  header: string;
  resendButtons?: {
    displayEmailButton: boolean
    displaySmsButton: boolean
  };
  handleButtonOnClick: (dispatchMethod: authDocDispatchMethod) => Promise<void>;
}

const buttonProps: ButtonProps = {
  variant: 'outlined',
  color: 'inherit',
  sx: {
    height: '3rem',
    borderWidth: '2px',
    borderColor: '#828282',
    minWidth: '5rem',
    boxShadow: 3,
    margin: '0.5rem 1rem 0 0',
  },
};

const iconProps: SvgIconProps = {
  fontSize: 'small',
  color: 'inherit',
};

const circularProgressProps: CircularProgressProps = {
  size: 24,
  style: {
    left: '1.3rem',
    position: 'absolute',
    marginTop: '0.5rem',
  },
};

export const DocumentHeaderWithDetail = (props: DocumentHeaderWithDetailProps) => {

  const {
    doesDocumentExist,
    documentStatusForText,
    documentStatusForColor,
    header,
    resendButtons = {
      displayEmailButton: false,
      displaySmsButton: false,
    },
    handleButtonOnClick,
  } = props;

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isSendingSms, setIsSendingSms] = useState(false);

  return (
      <>
        <Grid item xs={6}>
          <HeaderWithDetails
              header={header}
              width="full"
              details={
                <Status
                    typographyVariant="h3"
                    statusColor={Utils.getDocumentStatusColor(doesDocumentExist,
                        doesDocumentExist ? documentStatusForColor : '')}
                    text={doesDocumentExist ?
                        documentStatusForText || '' :
                        'No documents exist'}
                />
              }
          />
        </Grid>
        {
            resendButtons?.displayEmailButton && (
                <Grid item xs={6}>
                  <Button
                      {...buttonProps}
                      disabled={isSendingEmail}
                      onClick={async () => {
                        setIsSendingEmail(true);
                        await handleButtonOnClick(authDocDispatchMethod.email);
                        setIsSendingEmail(false);
                      }}
                      children={
                        <>
                          <EmailOutlinedIcon {...iconProps}/>
                          {
                              isSendingEmail &&
                              (<CircularProgress {...circularProgressProps}/>)
                          }
                        </>
                      }
                  />
                  {
                      resendButtons?.displaySmsButton && (
                          <Button
                              {...buttonProps}
                              disabled={isSendingSms}
                              onClick={async () => {
                                setIsSendingSms(true);
                                await handleButtonOnClick(authDocDispatchMethod.sms);
                                setIsSendingSms(false);
                              }}
                              children={
                                <>
                                  <ChatBubbleIcon {...iconProps}/>
                                  {
                                      isSendingSms &&
                                      (<CircularProgress {...circularProgressProps}/>)
                                  }
                                </>
                              }
                          />
                      )
                  }
                </Grid>
            )
        }
      </>
  );

};