import { emptyDispute, emptyPayment, emptyPaymentReversal, Payment, PaymentDispute, PaymentReversal } from "./payment";
// todo: will need to get updated once new Transaction shape comes back from API
export interface Transaction {
  payment: Payment,
  paymentReversal: PaymentReversal,
  paymentDispute?: PaymentDispute
}

export const emptyTransaction: Transaction = {
  payment: emptyPayment,
  paymentReversal: emptyPaymentReversal,
  paymentDispute: emptyDispute,
}
