import React from "react";
import { Grid, Paper, Box, Typography } from "@mui/material";


function Licensing() {

  return (
      <Paper>
          <Grid container justifyContent="space-between">
              <Grid>
                  <Box style={{padding: "1.5em"}}>
                      <Typography variant="subtitle2">Licensing</Typography>
                  </Box>
              </Grid>
              <Grid item>
              </Grid>
          </Grid>
      </Paper>
  );
}

export default Licensing;
