import { PayorRisk, TimingRisk } from "../../shared/model/timing-and-payor-risk";

export interface Rule {
  ruleId: number;
  clientId: number;
  facilityId: number;
  ruleName?: string;
  timingRisk: TimingRisk;
  payorRisk: PayorRisk;
  availableTerms: string; // aka approvedTerms in the UI
  optimalTerm: string;
  ruleCriteria: RuleCriteria[];
  minDownPmt: number;
  minDownPmtType: "%" | "$";
  optimalDownPmt: number;
  optimalDownPmtType: "%" | "$";
  sortOrder: number;
  isActive: boolean;
  isDefaultRule: boolean;
}

export interface EditRule {
  ruleEditView: RuleEditViewModel;
  fullRule: Rule;
}

// for output in UI (i.e. tables)
export interface RuleTableViewModel {
  ruleId: number;
  timingRisk: string;
  payorRisk: string;
  condition: string;
  minDown: string;
  optimalDown: string;
  monthTerms: string;
  optimalTerm: string;
  sortOrder: number;
}

// View Model for Add/Edit form in modal
export interface RuleEditViewModel {
  ruleId: number;
  timingRisk: number;
  payorRisk: number;
  availableTerms: string; // aka approvedTerms in the UI
  optimalTerm: string;
  isDefaultRule?: boolean;
  ruleCriteria: {
    paramName: number;
    comparator: string;
    paramValue: string;
    param?: {
      dataType: string;
      compareBy: string;
    }
  }[];
  minDownPmt: string; // not number so default val in form can be empty string and not 0
  minDownPmtType: "%" | "$";
  optimalDownPmt: string; // not number so default val in form can be empty string and not 0
  optimalDownPmtType: "%" | "$";
  sortOrder: number;
}

export type RuleSet = RuleTableViewModel[][];
export class RuleCriteria {
  ruleCriteriaId!: number;
  param!: RuleCriteriaParam;
  comparator!: string;
  paramValue!: string;
  sortOrder!: number;
}

// This is essentially a 'formula'
export class RuleCriteriaParam {
  paramId!: number;
  paramName!: string;
  paramPropertyName!: string;
  dataType!: string;
  compareBy!: string | number;
  isList!: boolean;
  sortOrder!: number;
}

export interface ruleCriteria {
  paramName: number;
  comparator: string;
  paramValue: string;
}

export function getBlankRuleCriteriaParam(): RuleCriteriaParam {
  return {
    paramId: 0,
    paramName: "",
    dataType: "",
    compareBy: -1,
    isList: false,
    paramPropertyName: "",
    sortOrder: 1
  };
}

export function getBlankRule(): Rule {
  return {
    ruleId: 0,
    clientId: 0,
    facilityId: 0,
    ruleName: "",
    timingRisk: {
      timingRiskId: 0,
      timingRiskName: "",
    },
    payorRisk: {
      payorRiskId: 0,
      payorRiskName: "",
    },
    ruleCriteria: [
      {
        ruleCriteriaId: 0,
        param: getBlankRuleCriteriaParam(),
        comparator: "=",
        paramValue: "",
        sortOrder: 1,
      },
    ],
    minDownPmt: 0,
    minDownPmtType: "%",
    optimalDownPmt: 0,
    optimalDownPmtType: "%",
    availableTerms: "",
    optimalTerm: "",
    sortOrder: 0,
    isActive: true,
    isDefaultRule: true
  };
}

export function getDefaultRuleEdit(): RuleEditViewModel {
  return {
    ruleId: 0,
    timingRisk: -1, // selects 'placeholder' first value of dropdown
    payorRisk: -1, // selects 'placeholder' first value of dropdown
    availableTerms: "",
    optimalTerm: "",
    isDefaultRule: true,
    ruleCriteria: [
      {
        paramName: -1, // selects 'placeholder' first value of dropdown
        comparator: "",
        paramValue: "",
        param: {
          dataType: "",
          compareBy: "",
        }
      },
    ],
    minDownPmt: "",
    minDownPmtType: "$",
    optimalDownPmt: "",
    optimalDownPmtType: "$",
    sortOrder: +(new Date().valueOf().toString().slice(-5)), // number will guarantee it will be be higher than other rules, so will display at end of the list
  };
}

export function getDefaultRuleCriteria():ruleCriteria{
  return{
    paramName: -1, // selects 'placeholder' first value of dropdown
    comparator: "",
    paramValue: "",
  }
}