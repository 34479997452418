import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/shared/state/root-reducer";

const useIsClientConfiguredForNewEstimate = () => {
    const vobState =  useSelector(
        (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    )
    const estimatorState = useSelector(
        (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    )
    const clientMap = useSelector((state: RootState) => {
        return state.implementationContext.implementationSpecialistClient.allClientsMap
    })
      
    const currentClientId = estimatorState.client.clientId ? estimatorState?.client?.clientId : vobState?.client?.clientId
      
    const isClientConfiguredForNewEstimates = useMemo(() => {
        if(currentClientId) {
            const currentClient = clientMap.get(currentClientId) 
    
            if(currentClient) return currentClient.convRunNum === 76 
            return false
        }
    
    }, [currentClientId])

    return true 
}

export default useIsClientConfiguredForNewEstimate