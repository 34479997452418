// actual api response shape (from Implementation Specialist facility)

import { Rule, RuleSet } from "../../../../../admin-configuration/models/rule"
import { Workflow } from "../../../../../shared/model/client"
import { emptyExternalAccount, ExternalAccount } from "../../../../../shared/model/external-account"
import { emptyPrimaryAddress, PrimaryAddress } from "../../../../../shared/model/primary-address"
import { emptyPrimaryContact, PrimaryContact } from "../../../../../shared/model/primary-contact"
import { CustomConfig } from "../../../../models/custom-config"
import { emptyFacilityKPI, FacilityKPI } from "../../../../models/kpi"
import { PayOutFrequency } from "../../../../models/payOutFreq"

export interface ExternalAccountItem {
  externalId: string;
  externalIdFacilityName: string;
}

export interface ClientConnectAccounts {
  finpass: ExternalAccountItem[],
  webpayment: ExternalAccountItem[]
}

// full Facility returned from API
export interface ImplementationFacility {
  clientId: number;
  facilityId: number;
  facilityName: string;
  outpatientEnabled: boolean;
  isRuleSetupComplete: boolean;
  websiteUrl: string;
  patientPmtFreq: string;
  payOutFreq: PayOutFrequency;
  payOutDay: number;
  customDocTokenId?: string;
  primaryContact: PrimaryContact;
  address: PrimaryAddress;
  phoneNum: string;
  taxId: string;
  taxIdLast4: string;
  facilityType: string;
  externalAccount: ExternalAccount;
  workflow: Workflow;
  rulesGrouped: RuleSet; // not part of API. Injected into each facility when going to Risk Rules tab
  rulesUngrouped: Rule[]; // utility key to help with updating UI after add/edit rule (Risk Rules tab)
  facilityKPIs?: FacilityKPI;
  vboTimeThreshold?: number;
  vboPfrThreshold?: number;
  paynowExternalAccount: ExternalAccount;
  referenceNumberName: string;
  referenceNumberHelpText: string;
  bannerText: string;
  customConfigs: CustomConfig[],
  monthlyOperatingCostIp: number | undefined,
  monthlyOperatingCostOp: number | undefined,
  bedCapacityIp: number | undefined,
  bedCapacityOp: number | undefined
}


export const emptyImplementationFacility: ImplementationFacility = {
  clientId: 0,
  facilityId: 0,
  facilityName: "",
  outpatientEnabled: false,
  isRuleSetupComplete: false,
  websiteUrl: "",
  patientPmtFreq: "",
  payOutFreq: "daily",
  payOutDay: -1,
  customDocTokenId: "",
  primaryContact: emptyPrimaryContact,
  address: emptyPrimaryAddress,
  phoneNum: "",
  taxId: "",
  taxIdLast4: "",
  facilityType: "",
  vboTimeThreshold: 0,
  vboPfrThreshold: 0,
  externalAccount: emptyExternalAccount,
  workflow: {
    workflowId: 0,
    workflowName: "",
    workflowStatus: {
      workflowStatusId: 0,
      workflowStatusDesc: "",
    },
    workflowSubStatus: {
      workflowSubStatusId: 0,
      workflowSubStatusDesc: "",
    },
  },
  rulesGrouped: [],
  rulesUngrouped: [],
  facilityKPIs: emptyFacilityKPI,
  paynowExternalAccount: emptyExternalAccount,
  referenceNumberName: "",
  referenceNumberHelpText: "",
  bannerText: "",
  customConfigs: [],
  monthlyOperatingCostIp: 0,
  monthlyOperatingCostOp: 0,
  bedCapacityIp: 0,
  bedCapacityOp: 0
}

export interface FacilityType {
  clientFacilityId: number,
  facilityName: string,
  clientId: number,
  patientPmtFreq: string,
  payoutFreq: string,
  payoutDay: string,
  facilityType: string,
  addressId: number,
  websiteUrl: string,
  phoneNum: string,
  primaryContactId: number,
  taxId: string,
  taxIdLast4: string,
  randomUuid: string,
  workflowId: number,
  workflowStatusId: number,
  workflowSubStatusId: number,
  isRuleSetupComplete: boolean,
  sourceProviderId: string,
  convRunNum: number,
  customDocTokenId?: string,
  rcOutOfNetworkPFRHigh: number | null,
  rcOutOfNetworkPFRLow: number | null,
  rcInNetworkPFRHigh: number | null,
  rcInNetworkPFRLow: number | null,
  vboTimeThreshold?: number | null,
  vboPfrThreshold?: number | null
}

export const emptyFacilityType: FacilityType = {
  clientFacilityId: -1,
  facilityName: "",
  clientId: -1,
  patientPmtFreq: "",
  payoutFreq: "",
  payoutDay: "",
  facilityType: "",
  addressId: -1,
  websiteUrl: "",
  phoneNum: "",
  primaryContactId: -1,
  taxId: "",
  taxIdLast4: "",
  randomUuid: "",
  workflowId: -1,
  workflowStatusId: -1,
  workflowSubStatusId: -1,
  isRuleSetupComplete: false,
  sourceProviderId: "",
  convRunNum: -1,
  customDocTokenId: "",
  rcOutOfNetworkPFRHigh: null,
  rcOutOfNetworkPFRLow: null,
  rcInNetworkPFRHigh: null,
  rcInNetworkPFRLow: null,
  vboTimeThreshold: null,
  vboPfrThreshold: null
}

export interface ClientFacilitySelected {
  clientId: number,
  facilityId: number,
  facilityName: string,
  checked: boolean,
}

export interface FacilitiesInfo {
  clientFacilitiesList: ClientFacilitySelected[]
}

export const emptyFacilitiesInfo: FacilitiesInfo = {
  clientFacilitiesList: []
}

export interface OperatingCost {
  monthlyOperatingCostIp: number | undefined,
  monthlyOperatingCostOp: number  | undefined,
  bedCapacityIp: number  | undefined,
  bedCapacityOp: number  | undefined,
  variableCostPct: number
}