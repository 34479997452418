import { emptyState, State } from "./state";

export interface PrimaryAddress {
  addressId?: number,
  streetAddress1: string,
  streetAddress2: string,
  city: string,
  state: State,
  zipCode: string,
  zipCode4?: string,
  addressType: string,
}

export const emptyPrimaryAddress: PrimaryAddress = {
  addressId: 0,
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  state: emptyState,
  zipCode: '',
  zipCode4: '',
  addressType: '',
}
