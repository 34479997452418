import { UserRoleViewModel } from "../../security/model/user-role";
import { RoleTypes } from "../models/role-types";

// Hard coded external User Roles. NOT mock data
export const externalUserRoles: UserRoleViewModel[] = [
    {
      userRoleId: 2,
      roleName: "Client Owner",
      roleDesc: "Users with Client Owner Role",
      roleType: RoleTypes.External,
      isActive: true,
      navigation: [
        {
          pageName: "Dashboard",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
        {
          pageName: "Facilities",
          accessRights: "Edit",
          actions: [
            {
              value: "Complete Pay To",
            },
          ],
        },
        {
          pageName: "Transactions",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
        {
          pageName: "Reports",
          accessRights: "N/A",
          actions: [
            {
              value: "All Instance of Care",
            },
            {
              value: "Patient Financial Clearance",
            },
            {
              value: "Payment Programs",
            },
            {
              value: "Settlement Reports",
            },
          ],
        },
      ],
    },
    {
      userRoleId: 3,
      roleName: "Client Analyst",
      roleDesc: "User Handling Client level Analyst activities",
      roleType: RoleTypes.External,
      isActive: true,
      navigation: [
        {
          pageName: "Dashboard",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
        {
          pageName: "Facilities",
          accessRights: "Read Only",
          actions: [
            {
              value: "- -",
            },
          ],
        },
        {
          pageName: "Transactions",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
        {
          pageName: "Reports",
          accessRights: "N/A",
          actions: [
            {
              value: "All Instance of Care",
            },
            {
              value: "Patient Financial Clearance",
            },
            {
              value: "Payment Programs",
            },
            {
              value: "Settlement Reports",
            },
          ],
        },
      ],
    },
    {
      userRoleId: 4,
      roleName: "Account Holder",
      roleDesc: "User Role for Patients or Patient Guarantors",
      roleType: RoleTypes.External,
      isActive: true,
      navigation: [
        {
          pageName: "Active Programs",
          accessRights: "N/A",
          actions: [
            {
              value: "Make Payment",
            },
          ],
        },
        {
          pageName: "Completed Programs",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
        {
          pageName: "Canceled Programs",
          accessRights: "N/A",
          actions: [
            {
              value: "N/A",
            },
          ],
        },
      ],
    },

  //comment back in once below user roles are categorized as External

  // {
  //   userRoleId: 8,
  //   roleName: "Client CRM Agent",
  //   roleDesc: "User Role for Client CRM Agents",
  //   roleType: RoleTypes.External,
  //   isActive: true,
  //   navigation: [
  //     {
  //       pageName: "Reports",
  //       accessRights: "Read Only",
  //       actions: [
  //         {
  //           value: "- -",
  //         }
  //       ],
  //     },
  //     {
  //       pageName: "Patient Records",
  //       accessRights: "Edit",
  //       actions: [
  //         {
  //           value: "Add New Account",
  //         },
  //         {
  //           value: "Add Notes",
  //         },
  //         {
  //           value: "Take a Payment",
  //         },
  //         {
  //           value: "Send Ledger",
  //         },
  //         {
  //           value: "Change Status",
  //         },
  //         {
  //           value: "Convert Account",
  //         },
  //         {
  //           value: "Add Instance of Care",
  //         },
  //         {
  //           value: "Apply Payment Program",
  //         },
  //       ],
  //     },
  //     {
  //       pageName: "Admissions Advisor",
  //       accessRights: "Read-Only",
  //       actions: [
  //         {
  //           value: "- -",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   userRoleId: 9,
  //   roleName: "Client Facility Financial Counselor",
  //   roleDesc: "User Role for Client Facility Financial Counselor",
  //   roleType: RoleTypes.External,
  //   isActive: true,
  //   navigation: [
  //     {
  //       pageName: "Reports",
  //       accessRights: "Read Only",
  //       actions: [
  //         {
  //           value: "- -",
  //         }
  //       ],
  //     },
  //     {
  //       pageName: "Patient Records",
  //       accessRights: "Edit",
  //       actions: [
  //         {
  //           value: "Add New Account",
  //         },
  //         {
  //           value: "Add Notes",
  //         },
  //         {
  //           value: "Take a Payment",
  //         },
  //         {
  //           value: "Send Ledger",
  //         },
  //         {
  //           value: "Change Status",
  //         },
  //         {
  //           value: "Convert Account",
  //         },
  //         {
  //           value: "Add Instance of Care",
  //         },
  //         {
  //           value: "Apply Payment Program",
  //         },
  //       ],
  //     },
  //     {
  //       pageName: "Admissions Advisor",
  //       accessRights: "Read-Only",
  //       actions: [
  //         {
  //           value: "- -",
  //         },
  //       ],
  //     },
  //   ],
  // }
  ];