import { DialogActionButton } from "@finpay-development/shared-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import React from "react";
import { useSelector } from "react-redux";
import { formatNumberToUSD } from "../../../shared/calculators";
import { FacilityRiskAssessment } from "../../models/risk-assessment";
import { RootState } from "../../../shared/state/root-reducer";

interface RiskOverwriteEstimateModalProps {
  open: boolean;
  currentRiskAssessment: FacilityRiskAssessment;
  newRiskAssessment: FacilityRiskAssessment;
  handleOverwriteEstimateModalCancel: () => void;
  handleOverwriteEstimateModalSubmit: () => void;
}

export function RiskOverwriteEstimateModal(
  props: RiskOverwriteEstimateModalProps
) {
  const {
    open,
    currentRiskAssessment,
    newRiskAssessment,
    handleOverwriteEstimateModalCancel,
    handleOverwriteEstimateModalSubmit,
  } = props;

  const saveStatus = useSelector(
    (state: RootState) =>
      state.adminContext.adminConfigurationContext.modalSaveStatus
  );

  function handleSave() {
    handleOverwriteEstimateModalSubmit();
  }

  function handleCancelCallback() {
    handleOverwriteEstimateModalCancel();
  }

  return (
    <Dialog
      className="modal"
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle sx={{ backgroundColor: "#E8DB47" }}>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item direction="column" md={12} alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className="my-2"
            >
              <WarningIcon
                sx={{
                  color: "#E8DB47",
                  fontSize: "38pt",
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              />
            </Box>
            <p>
              By selecting this facility option, you will change your working
              PFR Estimate from{" "}
              <strong>{currentRiskAssessment.facilityName}</strong>, PFR:{" "}
              <strong>{formatNumberToUSD(currentRiskAssessment.pfr)}</strong> to{" "}
              <strong>{newRiskAssessment.facilityName}</strong>, PFR:{" "}
              <strong>{formatNumberToUSD(newRiskAssessment.pfr)}</strong>.
            </p>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={true}
          savebuttonText="Change"
          saveStatus={saveStatus}
          spinnerLeftPosition={5}
          executeSave={handleSave}
          handleCallbackCancel={handleCancelCallback}
        />
      </DialogActions>
    </Dialog>
  );
}
