import { Button, LoadingOverlay } from '@finpay-development/shared-components';
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { showStatus } from '../../../security/state/user-slice';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { RuleCriteriaParam } from '../../models/rule';
import { resetFormulaForm } from '../../state/admin-configuration-slice';
import { getRuleCriteriaParams } from '../../state/admin-configuration-thunk';
import Formula from './formula';
import { FormulaModal } from './formula-modal';

function ParameterSettingView() {
  const [showFormulaModal, setShowFormulaModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const stateFields = {
    ruleCriteriaParams: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext.allRuleCriteriaParams
    ),
    isLoading: useSelector(
      (state: RootState) =>
        state.adminContext.adminConfigurationContext.isLoading.isLoadingRules
    )
  }

  function handleFormulaModalCancel() {
    setShowFormulaModal(false);
    dispatch(resetFormulaForm());
  }

  function handleFormulaModalSubmit(isEditMode: boolean) {
    setShowFormulaModal(false);
    dispatch(resetFormulaForm());

    if (isEditMode) {
      dispatch(showStatus("Formula Updated"));
    } else {
      dispatch(showStatus("Formula created"));
    }
  }

  useEffect(() => {
    dispatch(getRuleCriteriaParams());
  }, [dispatch])

  const ruleView = (ruleCriteriaParam: RuleCriteriaParam, index: number) => {
    return (
      <Formula
        key={ruleCriteriaParam.paramId}
        name={`Formula ${index + 1}`}
        ruleCriteriaParam={ruleCriteriaParam}
      />
    )
  }

  return (
    <div className="parameter-setting">
      <Grid container justifyContent="flex-end" className="mb-6">
        <Grid item>
          <AccessControl rolePageName={RolePageNames.Configuration} actionName="Add New Formula"
          renderNoAccess={() => <Button disabled>Add New Formula</Button>}>
          <Button onClick={() => setShowFormulaModal(true)}>Add New Formula</Button>
        </AccessControl>
        </Grid>
      </Grid>
      {stateFields.isLoading ? <LoadingOverlay /> :
      stateFields.ruleCriteriaParams && stateFields.ruleCriteriaParams.length &&
      (stateFields.ruleCriteriaParams.length > 0) &&
      stateFields.ruleCriteriaParams.map(
        (ruleCriteriaParam, index) => ruleView(ruleCriteriaParam, index))
      }
      {showFormulaModal && (
        <FormulaModal
          open={showFormulaModal}
          handleFormulaModalCancel={handleFormulaModalCancel}
          handleFormulaModalSubmit={handleFormulaModalSubmit}
        />
      )}
    </div>
  );
}

export default ParameterSettingView;
