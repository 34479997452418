import {
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { formatNumberToUSD } from "../../../../shared/calculators";
import { PFRSummary } from "../../../models/estimator";

interface EstSummaryModalCrossoverProps {
  currentYearPFR: PFRSummary | undefined;
  crossOverPFR: PFRSummary | undefined;
  selectedPFRName?: string;
  financialSummary?: PFRSummary;
  setSelectedPFRName?: (selectedPFRName: string) => void;
  setSelectedPFRAmount?: (selectedPFRAmount: number) => void;
}

export function EstSummaryModalCrossover(props: EstSummaryModalCrossoverProps) {
  const { currentYearPFR, crossOverPFR, selectedPFRName, financialSummary, setSelectedPFRName, setSelectedPFRAmount } = props;

  return (
    <>
      <Grid
          container
          direction="row"
          spacing={2}
          sx={{ padding: "14px", display: "flex", border: "1px solid #bebebe", flexDirection: "column", borderRadius: "6px", marginTop: "0px", marginBottom: "20px" }}
      >
        <Grid style={{ display: "flex" }}>
          <Grid style={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{paddingLeft: "8px", color: "#297ec1"}}>Patient Financial Responsibility</Typography>
            <Typography variant="subtitle2" style={{fontSize: "14px", padding: "0px 0px 0px 8px", marginTop: "-4px", fontStyle: "italic" }}>Days before Crossover</Typography>
          </Grid>
          <FormControlLabel
            checked={selectedPFRName === "TotalPFR"}
            control={<Radio color="primary" />}
            name="selectPFR"
            label=""
            labelPlacement="start"
            style={{ float: "right", paddingRight: "10px" }}
            onChange={() => {
              setSelectedPFRName!("TotalPFR");
              setSelectedPFRAmount!(financialSummary?.totalPFR!);
            }}
          />
        </Grid>
        <TableContainer>
          <Table className="table alternating-row normal-pad">
            <TableBody>
              <TableRow>
                <TableCell>Estimated Adjusted Charges</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.coveredCharges!)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Length of Stay</TableCell>
                <TableCell align="right">
                  {currentYearPFR?.lengthOfStay}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Deductible</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.deductibleSpent)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Co-Insurance</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.patientTotalCoInsurance)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Co-Pay</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.patientTotalCoPays)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out of Pocket Max Remaining</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.oopMaxFromVob)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PFR for Covered Charges</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.coveredSvcsPfr)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>PFR for Uncovered Charges</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(currentYearPFR?.unCoveredCharges)}
                </TableCell>
              </TableRow>
              {currentYearPFR?.totalSurcharges! > 0 && (
                <>
                  <TableRow className="py-3">
                    <TableCell>PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(currentYearPFR?.subtotalPFR)}
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(currentYearPFR?.totalSurcharges)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow style={{ backgroundColor: "#E3E7EC" }}>
                <TableCell><strong>Estimated Patient Financial Responsibility</strong></TableCell>
                <TableCell align="right">
                <strong>{formatNumberToUSD(currentYearPFR?.totalPFR)}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid
          container
          direction="row"
          spacing={2}
          sx={{padding: "14px", display: "flex", border: "1px solid #bebebe", flexDirection: "column", borderRadius: "6px", marginTop: "0px", marginBottom: "0px"}}
      > 
        <Grid style={{ display: "flex" }}>
          <Grid style={{ flex: 1 }}>
            <Typography variant="subtitle2" style={{paddingLeft: "8px", color: "#297ec1"}}>Patient Financial Responsibility</Typography>
            <Typography variant="subtitle2" style={{fontSize: "14px", padding: "0px 0px 0px 8px", marginTop: "-4px", fontStyle: "italic"}}>with Crossover</Typography>
          </Grid>
          <FormControlLabel
            checked={selectedPFRName === "AdjustedPFR"}
            control={<Radio color="primary" />}
            name="selectPFR"
            label=""
            labelPlacement="start"
            style={{ float: "right", paddingRight: "10px" }}
            onChange={() => {
              setSelectedPFRName!("AdjustedPFR");
              setSelectedPFRAmount!(
                currentYearPFR?.totalPFR! + crossOverPFR?.totalPFR!
              );
            }}
          />
        </Grid>
        <TableContainer>
          <Table className="table alternating-row normal-pad">
            <TableBody>
            <TableRow>
              <TableCell>Estimated Adjusted Charges</TableCell>
              <TableCell align="right">
                {formatNumberToUSD(
                  currentYearPFR?.coveredCharges! +
                    crossOverPFR?.coveredCharges!
                )}
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell>Estimated Length of Stay</TableCell>
                <TableCell align="right">
                  {currentYearPFR?.lengthOfStay! + crossOverPFR?.lengthOfStay!}
                </TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell>Estimated Deductible</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    currentYearPFR?.deductibleSpent! + crossOverPFR?.deductibleSpent!
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Co-Insurance</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    currentYearPFR?.patientTotalCoInsurance! +
                      crossOverPFR?.patientTotalCoInsurance!
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Estimated Co-Pay</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    currentYearPFR?.patientTotalCoPays! +
                      crossOverPFR?.patientTotalCoPays!
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Out of Pocket Max Remaining</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                      crossOverPFR?.oopMaxFromVob!
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
              <TableCell>PFR for Covered Services</TableCell>
              <TableCell align="right">
                {formatNumberToUSD(
                  currentYearPFR?.coveredSvcsPfr! +
                    crossOverPFR?.coveredSvcsPfr!
                )}
              </TableCell>
            </TableRow>
              <TableRow>
                <TableCell>PFR for Uncovered Charges</TableCell>
                <TableCell align="right">
                  {formatNumberToUSD(
                    currentYearPFR?.unCoveredCharges! +
                      crossOverPFR?.unCoveredCharges!
                  )}
                </TableCell>
              </TableRow>
              {currentYearPFR?.totalSurcharges! > 0 && (
                <>
                  <TableRow className="py-3">
                    <TableCell>PFR Subtotal</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(currentYearPFR?.subtotalPFR! + crossOverPFR?.subtotalPFR!)} 
                    </TableCell>
                  </TableRow>
                  <TableRow className="py-3">
                    <TableCell>Admission Assessment</TableCell>
                    <TableCell align="right">
                      {formatNumberToUSD(currentYearPFR?.totalSurcharges! + crossOverPFR?.totalSurcharges!)}
                    </TableCell>
                  </TableRow>
                </>
              )}
              <TableRow style={{ backgroundColor: "#E3E7EC" }}>
                <TableCell>
                  <strong>Estimated Patient Financial Responsibility</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>
                    {formatNumberToUSD(
                      currentYearPFR?.totalPFR! + crossOverPFR?.totalPFR!
                    )}
                  </strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
