import "../../../scss/components/_reports.scss";
import {
  Checkbox,
  DatePicker,
  Button,
  TextField, Toggle,
} from '@finpay-development/shared-components';
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, MenuItem, Typography} from '@mui/material';
import { Form, Formik } from "formik";
import React, {useState, useEffect, useMemo} from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Filters } from "../../../implementation-specialist/models/reportsFilters";
import { userRoles } from "../../../shared/enums";
import {
  reportsType,
  restrictedReportsType,
  addedReportsType,
} from "../../../shared/Mocks/reportsType";
import { RootState } from "../../../shared/state/root-reducer";
import { AppDispatch } from "../../../shared/state/store";
import { commonFields } from "../../../shared/validation/schemas";
import {
  clearReportInfo,
  setReportFilters,
} from "../../state/users/admin-slice";
import { Utils } from "../../../shared/utils";
import { CustomFormikProps } from "../../../shared/model/formik-props";
import {
  ClientAndFacilitiesChecked,
} from "../../../implementation-specialist/models/reportsFilters";
import { setClientAndFacilityData } from "../../state/users/admin-slice";
import { showErrorStatus } from "../../../security/state/user-slice";
import { UserRolePage } from "../../../security/model/user-role-page";
import ClientFacilityCheckbox from '../common/client-facility-checkbox';
import {UserInfoClient} from '../../models/user-info-client';

interface ReportsFilterProps {
  handleFilterReportType: (reportsFilters: Filters) => void;
}

function AdminReports(props: ReportsFilterProps) {
  const { handleFilterReportType } = props;
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = Yup.object({
    startDate: commonFields.OPTIONAL_DATE,
    endDate: commonFields.OPTIONAL_DATE,
    reportsType: commonFields.REQUIRED_DROPDOWN,
    admissionDate: commonFields.OPTIONAL_DATE,
    dischargeDate: commonFields.OPTIONAL_DATE,
    patientName: commonFields.PATIENT_NAME_OPT,
  });

  const stateFields = {
    reportFilters: useSelector(
      (state: RootState) => state.adminContext.adminUserContext.reportFilters
    ),
    userRoleName: useSelector(
      (state: RootState) => state.userContext.userProfile.userRole.roleName
    ),
    userRolePages: useSelector(
      (state: RootState) => state.userContext.userProfile.userRole.userRolePages
    ),
    allowedClients: useSelector(
      (state: RootState) => state.userContext.userProfile.allowedClients
    ),
    allClientswithFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .allClientsWithFacillities!
    ),
    allClientsWithFacilitiesMap: useSelector(
        (state:RootState) => state.implementationContext.implementationSpecialistClient
            .allClientsWithFacilitiesMap
    )
  };

  const {
    reportFilters,
    userRolePages,
    userRoleName,
    allowedClients,
    allClientsWithFacilitiesMap
  } = stateFields;

  useEffect(() => {
    async function setData() {
      await dispatch(setClientAndFacilityData(allowedClients));
    }
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let initialValues = {
    startDate: reportFilters.startDate,
    endDate: reportFilters.endDate,
    reportType: reportFilters.reportType,
    admissionDate: reportFilters.admissionDate,
    dischargeDate: reportFilters.dischargeDate,
    patientName: reportFilters.patientName,
    clientId: reportFilters.clientId,
    facilityId: reportFilters.facilityId,
    clientsAndFacilities: {} as Record<string, UserInfoClient>,
    selectAllClients: false,
    includeFacilityTotals: reportFilters.includeFacilityTotals,
  };

  const handleClick = (formik: CustomFormikProps) => {
    const reportsFilters: Filters = formik.values;
    let clientAndFacilityIdsChecked: ClientAndFacilitiesChecked = {
      clientId: [],
      facilityId: [],
    };

    if(formik.values.selectAllClients){
      Array.from(filteredClientsAndFacilitiesMap.values()).forEach((client) => {
        if(client.clientFacilities!.length > 0){
          clientAndFacilityIdsChecked.clientId.push(client.clientId);
          client.clientFacilities!.forEach((facility)=>{
            clientAndFacilityIdsChecked.facilityId.push(facility.facilityId)
          })
        }
      })
    }else{
      Object.values(reportsFilters.clientsAndFacilities as Record<string, UserInfoClient>)
      .forEach((client: UserInfoClient)=>{
        if(client.allowedFacilities.length > 0) {
          clientAndFacilityIdsChecked.clientId.push(client.clientId);
          client.allowedFacilities.forEach((facility) => {
            clientAndFacilityIdsChecked.facilityId.push(facility.facilityId)
          })
        }
      })
    }

    const submittedReportFilters: Filters = {
      reportType: reportsFilters.reportType,
      startDate: reportsFilters.startDate,
      endDate: reportsFilters.endDate,
      admissionDate: reportsFilters.admissionDate,
      dischargeDate: reportsFilters.dischargeDate,
      patientName: reportsFilters.patientName,
    };

    if (reportsFilters.reportType === addedReportsType[0]) {

      if (clientAndFacilityIdsChecked.clientId.length ===0 && clientAndFacilityIdsChecked.facilityId.length ===0) {
        dispatch(showErrorStatus("No client and facilities selected"));
        return false;
      }

      Object.assign(submittedReportFilters, {
        clientId: clientAndFacilityIdsChecked.clientId,
        facilityId: clientAndFacilityIdsChecked.facilityId,
        includeFacilityTotals: reportsFilters.includeFacilityTotals,
      });

    }

    if (
      reportsFilters.reportType === addedReportsType[1] ||
      reportsFilters.reportType === addedReportsType[2] ||
      reportsFilters.reportType === addedReportsType[3]
    ) {
      delete submittedReportFilters.admissionDate;
      delete submittedReportFilters.dischargeDate;
      delete submittedReportFilters.patientName;

      if (clientAndFacilityIdsChecked.clientId.length ===0 && clientAndFacilityIdsChecked.facilityId.length ===0) {
        dispatch(showErrorStatus("No client and facilities selected"));
        return false;
      }

      Object.assign(submittedReportFilters, {
        clientId: clientAndFacilityIdsChecked.clientId,
        facilityId: clientAndFacilityIdsChecked.facilityId,
      });

    }

    dispatch(
      setReportFilters({
        reportType: reportsFilters.reportType,
        startDate: reportsFilters.startDate!,
        endDate: reportsFilters.endDate!,
        admissionDate: reportsFilters.admissionDate!,
        dischargeDate: reportsFilters.dischargeDate!,
        patientName: reportsFilters.patientName!,
        clientId: submittedReportFilters.clientId!,
        facilityId: submittedReportFilters.facilityId!,
        includeFacilityTotals: reportsFilters.includeFacilityTotals!,
      })
    );
    handleFilterReportType(submittedReportFilters);
  };

  function handleResetFilters(formik: CustomFormikProps) {
    dispatch(clearReportInfo());
    setDisableButton(true);
    formik.resetForm();
  }

  async function checkIfIsValid(value: any): Promise<boolean> {
    await validationSchema.validate(value).then(() => {
      if (
        Utils.isValidDate(value?.startDate) &&
        Utils.isValidDate(value?.endDate)
      ) {
        setDisableButton(false);
      }
    });
    return true;
  }

  const dateLabel = (reportType: string) => {
    switch (reportType) {
      case addedReportsType[3]:
        return "Settlement Date";
      case reportsType[0]:
        return "Convert Date";
      default:
        return "Create Date";
    }
  }

  const handleClientAndFacilityCheckboxOnChange = (formik: any)=>{
    return (selectedClientsAndFacilities: Record<string, UserInfoClient>)=>{
      formik.setFieldValue('clientsAndFacilities', selectedClientsAndFacilities);
    }
  }

  const filteredClientsAndFacilitiesMap = useMemo(()=>{
    //filtering out clients without any facilities
    const filteredArr = Array.from(allClientsWithFacilitiesMap!.entries()).filter(([_, clientObj])=>{
      return Array.isArray(clientObj.clientFacilities) && clientObj.clientFacilities.length > 0 && clientObj.clientId !== 0
    })
    return new Map(filteredArr)
  }, [allClientsWithFacilitiesMap])

  return (
    <div>
      <Typography variant="subtitle2" className="mb-8">
        Filters
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        validate={checkIfIsValid}
        enableReinitialize
      >
        {(formik) => (
          <Form data-testid="filter-form" noValidate>
            <Grid container spacing={3}>
              <Grid xs={12} item>
                <TextField
                  select={true}
                  error=""
                  label="Report Type"
                  name="reportType"
                  value={formik.values.reportType}
                  onChange={(e: any) => {
                    if (
                      e.target.value === addedReportsType[0] ||
                      e.target.value === addedReportsType[1] ||
                      e.target.value === addedReportsType[2]
                    )
                      setDisableButton(false);
                    else setDisableButton(true);
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value="-1" disabled>
                    Select report type
                  </MenuItem>
                  {/* As per the FPS-5707 acceptance criteria,
                   we're granting Super Users access to all reports and
                   allowing all other user roles access to:
                   1) Summary Transaction Report by Payment Channel
                   2) Transaction Report
                   3) Settlement Reports
                   This will be in place until user role page actions can be utilized
                   to determine report access for different user roles. */}
                  {(userRoleName === userRoles.finpaySuperUser) ? (
                      [
                          ...reportsType.map((report: string, index) => (
                          <MenuItem key={index} value={report}>
                            {report}
                          </MenuItem>
                      )),

                          ...restrictedReportsType.map((report: string, index) => (
                                  <MenuItem key={index} value={report}>
                                    {report}
                                  </MenuItem>
                              )
                          ),

                          ...addedReportsType.map((report: any, key: number) => {
                            if (
                                key === 2 &&
                                !userRolePages.some(
                                    (userRolePage: UserRolePage) =>
                                        userRolePage.sysRolePage &&
                                        userRolePage.sysRolePage.rolePageName === "Reports"
                                )
                            ) {
                              return null;
                            }

                            return (
                                <MenuItem value={report} key={key}>
                                  {report}
                                </MenuItem>
                            );
                          })

                      ]
                  ) : (
                      [
                        <MenuItem value={addedReportsType[0]}>
                          {addedReportsType[0]}
                        </MenuItem>,
                        <MenuItem value={addedReportsType[1]}>
                          {addedReportsType[1]}
                        </MenuItem>,
                        <MenuItem value={addedReportsType[3]}>
                          {addedReportsType[3]}
                        </MenuItem>,
                      ]
                  )
                  }

                </TextField>
              </Grid>

              <Grid xs={12} item>
                <DatePicker
                  error={
                    Utils.isValidDate(formik.values.startDate) &&
                    formik.touched["startDate"] &&
                    formik.errors["startDate"]
                  }
                  label={`Start ${dateLabel(formik.values.reportType)}`}
                  value={formik.values.startDate}
                  onChange={(date: Date) => {
                    formik.setFieldValue("startDate", date);
                  }}
                  minDate={Utils.getMinDate()}
                  maxDate={Utils.getMaxDate()}
                />
              </Grid>

              <Grid xs={12} item>
                <DatePicker
                  error={
                    Utils.isValidDate(formik.values.endDate) &&
                    formik.touched["endDate"] &&
                    formik.errors["endDate"]
                  }
                  label={`End ${dateLabel(formik.values.reportType)}`}
                  value={formik.values.endDate}
                  onChange={(date: Date) => {
                    formik.setFieldValue("endDate", date);
                  }}
                  minDate={Utils.getMinDate()}
                  maxDate={Utils.getMaxDate()}
                />
              </Grid>
              {(formik.values.reportType === restrictedReportsType[1]) && (
                <>
                  <Grid xs={12} item>
                    <DatePicker
                      error={
                        Utils.isValidDate(formik.values.admissionDate) &&
                        formik.touched["admissionDate"] &&
                        formik.errors["admissionDate"]
                      }
                      label="Admission Date"
                      value={formik.values.admissionDate}
                      onChange={(date: Date) => {
                        formik.setFieldValue("admissionDate", date);
                      }}
                      minDate={Utils.getMinDate()}
                      maxDate={Utils.getMaxDate()}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <DatePicker
                      error={
                        Utils.isValidDate(formik.values.dischargeDate) &&
                        formik.touched["dischargeDate"] &&
                        formik.errors["dischargeDate"]
                      }
                      label="Discharge Date"
                      value={formik.values.dischargeDate}
                      onChange={(date: Date) => {
                        formik.setFieldValue("dischargeDate", date);
                      }}
                      minDate={Utils.getMinDate()}
                      maxDate={Utils.getMaxDate()}
                    />
                  </Grid>
                </>
              )}
              {formik.values.reportType === addedReportsType[0] && (
                <Grid item xs={12} className="mt-4">
                  <Checkbox
                    checked={formik.values.includeFacilityTotals}
                    id="includeFacilityTotals"
                    label="Show Facility Totals"
                    name="includeFacilityTotals"
                    onChange={(e: any) => {
                      formik.handleChange(e);
                      if (e.target.checked) {
                        formik.setFieldValue("includeFacilityTotals", true);
                      }
                    }}
                  />
                </Grid>
              )}
              {(formik.values.reportType === addedReportsType[0] ||
                formik.values.reportType === addedReportsType[1] ||
                formik.values.reportType === addedReportsType[2] ||
                formik.values.reportType === addedReportsType[3]) && (
                <Grid item xs={12} className="mt-4">
                  <Typography variant="h2" className="my-5">
                    Clients and Facilities
                  </Typography>
                  <Typography className="mb-2" variant="body2">
                    Select All Clients And Facilities
                  </Typography>
                  <Toggle
                      name="selectAllClients"
                      value={formik.values.selectAllClients}
                      formik={formik}
                      onChange={async ()=>{
                        await formik.setFieldValue("clientsAndFacilities", {})
                        await formik.validateForm();
                      }}
                  />
                  <div className="mt-3" style={{
                    display: formik.values.selectAllClients ? 'none' : 'block',
                    height: '30vh'
                  }}>
                  <ClientFacilityCheckbox
                      allClientsWithFacilitiesMap={filteredClientsAndFacilitiesMap!}
                      selectedClientsAndFacilitiesObj={formik.values.clientsAndFacilities}
                      handleCheckBoxOnChange={(selectedClientsAndFacilities)=>{
                        handleClientAndFacilityCheckboxOnChange(formik)(selectedClientsAndFacilities)
                      }}
                  />
                  </div>
                </Grid>
              )}
              <Grid container alignItems="center" className="mt-4 pl-6">
                <Grid>
                  <Button
                    type="text"
                    paddingLeft={0}
                    icon={<ClearIcon />}
                    onClick={() => handleResetFilters(formik)}
                  >
                    Reset Filters
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    disabled={disableButton}
                    onClick={() => handleClick(formik)}
                  >
                    Create Report
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AdminReports;
