import { useState, useRef } from 'react';
import { Form, Formik } from "formik";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DialogActionButton, saveCallbackStatus, TextField } from "@finpay-development/shared-components";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import * as Yup from "yup";
import { resendReceiptModalSchema } from "../../../../shared/validation/schemas";

interface ResendReceiptModalProps {
  open: boolean;
  receiptEmail: string;
  titleText: string;
  onClose: () => void;
  onSend: (resendReceptEmail: string) => void;
}

export default function ResendReceiptModal(props: ResendReceiptModalProps) {
  const { open, receiptEmail, titleText, onClose, onSend } = props;
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const formRef: any = useRef();

  const handleSend = () => {
    const formValues = formRef.current.values;
    onSend(formValues.resendReceiptEmail);
    onClose();
  };

  const validationSchema = Yup.object(resendReceiptModalSchema);

  function checkIfIsValid(value: {}) {
    validationSchema
      .validate(value)
      .then(() => {
        setEnableSaveButton(true);
      })
      .catch(() => {
        setEnableSaveButton(false);
      });
  }

  const initialValuesEdit = {
    resendReceiptEmail: receiptEmail,
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="modal client-modal"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span className='title'>
          {titleText}
        </span>
        <NotificationsNoneIcon fontSize="large"/>
      </DialogTitle>
      <DialogContent>
        <Formik
          innerRef={formRef}
          initialValues={initialValuesEdit}
          validationSchema={validationSchema}
          validate={checkIfIsValid}
          onSubmit={() => { }}
        >
          {(formik) => {
            return (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={
                        formik.touched["resendReceiptEmail"] && formik.errors["resendReceiptEmail"]
                      }
                      label="Sending To:"
                      type="text"
                      variant="outlined"
                      value={formik.values.resendReceiptEmail}
                      placeholder="Enter Resend RecieptEmail"
                      name="resendReceiptEmail"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
      <DialogActions>
        <DialogActionButton
          isEnabled={enableSaveButton}
          savebuttonText='Send'
          saveStatus={saveCallbackStatus.none}
          executeSave={handleSend}
          handleCallbackSave={() => { }} // handled in thunk
          handleCallbackCancel={onClose}
        />
      </DialogActions>
    </Dialog>
  );
}
