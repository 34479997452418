import { SysRolePage } from "./sys-role-page";
import { UserRolePageAction } from "./user-role-page-action";

export class UserRolePage {
  userRolePageId?: number;
  sysRolePage!: SysRolePage;
  userRolePageActions!: UserRolePageAction[];
  isReadOnly!: boolean;
  isEnabled!: boolean;
}
