export interface Clipboard {
  Reason_for_HIGH_RISK: string,
  Patient_Name: string,
  Facility: string,
  Occupancy_Rate: string,
  Level_of_Care: string,
  Payor_Type: string,
  Timing_Risk: string,
  Risk_Class: string,
  Down_Payment: string,
  Patient_Financial_Responsibility: string,
  Patient_Champion_1_Relationship?: string,
  Patient_Champion_2_Relationship?: string,
  Patient_Champion_3_Relationship?: string,
  Stated_Income: string,
  Income_Verification_Score: string,
  Disposable_Income: string,
}

export const EmptyClipBoard: Clipboard = {
  Reason_for_HIGH_RISK: "Down Payment minimum not met” / “Acceptable Terms Exceeded",
  Patient_Name: "",
  Facility: "",
  Occupancy_Rate: "",
  Level_of_Care: "",
  Payor_Type: "",
  Timing_Risk:"",
  Risk_Class: "",
  Down_Payment: "",
  Patient_Financial_Responsibility: "",
  Stated_Income: "",
  Income_Verification_Score: "",
  Disposable_Income: "",
  }