import {FacilityPayorFormObj, PayorPlanActions} from '../preselect-payors-plan';
import createPayorPlanPromise from './createPayorPlanPromise';

export interface CreatePayorPlanPromiseResponse {
  hasErrors: boolean,
  cfgPayorId: number,
  facilityPayorId?: number
}

export default function createPayorPlanPromises(facilityPayors: FacilityPayorFormObj[] = []) {

  const payorPlanPromiseArr: Promise<any>[] = [];
  const actionValues = Object.values(PayorPlanActions);

  facilityPayors.forEach((payorAndPlans: FacilityPayorFormObj) => {

    const filteredFacilityPlans = Array.isArray(
        payorAndPlans.facilityPayorPlans) ?
        payorAndPlans.facilityPayorPlans.filter(
            plan => plan.action && actionValues.includes(plan.action)) :
        [];

    if ((payorAndPlans?.action &&
            actionValues.includes(payorAndPlans.action)) ||
        filteredFacilityPlans.length > 0) {

      const filteredFacilityPayorPlans = {
        ...payorAndPlans,
        facilityPayorPlans: filteredFacilityPlans,
      };

      payorPlanPromiseArr.push(
          createPayorPlanPromise(filteredFacilityPayorPlans));

    }

  });

  return payorPlanPromiseArr;

}