export interface BalanceAdjustment {
    openingPFR: number;
    pfrBalance: number;
    changeType: number;
    reasonForChangeId: number;
    adjustmentAmount: number | "";
  }

export const emptyBalanceAdjustment: BalanceAdjustment = {
    openingPFR: 0,
    pfrBalance: 0,
    changeType: -1,
    reasonForChangeId: -1,
    adjustmentAmount: ""
}