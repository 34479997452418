import {
  Button,
  DatePicker,
  LoadingOverlay,
} from "@finpay-development/shared-components";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CRMForm } from "src/implementation-specialist/models/crm-form";
import { getCRMMetaData } from "src/meta-data/state/crm-meta-data-thunk";
import { RootState } from "src/shared/state/root-reducer";
import { AppDispatch } from "src/shared/state/store";
import { crmSchema } from "src/shared/validation/schemas";
import { OAuthGrantType } from "./utils";
import {
  getClientCRM,
  saveCRM,
} from "src/implementation-specialist/state/crm/implementation-crm-thunk";
import { showStatus } from "src/security/state/user-slice";

interface CRMModalProps {
  selectedValue: any;
}

export default function CRMModal(props: CRMModalProps) {
  const [showApiPassword, setShowApiPassword] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    clientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient.client
          .clientId
    ),
    currentClientId: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient
          .currentClientEntityDetails.clientId
    ),
    crmObjectType: useSelector(
      (state: RootState) => state.metaData.crmObjectType
    ),
    crmType: useSelector((state: RootState) => state.metaData.crmType),
    implementationCRM: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.implementationCRM
    ),
    currentCRMForm: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.currentCRMForm
    ),
    isLoading: useSelector(
      (state: RootState) => state.crmContext.implementationCRM.isLoading
    ),
  };

  const { selectedValue } = props;

  const {
    crmObjectType,
    crmType,
    currentCRMForm,
    isLoading,
    implementationCRM,
    clientId,
  } = selectors;

  let isEditCRM = implementationCRM.some((crm) => crm?.clientCrmId);

  useEffect(() => {
    dispatch(getCRMMetaData());
  }, [dispatch]);

  const formRef: any = useRef();

  const initialValues = {
    clientId: clientId,
    clientCrmId: currentCRMForm?.clientCrmId,
    crmTypeId: currentCRMForm?.crmTypeId,
    isIntEnabled: currentCRMForm?.isIntEnabled,
    isPolledInt: currentCRMForm?.isPolledInt,
    isEventInt: currentCRMForm?.isEventInt,
    apiUrl: currentCRMForm?.apiUrl,
    apiUserName: currentCRMForm?.apiUserName,
    apiPassword: currentCRMForm?.apiPassword,
    preadmitTypeId: currentCRMForm?.preadmitTypeId,
    admitTypeId: currentCRMForm?.admitTypeId,
    isAdmitIntEnabled: currentCRMForm?.isAdmitIntEnabled,
    isPolledAdmitInt: currentCRMForm?.isPolledAdmitInt,
    isEventAdmitInt: currentCRMForm?.isEventAdmitInt,
    apiFinpayId: currentCRMForm?.apiFinpayId,
    apiFinpayToken: currentCRMForm?.apiFinpayToken,
    oauthGrantType: currentCRMForm?.oauthGrantType,
    authUrl: currentCRMForm?.authUrl,
    accessTokenUrl: currentCRMForm?.accessTokenUrl,
    oauthToken: currentCRMForm?.oauthToken,
    tokenExpireDt: currentCRMForm?.tokenExpireDt,
  };


  const validationSchema = crmSchema;

  const handleSave = async () => {

    const clientCrmId = isEditCRM ? formRef?.current?.values?.clientCrmId : 0;
    const clientId = selectedValue?.clientId;

    const clientFacilityId = selectedValue?.facilityId;

    const formValues: CRMForm = {
      ...formRef.current.values,
      clientCrmId: clientCrmId,
      clientFacilityId: clientFacilityId,
    };

    let serverResponse

    if (clientId) {
      setIsFormSubmitted(true);
    }

    serverResponse = await dispatch(
      saveCRM({
        clientId: clientId ? clientId : null,
        clientFacilityId : clientFacilityId ? clientFacilityId : null,
        formValues,
      })
     );
     setIsFormSubmitted(false);

      if (serverResponse) {
      const serverClientCRMId =
        serverResponse.payload.apiResponseCRM[0].clientCrmId;
      if (serverClientCRMId) {
        await dispatch(getClientCRM({ clientCrmId: serverClientCRMId }));
      }
    }

    const statusMessage = isEditCRM
      ? "CRM Configuration Updated"
      : "CRM Configuration Created";
    await dispatch(showStatus(statusMessage));

  };

  return (
    <>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <Paper>
          <Box
            sx={{
              pt: 4,
              pb: 2,
              margin: "1.5em",
            }}
          >
            <Grid container spacing={2}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                innerRef={formRef}
                validationSchema={validationSchema}
                onSubmit={handleSave}
              >
                {(formik) => (
                  <>
                    <Form autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={6} md={4}>
                          <TextField
                            select
                            sx={{ width: "100%" }}
                            size="small"
                            error={
                              (formik.touched["crmTypeId"] &&
                                Boolean(formik.errors["crmTypeId"])) ||
                              (isFormSubmitted && !formik.values.crmTypeId)
                            }
                            label="CRM Type"
                            name="crmTypeId"
                            value={formik.values.crmTypeId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {crmType.map((crm: any) => (
                              <MenuItem
                                key={crm.crmTypeId}
                                value={crm.crmTypeId}
                              >
                                {crm.crmName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={8} sm={6} md={4}>
                          <TextField
                            select
                            sx={{ width: "100%" }}
                            size="small"
                            error={
                              (formik.touched["preadmitTypeId"] &&
                                Boolean(formik.errors["preadmitTypeId"])) ||
                              (isFormSubmitted && !formik.values.preadmitTypeId)
                            }
                            label="Pre Admit Type"
                            name="preadmitTypeId"
                            value={formik.values.preadmitTypeId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {crmObjectType
                              .filter(
                                (pre) =>
                                  pre.crmTypeId === formik.values.crmTypeId
                              )
                              .map((pre) => (
                                <MenuItem
                                  key={pre.crmObjectTypeId}
                                  value={pre.crmObjectTypeId}
                                >
                                  {pre.crmObjectName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item xs={8} sm={6} md={4}>
                          <TextField
                            select
                            sx={{ width: "100%" }}
                            size="small"
                            error={
                              (formik.touched["admitTypeId"] &&
                                Boolean(formik.errors["admitTypeId"])) ||
                              (isFormSubmitted && !formik.values.admitTypeId)
                            }
                            label="Admit Type"
                            name="admitTypeId"
                            value={formik.values.admitTypeId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {crmObjectType
                              .filter(
                                (ad) => ad.crmTypeId === formik.values.crmTypeId
                              )
                              .map((ad) => (
                                <MenuItem
                                  key={ad.crmObjectTypeId}
                                  value={ad.crmObjectTypeId}
                                >
                                  {ad.crmObjectName}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isEventAdmitInt"
                                  aria-label="IsEventAdmitInt"
                                  checked={formik.values.isEventAdmitInt}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Event Admit Int"
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isPolledInt"
                                  aria-label="IsPolledInt"
                                  checked={formik.values.isPolledInt}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Polled Int"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isEventInt"
                                  aria-label="IsEventInt"
                                  checked={formik.values.isEventInt}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Event Int"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isAdmitIntEnabled"
                                  aria-label="IsAdmitIntEnabled"
                                  checked={formik.values.isAdmitIntEnabled}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Admit Int Enabled"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isPolledAdmitInt"
                                  aria-label="IsPolledAdmitInt"
                                  checked={formik.values.isPolledAdmitInt}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Polled Admit Int"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={8} sm={6} md={4}>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="isIntEnabled"
                                  aria-label="IsIntEnabled"
                                  checked={formik.values.isIntEnabled}
                                  onChange={(e) => formik.handleChange(e)}
                                />
                              }
                              label="Is Int Enabled"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>

                      <Grid container spacing={{ xs: 2, md: 3 }}>
                        <Grid item xs={8} sm={6} md={4} sx={{mt:2}}>
                          <TextField
                            sx={{ width: "100%" }}
                            error={
                              (formik.touched["apiUserName"] &&
                                Boolean(formik.errors["apiUserName"])) ||
                              (isFormSubmitted && !formik.values.apiUserName)
                            }
                            label="UserName"
                            name="apiUserName"
                            value={formik.values.apiUserName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter UserName"
                            helperText={
                              formik.touched["apiUserName"] &&
                              formik.errors["apiUserName"]
                            }
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid item xs={8} sm={6} md={4} sx={{mt:2}}>
                        <TextField
                            sx={{ width: "100%" }}
                            error={
                              (formik.touched["apiPassword"] &&
                                Boolean(formik.errors["apiPassword"])) ||
                              (isFormSubmitted && !formik.values.apiPassword)
                            }
                            label="Password"
                            name="apiPassword"
                            value={formik.values.apiPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter Password"
                            helperText={
                              formik.touched["apiPassword"]
                                ? formik.errors["apiPassword"]
                                : ""
                            }
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 4, md: 8 }}
                      >
                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            error={
                              (formik.touched["apiUrl"] &&
                                Boolean(formik.errors["apiUrl"])) ||
                              (isFormSubmitted && !formik.values.apiUrl)
                            }
                            label="URL"
                            name="apiUrl"
                            value={formik.values.apiUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter URL"
                            helperText={
                              formik.touched["apiUrl"] &&
                              formik.errors["apiUrl"]
                            }
                          />
                        </Grid>

                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            error={
                              (formik.touched["apiFinpayId"] &&
                                Boolean(formik.errors["apiFinpayId"])) ||
                              (isFormSubmitted && !formik.values.apiFinpayId)
                            }
                            label="FinPay ID"
                            name="apiFinpayId"
                            value={formik.values.apiFinpayId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter FinPay ID"
                            helperText={
                              formik.touched["apiFinpayId"] &&
                              formik.errors["apiFinpayId"]
                            }
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 4, md: 8 }}
                      >
                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            label="FinPay Token"
                            name="apiFinpayToken"
                            value={formik.values.apiFinpayToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter FinPay Token"
                          />
                        </Grid>

                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            label="Access Token URL"
                            name="accessTokenUrl"
                            value={formik.values.accessTokenUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter Access Token URL"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 4, md: 8 }}
                      >
                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            label="Auth URL"
                            name="authUrl"
                            value={formik.values.authUrl}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter Auth URL"
                          />
                        </Grid>

                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            select
                            sx={{ width: 250 }}
                            size="small"
                            label="OAuth Grant Type"
                            name="oauthGrantType"
                            value={formik.values.oauthGrantType}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {OAuthGrantType.map((oauth) => (
                              <MenuItem
                                key={oauth.oauthGrantTypeId}
                                value={oauth.oauthGrantTypeId}
                              >
                                {oauth.oauthGranTypeName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 4, md: 8 }}
                      >
                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <TextField
                            sx={{ width: "100%" }}
                            label="OAuth Token"
                            name="oauthToken"
                            value={formik.values.oauthToken}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Please enter OAuth Token"
                          />
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} sx={{ mt: 3 }}>
                          <DatePicker
                            label="Token Expire Date"
                            value={formik.values.tokenExpireDt}
                            onChange={(date: Date) => {
                              formik.setFieldValue("tokenExpireDt", date);
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Form>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        justifyContent="flex-end"
                        sx={{ textAlign: "right", mt: 3, marginRight: 5 }}
                      >
                        <Button onClick={formik.handleSubmit}>Save</Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Formik>
            </Grid>
          </Box>
        </Paper>
      )}
    </>
  );
}
