import '../../../scss/components/_forms.scss';

import { useState } from 'react';

import { Button } from '@finpay-development/shared-components';

import AccessControl from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { PatientModal } from './patient-modal';

const AddPatientRecord = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function handleModalSubmit() {
    setIsModalOpen(false);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  return (
    <>
      <AccessControl
        rolePageName={RolePageNames.PatientRecords}
        actionName="Add New Account"
        renderNoAccess={() => (
          <Button disabled>Add Patient Record</Button>
        )}
      >
        <Button onClick={() => setIsModalOpen(true)}>Add Patient Record</Button>
      </AccessControl>
      {
        isModalOpen &&
        <PatientModal
          open={isModalOpen}
          isEdit={false}
          handleModalSubmit={handleModalSubmit}
          handleModalCancel={handleModalClose}
        />
      }
    </>
  )
}

export default AddPatientRecord