export const reportsType= [
  "Patient Financial Clearance",
  "Risk Class Reports",
]

export const restrictedReportsType = [
	"PES Activity Log Report",
	"All Instance of Care"
]

export const addedReportsType = [
	"Summary Transaction Report by Payment Channel",
	"Transaction Report",
	"Automated Payment Report",
	"Settlement Reports"
]

export const getReportsName = (reportType: string) => {
	let reportString = ""
	switch (reportType) {
		case "PatientFinancialClearance":
			reportString = reportsType[0]
			break;
		case "SettlementDetail":
			reportString = "Settlement Reports"
			break;
		case "RiskClassReports":
			reportString = reportsType[1]
			break;
		case "PESActivityLog":
			reportString = restrictedReportsType[0];
			break;
		case "AllInstanceofCare":
			reportString = restrictedReportsType[1];
			break;
		case "SummaryTransactions":
			reportString = addedReportsType[0];
			break;
		case "MergedTransactions":
			reportString = addedReportsType[1];
			break;
		case "AutomatedPayment":
			reportString = addedReportsType[2];
			break;
		case "SettlementSummary":
			reportString = addedReportsType[3];
	}
	return reportString;
}