import * as Yup from 'yup';
import { paymentToggleTypes } from './take-payment-modal-content';
import { commonFields } from 'src/shared/validation/schemas';

export const minLengthTwo = (v: string | undefined) => {
  const names = (v || '').trim()?.split(' ') || [];
  const firstName = names[0];
  const lastName = (names.slice(1).join(' ')).trim();

  if (firstName.length < 2 || lastName.length < 2) {
    return false;
  }
  return true;
}

//to do - remove refernce to PAID_AT_FACILITY and move to CASH

const validationSchema = Yup.object({
  payorName: Yup.string(),
  useExistingSource: Yup.boolean(),
  nameOnCard: Yup.string().when('useExistingSource', {
    is: false,
    then: Yup.string().when('paymentMethodType', {
      is: paymentToggleTypes.CREDIT_CARD,
      then: Yup.string()
        .test(
          'min-words-count-2',
          'The value must contain both a first and last name',
          (v) => ((v?.split(' ') || []).length >= 2)
        ).test(
          'min-length-2',
          'Both the lengths of the first and last names must be at least 2 characters',
          (v) => (minLengthTwo(v)),
        )
    })
  }),
  nameOnAccount: Yup.string().when('useExistingSource', {
    is: false,
    then: Yup.string().when('paymentMethodType', {
      is: paymentToggleTypes.ACH,
      then: Yup.string().when('payorName', {
        is: 'add-new-payor',
        then: Yup.string()
          .test(
            'min-words-count-2',
            'The value must contain both a first and last name',
            (v) => ((v?.split(' ') || []).length >= 2)
          ).test(
            'min-length-2',
            'Both the lengths of the first and last names must be at least 2 characters',
            (v) => (minLengthTwo(v)),
          ),
      })
    })
  }),
  bankName: Yup.string().when('useExistingSource', {
    is: false,
    then: Yup.string().when('paymentMethodType', {
      is: paymentToggleTypes.ACH,
      then: Yup.string().required(),
    }),
  }),
  paymentSources: Yup.string().when('useExistingSource', {
    is: true,
    then: Yup.string().test(
      'custom-payment-sources-validation',
      'Required field',
      (v) => (!!v && v !== '-1')
    )
  }),
  amount: Yup.number().required()
    .typeError('Amount must be a number')
    .min(.50, 'Minimum payment is 50 cents'),
  receiptEmail: commonFields.EMAIL.required('Payor Email is a required field'),
  paymentReason: Yup.string().max(100, 'Maximum payment reason is 100 characters').optional(),
  firstName: Yup.string().when('useExistingSource', {
    is: false,
    then: Yup.string().when('paymentMethodType', {
      is: (value: string) => value === paymentToggleTypes.PAID_AT_FACILITY || value === paymentToggleTypes.CHECK,
      then: Yup.string(),
    }),
  }),
  lastName: Yup.string().when('useExistingSource', {
    is: false,
    then: Yup.string().when('paymentMethodType', {
      is: (value: string) => value === paymentToggleTypes.PAID_AT_FACILITY || value === paymentToggleTypes.CHECK,
      then: Yup.string(),
    }),
  }),
});

export default validationSchema;
