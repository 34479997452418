import React, { ChangeEvent, useEffect, useState } from "react";
import { Grid, Paper, Box, Typography, MenuItem, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Menu } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../shared/state/store";
import { ImplementationFacility } from "../models/implementation-facility";
import { RootState } from "../../../../../shared/state/root-reducer";
import { getClientFacilities } from "../../../../state/facility/implementation-facility-thunk";
import { Checkbox, DeleteModal, LoadingOverlay, TextField } from "@finpay-development/shared-components";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import { ClientReferralSource, FacilityLOCReferralSourceForm, SelectedFacilityReferralSource } from "../../../../models/referral-source";

import { deleteReferralSource, getClientReferralSources, getFacilityLOCReferralSources, getFacilityReferralSources } from "../../../../state/clients/implementation-clients-thunk";
import { configGetLOC } from "../../../../../admin-configuration/state/admin-configuration-thunk";
import { apiStatus, EstLevelOfCare } from "../../../../../admissions-advisor/models/estimator";
import { clearConfigGetLOCStatus } from "../../../../../admissions-advisor/state/estimator-slice";
import { getClientLevelsOfCare } from "../../../../../admissions-advisor/state/estimator-thunk";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { ClientReferralSourceModal } from "./client-referral-source-modal";
import { FacilityReferralSourceModal } from "./facility-referral-source-modal";
import { FacilityLOCReferralSourceModal } from "./facility-loc-referral-source-modal";


function ReferralSourceRevenue() {

  const paramId = -2;
  const [selectedReferralSourceType, setSelectedReferralSourceType] = useState('Client');
  const [selectedFacilityId, setSelectedFacilityId] = useState('');
  const [selectedLevelOfCareType, setSelectedLevelOfCareType] = useState('off');
  const [selectedLevelOfCareId, setSelectedLevelOfCareId] = useState('');
  const [selectedClientReferralSourceId, setSelectedClientReferralSourceId] = useState('');
  const [selectedClientReferralSource, setSelectedClientReferralSource] = useState<ClientReferralSource>();
  const [selectedFacilityReferralSourceId, setSelectedFacilityReferralSourceId] = useState('');
  const [selectedFacilityReferralSource, setSelectedFacilityReferralSource] = useState<SelectedFacilityReferralSource>();
  const [selectedFacilityLOCReferralSource, setSelectedFacilityLOCReferralSource] = useState<FacilityLOCReferralSourceForm>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [facilityLevelsOfCare, setFacilityLevelsOfCare] = useState<EstLevelOfCare[]>([]);
  const [isEditModal, setIsEditModal] = useState(true);
  const [isClientReferralSourceModalOpen, setIsClientReferralSourceModalOpen] = useState(false);
  const [isFacilityReferralSourceModalOpen, setIsFacilityReferralSourceModalOpen] = useState(false);
  const [isFacilityLOCReferralSourceModalOpen, setIsFacilityLOCReferralSourceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTitle, setDeleteTitle] = useState('');
  const [facilitySelectedReferralSources, setFacilitySelectedReferralSources] = useState<SelectedFacilityReferralSource[]>([]);
  const [deletedClientReferralSource, setDeletedClientReferralSource] = useState<ClientReferralSource>();
  const [deletedFacilityReferralSource, setDeletedFacilityReferralSource] = useState<SelectedFacilityReferralSource>();

  type MenuActionType = "edit" | "delete";

  const selectors = {
    fullClient: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient?.client
    ),
    // currentClient: useSelector(
    //   (state: RootState) =>
    //     state.implementationContext.implementationSpecialistClient.currentClientEntityDetails
    // ),
    facilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility?.facilities
    ),
    clientReferralSources: useSelector(
        (state: RootState) =>
        state.implementationContext.implementationSpecialistClient?.clientReferralSources
    ),
    facilityReferralSources: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient?.facilityReferralSources
    ),
    facilityLOCReferralSources: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient?.facilityLOCReferralSources
    ),
    isLoadingFacilities: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationFacility?.isLoading
    ),
    isLoadingReferralSources: useSelector(
      (state: RootState) =>
        state.implementationContext.implementationSpecialistClient?.isLoadingReferralSources
    ),
    masterLevelsOfCareState: useSelector(
        (state: RootState) =>
          state.adminContext.adminConfigurationContext?.levelsOfCare
    ),
    clientLevelsOfCareState: useSelector(
    (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare
    ),
    getClientLOCStatus: useSelector(
    (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext?.isLoading
        .getClientLOCStatus
    ),
    isLoadingClientLoc: useSelector(
        (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext?.isLoading
            .isLoadingClientLoc
      ),
    isLoadingMasterLoc: useSelector(
        (state: RootState) =>
          state.admissionsAdvisorContext.estimatorContext?.isLoading
            .isLoadingMasterLoc
      ),
    configGetLOCStatus: useSelector(
    (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext?.isLoading
        .configGetLOCStatus
    ),
  };

  const {
    facilities,
    isLoadingFacilities,
    clientReferralSources,
    facilityReferralSources,
    facilityLOCReferralSources,
    masterLevelsOfCareState,
    clientLevelsOfCareState,
    isLoadingMasterLoc,
    configGetLOCStatus,
    isLoadingReferralSources
  } = selectors;

  const dispatch = useDispatch<AppDispatch>();

  useEffect( () => {
    if (masterLevelsOfCareState.length === 0) {
        dispatch(configGetLOC(paramId));
    } else {
        getLevelsOfCareByClient();
    }
    if (selectors.fullClient.clientId > 0) {
        dispatch(getClientReferralSources({clientId: selectors.fullClient.clientId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isLoadingMasterLoc && configGetLOCStatus === apiStatus.success) {
      // config Levels of Care has loaded
      dispatch(clearConfigGetLOCStatus());
      getLevelsOfCareByClient();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMasterLoc]);    //, isLoadingClientLoc

  const getLevelsOfCareByClient = () => {
    if (selectors?.masterLevelsOfCareState?.length > 0) {
      const config: any = {
        paramId: paramId,
        clientId: selectors.fullClient.clientId,
        filterByPayerPlanId: false,
        masterListLevelsOfCare: masterLevelsOfCareState,
      };
      dispatch(getClientLevelsOfCare(config));
    }
  };

  useEffect(() => {
    if ((selectors.fullClient.clientId > 0) && (facilities.length === 0)) {
      dispatch(getClientFacilities(selectors.fullClient.clientId))
    }
  }, [selectors.fullClient, dispatch, facilities])

  useEffect(() => {
    if (Number(selectedFacilityId) > 0) {
        dispatch(getFacilityReferralSources({ facilityId: Number(selectedFacilityId), clientId: selectors.fullClient.clientId }));
        getFacilityLevelsOfCare(selectedFacilityId);
    } else {
      setFacilitySelectedReferralSources([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilityId])

  useEffect(() => {
    setFacilitySelectedReferralSources(facilityReferralSources);
  }, [facilityReferralSources])

  useEffect(() => {
    const numSelectedLevelOfCareId = Number(selectedLevelOfCareId);
    if (numSelectedLevelOfCareId > 0) {
      dispatch(getFacilityLOCReferralSources({ facilityId: Number(selectedFacilityId),
                                               clientId: selectors.fullClient.clientId,
                                               facilityReferralSources: facilitySelectedReferralSources,
                                               facilityLevelsOfCare: facilityLevelsOfCare,
                                               levelOfCareId: numSelectedLevelOfCareId }));
    }

  }, [selectedLevelOfCareId, dispatch, facilitySelectedReferralSources, selectedFacilityId, selectors.fullClient.clientId, facilityLevelsOfCare])

  const getSelectedClientReferralSources = async () => {
    dispatch(getClientReferralSources({clientId: selectors.fullClient.clientId }))
  }

  const getFacilityLevelsOfCare = (facilityId: string) => {
    if (clientLevelsOfCareState.length > 0) {
      const facLOC = clientLevelsOfCareState
              ?.flat()
              .filter((fac) => fac?.clientFacilityId === Number(facilityId));
      setFacilityLevelsOfCare(facLOC);
    }
  }

  const handleFacilityChange = (e: Event) => {
    const selectedFacId = (e.target as HTMLInputElement).value;
    setSelectedFacilityId(selectedFacId);
    setSelectedLevelOfCareType('off');
    setSelectedLevelOfCareId('');
    setSelectedFacilityLOCReferralSource(undefined);
  };

  const handleBlur = () => {}

  const resetFacilitySelections = () => {
    setSelectedFacilityId('');
    setSelectedFacilityReferralSource(undefined);
    setSelectedLevelOfCareType('off');
    setSelectedLevelOfCareId('');
    setSelectedFacilityLOCReferralSource(undefined);
  }

  const handleReferralSourceTypeChange = (e: ChangeEvent) => {
    const selectedType = (e.target as HTMLInputElement).value;
    setSelectedReferralSourceType(selectedType);
    resetFacilitySelections();
  };

  const handleLevelOfCareTypeChange = (e: ChangeEvent) => {
    const isChecked = (e.target as HTMLInputElement).checked;
    if (isChecked) {
      setSelectedLevelOfCareType("on");
    } else {
      resetFacilitySelections();
    }
  };

  const handleLOCChange = (e: ChangeEvent) => {
    const selectedLOCId = (e.target as HTMLInputElement).value;
    setSelectedLevelOfCareId(selectedLOCId);
  }

  const handleClick = (e: any, refSourceType: string, facilityRowType: string, refSourceId: string) => {
    if (refSourceType === 'Client') {
      setAnchorEl(e.currentTarget);
      setSelectedClientReferralSourceId(refSourceId);
      const clientRefItem = clientReferralSources.find((x) => x.clientReferralSourceId === Number(refSourceId));
      if (clientRefItem !== undefined) {
        setSelectedClientReferralSource(clientRefItem);
      }
    } else {
      if (refSourceType === 'Facility') {
        setAnchorEl(e.currentTarget);
        setSelectedFacilityReferralSourceId(refSourceId + '_' + facilityRowType);
        const facilityRefItem = facilityReferralSources.find((x) => x.facilityReferralSourceId === Number(refSourceId));
        if (facilityRefItem !== undefined) {
          setSelectedFacilityReferralSource(facilityRefItem);
        }
      }
    }
  }

  function handleClientReferralSourceModalCancel() {
    setIsClientReferralSourceModalOpen(false);
  }

  async function handleClientReferralSourceModalSubmit(isEditMode: boolean) {
    setIsClientReferralSourceModalOpen(false);
    await getSelectedClientReferralSources();
  }

  function handleFacilityReferralSourceModalCancel() {
    setIsFacilityReferralSourceModalOpen(false);
  }

  async function handleFacilityReferralSourceModalSubmit(isEditMode: boolean) {
    setIsFacilityReferralSourceModalOpen(false);
    dispatch(getFacilityReferralSources({ facilityId: Number(selectedFacilityId), clientId: selectors.fullClient.clientId }));
  }

  function handleFacilityLOCReferralSourceModalCancel() {
    setIsFacilityLOCReferralSourceModalOpen(false);
  }

  async function handleFacilityLOCReferralSourceModalSubmit(isEditMode: boolean) {
    setIsFacilityLOCReferralSourceModalOpen(false);
    dispatch(getFacilityLOCReferralSources({ facilityId: Number(selectedFacilityId), clientId: selectors.fullClient.clientId, facilityReferralSources: facilitySelectedReferralSources,
       facilityLevelsOfCare: facilityLevelsOfCare, levelOfCareId: Number(selectedLevelOfCareId) }));
  }

  const handleNewFacilityReferralSourceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEditModal(false);
    setSelectedFacilityReferralSource({
        facilityId: Number(selectedFacilityId),
        clientReferralSourceId: 0,
        facilityReferralSourceId: 0,
        clientId: selectors.fullClient.clientId,
        facilityReferralSourceName: "",
        clientReferralSourceName: "",
    })
    setIsFacilityReferralSourceModalOpen(true);
  };

  const handleNewClientReferralSourceClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsEditModal(false);
    setSelectedClientReferralSource({
      clientId: selectors.fullClient.clientId,
      clientReferralSourceName: "",
      clientReferralSourceId: undefined,
    })
    setIsClientReferralSourceModalOpen(true);
  };

  function handleDeleteCancel() {
    setIsDeleteModalOpen(false);
    //reset deleted instances
    setDeletedClientReferralSource(undefined);
    setDeletedFacilityReferralSource(undefined);
}

  async function handleDeleteOk() {
    // check which instance is to be deleted
    if (deletedClientReferralSource !== undefined) {
      const deletePostBody: any = { referralSourceId: deletedClientReferralSource.clientReferralSourceId,
                                    clientId: selectors.fullClient.clientId,
                                    facilityId: undefined, paramId: paramId};
      await dispatch(deleteReferralSource(deletePostBody));
      setDeletedClientReferralSource(undefined);
      getSelectedClientReferralSources();
    } else {
      if (deletedFacilityReferralSource !== undefined) {
        const deletePostBody: any = { referralSourceId: deletedFacilityReferralSource.facilityReferralSourceId,
                                      clientId: selectors.fullClient.clientId,
                                      facilityId: deletedFacilityReferralSource.facilityId, paramId: paramId};
        await dispatch(deleteReferralSource(deletePostBody));
        setDeletedFacilityReferralSource(undefined);
        dispatch(getFacilityReferralSources({ facilityId: Number(selectedFacilityId), clientId: selectors.fullClient.clientId }));
      }
    }

    setIsDeleteModalOpen(false);
  }


  const handleMenuClose = (menuActionType?: MenuActionType) => {
    setAnchorEl(null);

    switch (menuActionType) {
      case "edit":
        setIsEditModal(true);
        if (selectedReferralSourceType === 'Client') {
          setIsClientReferralSourceModalOpen(true);
          setIsFacilityReferralSourceModalOpen(false);
        } else {
          setIsClientReferralSourceModalOpen(false);
          setIsFacilityReferralSourceModalOpen(true);
        }
        break;

      case "delete":
        let deletedItem = null;
        let refSourceDesc = null;
        if (selectedReferralSourceType === 'Client') {
          deletedItem = clientReferralSources.find((x) => x.clientReferralSourceId === Number(selectedClientReferralSourceId));
          if (deletedItem !== undefined) {
            refSourceDesc = deletedItem.clientReferralSourceName;
            setDeletedClientReferralSource(deletedItem);
          }
        } else {
          const refId = selectedFacilityReferralSourceId.substring(0,selectedFacilityReferralSourceId.lastIndexOf('_'))
          deletedItem = facilityReferralSources.find((x) => x.facilityReferralSourceId === Number(refId));
          if (deletedItem !== undefined) {
            refSourceDesc = (deletedItem.clientReferralSourceId === undefined || deletedItem.clientReferralSourceId === null || deletedItem.clientReferralSourceId === 0) ?  deletedItem.facilityReferralSourceName : deletedItem.clientReferralSourceName;
            setDeletedFacilityReferralSource(deletedItem);
          }
        }

        const deleteTitle = "Delete Referral Source: " + refSourceDesc + "?";
        setDeleteTitle(deleteTitle);
        setIsDeleteModalOpen(true);

        break;
    }
  };

  const handleEditFacilityLOCReferralClick = (event: React.MouseEvent<HTMLButtonElement>, refSourceId: number) => {

    setIsEditModal(true);
    setIsClientReferralSourceModalOpen(false);
    setIsFacilityReferralSourceModalOpen(false);
    setIsFacilityLOCReferralSourceModalOpen(true);

        const facilityLOCRefItem = facilityLOCReferralSources.find((x:FacilityLOCReferralSourceForm ) => x.facilityReferralSourceId === Number(refSourceId));
        if (facilityLOCRefItem !== undefined) {
          setSelectedFacilityLOCReferralSource(facilityLOCRefItem);
        }
  };

  const clientRefSourcesBox = (
    <TableContainer component={Paper}>
      <Table
        data-testid="results"
        className="table alternating-row"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell
              key="lbl_referralSource"
              align="left"
              style={{
                padding: "0.5em",
              }}
            >Referral Sources</TableCell>
            <TableCell
              key="action"
              align="center"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientReferralSources?.map((row: any) => (
            <TableRow key={row?.clientReferralSourceId}>
              <TableCell align="left" style={{ padding: "0.5em" }}>
                {row?.clientReferralSourceName}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-haspopup="true"
                  onClick={(e: any) => handleClick(e, 'Client', 'clientSource', row?.clientReferralSourceId)}
                  size="large">
                  <MoreHorizIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            title="Add Referral Source"
            color="primary"
            onClick={(e) => handleNewClientReferralSourceClick(e)}
            size="large">
            <AddBoxOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </TableContainer>
  )

  const facilityRefSourcesBox = (
    <TableContainer component={Paper}>
      <Table
        data-testid="results"
        className="table alternating-row"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell
              key="lbl_referralSource"
              align="left"
              style={{
                padding: "0.5em",
              }}
            >Referral Sources For Facility</TableCell>
            <TableCell
              key="action"
              align="center"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {facilitySelectedReferralSources?.map((row: any) => (
            <TableRow key={row?.facilityReferralSourceId}>
              <TableCell align="left" style={{ padding: "0.5em" }}>
                {row?.clientReferralSourceId > 0 ? row?.clientReferralSourceName : row?.facilityReferralSourceName}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  aria-haspopup="true"
                  onClick={(e: any) => handleClick(e, 'Facility', row?.clientReferralSourceId > 0 ? 'clientSource' : 'facilitySource', row?.facilityReferralSourceId)}
                  size="large">
                  <MoreHorizIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container justifyContent="space-between">
        <Grid item>
          <IconButton
            title="Add Referral Source To Facility"
            color="primary"
            onClick={(e) => handleNewFacilityReferralSourceClick(e)}
            size="large"
            disabled={selectedFacilityId === ''}>
            <AddBoxOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </TableContainer>
  )

  const facilityLOCRefSourcesBox = (
    <TableContainer component={Paper}>
      <Table
        data-testid="results"
        className="table alternating-row"
        size="small"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell
              key="lbl_referralSource"
              align="left"
              style={{
                padding: "0.5em",
              }}
            >Referral Sources For Facility LOC</TableCell>
              <TableCell
                key="lbl_revenue"
                align="left"
                style={{
                  padding: "0.5em",
                }}
              >Revenue</TableCell>
            <TableCell
              key="action"
              align="center"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {facilityLOCReferralSources?.map((row: any) => (
            <TableRow key={row?.facilityReferralSourceId}>
              <TableCell align="left" style={{ padding: "0.5em" }}>
                {row?.facilityReferralSourceName}
              </TableCell>
              <TableCell
                key="lbl_revenue"
                align="left"
                style={{
                  padding: "0.5em",
                }}
              >
                $&nbsp;{row?.locReferralRevenue}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  title="Edit"
                  aria-haspopup="true"
                  onClick={(e) => {
                    handleEditFacilityLOCReferralClick(e, row.facilityReferralSourceId);
                  }}
                  size="large">
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Paper>
      {isLoadingReferralSources ? (
        <LoadingOverlay />
      ) : (
        <>
        <Box
          style={{
            margin: "0.5em",
            padding: "1.0em",
            borderBottom: "1px solid #cccccc",
          }}
        >
          <Grid container justifyContent="space-between" direction="row">
              <Grid item justifyContent="center"
                style={{
                    display: "flex",
                    alignItems:  "center",
                    flexWrap: "wrap",
                }}
              >
                <Typography variant="subtitle2">Referral Source Per Diem Revenue Data</Typography>
              </Grid>
          </Grid>
        </Box>
        <Box
          style={{
            margin: "0.5em",
            padding: "1.0em",
            borderBottom: "1px solid #cccccc",
          }}
        >
            <Grid container>
              <Grid item justifyContent="center" xs={3}>
                <Typography variant='body2'>
                   Edit Referral Sources for
                </Typography>
                <div className="buttongroup">
                    <input
                      id="refSourceType_Client"
                      type="radio"
                      value="Client"
                      name="refSourceType"
                      checked={selectedReferralSourceType === "Client"}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleReferralSourceTypeChange(e);
                      }}
                    />
                    <label htmlFor="refSourceType_Client">Client</label>
                    <input
                      id="refSourceType_Facility"
                      type="radio"
                      value="Facility"
                      name="refSourceType"
                      checked={selectedReferralSourceType === "Facility"}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleReferralSourceTypeChange(e);
                      }}
                    />
                    <label htmlFor="refSourceType_Facility">Facility</label>
                  </div>
              </Grid>
              <Grid item xs={3}>
                <TextField
                    select={true}
                    label="Facility"
                    name="facility"
                    value={selectedFacilityId}
                    disabled={
                    isLoadingFacilities || selectedReferralSourceType === 'Client'
                    }
                    loading={isLoadingFacilities}
                    onChange={handleFacilityChange}
                    onBlur={handleBlur}
                >
                    {facilities
                    .flat()
                    .map((facility: ImplementationFacility) => (
                        <MenuItem
                        key={facility.facilityId}
                        value={facility.facilityId}
                        >
                        {facility.facilityName}
                        </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={2}
                style={{
                    marginTop: "0.5em",
                    paddingLeft: "40px",
                }}
              >
                <Checkbox
                    id="chk_LOCType"
                    name="selectedLOCType"
                    label="Level Of Care"
                    checked={selectedLevelOfCareType === "on"}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    handleLevelOfCareTypeChange(e);
                    }}
                    disabled={selectedFacilityId === ''}
                />
              </Grid>
              <Grid item xs={3}>
              <TextField
                    select={true}
                    label="Select Level Of Care"
                    name="ddLevelOfCare"
                    value={selectedLevelOfCareId}
                    disabled={
                    selectedReferralSourceType === 'Client' || selectedLevelOfCareType === 'off'
                    }
                    onChange={handleLOCChange}
                    onBlur={handleBlur}
                >
                    {facilityLevelsOfCare
                    .flat()
                    .map((locItem: EstLevelOfCare) => (
                        <MenuItem
                        key={locItem.facilityLevelOfCareId}
                        value={locItem.facilityLevelOfCareId}
                        >
                        {locItem.facilityLevelOfCareCode} - {locItem.facilityLevelOfCareName}
                        </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </Grid>
        </Box>
        <Box
          style={{
            margin: "0.5em",
            padding: "1.0em",
            borderBottom: "1px solid #cccccc",
          }}
        >
            {selectedReferralSourceType === 'Client' && clientRefSourcesBox }
            {selectedReferralSourceType === 'Facility' && selectedLevelOfCareId === '' && facilityRefSourcesBox }
            {selectedReferralSourceType === 'Facility' && selectedLevelOfCareId !== '' && facilityLOCRefSourcesBox }
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose()}
          >
            <div>
            { selectedReferralSourceType === 'Client' && (
              <MenuItem className="primary" onClick={() => handleMenuClose("edit")}>Edit</MenuItem>
            )}
            { selectedReferralSourceType === 'Facility' && (selectedFacilityReferralSourceId?.indexOf('facility') > 0) && (
              <MenuItem className="primary" onClick={() => handleMenuClose("edit")}>Edit</MenuItem>
            )}
              <MenuItem className="primary" onClick={() => handleMenuClose("delete")}>Delete</MenuItem>
            </div>
          </Menu>

          {isClientReferralSourceModalOpen && selectedReferralSourceType === 'Client' && (
            <ClientReferralSourceModal
                open={isClientReferralSourceModalOpen}
                isEdit={isEditModal}
                handleReferralSourceCancel={handleClientReferralSourceModalCancel}
                handleReferralSourceSubmit={handleClientReferralSourceModalSubmit}
                referralSourceItem={ selectedClientReferralSource }
                referralSourceType={selectedReferralSourceType}
            />
          )}

          {isFacilityReferralSourceModalOpen && (
            <FacilityReferralSourceModal
                open={isFacilityReferralSourceModalOpen}
                isEdit={isEditModal}
                handleReferralSourceCancel={handleFacilityReferralSourceModalCancel}
                handleReferralSourceSubmit={handleFacilityReferralSourceModalSubmit}
                referralSourceItem={ selectedFacilityReferralSource }
                referralSourceType={selectedReferralSourceType}
            />
          )}

          {isFacilityLOCReferralSourceModalOpen && (
            <FacilityLOCReferralSourceModal
                open={isFacilityLOCReferralSourceModalOpen}
                isEdit={isEditModal}
                handleReferralSourceCancel={handleFacilityLOCReferralSourceModalCancel}
                handleReferralSourceSubmit={handleFacilityLOCReferralSourceModalSubmit}
                referralSourceItem={ selectedFacilityLOCReferralSource }
            />
          )}

          {isDeleteModalOpen && (
              <DeleteModal
                  open={isDeleteModalOpen}
                  title={ deleteTitle }
                  subTitle="This action cannot be undone"
                  okButtonText="Delete Forever"
                  handleDeleteCancel={handleDeleteCancel}
                  handleDeleteOk={handleDeleteOk}
              />
          )}
        </Box>
        </>
      )
      }
    </Paper>
  );
}

export default ReferralSourceRevenue;