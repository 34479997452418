export interface ClientKPI {
  clientId?: number;
  facilityId?: number;
  externalAccountId?: string;
  downPmts?: number;
  transactions?: number;
  converted?: number;
  fullPayments?: number;
  paidOff?: number;
}

export const emptyClientKPI: ClientKPI = {
  clientId: 0,
  facilityId: 0,
  externalAccountId: '',
  downPmts: 0,
  transactions: 0,
  converted: 0,
  fullPayments: 0,
  paidOff: 0,
}

export interface FacilityKPI {
  clientId?: number;
  facilityId?: number;
  externalAccountId?: string;
  downPmts?: number;
  transactions?: number;
  converted?: number;
  fullPayments?: number;
  paidOff?: number;
}

export const emptyFacilityKPI: FacilityKPI = {
  clientId: 0,
  facilityId: 0,
  externalAccountId: '',
  downPmts: 0,
  transactions: 0,
  converted: 0,
  fullPayments: 0,
  paidOff: 0,
}

