export enum SignatureStatus {
  null = "",
  Available = "Available",
  PendingEsign = "Pending eSign",
  SignatureCompleted = "Signature Completed",
  PendingSignature = 'Pending Signature',
  created = 'created',
  DocumentCreated = 'Document Created',
  SignatureRequested = 'Signature Requested',
  // local statuses
  pending = 'pending',
  signed = 'signed',

}
export interface SignatureTracking {
  externalDocumentId: string;
  signatureId: string;
  signatureRequestDt: string;
  signatureStatus: SignatureStatus;
  signatureStatusDt: string;
  signatureTrackingId: number;
}

export const emptySignatureTracking: SignatureTracking = {
  externalDocumentId: '',
  signatureId: '0',
  signatureRequestDt: "",
  signatureStatus: SignatureStatus.null,
  signatureStatusDt: "",
  signatureTrackingId: 0
}
