import {AxiosReadPayload} from '../../shared/service/axios-read-payload';
import {axiosReadHelper} from '../../shared/service/axios-read-helper';

class MetaDataService{
  async getPatientEncounterMetaData(){
    const readPayload: AxiosReadPayload = {
      url: `patient-encounter/v2/patient/encounter/metaData`,
    };
    return await axiosReadHelper(readPayload);
  }

  async getPaymentMetadata(sources: string[] | undefined){

    let url = `payment/v2/payment/metadata`

    if(Array.isArray(sources) && sources.length > 0){
      url += `?sources=${sources.join(",")}`
    }
    const readPayload: AxiosReadPayload = {
      url,
    };
    return await axiosReadHelper(readPayload);
  }

  async getPaymentChannels(){
    const readPayload: AxiosReadPayload = {
      url: `payment/v2/payment/paymentchannels`,
    };
    return await axiosReadHelper(readPayload);
  }

  async getCRMMetaData() {
    const readPayload: AxiosReadPayload = {
      url: `clients/v2/client/metadata`,
      dataId:-2
    }
    return await axiosReadHelper(readPayload)
  }

  async getAlerts(clientId: string | number) {
    const readPayload: AxiosReadPayload = {
      url: `clients/v2/client/${clientId}/alert`,
      dataId:-2
    }
    return await axiosReadHelper(readPayload)
  }

  async getEmployers(clientId: string | number) {
    const readPayload: AxiosReadPayload = {
      url: `clients/v2/client/${clientId}/employer`,
      dataId:-2
    }
    return await axiosReadHelper(readPayload)
  }

}

export const metaDataService = new MetaDataService();