import React, { useCallback, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionCompleteStatus from "../../../../../shared/components/section-complete-status";
import { Grid, IconButton } from "@mui/material";
import "./../../../../../scss/components/_btn-group.scss";
import EditIcon from "@mui/icons-material/Edit";
import { EditClientBusinessRulesModal } from "./edit-client-business-rules";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../shared/state/root-reducer";
import { clearStatus, setEntitySettingsTabComplete } from "../../../../state/clients/implementation-clients-slice";
import AccessControl from '../../../../../security/components/access-control';
import { RolePageNames } from "../../../../../security/model/role-page-names";
import {Utils} from "../../../../../shared/utils";
import { AppDispatch } from "../../../../../shared/state/store";
import HeaderWithDetails from "../../../../../shared/components/header-with-details";

function EntitySettings() {

  const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const selectors = {
    currentClientBusinessRules: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.currentClientBusinessRules
    ),
    currentClient: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.currentClient
    ),
    implementationFacilities: useSelector(
      (state: RootState) => state.implementationContext.implementationFacility.facilities
    ),
  }

  function handleEditClose() {
    setShowEditSettingsModal(false);
  }

  function handleStartClick() {
    setShowEditSettingsModal(true);
  }

  const isBusinessRulesComplete = useCallback(() => {
    // if such field is falsy (or really any business rule field) then Business Rules form was never completed
    return !!selectors.currentClientBusinessRules.customDocTokenId;
  }, [selectors.currentClientBusinessRules.customDocTokenId])

  useEffect(() => {
    dispatch(setEntitySettingsTabComplete(isBusinessRulesComplete()));
    dispatch(clearStatus());
  }, [dispatch, isBusinessRulesComplete])

 return (
   <div className="entity-settings">
     {/* TODO: Switch over to Customizable Accordion component */}
     <Accordion defaultExpanded>
       {isBusinessRulesComplete() ? (
         <AccordionSummary
           expandIcon={<ExpandMoreIcon />}
           id="accordion-entity"
         >
           <SectionCompleteStatus label="Business Rules" isComplete />
         </AccordionSummary>
       ) : (
         <Grid
           container
           justifyContent="space-between"
           alignItems="center"
           className="px-4 py-4 pr-6"
         >
           <Grid item>
             <SectionCompleteStatus
               label="Business Rules"
               isComplete={false}
             />
           </Grid>
           <Grid
             item
             onClick={() => {handleStartClick()}}
           className="link">
             Start
           </Grid>
         </Grid>
       )}
       {isBusinessRulesComplete() && <AccordionDetails>
         <Grid container direction="column">
           <Grid item>
             <Grid container direction="column">
               <Grid container justifyContent="space-between" alignItems="center">
                 <Grid item>
                   <Typography variant="h3">Full Pay Discount</Typography>
                 </Grid>
                 <Grid item>
                   <AccessControl rolePageName={RolePageNames.Clients} actionName="Add New Client"
                     renderNoAccess={() => <IconButton disabled size="large"> <EditIcon className="colorDisabled" /></IconButton>}>
                     <IconButton
                       color="primary"
                       onClick={() => setShowEditSettingsModal(true)}
                       className="edit-button"
                       size="large"> <EditIcon /></IconButton>
                   </AccessControl>
                 </Grid>
               </Grid>

               <Typography variant="subtitle2" className="mt-4">Contact</Typography>
               <Grid container>
                 <HeaderWithDetails
                   header="Pre/During Care"
                   details={selectors.currentClientBusinessRules?.preCareFullPayMaxDiscount || 0}
                   width="half"/>
                   <HeaderWithDetails
                     header="Post Care Max"
                     details={selectors.currentClientBusinessRules?.postCareFullPayMaxDiscount || 0}
                     width="half" />
               </Grid>

               <Typography variant="subtitle2" className="mt-4">Refunds</Typography>
               <Grid container>
                 <HeaderWithDetails
                   header="Issued By"
                   details={selectors.currentClientBusinessRules?.refundsIssuedBy}
                   width="full" />
               </Grid>

               <Typography variant="subtitle2">Accounts Defaulted</Typography>
               <Grid container>
                 <HeaderWithDetails
                   header="Consecutive Missed Payments"
                   details={selectors.currentClientBusinessRules?.missedPmtsForDefault}
                   width="full" />
               </Grid>

               <Typography variant="subtitle2">PFR Variance</Typography>
               <Grid container>
                 <HeaderWithDetails
                   header="Contact patient when variance exeeds"
                   details={selectors.currentClientBusinessRules?.pfrVarianceTolerance}
                   width="full" />
               </Grid>

               <Typography variant="subtitle2">Fees</Typography>
               <Grid container>
                 <HeaderWithDetails
                   header="Automation Fee"
                   details={`${selectors.currentClientBusinessRules?.automationFee}%`}
                   width="third" />
                 <HeaderWithDetails
                   header="Merchant Processing"
                   details={`${selectors.currentClientBusinessRules?.merchantProcessingFee}%`}
                   width="third" />
                 <HeaderWithDetails
                   header="Processing Fee Total"
                   details={`${
                     +selectors.currentClientBusinessRules?.automationFee +
                     +selectors.currentClientBusinessRules?.merchantProcessingFee
                   }%`}
                   width="third" />
               </Grid>
               <Grid container>
                 <HeaderWithDetails
                    header="Web Payments Fee"
                    details={`${selectors.currentClientBusinessRules?.paynowFee === "" || selectors.currentClientBusinessRules?.paynowFee === undefined ? 0 : selectors.currentClientBusinessRules?.paynowFee}%`}
                    width="third" />
                 <Grid item xs={12} sm={4} className="pb-4"></Grid>
                 <HeaderWithDetails
                   header="Total Web Payments Fees"
                   details={`${+selectors.currentClientBusinessRules?.merchantProcessingFee +
                     +selectors.currentClientBusinessRules?.paynowFee
                     }%`}
                   width="third" />
               </Grid>

               <Grid container>
                 <HeaderWithDetails
                   header="Compliance Document Token ID"
                   details={selectors.currentClientBusinessRules?.customDocTokenId}
                   width="third" />

                 {/* A middle blank column so column to left is aligned left and column to right is aligned right. 1/3 blank column between */}
                 <Grid item xs={12} sm={4} className="pb-4"></Grid>

                 <HeaderWithDetails
                   header="Client Token ID Applies to"
                   details={selectors.currentClientBusinessRules?.useClientTemplate}
                   width="third" />
               </Grid>
               <Grid container>
                 <HeaderWithDetails
                     header="Real Time Updates"
                     details={Utils.convertBoolToYesNo(selectors.currentClientBusinessRules?.realTimeUpdates) || "No"}
                     width="third" />
               </Grid>
               <Grid container>
                   <HeaderWithDetails
                       header="Send PFR Adjustment Document"
                       details={Utils.convertBoolToYesNo(selectors.currentClientBusinessRules?.sendPFRDocs) || "No"}
                       width="third"/>
               </Grid>
             </Grid>
           </Grid>
         </Grid>
       </AccordionDetails>}
     </Accordion>
     {showEditSettingsModal && <EditClientBusinessRulesModal
       open={showEditSettingsModal}
       isEdit={isBusinessRulesComplete()}
       handleEditCancel={handleEditClose}
       handleEditSubmit={handleEditClose}
     ></EditClientBusinessRulesModal>}

   </div>
 );
}

export default EntitySettings;
